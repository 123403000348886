import { useState } from 'react';
import { useAuth } from '../../util/hooks.jsx';
import { useAppStateStore } from '../../appStateStore.jsx';
import { goToStoreDiamond, goToStoreHeart } from '../../util/goToStore.js';
import { useNavigate } from 'react-router-dom';
import SubscribeToNewsletter from '../../marketing/SubscribeToNewsletter.jsx';
import { sendPushNotificationByType } from 'cuebids-firebase/notifications';
import useNotifications from '../../components/notifications/useNotifications.jsx'

export default function Admin() {
  const { currentUser } = useAuth();
  const nav = useNavigate();
  const isNative = useAppStateStore((state) => state.isNative);
  const iap = useAppStateStore((state) => state.iap);
  const notify = useNotifications();
  const [showNewsletter, setShowNewsletter] = useState(false);
  const [linkUrl, setLinkUrl] = useState('http://192.168.1.1:3000');
  const [uid, setUid] = useState(currentUser?.uid);

  const handleClickStoreHeart = async () => {
    await goToStoreHeart({
      nav,
      isNative,
      iap,
      uid: currentUser.uid,
    });
  };

  const handleClickStoreDiamond = async () => {
    await goToStoreDiamond({ nav,
      isNative,
      iap,
      uid: currentUser.uid,
    });
  };
  
  
  const handleTestError = async () => {
    console.log('testing error', uid);
    
    throw new Error('Test Error');
  }

  const handleTestNotification = async () => {
    console.log('testing uid', uid);
    // await sendPushNotification(uid);
    await sendPushNotificationByType(
      uid,
      'partner_finished_bidding',
      'http://pro-brige.com',
      {
        partner: 'John Doe',
      })
  }

  const handleTestInAppNotification = async (type) => {
    notify(
      {
        title: 'title',
        text: 'Settings saved',
        type,
        url: 'https://cuebids.com/challengeSession',
      }
    );
  }

  return (
    <div className="page">
      <div>
        <div>Uid</div>
        <div>{currentUser?.uid}</div>
      </div>
        
      <button className={'btn'} onClick={handleTestError}>Test
          error
      </button>
        
      <input id="test-notify" className="input" value={uid}
        onChange={(e) => setUid(e.target.value)}/>
        
      <button className={'btn'} onClick={handleTestNotification}>Test
          Notification
      </button>
        
      <button className={'btn'}
        onClick={() => handleTestInAppNotification('error')}>Test Error
          App Notification (Toaster)
      </button>
      <button className={'btn'}
        onClick={() => handleTestInAppNotification('info')}>Test Info
          App Notification (Toaster)
      </button>
      <button className={'btn'}
        onClick={() => handleTestInAppNotification('warning')}>Test
          Warning App Notification (Toaster)
      </button>
      <button className={'btn'}
        onClick={() => handleTestInAppNotification('success')}>Test
          Success App Notification (Toaster)
      </button>
        
      {
        showNewsletter && <SubscribeToNewsletter/>
      }
        
        
      <div style={{ marginTop: 20 }}>
        <button
          className="btn"
          onClick={function () {
            setShowNewsletter(true);
          }}
        >
            Newsletter
        </button>
      </div>
        
      <div style={{ marginTop: 20 }}>
        <button
          className="btn"
          onClick={function () {
            void handleClickStoreHeart();
          }}
        >
            Heart
        </button>
      </div>
        
      <div style={{ marginTop: 20 }}>
        <button
          className="btn"
          onClick={function () {
            void handleClickStoreDiamond();
          }}
        >
            Diamond
        </button>
      </div>
        
      <div className="mt-5 p-1 flex flex-col gap-2 w-full md:w-3/5">
        <label htmlFor="link-input">Enter link address</label>
        <div className="info">Remember http etc if external link</div>
        <input id="link-input" className="input" value={linkUrl}
          onChange={(e) => setLinkUrl(e.target.value)}/>
        <a href={linkUrl}>Click me to go to link</a>
      </div>
      
    </div>
  );
}
