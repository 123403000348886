import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getGroup } from '../../firebase/groups';
import GroupHeader from '../../components/groupHeader/GroupHeader';
import Alert from '../../components/alert/alert';
import { LinkedUserDisplay } from '../../components/users/userDisplay';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next'

export default function PublicGroupPage() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.public_group' });
  const { groupId } = useParams();
  const groups = useAppStateStore((state) => state.groups);
  const navigate = useNavigate();

  const groupFromAppState = (groups || []).find(function (group) {
    return group.id === groupId;
  });

  useEffect(
    function () {
      if (groupFromAppState) {
        navigate(`/groups/${groupId}`);
      }
    },
    [groupFromAppState, navigate, groupId]
  );

  const { data: group } = useQuery({
    queryKey: ['group', groupId],
    queryFn: async () => await getGroup(groupId)
  });

  if (!group) {
    return null;
  }

  return (
    <div className="page pb-24">
      <GroupHeader
        name={group.name}
        description={group.description}
        premium={group.premium}
        groupFlag={group.groupFlag}
        logo={group.logo}
      />
      <Alert
        sx="mb-4"
        text={t('not_member_info')}
      />
      <LinkedUserDisplay uid={group.owner} />
    </div>
  );
}
