import { useState } from 'react'
import { Link } from 'react-router-dom'
import ChevronDown from '../../../components/icons/chevronDown'
import ChevronUp from '../../../components/icons/chevronUp'
import ChallengeStatisticBlock from './ChallengeStatisticBlock'
import { useTranslation } from 'react-i18next'

export default function ChallengeStatisticUserBlocks ({ user, linkToChallengeStats = false }) {
  const { t } = useTranslation('translation', { keyPrefix: 'challenge' });

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(e => !e);

  const wins = user.challengeStatistics?.wins ?? 0
  const draws = user.challengeStatistics?.draws ?? 0
  const losses = user.challengeStatistics?.losses ?? 0

  const numberOfBoards = user.challengeStatistics?.numberOfBoards ?? 0
  const numberOfChallenges = user.challengeStatistics?.numberOfChallenges ?? 0

  const winsRanked = user.challengeStatistics?.winsRanked ?? 0
  const drawsRanked = user.challengeStatistics?.drawsRanked ?? 0
  const lossesRanked = user.challengeStatistics?.lossesRanked ?? 0

  const numberOfBoardsRanked = user.challengeStatistics?.numberOfBoardsRanked ?? 0
  const numberOfChallengesRanked = user.challengeStatistics?.numberOfChallengesRanked ?? 0

  const winsFriendly = user.challengeStatistics?.winsFriendly ?? 0
  const drawsFriendly = user.challengeStatistics?.drawsFriendly ?? 0
  const lossesFriendly = user.challengeStatistics?.lossesFriendly ?? 0

  const numberOfBoardsFriendly = user.challengeStatistics?.numberOfBoardsFriendly ?? 0
  const numberOfChallengesFriendly = user.challengeStatistics?.numberOfChallengesFriendly ?? 0

  return (
    <>
      <div className="grid grid-cols-[1fr_1fr_40px_1fr_1fr] gap-y-2 place-items-center mt-2" role="button" onClick={toggleExpanded}>
        <ChallengeStatisticBlock
          numberOfChallenges={numberOfChallenges}
          wins={wins}
          draws={draws}
          losses={losses}
          numberOfBoards={numberOfBoards}
          header={t('challenges')}
        />
        {expanded && (
          <>
            <ChallengeStatisticBlock
              numberOfChallenges={numberOfChallengesRanked}
              wins={winsRanked}
              draws={drawsRanked}
              losses={lossesRanked}
              numberOfBoards={numberOfBoardsRanked}
              header={t('ranked')}
            />
            <ChallengeStatisticBlock
              numberOfChallenges={numberOfChallengesFriendly}
              wins={winsFriendly}
              draws={drawsFriendly}
              losses={lossesFriendly}
              numberOfBoards={numberOfBoardsFriendly}
              header={t('friendly')}
              friendlyIcon
            />
            {linkToChallengeStats && (
              <div className="w-full flex justify-center col-span-5 mb-2">
                <Link
                  to={`/challenge?tab=stats&statsTab=player&user=${user.id}`}
                  className="btn btn-secondary btn-sm h-8"
                >
                  {t('more_stats')}
                </Link>
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex justify-center" role="button" onClick={toggleExpanded}>{expanded ? <ChevronUp/> : <ChevronDown/>}</div>
    </>
  )
}
