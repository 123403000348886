import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Bar,
  BarChart,
  Cell,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Alert from '../../components/alert/alert';
import { Star } from '../../components/rating/starRating';
import { Tab, TabPanel, Tabs } from '../../components/tabs/tabs';
import PairDisplay from '../../components/users/pairDisplay';
import {
  UserDisplayName,
  UserDisplaySmall,
} from '../../components/users/userDisplay';
import { useAuth } from '../../util/hooks.jsx';
import {
  getAllDailiesForUser,
  getAllPairPerformanceReportsForUser,
  getAllWeekliesForUser,
  markPerformanceReportsAsRead,
} from 'cuebids-firebase/stats';
import { useAppStateStore } from '../../appStateStore.jsx'
import { useTranslation } from 'react-i18next'

const EventTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { stars, partner, rank, pairs } = payload[0].payload;

    return (
      <div className="rounded-lg border-0 bg-black/90 p-2 outline-none">
        <div className="flex flex-col items-center justify-between">
          <div className="label w-10 text-xs">
            {`${stars}`}
            <Star />
          </div>
          <div className="label w-10 text-xs">{`${rank}/${pairs}`}</div>
        </div>
        <div>
          <UserDisplaySmall uid={partner} />
        </div>
      </div>
    );
  }

  return null;
};

const PerformanceTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { averageGrade, dealsFinished, users } = payload[0].payload;

    return (
      <div className="rounded-lg border-0 bg-black/90 p-2 outline-none">
        <div className="flex flex-col items-center justify-between">
          <div className="label w-10 text-xs">
            {`${averageGrade}`}
            <Star />
          </div>
          <div className="label w-10 text-xs">{`${dealsFinished}`}</div>
        </div>
        <div>
          <PairDisplay uid1={users[0]} uid2={users[1]} />
        </div>
      </div>
    );
  }

  return null;
};

export function WeeklyBars({ weeklies, onClick, t }) {
  if ((weeklies?.length ?? 0) === 0) {
    return <div>{t('no_weekly_info')}</div>;
  }

  const lowStars = weeklies.reduce(
    (min, obj) => (obj.stars < min ? obj.stars : min),
    weeklies[0].stars
  );
  const highStars = weeklies.reduce(
    (max, obj) => (obj.stars > max ? obj.stars : max),
    weeklies[0].stars
  );

  return (
    <BarChart
      width={300}
      height={150}
      data={weeklies}
      margin={{
        top: 10,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      onClick={function (e) {
        const id = e?.activePayload[0].payload.id;
        if (id) {
          onClick(id);
        }
      }}
    >
      <Tooltip
        cursor={{ fill: '#224957' }}
        wrapperStyle={{ outline: 'none' }}
        content={<EventTooltip />}
      />
      <YAxis domain={[Math.min(lowStars, 50), highStars]} hide />
      <Bar dataKey="stars" fill="#e29627">
        {weeklies.map((entry, index) => (
          <Cell
            key={index}
            className="cursor-pointer"
            stroke={entry.rank === 1 ? 'white' : undefined}
            style={{
              filter:
                entry.rank === 1
                  ? 'drop-shadow(0px 0px 5px #e29627)'
                  : undefined,
            }}
          />
        ))}
      </Bar>
    </BarChart>
  );
}

export function DailyBars({ dailies, onClick, t }) {
  if ((dailies?.length ?? 0) === 0) {
    return <div>{t('no_daily_info')}</div>;
  }

  const lowStars = dailies.reduce(
    (min, obj) => (obj.stars < min ? obj.stars : min),
    dailies[0].stars
  );
  const highStars = dailies.reduce(
    (max, obj) => (obj.stars > max ? obj.stars : max),
    dailies[0].stars
  );

  return (
    <BarChart
      width={300}
      height={150}
      data={dailies}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      onClick={function (e) {
        const id = e?.activePayload[0].payload.id;
        if (id) {
          onClick(id);
        }
      }}
    >
      <Tooltip
        cursor={{ fill: '#224957' }}
        wrapperStyle={{ outline: 'none' }}
        content={<EventTooltip label={'Stars'} />}
      />
      <YAxis domain={[Math.min(lowStars, 10), highStars]} hide />
      <Bar dataKey="stars" fill="#4d754d">
        {dailies.map((entry, index) => (
          <Cell
            key={index}
            stroke={entry.rank === 1 ? 'white' : undefined}
            className="cursor-pointer"
            style={{
              filter:
                entry.rank === 1
                  ? 'drop-shadow(0px 0px 5px #4d754d)'
                  : undefined,
            }}
          />
        ))}
      </Bar>
    </BarChart>
  );
}

function extractDate(dateItem) {
  const d = dateItem.substring(8, 10);
  if (d.startsWith('0')) {
    return d.substring(1);
  }
  return d;
}

function extractMonth(dateItem) {
  const m = dateItem.substring(5, 7);
  if (m.startsWith('0')) {
    return m.substring(1);
  }
  return m;
}

function formatDate(dateItem) {
  return `${extractDate(dateItem)}/${extractMonth(dateItem)}`;
}

export function PairPerformance({ performance }) {
  return (
    <LineChart
      width={300}
      height={150}
      data={performance}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <Tooltip
        cursor={{ fill: '#224957' }}
        wrapperStyle={{ outline: 'none' }}
        content={<PerformanceTooltip />}
      />
      <YAxis domain={[1, 3]} hide />
      <Line
        type="monotone"
        dataKey="averageGrade"
        stroke="#4d754d"
        strokeWidth={2}
      />
      <XAxis dataKey="startDate" tickFormatter={formatDate} />
    </LineChart>
  );
}

function WeeklyTab({ weeklies, t }) {
  const [selectedWeekly, setSelectedWeekly] = useState(null);

  function handleSelectWeekly(weeklyId) {
    setSelectedWeekly(weeklies.find((w) => w.id === weeklyId));
  }
  return (
    <div className="w-full flex flex-col items-center mt-4">
      <Alert
        className="w-full"
        type="info"
        text={t('weekly_info')}
      />
      <div className="card w-full">
        <div className="card-body flex flex-col items-center justify-center rounded-xl shadow-xl">
          <h2 className="card-title flex w-full justify-center">{t('weekly')}</h2>
          <WeeklyBars weeklies={weeklies} onClick={handleSelectWeekly} t={t} />
        </div>
      </div>

      <div className="card rounded-xl shadow-xl px-4 w-75">
        {selectedWeekly ? (
          <div className="stat">
            <div className="stat-title">
              {new Date(selectedWeekly.end)?.toDateString()}
            </div>
            <div className="stat-value">{t('rank', {rank: selectedWeekly.rank})}</div>
            <div className="stat-value flex items-center">
              {selectedWeekly.stars} <Star />{' '}
            </div>
            <div className="stat-desc">{t('pairs_count', {count: selectedWeekly.pairs})}</div>
            <Link to={`/weeklyLeaderboard/${selectedWeekly.id}`}>
              <button className="btn btn-primary btn-sm mt-4">
                {t('leaderboard')}
              </button>
            </Link>
          </div>
        ) : (
          <div className="stat">
            <div className="stat-title">
              {t('select_weekly_info')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function DailyTab({ dailies, t }) {
  const [selectedDaily, setSelectedDaily] = useState(null);

  function handleSelectDaily(dailyId) {
    setSelectedDaily(dailies.find((w) => w.id === dailyId));
  }

  return (
    <div className="w-full flex flex-col items-center mt-4">
      <Alert
        className="w-full"
        type="info"
        text={t('daily_info')}
      />
      <div className="card w-full">
        <div className="card-body flex flex-col items-center justify-center rounded-xl shadow-xl">
          <h2 className="card-title flex w-full justify-center">{t('daily')}</h2>
          <DailyBars dailies={dailies} onClick={handleSelectDaily} t={t} />
        </div>
      </div>
      <div className="card rounded-xl shadow-xl px-4 w-75">
        {selectedDaily ? (
          <div className="stat">
            <div className="stat-title">
              {new Date(selectedDaily.end)?.toDateString()}
            </div>
            <div className="stat-value">{t('rank', {rank: selectedDaily.rank})}</div>
            <div className="stat-value flex items-center">
              {selectedDaily.stars} <Star />{' '}
            </div>
            <div className="stat-desc">{t('pairs_count', {count: selectedDaily.pairs})}</div>
            <Link to={`/dailyLeaderboard/${selectedDaily.id}`}>
              <button className="btn btn-primary btn-sm mt-4">
                {t('leaderboard')}
              </button>
            </Link>
          </div>
        ) : (
          <div className="stat">
            <div className="stat-title">{t('select_daily_info')}</div>
          </div>
        )}
      </div>
    </div>
  );
}

function PairPerformanceTab({
  setSelectedPair,
  pairs,
  selectedPair,
  selectedPairPerformance,
  t,
}) {
  return (
    <div className="card w-full flex flex-col mt-4 items-center">
      <Alert
        className="w-full"
        type="info"
        text={t('pairs_info')}
      />
      <div className="card w-full">
        <div className="card-body flex flex-col items-center justify-center rounded-xl shadow-xl">
          <table
            id="pair-performance"
            className="w-full border-collapse rounded-sm"
          >
            <thead>
              <tr>
                <th>{t('partner')}</th>
                <th className="pl-0">{t('deals')}</th>
                <th className="pl-0 text-end pr-5">{t('stars')}</th>
              </tr>
            </thead>
            <tbody className="shadow-lg">
              {pairs.map((p) => {
                return (
                  <tr
                    key={p.pd}
                    onClick={() => setSelectedPair(p.pd)}
                    className="cursor-pointer"
                  >
                    <td className="p-2">
                      <UserDisplaySmall uid={p.pd} />
                    </td>
                    <td>
                      <span>{p.dealsFinished}</span>
                    </td>
                    <td>
                      <div className="flex justify-end items-center h-full">
                        {p.averageGrade.toFixed(2)}
                        <Star />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card w-full">
        <div className="card-body flex flex-col items-center justify-center rounded-xl shadow-xl">
          {selectedPairPerformance && selectedPairPerformance.length > 0 && (
            <>
              <UserDisplayName uid={selectedPair} />

              <PairPerformance performance={selectedPairPerformance} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function Tabulars({
  weeklies,
  dailies,
  pairs,
  selectedPair,
  setSelectedPair,
  selectedPairPerformance,
  t,
}) {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? 'pairs';
  const navigate = useNavigate();

  const handeTabChange = (tabId) => {
    navigate(`/dashboard?tab=${tabId}`, { replace: true });
  };

  return (
    <div className="w-full md:w-4/5 mt-4">
      <Tabs>
        <Tab
          key={'pair'}
          label={t('tab_pairs')}
          value="pairs"
          active={tab === 'pairs'}
          onChange={handeTabChange}
        />
        <Tab
          key={'weekly'}
          label={t('tab_weekly')}
          value="weekly"
          active={tab === 'weekly'}
          onChange={handeTabChange}
        />
        <Tab
          key={'daily'}
          label={t('tab_daily')}
          value="daily"
          active={tab === 'daily'}
          onChange={handeTabChange}
        />
      </Tabs>
      <TabPanel key={'pairPanel'} value={tab} index={'pairs'}>
        <PairPerformanceTab
          pairs={pairs}
          selectedPair={selectedPair}
          setSelectedPair={setSelectedPair}
          selectedPairPerformance={selectedPairPerformance}
          t={t}
        />
      </TabPanel>
      <TabPanel key={'weeklyPanel'} value={tab} index={'weekly'}>
        <WeeklyTab weeklies={weeklies} t={t} />
      </TabPanel>
      <TabPanel key={'dailyPanel'} value={tab} index={'daily'}>
        <DailyTab dailies={dailies} t={t} />
      </TabPanel>
    </div>
  );
}

export default function DashBoard() {
  const { t } = useTranslation('translation', { keyPrefix: 'stats_dashboard' });
  const [weeklies, setWeeklies] = useState([]);
  const [dailies, setDailies] = useState([]);
  const [pairPerformance, setPairPerformance] = useState([]);
  const [pairs, setPairs] = useState([]);
  const [selectedPair, setSelectedPair] = useState([]);
  const user = useAppStateStore((state) => state.user);
  const { currentUser } = useAuth();

  const useTabs = true;

  useEffect(() => {
    async function getAllWeekly() {
      const weeklies = await getAllWeekliesForUser(currentUser.uid);
      setWeeklies(weeklies);
    }
    getAllWeekly();
  }, [currentUser.uid]);

  useEffect(() => {
    async function getAllDailies() {
      const dailies = await getAllDailiesForUser(currentUser.uid);
      setDailies(dailies);
    }
    getAllDailies();
  }, [currentUser.uid]);

  useEffect(function () {
    if (user?.hasUnreadPerformanceReports) {
      void markPerformanceReportsAsRead()
    }
  }, [user?.hasUnreadPerformanceReports]);

  useEffect(() => {
    async function getAllPairPerformanceReports() {
      const uid = currentUser.uid;
      const pairPerformance = await getAllPairPerformanceReportsForUser(uid);

      const result = [];
      pairPerformance.reduce(function (res, value) {
        const partner = value.users.find((p) => p !== uid);
        if (!res[partner]) {
          res[partner] = {
            pd: partner,
            dealsFinished: 0,
            averageGrade: 0,
          };
          result.push(res[partner]);
        }
        res[partner].averageGrade =
          (res[partner].averageGrade * res[partner].dealsFinished +
            value.averageGrade * value.dealsFinished) /
          (value.dealsFinished + res[partner].dealsFinished);
        res[partner].dealsFinished += value.dealsFinished;
        return res;
      }, {});
      const pairs = result
        .sort((a, b) => b.dealsFinished - a.dealsFinished)
        .slice(0, 20);
      setPairs(pairs);
      setPairPerformance(pairPerformance);
      setSelectedPair(pairs[0].pd);
    }
    getAllPairPerformanceReports();
  }, [currentUser.uid]);

  const selectedPairPerformance = selectedPair
    ? pairPerformance
      .filter((p) => p.users.includes(selectedPair))
      .sort((a, b) => new Date(a.startDate.substring(0, 10)) - new Date(b.startDate.substring(0, 10)))
    : [];

  if (useTabs) {
    return (
      <div className="dashboard-wrapper w-full flex justify-center">
        <Tabulars
          weeklies={weeklies}
          dailies={dailies}
          selectedPairPerformance={selectedPairPerformance}
          pairs={pairs}
          setSelectedPair={setSelectedPair}
          selectedPair={selectedPair}
          t={t}
        />
      </div>
    );
  }
}
