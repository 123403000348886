import { ranges } from './constants';

const opening = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*$/,
    explain: 'no one opened, open?',
    options: [
      {
        bidWithoutAlert: '1C',
        bid: '1C["3+!C better minor"]',
        reqHp: ranges.open,
        reqC: 3,
        reqMaxH: 4,
        reqMaxS: 4,
        reqStrains: function(strains) {
          return strains.C >= strains.D;
        },
      },
      {
        bidWithoutAlert: '1D',
        bid: '1D["3+!D better minor"]',
        reqHp: ranges.open,
        reqD: 3,
        reqMaxH: 4,
        reqMaxS: 4,
        reqStrains: function(strains) {
          return strains.D > strains.C;
        },
      },
      {
        bidWithoutAlert: '1H',
        bid: '1H["5+!H"]',
        reqHp: ranges.open,
        reqSuit: 5,
        reqStrains: function(strains) {
          return strains.H > strains.S;
        },
      },
      {
        bidWithoutAlert: '1S',
        bid: '1S["5+!S"]',
        reqHp: ranges.open,
        reqSuit: 5,
      },
      {
        bidWithoutAlert: '1N',
        bid: '1N["Balanced, 15—17"]',
        reqHp: 15,
        reqMaxHp: 17,
        reqS: 2,
        reqH: 2,
        reqD: 2,
        reqC: 2,
        reqMaxS: 5,
        reqMaxH: 5,
        reqMaxD: 6,
        reqMaxC: 6,
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P){0,2}$/,
    explain: 'no one opened, but not in fourth seat, weak open?',
    options: [
      {
        bidWithoutAlert: '2D',
        bid: '2D["Weak, 6+!D"]',
        reqD: 6,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 5),
      },
      {
        bidWithoutAlert: '2H',
        bid: '2H["Weak, 6+!H"]',
        reqH: 6,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 5),
      },
      {
        bidWithoutAlert: '2S',
        bid: '2S["Weak, 6+!S"]',
        reqS: 6,
        reqMaxH: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 5),
      },
      {
        bidWithoutAlert: '3C',
        bid: '3C["Weak, 6+!C"]',
        reqC: 6,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bidWithoutAlert: '3D',
        bid: '3D["Weak, 7+!D"]',
        reqD: 7,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 2,
      },
      {
        bidWithoutAlert: '3H',
        bid: '3H["Weak, 7+!H"]',
        reqH: 7,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 2,
      },
      {
        bidWithoutAlert: '3S',
        bid: '3S["Weak, 7+!S"]',
        reqS: 7,
        reqMaxH: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 2,
      },
      {
        bidWithoutAlert: '4C',
        bid: '4C["Weak, 8+!C"]',
        reqC: 8,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqParC: 110,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 3,
      },
      {
        bidWithoutAlert: '4D',
        bid: '4D["Weak, 8+!D"]',
        reqD: 8,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqParD: 110,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 3,
      },
      {
        bidWithoutAlert: '4H',
        bid: '4H["Weak, 8+!H"]',
        reqH: 8,
        reqMaxHp: ranges.weakOpen,
        reqParH: 150,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 3,
      },
      {
        bidWithoutAlert: '4S',
        bid: '4S["Weak, 8+!S"]',
        reqS: 8,
        reqMaxHp: ranges.weakOpen,
        reqParS: 150,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 3,
      },
    ],
  },
];

export default opening;
