import { useNavigate } from 'react-router-dom';
import moveToNextDeal from './moveToNextDeal';

export default function NextDealButton({
  sessionId,
  sessionDeals,
  userId,
  showOnboarding,
  currentDealIndex,
}) {
  const navigate = useNavigate();

  return (
    <button
      className="btn-primary btn w-full max-w-[100px] relative"
      type="submit"
      onClick={() => {
        moveToNextDeal({
          sessionDeals,
          sessionId,
          navigate,
          userId,
          currentDealIndex,
        });
      }}
    >
      {showOnboarding && (
        <div className="absolute right-0 top-0 badge badge-error pulse w-5" />
      )}
      <svg
        className="h-6 w-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
        ></path>
      </svg>
    </button>
  );
}
