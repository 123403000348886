import { currentUserId, getDatabase, getFirestore, isLoggedIn, getRepos, currentUser } from '../base';
import { limit, orderBy, query, where, getDocs, doc, updateDoc, arrayUnion, Timestamp, arrayRemove, writeBatch, increment, setDoc, getCountFromServer, serverTimestamp as fsServerTimestamp } from 'firebase/firestore';
import { docToObject, queryListenWithCallback } from 'firebase-util';
import { get, ref, set, serverTimestamp as rtdbServerTimestamp } from 'firebase/database';
import { createCommentOnBidYouFollowNotification, createCommentOnYourBidNotification, createFirstBidOnSpotNotification, createNewBidsOnSpotNotification } from '../notifications';
import { getPublicUserAsync, isAdmin } from '../users';
export function getSpotsObservable({ fed, callback }) {
    const repo = getRepos().spots;
    const q = query(repo.collection, where(repo.fieldName('fed'), '==', fed), where(repo.fieldName('deleted'), '==', false), orderBy(repo.fieldName('timestamp'), 'desc'), limit(100));
    return queryListenWithCallback(q, callback);
}
export async function getNumberOfNewSpots({ fed, timestamp }) {
    const repo = getRepos().spots;
    const q = query(repo.collection, where(repo.fieldName('fed'), '==', fed), where(repo.fieldName('deleted'), '==', false), where(repo.fieldName('timestamp'), '>', Timestamp.fromMillis(timestamp)));
    const res = await getCountFromServer(q);
    return res.data().count;
}
export async function getMyResponses() {
    if (!isLoggedIn()) {
        return [];
    }
    const q = query(getRepos().spotBids.collectionGroup, where(getRepos().spots.fieldName('uid'), '==', currentUserId()));
    const snap = await getDocs(q);
    return snap.docs.reduce((acc, doc) => {
        if (doc.ref.parent.parent == null) {
            throw new Error('Invalid poll');
        }
        const data = doc.data();
        // Could also add this to query if needed
        if (data.deleted !== true) {
            acc.push({
                spotId: doc.ref.parent.parent.id,
                id: doc.ref.id,
                bid: data.bid,
                text: data.text,
                timestamp: data.timestamp,
                uid: data.uid
            });
        }
        return acc;
    }, []);
}
export async function replyToBid(uid, spotId, id, reply, fed, bidOwnerUid, replies, likes, t) {
    const _resp = getRepos().spotBids.docRef(spotId, id);
    await updateDoc(_resp, {
        replies: arrayUnion({
            uid,
            reply,
            timestamp: Timestamp.now()
        })
    });
    void getPublicUserAsync(currentUserId()).then(function (user) {
        const displayName = user.displayName ?? currentUser()?.displayName;
        if (uid !== bidOwnerUid) {
            void createCommentOnYourBidNotification(bidOwnerUid, displayName, reply, fed, spotId, t);
        }
        const repliesUids = replies.map(r => r.uid);
        const allUids = repliesUids.concat(likes);
        const union = Array.from(new Set(allUids));
        union.forEach(u => {
            if (u !== uid && u !== bidOwnerUid) {
                void createCommentOnBidYouFollowNotification(u, displayName, reply, fed, spotId, t);
            }
        });
    });
}
export async function deleteReply(spotId, id, reply) {
    const _resp = getRepos().spotBids.docRef(spotId, id);
    await updateDoc(_resp, { replies: arrayRemove(reply) });
}
export async function likeResponse(uid, spotId, id) {
    const _resp = getRepos().spotBids.docRef(spotId, id);
    await updateDoc(_resp, { likes: arrayUnion(uid) });
}
export async function unLikeResponse(uid, spotId, id) {
    const _resp = getRepos().spotBids.docRef(spotId, id);
    await updateDoc(_resp, { likes: arrayRemove(uid) });
}
export async function getSpot(id) {
    const spot = await getRepos().spots.doc(id);
    const data = await docToObject(spot);
    if (data.deleted === true) {
        return null;
    }
    return data;
}
export function getAllResponsesObservable(id, callback) {
    const repo = getRepos().spotBids;
    const q = query(repo.collectionById(id), orderBy(repo.fieldName('timestamp'), 'desc'));
    return queryListenWithCallback(q, callback);
}
export async function handleBidOnSpot({ spotId, bid, text, uid, spotOwnerUid, responses = [], fed, t }) {
    if (spotId == null) {
        return;
    }
    const newBid = doc(getRepos().spotBids.collectionById(spotId));
    const batch = writeBatch(getFirestore());
    batch.set(newBid, {
        bid,
        text,
        uid: uid ?? null,
        timestamp: fsServerTimestamp(),
        deleted: false
    });
    const spotRef = getRepos().spots.docRef(spotId);
    if (bid != null) {
        batch.update(spotRef, {
            numberOfBids: increment(1)
        });
    }
    await batch.commit();
    if (bid != null && uid !== spotOwnerUid) {
        const numberOfResponses = responses.filter(r => r.bid != null && r.uid !== spotOwnerUid).length;
        if (numberOfResponses === 0) {
            void createFirstBidOnSpotNotification(spotOwnerUid, fed, spotId, t);
        }
        else if (numberOfResponses % 10 === 9) {
            void createNewBidsOnSpotNotification(spotOwnerUid, fed, spotId, t);
        }
    }
}
export async function createSpot({ bidding, vulnerability, dealer, scoring, description, answer = '', hand, fed, uid, sessionDealId }) {
    const newSpot = getRepos().spots.new();
    await setDoc(newSpot, {
        bidding,
        vulnerability,
        dealer,
        description,
        answer,
        hand,
        scoring,
        fed,
        uid,
        numberOfBids: 0,
        timestamp: fsServerTimestamp(),
        sessionDealId: sessionDealId ?? null,
        deleted: false
    });
    if (sessionDealId != null) {
        const sessionDealRef = getRepos().sessionDeals.docRef(sessionDealId);
        await updateDoc(sessionDealRef, {
            spots: arrayUnion({
                id: newSpot.id,
                fed
            }),
            lastAction: 'postSpot'
        });
    }
    return newSpot.id;
}
export async function deleteSpot(id) {
    const uid = currentUserId();
    const admin = isAdmin(uid);
    const spot = await getSpot(id);
    if (spot == null || spot.deleted) {
        throw new Error('the poll does not exist');
    }
    if (spot.uid !== currentUserId() && !admin) {
        throw new Error('this is not your poll');
    }
    if (spot.numberOfBids >= 10 && !admin) {
        throw new Error('this poll has too many bids to be deleted');
    }
    const batch = writeBatch(getFirestore());
    const spotRef = getRepos().spots.docRef(id);
    batch.update(spotRef, {
        deleted: true
    });
    const spotResponses = await getDocs(getRepos().spotBids.collectionById(id));
    spotResponses.docs.forEach(qds => batch.update(qds.ref, {
        deleted: true
    }));
    if (spot.sessionDealId != null) {
        const sessionDealRef = getRepos().sessionDeals.docRef(spot.sessionDealId);
        batch.update(sessionDealRef, {
            spots: arrayRemove({
                id,
                fed: spot.fed
            }),
            lastAction: 'removeSpot'
        });
    }
    await batch.commit();
}
export async function editSpot({ id, bidding, vulnerability, dealer, scoring, description, answer = '', hand }) {
    const spot = await getSpot(id);
    if (spot == null || spot.deleted) {
        throw new Error('the poll does not exist');
    }
    if (spot.uid !== currentUserId()) {
        throw new Error('this is not your poll');
    }
    if (spot.numberOfBids >= 10) {
        throw new Error('this poll has too many bids to be edited');
    }
    const spotRef = getRepos().spots.docRef(id);
    await updateDoc(spotRef, {
        bidding,
        vulnerability,
        dealer,
        description,
        answer,
        hand,
        scoring
    });
}
export async function setLastReadTimestamp(id) {
    if (!isLoggedIn()) {
        return;
    }
    const lastReadData = ref(getDatabase(), `spots/${id}/lastReadData/${currentUserId()}`);
    await set(lastReadData, rtdbServerTimestamp());
}
export async function getLastReadTimestamp(id) {
    if (!isLoggedIn()) {
        return;
    }
    const lastReadData = ref(getDatabase(), `spots/${id}/lastReadData/${currentUserId()}`);
    return await get(lastReadData).then((snapshot) => {
        return snapshot.val();
    });
}
export async function getNumberOfNewBids(id, lastReadTimestamp) {
    // Cannot do query with both > and !=, so we need to do two queries and subtract manually
    const qTotal = query(getRepos().spotBids.collectionById(id), where(getRepos().spotBids.fieldName('timestamp'), '>', Timestamp.fromMillis(lastReadTimestamp)));
    const qAbstain = query(getRepos().spotBids.collectionById(id), where(getRepos().spotBids.fieldName('timestamp'), '>', Timestamp.fromMillis(lastReadTimestamp)), where(getRepos().spotBids.fieldName('bid'), '==', null));
    const p1 = getCountFromServer(qTotal);
    const p2 = getCountFromServer(qAbstain);
    const [total, abstain] = await Promise.all([p1, p2]);
    return total.data().count - abstain.data().count;
}
