import Icon from '../../images/icons/notificationCircle.svg?react';

export const NotificationCircleIcon = (props) => (
  <Icon
    width={props.width || '16px'}
    height={props.width || '16px'}
    color={props.color || 'white'}
    fill={props.fill || 'white'}
    {...props}
  />
);
