import { useNavigate } from 'react-router-dom';

export default function GoBack(props) {
  const nav = useNavigate();

  const goBack = () => nav(-1);

  return (
    <button className="btn-ghost btn" onClick={props.backAction || goBack}>
      <svg
        className="h-5 w-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 19l-7-7 7-7"
        ></path>
      </svg>
    </button>
  );
}
