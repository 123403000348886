import { getHandFromDirection } from 'cuebids-hand-util';
import {
  getBidArrayWithoutAlertsIncludingPositionalSymbols,
  getOpeningBidWithIndex,
  getPositionForNextBidder,
  isBidHigher,
  removeAlerts,
} from 'cuebids-bidding-util';
import { baseSystem, findOptions, miniNTSystem, multiSystem } from './base'
import { willRobotsDouble } from './balancingPenaltyDouble';
import opening from './opening';

export { willRobotsDouble };

const hpMap = {
  A: 4,
  K: 3,
  Q: 2,
  J: 1,
};

function getHpCount(hand) {
  if (!hand) return 0;
  return hand.split('').reduce((a, v) => {
    return a + (hpMap[v] ?? 0);
  }, 0);
}

export const getRobotStrainsAndHps = (hand) => {
  const suits = hand.split('.');

  const lens = suits.map((v) => {
    return v.length;
  });

  const hps = suits.map((v) => {
    return getHpCount(v);
  });

  return {
    strains: { S: lens[0],
      H: lens[1],
      D: lens[2],
      C: lens[3] },
    hp: { S: hps[0],
      H: hps[1],
      D: hps[2],
      C: hps[3] },
  };
};

export function whoAmI(bidding) {
  const bidArray = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding);
  const biddingLength = bidArray.length;
  const playerMap = {
    0: 'W',
    1: 'N',
    2: 'E',
    3: 'S'
  }
  return playerMap[biddingLength % 4];
}

const getAIAction = (ai) => {
  if (ai === 'P') {
    return '-P';
  }
  if (ai.substring(0, 4) === 'open') {
    return ai.substring(4);
  }
};

function getAiSystem(aiSystem) {
  if (aiSystem === 'multi') {
    return multiSystem;
  } else if (aiSystem === 'miniNT') {
    return miniNTSystem;
  }

  return baseSystem;
}

function getOpenings(aiSystem) {
  if (aiSystem === 'multi') {
    return multiSystem;
  } else if (aiSystem === 'miniNT') {
    return miniNTSystem;
  }

  return opening;
}

export function getPotentialLevelForNT(
  eastStrainsAndHps,
  westStrainsAndHps,
  vulnerability,
  ai,
) {
  const eastHp =
      eastStrainsAndHps.hp.S +
      eastStrainsAndHps.hp.H +
      eastStrainsAndHps.hp.D +
      eastStrainsAndHps.hp.C;
  const westHp =
      westStrainsAndHps.hp.S +
      westStrainsAndHps.hp.H +
      westStrainsAndHps.hp.D +
      westStrainsAndHps.hp.C;
  const hp = eastHp + westHp;

  let aiPotentialLevel = 0;
  if (ai) {
    if (ai === 'P') {
      return 0;
    }
    // ai on form "open-1H"
    const aiLevel = ai[5];
    const aiSuit = ai[6];
    if (aiSuit === 'N') {
      aiPotentialLevel = parseInt(aiLevel, 10);
    }
  }

  const hpPerLevel = [20, 22, 24, 27, 30, 32, 36];
  for (let i = 0; i < hpPerLevel.length; i++) {
    if (hp < hpPerLevel[i]) return Math.max(0, i, aiPotentialLevel);
  }
  return Math.max(0, 7, aiPotentialLevel);
}

export function getPotentialLevelForSuit(
  eastStrainsAndHps,
  westStrainsAndHps,
  suit,
  vulnerability,
  ai,
) {
  const eastHp =
      eastStrainsAndHps.hp.S +
      eastStrainsAndHps.hp.H +
      eastStrainsAndHps.hp.D +
      eastStrainsAndHps.hp.C;
  const westHp =
      westStrainsAndHps.hp.S +
      westStrainsAndHps.hp.H +
      westStrainsAndHps.hp.D +
      westStrainsAndHps.hp.C;
  const hp = eastHp + westHp;

  const eastSuit = eastStrainsAndHps.strains[suit];
  const westSuit = westStrainsAndHps.strains[suit];
  const fit = eastSuit + westSuit;

  let potentialLevel;
  if (hp < 16) {
    // sac mode - bid according to law modified for vul
    if (vulnerability === 'NS') {
      // green vs red
      potentialLevel = fit - 6 + 1;
    } else if (vulnerability === 'EW') {
      // red vs green
      potentialLevel = fit - 6 - 1;
    } else {
      // equal vul
      potentialLevel = fit - 6;
    }
  } else {
    // constructive mode
    // 8 trumps and 24 hp is enough for major game, 9 trumps and 26 hp is enough for minor game, treat a trump as 3 hp
    const hpFitScore = 3 * fit + hp; // 48 is game in major, aka 10 tricks. 53 is game in minor, aka 11 tricks (round to 52.8 for minor/major to work same)
    potentialLevel = Math.floor(hpFitScore / 4.8) - 6;
  }

  let aiPotentialLevel = 0;
  if (ai) {
    if (ai === 'P') {
      return 0;
    }
    // ai on form "open-1H"
    const aiLevel = ai[5];
    const aiSuit = ai[6];
    if (aiSuit === suit) {
      aiPotentialLevel = parseInt(aiLevel, 10);
    }
  }

  return Math.max(0, potentialLevel, aiPotentialLevel);
}

export function getPotentialRobotLevels(hand, vulnerability, ai) {
  const eastStrainsAndHps = getRobotStrainsAndHps(
    getHandFromDirection(hand, 'E'),
  );
  const westStrainsAndHps = getRobotStrainsAndHps(
    getHandFromDirection(hand, 'W'),
  );

  return {
    N: getPotentialLevelForNT(
      eastStrainsAndHps,
      westStrainsAndHps,
      vulnerability,
      ai,
    ),
    S: getPotentialLevelForSuit(
      eastStrainsAndHps,
      westStrainsAndHps,
      'S',
      vulnerability,
      ai,
    ),
    H: getPotentialLevelForSuit(
      eastStrainsAndHps,
      westStrainsAndHps,
      'H',
      vulnerability,
      ai,
    ),
    D: getPotentialLevelForSuit(
      eastStrainsAndHps,
      westStrainsAndHps,
      'D',
      vulnerability,
      ai,
    ),
    C: getPotentialLevelForSuit(
      eastStrainsAndHps,
      westStrainsAndHps,
      'C',
      vulnerability,
      ai,
    ),
  };
}

export function getHighestPotentialBid(potentialLevels) {
  return ['N', 'S', 'H', 'D', 'C'].reduce((highest, suit) => {
    const level = potentialLevels[suit];
    if (level > 0 && isBidHigher(`${level}${suit}`, highest)) {
      return `${level}${suit}`;
    }

    return highest;
  }, 'P');
}

function actionWithAlert(action, aiSystem) {
  const bid = action.slice(1, 3)

  let foundOption;

  const options = getOpenings(aiSystem);

  for (const item of options) {
    foundOption = item.options.find((o) => o.bidWithoutAlert === bid)?.bid;
    if (foundOption) break;
  }

  return foundOption ? '-' + foundOption : action;
}


export default function getRobotActionV2({ sessionDeal, bidding, test = false }) {
  if (sessionDeal.ai) {
    if (sessionDeal.ai === 'P') {
      return '-P';
    }
    const aiAction = sessionDeal.ai.command ? getAIAction(sessionDeal.ai.command) : getAIAction(sessionDeal.ai);
    if (aiAction && getOpeningBidWithIndex(bidding).bid === 'P') {
      return actionWithAlert(aiAction, sessionDeal.aiSystem);
    }
  }

  const biddingWithoutAlerts = removeAlerts(bidding);
  const direction = whoAmI(biddingWithoutAlerts);
  const hand = getHandFromDirection(sessionDeal.hand, direction);
  const strainsAndHps = getRobotStrainsAndHps(hand);
  const potentialLevels = getPotentialRobotLevels(sessionDeal.hand, sessionDeal.vulnerability, sessionDeal.ai);
  const highestPotentialBid = getHighestPotentialBid(potentialLevels);

  if(test)
    console.log(`Robot hand: ${hand}. Shape: ${strainsAndHps.strains.S}-${strainsAndHps.strains.H}-${strainsAndHps.strains.D}-${strainsAndHps.strains.C}. HCP: ${strainsAndHps.hp.S + strainsAndHps.hp.H + strainsAndHps.hp.D + strainsAndHps.hp.C}`);

  const ob = {
    bidding: biddingWithoutAlerts,
    hp:
      strainsAndHps.hp.S +
      strainsAndHps.hp.H +
      strainsAndHps.hp.D +
      strainsAndHps.hp.C,
    strains: strainsAndHps.strains,
    hpSuits: strainsAndHps.hp,
    vulnerability: sessionDeal.vulnerability,
    position: getPositionForNextBidder(biddingWithoutAlerts),
    robotPars: sessionDeal.robotPars,
    potentialLevels,
    highestPotentialBid,
    hasPotentialForParBeatBest: !!sessionDeal.hasPotentialForParBeatBest,
  };

  let system = getAiSystem(sessionDeal.aiSystem)

  let opts = [];
  system.forEach((action) => {
    const res = action.biddingMatch.test(ob.bidding);
    if (res) {
      if (test) console.log('Matched: ', action.explain);
      opts = opts.concat(findOptions(ob, action, sessionDeal.ai));
    }
  });

  // console.log('All options: ', opts);

  if (!opts.length) {
    if(test)
      console.log('no options');
    return '-P';
  }


  if (test) console.log('opts: ', opts);

  const sortedOpts = opts
    .map((o) => {
      return { ...o,
        weight: o.weight || 1 };
    })
    .sort(function(a, b) {
      return b.weight - a.weight;
    });

  const opt = sortedOpts[0];

  if (test) console.log('Selected opt: ', opt);
  if (test) console.log('Selected bid: ', opt.bid);

  return '-' + opt.bid;
}
