import Hand from '../../../components/cards/hand';
import BiddingDisplay from '../../../components/bidding/biddingDisplay';
import Alert from '../../../components/alert/alert';
import { useAppStateStore } from '../../../appStateStore.jsx';

export default function ExpiredSessionDeal({
  sessionDeal,
  isSmall,
  topHandDirection,
}) {
  const user = useAppStateStore((state) => state.user);

  return (
    <div className="flex max-h-[525px] grow flex-col items-center justify-around md:max-h-[750px]">
      <Hand
        variant={
          user?.cardVariant ??
          (user?.handAsDiagram ? 'diagram' : 'modern')
        }
        showHcp={!(user?.hideHcpCount ?? false)}
        hand={sessionDeal.partnerHandPart}
        size={user?.cardSize ?? 'sm'}
        direction={topHandDirection}
        order={user?.suitOrder ?? 'default'}
      />
      <div className="mt-4 mb-8 h-[200px] text-base-content">
        <BiddingDisplay
          bidding={sessionDeal.bidding}
          vulnerability={sessionDeal.vulnerability}
          variant={isSmall ? 'compact' : 'standard'}
          startWithEast={topHandDirection === 'S'}
          hideAlertsIndex={sessionDeal.finished ? -1 : 1}
        />
      </div>
      <div className="flex w-full justify-center">
        <Alert
          severity="warning"
          text="The challenge has ended and can no longer be finished."
        />
      </div>
    </div>
  );
}
