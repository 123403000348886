import {
  getHand
} from 'cuebids-hand-util';
import {
  getBidArrayWithoutAlertsExcludingPositionalSymbols,
  getOpeningBidWithIndex,
  isBidHigher,
} from 'cuebids-bidding-util';

import { getPotentialRobotLevels } from '../V2/getRobotAction';

const hpMap = {
  A: 4,
  K: 3,
  Q: 2,
  J: 1,
};

const suitMap = {
  0: 'S',
  1: 'H',
  2: 'D',
  3: 'C',
};

const strainValue = {
  C: 1,
  D: 2,
  H: 3,
  S: 4,
  N: 5,
};

export function getRobotMode(hand) {
  const hp = getRobotPairHp(hand);
  const robotStrain = getRobotStrain(hand);

  if (hp < 8) {
    return robotStrain.length > 10 ? 'sac' : 'none';
  }
  if (hp < 10) {
    return robotStrain.length > 9 ? 'sac' : 'none';
  }
  if (hp < 12) {
    return robotStrain.length > 8 ? 'sac' : 'none';
  }
  if (hp > 11) {
    return robotStrain.length > 7 ? 'compete' : 'none';
  }
  if (hp > 13) {
    return robotStrain.length === 7 ? 'compete' : 'none';
  }

  return 'none';
}

export function getPotentialContract(hand, vulnerability, ai) {
  if (ai) {
    if (ai === 'P') {
      return 'P';
    }
    const aiAction = getAIAction(ai);
    if (aiAction) {
      return aiAction.substring(1); // Remove leading -
    }
    // TODO: Now robots cannot bid higher than the opening bid in this case
  }

  const robotStrain = getRobotStrain(hand);
  const mode = getRobotMode(hand);
  if (mode === 'none') {
    return 'P';
  }

  if (mode === 'compete') {
    let modifier = 0;
    // red vs green, lower level by one for bids above 2-level (more than 8-card fit)
    if (vulnerability === 'EW' && robotStrain.length >= 9) {
      modifier = -1;
    }

    // max level from law and modifier
    const maxLevel = robotStrain.length - 6 + modifier;

    // never bid higher than 5D or according to maxLevel
    return getActualBid(maxLevel + robotStrain.suit, '.', '5D');
  }

  // mode === 'sac'
  let lawBreaking = 0; // How much robots can diverge from law due to vulnerability
  if (vulnerability === 'NS') {
    lawBreaking = 1;
  } else if (vulnerability === 'EW') {
    lawBreaking = -1;
  }

  // max level from law and vulnerability
  const maxLevel = robotStrain.length - 6 + lawBreaking;

  // never bid higher than 5D or according to maxLevel
  return getActualBid(maxLevel + robotStrain.suit, '.', '5D');
}

function getFinalSuitBid(bidding) {
  if (bidding.indexOf('-P-P-P-P') > -1) {
    return {
      finalBid: 'P',
      declarer: null,
    };
  }
  const bids = getBidArrayWithoutAlertsExcludingPositionalSymbols(bidding);

  if (!bids.length) {
    return {
      finalBid: 'P',
      declarer: null,
    };
  }

  let counter = 0;
  let finalBid = '';
  let finalBidSuit = '';
  let declarer = '';
  let lastBidFound = false;

  for (let i = bids.length - 1; i >= 0; i--) {
    counter++;
    if (
      bids[i] === 'P' ||
      bids[i] === '.' ||
      bids[i] === 'R' ||
      bids[i] === 'D'
    ) {
      continue;
    }

    if (!lastBidFound) {
      lastBidFound = true;
      finalBid = bids[i];
      finalBidSuit = finalBid.substring(1);
      declarer = counter;
      continue;
    }

    if (
      bids[i].substring(1) === finalBidSuit &&
      (counter - declarer) % 4 === 2
    ) {
      declarer = counter;
    }
  }

  if (!lastBidFound) {
    return {
      finalBid: 'P',
      declarer: null,
    };
  }

  const declarerIndex = (bids.length - declarer) % 4;

  return {
    finalBid: finalBid,
    declarer: ['W', 'N', 'E', 'S'][declarerIndex],
  };
}

function getHpCount(hand) {
  if (!hand) return;
  return hand.split('').reduce((a, v) => {
    return a + (hpMap[v] ?? 0);
  }, 0);
}

const getRobotPairHp = (hands) => {
  const handE = getHand(hands, 1);
  const handW = getHand(hands, 3);
  return getHpCount(handE) + getHpCount(handW);
};

const getRobotStrain = (hands) => {
  const handE = getHand(hands, 1);
  const suitsE = handE.split('.');
  const handW = getHand(hands, 3);
  const suitsW = handW.split('.');

  const lens = suitsE.map((v, i) => {
    return v.length + suitsW[i].length;
  });
  const len = Math.max(...lens);

  return { suit: suitMap[lens.indexOf(len)],
    length: len };
};

const getConstructiveBid = (over, suit) => {
  if (over === 'P' || over === '.') {
    return '1' + suit;
  }
  if (strainValue[over[1]] >= strainValue[suit]) {
    return parseInt(over[0]) + 1 + suit;
  }

  return over[0] + suit;
};

const getDestructiveBid = (over, suit) => {
  if (over === 'P' || over === '.') {
    return suit === 'C' ? '3C' : '2' + suit;
  }
  if (strainValue[over[1]] >= strainValue[suit]) {
    return parseInt(over[0]) + 2 + suit;
  }

  return parseInt(over[0]) + 1 + suit;
};

export function getActualBid(wantedBid, over, maxBid = '7N') {
  if (over === 'P' || over === '.') {
    return getActualBid(wantedBid, '0C', maxBid);
  }

  if (!isBidHigher(wantedBid, over)) {
    return 'P';
  }

  if (!isBidHigher(wantedBid, maxBid)) {
    return wantedBid;
  }

  return getActualBid(wantedBid[0] - 1 + wantedBid[1], over, maxBid);
}

// TODO: Other code will only work if this only does openings bids
const getAIAction = (ai) => {
  if (ai === 'P') {
    return '-P';
  }

  if (ai.command?.substring(0, 4) === 'open') {
    return ai.command.substring(4);
  }

  // If not, it's a string
  if (ai.substring(0, 4) === 'open') {
    return ai.substring(4);
  }
};

export default function getRobotActionV1(
  hand,
  bidding,
  vulnerability,
  ai = null,
) {
  if (ai) {
    if (ai === 'P') {
      return '-P';
    }
    const aiAction = getAIAction(ai);
    // TODO: Can only do it for openings right now
    if (aiAction && getOpeningBidWithIndex(bidding).bid === 'P') {
      return aiAction;
    }
  }

  const mode = getRobotMode(hand);
  const hp = getRobotPairHp(hand);
  const robotStrain = getRobotStrain(hand);
  const lastBid = getFinalSuitBid(bidding);
  const openingBid = getOpeningBidWithIndex(bidding);
  // const robotBidCount = getRobotBidCount(bidding);

  const potentialLevels = getPotentialRobotLevels(hand, vulnerability, ai);
  const potentialContract = getPotentialContract(hand, vulnerability, ai);
  const highestPotentialBid = potentialLevels[robotStrain.suit] + robotStrain.suit;
  // Take the lowest
  const limit = isBidHigher(highestPotentialBid, potentialContract) ? potentialContract : highestPotentialBid;

  if (limit === 'P') {
    return '-P';
  }

  // robot opening (nobody has bid)
  if (openingBid.index === -1) {
    if (mode === 'compete') {
      // 50 % (33 % for 7-card fit)
      if (
        (hp % 2 === 0 && robotStrain.length >= 8) ||
        (hp % 3 === 0 && robotStrain.length === 7)
      ) {
        return '-' + getActualBid(getConstructiveBid(lastBid.finalBid, robotStrain.suit), 'P', limit);
      } else {
        // It would be good to pass here, because now the robots will go the logic for overcalling later and if they would ever bid they will bid immediately, meaning they will never pass first and bid later.
        // HOWEVER, if we return pass here we will behave differently depending on if players pass/open.
        // For example, Table 1 player opens 1H, Table 2 player passes in first seat. At table 1 robot will overcall 1S. Att table 2 robot will (might) pass initially. Then if player 2 at table 2 opens says 1D, the second robot will overcall 1S.
        // This leads to a situation where at one table it looks like robot 1 has spades, and at table 2 robot 2 will have it. This confusion is worse than the robots not being able to pass then bid.
        // TODO: Solve both problems so that we can pass here safely without confusion.
        // return '-P';
      }
    }
    if (mode === 'sac') {
      // 50 %
      if (hp % 2 === 0) {
        return '-' + getActualBid(getDestructiveBid(lastBid.finalBid, robotStrain.suit), '2C', limit);
      } else {
        // See comment above why we cannot pass here.
        // return '-P';
      }
    }
  }

  // remove potential incorrect robot overcall in our opening suit
  // we opened
  if (openingBid.index === 1 || openingBid.index === 3) {
    // in robot best suit
    if (openingBid.bid[1] === robotStrain.suit) {
      // no 10 card fit
      if (robotStrain.length < 10) {
        return '-P';
      }
    }
  }

  // remove takeout double since it never happens and there is a small risk (maybe) that robots double but are later blocked by highestPotentialBid to respond to double
  // robot takeout double
  // if (mode === 'compete') {
  //   // we opened
  //   if (openingBid.index === 1 || openingBid.index === 3) {
  //     // robots haven't bid
  //     if (robotBidCount === 0) {
  //       // opening on 1 or 2 level, and not in no trump
  //       if (openingBid[0] <= 2 && openingBid[2] !== 'N') {
  //         // 50 % for 8 card fit, 33 % for better fit
  //         if (
  //           (hp % 2 === 0 && robotStrain.length === 8) ||
  //           (hp % 3 === 0 && robotStrain.length >= 9)
  //         ) {
  //           return '-D';
  //         }
  //       }
  //     }
  //   }
  // }

  // robot 1-level overcall without good fit (7 card)
  // 33 %
  if (robotStrain.length === 7 && hp % 3 === 0) {
    const bid = getConstructiveBid(lastBid.finalBid, robotStrain.suit);
    // only accept bids on 1-level
    return '-' + getActualBid(bid, lastBid.finalBid, isBidHigher('1S', limit) ? limit : '1S');
  }

  // robot compete
  if (mode === 'compete') {
    const bid = getConstructiveBid(lastBid.finalBid, robotStrain.suit);
    return '-' + getActualBid(bid, lastBid.finalBid, limit);
  }

  // robot pre-empt/sac
  if (mode === 'sac') {
    const bid = getDestructiveBid(lastBid.finalBid, robotStrain.suit);
    const actualBid = getActualBid(bid, lastBid.finalBid, limit);

    // Since we cannot pass in the if statement above when robots are in first seat (see details in comment above), we have an issue here.
    // The scenario is that robots want to open with 3C, but are limited by potentialContract to only bid 2C. That does not work when it is an opening bid (would be confusing).
    // To avoid any confusion with different robots bidding at different tables, the robots can never pre-empt (sac mode) with only 2C.
    if (actualBid === '2C') {
      return '-P';
    }

    return '-' + actualBid;
    // Note: There is an unlikely scenario where NS open with a high bid, robots wants to pre-empt, but are corrected down to a lower level, resulting in a minimum level overcall.
    // If that is not desirable (if the bots passing is preferred), we can calculate a minimum bid (over) as well.
  }

  return '-P';
}
