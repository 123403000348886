import { useState } from "react";
import { useParams } from "react-router-dom";
import { tryClaimCode } from "cuebids-firebase/codes";
import useNotifications from "../../components/notifications/useNotifications";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function Codes() {
  const { t } = useTranslation('translation', { keyPrefix: 'code' });
  const { code: codeParam } = useParams();
  const notify = useNotifications();

  const [code, setCode] = useState(codeParam ?? '');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleUpdateCode = (e) => {
    setCode(e.target.value);
  }

  const tryClaim = async () => {
    // Do something with the code
    setLoading(true);
    try {
      await tryClaimCode(code);
      notify(
        {
          type: 'success',
          text: t('code_claimed'),
        },
      );
      setSuccess(true);
    } catch (e) {
      notify(
        {
          type: 'failure',
          text: t(e.message || 'error_claim'),
        },
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="page p-4">
      <article className="prose">
        <h1>{t('title')}</h1>
        <div
          className={
            "not-prose flex flex-col items-center justify-center mt-4 w-full"
          }
        >
          <form className="mt-4 flex w-80 flex-col gap-5 pb-1">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">{t('code_input_label')}</span>
              </label>
              <input
                value={code}
                placeholder={t('code_input_placeholder')}
                onChange={handleUpdateCode}
                disabled={loading}
                type="text"
                required
                className="input w-44 uppercase text-white"
              />
            </div>
            <button
              type={"submit"}
              disabled={code.length < 3 || loading}
              onClick={tryClaim}
              className="btn-secondary btn w-full rounded-[10px]"
            >
              {t('claim_submit_button')}
            </button>
          </form>
        </div>
      </article>

      {
        loading && (
          <div className="loading mt-4">
            <div className="spinner"></div>
          </div>
        )
      }
      {
      success && (
        <Link to="/messages" className="btn btn-primary mt-4">
          {t('claim_successful_info')}
          </Link>
        )
      }
    </div>
  );
}
