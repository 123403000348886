import { get, ref, orderByChild, query, equalTo } from 'firebase/database';
import { getDatabase } from '../base';
export async function getPublicUserAsync(uid) {
    const userRef = ref(getDatabase(), `users/${uid}`);
    return {
        ...(await get(userRef)).val(),
        uid
    };
}
export async function getPros() {
    const usersRef = ref(getDatabase(), 'users');
    const a = await get(query(usersRef, orderByChild('pro'), equalTo(true)));
    return Object.keys(a.val());
}
