import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import goToStore from '../../util/goToStore';
import GoBack from '../goBack/goBack';
import logo from '../../images/logos/logo512.png';
import { Menu } from '../menu/menu';
import {
  getCurrentOnboardingStep,
  onboardingSteps,
  shouldShowOnboarding,
} from '../../util/onboarding'
import SpotsProfile from '../../pages/profile/spotsProfile';
import { SettingsIcon } from '../icons/settingsIcon';
import { useAuth, useOutsideClick } from '../../util/hooks';
import { useAppStateStore } from '../../appStateStore';
import sv from '../../images/logos/feds/sv.png';
import SelectFed from '../../pages/profile/selectFed';
import { StatsIcon } from '../icons/statsIcon';
import { TicketIcon } from '../icons/ticketsIcon';
import { BarChartIcon } from '../icons/barChartIcon';
import { ShareIcon } from '../icons/shareIcon';
import { MessageIcon } from '../icons/messageIcon';
import { QuestionIcon } from '../icons/questionIcon';
import { getTotalNumberOfNewSpots } from '../../util/spots';
import { TypeIcon } from '../icons/typeIcon';
import { StarIcon } from '../icons/starIcon';
import { LogoutIcon } from '../icons/logoutIcon.jsx';
import { getNumberOfUnreadGroupMessages } from '../../util/groups.js'
import { GroupsIcon } from '../icons/groupsIcon.jsx'

export default function Navbar() {
  const { currentUser } = useAuth();
  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const setLanguage = useAppStateStore((state) => state.setLanguage);
  const history = useAppStateStore((state) => state.history);
  const isNative = useAppStateStore((state) => state.isNative);
  const iap = useAppStateStore((state) => state.iap);
  const user = useAppStateStore((state) => state.user);
  const title = useAppStateStore((state) => state.title);
  const subtitle = useAppStateStore((state) => state.subtitle);
  const navbarItems = useAppStateStore((state) => state.navbarItems);
  const numberOfNewSpots = useAppStateStore((state) => state.numberOfNewSpots);
  const location = useLocation();
  const nav = useNavigate();
  const metadata = useAppStateStore((state) => state.metadata);
  const groups = useAppStateStore((state) => state.groups) || [];
  const groupsExtraData = useAppStateStore((state) => state.groupsExtraData);
  const groupsMessages = useAppStateStore((state) => state.groupsMessages);
  const eventInvites = useAppStateStore((state) => state.eventInvites);
  const groupInvites = useAppStateStore((state) => state.groupInvites);
  const groupEventsBadges = user?.eventBadges?.length ?? 0;

  const { fed } = useParams();

  const groupsUnreadMessagesLength = groups.reduce((acc, group) => {
    if (!currentUser) return acc;
    return acc + getNumberOfUnreadGroupMessages({ groupId: group.id, groupsExtraData, groupsMessages, userId: currentUser.uid });
  }, 0);

  const numberOfGroupEventInvites = eventInvites.filter(
    (i) =>
      i.invitee === currentUser?.uid &&
      groups.map((g) => g.id).includes(i.groupId)
  ).length;
  const numberOfGroupInvites = groupInvites.length;

  const numberOfGroupBadges = numberOfGroupEventInvites + numberOfGroupInvites + groupEventsBadges + groupsUnreadMessagesLength;

  const [isSpotMenuOpen, setIsSpotMenuOpen] = useState(false);
  const [isFedMenuOpen, setIsFedMenuOpen] = useState(false);

  const { t } = useTranslation();
  const quickSettingsRef = useRef();
  const fedMenuRef = useRef();

  useOutsideClick(quickSettingsRef, () => setIsSpotMenuOpen(false));
  useOutsideClick(fedMenuRef, () => setIsFedMenuOpen(false));

  const isOnLoginPage = location.pathname.startsWith('/login') || location.pathname.startsWith('/join');
  const isOnAppsPage = location.pathname === '/apps';
  const isOnSettingsPage = location.pathname === '/settings';
  const isOnHelpPage = location.pathname === '/help';
  const isOnSpotPage = location.pathname.startsWith('/spots/');
  const isOnSpotStartPage = location.pathname === '/spots/en/' || location.pathname === '/spots/sv/' || location.pathname === '/spots/en' || location.pathname === '/spots/sv'
  const isOnStartPage = location.pathname === '/';
  const isOnPdfPage = location.pathname.startsWith('/pdf/');

  function handleLoginAndRedirect() {
    updateAppState({ redirect: location.pathname });
    nav('/login');
  }

  function handleLoginAndRedirectSpotPage() {
    if (fed) {
      // TODO: Maybe this should be handled by having fed params on login page instead
      setLanguage(fed);
    }
    updateAppState({ redirect: location.pathname });
    nav('/login');
  }

  useEffect(function () {
    const pathname = location.pathname;
    const i = history.indexOf(pathname);

    if (i > -1) {
      updateAppState({
        history: history.slice(0, i + 1),
      });
    } else {
      updateAppState({
        history: [...history, pathname],
      });
    }
    // We do not want to run this whenever history changes since that would lead to circular triggering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!isOnSpotPage) {
      setIsSpotMenuOpen(false);
    }
  }, [isOnSpotPage]);

  useEffect(() => {
    if (!isOnStartPage) {
      setIsFedMenuOpen(false);
    }
  }, [isOnStartPage]);

  const handleBack = () => {
    if (history.length > 1) {
      nav(-1);
    } else {
      // TODO: If on start page already should we do something different?
      if (isOnSpotPage) {
        nav('/spots/' + (fed ?? 'en/'));
        return;
      }
      if (isOnLoginPage) {
        window.location.reload();
        return;
      }
      nav('/');
    }
  };

  const handleClickStore = async () => {
    await goToStore({
      nav,
      isNative,
      iap,
      uid: currentUser?.uid,
    });
  };

  if (isOnAppsPage) {
    return null;
  }

  const currentOnboardingStep = getCurrentOnboardingStep({ user });
  const showOnboarding = shouldShowOnboarding({ user });

  const fedLogo = {
    sv: {
      logo: sv,
      name: 'Svenska Bridgeförbundet',
      font: 'Lato, sans-serif',
      color: '#56a202'
    },
  };

  if(isOnPdfPage) {
    return null;
  }

  if(metadata?.maintenance) {
    return <div className={'mb-24'}></div>
  }

  if (isOnSpotPage) {
    return (
      <div className="navbar top-0 z-40 w-full bg-opacity-25 flex-none transition-colors lg:z-50">
        <div className="fade-in w-1/3">
          {
            isOnSpotStartPage ?
              <Link to="https://cuebids.com" className="w-[40px]">
                <img
                  src={logo}
                  className={'ml-2 w-[40px] cursor-pointer'}
                  alt="Cuebids"
                />
              </Link>
              : <GoBack backAction={handleBack} />
          }

        </div>
        <div className={'flex w-1/3 items-center justify-center text-center'}>
          {
            fed && fedLogo[fed] && <img src={fedLogo[fed].logo} alt={fedLogo[fed].name} className='h-10' />
          }
          {
            fed && fedLogo[fed] && <span className='md:flex ml-4 h-10 text-xl items-center hidden text-left' style={{ fontFamily: fedLogo[fed].font }}>{fedLogo[fed].name}</span>
          }
        </div>
        <div className="w-1/3 flex justify-end items-center fade-in">
          {navbarItems}
          {!currentUser ? (
            <button
              className="btn-primary btn-sm btn"
              onClick={handleLoginAndRedirectSpotPage}
            >
              {t('app.login')}
            </button>
          ) : (
            <div ref={quickSettingsRef} className="flex items-center">
              <button
                className="btn btn-small btn-primary mr-2"
                onClick={() => setIsSpotMenuOpen((x) => !x)}
              >
                <SettingsIcon width={18} height={18} className={''} />
              </button>
              {isSpotMenuOpen && (
                <div className="absolute fade-in top-20 right-5 z-[9999] bg-base-100 border-sky-400 border-2 rounded-xl">
                  <SpotsProfile />
                  <div className={'fade-in flex justify-center m-2'}>
                    <label htmlFor="sign-out-dialog" className="btn-secondary btn btn-sm h-8 mt-4">
                      {t('app.logout')}
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  const totalNumberOfNewSpots = getTotalNumberOfNewSpots(numberOfNewSpots);
  const allowNewSpotsPush = user?.allowNewSpotsPush;
  const hiddenSections = user?.hiddenSections ?? [];
  const shouldShowTotalNumberOfNewSpotsBadge = !hiddenSections.includes('Polls') && !showOnboarding && (allowNewSpotsPush || (new Date()).getDate() % 3 === 0);
  const shouldShowHistoryBadge = !hiddenSections.includes('History') && !showOnboarding && user?.hasUnreadPerformanceReports;

  const shouldShowGroupsBadge = numberOfGroupBadges > 0;

  let menuBadge = null;
  if (currentOnboardingStep === onboardingSteps.settings && !isOnSettingsPage) {
    menuBadge = <div className="pulse badge w-5 top-0 right-0 badge-error indicator-item -translate-x-2 translate-y-0" />;
  } else if (currentOnboardingStep === onboardingSteps.help && !isOnHelpPage) {
    menuBadge = <div className="pulse badge w-5 top-0 right-0 badge-error indicator-item -translate-x-2 translate-y-0" />;
  } else if (shouldShowTotalNumberOfNewSpotsBadge && totalNumberOfNewSpots > 0) {
    menuBadge = (
      <span className="indicator-item top-0 right-0 badge badge-sm badge-error -translate-x-2 translate-y-0">
        {totalNumberOfNewSpots > 9 ? '9+' : totalNumberOfNewSpots}
      </span>
    )
  } else if (shouldShowHistoryBadge) {
    menuBadge = <div className="pulse badge w-5 top-0 right-0 badge-error indicator-item -translate-x-2 translate-y-0" />;
  } else if (shouldShowGroupsBadge){
    menuBadge = <div className="pulse badge w-5 top-0 right-0 badge-error indicator-item -translate-x-2 translate-y-0" />;
  }

  return (
    <div className={`supports-backdrop-blur:bg-white/60 navbar sticky ${isNative ? 'top-1' : 'top-0'} z-40 w-full flex-none bg-transparent backdrop-blur transition-colors duration-500 lg:z-50`}>
      <div className="w-1/4 fade-in flex">
        <GoBack backAction={handleBack} />
        {currentUser && (
          <Link to="./" className="w-[40px]">
            <img
              src={logo}
              className={'w-[40px] cursor-pointer'}
              alt="Cuebids"
            />
          </Link>
        )}
      </div>
      <div className="flex w-1/2 flex-col items-center justify-center fade-in md:mt-0 mt-2">
        <h3 style={{ height: '24px' }}>
          <strong>{title}</strong>
        </h3>
        {subtitle && (
          <span className="info" style={{ height: '18px' }}>
            {subtitle}
          </span>
        )}
      </div>
      {!currentUser && !isOnLoginPage && (
        <div className="w-full flex justify-end fade-in">
          <button
            className="btn-primary btn-sm btn"
            onClick={handleLoginAndRedirect}
          >
            {t('app.login')}
          </button>
        </div>
      )}
      {currentUser && (
        <>
          <div className="w-1/4 justify-end fade-in flex">
            {navbarItems}
            <div className="indicator">
              {menuBadge}
              {
                isFedMenuOpen && <div ref={fedMenuRef} className='absolute top-20 right-1 md:right-1 z-[9999] w-80 bg-base-100 border-sky-400 border-2 rounded-xl'>
                  <SelectFed />
                </div>
              }
              <Menu
                key={location.pathname}
                options={[
                  {
                    hidden: !currentUser,
                    component: (
                      <button
                        className="btn-ghost btn flex relative justify-start items-center"
                        onClick={() => nav('/settings')}
                      >
                        {currentOnboardingStep === onboardingSteps.settings &&
                          !isOnSettingsPage && (
                          <div className="pulse badge w-5 badge-error absolute right-2 top-0" />
                        )}
                        <div className='w-full h-full flex items-center justify-start gap-2'>
                          <SettingsIcon width={20}  className={'normal'}  />
                          &nbsp;{t('routes.settings')}
                        </div>
                      </button>
                    ),
                  },
                  {
                    hidden: !currentUser,
                    lowPrio: true,
                    component: (
                      <button
                        className="btn-ghost btn flex justify-start items-center"
                        onClick={handleClickStore}
                      >
                        <div className='w-full h-full flex items-center justify-start gap-2'>
                          <TicketIcon className="fill-secondary-400 " width={24} />
                          &nbsp;{t('routes.store')}
                        </div>
                      </button>
                    ),
                  },
                  {
                    hidden: !currentUser || hiddenSections.includes('Scenarios'),
                    component: (
                      <button
                        className="btn-ghost btn flex justify-start items-center"
                        onClick={() => nav('/manageDealTypes')}
                      >
                        <div className='w-full h-full flex items-center justify-start gap-2'>
                          <TypeIcon className="stroke-scenarios" width={24} />
                            &nbsp;{t('routes.scenarios')}
                        </div>
                      </button>
                    ),
                  },
                  {
                    hidden: !currentUser || hiddenSections.includes('History'),
                    component: (
                      <button
                        className="btn-ghost btn flex relative justify-start items-center"
                        onClick={() => nav('/dashboard')}
                      >
                        {shouldShowHistoryBadge && (
                          <div className="pulse badge w-5 badge-error absolute right-2 top-0" />
                        )}
                        <div className='w-full h-full flex items-center justify-start gap-2'>
                          <StatsIcon className="fill-emerald-400" width={24} />
                          &nbsp;{t('routes.history')}
                        </div>
                      </button>
                    ),
                  },
                  {
                    hidden: !currentUser || hiddenSections.includes('Polls'),
                    component: (
                      <button
                        className="btn-ghost btn flex relative justify-start items-center"
                        onClick={() => setIsFedMenuOpen((x) => !x)}
                      >
                        {!showOnboarding && totalNumberOfNewSpots > 0 && (
                          <span className="badge badge-error badge-sm absolute right-4 top-0" >
                            {totalNumberOfNewSpots > 9 ? '9+' : totalNumberOfNewSpots}
                          </span>
                        )}
                        <div className='w-full h-full flex items-center justify-start gap-2'>
                          <BarChartIcon className="fill-rose-400 " width={24} />
                          &nbsp;{t('routes.spots')}
                        </div>
                      </button>
                    ),
                  },
                  {
                    component: (
                      <button
                        className="btn-ghost btn flex relative justify-start items-center"
                        onClick={() => nav('/groups')}
                      >
                        {!showOnboarding && numberOfGroupBadges > 0 && (
                          <span className="badge badge-error badge-sm absolute right-4 top-0">
                            {numberOfGroupBadges > 9 ? '9+' : numberOfGroupBadges}
                          </span>
                        )}
                        <div className='w-full h-full flex items-center justify-start gap-2'>
                          <GroupsIcon width="24px" className="fill-blue-400" />
                          &nbsp;{t('routes.groups')}
                        </div>
                      </button>
                    ),
                  },
                  {
                    hidden: !currentUser,
                    lowPrio: true,
                    component: (
                      <button
                        className="btn-ghost btn flex relative justify-start items-center"
                        onClick={() => nav('/stats')}
                      >
                        <div className='w-full h-full flex items-center justify-start gap-2'>
                          <StarIcon className="fill-secondary" width={24} />
                        &nbsp;{t('routes.top10')}
                        </div>
                      </button>
                    ),
                  },
                  {
                    hidden: !currentUser || hiddenSections.includes('Shared'),
                    component: (
                      <button
                        className="btn-ghost btn flex relative justify-start items-center"
                        onClick={() => nav('/sharedSessions')}
                      >
                        <div className='w-full h-full flex items-center justify-start gap-2'>
                          <ShareIcon className="fill-emerald-400 " width={24} />
                          &nbsp;{t('routes.shared')}
                        </div>
                      </button>
                    ),
                  },
                  {
                    hidden: !currentUser,
                    lowPrio: true,
                    component: (
                      <button
                        className="btn-ghost btn flex relative justify-start items-center"
                        onClick={() => nav('/contact')}
                      >
                        <div className='w-full h-full flex items-center justify-start gap-2'>
                          <MessageIcon className="normal" width={24} />
                          &nbsp;{t('routes.contact')}
                        </div>
                      </button>
                    ),
                  },
                  {
                    hidden: !currentUser,
                    component: (
                      <button
                        className="btn-ghost btn flex relative justify-start items-center"
                        onClick={() => nav('/help')}
                      >
                        {currentOnboardingStep === onboardingSteps.help &&
                          !isOnHelpPage && (
                          <div className="pulse badge w-5 badge-error absolute right-2 top-0" />
                        )}
                        <div className='w-full h-full flex items-center justify-start gap-2'>
                          <QuestionIcon  className={'normal'} width={24} />
                          &nbsp;{t('routes.faq')}
                        </div>
                      </button>
                    ),
                  },
                  {
                    hidden: !currentUser,
                    component: (
                      <label
                        tabIndex={0}
                        htmlFor="sign-out-dialog"
                        className="btn-ghost btn flex relative justify-start items-center"
                      >
                        <div className='w-full h-full flex items-center justify-start gap-2'>
                          <LogoutIcon className="stroke-rose-400 fill-none" width={24} />
                          &nbsp;{t('app.logout')}
                        </div>
                      </label>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
