import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Animated from '../animation/animated';
import { UserDisplaySmallRight } from '../users/userDisplay';
import { useAuth } from '../../util/hooks.jsx';
import BidDisplay from '../bidding/bidDisplay';
import { isToday } from '../../util/dates';
import { getLastReadTimestamp, getNumberOfNewBids } from 'cuebids-firebase/spots';
import DisplayHand from '../hand/displayHand.jsx';

function getNumberOfNewCommentsOnMyBid(replies, lastReadTimestamp) {
  return (replies || []).filter((reply) => {
    return reply.timestamp?.toMillis() > lastReadTimestamp;
  }).length;
}

export default function SpotCard({ t, i, spot, myResponse }) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [lastReadTimestamp, setLastReadTimestamp] = useState(null);
  const [numberOfNewBids, setNumberOfNewBids] = useState(0);

  const { id, hand, description, uid, timestamp, numberOfBids = 0 } = spot;

  const dateTime = timestamp?.toDate();
  const locale = t('locale');
  const displayDate = isToday(dateTime)
    ? dateTime?.toLocaleTimeString({
      hour: '2-digit',
      minute: '2-digit',
    })
    : ( locale === 'sv-SE' ? dateTime.toLocaleDateString(locale) : dateTime?.toDateString());

  useEffect(
    function () {
      if (!currentUser) {
        return;
      }
      getLastReadTimestamp(id).then((timestamp) => {
        setLastReadTimestamp(timestamp);
      });
    },
    [id, currentUser]
  );

  useEffect(
    function () {
      if (lastReadTimestamp !== null) {
        getNumberOfNewBids(id, lastReadTimestamp).then((numberOfNewBids) => {
          setNumberOfNewBids(numberOfNewBids);
        });
      }
    },
    [id, lastReadTimestamp]
  );

  const numberOfNewCommentsOnMyBid =
    lastReadTimestamp === null
      ? 0
      : getNumberOfNewCommentsOnMyBid(myResponse?.replies, lastReadTimestamp);

  const badgeCount = myResponse
    ? numberOfNewBids + numberOfNewCommentsOnMyBid
    : 0;

  const desc =
    description.indexOf('?') > 0
      ? description.split('?')[0] + '?'
      : description;

  const spotCardDisplay = () => {
    return (
      <>
        <td>
          <div className="flex flex-col text-xs items-start justify-start h-full">
            <div className="badge badge-xs badge-primary py-2">
              {numberOfBids}
            </div>
            <div className="h-4"></div>
          </div>
        </td>
        <td className={'p-2 md:pr-20'}>
          <DisplayHand hand={hand} />
        </td>

        <td className="md:table-cell hidden w-[250px]">
          <div className="flex items-center gap-2">
            <span className="max-w-[220px] inline-block overflow-hidden text-ellipsis whitespace-nowrap pl-2">
              {desc}
            </span>
            {myResponse && myResponse.bid !== null && (
              <BidDisplay bid={myResponse.bid} size={25} />
            )}
          </div>
        </td>
        <td>
          <div className="flex flex-col indicator">
            {badgeCount > 0 && (
              <span
                className={`indicator-item badge z-20 badge-error badge-xs${
                  badgeCount > 9 ? ' text-[10px]' : ''
                }`}
              >
                {badgeCount > 9 ? '9+' : badgeCount}
              </span>
            )}
            <UserDisplaySmallRight uid={uid} />
            <span className="info text-right">{displayDate}</span>
          </div>
        </td>
      </>
    );
  };

  return (
    <>
      <Animated
        element="tr"
        variants={{
          hidden: (i) => ({
            opacity: 0,
          }),
          visible: (i) => ({
            opacity: 1,
            transition: {
              delay: i * 0.02,
            },
          }),
        }}
        initial="hidden"
        exit="hidden"
        animate="visible"
        custom={i}
        key={id}
        sx={'h-14 cursor-pointer ' + (myResponse ? '' : 'turn')}
        onClick={function () {
          navigate(`./${id}`);
        }}
      >
        {spotCardDisplay()}
      </Animated>
    </>
  );
}
