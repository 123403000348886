import { getMyUnreadChatMessagesField } from '../../../firebase/challenges';
import ChallengeResultCard from '../../../components/challengeCard/challengeCard';

export default function ChallengeToCard({ challenge, currentUser, navigate }) {
  const userIndex = challenge.users.indexOf(currentUser.uid);
  const unreadMessagesCount =
        challenge[getMyUnreadChatMessagesField(userIndex)] ?? 0;

  // TODO: This assumes the current user is in challenge, not possible to link/kibitz others
  const first = userIndex === 0 || userIndex === 1;
  const currentUserSessionId =
        userIndex === 0 || userIndex === 1 ? challenge.session1 : challenge.session2;

  const currentUserSession = challenge.deals
    .filter((f) => f.sessionId === currentUserSessionId)
    .sort((a, b) => a.dealNr - b.dealNr);
  const opponentSession = challenge.deals
    .filter((f) => f.sessionId !== currentUserSessionId)
    .sort((a, b) => a.dealNr - b.dealNr);

  const starsTeam1 = currentUserSession.reduce(
    function (stars, d, i) {
      stars.all += d.finished ? d.resultGrade : 0;
      stars.oppFinished +=
                d.finished && opponentSession[i].finished
                  ? d.resultGrade
                  : 0;
      return stars;
    },
    { all: 0, oppFinished: 0 }
  );

  const starsTeam2 = opponentSession.reduce(function (
    stars,
    d,
    i
  ) {
    return (
      stars +
                (d.finished && currentUserSession[i].finished
                  ? d.resultGrade
                  : 0)
    );
  },
  0);

  return (
    <div
      className="indicator mt-4 cursor-pointer"
      key={challenge.id}
      onClick={() => navigate(`/challenge/${challenge.id}`)}
    >
      {unreadMessagesCount !== 0 && (
        <span className="fade-in badge badge-error indicator-item">
          {unreadMessagesCount > 9 ? '9+' : unreadMessagesCount}
        </span>
      )}
      <ChallengeResultCard
        user1={challenge.users[first ? 0 : 2]}
        user2={challenge.users[first ? 1 : 3]}
        user3={challenge.users[first ? 2 : 0]}
        user4={challenge.users[first ? 3 : 1]}
        starsTeam1={starsTeam1.oppFinished}
        starsTeam1All={starsTeam1.all}
        starsTeam2={starsTeam2}
        finished={challenge.finished}
        ranked={challenge.ranked}
        proChallenge={challenge.proChallenge}
      />
    </div>
  );
}
