import { ranges } from './constants';

const overcallOver1N = [
  {
    premise: 'Over1N',
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?$/,
    explain:
      'We can overcall X (pen), 2C both majors, 2D multi, 2M 5M-4m, 2N both minors, or 3m nat',
    options: [
      {
        bid: 'D["Penalty"]',
        reqHp: ranges.penaltyDouble,
      },
      {
        bid: '2C[@"4+!H, 4+!S"]',
        reqH: 5,
        reqS: 4,
        reqHp: ranges.overcallOneLevel,
      },
      {
        bid: '2C[@"4+!H, 4+!S"]',
        reqH: 4,
        reqS: 5,
        reqHp: ranges.overcallOneLevel,
      },
      {
        bid: '2C[@"4+!H, 4+!S"]',
        reqH: 4,
        reqS: 4,
        reqD: 5,
        reqHp: ranges.overcallOneLevel,
      },
      {
        bid: '2D[@"6+!H or !S"]',
        reqS: 6,
        reqHp: ranges.overcallOneLevel,
      },
      {
        bid: '2D[@"6+!H or !S"]',
        reqH: 6,
        reqHp: ranges.overcallOneLevel,
      },
      {
        bid: '2H[@"5+!H and 4+!C or !D"]',
        reqH: 5,
        reqC: 4,
        reqHp: ranges.overcallOneLevel,
      },
      {
        bid: '2H[@"5+!H and 4+!C or !D"]',
        reqH: 5,
        reqD: 4,
        reqHp: ranges.overcallOneLevel,
      },
      {
        bid: '2S[@"5+!S and 4+!C or !D"]',
        reqS: 5,
        reqC: 4,
        reqHp: ranges.overcallOneLevel,
      },
      {
        bid: '2S[@"5+!S and 4+!C or !D"]',
        reqS: 5,
        reqD: 4,
        reqHp: ranges.overcallOneLevel,
      },
      {
        bid: '2N[@"5+!C, 5+!D"]',
        reqC: 5,
        reqD: 5,
        reqHp: ranges.overcallTwoLevel,
      },
      {
        bid: '3C["6+!C, wide-range"]',
        reqC: 6,
        reqHp: ranges.overcallTwoLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 5),
      },
      {
        bid: '3C["6+!C, wide-range"]',
        reqC: 7,
        reqHp: ranges.overcallOneLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 5),
      },
      {
        bid: '3D["6+!D, wide-range"]',
        reqD: 6,
        reqHp: ranges.overcallTwoLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 5),
      },
      {
        bid: '3D["6+!D, wide-range"]',
        reqD: 7,
        reqHp: ranges.overcallOneLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 5),
      },
      // TODO: Are continuations to 3m needed or handled by some other sequence?
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D-[PR]$/,
    explain: 'Partner overcall with X, pen, do we let it sit?',
    options: [
      {
        bid: '2C["To play"]',
        reqMaxHp: 3,
        reqC: 5,
      },
      {
        bid: '2D["To play"]',
        reqMaxHp: 3,
        reqD: 5,
      },
      {
        bid: '2H["To play"]',
        reqMaxHp: 3,
        reqH: 5,
      },
      {
        bid: '2S["To play"]',
        reqMaxHp: 3,
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D-2[CDHS]$/,
    explain: 'Partner overcall with X, pen, they escape',
    options: [
      {
        bid: 'D["Penalty"]',
        reqHp: 4,
        reqC: 4,
        lastBid: '2C',
      },
      {
        bid: 'D["Penalty"]',
        reqHp: 4,
        reqD: 4,
        lastBid: '2D',
      },
      {
        bid: 'D["Penalty"]',
        reqHp: 4,
        reqH: 4,
        lastBid: '2H',
      },
      {
        bid: 'D["Penalty"]',
        reqHp: 4,
        reqS: 4,
        lastBid: '2S',
      },
      {
        bid: '2D["Natural, 5+!D \nTo play"]',
        reqMaxHp: 5,
        reqD: 5,
      },
      {
        bid: '2H["Natural, 5+!H \nTo play"]',
        reqMaxHp: 5,
        reqH: 5,
      },
      {
        bid: '2S["Natural, 5+!S \nTo play"]',
        reqMaxHp: 5,
        reqS: 5,
      },
      {
        bid: '3C["Natural, 5+!C \nInvitational"]',
        reqHp: 6,
        reqC: 5,
      },
      {
        bid: '3D["Natural, 5+!D \nInvitational"]',
        reqHp: 6,
        reqD: 5,
      },
      {
        bid: '3H["Natural, 5+!H, \nInvitational"]',
        reqHp: 6,
        reqH: 5,
      },
      {
        bid: '3S["Natural, 5+!S, \nInvitational"]',
        reqHp: 6,
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D-(.|..)-2C$/,
    explain: 'I overcall with X, pen, partner bids 2C natural',
    options: [
      {
        bid: '2D["Natural, 5+!D"]',
        reqMaxC: 1,
        reqD: 5,
      },
      {
        bid: '2H["Natural, 5+!H"]',
        reqMaxC: 1,
        reqH: 5,
      },
      {
        bid: '2S["Natural, 5+!S"]',
        reqMaxC: 1,
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D-(.|..)-2D$/,
    explain: 'I overcall with X, pen, partner bids 2D natural',
    options: [
      {
        bid: '2H["Natural, 5+!H"]',
        reqMaxD: 1,
        reqH: 5,
      },
      {
        bid: '2S["Natural, 5+!S"]',
        reqMaxD: 1,
        reqS: 5,
      },
      {
        bid: '3C["Natural, 5+!C"]',
        reqMaxD: 1,
        reqC: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D-(.|..)-2H$/,
    explain: 'I overcall with X, pen, partner bids 2H natural',
    options: [
      {
        bid: '2S["Natural, 5+!S"]',
        reqMaxH: 1,
        reqS: 5,
      },
      {
        bid: '3C["Natural, 5+!C"]',
        reqMaxH: 1,
        reqC: 5,
      },
      {
        bid: '3D["Natural, 5+!D"]',
        reqMaxH: 1,
        reqD: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D-(.|..)-2S$/,
    explain: 'I overcall with X, pen, partner bids 2S natural',
    options: [
      {
        bid: '3C["Natural, 5+!C"]',
        reqMaxS: 1,
        reqC: 5,
      },
      {
        bid: '3D["Natural, 5+!D"]',
        reqMaxS: 1,
        reqD: 5,
      },
      {
        bid: '3H["Natural, 5+!H"]',
        reqMaxS: 1,
        reqH: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D-P-P-2[CDHS]$/,
    explain: 'I overcall with X, pen, they escape and im first to act',
    options: [
      {
        bid: 'D["Penalty"]',
        reqC: 4,
        lastBid: '2C',
      },
      {
        bid: 'D["Penalty"]',
        reqD: 4,
        lastBid: '2D',
      },
      {
        bid: 'D["Penalty"]',
        reqH: 4,
        lastBid: '2H',
      },
      {
        bid: 'D["Penalty"]',
        reqS: 4,
        lastBid: '2S',
      },
      {
        bid: '2H["Natural, 5+!H"]',
        reqH: 5,
      },
      {
        bid: '2S["Natural, 5+!S"]',
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D(-P-P)?-3[CDHSN]$/,
    explain: 'Someone overcall with X, pen, they jump to 3 level',
    options: [
      {
        bid: 'D["Penalty"]',
        reqC: 4,
        lastBid: '3C',
      },
      {
        bid: 'D["Penalty"]',
        reqD: 4,
        lastBid: '3D',
      },
      {
        bid: 'D["Penalty"]',
        reqH: 4,
        lastBid: '3H',
      },
      {
        bid: 'D["Penalty"]',
        reqS: 4,
        lastBid: '3S',
      },
      {
        bid: 'D["Penalty"]',
        reqHp: 4,
        lastBid: '3N',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D-2[CDHS]-2H-(.|..)$/,
    explain: 'I overcall with X, pen, they escape, partner bids 2H',
    options: [
      {
        bid: '4H["To play"]',
        reqHp: 20,
        reqH: 3,
      },
      {
        bid: '4H["To play"]',
        reqHp: 18,
        reqH: 4,
      },
      {
        bid: '4H["To play"]',
        reqHp: 17,
        reqH: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D-2[CDHS]-2S-(.|..)$/,
    explain: 'I overcall with X, pen, they escape, partner bids 2S',
    options: [
      {
        bid: '4S["To play"]',
        reqHp: 20,
        reqS: 3,
      },
      {
        bid: '4S["To play"]',
        reqHp: 18,
        reqS: 4,
      },
      {
        bid: '4S["To play"]',
        reqHp: 17,
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D-P-P-2[CD]-2H-(.|..)$/,
    explain: 'Partner overcall with X, pen, they escape, Partner bids 2H',
    options: [
      {
        bid: '4H["To play"]',
        reqHp: 7,
        reqH: 3,
      },
      {
        bid: '4H["To play"]',
        reqHp: 5,
        reqH: 4,
      },
      {
        bid: '4H["To play"]',
        reqHp: 3,
        reqH: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-D-P-P-2[CDH]-2S-(.|..)$/,
    explain: 'Partner overcall with X, pen, they escape, Partner bids 2S',
    options: [
      {
        bid: '4S["To play"]',
        reqHp: 7,
        reqS: 3,
      },
      {
        bid: '4S["To play"]',
        reqHp: 5,
        reqS: 4,
      },
      {
        bid: '4S["To play"]',
        reqHp: 3,
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-(P|D|..)$/,
    explain: 'Partner overcall with 2C both majors, do we bid game?',
    options: [
      {
        bid: '4H["To play"]',
        reqHp: ranges.weakRaise,
        reqH: 6,
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '4H["To play"]',
        reqHp: ranges.raise,
        reqH: 5,
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '4H["To play"]',
        reqHp: ranges.open,
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '4S["To play"]',
        reqHp: ranges.weakRaise,
        reqS: 6,
        reqStrains: function(strains) {
          return strains.S >= strains.H;
        },
      },
      {
        bid: '4S["To play"]',
        reqHp: ranges.raise,
        reqS: 5,
        reqStrains: function(strains) {
          return strains.S >= strains.H;
        },
      },
      {
        bid: '4S["To play"]',
        reqHp: ranges.open,
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.H;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-D$/,
    explain:
      'Partner overcall with 2C both majors, make preference over double',
    options: [
      {
        bid: 'P[@"!C, No 4—card M"]',
        reqMaxH: 3,
        reqMaxS: 3,
        reqC: 5,
        reqStrains: function(strains) {
          return strains.C >= strains.D &&
            strains.C >= (strains.H + 3) &&
            strains.C >= (strains.S + 3);
        },
      },
      {
        bid: '2D["!D, No 4—card M"]',
        reqMaxH: 3,
        reqMaxS: 3,
        reqD: 5,
        reqStrains: function(strains) {
          return strains.D >= strains.C &&
            strains.D >= (strains.H + 3) &&
            strains.D >= (strains.S + 3);
        },
      },
      {
        bid: 'R[@"No 4—card M"]',
        reqMaxH: 3,
        reqMaxS: 3,
        reqStrains: function(strains) {
          // With 3H and 0-1S we want to play in 2H
          return !(strains.S <= 1 && strains.H >= 3);
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-[PD]$/,
    explain: 'Partner overcall with 2C both majors, make preference',
    options: [
      {
        bid: '2H[@"4+!H"]',
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '2H[@"4+!H"]',
        // With 3H and 1S we want to play in 2H
        reqH: 3,
        reqMaxS: 1,
      },
      {
        bid: '2S[@"4+!S"]',
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.H;
        },
      },
      // TODO: Invitational raises
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-P$/,
    explain: 'Partner overcall with 2C both majors, make preference over pass',
    options: [
      {
        bid: '2D[@"No 4—card M"]',
        reqMaxH: 3,
        reqMaxS: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-P-2D-[PD]$/,
    explain: 'We overcall with 2C both majors, partner has no preference',
    options: [
      {
        // Pass with diamonds and no extra major length
        bid: 'P["To play"]',
        reqD: 5,
        reqMaxS: 4,
        reqMaxH: 4,
      },
      {
        bid: '2S["5+!S"]',
        reqS: 5,
        reqStrains: function(strains) {
          return strains.S > strains.H;
        },
      },
      {
        bid: '2H["5+!H"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-D-R-P$/,
    explain: 'We overcall with 2C both majors, partner has no preference',
    options: [
      {
        bid: '2D["5+!D"]',
        reqD: 5,
        reqMaxS: 4,
        reqMaxH: 4,
      },
      {
        bid: '2S["5+!S"]',
        reqS: 5,
        reqStrains: function(strains) {
          return strains.S > strains.H;
        },
      },
      {
        bid: '2H["5+!H"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-D-P-P$/,
    explain: 'We overcall with 2C both majors, partner shows clubs over double',
    options: [
      {
        bid: 'P',
        reqC: 2,
      },
      {
        bid: '2D["5+!D"]',
        reqD: 5,
        reqMaxS: 4,
        reqMaxH: 4,
      },
      {
        bid: '2S["5+!S"]',
        reqS: 5,
        reqStrains: function(strains) {
          return strains.S > strains.H;
        },
      },
      {
        bid: '2H["5+!H"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-D-P-P-2H-[PD]$/,
    explain: 'Partner overcalls with 2C both majors, we show clubs over double, partner bids 2H',
    options: [
      {
        bid: '2S["Preference"]',
        reqStrains: function(strains) {
          return strains.S > strains.H;
        },
      },
      {
        bid: 'P',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-D-2D-[PD]$/,
    explain: 'We overcall with 2C both majors, partner shows diamonds over double',
    options: [
      {
        bid: 'P',
        reqD: 2,
      },
      {
        bid: '2S["5+!S"]',
        reqS: 5,
        weight: 2,
        reqStrains: function(strains) {
          return strains.S > strains.H;
        },
      },
      {
        bid: '2H["5+!H"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-D-2D-[PD]-2H-[PD]$/,
    explain: 'Partner overcalls with 2C both majors, we show diamonds over double, partner bids 2H',
    options: [
      {
        bid: '2S["Preference"]',
        reqStrains: function(strains) {
          return strains.S > strains.H;
        },
      },
      {
        bid: 'P',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-2D$/,
    explain:
      'Partner overcall with 2C both majors, show support in competition',
    options: [
      {
        bid: '2H["4+!H"]',
        reqH: 4,
        reqHp: ranges.weakRaise,
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '2S["4+!S"]',
        reqS: 4,
        reqHp: ranges.weakRaise,
        reqStrains: function(strains) {
          return strains.S >= strains.H;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-2H$/,
    explain:
      'Partner overcall with 2C both majors, show support in competition',
    options: [
      {
        bid: '3H["4+!H"]',
        reqH: 4,
        reqHp: ranges.raise,
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '3H["4+!H"]',
        reqH: 5,
        reqHp: ranges.weakRaise,
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '2S["4+!S"]',
        reqS: 4,
        reqHp: ranges.weakRaise,
        reqStrains: function(strains) {
          return strains.S >= strains.H;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-(2S|2N|3C|3D)$/,
    explain:
      'Partner overcall with 2C both majors, show support in competition',
    options: [
      {
        bid: '3H["4+!H"]',
        reqH: 4,
        reqHp: ranges.raise,
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '3H["4+!H"]',
        reqH: 5,
        reqHp: ranges.weakRaise,
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '3S["4+!S"]',
        reqS: 4,
        reqHp: ranges.raise,
        reqStrains: function(strains) {
          return strains.S >= strains.H;
        },
      },
      {
        bid: '3S["4+!S"]',
        reqS: 5,
        reqHp: ranges.weakRaise,
        reqStrains: function(strains) {
          return strains.S >= strains.H;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2C-3H$/,
    explain:
      'Partner overcall with 2C both majors, show support in competition',
    options: [
      {
        bid: '3S["4+!S"]',
        reqS: 4,
        reqHp: ranges.raise,
        reqStrains: function(strains) {
          return strains.S >= strains.H;
        },
      },
      {
        bid: '3S["4+!S"]',
        reqS: 5,
        reqHp: ranges.weakRaise,
        reqStrains: function(strains) {
          return strains.S >= strains.H;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2D-D$/,
    explain: 'Partner overcalls with 2D multi, show own suit over D',
    options: [
      {
        bid: 'R[@"Own long suit"]',
        reqH: 6,
        reqMaxS: 1,
      },
      {
        bid: 'R[@"Own long suit"]',
        reqS: 6,
        reqMaxH: 1,
      },
      {
        bid: 'R[@"Own long suit"]',
        reqC: 6,
        reqMaxH: 1,
      },
      {
        bid: 'R[@"Own long suit"]',
        reqC: 6,
        reqMaxS: 1,
      },
      // Note: Diamond suit handled below (pass)
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2D-D-R-P$/,
    explain: 'We overcall with 2D multi, partner shows own suit over D',
    options: [
      {
        bid: '2H[@"Pass or correct"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2D-D-R-P-2H-[PD]$/,
    explain: 'Partner overcalls with 2D multi, we showed own suit over D, show what suit it is',
    options: [
      {
        bid: '2H["Natural"]',
        reqH: 6,
      },
      {
        bid: '2S["Natural"]',
        reqS: 6,
      },
      {
        bid: '3C["Natural"]',
        reqC: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2D-[PD]$/,
    explain: 'Partner overcalls with 2D multi, bid P/C',
    options: [
      {
        bid: 'P["Long !D"]',
        reqD: 6,
        reqMaxS: 1,
      },
      {
        bid: 'P["Long !D"]',
        reqD: 6,
        reqMaxH: 1,
      },
      {
        bid: '2H[@"Pass or correct"]',
      },
      {
        bid: '3H[@"Pass or correct"]',
        reqS: 3,
        reqH: 3,
        weight: 2,
      },
      // TODO: Invitational raises
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2D-(2.|3C|3D)$/,
    explain: 'Partner overcalls with 2D multi, bid P/C in competition',
    options: [
      {
        bid: '3H[@"Pass or correct"]',
        reqHp: ranges.raise,
        reqS: 3,
        reqH: 3,
      },
      {
        bid: '3H[@"Pass or correct"]',
        reqHp: ranges.weakRaise,
        reqS: 4,
        reqH: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2D-[PD]-2H-[PD]$/,
    explain: 'We overcall with 2D multi, partner bid 2H P/C',
    options: [
      {
        bid: '2S["6+!S"]',
        reqS: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2D-(.|..)-3H-[PD]$/,
    explain: 'We overcall with 2D multi, partner bid 3H P/C',
    options: [
      {
        bid: '3S["6+!S"]',
        reqS: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2H-(P|D|..)$/,
    explain: 'Partner overcall with 2H 5-4, do we bid game?',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: ranges.open,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2H-[PD]$/,
    explain: 'Partner overcall with 2H 5-4',
    options: [
      {
        bid: '2S["Not forcing, 6+!S"]',
        reqS: 6,
        reqMaxH: 2,
      },
      {
        bid: '3C[@"Pass or correct"]',
        reqC: 4,
        reqD: 4,
        reqMaxH: 2,
      },
      {
        bid: '3H["Single raise, 3+!H"]',
        reqH: 3,
        reqHp: ranges.raise,
      },
      {
        bid: '3H["Single raise, 3+!H"]',
        reqH: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '3C[@"Pass or correct"]',
        reqStrains: function(strains) {
          return (strains.D >= strains.H + 2) && (strains.C >= strains.H + 2);
        },
      },
      // TODO: Invitational raises
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2H-2.$/,
    explain: 'Partner overcall with 2H 5-4, P/C in competition',
    options: [
      {
        bid: '3C[@"Pass or correct"]',
        reqC: 4,
        reqD: 4,
        reqMaxH: 2,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2H-(2.|3C|3D)$/,
    explain: 'Partner overcall with 2H 5-4, raise in competition',
    options: [
      {
        bid: '3H["Single raise, 3+!H"]',
        reqH: 3,
        reqHp: ranges.raise,
      },
      {
        bid: '3H["Single raise, 3+!H"]',
        reqH: 4,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2H-(P|D|2.)-3C-[PD]$/,
    explain: 'We overcall with 2H 5-4, respond to P/C',
    options: [
      {
        bid: '3D["4+!D"]',
        reqD: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2S-(P|D|..)$/,
    explain: 'Partner overcall with 2S 5-4, do we bid game?',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '4S["To play"]',
        reqS: 3,
        reqHp: ranges.open,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2S-[PD]$/,
    explain: 'Partner overcall with 2S 5-4',
    options: [
      {
        bid: '3C[@"Pass or correct"]',
        reqC: 4,
        reqD: 4,
        reqMaxS: 2,
      },
      {
        bid: '3S["Single raise, 3+!S"]',
        reqS: 3,
        reqHp: ranges.raise,
      },
      {
        bid: '3S["Single raise, 3+!S"]',
        reqS: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '3C[@"Pass or correct"]',
        reqStrains: function(strains) {
          return (strains.D >= strains.S + 2) && (strains.C >= strains.S + 2);
        },
      },
      // TODO: Invitational raises
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2S-2N$/,
    explain: 'Partner overcall with 2S 5-4, P/C in competition',
    options: [
      {
        bid: '3C[@"Pass or correct"]',
        reqC: 4,
        reqD: 4,
        reqMaxS: 2,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2S-(2N|3C|3D|3H)$/,
    explain: 'Partner overcall with 2S 5-4, raise in competition',
    options: [
      {
        bid: '3S["Single raise, 3+!S"]',
        reqS: 3,
        reqHp: ranges.raise,
      },
      {
        bid: '3S["Single raise, 3+!S"]',
        reqS: 4,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2S-(P|D|2N)-3C-[PD]$/,
    explain: 'We overcall with 2S 5-4, respond to P/C',
    options: [
      {
        bid: '3D["4+!D"]',
        reqD: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2N-(P|D|..)$/,
    explain: 'Partner overcall with 2N minors, do we bid 4 or 5m?',
    options: [
      {
        bid: '5C["To play"]',
        reqHp: ranges.weakRaise,
        reqC: 5,
        reqStrains: function(strains) {
          return strains.C >= strains.D;
        },
      },
      {
        bid: '5D["To play"]',
        reqHp: ranges.weakRaise,
        reqD: 5,
        reqStrains: function(strains) {
          return strains.D >= strains.C;
        },
      },
      {
        bid: '4C["To play"]',
        reqHp: ranges.weakRaise,
        reqC: 4,
        reqStrains: function(strains) {
          return strains.C >= strains.D;
        },
      },
      {
        bid: '4D["To play"]',
        reqHp: ranges.weakRaise,
        reqD: 4,
        reqStrains: function(strains) {
          return strains.D >= strains.C;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2N-[PD]$/,
    explain: 'Partner overcall with 2N minors, make preference',
    options: [
      {
        bid: '3C["Preference"]',
        reqStrains: function(strains) {
          return strains.C >= strains.D;
        },
      },
      {
        bid: '3D["Preference"]',
        reqStrains: function(strains) {
          return strains.D >= strains.C;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N(-P-P)?-2N-3C$/,
    explain: 'Partner overcall with 2N minors, bid 3D over 3C?',
    options: [
      {
        bid: '3D["To play"]',
        reqHp: ranges.weakRaise,
        reqD: 3,
      },
    ],
  },
  // TODO: Bid in fourth seat
];

export default overcallOver1N;
