export const subOrder = {
  club: 1,
  diamond: 2,
  heart: 3,
  spade: 4,
};

export const officialSubs = ['club', 'diamond', 'heart', 'spade'];

export function getSubSortOrder(sub) {
  return subOrder[sub] ?? '-1';
}

export function isSubEnough(activeSubs, requiredSub) {
  return isHighestSubEnough(getHighestSub(activeSubs), requiredSub);
}

export function isHighestSubEnough(highestSub, requiredSub) {
  return (
    getSubSortOrder(highestSub) >= getSubSortOrder(requiredSub)
  );
}

function getHighestSub(subs) {
  if (subs.length > 0) {
    return subs.sort(function(a, b) {
      return getSubSortOrder(b) - getSubSortOrder(a);
    })[0];
  }
}

export function getHighestSubFromUser(user) {
  const IAPsubscriptions = user.IAPsubscriptions ?? [];
  const StripeSubs = user.subscriptions ?? [];
  const GroupSubs = user.groupSubscriptions ?? [];

  const union = Array.from(new Set([...IAPsubscriptions, ...StripeSubs, ...GroupSubs]));

  if (union.length < 1) {
    return null;
  }

  union.sort(function(a, b) {
    return getSubSortOrder(b) - getSubSortOrder(a);
  });

  return union[0];
}

function getSubsFromActiveSubs(activeIAPSubs, activeWebSubs) {
  const IAPsubscriptions = activeIAPSubs ?? [];
  const StripeSubs = activeWebSubs ?? [];

  return IAPsubscriptions.concat(StripeSubs);
}

function getSubFromGroup(group, uid) {
  if (group.premium && group.premiumMembers?.some((m) => m.uid === uid)) {
    if ((group.premiumFeatures || []).includes('freeHeartSub')) {
      return 'heart';
    } else if ((group.premiumFeatures || []).includes('freeDiamondSub')) {
      return 'diamond';
    }
  }

  return null;
}

export function getSubsFromGroups(groups, uid) {
  return groups.reduce(function(acc, group) {
    const sub = getSubFromGroup(group, uid);
    if (sub) {
      acc.push(sub);
    }
    return acc;
  }, []);
}

function getSortedUniqueSubs(subs) {
  const union = Array.from(new Set(subs));

  if (union.length < 1) {
    return [];
  }

  union.sort(function(a, b) {
    return getSubSortOrder(b) - getSubSortOrder(a);
  });

  return union;
}

export function getActiveSubs({ activeIAPSubs, activeWebSubs, groups, user }) {
  const subsFromActiveSubs = getSubsFromActiveSubs(activeIAPSubs ?? [], activeWebSubs ?? []);
  const subsFromGroups = getSubsFromGroups(groups ?? [], user?.id);

  return getSortedUniqueSubs(subsFromActiveSubs.concat(subsFromGroups)).filter((s) => officialSubs.includes(s));
}

export function hasLoadedDataToGetSubs({ activeIAPSubs, activeWebSubs, groups, user }) {
  return !!groups && !!user && !!activeWebSubs && !!activeIAPSubs;
}
