import { db } from './firebase';
import {
  collection,
  doc,
  onSnapshot,
} from 'firebase/firestore';

export function getMetadataObservable(callback) {
  const metadataRef = doc(collection(db, 'metadata'), 'prod');
  return onSnapshot(metadataRef, (metadata) => {
    callback(metadata.data());
  });
}
