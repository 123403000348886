import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Star } from '../../../components/rating/starRating'
import { LinkedUserDisplaySmall, LinkedUserDisplaySmallRight, UserDisplaySmall, UserDisplaySmallRight } from '../../../components/users/userDisplay'
import { roundToTwoDecimals } from '../../../util/challenge'
import ChallengeStatisticBlock from './ChallengeStatisticBlock'
import ChevronUp from '../../../components/icons/chevronUp'
import ChevronDown from '../../../components/icons/chevronDown'
import { useTranslation } from 'react-i18next'

function FlexibleUserDisplay({ onUserClick, uid, reverse = false }) {
  if (onUserClick) {
    return (
      <div role="button" onClick={() => onUserClick(uid)}>
        {reverse ? <UserDisplaySmallRight uid={uid} /> : <UserDisplaySmall uid={uid} />}
      </div>
    );
  }

  return reverse ? <LinkedUserDisplaySmallRight uid={uid} /> : <LinkedUserDisplaySmall uid={uid} />;
}

export default function ChallengeStatisticPartnership({
  challengeStatisticPartnership,
  firstUserId,
  sx = '',
  onUserClick,
  header,
  linkToChallengeStats = false,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'challenge' });

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(e => !e);

  const isFirst = challengeStatisticPartnership.users[0] === firstUserId;
  const me = isFirst ? challengeStatisticPartnership.users[0] : challengeStatisticPartnership.users[1];
  const partner = isFirst ? challengeStatisticPartnership.users[1] : challengeStatisticPartnership.users[0];

  const wins = challengeStatisticPartnership.wins;
  const draws = challengeStatisticPartnership.draws;
  const losses = challengeStatisticPartnership.losses;
  const stars = challengeStatisticPartnership.numberOfStars;
  const numberOfChallenges = challengeStatisticPartnership.numberOfChallenges;
  const numberOfBoards = challengeStatisticPartnership.numberOfBoards;

  const winsRanked = challengeStatisticPartnership.winsRanked;
  const drawsRanked = challengeStatisticPartnership.drawsRanked;
  const lossesRanked = challengeStatisticPartnership.lossesRanked;
  const numberOfChallengesRanked = challengeStatisticPartnership.numberOfChallengesRanked;
  const numberOfBoardsRanked = challengeStatisticPartnership.numberOfBoardsRanked;

  const winsFriendly = challengeStatisticPartnership.winsFriendly;
  const drawsFriendly = challengeStatisticPartnership.drawsFriendly;
  const lossesFriendly = challengeStatisticPartnership.lossesFriendly;
  const numberOfChallengesFriendly = challengeStatisticPartnership.numberOfChallengesFriendly;
  const numberOfBoardsFriendly = challengeStatisticPartnership.numberOfBoardsFriendly;

  return (
    <div className={`border border-accent p-2 md:px-4 bg-base-200 rounded-md ${sx}`}>
      {header && <div className="info text-center">{header}</div>}
      <div className='grid grid-cols-[auto_50px_auto] gap-y-2 place-items-center mt-2'>
        <FlexibleUserDisplay onUserClick={onUserClick} uid={me} />
        <span className="info">{t('with')}</span>
        <FlexibleUserDisplay onUserClick={onUserClick} uid={partner} reverse />

        <div
          className={'badge badge-outline border-info bg-base-100 pl-0'}
        >
          <Star /> {stars}
        </div>
        <span className="info">{t('tot_avg')}</span>
        <div
          className={'badge badge-outline border-info bg-base-100 pl-0'}
        >
          <Star /> {roundToTwoDecimals(stars/challengeStatisticPartnership.numberOfBoards)}
        </div>
      </div>
      <div className="grid grid-cols-[1fr_1fr_40px_1fr_1fr] gap-y-2 place-items-center mt-2" role="button" onClick={toggleExpanded}>
        <ChallengeStatisticBlock
          numberOfChallenges={numberOfChallenges}
          wins={wins}
          draws={draws}
          losses={losses}
          numberOfBoards={numberOfBoards}
          header={t('challenges')}
        />
        {expanded && (
          <>
            <ChallengeStatisticBlock
              numberOfChallenges={numberOfChallengesRanked}
              wins={winsRanked}
              draws={drawsRanked}
              losses={lossesRanked}
              numberOfBoards={numberOfBoardsRanked}
              header={t('ranked')}
            />
            <ChallengeStatisticBlock
              numberOfChallenges={numberOfChallengesFriendly}
              wins={winsFriendly}
              draws={drawsFriendly}
              losses={lossesFriendly}
              numberOfBoards={numberOfBoardsFriendly}
              header={t('friendly')}
              friendlyIcon
            />
            {linkToChallengeStats && (
              <div className="w-full flex justify-center col-span-5 mb-2">
                <Link
                  to={`/challenge?tab=stats&statsTab=partner&user=${me}&partner=${partner}`}
                  className="btn btn-secondary btn-sm h-8"
                >
                  {t('more_stats')}
                </Link>
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex justify-center" role="button" onClick={toggleExpanded}>{expanded ? <ChevronUp /> : <ChevronDown />}</div>
    </div>
  );
}
