import {  useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Leaderboard from '../../../components/leaderBoards/leaderBoard';
import { getDailyById } from '../../../firebase/daily';
import { EventIcon } from '../../../components/icons/dailyIcon';
import { getSessionForEvent } from '../../../firebase/events';
import { useAppStateStore } from '../../../appStateStore.jsx';
import { useTranslation } from 'react-i18next'

export default function DailyLeaderBoard() {
  const { t } = useTranslation('translation', { keyPrefix: 'daily_leaderboard' });
  const { id } = useParams();
  const sessions = useAppStateStore((state) => state.sessions);
  const [daily, setDaily] = useState();
  const [loadedDailySession, setLoadedDailySession] = useState();
  const [showRobotPairs, setShowRobotPairs] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getDaily() {
      const d = await getDailyById(id);
      if (d) {
        setDaily(d);
      } else {
        navigate(`/404${location.pathname}`);
      }
    }

    void getDaily();
  }, [id, navigate]);

  useEffect(
    function () {
      if (id) {
        const getData = async () => {
          const dailySess = await getSessionForEvent('daily', id);
          setLoadedDailySession(dailySess);
        };
        void getData();
      }
    },
    [id]
  );

  const dailySession =
    sessions.find((s) => s.daily === id) || loadedDailySession;

  if (!daily) {
    return <></>;
  }

  return (
      <div className="page pb-24">
        <Link
            style={{width: '100%'}}
            to={dailySession ? `/session/${dailySession.id}` : '/'}
        >
          <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
          >
            <button
                className="btn btn-primary"
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  width: '350px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
            >
              <EventIcon fill="#4d774e"/>
              <span>
              {dailySession
                  ? dailySession.dealsCount - dailySession.numberOfFinishedDeals >
                  0
                  ? t('continue')
                  : t('completed')
                : t('to_start')}
            </span>
            </button>
          </div>
        </Link>
      <div style={{ marginBottom: 5 }}>
        <span>{t('participants', {count: daily.pairCount ?? 0})}</span>
      </div>
      {
        <span className="info">
          {t('leaderboard_minimum')}
        </span>
      }
      <div className="mt-4 w-full md:w-1/2">
        <Leaderboard
          leaderBoard={daily.leaderBoard}
          top={10}
          header={t('leaderboard_title')}
          min={15}
          showRobotPairs={showRobotPairs}
          leadersIcon={<EventIcon fill="#4d774e" />}
        />
      </div>
    </div>
  );
}
