import BidDisplay from '../bidding/bidDisplay';
import { replaceSuitShorthandWithSymbols } from '../../util/symbols';
import { useTranslation } from 'react-i18next';

function OpeningBidRow({ bid, explain }) {
  return (
    <tr style={{ height: '25px' }}>
      <td
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '25px',
          maxWidth: '30px',
          padding: 2,
          margin: 0,
        }}
      >
        <BidDisplay size={20} bid={bid} />
      </td>
      <td>
        <span style={{ textAlign: 'left' }} className="info">
          {replaceSuitShorthandWithSymbols(explain)}
        </span>
      </td>
    </tr>
  );
}

function ConventionRow({ explain }) {
  return (
    <tr style={{ height: '25px' }}>
      <td>
        <span className="info">{replaceSuitShorthandWithSymbols(explain)}</span>
      </td>
    </tr>
  );
}

const OB = [
  { bid: '1C',
    explain: '11+ HCP, 3+!C' },
  { bid: '1D',
    explain: '11+ HCP, 3+!D' },
  { bid: '1H',
    explain: '11+ HCP, 5+!H' },
  { bid: '1S',
    explain: '11+ HCP, 5+!S' },
  { bid: '1N',
    explain: '15-17 HCP' },
  { bid: '2C',
    explain: '20+ HCP' },
  { bid: '2D',
    explain: 'Weak, 6+!D' },
  { bid: '2H',
    explain: 'Weak, 6+!H' },
  { bid: '2S',
    explain: 'Weak, 6+!S' },
];

const CC = [
  { explain: 'RKCB 1430' },
  { explain: 'Lebensohl' },
  { explain: '4 Way Transfer' },
];

export default function ConventionCardDisplay({ base = 'Basic 2/1', ob = OB, cc = CC }) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: '300px',
      }}
    >
      {<span>{base}</span>}
      <div
        style={{
          width: '280px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ flexGrow: '1' }}>
          <table>
            <thead>
              <tr>
                <th colSpan={2} className="info">
                  {t('convention_card.opening_bids')}
                </th>
              </tr>
            </thead>
            <tbody>
              {ob.map((cr) => {
                return (
                  <OpeningBidRow
                    key={cr.bid}
                    bid={cr.bid}
                    explain={cr.explain}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        <div style={{ flexGrow: '1' }}>
          <table>
            <thead>
              <tr>
                <th colSpan={2} className="info">
                  {t('convention_card.conventions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {cc.map((cr) => {
                return <ConventionRow key={cr.explain} explain={cr.explain} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
