import jack from '../../images/symbols/Jack.svg';
import queen from '../../images/symbols/Queen.svg';
import king from '../../images/symbols/King.svg';
import ace from '../../images/symbols/Ace.svg';
import {
  UserDisplayAvatarMicro,
  UserDisplayAvatarNormal,
  UserDisplayAvatarSmall,
} from '../users/userDisplay';
import { StarRating } from '../rating/starRating';

const subIcon = {
  jack: jack,
  queen: queen,
  king: king,
  ace: ace,
};

export function PreloadRankIcons() {
  return Object.keys(subIcon).map((p) => {
    return <img key={p} src={subIcon[p]} alt={'preload'} />;
  })
}

export function RankIcon({ uid, rank, size = 'lg' }) {
  if (size === 'sm') {
    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: 2.4,
            left: 1.1,
            right: 0,
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <UserDisplayAvatarMicro uid={uid} />
        </div>
        <img width={24} src={subIcon[rank]}  alt={rank}/>
      </div>
    );
  }
  if (size === 'md') {
    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: 2,
            left: 0,
            right: 0,
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <UserDisplayAvatarSmall uid={uid} />
        </div>
        <img width={28} src={subIcon[rank]} className="drop-shadow-xx"  alt={rank}/>
      </div>
    );
  }
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: 4,
          left: 0,
          right: 0,
          zIndex: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <UserDisplayAvatarNormal uid={uid} />
      </div>
      <img width={48} src={subIcon[rank]} className="drop-shadow-xx"  alt={rank}/>
    </div>
  );
}

export default function RankCard({
  uid,
  rank,
  rankStars,
  rating,
  hideRating = false,
  alwaysShowRating = false,
  size = 'lg',
}) {
  const starsNeeded = 5;
  const isLegendary = rank === 'ace' && rankStars === 6;

  return (
    <div className="fade-in-slow card flex flex-col items-center">
      <RankIcon rank={rank} uid={uid} size={size} />
      {size === 'lg' && (
        <div
          className="w-20 flex items-center justify-center"
          style={{
            filter: isLegendary
              ? 'drop-shadow(0px 0px 6px rgb(103,30,207)) drop-shadow(0px 0px 10px rgb(103,30,207))'
              : undefined,
          }}
        >
          <StarRating stars={rankStars} maxStars={starsNeeded} size="sm" />
        </div>
      )}
      {(isLegendary || alwaysShowRating) && !hideRating && (
        <div className="badge badge-accent badge-lg mt-2">{rating}</div>
      )}
    </div>
  );
}
