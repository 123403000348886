import { useState, useEffect, useCallback } from 'react';
import {
  getUser,
  updateUser,
  initiateAccountRemoval,
  updateProfilePicture,
} from '../../firebase/userApi';
import { Link, useNavigate } from 'react-router-dom';
import { UserDisplayAvatar } from '../../components/users/userDisplay';
import { getCustomerPortalLink } from 'cuebids-firebase/store';
import getFlag from '../../util/getFlag';
import 'flag-icons/css/flag-icons.css';
import { isAdmin } from '../../util/admin';

import {
  doesDisplayNameHaveForbiddenCharacters,
  getTrimmedDisplayName,
  isDisplayNameTooShort,
} from '../../util/displayName';

import sbu from '../../images/logos/feds/sbu.png';
import sbf from '../../images/logos/feds/sv.png';

import Player from './player';
import CloseIcon from '../../images/icons/close';
import Hand from '../../components/cards/hand';
import Alert, { AlertInline } from '../../components/alert/alert';
import Dialog from '../../components/dialog';
import log from '../../util/logger';
import {
  getCurrentOnboardingStep,
  onboardingSteps,
} from '../../util/onboarding';
import Collapse from '../../components/collapse/Collapse';
import { RefreshIcon } from '../../components/icons/refreshIcon';
import { SaveIcon } from '../../components/icons/saveIcon';
import { TicketIcon } from '../../components/icons/ticketsIcon';
import {
  optInForNewsletter,
  retryCompleteAchievement,
  tryClaimAchievement,
} from '../../firebase/achievements';
import {
  DOUBLE_OPT_IN,
  DOUBLE_OPT_IN_REWARD,
  hasAttemptedCompleteAchievement,
  hasClaimedAchievement,
  hasCompletedAchievement,
} from '../../util/achievements';
import { useActiveSubs, useAuth } from '../../util/hooks';
import { useAppStateStore } from '../../appStateStore.jsx';
import sbfLogo from '../../images/logos/feds/sv.png';
import SelectCountryDropdown from '../../components/countryDropdown/SelectCountryDropdown.jsx';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next';
import { LanguageDropdown } from '../../components/dropdown/languageDropdown.jsx'

const cardVariants = ['diagram', 'modern', 'classic'];
const suitOrders = ['default', 'shcd'];
const cardSizes = ['sm', 'md', 'lg', 'xl'];
const interfaceScale = ['sm', 'md', 'lg'];

function themeChange(theme) {
  localStorage.setItem('theme', theme);
  document.documentElement.setAttribute('data-theme', theme);
}

function isPrivateAppleEmail(email) {
  return email?.endsWith('@privaterelay.appleid.com');
}

function getDisplayNameError(displayName, t) {
  if (!displayName || isDisplayNameTooShort(displayName)) {
    return t('display_name_short_error');
  }

  if (doesDisplayNameHaveForbiddenCharacters(displayName)) {
    return t('display_name_forbidden_characters_error');
  }

  return '';
}

function SubscribeNewsletter({
  optInStatus,
  setSubscribeNewsletterModalOpen,
  loading,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });

  if (optInStatus === 'DOUBLE_OPT_IN') {
    return null;
  }

  if (optInStatus === 'EMAIL_SENT') {
    return (
      <div className="flex flex-col items-center mb-4">
        <Alert text={t('newsletter_confirm_sent')} />
        <button
          disabled={loading}
          onClick={function () {
            setSubscribeNewsletterModalOpen(true);
          }}
          className="btn btn-secondary"
        >
          {t('try_again')}
        </button>
      </div>
    );
  }

  if (optInStatus === 'STARTED') {
    return (
      <div className="flex flex-col items-center mb-4">
        <Alert text={t('newsletter_started')} />
        <button
          disabled={loading}
          onClick={function () {
            setSubscribeNewsletterModalOpen(true);
          }}
          className="btn btn-secondary"
        >
          {t('try_again')}
        </button>
      </div>
    );
  }

  // NOT_STARTED (or undefined)
  return (
    <button
      onClick={function () {
        setSubscribeNewsletterModalOpen(true);
      }}
      className="btn-primary btn flex-col mb-4"
      style={{ height: '64px' }}
      disabled={loading}
    >
      {t('newsletter_cta')}
      <div className="flex items-center gap-2">
        {t('rewards')} <TicketIcon /> &times;{DOUBLE_OPT_IN_REWARD}
      </div>
    </button>
  );
}

export default function Profile() {
  const [user, setUser] = useState(null);
  const [fedNrInput, setFedNrInput] = useState('');
  const [showFedNrInput, setShowFedNrInput] = useState(false);
  const [autoNext, setAutoNext] = useState(false);
  const [email, setEmail] = useState('');
  const [sameEmail, setSameEmail] = useState(true);
  const [hideHcpCount, setHideHcpCount] = useState(false);
  const [hideHelp, setHideHelp] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [imageReload, setImageReload] = useState();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState();
  const [flag, setFlag] = useState();
  const [showFlag, setShowFlag] = useState();
  const [cardVariant, setCardVariant] = useState('diagram');
  const [suitOrder, setSuitOrder] = useState('default');
  const [cardSize, setCardSize] = useState('sm');
  const [interfaceScale, setInterfaceScale] = useState('sm');
  const [showProfile, setShowProfile] = useState(false);
  const [allowChatPush, setAllowChatPush] = useState(true);
  const [allowNewSpotsPush, setAllowNewSpotsPush] = useState(false);
  const [allowEventPush, setAllowEventPush] = useState(false);
  // const [allowPollReplyPush, setAllowPollReplyPush] = useState(false);
  const [allowAnimations, setAllowAnimations] = useState(true);
  const [allowBlinking, setAllowBlinking] = useState(true);
  const [discordTag, setDiscordTag] = useState('');
  const [subscribeNewsletterModalOpen, setSubscribeNewsletterModalOpen] =
    useState(false);
  const [retryCompleteOptInAchievement, setRetryCompleteOptInAchievement] =
    useState(false);

  const sections = ['Polls', 'Scenarios', 'History', 'Shared', 'Matchmaking'];
  //'Events', 'Challenges', 'Robots' is also possible
  const [hiddenSections, setHiddenSections] = useState([]);

  const { currentUser } = useAuth();

  const [madeChanges, setMadeChanges] = useState(false);
  const [saved, setSaved] = useState(false);

  const navigate = useNavigate();

  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const isNative = useAppStateStore((state) => state.isNative);
  const iap = useAppStateStore((state) => state.iap);
  const achievements = useAppStateStore((state) => state.achievements);
  const appStateUser = useAppStateStore((state) => state.user);
  const notify = useNotifications();
  const activeWebSubs = useAppStateStore((state) => state.activeWebSubs);
  const allowNotifications = useAppStateStore(
    (state) => state.allowNotifications
  );
  const activeSubs = useActiveSubs();

  const currentOnboardingStep = getCurrentOnboardingStep({
    user: appStateUser,
  });

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'settings' });

  const fedIconMap = {
    sbu: sbu,
    sv: sbf,
  };

  const save = useCallback(async () => {
    if (displayNameError) {
      return;
    }

    const err = getDisplayNameError(displayName, t);
    if (err) {
      setDisplayNameError(err);
      return;
    }

    void updateUser({
      autoNext,
      language,
      flag: flag ?? null,
      showFlag,
      cardVariant,
      suitOrder,
      email: email ?? currentUser.email,
      oldEmail: user.email ?? currentUser.email,
      cardSize,
      interfaceScale,
      hideHcpCount,
      hideHelp,
      allowChatPush,
      allowNewSpotsPush,
      // allowPollReplyPush,
      allowEventPush,
      allowAnimations,
      allowBlinking,
      displayName: getTrimmedDisplayName(displayName),
      discordTag,
      hiddenSections,
    });
    const _user = appStateUser;
    _user.language = language;
    _user.autoNext = autoNext;
    _user.cardVariant = cardVariant;
    _user.suitOrder = suitOrder;
    _user.cardSize = cardSize;
    _user.interfaceScale = interfaceScale;
    _user.hideHcpCount = hideHcpCount;
    _user.hideHelp = hideHelp;
    _user.hiddenSections = hiddenSections;
    _user.allowChatPush = allowChatPush;
    _user.allowNewSpotsPush = allowNewSpotsPush;
    // _user.allowPollReplyPush = allowPollReplyPush;
    _user.allowEventPush = allowEventPush;
    _user.allowAnimations = allowAnimations;
    _user.allowBlinking = allowBlinking;
    updateAppState({
      user: _user,
    });
    notify({
      text: t('notifications.saved'),
      type: 'success',
    });
    setMadeChanges(false);
    setSaved(true);
    setUser((u) => {
      return {
        ...u,
        email: email ?? currentUser.email,
        displayName: getTrimmedDisplayName(displayName),
      };
    });

    if (currentOnboardingStep === onboardingSteps.settings) {
      navigate('/');
    }
  }, [
    displayNameError,
    displayName,
    t,
    autoNext,
    language,
    flag,
    showFlag,
    cardVariant,
    suitOrder,
    email,
    currentUser?.email,
    user?.email,
    cardSize,
    interfaceScale,
    hideHcpCount,
    hideHelp,
    allowChatPush,
    allowNewSpotsPush,
    allowEventPush,
    allowAnimations,
    allowBlinking,
    discordTag,
    hiddenSections,
    appStateUser,
    updateAppState,
    notify,
    currentOnboardingStep,
    navigate,
  ]);

  useEffect(() => {
    updateAppState({
      title: madeChanges ? (
        <div className="text-error">{t('unsaved_changes')}</div>
      ) : saved ? (
        <div className="mt-2 text-primary">{t('saved')}</div>
      ) : undefined,
      navbarItems: (
        <button
          key="navbar-items-profile-save"
          className={`btn-ghost btn ${madeChanges ? 'text-primary' : ''}`}
          onClick={save}
          disabled={!madeChanges}
        >
          <SaveIcon className="fill-primary" />
        </button>
      ),
    });
    return () =>
      updateAppState({
        title: undefined,
        navbarItems: undefined,
      });
  }, [updateAppState, save, madeChanges, user?.displayName, saved, t]);

  useEffect(
    function () {
      getUser().then(function (data) {
        data.flag = data.flag === '' ? undefined : data.flag;
        setUser(data);
        setFlag(data.flag ?? getFlag());
        setEmail(data.email ?? currentUser.email);
        setSameEmail((data.email ?? currentUser.email) === currentUser.email);
        setLanguage(data.language ?? i18n.language);
        setShowFlag(data.showFlag ?? true);
        setAutoNext(data.autoNext ?? false);
        setHideHcpCount(data.hideHcpCount ?? false);
        setHideHelp(data.hideHelp ?? false);
        setCardVariant(
          data.cardVariant ?? (data.handAsDiagram ? 'diagram' : 'modern')
        );
        setCardSize(data.cardSize ?? 'sm');
        setInterfaceScale(data.interfaceScale ?? 'sm');
        setSuitOrder(data.suitOrder ?? 'default');
        setAllowChatPush(data.allowChatPush ?? true);
        setAllowNewSpotsPush(data.allowNewSpotsPush ?? false);
        setAllowEventPush(data.allowEventPush ?? true);
        // setAllowPollReplyPush(data.allowPollReplyPush ?? true);
        setAllowAnimations(data.allowAnimations ?? true);
        setAllowBlinking(data.allowBlinking ?? true);
        setDisplayName(data.displayName || '');
        setDisplayNameError(getDisplayNameError(data.displayName || '', t));
        setDiscordTag(data.discordTag || '');
        setHiddenSections(data.hiddenSections || '');
      });
    },
    [currentUser.email, t, i18n]
  );

  useEffect(
    function () {
      // User has never saved any flag settings, set it one time automatically
      if (user && !user.flag && user.showFlag !== false) {
        const f = getFlag();
        setFlag(f);
        setShowFlag(true);

        updateUser({
          flag: f ?? null,
          showFlag: true,
        });
      }
    },
    [user, updateAppState]
  );

  // TODO: Temporary, this should live on achievements page later
  useEffect(
    function () {
      if (
        !retryCompleteOptInAchievement &&
        hasAttemptedCompleteAchievement(achievements, DOUBLE_OPT_IN) &&
        !hasCompletedAchievement(achievements, DOUBLE_OPT_IN)
      ) {
        retryCompleteAchievement(DOUBLE_OPT_IN)
          .then(function () {
            // Some time for change to propagate to not try to complete same again
            setTimeout(function () {
              setRetryCompleteOptInAchievement(false);
            }, 5000);
          })
          .catch(function () {
            // Wait for error to hopefully be fixed
            setTimeout(function () {
              setRetryCompleteOptInAchievement(false);
            }, 60000);
          });
      }
    },
    [achievements, retryCompleteOptInAchievement]
  );

  const handleSubscription = () => {
    if (iap) {
      // TODO: Will not work for Android, need other url
      // TODO: Maybe get url from managmentUrl in RC object?
      window.open(
        'https://apps.apple.com/account/subscriptions?:ext',
        '_system'
      );
      return;
    }

    if (isNative) return;

    let url;

    if (user.stripeId && activeWebSubs.length > 0) {
      setLoading(true);
      getCustomerPortalLink()
        .then((e) => {
          url = e.data.url;
          window.open(url, '_blank').focus();
        })
        .catch(() => {
          if (url) {
            window.location.replace(url);
          }
        })
        .finally(() => setLoading(false));
    } else {
      navigate('/store');
    }
  };

  const handleUploadPicture = async (e) => {
    const file = e.target.files[0];
    try {
      await updateProfilePicture(file);
      setImageReload(Date.now());
    } catch (e) {
      log(e);
    }
  };

  const handleUpdateFlag = () => {
    const flag = getFlag();
    if (flag) {
      setFlag(flag);
      setMadeChanges(true);
    }
  };

  const handleManualUpdateFlag = (f) => {
    setFlag(f);
    setMadeChanges(true);
  };

  const handleAccountRemoval = () => {
    setConfirmDeleteOpen(true);
  };

  const handleClose = () => {
    setConfirmDeleteOpen(false);
    setMadeChanges(true);
  };

  function handleUpdateSameEmail(e) {
    setSameEmail(e.target.checked);
    if (e.target.checked) {
      setEmail(currentUser.email);
    }
    setMadeChanges(true);
  }

  function handleUpdateEmail(e) {
    setEmail(e.target.value);
    setMadeChanges(true);
  }

  const handleNextCardVariant = () => {
    const currentIndex =
      (cardVariants.indexOf(cardVariant) + 1) % cardVariants.length;

    setCardVariant(cardVariants[currentIndex]);
    setMadeChanges(true);
  };

  const handleNextSuitOrder = () => {
    const currentIndex =
      (suitOrders.indexOf(suitOrder) + 1) % suitOrders.length;

    setSuitOrder(suitOrders[currentIndex]);
    setMadeChanges(true);
  };

  const handleNextCardSize = () => {
    const currentIndex = (cardSizes.indexOf(cardSize) + 1) % cardSizes.length;

    if (interfaceScale !== 'sm' && cardSizes[currentIndex] === 'sm') {
      setInterfaceScale('sm');
    }
    setCardSize(cardSizes[currentIndex]);
    setMadeChanges(true);
  };

  const handleChangeInterfaceScale = (scale) => {
    setInterfaceScale(scale);
    if (scale !== 'sm' && cardSize === 'sm') {
      setCardSize('md');
    }
    setMadeChanges(true);
  };

  const handleToggleSection = (section) => {
    if (hiddenSections.includes(section)) {
      setHiddenSections(hiddenSections.filter((s) => s !== section));
    } else {
      setHiddenSections([...hiddenSections, section]);
    }
    setMadeChanges(true);
  };

  const handleConfirmDeleteAccount = async () => {
    setConfirmDeleteOpen(false);
    await initiateAccountRemoval(true);
    setUser((u) => ({
      ...u,
      removeMe: true,
    }));
  };

  const handleRestoreAccount = () => {
    initiateAccountRemoval(false);
    setUser((u) => ({
      ...u,
      removeMe: false,
    }));
  };

  const updateLanguage = (language) => {
    setLanguage(language);
    setMadeChanges(true);
  };

  const updateShowFlag = (event) => {
    setShowFlag(event.target.checked);
    setMadeChanges(true);
  };

  const updateAutoNext = (event) => {
    setAutoNext(event.target.checked);
    setMadeChanges(true);
  };

  const updateHideHcpCount = (event) => {
    setHideHcpCount(!event.target.checked);
    setMadeChanges(true);
  };

  const updateAllowChatPush = (event) => {
    setAllowChatPush(event.target.checked);
    setMadeChanges(true);
  };

  const updateAllowNewSpotsPush = (event) => {
    setAllowNewSpotsPush(event.target.checked);
    setMadeChanges(true);
  };

  const updateAllowEventPush = (event) => {
    setAllowEventPush(event.target.checked);
    setMadeChanges(true);
  };

  // const updateAllowPollReplyPush = (event) => {
  //   setAllowPollReplyPush(event.target.checked);
  //   setMadeChanges(true);
  // };

  const updateAllowAnimations = (event) => {
    setAllowAnimations(event.target.checked);
    setMadeChanges(true);
  };

  const updateAllowBlinking = (event) => {
    setAllowBlinking(event.target.checked);
    setMadeChanges(true);
  };

  const updateDisplayName = (event) => {
    const newValue = event.target.value.trimStart();
    setDisplayName(newValue);

    setDisplayNameError(getDisplayNameError(newValue, t));
    setMadeChanges(true);
  };

  const updateDiscordTag = (event) => {
    const newValue = event.target.value.trimStart();
    setDiscordTag(newValue);
    setMadeChanges(true);
  };

  const handleCloseSubscribeNewsletter = () => {
    setSubscribeNewsletterModalOpen(false);
  };

  const confirmSubscribeNewsletter = async () => {
    if (loading) return;

    setLoading(true);
    try {
      await updateUser({
        oldEmail: user.email ?? currentUser.email,
        email,
        optIn: 'STARTED',
      });

      setUser((u) => {
        return {
          ...u,
          email,
        };
      });

      await optInForNewsletter(email);

      notify({
        text: t('notifications.verification_email_sent'),
        type: 'success',
      });

      setSubscribeNewsletterModalOpen(false);
      setLoading(false);
    } catch (e) {
      notify({
        text: t('notifications.something_went_wrong'),
        type: 'failure',
      });
      setLoading(false);
    }
  };

  const handleConnectToSbf = async () => {
    if (!showFedNrInput) {
      setShowFedNrInput(true);
      return;
    }

    if (!fedNrInput) {
      return;
    }

    await updateUser({ fed: 'sv', fedNr: fedNrInput, fedVerified: false });
    location.href = 'https://www.svenskbridge.se/api/v1/auth/bp';
  };

  if (!user) {
    return null;
  }

  const optInStatus = appStateUser?.optIn;

  return (
    <div className="fade-in relative">
      {showProfile && (
        <div className="card fixed top-1/2 left-1/2 z-[100] -translate-x-1/2 -translate-y-1/2 transform bg-[rgb(33,35,48)] p-2">
          <button
            className="btn-secondary btn-square btn absolute top-0 right-0"
            onClick={() => setShowProfile(false)}
          >
            <CloseIcon />
          </button>
          <Player embedded user1={currentUser.uid} />
        </div>
      )}
      <div>
        <div className="page p-0 md:p-2 pb-24 md:pb-24">
          {user.removeMe && (
            <Alert
              sx={'m-2 w-1/2'}
              onClick={handleRestoreAccount}
              severity="warning"
              text={t('removal_warning')}
            />
          )}
          <div className="mb-4 flex w-full flex-col items-center justify-center px-2">
            <span className="font-bold">
              Account:{' '}
              {isPrivateAppleEmail(currentUser.email)
                ? t('private_apple_login')
                : currentUser.email}
            </span>
            {user.fed && user.fedVerified && (
              <span className="flex items-center m-2">
                <img
                  className={'w-10'}
                  alt={user.fed}
                  src={fedIconMap[user.fed]}
                />{' '}
                : {user.fedNr ?? t('unknown')}
              </span>
            )}
            <span className="info text-center md:w-2/3">
              {t('account_bound')}
            </span>
          </div>
          <Link to={'/code'} className='underline decoration-secondary underline-offset-2 decoration-2 my-2'>
            {t('redeem_code_button')}
          </Link>
          <SubscribeNewsletter
            optInStatus={optInStatus ?? 'NOT_STARTED'}
            setSubscribeNewsletterModalOpen={setSubscribeNewsletterModalOpen}
            loading={loading}
          />
          {optInStatus === 'DOUBLE_OPT_IN' &&
            !hasClaimedAchievement(achievements, DOUBLE_OPT_IN) &&
            (hasCompletedAchievement(achievements, DOUBLE_OPT_IN) ? (
              <div className="mb-4">
                {t('newsletter_subscribed')}
                <div>
                  <button
                    disabled={loading}
                    className="btn btn-primary w-48"
                    onClick={async function () {
                      if (loading) return;

                      setLoading(true);
                      try {
                        await tryClaimAchievement(
                          DOUBLE_OPT_IN,
                          DOUBLE_OPT_IN_REWARD
                        );
                        notify({
                          text: t('notifications.reward_claimed'),
                          type: 'success',
                        });
                      } catch (e) {
                        // error
                      } finally {
                        setLoading(false);
                      }
                    }}
                  >
                    <div className="flex items-center gap-2">
                      {t('claim')} <TicketIcon /> &times;{DOUBLE_OPT_IN_REWARD}
                    </div>
                  </button>
                </div>
              </div>
            ) : (
              <div className="mb-4">
                {t('newsletter_subscribed')}
                <div>
                  <button disabled className="btn btn-primary w-48">
                    <div className="flex items-center gap-2">
                      {t('preparing_reward')} <TicketIcon /> &times;
                      {DOUBLE_OPT_IN_REWARD}
                    </div>
                  </button>
                </div>
              </div>
            ))}

          <Collapse title={t('profile_collapse_title')}>
            <div className="flex flex-col items-center">
              <div className="my-2 flex w-3/4 flex-col items-center justify-between md:w-2/3">
                <label className="font-bold">{t('display_name')}</label>
                <input
                  className="input-accent input w-full border-2 border-solid border-slate-200 bg-white text-black caret-secondary"
                  onChange={updateDisplayName}
                  value={displayName}
                  placeholder="Enter username"
                  aria-describedby={
                    displayNameError ? 'username-helper-text' : undefined
                  }
                />
                {displayNameError && (
                  <span id="username-helper-text" className="text-error">
                    {displayNameError}
                  </span>
                )}
              </div>

              <div className="my-2 flex w-3/4 flex-col items-center justify-between md:w-2/3">
                <label className="label">
                  <span className="label-text font-bold">{t('contact_email_label')}</span>
                </label>

                <div className="form-control w-full">
                  <label className="label w-full cursor-pointer p-0 font-bold">
                    {t('same_as_account_label')}
                    <input
                      type="checkbox"
                      className="toggle-primary toggle"
                      checked={sameEmail}
                      onChange={handleUpdateSameEmail}
                    />
                  </label>
                </div>
                {!sameEmail && (
                  <div className="form-control my-2 w-full">
                    <input
                      value={email}
                      onChange={handleUpdateEmail}
                      autoFocus
                      type="email"
                      required
                      placeholder={t('email_placeholder')}
                      className="input"
                    />
                  </div>
                )}
              </div>

              <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
                <span className="font-bold">{t('avatar_label')}</span>
                <button className="btn-accent btn flex rounded-full p-0">
                  <label htmlFor="avatar">
                    <UserDisplayAvatar key={imageReload} uid={user.id} />
                  </label>
                  <input
                    type="file"
                    id="avatar"
                    accept="image/*"
                    hidden
                    onChange={handleUploadPicture}
                  />
                </button>
              </div>
              <div className="my-2 w-3/4 md:w-2/3">
                <LanguageDropdown
                  language={language}
                  handleChangeLanguage={updateLanguage}
                />
              </div>
              <div className="my-2 w-3/4 md:w-2/3">
                <div className="w-full mb-2 flex flex-row items-center justify-between">
                  <label className="font-bold">{t('flag_label')}</label>
                  <div
                    className="badge badge-md cursor-pointer"
                    onClick={handleUpdateFlag}
                  >
                    {t('flag_automatic')}
                    <RefreshIcon width="16px" className={'normal'} />
                  </div>
                </div>
                <SelectCountryDropdown
                  value={flag}
                  onChange={handleManualUpdateFlag}
                  disabled={!showFlag}
                />
              </div>
              <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
                <label className="label w-full cursor-pointer p-0 font-bold">
                  {t('public_flag_label')}
                  <input
                    type="checkbox"
                    className="toggle-primary toggle"
                    checked={showFlag}
                    onChange={updateShowFlag}
                  />
                </label>
              </div>
              {flag === 'se' && !user.fedVerified && (
                <div className="my-2 flex w-3/4 flex-col items-center justify-between md:w-2/3">
                  <button
                    className="btn bg-sbf !text-white my-2"
                    onClick={handleConnectToSbf}
                  >
                    <img
                      src={sbfLogo}
                      alt={t('sbf_logo_alt')}
                      className="h-4 mr-2 -ml-1"
                    />
                    {showFedNrInput
                      ? t('verify_mid')
                      : t('connect_to_sbf')}
                  </button>
                  {showFedNrInput && (
                    <div className="mb-4">
                      <label htmlFor="fedNrInput" className="font-bold">
                        {t('mid_label')}
                      </label>
                      <input
                        id="fedNrInput"
                        className="input-accent input w-full border-2 border-solid border-slate-200 bg-white text-black caret-secondary"
                        onChange={(e) => setFedNrInput(e.target.value)}
                        value={fedNrInput}
                      />
                      <Alert text={t('connect_to_sbf_info')} />
                    </div>
                  )}
                </div>
              )}
              {/* <div className="my-2 flex w-3/4 flex-col items-center justify-between md:w-2/3">
                <label htmlFor="discordTag" className="font-bold">
                  Discord tag
                </label>
                <input
                  id="discordTag"
                  className="input-accent input w-full border-2 border-solid border-slate-200 bg-white text-black caret-secondary"
                  onChange={updateDiscordTag}
                  value={discordTag}
                />
              </div> */}
              <div
                className="link mt-4 flex w-3/4 flex-row items-center justify-center md:w-2/3"
                onClick={() => setShowProfile((v) => !v)}
              >
                {t('view_profile')}
              </div>
            </div>
          </Collapse>
          <Collapse title={t('subscriptions_collapse_title')}>
            <div className="flex flex-col items-center">
              <div className="flex flex-col mb-4">
                {activeSubs.map((s) => {
                  const preload = [
                    'text-hearts',
                    'text-diamonds',
                    'text-clubs',
                  ];
                  return (
                    <div
                      key={s}
                      className={`${s}s font-bold text-xl`}
                    >
                      {t('active_subscription', {sub: t(`subs.${s}`)})}
                    </div>
                  );
                })}
              </div>
              {isNative && !iap ? (
                <div className="alert alert-warning">
                  {
                    <div style={{ textAlign: 'left' }}>
                      <span className="info">
                        {t('mobile_sub_info')}
                      </span>
                    </div>
                  }
                </div>
              ) : (
                <button
                  className="btn-secondary btn"
                  onClick={handleSubscription}
                  disabled={loading}
                >
                  {loading ? t('manage_subscription_loading') : t('manage_subscription_button')}
                </button>
              )}
            </div>
          </Collapse>
          <Collapse title={t('functionality_collapse_title')}>
            <div className="flex flex-col items-center">
              <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
                <label className="label w-full cursor-pointer p-0 font-bold">
                  {t('auto_next_label')}
                  <input
                    type="checkbox"
                    className="toggle-primary toggle"
                    checked={autoNext}
                    onChange={updateAutoNext}
                  />
                </label>
              </div>
              {!allowNotifications && (
                <AlertInline
                  text={t('notifications_disabled_info')}
                  severity={'warning'}
                />
              )}
              <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
                <label className="label w-full cursor-pointer p-0 font-bold">
                  {t('chat_notifications_label')}
                  <input
                    type="checkbox"
                    className="toggle-primary toggle"
                    checked={allowChatPush}
                    onChange={updateAllowChatPush}
                  />
                </label>
              </div>
              <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
                <label className="label w-full cursor-pointer p-0 font-bold">
                  {t('poll_notifications_label')}
                  <input
                    type="checkbox"
                    className="toggle-primary toggle"
                    checked={allowNewSpotsPush}
                    onChange={updateAllowNewSpotsPush}
                  />
                </label>
              </div>
              {/* <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3"> */}
              {/*   <label className="label w-full cursor-pointer p-0 font-bold"> */}
              {/*     Notifications for poll replies */}
              {/*     <input */}
              {/*       type="checkbox" */}
              {/*       className="toggle-primary toggle" */}
              {/*       checked={allowPollReplyPush} */}
              {/*       onChange={updateAllowPollReplyPush} */}
              {/*     /> */}
              {/*   </label> */}
              {/* </div> */}
              <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
                <label className="label w-full cursor-pointer p-0 font-bold">
                  {t('event_notifications_label')}
                  <input
                    type="checkbox"
                    className="toggle-primary toggle"
                    checked={allowEventPush}
                    onChange={updateAllowEventPush}
                  />
                </label>
              </div>
            </div>
          </Collapse>
          <Collapse
            contextSx="px-0"
            title={
              <div className={'relative'}>
                {currentOnboardingStep === onboardingSteps.settings && (
                  <div className="pulse w-5 badge-error badge absolute top-0 left-20" />
                )}
                {t('display_collapse_title')}
              </div>
            }
          >
            <div className="flex flex-col items-center">
              <div className="my-2 flex w-3/4 flex-row items-center justify-center md:w-2/3">
                <div className="join">
                  <button
                    className={'btn btn-primary btn-sm h-10 join-item'}
                    onClick={() => themeChange('dark')}
                  >
                    {t('dark_mode_button')}
                  </button>
                  <button
                    data-theme={'light'}
                    className={
                      'btn btn-primary btn-sm h-10 bg-opacity-20 text-white join-item'
                    }
                    onClick={() => themeChange('light')}
                  >
                    {t('light_mode_button')}
                  </button>
                </div>
              </div>

                <div className="my-2 flex w-3/4 flex-col gao-2 items-center justify-between md:w-2/3 hide-scales">
                  <label className="label w-full cursor-pointer p-0 font-bold justify-center">
                    {t('interface_scale_label')}
                  </label>

                <div className="join">
                  <button
                    className={`btn btn-sm h-10 !text-xs join-item ${interfaceScale === "sm" ? "btn-primary" : "bg-opacity-20"}`}
                    onClick={() => handleChangeInterfaceScale('sm')}
                  >
                    {t('interface_scale_small')}
                  </button>
                  <button
                    className={`btn btn-sm h-10 text-md join-item ${interfaceScale === "md" ? "btn-primary" : "bg-opacity-20"}`}
                    onClick={() => handleChangeInterfaceScale('md')}
                  >
                    {t('interface_scale_medium')}
                  </button>
                  <button
                    className={`hide-large-scales btn btn-sm h-10 text-lg join-item ${interfaceScale === "lg" ? "btn-primary" : "bg-opacity-20"}`}
                    onClick={() => handleChangeInterfaceScale('lg')}
                  >
                    {t('interface_scale_large')}
                  </button>
                </div>
                </div>
              <div className="my-2 flex w-full flex-col items-center justify-between ">
                <div className="popup rounded-t-box rounded-b-box top-px w-full overflow-y-auto slated text-base-content">
                  <div
                    className="w-full rounded-lg outline-2 outline-offset-2 outline-base-content"
                    data-act-class="outline"
                  >
                    <div className="flex w-full h-72 flex-col justify-between font-sans text-base-content">
                      <div className="row-start-1 flex flex-col items-center justify-center gap-1 py-3">
                        <div className="text-sm font-bold capitalize">
                          {cardVariant}
                        </div>
                        <Hand
                          scale={interfaceScale}
                          size={cardSize}
                          variant={cardVariant}
                          hand={'AKQ32.JT9.876.54'}
                          showHcp={!hideHcpCount}
                          direction={'N'}
                          order={suitOrder}
                        />
                      </div>
                      <div className="flex w-full items-center justify-end gap-2 pr-4">
                        <button
                          className="btn-outline btn-sm btn h-8"
                          onClick={handleNextCardSize}
                        >
                          <span className="m-2">{t('symbol_size_label')}</span>
                        </button>
                        <button
                          className="btn-outline btn-sm btn h-8"
                          onClick={handleNextCardVariant}
                        >
                          <span className="m-2">{t('style_label')}</span>
                        </button>
                        <button
                          className="btn-outline btn-sm btn h-8"
                          onClick={handleNextSuitOrder}
                        >
                          <span className="m-2">{t('order_label')}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
                <label className="label w-full cursor-pointer p-0 font-bold">
                  {t('hcp_label')}
                  <input
                    type="checkbox"
                    className="toggle-primary toggle"
                    checked={!hideHcpCount}
                    onChange={updateHideHcpCount}
                  />
                </label>
              </div>
              <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
                <label className="label w-full cursor-pointer p-0 font-bold">
                  {t('animations_label')}
                  <input
                    type="checkbox"
                    className="toggle-primary toggle"
                    checked={allowAnimations}
                    onChange={updateAllowAnimations}
                  />
                </label>
              </div>
              <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
                <label className="label w-full cursor-pointer p-0 font-bold">
                  {t('blinking_label')}
                  <input
                    type="checkbox"
                    className="toggle-primary toggle"
                    checked={allowBlinking}
                    onChange={updateAllowBlinking}
                  />
                </label>
              </div>
            </div>
          </Collapse>
          <Collapse title={<div className={'relative'}>{t('sections_collapse_title')}</div>}>
            <AlertInline text={t('sections_info')} />
            <div className="flex flex-col items-center">
              {sections.map((section) => {
                return (
                  <div
                    key={section}
                    className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3"
                  >
                    <label className="label w-full cursor-pointer p-0 font-bold">
                      {t(`sections.${section.toLowerCase()}`)}
                      <input
                        type="checkbox"
                        className="toggle-primary toggle"
                        checked={!hiddenSections.includes(section)}
                        onChange={() => handleToggleSection(section)}
                      />
                    </label>
                  </div>
                );
              })}
            </div>
          </Collapse>
          <button
            className="btn-primary btn-wide btn mt-4 items-center gap-4"
            onClick={save}
            disabled={!madeChanges}
          >
            {t('save')}<SaveIcon />
          </button>
          {displayNameError && (
            <span className="text-error">{t('display_name_error')}</span>
          )}
          <div className="mt-2">
            {isAdmin(currentUser.uid) && <Link to="/admin">Admin</Link>}
          </div>
          <div className="mt-6">
            {user.removeMe ? (
              <span
                onClick={handleRestoreAccount}
                className="info link cursor-pointer"
              >
                {t('restore_account')}
              </span>
            ) : (
              <span
                className="info link cursor-pointer"
                onClick={handleAccountRemoval}
              >
                {t('remove_account')}
              </span>
            )}
          </div>
          {
            <span className="info mt-4">
              Cuebids v3.36.1 {isNative ? t('mobile') : t('browser')}
            </span>
          }
        </div>
      </div>
      <Dialog
        id="delete-account"
        open={confirmDeleteOpen}
        onClose={handleClose}
        title={t('remove_account_dialog_title')}
        actions={[
          {
            component: (
              <div
                key="delete-account"
                className="btn-error btn"
                role="button"
                onClick={handleConfirmDeleteAccount}
              >
                {t('remove_account_dialog_confirm')}
              </div>
            ),
          },
        ]}
      >
        <div>{t('remove_account_dialog_info')}</div>
      </Dialog>
      {subscribeNewsletterModalOpen && (
        <Dialog
          id="subscribe-to-newsletter"
          open={subscribeNewsletterModalOpen}
          onClose={handleCloseSubscribeNewsletter}
          title={t('newsletter_dialog_title')}
          actions={[
            {
              component: (
                <div
                  disabled={loading || isPrivateAppleEmail(email)}
                  key="subscribe-to-newsletter"
                  className="btn-primary btn"
                  role="button"
                  onClick={confirmSubscribeNewsletter}
                >
                  {t('newsletter_dialog_confirm')}
                </div>
              ),
            },
          ]}
        >
          <div>
            <div>{t('newsletter_dialog_email_info')}</div>
            <div className="form-control my-2">
              <input
                value={email}
                onChange={function (e) {
                  setEmail(e.target.value);
                  setSameEmail(false);
                }}
                type="email"
                required
                placeholder={t('email_placeholder')}
                className="input"
              />
              {isPrivateAppleEmail(email) && (
                <div className="info">
                  {t('newsletter_dialog_apple_email_info')}
                </div>
              )}
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}
