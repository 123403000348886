import { useContext, useEffect, useMemo, useState } from 'react';
import { useAppStateStore } from '../appStateStore';
import { getActiveSubs, hasLoadedDataToGetSubs } from './sub';
import { useNavigate } from 'react-router-dom';
import InfoPopup from '../components/infoPopup/infoPopup';
import { MessageIcon } from '../components/icons/messageIcon';
import { AuthContext } from '../firebase/authContext';
import { getNumberOfUnreadGroupMessages } from './groups';
import { GroupsIcon } from '../components/icons/groupsIcon';
import { getNumberOfUnclaimedAchievements } from './achievements';
import { AchievementsIcon } from '../components/icons/achievementsIcon';
import { getUserRemainingTickets, ticketsMap } from './tickets';
import goToStore from './goToStore';
import { TicketIcon } from '../components/icons/ticketsIcon';
import { DisplayContext } from './displayContext.jsx';
import { UserDisplayAvatarSmall } from '../components/users/userDisplay.jsx'
import { useTranslation } from 'react-i18next';

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );

    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

export function useOutsideClick(ref, onClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick?.();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClick]);
}

export function useHasLoadedDataToGetSubs() {
  const activeIAPSubs = useAppStateStore((state) => state.activeIAPSubs);
  const activeWebSubs = useAppStateStore((state) => state.activeWebSubs);
  const groups = useAppStateStore((state) => state.groups);
  const user = useAppStateStore((state) => state.user);

  return hasLoadedDataToGetSubs({ activeIAPSubs, activeWebSubs, groups, user });
}

export function useActiveSubs() {
  const activeIAPSubs = useAppStateStore((state) => state.activeIAPSubs);
  const activeWebSubs = useAppStateStore((state) => state.activeWebSubs);
  const groups = useAppStateStore((state) => state.groups);
  const user = useAppStateStore((state) => state.user);

  return getActiveSubs({ activeIAPSubs, activeWebSubs, groups, user });
}

export function useGoToPublicGroupPage(groupId) {
  const groups = useAppStateStore((state) => state.groups);
  const navigate = useNavigate();

  const group = (groups || []).find(function(group) {
    return group.id === groupId;
  });

  // User groups have loaded, but user is not member of this group
  if (groups && !group) {
    navigate(`/groupPublic/${groupId}`);
  }
}

export function useMessagesNavbarItem() {
  const messages = useAppStateStore((state) => state.messages);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const numberOfUnreadMessages =
      messages?.filter((m) => m.read === false).length ?? 0;

  return useMemo(
    function () {
      const handleGoToMessages = async () => {
        navigate('/messages');
      };
      return (
        <InfoPopup
          id="tooltip-messages"
          key="navbar-items-messages"
          anchorElement={
            <button className="btn-ghost btn" onClick={handleGoToMessages}>
              <div className="indicator">
                {numberOfUnreadMessages > 0 && (
                  <span className="badge badge-error indicator-item badge-sm">
                    {numberOfUnreadMessages}
                  </span>
                )}
                <MessageIcon width="24px" className={'normal'} />
              </div>
            </button>
          }
        >
          <article className="prose">
            {t('navbar.you_have')} <strong>{numberOfUnreadMessages}</strong> {t('navbar.messages')}.
          </article>
        </InfoPopup>
      );
    },
    [navigate, numberOfUnreadMessages, t]
  );
}

export function useAvatarNavbarItem() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  return useMemo(function () {
    const handleGoToProfile = () => {
      navigate(`/player/${currentUser.uid}`);
    };

    return (
      <button
        className="btn-ghost btn"
        key="navbar-items-avatar"
        onClick={handleGoToProfile}
      >
        <UserDisplayAvatarSmall uid={currentUser.uid} />
      </button>
    );
  }, [navigate, currentUser.uid]);
}

export function useGroupsNavbarItem() {
  const navigate = useNavigate();
  const groups = useAppStateStore((state) => state.groups) || [];
  const groupsExtraData = useAppStateStore((state) => state.groupsExtraData);
  const groupsMessages = useAppStateStore((state) => state.groupsMessages);
  const eventInvites = useAppStateStore((state) => state.eventInvites);
  const groupInvites = useAppStateStore((state) => state.groupInvites);
  const { currentUser } = useAuth();

  const unreadMessagesLength = groups.reduce((acc, group) => {
    return acc + getNumberOfUnreadGroupMessages({ groupId: group.id, groupsExtraData, groupsMessages, userId: currentUser.uid });
  }, 0);

  const numberOfEventInvites = eventInvites.filter(
    (i) =>
      i.invitee === currentUser.uid &&
          groups.map((g) => g.id).includes(i.groupId)
  ).length;

  const numberOfGroupInvites = groupInvites.length;
  const numberOfInvites = numberOfEventInvites + numberOfGroupInvites;

  return useMemo(
    function () {
      const handleGoToGroups = async () => {
        navigate('/groups');
      };

      return (
        <button
          className="btn-ghost btn"
          key="navbar-items-groups"
          onClick={handleGoToGroups}
        >
          <div className="indicator">
            {numberOfInvites > 0 && (
              <span className="indicator-item indicator-start badge badge-success badge-sm">
                {numberOfInvites}
              </span>
            )}
            {unreadMessagesLength > 0 && (
              <span className="badge badge-error indicator-item badge-sm">
                {unreadMessagesLength}
              </span>
            )}
            <GroupsIcon width="24px" className="fill-blue-400" />
          </div>
        </button>
      );
    },
    [navigate, unreadMessagesLength, numberOfInvites]
  );
}

// TODO: Should this be hidden during onboarding?
export function useAchievementsNavbarItem() {
  const achievements = useAppStateStore((state) => state.achievements);

  const numberOfUnclaimedAchievements =
      getNumberOfUnclaimedAchievements(achievements);

  const navigate = useNavigate();

  return useMemo(
    function () {
      const handleGoToAchievements = async () => {
        navigate('/achievements');
      };

      return (
        <button
          className="btn-ghost btn"
          key="navbar-items-achievements"
          onClick={handleGoToAchievements}
        >
          <div className="indicator">
            {numberOfUnclaimedAchievements > 0 && (
              <span className="badge badge-error indicator-item badge-sm">
                {numberOfUnclaimedAchievements}
              </span>
            )}
            <AchievementsIcon width="24px" className="fill-emerald-500" />
          </div>
        </button>
      );
    },
    [numberOfUnclaimedAchievements, navigate]
  );
}

export function useRemainingTicketsNavbarItem() {
  const { currentUser } = useAuth();
  const nav = useNavigate();
  const user = useAppStateStore((state) => state.user);
  const activeIAPSubs = useAppStateStore((state) => state.activeIAPSubs);
  const activeWebSubs = useAppStateStore((state) => state.activeWebSubs);
  const groups = useAppStateStore((state) => state.groups);
  const isNative = useAppStateStore((state) => state.isNative);
  const iap = useAppStateStore((state) => state.iap);
  const { t } = useTranslation()
  const highestSub = useActiveSubs()[0];

  const myRemainingTickets = getUserRemainingTickets(
    user?.ticketsSpent ?? 0,
    highestSub,
    user?.extraTickets ?? 0
  );

  const extraTickets = user?.extraTickets;

  const hasLoaded = hasLoadedDataToGetSubs({ activeIAPSubs, activeWebSubs, groups, user })

  return useMemo(
    function () {
      const handleClickStore = async () => {
        await goToStore({
          nav,
          isNative: isNative,
          iap: iap,
          uid: currentUser.uid,
        });
      };

      return (
        <InfoPopup
          id="tooltip-tickets"
          key="navbar-items-tickets"
          anchorElement={
            <button className="btn-ghost btn" onClick={handleClickStore}>
              <div className="flex flex-col items-center justify-center">
                <TicketIcon />
                {hasLoaded ? (
                  myRemainingTickets - (extraTickets ?? 0) > 0 ? (
                    <span className="font-bold text-secondary">
                      {myRemainingTickets === Infinity
                        ? '∞'
                        : myRemainingTickets}
                    </span>
                  ) : (
                    <span className="font-bold hearts">
                      {myRemainingTickets === Infinity
                        ? '∞'
                        : myRemainingTickets}
                    </span>
                  )
                ) : (
                  <span className="font-bold text-secondary">...</span>
                )}
              </div>
            </button>
          }
        >
          <article className="prose">
            {hasLoaded ? (
              <>
                {t('navbar.tickets_usage')}
                {myRemainingTickets !== Infinity && (
                  <>
                    <p>
                      <span className="font-bold text-secondary">
                        {t('navbar.subscription_tickets')}
                      </span>
                      {' '}
                      <span>
                        {t('navbar.refreshed')}
                      </span>
                      {' '}
                      <span className="font-bold">
                        {t('navbar.monthly')}
                      </span>.
                      
                      <br />
                      <span className="font-bold hearts">
                        {t('navbar.extra_tickets')}
                      </span>
                      {' '}
                      {t('navbar.saved_for_last')}
                    </p>
                    <p>
                      {t('navbar.you_have')}
                      {' '}
                      <span className="font-bold text-secondary">
                        {myRemainingTickets - (extraTickets ?? 0)}{' '}
                        {extraTickets > 0 && (
                          <span className="font-bold hearts">
                            &#40;+
                            {extraTickets}
                                      &#41;{' '}
                          </span>
                        )}
                      </span>
                      {t('navbar.tickets')}.
                    </p>
                  </>
                )}
                {myRemainingTickets === Infinity ? (
                  <p>
                    <span>
                      {t('navbar.as_a')}
                    </span>
                    {' '}
                    <span className="font-bold text-rose-400">
                      {t('navbar.heart')}{t('navbar.subscriber')}
                    </span>
                    {' '}
                    <span>
                      {t('navbar.you_get')}
                    </span>
                    {' '}
                    <span className="font-bold text-amber-500">
                      {t('navbar.infinite')}
                    </span>
                    {' '}
                    {t('navbar.tickets')}.
                  </p>
                ) : highestSub ? (
                  <>
                    <p>
                      <span>
                        {t('navbar.as_a')}
                      </span>
                      {' '}
                      <span className={`${highestSub}s font-bold capitalize`}>
                        {t('navbar.' + highestSub)}
                      </span>{t('navbar.subscriber')}, {t('navbar.you_get')}
                      <strong>{ticketsMap[highestSub]}</strong> {t('navbar.tickets')} {t('navbar.each_month')}.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      {t('navbar.free_user_tickets', { tickets: ticketsMap['basic'] })}
                    </p>
                    <p className={'font-bold text-secondary'}>
                      {t('navbar.upgrade_cta')}
                    </p>
                    {isNative && !iap && (
                      <p className="font-bold text-error">
                        {t('navbar.no_iap')}
                      </p>
                    )}
                  </>
                )}
              </>
            ) : (
              <p>Loading...</p>
            )}
          </article>
        </InfoPopup>
      );
    },
    [
      iap,
      isNative,
      currentUser.uid,
      extraTickets,
      hasLoaded,
      highestSub,
      myRemainingTickets,
      nav,
      t
    ]
  );
}

// Note: Spreading ...items in arguments will create a new array every time and infinite renders
// More than 4 will probably not work on small mobiles anyway
export function useNavbarItems(item1, item2, item3, item4) {
  const updateAppState = useAppStateStore((state) => state.updateAppState);

  useEffect(function () {
    if (item1) {
      updateAppState({
        navbarItems: (
          <div className="flex items-center">
            {item1}
            {item2}
            {item3}
            {item4}
          </div>
        ),
      });

      return function () {
        updateAppState({ navbarItems: undefined });
      };
    }
  }, [item1, item2, item3, item4, updateAppState]);
}

export function useRemainingTicketsNavbarItems() {
  const item = useRemainingTicketsNavbarItem();

  useNavbarItems(item);
}

export function useGroupsNavbarItems() {
  const item = useGroupsNavbarItem();

  useNavbarItems(item);
}

export function useAvatarAndMessagesAndTicketsNavbarItems() {
  const item1 = useAvatarNavbarItem();
  const item2 = useMessagesNavbarItem();
  const item3 = useRemainingTicketsNavbarItem();

  useNavbarItems(item1, item2, item3);
}

export function useDisplay() {
  return useContext(DisplayContext);
}

export function useAuth() {
  return useContext(AuthContext);
}
