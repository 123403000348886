import { auth, db } from './firebase';
import { collection, doc, increment, limit, onSnapshot, orderBy, query, writeBatch, updateDoc, where, getDoc, startAfter } from 'firebase/firestore';
import { contractFromOldStyle } from 'cuebids-bidding-util';
import { docListenWithCallback, docToObject, queryListenWithCallback, queryToObjects } from 'firebase-util';
import { updateUser } from './userApi';
import { createSession, getLastDealNumber } from './biddingSessions'
import { createChatNotification } from 'cuebids-firebase/notifications';
import { getPublicUserAsync } from 'cuebids-firebase/users';
import { getRobotUserId } from '../util/robotPartner'
import i18next from 'i18next'

function challengeSessionAdapter(challengeSession) {
  if (!challengeSession) return null;

  challengeSession.deals.forEach(function(d) {
    if (d.finished && !d.contract) {
      d.contract = contractFromOldStyle({
        finalBid: d.finalBid,
        declarer: d.declarer,
        doubled: d.doubled,
      });
    }
  });
  return challengeSession;
}

export function getChallengeDealsObservable({ challengeId, callback }) {
  const docRef = doc(collection(db, 'challengeSession'), challengeId);

  return docListenWithCallback(docRef, callback, challengeSessionAdapter);
}

export const challengesObservableLimit = 10;
export function getChallengesObservable({ callback }) {
  const currentUser = auth.currentUser;

  const q = query(
    collection(db, 'challengeSession'),
    where('users', 'array-contains', currentUser.uid),
    orderBy('timestamp', 'desc'),
    limit(10),
  );

  return queryListenWithCallback(q, callback, challengeSessionAdapter);
}

export const challengesBeforeTimestampLimit = 100;
export async function getChallengesBeforeTimestamp({ timestamp }) {
  const currentUser = auth.currentUser;

  const q = query(
    collection(db, 'challengeSession'),
    where('users', 'array-contains', currentUser.uid),
    orderBy('timestamp', 'desc'),
    startAfter(timestamp),
    limit(challengesBeforeTimestampLimit),
  );

  return queryToObjects(q, challengeSessionAdapter);
}

export async function getChallenge(id) {
  const challengeRef = doc(collection(db, 'challengeSession'), id);
  return challengeSessionAdapter(docToObject(await getDoc(challengeRef)));
}

// This is used to trigger our cloud function to handle (incorrectly) unscored challenges
export function pingChallenge(id) {
  const challengeRef = doc(collection(db, 'challengeSession'), id);
  return updateDoc(challengeRef, {
    pingCounter: increment(1),
  });
}

export async function getProChallengeDeals(numberOfDeals) {
  const dealsRef = collection(db, 'deals');

  const q = query(
    dealsRef,
    orderBy('dealNumber', 'desc'),
    where('version', '==', 3),
    where('type', '==', 'challenge'),
    limit(numberOfDeals),
  );

  return await queryToObjects(q);
}

// Get deals not used by the challenges for the current user and a partner and 2 opponents, by looking at the latest deal number for challenge deals
export async function getUnusedChallengeDeals({
  numberOfDeals,
  partnerId,
  opp1Id,
  opp2Id,
  minDealNumber = 0,
  // tag,
}) {
  const currentUserId = auth.currentUser.uid;

  let users = [currentUserId, partnerId, opp1Id, opp2Id];

  users = users.filter(u => u !== getRobotUserId());

  const lastDealNumber = await getLastDealNumber(users, null, true);

  const startAfterDealNumber = Math.max(lastDealNumber, minDealNumber);

  const dealsRef = collection(db, 'deals');

  const q = query(
    dealsRef,
    orderBy('dealNumber'),
    startAfter(startAfterDealNumber),
    where('version', '==', 3),
    where('type', '==', 'challenge'),
    limit(numberOfDeals),
  );

  return await queryToObjects(q);
}

export async function createChallenge({
  partnerUserId,
  numberOfDeals,
  compete = 0,
  opp1UserId,
  opp2UserId,
  // minDealNumber,
  matchmaking = false,
  returnBatch = false,
  proChallenge = false,
} = {}) {
  if (!partnerUserId) throw new Error(i18next.t('challenge.error_missing_partner'));
  if (!opp1UserId || !opp2UserId) throw new Error(i18next.t('challenge.error_missing_opponents'));
  const currentUser = auth.currentUser;

  let deals;
  if (proChallenge) {
    deals = await getProChallengeDeals(numberOfDeals);
  } else {
    deals = await getUnusedChallengeDeals({
      numberOfDeals,
      partnerId: partnerUserId,
      opp1Id: opp1UserId,
      opp2Id: opp2UserId,
      // minDealNumber,
    });
  }

  if ((deals?.length ?? 0) < numberOfDeals) {
    throw new Error(i18next.t('challenge.error_insufficient_deals'));
  }

  if (!matchmaking) {
    void updateUser({
      challengeDefaultNumberOfDeals: numberOfDeals,
      challengeDefaultPartner: partnerUserId,
      challengeDefaultOpponent1: opp1UserId,
      challengeDefaultOpponent2: opp2UserId,
    });
  }

  const batch = writeBatch(db);

  const challengeRef = doc(collection(db, 'challengeSession'));

  const { sessionRef: sessionRef1, sessionDeals: sessionDeals1 } =
      await createSession({
        deals,
        compete,
        userOneId: currentUser.uid,
        userTwoId: partnerUserId,
        challenge: challengeRef.id,
        matchmaking,
        ranked: matchmaking,
        returnBatch: true,
        batchToUse: batch,
        proChallenge,
      });

  const { sessionRef: sessionRef2, sessionDeals: sessionDeals2 } =
      await createSession({
        deals,
        compete,
        userOneId: opp1UserId,
        userTwoId: opp2UserId,
        challenge: challengeRef.id,
        matchmaking,
        ranked: matchmaking,
        returnBatch: true,
        batchToUse: batch,
        proChallenge,
      });

  const challengeDeals = sessionDeals1.concat(sessionDeals2);

  batch.set(challengeRef, {
    deals: challengeDeals,
    timestamp: Date.now(),
    finished: false,
    deleted: false,
    users: [currentUser.uid, partnerUserId, opp1UserId, opp2UserId],
    session1: sessionRef1.id,
    session2: sessionRef2.id,
    matchmaking,
    ranked: matchmaking,
    proChallenge,
    dealIds: deals.map(d => d.id),
  });

  if (proChallenge) {
    const dealsRef = collection(db, 'deals');
    deals.forEach((d) => {
      // Remove deals from normal pool by setting them to special type
      batch.update(doc(dealsRef, d.id), {
        type: 'pro-challenge',
      });
    })
  }

  if (returnBatch) {
    return { batch,
      challengeRef };
  }

  await batch.commit();

  return challengeRef.id;
}

async function sendChatMessageNotification(challengeRef, message) {
  const currentUser = auth.currentUser;

  const challengeDoc = await getDoc(challengeRef);
  const data = docToObject(challengeDoc);

  const users = data.users.filter((u) => u !== currentUser.uid);

  const usr = await getPublicUserAsync(currentUser.uid)

  users.forEach(async (u) => {
    const usrRef = doc(collection(db, 'users'), u);
    const user = await getDoc(usrRef);
    const userSettings = user.data();

    if (userSettings.allowChatPush) {
      createChatNotification(u, usr.displayName ?? currentUser.displayName, message, 'https://cuebids.com/challenge/' + challengeDoc.id + '?chatOpen=true');
    }
  });
}

const fields = ['user0ToRead', 'user1ToRead', 'user2ToRead', 'user3ToRead'];

export function getMyUnreadChatMessagesField(userIndex) {
  return `user${userIndex}ToRead`;
}

function getOthersFields(userIndex) {
  const myField = getMyUnreadChatMessagesField(userIndex);
  return fields.filter((f) => f !== myField);
}

export async function sendChatMessage(
  challengeId,
  message,
  userIndex,
) {
  const currentUser = auth.currentUser;
  const inc = increment(1);
  const challengeRef = doc(collection(db, 'challengeSession'), challengeId);
  const challengeMessagesRef = doc(collection(
    doc(collection(db, 'challengeSession'), challengeId),
    'messages',
  ));

  const batch = writeBatch(db);

  const data = {};
  getOthersFields(userIndex).forEach(function(f) {
    data[f] = inc;
  });
  batch.update(challengeRef, data);

  batch.set(challengeMessagesRef, {
    timestamp: Date.now(),
    displayName: currentUser.displayName,
    photoURL: currentUser.photoURL, // TODO: Detta borde kunna tas bort?
    message: message,
    userId: currentUser.uid,
  });

  await batch.commit();

  sendChatMessageNotification(challengeRef, message);
}

export async function setChatMessagesAsRead(
  challengeId,
  userIndex,
) {
  const field = getMyUnreadChatMessagesField(userIndex);

  const challengeRef = doc(collection(db, 'challengeSession'), challengeId);

  return updateDoc(challengeRef, {
    [field]: 0,
  });
}

export function getChatMessagesObservable({ challengeId, callback }) {
  const challenge = doc(collection(db, 'challengeSession'), challengeId);
  const messages = collection(challenge, 'messages');
  const q = query(messages, orderBy('timestamp', 'desc'), limit(100));

  return onSnapshot(q, (querySnapshot) => {
    callback(
      querySnapshot
        .docChanges()
        .filter(c => c.type === 'added')
        .reverse()
        .map((c) => {
          return {
            id: c.doc.id,
            ...c.doc.data()
          }
        }),
    );
  });
}
