import Hand from '../../components/cards/hand.jsx';
import BiddingDisplay from '../../components/bidding/biddingDisplay.jsx';
import BiddingBox from '../../components/bidding/biddingBox.jsx';
import useMockStore from './mockStore.jsx';
import { deals } from './dealMocks.js';
import ContractEvDisplay from '../sessions/sessionComponents/contractEvDisplay.jsx';
import Animated from '../../components/animation/animated.jsx';
import StarRatingDisplay from '../sessions/sessionComponents/starRatingDisplay.jsx';
import { AvatarDisplay, UserNameAndAvatarDisplay } from '../../components/users/userDisplay.jsx';
import { useEffect, useState } from 'react'
import { getLiveBiddingToPresent } from '../sessions/utils.js';
import Alert from '../../components/alert/alert.jsx'
import { useTranslation } from 'react-i18next'

function getNextSteps(deal, step) {
  const next = deal.biddingSteps[step + 1]
  if(!next) {
    return Object.keys(deal.scoring)
  }
  const curr = deal.biddingSteps[step]
  return [next.slice(curr.length + 1, curr.length + 3)]
}

function ActiveSessionDealMock({ bidding, handleOnBid, hand, waiting, partner, bidError }) {
  const { t } = useTranslation('translation', { keyPrefix: 'session_deal_mock' });

  return (
    <>
      <BiddingDisplay bidding={bidding} />
      {
        waiting ? (
          <div className="mt-2 flex w-1/2 flex-col items-center justify-center gap-4  h-[150px]">
            <h2>{t('waiting_for_partner')}</h2>
            <UserNameAndAvatarDisplay
              size={'small'}
              displayName={partner}
              avatar={
                <AvatarDisplay
                  displayName={partner}
                  online={true}
                  size={'small'}
                  avatar={''}
                />
              }
            />
          </div>
        ) : (
          <>
            <div className="mt-2 flex w-1/2 flex-col items-center justify-center gap-4 h-[150px]">
              <BiddingBox bidding={bidding} onBid={handleOnBid}/>
            </div>
            {bidError && <Alert text={bidError} />}
          </>
        )
      }
      <Hand
        variant="modern"
        direction={'S'}
        hand={hand}
      />
    </>
  )
}

function ResultSessionDealMock({ bidding, hand, partnerHand, contract, numberOfStars, ev, best, bestEv, users }) {
  return (
    <>
      <div className='flex flex-col gap-2 items-center'>
        <div className='flex flex-col items-center gap-2'>
          <Hand
            variant="modern"
            direction={'N'}
            hand={partnerHand}
          />
          <UserNameAndAvatarDisplay
            size={'small'}
            displayName={users[0]}
            avatar={
              <AvatarDisplay
                displayName={users[0]}
                online={true}
                size={'small'}
                avatar={''}
              />
            }
          />
        </div>
        <div className="my-4 flex h-[200px] w-[500px] justify-between">
          <Animated
            key={'1'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0,
              duration: 0.5,
            }}
            sx="flex w-1/4 select-none flex-col items-center pl-4"
          >
            <ContractEvDisplay contract={contract} ev={ev} isSmall={false} me={true} />
          </Animated>
          <BiddingDisplay bidding={bidding} />
          <Animated
            key={'2'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.5,
              duration: 0.5,
            }}
            sx="flex w-1/4 select-none flex-col items-center pr-4"
          >
            <ContractEvDisplay contract={best} ev={bestEv} isSmall={false} me={false} />
          </Animated>
        </div>

        <div className='my-4'>
          <Animated
            key={'3'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 1,
              duration: 0.5,
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <StarRatingDisplay id={1} numberOfStars={numberOfStars} />
          </Animated>
        </div>
        <div className='flex flex-col items-center gap-2'>
          <UserNameAndAvatarDisplay
            size={'small'}
            displayName={users[1]}
            avatar={
              <AvatarDisplay
                displayName={users[1]}
                online={true}
                size={'small'}
                avatar={''}
              />
            }
          />
          <Hand
            variant="modern"
            direction={'S'}
            hand={hand}
          />
        </div>
      </div>
    </>
  )
}

function makeFinalBiddingString(bidding, finalBid) {
  return `${bidding}-${finalBid}-P${finalBid === 'P' ? '' : '-P-P'}`
}

export default function SessionDealMock({ id }) {
  const { t } = useTranslation('translation', { keyPrefix: 'session_deal_mock' });
  const { currentDeal, nextStep, users, finalBid, partnerBid, deals: stateDeals, reset } = useMockStore()

  const deal = deals[currentDeal]
  const stateDeal = stateDeals[currentDeal]

  const step = stateDeal.step
  const hand = deal.hand
  const bidding = deal.biddingSteps[step] || makeFinalBiddingString(deal.biddingSteps.slice(-1)[0], stateDeal.finalBid)

  const validOptions = getNextSteps(deal, step)
  const [bidError, setBidError] = useState('');

  const pair = users.slice(0, 2)

  useEffect(() => {
    reset();
  }, [id, reset])

  useEffect(() => {
    let id;
    if (stateDeal.waiting) {
      id = setTimeout(() =>{
        partnerBid()
      }, 3000)
    }

    return () => clearTimeout(id)
  }, [partnerBid, stateDeal.waiting])

  const handleOnBid = (bid) => {
    if (deal.biddingSteps.length - 1 === step) {
      if (validOptions.includes(bid)) {
        setBidError('');
        finalBid(bid);
      } else {
        setBidError(t('bid_error', {options: validOptions.join(t('or'))}));
      }
      return;
    }
    if (validOptions.includes(bid)) {
      setBidError('');
      nextStep();
    } else {
      setBidError(t('bid_error', {options: validOptions.join(t('or'))}));
    }
  }

  if (stateDeal.finalBid) {
    const scoring = deal.scoring[stateDeal.finalBid];
    const best = deal.best;
    return (
      <div className='flex flex-col w-full items-center justify-center ml-10 pt-10'>
        <ResultSessionDealMock
          hand={hand}
          users={pair}
          partnerHand={deal.partner}
          bidding={bidding}
          best={best}
          bestEv={deal.scoring[best].ev}
          numberOfStars={scoring.numberOfStars}
          ev={scoring.ev}
          contract={scoring.contract} />
      </div>
    )
  }

  const adjustedBidding = getLiveBiddingToPresent(bidding, 'S')

  return (
    <div className='flex flex-col w-full items-center justify-center ml-10 pt-10'>
      <ActiveSessionDealMock
        hand={hand}
        bidding={adjustedBidding}
        handleOnBid={handleOnBid}
        waiting={stateDeal.waiting}
        partner={pair[0]}
        bidError={bidError}
      />
    </div>
  )
}
