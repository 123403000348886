import { useState } from 'react';
import Dialog from '../components/dialog';
import { useTranslation } from 'react-i18next';
import UserDisplay, { UserDisplayCoach } from '../components/users/userDisplay';

const buttonClasses = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  error: 'btn-error'
}

const useConfirm = (
  title,
  message, 
  confirmButtonType = 'primary',
  cancelButtonType = 'secondary',
  confirmButtonLabel = 'app.confirm',
  cancelButtonLabel = 'app.cancel'
) => {
  const { t } = useTranslation();
  const [promise, setPromise] = useState(null);
  const [context, setContext] = useState(null);

  const confirm = () => {
    return new Promise((resolve) => {
      setPromise({ resolve });
    });
  };

  const handleClose = () => {
    promise?.resolve(false);
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const ConfirmationDialog = () => (
    <Dialog
      open={promise !== null}
      title={t(title)}
      onClose={handleClose}
      actions={[
        {
          component: (
            <div
              key="no"
              role="button"
              className={`btn ${buttonClasses[cancelButtonType]}`}
              onClick={handleClose}
            >
              {t(cancelButtonLabel)}
            </div>
          ),
        },
        {
          component: (
            <div
              key="yes"
              role="button"
              className={`btn ${buttonClasses[confirmButtonType]}`}
              onClick={handleConfirm}
            >
              {t(confirmButtonLabel)}
            </div>
          ),
        },
      ]}
    >
      {context && context.uid && (
        <div className="m-2">
          {
            context.name ? <UserDisplayCoach uid={context.uid} name={context.name} /> : <UserDisplay uid={context.uid} />
          }
        </div>
      )}
      {t(message)}
    </Dialog>
  );
  return [ConfirmationDialog, confirm, setContext];
};

export default useConfirm;
