import './starRating.css';
import { StarIcon } from '../icons/starIcon.jsx';
import { StarHalfIcon } from '../icons/starHalfIcon.jsx';
import { useAppStateStore } from '../../appStateStore.jsx'

export function Star() {
  return  <div className={'h-4 w-4 mx-1 flex items-center justify-center'}><FullStar size={'sm'} /></div>
}

function EmptyStar({ size }) {
  return <StarIcon className={`star fill-secondary opacity-10 ${starWidthMap[size]}`}  />;
}

function FullStar({ bonus, size, allowBlinking }) {
  if (!bonus|| !allowBlinking) {
    return <StarIcon className={`fill-secondary star ${starWidthMap[size]}`}  />;
  }

  return <StarIcon className={`fill-secondary star bonus-animate ${starWidthMap[size]}`}  />;
}

function HalfStar({ size }) {

  return (
    <StarHalfIcon className={`star fill-secondary ${starWidthMap[size]}`} />
  );
}

function roundHalf(num) {
  return Math.round(num * 2) / 2
}

const starWidthMap = {
  sm: 'w-[18px] h-[18px]',
  md: 'w-[24px] h-[24px]',
  lg: 'w-[32px] h-[32px]',
}

const classMap = {
  sm: 'h-4 gap-[1px]',
  md: 'h-6 gap-0.5',
  lg: 'h-8 gap-1',
};

export function StarRating({
  stars,
  maxStars = 3,
  size = 'md',
  bonus = false,
  useHalfStars = true,
}) {
  const user = useAppStateStore((state) => state.user);
  const allowBlinking = user?.allowBlinking ?? true;

  stars = Math.min(stars, maxStars);
  let fullStars;
  let hasHalfStar = false;
  let emptyStars;
  if (useHalfStars) {
    const roundedStars = roundHalf(stars);

    fullStars = Math.floor(roundedStars);
    hasHalfStar = roundedStars % 1 !== 0;
    const totalStars = hasHalfStar ? fullStars + 1 : fullStars;
    emptyStars = maxStars - totalStars;
  } else {
    fullStars = Math.round(stars)
    emptyStars = maxStars - fullStars
  }

  return (
    <div
      className={`flex ${classMap[size]} items-center justify-center`}
      style={{
        filter: bonus && !allowBlinking
          ? 'drop-shadow(0px 0px 6px rgb(103,30,207)) drop-shadow(0px 0px 10px rgb(103,30,207))'
          : undefined,
      }}
    >
      {[...Array(fullStars)].map((_, i) => <FullStar bonus={bonus} size={size} key={`full-${i}`} allowBlinking={allowBlinking}/>)}
      {hasHalfStar && <HalfStar size={size} />}
      {[...Array(emptyStars)].map((_, i) => <EmptyStar size={size} key={`empty-${i}`}/>)}
    </div>
  );
}
