import { queryListenWithCallback } from 'firebase-util';
import {
  increment,
  runTransaction,
  serverTimestamp,
  collection,
  limit,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from './firebase';
import i18next from 'i18next'

const MESSAGES_COLLECTION = 'messages';

export function getMessagesUserObservable({ uid, callback }) {
  const messagesCollection = collection(db, MESSAGES_COLLECTION);
  const q = query(
    messagesCollection,
    where('uid', '==', uid),
    orderBy('timestamp', 'desc'),
    limit(10)
  );

  return queryListenWithCallback(q, callback);
}

export async function setMessageRead(messageId) {
  if (!messageId) return;
  const messagesCollection = collection(db, MESSAGES_COLLECTION);
  const docRef = doc(messagesCollection, messageId);
  return updateDoc(docRef, {
    read: true,
  });
}

export async function tryClaimItem(messageId) {
  if (!messageId) return;
  const messagesCollection = collection(db, MESSAGES_COLLECTION);
  const docRef = doc(messagesCollection, messageId);

  await runTransaction(db, async (transaction) => {
    const _doc = await transaction.get(docRef);
    if (!_doc.exists()) {
      throw new Error(i18next.t('messages.error_generic'));
    }
    const { item, uid } = _doc.data();

    if (!item || item.claimed) {
      throw new Error(i18next.t('messages.error_already_claimed'));
    }

    if (item.type === 'tickets') {
      const userDocRef = doc(collection(db, 'users'), uid);
      transaction.update(userDocRef, {
        extraTickets: increment(item.quantity),
      });

      const ticketTransactionsRef = doc(
        collection(userDocRef, 'ticketsTransactions')
      );

      transaction.set(ticketTransactionsRef, {
        datetime: serverTimestamp(),
        type: 'itemClaim',
        paymentId: null,
        tickets: item.quantity,
      });
    }

    transaction.update(docRef, {
      item: {
        ...item,
        claimed: true,
      },
    });
  });
}
