import { currentUserId, functions } from '../base';
import { httpsCallable } from 'firebase/functions';
export async function tryClaimCode(code) {
    const uid = currentUserId();
    if (functions === null || functions === undefined) {
        throw new Error('Lost connection, try again.');
    }
    const func = httpsCallable(functions, 'tryClaimCode');
    const resp = await func({
        uid,
        code: code.toUpperCase()
    });
    if (!resp.data.success) {
        throw new Error(resp.data.message);
    }
    return true;
}
