import { useTranslation } from 'react-i18next';
import {
  bidArrayToBidding,
  getBidArrayWithAlertsExcludingPositionalSymbols,
} from 'cuebids-bidding-util'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { editSpot, getSpot } from 'cuebids-firebase/spots'
import CreateEditSpotForm from './components/CreateEditSpotForm.jsx'
import { useQuery } from '@tanstack/react-query'
import useNotifications from '../../components/notifications/useNotifications.jsx';

export default function EditSpot() {
  const { fed, id } = useParams();

  const nav = useNavigate();
  const notify = useNotifications();
  const { t } = useTranslation();

  const { data: spot, isFetchedAfterMount } = useQuery({
    queryKey: ['spot', id],
    queryFn: async () => await getSpot(id),
  });


  if (spot === null) {
    // No spot exists with this id
    return <Navigate to={`/404${location.pathname}`} />
  }

  if (!spot || !isFetchedAfterMount) {
    return null;
  }

  async function onSubmit({
    bidding,
    dealer,
    vulnerability,
    description,
    answer,
    scoring,
    hand,
  }) {

    try {
      await editSpot({
        id,
        bidding,
        dealer,
        vulnerability,
        description,
        answer,
        scoring,
        hand,
      });

      nav(`/spots/${fed}/${id}`);
    } catch (e) {
      notify(
        { text: t('spots.spot_edit_error'), type: 'error' },
      );
    }
  }

  const strippedBidArray = getBidArrayWithAlertsExcludingPositionalSymbols(spot.bidding);
  const initialBidding = bidArrayToBidding(strippedBidArray);

  return (
    <CreateEditSpotForm
      submitLabel={t('spots.spot_edit_submit')}
      onSubmit={onSubmit}
      initialDealer={spot.dealer}
      initialVulnerability={spot.vulnerability}
      initialScoring={spot.scoring}
      initialHand={spot.hand}
      initialDescription={spot.description}
      initialAnswer={spot.answer}
      initialBiddingWithoutPositionalSymbols={initialBidding}
    />
  );
}
