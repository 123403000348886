import { useQuery } from '@tanstack/react-query';
import { sendFriendRequest } from '../../../firebase/friends';
import { Star } from '../../rating/starRating';
import './userCard.css';
import RankCard from '../../rankCard/rankCard';
import { DiscordIcon } from '../../icons/discordIcon';
import { EventIcon } from '../../icons/dailyIcon';
import { useAppStateStore } from '../../../appStateStore';
import { getPublicUserAsync } from '../../../firebase/subscriptions';
import InfoPopup from '../../infoPopup/infoPopup';
import ChallengeStatisticUserBlocks from '../../../pages/challenge/challengeComponents/ChallengeStatisticUserBlocks'
import useNotifications from '../../notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

const ChipsLeft = ({ user, t }) => {
  return (
    <div style={{ width: '100px' }}>
      {user.junior && <InfoPopup
        id={'junior' + user.uid}
        anchorElement={
          <div className="badge badge-primary badge-md">{t('junior')}</div>
        }
      >
        {t('junior_info')}
      </InfoPopup>
      }

      {user.pro && <InfoPopup
        id={'pro' + user.uid}
        anchorElement={
          <div className="badge badge-secondary badge-md">{t('pro')}</div>
        }
      >
        {t('pro_info')}
      </InfoPopup>}

      {user.robot && <InfoPopup
        id={'robot' + user.uid}
        anchorElement={
          <div className="badge badge-info badge-md">{t('robot')}</div>
        }
      >
        {t('robot_info')}
      </InfoPopup>}
    </div>
  );
};

const ChipsRight = () => {
  return <div style={{ width: '100px' }}></div>;
};

const SendRequest = ({ user, t }) => {
  const notify = useNotifications();
  return (
    <div>
      {user.friendStatus === 'none' && !user.robot && (
        <button
          className="btn-primary btn-block btn"
          onClick={async () => {
            const newFriend = await sendFriendRequest(user.id);

            if (newFriend?.isNew) {
              notify(
                {
                  text: t('friend_request_sent', {displayName: newFriend.displayName}),
                  type: 'success',
                },
              );
            }
          }}
        >
          {t('send_friend_request')}
        </button>
      )}
    </div>
  );
};

const User = ({ user, t }) => {
  const { data: usr } = useQuery({
    queryKey: ['user', user.uid],
    queryFn: async () => await getPublicUserAsync( user.uid ),
  });

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex justify-center">
        <div>
          <ChipsLeft user={user} t={t} />
        </div>
        <div style={{ width: 150 }}>
          <RankCard
            uid={user.uid}
            rank={user.rankLevel || 'jack'}
            rankStars={user.rankLevelStars || 0}
            rating={user.rating || 1500}
            alwaysShowRating={user.me}
          />
        </div>
        <div>
          <ChipsRight />
        </div>
      </div>
      <div className="mt-4 flex w-75 flex-col items-center">
        <div className="flex align-center mb-2">
          {(user.showFlag ?? false) && (
            <span className={`mr-2 fi fi-${user.flag}`}></span>
          )}
          <h1 className={`text-2xl ${usr?.pro ? 'text-secondary' : ''} ${usr?.robot ? 'robot' : ''}`}>{usr?.displayName}</h1>
        </div>
        {user.discordTag && (
          <div className="flex items-center justify-center mb-2">
            <DiscordIcon height={20} width={40} />
            <span>{user.discordTag}</span>
          </div>
        )}
      </div>
      <div className="stats-display">
        <div className="flex flex-col justify-center text-center m-2">
          <span className="info">{t('total_stars')}</span>
          <div style={{ padding: 5 }} className="stats flex items-center">
            <Star /> {(user.numberOfStars ?? 0).toLocaleString()}
          </div>
        </div>
        <div className="flex flex-col justify-center text-center m-2">
          <span className="info">{t('weekly_wins')}</span>
          <div style={{ padding: 5 }} className="stats">
            <EventIcon fill="#e29627" /> {user.weeklyWins ?? 0}
          </div>
        </div>

        <div className="flex flex-col justify-center text-center m-2">
          <span className="info">{t('daily_wins')}</span>
          <div style={{ padding: 5 }} className="stats">
            <EventIcon fill="#4d774e" /> {user.dailyWins ?? 0}
          </div>
        </div>
      </div>
      <ChallengeStatisticUserBlocks user={user} linkToChallengeStats />
    </div>
  );
};

export default function UserCard({ user }) {
  const { t } = useTranslation('translation', { keyPrefix: 'components.user_card' });
  if (!user) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-between border border-accent p-4 slated rounded-md">
        <User user={user} t={t} />
      </div>
      {!user.me && <SendRequest user={user} t={t} />}
    </div>
  );
}
