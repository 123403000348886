import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const getDiffFormat = (difference) => {
  if (difference <= 0) return {};
  return {
    d: Math.floor(difference / (1000 * 60 * 60 * 24)),
    h: Math.floor((difference / (1000 * 60 * 60)) % 24),
    m: Math.floor((difference / 1000 / 60) % 60),
    s: Math.floor((difference / 1000) % 60),
  };
};

const calculateTimeLeft = (compareDate) => {
  const now = +new Date();
  return getDiffFormat(compareDate - now);
};

export default function EventTimer({
  event,
  startDate,
  endDate,
  label,
  callback,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'components.event_timer' });

  const now = Date.now();
  const hasStarted = now > (event?.startDate ?? 0);
  const hasEnded = now > (event?.endDate ?? 0);
  const compareDate = (hasStarted ? event?.endDate : event?.startDate) ?? 0;
  const timeLeft = calculateTimeLeft(compareDate);

  const newCallbackState = hasStarted
    ? hasEnded
      ? 'ENDED'
      : 'STARTED'
    : 'NOT_STARTED';

  const [callbackState, setCallbackState] = useState(newCallbackState);
  const [counter, setCounter] = useState(0); // used to trigger re-rendering

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((c) => c + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(
    function () {
      if (newCallbackState !== callbackState) {
        setCallbackState(newCallbackState);
        callback?.(newCallbackState);
      }
    },
    [newCallbackState, callbackState, callback]
  );

  const timerComponents = [];

  try {
    timeLeft &&
      Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
          return;
        }

        timerComponents.push(
          <span key={interval}>
            {timeLeft[interval]}
            {t(interval)}{' '}
          </span>
        );
      });
  } catch (e) {
    //
  }

  if (!hasStarted) {
    return (
      <div>
        {event && (
          <div>
            <div style={{ fontSize: 'small', color: 'grey' }}>{t('starts_soon')}</div>
            <div style={{ fontSize: 'small', color: 'grey' }}>{startDate}</div>
            <div style={{ fontSize: 'x-small', color: 'grey' }}>
              {timeLeft || timerComponents.length ? timerComponents : null}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {event && (
        <div>
          <div style={{ fontSize: 'small', color: 'grey' }}>{endDate}</div>
          <div style={{ fontSize: 'x-small', color: 'grey' }}>
            {timeLeft || timerComponents.length ? (
              timerComponents
            ) : (
              <span>{t('has_ended', { label })}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
