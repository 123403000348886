import { useEffect, useMemo, useState } from 'react';
import BiddingDisplay from '../../../components/bidding/biddingDisplay';
import { ContractDisplay } from '../../../components/bidding/bidDisplay';
import { StarRating } from '../../../components/rating/starRating';
import PairDisplay from '../../../components/users/pairDisplay';
import {
  getBidArrayWithAlertsExcludingPositionalSymbols,
  getBidArrayWithoutAlertsIncludingPositionalSymbols,
  getContractDeclarer
} from 'cuebids-bidding-util'
import Alert, { AlertInline } from '../../../components/alert/alert';
import InfoPopup from '../../../components/infoPopup/infoPopup';
import { FriendsIcon } from '../../../components/icons/friendsIcon';
import Collapse from '../../../components/collapse/Collapse';
import { Tab, TabPanel, Tabs } from '../../../components/tabs/tabs';
import groupByArray from '../../../util/groupBy';
import { getReviewResultKey } from '../../../firebase/dealReviews';
import { useAppStateStore } from '../../../appStateStore.jsx';
import { getPros } from 'cuebids-firebase/users';
import { useQuery } from '@tanstack/react-query';
import { UserDisplayNameSmall } from '../../../components/users/userDisplay.jsx';
import { compareRobotActions } from '../utils.js'
import { Link } from 'react-router-dom';
import { getGroupSession } from '../../../firebase/groups.js'
import Dialog from '../../../components/dialog/index.jsx';
import { NoRobotIcon } from '../../../components/icons/noRobotIcon.jsx';
import { RobotIcon } from '../../../components/icons/robotIcon.jsx';
import { AdvancedRobotIcon } from '../../../components/icons/advancedRobotIcon.jsx';
import { getHandFromDirection } from 'cuebids-hand-util';
import DisplayHand from '../../../components/hand/displayHand.jsx';
import { useAuth } from '../../../util/hooks.jsx'
import { useTranslation } from 'react-i18next';
import {
  getAllRobots,
  getRobotUserId,
  robotSystemMap
} from '../../../util/robotPartner';
import { RobotSystemDisplay } from './robotSystemDisplay';

function isSameResult(users1, users2) {
  return users1[0] === users2[0] && users1[1] === users2[1];
}

const settingMap = {
  0: <NoRobotIcon className={'fill-gray-500 opacity-40'} />,
  1: <RobotIcon className={'fill-sky-400'}/>,
  2: <AdvancedRobotIcon className={'fill-rose-400'} />,
}

const ExplainParBeatBest = () => {
  return (
    <span className="text-base">
      Because of different robot actions, this contract has been graded
      differently for some users.
    </span>
  );
};

export function ReviewResultAccordion({
  id,
  contract,
  results,
  parBeatBestInMajority,
  vulnerability,
  topHandDirection,
  isSmall,
  mySetting
}) {
  const [biddingToShow, setBiddingToShow] = useState(results[0].bidding);
  const [selectedRowUsers, setSelectedRowUsers] = useState(results[0].users);
  const [liaSystem, setLiaSystem] = useState('UNKNOWN');

  const mp = results[0].mp;
  const grade = results[0].grade;
  const ev = results[0].ev;
  const parBeatBest = !!results[0].parBeatBest;
  const showParBeatBestInfo = parBeatBestInMajority
    ? !parBeatBest
    : parBeatBest;

  function handleClickResultRow(users, bidding, liaSystem) {
    setBiddingToShow(bidding);
    setSelectedRowUsers(users);
    setLiaSystem(liaSystem || 'UNKNOWN');
  }

  useEffect(
    function () {
      if (!results.some((r) => isSameResult(r.users, selectedRowUsers))) {
        setBiddingToShow(results[0].bidding);
        setSelectedRowUsers(results[0].users);
        setLiaSystem(results[0].liaSystem || 'UNKNOWN');
      }

    },
    [results, selectedRowUsers]
  );

  const selectedRowIndex = results.findIndex((r) =>
    isSameResult(r.users, selectedRowUsers)
  );

  const settingNameMap = {
    0: 'No robots',
    1: 'Basic robots',
    2: 'Advanced robots',
  }

  const partnerIsRobot = selectedRowUsers.includes(getRobotUserId());
  const robotSystemName = liaSystem === 'MY_SYSTEM' ? 'CUSTOM' : robotSystemMap[liaSystem];

  return (
    <Collapse
      contextSx="px-0 md:px-4"
      fullSizeAlways
      titleElement={
        <>
          <div
            className="flex w-full items-center justify-between relative"
            style={{
              opacity: showParBeatBestInfo ? 0.5 : 1,
            }}
          >
            <div className="min-w-16 badge-accent badge mx-2 w-16">
              <span style={{ marginRight: 5 }}>{results.length}</span>
              <FriendsIcon width="16px" className={'spades'} />
            </div>
            <div className="min-w-20 mx-2 mr-4 flex w-20 items-center">
              <ContractDisplay size={40} contract={contract} />
              <div className="ml-2 h-0 w-2" />
              {contract.length > 2 && getContractDeclarer(contract)}
            </div>
            {showParBeatBestInfo && (
              <div
                className="absolute"
                style={{
                  left: -8,
                  top: -8,
                }}
              >
                <InfoPopup
                  id={`explain-par-beat-best-${id}`}
                  sx="w-8 h-8 stroke-warning"
                  delayShow={0}
                >
                  <ExplainParBeatBest />
                </InfoPopup>
              </div>
            )}
            {!isSmall && <div className="w-12">{mp}%</div>}
            <div className="mx-2 w-20">
              <StarRating stars={grade} size="sm" />
            </div>
            {!isSmall && <div className="w-12 info">{ev}</div>}
          </div>
        </>
      }
    >
      {isSmall && (
        <div className="mb-4 flex items-center justify-around">
          <div className="badge-outline badge">Mp: {mp}%</div>
          <div className="badge-outline badge">Ev: {ev}</div>
        </div>
      )}
      {showParBeatBestInfo && (
        <AlertInline sx="mb-2" severity="info" text={<ExplainParBeatBest />} />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            marginTop: `${
              50 * Math.max(Math.min(selectedRowIndex, results.length - 5), 0)
            }px`,
            position: 'relative',
          }}
        >
          {
            selectedRowUsers && (
              <div className='flex flex-col items-start p-0 m-0'>
                <div className='flex items-center text-xs -mb-2'> <UserDisplayNameSmall uid={selectedRowUsers[0]} /></div>
                <div className='flex items-center text-xs'> <UserDisplayNameSmall uid={selectedRowUsers[1]} /></div>
                {
                  partnerIsRobot &&
                  <div className='mb-2'>
                    <RobotSystemDisplay key={selectedRowIndex} session={{users: selectedRowUsers, liaSystem}} />
                    </div>
                }
              </div>
            )
          }

          <div className="rounded-sm mr-2">
            {biddingToShow && (
              <BiddingDisplay
                bidding={biddingToShow}
                vulnerability={vulnerability}
                startWithEast={topHandDirection === 'S'}
                variant="compact"
              />
            )}
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <table style={{ marginTop: 0, width: '100%' }}>
            <tbody className="[&>*:nth-child(odd)]:bg-base-100">
              {results.map((r) => {
                return (
                  <tr
                    key={r.users[0] + r.users[1]}
                    className={`cursor-pointer max-h-[50px] h-[50px] ${isSameResult(r.users, selectedRowUsers) ? '!bg-success !bg-opacity-10' : ''}`}
                    onClick={() => handleClickResultRow(r.users, r.bidding, r.liaSystem)}
                  >
                    <td className={'relative'}>
                      <PairDisplay uid1={r.users[0]} uid2={r.users[1]} />
                      {
                        r.compete !== mySetting ? (
                          <InfoPopup
                            id={`explain-different-robots-${r.compete}`}
                            sx={'ml-8'}
                            delayShow={0}
                            anchorElement={<div
                              className='badge absolute z-[998] right-0 py-4 top-1/2 transform -translate-y-1/2  '>
                              {settingMap[r.compete]}
                            </div>}
                          >
                            This pair has different robot settings <br/>({settingNameMap[r.compete]})
                          </InfoPopup>
                        ) : <div
                          className='badge absolute z-[998] right-0 py-4 top-1/2 transform -translate-y-1/2  '>
                          {settingMap[r.compete]}
                        </div>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Collapse>
  );
}

function getParBeatBestInMajority(review) {
  let usersWithParBeatBest = 0;
  let usersWithoutParBeatBest = 0;
  Object.values(review).forEach(function (r) {
    const numberOfUsers = r.values.length;
    const parBeatBest = !!r.values[0].parBeatBest;
    if (parBeatBest) {
      usersWithParBeatBest += numberOfUsers;
    } else {
      usersWithoutParBeatBest += numberOfUsers;
    }
  });

  return usersWithParBeatBest > usersWithoutParBeatBest;
}

function addMatchPointsToReview(review) {
  return review.map((r) => {
    const ties = review.filter((r2) => r2.ev === r.ev).length - 1;
    const below = review.filter((r2) => r2.ev < r.ev).length;
    const total = review.length - 1;

    // Handle case when only 1 result
    const mp = total === 0 ? 0.5 : (ties * 0.5 + below * 1) / total;

    return {
      ...r,
      mp: (mp * 100).toFixed(0),
    };
  });
}

function getGroupedReviews(results) {
  const withMp = addMatchPointsToReview(results);
  const grouped = groupByArray(withMp, getReviewResultKey);
  return grouped.sort((a, b) => b.values[0].ev - a.values[0].ev);
}

function filterSameSettingsResults(results, sessionDeal) {
  const myBidArray = getBidArrayWithoutAlertsIncludingPositionalSymbols(sessionDeal.bidding);
  return results.filter(function(r) {
    if (r.compete !== sessionDeal.compete) {
      return false;
    }

    if (r.bidding === sessionDeal.bidding) {
      return true;
    }

    // Exclude hands with different robot actions
    return compareRobotActions(myBidArray, getBidArrayWithoutAlertsIncludingPositionalSymbols(r.bidding));
  });
}

function ResultTab({ results, sessionDeal, topHandDirection, isSmall }) {
  const sorted = useMemo(() => getGroupedReviews(results), [results]);

  return sorted.map((r) => {
    return (
      <ReviewResultAccordion
        key={r.key}
        id={r.key}
        contract={r.values[0].contract}
        results={r.values}
        parBeatBestInMajority={getParBeatBestInMajority(sorted)}
        vulnerability={sessionDeal.vulnerability}
        topHandDirection={topHandDirection}
        isSmall={isSmall}
        mySetting={sessionDeal.compete}
      />
    )
  });
}

function GroupSessionReview({
  sessionDeal,
  results,
  topHandDirection,
  isSmall,
}) {
  const { data: groupSession } = useQuery({
    queryKey: ['groupSession', sessionDeal.groupSession],
    queryFn: async () => await getGroupSession(sessionDeal.groupSession),
  });

  const suggestedBidding = groupSession?.suggestedBidding?.[sessionDeal.dealId]?.bidding ?? '';
  const hasBids = getBidArrayWithAlertsExcludingPositionalSymbols(suggestedBidding).length > 0;
  const suggestedBiddingComment = groupSession?.suggestedBidding?.[sessionDeal.dealId]?.comment;

  return (
    <div className="w-full">
      {suggestedBidding && (
        <Collapse
          contextSx="px-0 md:px-4"
          fullSizeAlways
          title="Suggested bidding"
          initialOpen
        >
          <div
            className={'p-2'}
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
            }}
          >

            <div>
              {hasBids && <div className="border border-black rounded-sm">
                <BiddingDisplay
                  bidding={suggestedBidding}
                  vulnerability={sessionDeal.vulnerability}
                  startWithEast={topHandDirection === 'S'}
                  variant="compact"
                />
              </div>
              }
            </div>
            <p className="px-2 md:pl-0">
              {suggestedBiddingComment}
            </p>
          </div>
        </Collapse>
      )}
      <ResultTab
        sessionDeal={sessionDeal}
        results={results}
        topHandDirection={topHandDirection}
        isSmall={isSmall}
      />
    </div>
  );
}

function Tabulars({
  results,
  friends,
  sessionDeal,
  topHandDirection,
  isSmall,
}) {
  const { currentUser } = useAuth();
  const { t } = useTranslation()

  const { data: pros } = useQuery({
    queryKey: ['pros'],
    queryFn: async () => await getPros(),
  });

  const proResults = results.filter(
    (r) => pros?.includes(r.users[0]) || pros?.includes(r.users[1])
  )

  const friendsResults = results.filter(
    (r) => r.users.includes(currentUser.uid) || friends?.includes(r.users[0]) || friends?.includes(r.users[1])
  )

  const robotResults = results.filter(
      (r) => r.users.some(u => getAllRobots().includes(u))
  )

  const partnershipResults = filterSameSettingsResults(results, sessionDeal).filter(
      (r) => !r.users.some(u => getAllRobots().includes(u))
  );

  const defaultTab = partnershipResults.length > 0 ? 0 : 1;

  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleSetTab = (tab) => {
    if (tab === 0 && partnershipResults.length === 0) {
      return;
    }
    if (tab === 1 && friendsResults.length === 0) {
      return;
    }
    if (tab === 2 && proResults.length === 0) {
      return;
    }
    if (tab === 3 && robotResults.length === 0) {
      return;
    }
    setActiveTab(tab);
  };

  useEffect(() => {
    if (activeTab === 0 && partnershipResults.length === 0) {
      setActiveTab(1);
    }
  }, [partnershipResults, activeTab]);

  if (sessionDeal.groupSession) {
    return (
      <GroupSessionReview
        sessionDeal={sessionDeal}
        results={results}
        topHandDirection={topHandDirection}
        isSmall={isSmall}
      />
    );
  }

  return (
    <div className="w-full">
      <Tabs>
        <Tab
          key={'same'}
          label={t('review_modal.tabs.players', { count: partnershipResults.length })}
          value={0}
          active={activeTab === 0}
          onChange={handleSetTab}
        />
        <Tab
          key={'friends'}
          label={t('review_modal.tabs.friends', { count: friendsResults.length })}
          value={1}
          active={activeTab === 1}
          onChange={handleSetTab}
        />
        <Tab
          key={'pros'}
          label={t('review_modal.tabs.pros', { count: proResults.length })}
          value={2}
          active={activeTab === 2}
          onChange={handleSetTab}
        />
        <Tab
          key={'robot'}
          label={t('review_modal.tabs.robots', { count: robotResults.length })}
          value={3}
          active={activeTab === 3}
          onChange={handleSetTab}
        />
        <Tab
          key={'all'}
          label={t('review_modal.tabs.all', { count: results.length })}
          value={4}
          active={activeTab === 4}
          onChange={handleSetTab}
        />
      </Tabs>

      <TabPanel key={'samePanel'} value={activeTab} index={0}>
        <ResultTab
          sessionDeal={sessionDeal}
          results={partnershipResults}
          topHandDirection={topHandDirection}
          isSmall={isSmall}
        />
      </TabPanel>
      <TabPanel key={'friendsPanel'} value={activeTab} index={1}>
        <ResultTab
          sessionDeal={sessionDeal}
          results={friendsResults}
          topHandDirection={topHandDirection}
          isSmall={isSmall}
        />
      </TabPanel>
      <TabPanel key={'proPanel'} value={activeTab} index={2}>
        <ResultTab
          sessionDeal={sessionDeal}
          results={proResults}
          topHandDirection={topHandDirection}
          isSmall={isSmall}
        />
        {proResults.length < 10 &&
        <Link className='w-full mt-4 flex justify-center' to={'/contact'}>
          <AlertInline sx={'w-2/3 p-2 justify-center flex'}
            text={
              <span>{t('review_modal.please_apply')}
                <div className='badge badge-xs badge-secondary'>
                  {t('review_modal.pro')}
                </div>
              </span>} />
        </Link>
        }
      </TabPanel>
      <TabPanel key={'robotsPanel'} value={activeTab} index={3}>
      <div className='w-full flex justify-center my-1'>
        <Alert sx={'w-2/3 p-2 justify-center flex'} text={<span>{t('review_modal.different_system_warning')}</span>} />
      </div>
        <ResultTab
          sessionDeal={sessionDeal}
          results={robotResults}
          topHandDirection={topHandDirection}
          isSmall={isSmall}
        />
      </TabPanel>
      <TabPanel key={'allPanel'} value={activeTab} index={4}>
        <ResultTab
          sessionDeal={sessionDeal}
          results={results}
          topHandDirection={topHandDirection}
          isSmall={isSmall}
        />
      </TabPanel>
    </div>
  );
}

export default function ReviewModal({
  sessionDeal,
  show,
  results,
  topHandDirection,
  isSmall,
  showOnboarding,
  moveDeal
}) {
  const [ready, setReady] = useState(false);
  const appStateFriends = useAppStateStore((state) => state.friends);
  const hide = useAppStateStore((state) => state.hideReviewDialog);
  const { t } = useTranslation()

  const friends = appStateFriends
    .filter((f) => f.status !== 'blocked')
    .map((f) => f.uid);

  useEffect(function () {
    // There was a performance issue with animations (for Daniel) when loading a session deal result due to review modal ser displays
    const timeoutId = setTimeout(function () {
      setReady(true);
    }, 0);

    return function () {
      clearTimeout(timeoutId);
    };
  }, []);

  if (!sessionDeal || !results) {
    return <Dialog id={'review-modal'} header={<div className="flex justify-center items-center gap-4 font-bold text-white">
      <div className="">
        <button className="" onClick={() => moveDeal(false)}>
          <kbd className="kbd text-sm text-normal">◀︎</kbd>
        </button>
      </div>
      {t('review_modal.title')}
      <div className="">
        <button className="" onClick={() => moveDeal(true)}>
          <kbd className="kbd text-sm text-normal">▶︎</kbd>
        </button>
      </div>
    </div>
    } fullSize open={show} onClose={hide} sx={'m-0 w-full h-screen lg:h-[600px] fade-in'} bodySx={'p-0'}>
      <Tabs>
        <Tab
          key={'same'}
          label={t('review_modal.tabs.players', { count: 0 } )}
          value={0}
          active={true}
          onChange={() => {}}
        />
        <Tab
          key={'friends'}
          label={t('review_modal.tabs.friends', { count: 0 })}
          value={1}
          onChange={() => {}}
        />
        <Tab
          key={'pros'}
          label={t('review_modal.tabs.pros', { count: 0 })}
          value={2}
          onChange={() => {}}
        />
         <Tab
          key={'robots'}
          label={t('review_modal.tabs.robots', { count: 0 })}
          value={3}
          onChange={() => {}}
        />
        <Tab
          key={'all'}
          label={t('review_modal.tabs.all', { count: 0 })}
          value={4}
          onChange={() => {}}
        />
      </Tabs>
      <div className='w-full h-full flex flex-col justify-center items-center'>
        <span className="loading loading-ring loading-lg my-8"/>
      </div>
    </Dialog>
  }

  return (
    <Dialog
      id={'review-modal'}
      header={
        <div className="flex justify-center items-center gap-4 font-bold text-white">
          <div className="">
            <button className="" onClick={() => moveDeal(false)}>
              <kbd className="kbd text-sm text-normal">◀︎</kbd>
            </button>
          </div>
          {t('review_modal.title')}
          <div className="">
            <button className="" onClick={() => moveDeal(true)}>
              <kbd className="kbd text-sm text-normal">▶︎</kbd>
            </button>
          </div>
        </div>
      }
      fullSize
      open={show}
      onClose={hide}
      sx={'m-0 w-full h-screen lg:h-[600px] fade-in'}
      bodySx={'p-0'}
    >
      {showOnboarding && (
        <Alert
          sx="ml-4"
          text={t('onboarding.step_bidding.compare')}
        />
      )}
      {sessionDeal.hand && (
        <Collapse
          titleElement={
            <>
              <div className="flex w-full items-center justify-between">
                <div className="badge-accent badge p-4">
                  {t('review_modal.you')}: &nbsp;{settingMap[sessionDeal.compete]}
                </div>
                <div className="min-w-20 mx-2 mr-4 flex w-20 items-center">
                  <ContractDisplay size={40} contract={sessionDeal.contract} />
                  <div className="ml-2 h-0 w-2" />
                  {sessionDeal.contract.length > 2 &&
                    getContractDeclarer(sessionDeal.contract)}
                </div>
                <div className="mx-2 w-20">
                  <StarRating stars={sessionDeal.resultGrade} bonus={sessionDeal.bonusGrade} size="sm" />
                </div>
                {!isSmall && <div className="info">{sessionDeal.ev}</div>}
              </div>
            </>
          }
          fullSizeAlways={true}
        >
          <div className={'flex items-center w-full'}>
            <div className={'flex flex-col items-center w-full gap-8'}>
              <div className={'flex flex-col items-center text-xs'}>
                <span>
                  <UserDisplayNameSmall uid={topHandDirection === 'N' ? sessionDeal.users[0] : sessionDeal.users[1]} />&nbsp;({topHandDirection === 'N' ? t('bridge.directions.N') : t('bridge.directions.S')})</span>
                <DisplayHand
                  hand={getHandFromDirection(
                    sessionDeal.hand,
                    topHandDirection
                  )}
                />
              </div>
              <div className={'flex flex-col items-center text-xs'}>
                <DisplayHand
                  hand={getHandFromDirection(
                    sessionDeal.hand,
                    topHandDirection === 'N' ? 'S' : 'N'
                  )}
                />
                <span><UserDisplayNameSmall uid={topHandDirection === 'N' ? sessionDeal.users[1] : sessionDeal.users[0]} />&nbsp;({topHandDirection === 'S' ? t('bridge.directions.N') : t('bridge.directions.S')})</span>
              </div>
            </div>
            {
              <BiddingDisplay
                bidding={sessionDeal.bidding}
                vulnerability={sessionDeal.vulnerability}
                startWithEast={topHandDirection === 'S'}
                variant="compact"
              />
            }
          </div>
        </Collapse>
      )}
      {ready && results && (
        <Tabulars
          friends={friends}
          results={results}
          sessionDeal={sessionDeal}
          topHandDirection={topHandDirection}
          isSmall={isSmall}
        />
      )}
    </Dialog>
  );
}
