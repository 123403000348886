import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getHand } from 'cuebids-hand-util';
import { StarRating } from '../rating/starRating';
import { ContractDisplay } from '../bidding/bidDisplay';
import Animated from '../animation/animated';
import { FlagCircleIcon } from '../icons/flagCircleIcon';
import { MessageIcon } from '../icons/messageIcon';
import { NotificationCircleIcon } from '../icons/notificationCircle';
import { getNumberOfUnreadMessagesForKibitzer } from '../../firebase/biddingSessions';
import DisplayHand from './displayHand.jsx';

export default function HandCard({
  deal,
  currentUser,
  i,
  hideDealNumber = false,
  mock = false,
}) {
  const navigate = useNavigate();
  const [numberOfUnreadMessagesKibitzer, setNumberOfUnreadMessagesKibitzer] =
    useState(0);

  const isShared = deal.shared;

  useEffect(
    function () {
      if (isShared) {
        getNumberOfUnreadMessagesForKibitzer(deal.id).then((count) => {
          setNumberOfUnreadMessagesKibitzer(count);
        });
      }
    },
    [isShared, deal.id]
  );

  let userIndex = deal.users.indexOf(currentUser.uid);
  const chatMessageField = ['northToRead', 'southToRead'][userIndex];
  if (deal.users.length === 2 && userIndex === 1) {
    userIndex = 2;
  }
  if (userIndex === -1) {
    userIndex = 1;
  }

  const handCardDisplay = (turn) => {
    const unreadMessages = isShared
      ? numberOfUnreadMessagesKibitzer
      : deal[chatMessageField];
    const numberOfMessages = deal.numberOfMessages;
    return (
      <>
        <td>
          <DisplayHand
            hand={getHand(deal.hand, userIndex)}
            hideDealNumber={hideDealNumber}
            dealNumber={deal.dealNumber}
          />
        </td>
        <td>
          {deal.contract && (
            <ContractDisplay contract={deal.contract} size={30} />
          )}
        </td>
        <td className="align-right">
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {deal.resultGrade != null && (
              <div className="mx-4 flex w-16 items-center md:w-20">
                <StarRating stars={deal.resultGrade} bonus={deal.bonusGrade} size={'sm'} />
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <FlagCircleIcon
                className={deal.marked ? 'fill-secondary' : 'fill-gray-400'}
              />
              <div className="indicator align-middle">
                {unreadMessages > 0 && (
                  <span className="indicator-item badge badge-xs badge-error text-[10px]">
                    {unreadMessages > 9 ? '9+' : unreadMessages}
                  </span>
                )}
                {turn ? (
                  <NotificationCircleIcon className="fill-red-400" />
                ) : (
                  <MessageIcon
                    className={
                      (unreadMessages > 0 || numberOfMessages > 0) ? 'fill-gray-100' : 'fill-gray-400'
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </td>
      </>
    );
  };

  return (
    <Animated
      element="tr"
      variants={{
        hidden: (i) => ({
          opacity: 0,
          scale: 0,
        }),
        visible: (i) => ({
          opacity: 1,
          scale: 1,
          transition: {
            delay: i * 0.02,
          },
        }),
      }}
      initial="hidden"
      exit="hidden"
      animate="visible"
      custom={i}
      sx={
        'h-14 cursor-pointer' +
        (deal.turn === currentUser.uid
          ? !deal.finished
            ? ' turn'
            : ' finished'
          : '')
      }
      style={{ cursor: 'pointer' }}
      onClick={function () {
        if(mock)
          return;
        navigate(`/session/deal/${deal.id}`);
      }}
    >
      {handCardDisplay(!deal.finished && deal.turn === currentUser.uid)}
    </Animated>
  );
}
