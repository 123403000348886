import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import scrollToTop from '../../util/scrollToTop.jsx';

export default function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [location]);

  return null;
}
