import { getRobotUserId } from './robotPartner.js'

export const onboardingSteps = {
  settings: 'settings',
  testSession: 'testSession',
  addFriend: 'addFriend',
  createSession: 'createSession',
  finishSession: 'finishSession',
  stars: 'stars', // Deprecated
  robots: 'robots', // Deprecated
  matchPoints: 'matchPoints', // Deprecated
  help: 'help',
  completed: 'completed',
};

const onboardingStepsOrder = [
  onboardingSteps.settings,
  onboardingSteps.testSession,
  onboardingSteps.addFriend,
  onboardingSteps.createSession,
  onboardingSteps.finishSession,
  onboardingSteps.stars, // Deprecated
  onboardingSteps.robots, // Deprecated
  onboardingSteps.matchPoints, // Deprecated
  onboardingSteps.help,
  onboardingSteps.completed,
];

export function getCurrentOnboardingStep({ user }) {
  return user?.onboardingStep ?? onboardingSteps.completed;
}

export function shouldShowOnboarding({ user }) {
  const currentStep = getCurrentOnboardingStep({ user });
  return currentStep && currentStep !== onboardingSteps.completed;
}

export function getNextOnboardingStep(step) {
  if (!step) {
    return onboardingStepsOrder[0];
  }

  const i = onboardingStepsOrder.indexOf(step);
  if (i === onboardingStepsOrder.length - 1) {
    return step;
  }

  return onboardingStepsOrder[i + 1];
}

export function hasCompletedOnboardingStep({ user, step }) {
  return onboardingStepsOrder.indexOf(getCurrentOnboardingStep({ user })) > onboardingStepsOrder.indexOf(step);
}

function hasFinishedSettingsStep({ user }) {
  // TODO: Some other way to know if user has saved settings? This will stop working if start adding all fields to a new user when creating them
  return user?.allowChatPush !== undefined;
}

function hasFinishedTestSessionStep({ sessions }) {
  return (sessions || []).some(function(s) {
    return s.users[1] === getRobotUserId() && s.numberOfFinishedDeals === s.dealsCount;
  });
}

function hasFinishedAddFriendStep({ friends }) {
  return friends && friends.filter((f) => f.status === 'mutual').length > 0;
}

function hasFinishedCreateSessionStep({ sessions }) {
  if ((sessions || []).length > 1) {
    return true;
  }

  return (sessions || []).some(function(s) {
    return s.users[1] !== getRobotUserId();
  });
}

function hasFinishedFinishSessionStep({ sessions }) {
  const finishedSessions = (sessions || []).filter(function(s) {
    return  s.numberOfFinishedDeals === s.dealsCount;
  });

  if (finishedSessions.length > 1) {
    return true;
  }

  return finishedSessions.some(function(s) {
    return s.users[1] !== getRobotUserId();
  });
}

export const onboardingRules = {
  [onboardingSteps.settings]: hasFinishedSettingsStep,
  [onboardingSteps.testSession]: hasFinishedTestSessionStep,
  [onboardingSteps.addFriend]: hasFinishedAddFriendStep,
  [onboardingSteps.createSession]: hasFinishedCreateSessionStep,
  [onboardingSteps.finishSession]: hasFinishedFinishSessionStep,

  // These exist because (some of) the last explanatory steps were removed.
  [onboardingSteps.stars]: () => true,
  [onboardingSteps.robots]: () => true,
  [onboardingSteps.matchPoints]: () => true,
};
