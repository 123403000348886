import { useEffect, useState } from 'react'
import scrollToTop from '../../util/scrollToTop.jsx'
import Animated from '../animation/animated.jsx'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'

export default function ScrollToTopButton ({ offset = 500 }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false)

  useEffect(function () {
    const appElem = document.getElementById('app')

    const toggleVisible = () => {
      const scrolled = appElem.scrollTop
      if (scrolled > offset) {
        setVisible(true)
      } else if (scrolled <= offset) {
        setVisible(false)
      }
    }
    appElem.addEventListener('scroll', toggleVisible, { passive: true })

    return function () {
      appElem.removeEventListener('scroll', toggleVisible)
    }
  }, [offset]);

  return (
    <AnimatePresence>
      {visible && (
        <Animated
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
        >
          <button
            className="fixed bottom-4 right-[calc(50%-24px)] btn btn-circle btn-primary flex flex-col justify-start items-center gap-1"
            onClick={() => scrollToTop('smooth')}
          >
            <kbd className="text-sm">▲︎</kbd>
            {t('app.go_to_top')}
          </button>
        </Animated>
      )}
    </AnimatePresence>
  )
}
