import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'
import { useAuth } from '../../util/hooks.jsx';
import { isAdmin } from '../../util/admin';
import { useAppStateStore } from '../../appStateStore.jsx';

export function PrivateRoute() {
  const { isVerified } = useAuth();

  const location = useLocation();

  const updateAppState = useAppStateStore((state) => state.updateAppState);

  const isVerifiedNow = isVerified();

  useEffect(() => {
    if (location.pathname !== '/') {
      updateAppState({ redirect: location });
    }
  }, [location, updateAppState]);

  return isVerifiedNow ? <Outlet /> : <Navigate to={'/login'} />;
}

export function PrivatePublicRoute({ publicRoute }) {
  const { isVerified } = useAuth();

  const location = useLocation();

  const updateAppState = useAppStateStore((state) => state.updateAppState);

  const isVerifiedNow = isVerified();

  useEffect(() => {
    updateAppState({ redirect: location });
  }, [location, updateAppState]);

  return isVerifiedNow ? <Outlet /> : publicRoute;
}

export function AdminRoute() {
  const { isVerified, currentUser } = useAuth();

  const isVerifiedNow = isVerified();

  if (!isVerifiedNow) return <Navigate to="/login" />;

  if (!isAdmin(currentUser.uid)) return <Navigate to="/" />;

  return <Outlet />;
}

export function LoginRoute() {
  const { isVerified } = useAuth();
  const { fed } = useParams();

  const isVerifiedNow = isVerified();
  const isNative = useAppStateStore((state) => state.isNative);
  const isMobile = useAppStateStore((state) => state.isMobile);
  const preferWeb = useAppStateStore((state) => state.preferWeb);
  const isSpotPage = useAppStateStore((state) => state.isSpotPage);
  const redirect = useAppStateStore((state) => state.redirect);
  const isJoinGroup = redirect?.pathname?.includes('/joinGroup/');

  if (
    !isNative &&
    isMobile &&
    !preferWeb &&
    !isSpotPage &&
    !isJoinGroup
  ) {
    return <Navigate to={'/apps'} />;
  }

  if (!isVerifiedNow) {
    return <Outlet />;
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  if (fed) {
    return <Outlet />;
  }

  return <Navigate to="/" />;
}
