import { ChatDrawer } from '../../../components/chat/chat.jsx';
import {
  getChatMessagesObservable, getLastReadKibitzerObservable,
  sendChatMessage,
  setChatMessagesAsRead,
  setLastReadKibitzer
} from '../../../firebase/biddingSessions.js';
import { useEffect, useState } from 'react';
import { useAppStateStore } from '../../../appStateStore.jsx';
import { useTranslation } from 'react-i18next';

export default function SessionDealChat({ sessionDeal, currentUserId, moveDeal }) {
  const [messages, setMessages] = useState([]);
  const [lastReadKibitzerTimestamp, setLastReadKibitzerTimestamp] = useState(0);
  const isNative = useAppStateStore((state) => state.isNative);

  useEffect(
    function () {
      if (sessionDeal?.shared) {
        return getLastReadKibitzerObservable({
          sessionDealId: sessionDeal.id,
          callback: setLastReadKibitzerTimestamp,
        });
      }
    },
    [sessionDeal?.shared, sessionDeal?.id]
  );

  const getUnreadMessages = () => {
    if (!sessionDeal || (sessionDeal.kibitzing && !sessionDeal.shared)) {
      return;
    }

    if (sessionDeal.shared) {
      return (
        messages.filter((m) => m.timestamp > lastReadKibitzerTimestamp)
          .length ?? 0
      );
    } else {
      const { northToRead, southToRead, users } = sessionDeal;
      const userIndex = users.indexOf(currentUserId);

      return [northToRead, southToRead][userIndex] ?? 0;
    }
  };

  const setRead = async () => {
    if (!sessionDeal || (sessionDeal.kibitzing && !sessionDeal.shared)) {
      return;
    }

    if (sessionDeal.shared) {
      await setLastReadKibitzer(sessionDeal.id);
    } else {
      const unreadMessages = getUnreadMessages() ?? 0;
      if (unreadMessages === 0) {
        return;
      }

      const userIndex = sessionDeal.users.indexOf(currentUserId);
      await setChatMessagesAsRead(
        sessionDeal.sessionId,
        sessionDeal.id,
        userIndex,
        unreadMessages
      );
    }
  };

  useEffect(
    function () {
      const appendNewMessage = (message) => {
        setMessages((messages) => {
          return messages.concat(message);
        });
      };

      return getChatMessagesObservable({
        sessionDealId: sessionDeal.id,
        callback: appendNewMessage,
      });
    },
    [sessionDeal.id]
  );

  return <ChatDrawer
    usersForHeader={sessionDeal.users.filter(
      (u) => u !== currentUserId
    )}
    usersForHeaderExtraText={
      sessionDeal.extraUsers?.length
        ? `+${sessionDeal.extraUsers.length} users`
        : undefined
    }
    userId={currentUserId}
    messages={messages}
    setRead={setRead}
    unreadMessagesCount={getUnreadMessages()}
    totalCount={messages.length}
    sendMessage={function (message) {
      if (sessionDeal) {
        void sendChatMessage(
          sessionDeal.sessionId,
          sessionDeal.id,
          message,
          sessionDeal.users.indexOf(currentUserId),
          sessionDeal.shared,
        );
        return true;
      }
    }}
    isNative={isNative}
    moveDeal={moveDeal}
  />
}
