import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BiddingDisplay from '../../components/bidding/biddingDisplay';
import Hand from '../../components/cards/hand';
import { useDisplay } from '../../util/hooks.jsx';
import { getSessionDealPublicObservable } from '../../firebase/biddingSessions';
import { useAppStateStore } from '../../appStateStore.jsx';

export default function SessionDealPublic() {
  const { id } = useParams();
  const [sessionDeal, setSessionDeal] = useState(null);
  const updateAppState = useAppStateStore((state) => state.updateAppState);

  const { isSmallNew } = useDisplay();

  const navigate = useNavigate();

  useEffect(
    function () {
      setSessionDeal(null);
      return getSessionDealPublicObservable({
        sessionDealId: id,
        callback: function (sd) {
          if (sd) {
            setSessionDeal(sd);
          } else {
            navigate(`/404${location.pathname}`);
          }
        },
      });
    },
    [id, navigate]
  );

  useEffect(() => {
    if (!sessionDeal?.dealId) {
      return;
    }
    updateAppState({
      title: 'Hand',
      subtitle: sessionDeal.dealId,
    });
    return () => updateAppState({ title: undefined, subtitle: undefined });
  }, [updateAppState, sessionDeal?.dealId]);

  return (
    <div className="page fade-in relative h-[calc(100%_-_70px)]">
      {sessionDeal &&
        (!sessionDeal.finished ? (
          <article className="prose p-10">
            <b>
              Shh, the players are bidding, do not disturb them! When they have
              finished you can see their result.
            </b>
          </article>
        ) : (
          <>
            <div className="flex grow flex-col items-center justify-around md:w-4/5">
              <Hand
                variant={'modern'}
                showHcp={true}
                hand={sessionDeal.northHandPart}
                size={'md'}
                direction={'N'}
              />
              <div className="flex text-base-content">
                <BiddingDisplay
                  bidding={sessionDeal.bidding}
                  vulnerability={sessionDeal.vulnerability}
                  variant={isSmallNew ? 'compact' : 'standard'}
                />
              </div>
              <Hand
                variant={'modern'}
                showHcp={true}
                hand={sessionDeal.southHandPart}
                size={'md'}
                direction={'S'}
              />
            </div>
          </>
        ))}
    </div>
  );
}
