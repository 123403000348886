import React, { useLayoutEffect, useState } from 'react';
import { DisplayContext } from './displayContext';

export const DisplayProvider = ({ children }) => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  let zoomLevel = 0;

  if (size[0] >= 1500 && size[1] >= 1500) {
    zoomLevel = 4;
  }
  else if (size[0] >= 1100 && size[1] >= 1300) {
    zoomLevel = 3;
  }
  else if (size[0] >= 1000 && size[1] >= 1100) {
    zoomLevel = 2;
  }
  else if (size[0] >= 900 && size[1] >= 900) {
    zoomLevel = 1;
  }

  return (
    <DisplayContext.Provider
      value={{
        width: size[0],
        height: size[1],
        small: 576,
        medium: 768,
        large: 992,
        extra: 1200,
        smallHeight: 1000,
        tinyHeight: 650,
        zoomLevel,
        isWide: size[0] > 1400,
        isWidish: size[0] > 1100,
        isSmall: size[0] <= 576 || size[1] <= 650,
        isThin: size[0] <= 450,
        isSmallNew: size[0] <= 678,
        isSmallNewNew: size[0] <= 678 || size[1] <= 650,
      }}
    >
      {children}
    </DisplayContext.Provider>
  );
};
