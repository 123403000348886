import C1 from './bids/1C.png';
import D1 from './bids/1D.png';
import H1 from './bids/1H.png';
import S1 from './bids/1S.png';
import N1 from './bids/1N.png';
import C2 from './bids/2C.png';
import D2 from './bids/2D.png';
import H2 from './bids/2H.png';
import S2 from './bids/2S.png';
import N2 from './bids/2N.png';
import C3 from './bids/3C.png';
import D3 from './bids/3D.png';
import H3 from './bids/3H.png';
import S3 from './bids/3S.png';
import N3 from './bids/3N.png';
import C4 from './bids/4C.png';
import D4 from './bids/4D.png';
import H4 from './bids/4H.png';
import S4 from './bids/4S.png';
import N4 from './bids/4N.png';
import C5 from './bids/5C.png';
import D5 from './bids/5D.png';
import H5 from './bids/5H.png';
import S5 from './bids/5S.png';
import N5 from './bids/5N.png';
import C6 from './bids/6C.png';
import D6 from './bids/6D.png';
import H6 from './bids/6H.png';
import S6 from './bids/6S.png';
import N6 from './bids/6N.png';
import C7 from './bids/7C.png';
import D7 from './bids/7D.png';
import H7 from './bids/7H.png';
import S7 from './bids/7S.png';
import N7 from './bids/7N.png';
import P from './bids/P.png';
import X from './bids/D.png';
import XX from './bids/R.png';

const bidToSvgMap = {
  '1C': C1,
  '1D': D1,
  '1H': H1,
  '1S': S1,
  '1N': N1,
  '2C': C2,
  '2D': D2,
  '2H': H2,
  '2S': S2,
  '2N': N2,
  '3C': C3,
  '3D': D3,
  '3H': H3,
  '3S': S3,
  '3N': N3,
  '4C': C4,
  '4D': D4,
  '4H': H4,
  '4S': S4,
  '4N': N4,
  '5C': C5,
  '5D': D5,
  '5H': H5,
  '5S': S5,
  '5N': N5,
  '6C': C6,
  '6D': D6,
  '6H': H6,
  '6S': S6,
  '6N': N6,
  '7C': C7,
  '7D': D7,
  '7H': H7,
  '7S': S7,
  '7N': N7,
  P: P,
  D: X,
  R: XX,
};

export default function BidDisplaySvg({ bid, x, y, size = 25 }) {
  return (
    <image
      href={bidToSvgMap[bid]}
      x={x - size / 2}
      y={y - size / 2}
      width={size}
      height={size}
    ></image>
  );
}
