import { useEffect, useState } from 'react';
import { getUserPublic } from '../../firebase/userApi';
import UserCard from '../../components/users/userCard/userCard';
import { useAuth } from '../../util/hooks.jsx';
import { useParams } from 'react-router-dom';
import { useAppStateStore } from '../../appStateStore.jsx';

export default function Player({ user1, embedded }) {
  const [userOne, setUserOne] = useState();
  const friends = useAppStateStore((state) => state.friends);
  const { currentUser } = useAuth();
  const { user1: userParam } = useParams();
  user1 = user1 || userParam;

  useEffect(() => {
    async function getUserOne() {
      const usr = await getUserPublic(user1);
      if (currentUser.uid === user1) {
        usr['me'] = true;
      } else {
        usr['friendStatus'] =
          friends?.find((f) => f.id === user1)?.status ?? 'none';
      }
      setUserOne(usr);
    }

    getUserOne();
  }, [user1, userParam, friends, currentUser]);

  return (
    <div className="fade-in mt-10 flex w-full items-center justify-center">
      <div className={embedded ? undefined : 'page pb-24'}>
        {userOne && <UserCard user={userOne} />}
      </div>
    </div>
  );
}
