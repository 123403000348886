import { useEffect, useState } from 'react';
import { useAuth } from '../../util/hooks.jsx';
import Weekly from '../../components/areaCards/weekly';
import { AnimatePresence } from 'framer-motion';
import Animated from '../../components/animation/animated';
import Daily from '../../components/areaCards/daily';
import SessionCard from '../../components/session/sessionCard';
import { getSessionForEvent } from '../../firebase/events';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useQueries } from '@tanstack/react-query'
import { getNextGroupSessionToFinishForGroup } from '../../firebase/groups.js'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import GroupSessionCard from '../../components/areaCards/groupSession.jsx'
import { useTranslation } from 'react-i18next';

export default function Events() {
  const { currentUser } = useAuth();
  const sessions = useAppStateStore((state) => state.sessions);
  const weekly = useAppStateStore((state) => state.weekly);
  const daily = useAppStateStore((state) => state.daily);
  const eventInvites = useAppStateStore((state) => state.eventInvites);
  const groups = useAppStateStore((state) => state.groups) || []
  const { t } = useTranslation()
  const groupSessionQueries = useQueries({
    queries: groups.map((g) => {
      return {
        queryKey: ['groupSession', g.id],
        queryFn: async () => await getNextGroupSessionToFinishForGroup(g.id),
      };
    }),
  });

  const groupSessions = groupSessionQueries.reduce(function (gss, q, i) {
    if (q && q.data) {
      const group = groups[i];
      gss.push({ ...q.data, groupName: group.name, groupFlag: group.groupFlag });
    }
    return gss;
  }, []);

  const [loadedDailySession, setLoadedDailySession] = useState();
  const [loadedWeeklySession, setLoadedWeeklySession] = useState();

  const dailyId = daily?.id;
  const weeklyId = weekly?.id;

  useEffect(
    function () {
      if (dailyId) {
        const getData = async () => {
          const dailySess = await getSessionForEvent('daily', dailyId);
          setLoadedDailySession(dailySess);
        };
        void getData();
      }
    },
    [dailyId]
  );

  useEffect(
    function () {
      if (weeklyId) {
        const getData = async () => {
          const weeklySess = await getSessionForEvent('weekly', weeklyId);
          setLoadedWeeklySession(weeklySess);
        };
        void getData();
      }
    },
    [weeklyId]
  );

  const dailySession =
    sessions.find((s) => s.daily === dailyId) || loadedDailySession;
  const weeklySession =
    sessions.find((s) => s.weekly === weeklyId) || loadedWeeklySession;

  const eventSessions = sessions.filter(
    (s) => s.weekly || s.daily || s.groupSession
  );

  return (
    <Animated
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <div className="page pb-24">
        {weeklyId && <Weekly session={weeklySession} />}
        <div className="mt-4" />
        {dailyId && <Daily session={dailySession} />}
        {groupSessions.length > 0 && (
          <div className="mt-4 w-[350px] md:w-[400px]">
            <Carousel
              autoPlay
              infiniteLoop
              interval={8000}
              stopOnHover
              showStatus={false}
              showThumbs={false}
            >
              {groupSessions.map(function (gs) {
                const numberOfEventInvites = eventInvites.filter((i) => i.invitee === currentUser.uid && i.eventId === gs.id).length;

                return (
                  <GroupSessionCard
                    key={gs.id}
                    event={gs}
                    numberOfEventInvites={numberOfEventInvites}
                    groupName={gs.groupName}
                    groupFlag={gs.groupFlag}
                  />
                );
              })}
            </Carousel>
          </div>
        )}

        {eventSessions && eventSessions.length > 0 && (
          <table
            id="session-list"
            className="mt-10 w-full border-collapse rounded-sm md:w-4/5"
          >
            <thead>
              <tr>
                <th>{t('events.partner')}</th>
                <th>{t('events.progress')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <AnimatePresence>
                {eventSessions.map((s, i) => {
                  return (
                    <SessionCard
                      session={s}
                      key={s.id}
                      currentUser={currentUser}
                      i={i}
                    />
                  );
                })}
              </AnimatePresence>
            </tbody>
          </table>
        )}
      </div>
    </Animated>
  );
}
