import { useState, useEffect } from 'react';
import { checkIfAlreadyPlayingDaily } from '../../../firebase/daily';
import '../../../components/hand/hands.css';
import { SelectFriendDropdown } from '../../../components/dropdown/dropdown';
import { useNavigate } from 'react-router-dom';
import EventTimer from '../../../components/areaCards/eventTimer';
import { timestampToString } from '../../../util/dates';
import { joinDailySession } from '../../../firebase/daily';
import Price from '../../../components/price/price';
import { getUserHighestSubscription } from '../../../firebase/subscriptions';
import PriceDialog from '../../../components/priceDialog/priceDialog';
import {
  canPairAffordToPlay,
  getCharges,
  getUserRemainingTickets,
} from '../../../util/tickets';
import { getUser } from '../../../firebase/userApi';
import RemainingTicketsWarning from '../../../components/remainingTicketsWarning/remainingTicketsWarning';
import Alert from '../../../components/alert/alert';
import { createEventJoinedNotification } from 'cuebids-firebase/notifications';
import { useActiveSubs, useAuth, useRemainingTicketsNavbarItems } from '../../../util/hooks';
import { useAppStateStore } from '../../../appStateStore.jsx';
import TagsDisplay from '../../../components/tagsDisplay/TagsDisplay.jsx'
import { getRobotUserId, getInitialLiaSystem } from '../../../util/robotPartner.js';
import { isHighestSubEnough } from '../../../util/sub.js';
import RequireDiamondDialog from '../../../marketing/RequireDiamondDialog.jsx';
import Diamond from '../../../images/suits/Diamond.jsx';
import { useTranslation } from 'react-i18next';
import useNotifications from '../../../components/notifications/useNotifications.jsx';
import {LiaSystemDropdown} from '../../../components/dropdown/liaSystemDropdown';

const DEFAULT_PRICE = 8;

export default function JoinDailySession() {
  const { t } = useTranslation('translation', { keyPrefix: 'join_daily' });
  const friends = useAppStateStore((state) => state.friends);
  const user = useAppStateStore((state) => state.user);
  const daily = useAppStateStore((state) => state.daily);
  const notify = useNotifications();
  const { currentUser } = useAuth();
  const [friend, setFriend] = useState(null);
  const [friendSub, setFriendSub] = useState(null);
  const [promptToSubscribe, setPromptToSubscribe] = useState(false);

  const [friendId, setFriendId] = useState(
    user?.dailyDefaultPartner || ''
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [alreadyPlay, setAlreadyPlay] = useState(false);
  const [friendAlreadyPlay, setFriendAlreadyPlay] = useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const highestSub = useActiveSubs()[0];

  const [liaSystem, setLiaSystem] = useState(getInitialLiaSystem(user?.defaultLiaSystem));

  const price = daily?.price ?? DEFAULT_PRICE;

  const canUserPlayWithLia = isHighestSubEnough(highestSub, 'diamond');

  const friendsAndRobots = friends.concat([
    { id: getRobotUserId(), status: 'mutual', robot: true, uid: getRobotUserId(),
      icon: <Diamond width={24} />, }
  ]);

  const handleChangeLiaSystem = (val) => {
    setLiaSystem(val);
  };

  const handleClose = () => {
    setConfirmationModalOpen(false);
  };

  const handleSelectFriend = (id) => {
    setFriendId(id);
    setFriend(null);
    setFriendSub(null);
    setFriendAlreadyPlay(false);
  };

  useRemainingTicketsNavbarItems();

  useEffect(() => {
    if(friendId === getRobotUserId() && !canUserPlayWithLia) {
      setPromptToSubscribe(true);
      if(friendId === user?.dailyDefaultPartner) {
        setFriendId('');
      } else {
        setFriendId(user?.dailyDefaultPartner || '')
      }
    }
  }, [canUserPlayWithLia, friendId, user?.dailyDefaultPartner]);

  useEffect(() => {
    async function updateStatus() {
      if (daily?.id && currentUser?.uid) {
        const already = await checkIfAlreadyPlayingDaily({
          id: daily.id,
          userId: currentUser.uid,
        });
        setAlreadyPlay(already);

        if (friendId) {
          const alreadyFriend = await checkIfAlreadyPlayingDaily({
            id: daily.id,
            userId: friendId,
          });
          setFriendAlreadyPlay(alreadyFriend);
        }
      }
    }

    updateStatus();
  }, [daily, friendId, currentUser?.uid]);

  useEffect(
    function () {
      if (friendId) {
        // Slightly ugly to nest, but now we know that when friend is loaded we have loaded both.
        getUserHighestSubscription(friendId).then(function (fs) {
          setFriendSub(fs);
          getUser({ userId: friendId }).then(function (f) {
            setFriend(f);
          });
        });
      }
    },
    [friendId]
  );

  if (!friends || !user) {
    return <></>;
  }

  const banned = user?.banned ?? false;
  const friendBanned = friend?.banned ?? false;

  const myRemainingTickets = getUserRemainingTickets(
    user.ticketsSpent ?? 0,
    highestSub,
    user.extraTickets ?? 0
  );

  const partnerRemainingTickets = friend ?
    (friend.robot ? Infinity : getUserRemainingTickets(
      friend.ticketsSpent ?? 0,
      friendSub,
      friend.extraTickets ?? 0
    )
    ) : 0;

  const { myCharge, partnerCharge } = getCharges({
    myRemainingTickets,
    partnerRemainingTickets,
    price,
  });

  const handleClick = () => {
    if (myCharge !== 0 || partnerCharge !== 0) {
      setConfirmationModalOpen(true);
    } else {
      void joinDailyConfirmed();
    }
  };

  const joinDailyConfirmed = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const { uid: partnerUserId, robot } = friendsAndRobots.find((f) => f.id === friendId);
      const id = await joinDailySession({
        id: daily.id,
        partnerUserId,
        liaSystem,
      });
      if (id) {
        if (!robot) {
          void createEventJoinedNotification(
            partnerUserId,
            user.displayName,
            id,
            'Daily Event'
          );
        }
        navigate(`/session/${id}`, { replace: true });
      }
    } catch (e) {
      notify(
        {
          type: 'failure',
          text: t('join_error', {error: e.message}),
        },
      );
    } finally {
      setLoading(false);
    }
    setConfirmationModalOpen(false);
  };

  if (!daily) {
    return null
  }

  return (
    <div className="fade-in">
      <div className="page pb-24">
        <article className='prose'>
          <h1>{daily.headline || t('headline')}</h1>
        </article>
        <TagsDisplay
          tags={daily.tags}
          tagNames={daily.tagNames}
          sx="mt-2"
        />
        <Alert text={daily.description || t('description')} />
        <Alert severity='success' text={<EventTimer
          event={daily}
          endDate={timestampToString(daily.endDate)}
        />} />
        <div className="mb-4 flex w-full justify-center">
          <SelectFriendDropdown
            value={friendId}
            onChange={handleSelectFriend}
            label={t('partner_label')}
            friends={friendsAndRobots}
            disableFromTicketsAndEvents
          />
        </div>
        {friendId === getRobotUserId() && (
          <LiaSystemDropdown liaSystem={liaSystem} handleChangeLiaSystem={handleChangeLiaSystem} />
        )}
        <div className="flex flex-col items-center gap-2 ">
          {friendId === getRobotUserId() && !canUserPlayWithLia && (
            <Alert
              severity="error"
              text={t('lia_sub_error')}
            />
          )}
          {
            promptToSubscribe && <RequireDiamondDialog onClose={() => setPromptToSubscribe(false)} />
          }
          <Alert
            severity="info"
            text={t('fair_play_info')}
          />
          {!loading && alreadyPlay && (
            <Alert
              severity="error"
              text={t('already_participating_error')}
            />
          )}
          {!loading && (friendAlreadyPlay && !friend?.robot ) && (
            <Alert
              severity="error"
              text={t('partner_participating_error')}
            />
          )}
        </div>
        <Price price={price} defaultPrice={DEFAULT_PRICE} />
        {friend && (
          <RemainingTicketsWarning
            myRemainingTickets={myRemainingTickets}
            partnerRemainingTickets={partnerRemainingTickets}
            price={price}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        {banned && (
          <Alert
            severity="error"
            sx={'w-75 mt-4'}
            text={t('banned_error')}
          />
        )}
        {friendBanned && (
          <Alert
            severity="error"
            sx={'w-75 mt-4'}
            text={t('partner_banned_error')}
          />
        )}
        <button
          className="btn-primary btn mt-4"
          disabled={
            banned ||
            friendBanned ||
            !friendId ||
            !friend ||
            loading ||
            alreadyPlay ||
            (friendId === getRobotUserId() && !canUserPlayWithLia) ||
            (friendAlreadyPlay && !friend?.robot) ||
            !canPairAffordToPlay({
              myRemainingTickets,
              partnerRemainingTickets,
              price,
            })
          }
          onClick={handleClick}
        >
          {t('join_daily_submit')}
        </button>
      </div>
      <PriceDialog
        open={confirmationModalOpen}
        onClose={handleClose}
        title={t('price_dialog_title')}
        myRemainingTickets={myRemainingTickets}
        partnerRemainingTickets={partnerRemainingTickets}
        myCharge={myCharge}
        partnerCharge={partnerCharge}
        actions={[
          {
            component: (
              <div
                disabled={loading}
                role="button"
                key="join-daily"
                className="btn-primary btn"
                onClick={joinDailyConfirmed}
              >
                {t('price_dialog_submit')}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
