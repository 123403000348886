import { currentUserId, getIdToken, getRepos } from '../base';
export * from './types';
export { getPublicUserAsync, getPros } from './rtdbUsers';
export async function setExpoPushToken(token) {
    const ref = getRepos().users.docRef(currentUserId());
    await getRepos().users.update?.(ref, { expoPushToken: token });
}
export async function setConventions(name, data) {
    const ref = getRepos().users.docRef(currentUserId());
    await getRepos().users.update?.(ref, { conventions: data, myLiaSystem: name });
}
export function isAdmin(uid) {
    return (uid === 'vpH1ddRYgsMI4j25QaTZ0Q3EWjV2' || uid === 'F1mBtLClEZPQuOh5xLx1jVJWT5V2');
}
export async function getToken() {
    return await getIdToken();
}
