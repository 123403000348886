import { useState } from 'react';
import Dialog from '../components/dialog';
import { useTranslation } from 'react-i18next';

const buttonClasses = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  error: 'btn-error'
}

const useConfirmWithInput = (title, message, confirmButtonType = 'primary', cancelButtonType = 'secondary') => {
  const { t } = useTranslation();
  const [promise, setPromise] = useState(null);

  const confirm = () => {
    return new Promise((resolve) => {
      setPromise({ resolve });
    });
  };

  const handleClose = () => {
    promise?.resolve(false);
    setPromise(null);
  };

  const handleConfirm = (value) => {
    if (value.trim().length === 0) {
      return
    }
    promise?.resolve(value);
    handleClose();
  };

  const messageIsText = typeof message === 'string';

  const ConfirmationDialog = () => {
    const [value, setValue] = useState('');

    return (
      <Dialog
        open={promise !== null}
        title={t(title)}
        onClose={handleClose}
        actions={[
          {
            component: (
              <div
                key="no"
                role="button"
                className={`btn ${buttonClasses[cancelButtonType]}`}
                onClick={handleClose}
              >
                {t('app.cancel')}
              </div>
            ),
          },
          {
            component: (
              <div
                key="yes"
                role="button"
                disabled={value.trim().length === 0}
                className={`btn ${buttonClasses[confirmButtonType]}`}
                onClick={() => handleConfirm(value)}
              >
                {t('app.confirm')}
              </div>
            ),
          },
        ]}
      >
        {
          messageIsText ? <span className={'info mb-4'}>
            {t(message)}
          </span> :
            <div className={'info mb-4'}>
              {message}
            </div>
        }
        {
          promise !== null && <textarea
            className='input input-primary mt-4 w-full h-24'
            autoFocus
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        }
      </Dialog>
    )};

  return [ConfirmationDialog, confirm];
};

export default useConfirmWithInput;
