
import logo from '../images/logos/logo512.png';
import se from '../images/logos/feds/sv.png';
import pl from '../images/logos/feds/pl.png';
import cz from '../images/logos/feds/cs.png';

export const feds = [
  {
    id: 1,
    flag: 'none',
    name: 'Cuebids Global',
    image: logo,
    language: 'en',
    url: '/spots/en/',
  },
  {
    id: 2,
    flag: 'se',
    name: 'Svenska Bridgeförbundet',
    image: se,
    language: 'sv',
    url: '/spots/sv/',
  },
  {
    id: 3,
    flag: 'none',
    name: 'Polski Związek Brydża Sportowego',
    image: pl,
    language: 'pl',
    url: '/spots/pl/',
  },
  {
    id: 4,
    flag: 'none',
    name: 'Český bridžový svaz',
    image: cz,
    language: 'cs',
    url: '/spots/cs/',
  },
];


export const swedishSpotPages = ['budproblem.se', 'budproblemet.se'];

export function filterFeds(userFlag) {
  return feds.filter(
    (fed) => fed.id === 1 || (fed.flag !== 'none' && fed.flag === userFlag)
  )
}

export function findFed(fed) {
  // TODO: Should probably be on id rather than language
  return feds.find(f => f.language === fed);
}
