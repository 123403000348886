import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Collapse from '../../components/collapse/Collapse';
import {
  deleteGroup,
  leaveGroup,
  transferOwnershipOfGroup,
  updateAllowPushNotificationsInGroup,
  updateGroup,
  updateGroupSettings,
} from '../../firebase/groups';
import Alert from '../../components/alert/alert';
import { LinkedUserDisplay } from '../../components/users/userDisplay';
import GroupHeader from '../../components/groupHeader/GroupHeader';
import {
  doesDescriptionHaveForbiddenCharacters,
  doesDisplayNameHaveForbiddenCharacters,
  getTrimmedDisplayName,
  isDisplayNameTooShort,
} from '../../util/displayName';
import TextField from '../../components/textField/TextField';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useAuth, useGoToPublicGroupPage } from '../../util/hooks';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

function getGroupNameError(name, t) {
  if (!name || isDisplayNameTooShort(name)) {
    return t('too_short_error');
  }

  if (doesDisplayNameHaveForbiddenCharacters(name)) {
    return t('forbidden_characters_error');
  }

  return '';
}

function getGroupDescriptionError(name, t) {
  if (doesDescriptionHaveForbiddenCharacters(name)) {
    return t('forbidden_characters_error');
  }

  return '';
}

export default function ManageGroup() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.manage_group' });
  const { groupId } = useParams();
  const { currentUser } = useAuth();
  const user = useAppStateStore((state) => state.user);
  const groups = useAppStateStore((state) => state.groups);
  const notify = useNotifications();
  const [loading, setLoading] = useState(false);
  const [confirmOwnershipTransfer, setConfirmOwnershipTransfer] =
    useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [reallyConfirmDelete, setReallyConfirmDelete] = useState(false);
  const [allowPush, setAllowPush] = useState(false);
  const [compactEventView, setCompactEventView] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [groupNameError, setGroupNameError] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupDescriptionError, setGroupDescriptionError] = useState('');
  const navigate = useNavigate();

  useGoToPublicGroupPage(groupId);

  const hasLoadedUser = !!user;

  useEffect(
    function () {
      if (hasLoadedUser) {
        setAllowPush(
          (user?.allowPushNotificationsInGroup || []).includes(groupId)
        );
      }
    },
    [hasLoadedUser, user?.allowPushNotificationsInGroup, groupId]
  );

  const group = (groups || []).find(function (group) {
    return group.id === groupId;
  });

  const hasLoadedGroup = !!group;

  useEffect(
    function () {
      if (hasLoadedGroup) {
        setGroupName(group.name);
        setGroupDescription(group.description);
        setCompactEventView(group.compactEventView);
      }
    },
    [hasLoadedGroup, group?.name, group?.description, group?.compactEventView]
  );

  function handleUpdateConfirmTransferOwnership(e) {
    setConfirmOwnershipTransfer(e.target.checked);
  }

  function handleUpdateConfirmDelete(e) {
    setReallyConfirmDelete(false);
    setConfirmDelete(e.target.checked);
  }

  function handleUpdateReallyConfirmDelete(e) {
    setReallyConfirmDelete(e.target.checked);
  }

  const updateAllowPush = (event) => {
    const value = event.target.checked;
    setAllowPush(value);
    updateAllowPushNotificationsInGroup({ groupId, value });
  };

  const onChangeGroupName = (value) => {
    const newValue = value.trimStart();
    setGroupName(newValue);
    setGroupNameError(getGroupNameError(newValue, t));
  };

  const updateCompactEvents = async (event) => {
    const value = event.target.checked;
    setCompactEventView(value);
    setLoading(true);
    await updateGroupSettings({
      groupId,
      settings: {
        compactEventView: value
      }
    });
    setLoading(false);
  };

  const onChangeGroupDescription = (value) => {
    const newValue = value.trimStart();
    setGroupDescription(newValue);
    setGroupDescriptionError(getGroupDescriptionError(newValue, t));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (groupNameError || groupDescriptionError) return;

    let err = getGroupNameError(groupName, t);
    if (err) {
      setGroupNameError(err);
      return;
    }

    err = getGroupDescriptionError(groupDescription, t);
    if (err) {
      setGroupDescriptionError(err);
      return;
    }

    const trimmedGroupName = getTrimmedDisplayName(groupName);

    setLoading(true);
    const res = await updateGroup({
      groupId,
      name: trimmedGroupName,
      description: groupDescription,
    });
    setLoading(false);

    if (res?.nameAlreadyExists) {
      notify(
        { type: 'failure', text: t('name_taken_error') },
      );
      return;
    }

    if (res?.success) {
      notify({ type: 'success', text: t('success') });
    }
  };

  if (!group) {
    return null;
  }

  const members = group.members;
  const admins = group.admins;
  const owner = group.owner;

  const isGroupAdmin = admins.includes(currentUser.uid);
  const isGroupOwner = owner === currentUser.uid;

  return (
    <div className="page pb-24">
      <GroupHeader
        name={group.name}
        description={group.description}
        links
        groupId={groupId}
        premium={group.premium}
        groupFlag={group.groupFlag}
        logo={group.logo}
      />
      <div className="info my-2 px-2">{t('group_settings')}</div>
      {isGroupAdmin && (
        <Link
          to={`/groups/${groupId}/addMembers`}
          className="btn btn-primary my-4"
        >
          {t('add_members')}
        </Link>
      )}
      {
        isGroupAdmin && <Collapse title={t('collapse_title_settings')}>
          <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
            <label className="label w-full cursor-pointer p-0 font-bold">
              {t('compact_events_label')}
              <input
                type="checkbox"
                className="toggle-primary toggle"
                checked={compactEventView}
                onChange={updateCompactEvents}
              />
            </label>
          </div>
        </Collapse>
      }
      <Collapse title={t('collapse_title_notifications')}>
        <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
          <label className="label w-full cursor-pointer p-0 font-bold">
            {t('notifications_label')}
            <input
              type="checkbox"
              className="toggle-primary toggle"
              checked={allowPush}
              onChange={updateAllowPush}
            />
          </label>
        </div>
      </Collapse>
      <Collapse title={t('collapse_title_leave')}>
        {isGroupOwner && (
          <Alert
            severity="warning"
            text={t('owner_leave_info')}
          />
        )}
        <button
          className="btn btn-error"
          disabled={loading || isGroupOwner}
          onClick={async function () {
            if (loading || isGroupOwner) return;

            setLoading(true);
            await leaveGroup(groupId);
            setLoading(false);
            navigate('/');
          }}
        >
          {t('leave_group')}
        </button>
      </Collapse>
      {isGroupOwner && (
        <>
          <Collapse title={t('collapse_title_name_description')}>
            <form
              className="w-80"
              onSubmit={onSubmit}
            >
              <TextField
                fullWidth
                label={t('name_label')}
                value={groupName}
                onChange={onChangeGroupName}
                placeholder={t('name_placeholder')}
                id="groupname"
                error={groupNameError}
              />
              <TextField
                fullWidth
                label={t('description_label')}
                value={groupDescription}
                onChange={onChangeGroupDescription}
                placeholder={t('description_placeholder')}
                id="groupdescription"
                error={groupDescriptionError}
              />
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary mt-4 w-48"
              >
                {t('update_group')}
              </button>
            </form>
          </Collapse>
          <Collapse title={t('collapse_title_transfer_ownership')}>
            <div>
              <Alert
                text={t('transfer_ownership_info')}
                severity="warning"
              />
              <div className="w-80 mt-4">
                <div className="form-control w-full">
                  <label className="label w-full cursor-pointer p-0">
                    {t('transfer_ownership_confirm_label')}
                    <input
                      type="checkbox"
                      className="toggle-primary toggle"
                      checked={confirmOwnershipTransfer}
                      onChange={handleUpdateConfirmTransferOwnership}
                    />
                  </label>
                </div>
              </div>
              <table className="mt-4 w-full border-collapse rounded-sm">
                <thead>
                  <tr>
                    <th>{t('members_count', {count: members.length})}</th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((uid) => {
                    const isOwner = owner === uid;
                    const isAdmin = admins.includes(uid);
                    return (
                      <tr key={uid} className="fade-in-slow">
                        <td className="p-2">
                          <div className="flex items-center gap-2">
                            <LinkedUserDisplay key={uid} uid={uid} />
                            {isOwner && (
                              <div className="badge badge-primary badge-md">
                                {t('owner_badge')}
                              </div>
                            )}
                            {!isOwner && isAdmin && (
                              <div className="badge badge-secondary badge-md">
                                {t('admin_badge')}
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="flex flex-col items-end justify-center gap-2">
                            {!isOwner && (
                              <button
                                disabled={loading || !confirmOwnershipTransfer}
                                onClick={async function () {
                                  if (loading) return;

                                  setLoading(true);
                                  await transferOwnershipOfGroup({
                                    groupId,
                                    userId: uid,
                                  });
                                  setLoading(false);
                                }}
                                className={'btn-secondary btn-xs btn'}
                              >
                                {t('make_owner')}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Collapse>
          <Collapse title={t('collapse_title_delete_group')}>
            <div>
              <Alert
                text={t('delete_group_info', {name: group.name})}
                severity="error"
              />
              <div className="w-80 mt-4">
                <div className="form-control w-full">
                  <label className="label w-full cursor-pointer p-0">
                    {t('delete_group_confirm_label')}
                    <input
                      type="checkbox"
                      className="toggle-primary toggle"
                      checked={confirmDelete}
                      onChange={handleUpdateConfirmDelete}
                    />
                  </label>
                </div>
              </div>
              {confirmDelete && (
                <div className="w-80 mt-4">
                  <div className="form-control w-full">
                    <label className="label w-full cursor-pointer p-0">
                      {t('delete_group_really_confirm_label')}
                      <input
                        type="checkbox"
                        className="toggle-primary toggle"
                        checked={reallyConfirmDelete}
                        onChange={handleUpdateReallyConfirmDelete}
                      />
                    </label>
                  </div>
                </div>
              )}
              {confirmDelete && reallyConfirmDelete && (
                <button
                  disabled={loading}
                  className="btn btn-error mt-4"
                  onClick={async function () {
                    setLoading(true);
                    await deleteGroup(groupId);
                    navigate('/');
                  }}
                >
                  {t('delete_group')}
                </button>
              )}
            </div>
          </Collapse>
        </>
      )}
    </div>
  );
}
