import { Link, useNavigate } from 'react-router-dom';
import Leaderboard from '../leaderBoards/leaderBoard';
import EventTimer from './eventTimer';
import { GroupsIcon } from '../icons/groupsIcon';
import Animated from '../animation/animated.jsx';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../icons/editIcon';
import { DeleteIcon } from '../icons/deleteIcon';
import useConfirm from '../../hooks/confirm';
import { deleteGroupSession } from 'cuebids-firebase/groups';
import useNotifications from '../notifications/useNotifications';
import { useState } from 'react';

export default function GroupSessionCard({ i, event, numberOfEventInvites, groupName, groupFlag, compact, eventBadge = false, groupAdmin }) {
  const navigate = useNavigate()

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.group_event_card' });

  const [loading, setLoading] = useState(false);
  const notify = useNotifications();
  
  const [ConfirmDelete, confirmDelete] = useConfirm(
    'app.confirm_title',
    'groups.delete_confirm',
    'error',
    'secondary'
  );


  const handleDeleteEvent = async () => {
    const ans = await confirmDelete();
    if (ans) {
      if (loading) return;
      try {
        await deleteGroupSession(event.id);
      } catch (e) {
        console.log(e);
        notify(
          { text: t('delete_error'), type: 'error' },
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const startDateString = new Date(event.startDate).toLocaleDateString(i18n.language, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  })
  const endDateString = new Date(event.endDate).toLocaleDateString(i18n.language, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  })

  const permanent = !event.endDate;
  const hasFinished = !permanent && event.endDate < Date.now();

  const groupIconSmall = groupFlag ? (
    <span className={`fi fi-${groupFlag} w-4`}></span>
  ) : (
    <GroupsIcon className="robot w-4"/>
  )

  const groupIcon = groupFlag ? (
    <span className={`fi fi-${groupFlag} w-6`}></span>
  ) : (
    <GroupsIcon className="robot w-6"/>
  )

  if (compact) {
    return (
      <Animated
        element="tr"
        variants={{
          hidden: (i) => ({
            opacity: 0,
          }),
          visible: (i) => ({
            opacity: 1,
            transition: {
              delay: i * 0.02,
            },
          }),
        }}
        initial="hidden"
        exit="hidden"
        animate="visible"
        custom={i}
        key={event.id}
        sx={
          'h-14 cursor-pointer ' +
                    (hasFinished ? ' turn' : (permanent ? ' ' : ' finished'))
        }
        onClick={function () {
          navigate(`/groups/${event.groupId}/eventLeaderboard/${event.id}`);
        }}
      >
        <>
          <td className={'w-[300px]'}>
            <div className="flex items-center justify-start mt-2">
              {!groupName && groupIcon}
              <div className={'flex flex-col'}>
                <div className="ml-2 max-w-75 overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {event.name}
                </div>
                <div className="ml-2 info max-w-75 overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {event.description}
                </div>
              </div>
            </div>
          </td>
          <td className={'w-[200px]'}>
            <div className={'flex flex-col indicator'}>
              <div className="mb-3">
                <span className="info">{event.pairCount || '0'} {t('pairs')}</span>
              </div>
              {numberOfEventInvites > 0 && (
                <span className="indicator-item badge badge-sm badge-success">
                  {numberOfEventInvites}
                </span>
              )}
              {
                eventBadge && (
                  <div className="badge badge-error relative top-0 right-0">{t('join_event')}</div>
                )
              }
              {
                hasFinished && <span className={'info'}>{t('finished')}</span>
              }
              {
                !permanent && <EventTimer
                  event={event}
                  startDate={startDateString}
                  endDate={endDateString}
                  label={'event'}
                />
              }
            </div>
          </td>
        </>
      </Animated>
    )
  }

  return (
    <div className="fade-in-slow card w-[350px] border-2 border-accent md:w-[400px] mb-2">
      <div className="flex items-center justify-center mt-2">
        {!groupName && groupIcon}
        <div className="ml-2 max-w-75 overflow-hidden overflow-ellipsis whitespace-nowrap">
          {event.name}
        </div>
      </div>
      {groupName && (
        <div className="flex items-center justify-center">
          {groupIconSmall}
          <div className="ml-2 max-w-75 overflow-hidden overflow-ellipsis whitespace-nowrap">
            <span className="info">{groupName}</span>
          </div>
        </div>
      )}
      <div className="flex items-center">
        {
          groupAdmin && (
            <div className='absolute top-0 right-0'>
              <div className="flex items-center justify-center gap-1">
                <Link to={`/groups/${event.groupId}/editEvent/${event.id}`}>
                  <button className="btn-secondary btn-sm btn mb-4 h-3 p-1">
                    <EditIcon className='w-4 h-4' />
                  </button>
                </Link>
                <button className="btn-error btn-sm btn mb-4 h-4 p-1" disabled={loading} onClick={handleDeleteEvent}>
                  <DeleteIcon className='w-4 h-4' />
                </button>              
              </div>
          </div>
          )
        }
        {
          eventBadge && (
            <Link to={`/groups/${event.groupId}/joinEvent/${event.id}`}>
              <div className="badge badge-error absolute top-5 right-5">{t('join_event')}</div>
            </Link>
          )
        }
        <div className="flex w-[60%] flex-col items-center md:pr-4">
          <div className="ml-1 w-full md:ml-4">
            <Leaderboard
              leaderBoard={event.leaderBoard}
              top={3}
              links={true}
              header=""
              min={0}
            />
          </div>
        </div>
        <div className="my-2 flex w-[40%] flex-col items-center justify-center text-center relative">
          <div>
            <div className="mb-3">
              <span className="info">{event.pairCount || '0'} {t('pairs')}</span>
            </div>
            <Link to={`/groups/${event.groupId}/eventLeaderboard/${event.id}`}>
              <button className="btn-primary btn-sm btn mb-4 indicator">
                {numberOfEventInvites > 0 && (
                  <span className="indicator-item badge badge-sm badge-success">
                    {numberOfEventInvites}
                  </span>
                )}
                <span>{t('details')}</span>
              </button>
            </Link>
            {
              !permanent && <EventTimer
                event={event}
                startDate={startDateString}
                endDate={endDateString}
                label={'event'}
              />
            }
          </div>
        </div>
      </div>
      
      <ConfirmDelete />
    </div>
  );
}
