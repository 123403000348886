import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  getGroupSessionsObservable,
  addAdminToGroup,
  removeAdminFromGroup,
  removeMemberFromGroup,
  removeMemberInviteFromGroup,
  removePremiumFromMember,
  makeMemberPremium,
} from '../../firebase/groups';
import Alert from '../../components/alert/alert';
import { Tab, TabPanel, Tabs } from '../../components/tabs/tabs';
import UserDisplay, { LinkedUserDisplay } from '../../components/users/userDisplay';
import { ChatDrawer } from '../../components/chat/chat';
import { sendGroupChatMessage, setLastRead } from '../../firebase/groupChat';
import GroupHeader from '../../components/groupHeader/GroupHeader';
import GroupSessionCard from '../../components/areaCards/groupSession';
import useConfirm from '../../hooks/confirm';
import { GroupAdminIcon } from '../../components/icons/groupAdminIcon';
import { RemoveFromGroupIcon } from '../../components/icons/removeFromGroupIcon';
import { PremiumMemberIcon } from '../../components/icons/premiumMemberIcon';
import { checkIf30DaysAgo } from '../../util/dates';
import log from '../../util/logger';
import { useAppStateStore } from '../../appStateStore';
import { getNumberOfUnreadGroupMessages } from '../../util/groups';
import { useAuth, useGoToPublicGroupPage, useGroupsNavbarItems } from '../../util/hooks';
import GroupStats from '../stats/groupStats.jsx'
import { useQuery } from '@tanstack/react-query';
import {
  deleteDraft,
  getEventDrafts
} from 'cuebids-firebase/dealGeneration';
import { checkIfSubscribedToGroup } from '../../firebase/subscriptions.js';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

function EventsTab({ events, isGroupAdmin, groupId, groupFlag, compactView, groupHasPbnImport, t }) {
  const eventInvites = useAppStateStore((state) => state.eventInvites);
  const { currentUser } = useAuth();
  const [searchString, setSearchString] = useState('');
  const [showFinished, setShowFinished] = useState(false);

  const lowerSearchString = searchString.toLowerCase();

  const [drafts, setDrafts] = useState([]);

  const { data } = useQuery(
      {
        queryKey: ['drafts', groupId],
        queryFn:
  () => {
    return getEventDrafts(groupId);
  },
}
  );

  useEffect(() => {
    if (data && isGroupAdmin)
      setDrafts(data)
  }, [data, isGroupAdmin]);

  const removeDraft = async (id) => {
    setDrafts(drafts.filter(d => d.id !== id));
    await deleteDraft(id);
  }


  const handleUpdateShowFinished = () => {
    setShowFinished((f) => !f)
  }

  const filteredEvents = events
    .filter(e => {
      return showFinished || !e.endDate || e.endDate > new Date()
    })
    .filter(e => !e.deleted)
    .filter(e => e.name.toLowerCase().includes(lowerSearchString))
    .sort((a, b) => {
      const now = new Date();
      const aEnd = new Date(a.endDate || 0);
      const bEnd = new Date(b.endDate || 0);

      const aStatus = a.endDate ? (aEnd > now ? 1 : 2) : 0;
      const bStatus = b.endDate ? (bEnd > now ? 1 : 2) : 0;

      const aStartsWith = a.name.toLowerCase().startsWith(lowerSearchString);
      const bStartsWith = b.name.toLowerCase().startsWith(lowerSearchString);

      if (searchString.length === 0) {
        if (aStatus === bStatus) {
          return b.startDate - a.startDate;
        }
        return aStatus - bStatus;
      }

      if (aStartsWith !== bStartsWith) {
        return aStartsWith ? -1 : 1;
      }

      return aStatus - bStatus;
    });


  return (
    <>
      <div className="flex w-full flex-col items-center justify-center">
        <div className={'flex gap-4'}>
          {isGroupAdmin && (
            <Link to={`/groups/${groupId}/createEvent`} className="btn btn-primary my-4">
              {t('create_event')}
            </Link>
          )}
          {isGroupAdmin && groupHasPbnImport && (
            <Link to={`/groups/${groupId}/pbnImport`} className="btn btn-primary my-4">
              {t('import_pbn')} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-file-up"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"/><polyline points="14 2 14 8 20 8"/><path d="M12 12v6"/><path d="m15 15-3-3-3 3"/></svg>
            </Link>
          )}
        </div>
        {
          isGroupAdmin && drafts && drafts.map((draft) => (
            <div key={draft.id} className={'flex items-center gap-2 my-2'}>
              <span className={'info'}>{t('unfinished_draft_info')}</span>
              <Link to={`/eventDraft/${draft.id}`}>
                <button type={'button'} className={'btn btn-secondary btn-sm'}>{t('resume')}</button>
              </Link>
              <button type={'button'} className={'btn btn-error btn-sm'} onClick={() => removeDraft(draft.id)}>{t('delete')}</button>
            </div>
          ))
        }
        {
          events.length > 2 &&
          <div className={'flex items-center gap-4 justify-center'}>
            <input
              value={searchString}
              className='input input-primary'
              placeholder={t('search_event_placeholder')}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <div className="form-control w-full">
              <label className="label w-full cursor-pointer p-0 info flex flex-col items-center">
                {t('show_finished_label')}
                <input
                  type="checkbox"
                  className="toggle-primary toggle"
                  checked={showFinished}
                  onChange={handleUpdateShowFinished}
                />
              </label>
            </div>
          </div>
        }
        {
          !events.length ? <Alert text={t('no_events_info')} /> : (
            compactView ? (
              <table
                id="session-list"
                className="mt-4 w-full border-collapse rounded-sm md:w-4/5"
              >
                <tbody>
                  {filteredEvents.filter(e => e.name)
                    .map((event, i) => {
                      const numberOfEventInvites = eventInvites.filter((i) => i.invitee === currentUser.uid && i.eventId === event.id).length;

                      return (
                        <GroupSessionCard
                          i={i}
                          key={event.id}
                          event={event}
                          numberOfEventInvites={numberOfEventInvites}
                          groupFlag={groupFlag}
                          groupAdmin={isGroupAdmin}
                          compact
                        />
                      );
                    })}
                </tbody>
              </table>
            ) : (
              <>
                <div className="mt-4" />
                {filteredEvents.filter(e => e.name)
                  .map((event, i) => {
                    const numberOfEventInvites = eventInvites.filter((i) => i.invitee === currentUser.uid && i.eventId === event.id).length;

                    return (
                      <GroupSessionCard
                        i={i}
                        key={event.id}
                        event={event}
                        numberOfEventInvites={numberOfEventInvites}
                        groupFlag={groupFlag}
                        groupAdmin={isGroupAdmin}
                      />
                    );
                  })}
              </>
            )
          )}
      </div>
    </>
  );
}

function MembersTab({
  members,
  isGroupOwner,
  isGroupAdmin,
  groupId,
  admins,
  owner,
  premiumMembers,
  isPayToJoinGroup,
  t,
}) {
  const [subs, setSubs] = useState([]);
  const [ConfirmAdmin, confirmMakeAdmin, setAdminContext] = useConfirm(
    'app.confirm_title',
    'groups.make_admin_confirm',
    'primary',
    'secondary',
  );

  const [ConfirmRemoveAdmin, confirmRemoveAdmin, setRemoveAdminContext] = useConfirm(
    'app.confirm_title',
    'groups.remove_admin_confirm',
    'error',
    'secondary',
  );

  const [ConfirmRemoveFromGroup, confirmRemoveFromGroup, setRemoveFromGroupContext] = useConfirm(
    'app.confirm_title',
    'groups.remove_member_confirm',
    'error',
    'secondary',
  );

  const handleMakeAdmin = async (groupId, uid) => {
    setAdminContext({ uid });
    // this await does matter...
    const ans = await confirmMakeAdmin();
    if (ans) {
      await addAdminToGroup({ groupId,
        userId: uid });
    }
  };

  const handleRemoveAdmin = async (groupId, uid) => {
    setRemoveAdminContext({ uid });
    const ans = await confirmRemoveAdmin();
    if (ans) {
      await removeAdminFromGroup({ groupId,
        userId: uid });
    }
  };

  const handleRemoveFromGroup = async (groupId, uid) => {
    setRemoveFromGroupContext({ uid });
    const ans = await confirmRemoveFromGroup();
    if (ans) {
      await removeMemberFromGroup({ groupId,
        userId: uid });
    }
  };

  useEffect(() => {
    if(!isGroupAdmin || !isPayToJoinGroup) return;
    async function getData() {
      const _subs = []
      for (const uid of members) {
        const isSubscribed = await checkIfSubscribedToGroup(uid, groupId);
        if (isSubscribed) {
          _subs.push(uid)
        }
      }

      setSubs(_subs);

    }
    getData();
  }, [groupId, members, isGroupAdmin, isPayToJoinGroup]);

  return (
    <div className="flex w-full flex-col items-center">
      {isGroupAdmin && (
        <Link to={`/groups/${groupId}/addMembers`} className="btn btn-primary my-4">
          {t('add_members')}
        </Link>
      )}
      <table className="mt-4 w-full border-collapse rounded-sm">
        <thead>
          <tr>
            <th>
              {t('members_count', {count: members.length})}
            </th>
          </tr>
        </thead>
        <tbody>
          {members.map( (uid) => {
            const isOwner = owner === uid;
            const isAdmin = admins.includes(uid);
            const isPremiumMember = premiumMembers.some((m) => m.uid === uid);
            const isSubscribed = subs.includes(uid);
            return (
              <tr key={uid} className="fade-in-slow">
                <td className="p-2">
                  <LinkedUserDisplay key={uid} uid={uid} />
                </td>
                <td>
                  <div className="flex flex-col items-center gap-1">
                    {isOwner && <div className="badge badge-primary badge-md" >{t('owner_badge')}</div>}
                    {!isOwner && isAdmin && <div className="badge badge-secondary badge-md" >{t('admin_badge')}</div>}
                    {isGroupAdmin && isPremiumMember && <div className="badge badge-success badge-md" >{t('premium_badge')}</div>}
                    {isSubscribed && isGroupAdmin && <div className="badge badge-success badge-md" >{t('subscribed_badge')}</div>}
                  </div>
                </td>
                <td>
                  <div className="flex flex-row items-end justify-end gap-2 py-2">
                    {isGroupOwner && !isOwner && isAdmin && (
                      <button
                        onClick={function() {
                          handleRemoveAdmin(
                            groupId,
                            uid);
                        }}
                        className={'btn-outline btn-secondary btn-xs btn'}
                      >
                        <GroupAdminIcon className="fill-rose-400" />
                      </button>
                    )}
                    {isGroupOwner && !isOwner && !isAdmin && (
                      <button
                        onClick={function() {
                          handleMakeAdmin(
                            groupId,
                            uid);
                        }}
                        className={'btn-outline btn-secondary btn-xs btn flex flex-col'}
                      >
                        <GroupAdminIcon className="fill-emerald-400" />
                      </button>
                    )}
                    {isGroupAdmin && !isAdmin && (
                      <button
                        onClick={function() {
                          handleRemoveFromGroup(
                            groupId,
                            uid,
                          );
                        }}
                        className={'btn-outline btn-secondary btn-xs btn'}
                      >
                        <RemoveFromGroupIcon className="fill-rose-400" />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ConfirmAdmin />
      <ConfirmRemoveAdmin />
      <ConfirmRemoveFromGroup />
    </div>
  );
}

function InvitedTab({
  invited,
  groupId,
  t,
}) {
  return (
    <div className="flex w-full flex-col items-center">
      <Link to={`/groups/${groupId}/addMembers`} className="btn btn-primary my-4">
        {t('add_members')}
      </Link>
      <table className="mt-4 w-full border-collapse rounded-sm">
        <thead>
          <tr>
            <th>
              {t('invited_count', {count: invited.length})}
            </th>
          </tr>
        </thead>
        <tbody>
          {invited.map((uid) => {
            return (
              <tr key={uid} className="fade-in-slow">
                <td className="p-2">
                  <div className="flex items-center gap-2">
                    <LinkedUserDisplay key={uid} uid={uid} />
                  </div>
                </td>
                <td>
                  <div className="flex flex-col items-end justify-center gap-2 py-2">
                    <button
                      onClick={function() {
                        removeMemberInviteFromGroup({
                          groupId,
                          userId: uid,
                        });
                      }}
                      className={'btn-secondary btn-xs btn'}
                    >
                      {t('remove_invite')}
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function PremiumMembersTab({
  members,
  groupId,
  groupName,
  premiumMembers,
  premiumMembersLimit,
  t,
}) {
  const [loading, setLoading] = useState(false);

  const notify = useNotifications();

  const [ConfirmMakePremium, confirmMakePremium, setMakePremiumContext] = useConfirm(
    'app.confirm_title',
    'groups.premium_promote_confirm',
    'primary',
    'secondary',
  );

  const [ConfirmRemovePremium, confirmRemovePremium, setRemovePremiumContext] = useConfirm(
    'app.confirm_title',
    'groups.premium_demote_confirm',
    'error',
    'secondary',
  );

  const handleMakePremium = async (groupId, uid) => {
    setMakePremiumContext({ uid });
    const ans = await confirmMakePremium();
    if (!ans) return;

    try {
      if (loading) return;

      setLoading(true);
      await makeMemberPremium({
        groupId,
        groupName,
        userId: uid,
      });
      setLoading(false);
    } catch (error) {
      notify(
        {
          text: t('make_premium_error', {error: error.message}),
          type: 'failure',
        },
      );
      setLoading(false);
    }
  };

  const handleRemovePremium = async (groupId, uid) => {
    setRemovePremiumContext({ uid });
    const ans = await confirmRemovePremium();
    if (!ans) return;

    try {
      if (loading) return;

      setLoading(true);
      await removePremiumFromMember({
        groupId,
        userId: uid,
      });
      setLoading(false);
    } catch (error) {
      notify(
        {
          text: t('remove_premium_error', {error: error.message}),
          type: 'failure',
        },
      );
      setLoading(false);
    }
  };

  const extraPremiumUsers = premiumMembers.filter((m) => !members.includes(m.uid)).map((m) => m.uid);
  const premiumMembersLimitReached = premiumMembers.length >= premiumMembersLimit;

  return (
    <div className="flex w-full flex-col items-center">
      <Alert
        text={t('premium_members_info')}
      />
      <table className="mt-4 w-full border-collapse rounded-sm">
        <thead>
          <tr>
            <th colSpan="3">
              {t('premium_seats_used_header', {used: premiumMembers.length, limit: premiumMembersLimit})}
            </th>
          </tr>
        </thead>
        <tbody>
          {extraPremiumUsers.concat(members).map((uid) => {
            const premiumMemberData = premiumMembers.find((m) => m.uid === uid);
            const isPremiumMember = !!premiumMemberData;
            const tooRecentToRemove = isPremiumMember && !checkIf30DaysAgo(premiumMemberData.addedTimestamp.toDate(), new Date());

            const noLongerMember = extraPremiumUsers.includes(uid);
            return (
              <tr key={uid} className="fade-in-slow">
                <td className="p-2">
                  <div className={`flex items-center gap-2${noLongerMember ? ' opacity-50' : ''}`}>
                    <UserDisplay key={uid} uid={uid} />
                  </div>
                </td>
                <td>
                  <div className="flex flex-col gap-1 items-center justify-center">
                    {isPremiumMember && <div className="badge badge-success badge-md" >{t('premium_badge')}</div>}
                    {tooRecentToRemove && <div className="info" >{t('date_added_info', {timestamp: premiumMemberData.addedTimestamp.toDate().toDateString()})}</div>}
                  </div>
                </td>
                <td>
                  <div className="flex flex-col items-end justify-center gap-2 py-2">
                    {isPremiumMember ? (
                      <button
                        disabled={loading || tooRecentToRemove}
                        onClick={async function() {
                          handleRemovePremium(groupId, uid);
                        }}
                        className={'btn-secondary btn-outline btn-xs btn'}
                      >
                        <PremiumMemberIcon className="fill-rose-400" />
                      </button>
                    ) : (
                      <button
                        disabled={loading || premiumMembersLimitReached}
                        onClick={async function() {
                          handleMakePremium(groupId, uid);
                        }}
                        className={'btn-secondary btn-outline btn-xs btn'}
                      >
                        <PremiumMemberIcon className="fill-emerald-400" />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ConfirmMakePremium />
      <ConfirmRemovePremium />
    </div>
  );
}

function Tabulars({
  events,
  members,
  invited,
  groupId,
  groupName,
  groupFlag,
  isGroupOwner,
  isGroupAdmin,
  admins,
  owner,
  isPremiumGroup,
  premiumMembers,
  premiumMembersLimit,
  premiumFeatures,
  groupHasCoaching,
  compactEventView,
  isPayToJoinGroup,
  t,
}) {
  const eventInvites = useAppStateStore((state) => state.eventInvites);
  const { currentUser } = useAuth();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? 'events';
  const navigate = useNavigate();

  const numberOfEventInvites = eventInvites.filter((i) => i.invitee === currentUser.uid && i.groupId === groupId).length;

  const shouldShowPremiumMembers = isPremiumGroup && premiumMembersLimit > 0 && isGroupAdmin;
  const groupHasPbnImport = premiumFeatures?.includes('pbnImport');
  const premiumProduct = premiumFeatures?.includes('product');

  const handeTabChange = (tabId) => {
    navigate(`/groups/${groupId}?tab=${tabId}`, { replace: true });
  }

  return (
    <div className="w-full md:w-4/5 mt-4">
      <Tabs>
        <Tab
          key={'events'}
          label={
            <div className="indicator">
              {
                numberOfEventInvites > 0 && <span className="indicator-item indicator-start badge badge-sm badge-error">{numberOfEventInvites}</span>
              }
              {t('tab_events')}
            </div>
          }
          value={'events'}
          active={tab === 'events'}
          onChange={handeTabChange}
        />
        <Tab
          key={'members'}
          label={t('tab_members')}
          value={'members'}
          active={tab === 'members'}
          onChange={handeTabChange}
        />
        {isGroupAdmin && !premiumProduct && (
          <Tab
            key={'invited'}
            label={t('tab_invited')}
            value={'invited'}
            active={tab === 'invited'}
            onChange={handeTabChange}
          />
        )}
        {shouldShowPremiumMembers && (
          <Tab
            key={'premiumMembers'}
            label={t('tab_premium_members')}
            value={'premium'}
            active={tab === 'premium'}
            onChange={handeTabChange}
          />
        )}
        {groupHasCoaching && isGroupAdmin && (
          <Tab
            key={'stats'}
            label={t('tab_stats')}
            value={'stats'}
            active={tab === 'stats'}
            onChange={handeTabChange}
          />
        )}
      </Tabs>
      <TabPanel key={'eventsPanel'} value={tab} index={'events'}>
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <EventsTab
            events={events}
            groupId={groupId}
            isGroupAdmin={isGroupAdmin}
            groupFlag={groupFlag}
            compactView={compactEventView}
            groupHasPbnImport={groupHasPbnImport}
            t={t}
          />
        </div>
      </TabPanel>
      <TabPanel key={'membersPanel'} value={tab} index={'members'}>
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MembersTab
            members={members}
            groupId={groupId}
            isGroupAdmin={isGroupAdmin}
            admins={admins}
            owner={owner}
            isGroupOwner={isGroupOwner}
            premiumMembers={premiumMembers}
            isPayToJoinGroup={isPayToJoinGroup}
            t={t}
          />
        </div>
      </TabPanel>
      {isGroupAdmin && !premiumProduct && (
        <TabPanel key={'invitedPanel'} value={tab} index={'invited'}>
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <InvitedTab
              invited={invited}
              groupId={groupId}
              t={t}
            />
          </div>
        </TabPanel>
      )}
      {shouldShowPremiumMembers && (
        <TabPanel key={'premiumMembersPanel'} value={tab} index={'premium'}>
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PremiumMembersTab
              members={members}
              groupId={groupId}
              groupName={groupName}
              premiumMembers={premiumMembers}
              premiumMembersLimit={premiumMembersLimit}
              t={t}
            />
          </div>
        </TabPanel>
      )}
      {groupHasCoaching && isGroupAdmin && (
        <TabPanel key={'stats'} value={tab} index={'stats'}>
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <GroupStats
              groupId={groupId}
              members={members}
              t={t}
            />
          </div>
        </TabPanel>
      )}
    </div>
  );
}

export default function Group() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.group' });
  const { groupId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUser } = useAuth();
  const groups = useAppStateStore((state) => state.groups);
  const groupsExtraData = useAppStateStore((state) => state.groupsExtraData);
  const groupsMessages = useAppStateStore((state) => state.groupsMessages);
  const isNative = useAppStateStore((state) => state.isNative);
  const [groupSessions, setGroupSessions] = useState();

  const showChat = useAppStateStore((state) => state.showChat);
  const chatOpen = useAppStateStore((state) => state.chatOpen);

  const group = (groups || []).find(function(group) {
    return group.id === groupId;
  });

  useGoToPublicGroupPage(groupId);

  useGroupsNavbarItems();


  useEffect(() => {
    if (searchParams && searchParams.get('chatOpen') && !chatOpen) {
      showChat();
    }
  }, [chatOpen, searchParams, showChat]);

  useEffect(function() {
    setSearchParams(function(oldParams) {
      oldParams.delete('chatOpen');
      return oldParams;
    }, { replace: true });
  }, [setSearchParams]);

  useEffect(function() {
    if (groupId) {
      try {
        return getGroupSessionsObservable({
          groupId,
          callback: setGroupSessions,
        });
      } catch (e) {
        log(e)
      }
    }
  }, [groupId]);

  function handleLastReadUpdate() {
    setLastRead(groupId);
  }

  if (!group || !groupSessions) {
    return null;
  }

  const members = group.members;
  const invited = group.invited;

  const isGroupAdmin = group.admins.includes(currentUser.uid);
  const isGroupOwner = group.owner === currentUser.uid;

  const messages = groupsMessages[groupId] || [];
  const numberOfUnreadMessages = getNumberOfUnreadGroupMessages({ groupId, groupsMessages, groupsExtraData, userId: currentUser.uid })

  const groupHasCoaching = (group.premiumFeatures || []).includes('coaching');
  const premiumFeatures = group.premiumFeatures || [];

  const isPayToJoinGroup = group.premium && premiumFeatures && premiumFeatures.includes('product');

  return (
    <div className="page pb-36">
      <GroupHeader
        name={group.name}
        description={group.description}
        groupId={groupId}
        premium={group.premium}
        withSettings
        groupFlag={group.groupFlag}
        logo={group.logo}
      />
      <Tabulars
        members={members}
        events={groupSessions}
        isGroupOwner={isGroupOwner}
        isGroupAdmin={isGroupAdmin}
        groupId={groupId}
        groupName={group.name}
        groupFlag={group.groupFlag}
        owner={group.owner}
        admins={group.admins}
        invited={invited}
        isPremiumGroup={group.premium}
        premiumMembers={group.premiumMembers || []}
        premiumMembersLimit={group.premiumMembersLimit}
        groupHasCoaching={groupHasCoaching}
        premiumFeatures={premiumFeatures}
        compactEventView={group.compactEventView}
        isPayToJoinGroup={isPayToJoinGroup}
        t={t}
      />
      <ChatDrawer
        header={<span>{group.name}</span>}
        userId={currentUser.uid}
        messages={messages}
        setRead={handleLastReadUpdate}
        unreadMessagesCount={numberOfUnreadMessages}
        totalCount={messages.length}
        sendMessage={(message) => sendGroupChatMessage(group.id, group.name, message)}
        isNative={isNative}
        bottomNavigationVisible={true}
      />
    </div>
  );
}
