import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getGroup, joinGroup } from '../../firebase/groups.js';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getAllProducts, startCheckoutSessionPremium } from 'cuebids-firebase/store';
import { useAppStateStore } from '../../appStateStore.jsx';
import { logoMap } from './logoMap.js';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

export default function JoinGroupByLink() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.join_group' });
  const { groupId } = useParams()

  const [products, setProducts] = useState([]);
  const [hasLoadedProducts, setHasLoadedProducts] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const notify = useNotifications();
  const status = searchParams.get('status');
  const isNative = useAppStateStore((state) => state.isNative);

  const { data: group } = useQuery({
    queryKey: ['group', groupId],
    queryFn: async () => await getGroup(groupId)
  });

  const handleBuyProduct = async (price) => {
    setLoading(true);
    await startCheckoutSessionPremium(
      price,
      group.id,
      () => setLoading(false),
    );
  };

  useEffect( () => {
    if (status === 'failed') {
      notify(
        {
          type: 'error',
          text: t('error'),
        },
      );
      return;
    }
    if (status === 'welcome') {
      notify(
        {
          type: 'success',
          text: t('success'),
        },
      );
    }

  }, [notify, status]);

  useEffect(() => {
    async function getData() {
      const data = await getAllProducts();
      setProducts(data);
      setHasLoadedProducts(true);
    }

    getData();
  }, [setProducts]);

  const joinGroupLocal = async () => {
    await joinGroup(groupId)
    navigate(`/groups/${groupId}`);
  }

  if(!group) {
    return null;
  }

  const groupImage = group.logo && logoMap[group.logo] ? logoMap[group.logo] : null;

  if(!group.openGroup) {
    return <div className="page pb-24">
      <div className="flex flex-col items-center justify-center">
        {
          groupImage && <img src={groupImage} alt={group.name} className="mb-4 w-72 bg-white p-4 rounded-xl bg-opacity-75" />
        }
        <h1 className="text-3xl font-bold mb-4">{t('title')}</h1>
        <p className="text-xl mb-4">{t('private_info')}</p>
      </div>
    </div>
  }

  if(group.premium && group.premiumFeatures && group.premiumFeatures?.includes('product')) {
    if(!hasLoadedProducts) {
      return <div className="page pb-24">
        <div className="flex flex-col items-center justify-center">
          {
            groupImage && <img src={groupImage} alt={group.name} className="mb-4 w-72 bg-white p-4 rounded-xl bg-opacity-75" />
          }
          <h1 className="text-3xl font-bold mb-4">{t('title')}</h1>
          <p className="text-xl mb-4">{t('loading')}</p>
        </div>
      </div>
    }

    const product = products.find(product => product.metadata?.premiumGroupId === group.id);
    const price = product.prices[0]
    return <div className="page pb-24">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-3xl font-bold mb-4">{t('title')}</h1>
        {
          groupImage && <img src={groupImage} alt={group.name} className="mb-4 w-72 bg-white p-4 rounded-xl bg-opacity-75" />
        }
        <p className="text-xl mb-4">{t('membership_required')}</p>
        <h2 className={'text-xl text-sky-400 font-bold m-4'}>
          {product.name}
        </h2>
        <p className="text-xl mb-4">
          ${price.unit_amount.toFixed(2) / 100} / {price.interval}
        </p>
        {
          isNative ? <p className="text-xl mb-4">{t('native_purchase_info_pre_styled')}<a
            href={`https://www.cuebids.com/joinGroup/${groupId}:ext`}>{t('native_purchase_info_styled')}</a>{t('native_purchase_info_post_styled')}</p> :
            <button className="btn btn-primary"
              disabled={loading}
              onClick={() => handleBuyProduct(price)}>{loading ? t('purchase_loading') : t('purchase')}</button>
        }
      </div>
    </div>
  }

  return (
    <div className="page pb-24">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-3xl font-bold mb-4">{t('title')}</h1>
        {
          groupImage && <img src={groupImage} alt={group.name} className="mb-4 w-72 bg-white p-4 rounded-xl bg-opacity-75" />
        }
        <p className="text-xl mb-4">{t('join_info_pre_styled')}<span className="font-bold">{group.name}</span>{t('join_info_post_styled')}</p>
        <button className="btn btn-primary" onClick={joinGroupLocal}>{t('join_group')}</button>
      </div>
    </div>
  )
}
