export function timestampToString(timestamp) {
  const time = new Date(timestamp);
  return time.toDateString();
}

export const isToday = (someDate) => {
  if (!someDate) return false;
  const today = new Date();
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear();
};

export function checkIf30DaysAgo(date, nowDate) {
  nowDate.setDate(nowDate.getDate() - 30);
  return date < nowDate;
}
