import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserDisplay from '../users/userDisplay';
import { acceptEventInvite, deleteInvite } from '../../firebase/events';
import {
  canPairAffordToPlay,
  getCharges, getRemainingTicketsWarningTextSimple,
  getUserRemainingTickets,
  PAYMENT_STRATEGY_SPLIT,
} from '../../util/tickets';
import Price from '../price/price';
import PriceDialog from '../priceDialog/priceDialog';
import { getUserHighestSubscription } from '../../firebase/subscriptions';
import { getUser } from '../../firebase/userApi';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useActiveSubs } from '../../util/hooks';
import useNotifications from '../notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

function InviteFromMe({ invite, t }) {
  const [loading, setLoading] = useState(false);

  return (
    <div className="mt-2 flex items-center w-full max-w-[420px] rounded-xl border bg-info border-info bg-opacity-10">
      <div className="w-full p-2 flex items-center justify-between">
        <UserDisplay uid={invite.invitee} />
        <div className="flex items-center">
          <button
            disabled={loading}
            onClick={async function () {
              if (loading) return;
              setLoading(true);
              await deleteInvite(invite.id);
              setLoading(false);
            }}
            className="btn btn-error btn-sm ml-2"
          >
            {t('components.invite.remove')}
          </button>
        </div>
      </div>
    </div>
  );
}

function InviteToMe({ invite, t }) {
  const [loading, setLoading] = useState(false);
  const user = useAppStateStore((state) => state.user);
  const notify = useNotifications();
  const navigate = useNavigate();

  const [partner, setPartner] = useState(null);
  const [partnerSub, setPartnerSub] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  useEffect(
    function () {
      if (invite.inviter && invite.price) {
        // Slightly ugly to nest, but now we know that when partner is loaded we have loaded both.
        getUserHighestSubscription(invite.inviter).then(function (ps) {
          setPartnerSub(ps);
          getUser({ userId: invite.inviter }).then(function (p) {
            setPartner(p);
          });
        });
      }
    },
    [invite.inviter, invite.price]
  );

  const handleClose = () => {
    setConfirmationModalOpen(false);
  };

  const highestSub = useActiveSubs()[0];

  const myRemainingTickets = getUserRemainingTickets(
    user.ticketsSpent ?? 0,
    highestSub,
    user.extraTickets ?? 0
  );

  const partnerRemainingTickets = partner
    ? getUserRemainingTickets(
      partner.ticketsSpent ?? 0,
      partnerSub,
      partner.extraTickets ?? 0
    )
    : 0;

  const { myCharge, partnerCharge } = getCharges({
    myRemainingTickets,
    partnerRemainingTickets,
    price: invite.price,
    paymentStrategy: invite.paymentStrategy || PAYMENT_STRATEGY_SPLIT,
  });

  const acceptInvite = () => {
    if (myCharge !== 0 || partnerCharge !== 0) {
      setConfirmationModalOpen(true);
    } else {
      void acceptInviteConfirmed();
    }
  };

  const acceptInviteConfirmed = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const sessionId = await acceptEventInvite(invite.id);
      if (sessionId) {
        navigate(`/session/${sessionId}`, { replace: true });
      }
    } catch (e) {
      notify(
        {
          type: 'failure',
          text: t('components.invite.join_event_error', {error: e.message}),
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mt-2 p-2 w-full max-w-[420px] rounded-xl border bg-info border-info bg-opacity-10">
        <div className="w-full flex items-center justify-between">
          <UserDisplay uid={invite.inviter} />
          <div className="flex flex-col items-start gap-1">
            {!!invite.price && <Price price={invite.price} />}
            <div className="flex items-center">
              <button
                disabled={
                  loading ||
                  (invite.price &&
                    (!partner ||
                      !canPairAffordToPlay({
                        myRemainingTickets,
                        partnerRemainingTickets,
                        price: invite.price,
                        paymentStrategy: PAYMENT_STRATEGY_SPLIT,
                      })))
                }
                onClick={acceptInvite}
                className="btn btn-primary btn-sm"
              >
                {t('components.invite.accept')}
              </button>
              <button
                disabled={loading}
                onClick={async function () {
                  if (loading) return;
                  setLoading(true);
                  await deleteInvite(invite.id);
                  setLoading(false);
                }}
                className="btn btn-error btn-sm ml-2"
              >
                {t('components.invite.reject')}
              </button>
            </div>
          </div>
        </div>
        <div className="info mt-1">
          {partner
            ? getRemainingTicketsWarningTextSimple({
              myRemainingTickets,
              partnerRemainingTickets,
              price: invite.price,
              paymentStrategy:
                  invite.paymentStrategy || PAYMENT_STRATEGY_SPLIT,
              t,
            })
            : ''}
        </div>
      </div>
      <PriceDialog
        paymentStrategy={invite.paymentStrategy}
        open={confirmationModalOpen}
        onClose={handleClose}
        title={t('components.invite.price_dialog_title')}
        myRemainingTickets={myRemainingTickets}
        partnerRemainingTickets={partnerRemainingTickets}
        myCharge={myCharge}
        partnerCharge={partnerCharge}
        actions={[
          {
            component: (
              <div
                disabled={loading}
                role="button"
                key="join-daily"
                className="btn-primary btn"
                onClick={acceptInviteConfirmed}
              >
                {t('components.invite.price_dialog_confirm')}
              </div>
            ),
          },
        ]}
      />
    </>
  );
}

export default function Invite({ invite, currentUserId }) {
  const { t } = useTranslation();
  if (invite.inviter === currentUserId) {
    return <InviteFromMe invite={invite} t={t} />;
  }

  return <InviteToMe invite={invite} t={t} />;
}
