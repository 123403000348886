import { useState } from "react";
import { updateGroupSession, getGroupSession } from "../../firebase/groups";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "../../components/textField/TextField";
import GroupHeader from "../../components/groupHeader/GroupHeader";
import { useAuth, useGoToPublicGroupPage } from "../../util/hooks";
import { useAppStateStore } from "../../appStateStore.jsx";
import { LowScenarioStock } from "../../firebase/errors.js";
import { NoRobotIcon } from "../../components/icons/noRobotIcon.jsx";
import { RobotIcon } from "../../components/icons/robotIcon.jsx";
import { AdvancedRobotIcon } from "../../components/icons/advancedRobotIcon.jsx";
import useNotifications from "../../components/notifications/useNotifications.jsx";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

const settingMap = {
  0: <NoRobotIcon className={"fill-gray-500 opacity-40"} />,
  1: <RobotIcon className={"fill-sky-400"} />,
  2: <AdvancedRobotIcon className={"fill-rose-400"} />,
};

export default function EditGroupSession() {
  const { t } = useTranslation("translation", {
    keyPrefix: "groups.create_group_session",
  });
  const { groupId } = useParams();
  const { groupSessionId } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const groups = useAppStateStore((state) => state.groups);
  const notify = useNotifications();

  const { data: groupSession } = useQuery({
    queryKey: ["groupSessions", groupSessionId],
    queryFn: async () => await getGroupSession(groupSessionId),
  });

  const [eventName, setEventName] = useState(groupSession?.name || "");
  const [eventDescription, setEventDescription] = useState(
    groupSession?.description || ""
  );
  const [showLeaderboard, setShowLeaderboard] = useState(
    groupSession?.showLeaderboard || false
  );

  useEffect(() => {
    setEventName(groupSession?.name || "");
    setEventDescription(groupSession?.description || "");
    setShowLeaderboard(groupSession?.showLeaderboard || false);
  }, [groupSession]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useGoToPublicGroupPage(groupId);

  const group = (groups || []).find(function (group) {
    return group.id === groupId;
  });

  if (!group || !groupSession) {
    return null;
  }

  const isGroupAdmin = group.admins.includes(currentUser.uid);

  async function handleUpdateEvent() {
    if (!isGroupAdmin || loading) return;

    if (!eventName) {
      setError(t("missing_name_error"));
      return;
    }

    setError(null);
    setLoading(true);
    try {
      await updateGroupSession({
        groupSessionId,
        updateObject: {
          name: eventName,
          description: eventDescription,
          showLeaderboard,
        },
      });
      setLoading(false);
      if (groupSessionId) {
        navigate(`/groups/${groupId}/eventLeaderboard/${groupSessionId}`);
      }
    } catch (e) {
      if (e instanceof LowScenarioStock) {
        notify({
          type: "failure",
          text: t("scenario_stock_error"),
          url: "/manageDealTypes",
        });
      } else {
        notify({
          text: t("create_event_error", { error: e.message }),
          type: "failure",
        });
      }
      setLoading(false);
    }
  }

  function handleUpdateShowLeaderboard(e) {
    setShowLeaderboard(e.target.checked);
  }

  return (
    <div className="fade-in">
      <div className="page pb-24">
        <GroupHeader
          name={group.name}
          links
          groupId={groupId}
          premium={group.premium}
          groupFlag={group.groupFlag}
          logo={group.logo}
        />
        <div className="flex flex-col items-start">
          <div className="flex items-center gap-2">
            <label className="label">
              <span className="label-text font-bold">{t("boards_label")}:</span>
            </label>
            {groupSession.numberOfDeals}
          </div>
          <div className="flex items-center gap-1">
            <label className="label">
              <span className="label-text font-bold">{t("robots_label")}:</span>
            </label>
            {settingMap[groupSession.compete]}
          </div>
          {
            groupSession.coach && (
              <>
              <div className="flex items-center gap-1">
                <label className="label">
                  <span className="label-text font-bold">{t("coach_robot_label")}:</span>
                </label>
                {groupSession.coach.name}
              </div>
              <div className="flex items-center gap-1">
              <label className="label">
                <span className="label-text font-bold">{t("coach_direction_label")}:</span>
              </label>
              {groupSession.coach.direction === 'N' ? t('north') : t('south')}
            </div>
            </>
            )
          }
        </div>

        <div className="w-80 mt-4">
          <TextField
            label={t("name_label")}
            value={eventName}
            onChange={(v) => {
              setError(null);
              setEventName(v);
            }}
            placeholder={t("name_placeholder")}
          />
        </div>
        <div className="w-80 mt-4">
          <TextField
            label={t("description_label")}
            value={eventDescription}
            onChange={setEventDescription}
            placeholder={t("description_placeholder")}
          />
        </div>
        <div className="w-80 mt-4">
          <div className="form-control w-full">
            <label className="label w-full cursor-pointer p-0">
              {t("leaderboard_label")}
              <input
                type="checkbox"
                className="toggle-primary toggle"
                checked={showLeaderboard}
                onChange={handleUpdateShowLeaderboard}
              />
            </label>
          </div>
        </div>

        <button
          className="btn-primary btn pr-8 relative w-64 my-4"
          disabled={!isGroupAdmin || loading}
          onClick={handleUpdateEvent}
        >
          {t("edit_submit")}
        </button>
        {error && <span className="text-error">{error}</span>}
      </div>
    </div>
  );
}
