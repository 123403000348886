import Spade from '../../images/suits/Spade';
import Club from '../../images/suits/Club';
import Diamond from '../../images/suits/Diamond';
import Heart from '../../images/suits/Heart';

// #region
import C2 from './faces/2C.svg';
import C3 from './faces/3C.svg';
import C4 from './faces/4C.svg';
import C5 from './faces/5C.svg';
import C6 from './faces/6C.svg';
import C7 from './faces/7C.svg';
import C8 from './faces/8C.svg';
import C9 from './faces/9C.svg';
import CT from './faces/TC.svg';
import CJ from './faces/JC.svg';
import CQ from './faces/QC.svg';
import CK from './faces/KC.svg';
import CA from './faces/AC.svg';

import D2 from './faces/2D.svg';
import D3 from './faces/3D.svg';
import D4 from './faces/4D.svg';
import D5 from './faces/5D.svg';
import D6 from './faces/6D.svg';
import D7 from './faces/7D.svg';
import D8 from './faces/8D.svg';
import D9 from './faces/9D.svg';
import DT from './faces/TD.svg';
import DJ from './faces/JD.svg';
import DQ from './faces/QD.svg';
import DK from './faces/KD.svg';
import DA from './faces/DA.svg';

import H2 from './faces/2H.svg';
import H3 from './faces/3H.svg';
import H4 from './faces/4H.svg';
import H5 from './faces/5H.svg';
import H6 from './faces/6H.svg';
import H7 from './faces/7H.svg';
import H8 from './faces/8H.svg';
import H9 from './faces/9H.svg';
import HT from './faces/TH.svg';
import HJ from './faces/JH.svg';
import HQ from './faces/QH.svg';
import HK from './faces/KH.svg';
import HA from './faces/AH.svg';

import S2 from './faces/2S.svg';
import S3 from './faces/3S.svg';
import S4 from './faces/4S.svg';
import S5 from './faces/5S.svg';
import S6 from './faces/6S.svg';
import S7 from './faces/7S.svg';
import S8 from './faces/8S.svg';
import S9 from './faces/9S.svg';
import ST from './faces/TS.svg';
import SJ from './faces/JS.svg';
import SQ from './faces/QS.svg';
import SK from './faces/KS.svg';
import SA from './faces/AS.svg';

// #endregion

import './hand.css';
import { useDisplay } from '../../util/hooks.jsx';
import { useTranslation } from 'react-i18next';
import { replaceXInHand } from 'cuebids-hand-util';

const suits = {
  0: 'S',
  1: 'H',
  2: 'D',
  3: 'C',
};

const cardMap = {
  SA: SA,
  SK: SK,
  SQ: SQ,
  SJ: SJ,
  ST: ST,
  S9: S9,
  S8: S8,
  S7: S7,
  S6: S6,
  S5: S5,
  S4: S4,
  S3: S3,
  S2: S2,

  HA: HA,
  HK: HK,
  HQ: HQ,
  HJ: HJ,
  HT: HT,
  H9: H9,
  H8: H8,
  H7: H7,
  H6: H6,
  H5: H5,
  H4: H4,
  H3: H3,
  H2: H2,

  DA: DA,
  DK: DK,
  DQ: DQ,
  DJ: DJ,
  DT: DT,
  D9: D9,
  D8: D8,
  D7: D7,
  D6: D6,
  D5: D5,
  D4: D4,
  D3: D3,
  D2: D2,

  CA: CA,
  CK: CK,
  CQ: CQ,
  CJ: CJ,
  CT: CT,
  C9: C9,
  C8: C8,
  C7: C7,
  C6: C6,
  C5: C5,
  C4: C4,
  C3: C3,
  C2: C2,
};

function getHand(apiHand) {
  return apiHand.split('.').reduce(function (hand, suit, index) {
    for (let i = 0; i < suit.length; i++) {
      hand.push(suits[index] + suit.charAt(i));
    }
    return hand;
  }, []);
}

function groupHandIntoSuits(hand) {
  const output = {
    S: [],
    H: [],
    D: [],
    C: [],
  };
  for (let i = 0; i < hand.length; i++) {
    const card = hand[i];
    const suit = card.substring(0, 1);
    const value = card.substring(1);

    output[suit].push(value);
  }
  return output;
}

const hpMap = {
  A: 4,
  K: 3,
  Q: 2,
  J: 1,
};

const directionMap = {
  N: 'bridge.directions.N',
  E: 'bridge.directions.E',
  S: 'bridge.directions.S',
  W: 'bridge.directions.W',
};

function getHpCount(hand) {
  if (!hand) return;
  return hand.split('').reduce((a, v) => {
    return a + (hpMap[v] ?? 0);
  }, 0);
}

function HandDiagram({
  hand,
  size,
  direction,
  showHcp,
  hcp,
  lineHeightOverride,
  allowAnimation = true
}) {
  const suits = groupHandIntoSuits(getHand(hand));
  const { t } = useTranslation();

  const textSizeMap = {
    sm: 'text-xl font-bold leading-7',
    md: 'text-2xl font-bold leading-7',
    lg: `text-3xl font-bold ${lineHeightOverride ? 'leading-7' : ''}`,
    xl: `text-4xl font-bold ${lineHeightOverride ? 'leading-8' : ''}`,
  };

  const textSizeDown = {
    sm: 'text-base',
    md: 'text-xl',
    lg: 'text-2xl',
    xl: 'text-3xl',
  };

  return (
    <div className={`${allowAnimation ? 'fade-in' : ''} flex flex-col items-center`}>
      {showHcp &&
        `${t(directionMap[direction])} ${hcp.toString()} ${t(
          'bridge.hand.HCP'
        )}`}
      <div className={'flex justify-center ' + textSizeMap[size]}>
        <div className="flex flex-col">
          <div className="spades flex flex-row items-center">
            <div className="mr-0.5 opacity-50 w-5">
              <Spade width={18} />
            </div>
            <span className={suits.S.length > 5 ? textSizeDown[size] : ''}>{suits.S}&nbsp;</span>
          </div>
          <div className="hearts flex flex-row items-center">
            <div className="mr-0.5 opacity-50 w-5">
              <Heart width={18} />
            </div>
            <span className={suits.H.length > 5 ? textSizeDown[size] : ''}>{suits.H}&nbsp;</span>
          </div>
          <div className="diamonds flex flex-row items-center">
            <div className="mr-0.5 opacity-50 w-5">
              <Diamond width={18} />
            </div>
            <span className={suits.D.length > 5 ? textSizeDown[size] : ''}>{suits.D}&nbsp;</span>
          </div>
          <div className="clubs flex flex-row items-center">
            <div className="mr-0.5 opacity-50 w-5">
              <Club width={18} />
            </div>
            <span className={suits.C.length > 5 ? textSizeDown[size] : ''}>{suits.C}&nbsp;</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function Card({ i, card, size, spacing, variant, compact = false }) {
  const widthSizeMap = {
    sm: 12,
    md: 14,
    lg: 16,
    xl: 18,
  };

  const suitMap = {
    S: <Spade width={widthSizeMap[size]} dark />,
    H: <Heart width={widthSizeMap[size]} dark />,
    D: <Diamond width={widthSizeMap[size]} dark />,
    C: <Club width={widthSizeMap[size]} dark />,
  };

  const textSizeMap = {
    sm: 'text-xl',
    md: 'text-2xl',
    lg: 'text-3xl',
    xl: 'text-4xl',
  };

  const suitClassMap = {
    S: 'spades-dark font-bold',
    H: 'hearts-dark font-bold',
    D: 'diamonds-dark font-bold',
    C: 'clubs-dark font-bold',
  };

  const sizeMap = {
    sm: 'w-24' /* 96px */,
    md: 'w-32' /* 128px */,
    lg: 'w-36' /* 144px */,
    xl: 'w-44' /* 96px */,
  };

  const marginMap = {
    sm: 'ml-1.5' /* 96px */,
    md: 'ml-1' /* 128px */,
    lg: 'ml-[3px]' /* 144px */,
    xl: 'ml-[1px]' /* 96px */,
  };

  const sizingClass =
    variant === 'modern'
      ? 'absolute flex h-36 w-24 flex-col items-start justify-start rounded-sm bg-slate-100 border'
      : 'absolute flex h-36 w-24 flex-col items-start justify-start rounded-sm p-0';

  return (
    <div
      className={sizingClass}
      style={{
        left: i * spacing,
      }}
    >
      {variant === 'classic' ? (
        <div className={'relative ' + sizeMap[size]}>
          <img src={cardMap[card]} />
        </div>
      ) : (
        <div className={`${marginMap[size]} flex select-none flex-col items-center justify-center`}>
          <span className={suitClassMap[card[0]] + ' ' + textSizeMap[size]}>
            {card[1]}
          </span>
          <div className="flex h-4 select-none items-center justify-center">
            <div>{suitMap[card[0]]}</div>
          </div>
        </div>
      )}
    </div>
  );
}

const scaleMap = {
  sm: '',
  md: 'scaled-md',
  lg: 'scaled-lg',
}

export default function Hand({
  scale = 'sm',
  size = 'sm',
  variant = 'modern',
  hand,
  direction,
  showHcp = true,
  compact,
  order = 'default',
  keyOverride,
  handLength = 13,
  allowAnimation = true
}) {
  const { width, height } = useDisplay() ?? {};
  const { t } = useTranslation();

  if (hand.includes('x')) {
    hand = replaceXInHand(hand);
  }

  const handPart = getHand(hand);
  const hcp = getHpCount(hand);

  const thin = width < 400;
  const smallDiagram = height < 800;
  const shortCards =
    height < 800 || thin || size !== 'sm' || variant === 'modern';

  if (variant === 'diagram') {
    return (
      <HandDiagram
        hand={hand}
        size={size}
        lineHeightOverride={smallDiagram && ['lg', 'xl'].includes(size)}
        direction={direction}
        showHcp={showHcp}
        hcp={hcp}
        allowAnimation={allowAnimation}
      />
    );
  }

  const suitOrderMap =
    order === 'default'
      ? {
        S: 0,
        H: 1,
        D: 2,
        C: 3,
      }
      : {
        S: 0,
        H: 1,
        D: 3,
        C: 2,
      };

  const symbolSizeToSpacingMap = {
    sm: 25,
    md: 27,
    lg: 31,
    xl: 35,
  };

  const maxSpacing = symbolSizeToSpacingMap[size];

  const handTotalWidth = thin ? width : 400;
  const spacing = thin
    ? Math.min(handTotalWidth / handLength, maxSpacing)
    : Math.min(
      (handTotalWidth - (shortCards ? 67 : 96)) / (handLength - 1),
      maxSpacing
    );

  const handWidth =
    spacing * (thin ? handLength : handLength - 1) +
    (thin ? 0 : shortCards ? 67 : 96);
  const handHeight = shortCards ? 100 : 149;

  return (
    <div
      key={keyOverride ?? hand + variant}
      className={`fade-in relative flex select-none flex-col items-center justify-center ${scaleMap[scale]}`}
    >
      <div className="flex w-full justify-center">
        {showHcp &&
          `${t(directionMap[direction])} ${hcp.toString()} ${t(
            'bridge.hand.HCP'
          )}`}
      </div>
      <ul
        style={{
          width: handWidth,
          height: handHeight,
          position: 'relative',
          borderRadius: 8,
          overflow: 'hidden',
        }}
        className={variant === 'modern' ? 'border-slate border-2' : undefined}
      >
        {handPart
          .sort((a, b) => suitOrderMap[a[0]] - suitOrderMap[b[0]])
          .map((card, i) => {
            return (
              <li key={`${i}-${card}`}>
                <Card
                  i={i}
                  card={card}
                  size={size}
                  spacing={spacing}
                  compact={compact}
                  variant={variant}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
}
