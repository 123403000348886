import React, { useState, useEffect, useRef } from 'react';
import './chat.css';
import { replaceSuitShorthandWithSymbols } from '../../util/symbols';
import UserMessagesDisplay from './userMessagesDisplay';
import { useDisplay, useOutsideClick } from '../../util/hooks';
import CloseButton from '../buttons/closeButton';
import Animated from '../animation/animated';
import { UserDisplaySmall } from '../users/userDisplay';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useTranslation } from 'react-i18next';

function groupMessages(messages) {
  if (!messages.length) return [];
  let currentGroup = [];
  let currentUserId = messages[0].userId;
  const groups = [currentGroup];
  for (const message of messages) {
    if (message.userId !== currentUserId) {
      currentGroup = [message];
      currentUserId = message.userId;
      groups.push(currentGroup);
    } else {
      currentGroup.push(message);
    }
  }

  return groups;
}

function isToday(date) {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
}

function zeroPad(stringOrNumber) {
  const string = '' + stringOrNumber;
  return string.length === 1 ? '0' + string : string;
}

function moreThan15MinutesApart(t1, t2) {
  return t1 - t2 > 15 * 60 * 1000;
}

export function ChatDrawer({
  header,
  usersForHeader,
  usersForHeaderExtraText,
  userId,
  messages,
  setRead,
  sendMessage,
  unreadMessagesCount,
  isNative,
  bottomNavigationVisible = false,
  moveDeal
}) {
  const wrapperRef = useRef(null);
  const [nextMessage, setNextMessage] = useState('');
  const open = useAppStateStore((state) => state.chatOpen);
  const show = useAppStateStore((state) => state.showChat);
  const hide = useAppStateStore((state) => state.hideChat);
  const { t } = useTranslation('translation', { keyPrefix: 'components.chat' });

  const { zoomLevel, width } = useDisplay();

  function close() {
    hide();
  }

  useOutsideClick(wrapperRef, close);

  const lastMessage = messages.slice(-1);

  const bottomClass = bottomNavigationVisible
    ? 'bottom-24'
    : 'bottom-8 md:bottom-0';

  if (!open) {
    return (
      <div
        className={'fixed right-0 md:right-[calc(50%-400px)] ' + bottomClass}
      >
        <div className="chat chat-end">
          <div className="indicator">
            {unreadMessagesCount > 0 && (
              <span className="badge badge-error indicator-item right-2">
                {unreadMessagesCount > 9 ? '9+' : unreadMessagesCount}
              </span>
            )}
            <button
              className="chat-bubble slated btn normal-case max-w-[100px]"
              onClick={show}
            >
              {lastMessage[0]?.message ? (
                <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs">
                  {lastMessage[0]?.message}
                </div>
              ) : (
                <>
                  <svg
                    className="w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                    ></path>
                  </svg>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }

  // TODO: Only works if maximum 4 users in header (and not with 4 and extra text)
  const headerPaddingClass =
    usersForHeader?.length ?? 0 > 2 ? 'pt-[80px]' : 'pt-[56px]';

  const zoomMap = [
    100,
    91,
    83,
    67,
    50
  ]

  const extraWidth = (width - 800) * zoomMap[zoomLevel] / 100;

  const positionClass = extraWidth > 750 ? 'right-[calc(50%-710px)]' : '';


  return (
    <div className="fixed top-0 left-0 z-[9998] h-full w-full bg-black bg-opacity-20">
      {
        <Animated
          key="chatDrawer"
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          exit={{ x: 100 }}
          transition={{ duration: 0.1 }}
          ref={wrapperRef}
          id="chat-drawer"
          sx={`absolute top-0 right-0 h-full w-80 ${positionClass} rounded-l-xl slated ${headerPaddingClass} pb-8 md:pb-0`}
          tabIndex="-1"
          aria-labelledby="drawer-label"
        >
          <div className="absolute top-0 left-0 z-[199] flex flex-col w-full items-center bg-base-100">
            <div>
              <div className="absolute top-0 left-0 ">
                <CloseButton onClose={close}/>
              </div>
              <div className="ml-14 grid grow grid-cols-2 py-4 items-center">
                {header ? (
                  header
                ) : (
                  <>
                    {usersForHeader.map((u) => (
                      <UserDisplaySmall key={u} uid={u}/>
                    ))}
                    {usersForHeaderExtraText && (
                      <div className="info">{usersForHeaderExtraText}</div>
                    )}
                  </>
                )}
              </div>
            </div>
            {
              moveDeal && <div className="flex items-center gap-8 my-2 ">
                <div className="">
                  <button className="" onClick={() => moveDeal(false)}>
                    <kbd className="kbd text-sm">◀︎</kbd>
                  </button>
                </div>

                <div className="">
                  <button className="" onClick={() => moveDeal(true)}>
                    <kbd className="kbd text-sm">▶︎</kbd>
                  </button>
                </div>
              </div>
            }

          </div>
          <Chat
            userId={userId}
            messages={messages}
            setRead={setRead}
            sendMessage={sendMessage}
            nextMessage={nextMessage}
            setNextMessage={setNextMessage}
            isNative={isNative}
            placeholder={t('placeholder')}
          />
        </Animated>
      }
    </div>
  );
}

export default function Chat({
  userId,
  messages,
  setRead,
  sendMessage,
  links = false,
  placeholder = 'Type your message',
  nextMessage,
  setNextMessage,
  isNative,
}) {
  const chatMessagesContainerRef = useRef(null);
  const inputRef = useRef(null);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.chat' });

  const [message, setMessage] = useState(nextMessage || '');
  const [height, setHeight] = useState(48);

  // Want to use setRead in effect, but if dependency, then it triggers on every parent re-render, since parents create new setRead.
  // Store in ref instead, to avoid triggering useEffect, but can still use latest version.
  const setReadRef = useRef(setRead);

  useEffect(
    function () {
      setReadRef.current = setRead;
    },
    [setRead]
  );

  useEffect(
    function () {
      chatMessagesContainerRef.current.scrollTop =
        chatMessagesContainerRef.current.scrollHeight;
      setReadRef?.current?.();
    },
    [messages.length]
  );

  useEffect(() => {
    setNextMessage?.(message);
  }, [message, setNextMessage]);

  function resizeTextArea() {
    inputRef.current.style.height = '48px';
    inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    setHeight(inputRef.current.scrollHeight);
  }

  function handleUpdateMessage(e) {
    const temp = e.target.selectionStart;
    setMessage(replaceSuitShorthandWithSymbols(e.target.value));
    resizeTextArea();
    setTimeout(() => e.target.setSelectionRange(temp, temp), 0);
  }

  const _sendMessage = (e) => {
    e.preventDefault();
    if (message.length < 1) {
      return;
    }
    if (sendMessage(message)) {
      setMessage('');
      inputRef.current.style.height = '48px';
      setHeight(48);
    }
  };

  const handleKeyDown = (event) => {
    if (event.which === 13 && !event.shiftKey && !isNative) {
      _sendMessage(event);
      event.preventDefault();
    }
  };

  const messageGroups = groupMessages(messages);

  return (
    <div className="chat-our">
      <div className="chat-content">
        <div className="chat-messages-container" ref={chatMessagesContainerRef}>
          {messageGroups.map((g, i) => {
            const d = new Date(g[0].timestamp);
            return (
              <React.Fragment key={g[0].id + 'G'}>
                {(i === 0 ||
                  moreThan15MinutesApart(
                    g[0].timestamp,
                    messageGroups[i - 1][0].timestamp
                  )) && (
                  <div
                    className="info"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginRight: '40px',
                      marginLeft: '40px',
                      marginTop: i && 10,
                      textAlign: 'center',
                    }}
                  >
                    {isToday(d) ? `${zeroPad(
                      d.getHours()
                    )}:${zeroPad(d.getMinutes())}` : d.toLocaleDateString(i18n.language, {
                      weekday: 'short',
                      month: 'short',
                      day: 'numeric',
                    }) + ' '}
                  </div>
                )}
                <UserMessagesDisplay
                  messages={g}
                  userId={userId}
                  links={links}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div style={{ textAlign: 'left' }}>
        <form onSubmit={_sendMessage}>
          <div className="form-control">
            <div className={'join'} style={{ height }}>
              <textarea
                ref={inputRef}
                value={message}
                autoComplete="off"
                required
                rows={1}
                className="input w-full pt-2 resize-none join-item focus:outline-none focus:ring"
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                onChange={handleUpdateMessage}
              />
              <button
                className="btn-primary btn h-full join-item"
                disabled={message.length < 1}
              >
                {t('send')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
