import {useCallback, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import BiddingDisplay from '../../components/bidding/biddingDisplay';
import Hand from '../../components/cards/hand';
import {getDeal, getSessionDealObservable, joinSessionDeal} from '../../firebase/biddingSessions';
import {getCoachReview} from '../../firebase/dealReviews.js';
import {
    addSuggestedBidding,
    replaceDeal,
    GROUP_SESSION_COLLECTION,
    getGroupSessionWithSortedDeals,
    rotateDeal
} from '../../firebase/groups.js'
import {getHandFromDirection} from 'cuebids-hand-util';
import PairDisplay from '../../components/users/pairDisplay.jsx';
import {useQuery} from '@tanstack/react-query';
import {getEventParticipants} from '../../firebase/events.js';
import {Star, StarRating} from '../../components/rating/starRating.jsx';
import {ContractDisplay} from '../../components/bidding/bidDisplay.jsx';
import {UserDisplaySmall} from '../../components/users/userDisplay.jsx';
import {useAppStateStore} from '../../appStateStore.jsx';
import SessionDealChat from '../sessions/sessionComponents/sessionDealChat.jsx';
import {useAuth, useOutsideClick} from '../../util/hooks.jsx';
import {getRobotAction, getRobotParStrains} from 'cuebids-ai'
import {checkHasPotentialForParBeatBest, evaluate} from 'cuebids-evaluation'
import {
    bidArrayToBidding,
    directionToBid,
    getAlert,
    getBidArrayWithAlertsIncludingPositionalSymbols,
    getBidArrayWithoutAlertsExcludingPositionalSymbols, getContractDeclarer, getContracts, getHighestRobotBid,
    getInitialBidding,
    hasAlert,
    isBiddingFinished,
    isDirectionInTurn, parseBidding,
    removeAlerts,
    removeLastBid
} from 'cuebids-bidding-util'
import BiddingBox from '../../components/bidding/biddingBox'
import {replaceSuitShorthandWithSymbols} from '../../util/symbols.js'
import {isAcceptedInputForAlert} from '../../util/text.js'
import {ExclamIcon} from '../../components/icons/exclamIcon.jsx'
import SmallButton from '../../components/buttons/smallButton.jsx'
import useConfirm from '../../hooks/confirm.jsx';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import {useTranslation} from 'react-i18next'
import {Drawer} from "../../components/drawer/drawer.jsx";
import Alert from "../../components/alert/alert.jsx";

export default function CoachSessionDeal() {
    const {t} = useTranslation('translation', {keyPrefix: 'groups.coach_session_deal'});
    const {eventId, dealId, groupId} = useParams();
    const updateAppState = useAppStateStore((state) => state.updateAppState);
    const notify = useNotifications();
    const navigate = useNavigate();
    const {currentUser} = useAuth();

    const [selectedResult, setSelectedResult] = useState();
    const [sessionDeal, setSessionDeal] = useState();
    const [loading, setLoading] = useState(false);
    const [sessionDealForSuggestedBidding, setSessionDealForSuggestedBidding] = useState(null); // deal + some stuff robots need
    const [biddingForSuggestedBidding, setBiddingForSuggestedBidding] = useState('');
    const [commentForSuggestedBidding, setCommentForSuggestedBidding] = useState('');
    const [contracts, setContracts] = useState([]);
    const [alertForSuggestedBidding, setAlertForSuggestedBidding] = useState('');
    const [isArtificialForSuggestedBidding, setIsArtificialForSuggestedBidding] = useState(false);
    const [suggestedBiddingShowMode, setSuggestedBiddingShowMode] = useState('hidden'); // hidden, edit, show
    const commentRef = useRef(null);
    const [height, setHeight] = useState(96);
    const [alertPopupValue, setAlertPopupValue] = useState('');
    const joined = sessionDeal?.users.includes(currentUser.uid) || sessionDeal?.extraUsers.includes(currentUser.uid);
    const alertPopupRef = useRef();
    const [alertPopup, setAlertPopup] = useState({show: false, bidIndex: -1});
    const alertRef = useRef();

    useOutsideClick(alertPopupRef, () => hideAlertPopup());

    const [ConfirmReplace, confirmReplace] = useConfirm(
        'app.confirm_title',
        'groups.replace_deal_confirm',
        'error',
        'secondary',
    );

    const {data: groupSession, refetch} = useQuery({
        queryKey: ['groupSessionSortedDeals', eventId],
        queryFn: async () => await getGroupSessionWithSortedDeals(eventId)
    });

    const {data: deal, refetch: refetchDeal} = useQuery({
        queryKey: ['deal', dealId],
        queryFn: async () => {
            if (dealId) {
                return await getDeal(dealId);
            } else {
                return null;
            }
        }
    });

    const {data: participants} = useQuery({
        queryKey: ['eventParticipants', eventId],
        queryFn: async () => await getEventParticipants(GROUP_SESSION_COLLECTION, eventId)
    });

    const {data: review} = useQuery({
        queryKey: ['review', dealId],
        queryFn: async () => {
            if (dealId) {
                return await getCoachReview(dealId)
            } else {
                return null
            }
        }
    });


    useEffect(() => {
        if (!deal || !deal.score || !groupSession) {
            return;
        }
        const {finalBid, declarer, doubled} = parseBidding(
            biddingForSuggestedBidding,
            deal.version
        )

        const highestRobotBid = getHighestRobotBid(biddingForSuggestedBidding)

        const evaluation = evaluate({
            deal,
            finalBid,
            declarer,
            highestRobotBid,
            doubled,
            vulnerability: deal.vulnerability,
            bidding: biddingForSuggestedBidding,
            compete: groupSession.compete ?? 2,
            ai: ''
        })

        evaluation.contract = finalBid + doubled + declarer;
        if (finalBid === 'P') {
            evaluation.contract = 'P';
        }

        const _contracts = getContracts(evaluation);

        setContracts(_contracts)
    }, [deal, groupSession, biddingForSuggestedBidding])

    useEffect(function () {
        if (!dealId && groupSession?.deals) {
            navigate(`/groups/${groupId}/event/${eventId}/coach/${groupSession.deals[0]}`, {replace: true});
        }
    }, [dealId, groupSession?.deals, navigate, groupId, eventId, groupSession]);

    const hideAlertPopup = async () => {
        if (alertPopup.show) {
            handleAlertExistingBid(alertPopup.bidIndex, alertPopupValue);
            setAlertPopup({
                show: false,
                bidIndex: -1
            });
        }
    }

    const handleAlertExistingBid = (biddingIndex, newAlert) => {
        let bids = getBidArrayWithAlertsIncludingPositionalSymbols(biddingForSuggestedBidding);
        const withAlert = bids[biddingIndex];
        const withoutAlert = removeAlerts(withAlert);
        bids[biddingIndex] = withoutAlert + (hasAlert(withAlert) ? '[@"' : '["') + newAlert + '"]';
        const newBidding = bidArrayToBidding(bids);
        setBiddingForSuggestedBidding(newBidding);
    }

    useEffect(() => {
        return function () {
            setSelectedResult(undefined);
            setSessionDeal(undefined);
            setLoading(false);
            setBiddingForSuggestedBidding('');
            setCommentForSuggestedBidding('');
            setSuggestedBiddingShowMode('none');
            setHeight(96);
        }
    }, [dealId])

    useEffect(() => {
        setSessionDeal(null);

        if (selectedResult?.sessionDealId) {
            return getSessionDealObservable({sessionDealId: selectedResult.sessionDealId, callback: setSessionDeal})
        }
    }, [selectedResult, currentUser.uid])

    const moveDeal = useCallback((forward) => {
        const currentIndex = groupSession.deals.indexOf(dealId);
        const totalDeals = groupSession.deals.length;

        let nextIndex;
        if (forward) {
            nextIndex = (currentIndex + 1) % totalDeals;
        } else {
            nextIndex = (currentIndex - 1 + totalDeals) % totalDeals;
        }

        setSuggestedBiddingShowMode('none'); // This would not always reset causing a crash

        navigate(`/groups/${groupId}/event/${eventId}/coach/${groupSession.deals[nextIndex]}`, {replace: true});
    }, [groupSession, groupId, eventId, dealId, navigate]);

    useEffect(() => {
        if (!groupSession || !dealId) return;
        updateAppState({
            title: t('title', {number: groupSession.deals.indexOf(dealId) + 1}),
            subtitle: t('subtitle'),
            navbarItems: (
                <div className="flex items-center gap-4 mr-1 md:mr-8 md:gap-8 ">
                    <div className="">
                        <button className="" onClick={() => moveDeal(false)}>
                            <kbd className="kbd text-sm">◀︎</kbd>
                        </button>
                    </div>

                    <div className="">
                        <button className="" onClick={() => moveDeal(true)}>
                            <kbd className="kbd text-sm">▶︎</kbd>
                        </button>
                    </div>
                </div>
            ),
        });
        return () => updateAppState({navbarItems: undefined, title: undefined, subtitle: undefined});
    }, [updateAppState, groupSession, dealId, eventId, groupId, navigate, t, moveDeal]);

    async function handleJoinAsCoach() {
        if (joined || !sessionDeal || loading) {
            return;
        }
        setLoading(true)
        await joinSessionDeal(sessionDeal.id, currentUser.uid)
        setLoading(false)
    }

    const handleSelectReview = (reviewItem) => {
        setSuggestedBiddingShowMode('none');
        setSelectedResult(reviewItem)
    }


    const suggestedBidding = groupSession?.suggestedBidding?.[dealId]?.bidding;
    const suggestedBiddingComment = groupSession?.suggestedBidding?.[dealId]?.comment;

    const handleAddSuggestedBidding = () => {
        const robotPars = getRobotParStrains({scores: deal.score});
        const hasPotentialForParBeatBest =
            groupSession.compete &&
            checkHasPotentialForParBeatBest(
                deal.score,
                deal.hand,
                deal.vulnerability,
                deal.ai
            );
        let startingBidding = getInitialBidding(deal.dealer);
        if (!groupSession.compete && (deal.dealer === 'E' || deal.dealer === 'W')) {
            startingBidding += '-P';
        }

        setBiddingForSuggestedBidding(startingBidding);
        setCommentForSuggestedBidding(suggestedBiddingComment);
        setHeight(96);
        setSessionDealForSuggestedBidding({
            bidding: startingBidding,
            hand: deal.hand,
            vulnerability: deal.vulnerability,
            robotPars,
            hasPotentialForParBeatBest,
            dealer: deal.dealer,
            compete: groupSession.compete,
            aiSystem: deal.aiSystem,
            tag: deal.tag || null,
            ai: deal.ai || null,
        });
        setSuggestedBiddingShowMode('edit');
        setSelectedResult(null);
    };

    const handleShowSuggestedBidding = () => {
        setSuggestedBiddingShowMode('show');
        setSelectedResult(null);
    }

    const showSuggestedBidding = suggestedBiddingShowMode === 'show';
    const editSuggestedBidding = suggestedBiddingShowMode === 'edit';

    const handleEditSuggestedBidding = () => {
        const robotPars = getRobotParStrains({scores: deal.score});
        const hasPotentialForParBeatBest =
            groupSession.compete &&
            checkHasPotentialForParBeatBest(
                deal.score,
                deal.hand,
                deal.vulnerability,
                deal.ai
            );
        setBiddingForSuggestedBidding(suggestedBidding);
        setCommentForSuggestedBidding(suggestedBiddingComment);
        setSessionDealForSuggestedBidding({
            bidding: suggestedBidding,
            hand: deal.hand,
            vulnerability: deal.vulnerability,
            robotPars,
            hasPotentialForParBeatBest,
            dealer: deal.dealer,
            compete: groupSession.compete,
            tag: deal.tag || null,
            ai: deal.ai || null,
        });
        setSuggestedBiddingShowMode('edit');
        setSelectedResult(null);
        setTimeout(resizeTextArea, 0);
    }

    function resizeTextArea() {
        if (!commentRef?.current) {
            return
        }
        commentRef.current.style.height = '96px';
        commentRef.current.style.height =
            commentRef.current.scrollHeight + 'px';
        setHeight(commentRef.current.scrollHeight);
    }

    function handleSetComment(e) {
        const temp = e.target.selectionStart;

        setCommentForSuggestedBidding(replaceSuitShorthandWithSymbols(e.target.value));
        resizeTextArea();
        setTimeout(() => e.target.setSelectionRange(temp, temp), 0);
    }

    const handleChangeAlert = (a) => {
        const withSymbols = replaceSuitShorthandWithSymbols(a);
        if (isAcceptedInputForAlert(withSymbols)) {
            setAlertForSuggestedBidding(withSymbols);
        }
    };

    const handleOpenAlertForBid = (bid, biddingIndex) => {
        if (!editSuggestedBidding) {
            return
        }
        let bids = getBidArrayWithAlertsIncludingPositionalSymbols(biddingForSuggestedBidding)
        const alert = getAlert(bids[biddingIndex])
        setAlertPopupValue(alert);
        setAlertPopup({show: true, bidIndex: biddingIndex});
    }

    const handleAbortChanges = () => {
        setSuggestedBiddingShowMode('hidden')
    }

    const handleClickReplace = async () => {
        const ans = await confirmReplace()
        if (ans) {
            setLoading(true)
            await handleReplaceDeal()
            setLoading(false)
        }
    }

    const handleReplaceDeal = async () => {
        try {
            const id = await replaceDeal({groupSessionId: groupSession.id, dealId, tags: groupSession.tags})
            // Note: We used to await refetch here, but something weird happens with navigate that causes useParams not to retrigger, so we were stuck with old dealId.
            void refetch();
            navigate(`/groups/${groupId}/event/${eventId}/coach/${id}`, {replace: true});
        } catch (e) {
            notify(
                {
                    type: 'failure',
                    text: t('replace_deal_error', {error: e.message}),
                },
            );
        }
    }

  const handleClickRotate = async () => {
    setLoading(true)
    await handleRotateDeal()
    setLoading(false)
  }

  const handleRotateDeal = async () => {
    try {
      await rotateDeal({ groupSessionId: groupSession.id, dealId })
      await Promise.all([refetch(), refetchDeal()]);
    } catch (e) {
      notify(
        {
          type: 'failure',
          text: t('rotate_deal_error'),
        },
      );
    }
  }


    const { hand, vulnerability } = deal ?? { hand: null, vulnerability: null};

    const biddingToShow = editSuggestedBidding ? (
        isBiddingFinished(biddingForSuggestedBidding) ? biddingForSuggestedBidding : biddingForSuggestedBidding + '-?'
    ) : (
        showSuggestedBidding ? suggestedBidding
            : selectedResult?.bidding || '?'
    );

    const uniqueCombinations = new Set();

    participants?.forEach(({id, entries}) => {
        entries.forEach(({partner}) => {
            const combination = [id, partner].sort().join(',');
            uniqueCombinations.add(combination);
        });
    });

    review?.forEach((r) => {
        const {users} = r
        const sortedUsers = [...users].sort().join(',');
        uniqueCombinations.delete(sortedUsers);
    });

    const missingCombinations = Array.from(uniqueCombinations, combination => combination.split(','));

    if (!dealId) {
        return null;
    }

    const ready = deal && groupSession && review;

    return (
        <div className={'flex gap-0 md:gap-2 justify-start'}>
            <ConfirmReplace/>
            {
                ready && (
                    <div
                        className={`flex flex-col items-center scale-75 origin-top-left m-2 sm:scale-100 pt-2 ${editSuggestedBidding ? 'w-full' : 'w-full'}`}>
                        <div className={'flex items-center justify-between gap-4 mb-2'}>
                            {
                                (!groupSession.pairCount || groupSession.pairCount === 0) && !editSuggestedBidding &&
                                <button disabled={loading} className={'btn btn-error btn-sm h-4'}
                                        onClick={handleClickReplace}>{t('replace_deal')}</button>
                            }
                            {
                                (!groupSession.pairCount || groupSession.pairCount === 0) && !editSuggestedBidding &&
                                <button disabled={loading} className={'btn btn-secondary btn-sm h-4'}
                                        onClick={handleClickRotate}>{t('rotate_deal')}</button>
                            }
                            {
                                ((groupSession?.pairCount ?? 0) > 0) &&
                                <div className={'cursor-pointer btn btn-sm btn-primary h-4'}
                                     onClick={() => navigate(`/groups/${groupId}/event/${eventId}/coachPresent/${dealId}`)}>{t('present')}</div>
                            }
                        </div>

                        <Hand hand={getHandFromDirection(hand, 'N')} direction={'N'}/>
                        {selectedResult && (
                            <div className={'h-12 md:h-16 flex items-center'}>
                                <UserDisplaySmall uid={selectedResult.users[0]}/>
                            </div>
                        )}
                        <div className={'flex gap-4 relative m-4'}>
                            {editSuggestedBidding && !!groupSession.compete && <div className="w-28"/>}
                            {editSuggestedBidding && !!groupSession.compete &&
                                <Hand variant={'diagram'} hand={getHandFromDirection(hand, 'W')} direction={'W'}/>}
                            <BiddingDisplay
                                bidding={biddingToShow}
                                vulnerability={vulnerability}
                                variant={'standard'}
                                onBidClick={handleOpenAlertForBid}
                                startWithEast={false}
                                hideAlertsIndex={-1}
                            />
                            {editSuggestedBidding && !!groupSession.compete &&
                                <Hand variant={'diagram'} hand={getHandFromDirection(hand, 'E')} direction={'E'}/>}
                            <div className={'flex flex-col w-12 sm:w-28'}>
                                <span className="info">{t('suggested_bidding')}</span>
                                {
                                    suggestedBidding && !showSuggestedBidding && !editSuggestedBidding &&
                                    <button className="btn btn-primary mb-4 h-4 text-xs w-12 sm:text-md sm:w-24"
                                            onClick={handleShowSuggestedBidding}>{t('show')}</button>
                                }
                                {suggestedBidding && !editSuggestedBidding &&
                                    <button className="btn  btn-secondary mb-4 h-4 text-xs w-12 sm:text-md sm:w-24"
                                            onClick={handleEditSuggestedBidding}>{t('edit')}</button>
                                }
                                {
                                    !suggestedBidding && !editSuggestedBidding &&
                                    <button className="btn btn-primary mb-4 h-4 text-xs w-12 sm:text-md sm:w-24"
                                            onClick={handleAddSuggestedBidding}>{t('add')}</button>
                                }
                                {
                                    editSuggestedBidding && <>
                                        <button className="btn btn-secondary mb-4 h-4 text-xs w-12 sm:text-md sm:w-24"
                                                onClick={handleAbortChanges}>{t('cancel')}</button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary mb-4 h-4 text-xs w-12 sm:text-md sm:w-24"
                                            disabled={getBidArrayWithoutAlertsExcludingPositionalSymbols(
                                                biddingForSuggestedBidding
                                            ).length < (groupSession.compete ? 1 : 2)}
                                            onClick={function () {
                                                let newBidding = removeLastBid(biddingForSuggestedBidding);
                                                if (!groupSession.compete) {
                                                    const isPlayerInTurn = isDirectionInTurn(newBidding, 'N') || isDirectionInTurn(newBidding, 'S');
                                                    newBidding = isPlayerInTurn ? newBidding : removeLastBid(newBidding);
                                                }
                                                setBiddingForSuggestedBidding(newBidding);
                                                setAlertForSuggestedBidding('');
                                                setIsArtificialForSuggestedBidding(false);
                                            }}
                                        >
                                            {t('undo_bid')}
                                        </button>
                                        {!!groupSession.compete && (
                                            <button
                                                type="button"
                                                className="btn btn-secondary mb-4 h-4 text-xs w-12 sm:text-md sm:w-24"
                                                disabled={isBiddingFinished(biddingForSuggestedBidding) || ['N', 'S'].includes(directionToBid(biddingForSuggestedBidding))}
                                                onClick={function () {
                                                    const newBidding = biddingForSuggestedBidding + getRobotAction({
                                                        compete: groupSession.compete,
                                                        sessionDeal: sessionDealForSuggestedBidding,
                                                        bidding: biddingForSuggestedBidding,
                                                    });

                                                    setBiddingForSuggestedBidding(newBidding);
                                                    setAlertForSuggestedBidding('');
                                                    setIsArtificialForSuggestedBidding(false);
                                                }}
                                            >
                                                {t('robot_bid')}
                                            </button>
                                        )}
                                        <button
                                            className="btn btn-primary mb-4 h-4 text-xs w-12 sm:text-md sm:w-24"
                                            disabled={loading}
                                            onClick={async function () {
                                                if (loading) return;

                                                setLoading(true);
                                                await addSuggestedBidding({
                                                    groupSessionId: eventId,
                                                    dealId,
                                                    bidding: biddingForSuggestedBidding,
                                                    comment: commentForSuggestedBidding ?? '',
                                                });
                                                await refetch();
                                                setLoading(false);
                                                setSuggestedBiddingShowMode('show');
                                            }}
                                        >
                                            {t('save')}
                                        </button>
                                    </>
                                }
                            </div>
                            {alertPopup?.show && (
                                <div
                                    ref={alertPopupRef}
                                    className="absolute fade-in top-20 right-5 z-[9999] bg-base-100 border-sky-400 border-2 rounded-xl">
                                    <input
                                        value={alertPopupValue}
                                        ref={alertRef}
                                        autoFocus
                                        autoComplete="off"
                                        onChange={(e) => {
                                            const withSymbols = replaceSuitShorthandWithSymbols(e?.target?.value);
                                            if (isAcceptedInputForAlert(withSymbols)) {
                                                setAlertPopupValue(withSymbols);
                                            }
                                        }}
                                        type="text"
                                        placeholder={t('alert_placeholder')}
                                        className="input-primary input w-full border-0 bg-primary text-white caret-secondary"
                                    />
                                </div>
                            )}
                        </div>
                        {showSuggestedBidding && <p className="px-4 mb-4">{suggestedBiddingComment}</p>}
                        {editSuggestedBidding && (
                            isBiddingFinished(biddingForSuggestedBidding) ? (
                                <div style={{height}} className="w-full mt-4 px-4">
              <textarea
                  id="bid_description_input"
                  rows={4}
                  className="input h-[96px] w-full resize-none py-2"
                  value={commentForSuggestedBidding}
                  required
                  ref={commentRef}
                  autoComplete="off"
                  onChange={handleSetComment}
                  type="text"
                  placeholder={t('comment_placeholder')}
              />
                                </div>
                            ) : (
                                <>
                                    <BiddingBox
                                        bidding={biddingForSuggestedBidding}
                                        onBid={function (bid) {
                                            if (alertForSuggestedBidding !== '') {
                                                if (isArtificialForSuggestedBidding) {
                                                    bid = `${bid}[@"${alertForSuggestedBidding}"]`;
                                                } else {
                                                    bid = `${bid}["${alertForSuggestedBidding}"]`;
                                                }
                                            }

                                            let newBidding = `${biddingForSuggestedBidding}-${bid}`
                                            if (!groupSession.compete && !isBiddingFinished(newBidding) && ['E', 'W'].includes(directionToBid(newBidding))) {
                                                newBidding += '-P';
                                            }

                                            setBiddingForSuggestedBidding(newBidding);
                                            setAlertForSuggestedBidding('');
                                            setIsArtificialForSuggestedBidding(false);
                                        }}
                                    />
                                    <div className="mb-4 flex items-center justify-center">
                                        <SmallButton
                                            icon={<ExclamIcon fill={isArtificialForSuggestedBidding ? '#bfcbff' : '#fff'}/>}
                                            text={t('alert')}
                                            style={{marginRight: 10}}
                                            onClick={() => {
                                                setIsArtificialForSuggestedBidding(v => !v);
                                            }}
                                        />
                                        <input
                                            value={alertForSuggestedBidding}
                                            autoFocus
                                            autoComplete="off"
                                            onChange={(e) => handleChangeAlert(e?.target?.value)}
                                            type="text"
                                            placeholder={isArtificialForSuggestedBidding ? t('alert_bid_placeholder') : t('explain_bid_placeholder')}
                                            className="input-primary input w-full border-0 bg-primary text-white caret-secondary"
                                        />
                                    </div>
                                </>
                            )
                        )}
                        {selectedResult && (
                            <>
                                <div>
                                    <div className='flex items-center gap-2'>
                                        <ContractDisplay contract={selectedResult.contract} size={25}/>
                                        <StarRating stars={selectedResult.grade}/>
                                    </div>
                                </div>
                                <div className={'h-12 md:h-16 flex items-center'}>
                                    <UserDisplaySmall uid={selectedResult.users[1]}/>
                                </div>
                            </>
                        )}
                        <Hand hand={getHandFromDirection(hand, 'S')} direction={'S'}/>
                    </div>
                )
            }
            {
                !editSuggestedBidding && (
                    <Drawer moveDeal={moveDeal} btn={<Star/>} header={t('contracts')}>
                        {ready && (
                            <div>
                                {
                                    <div className={'m-2 flex items-start justify-around w-full'}>
                                        {
                                            contracts?.length > 0 && (
                                                <table className={'w-full'}>
                                                    <thead>
                                                    <tr>
                                                        <th>{t('contract')}</th>
                                                        <th>{t('stars')}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {contracts.map((c, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className={'flex items-center'}
                                                                    >
                                                                        <ContractDisplay size={40} contract={c.contract}/>
                                                                        <div style={{width: '5px', height: '0px'}}/>
                                                                        {c.contract.length > 1 &&
                                                                            getContractDeclarer(c.contract)}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className={'w-24'}>
                                                                        <StarRating stars={c.stars}/>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </table>
                                            )
                                        }

                                    </div>
                                }
                                <div className={'max-h-[80%] mt-4 overflow-y-auto overflow-x-hidden'}>
                                    <table className={'w-full'}>
                                        <thead>
                                        <tr>
                                            <th>
                                                {t('pairs')}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="[&>*:nth-child(odd)]:bg-base-100">
                                        {
                                            review.length === 0 && missingCombinations.length === 0 && (
                                                <tr>
                                                    <td>
                                                        <div className={'w-full flex items-center justify-center'}><Alert
                                                            text={t('no_pairs_started')}/></div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        {review.map((r) => {
                                            return (
                                                <tr
                                                    key={r.users[0] + r.users[1]}
                                                    style={{
                                                        background: r === selectedResult
                                                            ? '#32994538'
                                                            : undefined,
                                                        cursor: 'pointer',
                                                        maxHeight: 50,
                                                        height: 50,
                                                    }}
                                                    onClick={() => handleSelectReview(r)}
                                                >
                                                    <td>
                                                        <PairDisplay uid1={r.users[0]} uid2={r.users[1]}/>
                                                    </td>
                                                    <td className={'hidden md:table-cell'}>
                                                        <ContractDisplay contract={r.contract} size={25}/>
                                                    </td>
                                                    <td className={'hidden md:table-cell'}>
                                                        <StarRating stars={r.grade} size={'sm'}/>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {
                                            missingCombinations.map((m) => {
                                                return (
                                                    <tr
                                                        className={'opacity-25'}
                                                        key={m[0] + m[1]}
                                                        style={{
                                                            maxHeight: 50,
                                                            height: 50,
                                                        }}
                                                    >
                                                        <td>
                                                            <PairDisplay uid1={m[0]} uid2={m[1]}/>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    sessionDeal && !joined && (
                                        <button
                                            disabled={!sessionDeal || joined || loading}
                                            className={'btn btn-secondary mt-10'}
                                            onClick={handleJoinAsCoach}>{t('join_chat')}</button>
                                    )
                                }
                                {sessionDeal && joined && (
                                    <SessionDealChat key={sessionDeal.id} sessionDeal={sessionDeal}
                                                     currentUserId={currentUser.uid}/>
                                )}
                            </div>
                        )}
                    </Drawer>
                )
            }

        </div>
    )
}
