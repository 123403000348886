import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import SpotCard from '../../components/spot/spotCard';
import Alert from '../../components/alert/alert'
import { useAuth } from '../../util/hooks';
import { getMyResponses } from 'cuebids-firebase/spots'
import { useAppStateStore } from '../../appStateStore';
import logo from '../../images/logos/logo512.png'
import { swedishSpotPages } from '../../util/feds'
import { updateUser } from '../../firebase/userApi'
import { getSpotsLastSeenTimestampUserField } from '../../util/spots'
import { Tab, Tabs } from '../../components/tabs/tabs'

export default function SpotDashboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? 'all';
  const page = parseInt(searchParams.get('page')) || 0;
  const navigate = useNavigate();

  const { fed } = useParams();
  const { currentUser } = useAuth();

  const { t } = useTranslation();

  const spots = useAppStateStore((state) => state.spots);
  const appStateFriends = useAppStateStore((state) => state.friends);
  const user = useAppStateStore((state) => state.user);
  const updateNumberOfNewSpots = useAppStateStore((state) => state.updateNumberOfNewSpots);

  const friends = appStateFriends
    .filter((f) => f.status !== 'blocked')
    .map((f) => f.uid);

  const handeTabChange = (tabId) => {
    setSearchParams({
      tab: tabId,
      page: 0,
    });
  };

  const setPage = (p) => {
    setSearchParams({
      tab,
      page: p,
    });
  };

  const { data } = useQuery({
    queryKey: ['myResponses'],
    queryFn: async () => await getMyResponses(),
  });

  const alreadyBidSpots = data?.map((r) => r.spotId) ?? [];
  const spotsNotBid =
    spots?.filter((s) => !alreadyBidSpots.includes(s.id)) ?? [];
  function getRandomSpot() {
    const spot = spotsNotBid[Math.floor(Math.random() * spotsNotBid.length)];
    if (spot) {
      navigate(`/spots/${fed}/${spot.id}`);
    }
  }

  const hasLoadedUser = !!user;
  useEffect(function () {
    if (!hasLoadedUser) return;

    updateNumberOfNewSpots({ [fed]: 0 });
    void updateUser({ [getSpotsLastSeenTimestampUserField(fed)]: Date.now() });

  }, [fed, updateNumberOfNewSpots, hasLoadedUser]);

  if (!spots || spots[0]?.fed !== fed) {
    return null;
  }

  let spotsForTab = spots;
  if (tab === 'friends') {
    spotsForTab = spots.filter(s => friends.includes(s.uid));
  } else if (tab === 'mine') {
    spotsForTab = spots.filter(s => s.uid === currentUser.uid);
  }

  const pages = Math.ceil(spotsForTab.length / 7);

  return (
    <>
      <div className="fade-in page mb-24">
        <article className="prose mb-8 mt-8">
          <h1>{t('spots.title')}</h1>
        </article>
        <div className="flex items-center gap-4 mb-4">
          {!currentUser ? (
            <Alert type="info" text={t('spots.warnings.login_to_create')} />
          ) : (
            <Link to={'./new'}>
              <button type="button" className="btn btn-primary">
                {t('spots.create_new_spot_button')}
              </button>
            </Link>
          )}
          <button
            type="button"
            onClick={getRandomSpot}
            disabled={(spotsNotBid?.length ?? 0) === 0}
            className="btn btn-secondary"
          >
            {t('spots.get_random_spot')}
          </button>
        </div>
        <Tabs>
          <Tab
            key={'all'}
            label={t('spots.all')}
            value={'all'}
            active={tab === 'all'}
            onChange={handeTabChange}
          />
          <Tab
            key={'friends'}
            label={t('spots.friends')}
            value={'friends'}
            active={tab === 'friends'}
            onChange={handeTabChange}
          />
          <Tab
            key={'mine'}
            label={t('spots.mine')}
            value={'mine'}
            active={tab === 'mine'}
            onChange={handeTabChange}
          />
        </Tabs>
        <div className={'flex flex-col items-center grow'}>
          <table
            id="spots-swe"
            className="mt-4 w-full border-collapse rounded-sm md:w-4/5"
          >
            <thead>
              <tr>
                <th></th>
                <th>{t('spots.labels.hand')}</th>
                <th className="md:block hidden">
                  {t('spots.labels.description')}
                </th>
                <th className="text-right w-24">
                  <span className="mr-5">{t('spots.labels.by')}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {spotsForTab.slice(page * 7, (page * 7) + 7).map((s, i) => {
                const myBid = data && data.find((r) => r.spotId === s.id);
                return (
                  <SpotCard t={t} spot={s} myResponse={myBid} key={s.id} i={i} />
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="btn-group grid grid-cols-2 gap-4 mt-2">
          <button
            disabled={page === 0}
            className="btn btn-outline btn-sm h-8"
            onClick={() => setPage(page - 1)}
          > {t('app.prev')}</button>
          <button
            disabled={page >= pages - 1}
            className="btn btn-outline btn-sm h-8"
            onClick={() => setPage(page + 1)}> {t('app.next')}</button>
        </div>
        <footer className="fixed bottom-0 right-0 z-50 w-full items-center p-4 bg-accent text-primary-content text-lg text-center">
          <div className="items-center flex w-full justify-center gap-2">
            <div className={'w-[50px]'}>
              <Link to={'/'}>
                <img
                  src={logo}
                  className={'w-[50px] cursor-pointer'}
                  alt="Cuebids"
                />
              </Link>
            </div>
            <Link to={swedishSpotPages.includes(window?.location?.hostname) ? 'https://cuebids.com' : '/'}><p className='link'>
              {t('spots.footer')}
            </p> </Link>
          </div>
        </footer>
      </div>

    </>
  );
}
