export const resetOnLogoutInitialAppState = {
    notifications: [],
    friends: [],
    user: null,
    achievements: {},
    sessions: [],
    sessionId: null,
    sessionDeals: [],
    currentSession: null,
    sharedSessions: [],
    daily: null,
    weekly: null,
    challenges: [],
    challengeId: null,
    challengeDeals: [],
    challengeMatchmaking: null,
    challengeQueue: [],
    messages: [],
    groups: null, // Start as null instead of [] so we can determine when loaded
    groupsExtraData: {},
    groupsMessages: {},
    groupInvites: [],
    eventInvites: [],
    matchmaking: null,
    activeWebSubs: null, // Start as null instead of [] so we can determine when loaded
    activeIAPSubs: null, // Start as null instead of [] so we can determine when loaded
    showCompleted: true,
    title: undefined,
    subtitle: undefined,
    navbarItems: undefined,
    numberOfNewSpots: {},
    sbfData: null,
    newAccountPrepData: null,
    singlePlayerRobotErrors: {},
    checkShowMarketing: false,
    reviewDialogOpen: false,
    chatOpen: false
};
export const keepOnLogoutInitialAppState = {
    language: null,
    fedForLanguage: null,
    history: [],
    metadata: null,
    isSpotPage: false,
    connected: true,
    isNative: false,
    isMobile: false,
    preferWeb: false,
    appCopy: false,
    appShare: false,
    iap: false,
    redirect: null,
    spots: null, // Start as null instead of [] so we can determine when loaded
    android: false,
    allowNotifications: true
};
export const initialAppState = {
    ...resetOnLogoutInitialAppState,
    ...keepOnLogoutInitialAppState
};
