export const getHand = (deal, index) => {
  return deal.match(/[AKQJT2-9]*\.[AKQJT2-9]*\.[AKQJT2-9]*\.[AKQJT2-9]*/g)[
    index
  ];
};

export const getHandFromDirection = (deal, direction) => {
  return getHand(deal, { N: 0,
    E: 1,
    S: 2,
    W: 3 }[direction]);
};

export const getSuit = (hand, suit) => {
  return hand.split('.')[{ S: 0,
    H: 1,
    D: 2,
    C: 3 }[suit]];
};


export function replaceXInHand(hand) {
  const cardsReverseOrder = ['2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A'];
  return hand.split('.')
    .map(suit => {
      const copy = [...cardsReverseOrder];
      return suit.replace(/x/g, () => copy.shift())
        .split('')
        .sort((a,b) => {
          return cardsReverseOrder.indexOf(b) - cardsReverseOrder.indexOf(a)})
        .join('');
    }).join('.');
}