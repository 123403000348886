import { useRef, useState } from 'react';
import { useDisplay, useOutsideClick } from '../../util/hooks.jsx';

export function Menu({ options }) {
  const [open, setOpen] = useState(false)

  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => setOpen(false));

  const { height } = useDisplay();

  const short = height < 700;


  return (
    <div className={''} ref={wrapperRef}>
      <button
        className="btn-ghost btn"
        onClick={() => setOpen((e) => !e)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="inline-block h-5 w-5 stroke-current"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </button>
      {
        open && <div
          className={`popup w-42 absolute right-0 rounded-t-box rounded-b-box -top-2 mt-16 max-h-[550px]
         overflow-hidden overflow-y-auto overflow-x-hidden bg-accent text-base-content `}
        >
          <ul className="menu" tabIndex={0}>
            {options.filter((o) => !o.hidden).filter((o) => !short || !o.lowPrio).map((option, i) => (
              <li
                key={i}
                className="w-full"
                onClick={function () {
                  document.activeElement?.blur();
                }}
              >
                {option.component}
              </li>
            ))}
          </ul>
        </div>
      }
    </div>
  );
}
