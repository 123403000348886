import { useEffect, useRef, useState } from 'react'
import {
  bidArrayToBidding,
  directionToBid,
  getAllSpotsForPair,
  getBidArrayWithoutAlertsIncludingPositionalSymbols,
} from 'cuebids-bidding-util'
import BiddingDisplay from '../bidding/biddingDisplay'
import Hand from '../cards/hand'
import Dialog from '../dialog'
import { ArrowBackIcon } from '../icons/arrowBackIcon'
import { ArrowForwardIcon } from '../icons/arrowForwardIcon'
import { useAppStateStore } from '../../appStateStore.jsx'
import { SelectFedDropdown } from '../../pages/profile/selectFed.jsx'
import { feds } from '../../util/feds.js'
import { useTranslation } from 'react-i18next'
import { createSpot } from 'cuebids-firebase/spots'
import { useAuth } from '../../util/hooks.jsx'
import useNotifications from '../notifications/useNotifications.jsx';

function getBiddingUntilMyLastAction(bidding) {
  const bids = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding);
  const bidsToRemove = (bids.length % 2) + 1;

  return bids.slice(0, bids.length - bidsToRemove);
}

function getBiddingStep(bidding, step) {
  if (step === 0) {
    return bidding;
  }

  const lastAct = getBiddingUntilMyLastAction(bidding);

  if (step === 1) {
    return bidArrayToBidding(lastAct);
  }

  const removeSteps = lastAct.slice(0, lastAct.length - (step - 1) * 2);
  return bidArrayToBidding(removeSteps);
}

export default function CreateSpotDialog({
  bidding,
  direction,
  vulnerability,
  partnersHand,
  hand,
  dealer,
  sessionDealId,
  open,
  onClose,
}) {

  const { currentUser } = useAuth();

  const container = useRef(null);
  const user = useAppStateStore((state) => state.user);
  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const notify = useNotifications();
  const [steps, setSteps] = useState(1); // For spots we must have at least one step removed from a finished deal (where all have passed)
  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState('');
  const [includeAnswer, setIncludeAnswer] = useState(false);
  const [answer, setAnswer] = useState('');
  const [fed, setFed] = useState();

  const selectedFed = feds.find((f) => f.id === fed);

  const { t } = useTranslation();

  const adjustedBidding = getBiddingStep(bidding, steps);

  const maxSteps = getAllSpotsForPair(bidding).length;

  let toBid = directionToBid(adjustedBidding);
  if (steps === 0) {
    toBid = direction;
  }

  function handleOnClose(id) {
    setFed(null)

    updateAppState({ fedForLanguage: null });

    onClose?.(id);
  }

  const handleSetIncludeAnswer = (event) => {
    const newChecked = event.target.checked
    setIncludeAnswer(newChecked);
  };

  useEffect(() => {
    if (selectedFed) {
      const { language } = selectedFed;
      updateAppState({ fedForLanguage: language });

      return () => {
        updateAppState({ fedForLanguage: null });
      }
    }
  }, [selectedFed, updateAppState])

  async function handleCreateSpot() {
    if (loading) return;

    setLoading(true);
    const id = await createSpot({
      bidding: adjustedBidding,
      dealer,
      vulnerability,
      description,
      answer: includeAnswer ? answer : '',
      scoring: 'TEAMS',
      fed: selectedFed.language,
      uid: currentUser?.uid,
      sessionDealId,
      hand: toBid === direction ? hand : partnersHand,
    });
    setLoading(false);

    if (id) {
      notify(
        { type: 'success', text: t('spots.successfully_created') },
      );
      handleOnClose(id);
    }
  }

  const actions = [
    {
      component: (
        <button
          disabled={loading}
          key="post"
          role="button"
          className="btn-primary btn-sm btn"
          onClick={handleCreateSpot}
        >
          {t('spots.create_new_spot_button')}
        </button>
      ),
    },
  ]

  return (
    <Dialog
      id="share-dialog"
      open={open}
      onClose={handleOnClose}
      header={
        !selectedFed ? <h1 className='text-lg flex items-center justify-center w-full'>
          {t('spots.select_fed')}
        </h1> :
          <div className='w-full flex justify-around'>
            <button
              className="btn-primary btn-sm btn h-8"
              disabled={steps === maxSteps}
              onClick={() =>
                setSteps((n) => {
                  return Math.min(maxSteps, n + 1);
                })
              }
            >
              <ArrowBackIcon width={20} />
            </button>
            <button
              className="btn-primary btn-sm btn h-8"
              disabled={steps === 1}
              onClick={() =>
                setSteps((n) => {
                  return Math.max(1, n - 1);
                })
              }
            >
              <ArrowForwardIcon width={20} />
            </button>
          </div>
      }
      actions={selectedFed ? actions : []}
    >
      {
        !selectedFed ?
          <SelectFedDropdown onSelect={(fed) => setFed(fed)} />
          :
          <>
            <div className="flex w-full justify-center">
              <span className="info">{t('share.navigate_with_buttons_info')}</span>
            </div>
            <div key={selectedFed.id} className="flex w-full justify-center my-4 items-center">
              <img src={selectedFed.image} className="w-8 mr-2" alt={selectedFed.name} />
              {selectedFed.name}
            </div>
            <div className="flex w-full justify-center">
              <div
                ref={container}
                className="flex w-full flex-col items-center bg-base-100"
              >
                <div className="flex w-full flex-col items-center justify-center gap-4 pt-4">
                  {steps === 0 && (
                    <div className="flex h-24 w-full items-center justify-center">
                      <Hand
                        variant={'modern'}
                        showHcp={!(user?.hideHcpCount ?? false)}
                        hand={partnersHand}
                        size={'sm'}
                        direction={toBid === 'S' ? 'N' : 'S'}
                        compact
                        order={user?.suitOrder ?? 'default'}
                      />
                    </div>
                  )}
                  <div className="flex w-full items-center justify-center">
                    <BiddingDisplay
                      bidding={adjustedBidding + (steps > 0 ? '-?' : '')}
                      vulnerability={vulnerability}
                      variant={'compact full'}
                      startWithEast={direction === 'N'}
                      hideAlertsIndex={-1}
                    />
                  </div>
                  <div className="flex h-24 w-full items-center justify-center">
                    <Hand
                      variant={'modern'}
                      showHcp={!(user?.hideHcpCount ?? false)}
                      hand={toBid === direction ? hand : partnersHand}
                      size={'sm'}
                      direction={toBid}
                      compact
                      order={user?.suitOrder ?? 'default'}
                    />
                  </div>
                  <textarea
                    rows={2}
                    className="input h-[96px] w-full resize-none py-2 mt-2"
                    value={description}
                    placeholder={t('spots.spot_description_input')}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <label className="label w-full cursor-pointer p-0 font-bold">
                    {t('spots.spot_include_answer_input')}
                    <input
                      type="checkbox"
                      className="toggle-primary toggle"
                      checked={includeAnswer}
                      onChange={handleSetIncludeAnswer}
                    />
                  </label>
                  {includeAnswer && (
                    <textarea
                      rows={2}
                      className="input h-[96px] w-full resize-none py-2 -mt-2"
                      value={answer}
                      placeholder={t('spots.spot_answer_input')}
                      onChange={(e) => setAnswer(e.target.value)}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
      }
    </Dialog>
  );
}
