
import {
  getAuth,
  getFirestore,
  getDatabase,
  getStorage,
  getMessaging,
  getAnalytics,
  getFunctions,
  init
} from 'cuebids-firebase'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const appCheckToken = import.meta.env.VITE_APP_CHECK_TOKEN

const debugToken = import.meta.env.DEV ? import.meta.env.VITE_APP_CHECK_DEBUG_TOKEN : null

init(firebaseConfig, appCheckToken, debugToken)

export const auth = getAuth();
export const db = getFirestore();
export const realDb = getDatabase();
export const storage = getStorage();
export const analytics = getAnalytics();
export const functions = getFunctions();
export const messaging = getMessaging();