import Tooltip from '../tooltip/tooltip';
import { InfoIcon } from '../icons/infoIcon';

export default function InfoPopup({
  id,
  anchorElement,
  children,
  sx,
  open,
  delayShow = 300,
}) {
  return (
    <>
      <div
        data-tooltip-id={id}
        data-tooltip-delay-show={delayShow}
        className={sx}
      >
        {anchorElement || <InfoIcon width={16} />}
      </div>
      <Tooltip id={id} open={open}>
        {children}
      </Tooltip>
    </>
  );
}
