import { getRepos } from '../base';
import { limit, orderBy, query, where } from 'firebase/firestore';
import { docToObject, queryFirstItemToObject, queryListenWithCallback, queryToObjects } from 'firebase-util';
function getChallengeStatisticsPartnershipDocId(users) {
    return [...users].sort().join('-');
}
function getChallengeStatisticsUsersArray(users) {
    const team1 = users.slice(0, 2).sort();
    const team2 = users.slice(2).sort();
    const sortedUsers = [...users].sort();
    const firstUser = sortedUsers[0];
    return team1.includes(firstUser) ? team1.concat(team2) : team2.concat(team1);
}
function getChallengeStatisticsDocId(users) {
    return getChallengeStatisticsUsersArray(users).join('-');
}
export function getTeamId(users) {
    return getChallengeStatisticsPartnershipDocId(users);
}
export function getTeamFromId(teamId) {
    return teamId.split('-');
}
export function getLatestRankedChallengeHistoryObservable({ count, callback }) {
    const repo = getRepos().challengeSession;
    const q = query(repo.collection, where(repo.fieldName('ranked'), '==', true), where(repo.fieldName('finished'), '==', true), where(repo.fieldName('proChallenge'), '==', false), orderBy(repo.fieldName('finishedTimestamp'), 'desc'), limit(count));
    return queryListenWithCallback(q, callback);
}
export async function getTopChallengePartnership(userId) {
    const repo = getRepos().challengeStatisticsPartnership;
    const q = query(repo.collection, where(repo.fieldName('users'), 'array-contains', userId), orderBy(repo.fieldName('numberOfChallenges'), 'desc'), orderBy(repo.fieldName('timestamp'), 'desc'), limit(1));
    return await queryFirstItemToObject(q);
}
export async function getTopChallengeOpponents(userId) {
    const repo = getRepos().challengeStatistics;
    const q = query(repo.collection, where(repo.fieldName('users'), 'array-contains', userId), orderBy(repo.fieldName('numberOfChallenges'), 'desc'), orderBy(repo.fieldName('timestamp'), 'desc'), limit(1));
    return await queryFirstItemToObject(q);
}
export async function getPartnershipTopChallengeOpponents(users) {
    const repo = getRepos().challengeStatistics;
    const q = query(repo.collection, where(repo.fieldName('teamIds'), 'array-contains', getTeamId(users)), orderBy(repo.fieldName('numberOfChallenges'), 'desc'), orderBy(repo.fieldName('timestamp'), 'desc'), limit(1));
    return await queryFirstItemToObject(q);
}
export async function getChallengeStatisticsPartnership(users) {
    const repo = getRepos().challengeStatisticsPartnership;
    const documentSnapshot = await repo.doc(getChallengeStatisticsPartnershipDocId(users));
    return docToObject(documentSnapshot);
}
export async function getChallengeStatistics(usersTeam1, usersTeam2) {
    const repo = getRepos().challengeStatistics;
    const documentSnapshot = await repo.doc(getChallengeStatisticsDocId(usersTeam1.concat(usersTeam2)));
    return docToObject(documentSnapshot);
}
export async function getLatestChallengeHistoryUser(userId, count) {
    const repo = getRepos().challengeSession;
    const q = query(repo.collection, where(repo.fieldName('users'), 'array-contains', userId), where(repo.fieldName('finished'), '==', true), where(repo.fieldName('proChallenge'), '==', false), orderBy(repo.fieldName('finishedTimestamp'), 'desc'), limit(count));
    return await queryToObjects(q);
}
export async function getLatestChallengeHistoryPartnership(users, count) {
    const repo = getRepos().challengeSession;
    const q = query(repo.collection, where(repo.fieldName('teamIds'), 'array-contains', getTeamId(users)), where(repo.fieldName('finished'), '==', true), where(repo.fieldName('proChallenge'), '==', false), orderBy(repo.fieldName('finishedTimestamp'), 'desc'), limit(count));
    return await queryToObjects(q);
}
export async function getLatestChallengeHistoryOpponents(usersTeam1, usersTeam2, count) {
    const repo = getRepos().challengeSession;
    const q = query(repo.collection, where(repo.fieldName('statisticsId'), '==', getChallengeStatisticsDocId(usersTeam1.concat(usersTeam2))), where(repo.fieldName('finished'), '==', true), where(repo.fieldName('proChallenge'), '==', false), orderBy(repo.fieldName('finishedTimestamp'), 'desc'), limit(count));
    return await queryToObjects(q);
}
