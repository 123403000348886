import { useAuth } from '../../util/hooks.jsx';
import Dialog from '../dialog';
import { useTranslation } from 'react-i18next';

export default function SignOut() {
  const { currentUser, signOut } = useAuth();

  const { t } = useTranslation();

  const handleConfirmSignOut = async () => {
    await signOut(currentUser.uid);
  };

  return (
    <Dialog
      id="sign-out-dialog"
      aria-labelledby="sign-out-confirm"
      aria-describedby="sign-out-confirm"
      title={t('app.sign-out-dialog.title')}
      actions={[
        {
          component: (
            <div key="no" role="button" className="btn btn-error">
              {t('app.cancel')}
            </div>
          ),
        },
        {
          component: (
            <div
              key="yes"
              role="button"
              className="btn btn-secondary"
              onClick={handleConfirmSignOut}
            >
              {t('app.sign-out-dialog.button')}
            </div>
          ),
        },
      ]}
    >
      {t('app.sign-out-dialog.confirm_text')}
    </Dialog>
  );
}
