export function getWinPercent(wins, draws, losses) {
  if (!wins && !draws && !losses) {
    return 50;
  }

  return Math.round((100 * (wins + 0.5 * draws)) / (wins + draws + losses));
}

export function roundToTwoDecimals(num) {
  return Math.round(num * 100) / 100;
}
