import { useRef, useState } from 'react'
import { useOutsideClick } from '../../util/hooks.jsx'
import { capitalizeFirstLetter } from '../../util/text.js'
import { countries } from '../../util/getFlag.js'

const sortedCountries = Object.entries(countries).reduce(function (a, v){
  const [key, val] = v;
  a.push({ name: val, flag: key.toLowerCase() })
  return a;
}, []).sort(function (a, b) {
  if (a.name > b.name) {
    return 1;
  }

  if (a.name < b.name) {
    return -1;
  }

  return 0;
});

export default function SelectCountryDropdown({
  label,
  value,
  onChange,
  disabled,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => setDropdownOpen(false));

  function toggleDropdown() {
    setDropdownOpen((x) => !x);
  }

  function onSelect(id) {
    if (id !== value) {
      onChange(id);
    }
    setDropdownOpen(false);
  }

  const options = sortedCountries
    .filter(
      (f) =>
        !filter || f.name.toLowerCase().startsWith(filter.toLowerCase())
    )
    .map((f) => {
      return {
        display: (
          <button
            tabIndex={0}
            className="btn-ghost btn relative flex w-full justify-start text-start"
            style={{ height: 32, minHeight: 32 }}
            key={f.flag}
            onClick={() => onSelect(f.flag)}
          >
            <span className="truncate max-w-[220px]">{f.name}</span>
            <span className={`fi fi-${f.flag}`}></span>
          </button>
        ),
        value: f.id,
      };
    });

  const selectedCountry = sortedCountries.find(c => c.flag === value);

  return (
    <div className="relative" ref={wrapperRef}>
      {label && (
        <label className="label">
          <span className="label-text">{capitalizeFirstLetter(label)}</span>
        </label>
      )}
      <button
        className="btn-accent btn relative flex h-12 w-full flex-row items-center justify-between overflow-hidden"
        type="button"
        onClick={toggleDropdown}
        disabled={disabled}
      >
        {dropdownOpen ? (
          <input
            type="text"
            placeholder="Filter..."
            className="input w-4/5 h-8"
            autoFocus
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        ) : value ? (
          <span>
            <span className="truncate max-w-[220px] mr-2">{selectedCountry.name}</span>
            <span className={`fi fi-${selectedCountry.flag}`}></span>
          </span>
        ) : (
          <>Select country</>
        )}
        <svg
          className="mx-1.5 h-4 w-4"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div
        className={
          (!dropdownOpen ? 'hidden ' : '') +
          // Note: rounded + max height + modal resulted in a bug with blurry text, so rounded was removed
          'scroll absolute z-[9997] mt-0 max-h-44 overflow-x-hidden overflow-y-scroll border border-slate-500 border-opacity-50 bg-base-100 p-4'
        }
      >
        <div className="flex w-[281px] flex-col items-start justify-start">
          {options.length > 0 ? (
            options.map((o) => {
              return o.display;
            })
          ) : (
            <>No countries match filter...</>
          )}
        </div>
      </div>
    </div>
  );
}
