import { useCallback, useEffect, useMemo, useState } from 'react'
import { createPracticeSession, getNumberOfSessionsWithLia } from '../../firebase/biddingSessions'
import '../../components/hand/hands.css'
import Dropdown, { SelectFriendDropdown } from '../../components/dropdown/dropdown'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { DealSourcesSelect } from '../../components/dealSources/dealSourcesSelect'
import Price from '../../components/price/price'
import Alert, { AlertInline } from '../../components/alert/alert'
import { canPairAffordToPlay, getCharges, getUserRemainingTickets } from '../../util/tickets'
import { getUserHighestSubscription } from '../../firebase/subscriptions'
import { getUser } from '../../firebase/userApi'
import RemainingTicketsWarning from '../../components/remainingTicketsWarning/remainingTicketsWarning'
import PriceDialog from '../../components/priceDialog/priceDialog'
import { getCurrentOnboardingStep, onboardingSteps } from '../../util/onboarding'
import { TicketIcon } from '../../components/icons/ticketsIcon'
import { createSessionStartedNotification } from 'cuebids-firebase/notifications'
import { useActiveSubs, useAuth, useDisplay, useHasLoadedDataToGetSubs, useRemainingTicketsNavbarItems } from '../../util/hooks'
import { useAppStateStore } from '../../appStateStore.jsx'
import { getRobotUserId, getInitialLiaSystem } from '../../util/robotPartner.js'
import { useQuery } from '@tanstack/react-query'
import { Tab, TabPanel, Tabs } from '../../components/tabs/tabs.jsx';
import { ScenarioSelect } from '../../components/dealSources/scenarioSelect.jsx';
import { isSubEnough } from '../../util/sub.js';
import UserDisplay from '../../components/users/userDisplay.jsx';
import goToStore from '../../util/goToStore.js'
import { LowScenarioStock } from '../../firebase/errors.js';
import RequireHeartDialog from '../../marketing/RequireHeartDialog.jsx'
import { NoRobotIcon } from '../../components/icons/noRobotIcon.jsx';
import { RobotIcon } from '../../components/icons/robotIcon.jsx';
import { AdvancedRobotIcon } from '../../components/icons/advancedRobotIcon.jsx';
import { getPartnerPriceFactor, getPrice } from '../../util/priceFactor.js';
import { dealTypes } from 'cuebids-deals';
import { LiaSystemDropdown } from '../../components/dropdown/liaSystemDropdown.jsx'
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next';

function NoFriendsInfo({ friends }) {
  const { t } = useTranslation();
  if (friends.length === 0) {
    return (
      <Alert
        sx="border-pulse"
        text={t('create_session.no_friends_info')}
      />
    );
  }

  if (friends.filter((f) => f.status === 'mutual').length === 0) {
    return (
      <Alert
        sx="border-pulse"
        text={t('create_session.no_mutual_friends_info')}
      />
    );
  }
}

function validateTags(tags, activeSubs) {
  return tags.every(t => (
    dealTypes[t] && isSubEnough(
      activeSubs,
      dealTypes[t].sub,
    ))
  )
}

const settingMap = {
  0: <NoRobotIcon className={'fill-gray-500 opacity-40'} />,
  1: <RobotIcon className={'fill-sky-400'}/>,
  2: <AdvancedRobotIcon className={'fill-rose-400'} />,
}


export default function CreateNewSession() {
  const metadata = useAppStateStore((state) => state.metadata);
  const user = useAppStateStore((state) => state.user);
  const friends = useAppStateStore((state) => state.friends);
  const sessions = useAppStateStore((state) => state.sessions);
  const iap = useAppStateStore((state) => state.iap);
  const isNative = useAppStateStore((state) => state.isNative);
  const notify = useNotifications();
  const showMarketingDialog = useAppStateStore((state) => state.showMarketingDialog);

  const { currentUser } = useAuth();

  const hasLoadedDataToGetSubs = useHasLoadedDataToGetSubs()
  const activeSubs = useActiveSubs();

  const [promptToSubscribe, setPromptToSubscribe] = useState(false);
  const canUserDoScenarios = isSubEnough(activeSubs, 'heart');
  const highestSub = activeSubs[0];

  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const pd = searchParams.get('pd');
  const pdId = pd ? getRobotUserId() : null;
  const [friendId, setFriendId] = useState(
    pdId || user?.practiceDefaultPartner || (friends?.length > 0 ? '' : getRobotUserId())
  );

  const paramsHandCount = searchParams.get('handCount');
  const [handCountInput, setHandCountInput] = useState(
    paramsHandCount || user?.practiceDefaultNumberOfDeals || 3
  );
  const handCount = parseInt(handCountInput, 10);

  const paramsRobots = searchParams.get('robots');
  const [robots, setRobots] = useState(
    ['0', '1', '2'].includes(paramsRobots) ? parseInt(paramsRobots, 10) : (user?.practiceDefaultRobots ?? 0)
  );

  const [liaSystem, setLiaSystem] = useState(getInitialLiaSystem(user?.defaultLiaSystem));

  const paramsTag = searchParams.get('tag');
  const [tags, setTags] = useState(paramsTag ? [paramsTag] : []);

  const [scenarios, setScenarios] = useState([]);

  const [friend, setFriend] = useState(null);
  const [friendSub, setFriendSub] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const [autoStart, setAutoStart] = useState(!!searchParams.get('autoStart'));

  const nav = useNavigate();
  const { t } = useTranslation();

  useRemainingTicketsNavbarItems();

  const { isSmall } = useDisplay();

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);


  const settingNameMap = {
    0: t('robots.none'),
    1: t('robots.basic'),
    2: t('robots.advanced'),
  }

  const shouldForceAdvancedRobots = useMemo(function () {
    if (tags.length > 0 && tags.some(t => dealTypes[t].ai)) {
      return true;
    } else if (scenarios.length > 0 && scenarios.some(scenario =>
    {
      const hasAi = scenario?.directions?.settings?.ai ?? 'P' !== 'P';
      const hasAiSystem = !!scenario?.directions?.settings?.aiSystem;
      const hasEastCommand = scenario?.directions?.east?.some(d => d.command);
      const hasWestCommand = scenario?.directions?.west?.some(d => d.command);

      return (hasAi || hasAiSystem || hasEastCommand || hasWestCommand)
    }))
    {
      return true;
    }
    return false;
  }, [tags, scenarios]);

  const shouldForceNoRobots = useMemo(function () {
    return scenarios.length > 0 && scenarios.some(scenario =>
      (scenario?.directions?.settings?.ai === 'P') ||
      (scenario?.directions?.settings?.robots === 'NONE') ||
      (scenario?.directions?.settings?.disableRobots))

  }, [scenarios]);

  useEffect(() => {
    if(shouldForceAdvancedRobots && shouldForceNoRobots) {
      notify(
        {
          type: 'failure',
          text: t('create_session.robots_conflict')
        }
      )
      setScenarios([])
      return;
    }
    if (shouldForceNoRobots && robots !== 0) {
      handleChangeRobots(0);
    }
    if (shouldForceAdvancedRobots && robots !== 2) {
      handleChangeRobots(2);
    }
  }, [robots, shouldForceAdvancedRobots, shouldForceNoRobots]);

  useEffect(
    function () {
      if (friendId) {
        // Slightly ugly to nest, but now we know that when friend is loaded we have loaded both.
        getUserHighestSubscription(friendId).then(function (fs) {
          setFriendSub(fs);
          getUser({ userId: friendId }).then(function (f) {
            setFriend(f);
          });
        });
      }
    },
    [friendId]
  );

  const { data: numberOfSessionsWithLia } = useQuery({
    queryKey: ['numberOfSessionsWithLia'],
    queryFn: getNumberOfSessionsWithLia,
  });

  const friendsAndRobots = friends.concat([
    { id: getRobotUserId(), status: 'mutual', robot: true, uid: getRobotUserId(), icon: getPartnerPriceFactor({ numberOfSessionsWithLia, sessions, friendId: getRobotUserId() }) ? <TicketIcon /> : undefined }
  ]);

  const handleClose = () => {
    setConfirmationModalOpen(false);
  };

  const handleChange = (e) => {
    setHandCountInput(e.target.value);
  };

  const handleSetTags = (ts) => {
    setTags(ts);
    setScenarios([]);
  };

  const handleRemoveTag = (tag) => {
    setTags(tags.filter((t) => t !== tag));
  }

  const handleRemoveScenario = (scenario) => {
    setScenarios(scenarios.filter((s) => s.id !== scenario.id));
  }

  const handleSetScenarios = (ss) => {
    setTags([]);
    setScenarios(ss);
  };

  const handleChangeRobots = (val) => {
    setRobots(val);
  };

  const handleChangeLiaSystem = (val) => {
    setLiaSystem(val);
  };

  const handleSelectFriend = (id) => {
    setFriendId(id);
    setFriend(null);
    setFriendSub(null);
  };

  const canUseSelectedTags = validateTags(tags, activeSubs);

  const price = getPrice({ robots, friendId, handCount, sessions, numberOfSessionsWithLia });

  const myRemainingTickets = user ? getUserRemainingTickets(
    user.ticketsSpent ?? 0,
    highestSub,
    user.extraTickets ?? 0
  ) : 0;

  const partnerRemainingTickets = friend ?
    (friend.robot ? Infinity : getUserRemainingTickets(
      friend.ticketsSpent ?? 0,
      friendSub,
      friend.extraTickets ?? 0
    )
    ) : 0;

  const { myCharge, partnerCharge } = getCharges({
    myRemainingTickets,
    partnerRemainingTickets,
    price,
  });

  const canAffordToPlay = canPairAffordToPlay({
    myRemainingTickets,
    partnerRemainingTickets,
    price,
  });

  const formValid = friendId &&
    friend &&
    handCount &&
    handCount >= 1 &&
    handCount <= 24 &&
    canAffordToPlay &&
    canUseSelectedTags;

  const startSessionConfirmed = useCallback(async () => {
    setLoading(true);
    try {
      const { uid: friendUserId, robot } = friendsAndRobots.find((f) => f.id === friendId);

      const id = await createPracticeSession({
        userTwoId: friendUserId,
        numberOfDeals: handCount,
        compete: robots,
        tags: tags,
        scenarios,
        minDealNumber: metadata?.minDealNumber,
        price,
        liaSystem,
      });

      if (!robot) {
        void createSessionStartedNotification(
          friendUserId,
          user?.displayName ?? '',
          id,
          t
        );
      }

      setLoading(false);
      showMarketingDialog();
      nav(`/session/${id}`, { replace: true });
    } catch (e) {
      if (e instanceof LowScenarioStock) {
        notify(
          {
            type: 'failure',
            text: t('create_session.robots_conflict'),
            url: '/manageDealTypes'
          },
        );
      }
      else {
        notify(
          {
            type: 'failure',
            text: t('create_session.error_creating_session'),
          },
        );
      }
      setLoading(false);
    }
  }, [
    t,
    showMarketingDialog,
    notify,
    friendId,
    friendsAndRobots,
    handCount,
    metadata?.minDealNumber,
    nav,
    price,
    robots,
    scenarios,
    tags,
    user?.displayName,
    liaSystem,
  ]);

  const handleClick = useCallback(() => {
    if (myCharge !== 0 || partnerCharge !== 0) {
      setConfirmationModalOpen(true);
    } else {
      void startSessionConfirmed();
    }
  }, [myCharge, partnerCharge, startSessionConfirmed]);

  const readyForAutoStart = friend && hasLoadedDataToGetSubs && user && numberOfSessionsWithLia != null;
  useEffect(function () {
    if (autoStart && readyForAutoStart) {
      setAutoStart(false);
      if (formValid) {
        handleClick();
      } else {
        notify(
          {
            type: 'failure',
            text: t('create_session.error_creating_session'),
          },
        );
      }
    }
  }, [autoStart, readyForAutoStart, handleClick, formValid, notify, t]);

  if (loading || autoStart) {
    return (
      <div className="mt-20 flex w-full items-center justify-center">
        <article className="prose w-1/2">
          <h1>{t('create_session.starting')}</h1>
        </article>
      </div>
    );
  }

  if (!friends || !user || !hasLoadedDataToGetSubs) {
    return <></>;
  }

  const currentOnboardingStep = getCurrentOnboardingStep({ user });

  const robotOptions = [
    {
      value: 0,
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {settingMap[0]}{settingNameMap[0]}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn w-full justify-start items-center flex"
          onClick={() => handleChangeRobots(0)}
          disabled={shouldForceAdvancedRobots}
        >
          <div className="w-full h-full flex items-center justify-start gap-2">
            {settingMap[0]}{settingNameMap[0]}
          </div>
        </button>
      ),
    },
    {
      value: 1,
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {settingMap[1]}{settingNameMap[1]}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn w-full justify-between items-center flex"
          onClick={() => handleChangeRobots(1)}
          disabled={shouldForceAdvancedRobots || shouldForceNoRobots}
        >
          <div className="w-full h-full flex items-center justify-start gap-2">
            {settingMap[1]}{settingNameMap[1]}
          </div>
        </button>
      ),
    },
    {
      value: 2,
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {settingMap[2]}{settingNameMap[2]}
          </div>
          <TicketIcon />
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn relative w-full justify-between items-center flex"
          onClick={() => handleChangeRobots(2)}
          disabled={shouldForceNoRobots}
        >
          <div className="h-full flex items-center justify-start">
            <div className="w-full h-full flex items-center justify-start gap-2">
              {settingMap[2]}{settingNameMap[2]}
            </div>
            <div className="absolute right-8">
              <TicketIcon />
            </div>
          </div>
        </button>
      ),
    },
  ];

  return (
    <div className="fade-in">
      <div className="page pb-24">
        {promptToSubscribe && (
          <RequireHeartDialog onClose={() => setPromptToSubscribe(false)} />
        )}
        {!pd && <NoFriendsInfo friends={friends} />}
        <div className="indicator">
          {currentOnboardingStep === onboardingSteps.createSession &&
            !friendId && (
            <div className="pulse w-5 badge badge-error indicator-item top-10" />
          )}
          {pd === 'lia' ? (
            <UserDisplay uid={getRobotUserId()} />
          ) : (
            <SelectFriendDropdown
              value={friendId}
              onChange={handleSelectFriend}
              label={'partner'}
              friends={friendsAndRobots}
            />
          )}
        </div>
        {friendId === getRobotUserId() && (
          <LiaSystemDropdown liaSystem={liaSystem} handleChangeLiaSystem={handleChangeLiaSystem} />
        )}
        <div className="mt-4 flex w-80 items-start justify-between gap-4">
          <div className="flex w-20 shrink flex-col">
            <label className="label">
              <span className="label-text">{t('create_session.boards')}</span>
            </label>
            <input
              className="input w-20"
              type="number"
              min={1}
              onChange={handleChange}
              max={24}
              value={handCountInput}
            />
          </div>
          <div className="flex grow flex-col">
            <label className="label">
              <span className="label-text">{t('create_session.robots')}</span>
            </label>
            <Dropdown
              size={'normal'}
              value={robots}
              label={'Robots'}
              options={robotOptions}
            />
            {robots === 0 && (
              <AlertInline
                sx={'mt-2'}
                text={t('robots.none_explain')}
              />
            )}
            {robots === 1 && (
              <AlertInline
                sx={'mt-2'}
                onClick={() => handleChangeRobots(2)}
                text={t('robots.basic_explain')}
              />
            )}
          </div>
        </div>

        <div className="my-4">
          <Tabs>
            <Tab
              key={'public'}
              label={t('create_session.deal_types')}
              value={0}
              active={activeTab === 0}
              onChange={(i) => {
                setScenarios([]);
                setActiveTab(i);
              }}
            />
            <Tab
              key={'private'}
              label={t('create_session.scenarios')}
              value={1}
              active={activeTab === 1}
              onChange={(i) => {
                setTags([]);
                setActiveTab(i);
                if (!canUserDoScenarios) {
                  setPromptToSubscribe(true);
                }
              }}
            />
          </Tabs>
          <TabPanel key={'types'} value={activeTab} index={0}>
            <div className={'w-80 py-2 text-right'}>
              <div className="flex gap-2 m-1 flex-wrap">
                {tags.map((s, i) => {
                  return (
                    <div
                      key={'outer-badge' + s + i}
                      className="badge badge-accent justify-start text-ellipsis whitespace-nowrap text-start relative cursor-pointer"
                      onClick={() => handleRemoveTag(s)}
                    >
                      <span>{t(`deal_types.${dealTypes[s].tag}`)}</span>
                      <div
                        className={
                          'badge badge-error absolute -top-2 -right-2 z-10 p-1 leading-none text-xs pb-1.5'
                        }
                      >
                        x
                      </div>
                    </div>
                  );
                })}
              </div>
              <DealSourcesSelect
                selected={tags}
                setSelected={handleSetTags}
                fullWidth={isSmall}
                activeSubs={activeSubs}
                defaultTags={user?.practiceDefaultTags ?? []}
                label={false}
              />
              <span
                className="info"
              >
                <Link className={'underline-offset-2 underline decoration-2 decoration-primary'} to="/dealTypes">
                  {t('create_session.read_more_deal_types')}
                </Link>
              </span>
              {!canUseSelectedTags && (
                <AlertInline
                  sx="mt-1"
                  severity="warning"
                  text={t('create_session.sub_not_enough_deal_types')}
                  onClick={() =>
                    goToStore({
                      nav,
                      isNative,
                      iap,
                      uid: currentUser.uid,
                      tab: 'sub',
                    })
                  }
                />
              )}
            </div>
          </TabPanel>
          <TabPanel key={'scenarios'} value={activeTab} index={1}>
            <div className={'w-full py-2'}>
              <div className="w-80 flex gap-2 m-1 flex-wrap">
                {scenarios.map((s, i) => {
                  return (
                    <div
                      key={'outer-badge' + s + i}
                      className="badge badge-accent justify-start text-ellipsis whitespace-nowrap text-start relative cursor-pointer"
                      onClick={() => handleRemoveScenario(s)}
                    >
                      <span>{s.name}</span>
                      <div
                        className={
                          'badge badge-error absolute -top-2 -right-2 z-10 p-1 leading-none text-xs pb-1.5'
                        }
                      >
                        x
                      </div>
                    </div>
                  );
                })}
              </div>
              {canUserDoScenarios ? (
                <>
                  <ScenarioSelect
                    selected={scenarios}
                    setSelected={handleSetScenarios}
                    fullWidth={isSmall}
                    activeSubs={activeSubs}
                    defaultScenarioIds={user?.practiceDefaultScenarios ?? []}
                    label={false}
                    disableScenariosNotPlayableWithRobot={friendId === getRobotUserId()}
                  />
                  <span
                    className="info"
                    style={{ fontSize: '14px', marginTop: '4px' }}
                  >
                    {t('create_session.scenario_missing')}
                    <Link
                      style={{ textDecoration: 'underline' }}
                      to="/manageDealTypes"
                    >
                      {t('create_session.scenario_missing_info')}
                    </Link>
                  </span>
                </>
              ) : (
                <div className={'flex flex-col items-center'}>
                  <AlertInline
                    sx="mt-4"
                    severity="warning"
                    text={t('create_session.scenarios_requires_heart')}
                    onClick={() =>
                      goToStore({
                        nav,
                        isNative,
                        iap,
                        uid: currentUser.uid,
                        tab: 'sub',
                      })
                    }
                  />
                </div>
              )}
            </div>
          </TabPanel>
        </div>
        {price > 0 && <Price price={price} />}
        {friend && (
          <RemainingTicketsWarning
            myRemainingTickets={myRemainingTickets}
            partnerRemainingTickets={partnerRemainingTickets}
            price={price}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        <div className="indicator">
          {currentOnboardingStep === onboardingSteps.createSession &&
            friendId && (
            <div className="pulse w-5 badge badge-error indicator-item top-4" />
          )}
          <button
            className="btn-primary btn mt-4"
            disabled={
              !formValid ||
              loading ||
              (shouldForceAdvancedRobots && shouldForceNoRobots)
            }
            onClick={handleClick}
          >
            {t('create_session.create_new_session')}
          </button>
        </div>
      </div>
      <PriceDialog
        open={confirmationModalOpen}
        onClose={handleClose}
        title= {t('create_session.start_practice_session')}
        myRemainingTickets={myRemainingTickets}
        partnerRemainingTickets={partnerRemainingTickets}
        myCharge={myCharge}
        partnerCharge={partnerCharge}
        actions={[
          {
            component: (
              <div
                disabled={loading}
                role="button"
                key="join-daily"
                className="btn-primary btn"
                onClick={startSessionConfirmed}
              >
                {t('app.start')}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
