import { useTranslation } from 'react-i18next'
import { defaultLanguage } from '../../util/language.js'
import Alert from '../alert/alert.jsx'

export default function LanguageDisclaimer({language = defaultLanguage}) {
  const { i18n, t } = useTranslation()

  if (i18n.language === language) {
    return null;
  }

  if (language === "sv") {
    return (
      <Alert
        text={t('app.language_disclaimer_sv')}
      />
    );
  }

  if (language === "en") {
    return (
      <Alert
        text={t('app.language_disclaimer_en')}
      />
    );
  }

  // TODO: Throw error?
  return null;
}
