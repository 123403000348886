import { useEffect, useRef, useState } from 'react';
import BiddingDisplay from '../../../components/bidding/biddingDisplay';
import { getLiveBiddingToPresent } from '../utils';
import BiddingBox from '../../../components/bidding/biddingBox';
import {
  bidArrayToBidding,
  getAlert,
  getBidArrayWithAlertsIncludingPositionalSymbols,
  getBidArrayWithoutAlertsExcludingPositionalSymbols, getBidArrayWithoutAlertsIncludingPositionalSymbols,
  isBiddingFinished,
  removeAlerts,
} from 'cuebids-bidding-util'
import { getNewBidding, isCurrentBiddingPartOfSuggestedBidding } from 'cuebids-firebase/bidding';
import NextDealButton from './nextDealButton';
import SmallButton from '../../../components/buttons/smallButton';
import { ExclamIcon } from '../../../components/icons/exclamIcon';
import Alert from '../../../components/alert/alert';
import Animated from '../../../components/animation/animated';
import UserDisplay from '../../../components/users/userDisplay';
import {
  doubleUndo,
  undoLastBid,
} from '../../../firebase/biddingSessions';
import { isAcceptedInputForAlert } from '../../../util/text';
import { replaceSuitShorthandWithSymbols } from '../../../util/symbols';
import {
  hasCompletedOnboardingStep,
  onboardingSteps,
} from '../../../util/onboarding';
import { useNavigate } from 'react-router-dom';
import { useAppStateStore } from '../../../appStateStore';
import moveToNextDeal from './moveToNextDeal';
import useConfirm from '../../../hooks/confirm.jsx';
import { Timer } from '../../../components/areaCards/challengeTimer.jsx'
import { prepareBidOnSessionDeal, bidOnSessionDeal } from 'cuebids-firebase/bidding';
import { updateAlert } from 'cuebids-firebase/bidding'
import { useOutsideClick } from '../../../util/hooks.jsx'
import { getRobotUserId } from '../../../util/robotPartner.js'
import { useQuery } from '@tanstack/react-query';
import { httpGetLiaAlerts } from 'cuebids-firebase/bidding';
import SessionTypeIcon from '../../../components/sessionTypeIcon/sessionTypeIcon';
import useNotifications from '../../../components/notifications/useNotifications';
import { useTranslation } from 'react-i18next';
import { RobotSystemDisplay } from './robotSystemDisplay';
const confirmFinalBidTimeSeconds = 5;

function checkIfSuggestedBidding(bidding, suggestedBidding, bid) {
  const suggestedBiddingArray = getBidArrayWithoutAlertsIncludingPositionalSymbols(suggestedBidding)
  const currentBidArray = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding)

  const stillOnTrack = isCurrentBiddingPartOfSuggestedBidding(currentBidArray, suggestedBiddingArray)
  const nextInLine = suggestedBiddingArray[currentBidArray.length]

  return {
    firstDeviation: stillOnTrack && nextInLine !== bid,
    inSuggestedBidding: stillOnTrack
  }
}

// If my last bid was a pass that could end the bidding
// Assumes it is partner's turn to bid
function hasConfirmedBid(bidding) {
  const bids = getBidArrayWithoutAlertsExcludingPositionalSymbols(bidding);

  if (bids.length < 4) {
    return false;
  }

  const myLastBid = bids.at(-2);
  if (myLastBid !== 'P') {
    return false;
  }

  const rhoLastBid = bids.at(-3);
  if (rhoLastBid !== 'P') {
    return false;
  }

  return true;
}

export default function ActiveSessionDeal({
  sessionDeal,
  session,
  bottomHandDirection,
  currentUser,
  height,
  isKibitzing,
  loading,
  setLoading,
  showAlert,
  setShowAlert,
  chatOpen,
  partner,
  marked,
  markForDiscussion,
  setShouldAnimateResult,
  alertPopup,
  setAlertPopup,
}) {
  const sessionDeals = useAppStateStore((state) => state.sessionDeals);
  const user = useAppStateStore((state) => state.user);
  const notify = useNotifications();
  const navigate = useNavigate();
  const alertRef = useRef();
  const [unconfirmedBid, setUnconfirmedBid] = useState(null);
  const [unconfirmedBidTimestamp, setUnconfirmedBidTimestamp] = useState(null);
  const unconfirmedBidTimeoutIdRef = useRef();
  const [alerted, setAlerted] = useState('');
  const [ConfirmDoubleUndo, confirmDoubleUndo] = useConfirm(
    'app.confirm_title',
    'session_deal.double_undo_confirm',
    'primary',
    'secondary',
  );

  const { t } = useTranslation();

  const [, setDummyCounter] = useState(0); // Just used to trigger re-render
  const [preparePromise, setPreparePromise] = useState();

  const [alertPopupValue, setAlertPopupValue] = useState('');

  const lastBidTimestamp = sessionDeal.clientLastBidTimestamp ?? sessionDeal.lastBidTimestamp;
  const isCompetition = (sessionDeal.type ?? 'practice') !== 'practice';

  const hasLoadedSession = session && (session.id === sessionDeal.sessionId);

  const hasRobotPartner = sessionDeal.users.includes(getRobotUserId());

  const [ConfirmNotRecommendedBid, confirmNotRecommended, setNotRecommendedContext] = useConfirm(
    'app.confirm_title',
    'session_deal.not_recommended_confirm',
    'primary',
    'secondary',
  );

  const conventions = user?.conventions ?? [];


  const { data: nextBidAlerts, } = useQuery({
    queryKey: ['nextBidAlerts', hasRobotPartner, sessionDeal.bidding],
    queryFn: async () => {
      if (hasRobotPartner) {
        return await httpGetLiaAlerts(sessionDeal, getRobotUserId(), conventions, t('lia_lang'))
      } else {
        return null;
      }
    },
  });

  const currentDealIndex = sessionDeal
    ? sessionDeals.map((s) => s.dealId).indexOf(sessionDeal.dealId)
    : 0;

  const alertPopupRef = useRef();

  useOutsideClick(alertPopupRef, () => hideAlertPopup());

  useEffect(function () {
    if (isCompetition && lastBidTimestamp && ((lastBidTimestamp.toMillis() + 30000) > Date.now())) {
      // Time to make undo is in the future, set timeout to when it happens to disable undo button with re-render.
      let timeoutId = setTimeout(function() {
        setDummyCounter((c) => c + 1);
      }, lastBidTimestamp.toMillis() + 30000 - Date.now());

      return function() {
        clearTimeout(timeoutId);
      }
    }
  }, [isCompetition, lastBidTimestamp]);

  useEffect(function () {
    return function() {
      // If partner does undo we must cancel our pending bid
      // Put this in return to also clear if we leave the deal during timeout
      // It also helps (probably) to not have a render cycle with old unconfirmed bid when transaction has loaded new bidding
      setShouldAnimateResult(false);
      setUnconfirmedBid(null);
      setUnconfirmedBidTimestamp(null);
      setPreparePromise(undefined);
      setAlerted('');
      if (unconfirmedBidTimeoutIdRef.current) {
        clearTimeout(unconfirmedBidTimeoutIdRef.current);
      }
    }
  }, [sessionDeal.bidding, sessionDeal.coachRobot?.name, sessionDeal.id, setShouldAnimateResult])

  const handleBidOnSessionDeal = async (newBid, _preparePromise) => {
    setLoading(true);
    try {
      // Since we can call this from timeout, we need to make sure session is not stale
      const _session = useAppStateStore.getState().currentSession;

      const isFinalBid = await bidOnSessionDeal({
        sessionDeal,
        numberOfRemainingDeals: _session?.dealsCount - _session?.numberOfFinishedDeals,
        preparePromise: _preparePromise || preparePromise,
        weeklySessionId: _session?.weekly,
        dailySessionId: _session?.daily,
        groupSessionId: _session?.groupSession
      });
      if (!isFinalBid && (user?.autoNext ?? false)) {
        moveToNextDeal({
          sessionDeals: sessionDeals,
          sessionId: sessionDeal.sessionId,
          navigate,
          userId: currentUser.uid,
          currentDealIndex,
        });
      } else {
        setLoading(false);
        setShowAlert(false);
        setAlertPopup({ show: false, bidIndex: -1 });
        setAlertPopupValue('');
        setAlerted('');
        setUnconfirmedBidTimestamp(null);
        // Note: unconfirmedBid cleared in effect
        // (Since transaction does not do same optimistic updates this was needed to avoid flicker)
        // (However, the timestamp is cleared to avoid another flicker)
        // TODO: Is there a better way to do this?
      }
    } catch (e) {
      setLoading(false);
      setUnconfirmedBid(null);
      setUnconfirmedBidTimestamp(null);
      notify(
        { type: 'failure', text: 'Something went wrong' },
      );
    }
  };

  const activeDealShortCompactBidding = height < 750;

  const hideAlertPopup = async () => {
    if (alertPopup.show) {
      await handleAlertExistingBid(alertPopup.bidIndex, alertPopupValue);
      setAlertPopup({
        show: false,
        bidIndex: -1
      });
    }
  }

  const handleAlertExistingBid = async (biddingIndex, newAlert) => {
    let bids = getBidArrayWithAlertsIncludingPositionalSymbols(sessionDeal.bidding)
    const withoutAlert = removeAlerts(bids[biddingIndex])
    bids[biddingIndex] = withoutAlert + '[@"' + newAlert + '"]'
    const newBidding = bidArrayToBidding(bids);
    await updateAlert(sessionDeal, newBidding)
  }

  const handleOpenAlertForBid = (bid, biddingIndex, biddingIndexForRow) => {
    if (sessionDeal.turn !== currentUser.uid) return;
    if (hasRobotPartner) return;

    let bids = getBidArrayWithAlertsIncludingPositionalSymbols(sessionDeal.bidding)

    // TODO: Can calculate biddingIndexForRow from bidding and biddingIndex
    if (biddingIndexForRow === 3) {
      const alert = getAlert(bids[biddingIndex])
      setAlertPopupValue(alert);
      setAlertPopup({ show: true, bidIndex: biddingIndex });
    } else {
      setAlertPopupValue('');
      setAlertPopup({ show: false, bidIndex: -1 });
    }
  }

  const handleChangeAlert = (a) => {
    const withSymbols = replaceSuitShorthandWithSymbols(a);
    if (isAcceptedInputForAlert(withSymbols)) {
      setAlerted(withSymbols);
    }
  };

  const showOnboarding = !hasCompletedOnboardingStep({ user, step: onboardingSteps.finishSession });

  return (
    <>
      <div className="flex max-h-[525px] grow flex-col items-center justify-around md:max-h-[750px]">
        <div className={'flex justify-center'}>
          <div className={'w-[100px]'} />
          <BiddingDisplay
            bidding={getLiveBiddingToPresent(
              sessionDeal.bidding,
              bottomHandDirection,
              unconfirmedBid
            )}
            onBidClick={handleOpenAlertForBid}
            vulnerability={sessionDeal.vulnerability}
            variant={activeDealShortCompactBidding ? 'compact' : 'standard'}
            startWithEast={bottomHandDirection === 'N'}
            hideAlertsIndex={
              sessionDeal.users.includes(getRobotUserId()) ||
              sessionDeal.finished ||
              isKibitzing
                ? -1
                : 1
            }
          />
          <div className={'w-[100px] flex flex-col gap-4 px-2'}>
            <div className="flex flex-col items-center justify-center mt-1">
              <SessionTypeIcon session={session} />
              {session?.proChallenge && (
                <div className="badge badge-xs badge-secondary">PRO</div>
              )}
            </div>
            <RobotSystemDisplay session={session} />
          </div>
        </div>

        {alertPopup?.show && (
          <div
            ref={alertPopupRef}
            className="absolute fade-in top-20 right-5 z-[9999] bg-base-100 border-sky-400 border-2 rounded-xl"
          >
            <input
              value={alertPopupValue}
              ref={alertRef}
              autoFocus
              autoComplete="off"
              onChange={(e) => {
                const withSymbols = replaceSuitShorthandWithSymbols(e?.target?.value);
                if (isAcceptedInputForAlert(withSymbols)) {
                  setAlertPopupValue(withSymbols);
                }
              }}
              type="text"
              placeholder="Artificial"
              className="input-primary input w-full border-0 bg-primary text-white caret-secondary"
            />
          </div>
        )}
        <div className="flex h-[220px] w-full flex-col items-center">
          {!loading &&
            hasLoadedSession &&
            (sessionDeal.turn === currentUser.uid ? (
              unconfirmedBid ? (
                unconfirmedBidTimestamp ? (
                  <div className="flex flex-col items-center gap-1">
                    <span>{t('session_deal.sure')}</span>
                    <div className="flex items-center gap-3">
                      <button
                        className={
                          'btn-small btn-outline btn-secondary btn gap-1 flex flex-col'
                        }
                        type="submit"
                        onClick={() => {
                          setUnconfirmedBid(null);
                          setUnconfirmedBidTimestamp(null);
                          clearTimeout(unconfirmedBidTimeoutIdRef.current);
                        }}
                      >
                        {t('session_deal.undo')}
                      </button>
                      <div className="w-2">
                        <Timer
                          seconds={confirmFinalBidTimeSeconds}
                          compare={unconfirmedBidTimestamp}

                          // TODO: This resulted in both this and the normal timeout firing -> error message for one
                          // If we get problem with negative timer here we could maybe remove the other timeout and only use this callback
                          // (But maybe there is a risk of the callback firing multiple times as well?)
                          // callback={() => {
                          //   if (unconfirmedBidTimeoutIdRef.current) {
                          //     clearTimeout(unconfirmedBidTimeoutIdRef.current);
                          //     handleBidOnSessionDeal(unconfirmedBid, preparePromise);
                          //   }
                          // }}
                        />
                      </div>
                      <button
                        className={
                          'btn-small btn-primary btn gap-1 flex flex-col'
                        }
                        type="submit"
                        onClick={() => {
                          clearTimeout(unconfirmedBidTimeoutIdRef.current);
                          handleBidOnSessionDeal(
                            unconfirmedBid,
                            preparePromise
                          );
                        }}
                      >
                        {t('session_deal.confirm')}
                      </button>
                    </div>
                  </div>
                ) : null
              ) : (
                <>
                  <BiddingBox
                    bidding={sessionDeal.bidding}
                    acceptShortcuts={
                      !showAlert && !chatOpen && !alertPopup?.show
                    }
                    alerts={sessionDeal.coachRobot ? {} : nextBidAlerts}
                    onBid={async function (bid) {
                      if (loading || !hasLoadedSession) return;

                      if (
                        sessionDeal.coachRobot &&
                        sessionDeal.suggestedBidding?.bidding
                      ) {
                        const suggested = sessionDeal.suggestedBidding?.bidding;
                        const { firstDeviation, inSuggestedBidding } =
                          checkIfSuggestedBidding(
                            sessionDeal.bidding,
                            suggested,
                            bid
                          );

                        if (
                          inSuggestedBidding &&
                          firstDeviation &&
                          sessionDeal.coachRobot.warning
                        ) {
                          setNotRecommendedContext({
                            uid: getRobotUserId(),
                            name: sessionDeal.coachRobot?.name,
                          });
                          const confirmed = await confirmNotRecommended();
                          if (!confirmed) return;
                        }
                      }
                      const isPotentialFinalBid = isBiddingFinished(
                        getNewBidding({
                          sessionDeal,
                          bid,
                          compete: 0,
                        })
                      );
                      const _preparePromise = prepareBidOnSessionDeal({
                        sessionDeal,
                        bid,
                        alert: showAlert ? alerted : '',
                        challengeId: session.challenge,
                      });
                      setPreparePromise(_preparePromise);
                      if (isPotentialFinalBid) {
                        setShouldAnimateResult(true); // Must be set beforehand
                        setUnconfirmedBid(bid);
                        setUnconfirmedBidTimestamp(Date.now());
                        unconfirmedBidTimeoutIdRef.current = setTimeout(
                          function () {
                            handleBidOnSessionDeal(bid, _preparePromise);
                          },
                          confirmFinalBidTimeSeconds * 1000
                        );
                      } else {
                        handleBidOnSessionDeal(bid, _preparePromise);
                      }
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 10
                    }}
                  >
                    {!showAlert && (
                      <button
                        className="btn-outline btn-primary btn"
                        onClick={markForDiscussion}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={
                            'h-6 w-6  ' +
                            (marked ? 'stroke-secondary' : 'normal-stroke')
                          }
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            d="M6 5H12.1716C12.702 5 13.2107 5.21071 13.5858 5.58579L14.4142 6.41421C14.7893 6.78929 15.298 7 15.8284 7H18C19.1046 7 20 7.89543 20 9V13C20 14.1046 19.1046 15 18 15H14.8284C14.298 15 13.7893 14.7893 13.4142 14.4142L12.5858 13.5858C12.2107 13.2107 11.702 13 11.1716 13H6M6 3V21"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    )}
                    {!showAlert &&
                      sessionDeal.type === 'practice' &&
                      getBidArrayWithoutAlertsExcludingPositionalSymbols(
                        sessionDeal.bidding
                      ).length >= 4 && (
                      <button
                        className={
                          'btn-small btn-outline btn-secondary btn'
                        }
                        onClick={async () => {
                          if (
                            getBidArrayWithoutAlertsExcludingPositionalSymbols(
                              sessionDeal.bidding
                            ).length < 4
                          ) {
                            return;
                          }
                          const ans = await confirmDoubleUndo();
                          if (ans) {
                            setLoading(true);
                            await doubleUndo({ deal: sessionDeal });
                            setLoading(false);
                          }
                        }}
                      >
                        {t('session_deal.undo')}
                      </button>
                    )}
                    {!hasRobotPartner && (
                      <SmallButton
                        icon={<ExclamIcon fill="#bfcbff" />}
                        text={'Alert'}
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          setShowAlert(() => !showAlert);
                        }}
                      />
                    )}
                    {showAlert && (
                      <input
                        value={alerted}
                        ref={alertRef}
                        autoFocus
                        autoComplete="off"
                        onChange={(e) => handleChangeAlert(e?.target?.value)}
                        type="text"
                        placeholder="Artificial"
                        className="input-primary input w-full border-0 bg-primary text-white caret-secondary"
                      />
                    )}
                  </div>
                </>
              )
            ) : (
              <>
                {showOnboarding && (
                  <Alert
                    sx="border-pulse"
                    text={t('onboarding.step_bidding.partners_turn_deal')}
                  />
                )}
                <div className="mt-2 flex w-1/2 flex-col items-center justify-center gap-4">
                  <h2>{t('session_deal.waiting_for_partner')}</h2>
                  {!showOnboarding && (
                    <Animated initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                      <UserDisplay uid={partner.id} />
                    </Animated>
                  )}
                  <div className="flex min-w-[250px] justify-between">
                    <button
                      className="btn-outline btn-primary btn"
                      onClick={markForDiscussion}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={
                          'h-6 w-6  ' +
                          (marked ? 'stroke-secondary' : 'stroke-primary')
                        }
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          xmlns="http://www.w3.org/2000/svg"
                          d="M6 5H12.1716C12.702 5 13.2107 5.21071 13.5858 5.58579L14.4142 6.41421C14.7893 6.78929 15.298 7 15.8284 7H18C19.1046 7 20 7.89543 20 9V13C20 14.1046 19.1046 15 18 15H14.8284C14.298 15 13.7893 14.7893 13.4142 14.4142L12.5858 13.5858C12.2107 13.2107 11.702 13 11.1716 13H6M6 3V21"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <button
                      style={{
                        visibility:
                          getBidArrayWithoutAlertsExcludingPositionalSymbols(
                            sessionDeal.bidding
                          ).length >= 2
                            ? 'visible'
                            : 'hidden',
                      }}
                      disabled={
                        isCompetition &&
                        (hasConfirmedBid(sessionDeal.bidding) ||
                          !lastBidTimestamp ||
                          Date.now() >= lastBidTimestamp?.toMillis() + 30000)
                      }
                      className={
                        'btn-small btn-outline btn-secondary btn gap-1 flex flex-col'
                      }
                      type="submit"
                      onClick={async () => {
                        if (
                          getBidArrayWithoutAlertsExcludingPositionalSymbols(
                            sessionDeal.bidding
                          ).length < 2
                        ) {
                          return;
                        }
                        setLoading(true);
                        try {
                          await undoLastBid({ deal: sessionDeal });
                        } catch (e) {
                          notify(
                            {
                              text:
                                  t('session_deal.undo_failed') +
                                (isCompetition
                                  ? t('session_deal.undo_failed_competition')
                                  : ''),
                              type: 'error',
                            },
                          );
                        }
                        setLoading(false);
                      }}
                    >
                      {t('session_deal.undo')}
                      {isCompetition &&
                        !hasConfirmedBid(sessionDeal.bidding) &&
                        lastBidTimestamp &&
                        Date.now() < lastBidTimestamp?.toMillis() + 30000 && (
                        <Timer
                          seconds={30}
                          compare={lastBidTimestamp?.toMillis()}
                          callback={function () {
                            setDummyCounter((c) => c + 1);
                          }}
                        />
                      )}
                    </button>
                    <NextDealButton
                      sessionDeals={sessionDeals}
                      userId={currentUser.uid}
                      sessionId={sessionDeal.sessionId}
                      showOnboarding={showOnboarding}
                      currentDealIndex={currentDealIndex}
                    />
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
      <ConfirmDoubleUndo />
      <ConfirmNotRecommendedBid />
    </>
  );
}
