// Note: No constructive bids here (won't bid game)

const ntDoubledEscapes = [
  // TODO: Opener redouble as escape
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-D$/,
    explain: 'Partner opens or overcalls 1N, they double',
    options: [
      {
        bid: '2C["To play"]',
        reqC: 5,
      },
      {
        bid: '2D["To play"]',
        reqD: 5,
      },
      {
        bid: '2H["To play"]',
        reqH: 5,
      },
      {
        bid: '2S["To play"]',
        reqS: 5,
      },
      {
        bid: 'R[@"4-4+!C and another suit"]',
        reqC: 4,
        reqD: 4,
      },
      {
        bid: 'R[@"4-4+!C and another suit"]',
        reqC: 4,
        reqH: 4,
      },
      {
        bid: 'R[@"4-4+!C and another suit"]',
        reqC: 4,
        reqS: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-D-R-P$/,
    explain: 'We open 1N or overcalled, they double, partner redoubles as escape',
    options: [
      {
        bid: '2C["To play"]',
        reqC: 4,
      },
      {
        bid: '2H["To play"]',
        reqH: 5,
      },
      {
        bid: '2S["To play"]',
        reqS: 5,
      },
      {
        bid: '2D[@"Pass or correct"]',
        reqStrains: function(strains) {
          return (
            // When all other suits are at least as good as clubs we try for a better fit
            strains.D >= strains.C &&
            strains.H >= strains.C &&
            strains.S >= strains.C
          );
        },
      },
      {
        bid: '2C["To play"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-D-R-P-2D-[PD]$/,
    explain:
      'Partner opens or overcalls 1N, they double, we redouble as escape, opener bids pass/correct',
    options: [
      {
        bid: 'P["4+!D"]',
        reqD: 4,
      },
      {
        bid: '2H["To play, 4+!H"]',
        reqH: 4,
      },
      {
        bid: '2S["To play, 4+!S"]',
        reqS: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-D-P-P$/,
    explain: "We open or overcalls 1N, they double, opener's escape",
    options: [
      {
        bid: '2C["To play"]',
        reqC: 5,
      },
      {
        bid: '2D["To play"]',
        reqD: 5,
      },
      {
        bid: '2H["To play"]',
        reqH: 5,
      },
      {
        bid: '2S["To play"]',
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-P-D$/,
    explain: "We open or overcalls 1N, they double in last seat, opener's escape",
    options: [
      {
        bid: '2C["To play"]',
        reqC: 5,
      },
      {
        bid: '2D["To play"]',
        reqD: 5,
      },
      {
        bid: '2H["To play"]',
        reqH: 5,
      },
      {
        bid: '2S["To play"]',
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-P-D-P-P$/,
    explain: "Partner opens or overcalls 1N, they double in last seat, responder's escape",
    options: [
      {
        bid: '2C["To play"]',
        reqC: 5,
      },
      {
        bid: '2D["To play"]',
        reqD: 5,
      },
      {
        bid: '2H[@"4-4 majors"]',
        reqH: 4,
        reqS: 4,
      },
      {
        bid: 'R[@"4-4+!C and another suit"]',
        reqC: 4,
        reqD: 4,
      },
      {
        bid: 'R[@"4-4+!C and another suit"]',
        reqC: 4,
        reqH: 4,
      },
      {
        bid: 'R[@"4-4+!C and another suit"]',
        reqC: 4,
        reqS: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-P-D-P-P-2H-[PD]$/,
    explain:
      'Partner opens or overcalls 1N, they double in last seat, responder shows both majors',
    options: [
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '2S["To play"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-P-D-P-P-R-P$/,
    explain:
      'We open 1N or overcall, they double in last seat, responder redoubles as escape',
    options: [
      {
        bid: '2C["To play"]',
        reqStrains: function(strains) {
          return (
            strains.C > strains.D ||
            strains.C > strains.H ||
            strains.C >= strains.S
          );
        },
      },
      {
        bid: '2D[@"Pass or correct"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-P-D-P-P-R-P-2D-[PD]$/,
    explain:
      'Partner opens or overcalls 1N, they double in last seat, we redouble as escape, opener bids pass/correct',
    options: [
      {
        bid: 'P["4+!D"]',
        reqD: 4,
      },
      {
        bid: '2H["To play, 4+!H"]',
        reqH: 4,
      },
      {
        bid: '2S["To play, 4+!S"]',
        reqS: 4,
      },
    ],
  },
];

export default ntDoubledEscapes;
