import { GroupsIcon } from '../icons/groupsIcon';

import { Link } from 'react-router-dom';

export default function GroupSessionHeader({
  groupName,
  name,
  description,
  links,
  groupId,
  groupFlag,
}) {
  let groupHeader;

  const groupIcon = groupFlag ? (
    <span className={`fi fi-${groupFlag} w-4`}></span>
  ) : (
    <GroupsIcon className="fill-blue-400 w-4" />
  )

  if (links) {
    groupHeader = (
      <Link to={`/groups/${groupId}`}>
        <div className="flex items-center gap-2 my-2">
          {groupIcon}
          <div className="info">{groupName}</div>
        </div>
      </Link>
    );
  } else {
    groupHeader = (
      <div className="flex items-center gap-2 my-2">
        {groupIcon}
        <div className="info">{groupName} </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center text-center">
      <div className="text-3xl">{name}</div>
      {description && <div className="text-xl mb-2">{description}</div>}
      {groupHeader}
    </div>
  );
}
