import { docsToObjects, queryFirstItemToObject } from 'firebase-util';
import { addDoc, getDocs, limit, onSnapshot, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { currentUserId, getFunctions, getRepos } from '../base';
export async function getAllProducts() {
    const q = query(getRepos().products.collection);
    const querySnapshot = await getDocs(q);
    const prods = docsToObjects(querySnapshot);
    const productArrayWithPrices = await Promise.allSettled(prods.map(async (p) => {
        const prices = await getPrices(p.id);
        return {
            ...p,
            prices
        };
    }));
    return productArrayWithPrices
        .filter((result) => result.status === 'fulfilled')
        .map((result) => result.value);
}
export async function getCustomerPortalLink() {
    const functionRef = httpsCallable(getFunctions(), 'ext-firestore-stripe-payments-createPortalLink');
    return await functionRef({
        returnUrl: `${window.location.origin}/settings/`,
        locale: 'auto'
    });
}
function getCancelUrl(tab, sub) {
    return window.location.origin + '/store?status=failed' + `&tab=${tab}` + (sub != null ? `&sub=${sub}` : '');
}
export async function createCheckoutSession(data) {
    const func = httpsCallable(getFunctions(), 'createCheckoutSession');
    const res = await func(data);
    return res.data;
}
export async function addGift({ checkoutSessionId, email, name, message, friendEmail, friendName }) {
    return await getRepos().gifts.add?.({
        checkoutSessionId,
        email,
        name,
        message,
        friendEmail,
        friendName,
        paid: false
    });
}
export async function updateGift({ ref, checkoutSessionId }) {
    await getRepos().gifts.update?.(ref, {
        checkoutSessionId
    });
}
export async function setGiftPaid(id) {
    const ref = getRepos().gifts.docRef(id);
    await getRepos().gifts.update?.(ref, {
        paid: true
    });
}
export async function startCheckoutSessionPremium(price, premiumGroupId, callback) {
    const docRef = await addDoc(getRepos().checkoutSessions.collectionById(currentUserId()), {
        price: price.id,
        automatic_tax: true,
        allow_promotion_codes: true,
        promotion_code: '',
        trial_from_plan: true,
        mode: (price.interval != null) ? 'subscription' : 'payment',
        success_url: window.location.origin + '/joinGroup/' + premiumGroupId + '?status=welcome',
        cancel_url: window.location.origin + '/joinGroup/' + premiumGroupId + '?status=failed'
    });
    // Wait for the CheckoutSession to get attached by the extension
    const unsubscribe = onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error != null) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
            unsubscribe();
            callback();
        }
        if (url != null) {
            unsubscribe();
            callback();
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
        }
    });
}
export function eligibleForTrial(price, uid) {
    const hasTrial = (price.trial_period_days ?? 0) > 0;
    const customerInBanList = [
        // 'Skt0yvf3YvZB0UZ1pZqX9BGDFpj1', // dev test
        '4E5eN1Q4dqZU3U28ouSWWDJBLhg1'
    ].includes(uid);
    return hasTrial && !customerInBanList;
}
export async function startCheckoutSession(price, productName, callback, promo, tab, sub, trial = false) {
    const docRef = await addDoc(getRepos().checkoutSessions.collectionById(currentUserId()), {
        price: price.id,
        automatic_tax: true,
        allow_promotion_codes: true,
        promotion_code: promo,
        trial_from_plan: trial,
        mode: (price.interval != null) ? 'subscription' : 'payment',
        success_url: window.location.origin + '/thanks/' + productName,
        cancel_url: getCancelUrl(tab, sub)
    });
    // Wait for the CheckoutSession to get attached by the extension
    const unsubscribe = onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error != null) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
            unsubscribe();
            callback();
        }
        if (url != null) {
            unsubscribe();
            callback();
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
        }
    });
}
async function getPrices(productId) {
    const q = query(getRepos().productPrices.collectionById(productId), where(getRepos().productPrices.fieldName('active'), '==', true));
    const querySnapshot = await getDocs(q);
    return docsToObjects(querySnapshot);
}
export async function getUserCurrency() {
    const q = query(getRepos().userPayments.collectionById(currentUserId()), limit(1));
    const payment = await queryFirstItemToObject(q);
    if (payment == null)
        return null;
    return payment.currency;
}
