import {
  getContractDeclarer,
  getContracts,
  getHighestRobotBid,
  parseBidding
} from 'cuebids-bidding-util';
import { evaluate } from 'cuebids-evaluation';
import HandDiagram from '../cards/diagram.jsx';
import BiddingDisplay from '../bidding/biddingDisplay.jsx';
import { ContractDisplay } from '../bidding/bidDisplay.jsx';
import { StarRating } from '../rating/starRating.jsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'

export function UnscoredDeal({ deal, onScore, loading }) {
  const { t } = useTranslation();

  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    const handleContracts = (hand) => {
      if(!hand.score) {
        return;
      }

      const { finalBid, declarer, doubled } = parseBidding(
        hand.bidding,
        hand.version ?? 3
      )

      const highestRobotBid = getHighestRobotBid(hand.bidding)

      const evaluation = evaluate({
        deal: hand,
        finalBid,
        declarer,
        highestRobotBid,
        doubled,
        vulnerability: hand.vulnerability,
        bidding: hand.bidding,
        compete: 2,
        ai: ''
      })

      evaluation.contract = finalBid + doubled + declarer;
      if(finalBid === 'P') {
        evaluation.contract = 'P';
      }

      const contracts = getContracts(evaluation);

      setContracts(contracts)
    }

    if (deal?.score) {
      handleContracts(deal)
    }
  }, [deal, deal.scoreKey]);

  if(!deal) return null;

  const hand = deal;

  return (
    <div className={'relative w-full'}>
      <div className={'grid grid-cols-2 w-full'}>
        <HandDiagram hand={hand.hand} vulnerability={hand.vulnerability} dealer={hand.dealer} />
        {
          hand.bidding && <div className={'flex items-center justify-center'}>
            <BiddingDisplay vulnerability={hand.vulnerability} bidding={hand.bidding} variant={'compact full'}  />
          </div>
        }
        {
          loading ?
            <div className={'w-full flex items-center justify-center mt-4'}>
              <span className="loading loading-ring loading-lg"></span>
            </div>:
            <div className={'m-2 flex items-start justify-around w-full'}>
              {
                contracts?.length > 0 && (
                  <table>
                    <thead>
                      <tr>
                        <th>{t('components.unscored_deal.contract')}</th>
                        <th>{t('components.unscored_deal.stars')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contracts.map((c, i) => {
                        return (
                          <tr key={i}>
                            <td width={100}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <ContractDisplay size={40} contract={c.contract} />
                                <div style={{ width: '5px', height: '0px' }} />
                                {c.contract.length > 1 &&
                                            getContractDeclarer(c.contract)}
                              </div>
                            </td>
                            <td>
                              <StarRating stars={c.stars} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )
              }

            </div>
        }

        <div className={'flex gap-2 items-end mt-4 w-full justify-end col-span-2'}>
          <button  className={'btn btn-primary'} disabled={loading} onClick={() => onScore('INSTANT')}>{t('components.unscored_deal.fast_scoring')}</button>
          <button  className={'btn btn-secondary'} disabled={loading} onClick={() => onScore('STANDARD')}>{t('components.unscored_deal.regular_scoring')}</button>
        </div>
      </div>
    </div>
  )
}
