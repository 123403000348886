import { ranges } from './constants';

const respondingTo1Minor = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-[PD]$/,
    explain: 'partner opened 1m, no interference',
    options: [
      {
        bid: '1D["4+!D"]',
        reqHp: ranges.newSuitForced,
        reqD: 4,
        reqStrains: function(strains) {
          return ((strains.D > (strains.S + 1)) || strains.S < 4)
          && ((strains.D > (strains.H + 1)) || strains.H < 4)
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-[PD]$/,
    explain: 'partner opened 1m, no real interference',
    options: [
      {
        bid: '1H["4+!H"]',
        reqHp: ranges.newSuitForced,
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.D;
        },
      },
      {
        bid: '1S["4+!S"]',
        reqHp: ranges.newSuitForced,
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.D;
        },
      },
      {
        bid: '2o["9+HCP, 4+!o"]',
        reqHp: ranges.invitational,
        reqFit: 5,
      },
      {
        bid: '1N["Not forcing"]',
        reqHp: ranges.newSuitUnforced,
        reqMaxS: 3,
        reqMaxH: 3,
        reqMaxFit: 4,
        lastBid: 'P',
      },
      {
        bid: '1N["Not forcing"]',
        reqHp: ranges.invitational,
        reqMaxS: 3,
        reqMaxH: 3,
        reqMaxFit: 4,
        lastBid: 'D',
      },
      {
        bid: '2o["9+HCP, 4+!o"]',
        reqHp: ranges.invitational,
        reqFit: 4,
      },
      {
        bid: '3o["Weak, 5+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 5,
      },
      {
        bid: '2H["Weak, 6+!H"]',
        reqHp: ranges.weakJumpShift,
        reqMaxHp: ranges.weakOpen,
        reqMaxFit: 4,
        reqH: 6,
        weight: 2,
      },
      {
        bid: '2S["Weak, 6+!S"]',
        reqHp: ranges.weakJumpShift,
        reqMaxHp: ranges.weakOpen,
        reqMaxFit: 4,
        reqS: 6,
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-[PD]-2C-(.|..)$/,
    explain: 'Responding to inverted minor (C) after any interference',
    options: [
      {
        bid: '3C["Minimum"]',
        reqC: 3,
        weight: 0.1,
      },
      {
        bid: '4C["Values to compete"]',
        reqC: 4,
        reqParC: 100,
        weight: 1.1,
      },
      {
        bid: '5C["To play"]',
        reqC: 4,
        reqParC: 130,
        weight: 1.2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-[PD]-2D-(.|..)$/,
    explain: 'Responding to inverted minor (D) after any interference',
    options: [
      {
        bid: '3D["Minimum"]',
        reqD: 3,
        weight: 0.1,
      },
      {
        bid: '4D["Values to compete"]',
        reqD: 4,
        reqParD: 100,
        weight: 1.1,
      },
      {
        bid: '5D["To play"]',
        reqD: 4,
        reqParD: 130,
        weight: 1.2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-[PD]-2C-(.|..)-(.|..)-(.|..)$/,
    explain: 'Rebidding as inverted minor (C) after any interference',
    options: [
      {
        bid: '3C["To play"]',
        weight: 0.1,
      },
      {
        bid: '4C["Values to compete"]',
        reqC: 4,
        reqParC: 100,
        weight: 1.1,
      },
      {
        bid: '5C["To play"]',
        reqC: 4,
        reqParC: 130,
        weight: 1.2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-[PD]-2D-(.|..)-(.|..)-(.|..)$/,
    explain: 'Rebidding as inverted minor (D) after any interference',
    options: [
      {
        bid: '3D["To play"]',
        weight: 0.1,
      },
      {
        bid: '4D["Values to compete"]',
        reqD: 4,
        reqParD: 100,
        weight: 1.1,
      },
      {
        bid: '5D["To play"]',
        reqD: 4,
        reqParD: 130,
        weight: 1.2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-[PD]-1[HS]-[PD]$/,
    explain: "We opened 1D, partner bid a new suit, opener's rebid",
    options: [
      {
        bid: '2C["4+!C, not balanced"]',
        reqMaxR: 3,
        reqC: 4,
        reqD: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-[PD]-1[DHS]-[PD]$/,
    explain: "We opened 1m, partner bid a new suit, opener's rebid",
    options: [
      {
        bid: '2r["4!r"]',
        reqR: 4,
      },
      {
        // TODO: Is this bid too narrow in reqs?
        bid: '3r["13—15 HCP, 4!r"]',
        reqR: 4,
        reqFit: 5,
        reqHp: 13,
        reqMaxHp: 15,
        weight: 2,
      },
      {
        bid: '1H["4+!H, not balanced"]',
        reqH: 4,
        reqFit: 5,
      },
      {
        bid: '1S["4+!S, not balanced"]',
        reqS: 4,
        reqFit: 5,
      },
      {
        bid: '2o["6+!o"]',
        reqFit: 6,
      },
      {
        bid: '1N["12-14"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-[PD]-1D-[PD]-1H-[PD]$/,
    explain: 'We opened 1C, responder’s preference after opener rebids 1H',
    options: [
      {
        bid: '2H["4+!H"]',
        reqH: 4,
      },
      {
        bid: '2o["Preference"]',
        reqFit: 3,
      },
      {
        bid: '2r["6+!r"]',
        reqR: 6,
      },
      {
        bid: '1N',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-[PD]-1[DH]-[PD]-1S-[PD]$/,
    explain: 'We opened 1m, responder’s preference after opener rebids 1S',
    options: [
      {
        bid: '2S["4+!S"]',
        reqS: 4,
      },
      {
        bid: '2o["Preference"]',
        reqFit: 3,
      },
      {
        bid: '2r["6+!r"]',
        reqR: 6,
      },
      {
        bid: '1N',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-[PD]-1[HS]-[PD]-1N-[PD]$/,
    explain: 'We opened 1m, responder’s preference after opener rebids 1N',
    options: [
      {
        bid: '2r["To play"]',
        reqR: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-[PD]-1S-[PD]-1N-[PD]$/,
    explain: 'We opened 1m, responder’s preference after opener rebids 1N',
    options: [
      {
        bid: '2H["4+!H, not forcing"]',
        reqH: 4,
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-[PD]-1[DHS]-[PD]-1N-[PD]$/,
    explain: 'We opened 1m, responder’s preference after opener rebids 1N',
    options: [
      {
        bid: '2C[@"Forces 2!D (Two-way checkback)"]',
        reqD: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-[PD]-1[DHS]-[PD]-1N-[PD]-2C-[PD]$/,
    explain: 'We opened 1m, responder’s bids checkback over 1N',
    options: [
      {
        bid: '2D[@"Forced"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-[PD]-1S-[PD]-1N-[PD]-2H-[PD]$/,
    explain: 'We opened 1m, opener’s preference after responder shows both majors',
    options: [
      {
        bid: 'P["Preference"]',
        reqStrains: function(strains) {
          return strains.H >= strains.S + 2;
        },
      },
      {
        bid: '2S["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-[PD]-1[HS]-[PD]-2C-[PD]$/,
    explain: 'We opened 1D, responder’s preference after opener rebids 2C',
    options: [
      {
        bid: 'P',
        reqC: 4,
        reqStrains: function(strains) {
          return strains.C >= strains.D + 2;
        },
      },
      {
        bid: '2o["Preference"]',
        reqD: 3,
      },
      {
        bid: '2r["To play"]',
        reqR: 6,
      },
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.C >= strains.D + 2;
        },
      },
      {
        bid: '2o["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-[PD]-1[DHS]-\d\w$/,
    explain:
      "We opened 1m, we bid a new suit, opener's rebid after interference",
    options: [
      {
        bid: '2r["4!r"]',
        reqR: 4,
      },
      {
        bid: '2o["6+!o"]',
        reqFit: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H$/,
    explain: 'partner opened 1m, 1H entry',
    options: [
      {
        bid: 'D["Take out, (4!S)"]',
        reqHp: ranges.newSuitUnforced,
        reqS: 4,
        reqMaxS: 4,
      },
      {
        bid: '1S["5+!S"]',
        reqHp: ranges.newSuitUnforced,
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-1H-1S-[PD]$/,
    explain: "1D (1H) 1S, opener's rebid",
    options: [
      {
        bid: '2C["4+ !C"]',
        reqMaxS: 2,
        reqC: 4,
        reqD: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-1S-[PD]$/,
    explain: "1m (1H) 1S, opener's rebid",
    options: [
      {
        bid: '2S',
        reqS: 3,
      },
      {
        bid: '2o["6+!o"]',
        reqFit: 6,
      },
      {
        bid: '1N["12-14"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-1S-[PD]-1N-[PD]$/,
    explain: "1m (1H) 1S 1N, responder's preference",
    options: [
      {
        bid: '2S["6+!S"]',
        reqS: 6,
      },
      {
        bid: '2o["4+!o"]',
        reqFit: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-1H-1S-[PD]-2C-[PD]$/,
    explain: "1D (1H) 1S 2C, responder's preference",
    options: [
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.C >= strains.D + 2;
        },
      },
      {
        bid: '2D["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-1H-D-[PR]$/,
    explain: "1D (1H) D, opener's rebid",
    options: [
      {
        bid: '2C["4+ !C"]',
        reqMaxS: 2,
        reqC: 4,
        reqD: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-D-[PR]$/,
    explain: "1m (1H) D, opener's rebid",
    options: [
      {
        bid: '2S["4!S"]',
        reqS: 4,
      },
      {
        bid: '1S["3!S"]',
        reqS: 3,
      },
      {
        // TODO: Is this bid too narrow in reqs?
        bid: '3S["13—15 HCP, 4!S"]',
        reqS: 4,
        reqHp: 13,
        reqMaxHp: 15,
        weight: 2,
      },
      {
        bid: '2o["6+!o"]',
        reqFit: 6,
      },
      {
        bid: '1N["12-14"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-D-[PR]-1N-[PD]$/,
    explain: "1m (1H) D 1N, responder's preference",
    options: [
      {
        bid: '2o["4+!o"]',
        reqFit: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-1H-D-[PR]-2C-[PD]$/,
    explain: "1D (1H) D 2C, responder's preference",
    options: [
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.C >= strains.D + 2;
        },
      },
      {
        bid: '2D["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1S$/,
    explain: 'partner opened 1m, 1S entry, do we make takeout double?',
    options: [
      {
        bid: 'D["Take-out, 4+!H"]',
        reqHp: ranges.newSuitUnforced,
        reqH: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-1S-D-[PR]$/,
    explain: "We opened 1D, 1S entry, respond to partner's takeout double",
    options: [
      {
        bid: '2C["4+!C"]',
        reqMaxH: 3,
        reqD: 5,
        reqC: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1S-D-[PR]$/,
    explain: "We opened 1m, 1S entry, respond to partner's takeout double",
    options: [
      {
        bid: '2H["4!H"]',
        reqH: 4,
      },
      {
        bid: '2o["6+!o"]',
        reqFit: 6,
      },
      {
        bid: '1N["12-14"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1S-D-[PR]-1N-[PD]$/,
    explain:
      'partner opened 1m, 1S entry, we made takeout double, responders preference over 1N',
    options: [
      {
        bid: '2o["To play"]',
        reqFit: 4,
      },
      {
        bid: '2H["To play"]',
        reqH: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-1S-D-[PR]-2C-[PD]$/,
    explain:
      'partner opened 1D, 1S entry, we made takeout double, responders preference over 2C',
    options: [
      {
        bid: 'P',
        reqC: 4,
        reqStrains: function(strains) {
          return strains.C >= strains.D + 2;
        },
      },
      {
        bid: '2o["Preference"]',
        reqD: 3,
      },
      {
        bid: '2H["To play"]',
        reqH: 6,
      },
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.C >= strains.D + 2;
        },
      },
      {
        bid: '2o["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D$/,
    explain: 'partner opened 1C, 1D entry, we can bid 1M or good raise',
    options: [
      {
        bid: '1H["4+!H"]',
        reqHp: ranges.newSuitUnforced,
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H > strains.S;
        },
      },
      {
        bid: '1S["4+!S"]',
        reqHp: ranges.newSuitUnforced,
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.H;
        },
      },
      {
        bid: '2D[@"10+, 4+!o"]',
        reqHp: ranges.invitational,
        reqC: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-1[HS]-(1S|1N|2C|2D|2H)$/,
    explain:
      "we opened opened 1C, 1D entry, partner bid 1M, opener's bid over interference",
    options: [
      {
        bid: '2r["4!r"]',
        reqR: 4,
      },
      {
        bid: '2o["6!o"]',
        reqFit: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-1[HS]-[PD]$/,
    explain:
      "we opened opened 1C, 1D entry, partner bid 1M, opener's bid without interference",
    options: [
      {
        bid: '2r["4!r"]',
        reqR: 4,
      },
      {
        bid: '2o["6+!o"]',
        reqFit: 6,
      },
      {
        bid: '1N["12-14"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-1[HS]-[PD]-1N-(P|D|2C|2D|2H)$/,
    explain:
      "Partner opened 1C, 1D entry, we bid 1M, opener 1N, responder's preference",
    options: [
      {
        bid: '2r["To play, 5+!r"]',
        reqR: 6,
      },
      {
        bid: '2o["To play, 4+!o"]',
        reqFit: 4,
      },
      {
        bid: '2r["To play, 5+!r"]',
        reqR: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1[DHS]$/,
    explain: 'partner opened 1m, 1M or 1D entry',
    options: [
      {
        bid: '2l[@"10+, 4+!o"]',
        reqHp: ranges.invitational,
        reqFit: 4,
      },
      {
        bid: '3o["Weak, 5+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 5,
      },
      {
        bid: '2o["6—9 HCP, 4+!o"]',
        reqHp: ranges.raise,
        reqFit: 4,
      },
      {
        bid: '1N["Not forcing"]',
        reqHp: ranges.newSuitUnforced,
        reqHcpInLastSuit: 4,
      },
    ],
  },
  // TODO: Respond to good raise
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-2D-(P|D|2.)$/,
    explain: 'Responding to cue bid good raise after 1C',
    options: [
      {
        bid: '3C["Minimum"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1H-2H-(P|D|2.)$/,
    explain: 'Responding to cue bid good raise after 1C',
    options: [
      {
        bid: '3C["Minimum"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1S-2S-(P|D|2.)$/,
    explain: 'Responding to cue bid good raise after 1C',
    options: [
      {
        bid: '3C["Minimum"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-1H-2H-(P|D|2.|3C)$/,
    explain: 'Responding to cue bid good raise after 1D',
    options: [
      {
        bid: '3D["Minimum"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-1S-2S-(P|D|2.|3C)$/,
    explain: 'Responding to cue bid good raise after 1D',
    options: [
      {
        bid: '3D["Minimum"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1N$/,
    explain: 'partner opened 1m, 1N entry, should we raise?',
    options: [
      {
        bid: '3o["Weak, 5+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 5,
      },
      {
        bid: '2o["6—9 HCP, 4+!o"]',
        reqHp: ranges.raise,
        reqFit: 4,
      },
      // TODO: Invitational raises
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-2C$/,
    explain: 'partner opened 1D, and RHO overcalled 2C',
    options: [
      {
        bid: '3o["Weak, 5+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 5,
      },
      {
        bid: '2o["6—9 HCP, 4+!o"]',
        reqHp: ranges.raise,
        reqFit: 4,
      },
      // TODO: Invitational raises
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-(2H|2S|2N)$/,
    explain: 'partner opened with 1m, and RHO made a pre-empt on 2 level',
    options: [
      {
        bid: '4o["Weak, 5+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 5,
      },
      {
        bid: '3o["Not forcing, 4+!o"]',
        reqHp: ranges.intermediateRaise,
        reqFit: 4,
      },
      {
        bid: '3o["Not forcing, 4+!o"]',
        reqHp: ranges.raise,
        reqFit: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-2D$/,
    explain: 'partner opened with 1m, and RHO made a pre-empt on 2 level',
    options: [
      {
        bid: '4o["Weak, 5+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 5,
      },
      {
        bid: '3o["Not forcing, 4+!o"]',
        reqHp: ranges.intermediateRaise,
        reqFit: 4,
      },
      {
        bid: '3o["Not forcing, 4+!o"]',
        reqHp: ranges.raise,
        reqFit: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-2C$/,
    explain: 'partner opened 1C, and RHO bid 2C',
    options: [
      {
        bid: '3o["6—9 HCP, 5+!o"]',
        reqHp: ranges.raise,
        reqFit: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-2D$/,
    explain: 'partner opened 1D, and RHO bid 2D',
    options: [
      {
        bid: '3o["6—9 HCP, 5+!o"]',
        reqHp: ranges.raise,
        reqFit: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-(3D|3H|3S|3N)$/,
    explain: 'partner opened with 1m, and RHO made a pre-empt on 3 level',
    options: [
      {
        bid: '4o["Not forcing, 5+!o"]',
        reqHp: ranges.intermediateRaise,
        reqFit: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-3C$/,
    explain: 'partner opened with 1m, and RHO made a pre-empt on 3 level',
    options: [
      {
        bid: '4o["Not forcing, 5+!o"]',
        reqHp: ranges.intermediateRaise,
        reqFit: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-3C$/,
    explain: 'partner opened with 1m, and RHO made a pre-empt on 3 level',
    options: [
      {
        bid: '4o["Weak, 5+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 5,
      },
      {
        bid: '3o["Not forcing, 4+!o"]',
        reqHp: ranges.intermediateRaise,
        reqFit: 4,
      },
      {
        bid: '3o["Not forcing, 4+!o"]',
        reqHp: ranges.raise,
        reqFit: 5,
      },
    ],
  },
];

export default respondingTo1Minor;
