import { useState } from 'react'
import PairDisplay, { LinkedPairDisplay } from '../../../components/users/pairDisplay'
import { Star } from '../../../components/rating/starRating'
import { roundToTwoDecimals } from '../../../util/challenge'
import ChallengeStatisticBlock from './ChallengeStatisticBlock'
import ChevronUp from '../../../components/icons/chevronUp'
import ChevronDown from '../../../components/icons/chevronDown'
import { useTranslation } from 'react-i18next'

function FlexibleUserDisplay({ onPairClick, uid1, uid2, reverse = false }) {
  if (onPairClick) {
    return (
      <div role="button" onClick={() => onPairClick([uid1, uid2])}>
        <PairDisplay uid1={uid1} uid2={uid2} reverse={reverse} />
      </div>
    );
  }

  return <LinkedPairDisplay uid1={uid1} uid2={uid2} reverse={reverse} />
}

export default function ChallengeStatistic({ challengeStatistic, firstUserId, sx = '', onPairClick, header }) {
  const { t } = useTranslation('translation', { keyPrefix: 'challenge' });

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(e => !e);

  const isTeamA = challengeStatistic.teamAUsers.includes(firstUserId);
  const myPrefix = isTeamA ? 'teamA' : 'teamB';
  const oppPrefix = isTeamA ? 'teamB' : 'teamA';
  const myTeam = challengeStatistic[myPrefix + 'Users'];
  const isFirstInTeam = myTeam[0] === firstUserId;
  const oppTeam = challengeStatistic[oppPrefix + 'Users'];

  const teamAWins = challengeStatistic.teamAWins ?? 0;
  const teamADraws = challengeStatistic.teamADraws ?? 0;
  const teamALosses = challengeStatistic.teamALosses ?? 0;
  const wins = isTeamA ? teamAWins : teamALosses;
  const draws = teamADraws;
  const losses = isTeamA ? teamALosses : teamAWins;
  const myStars = challengeStatistic[myPrefix + 'NumberOfStars'];
  const oppStars = challengeStatistic[oppPrefix + 'NumberOfStars'];
  const numberOfChallenges = challengeStatistic.numberOfChallenges;
  const numberOfBoards = challengeStatistic.numberOfBoards;

  const teamAWinsRanked = challengeStatistic.teamAWinsRanked ?? 0;
  const teamADrawsRanked = challengeStatistic.teamADrawsRanked ?? 0;
  const teamALossesRanked = challengeStatistic.teamALossesRanked ?? 0;
  const winsRanked = isTeamA ? teamAWinsRanked : teamALossesRanked;
  const drawsRanked = teamADrawsRanked;
  const lossesRanked = isTeamA ? teamALossesRanked : teamAWinsRanked;
  const numberOfChallengesRanked = challengeStatistic.numberOfChallengesRanked;
  const numberOfBoardsRanked = challengeStatistic.numberOfBoardsRanked;

  const teamAWinsFriendly = challengeStatistic.teamAWinsFriendly ?? 0;
  const teamADrawsFriendly = challengeStatistic.teamADrawsFriendly ?? 0;
  const teamALossesFriendly = challengeStatistic.teamALossesFriendly ?? 0;
  const winsFriendly = isTeamA ? teamAWinsFriendly : teamALossesFriendly;
  const drawsFriendly = teamADrawsFriendly;
  const lossesFriendly = isTeamA ? teamALossesFriendly : teamAWinsFriendly;
  const numberOfChallengesFriendly = challengeStatistic.numberOfChallengesFriendly;
  const numberOfBoardsFriendly = challengeStatistic.numberOfBoardsFriendly;

  return (
    <div className={`border border-accent p-2 md:px-4 bg-base-200 rounded-md ${sx}`}>
      {header && <div className="info text-center">{header}</div>}
      <div className='grid grid-cols-[auto_50px_auto] gap-y-2 place-items-center mt-2'>
        <FlexibleUserDisplay onPairClick={onPairClick} uid1={myTeam[isFirstInTeam ? 0 : 1]} uid2={myTeam[isFirstInTeam ? 1 : 0]} />
        <span className="info">{t('vs')}</span>
        <FlexibleUserDisplay onPairClick={onPairClick} uid1={oppTeam[0]} uid2={oppTeam[1]} reverse />

        <div
          className={'badge badge-outline border-info bg-base-100 pl-0'}
        >
          <Star /> {myStars}
        </div>
        <span className="info">{t('tot')}</span>
        <div
          className={'badge badge-outline border-info bg-base-100 pl-0'}
        >
          <Star /> {oppStars}
        </div>

        <div
          className={'badge badge-outline border-info bg-base-100 pl-0'}
        >
          <Star /> {roundToTwoDecimals(myStars/challengeStatistic.numberOfBoards)}
        </div>
        <span className="info">{t('avg')}</span>
        <div
          className={'badge badge-outline border-info bg-base-100 pl-0'}
        >
          <Star /> {roundToTwoDecimals(oppStars/challengeStatistic.numberOfBoards)}
        </div>
      </div>
      <div className="grid grid-cols-[1fr_1fr_40px_1fr_1fr] gap-y-2 place-items-center mt-2" role="button" onClick={toggleExpanded}>
        <ChallengeStatisticBlock
          numberOfChallenges={numberOfChallenges}
          wins={wins}
          draws={draws}
          losses={losses}
          numberOfBoards={numberOfBoards}
          header={t('challenges')}
        />
        {expanded && (
          <>
            <ChallengeStatisticBlock
              numberOfChallenges={numberOfChallengesRanked}
              wins={winsRanked}
              draws={drawsRanked}
              losses={lossesRanked}
              numberOfBoards={numberOfBoardsRanked}
              header={t('ranked')}
            />
            <ChallengeStatisticBlock
              numberOfChallenges={numberOfChallengesFriendly}
              wins={winsFriendly}
              draws={drawsFriendly}
              losses={lossesFriendly}
              numberOfBoards={numberOfBoardsFriendly}
              header={t('friendly')}
              friendlyIcon
            />
          </>
        )}
      </div>
      <div className="flex justify-center" role="button" onClick={toggleExpanded}>{expanded ? <ChevronUp /> : <ChevronDown />}</div>
    </div>
  );
}
