import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getGroupSession } from '../../firebase/groups';
import Leaderboard from '../../components/leaderBoards/leaderBoard';
import Alert from '../../components/alert/alert';
import EventTimer from '../../components/areaCards/eventTimer';
import { timestampToString } from '../../util/dates';
import { GroupsIcon } from '../../components/icons/groupsIcon';
import GroupSessionHeader from '../../components/groupSessionHeader/GroupSessionHeader';
import { sendGroupChatMessage, setLastRead } from '../../firebase/groupChat';
import { ChatDrawer } from '../../components/chat/chat';
import GroupSessionTimer from '../../components/areaCards/groupSessionTimer';
import { getSessionForEvent } from '../../firebase/events';
import { useAppStateStore } from '../../appStateStore';
import { getNumberOfUnreadGroupMessages } from '../../util/groups';
import { useAuth, useGoToPublicGroupPage } from '../../util/hooks';
import { useQuery } from '@tanstack/react-query';
import log from '../../util/logger.js';
import { CoachIcon } from '../../components/icons/coachIcon.jsx';
import { GridCard } from '../../components/cardGrid/cardGrid.jsx';
import TagsDisplay from '../../components/tagsDisplay/TagsDisplay.jsx'
import useConfirm from '../../hooks/confirm.jsx';
import { deleteGroupSession, removeEventBadge } from 'cuebids-firebase/groups';
import { useTranslation } from 'react-i18next';
import useNotifications from '../../components/notifications/useNotifications.jsx';

export default function GroupSessionLeaderboard() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.group_session_leaderboard' });
  const { id, groupId } = useParams();
  const groups = useAppStateStore((state) => state.groups);
  const groupsExtraData = useAppStateStore((state) => state.groupsExtraData);
  const groupsMessages = useAppStateStore((state) => state.groupsMessages);
  const sessions = useAppStateStore((state) => state.sessions);
  const eventInvites = useAppStateStore((state) => state.eventInvites);
  const user = useAppStateStore((state) => state.user);
  const isNative = useAppStateStore((state) => state.isNative);
  const { currentUser } = useAuth();
  const [eventTimerState, setEventTimerState] = useState(); // Only used to trigger re-rendering
  const groupSessionSessionFromAppState = sessions.find((s) => s.groupSession === id);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useGoToPublicGroupPage(groupId);
  const notify = useNotifications();

  useEffect(() => {
    if(user?.eventBadges?.includes(id)) {
      void removeEventBadge(id);
    }
  }, [id, user?.eventBadges]);

  const { data: loadedGroupSessionSession, refetch } = useQuery({
    queryKey: ['groupSessionSession', id],
    refetchOnWindowFocus:false,
    enabled: false,
    queryFn: async () => await getSessionForEvent('groupSession', id)
  });

  const { data: groupSession } = useQuery({
    queryKey: ['groupSession', id],
    queryFn: async () => await getGroupSession(id)
  });


  const [ConfirmDelete, confirmDelete] = useConfirm(
    'app.confirm_title',
    'groups.delete_confirm',
    'error',
    'secondary'
  );


  const handleDeleteEvent = async () => {
    const ans = await confirmDelete();
    if (ans) {
      if (loading) return;
      setLoading(true);
      try {
        await deleteGroupSession(id);
        navigate(`/groups/${groupId}`);
      } catch (e) {
        notify(
          { text: t('delete_error'), type: 'error' },
        );
      } finally {
        setLoading(false);
      }
    }
  };


  useEffect(() => {
    function getData() {
      if(!groupSessionSessionFromAppState)
      {
        refetch().then(r => log('fetching with query', r.data))
      }
    }

    getData()
  }, [id, refetch, groupSessionSessionFromAppState])

  const groupSessionSession = groupSessionSessionFromAppState || loadedGroupSessionSession;

  // groupSessionSession is undefined while it is loading (null if does not exist after load)
  if (groupSessionSession === undefined || !groupSession ) {
    return null;
  }

  const group = (groups || []).find(function (group) {
    return group.id === groupId;
  });

  function handleLastReadUpdate() {
    setLastRead(groupId);
  }

  if (!group || !user) {
    return null;
  }

  const hasStarted = groupSession.startDate < Date.now();
  const permanent = !groupSession.endDate;
  const hasFinished = !permanent && groupSession.endDate < Date.now();


  const sentInvites = eventInvites.filter(
    (i) => i.eventId === groupSession.id && i.inviter === currentUser.uid
  );
  const receivedInvites = eventInvites.filter(
    (i) => i.eventId === groupSession.id && i.invitee === currentUser.uid
  );
  const numberOfSentInvites = hasFinished ? 0 : sentInvites.length;
  const numberOfReceivedInvites = hasFinished ? 0 : receivedInvites.length;

  const messages = groupsMessages[groupId] || [];
  const numberOfUnreadMessages = getNumberOfUnreadGroupMessages({ groupId, groupsMessages, groupsExtraData, userId: currentUser.uid });

  const groupHasCoaching = (group.premiumFeatures || []).includes('coaching');
  const isAdmin = group.admins.includes(currentUser.uid);
  const isCoach = groupHasCoaching && isAdmin;
  const multipleJoins = (group.premiumFeatures || []).includes('multipleJoins');

  if (groupSession.deleted) {
    return null;
  }

  return (
    <div className="page pb-24 px-2">
      {isAdmin && (
        <div className="mb-4 flex gap-2">
          <Link to={`/groups/${groupId}/editEvent/${id}`}>
            <button
              className="btn btn-secondary btn-outline btn-sm h-4"
            >
              {t('edit')}
            </button>
          </Link>
          <button
            onClick={handleDeleteEvent}
            disabled={loading}
            className="btn btn-error btn-outline btn-sm h-4"
          >
            {t('delete')}
          </button>
        </div>
      )}
      <GroupSessionHeader
        name={groupSession.name}
        description={groupSession.description}
        groupName={group.name}
        links
        groupId={groupId}
        groupFlag={group.groupFlag}
      />
      <div className="text-center">
        {!hasStarted ? (
          <div>{t('not_started')}</div>
        ) : hasFinished ? (
          <div>{t('finished')}</div>
        ) : permanent ? (<></>) : (
          <div>{t('runs_until')}</div>
        )}
        {
          !permanent && <EventTimer
            event={groupSession}
            startDate={timestampToString(groupSession.startDate)}
            endDate={timestampToString(groupSession.endDate)}
            callback={setEventTimerState}
            label="event"
          />
        }

      </div>
      {hasFinished && !groupSession.scored && (
        <GroupSessionTimer endDate={groupSession.endDate}/>
      )}
      <TagsDisplay
        tags={groupSession.tags}
        tagNames={groupSession.tagNames}
        sx="mt-2"
      />
      <Link
        className={`mt-4${
          !hasStarted || (hasFinished && !groupSessionSession)
            ? ' pointer-events-none'
            : ''
        }`}
        to={
          (groupSessionSession && !isCoach && !multipleJoins)
            ? `/session/${groupSessionSession.id}`
            : `/groups/${groupId}/joinEvent/${id}`
        }
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <button
            disabled={!hasStarted || (hasFinished && !groupSessionSession)}
            className="btn btn-primary indicator"
            style={{
              marginTop: 10,
              marginBottom: 10,
              width: '350px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {numberOfReceivedInvites > 0 && (
              <span className="indicator-item badge badge-success badge-sm">
                {numberOfReceivedInvites}
              </span>
            )}
            <span>
              {(groupSessionSession && !isCoach && !multipleJoins)
                ? groupSessionSession.dealsCount -
                  groupSessionSession.numberOfFinishedDeals >
                  0
                  ? t('continue')
                  : t('completed')
                : numberOfSentInvites > 0
                  ? t('invites_sent')
                  : t('join_event')}
            </span>
          </button>
        </div>
      </Link>
      <div style={{ marginBottom: 5 }}>
        <span>{t('pair_count', {count: groupSession.pairCount ?? 0})}</span>
      </div>
      {isCoach && (
        <Link to={`/groups/${groupId}/event/${id}/coach`}>
          <GridCard
            name={t('grid_card_coaching')}
            icon={<CoachIcon className={'fill-amber-500'} width={32}/>}
          />
        </Link>
      )}
      {groupSession.showLeaderboard ? (
        <div className="mt-4 w-full md:w-1/2">
          <Leaderboard
            leaderBoard={groupSession.leaderBoard}
            top={10}
            header={t('leaderboard_title')}
            min={0}
            leadersIcon={<GroupsIcon className="fill-blue-400" width={20}/>}
          />
        </div>
      ) : (
        <Alert text={t('no_leaderboard')} sx="w-75"/>
      )}
      <ChatDrawer
        header={<span>{group.name}</span>}
        userId={currentUser.uid}
        messages={messages}
        setRead={handleLastReadUpdate}
        unreadMessagesCount={numberOfUnreadMessages}
        totalCount={messages.length}
        sendMessage={(message) =>
          sendGroupChatMessage(group.id, group.name, message)
        }
        isNative={isNative}
        bottomNavigationVisible={true}
      />
      <ConfirmDelete />
    </div>
  );
}
