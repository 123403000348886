import React, {useRef, useState} from "react";
import {useDisplay, useOutsideClick} from "../../util/hooks.jsx";
import Animated from "../animation/animated.jsx";
import CloseButton from "../buttons/closeButton.jsx";
import {Star} from "../rating/starRating.jsx";

export function Drawer({
                           header,
                            btn,
                           children,
                            moveDeal
                       }) {

    const [open, setOpen] = useState(false);

    const {zoomLevel, width} = useDisplay();

    const wrapperRef = useRef(null);

    const show = () => {
        setOpen(true);
    }

    const hide = () => {
        setOpen(false);
    }

    useOutsideClick(wrapperRef, hide);

    const headerPaddingClass = 'pt-[56px]';

    if (!open) {
        return (

            <div className={'fixed right-0 md:right-[calc(50%-390px)] md:top-[200px]'}>
                <button
                    className="btn btn-accent normal-case max-w-[100px]"
                    onClick={show}
                >
                    {btn}
                </button>
            </div>
        );
    }


    const zoomMap = [
        100,
        91,
        83,
        67,
        50
    ]

    const extraWidth = (width - 800) * zoomMap[zoomLevel] / 100;

    const positionClass = extraWidth > 750 ? 'right-[calc(50%-710px)]' : '';


    return (
        <div className="fixed top-0 left-0 z-[9998] h-full w-full bg-black bg-opacity-20">
            {
                <Animated
                    key="drawer"
                    initial={{x: 100}}
                    animate={{x: 0}}
                    exit={{x: 100}}
                    transition={{duration: 0.1}}
                    ref={wrapperRef}
                    id="drawer"
                    sx={`absolute top-0 right-0 h-full w-80 ${positionClass} rounded-l-xl slated ${headerPaddingClass} pb-8 md:pb-0`}
                    tabIndex="-1"
                    aria-labelledby="drawer-label"
                >
                    <div className="absolute top-0 left-0 z-[199] flex flex-col w-full items-center bg-base-100">
                        <div>
                            <div className="absolute top-0 left-0 ">
                                <CloseButton onClose={hide}/>
                            </div>
                            <div className="ml-14 grid grow grid-cols-2 items-center">
                                {
                                    header
                                }
                                {
                                    moveDeal && <div className="flex items-center gap-8 my-2 ">
                                        <div className="">
                                            <button className="" onClick={() => moveDeal(false)}>
                                                <kbd className="kbd text-sm">◀︎</kbd>
                                            </button>
                                        </div>

                                        <div className="">
                                            <button className="" onClick={() => moveDeal(true)}>
                                                <kbd className="kbd text-sm">▶︎</kbd>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {children}
                </Animated>
            }
        </div>
    );
}