import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'
import { useAuth } from '../../util/hooks.jsx';
import HandCard from '../../components/hand/handCard';
import '../../components/hand/hands.css';
import { getAllMarkedSessionDeals } from '../../firebase/biddingSessions';
import Alert, { AlertInline } from '../../components/alert/alert';
import { SelectUserDropdown } from '../../components/dropdown/dropdown.jsx'
import { useTranslation } from 'react-i18next';

export default function FlaggedSessionDealsPage() {
  const { currentUser } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const partnerId = searchParams.get('partner') ?? '';
  const { t } = useTranslation();
  const [markedSessionDeals, setMarkedSessionDeals] = useState();
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    getAllMarkedSessionDeals().then(function (d) {
      setMarkedSessionDeals(d);
      setPartners(Array.from(d.reduce(function (a, { users }) {
        const p = users.find(uid => uid !== currentUser.uid);
        a.add(p);
        return a;
      }, new Set())))
    });
  }, [currentUser?.uid]);

  if (!markedSessionDeals) {
    return <></>;
  }

  if (!markedSessionDeals.length) {
    return (
      <AlertInline
        severity={'info'}
        sx="mt-8"
        text={t('flagged_session_deals.none')}
      />
    );
  }

  const filteredSessionDeals = partnerId ? markedSessionDeals.filter(({ users }) => users.includes(partnerId)) : markedSessionDeals;

  const setPartnerId = (uid) => {
    setSearchParams(
      function (oldParams) {
        if (uid) {
          oldParams.set('partner', uid);
        } else {
          oldParams.delete('partner');
        }
        return oldParams;
      },
      { replace: true }
    );
  }

  return (
    <div className="page pb-24">
      <SelectUserDropdown
        label="Partner"
        value={partnerId}
        onChange={setPartnerId}
        users={partners}
      />
      {partnerId && <button className="btn btn-sm btn-secondary h-8 mt-4" onClick={() => setPartnerId(null)}>{t('flagged_session_deals.show_all_partners')}</button>}
      <table className="mt-4 w-full table-auto border-collapse rounded-sm md:w-4/5">
        <thead>
          <tr>
            <th>Hand</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredSessionDeals.map((s) => {
            return (
              <HandCard
                i={s.id}
                deal={s}
                key={s.id}
                currentUser={currentUser}
                hideDealNumber
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
