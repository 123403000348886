import { useEffect, useState } from 'react';
import {
  deleteChallengeFinishedNotification,
  getChallengeFinishedNotificationsObservable,
} from '../../firebase/challengeMatchmaking';
import { useAuth } from '../../util/hooks.jsx';
import ChallengeResultCard from '../challengeCard/challengeCard';
import { RankIcon } from '../rankCard/rankCard';
import { StarRating } from '../rating/starRating';
import Dialog from '../dialog';
import Animated from '../animation/animated';

// TODO: The styling/layout solutions for all different kinds of notifications are quite different, would be better if they were more similar.

function NoChange({ stars, uid, rank }) {
  return (
    <div
      style={{
        width: '100%',
        height: 150,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 30,
      }}
    >
      <RankIcon rank={rank} uid={uid} />
      <div style={{ position: 'relative' }}>
        <StarRating stars={stars} maxStars={5} />
      </div>
    </div>
  );
}

function StarChange({ oldStars, newStars, uid, rank }) {
  return (
    <div
      style={{
        width: '100%',
        height: 150,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 30,
      }}
    >
      <RankIcon rank={rank} uid={uid} />
      <div style={{ position: 'relative' }}>
        <Animated
          key="starChangeBefore"
          style={{
            position: 'absolute',
            height: 30,
            width: 120,
            left: -60,
            right: 0,
          }}
          animate={{ opacity: 0 }}
          initial={{ opacity: 1 }}
          transition={{
            delay: 4,
            duration: 1,
          }}
        >
          <StarRating stars={oldStars} maxStars={5} />
        </Animated>
        <Animated
          key="starChangeAfter"
          style={{
            position: 'absolute',
            height: 30,
            width: 120,
            left: -60,
            right: 0,
          }}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            delay: 6,
            duration: 1,
          }}
        >
          <StarRating stars={newStars} maxStars={5} />
        </Animated>
      </div>
    </div>
  );
}

function RankChange({ oldRank, newRank, uid }) {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: 150,
        marginTop: 30,
      }}
    >
      <Animated
        key="rankChangeBefore"
        style={{ position: 'absolute', height: 30, left: 0, right: 0 }}
        animate={{ opacity: 0 }}
        initial={{ opacity: 1 }}
        transition={{
          delay: 4,
          duration: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <RankIcon rank={oldRank} uid={uid} />
          <div style={{ width: 120 }}>
            <StarRating stars={5} maxStars={5} />
          </div>
        </div>
      </Animated>
      <Animated
        key="rankChangeAfter"
        style={{ position: 'absolute', height: 30, left: 0, right: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{
          delay: 6,
          duration: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <RankIcon rank={newRank} uid={uid} />
          <div style={{ width: 120 }}>
            <StarRating stars={1} maxStars={5} />
          </div>
        </div>
      </Animated>
    </div>
  );
}

function RatingChange({ oldRating, newRating, ratingChange, uid }) {
  return (
    <div
      style={{
        width: '100%',
        height: 180,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 30,
      }}
    >
      <RankIcon rank={'ace'} uid={uid} />
      <div
        style={{
          filter:
            'drop-shadow(0px 0px 6px rgb(103,30,207)) drop-shadow(0px 0px 10px rgb(103,30,207))',
        }}
      >
        <StarRating stars={5} maxStars={5} />
      </div>
      <div style={{ position: 'relative', width: '100%' }}>
        <Animated
          key="ratingChangeBefore"
          style={{
            position: 'absolute',
            height: 30,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
          animate={{ opacity: 0 }}
          initial={{ opacity: 1 }}
          transition={{
            delay: 4,
            duration: 1,
          }}
        >
          <h6>{oldRating}</h6>
        </Animated>
        <Animated
          key="ratingChangeAfter"
          style={{
            position: 'absolute',
            height: 30,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            delay: 6,
            duration: 1,
          }}
        >
          <div className="flex items-center">
            <div className="w-2 mr-2" />
            <h6>{newRating}</h6>
            <div className="info w-2 ml-2">{`(${
              ratingChange < 0 ? '' : '+'
            }${ratingChange})`}</div>
          </div>
        </Animated>
      </div>
    </div>
  );
}

function ReachedLegendary({ rating, uid }) {
  return (
    <div
      style={{
        width: '100%',
        height: 180,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 30,
      }}
    >
      <RankIcon rank={'ace'} uid={uid} />
      <div style={{ position: 'relative', width: '100%' }}>
        <Animated
          key="reachedLegendaryBefore"
          style={{
            position: 'absolute',
            height: 60,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
          animate={{ opacity: 0 }}
          initial={{ opacity: 1 }}
          transition={{
            delay: 4,
            duration: 1,
          }}
        >
          <div style={{ width: 120 }}>
            <StarRating stars={5} maxStars={5} />
          </div>
        </Animated>
        <Animated
          key="reachedLegendaryAfter"
          style={{
            position: 'absolute',
            height: 60,
            left: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            delay: 6,
            duration: 1,
          }}
        >
          <div
            style={{
              filter:
                'drop-shadow(0px 0px 6px rgb(103,30,207)) drop-shadow(0px 0px 10px rgb(103,30,207))',
            }}
          >
            <StarRating stars={5} maxStars={5} />
          </div>
          <h6>{rating}</h6>
        </Animated>
      </div>
    </div>
  );
}

export default function ChallengeFinishedNotifications() {
  const { currentUser } = useAuth();
  const [notifications, setNotifications] = useState([]);

  useEffect(
    function () {
      if (currentUser) {
        return getChallengeFinishedNotificationsObservable(function (
          challengeFinishedNotifications
        ) {
          setNotifications((ns) => ns.concat(challengeFinishedNotifications));
        });
      }
    },
    [currentUser]
  );

  const notification = notifications[0];

  if (!notification) {
    return null;
  }

  const handleClose = () => {
    deleteChallengeFinishedNotification(notification.id);
    setNotifications(function (ns) {
      return ns.slice(1);
    });
  };

  let rankedChangeContent = null;
  if (notification.ranked) {
    if (
      notification.oldRankLevel === 'ace' &&
      notification.oldRankLevelStars === 6
    ) {
      // is legendary (was already)
      rankedChangeContent = (
        <RatingChange
          uid={currentUser.uid}
          oldRating={notification.oldRating}
          newRating={notification.newRating}
          ratingChange={notification.ratingChange}
        />
      );
    } else if (
      notification.newRankLevel === 'ace' &&
      notification.newRankLevelStars === 6
    ) {
      // reached legendary
      rankedChangeContent = (
        <ReachedLegendary
          uid={currentUser.uid}
          rating={notification.newRating}
        />
      );
    } else if (notification.oldRankLevel !== notification.newRankLevel) {
      // ranked up
      rankedChangeContent = (
        <RankChange
          uid={currentUser.uid}
          oldRank={notification.oldRankLevel}
          newRank={notification.newRankLevel}
        />
      );
    } else if (
      notification.oldRankLevelStars !== notification.newRankLevelStars
    ) {
      // stars changed
      rankedChangeContent = (
        <StarChange
          uid={currentUser.uid}
          rank={notification.newRankLevel}
          oldStars={notification.oldRankLevelStars}
          newStars={notification.newRankLevelStars}
        />
      );
    } else {
      rankedChangeContent = (
        <NoChange
          uid={currentUser.uid}
          rank={notification.newRankLevel}
          stars={notification.newRankLevelStars}
        />
      );
    }
  }

  return (
    <Dialog
      key={notification.id}
      id={notification.id}
      open={true}
      onClose={handleClose}
    >
      <div className="flex flex-col items-center justify-center">
        <ChallengeResultCard
          user1={notification.myTeam.users[0]}
          user2={notification.myTeam.users[1]}
          user3={notification.oppTeam.users[0]}
          user4={notification.oppTeam.users[1]}
          starsTeam1={notification.myTeam.stars}
          starsTeam2={notification.oppTeam.stars}
          finished
          ranked={notification.ranked}
        />
        {notification.ranked && <>{rankedChangeContent}</>}
      </div>
    </Dialog>
  );
}
