import Hand from './hand.jsx';
import { getHandFromDirection } from 'cuebids-hand-util';
import DealerVulnerabilityDiagram from './dealerVulnerabilityDiagram.jsx'

export default function HandDiagram({ hand, vulnerability, dealer, showEW = true }) {
  return (
    <div className={'grid grid-cols-3 w-80 max-h-[340px]'}>
      <div/>
      <Hand
        variant={'diagram'}
        hand={getHandFromDirection(hand, 'N')}
        allowAnimation={false}
        showHcp={false}/>
      <div/>

      {
        showEW ? <Hand
          variant={'diagram'}
          hand={getHandFromDirection(hand, 'W')}
          allowAnimation={false}
          showHcp={false}/> : <div/>
      }

      <DealerVulnerabilityDiagram
        vulnerability={vulnerability}
        dealer={dealer} />

      {
        showEW ?       <Hand
          variant={'diagram'}
          hand={getHandFromDirection(hand, 'E')}
          allowAnimation={false}
          showHcp={false}/> : <div/>
      }
      <div/>
      <Hand
        variant={'diagram'}
        hand={getHandFromDirection(hand, 'S')}
        allowAnimation={false}
        showHcp={false}/>
      <div/>
    </div>
  );
}
