const respondingTo1N = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P$/,
    explain: 'Partner opens or overcalls 1N, no interference',
    options: [
      {
        // Garbage Stayman + handle good hand with a major
        // TODO: Smolen
        bid: '2C[@"Stayman"]',
        reqH: 4,
        reqS: 4,
      },
      {
        bid: '2C[@"Stayman"]',
        reqHp: 10,
        reqS: 4,
        reqMaxS: 4,
        reqMaxH: 4,
      },
      {
        bid: '2C[@"Stayman"]',
        reqHp: 10,
        reqH: 6,
        reqS: 4,
      },
      {
        bid: '2C[@"Stayman"]',
        reqHp: 10,
        reqH: 4,
        reqMaxH: 4,
        reqMaxS: 4,
      },
      {
        bid: '2C[@"Stayman"]',
        reqHp: 10,
        reqS: 6,
        reqH: 4,
      },
      {
        bid: '2D[@"5+ !H"]',
        reqH: 5,
      },
      {
        bid: '2H[@"5+ !S"]',
        reqS: 5,
      },
      {
        bid: '2S[@"6+ !C"]',
        reqC: 6,
      },
      {
        bid: '2N[@"6+ !D"]',
        reqD: 6,
      },
      {
        bid: '3N["To play"]',
        reqHp: 10,
        reqMaxH: 3,
        reqMaxS: 3,
        weight: 2,
      }
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2C-[PD]$/,
    explain: 'We open or overcall 1N, partner bids Stayman, no interference',
    options: [
      {
        bid: '2H["4+!H"]',
        reqH: 4,
      },
      {
        bid: '2S["4+!S"]',
        reqS: 4,
      },
      {
        bid: '2D[@"No 4-card M"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2C-..-P-(.|..)$/,
    explain: 'Competition over Stayman, responder can bid game',
    options: [
      {
        bid: '4H["To play, 6!H, 4!S"]',
        reqH: 6,
        reqHp: 10
      },
      {
        bid: '4S["To play, 6!S, 4!H"]',
        reqS: 6,
        reqHp: 10
      },
      {
        bid: '3N["To play"]',
        reqHp: 10
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2C-[PD]-2D-[PD]$/,
    explain: 'Partner opens or overcalls 1N, we bid Stayman, opener denies major',
    options: [
      {
        bid: '4H["To play, 6!H, 4!S"]',
        reqH: 6,
        reqHp: 10
      },
      {
        bid: '4S["To play, 6!S, 4!H"]',
        reqS: 6,
        reqHp: 10
      },
      {
        bid: '3N["To play"]',
        reqHp: 10
      },
      // Garbage Stayman below
      {
        bid: 'P["!D, To play"]',
        reqD: 4,
      },
      {
        bid: '2H["Weak with both majors"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2C-[PD]-2D-[PD]-2H-[PD]$/,
    explain: 'We open or overcall 1N, partner bids Stayman, and shows both majors',
    options: [
      {
        bid: '2S["Preference"]',
        reqS: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2C-[PD]-2D-(..)$/,
    explain: 'Competition after partner denies major over Stayman',
    options: [
      {
        bid: '4H["To play, 6!H, 4!S"]',
        reqH: 6,
        reqHp: 10
      },
      {
        bid: '4S["To play, 6!S, 4!H"]',
        reqS: 6,
        reqHp: 10
      },
      {
        bid: '3N["To play"]',
        reqHp: 10
      }
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2C-[PD]-2H-(.|..)$/,
    explain: 'Partner shows hearts over Stayman',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 4,
        reqHp: 10
      },
      {
        bid: '3N["To play"]',
        reqHp: 10
      }
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2C-[PD]-2H-(.|..)-3N-(.|..)$/,
    explain: 'We open 1N and show hearts over Stayman, can make preference to spades if responder bids 3N',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2C-[PD]-2S-(.|..)$/,
    explain: 'Partner shows spades over Stayman',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 4,
        reqHp: 10
      },
      {
        bid: '3N["To play"]',
        reqHp: 10
      }
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2D-(.|..)$/,
    explain: 'We open or overcalled 1N, partner transfers to hearts',
    options: [
      {
        bid: '3H["4+!H"]',
        reqH: 4,
      },
      {
        bid: '2H[@"Accept transfer"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2D-(.|..)-(P|2H)-(.|..)$/,
    explain: 'Partner opens or overcalls 1N, we transfer to hearts, responder can bid game',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 6,
        reqHp: 10,
      },
      {
        bid: '3N["To play"]',
        reqHp: 10,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2D-(.|..)-(P|2H)-(.|..)-3N-(.|..)$/,
    explain: 'We open or overcaled 1N, partner transfers to hearts and bids 3N, we can correct to 4H',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2D-(.|..)-3H-(.|..)$/,
    explain: 'Partner opens or overcalled 1N, we transfer to hearts, responder can bid game after superaccept',
    options: [
      {
        bid: '4H["To play"]',
        reqHp: 8,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2H-(.|..)$/,
    explain: 'We open or overcalled 1N, partner transfers to spades',
    options: [
      {
        bid: '3S["4+!S"]',
        reqS: 4,
      },
      {
        bid: '2S[@"Accept transfer"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2H-(.|..)-(P|2S)-(.|..)$/,
    explain: 'Partner opens 1N, we transfer to spades, responder can bid game',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 6,
        reqHp: 10,
      },
      {
        bid: '3N["To play"]',
        reqHp: 10,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2H-(.|..)-(P|2S)-(.|..)-3N-(.|..)$/,
    explain: 'We open or overcalled 1N, partner transfers to spades and bids 3N, we can correct to 4S',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2H-(.|..)-3S-(.|..)$/,
    explain: 'Partner opens or overcalled 1N, we transfer to spades, responder can bid game after superaccept',
    options: [
      {
        bid: '4S["To play"]',
        reqHp: 8,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2S-(.|..)$/,
    explain: 'We open or overcalled 1N, partner transfers to clubs',
    options: [
      {
        bid: '3C[@"Accept transfer"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-P-2N-(.|..)$/,
    explain: 'We open or overcalled 1N, partner transfers to diamonds',
    options: [
      {
        bid: '3D[@"Accept transfer"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-D$/,
    explain: 'Partner opens or overcalls 1N, they double',
    options: [
      {
        bid: '3N["To play"]',
        reqHp: 10,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*(-1[CDHS])?-1N-[23][CDHSN]$/,
    explain: 'Partner opens or overcalls 1N, they overcall',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 6,
        reqHp: 10,
      },
      {
        bid: '4S["To play"]',
        reqS: 6,
        reqHp: 10,
      },
      {
        bid: '3N["To play"]',
        reqHp: 10,
      },
      {
        bid: '2D["To play"]',
        reqD: 5,
        reqHp: 3,
      },
      {
        bid: '2H["To play"]',
        reqH: 5,
        reqHp: 3,
      },
      {
        bid: '2S["To play"]',
        reqS: 5,
        reqHp: 3,
      },
      {
        bid: '3C["To play"]',
        reqC: 5,
        reqHp: 6,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '3C["To play"]',
        reqC: 6,
        reqHp: 3,
      },
      {
        bid: '3D["To play"]',
        reqD: 5,
        reqHp: 6,
        lastBidExclusions: ['2D'],
      },
      {
        bid: '3D["To play"]',
        reqD: 6,
        reqHp: 3,
      },
      {
        bid: '3H["To play"]',
        reqH: 5,
        reqHp: 6,
        lastBidExclusions: ['2H'],
      },
      {
        bid: '3H["To play"]',
        reqH: 6,
        reqHp: 3,
      },
      {
        bid: '3S["To play"]',
        reqS: 5,
        reqHp: 6,
        lastBidExclusions: ['2S'],
      },
      {
        bid: '3S["To play"]',
        reqS: 6,
        reqHp: 3,
      },
    ],
  },
];

export default respondingTo1N;
