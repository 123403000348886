import {getSupportedLanguages} from '../../util/language.js';
import Dropdown from './dropdown.jsx'
import {useTranslation} from 'react-i18next';

export function LanguageDropdown({language, handleChangeLanguage}) {
    const {t} = useTranslation();
    // NOTE: When adding languages, add them in supportedLanguages (language util)
    const languageOptions = [{
        value: 'en',
        label: (
            <div className="h-full flex items-center justify-start relative gap-2">
                English
                <span className={'fi fi-gb'}></span>
            </div>
        ),
        component: (
            <button
                className="btn-ghost btn w-full justify-start items-center  flex p-2"
                onClick={() => handleChangeLanguage('en')}
            >
                <p>English</p>
                <span className={'fi fi-gb'}></span>
            </button>
        ),
    },
        {
            value: 'pl',
            label: (
                <div className="h-full flex items-center justify-start relative gap-2">
                    Polski
                    <span className={'fi fi-pl'}></span>
                </div>
            ),
            component: (
                <button
                    className="btn-ghost btn w-full justify-start items-center  flex p-2"
                    onClick={() => handleChangeLanguage('pl')}
                >
                    <p>Polski</p>
                    <span className={'fi fi-pl'}></span>
                </button>
            ),
        },
        {
            value: 'sv',
            label: (
                <div className="h-full flex items-center justify-start relative gap-2">
                    Svenska
                    <span className={'fi fi-se'}></span>
                </div>
            ),
            component: (
                <button
                    className="btn-ghost btn w-full justify-start items-center  flex p-2"
                    onClick={() => handleChangeLanguage('sv')}
                >
                    <p>Svenska</p>
                    <span className={'fi fi-se'}></span>
                </button>
            ),
        },
    ];

    const supportedLanguages = getSupportedLanguages();

    return (
        <div className="flex flex-row items-center justify-between">
            <label className="label">
                <span className="label-text">{t('app.language')}</span>
            </label>
            <Dropdown
                size={'flex'}
                value={language}
                options={languageOptions.filter((option) => supportedLanguages.includes(option.value))}
            />
        </div>
    );
}


