import { ranges } from './constants';

// Note: These regexes can be more inclusive (with .) since we also have reqPar, meaning if opps bid the suit it was probably not natural.
const preEmpt4Level = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*-(1[DHSN]|2.|(1[DHSN]|2.)-P-(1[HSN]|2.))$/,
    explain: 'We can pre-empt with 4C',
    options: [
      {
        bid: '4C["Weak, 8+!C"]',
        reqC: 8,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqParC: 110,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 4,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(-\.)*(-P)*-(1[CHSN]|2.|3C|(1[CHSN]|2.)-P-(1[HSN]|2.|3C))$/,
    explain: 'We can pre-empt with 4D',
    options: [
      {
        bid: '4D["Weak, 8+!D"]',
        reqD: 8,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqParD: 110,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 4,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(-\.)*(-P)*-(1[CDSN]|2.|3[CD]|(1[CDSN]|2.|3C)-P-(1[DSN]|2.|3[CD]))$/,
    explain: 'We can pre-empt with 4H',
    options: [
      {
        bid: '4H["Wide-range, 8+!H"]',
        reqH: 8,
        reqParH: 150,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 4,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(-\.)*(-P)*-(1[CDHN]|2.|3[CDH]|(1[CDHN]|2.|3[CD])-P-(1[DHN]|2.|3[CDH]))$/,
    explain: 'We can pre-empt with 4S',
    options: [
      {
        bid: '4S["Wide-range, 8+!S"]',
        reqS: 8,
        reqParS: 150,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 4,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(-\.)*(-P)*-(1[DHSN]|2.|(1[DHSN]|2.)-P-(1[HSN]|2.))-4C-(P|D|4.)$/,
    explain: 'Partner pre-empt with 4C, we can bid 5C',
    options: [
      {
        bid: '5C["To play"]',
        reqC: 2,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(-\.)*(-P)*-(1[CHSN]|2.|3C|(1[CHSN]|2.)-P-(1[HSN]|2.|3C))-4D-(P|D|4.|5C)$/,
    explain: 'Partner pre-empt with 4D, we can bid 5D',
    options: [
      {
        bid: '5D["To play"]',
        reqD: 2,
        reqHp: ranges.weakRaise,
      },
    ],
  },
];

export default preEmpt4Level;
