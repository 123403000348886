import { isDirectionInTurn, removeLastBid } from 'cuebids-bidding-util';

export function getLiveBiddingToPresent(bidding, direction, unconfirmedBid) {
  if (isDirectionInTurn(bidding, direction)) {
    if (unconfirmedBid) {
      return bidding + '-' + unconfirmedBid;
    }
    return bidding + '-?';
  }

  const biddingWithoutLastBid = removeLastBid(bidding);

  return biddingWithoutLastBid + '-?';
}

export function compareRobotActions(bidArray1, bidArray2) {
  for (let i = 0; i < Math.min(bidArray1.length, bidArray2.length); i++) {
    const isRobotBid = i % 2 === 0;
    const bid1 = bidArray1[i];
    const bid2 = bidArray2[i];

    if (bid1 === bid2) {
      continue;
    }

    return !isRobotBid;
  }

  return true;
}
