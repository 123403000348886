import { auth, db } from './firebase';
import {
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import {
  docsToObjects,
  docToObject,
  getContentByIds, queryFirstItemToObject,
  queryListenFirstItemWithCallback,
  queryListenWithCallback,
} from 'firebase-util';
import { createSession } from './biddingSessions';
import { canPairAffordToPlay, getCharges, getUserRemainingTickets } from '../util/tickets';
import { getUserHighestSubscription } from './subscriptions';
import { chargeUsersTickets, checkAllowedToChargeFriendTicketsAndEvents, updateUser } from './userApi'
import { createGroupSessionInviteAcceptedNotification } from 'cuebids-firebase/notifications';
import log from '../util/logger.js';
import { getGroup } from './groups.js'
import { getRobotUserId } from '../util/robotPartner.js'
import i18next from 'i18next'

export const EVENT_INVITES_COLLECTION = 'eventInvites';

export function getLatestEventObservable({ eventCollection, callback }) {
  const q = query(
    collection(db, eventCollection),
    where('startDate', '<', +Date.now()),
    orderBy('startDate', 'desc'),
    limit(1),
  );

  return queryListenFirstItemWithCallback(q, callback);
}

export async function getEventById({ eventCollection, eventId }) {
  const docRef = doc(collection(db, eventCollection), eventId);

  const resp = await getDoc(docRef);

  return docToObject(resp);
}

export async function getEventDeals(dealIds) {
  const docs = await getContentByIds(collection(db, 'deals'), dealIds);

  return docs.sort((a, b) => a.dealNumber - b.dealNumber);
}

export function participantAdapter(participant) {
  if (!participant) return null;

  if (!participant.entries) {
    participant.entries = [{
      partner: participant.partner,
      session: participant.session,
    }];
  }

  return participant;
}

// Does not scale with very large events with many participants
export async function getEventParticipants(eventCollection, eventId) {
  const participantsRef = collection(doc(collection(db, eventCollection), eventId), 'participants');

  const participants = await getDocs(participantsRef);

  return docsToObjects(participants, participantAdapter);
}

export async function checkIfAlreadyPlaying({
  eventCollection,
  eventId,
  userId,
}) {
  const ref = doc(
    collection(doc(collection(db, eventCollection), eventId), 'participants'),
    userId,
  );

  const participationDoc = await getDoc(ref);

  return participationDoc.exists();
}

export async function checkIfAlreadyPlayingTogether({
  eventCollection,
  eventId,
  userId,
  partnerUserId,
}) {
  const ref = doc(
    collection(doc(collection(db, eventCollection), eventId), 'participants'),
    userId,
  );

  const participationDoc = await getDoc(ref);

  const participationData = docToObject(participationDoc, participantAdapter);

  return participationData?.entries.some(({ partner }) => partner === partnerUserId);
}

// TODO: Should this be handled differently?
function getCreateSessionEventFieldName(eventCollection) {
  if (eventCollection === 'weeklySession') return 'weekly';
  if (eventCollection === 'dailySession') return 'daily';
  return 'groupSession';
}

export async function joinEvent({
  eventCollection,
  groupId,
  eventId,
  partnerUserId,
  defaultPrice = 0,
  additionalPrice = 0,
  paymentStrategy,
  fromInvite = false,
  allowedToPlayMultiple = false,
  partnerAllowedToPlayMultiple = false,
  coachRobot = null,
  liaSystem,
}) {
  if (!partnerUserId) return;

  const currentUser = auth.currentUser;

  const event = await getEventById({ eventCollection,
    eventId });

  const price = (event.price ?? defaultPrice) + additionalPrice;

  let myRemainingTickets;
  let partnerRemainingTickets;
  if (price > 0) {
    const partnerUserRef = doc(collection(db, 'users'), partnerUserId);
    const partnerUserDoc = await getDoc(partnerUserRef);
    const partnerUserData = partnerUserDoc.data();

    const _user = doc(collection(db, 'users'), currentUser.uid);
    const userDoc = await getDoc(_user);
    const userDocData = userDoc.data();

    // Check available tickets
    const myHighestSub = await getUserHighestSubscription(currentUser.uid);
    const partnersHighestSub = await getUserHighestSubscription(partnerUserId);
    const partnerTicketsSpent = partnerUserData.ticketsSpent ?? 0;
    const partnerExtraTickets = partnerUserData.extraTickets ?? 0;

    myRemainingTickets = getUserRemainingTickets(
      userDocData.ticketsSpent ?? 0,
      myHighestSub,
      userDocData.extraTickets ?? 0,
    );
    partnerRemainingTickets = partnerUserId === getRobotUserId() ? Infinity : getUserRemainingTickets(
      partnerTicketsSpent,
      partnersHighestSub,
      partnerExtraTickets,
    );

    if (!canPairAffordToPlay({
      myRemainingTickets,
      partnerRemainingTickets,
      price,
      paymentStrategy,
    })) {
      if (myRemainingTickets < price) {
        throw new Error(i18next.t('events.error_cannot_afford'));
      } else {
        throw new Error(i18next.t('events.error_partner_cannot_afford'));
      }
    }
  }

  if (
    !allowedToPlayMultiple &&
    await checkIfAlreadyPlaying({
      eventCollection,
      eventId,
      userId: currentUser.uid,
    })
  ) {
    throw new Error(i18next.t('events.error_already_playing'));
  }

  if (
    !partnerAllowedToPlayMultiple &&
    await checkIfAlreadyPlaying({
      eventCollection,
      eventId,
      userId: partnerUserId,
    })
  ) {
    throw new Error(i18next.t('events.error_partner_already_playing'));
  }

  if (
    allowedToPlayMultiple &&
    partnerAllowedToPlayMultiple &&
    await checkIfAlreadyPlayingTogether({
      eventCollection,
      eventId,
      userId: currentUser.uid,
      partnerUserId,
    })
  ) {
    throw new Error(i18next.t('events.error_already_playing_together'));
  }

  if (!fromInvite) {
    const allowedToCharge = await checkAllowedToChargeFriendTicketsAndEvents(partnerUserId);

    if (!allowedToCharge) {
      throw new Error(i18next.t('events.error_joining_events_disabled'));
    }
  }

  const deals = await getEventDeals(event.deals);

  if (!deals || deals.length === 0) {
    return;
  }

  if (event.suggestedBidding) {
    deals.forEach(deal => {
      deal.suggestedBidding = event.suggestedBidding[deal.id];
    });
  }

  let user1 = currentUser.uid;
  let user2 = partnerUserId;

  if (coachRobot && coachRobot.direction === 'N') {
    user1 = partnerUserId;
    user2 = currentUser.uid;
  }

  const { batch, sessionRef } = await createSession({
    deals,
    compete: event.compete ?? 2,
    userOneId: user1,
    userTwoId: user2,
    [getCreateSessionEventFieldName(eventCollection)]: event.id,
    groupId,
    sessionHeadline: event.headline,
    returnBatch: true,
    tags: event.tag ? [event.tag] : event.tags,
    tagNames: event.tagNames,
    coachRobot,
    liaSystem,
  });

  const sessionId = sessionRef.id;

  const eventRef = doc(collection(db, eventCollection), event.id);

  batch.update(eventRef, {
    pairCount: increment(1),
  });

  const currentUserParticipationRef = doc(
    collection(doc(collection(db, eventCollection), event.id), 'participants'),
    currentUser.uid,
  );
  const partnerUserParticipationRef = doc(
    collection(doc(collection(db, eventCollection), event.id), 'participants'),
    partnerUserId,
  );

  batch.set(currentUserParticipationRef, {
    allowedToPlayMultiple,
    entries: arrayUnion({
      partner: partnerUserId,
      session: sessionId,
    }),
  }, {
    merge: true,
  });

  batch.set(partnerUserParticipationRef, {
    allowedToPlayMultiple: partnerAllowedToPlayMultiple,
    entries: arrayUnion({
      partner: currentUser.uid,
      session: sessionId,
    }),
  }, {
    merge: true,
  });

  if (price > 0) {
    const { myCharge, partnerCharge } = getCharges({
      myRemainingTickets,
      partnerRemainingTickets,
      price,
      paymentStrategy,
    });

    await chargeUsersTickets({
      userCharges: [
        {
          user: currentUser.uid,
          charge: myCharge,
        },
        {
          user: partnerUserId,
          charge: partnerCharge,
        },
      ],
      batch,
    });
  }

  if (partnerUserId === getRobotUserId() && liaSystem) {
    void updateUser({ defaultLiaSystem: liaSystem });
  }

  await batch.commit();

  return sessionId;
}

export function getCurrentUserInvitesObservable(callback) {
  const currentUser = auth.currentUser;

  // TODO: Handle expired in query?
  const q = query(
    collection(db, EVENT_INVITES_COLLECTION),
    where('users', 'array-contains', currentUser.uid),
  );

  return queryListenWithCallback(q, callback);
}

export async function deleteInvite(id) {
  const inviteRef = doc(collection(db, EVENT_INVITES_COLLECTION), id);

  return await deleteDoc(inviteRef);
}

function getEventCollection(inviteType) {
  if (inviteType === 'groupSession') {
    return 'groupSession';
  }
}

async function deleteOtherInvites({ inviteData, partnerUserId, group }) {
  const currentUser = auth.currentUser;

  let allowedToPlayMultiple = false;
  let partnerAllowedToPlayMultiple = false;
  if (inviteData.groupId) {
    const groupHasCoaching = (group?.premiumFeatures || []).includes('coaching');
    const multipleJoins = (group?.premiumFeatures || []).includes('multipleJoins');

    allowedToPlayMultiple = multipleJoins || (groupHasCoaching && group?.admins.includes(currentUser.uid));
    partnerAllowedToPlayMultiple = multipleJoins || (groupHasCoaching && group?.admins.includes(partnerUserId));
  }

  if (!allowedToPlayMultiple) {
    const myInvitesQuery = query(
      collection(db, EVENT_INVITES_COLLECTION),
      where('users', 'array-contains', currentUser.uid),
      where('eventId', '==', inviteData.eventId),
    );
    getDocs(myInvitesQuery).then(function (invitesToDelete) {
      invitesToDelete.docs.forEach(function (qds) {
        deleteDoc(qds.ref);
      });
    });
  }

  if (!partnerAllowedToPlayMultiple) {
    const partnerInvitesQuery = query(
      collection(db, EVENT_INVITES_COLLECTION),
      where('users', 'array-contains', partnerUserId),
      where('eventId', '==', inviteData.eventId),
    );
    getDocs(partnerInvitesQuery).then(function (invitesToDelete) {
      invitesToDelete.docs.forEach(function (qds) {
        deleteDoc(qds.ref);
      });
    });
  }
}

export async function acceptEventInvite(id) {
  const currentUser = auth.currentUser;

  const inviteRef = doc(collection(db, EVENT_INVITES_COLLECTION), id);

  const inviteDoc = await getDoc(inviteRef);
  const inviteData = docToObject(inviteDoc);

  if (!inviteData.users.includes(currentUser.uid)) {
    throw new Error(i18next.t('events.error_invite_not_for_you'));
  }

  if (!!inviteData.expiration && inviteData.expiration < Date.now()) {
    throw new Error(i18next.t('events.error_invite_expired'));
  }

  const partnerUserId = inviteData.inviter === currentUser.uid ? inviteData.invitee : inviteData.inviter;
  const eventCollection = getEventCollection(inviteData.type);

  let allowedToPlayMultiple = false;
  let partnerAllowedToPlayMultiple = false;
  let group;
  if (inviteData.groupId) {
    group = await getGroup(inviteData.groupId);
    const groupHasCoaching = (group?.premiumFeatures || []).includes('coaching');
    const multipleJoins = (group?.premiumFeatures || []).includes('multipleJoins');

    allowedToPlayMultiple = multipleJoins || (groupHasCoaching && group?.admins.includes(currentUser.uid));
    partnerAllowedToPlayMultiple = multipleJoins || (groupHasCoaching && group?.admins.includes(partnerUserId));
  }

  const sessionId = await joinEvent({
    eventCollection,
    groupId: inviteData.groupId,
    eventId: inviteData.eventId,
    partnerUserId,
    defaultPrice: inviteData.price,
    paymentStrategy: inviteData.paymentStrategy,
    fromInvite: true,
    allowedToPlayMultiple,
    partnerAllowedToPlayMultiple,
  });

  // TODO: have this in batch too
  await deleteDoc(inviteRef);

  // TODO: Better way to do this?
  if (eventCollection === 'groupSession') {
    void createGroupSessionInviteAcceptedNotification(partnerUserId, currentUser.displayName, inviteData.eventName, sessionId);
  }

  void deleteOtherInvites({ inviteData, partnerUserId, group });

  return sessionId;
}

// The bidding session connected to an event
export async function getSessionForEvent(eventFieldName, eventId) {
  const currentUser = auth.currentUser;

  const sessionRef = collection(db, 'sessions');

  const q = query(
    sessionRef,
    where(eventFieldName, '==', eventId),
    where('users', 'array-contains', currentUser.uid),
    limit(1),
  );

  log('making db request')

  return queryFirstItemToObject(q);
}
