import { ranges } from './constants';

const respondingTo1Major = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-[PD]$/,
    explain: 'partner opened 1H, we want to bid spades?',
    options: [
      {
        bid: '1S["4+!S"]',
        reqHp: ranges.newSuitForced,
        reqMaxH: 2,
        reqS: 4,
      },
      {
        bid: '2S["Weak, 6+!S"]',
        reqHp: ranges.weakJumpShift,
        reqMaxHp: ranges.weakOpen,
        reqMaxH: 2,
        reqS: 6,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 2 : 4),
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-(P|D|1S)$/,
    explain: 'partner opened 1M, we want to raise major?',
    options: [
      {
        bid: '2N[@"Inv+, 3+!o"]',
        reqHp: ranges.invitational,
        reqFit: 3,
      },
      {
        bid: '4o["To play"]',
        reqHp: ranges.weakRaise,
        reqFit: 5,
      },
      {
        bid: '1N["Not forcing"]',
        reqHp: ranges.newSuitUnforced,
        reqMaxHp: ranges.weakOpen,
        reqMaxFit: 2,
        reqMaxS: 3,
      },
      {
        bid: '3o["Weak, 4+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 4,
      },
      {
        bid: '2o["5—9 HCP, 3+!o"]',
        reqHp: ranges.raise,
        reqFit: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-[PD]-1S-P$/,
    explain: 'we bid 1H-1S, rebid over no interference',
    options: [
      {
        bid: '2S["4!S"]',
        reqS: 4,
      },
      {
        bid: '2C["Not forcing, 4+!C"]',
        reqC: 4,
      },
      {
        bid: '2D["Not forcing, 4+!D"]',
        reqD: 4,
      },
      {
        bid: '2H["6+!H"]',
        reqH: 6,
      },
      {
        bid: '1N["12-14"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-[PD]-1S-(D|1N|2C|2D)$/,
    explain: 'we bid 1H-1S, rebid over interference',
    options: [
      {
        bid: 'P',
        reqS: 3,
        maxReqH: 5,
        lastBid: 'D',
        weight: 2,
      },
      {
        bid: '2S["4!S"]',
        reqS: 4,
      },
      {
        bid: '2H["6+!H"]',
        reqH: 6,
      },
      {
        bid: '2C["Not forcing, 4+!C"]',
        reqC: 4,
      },
      {
        bid: '2D["Not forcing, 4+!D"]',
        reqD: 4,
      },
      {
        bid: '1N["12-14"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-[PD]-1S-[PD]-1N-(P|D|2C|2D|2H)$/,
    explain: 'we bid 1H-1S-1N, responder preference',
    options: [
      {
        bid: '2S["5+!S"]',
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-[PD]-1S-([PD]|1N)-2C-(P|D|2D|2H)$/,
    explain: 'we bid 1H-1S-2C, responder preference',
    options: [
      {
        bid: 'P',
        reqC: 4,
      },
      {
        bid: '2H["Preference"]',
        reqH: 2,
      },
      {
        bid: '2S["6+!S"]',
        reqS: 6,
      },
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.C - 2 >= strains.H;
        },
      },
      {
        bid: '2o["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-[PD]-1S-([PD]|1N|2C)-2D-(P|D|2H)$/,
    explain: 'we bid 1H-1S-2D, responder preference',
    options: [
      {
        bid: 'P',
        reqD: 4,
      },
      {
        bid: '2H["Preference"]',
        reqH: 2,
      },
      {
        bid: '2S["6+!S"]',
        reqS: 6,
      },
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.D - 2 >= strains.H;
        },
      },
      {
        bid: '2o["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-1S$/,
    explain: 'partner opened 1H, entry 1S, we take out',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqHp: ranges.intermediateRaise,
        reqMaxH: 2,
        reqMaxS: 3,
        reqC: 4,
        reqD: 4,
      },
      {
        bid: '1N["Not forcing"]',
        reqHp: ranges.newSuitUnforced,
        reqMaxH: 2,
        reqHcpInLastSuit: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-1S-D-(P|R|1N|2.)$/,
    explain: '1H (1S) D, we respond to take out double',
    options: [
      {
        bid: '2C["Not forcing, 4+!C"]',
        reqC: 4,
      },
      {
        bid: '2D["Not forcing, 4+!D"]',
        reqD: 4,
      },
      {
        bid: '2H["6+!H"]',
        reqH: 6,
      },
      {
        bid: '1N["12-14"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-(2C|2D)$/,
    explain: 'partner opened 1M, they entered with 2m, we want to raise major?',
    options: [
      {
        bid: '2N[@"Inv+, 4+!o"]',
        reqHp: ranges.invitational,
        reqFit: 4,
      },
      {
        bid: '4o["To play"]',
        reqHp: ranges.weakRaise,
        reqFit: 5,
      },
      {
        bid: '3o["Weak, 4+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 4,
      },
      {
        bid: '2o["5—9 HCP, 3+!o"]',
        reqHp: ranges.raise,
        reqFit: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2H$/,
    explain: 'partner opened 1S, they entered with 2H, we want to raise S?',
    options: [
      {
        bid: '2N[@"Inv+, 4+!o"]',
        reqHp: ranges.invitational,
        reqFit: 4,
      },
      {
        bid: '4o["To play"]',
        reqHp: ranges.weakRaise,
        reqFit: 5,
      },
      {
        bid: '3o["Weak, 4+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 4,
      },
      {
        bid: '2o["5—9 HCP, 3+!o"]',
        reqHp: ranges.raise,
        reqFit: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-(P|D|1S|2.)-2N-(P|D|3C|3D)$/,
    explain: 'We opened 1M, Responding to Stenberg',
    options: [
      {
        bid: '4o["To play"]',
        reqHp: 16,
      },
      {
        bid: '4o["To play"]',
        reqFit: 6,
        reqHp: 14,
      },
      {
        bid: '3o["Minimum"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-(P|D|2.)-2N-3H$/,
    explain: 'We opened 1M, Responding to Stenberg',
    options: [
      {
        bid: '4o["To play"]',
        reqHp: 16,
      },
      {
        bid: '4o["To play"]',
        reqFit: 6,
        reqHp: 14,
      },
      {
        bid: '3o["Minimum"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(?:-\.)*(?:-P)*-1([HS])-(?:P|D|2.)-2N-(?:P|D|..)-3\1-(?:P|D|..)$/,
    explain: "We bid Stenberg, should we raise partner's minimum bid?",
    options: [
      {
        bid: '4o["To play"]',
        reqHp: 13,
      },
      {
        bid: '4o["To play"]',
        reqFit: 4,
        reqHp: 11,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-[PD]-1N-[PD]$/,
    explain: 'We opened 1S and responded 1N, we bid 2H?',
    options: [
      {
        bid: '2H["Not forcing, 4+!H"]',
        reqH: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-(P|D|1S)-1N-[PD]$/,
    explain: 'We opened 1M and responded 1N, we bid again?',
    options: [
      {
        bid: '2C["Not forcing, 4+!C"]',
        reqC: 4,
      },
      {
        bid: '2D["Not forcing, 4+!D"]',
        reqD: 4,
      },
      {
        bid: '2o["6+!o"]',
        reqFit: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-(P|D|1S)-1N-2[CD]$/,
    explain: 'We opened 1M and responded 1N, they overcall, we bid again?',
    options: [
      {
        bid: '2o["6+!o"]',
        reqFit: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-[PD]-1N-2H$/,
    explain: 'We opened 1M and responded 1N, they overcall, we bid again?',
    options: [
      {
        bid: '2o["6+!o"]',
        reqFit: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-(P|D|1S)-1N-[PD]-2C-[PD]$/,
    explain: 'We opened 1H and rebid 1N and opener bid 2C, make preference?',
    options: [
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.C - 2 >= strains.H;
        },
      },
      {
        bid: '2o["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-[PD]-1N-[PD]-2C-[PD]$/,
    explain: 'We opened 1S and rebid 1N and opener bid 2C, make preference?',
    options: [
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.C - 2 >= strains.S;
        },
      },
      {
        bid: '2o["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-(P|D|1S)-1N-[PD]-2D-[PD]$/,
    explain: 'We opened 1H and rebid 1N and opener bid 2D, make preference?',
    options: [
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.D - 2 >= strains.H;
        },
      },
      {
        bid: '2o["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-[PD]-1N-[PD]-2D-[PD]$/,
    explain: 'We opened 1S and rebid 1N and opener bid 2D, make preference?',
    options: [
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.D - 2 >= strains.S;
        },
      },
      {
        bid: '2o["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-[PD]-1N-[PD]-2H-[PD]$/,
    explain: 'We opened 1S and rebid 1N and opener bid 2H, make preference?',
    options: [
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.H - 2 >= strains.S;
        },
      },
      {
        bid: '2o["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-1N$/,
    explain: 'partner opened 1M, 1N entry, should we raise?',
    options: [
      {
        bid: '3o["Weak, 4+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 4,
      },
      {
        bid: '2o["5—9 HCP, 3+!o"]',
        reqHp: ranges.raise,
        reqFit: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-(2S|2N|3.)$/,
    explain: 'partner opened with 1M, and RHO made a pre-empt',
    options: [
      {
        bid: '4o["To play"]',
        reqHp: ranges.raise,
        reqFit: 5,
      },
      {
        bid: '3o["6—9 HCP, 3+!o"]',
        reqHp: ranges.raise,
        reqFit: 3,
      },
      {
        bid: '3o["6—9 HCP, 3+!o"]',
        reqHp: ranges.weakRaise,
        reqFit: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H$/,
    explain: 'partner opened 1H, and RHO bid 2H',
    options: [
      {
        bid: '4o["To play"]',
        reqHp: ranges.raise,
        reqFit: 5,
      },
      {
        bid: '3o["6—9 HCP, 3+!o"]',
        reqHp: ranges.raise,
        reqFit: 3,
      },
    ],
  },
];

export default respondingTo1Major;
