import { getFirestore } from './base';
import { createCollectionRepo, createSubCollectionRepo } from 'firestore-ts-repo';
export function createRepos() {
    return {
        spots: createCollectionRepo(getFirestore(), 'spots'),
        spotBids: createSubCollectionRepo(getFirestore(), 'spots', 'bids'),
        weeklySessions: createCollectionRepo(getFirestore(), 'weeklySession'),
        weeklySessionParticipants: createSubCollectionRepo(getFirestore(), 'weeklySession', 'participants'),
        dailySessions: createCollectionRepo(getFirestore(), 'dailySession'),
        dailySessionParticipants: createSubCollectionRepo(getFirestore(), 'dailySession', 'participants'),
        reports: createCollectionRepo(getFirestore(), 'bqExport'),
        users: createCollectionRepo(getFirestore(), 'users'),
        checkoutSessions: createSubCollectionRepo(getFirestore(), 'users', 'checkout_sessions'),
        userPayments: createSubCollectionRepo(getFirestore(), 'users', 'payments'),
        products: createCollectionRepo(getFirestore(), 'products'),
        productPrices: createSubCollectionRepo(getFirestore(), 'products', 'prices'),
        pushNotifications: createCollectionRepo(getFirestore(), 'pushNotifications'),
        internalFeedback: createCollectionRepo(getFirestore(), 'internalFeedback'),
        deals: createCollectionRepo(getFirestore(), 'deals'),
        sessions: createCollectionRepo(getFirestore(), 'sessions'),
        sessionDeals: createCollectionRepo(getFirestore(), 'sessionDeals'),
        dealsForExport: createCollectionRepo(getFirestore(), 'dealsForExport'),
        dealReviews: createCollectionRepo(getFirestore(), 'dealReviews'),
        challengeSession: createCollectionRepo(getFirestore(), 'challengeSession'),
        challengeStatistics: createCollectionRepo(getFirestore(), 'challengeStatistics'),
        challengeStatisticsPartnership: createCollectionRepo(getFirestore(), 'challengeStatisticsPartnership'),
        dailySession: createCollectionRepo(getFirestore(), 'dailySession'),
        weeklySession: createCollectionRepo(getFirestore(), 'weeklySession'),
        groups: createCollectionRepo(getFirestore(), 'groups'),
        groupSession: createCollectionRepo(getFirestore(), 'groupSession'),
        groupSessionDraft: createCollectionRepo(getFirestore(), 'groupSessionDraft'),
        generationRequests: createCollectionRepo(getFirestore(), 'generationRequests'),
        communityScenarios: createCollectionRepo(getFirestore(), 'communityScenarios'),
        handTypes: createCollectionRepo(getFirestore(), 'handTypes'),
        dealTimestamps: createCollectionRepo(getFirestore(), 'dealTimestamps'),
        gifts: createCollectionRepo(getFirestore(), 'gifts')
    };
}
