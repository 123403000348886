import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>Cuebids - Perfect your bidding</title>
        <meta name="description" content="The most flexible, powerful way to practice bridge online, on the web and phones." />
        <meta name="author" content="Cuebids" />
        <meta name="robots" content="index, follow" />
        <meta name="canonical" content="https://cuebids.com" />
        <meta property="og:title" content="Cuebids - Perfect your bidding" />
        <meta property="og:description" content="The most flexible, powerful way to practice bridge online, on the web and phones." />
        <meta property="og:image" content="https://cuebids.com/logo512.png" />
        <meta property="og:url" content="https://cuebids.com" />
        <meta property="og:site_name" content="Cuebids" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@cuebids" />
        <meta name="twitter:title" content="Cuebids - Perfect your bidding" />
        <meta name="twitter:description" content="The most flexible, powerful way to practice bridge online, on the web and phones." />
        <meta name="twitter:image" content="https://cuebids.com/logo512.png" />
        <meta name="twitter:url" content="https://cuebids.com/guides/swe" />
        <meta name="keywords" content="Cuebids,bridge,bidding,practice,budträning" />
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);
