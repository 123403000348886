import { Link } from 'react-router-dom';
import Alert from '../../components/alert/alert';
import { GridCard } from '../../components/cardGrid/cardGrid';
import SessionCard from '../../components/session/sessionCard';
import { useAuth } from '../../util/hooks.jsx';
import { FriendsIcon } from '../../components/icons/friendsIcon';
import { HandshakeIcon } from '../../components/icons/handShakeIcon';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useTranslation } from 'react-i18next';

export default function Practice() {
  const sessions = useAppStateStore((state) => state.sessions);
  const lastSession = useAppStateStore((state) => state.sessionId);
  const { currentUser } = useAuth();
  const { t } = useTranslation();

  const practiceSessions = sessions.filter(
    (s) => !s.challenge && s.weekly === 0 && s.daily === 0 && !s.groupSession
  );

  return (
    <>
      <div className="fade-in">
        <div className="page pb-24">
          <Alert
            sx={'mt-5 md:w-1/2'}
            title={t('practice.title')}
            text={t('practice.description')}
          />
          <div className="mt-8 grid grid-cols-2 gap-4">
            <Link to="/session">
              <GridCard
                name={t('routes.with_friends')}
                icon={<FriendsIcon fill="#4d774e" width={32} />}
              />
            </Link>
            <Link to="/practice/match">
              <GridCard
                name={t('routes.with_random')}
                icon={<HandshakeIcon fill="#e29627" width={32} />}
              />
            </Link>
          </div>
          {practiceSessions.length > 0 && (
            <div
              key={'sessionList'}
              className="flex w-full flex-col items-center justify-center"
            >
              <table
                id="session-list"
                className="mt-10 w-full border-collapse rounded-sm md:w-4/5"
              >
                <thead>
                  <tr>
                    <th>{t('practice.partner')}</th>
                    <th>{t('practice.progress')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {practiceSessions.map((s, i) => {
                    return (
                      <SessionCard
                        session={s}
                        key={s.id}
                        currentUser={currentUser}
                        highlight={lastSession === s.id}
                        i={i}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
