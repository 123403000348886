import 'flag-icons/css/flag-icons.css';
import { useCallback, useEffect, useState } from 'react';
import { UserDisplayAvatar } from '../../components/users/userDisplay';
import { useAuth } from '../../util/hooks.jsx';
import {
  getUser,
  updateProfilePicture,
  updateUser,
} from '../../firebase/userApi';

import {
  doesDisplayNameHaveForbiddenCharacters,
  getTrimmedDisplayName,
  isDisplayNameTooShort,
} from '../../util/displayName';

import { SaveIcon } from '../../components/icons/saveIcon';
import { useTranslation } from 'react-i18next';
import log from '../../util/logger';
import { useAppStateStore } from '../../appStateStore.jsx';
import useNotifications from '../../components/notifications/useNotifications.jsx';

function getDisplayNameError(displayName, t) {
  if (!displayName || isDisplayNameTooShort(displayName)) {
    return t('profile.errors.display_name_too_short');
  }

  if (doesDisplayNameHaveForbiddenCharacters(displayName)) {
    return t('profile.errors.display_name_forbidden_characters');
  }

  return '';
}
export default function SpotsProfile() {
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [imageReload, setImageReload] = useState();
  const [allowNewSpotsPush, setAllowNewSpotsPush] = useState(false);

  const { t } = useTranslation();

  const { currentUser } = useAuth();

  const [madeChanges, setMadeChanges] = useState(false);

  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const notify = useNotifications();
  const appStateUser = useAppStateStore((state) => state.user);

  const save = useCallback(async () => {
    if (displayNameError) {
      return;
    }

    const err = getDisplayNameError(displayName, t);
    if (err) {
      setDisplayNameError(err);
      return;
    }

    updateUser({
      displayName: getTrimmedDisplayName(displayName),
      allowNewSpotsPush,
    });
    const _user = appStateUser;
    _user.displayName = getTrimmedDisplayName(displayName);
    _user.allowNewSpotsPush = allowNewSpotsPush;
    updateAppState({
      user: _user,
    });
    notify(
      {
        text: t('profile.notifications_saved'),
        type: 'success',
      }
    );
    setMadeChanges(false);
    setUser((u) => {
      return {
        ...u,
        displayName: getTrimmedDisplayName(displayName),
        allowNewSpotsPush,
      };
    });
  }, [
    displayNameError,
    displayName,
    t,
    allowNewSpotsPush,
    appStateUser,
    updateAppState,
    notify,
  ]);

  useEffect(function () {
    getUser().then(function (data) {
      data.flag = data.flag === '' ? undefined : data.flag;
      setUser(data);
      setDisplayName(data.displayName || '');
      setDisplayNameError(getDisplayNameError(data.displayName || ''));
      setAllowNewSpotsPush(data.allowNewSpotsPush ?? false);
    });
  }, [currentUser.email]);

  const updateDisplayName = (event) => {
    const newValue = event.target.value.trimStart();
    setDisplayName(newValue);

    setDisplayNameError(getDisplayNameError(newValue));
    setMadeChanges(true);
  };

  const updateAllowNewSpotsPush = (event) => {
    setAllowNewSpotsPush(event.target.checked);
    setMadeChanges(true);
  };

  const handleUploadPicture = async (e) => {
    const file = e.target.files[0];
    try {
      await updateProfilePicture(file);
      setImageReload(Date.now());
    } catch (e) {
      log(e);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="fade-in relative">
      <div className="flex flex-col items-center">
        <div className="my-2 flex w-3/4 flex-col items-center justify-between md:w-2/3">
          <label className="font-bold">{t('profile.display_name')}</label>
          <input
            className="input-accent input w-full border-2 border-solid border-slate-200 bg-white text-black caret-secondary"
            onChange={updateDisplayName}
            value={displayName}
            placeholder={t('profile.display_name_placeholder')}
            aria-describedby={
              displayNameError ? 'username-helper-text' : undefined
            }
          />
          {displayNameError && (
            <span id="username-helper-text" className="text-error">
              {displayNameError}
            </span>
          )}
        </div>

        <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
          <span className="font-bold">{t('profile.avatar')}</span>
          <button className="btn-accent btn flex rounded-full p-0">
            <label htmlFor="avatar" className="cursor-pointer">
              <UserDisplayAvatar key={imageReload} uid={user.id} />
            </label>
            <input
              type="file"
              id="avatar"
              accept="image/*"
              hidden
              onChange={handleUploadPicture}
            />
          </button>
        </div>

        <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
          <label className="label w-full cursor-pointer p-0 font-bold">
            {t('profile.new_spots_notifications')}
            <input
              type="checkbox"
              className="toggle-primary toggle"
              checked={allowNewSpotsPush}
              onChange={updateAllowNewSpotsPush}
            />
          </label>
        </div>
        <button
          className="btn-primary btn-wide btn mt-4 items-center gap-4"
          onClick={save}
          disabled={!madeChanges}
        >
          {t('app.save')} <SaveIcon />
        </button>
        {displayNameError && (
          <span id="username-helper-text" className="text-error">
            {t('profile.errors.display_name_invalid')}
          </span>
        )}
      </div>
    </div>
  );
}
