import { Link, useParams } from 'react-router-dom';
import Animated from '../../components/animation/animated';
import '../../components/hand/hands.css';
import club from '../../images/symbols/Club.svg';
import diamond from '../../images/symbols/Diamond.svg';
import heart from '../../images/symbols/Heart.svg';
import spade from '../../images/symbols/Spade.svg';
import { useTranslation } from 'react-i18next'

const subIcon = {
  club: club,
  diamond: diamond,
  heart: heart,
  spade: spade,
};

export default function Thanks() {
  const { t } = useTranslation('translation', { keyPrefix: 'store_thanks' });
  const { subscriptionLevel } = useParams();

  const ticketMap = {
    '50tickets': t('50_tickets'),
    '250tickets': t('250_tickets'),
    '1500tickets': t('1500_tickets'),
  };

  const subscriptionMap = {
    club: (
      <>
        <p>
          {t('club.info_1_pre_styled')}<span className="clubs font-bold">{t('club.info_1_styled')}</span>{t('club.info_1_post_styled')}
        </p>
        <p>{t('club.info_2')}</p>
      </>
    ),
    diamond: (
      <>
        <p>
          {t('diamond.info_1_pre_styled')}<span className="diamonds font-bold">{t('diamond.info_1_styled')}</span>{t('diamond.info_1_post_styled')}
        </p>
        <p>{t('diamond.info_2')}</p>
      </>
    ),
    heart: (
      <>
        <p>
          {t('heart.info_1_pre_styled')}<span className="hearts font-bold">{t('heart.info_1_styled')}</span>{t('heart.info_1_post_styled')}
        </p>
        <p>
          {t('heart.info_2_pre_styled')}
          <span className="font-bold text-amber-500">
          {t('heart.info_2_styled')}
        </span>
        </p>
      </>
    ),
  };

  return (
    <Animated
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <div className="page p-4">
        <article className="prose">
          <h1>{t('title')}</h1>
          {ticketMap[subscriptionLevel] ? (
            <h3>{ticketMap[subscriptionLevel]}</h3>
          ) : (
            <>
              {subscriptionMap[subscriptionLevel]}

              <img src={subIcon[subscriptionLevel]} width={75} />
            </>
          )}

          <p>{t('info')}</p>
          <Link className="btn-primary btn mt-10" to="/">
            {t('to_start_page')}
          </Link>
        </article>
      </div>
    </Animated>
  );
}
