import { Link, useNavigate } from 'react-router-dom'
import Alert from '../alert/alert';
import {
  getCurrentOnboardingStep,
  getNextOnboardingStep,
  hasCompletedOnboardingStep,
  onboardingSteps,
} from '../../util/onboarding'
import { updateUser } from '../../firebase/userApi';
import scrollToTop from '../../util/scrollToTop';
import { useAppStateStore } from '../../appStateStore.jsx';
import { createPracticeSession } from '../../firebase/biddingSessions.js'
import { getRobotUserId } from '../../util/robotPartner.js'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useNotifications from '../notifications/useNotifications.jsx';


function TestSessionButton() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const metadata = useAppStateStore((state) => state.metadata);
  const sessions = useAppStateStore((state) => state.sessions);
  const { t } = useTranslation()
  const notify = useNotifications();

  const handleClick = async () => {
    setLoading(true);
    try {
      const id = await createPracticeSession({
        userTwoId: getRobotUserId(),
        numberOfDeals: 3,
        compete: 0,
        minDealNumber: metadata?.minDealNumber,
        price: 0,
      });

      setLoading(false);
      navigate(`/session/${id}`, { replace: true });
    } catch (e) {
      setLoading(false);
      notify(
        {
          type: 'failure',
          text: t('onboarding.step_bidding.error', {error: e.message}),
        },
      );
    }
  };

  if (sessions.some(s => s.users[1] === getRobotUserId())) {
    return (
      <Alert
        text={t('onboarding.step_bidding.lia_session_started')}
      />
    );
  }

  return (
    <button
      className="btn-primary btn my-4"
      disabled={loading}
      onClick={handleClick}
    >
      {loading ? t('app.loading') : t('onboarding.step_bidding.try')}
    </button>
  );
}

export default function NewUser() {
  const user = useAppStateStore((state) => state.user);
  const friends = useAppStateStore((state) => state.friends);
  const { t } = useTranslation()
  const step = getCurrentOnboardingStep({ user });

  function handleNextStep(e) {
    e.preventDefault();
    void updateUser({ onboardingStep: getNextOnboardingStep(step) });
    scrollToTop('smooth');
  }

  const waitingForFriendToAcceptRequest =
    step === onboardingSteps.addFriend && friends.length > 0;

  if (step === onboardingSteps.finishSession) {
    return (
      <Alert
        sx="border-pulse mt-10"
        text={t('onboarding.step_bidding.click_to_navigate')}
      />
    );
  }

  if ([onboardingSteps.stars, onboardingSteps.robots, onboardingSteps.matchPoints, onboardingSteps.help].includes(step)) {
    return (
      <article className="break-word prose mb-4 mt-10 max-w-[80%] rounded-md p-4">
        <h1>{t('onboarding.step_help.title')}</h1>
        <p>
          {t('onboarding.step_help.info_1_pre_link')}
          <Link to="/help">{t('onboarding.step_help.info_1_link')}</Link>
          {t('onboarding.step_help.info_1_post_link')}
        </p>
        <p>
          {t('onboarding.step_help.info_2_pre_link')}
          <Link to="/contact">{t('onboarding.step_help.info_2_link')}</Link>
          {t('onboarding.step_help.info_2_post_link')}
        </p>
        <p>
          {t('onboarding.step_help.info_3_pre_link')}
          <a
            target="_blank"
            href="https://discord.gg/U75379cD3G?:ext"
            rel="noreferrer"
          >
            {t('onboarding.step_help.info_3_link')}
          </a>
        </p>
        <p>
          <strong>{t('onboarding.step_help.enjoy')}</strong>
        </p>
        <button
          className="border-pulse btn-primary btn-wide btn mt-4"
          onClick={handleNextStep}
        >
          {t('onboarding.step_help.confirm')}
        </button>
      </article>
    );
  }

  return (
    <div className="mt-10 flex w-96 flex-col items-center justify-center overflow-y-auto text-base-content">
      <article className="prose mb-4">
        <h1>
          {t('onboarding.welcome_title', { name: user.displayName })}
        </h1>
      </article>
      <ul className="steps mb-8">
        <li data-content="1" className="step-primary step cursor-pointer">
          <span
            className={
              step !== onboardingSteps.settings ? 'opacity-50' : undefined
            }
          >
            {t('onboarding.step_settings.title')}
          </span>
        </li>
        <li
          data-content="2"
          className={
            'step' +
            (hasCompletedOnboardingStep({ user, step: onboardingSteps.settings }) ?
              ' step-primary' :
              '')
          }
        >
          <span
            className={
              step !== onboardingSteps.testSession ? 'opacity-50' : undefined
            }
          >
            {t('onboarding.step_bidding.title')}
          </span>
        </li>
        <li
          data-content="3"
          className={
            'step' +
            (hasCompletedOnboardingStep({ user, step: onboardingSteps.testSession }) ?
              ' step-primary' :
              '')
          }
        >
          <span
            className={
              (step !== onboardingSteps.addFriend && step !== onboardingSteps.createSession) ? 'opacity-50' : undefined
            }
          >
            {t('onboarding.step_friend.title')}
          </span>
        </li>
      </ul>
      {step === onboardingSteps.settings && (
        <div>
          <article className="prose">
            <h2>{t('onboarding.step_settings.title')}</h2>
            {t('onboarding.step_settings.text')}
          </article>
          <button
            className="btn-secondary btn-wide btn mt-4"
            onClick={handleNextStep}
          >
            {t('onboarding.skip_step')}
          </button>
        </div>
      )}
      {step === onboardingSteps.testSession && (
        <div>
          <article className="prose">
            <h2>{t('onboarding.step_bidding.title')}</h2>
            {t('onboarding.step_bidding.text')}
            <br />
            {t('onboarding.step_bidding.more')}
          </article>
          <TestSessionButton />
          <button
            className="btn-secondary btn-wide btn mt-4"
            onClick={handleNextStep}
          >
            {t('onboarding.skip_step')}
          </button>
        </div>
      )}
      {step === onboardingSteps.addFriend && (
        <div className="w-full">
          {waitingForFriendToAcceptRequest ? (
            <>
              <article className="prose">
                <h2>{t('onboarding.step_friend.title')}</h2>
              </article>
              <Alert
                sx="border-pulse"
                text={t('onboarding.step_friend.pending_requests_info')}
              />
            </>
          ) : (
            <>
              <article className="prose">
                <h2>{t('onboarding.step_friend.title')}</h2>
                {t('onboarding.step_friend.info')}
              </article>
              <table className="mt-4 overflow-hidden rounded-md">
                <tbody>
                  <tr>
                    <td className="p-2" colSpan={2}>
                      <Trans
                        i18nKey="onboarding.step_friend.friend_key_info"
                        t={t}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2" colSpan={2}>
                      {t('onboarding.step_friend.search_info')}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2">
                      <Trans
                        i18nKey="onboarding.step_friend.send_friend_key_info"
                        t={t}
                      />
                    </td>
                    <td className="p-2">
                      {t('onboarding.step_friend.your_key')}<strong className="text-amber-400 select-text">{user.friendKey}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2" colSpan={2}>
                      {t('onboarding.step_friend.link_info')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          <div>
            <article className="mt-4">
              {t('onboarding.step_friend.no_partner_info')}
            </article>
            <table className="mt-4 overflow-hidden rounded-md">
              <tbody>
                <tr>
                  <td className="p-2">
                    <Trans
                      i18nKey="onboarding.step_friend.lia_info"
                      t={t}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-2">
                    <Trans
                      i18nKey="onboarding.step_friend.matchmaking_info"
                      t={t}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            className="btn-secondary btn-wide btn mt-4"
            onClick={handleNextStep}
          >
            {t('onboarding.skip_step')}
          </button>
        </div>
      )}
      {step === onboardingSteps.createSession && (
        <div className="w-full">
          <article className="prose">
            <h2>{t('onboarding.step_create_session.title')}</h2>
            <p>
              <Trans
                i18nKey="onboarding.step_create_session.info"
                t={t}
              />
            </p>
          </article>
          <Link to="/session" className="btn-secondary btn-wide btn mt-4">
            {t('onboarding.step_create_session.link')}
          </Link>
        </div>
      )}
    </div>
  );
}
