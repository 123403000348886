import { useEffect, useRef, useState } from 'react';
import './components/table/table.css';
import { useActiveSubs, useAuth, useHasLoadedDataToGetSubs } from './util/hooks.jsx'
import {
  getSessionDealsObservable,
  getSessionObservable,
  getSessionsObservable,
  getSharedSessionsObservable,
  syncSessionAggregatedFields,
} from './firebase/biddingSessions';
import { getDailyObservable } from './firebase/daily';
import { getAllFriendsObservable } from './firebase/friends';
import { getMetadataObservable } from './firebase/metadata';
import {
  myIAPSubscriptionObservable,
  mySubscriptionObservable,
  pingOwnUser,
  updateUserStateGroupSubscriptions,
} from './firebase/subscriptions';
import { getUserObservable, updateUser } from './firebase/userApi';
import { getWeeklyObservable } from './firebase/weekly';
import checkIfNative, {
  checkIfNativeIos,
  checkMobileDevice,
} from './util/checkNative';

import { goOffline, goOnline } from 'firebase/database';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useInterval } from 'usehooks-ts';
import {
  completeAchievement,
  getUserAchievementsObservable,
} from './firebase/achievements';
import {
  getChallengeMatchmakingForUserObservable,
  getChallengeQueueObservable,
} from './firebase/challengeMatchmaking';
import {
  getChallengeDealsObservable,
  getChallengesObservable,
} from './firebase/challenges';
import { getCurrentUserInvitesObservable } from './firebase/events';
import { submitInternalFeedback } from './firebase/feedback';
import { realDb } from './firebase/firebase';
import {
  getGroupChatObservable,
  getGroupDbObservable,
} from './firebase/groupChat';
import {
  getCurrentUserGroupInvitesObservable,
  getCurrentUserGroupsObservable,
  joinGroup,
} from './firebase/groups';
import { getMatchmakingObservable } from './firebase/matchmaking';
import { startPresenceObservable } from './firebase/presence';
import { checkForCompletedAchievements } from './util/achievements';
import log from './util/logger';
import {
  getCurrentOnboardingStep,
  getNextOnboardingStep,
  onboardingRules,
  shouldShowOnboarding,
} from './util/onboarding';
import { getSubsFromGroups, officialSubs } from './util/sub';
import { getMessagesUserObservable } from './firebase/messages';
import { getSpotsObservable } from 'cuebids-firebase/spots';
import { useAppStateStore } from './appStateStore.jsx';
import { singlePlayerRobotBidOnSessionDeal } from 'cuebids-firebase/bidding'
import {
  getRobotDealToBidOn,
  getRobotUserId,
  shouldRobotBidOnSession
} from './util/robotPartner.js'
import ClubToHeart from './marketing/ClubToHeartDialog.jsx';
import DiamondToHeart from './marketing/DiamondToHeartDialog.jsx';
import FreeToDiamond from './marketing/FreeToDiamondDialog.jsx';
import FreeToHeartDialog from './marketing/FreeToHeartDialog.jsx';
import { getNextMarketing, marketingSteps } from './util/marketing.js';
import { checkIfHasBoughtTicketsLast30Days, handleMarketingAttempt, resetMarketingTime } from './firebase/marketing.js'
import useNotifications from './components/notifications/useNotifications.jsx';
import { onMessage, isSupported } from 'firebase/messaging';
import { getMessaging } from 'cuebids-firebase';
import { getBrowserPreferredLanguage } from './util/language.js'

function getCurrentSession(sessions, sessionId) {
  return (sessions || []).find((s) => s.id === sessionId);
}

export function Marketing() {
  const showTime = 20;
  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const user = useAppStateStore((state) => state.user);
  const checkShowMarketing = useAppStateStore((state) => state.checkShowMarketing);
  const [startTime, setStartTime] = useState(0);
  const [loading, setLoading] = useState(false);

  const subs = useActiveSubs();
  const highestSub = subs?.[0] ?? 'none';
  const hasLoadedDataToGetSubs = useHasLoadedDataToGetSubs();

  const currentMarketingStep = user?.lastMarketingStep;

  useEffect(function () {
    if (loading) return;

    if (user?.marketingTime && !startTime) {
      // Check if user had ongoing marketing step (and reloaded app/page)
      const timeLeft = user.marketingTime + (showTime * 1000) - Date.now()

      if (timeLeft > 0) {
        // We should still show it
        setStartTime(user.marketingTime);
        updateAppState({ checkShowMarketing: false });
      } else {
        // Show time has expired
        void resetMarketingTime();
        updateAppState({ checkShowMarketing: false });
      }
    } else if (hasLoadedDataToGetSubs && checkShowMarketing) {
      // Check which step to show next
      const nextMarketingStep = getNextMarketing({
        lastMarketingStep: currentMarketingStep,
        currentSubscription: highestSub,
        lastMarketingNumberOfStars: user?.lastMarketingNumberOfStars ?? 0,
        marketingAttemptsCount: user?.marketingAttemptsCount ?? 0,
      });

      if (nextMarketingStep && nextMarketingStep !== marketingSteps.heartSubscriber) {
        const asyncFn = async () => {
          setLoading(true);
          try {
            const hasBoughtTickets = await checkIfHasBoughtTicketsLast30Days();
            if (!hasBoughtTickets) {
              await handleMarketingAttempt(nextMarketingStep, user?.numberOfStars);
              setStartTime(Date.now());
              log('marketing step', { nextMarketingStep });
            }
          } finally {
            updateAppState({ checkShowMarketing: false });
            setLoading(false);
          }
        };
        void asyncFn();
      } else {
        updateAppState({ checkShowMarketing: false });
      }
    }
  }, [
    loading,
    checkShowMarketing,
    currentMarketingStep,
    hasLoadedDataToGetSubs,
    highestSub,
    startTime,
    updateAppState,
    user?.marketingTime,
    user?.lastMarketingNumberOfStars,
    user?.numberOfStars,
    user?.marketingAttemptsCount,
  ]);

  const denyMarketing = async () => {
    await resetMarketingTime();
    setStartTime(0);
  }

  if (!startTime || !currentMarketingStep) {
    return null;
  }

  switch (user?.lastMarketingStep) {
  case marketingSteps.heartSubscriber: return null;
  case marketingSteps.clubToHeart: return <ClubToHeart onDeny={denyMarketing} startTime={startTime} showTime={showTime}  />
  case marketingSteps.diamondToHeart: return <DiamondToHeart onDeny={denyMarketing} startTime={startTime} showTime={showTime}  />
  case marketingSteps.tryDiamond: return <FreeToDiamond onDeny={denyMarketing} startTime={startTime} showTime={showTime}  />
  default: return <FreeToHeartDialog onDeny={denyMarketing} startTime={startTime} showTime={showTime} />
  }
}

export function EnsureConnection({ children }) {
  const { t } = useTranslation();
  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const connected = useAppStateStore((state) => state.connected);
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    async function testConnection() {
      const timeoutPromise = new Promise((resolve) => {
        setTimeout(resolve, 2000, false);
      });
      const pingPromise = pingOwnUser();
      const connected = await Promise.race([timeoutPromise, pingPromise]);

      if (!connected) {
        goOffline(realDb);
        goOnline(realDb);
      }
    }
    if (currentUser) {
      void testConnection();
    }
  }, [location, currentUser]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [setLoading]);

  useEffect(() => {
    let timeoutId;
    function connectionCallback(status) {
      if (!status) {
        timeoutId = setTimeout(() => {
          updateAppState({ connected: false });
        }, 2000);
      } else {
        clearTimeout(timeoutId);
        updateAppState({ connected: true });
      }
    }

    const sub = startPresenceObservable({ callback: connectionCallback });

    return () => {
      sub && sub();
      clearTimeout(timeoutId);
    };
  }, [updateAppState]);

  function handleReconnect() {
    try {
      location.reload(true);
    } catch (e) {
      log(e, {}, 'err');
    }
  }

  return (
    <>
      {!connected && !loading && (
        <div className="fixed z-[9999] flex h-full w-full items-start justify-center bg-black/25">
          <div className="mt-10 flex flex-col items-center justify-center gap-2">
            {t('app.connection_issues')}
            <button
              className="btn-secondary btn-sm btn"
              onClick={handleReconnect}
            >
              {t('app.reconnect')}
            </button>
          </div>
        </div>
      )}
      {children}
    </>
  );
}

export function DocumentAggregationSync() {
  const sessions = useAppStateStore((state) => state.sessions);
  const sessionId = useAppStateStore((state) => state.sessionId);
  const sessionDeals = useAppStateStore((state) => state.sessionDeals);

  const currentSession = getCurrentSession(
    sessions,
    sessionId
  );
  useEffect(
    function () {
      const timeoutId = setTimeout(function () {
        if (
          currentSession &&
          sessionDeals &&
          sessionDeals.length &&
          sessionDeals[0].sessionId === sessionId
        ) {
          const correctNorthToAct = sessionDeals.reduce(function (
            nta,
            sd
          ) {
            return nta + (sd.turn === sd.users[0] ? 1 : 0);
          },
          0);
          const correctSouthToAct = sessionDeals.reduce(function (
            sta,
            sd
          ) {
            return sta + (sd.turn === sd.users[1] ? 1 : 0);
          },
          0);
          const correctNorthToRead = sessionDeals.reduce(function (
            ntr,
            sd
          ) {
            return ntr + (sd.northToRead || 0);
          },
          0);
          const correctSouthToRead = sessionDeals.reduce(function (
            str,
            sd
          ) {
            return str + (sd.southToRead || 0);
          },
          0);
          const currentNorthToAct = currentSession.northToAct;
          const currentSouthToAct = currentSession.southToAct;
          const currentNorthToRead = currentSession.northToRead;
          const currentSouthToRead = currentSession.southToRead;

          if (
            correctNorthToAct !== currentNorthToAct ||
            correctSouthToAct !== currentSouthToAct ||
            correctNorthToRead !== currentNorthToRead ||
            correctSouthToRead !== currentSouthToRead
          ) {
            void syncSessionAggregatedFields(sessionId, {
              northToAct: correctNorthToAct,
              southToAct: correctSouthToAct,
              northToRead: correctNorthToRead,
              southToRead: correctSouthToRead,
            });
          }
        }
      }, 1000);
      return function () {
        clearTimeout(timeoutId);
      };
    },
    [currentSession, sessionDeals, sessionId]
  );
}

export function UserSync() {
  const user = useAppStateStore((state) => state.user);
  const groups = useAppStateStore((state) => state.groups);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && user) {
      const displayName = (user.displayName ?? '').trim();
      const photoURL = user.photoURL;
      const friendKey = user.friendKey;

      if ((user.extraTickets ?? 0) < 0) {
        void submitInternalFeedback({
          category: 'bug',
          message: `User has negative extra tickets. extraTickets: ${user.extraTickets}.`,
        });
        void updateUser({ extraTickets: 0 });
      }

      if (!displayName || photoURL === undefined || friendKey === undefined) {
        void submitInternalFeedback({
          category: 'bug',
          message: `User has missing fields. displayName: ${user.displayName}. photoURL: ${user.photoURL}. friendKey: ${user.friendKey}.`,
          newDisplayName: displayName || currentUser.displayName || 'New User',
          newPhotoURL: photoURL || currentUser.photoUrl || null,
          newFriendKey: friendKey || '',
        });
        void updateUser({
          displayName: displayName || currentUser.displayName || 'New User',
          photoURL: photoURL || currentUser.photoUrl || null,
          friendKey: friendKey || '',
        });
      }
    }
  }, [currentUser, user]);

  useEffect(
    function () {
      if (currentUser && groups) {
        const subs = getSubsFromGroups(groups, currentUser.uid);
        void updateUserStateGroupSubscriptions({
          ...(subs || []),
        });
      }
    },
    [currentUser, groups]
  );
}

export function NativeFunctionalitySubscribers() {
  const [lastPushToken, setLastPushToken] = useState();
  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const newAccountPrepData = useAppStateStore((state) => state.newAccountPrepData);

  const history = useAppStateStore((state) => state.history);
  const nav = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const language = i18n.language;
  const user = useAppStateStore((state) => state.user);
  const hasLoadedUser = !!user; // What we most care about is the userDoc having been created, so we can update it with tokens

  const {
    currentUser,
    signInWithGoogleWithToken,
    signInWithAppleWithToken,
    setPushToken,
    setPushTokenWeb,
  } = useAuth();

  const handleGoBackRef = useRef();

  handleGoBackRef.current = () => {
    if (history.length > 1) {
      nav(-1);
    } else {
      const isOnSpotPage = location.pathname.startsWith('/spots/');
      if (isOnSpotPage) {
        nav('/spots/' + language);
        return;
      }
      const isOnLoginPage = location.pathname.startsWith('/login') || location.pathname.startsWith('/join');
      if (isOnLoginPage) {
        window.location.reload();
        return;
      }

      nav('/');
    }
  }

  useEffect(() => {
    if (currentUser?.uid && hasLoadedUser) {
      void setPushTokenWeb();
    }
  }, [currentUser, hasLoadedUser, setPushTokenWeb]);

  useEffect(() => {
    if (currentUser?.uid && hasLoadedUser && lastPushToken) {
      void setPushToken(currentUser.uid, lastPushToken);
    }
  }, [currentUser, hasLoadedUser, lastPushToken, setPushToken]);

  useEffect(() => {
    updateAppState({
      iap: window.iap || checkIfNativeIos(),
      appCopy: window.appCopy,
      appShare: window.appShare,
      android: window.android,
      allowNotifications: !(window.notifications === false),
      isNative: checkIfNative() || window.isNative,
      isMobile: checkMobileDevice(),
    });
  }, [updateAppState]);

  useEffect(() => {
    if (!window?.isNative) {
      try {
        window.ReactNativeWebView.postMessage('isNative?');
        window.isNative = true;
      } catch (ex) {
        //
      }
    }
  }, [currentUser?.uid]);

  useEffect(() => {
    if (window?.isNative) {
      try {
        window.ReactNativeWebView.postMessage('resetTokenSent');
      } catch (ex) {
        //
      }
    }
  }, [currentUser?.uid]);

  useEffect(() => {
    if (window?.isNative) {
      try {
        window.ReactNativeWebView.postMessage('readyForMessage');
      } catch (ex) {
        //
      }
    }
  }, []);

  useEffect(() => {
    const handleNativeMessage = async (e) => {
      const message = e.data;

      try {
        const msg = JSON.parse(message);
        if (msg.data.datatype === 'google') {
          signInWithGoogleWithToken({
            token: msg.data.token,
            displayName: newAccountPrepData?.displayName,
            fed: newAccountPrepData?.fed,
            fedNr: newAccountPrepData?.fedNr,
            referral: newAccountPrepData?.referral,
          });
        }
        if (msg.data.datatype === 'apple') {
          signInWithAppleWithToken({
            token: msg.data.token,
            nonce: msg.data.nonce,
            displayName: newAccountPrepData?.displayName,
            fed: newAccountPrepData?.fed,
            fedNr: newAccountPrepData?.fedNr,
            referral: newAccountPrepData?.referral,
          });
        }
        if (msg.data.datatype === 'setPushToken') {
          setLastPushToken(msg.data.token);
        }
      } catch (ex) {
        if (message === 'goBack') {
          handleGoBackRef.current?.();
        }
      }
    };

    document.addEventListener('message', handleNativeMessage);
    window.addEventListener('message', handleNativeMessage);

    return function cleanup() {
      document.removeEventListener('message', handleNativeMessage);
      window.removeEventListener('message', handleNativeMessage);
    };
  }, [
    signInWithAppleWithToken,
    signInWithGoogleWithToken,
    newAccountPrepData,
  ]);

  return null;
}

export function SharedAppStateSubscribers() {
  const { t } = useTranslation();
  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const updateGroupsExtraData = useAppStateStore((state) => state.updateGroupsExtraData);
  const updateGroupsMessages = useAppStateStore((state) => state.updateGroupsMessages);
  const resetAppStateAfterLogout = useAppStateStore((state) => state.resetAppStateAfterLogout);
  const notify = useNotifications();
  const updateSinglePlayerRobotErrors = useAppStateStore((state) => state.updateSinglePlayerRobotErrors);
  const user = useAppStateStore((state) => state.user);
  const friends = useAppStateStore((state) => state.friends);
  const sessions = useAppStateStore((state) => state.sessions);
  const achievements = useAppStateStore((state) => state.achievements);
  const challengeId = useAppStateStore((state) => state.challengeId);
  const weekly = useAppStateStore((state) => state.weekly);
  const daily = useAppStateStore((state) => state.daily);
  const sessionId = useAppStateStore((state) => state.sessionId);
  const groups = useAppStateStore((state) => state.groups);
  const matchmaking = useAppStateStore((state) => state.matchmaking);
  const challengeMatchmaking = useAppStateStore((state) => state.challengeMatchmaking);
  const sessionDeals = useAppStateStore((state) => state.sessionDeals);
  const webSubs = useAppStateStore((state) => state.activeWebSubs);
  const singlePlayerRobotErrors = useAppStateStore((state) => state.singlePlayerRobotErrors);
  const currentSession = useAppStateStore((state) => state.currentSession);
  const conventions = user?.conventions ?? []

  const nav = useNavigate();
  const { currentUser } = useAuth();
  const [dailyPollFactor, setDailyPollFactor] = useState(1);
  const [weeklyPollFactor, setWeeklyPollFactor] = useState(1);
  const weeklyObservableUnsubscribeRef = useRef();
  const dailyObservableUnsubscribeRef = useRef();
  const [waitingForPartner, setWaitingForPartner] = useState(false);
  const [waitingForChallenge, setWaitingForChallenge] = useState(null);
  const [loadingCompleteAchievement, setLoadingCompleteAchievement] =
    useState(false);
  const [robotBiddingLoading, setRobotBiddingLoading] = useState(false);

  const showOnboarding = shouldShowOnboarding({ user });
  const [groupsLoaded, setGroupsLoaded] = useState(false);

  useEffect(() => {
    isSupported().then((supported) => {
      if(supported) {
        try {
          const messaging = getMessaging();
          onMessage(messaging, (payload) => {
            notify(
                {
                  type: 'info',
                  title: (payload.notification?.title ?? payload.data?.title),
                  text: (payload.notification?.body ?? payload.data?.body),
                  url: (payload.notification?.url ?? payload.data?.url)
                },
            );
          });
        } catch (e) {
          // nothing
        }
      }
    });
  }, [notify]);

  useEffect(() => {
    if (currentUser) {
      return getUserObservable({
        userId: currentUser.uid,
        callback: function (user) {
          updateAppState({ user });
        },
      });
    }
  }, [currentUser, updateAppState]);

  useEffect(() => {
    if (!shouldShowOnboarding({ user })) {
      return;
    }

    const currentStep = getCurrentOnboardingStep({ user });
    const hasFinishedStep = onboardingRules[currentStep]?.({ user, friends, sessions });

    if (hasFinishedStep) {
      const nextStep = getNextOnboardingStep(currentStep);
      void updateUser({ onboardingStep: nextStep });
    }
  }, [user, friends, sessions, updateAppState]);

  useEffect(
    function () {
      if (user && !loadingCompleteAchievement) {
        const achievementToComplete = checkForCompletedAchievements(
          user,
          achievements
        );
        if (achievementToComplete) {
          setLoadingCompleteAchievement(true);
          completeAchievement(achievementToComplete)
            .then(function () {
              // Some time for change to propagate to not try to complete same again
              setTimeout(function () {
                setLoadingCompleteAchievement(false);
              }, 5000);
            })
            .catch(function () {
              // Wait for error to hopefully be fixed
              setTimeout(function () {
                setLoadingCompleteAchievement(false);
              }, 60000);
            });
        }
      }
    },
    [user, loadingCompleteAchievement, achievements]
  );

  useEffect(() => {
    if (currentUser) {
      return getSessionsObservable({
        callback: function (sessions) {
          updateAppState({ sessions });
        },
      });
    }
  }, [currentUser, updateAppState]);

  useEffect(() => {
    if (currentUser && sessionId) {
      const sessionFromAppState = sessions.find((s) => s.id === sessionId);
      if (sessionFromAppState) {
        updateAppState({ currentSession: sessionFromAppState });
      } else {
        return getSessionObservable({
          sessionId,
          callback: function (session) {
            updateAppState({ currentSession: session });
          },
        });
      }
    }
  }, [currentUser, sessionId, sessions, updateAppState]);

  useEffect(() => {
    if (currentUser && sessionId) {
      return getSessionDealsObservable({
        acceptCachedData: false,
        sessionId: sessionId,
        callback: function (ds) {
          updateAppState({
            sessionDeals: ds,
          });
        },
      });
    }
  }, [sessionId, updateAppState, currentUser]);

  useEffect(
    function () {
      if (currentUser) {
        return getUserAchievementsObservable(function (achievements) {
          updateAppState({ achievements });
        });
      }
    },
    [currentUser, updateAppState]
  );

  useEffect(() => {
    if (currentUser) {
      return getMessagesUserObservable({
        uid: currentUser.uid,
        callback: function (messages) {
          updateAppState({ messages });
        },
      });
    }
  }, [currentUser, updateAppState]);

  useEffect(() => {
    if (currentUser) {
      return getSharedSessionsObservable({
        callback: function (sharedSessions) {
          updateAppState({ sharedSessions });
        },
      });
    }
  }, [currentUser, updateAppState]);

  useEffect(() => {
    if (currentUser) {
      return getChallengesObservable({
        callback: function (challenges) {
          updateAppState({ challenges });
        },
      });
    }
  }, [currentUser, updateAppState]);

  useEffect(() => {
    if (currentUser && challengeId) {
      return getChallengeDealsObservable({
        challengeId,
        callback: function (cd) {
          updateAppState({
            challengeDeals: cd,
          });
        },
      });
    }
  }, [currentUser, updateAppState, challengeId]);

  useEffect(
    function () {
      if (currentUser) {
        return mySubscriptionObservable({
          callback: function (subs) {
            updateAppState({
              activeWebSubs: subs,
            });
          },
        });
      }
    },
    [currentUser, updateAppState]
  );

  useEffect(() => {
    if(!groupsLoaded || !webSubs) {
      return;
    }
    webSubs.filter((sub) => !officialSubs.includes(sub)).forEach((sub) => {
      const member = groups?.find(g => g.id === sub)
      if(!member && sub) {
        joinGroup(sub).then(() => {
          nav(`/groups/${sub}`);
        })
      }
    })
  }, [groups, webSubs, nav, groupsLoaded]);

  useEffect(
    function () {
      if (currentUser) {
        return myIAPSubscriptionObservable({
          callback: function (subs) {
            updateAppState({
              activeIAPSubs: subs,
            });
          },
        });
      }
    },
    [currentUser, updateAppState]
  );

  useEffect(
    function () {
      if (currentUser) {
        return getAllFriendsObservable({
          callback: function (friends) {
            updateAppState({ friends });
          },
        });
      }
    },
    [currentUser, updateAppState]
  );

  useInterval(
    () => {
      if (!currentUser) {
        weeklyObservableUnsubscribeRef.current?.();
        return;
      }
      if (weekly?.endDate > Date.now()) {
        setWeeklyPollFactor(1);
        return;
      }
      weeklyObservableUnsubscribeRef.current?.();
      weeklyObservableUnsubscribeRef.current = getWeeklyObservable({
        callback: function (weekly) {
          if (weekly) {
            setWeeklyPollFactor(1);
            updateAppState({ weekly });
          } else {
            setWeeklyPollFactor((wpf) => wpf * 2);
          }
        },
      });
    },

    weekly?.endDate > Date.now()
      ? weekly?.endDate - Date.now() + 5000
      : 1000 * weeklyPollFactor
  );

  useInterval(
    () => {
      if (!currentUser) {
        dailyObservableUnsubscribeRef.current?.();
        return;
      }
      if (daily?.endDate > Date.now()) {
        setDailyPollFactor(1);
        return;
      }
      dailyObservableUnsubscribeRef.current?.();
      dailyObservableUnsubscribeRef.current = getDailyObservable({
        callback: function (daily) {
          // TODO: Why is this check different than for weekly?
          if (daily?.endDate > Date.now()) {
            setDailyPollFactor(1);
            updateAppState({ daily });
          } else {
            setDailyPollFactor((dpf) => dpf * 2);
          }
        },
      });
    },

    daily?.endDate > Date.now()
      ? daily?.endDate - Date.now() + 5000
      : 1000 * dailyPollFactor
  );

  useEffect(
    function () {
      if (!currentUser) {
        weeklyObservableUnsubscribeRef.current?.();
        dailyObservableUnsubscribeRef.current?.();
        resetAppStateAfterLogout();
      }
    },
    [currentUser, resetAppStateAfterLogout]
  );

  useEffect(() => {
    if (currentUser) {
      return getCurrentUserInvitesObservable(function (eventInvites) {
        updateAppState({ eventInvites });
      });
    }
  }, [currentUser, updateAppState]);

  useEffect(() => {
    if (currentUser) {
      return getCurrentUserGroupsObservable(function (groups) {
        updateAppState({ groups });
        setGroupsLoaded(true)
      });
    }
  }, [currentUser, updateAppState]);

  useEffect(() => {
    if (currentUser) {
      return getCurrentUserGroupInvitesObservable(function (groupInvites) {
        updateAppState({ groupInvites });
      });
    }
  }, [currentUser, updateAppState]);

  useEffect(() => {
    let subscriptions = [];
    if (currentUser && groups && groups.length > 0) {
      subscriptions = groups.map((g) => {
        return getGroupChatObservable({
          groupId: g.id,
          callback: (messages) =>
            updateGroupsMessages({ [g.id]: messages }),
        });
      });
    }

    return () => {
      subscriptions.forEach((unsubscribe) => unsubscribe());
    };
  }, [currentUser, groups, updateGroupsMessages]);

  useEffect(() => {
    let subscriptions = [];
    if (currentUser && groups && groups.length > 0) {
      subscriptions = groups.map((g) => {
        return getGroupDbObservable({
          groupId: g.id,
          callback: (data) => updateGroupsExtraData({ [g.id]: data }),
        });
      });
    }

    return () => {
      subscriptions.forEach((unsubscribe) => unsubscribe());
    };
  }, [currentUser, groups, updateGroupsExtraData]);

  useEffect(() => {
    if (currentUser) {
      return getMatchmakingObservable(function (matchmaking) {
        updateAppState({ matchmaking });
      });
    }
  }, [currentUser, updateAppState]);

  useEffect(() => {
    if (currentUser) {
      if (
        !matchmaking ||
        matchmaking.userId !== currentUser.uid
      ) {
        setWaitingForPartner(null);
        return;
      }
      if (matchmaking.sessionId) {
        if (waitingForPartner === matchmaking.id) {
          notify(
            { type: 'success', text: t('app.matchmaking_successful_notification') },
          );
        }
        setWaitingForPartner(null);
      } else {
        setWaitingForPartner(matchmaking.id);
      }
    }
  }, [currentUser, matchmaking, waitingForPartner, notify, t]);

  useEffect(() => {
    if (currentUser) {
      return getChallengeMatchmakingForUserObservable(
        currentUser.uid,
        function (challengeMatchmaking) {
          updateAppState({ challengeMatchmaking });
        }
      );
    }
  }, [currentUser, updateAppState]);

  useEffect(() => {
    if (currentUser) {
      return getChallengeQueueObservable(function (challengeQueue) {
        updateAppState({ challengeQueue });
      });
    }
  }, [currentUser, updateAppState]);


  useEffect(() => {
    if (currentUser) {
      if (!challengeMatchmaking) {
        setWaitingForChallenge(null);
        return;
      }
      if (challengeMatchmaking.challengeId) {
        if (waitingForChallenge === challengeMatchmaking.id) {
          notify(
            { type: 'success', text: t('app.challenge_matchmaking_successful_notification') },
          );
        }
        setWaitingForChallenge(null);
      } else {
        setWaitingForChallenge(challengeMatchmaking.id);
      }
    }
  }, [
    currentUser,
    challengeMatchmaking,
    waitingForChallenge,
    notify,
    t,
  ]);

  useEffect(function () {
    if (shouldRobotBidOnSession(sessionId, currentSession, sessionDeals) && !robotBiddingLoading) {
      const dealToBidOn = getRobotDealToBidOn(sessionDeals, singlePlayerRobotErrors);

      if (dealToBidOn) {
        const doStuff = async () => {
          try {
            setRobotBiddingLoading(true);

            await new Promise((resolve) => {
              // Lia bids too quickly, you cannot read the information in onboarding
              // And in real scenarios, a light delay might be nice too
              setTimeout(resolve, showOnboarding ? 5000 : 1000);
            });

            await singlePlayerRobotBidOnSessionDeal({
              sessionDeal: dealToBidOn,
              numberOfRemainingDeals: currentSession.dealsCount - currentSession.numberOfFinishedDeals,
              weeklySessionId: currentSession.weekly ?? 0,
              dailySessionId: currentSession.daily ?? 0,
              groupSessionId: currentSession.groupSession ?? 0,
              robotId: getRobotUserId(),
              conventions,
              t,
            });
          } catch (e) {
            log(e, {}, 'err');
            void submitInternalFeedback({
              category: 'singlePlayerRobotError',
              message: e.message,
              ...dealToBidOn,
            });
            updateSinglePlayerRobotErrors({ [dealToBidOn.id]: true });
          } finally {
            await new Promise((resolve) => {
              // TODO: This was added to solve some undescribed problem with Lia bidding in transaction, so I guess now it should always apply?
              setTimeout(resolve, 3000);
            });
            setRobotBiddingLoading(false);
          }
        }
        void doStuff();
      }
    }
  }, [robotBiddingLoading, sessionId, currentSession, sessionDeals, singlePlayerRobotErrors, updateSinglePlayerRobotErrors, showOnboarding]);

  return null;
}

export function LanguageEffects() {
  const user = useAppStateStore((state) => state.user);
  const appStateLanguage = useAppStateStore((state) => state.language);
  const appStateFedLanguage = useAppStateStore((state) => state.fedForLanguage);

  const { i18n } = useTranslation();

  const userLanguage = user?.language;
  const { fed } = useParams();

  const isOnSpotsPage = location.pathname.startsWith('/spots/');
  const forcedFedLanguage =  isOnSpotsPage ? fed : appStateFedLanguage;

  const handleChangeLanguage = (language) => {
    void i18n.changeLanguage(language);
    try {
      changeNativeLanguage(language);
    }
    catch (e) {
      //
    }
  }

  const saveLanguage = (language) => {
    try {
      localStorage.setItem('language', language);
    } catch (e) {
      //
    }
  }

  function changeNativeLanguage(language) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ message: 'changeLanguage', lang: language }),
    );
  }


  useEffect(() => {
    if (forcedFedLanguage) {
      handleChangeLanguage(forcedFedLanguage);
      return;
    }

    if (userLanguage) {
      handleChangeLanguage(userLanguage);
      saveLanguage(userLanguage)
      return;
    }

    try {
      const localStorageLanguage = localStorage.getItem('language');
      if (localStorageLanguage) {
        handleChangeLanguage(localStorageLanguage);
        return;
      }
    } catch (e) {
      // log(e, {}, 'err');
    }

    // Note: Since we typically will set a language in storage it will be rare to get here.
    // Therefore, the setLanguage function in appState also sets localStorage.
    // Changing language in appState is mainly a way to trigger this effect again.
    if (appStateLanguage) {
      void i18n.changeLanguage(appStateLanguage);
      saveLanguage(appStateLanguage)
      return;
    }

    void i18n.changeLanguage(getBrowserPreferredLanguage());
  }, [i18n, appStateLanguage, userLanguage, forcedFedLanguage]);

  return null;
}

export function SpotsSubscribers() {
  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const location = useLocation();
  const { fed } = useParams();

  const isOnSpotsPage = location.pathname.startsWith('/spots/');

  useEffect(
    function () {
      if (isOnSpotsPage && fed) {
        return getSpotsObservable({
          fed,
          callback: function(spots) {
            updateAppState({ spots });
            updateAppState({ isSpotPage: true });
          },
        });
      } else {
        updateAppState({ isSpotPage: false });
      }
    },
    [updateAppState, fed, isOnSpotsPage]
  );

  return null;
}

export function MetadataSubscribers() {
  const updateAppState = useAppStateStore((state) => state.updateAppState);

  useEffect(() => {
    return getMetadataObservable(function (m) {
      updateAppState({ metadata: m });
    });
  }, [updateAppState]);

  return null;
}
