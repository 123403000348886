import Alert from '../alert/alert';
import { useTranslation } from 'react-i18next'

export default function WeeklyEndWarning({ session, weekly }) {
  const { t } = useTranslation();

  if (!session) {
    return;
  }

  if (!session.weekly) {
    return;
  }

  if (session.dealsCount - session.numberOfFinishedDeals < 1) {
    return;
  }

  if (!weekly) {
    return;
  }

  if (Date.now() < weekly.endDate) {
    return;
  }

  return (
    <Alert
      sx="m-2"
      severity="warning"
      text={t('components.weekly_ended.info')}
    />
  );
}
