export default function moveToNextDeal({
  sessionDeals,
  sessionId,
  userId,
  navigate,
  currentDealIndex,
}) {
  let userIndex = sessionDeals?.[0]?.users.indexOf(userId);
  const chatMessageField = ['northToRead', 'southToRead'][userIndex];
  let dealWithUnreadMessages;
  for (let i = 0; i < sessionDeals?.length ?? 0; i++) {
    const dealIndex = (i + currentDealIndex) % sessionDeals.length;
    const sd = sessionDeals[dealIndex];
    const numberUnread = sd[chatMessageField] ?? 0;
    if (
      sd.sessionId === sessionId &&
      dealIndex !== currentDealIndex &&
      sd.turn === userId
    ) {
      navigate('/session/deal/' + sd.id, { replace: true });
      return;
    } else if (
      sd.sessionId === sessionId &&
      dealIndex !== currentDealIndex &&
      numberUnread > 0 &&
      !dealWithUnreadMessages
    ) {
      dealWithUnreadMessages = sd.id;
    }
  }

  if (dealWithUnreadMessages) {
    navigate('/session/deal/' + dealWithUnreadMessages, { replace: true });
    return;
  }

  navigate(-1);
}
