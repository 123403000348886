import { ChallengeIcon } from '../icons/challengeIcon';
import { Star } from '../rating/starRating';
import PairDisplay, { LinkedPairDisplay } from '../users/pairDisplay';
import { FriendsIcon } from '../icons/friendsIcon';
import { useTranslation } from 'react-i18next';

function getCardColorClasses(finished, starsTeam1, starsTeam2) {
  if (!finished) {
    return 'bg-info border-info';
  }

  if (starsTeam1 < starsTeam2) {
    return 'bg-gradient-to-tr from-error/20 to-success/20 border-r-success border-t-success border-b-error border-l-error';  }

  if (starsTeam1 === starsTeam2) {
    return 'bg-warning border-warning bg-opacity-10';
  }

  return 'bg-gradient-to-br from-success/20 to-error/20 border-l-success border-t-success border-b-error border-r-error';
}

function ProBadge() {
  const { t } = useTranslation('translation', { keyPrefix: 'components.challenge_card' });

  return (
    <div
      className="text-md absolute -bottom-12 left-0 -translate-x-1/2 drop-shadow-md "
    >
      <div className="badge badge-sm font-bold badge-secondary">{t('pro')}</div>
    </div>
  );
}

function ResultText({ finished, starsTeam1, starsTeam2 }) {
  const { t } = useTranslation();

  if (!finished) {
    return null;
  }

  if (starsTeam1 < starsTeam2) {
    return (
      <div
        style={{
          textShadow: '1px 1px 0px black',
        }}
        className="text-md absolute -top-14 left-0 -translate-x-1/2 font-bold text-rose-400 drop-shadow-md "
      >
        {t('challenge.loss')}
      </div>
    );
  }

  if (starsTeam1 === starsTeam2) {
    return (
      <div
        style={{
          textShadow: '1px 1px 0px black',
        }}
        className="text-md absolute -top-14 left-0 -translate-x-1/2 font-bold text-amber-400 "
      >
        {t('challenge.draw')}
      </div>
    );
  }

  return (
    <div
      style={{
        textShadow: '1px 1px 0px black',
      }}
      className="text-md absolute -top-14 left-0 -translate-x-1/2 font-bold text-emerald-400 "
    >
      {t('challenge.win')}
    </div>
  );
}

function getBadgeColorClasses(finished, starsTeam1, starsTeam2) {
  if (!finished) {
    return 'border-info';
  }

  if (starsTeam1 < starsTeam2) {
    return 'border-error';
  }

  if (starsTeam1 === starsTeam2) {
    return 'border-warning';
  }

  return 'border-success';
}

export default function OtherChallengeResultCard({
  user1,
  user2,
  user3,
  user4,
  starsTeam1,
  starsTeam2,
  finished,
  links = false,
  ranked = false,
  proChallenge = false
}) {
  return (
    <div
      className={`fade-in-slow card w-[350px] overflow-hidden rounded-xl border shadow-lg ${getCardColorClasses(
        finished,
        starsTeam1,
        starsTeam2
      )} overflow-visible`}
    >
      <div className={'card-body w-[350px] p-0'}>
        <div className="flex w-[350px] items-center justify-between">
          <div className={'flex w-1/2 flex-col p-[10px] pr-[30px] items-center'}>
            {links ? (
              <LinkedPairDisplay uid1={user1} uid2={user2} />
            ) : (
              <PairDisplay uid1={user1} uid2={user2} />
            )}
            <div
              className={`badge badge-outline ${getBadgeColorClasses(
                finished,
                starsTeam1,
                starsTeam2
              )} mt-2 bg-base-100 pl-0`}
            >
              <Star /> {starsTeam1}
            </div>
          </div>
          <div className="relative h-0 w-0">
            {
              starsTeam1 !== starsTeam2 ? (
                <>
                  <div className={'absolute -left-[100px]'}>
                    <ResultText
                      finished={finished}
                      starsTeam1={starsTeam1}
                      starsTeam2={starsTeam2}
                    />
                  </div>
                  <div className={'absolute -right-[100px]'}>
                    <ResultText
                      finished={finished}
                      starsTeam1={starsTeam2}
                      starsTeam2={starsTeam1}
                    />
                  </div>
                </>
              ) : (
                <ResultText
                  finished={finished}
                  starsTeam1={starsTeam1}
                  starsTeam2={starsTeam2}
                />
              )
            }

            {ranked ? (
              <ChallengeIcon
                strokeWidth={0.5}
                className="-translate-y-1/2 -translate-x-1/2"
                fill="#9f4e4e"
                width={32}
              />
            ) : (
              <FriendsIcon
                strokeWidth={0.5}
                className="-translate-y-1/2 -translate-x-1/2"
                fill="#9f4e4e"
                width={32}
              />
            )}
            {proChallenge && <ProBadge />}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '50%',
              padding: 10,
              paddingLeft: 30,
            }}
          >
            {links ? (
              <LinkedPairDisplay uid1={user3} uid2={user4} reverse />
            ) : (
              <PairDisplay uid1={user3} uid2={user4} reverse />
            )}
            <div
              className={`badge badge-outline ${getBadgeColorClasses(
                finished,
                starsTeam1,
                starsTeam2
              )} mt-2 bg-base-100 pl-0`}
            >
              <Star /> {starsTeam2}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
