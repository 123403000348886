import { useNavigate } from 'react-router-dom';
import apple from '../../images/appstores/appstore-img.png';
import android from '../../images/appstores/googleplay-img.png';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function AppsPage() {
  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const isNative = useAppStateStore((state) => state.isNative);
  const nav = useNavigate();

  const { t } = useTranslation('translation', { keyPrefix: 'apps' });

  useEffect(function () {
    if (isNative) {
      nav('/');
    }
  }, [nav, isNative])

  function handlePreferWeb() {
    updateAppState({ preferWeb: true });
    nav('/');
  }

  return (
    <div className="page pb-24">
      <article className="prose p-5">
        <h1 className="mb-6">{t('apps_title')}</h1>
        <p>{t('apps_p1')}</p>
        <p>{t('apps_p2')}</p>
      </article>
      <a className="mt-8" href="https://apps.apple.com/us/app/cuebids/id1616386226?:ext">
        <img src={apple} alt={t('app_store_alt_text')} />
      </a>
      <a className="mt-8" href="https://play.google.com/store/apps/details?id=com.unge.cuebids&hl=en&gl=US&:ext">
        <img src={android} alt={t('google_play_alt_text')} />
      </a>
      <button className="btn btn-primary mt-8" onClick={handlePreferWeb}>
        {t('prefer_web')}
      </button>
    </div>
  );
}
