import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getPublicUserAsync } from '../../firebase/subscriptions'
import avatarImg from '../../images/icons/avatar-person.svg';
import club from '../../images/symbols/Club.svg';
import diamond from '../../images/symbols/Diamond.svg';
import heart from '../../images/symbols/Heart.svg';
import spade from '../../images/symbols/Spade.svg';
import { getHighestSubFromUser } from '../../util/sub';
import './userDisplay.css';
import { useTranslation } from 'react-i18next'
import {isToday} from "../../util/dates.js";

const avatarSizeMap = {
  micro: 'w-5 h-5',
  small: 'w-6 h-6',
  normal: 'w-10 h-10',
  large: 'w-12 h-12',
};

const subIcon = {
  club: club,
  diamond: diamond,
  heart: heart,
  spade: spade,
};

const SubDiv = ({ user, size, sub }) => {
  const smallSettings = {
    position: 'absolute',
    top: 0,
    left: -3,
    padding: 0,
    margin: 0,
    zIndex: 500,
    width: 10,
    height: 10,
  };

  const mediumSettings = {
    position: 'absolute',
    top: 0,
    left: -3,
    padding: 0,
    margin: 0,
    zIndex: 500,
    width: 16,
  };

  return (
    <div style={size === 'small' ? smallSettings : mediumSettings}>
      <img src={subIcon[sub ?? getHighestSubFromUser(user)]} />
    </div>
  );
};

export function AvatarDisplay({ online, size='normal', avatar, displayName }) {
  const [hasError, setHasError] = useState(false);

  return (
    <div
      className={`avatar ${online === true ? 'online-pulse online before:!outline-[0.5px]' : ''}`}
    >
      <div className={`${avatarSizeMap[size]} rounded-full`}>
        <img
          src={hasError ? avatarImg : (avatar ?? avatarImg)}
          referrerPolicy="no-referrer"
          onError={() => setHasError(true)}
          alt={displayName}
        />
      </div>
    </div>
  )
}
const ProfilePic = ({ user, sub, size }) => {
  const [hasError, setHasError] = useState(false);

  if (!user) {
    return (
      <div className={`${avatarSizeMap[size]} fade-in-slow relative z-10`}>
        <div className="placeholder avatar">
          <div
            className={`${avatarSizeMap[size]} rounded-full bg-neutral-focus text-white`}
          >
            <span className="text-md">?</span>
          </div>
        </div>
      </div>
    );
  }

  const hasSub = () => {
    return getHighestSubFromUser(user) || sub;
  };

  const userAvatar = (
    <div
      className={`avatar ${user.online === true ? 'online-pulse online before:!outline-[0.5px]' : ''}`}
    >
      <div className={`${avatarSizeMap[size]} rounded-full`}>
        <img
          src={hasError ? avatarImg : user?.avatar ?? avatarImg}
          referrerPolicy="no-referrer"
          onError={() => setHasError(true)}
          alt={user.displayName}
        />
      </div>
    </div>
  );

  return (
    <div className={`${avatarSizeMap[size]} fade-in-slow relative z-10`}>
      {hasSub() && size !== 'micro' && (
        <SubDiv user={user} sub={sub} size={size} />
      )}
      <div
        className={`align-center flex ${avatarSizeMap[size]} justify-center rounded-full bg-black`}
      >
        {userAvatar}
      </div>
    </div>
  );
};

function GetDate({ d }) {
  const { t } = useTranslation();
  const locale = t('locale');
  const dateTime = new Date(d);
  const displayDate = isToday(dateTime)
      ? dateTime?.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })
      : ( locale === 'sv-SE' ? dateTime.toLocaleDateString(locale) : dateTime?.toDateString());

  return (
    <span className="info text-xs capitalize">
      {t('components.user_display.last_seen', {date: displayDate})}
    </span>
  );
}

export function UserNameAndAvatarDisplay(
  {
    displayName,
    online= true,
    size = 'normal',
    reverse = false,
    flag = '',
    avatar,
    pro= false,
    robot = false,
  }) {

  function dateValid(d) {
    return new Date(d).getTime() > 0;
  }

  function showDate(d) {
    return !robot && size !== 'small' && online !== true && dateValid(d);
  }

  return (
    <div
      className={`fade-in flex items-center ${
        reverse ? 'flex-row-reverse' : ''
      }`}
    >
      {avatar}
      <div
        className={`flex flex-col items-start justify-start overflow-hidden ${
          reverse ? 'mr-2' : 'ml-2'
        }`}
      >
        <span
          className={`${
            size === 'small' ? 'w-[90px]' : 'w-[130px]'
          } truncate text-ellipsis whitespace-nowrap ${
            reverse ? 'text-end' : 'text-start'
          } text-xs normal-case ${
            pro ? 'pro' : ''
          } ${
            robot ? 'robot' : ''
          }`}
        >
          {displayName}
        </span>
        {showDate(online) && <GetDate d={online} />}
      </div>
      {flag && size !== 'small' && (
        <span
          style={{ marginLeft: 20 }}
          className={`fi fi-${flag}`}
        ></span>
      )}
    </div>
  )
}

function UserDisplayInternal({
  uid,
  size = 'small',
  onlyAvatar = false,
  sub,
  flag = false,
  nameOnly = false,
  reverse = false,
  noPro = false,
  noRobot = false,
  nameOverride = '',
  coach = false,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'components.user_display' });
  const { isLoading, error, data: user } = useQuery({
    queryKey: ['user', uid],
    queryFn: async () => await getPublicUserAsync( uid ),
  });

  const name = nameOverride || (user?.displayName ?? t('unknown_user'))
  const pro = !noPro && (user?.pro ?? false);
  const robot = !noRobot && (coach || (user?.robot ?? false));

  if (nameOnly && !user) {
    return (
      // TODO: Now the small size is larger than the larger size.
      // And the two components UserDisplayInstantNameSmall and UserDisplayInstantName have very different styling patterns
      <div style={{ height: size === 'small' ? 32 : 20 }}>
        <span className={`${size === 'small' ? 'info' : ''}`}></span>
      </div>
    );
  }

  if (onlyAvatar) {
    return <ProfilePic user={user} sub={sub} size={size} />;
  }

  if (nameOnly) {
    return size === 'small' ? (
      <UserDisplayInstantNameSmall
        name={name}
        reverse={reverse}
        pro={pro}
        robot={robot}
      />
    ) : (
      <UserDisplayInstantName
        name={name}
        reverse={reverse}
        pro={pro}
        robot={robot}
      />
    );
  }

  return (
    <UserNameAndAvatarDisplay
      displayName={name}
      flag={flag ? user.flag : ''}
      online={user?.online}
      size={size}
      avatar={<ProfilePic user={user} sub={sub} size={size} />}
      pro={pro}
      robot={robot}
      reverse={reverse}
    />
  );
}

export function UserDisplayInstantName({ name, reverse = false, pro= false, robot = false }) {
  return (
    <span
      className={`w-[130px] max-w-[130px] overflow-hidden text-ellipsis whitespace-nowrap ${
        reverse ? 'text-end' : 'text-start'} ${pro ? 'pro' : ''} ${robot ? 'robot' : ''}`}>{name}</span>
  );
}

export function UserDisplayInstantNameFlag({ name, flag, showFlag, pro= false, robot = false }) {
  return (
    <div className={'fade-in flex items-center'}>
      <div className="w-[150px] max-w-[150px] overflow-hidden text-ellipsis font-bold whitespace-nowrap">
        <span className={`${pro ? 'pro' : ''} ${robot ? 'robot' : ''}`}>{name}</span>
      </div>
      {showFlag && (
        <span style={{ marginLeft: 20 }} className={`fi fi-${flag}`} />
      )}
    </div>
  );
}

export function UserDisplayInstantNameSmall({ name, reverse = false, pro= false, robot = false }) {
  return (
    <span
      className={`h-6 w-24 max-w-75 text-xs overflow-hidden text-ellipsis whitespace-nowrap font-bold ${
        reverse ? 'text-end' : 'text-start'} ${pro ? 'pro' : ''} ${robot ? 'robot' : ''}`}>{name}</span>
  );
}

export function UserDisplaySmall({ uid }) {
  return <UserDisplayInternal uid={uid} size={'small'} />;
}

export function UserDisplaySmallCoach({ uid, name }) {
  return <UserDisplayInternal uid={uid} size={'small'} nameOverride={name} coach={true} />;
}

export function UserDisplayCoach({ uid, name }) {
  return <UserDisplayInternal uid={uid} size={'normal'} nameOverride={name} coach={true} />;
}

export function UserDisplayCoachName({ uid, name, noPro= false, noRobot = false }) {
  return <UserDisplayInternal uid={uid} nameOnly={true} size={'normal'} nameOverride={name} coach={true} noPro={noPro} noRobot={noRobot} />;
}

export function UserDisplaySmallRight({ uid }) {
  return <UserDisplayInternal uid={uid} size={'small'} reverse={true} />;
}

export function UserDisplayNameSmallRight({ uid }) {
  return (
    <UserDisplayInternal
      uid={uid}
      nameOnly={true}
      size={'small'}
      reverse={true}
    />
  );
}

export function UserDisplayName({ uid, noPro= false, noRobot = false }) {
  return <UserDisplayInternal uid={uid} nameOnly={true} noPro={noPro} noRobot={noRobot} size={'normal'} />;
}

export function UserDisplayNameSmall({ uid }) {
  return <UserDisplayInternal uid={uid} nameOnly={true} size={'small'} />;
}

export default function UserDisplay({ uid }) {
  return <UserDisplayInternal uid={uid} size={'normal'} />;
}

export function LinkedUserDisplay({ uid }) {
  return (
    <Link to={'/player/' + uid}>
      <UserDisplay uid={uid} />
    </Link>
  );
}

export function LinkedUserDisplaySmall({ uid }) {
  return (
    <Link to={'/player/' + uid}>
      <UserDisplaySmall uid={uid} />
    </Link>
  );
}

export function LinkedUserDisplaySmallRight({ uid }) {
  return (
    <Link to={'/player/' + uid}>
      <UserDisplaySmallRight uid={uid} />
    </Link>
  );
}

export function UserDisplayWithFlag({ uid }) {
  return <UserDisplayInternal uid={uid} size={'normal'} flag={true} />;
}

export function UserDisplayAvatar({ uid }) {
  return <UserDisplayInternal uid={uid} size={'large'} onlyAvatar={true} />;
}

export function UserDisplayAvatarSmall({ uid }) {
  return <UserDisplayInternal uid={uid} size={'small'} onlyAvatar={true} />;
}

export function UserDisplayAvatarMicro({ uid }) {
  return <UserDisplayInternal uid={uid} size={'micro'} onlyAvatar={true} />;
}

export function UserDisplayAvatarNormal({ uid }) {
  return <UserDisplayInternal uid={uid} size={'normal'} onlyAvatar={true} />;
}

export function UserDisplayFakeSubSmall({ uid, sub }) {
  return (
    <UserDisplayInternal
      uid={uid}
      size={'small'}
      onlyAvatar={false}
      sub={sub}
    />
  );
}

export function UserDisplayFakeSub({ uid, sub }) {
  return (
    <UserDisplayInternal
      uid={uid}
      size={'normal'}
      onlyAvatar={false}
      sub={sub}
    />
  );
}
