import { useState } from 'react';
import { createGroup } from '../../firebase/groups';
import Alert from '../../components/alert/alert';
import { Link, useNavigate } from 'react-router-dom';
import TextField from '../../components/textField/TextField';
import Checkbox from '../../components/checkbox/Checkbox';
import { isSubEnough } from '../../util/sub';
import { SubIcon } from '../../components/subIcons/subIcons';
import {
  doesDisplayNameHaveForbiddenCharacters,
  isDisplayNameTooShort,
  getTrimmedDisplayName,
  doesDescriptionHaveForbiddenCharacters,
} from '../../util/displayName';
import { useActiveSubs, useHasLoadedDataToGetSubs } from '../../util/hooks'
import { useAppStateStore } from '../../appStateStore.jsx';
import RequireHeartDialog from '../../marketing/RequireHeartDialog.jsx'
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { Trans, useTranslation } from 'react-i18next'

function getGroupNameError(name, t) {
  if (!name || isDisplayNameTooShort(name)) {
    return t('too_short_error');
  }

  if (doesDisplayNameHaveForbiddenCharacters(name)) {
    return t('forbidden_characters_error');
  }

  return '';
}

function getGroupDescriptionError(name, t) {
  if (doesDescriptionHaveForbiddenCharacters(name)) {
    return t('forbidden_characters_error');
  }

  return '';
}

export default function CreateGroup() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.create_group' });
  const navigate = useNavigate();
  const user = useAppStateStore((state) => state.user);
  const notify = useNotifications();

  const [groupName, setGroupName] = useState('');
  const [groupNameError, setGroupNameError] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupDescriptionError, setGroupDescriptionError] = useState('');
  const [loading, setLoading] = useState(false);
  const [promptToSubscribe, setPromptToSubscribe] = useState(false);

  const userActiveSubs = useActiveSubs();
  const hasLoadedDataToGetSubs = useHasLoadedDataToGetSubs();
  const canUserCreateGroup = isSubEnough(userActiveSubs, 'heart');

  if (!user || !hasLoadedDataToGetSubs) {
    return null;
  }

  const onChangeGroupName = (value) => {
    const newValue = value.trimStart();
    setGroupName(newValue);

    setGroupNameError(getGroupNameError(newValue, t));
  };

  const onChangeGroupDescription = (value) => {
    const newValue = value.trimStart();
    setGroupDescription(newValue);

    setGroupDescriptionError(getGroupDescriptionError(newValue, t));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!canUserCreateGroup) {
      setPromptToSubscribe(true);
      return;
    }

    if (groupNameError || groupDescriptionError) return;

    let err = getGroupNameError(groupName, t);
    if (err) {
      setGroupNameError(err);
      return;
    }

    err = getGroupDescriptionError(groupDescription, t);
    if (err) {
      setGroupDescriptionError(err);
      return;
    }

    const trimmedGroupName = getTrimmedDisplayName(groupName);

    setLoading(true);
    const res = await createGroup({
      name: trimmedGroupName,
      description: groupDescription,
      allowChatPush: user.allowChatPush ?? true,
    });
    setLoading(false);

    if (res?.nameAlreadyExists) {
      notify(
        { type: 'failure', text: t('group_name_taken_error') },
      );
      return;
    }

    if (res?.groupId) {
      notify({ type: 'success', text: t('group_created_success') });
      navigate(`/groups/${res.groupId}`);
    }
  };

  return (
    <div className="page pb-24">
      {
        promptToSubscribe && <RequireHeartDialog onClose={() => setPromptToSubscribe(false)} />
      }
      <Alert text={t('create_group_info')} />
      <Link to="/contact/sales" className="flex justify-center">
        <Alert
          severity="success"
          text={
            <Trans
              i18nKey="premium_groups_button_styled"
              t={t}
            />
          }
        />
      </Link>
      <div className="flex flex-col">
        <Checkbox disabled checked label={t('invite_members_bullet')} />
        <Checkbox disabled checked label={t('group_chat_bullet')} />
        <Checkbox disabled checked label={t('group_events_bullet')} />
      </div>
      <form className="w-80 flex flex-col items-center" onSubmit={onSubmit}>
        {canUserCreateGroup && (
          <>
            <TextField
              fullWidth
              label={t('group_name_label')}
              value={groupName}
              onChange={onChangeGroupName}
              placeholder={t('group_name_placeholder')}
              id="groupname"
              error={groupNameError}
            />
            <TextField
              fullWidth
              label={t('group_description_label')}
              value={groupDescription}
              onChange={onChangeGroupDescription}
              placeholder={t('group_description_placeholder')}
              id="groupdescription"
              error={groupDescriptionError}
            />
          </>
        )}
        {!canUserCreateGroup && (
          <Alert
            sx="mt-4"
            severity="warning"
            text={t('sub_level_error')}
          />
        )}
        <button
          disabled={loading}
          type="submit"
          className="btn btn-primary mt-4 w-48 pr-8 relative"
        >
          {t('submit_button')}
          <div className="absolute right-2">
            <SubIcon sub={'heart'} />
          </div>
        </button>
      </form>
    </div>
  );
}
