import { auth, db } from './firebase';
import {
  collection,
  getDoc,
  onSnapshot,
  doc,
  setDoc,
  query,
  where,
  limit,
  getDocs,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import {
  createAddedFriendNotification,
  createSentYouAFriendRequestNotification,
} from 'cuebids-firebase/notifications';
import { docsToObjects, docToObject } from 'firebase-util';
import { getPublicUserAsync } from './subscriptions';

// Statuses:
// mutual = Both are friends with each other
// following = I have sent this user a friend request that is pending OR we used to be friends and then I got blocked
// pending = This user has sent me a friend request
// blocked = I have blocked this user

export async function unblockFriend(friendUser) {

  const currentUser = auth.currentUser;

  const currentUserRef = doc(
    collection(doc(collection(db, 'users'), currentUser.uid), 'friends'),
    friendUser.id,
  );

  const friendRef = doc(
    collection(doc(collection(db, 'users'), friendUser.id), 'friends'),
    currentUser.uid,
  );

  const friendDoc = await getDoc(friendRef);
  const friend = docToObject(friendDoc);

  updateDoc(currentUserRef, {
    status: 'following',
  });

  if (friend?.status !== 'blocked') {
    updateDoc(friendRef, {
      status: 'pending',
    });
  }
}

export async function removeFriend(friendUser) {
  const currentUser = auth.currentUser;

  const currentUserRef = doc(
    collection(doc(collection(db, 'users'), currentUser.uid), 'friends'),
    friendUser.id,
  );

  const friendRef = doc(
    collection(doc(collection(db, 'users'), friendUser.id), 'friends'),
    currentUser.uid,
  );

  const friendDoc = await getDoc(friendRef);
  const friend = docToObject(friendDoc);

  updateDoc(currentUserRef, {
    status: 'blocked',
  });

  if (friend?.status !== 'blocked') {
    updateDoc(friendRef, {
      status: 'following',
    });
  }
}

export function getAllFriendsObservable({ callback }) {
  const currentUser = auth.currentUser;

  const q = collection(doc(db, 'users', currentUser.uid), 'friends');

  return onSnapshot(q, async (querySnapshot) => {
    const friends = docsToObjects(querySnapshot) ?? [];
    const friendsPromises = friends.map(function(f) {
      return getPublicUserAsync(f.uid);
    });
    try {
      const friendsFromDb = await Promise.all(friendsPromises);
      callback(
        friends.map(function(f, i) {
          const friendFromDb = friendsFromDb[i];
          return {
            ...f,
            displayName: friendFromDb?.displayName || '',
          };
        }),
      );
    } catch (e) {
      callback(
        friends.map((f) => {
          return { ...f,
            displayName: '' };
        }),
      );
    }
  });
}

export async function sendFriendRequest(uid) {
  const currentUser = auth.currentUser;

  const currentUserRef = doc(
    collection(doc(collection(db, 'users'), currentUser.uid), 'friends'),
    uid,
  );

  const addedFriendRef = doc(
    collection(doc(collection(db, 'users'), uid), 'friends'),
    currentUser.uid,
  );

  const alreadyFriendDoc = await getDoc(currentUserRef);

  if (alreadyFriendDoc.exists()) {
    const data = alreadyFriendDoc.data();
    if (
      data.status === 'blocked' ||
      data.status === 'pending' ||
      data.status === 'following'
    ) {
      return;
    }

    return {
      isNew: false,
      id: alreadyFriendDoc.id,
      ...data,
    };
  }

  const friendDocRef = doc(collection(db, 'users'), uid);

  const friendDoc = await getDoc(friendDocRef);

  const friendUser = friendDoc.data();

  setDoc(currentUserRef, {
    displayName: friendUser.displayName,
    photoURL: friendUser.photoURL ?? null, // TODO: tror vi kan ta bort detta helt
    status: 'following',
    uid: friendDoc.id,
  });

  setDoc(addedFriendRef, {
    displayName: currentUser.displayName,
    photoURL: currentUser.photoURL ?? null, // TODO: tror vi kan ta bort detta helt
    status: 'pending',
    uid: currentUser.uid,
  });

  const user = await getPublicUserAsync(currentUser.uid);

  createSentYouAFriendRequestNotification(uid, user.displayName ?? currentUser.displayName);

  return {
    isNew: true,
    ...friendUser,
  };
}

export async function replyToFriendRequest({ user, accepted }) {
  const currentUser = auth.currentUser;

  const currentUserRef = doc(
    collection(doc(collection(db, 'users'), currentUser.uid), 'friends'),
    user.id,
  );

  const friendRequestUserRef = doc(
    collection(doc(collection(db, 'users'), user.id), 'friends'),
    currentUser.uid,
  );

  const batch = writeBatch(db);

  if (accepted) {
    batch.update(currentUserRef, {
      status: 'mutual',
    });
    batch.update(friendRequestUserRef, {
      status: 'mutual',
    });
  } else {
    batch.update(currentUserRef, {
      status: 'blocked',
    });
  }

  return batch.commit();
}

export async function addFriendByFriendKey(friendKey) {
  const currentUser = auth.currentUser;

  const q = query(
    collection(db, 'users'),
    where('friendKey', '==', friendKey.toUpperCase()),
    limit(1),
  );

  const friend = await getDocs(q);

  if (!friend?.docs || friend.docs.length < 1) {
    return;
  }

  const friendUser = {
    id: friend.docs[0].id,
    ...friend.docs[0].data(),
  };

  const currentUserRef = doc(
    collection(doc(collection(db, 'users'), currentUser.uid), 'friends'),
    friendUser.id,
  );

  const addedFriendRef = doc(
    collection(doc(collection(db, 'users'), friendUser.id), 'friends'),
    currentUser.uid,
  );

  const alreadyFriendDoc = await getDoc(addedFriendRef);

  if (alreadyFriendDoc.exists()) {
    const data = alreadyFriendDoc.data();
    if (data.status === 'blocked') {
      return {
        blocked: true,
      };
    }
  }

  if (friendUser.disableFriendKey) {
    return {
      disableFriendKey: true,
    };
  }

  const alreadyFriendDoc2 = await getDoc(currentUserRef);
  if (alreadyFriendDoc2.exists()) {
    const data = alreadyFriendDoc2.data();
    if (['mutual', 'following'].includes(data.status)) {
      return {
        isNew: false,
        id: alreadyFriendDoc2.id,
        ...data,
      };
    }
  }

  setDoc(currentUserRef, {
    displayName: friendUser.displayName,
    photoURL: friendUser.photoURL ?? null, // TODO: tror vi kan ta bort detta helt
    status: 'mutual',
    uid: friendUser.id,
  });

  setDoc(addedFriendRef, {
    displayName: currentUser.displayName,
    photoURL: currentUser.photoURL ?? null,
    status: 'mutual',
    uid: currentUser.uid,
  });

  createAddedFriendNotification(friendUser.id, currentUser.displayName);

  return {
    isNew: true,
    ...friendUser,
  };
}

export async function disableTicketsAndEventsForFriend(friendId) {
  const currentUser = auth.currentUser;

  const currentUserRef = doc(
    collection(doc(collection(db, 'users'), currentUser.uid), 'friends'),
    friendId,
  );

  const friendUserRef = doc(
    collection(doc(collection(db, 'users'), friendId), 'friends'),
    currentUser.uid,
  );

  await updateDoc(currentUserRef, {
    disableFriendFromTicketsAndEvents: true,
  });

  return await updateDoc(friendUserRef, {
    friendHasDisabledMeFromTicketsAndEvents: true,
  });
}

export async function enableTicketsAndEventsForFriend(friendId) {
  const currentUser = auth.currentUser;

  const currentUserRef = doc(
    collection(doc(collection(db, 'users'), currentUser.uid), 'friends'),
    friendId,
  );

  const friendUserRef = doc(
    collection(doc(collection(db, 'users'), friendId), 'friends'),
    currentUser.uid,
  );

  await updateDoc(currentUserRef, {
    disableFriendFromTicketsAndEvents: false,
  });

  return await updateDoc(friendUserRef, {
    friendHasDisabledMeFromTicketsAndEvents: false,
  });
}

export function getRandomKey(length) {
  let result = '';
  const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export async function getNewFriendKey() {
  const retries = 100;

  for (let i = 0; i < retries; i++) {
    const key = getRandomKey(6);

    const q = query(
      collection(db, 'users'),
      where('friendKey', '==', key),
      limit(1)
    );

    const usesKey = await getDocs(q);

    if (!usesKey?.docs || usesKey.docs.length < 1) {
      return key;
    }
  }
}

export async function setNewFriendKey() {
  const { uid } = auth.currentUser;

  const friendKey = await getNewFriendKey();

  await updateDoc(doc(collection(db, 'users'), uid), { friendKey });
}
