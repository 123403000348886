import i18next from 'i18next';
import { updateProfile } from 'firebase/auth';
import {
  ref,
  query as dbQuery,
  orderByChild,
  equalTo,
  update,
  onValue,
  set,
} from 'firebase/database';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { auth, db, realDb } from './firebase';
import { uploadFile } from './storage';
import { getTrimmedDisplayName, isDisplayNameValid } from '../util/displayName';
import { docListenWithCallback, docToObject } from 'firebase-util';
import { getNextOnboardingStep } from '../util/onboarding';
import { ticketsMap } from '../util/tickets';
import { getUserHighestSubscription } from './subscriptions';
import { getSubAndExtraTicketCharges } from './ticketsUtil';

export async function createUser({ friendKey, displayName, fed, fedNr, fedVerified }) {
  const { uid, photoURL, email } = auth.currentUser;

  if ((displayName ?? '').trim() === '') {
    displayName = 'New User';
  }

  // auth profile
  const authProfilePromise = updateProfile(auth.currentUser, {
    displayName,
  });

  // realtime db
  const rbRef = ref(realDb, `users/${uid}/`);
  const usrDbObj = {};
  usrDbObj['displayName'] = displayName;
  if (photoURL) {
    usrDbObj['avatar'] = photoURL;
  }
  const realtimeDbPromise = set(rbRef, usrDbObj);

  // firestore
  const firestorePromise = setDoc(
    doc(collection(db, 'users'), uid),
    {
      displayName,
      photoURL,
      email,
      language: i18next.language ?? 'en',
      friendKey: friendKey ?? '',
      onboardingStep: getNextOnboardingStep(),
      fed: fed ?? null,
      fedNr: fedNr ?? null,
      fedVerified: fedVerified ?? !!fedNr,
    },
    { merge: true },
  );

  // contact
  const marketingPromise = addDoc(collection(db, 'marketingContacts'), {
    email: auth.currentUser.email,
    displayName: displayName,
  });

  return Promise.all([
    authProfilePromise,
    realtimeDbPromise,
    firestorePromise,
    marketingPromise,
  ]);
}

export async function updateUser(user) {
  const currentUser = auth.currentUser;

  let authProfilePromise;
  let marketingPromise;
  if (user.displayName) {
    user.displayName = isDisplayNameValid(user.displayName) ?
      getTrimmedDisplayName(user.displayName) :
      currentUser.displayName;

    // auth profile
    authProfilePromise = updateProfile(currentUser, {
      displayName: user.displayName,
    });

    // contact
    marketingPromise = addDoc(collection(db, 'marketingContacts'), {
      update: true,
      email: user.oldEmail ?? auth.currentUser.email,
      newEmail: user.email ?? auth.currentUser.email,
      displayName: user.displayName ?? auth.currentUser.displayName,
    });
  }


  // realtime db
  let realtimeDbPromise;
  const rbRef = ref(realDb, `users/${currentUser.uid}/`);
  const usrDbObj = {};
  if (user.displayName) {
    usrDbObj['displayName'] = user.displayName;
  }
  if (user.photoURL) {
    usrDbObj['avatar'] = user.photoURL;
  }
  if (user.flag) {
    usrDbObj['flag'] = user.showFlag ? user.flag : null;
  }
  if (Object.keys(usrDbObj).length !== 0) {
    realtimeDbPromise = update(rbRef, usrDbObj);
  }

  // firestore
  const firestorePromise = updateDoc(
    doc(collection(db, 'users'), currentUser.uid),
    user,
  );

  return Promise.all([
    authProfilePromise,
    realtimeDbPromise,
    firestorePromise,
    marketingPromise,
  ]);
}

export async function updateProfilePicture(file) {
  const url = await uploadFile(file);

  await updateProfile(auth.currentUser, { photoURL: url });
  await updateUser({ photoURL: url });
}

export async function initiateAccountRemoval(remove) {
  const currentUser = auth.currentUser;

  return updateDoc(doc(collection(db, 'users'), currentUser.uid), {
    removeMe: remove,
  });
}

// #region GETS

function userAdapter(user) {
  return Object.assign(
    {
      // id // TODO: Some places use user.id/friend.id
      uid: user.id,
      // avatar
      ticketsSpent: 0,
      rankLevel: 'jack',
      rankLevelStars: 0,
      rating: 1500,
      dailyDefaultPartner: null,
      weeklyDefaultPartner: null,
      practiceDefaultPartner: null,
      practiceDefaultNumberOfDeals: 12,
      challengeDefaultPartner: null,
      challengeDefaultOpponent1: null,
      challengeDefaultOpponent2: null,
      challengeDefaultNumberOfDeals: 12,
      // friendKey
      autoNext: false,
      // disableConfirmFinalBid: false,
      disableFriendKey: false,
      handAsDiagram: false,
      hideHcpCount: false,
      // subscriptions: [], // From RTDB?
      // IAPsubscriptions: [], // From RTDB?
      // online: true, // From RTDB?
      // displayName
      // photoURL
      // email
      showFlag: true,
      flag: null,
      junior: false,
      pro: false,
      numberOfStars: 0,
      weeklyWins: 0,
      dailyWins: 0,
      // stripeId
      removeMe: false,
    },
    user,
  );
}

function publicUserAdapter(user) { }

export async function getUser({ userId } = {}) {
  if (!userId) {
    userId = auth.currentUser.uid;
  }

  const user = await getDoc(doc(collection(db, 'users'), userId));
  const data = user.data();

  return {
    ...data,
    id: user.id,
  };
}

export function getUserObservable({ userId, callback } = {}) {
  if (!userId) {
    userId = auth.currentUser.uid;
  }

  const docRef = doc(collection(db, 'users'), userId);

  return docListenWithCallback(docRef, callback);
}

export async function checkAllowedToChargeFriendTicketsAndEvents(friendId) {
  const currentUser = auth.currentUser;

  const friendUserRef = doc(
    collection(doc(collection(db, 'users'), friendId), 'friends'),
    currentUser.uid,
  );

  const friendUser = await getDoc(friendUserRef);
  const friendUserData = docToObject(friendUser);

  return !friendUserData?.disableFriendFromTicketsAndEvents;
}

export async function chargeUsersTickets({ userCharges, batch }) {
  for (let i = 0; i < userCharges.length; i++) {
    await chargeUserTickets({
      userId: userCharges[i].user,
      tickets: userCharges[i].charge,
      batch,
    });
  }
}

export async function chargeUserTickets({ userId, tickets, batch }) {
  const sub = await getUserHighestSubscription(userId);
  const _user = await getDoc(doc(collection(db, 'users'), userId));
  const user = _user.data();

  const subTickets = ticketsMap[sub ?? 'basic'];
  const spentTickets = user.ticketsSpent ?? 0;

  const { subTicketCharge, extraTicketsCharge } = getSubAndExtraTicketCharges(
    subTickets,
    spentTickets,
    tickets,
  );

  if (batch) {
    batch.update(doc(collection(db, 'users'), userId), {
      ticketsSpent: increment(subTicketCharge),
      extraTickets: increment(-extraTicketsCharge),
    });
  } else {
    await updateDoc(doc(collection(db, 'users'), userId), {
      ticketsSpent: increment(subTicketCharge),
      extraTickets: increment(-extraTicketsCharge),
    });
  }
}

export async function getUserPublic(userId) {
  const user = await getDoc(doc(collection(db, 'users'), userId));
  const data = user.data();

  if (!data) {
    return {
      displayName: i18next.t('user_api.unknown'),
      numberOfStars: 0,
      dailyWins: 0,
      weeklyWins: 0,
      id: userId,
      junior: false,
      pro: false,
      robot: false,
    };
  }

  return {
    displayName: data.displayName,
    numberOfStars: data.numberOfStars,
    dailyWins: data.dailyWins,
    weeklyWins: data.weeklyWins,
    flag: data.flag,
    showFlag: data.showFlag,
    id: userId,
    uid: userId,
    pro: data.pro ?? false,
    robot: data.robot ?? false,
    junior: data.junior ?? false,
    rankLevelStars: data.rankLevelStars ?? 0,
    rankLevel: data.rankLevel ?? 'jack',
    rating: data.rating ?? 1500,
    discordTag: data.discordTag,
    challengeStatistics: data.challengeStatistics ?? {}
  };
}

// #endregion
