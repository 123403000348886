import Dialog from '../dialog';
import { TicketIcon } from '../icons/ticketsIcon';
import InfoPopup from '../infoPopup/infoPopup';
import { PAYMENT_STRATEGY_SPLIT } from '../../util/tickets';
import { useTranslation } from 'react-i18next';

const ExplainTickets = ({ paymentStrategy }) => {
  const { t } = useTranslation();

  return <p>
    {t('price_dialog.explain_split')}
  </p>
};

export default function PriceDialog({
  open,
  onClose,
  actions,
  title,
  myCharge,
  partnerCharge,
  myRemainingTickets,
  partnerRemainingTickets,
  paymentStrategy,
}) {
  const { t } = useTranslation();
  const chargeString =
      t('price_dialog.your_charge', { count: myCharge }) +
      t('price_dialog.partner_charge', { count: partnerCharge })

  return (
    <Dialog open={open} onClose={onClose} title={title} actions={actions}>
      <div>
        <div className="flex justify-center">{chargeString}</div>
        <div className="mt-4 flex justify-center">
          <div className="flex flex-col items-center">
            <div style={{ height: '35px', lineHeight: '35px' }}>{t('price_dialog.you')}</div>
            <div style={{ height: '25px', lineHeight: '25px' }}>{`${
              myRemainingTickets === Infinity ? '\u221E' : myRemainingTickets
            }`}</div>
            <div
              style={{ height: '25px', lineHeight: '25px' }}
            >{`${myCharge}`}</div>
          </div>
          <div className="mx-8 flex flex-col items-center">
            <div style={{ height: '35px' }}>
              <TicketIcon />
            </div>
            <div style={{ height: '25px', lineHeight: '25px' }}>{t('price_dialog.available')}</div>
            <div style={{ height: '25px', lineHeight: '25px' }}>{t('price_dialog.charge')}</div>
            <div style={{ height: '35px' }}>
              <InfoPopup id="explain-ev" sx="stroke-info">
                <ExplainTickets paymentStrategy={paymentStrategy} />
              </InfoPopup>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div style={{ height: '35px', lineHeight: '35px' }}>{t('price_dialog.partner')}</div>
            <div style={{ height: '25px', lineHeight: '25px' }}>{`${
              partnerRemainingTickets === Infinity
                ? '\u221E'
                : partnerRemainingTickets
            }`}</div>
            <div
              style={{ height: '25px', lineHeight: '25px' }}
            >{`${partnerCharge}`}</div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
