import { getAnalytics as getAnalyticsWrapper } from 'firebase/analytics';
import { initializeApp, getApps } from 'firebase/app';
import { getAuth as getAuthWrapper } from 'firebase/auth';
import { getDatabase as getDatabaseWrapper } from 'firebase/database';
import { getFirestore as getFirestoreWrapper } from 'firebase/firestore';
import { getFunctions as getFunctionsWrapper } from 'firebase/functions';
import { getMessaging as getMessagingWrapper } from 'firebase/messaging';
import { getStorage as getStorageWrapper } from 'firebase/storage';
import { createRepos } from './repo';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
export let app;
export let auth;
export let db;
export let realDb;
export let storage;
export let analytics;
export let functions;
export let messaging;
export let repos;
export let appCheck;
export function init(config, appCheckToken, debugToken) {
    app = (getApps().length > 0) ? getApps()[0] : initializeApp(config);
    if (debugToken != null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        self.FIREBASE_APPCHECK_DEBUG_TOKEN = debugToken;
    }
    appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(debugToken ?? appCheckToken),
        isTokenAutoRefreshEnabled: true
    });
    auth = getAuthWrapper(app);
    db = getFirestoreWrapper(app);
    realDb = getDatabaseWrapper(app);
    storage = getStorageWrapper(app);
    analytics = getAnalyticsWrapper(app);
    functions = getFunctionsWrapper(app, 'europe-west1');
    try {
        messaging = getMessagingWrapper(app);
    }
    catch (ex) {
    }
    repos = createRepos();
    return app;
}
export function currentUser() {
    if (auth == null) {
        throw new Error('Firebase not initialized. Please call init() first.');
    }
    return auth.currentUser;
}
export async function getIdToken() {
    if (auth == null) {
        throw new Error('Firebase not initialized. Please call init() first.');
    }
    if (auth.currentUser == null) {
        throw new Error('Not logged in');
    }
    return await auth.currentUser.getIdToken(true);
}
export function currentUserId() {
    if (auth == null) {
        throw new Error('Firebase not initialized. Please call init() first.');
    }
    return auth?.currentUser?.uid ?? '';
}
export function isLoggedIn() {
    return auth?.currentUser?.uid !== null;
}
export function getAuth() {
    if (auth == null) {
        throw new Error('Firebase not initialized. Please call init() first.');
    }
    return auth;
}
export function getFirestore() {
    if (db == null) {
        throw new Error('Firebase not initialized. Please call init() first.');
    }
    return db;
}
export function getDatabase() {
    if (realDb == null) {
        throw new Error('Firebase database not initialized. Please call init() first.');
    }
    return realDb;
}
export function getStorage() {
    if (storage == null) {
        throw new Error('Firebase not initialized. Please call init() first.');
    }
    return storage;
}
export function getAnalytics() {
    if (analytics == null) {
        throw new Error('Firebase not initialized. Please call init() first.');
    }
    return analytics;
}
export function getFunctions() {
    if (functions == null) {
        throw new Error('Firebase not initialized. Please call init() first.');
    }
    return functions;
}
/**
 * Messaging is not allowed in WebViews
 */
export function getMessaging() {
    return messaging;
}
export function getRepos() {
    if (repos == null) {
        throw new Error('Firebase not initialized. Please call init() first.');
    }
    return repos;
}
