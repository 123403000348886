import Spade from '../../images/suits/Spade';
import Club from '../../images/suits/Club';
import Diamond from '../../images/suits/Diamond';
import Heart from '../../images/suits/Heart';

export default function DisplayHand({ hand, hideDealNumber, dealNumber }) {
  const suits = hand.split('.');
  return (
    <div className="text-md relative flex items-center min-w-[225px]">
      {!hideDealNumber && (
        <div
          style={{
            fontSize: 'x-small',
            position: 'absolute',
            top: -10,
            left: -5,
          }}
        >
          {dealNumber}
        </div>
      )}
      <div className="ml-2 flex items-center">
        <Spade />
        <span className="spades ml-0.5 font-bold md:text-lg">
          {suits[0].length > 0 ? `${suits[0]} ` : '-'}
        </span>
      </div>
      <div className="ml-2 flex items-center">
        <Heart />
        <span className="hearts ml-0.5 font-bold md:text-lg">
          {suits[1].length > 0 ? `${suits[1]} ` : '-'}
        </span>
      </div>
      <div className="ml-2 flex items-center">
        <Diamond />
        <span className="diamonds ml-0.5 font-bold md:text-lg">
          {suits[2].length > 0 ? `${suits[2]} ` : '-'}
        </span>
      </div>
      <div className="ml-2 flex items-center">
        <Club />
        <span className="clubs ml-0.5 font-bold md:text-lg">
          {suits[3].length > 0 ? `${suits[3]} ` : '-'}
        </span>
      </div>
    </div>
  );
}
