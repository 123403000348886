import EventTimer from './eventTimer';
import Leaderboard from '../leaderBoards/leaderBoard';
import { Link } from 'react-router-dom';
import { EventIcon } from '../icons/dailyIcon';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useTranslation } from 'react-i18next';

export default function Weekly({ session }) {
  const weekly = useAppStateStore((state) => state.weekly);

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.weekly_card' });

  const endDateString = weekly
    ? new Date(weekly.endDate).toLocaleDateString(i18n.language, {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    })
    : '';

  return (
    <div className="fade-in-slow card w-[350px] border-2 border-accent md:w-[400px]">
      <div className="flex items-center">
        <div className="flex w-[60%] flex-col items-center md:pr-4">
          {weekly && (
            <div className="ml-1 w-full md:ml-4">
              <Leaderboard
                leaderBoard={weekly.leaderBoard}
                top={3}
                links={true}
                header=""
                min={45}
              />
            </div>
          )}
        </div>
        <div className="my-2 flex w-[40%] flex-col items-center justify-center text-center">
          <div className="flex items-center">
            <EventIcon fill="#e29627" />
            <span>{t('title')}</span>
          </div>
          <div>
            <div className="mb-3">
              <span className="info">{weekly.pairCount ?? 0} {t('pairs')}</span>
            </div>
            <Link to={session ? '/weeklyLeaderboard/' + weekly.id : '/weekly'}>
              <button className="btn-primary btn-sm btn mb-4 ">
                {session ? (
                  session.dealsCount - session.numberOfFinishedDeals > 0 ? (
                    <span>{t('continue')}</span>
                  ) : (
                    <span>{t('leaderboard')}</span>
                  )
                ) : (
                  <span>{t('join_event')}</span>
                )}
              </button>
            </Link>
            {weekly && (
              <EventTimer
                event={weekly}
                endDate={endDateString}
                label={'Weekly'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
