import { useState, useEffect } from 'react';
import '../../components/hand/hands.css';
import { SelectFriendDropdown } from '../../components/dropdown/dropdown';

import { useNavigate } from 'react-router-dom';
import {
  createChallengeMatchmaking,
  cancelChallengeMatchmaking,
} from '../../firebase/challengeMatchmaking';
import RankCard from '../../components/rankCard/rankCard';
import { ChallengeIcon } from '../../components/icons/challengeIcon';
import { getUser } from '../../firebase/userApi';
import { getUserHighestSubscription } from '../../firebase/subscriptions';
import {
  getUserRemainingTickets,
  canPairAffordToPlay,
  getCharges,
} from '../../util/tickets';
import Alert from '../../components/alert/alert';
import Checkbox from '../../components/checkbox/Checkbox';
import Price from '../../components/price/price';
import RemainingTicketsWarning from '../../components/remainingTicketsWarning/remainingTicketsWarning';
import PriceDialog from '../../components/priceDialog/priceDialog';
import { useActiveSubs, useAuth, useRemainingTicketsNavbarItems } from '../../util/hooks';
import { useAppStateStore } from '../../appStateStore.jsx';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

const PRICE = 8;

export default function ChallengeMatchmaking() {
  const { t } = useTranslation('translation', { keyPrefix: 'challenge_matchmaking' });

  const { currentUser } = useAuth();
  const user = useAppStateStore((state) => state.user);
  const friends = useAppStateStore((state) => state.friends);
  const challengeMatchmaking = useAppStateStore((state) => state.challengeMatchmaking);
  const metadata = useAppStateStore((state) => state.metadata);
  const challengeQueue = useAppStateStore((state) => state.challengeQueue);
  const notify = useNotifications();
  const [friendId, setFriendId] = useState(
    user?.challengeDefaultPartner || ''
  );
  const [friend, setFriend] = useState(null);
  const [friendSub, setFriendSub] = useState(null);
  const [loading, setLoading] = useState(false);
  const [waitingForMatch, setWaitingForMatch] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const highestSub = useActiveSubs()[0];

  const nav = useNavigate();

  useRemainingTicketsNavbarItems();

  useEffect(
    function () {
      if (currentUser && challengeMatchmaking) {
        if (challengeMatchmaking.challengeId) {
          if (waitingForMatch === challengeMatchmaking.id) {
            // Notification is handled in AppEffects, do navigation here if on this page
            nav(`/challenge/${challengeMatchmaking.challengeId}`, {
              replace: true,
            });
          }
          setWaitingForMatch(null);
        } else {
          setWaitingForMatch(challengeMatchmaking.id);
        }
      } else {
        setWaitingForMatch(null);
      }
    },
    [currentUser, challengeMatchmaking, waitingForMatch, nav]
  );

  useEffect(
    function () {
      if (friendId) {
        // Slightly ugly to nest, but now we know that when friend is loaded we have loaded both.
        getUserHighestSubscription(friendId).then(function (fs) {
          setFriendSub(fs);
          getUser({ userId: friendId }).then(function (f) {
            setFriend(f);
          });
        });
      }
    },
    [friendId]
  );

  const handleSelectFriend = (id) => {
    setFriendId(id);
    setFriend(null);
    setFriendSub(null);
  };

  const handleCancel = async () => {
    setLoading(true);
    try {
      await cancelChallengeMatchmaking();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      notify(
        { type: 'failure', text: t('cancel_error') },
      );
    }
  };

  if (!friends || !user) {
    return <></>;
  }

  const banned = user.banned ?? false;
  const friendBanned = friend?.banned ?? false;

  const myRemainingTickets = getUserRemainingTickets(
    user.ticketsSpent ?? 0,
    highestSub,
    user.extraTickets ?? 0
  );

  const partnerRemainingTickets = getUserRemainingTickets(
    friend?.ticketsSpent ?? 0,
    friendSub,
    friend?.extraTickets ?? 0
  );

  const { myCharge, partnerCharge } = getCharges({
    myRemainingTickets,
    partnerRemainingTickets,
    price: PRICE,
  });

  const startMatchmaking = async () => {
    setLoading(true);
    try {
      const id = await createChallengeMatchmaking({
        minDealNumber: metadata?.minDealNumber,
        partnerUserId: friendId,
        userRating: user.rating,
        userRank: user.rankLevel,
        userTicketsSpent: user.ticketsSpent ?? 0,
        userExtraTickets: user.extraTickets ?? 0,
      });
      setLoading(false);
      if (id) {
        nav(`/challenge/${id}`, { replace: true });
      }
    } catch (e) {
      setLoading(false);
      notify({
        type: 'failure',
        text: t('create_error', {error: e.message}),
      });
    }
  };

  const handleClick = () => {
    if (myCharge !== 0 || partnerCharge !== 0) {
      setConfirmationModalOpen(true);
    } else {
      void startMatchmaking();
    }
  };

  const handleConfirmation = () => {
    void startMatchmaking();
    setConfirmationModalOpen(false);
  };

  const handleClose = () => {
    setConfirmationModalOpen(false);
  };

  return (
    <>
      <div className="fade-in">
        <div className="page pb-24">
          <Alert
            sx="w-full md:w-1/2"
            severity="info"
            title={t('title')}
            text={t('info')}
          />

          <SelectFriendDropdown
            disabled={!!waitingForMatch}
            value={friendId}
            onChange={handleSelectFriend}
            label={t('partner')}
            friends={friends}
            disableFromTicketsAndEvents
            disableFromTicketsAndEventsLabel={t('partner_disabled_label')}
          />

          <div className="my-4 flex items-center justify-between gap-16">
            <div className="flex flex-col">
              <Checkbox disabled checked label={t('bullet_robots')} />
              <Checkbox disabled checked label={t('bullet_boards')} />
              <Checkbox disabled checked label={t('bullet_ranked')} />
            </div>
            <RankCard
              uid={currentUser.uid}
              rank={user?.rankLevel || 'jack'}
              rankStars={user?.rankLevelStars || 0}
              rating={user?.rating || 1500}
              alwaysShowRating
            />
          </div>
          <Price price={PRICE} />
          {friend && (
            <RemainingTicketsWarning
              myRemainingTickets={myRemainingTickets}
              partnerRemainingTickets={partnerRemainingTickets}
              price={PRICE}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {waitingForMatch && (
            <Alert
              icon={
                <ChallengeIcon
                  className="handshake-animation"
                  fill="#9f4e4e"
                  strokeWidth={0.5}
                />
              }
              severity="info"
              sx={'mt-4'}
              text={t('in_progress')}
            />
          )}
          {!waitingForMatch && challengeQueue.length > 0 && (
            <Alert
              icon={
                <ChallengeIcon
                  className="handshake-animation"
                  fill="#9f4e4e"
                  strokeWidth={0.5}
                  width={24}
                />
              }
              sx={'w-75 mt-4'}
              text={t('somebody_looking')}
            />
          )}
          {banned && (
            <Alert
              severity="error"
              sx={'w-75 mt-4'}
              text={t('banned_you')}
            />
          )}
          {friendBanned && (
            <Alert
              severity="error"
              sx={'w-75 mt-4'}
              text={t('banned_partner')}
            />
          )}
          {waitingForMatch ? (
            <button
              className="btn-secondary btn mt-4"
              disabled={loading}
              onClick={handleCancel}
            >
              {t('cancel')}
            </button>
          ) : (
            <button
              className="btn-primary btn mt-4"
              disabled={
                banned ||
                friendBanned ||
                !friendId ||
                !friend ||
                !canPairAffordToPlay({
                  myRemainingTickets,
                  partnerRemainingTickets,
                  price: PRICE,
                }) ||
                loading
              }
              onClick={handleClick}
            >
              {t('find_match')}
            </button>
          )}
        </div>
      </div>
      <PriceDialog
        open={confirmationModalOpen}
        onClose={handleClose}
        title={t('price_dialog_title')}
        myRemainingTickets={myRemainingTickets}
        partnerRemainingTickets={partnerRemainingTickets}
        myCharge={myCharge}
        partnerCharge={partnerCharge}
        actions={[
          {
            component: (
              <div
                disabled={loading}
                role="button"
                key="start-challenge"
                className="btn-primary btn"
                onClick={handleConfirmation}
              >
                {t('price_dialog_submit')}
              </div>
            ),
          },
        ]}
      />
    </>
  );
}
