import Icon from '../../images/icons/edit.svg?react';

export const EditIcon = (props) => (
  <Icon
    width={props.width || '24px'}
    height={props.width || '24px'}
    color={props.color || 'white'}
    stroke={props.stroke || 'white'}
    {...props}
  />
);
