import Icon from '../../images/icons/info.svg?react';

export const InfoIcon = (props) => (
  <Icon
    width={props.width || '16px'}
    height={props.width || '16px'}
    color={props.color || 'white'}
    fill={props.fill || 'white'}
    {...props}
  />
);
