import { TicketIcon } from '../components/icons/ticketsIcon.jsx';
import { useTranslation } from 'react-i18next';

export default function FreeToDiamondInfo() {
  const { t } = useTranslation();

  return (
    <table className="table w-full">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th className={'text-md'}>{t('marketing.free')}</th>
          <th className={'diamonds text-xl'}>{t('marketing.diamond')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{t('marketing.tickets')}</th>
          <td>
            <TicketIcon />
          </td>
          <td>48</td>
          <td className={'text-sky-400 font-bold'}>240</td>
        </tr>
        <tr>
          <th>{t('marketing.deal_types')}</th>
          <td />
          <td>1</td>
          <td className={'text-sky-400 font-bold'}>14</td>
        </tr>
      </tbody>
    </table>
  );
}
