import './chatMessage.css';
import { UserDisplayNameSmall } from '../users/userDisplay';
import ChatMessageDisplay from './chatMessageDisplay';

export default function UserMessagesDisplay({ userId, messages, links }) {
  const isMe = userId === messages[0].userId;
  
  const messageFromExtra = messages[0].messageFromExtra ?? false;
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: isMe ? 'flex-end' : 'flex-start',
          marginRight: '40px',
          marginLeft: '40px',
          textAlign: isMe ? 'right' : 'left',
        }}
      >
        <UserDisplayNameSmall uid={messages[0].userId} />
      </div>
      {messages.map((m, i) => {
        return (
          <ChatMessageDisplay
            key={m.id + 'M'}
            isMe={isMe}
            messageFromExtra={messageFromExtra}
            specialMessage={m.specialMessage}
            uid={m.userId}
            message={m.message}
            showProfilePicture={i === messages.length - 1}
            links={links}
          />
        );
      })}
    </>
  );
}
