import Icon from '../../images/icons/stats.svg?react';

export const StatsIcon = (props) => (
  <Icon
    width={props.width || '24px'}
    height={props.width || '24px'}
    color={props.color || 'white'}
    fill={props.fill || 'white'}
    {...props}
  />
);
