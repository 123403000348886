import { useState } from 'react'
import { cloneScenario, getMyScenarios, getPublishedScenarios } from 'cuebids-firebase/dealGeneration'
import { useQuery } from '@tanstack/react-query';
import UserDisplay, { UserDisplayInstantNameFlag, UserDisplaySmallRight } from '../../components/users/userDisplay.jsx'
import { LikeIcon } from '../../components/icons/likeIcon.jsx'
import { useAppStateStore } from '../../appStateStore.jsx'
import { useActiveSubs, useAuth, useHasLoadedDataToGetSubs } from '../../util/hooks.jsx'
import { isSubEnough } from '../../util/sub.js'
import Alert from '../../components/alert/alert.jsx'
import goToStore from '../../util/goToStore.js'
import { Link, useNavigate } from 'react-router-dom'
import { SubIcon } from '../../components/subIcons/subIcons.jsx'
import RequireHeartDialog from '../../marketing/RequireHeartDialog.jsx'
import TextField from '../../components/textField/TextField.jsx'
import algoliasearch from 'algoliasearch'
import { resToObjects } from '../../util/algolia.js'
import { replaceSuitShorthandWithSymbols } from 'cuebids-bidding-util';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

const algoliaSearchApiKey = 'bbc7a654026166c01044ca839e5072f8';
const algoliaAppId = 'JVCVRW8KT6';

const algoliaClient = algoliasearch(algoliaAppId, algoliaSearchApiKey);

const algoliaUsersIndexName =
  import.meta.env.VITE_INSTANCE_NAME === 'dev' ? 'dev_USERS' : 'prod_USERS';

const algoliaUsersIndex = algoliaClient.initIndex(algoliaUsersIndexName);

const algoliaScenariosIndexName =
  import.meta.env.VITE_INSTANCE_NAME === 'dev' ? 'dev_SCENARIOS' : 'prod_SCENARIOS';

const algoliaScenariosIndex = algoliaClient.initIndex(algoliaScenariosIndexName);

function ScenarioCard({ scenario, myScenarios, handleCloneScenario, loading, t }) {
  return (
    <div className="fade-in card rounded-xl border shadow-lg bg-info border-info bg-opacity-10">
      <div className="card-body w-full p-2 md:p-4">
        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-around items-center">
            {scenario.name}
            <span className={'info ml-3'}>{t('community_scenarios.by')}</span>
            <UserDisplaySmallRight uid={scenario.uid} />
          </div>
          <div className="info max-w-[250px]">{replaceSuitShorthandWithSymbols(scenario.description ?? '')}</div>
          <div className="w-full flex justify-evenly items-center">
            <div
              className="flex items-center mr-4"
            >
              <LikeIcon className={'stroke-rose-400 fill-rose-400'} />
              <span>{scenario.numberOfClones}</span>
            </div>
            <button
              onClick={() => handleCloneScenario(scenario.id)}
              className="btn btn-primary mt-2 relative pr-10"
              disabled={loading || myScenarios.find(s => s.id === scenario.id || s.parentId === scenario.id)}
            >
              {t('community_scenarios.save')}
              <div className="absolute right-2">
                <SubIcon sub={'heart'} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CommunityScenarios() {
  const { currentUser } = useAuth();
  const isNative = useAppStateStore((state) => state.isNative);
  const iap = useAppStateStore((state) => state.iap);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { data: communityScenarios, refetch: refetchCommunityScenarios } = useQuery({
    queryKey: ['scenarios'],
    queryFn: async () => await getPublishedScenarios(),
  });

  const { data: myScenarios, refetch: refetchMyScenarios } = useQuery({
    queryKey: ['myScenarios'],
    queryFn: async () => await getMyScenarios(),
  });

  const notify = useNotifications();

  const userActiveSubs = useActiveSubs();
  const hasLoadedDataToGetSubs = useHasLoadedDataToGetSubs();
  const canUserCreateType = isSubEnough(userActiveSubs, 'heart');
  const [promptToSubscribe, setPromptToSubscribe] = useState(false);
  const [loadingSaveClone, setLoadingSaveClone] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchUsersString, setSearchUsersString] = useState('');
  const [searchUsersResults, setSearchUsersResults] = useState(null);
  const [searchScenariosString, setSearchScenariosString] = useState('');
  const [searchScenariosResults, setSearchScenariosResults] = useState(null);
  const [creatorUid, setCreatorUid] = useState(null);

  const searchUsers = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);
    setSearchUsersResults(null);
    setSearchScenariosResults(null);
    setCreatorUid(null);
    setSearchScenariosString('');
    const res = await algoliaUsersIndex.search(searchUsersString);
    setSearchUsersResults(resToObjects(res));
    setLoadingSearch(false);
  };

  const handleSelectCreator = async (uid) => {
    setLoadingSearch(true);
    setSearchUsersResults(null);
    const res = await algoliaScenariosIndex.search(uid);
    setSearchScenariosResults(resToObjects(res).filter(s => s.published));
    setCreatorUid(uid);
    setLoadingSearch(false);
  };

  const searchScenarios = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);
    setSearchScenariosResults(null);
    setSearchUsersResults(null);
    setCreatorUid(null);
    setSearchUsersString('');
    const res = await algoliaScenariosIndex.search(searchScenariosString);
    setSearchScenariosResults(resToObjects(res).filter(s => s.published));
    setLoadingSearch(false);
  };

  const handleClearSearch = () => {
    setSearchUsersString('');
    setSearchScenariosString('');
    setSearchScenariosResults(null);
    setSearchUsersResults(null);
    setCreatorUid(null);
  };

  async function handleCloneScenario(scenarioId) {
    if (!canUserCreateType) {
      setPromptToSubscribe(true);
      return;
    }

    if (loadingSaveClone) return;

    try {
      setLoadingSaveClone(true);
      await cloneScenario(scenarioId);
      await Promise.all([refetchCommunityScenarios(), refetchMyScenarios()]);
      notify(
        {
          text: t('community_scenarios.save_success'),
          type: 'success',
        },
      );
    } catch (e) {
      notify(
        {
          text: t('community_scenarios.save_error'),
          type: 'failure',
        },
      );
    } finally {
      setLoadingSaveClone(false);
    }
  }

  const isLoading = !communityScenarios || !myScenarios || !hasLoadedDataToGetSubs;

  const scenariosToShow = searchScenariosResults || communityScenarios || [];

  return (
    <div className="page pb-24">
      {promptToSubscribe && <RequireHeartDialog onClose={() => setPromptToSubscribe(false)} />}
      {isLoading ? (
        <span className="loading loading-lg"></span>
      ) : (
        <>
          {!canUserCreateType && (
            <div className={'flex flex-col items-center mb-8'}>
              <Alert
                sx="mt-4"
                severity="warning"
                text={t('community_scenarios.heart_sub_info')}
              />
              <button
                className={'btn btn-secondary mt-2'}
                onClick={() => goToStore({
                  nav: navigate,
                  isNative,
                  iap,
                  uid: currentUser.uid,
                  tab: 'sub',
                })}
              >
                {t('community_scenarios.subscribe')}
              </button>
            </div>
          )}
          <div className="md:flex md:gap-4">
            <form className="w-80 flex items-end" onSubmit={searchUsers}>
              <TextField
                value={searchUsersString}
                label={t('community_scenarios.search_user_label')}
                placeholder={t('community_scenarios.search_user_placeholder')}
                onChange={setSearchUsersString}
              />
              <button
                type="submit"
                disabled={searchUsersString.length < 3 || loadingSearch}
                className="btn btn-primary ml-2"
              >
                {t('community_scenarios.search_user_submit')}
              </button>
            </form>
            <form className="w-80 flex items-end mt-2 md:mt-0" onSubmit={searchScenarios}>
              <TextField
                value={searchScenariosString}
                label={t('community_scenarios.search_scenario_label')}
                placeholder={t('community_scenarios.search_scenario_placeholder')}
                onChange={setSearchScenariosString}
              />
              <button
                type="submit"
                disabled={searchScenariosString.length < 3 || loadingSearch}
                className="btn btn-primary ml-2"
              >
                {t('community_scenarios.search_scenario_submit')}
              </button>
            </form>
          </div>
          <div className="my-4 flex items-center">
            {creatorUid && (
              <div className="m-2">
                <UserDisplay uid={creatorUid} />
              </div>
            )}
            {(creatorUid || searchUsersResults || searchScenariosResults) && !loadingSearch && (
              <button className="btn btn-secondary" onClick={handleClearSearch}>{t('community_scenarios.clear_search')}</button>
            )}
          </div>
          {loadingSearch && <span className="loading loading-lg"></span>}
          {!loadingSearch && searchUsersResults &&
                (searchUsersResults.length > 0 ? (
                  <table className="mt-4 w-80">
                    <thead>
                      <tr>
                        <th>{t('community_scenarios.results_count', {count: searchUsersResults.length})}</th>
                      </tr>
                    </thead>
                    <tbody className="[&>*:nth-child(odd)]:bg-base-500">
                      {searchUsersResults.map((u) => {
                        const uid = u.id;
                        const flag = u.flag;
                        const showFlag = u.showFlag;
                        const displayName = u.displayName;

                        return (
                          <tr key={uid} className="fade-in-slow">
                            <td className="p-2">
                              <Link to={'/player/' + uid}>
                                <UserDisplayInstantNameFlag
                                  key={uid}
                                  flag={flag}
                                  showFlag={showFlag}
                                  name={displayName}
                                />
                              </Link>
                            </td>
                            <td>
                              <div className="flex justify-end">
                                <button
                                  className="btn btn-secondary btn-xs"
                                  onClick={function () {
                                    void handleSelectCreator(uid);
                                  }}
                                >
                                  {t('community_scenarios.select')}
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <Alert text={t('community_scenarios.user_search_empty')} severity="warning" />
                ))}
          {!loadingSearch && !searchUsersResults && (
            scenariosToShow.length > 0 ? (
              <div className='w-full flex flex-wrap gap-2 items-center justify-center'>
                {scenariosToShow.map((scenario) => (
                  <ScenarioCard
                    key={scenario.id}
                    scenario={scenario}
                    handleCloneScenario={handleCloneScenario}
                    myScenarios={myScenarios}
                    loading={loadingSaveClone}
                    t={t}
                  />
                ))}
              </div>
            ) : (
              <Alert severity="warning" text={communityScenarios.length > 0 ? t('community_scenarios.scenario_search_empty') : t('community_scenarios.no_scenarios')} />
            )
          )}
        </>
      )}
    </div>
  );
}
