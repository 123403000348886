import { GroupsIcon } from '../icons/groupsIcon';
import { useTranslation } from 'react-i18next'

export default function GroupCard({ name, description, premium, groupFlag, logo }) {
  const { t } = useTranslation();
  const groupIcon = groupFlag ? (
    <span className={`fi fi-${groupFlag} w-6`}></span>
  ) : (
    <GroupsIcon className="fill-blue-400" />
  )

  return (
    <div className="fade-in card w-[350px] overflow-hidden rounded-xl border shadow-lg bg-info border-info bg-opacity-10">
      <div className="card-body w-full p-2 md:p-4">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center gap-2">
            {logo ? logo : <>{groupIcon}<div className="text-2xl max-w-[250px]">{name}</div></>}
          </div>
          <div className="text-lg max-w-[250px]">{description}</div>
          {premium && <div className="badge badge-success mt-2">{t('components.group_card.premium_group_badge')}</div>}
        </div>
      </div>
    </div>
  );
}
