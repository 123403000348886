import { useEffect, useState, Fragment } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';
import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../../util/hooks.jsx';
import { StarRating } from '../../components/rating/starRating';
import { ContractDisplay } from '../../components/bidding/bidDisplay';
import HandCard from '../../components/hand/handCard';
import ChallengeResultCard from '../../components/challengeCard/challengeCard';
import { ChatDrawer } from '../../components/chat/chat';
import {
  getChallenge,
  getChatMessagesObservable,
  getMyUnreadChatMessagesField,
  pingChallenge,
  sendChatMessage,
  setChatMessagesAsRead,
} from '../../firebase/challenges';
import { getContractDeclarer } from 'cuebids-bidding-util';
import ChallengeTimer from '../../components/areaCards/challengeTimer';
import Animated from '../../components/animation/animated';
import { useAppStateStore } from '../../appStateStore';
import { getSessionDeal, getSessionDeals } from '../../firebase/biddingSessions';
import Hand from '../../components/cards/hand';
import BiddingDisplay from '../../components/bidding/biddingDisplay';
import Alert from '../../components/alert/alert';
import OtherChallengeResultCard from '../../components/challengeCard/otherChallengeCard'
import ChevronUp from '../../components/icons/chevronUp.jsx'
import ChevronDown from '../../components/icons/chevronDown.jsx'
import { useTranslation } from 'react-i18next'

const directions = {
  S: 'South',
  W: 'West',
  N: 'North',
  E: 'East',
};

export function WhenToFinishText({ timestamp }) {
  const endDate = 48 * 60 * 60 * 1000 + timestamp;

  return <ChallengeTimer endDate={endDate} />;
}

function maybePingChallenge(challenge) {
  if (!challenge) {
    return;
  }

  if (challenge.finished === true) {
    // Already scored
    return;
  }

  if (challenge.deals.some((d) => !d.finished)) {
    // Not finished
    return;
  }

  // Timeout in case cloud function is already doing scoring, want it to finish first to avoid double notifications.
  setTimeout(function () {
    void pingChallenge(challenge.id);
  }, 60000);
}

const display = (s, otherDone, our, bonusGrade) => {
  const { t } = useTranslation('translation', { keyPrefix: 'challenge' });
  let content;

  if (s.finished) {
    content = (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ContractDisplay contract={s.contract} size={40} />
          <div
            style={{
              marginLeft: '5px',
              marginRight: '5px',
              height: '14px',
            }}
            className="info"
          >
            {directions[getContractDeclarer(s.contract)] ?? '-'}
          </div>
        </div>
        <div style={{ width: 90 }}>
          <StarRating stars={s.resultGrade} bonus={bonusGrade} size="lg" />
        </div>
      </>
    );
  } else {
    content = <>{t('not_finished')}</>;
  }

  return (
    <div
      style={{
        width: 180,
        display: 'flex',
        flexDirection: our ? 'row-reverse' : 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 3,
      }}
    >
      {content}
    </div>
  );
};

function ExpandedRow({ user, sessionDeal, oppSessionDealId, t }) {
  const { data: opponentSessionDeal } = useQuery({
    queryKey: ['sessionDeal', oppSessionDealId],
    queryFn: async () => await getSessionDeal(oppSessionDealId)
  });

  const opponentBidding = opponentSessionDeal?.bidding
  const opponentFinished = opponentSessionDeal?.finished

  let topHandDirection = 'N';
  let bottomHandDirection = 'S';
  if (sessionDeal && sessionDeal.users.indexOf(user.id) === 0) {
    topHandDirection = 'S';
    bottomHandDirection = 'N';
  }

  return (
    <tr>
      <td colSpan={3}>
        <div className="my-4 -mx-[10px] flex flex-col items-center">
          <Hand
            size={user?.cardSize ?? 'sm'}
            variant={
              user?.cardVariant ??
              (user?.handAsDiagram ? 'diagram' : 'modern')
            }
            showHcp={!(user?.hideHcpCount ?? false)}
            hand={sessionDeal.kibitzing ? sessionDeal.northHandPart : sessionDeal.partnerHandPart}
            direction={topHandDirection}
            order={user?.suitOrder ?? 'default'}
          />
          <div className="w-full max-w-[500px] flex items-center justify-around my-4 px-2">
            <BiddingDisplay
              bidding={sessionDeal.bidding}
              vulnerability={sessionDeal.vulnerability}
              variant={'compact'}
              startWithEast={topHandDirection === 'S'}
            />
            <div className="w-[152px]">
              {opponentFinished && (
                <BiddingDisplay
                  bidding={opponentBidding}
                  vulnerability={sessionDeal.vulnerability}
                  variant={'compact'}
                  startWithEast={topHandDirection === 'S'}
                />
              )}
            </div>
          </div>
          <Hand
            size={user?.cardSize ?? 'sm'}
            variant={
              user?.cardVariant ??
              (user?.handAsDiagram ? 'diagram' : 'modern')
            }
            showHcp={!(user?.hideHcpCount ?? false)}
            hand={sessionDeal.kibitzing ? sessionDeal.southHandPart : sessionDeal.handPart}
            direction={bottomHandDirection}
            order={user?.suitOrder ?? 'default'}
          />
          <Link
            className="btn btn-secondary btn-outline mt-4"
            to={`/session/deal/${sessionDeal.id}`}
          >
            {t('go_to_deal')}
          </Link>
          <div className="divider" />
        </div>
      </td>
    </tr>
  );
}

function KibitzChallenge({
  sessionId,
  challenge,
  firstSession,
  secondSession,
  expandedSessionDeals,
  setExpandedSessionDeals,
  currentUser,
  user,
  t,
}) {
  const { data: sessionDeals } = useQuery({
    queryKey: ['sessionDeals', sessionId],
    queryFn: async () => await getSessionDeals(sessionId)
  });

  if (!challenge.finished) {
    return (
      <div className="page fade-in relative pb-24">
        <Alert text={t('kibitz_ongoing')} />
      </div>
    );
  }

  if (!sessionDeals) {
    return <></>;
  }

  const starsTeam1 = firstSession.reduce(function (stars, d) {
    return stars + d.resultGrade;
  }, 0)

  const starsTeam2 = secondSession.reduce(function (stars, d) {
    return stars + d.resultGrade;
  }, 0)

  return (
    <div className="page fade-in relative pb-24">
      <div className="mt-4 flex w-full flex-col items-center">
        <OtherChallengeResultCard
          user1={challenge.users[0]}
          user2={challenge.users[1]}
          user3={challenge.users[2]}
          user4={challenge.users[3]}
          starsTeam1={starsTeam1}
          starsTeam2={starsTeam2}
          finished={true}
          ranked={challenge.ranked}
          proChallenge={challenge.proChallenge}
          links={true}
        />
      </div>
      <table
        id="session-list"
        className="mt-10 w-full border-collapse rounded-sm md:w-4/5"
      >
        <tbody>
          <AnimatePresence>
            <ChallengeDeals
              firstSession={firstSession}
              secondSession={secondSession}
              sessionDeals={sessionDeals}
              expandedSessionDeals={expandedSessionDeals}
              setExpandedSessionDeals={setExpandedSessionDeals}
              currentUser={currentUser}
              user={user}
              t={t}
            />
          </AnimatePresence>
        </tbody>
      </table>
    </div>
  );
}

function ChallengeDeals({
  firstSession,
  secondSession,
  sessionDeals,
  expandedSessionDeals,
  setExpandedSessionDeals,
  currentUser,
  user,
  t,
}) {
  return firstSession.map((s, i) => {
    if (!s.finished) {
      return (
        <HandCard
          i={i}
          deal={sessionDeals[i]}
          key={i}
          currentUser={currentUser}
        />
      );
    }

    const handleExpandDeal = () => {
      const ourSessionDealId = firstSession[i].id;
      const oldExpanded = expandedSessionDeals[ourSessionDealId] ?? false;
      const newExpanded = !oldExpanded;
      setExpandedSessionDeals((esd) => ({
        ...esd,
        [ourSessionDealId]: newExpanded,
      }));
    }

    const sessionDeal = sessionDeals.find((sd) => sd.id === firstSession[i].id);
    const isExpanded = expandedSessionDeals[firstSession[i].id];

    return (
      <Fragment key={i}>
        <Animated
          element="tr"
          variants={{
            hidden: (i) => ({
              opacity: 0,
              scale: 0,
            }),
            visible: (i) => ({
              opacity: 1,
              scale: 1,
              transition: {
                delay: i * 0.02,
              },
            }),
          }}
          initial="hidden"
          exit="hidden"
          animate="visible"
          custom={i}
          style={{ cursor: 'pointer' }}
          onClick={handleExpandDeal}
        >
          <td colSpan={3}>
            <div className="relative">
              <div
                style={{
                  fontSize: 'x-small',
                  position: 'absolute',
                  top: 5,
                  left: -5,
                }}
              >
                {i + 1}
              </div>
              <div className="flex justify-between items-center">
                {display(s, secondSession[i].finished, true, !sessionDeal.kibitzing && sessionDeal.bonusGrade)}
                {isExpanded ? <ChevronUp /> : <ChevronDown />}
                {display(secondSession[i], s.finished, false)}
              </div>
            </div>
          </td>
        </Animated>
        {isExpanded && (
          <ExpandedRow
            user={user}
            sessionDeal={sessionDeal}
            oppSessionDealId={secondSession[i].id}
            t={t}
          />
        )}
      </Fragment>
    );
  });
}

export default function ChallengeSession() {
  const { t } = useTranslation('translation', { keyPrefix: 'challenge' });
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUser } = useAuth();
  const [challenge, setChallenge] = useState();
  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const challenges = useAppStateStore((state) => state.challenges);
  const sessionDeals = useAppStateStore((state) => state.sessionDeals);
  const challengeDeals = useAppStateStore((state) => state.challengeDeals);
  const isNative = useAppStateStore((state) => state.isNative);
  const user = useAppStateStore((state) => state.user);

  const showChat = useAppStateStore((state) => state.showChat);
  const chatOpen = useAppStateStore((state) => state.chatOpen);

  const [messages, setMessages] = useState([]);
  const [expandedSessionDeals, setExpandedSessionDeals] = useState({});

  const navigate = useNavigate();

  const userIndex = challenge ? challenge.users.indexOf(currentUser.uid) : -1;
  let firstSessionId;
  if (challenge) {
    firstSessionId = [-1,0,1].includes(userIndex) ? challenge.session1 : challenge.session2;
  }

  useEffect(() => {
    if (searchParams && searchParams.get('chatOpen') && !chatOpen) {
      showChat();
    }
  }, [chatOpen, searchParams, showChat]);

  useEffect(function () {
    setSearchParams(
      function (oldParams) {
        oldParams.delete('chatOpen');
        return oldParams;
      },
      { replace: true }
    );
  }, [setSearchParams]);

  const setRead = async () => {
    if (!challenge) {
      return;
    }

    const unreadMessages = getUnreadMessages() ?? 0;
    if (unreadMessages === 0) {
      return;
    }

    const userIndex = challenge.users.indexOf(currentUser.uid);
    await setChatMessagesAsRead(id, userIndex);

    // For challenges in archive which will not be updated in appState
    setChallenge((c) => ({
      ...c,
      [getMyUnreadChatMessagesField(userIndex)]: 0,
    }));
  };

  const getUnreadMessages = () => {
    if (!challenge) {
      return;
    }
    const userIndex = challenge.users.indexOf(currentUser.uid);
    return challenge[getMyUnreadChatMessagesField(userIndex)] ?? 0;
  };

  useEffect(function () {
    if (userIndex === -1) return;

    const appendNewMessage = (message) => {
      setMessages((messages) => {
        return messages.concat(message);
      });
    };

    return getChatMessagesObservable({
      challengeId: id,
      callback: appendNewMessage,
    });
  }, [id, userIndex]);

  useEffect(() => {
    updateAppState({ challengeId: id });
    const chall = challenges?.find((s) => s.id === id);
    if (!chall) {
      getChallenge(id).then((c) => {
        if (c) {
          setChallenge(c);
        } else {
          navigate(`/404${location.pathname}`);
        }
      });
    } else {
      setChallenge(chall);
    }
  }, [id, updateAppState, challenges, navigate]);

  useEffect(function () {
    if (userIndex === -1) return;

    maybePingChallenge(challenge);
  }, [challenge, userIndex]);

  useEffect(() => {
    if (challenge && userIndex !== -1) {
      updateAppState({ sessionId: firstSessionId });
    }
  }, [challenge, currentUser.uid, updateAppState, firstSessionId, userIndex]);

  if (
    !currentUser ||
    !challenge ||
    !challengeDeals ||
    !challengeDeals.deals ||
    challengeDeals.deals.length === 0
  ) {
    return <></>;
  }

  const firstSession = challengeDeals.deals
    .filter((f) => f.sessionId === firstSessionId)
    .sort((a, b) => a.dealNr - b.dealNr);
  const secondSession = challengeDeals.deals
    .filter((f) => f.sessionId !== firstSessionId)
    .sort((a, b) => a.dealNr - b.dealNr);
  const first = firstSessionId === challenge.session1;

  if (firstSession.length !== secondSession.length) {
    return <></>;
  }

  if (challenge && userIndex === -1) {
    return (
      <KibitzChallenge
        sessionId={firstSessionId}
        challenge={challenge}
        firstSession={firstSession}
        secondSession={secondSession}
        sessionDeals={sessionDeals}
        expandedSessionDeals={expandedSessionDeals}
        setExpandedSessionDeals={setExpandedSessionDeals}
        currentUser={currentUser}
        user={user}
        t={t}
      />
    );
  }

  if (userIndex !== -1 && !sessionDeals?.length || sessionDeals[0].sessionId !== firstSessionId) {
    // sessionDeals not loaded or are cached from another session, wait for the new ones to be loaded
    // (not for Kibitzers)
    return <></>;
  }

  const starsTeam1 = firstSession.reduce(
    function (stars, d, i) {
      stars.all += d.finished ? d.resultGrade : 0;
      stars.oppFinished +=
        d.finished && secondSession[i].finished ? d.resultGrade : 0;
      return stars;
    },
    { all: 0, oppFinished: 0 }
  );

  const [ourTeam, opps] = challenge?.team1Users?.includes(currentUser.uid)
    ? [challenge.team1Users, challenge.team2Users]
    : [challenge.team2Users, challenge.team1Users];

  const partner = ourTeam?.[0] === currentUser.uid ? ourTeam?.[1] : ourTeam?.[0]
  const opp1 = opps?.[0]
  const opp2 = opps?.[1]


  return (
    <>
      <div className="page fade-in relative pb-36">
        <div className="mt-4 flex w-full flex-col items-center">
          <ChallengeResultCard
            links={true}
            user1={challenge.users[first ? 0 : 2]}
            user2={challenge.users[first ? 1 : 3]}
            user3={challenge.users[first ? 2 : 0]}
            user4={challenge.users[first ? 3 : 1]}
            starsTeam1={starsTeam1.oppFinished}
            starsTeam1All={starsTeam1.all}
            starsTeam2={secondSession.reduce(function (stars, d, i) {
              return (
                stars +
                (d.finished && firstSession[i].finished
                  ? d.resultGrade
                  : 0)
              );
            }, 0)}
            finished={challenge.finished}
            ranked={challenge.ranked}
            proChallenge={challenge.proChallenge}
          />
          {
            challenge.finished && !challenge.ranked && (
              <div className={'mt-2'}>
                <Link to={`/challengeSession?pd=${partner}&o1=${opp1}&o2=${opp2}`} className="btn btn-primary">{t('rematch')}</Link>
              </div>
            )
          }
          {!challenge.finished && challenge.ranked && (
            <WhenToFinishText timestamp={challenge.timestamp} />
          )}
          <table
            id="session-list"
            className="mt-10 w-full border-collapse rounded-sm md:w-4/5"
          >
            <tbody>
              <AnimatePresence>
                <ChallengeDeals
                  firstSession={firstSession}
                  secondSession={secondSession}
                  sessionDeals={sessionDeals}
                  expandedSessionDeals={expandedSessionDeals}
                  setExpandedSessionDeals={setExpandedSessionDeals}
                  currentUser={currentUser}
                  user={user}
                  t={t}
                />
              </AnimatePresence>
            </tbody>
          </table>
        </div>
      </div>
      {challenge && (
        <ChatDrawer
          usersForHeader={challenge.users}
          userId={currentUser.uid}
          messages={messages}
          setRead={setRead}
          unreadMessagesCount={getUnreadMessages()}
          totalCount={messages.length}
          sendMessage={function (message) {
            if (challenge) {
              void sendChatMessage(
                id,
                message,
                challenge.users.indexOf(currentUser.uid)
              );
              return true;
            }
          }}
          isNative={isNative}
          bottomNavigationVisible={true}
        />
      )}
    </>
  );
}
