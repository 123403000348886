import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import BiddingDisplayPrint
  from '../../components/bidding/biddingDisplayPrint.jsx';
import HandDiagram from '../../components/cards/diagram.jsx';
import { useEffect } from 'react';
import Logo from '../../images/logos/logo512.png';
import {
  getSessionDealsInSession,
  getSession,
} from 'cuebids-firebase/bidding';
import { getPublicUserAsync } from '../../firebase/subscriptions.js';
import './print.css';

function themeChange() {
  document.documentElement.setAttribute('data-theme', 'light');
}

export default function Pdf() {
  const { sessionId } = useParams();

  useEffect(() => {
    themeChange();
  }, []);


  const { isLoading: sessionLoading, data: session } = useQuery({
    queryKey: ['session-pdf', sessionId],
    queryFn: async () => await getSession(sessionId),
    refetchOnWindowFocus: false,
  });


  const { isLoading: dealsLoading, data: deals } = useQuery({
    queryKey: ['session-deals-pdf', sessionId],
    queryFn: async () => await getSessionDealsInSession(sessionId)
  });


  if (sessionLoading || dealsLoading) return <div>Loading...</div>
  if (!session) return <div>No session found</div>
  if (deals.length === 0) return <div>No deals in session</div>
  if (!session.print) return <div>Not requested for print</div>
  if (session.numberOfFinishedDeals < session.dealsCount) return <div>Not all deals are finished</div>

  const showEW = session.compete === 2;


  return <div id={'pdf'} className={'p-4 bg-white w-full justify-center pb-12'} data-theme={'light'}>
    <article className={'flex items-end'}>
      <img src={Logo} className={'w-[100px]'} alt={'Cuebids.com'}/>
      <div className={'flex flex-col items-start ml-4 mb-2'}>
        <h1 className={'text-2xl text-center'}>Session</h1>
        <a href={`https://cuebids.com/session/${session.id}`}><h4 className={'text-md text-center underline underline-offset-2 decoration-primary'}>{sessionId}</h4></a>
      </div>
    </article>

    {
      !showEW && <div className={'flex justify-center info'}>East and West cards are hidden when the session was played with basic or no robots.</div>
    }

    <div className={'flex flex-col gap-24 mt-8'}>
      {
        deals
          .sort((a, b) => a.dealNumber - b.dealNumber)
          .map((sessionDeal, index) => {
            return <div key={sessionDeal.id} >
              <PdfSessionDeal sessionDeal={sessionDeal}/>
              {
                index % 2 === 1 && index !== deals.length - 1 && <div className={'page-break'} />
              }
            </div>
          })
      }
    </div>

  </div>
}

function PdfSessionDeal({ sessionDeal }) {
  const { hand, dealer, vulnerability, users, bidding, compete } = sessionDeal;

  const showEW = compete === 2;
  const north = users[0];
  const south = users[1];

  const { data: northName } = useQuery({
    queryKey: ['user', north],
    queryFn: async () => await getPublicUserAsync( north ),
  });

  const { data: southName } = useQuery({
    queryKey: ['user', south],
    queryFn: async () => await getPublicUserAsync( south ),
  });

  return (
    <div>
      <article className={'flex items-end'}>
        <div className={'flex flex-col items-start ml-4 mb-2'}>
          <h4 className={'text-md text-center'}>{sessionDeal.dealNumber}</h4>
        </div>
      </article>

      <div className={'flex justify-around'}>
        <div className={'row-span-2 col-span-2 flex justify-center'}>
          <HandDiagram hand={hand} dealer={dealer} showEW={showEW}
            vulnerability={vulnerability}/>
        </div>
        <div className={'border-2 rounded-xl p-2'}>
          <BiddingDisplayPrint
            bidding={bidding}
            vulnerability={vulnerability}
            north={northName?.displayName ?? 'North'}
            south={southName?.displayName ?? 'South'}
          />
        </div>

      </div>
    </div>
  )
}
