import { ranges } from './constants';

const balance = [
  {
    biddingMatch: /^(.*)-1C-P-P$/,
    explain:
      'I am in passout seat after opps have a low part-score club contract, take out?',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxC: 3,
        reqH: 3,
        reqS: 3,
        reqD: 3,
        reqHp: 6,
        reqMaxS: 4,
        reqMaxH: 4,
        reqMaxD: 5,
        reqMaxRobotBidCount: 1,
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^(.*)-2C-P-P$/,
    explain:
      'I am in passout seat after opps have a low part-score club contract, take out?',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxC: 2,
        reqH: 3,
        reqS: 3,
        reqD: 3,
        reqHp: 8,
        reqMaxS: 4,
        reqMaxH: 4,
        reqMaxD: 5,
        reqMaxRobotBidCount: 0,
        reqMaxBiddingRound: 2,
        weight: 0.1,
      },
      {
        bid: 'D["Takeout double"]',
        reqMaxC: 2,
        reqH: 3,
        reqS: 3,
        reqD: 3,
        reqHp: 10,
        reqMaxS: 4,
        reqMaxH: 4,
        reqMaxD: 5,
        reqMaxRobotBidCount: 1,
        reqMaxBiddingRound: 2,
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^(.*)-1D-P-P$/,
    explain:
      'I am in passout seat after opps have a low part-score diamond contract, take out?',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxD: 3,
        reqH: 3,
        reqS: 3,
        reqC: 3,
        reqHp: 6,
        reqMaxS: 4,
        reqMaxH: 4,
        reqMaxC: 5,
        reqMaxRobotBidCount: 1,
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^(.*)-2D-P-P$/,
    explain:
      'I am in passout seat after opps have a low part-score diamond contract, take out?',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxD: 2,
        reqH: 3,
        reqS: 3,
        reqC: 3,
        reqHp: 8,
        reqMaxS: 4,
        reqMaxH: 4,
        reqMaxC: 5,
        reqMaxRobotBidCount: 0,
        reqMaxBiddingRound: 2,
        weight: 0.1,
      },
      {
        bid: 'D["Takeout double"]',
        reqMaxD: 2,
        reqH: 3,
        reqS: 3,
        reqC: 3,
        reqHp: 10,
        reqMaxS: 4,
        reqMaxH: 4,
        reqMaxC: 5,
        reqMaxRobotBidCount: 1,
        reqMaxBiddingRound: 2,
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^(.*)-1H-P-P$/,
    explain:
      'I am in passout seat after opps have a low part-score heart contract, take out?',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxH: 2,
        reqD: 3,
        reqS: 4,
        reqC: 3,
        reqHp: 6,
        reqMaxS: 4,
        reqMaxD: 5,
        reqMaxC: 5,
        reqMaxRobotBidCount: 1,
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^(.*)-2H-P-P$/,
    explain:
      'I am in passout seat after opps have a low part-score heart contract, take out?',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxH: 2,
        reqD: 3,
        reqS: 4,
        reqC: 3,
        reqHp: 9,
        reqMaxS: 4,
        reqMaxD: 5,
        reqMaxC: 5,
        reqMaxRobotBidCount: 0,
        reqMaxBiddingRound: 2,
        weight: 0.1,
      },
      {
        bid: 'D["Takeout double"]',
        reqMaxH: 2,
        reqD: 3,
        reqS: 4,
        reqC: 3,
        reqHp: 11,
        reqMaxS: 4,
        reqMaxD: 5,
        reqMaxC: 5,
        reqMaxRobotBidCount: 1,
        reqMaxBiddingRound: 2,
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^(.*)-1S-P-P$/,
    explain:
      'I am in passout seat after opps have a low part-score spade contract, take out?',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxS: 2,
        reqH: 4,
        reqC: 3,
        reqHp: 6,
        reqMaxD: 5,
        reqMaxH: 4,
        reqMaxC: 5,
        reqMaxRobotBidCount: 1,
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^(.*)-2S-P-P$/,
    explain:
      'I am in passout seat after opps have a low part-score spade contract, take out?',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxS: 2,
        reqH: 4,
        reqC: 3,
        reqHp: 9,
        reqMaxD: 5,
        reqMaxH: 4,
        reqMaxC: 5,
        reqMaxRobotBidCount: 0,
        reqMaxBiddingRound: 2,
        weight: 0.1,
      },
      {
        bid: 'D["Takeout double"]',
        reqMaxS: 2,
        reqH: 4,
        reqC: 3,
        reqHp: 11,
        reqMaxD: 5,
        reqMaxH: 4,
        reqMaxC: 5,
        reqMaxRobotBidCount: 1,
        reqMaxBiddingRound: 2,
        weight: 0.1,
      },
    ],
  },

  // response to balance TO

  {
    biddingMatch: /^(.*)-[1-2]C-P-P-D-[PR]$/,
    explain:
      'Partner balanced with a take out over clubs, my response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqC: 5,
        reqHp: 6,
        reqMaxD: 3,
        reqMaxH: 3,
        reqMaxS: 3,
      },
      {
        bid: '1D',
        reqD: 4,
        reqStrains: function(strains) {
          return strains.D > strains.S && strains.D > strains.H;
        },
      },
      {
        bid: '1D',
        weight: 0.1, // Fallback bid
      },
      {
        bid: '2D',
        weight: 0.1, // Fallback bid
      },
      {
        bid: '1H',
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.D;
        },
      },
      {
        bid: '1S',
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.D;
        },
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxH: 3,
        reqMaxS: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '2D',
        reqD: 4,
        reqMaxH: 3,
        reqMaxS: 3,
      },
      {
        bid: '2H',
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.D;
        },
      },
      {
        bid: '2S',
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.D;
        },
      },
    ],
  },
  {
    biddingMatch: /^(.*)-[1-2]D-P-P-D-[PR]$/,
    explain:
      'Partner balanced with a take out over diamonds, my response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqD: 5,
        reqHp: 6,
        reqMaxC: 3,
        reqMaxH: 3,
        reqMaxS: 3,
      },
      {
        bid: '2C',
        weight: 0.1, // Fallback bid
      },
      {
        bid: '3C',
        weight: 0.1, // Fallback bid
      },
      {
        bid: '1H',
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.C;
        },
      },
      {
        bid: '1S',
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.C;
        },
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxH: 3,
        reqMaxS: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '2H',
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.C;
        },
      },
      {
        bid: '2S',
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.C;
        },
      },
    ],
  },

  {
    biddingMatch: /^(.*)-[1-2]H-P-P-D-[PR]$/,
    explain:
      'Partner balanced with a take out over hearts, my response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqH: 5,
        reqHp: 6,
        reqMaxD: 3,
        reqMaxC: 3,
        reqMaxS: 3,
      },
      {
        bid: '2C',
        weight: 0.1, // Fallback bid
      },
      {
        bid: '3C',
        weight: 0.1, // Fallback bid
      },
      {
        bid: '1S',
        reqS: 3,
        reqStrains: function(strains) {
          return strains.S >= strains.D && strains.S >= strains.C;
        },
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxS: 3,
        reqMaxC: 4,
        reqMaxD: 4,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '2C',
        reqC: 3,
        reqStrains: function(strains) {
          return strains.C > strains.S && strains.C >= strains.D;
        },
      },
      {
        bid: '2D',
        reqD: 4,
        reqStrains: function(strains) {
          return strains.D > strains.S && strains.D >= strains.C;
        },
      },
      {
        bid: '3C',
        reqC: 4,
        reqStrains: function(strains) {
          return strains.C > strains.S && strains.C >= strains.D;
        },
      },
      {
        bid: '3D',
        reqD: 4,
        reqStrains: function(strains) {
          return strains.D > strains.S && strains.D > strains.C;
        },
      },
      {
        bid: '2S',
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.C && strains.S >= strains.D;
        },
      },
    ],
  },
  {
    biddingMatch: /^(.*)-[1-2]S-P-P-D-[PR]$/,
    explain:
      'Partner balanced with a take out over spades, my response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqS: 5,
        reqHp: 6,
        reqMaxD: 3,
        reqMaxC: 3,
        reqMaxH: 3,
      },
      {
        bid: '2C',
        weight: 0.1, // Fallback bid
      },
      {
        bid: '3C',
        weight: 0.1, // Fallback bid
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxH: 3,
        reqMaxC: 4,
        reqMaxD: 4,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '2C',
        reqC: 3,
        reqStrains: function(strains) {
          return strains.C > strains.H && strains.C >= strains.D;
        },
      },
      {
        bid: '2D',
        reqD: 4,
        reqStrains: function(strains) {
          return strains.D > strains.H && strains.D >= strains.C;
        },
      },
      {
        bid: '2H',
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H >= strains.C && strains.H >= strains.D;
        },
      },
      {
        bid: '3C',
        reqC: 4,
        reqStrains: function(strains) {
          return strains.C > strains.H && strains.C >= strains.D;
        },
      },
      {
        bid: '3D',
        reqD: 4,
        reqStrains: function(strains) {
          return strains.D > strains.H && strains.D > strains.C;
        },
      },
      {
        bid: '3H',
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H >= strains.C && strains.H >= strains.D;
        },
      },
    ],
  },

  // passout ?
  {
    biddingMatch: /^(.*)-(\d\w)-P-P$/,
    explain: 'I am in passout seat after opps have a contract',
    options: [
      {
        bid: '1D["5+!D"]',
        reqD: 5,
        reqHp: 5,
        reqMaxRobotBidCount: 1,
        weight: 0.1,
      },
      {
        bid: '1H["5+!H"]',
        reqH: 5,
        reqHp: 5,
        reqMaxRobotBidCount: 1,
        weight: 0.1,
      },
      {
        bid: '1S["5+!S"]',
        reqS: 5,
        reqHp: 5,
        reqMaxRobotBidCount: 1,
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*-1[CDHS]-P-P$/,
    explain: 'I am in passout seat',
    options: [
      {
        bid: '1N["11-16, does not promise stopper"]',
        reqS: 2,
        reqH: 2,
        reqD: 2,
        reqC: 2,
        reqMaxS: 4,
        reqMaxH: 4,
        reqMaxD: 4,
        reqMaxC: 4,
        reqHp: 11,
        reqMaxHp: 16,
        reqMaxRobotBidCount: 0,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*-1[CDH]-1[CDHS]-P-P$/,
    explain: 'I am in passout seat ',
    options: [
      {
        bid: '1N["18-19, does not promise stopper"]',
        reqS: 2,
        reqH: 2,
        reqD: 2,
        reqC: 2,
        reqMaxS: 4,
        reqMaxH: 4,
        reqMaxD: 4,
        reqMaxC: 4,
        reqHp: 18,
        reqMaxHp: 19,
        reqMaxRobotBidCount: 1,
      },
    ],
  },
  {
    biddingMatch: /^(.*)-(\d\w)-P-P-1N-[PD]$/,
    explain: 'Partner balanced with 1N, should I bid a suit?',
    options: [
      {
        bid: '2C["To play"]',
        reqC: 5,
      },
      {
        bid: '2D["To play"]',
        reqD: 5,
      },
      {
        bid: '2H["To play"]',
        reqH: 5,
      },
      {
        bid: '2S["To play"]',
        reqS: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*-1[DHS]-P-P$/,
    explain: 'I am in passout seat after not having passed',
    options: [
      {
        bid: '2C["5+!C"]',
        reqC: 5,
        reqHp: 9,
      },
      {
        bid: '2C["5+!C"]',
        reqC: 6,
        reqHp: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*-(1[HS]|2C)-P-P$/,
    explain: 'I am in passout seat after not having passed',
    options: [
      {
        bid: '2D["5+!D"]',
        reqD: 5,
        reqHp: 9,
      },
      {
        bid: '2D["5+!D"]',
        reqD: 6,
        reqHp: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*-(1S|2[CD])-P-P$/,
    explain: 'I am in passout seat after not having passed',
    options: [
      {
        bid: '2H["5+!H"]',
        reqH: 5,
        reqHp: 9,
      },
      {
        bid: '2H["5+!H"]',
        reqH: 6,
        reqHp: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*-2[CDH]-P-P$/,
    explain: 'I am in passout seat after not having passed',
    options: [
      {
        bid: '2S["5+!S"]',
        reqS: 5,
        reqHp: 9,
      },
      {
        bid: '2S["5+!S"]',
        reqS: 6,
        reqHp: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*-2[DHS]-P-P$/,
    explain: 'I am in passout seat after not having passed',
    options: [
      {
        bid: '3C["5+!C"]',
        reqC: 5,
        reqHp: 11,
      },
      {
        bid: '3C["5+!C"]',
        reqC: 6,
        reqHp: 8,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*-(2[HS]|3C)-P-P$/,
    explain: 'I am in passout seat after not having passed',
    options: [
      {
        bid: '3D["5+!D"]',
        reqD: 5,
        reqHp: 11,
      },
      {
        bid: '3D["5+!D"]',
        reqD: 6,
        reqHp: 8,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*-(2S|3[CD])-P-P$/,
    explain: 'I am in passout seat after not having passed',
    options: [
      {
        bid: '3H["5+!H"]',
        reqH: 5,
        reqHp: 11,
      },
      {
        bid: '3H["5+!H"]',
        reqH: 6,
        reqHp: 8,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*-3[CDH]-P-P$/,
    explain: 'I am in passout seat after not having passed',
    options: [
      {
        bid: '3S["5+!S"]',
        reqS: 5,
        reqHp: 11,
      },
      {
        bid: '3S["5+!S"]',
        reqS: 6,
        reqHp: 8,
      },
    ],
  },
];

export default balance;
