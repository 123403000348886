export function scenarioToDirections(scenario) {
    const directions = scenario.directions;
    const directionsToUse = {};
    if (directions.north != null && directions.north.filter(h => h.constraints != null).length > 0) {
        directionsToUse.north = { hand_types: directions.north.filter(h => h.constraints != null) };
    }
    if (directions.east != null && directions.east.filter(h => h.constraints != null).length > 0) {
        directionsToUse.east = { hand_types: directions.east.filter(h => h.constraints != null) };
    }
    if (directions.south != null && directions.south.filter(h => h.constraints != null).length > 0) {
        directionsToUse.south = { hand_types: directions.south.filter(h => h.constraints != null) };
    }
    if (directions.west != null && directions.west.filter(h => h.constraints != null).length > 0) {
        directionsToUse.west = { hand_types: directions.west.filter(h => h.constraints != null) };
    }
    directionsToUse.settings = {};
    if (directions.settings != null) {
        // Note: Changes to settings are also done in saveNewScenario and updateScenario
        if (directions.settings.ai != null) {
            directionsToUse.settings.ai = directions.settings.ai;
        }
        if ((directions.settings?.dealer ?? 'any') !== 'any') {
            directionsToUse.settings.dealer = directions.settings.dealer;
        }
        if (directions.settings.nsHcpRange != null) {
            directionsToUse.settings.ns_hp_sum = directions.settings.nsHcpRange;
        }
        if (directions.settings.disableRobots != null) {
            directionsToUse.settings.disable_robots = directions.settings.disableRobots;
        }
        if (directions.settings.aiSystem != null) {
            directionsToUse.settings.ai_system = directions.settings.aiSystem;
        }
        if ((directions.settings?.vulnerability ?? 'any') !== 'any') {
            directionsToUse.settings.vulnerability = directions.settings.vulnerability;
        }
        const requestSettingsVariables = {};
        Object.keys(directions.settings.variables ?? {}).forEach(function (variable) {
            const suits = directions.settings?.variables?.[variable] ?? [];
            if (suits.length > 0) {
                requestSettingsVariables[variable] = suits;
            }
        });
        if (Object.keys(requestSettingsVariables).length > 0) {
            directionsToUse.settings.variables = requestSettingsVariables;
        }
        directionsToUse.settings.flip = directions.settings.flip ?? false;
    }
    const commands = (directions.east ?? []).concat(directions.west ?? []).filter(h => h.command != null);
    if (commands.length > 0) {
        directionsToUse.settings.ai = {
            command: commands[0].command,
            premise: commands[0].premise ?? null
        };
    }
    return directionsToUse;
}
export function directionAndSettingsToDirections(directions, settings) {
    const settingsToUse = { ...settings };
    if (settingsToUse.dealer === 'any') {
        delete settingsToUse.dealer;
    }
    if (settingsToUse.vulnerability === 'any') {
        delete settingsToUse.vulnerability;
    }
    if (settingsToUse.robots === 'NONE') {
        settingsToUse.disableRobots = true;
        delete settingsToUse.aiSystem;
    }
    else if (settingsToUse.robots === 'MULTI') {
        settingsToUse.aiSystem = 'multi';
        delete settingsToUse.disableRobots;
    }
    else if (settingsToUse.robots === 'MINI_NT') {
        settingsToUse.aiSystem = 'miniNT';
        delete settingsToUse.disableRobots;
    }
    else {
        // ADVANCED robots
        delete settingsToUse.disableRobots;
        delete settingsToUse.aiSystem;
    }
    return {
        ...directions,
        settings: settingsToUse
    };
}
export function compareConstraints(a, b) {
    return deepEqual(a, b);
}
// TODO: Some tests?
function deepEqual(obj1, obj2) {
    // Check if the types of the objects are the same
    if (typeof obj1 !== typeof obj2) {
        return false;
    }
    // Check if the objects are arrays
    if (Array.isArray(obj1)) {
        if (!Array.isArray(obj2) || obj1.length !== obj2.length) {
            return false;
        }
        // Deep compare each element in the array
        for (let i = 0; i < obj1.length; i++) {
            if (!deepEqual(obj1[i], obj2[i])) {
                return false;
            }
        }
        return true;
    }
    // Check if the objects are null
    if (obj1 === null || obj2 === null) {
        return obj1 === obj2;
    }
    // Check if the objects are primitive types
    if (typeof obj1 === 'string' ||
        typeof obj1 === 'number' ||
        typeof obj1 === 'boolean') {
        return obj1 === obj2;
    }
    // Check if the objects have the same keys
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length || !keys1.every(key => keys2.includes(key))) {
        return false;
    }
    // Deep compare each property in the objects
    for (const key of keys1) {
        if (!deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }
    return true;
}
// Example usage:
// const obj1 = {
//   a: 1,
//   b: "hello",
//   c: [1, 2, 3],
//   d: {
//     e: true,
//     f: [4, 5, 6]
//   }
// };
//
// const obj2 = {
//   a: 1,
//   b: "hello",
//   c: [1, 2, 3],
//   d: {
//     e: true,
//     f: [4, 5, 6]
//   }
// };
//
// console.log(deepEqual(obj1, obj2)); // Output: true
