
export default function Heart({ width = 12, dark = false }) {
  return (
    <svg
      width={width}
      height={width * (22 / 19)}
      viewBox="0 0 19 22"
      className={ dark ? 'fill-heart-100' : 'hearts'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5419 0.872172C9.97833 0.926949 9.26988 4.98408 9.26988 4.98408C9.26988 4.98408 8.44753 0.926949 4.88391 0.872172C1.32021 0.817308 -0.872865 3.94237 0.333354 7.23193C1.53949 10.5214 2.74127 11.2616 4.63498 13.6465C6.52861 16.0314 9.28362 20.1571 9.26988 21.1302C9.25623 20.1571 11.8972 16.0314 13.7909 13.6465C15.6846 11.2616 16.8864 10.5214 18.0925 7.23193C19.2986 3.94237 17.1057 0.817308 13.5419 0.872172Z"
      />
    </svg>
  );
}
