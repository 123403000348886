import { useRef } from 'react';

export default function Dialog({
  id,
  open,
  children,
  header,
  title,
  noClose = false,
  onClose,
  actions = [],
  sx = '',
  bodySx = 'p-4',
}) {
  const toggle = useRef(null);

  function handleClose() {
    if (open) {
      onClose?.();
    }
  }

  function handleClickAnything() {
    toggle.current.click();
  }

  return (
    <>
      <input
        readOnly
        type="checkbox"
        ref={toggle}
        checked={open}
        id={id}
        className="modal-toggle"
      />
      <div className={'modal modal-middle p-0'} onClick={handleClose}>
        <div
          onClick={function (e) {
            e.stopPropagation();
          }}
          tabIndex={0}
          className={
            'modal-box relative overflow-x-hidden border border-solid border-0 shadow-black p-0' + (sx ? ' ' + sx : '')
          }
        >
          <div className="sticky z-[9999999] top-0 left-0 pt-2 flex w-full justify-between bg-inherit bg-primary py-2">
            <div className="w-20"></div>
            {header ? (
              <div className="w-full">{header}</div>
            ) : (
              title && (
                <h3 className="flex grow min-w-[200px] justify-center text-lg font-bold text-white">
                  {title}
                </h3>
              )
            )}

            <div className="-mt-2 flex w-20 justify-end">
              {
                !noClose &&
                    <label
                      tabIndex={0}
                      className="btn-error w-7 btn-sm btn h-7"
                      onClick={handleClose}
                      htmlFor={id}
                    >
                    X
                    </label>
              }
            </div>

          </div>
          <div className={`${bodySx}`}>{children}</div>
          <div className="mb-2 flex w-full justify-end gap-4 px-2">
            {actions.map((b, i) => {
              return (
                <div
                  tabIndex={0}
                  key={i}
                  onClick={handleClickAnything}
                  className={b.style}
                >
                  {b.component}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
