import { UserDisplaySmall, UserDisplaySmallRight } from './userDisplay'
import './pairDisplay.css';
import { Link } from 'react-router-dom';

export const LinkedPairDisplay = ({ uid1, uid2, reverse = false }) => {
  return (
    <Link to={'/pair/' + uid1 + '/' + uid2}>
      <PairDisplay uid1={uid1} uid2={uid2} reverse={reverse} />
    </Link>
  );
};

export default function PairDisplay({ uid1, uid2, reverse = false }) {
  return (
    <div className="pair-display">
      {reverse ? <UserDisplaySmallRight uid={uid1} /> : <UserDisplaySmall uid={uid1} />}
      <div className="partner">
        {reverse ? <UserDisplaySmallRight uid={uid2} /> : <UserDisplaySmall uid={uid2} />}
      </div>
    </div>
  );
}
