import { useState } from 'react';
import Dropdown from '../../components/dropdown/dropdown';
import { submitFeedback } from '../../firebase/feedback';
import { DiscordIcon } from '../../components/icons/discordIcon';
import { Link } from 'react-router-dom';
import Alert from '../../components/alert/alert';
import AdminMessage from '../../components/AdminMessage';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { Trans, useTranslation } from 'react-i18next'

const category_keys = ['category_bug', 'category_feature_request', 'category_feedback', 'category_other'];

export default function Contact() {
  const [category, setCategory] = useState(2);
  const [message, setMessage] = useState('');
  const [contact, setContact] = useState('');

  const { t } = useTranslation('translation', { keyPrefix: 'contact' });

  const notify = useNotifications();

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleChangeContact = (e) => {
    setContact(e.target.value);
  };

  const handleSubmitFeedback = async () => {
    await submitFeedback({ category: t(category_keys[category]), message, contact });
    setMessage('');
    setContact('');
    notify({ text: t('notification_success'), type: 'success' });
  };

  return (
    <div className="page p-4 pb-24">
      <AdminMessage />
      <Link to={'/junior'} className="flex justify-center">
        <Alert
          title={t('junior_free_title')}
          text={
            <span>
               <Trans
                 i18nKey="junior_free_text"
                 t={t}
               />
            </span>
          }
        />
      </Link>
      <div className="flex w-full justify-center mt-4">
        <div className="flex w-1/2 justify-center">
          <Dropdown
            value={category}
            label={t('category_dropdown_label')}
            options={category_keys.map((c, i) => {
              return {
                label: t(c),
                value: i,
                component: (
                  <button
                    className="btn-ghost btn justify-start"
                    onClick={() => setCategory(i)}
                  >
                    {t(c)}
                  </button>
                ),
              };
            })}
          />
        </div>
      </div>
      <div className="form-control w-full md:w-4/5">
        <label className="label">
          <span className="label-text">{t('message_label')}</span>
        </label>
        <textarea
          value={message}
          onChange={handleChangeMessage}
          rows="4"
          autoFocus
          required
          placeholder={t('message_placeholder')}
          className="input mb-2 h-auto p-2"
        />
      </div>
      <div className="form-control w-full md:w-4/5">
        <label className="label">
          <span className="label-text">{t('contact_details_label')}</span>
        </label>
        <input
          value={contact}
          onChange={handleChangeContact}
          type="text"
          required
          placeholder={t('contact_details_placeholder')}
          className="input"
        />
      </div>
      <button
        className="btn-primary btn-wide btn mt-10"
        disabled={!message}
        onClick={handleSubmitFeedback}
      >
        {t('submit', {category: t(category_keys[category])})}
      </button>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <span className="body-text" style={{ fontSize: '16px' }}>
          {t('contact_us')}
          <a
            href="mailto:support@cuebids.com"
            style={{ textDecoration: 'underline' }}
          >
            support@cuebids.com
          </a>
        </span>
        <span className="body-text">{t('or')}</span>
        <a href="https://discord.gg/U75379cD3G?:ext" target="_system">
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              paddingRight: "12px",
              paddingLeft: "12px",
              alignItems: 'center',
              backgroundColor: '#5865f2',
              borderRadius: 5,
              cursor: 'pointer',
              color: 'white',
            }}
          >
            <DiscordIcon height={50} width={50} />
            <span style={{marginLeft: "16px"}}>{t('join_discord')}</span>
          </div>
        </a>
      </div>
    </div>
  );
}
