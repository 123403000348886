import { useState } from 'react';
import { submitApplication } from '../../firebase/feedback';
import { DiscordIcon } from '../../components/icons/discordIcon';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'
export default function JuniorApply() {
  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [dob, setDob] = useState('');

  const { t } = useTranslation('translation', { keyPrefix: 'contact_junior' });

  const notify = useNotifications();

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleSetCountry = (e) => {
    setCountry(e.target.value);
  };

  const handleChangeDob = (e) => {
    setDob(e.target.value);
  };

  const handleSubmitApplication = async () => {
    try {
      await submitApplication({
        category: t('category'),
        name,
        dob,
        country,
      });
      setName('');
      setDob('');
      setCountry('');
      notify(
        { text: t('notification_success'), type: 'success' },
      );
    } catch (e) {
      notify({ text: t('notification_error'), type: 'failure' });
    }
  };

  return (
    <div className="page">
      <div>
        <div className="mt-8" />
        {t('info')}
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">{t('name_label')}</span>
          </label>
          <input
            value={name}
            onChange={handleChangeName}
            type="text"
            autoFocus
            required
            placeholder={t('name_placeholder')}
            className="input"
          />
        </div>
        <div className="mt-4" />
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">{t('country_label')}</span>
          </label>
          <input
            value={country}
            onChange={handleSetCountry}
            type="text"
            required
            placeholder={t('country_placeholder')}
            className="input"
          />
        </div>
        <div className="mt-4" />
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">{t('birthyear_label')}</span>
          </label>
          <input
            value={dob}
            onChange={handleChangeDob}
            placeholder={t('birthyear_placeholder')}
            type={'number'}
            required
            className="input"
          />
        </div>
      </div>
      <button
        className="btn-primary btn"
        style={{ marginTop: 20 }}
        disabled={!name || !dob || !country}
        onClick={handleSubmitApplication}
      >
        {t('submit')}
      </button>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <span className="body-text" style={{ fontSize: '16px' }}>
          {t('contact_us')}
          <a
            href="mailto:support@cuebids.com"
            style={{ textDecoration: 'underline' }}
          >
            support@cuebids.com
          </a>
        </span>
        <span className="body-text">{t('or')}</span>
        <a href="https://discord.gg/U75379cD3G?:ext" target="_system">
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              paddingRight: "12px",
              paddingLeft: "12px",
              alignItems: 'center',
              backgroundColor: '#5865f2',
              borderRadius: 5,
              cursor: 'pointer',
              color: 'white',
            }}
          >
            <DiscordIcon height={50} width={50}/>
            <span style={{ marginLeft: "16px" }}>{t('join_discord')}</span>
          </div>
        </a>
      </div>
    </div>
  );
}
