import { useState, useEffect } from 'react';
import { useAuth } from '../../util/hooks.jsx';
import { AnimatePresence } from 'framer-motion';
import SessionCard from '../../components/session/sessionCard';
import {
  sessionsBeforeTimestampLimit,
  getSessionsBeforeTimestamp,
  deleteSession,
} from '../../firebase/biddingSessions';
import { EventIcon } from '../../components/icons/dailyIcon';
import Dialog from '../../components/dialog';
import { useAppStateStore } from '../../appStateStore.jsx';
import Alert from '../../components/alert/alert.jsx'

function createSessionsFilter({ userId, showCompleted }) {
  return function sessionsFilter (s) {
    if (showCompleted) return true;

    const {
      dealsCount,
      northToAct,
      southToAct,
      numberOfFinishedDeals,
      users,
      northToRead,
      southToRead,
    } = s;
    const userIndex = users.indexOf(userId);
    const yourTurn = [northToAct, southToAct][userIndex];
    const unreadMessages = [northToRead, southToRead][
      userIndex
    ];
    const isFinished = numberOfFinishedDeals === dealsCount;

    return yourTurn || (unreadMessages > 0) || !isFinished;
  }
}

async function getMore({ timestamp, showCompleted, userId, sessions = [], numberRequested = 0 }) {
  numberRequested += sessionsBeforeTimestampLimit;

  const moreSessions = await getSessionsBeforeTimestamp({ timestamp });
  if (!moreSessions) {
    return {
      sessions,
      numberRequested,
    };
  }

  sessions = sessions.concat(moreSessions);

  if (moreSessions.length < sessionsBeforeTimestampLimit) {
    return {
      sessions,
      numberRequested,
    };
  }

  const sessionsFilter = createSessionsFilter({ userId, showCompleted });
  if (moreSessions.filter(sessionsFilter).length === 0) {
    return getMore({
      showCompleted,
      userId,
      sessions,
      numberRequested,
      timestamp: sessions.length
        ? sessions[sessions.length - 1].timestamp
        : Date.now()
    })
  }

  return {
    sessions,
    numberRequested,
  };
}

export default function SessionArchivePage() {
  const { currentUser } = useAuth();
  const sessionId = useAppStateStore((state) => state.sessionId);
  const showCompleted = useAppStateStore((state) => state.showCompleted);
  const appStateSessions = useAppStateStore((state) => state.sessions);
  const [sessions, setSessions] = useState(appStateSessions || []);
  const [numberOfSessionsRequested, setNumberOfSessionsRequested] = useState(
    sessions.length
  );
  const [loading, setLoading] = useState(false);

  const [deleteSessionDialog, setDeleteSessionDialog] = useState(false);

  const handleDelete = (e, s) => {
    if (e.stopPropagation) e.stopPropagation();
    setDeleteSessionDialog(s);
  };

  const handleClose = () => {
    setDeleteSessionDialog(false);
  };

  const handleConfirmDeleteSession = async (session) => {
    await deleteSession(session.id);
    setSessions((sessions) => sessions.filter((s) => s.id !== session.id));
    setDeleteSessionDialog(false);
  };

  const lastSession = sessionId;

  async function loadMoreSessions() {
    if (loading) return;

    setLoading(true);
    try {
      const timestamp = sessions.length
        ? sessions[sessions.length - 1].timestamp
        : Date.now();
      const { sessions: moreSessions, numberRequested } = await getMore({ timestamp, showCompleted, userId: currentUser.uid });

      return { moreSessions, numberRequested };
    } finally {
      setLoading(false);
    }
  }

  async function handleLoadMore() {
    const res = await loadMoreSessions();

    if (res) {
      setSessions(sess => sess.concat(res.moreSessions));
      setNumberOfSessionsRequested((n) => n + res.numberRequested);
    }
  }

  useEffect(function () {
    let ignore = false; // Stupid way to avoid react running effects twice in dev mode
    loadMoreSessions().then(function (res){
      if (res && !ignore) {
        setSessions(sess => sess.concat(res.moreSessions));
        setNumberOfSessionsRequested((n) => n + res.numberRequested);
      }
    });

    return function cleanup() {
      ignore = true;
    }

    // We want to do one load more on mount, but not again later from effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="fade-in">
        <div className="page pb-24">
          <AnimatePresence>
            <table
              id="session-list"
              className="mt-10 w-full border-collapse rounded-sm md:w-4/5"
            >
              <thead>
                <tr>
                  <th>Partner</th>
                  <th>Progress</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {sessions
                  .filter(createSessionsFilter({ userId: currentUser.uid, showCompleted }))
                  .map((s) => {
                    return (
                      <SessionCard
                        session={s}
                        key={s.id}
                        currentUser={currentUser}
                        highlight={lastSession === s.id}
                        withDelete={(e) => handleDelete(e, s)}
                        i={0} // This controls staggering of entry animations
                      />
                    );
                  })}
              </tbody>
            </table>
          </AnimatePresence>
          {sessions.length === numberOfSessionsRequested ? (
            <button
              className="btn-secondary btn my-6"
              disabled={loading}
              onClick={handleLoadMore}
            >
              Show more
            </button>
          ) : (
            <Alert text="You have reached the end" />
          )}
        </div>
      </div>
      {deleteSessionDialog && (
        <Dialog
          open={deleteSessionDialog}
          onClose={handleClose}
          title={'Delete this session'}
          actions={[
            {
              component: (
                <div
                  key="yes"
                  className="btn-error btn"
                  role="button"
                  onClick={(e) =>
                    handleConfirmDeleteSession(deleteSessionDialog)
                  }
                >
                  Confirm
                </div>
              ),
            },
          ]}
        >
          Confirm deletion of this session. It will not be possible to restore
          it.
          {deleteSessionDialog?.weekly !== 0 && (
            <>
              <div className="mt-4" />
              <span>
                <EventIcon fill="#e29627" /> Even if you delete a weekly
                session, you will not be able to participate again this week.
              </span>
            </>
          )}
          {deleteSessionDialog?.daily !== 0 && (
            <>
              <div className="mt-4" />
              <span>
                <EventIcon fill="#4d774e" /> Even if you delete a daily session,
                you will not be able to participate again today.
              </span>
            </>
          )}
        </Dialog>
      )}
    </>
  );
}
