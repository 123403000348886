import { getDoc, increment, limit, orderBy, query, Timestamp, where, writeBatch } from 'firebase/firestore';
import { currentUserId, getFirestore, getRepos } from '../base';
import { docToObject, queryFirstItemToObject, queryToObjects } from 'firebase-util';
import { compareConstraints } from './utils';
export async function getPublishedScenarios() {
    const repo = getRepos().communityScenarios;
    const q = query(repo.collection, where(repo.fieldName('published'), '==', true), orderBy(repo.fieldName('numberOfClones'), 'desc'), orderBy(repo.fieldName('created'), 'desc'), limit(100));
    const [objects] = await Promise.all([queryToObjects(q)]);
    return objects;
}
export async function getScenario(id) {
    const repo = getRepos().communityScenarios;
    const documentSnapshot = await repo.doc(id);
    return docToObject(documentSnapshot);
}
export async function getMyScenarios() {
    const repo = getRepos().communityScenarios;
    const q = query(repo.collection, where(repo.fieldName('uid'), '==', currentUserId()), orderBy(repo.fieldName('created'), 'desc'), limit(100));
    const [objects] = await Promise.all([queryToObjects(q)]);
    return objects;
}
export async function saveNewScenario({ name, directions, settings }) {
    // Note: Changes to settings are also done in updateScenario and initiateGenerationRequests
    const settingsToUse = { ...settings };
    if (settingsToUse.dealer === 'any') {
        delete settingsToUse.dealer;
    }
    if (settingsToUse.vulnerability === 'any') {
        delete settingsToUse.vulnerability;
    }
    if (settingsToUse.robots === 'NONE') {
        settingsToUse.disableRobots = true;
        delete settingsToUse.aiSystem;
    }
    else if (settingsToUse.robots === 'MULTI') {
        settingsToUse.aiSystem = 'multi';
        delete settingsToUse.disableRobots;
    }
    else if (settingsToUse.robots === 'MINI_NT') {
        settingsToUse.aiSystem = 'miniNT';
        delete settingsToUse.disableRobots;
    }
    else {
        // ADVANCED robots
        delete settingsToUse.disableRobots;
        delete settingsToUse.aiSystem;
    }
    return await getRepos().communityScenarios.add?.({
        uid: currentUserId(),
        parentId: null,
        parentCreatorUid: null,
        name,
        directions: {
            ...directions,
            settings: settingsToUse
        },
        active: true,
        published: false,
        numberOfClones: 0,
        created: Timestamp.now()
    });
}
export async function updateScenario(id, { name, directions, settings, parentId }) {
    const ref = getRepos().communityScenarios.docRef(id);
    // Note: Changes to settings are also done in saveNewScenario and initiateGenerationRequests
    const settingsToUse = { ...settings };
    if (settingsToUse.dealer === 'any') {
        delete settingsToUse.dealer;
    }
    if (settingsToUse.vulnerability === 'any') {
        delete settingsToUse.vulnerability;
    }
    if (settingsToUse.robots === 'NONE') {
        settingsToUse.disableRobots = true;
        delete settingsToUse.aiSystem;
    }
    else if (settingsToUse.robots === 'MULTI') {
        settingsToUse.aiSystem = 'multi';
        delete settingsToUse.disableRobots;
    }
    else if (settingsToUse.robots === 'MINI_NT') {
        settingsToUse.aiSystem = 'miniNT';
        delete settingsToUse.disableRobots;
    }
    else {
        // ADVANCED robots
        delete settingsToUse.disableRobots;
        delete settingsToUse.aiSystem;
    }
    const dealsQuery = query(getRepos().deals.collection, orderBy(getRepos().deals.fieldName('dealNumber'), 'desc'), where(getRepos().deals.fieldName('version'), '==', 3), where(getRepos().deals.fieldName('type'), '==', 'tagged'), where(getRepos().deals.fieldName('tag'), '==', id), limit(1));
    const deal = await queryFirstItemToObject(dealsQuery);
    const minDealNumber = deal?.dealNumber ?? 0;
    const batch = writeBatch(getFirestore());
    if (parentId != null) {
        const parentDoc = await getRepos().communityScenarios.doc(parentId);
        if (parentDoc.exists()) {
            const parentRef = getRepos().communityScenarios.docRef(parentId);
            batch.update(parentRef, {
                numberOfClones: increment(-1)
            });
        }
    }
    batch.update(ref, {
        uid: currentUserId(),
        name,
        // directions: directionAndSettingsToDirections(directions, settings), // TODO: this resulted in TS error
        directions: {
            ...directions,
            settings: settingsToUse
        },
        active: true,
        minDealNumber,
        parentId: null,
        parentCreatorUid: null,
        created: Timestamp.now()
    });
    await batch.commit();
}
export async function deleteScenario(id, parentId) {
    const batch = writeBatch(getFirestore());
    const ref = getRepos().communityScenarios.docRef(id);
    batch.delete(ref);
    if (parentId != null) {
        const parentDoc = await getRepos().communityScenarios.doc(parentId);
        if (parentDoc.exists()) {
            const parentRef = getRepos().communityScenarios.docRef(parentId);
            batch.update(parentRef, {
                numberOfClones: increment(-1)
            });
        }
    }
    await batch.commit();
}
export async function publishScenario(id, description) {
    const ref = getRepos().communityScenarios.docRef(id);
    await getRepos().communityScenarios.update?.(ref, {
        published: true,
        numberOfClones: increment(0), // To handle scenarios that were created without this field (needed for query)
        description,
        parentCreatorUid: null,
        parentId: null
    });
}
export async function editScenarioDescription(id, description) {
    const ref = getRepos().communityScenarios.docRef(id);
    await getRepos().communityScenarios.update?.(ref, {
        description
    });
}
export async function cloneScenario(scenarioId) {
    const batch = writeBatch(getFirestore());
    const newScenarioRef = getRepos().communityScenarios.new();
    const oldScenarioRef = getRepos().communityScenarios.docRef(scenarioId);
    const oldScenarioDoc = await getDoc(oldScenarioRef);
    const scenario = docToObject(oldScenarioDoc);
    if (scenario == null) {
        throw new Error('Scenario not found');
    }
    batch.set(newScenarioRef, {
        uid: currentUserId(),
        name: scenario.name,
        description: scenario.description,
        directions: scenario.directions,
        active: true,
        published: false,
        numberOfClones: 0,
        parentId: scenario.id,
        parentCreatorUid: scenario.uid,
        created: Timestamp.now()
    });
    batch.update(oldScenarioRef, {
        numberOfClones: increment(1)
    });
    await batch.commit();
}
export async function validateHasChangedConstraints(directions, parentId) {
    if (parentId == null) {
        return true;
    }
    const parentDoc = await getRepos().communityScenarios.doc(parentId);
    if (!parentDoc.exists()) {
        return true;
    }
    const parentData = docToObject(parentDoc);
    return !compareConstraints(directions, parentData.directions);
}
