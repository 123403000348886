import { Link, useNavigate } from 'react-router-dom'
import ContactCard from '../../components/areaCards/contact';
import AdminMessage from '../../components/AdminMessage';
import Collapse from '../../components/collapse/Collapse';
import { StarRating } from '../../components/rating/starRating.jsx'
import { useAppStateStore } from '../../appStateStore.jsx'
import { useEffect, useState } from 'react'
import goToStore from '../../util/goToStore.js'
import { useAuth } from '../../util/hooks.jsx'
import { updateUser } from '../../firebase/userApi.js'
import { getCurrentOnboardingStep, getNextOnboardingStep, onboardingSteps } from '../../util/onboarding.js'
import { useTranslation, Trans } from 'react-i18next'

export default function Help() {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const nav = useNavigate();
  const isNative = useAppStateStore((state) => state.isNative);
  const iap = useAppStateStore((state) => state.iap);
  const user = useAppStateStore((state) => state.user);

  const currentOnboardingStep = getCurrentOnboardingStep({ user });

  const { t } = useTranslation('translation', { keyPrefix: 'help' });

  useEffect(function () {
    if (currentOnboardingStep === onboardingSteps.help) {
      void updateUser({ onboardingStep: getNextOnboardingStep(currentOnboardingStep) });
    }
  }, [currentOnboardingStep]);

  const handleClickStore = async (storeTab) => {
    if (loading) return;

    setLoading(true);
    await goToStore({ nav,
      isNative,
      iap,
      uid: currentUser.uid,
      tab: storeTab,
    });
    setLoading(false);
  };

  return (
    <div className="page pb-24">
      <Link to={'/contact'} className="mb-4">
        <ContactCard />
      </Link>
      <AdminMessage />
      <div className="mt-4" />
      <Collapse title={t('add_friends_title')}>

        <article className="break-word prose">
          <p>{t('add_friends_p1')}</p>
          <p>
            <Trans
              i18nKey="add_friends_p2"
              t={t}
            />
          </p>
          <p>{t('add_friends_p3')}</p>
          <p>
            <Trans
              i18nKey="add_friends_p4"
              t={t}
            />
          </p>
          <p>
            {t('add_friends_p5')}
            {user?.friendKey && (
              <strong className="text-secondary select-text">
                {user.friendKey}
              </strong>
            )}
          </p>
          <p>{t('add_friends_p6')}</p>

        </article>
      </Collapse>
      <Collapse title={t('friend_gone_title')}>
        <article className="break-word prose">
          <p>
            <Trans
              i18nKey="friend_gone_p1"
              t={t}
            />
          </p>
        </article>
      </Collapse>
      <Collapse title={t('stars_title')}>
        <article className="break-word prose">
          <p>
            <Trans
              i18nKey="stars_p1"
              t={t}
            />
          </p>
          <StarRating stars={0}/>
          <StarRating stars={1}/>
          <StarRating stars={2}/>
          <StarRating stars={3}/>
          <p>{t('stars_p2')}</p>
          <p>
            <Trans
              i18nKey="stars_p3"
              t={t}
            />
          </p>

          <h3>{t('stars_rainbow_subtitle')}</h3>
          <StarRating stars={3} bonus/>
          <p>
            <Trans
              i18nKey="stars_p4"
              t={t}
            />
          </p>
        </article>
      </Collapse>
      <Collapse title={t('robots_title')}>
        <article className="break-word prose">
          <h3>{t('robots_subtitle1')}</h3>
          <p>{t('robots_p1')}</p>
          <h3>{t('robots_subtitle2')}</h3>
          <p>{t('robots_p2')}</p>
          <h3>{t('robots_subtitle3')}</h3>
          <p>{t('robots_p3')}</p>
          <p>{t('robots_p4')}</p>
          <ul>
            <li>
              <p>{t('robots_ul_li1')}</p>
            </li>
            <li>
              <p>{t('robots_ul_li2')}</p>
            </li>
            <li>
              <p>{t('robots_ul_li3')}</p>
            </li>
            <li>
              <p>{t('robots_ul_li4')}</p>
            </li>
            <li>
              <p>{t('robots_ul_li5')}</p>
            </li>
          </ul>
          <h3>{t('robots_subtitle4')}</h3>
          <p>{t('robots_p5')}</p>
          <p>{t('robots_p6')}</p>
          <p>{t('robots_p7')}</p>
          <p>{t('robots_p8')}</p>
        </article>
      </Collapse>
      <Collapse title={t('lia_title')}>
        <article className="break-word prose">
          <p>{t('lia_p1')}</p>
          <p>{t('lia_p2')}</p>
          <p>{t('lia_p3')}</p>
        </article>
      </Collapse>
      <Collapse title={t('percentage_title')}>
        <article className="prose">
          <p>
            <Trans
              i18nKey="percentage_p1"
              t={t}
            />
          </p>
          <p>{t('percentage_p2')}</p>
          <p>
            <Trans
              i18nKey="percentage_p3"
              t={t}
            />
          </p>
          <p>{t('percentage_p4')}</p>
        </article>
      </Collapse>
      <Collapse title={t('scoring_title')}>
        <article className="prose">
          <p>
            <Trans
              i18nKey="scoring_p1"
              t={t}
            />
          </p>
          <p>{t('scoring_p2')}</p>
          <p>{t('scoring_p3')}</p>
          <p>{t('scoring_p4')}</p>
          <p>{t('scoring_p5')}</p>
        </article>
      </Collapse>
      <Collapse title={t('why_subscribe_title')}>
        <article className="prose">
          <p>{t('why_subscribe_p1')}</p>
          <ul>
            <li className="whitespace-normal">
              {t('why_subscribe_ul_li1')}
            </li>
            <li className="whitespace-normal">
              {t('why_subscribe_ul_li2')}
            </li>
            <li className="whitespace-normal">
              {t('why_subscribe_ul_li3')}
            </li>
            <li className="whitespace-normal">
              {t('why_subscribe_ul_li4')}
            </li>
            <li className="whitespace-normal">
              {t('why_subscribe_ul_li5')}
            </li>
          </ul>
          <p>{t('why_subscribe_p2')}</p>
          <div
            onClick={() => handleClickStore('sub')}
            className="underline text-secondary decoration-2 font-bold decoration-primary cursor-pointer"
          >
            {t('why_subscribe_store_button')}
          </div>
        </article>
      </Collapse>
      <Collapse title={t('subscribe_title')}>
        <article className="prose">
          <div
            onClick={() => handleClickStore('sub')}
            className="underline text-secondary decoration-2 font-bold decoration-primary cursor-pointer"
          >
            {t('subscribe_store_button')}
          </div>
          <p>{t('subscribe_p1')}</p>
          <p>{t('subscribe_p2')}</p>
        </article>
      </Collapse>
      <Collapse title={t('tickets_title')}>
        <article className="prose">
          <p>{t('tickets_p1')}</p>
          <p>{t('tickets_p2')}</p>
          <p>{t('tickets_p3')}</p>
          <div
            onClick={(e) => handleClickStore()}
            className="underline text-secondary decoration-2 font-bold decoration-primary cursor-pointer"
          >
            {t('tickets_store_button')}
          </div>
        </article>
      </Collapse>
      <Collapse title={t('events_title')}>
        <article className="prose">
          <p>{t('events_p1')}</p>
          <ul>
            <li className="whitespace-normal">
              <Trans
                i18nKey="events_ul_li1"
                t={t}
              />
            </li>
            <li className="whitespace-normal">
              <Trans
                i18nKey="events_ul_li2"
                t={t}
              />
            </li>
          </ul>
          <p>{t('events_p2')}</p>
          <p>{t('events_p3')}</p>
          <p>{t('events_p4')}</p>
        </article>
      </Collapse>
      <Collapse title={t('ranking_title')}>
        <article className="prose">
          <p>{t('ranking_p1')}</p>
          <ul>
            <li className="whitespace-normal">
              {t('ranking_ul_li1')}
            </li>
            <li className="whitespace-normal">
              {t('ranking_ul_li2')}
            </li>
            <li className="whitespace-normal">
              {t('ranking_ul_li3')}
            </li>
            <li className="whitespace-normal">
              {t('ranking_ul_li4')}
            </li>
          </ul>
          <p>{t('ranking_p2')}</p>
        </article>
      </Collapse>
      <Collapse title={t('contact_title')}>
        <Link to="/contact"
          className="underline text-secondary decoration-2 font-bold decoration-primary cursor-pointer"
        >
          {t('contact_link')}
        </Link>
      </Collapse>
      <Collapse title={t('release_notes_title')}>
        <Link to="/releaseNotes"
          className="underline text-secondary decoration-2 font-bold decoration-primary cursor-pointer"
        >
          {t('release_notes_link')}
        </Link>
      </Collapse>
    </div>
  );
}
