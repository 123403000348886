import Dropdown from './dropdown.jsx'
import ConventionCardDialog from '../conventionCards/conventionCardDialog.jsx'
import { base, CC, OB } from '../../util/robotPartner.js'
import { Link } from 'react-router-dom';
import {useAppStateStore} from "../../appStateStore.jsx";
import { useTranslation } from 'react-i18next';

export function LiaSystemDropdown({ liaSystem, handleChangeLiaSystem }) {
  const mySys = useAppStateStore((s) => s.user.myLiaSystem)
  const { t } = useTranslation();

  // NOTE: When adding systems, also add them in getInitialLiaSystem (robotPartner util)
  const systemOptions = [
    {
      value: 'DEFAULT',
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {t('lia.two_over_one_system')}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn w-full justify-start items-center  flex p-2"
          onClick={() => handleChangeLiaSystem('DEFAULT')}
        >
          <div className="w-full h-full flex flex-col items-start justify-start gap-1">
            <p>{t('lia.two_over_one_system')}</p>
            <p className={'info'}>{t('lia.two_over_one_explain')}</p>
          </div>
        </button>
      ),
    },
    {
      value: 'ACOL',
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {t('lia.acol')}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn w-full justify-start items-center  flex p-2"
          onClick={() => handleChangeLiaSystem('ACOL')}
        >
          <div className="w-full h-full flex flex-col items-start justify-start gap-1">
            <p>{t('lia.acol')}</p>
            <p className={'info'}>{t('lia.acol_explain')}</p>
          </div>
        </button>
      ),
    },
    {
      value: 'SEF',
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {t('lia.french')}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn w-full justify-start items-center flex p-2"
          onClick={() => handleChangeLiaSystem('SEF')}
        >
          <div className="w-full h-full flex flex-col items-start justify-start gap-1">
            <p> {t('lia.french')}</p>
            <p className={'info'}>{t('lia.french_explain')}</p>
          </div>
        </button>
      ),
    },
    {
      value: 'SWEDISH',
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {t('lia.swedish')}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn w-full justify-start items-center flex p-2"
          onClick={() => handleChangeLiaSystem('SWEDISH')}
        >
          <div className="w-full h-full flex flex-col items-start justify-start gap-1">
            <p>{t('lia.swedish')}</p>
            <p className={'info'}>{t('lia.swedish_explain')}</p>
          </div>
        </button>
      ),
    },
    {
      value: 'SWEDISH_BASIC',
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {t('lia.swedish_basic')}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn w-full justify-start items-center flex p-2"
          onClick={() => handleChangeLiaSystem('SWEDISH_BASIC')}
        >
          <div className="w-full h-full flex flex-col items-start justify-start gap-1">
            <p>{t('lia.swedish_basic')}</p>
            <p className={'info'}>{t('lia.swedish_basic_explain')}</p>
          </div>
        </button>
      ),
    },
    {
      value: 'GAVIN_LIGHT',
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {t('lia.gavin_light')}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn w-full justify-start items-center flex p-2"
          onClick={() => handleChangeLiaSystem('GAVIN_LIGHT')}
        >
          <div className="w-full h-full flex flex-col items-start justify-start gap-1">
            <p>{t('lia.gavin_light')}</p>
          </div>
        </button>
      ),
    },
    {
      value: 'GAVIN_ADVANCED',
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {t('lia.gavin_advanced')}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn w-full justify-start items-center flex p-2"
          onClick={() => handleChangeLiaSystem('GAVIN_ADVANCED')}
        >
          <div className="w-full h-full flex flex-col items-start justify-start gap-1">
            {t('lia.gavin_advanced')}
          </div>
        </button>
      ),
    },
  ];

  if (mySys) {
    systemOptions.unshift({
      value: 'MY_SYSTEM',
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {mySys}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn w-full justify-start items-center flex p-2"
          onClick={() => handleChangeLiaSystem('MY_SYSTEM')}
        >
          <div className="w-full h-full flex flex-col items-start justify-start gap-1">
            <p>{mySys}</p>
          </div>
        </button>
      ),
    })
  }

  return (
    <>
      {/* <span className="info mt-2">
        Lia made in collaboration with{' '}
        <strong className={'text-sky-400'}>IntoBridge</strong>
      </span> */}
      <div className="flex grow flex-col w-80">
        <label className="label">
          <span className="label-text">{t('lia.lia_system')}</span>
          <Link to={'/conventionPicker'}><span className="label-text underline underline-offset-2 decoration-2 decoration-secondary hover:decoration-primary">{t('lia.create_own_system')}</span></Link>
        </label>
        <Dropdown
          size={'normal'}
          value={liaSystem}
          label={t('lia.system')}
          options={systemOptions}
        />
      </div>
      <div className="flex items-center">
        <span className={'info'}>{t('lia.convention_card')}:</span>
        <ConventionCardDialog
          base={base[liaSystem]}
          ob={OB[liaSystem]}
          cc={CC[liaSystem]}
        />
      </div>
    </>
  );
}
