import { currentUserId } from '../base';
export function sessionAdapter(session) {
    if (session == null)
        return null;
    let compete = session.compete;
    if (compete == null || compete === false) {
        compete = 0;
    }
    else if (compete === true) {
        compete = session.robotVersion === 2 ? 2 : 1;
    }
    const extraUsers = session.extraUsers ?? [];
    return {
        ...session,
        compete,
        weekly: session.weekly ?? 0,
        daily: session.daily ?? 0,
        groupSession: session.groupSession ?? 0,
        challenge: session.challenge === false ? 0 : (session.challenge ?? 0),
        ranked: session.ranked ?? false,
        matchmaking: session.matchmaking ?? false,
        kibitzing: !session.users.includes(currentUserId()),
        extraUsers,
        shared: extraUsers.includes(currentUserId())
    };
}
