import { MessageIcon } from '../icons/messageIcon';
import { useTranslation } from 'react-i18next';

export default function ContactCard() {
  const { t } = useTranslation();
  
  return (
    <button className="btn-primary btn h-24">
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <MessageIcon fill="#dddddd" width="32" className="mb-2" />

          <span>{t('components.contact_card.contact')}</span>
        </div>
      </div>
    </button>
  );
}
