import {useMemo, useState} from 'react';
import { Star } from '../rating/starRating';
import PairDisplay, { LinkedPairDisplay } from '../users/pairDisplay';

import { useTranslation } from 'react-i18next';

import {getAllRobots} from "../../util/robotPartner.js";


function getLeaderboardByStarsAndTimestamp(leaderBoard) {
  return leaderBoard.sort((a, b) => {
    if (a.numberOfStars === b.numberOfStars) {
      return a.timestamp - b.timestamp;
    }
    return b.numberOfStars - a.numberOfStars;
  });
}

export default function Leaderboard({
  leaderBoard = [],
  top,
  header,
  min = 0,
  links = true,
  stars = true,
  showRobotPairs = false,
  leadersIcon,
}) {

  const { t } = useTranslation();

  const robots = getAllRobots();


  const sortedLeaderboard = useMemo(() => {
    const leaders = getLeaderboardByStarsAndTimestamp([...leaderBoard]);

    return leaders.map((l, i) => {
      const uid1 = l.users ? l.users[0] : l.userData[0].uid;
      const uid2 = l.users ? l.users[1] : l.userData[1].uid;
      const isRobotPair = robots.includes(uid1) && robots.includes(uid2);
      const isOneRobot = robots.includes(uid1) || robots.includes(uid2);
      const stars = l.numberOfStars;

      return {
        uid1,
        uid2,
        isRobotPair,
        isOneRobot,
        stars,
      };
    }).filter(l => showRobotPairs || !l.isRobotPair).filter((s) => s.stars >= min).slice(0, top);
  }, [leaderBoard, top, min, showRobotPairs]);

  const winningStars = sortedLeaderboard.filter(l => !l.isRobotPair)[0]?.stars;

  if (sortedLeaderboard.length === 0) {
    return (
      <div className="flex h-12 w-full items-center justify-center">
        <div className="badge-ghost badge badge-sm">{t('leaderboard.empty')}</div>
      </div>
    );
  }

  return (
    <table className="w-full overflow-hidden rounded-md">
      {header && (
        <thead>
          <tr>
            <th>{header}</th>
          </tr>
        </thead>
      )}
      <tbody>
        {sortedLeaderboard.map((l) => {

          return (
            <tr key={l.uid1+l.uid2} className="h-14">
              <td>
                <div className="flex items-center gap-3">
                  {l.stars === winningStars && !l.isRobotPair &&
                    leadersIcon}
                  {links ? (
                    <LinkedPairDisplay uid1={l.uid1} uid2={l.uid2} />
                  ) : (
                    <PairDisplay uid1={l.uid1} uid2={l.uid2} />
                  )}
                </div>
              </td>
              {stars && (
                <td>
                  <div className="flex justify-end items-center">
                    <Star /> {l.stars}
                  </div>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
