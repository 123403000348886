import { ranges } from './constants';

const overcall2Level = [
  // Note: reqFit is opening suit, will not work when we overcall
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[DHS]$/,
    explain: 'We can overcall with 2C',
    options: [
      {
        bid: '2C["5+!C"]',
        reqC: 5,
        reqHp: ranges.overcallTwoLevel + 3,
      },
      {
        bid: '2C["5+!C"]',
        reqC: 6,
        reqHp: ranges.overcallTwoLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]$/,
    explain: 'We can overcall with 2D',
    options: [
      {
        bid: '2D["5+!D"]',
        reqD: 5,
        reqHp: ranges.overcallTwoLevel + 3,
      },
      {
        bid: '2D["5+!D"]',
        reqD: 6,
        reqHp: ranges.overcallTwoLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S$/,
    explain: 'We can overcall with 2H',
    options: [
      {
        bid: '2H["5+!H"]',
        reqH: 5,
        reqHp: ranges.overcallTwoLevel + 3,
      },
      {
        bid: '2H["5+!H"]',
        reqH: 6,
        reqHp: ranges.overcallTwoLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[DHS]-2C-(P|D|..)$/,
    explain: 'Partner overcall with 2C, we can bid 3N or 5C',
    options: [
      {
        bid: '3N["To play"]',
        reqHp: 12,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5C["To play"]',
        reqC: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '5C["To play"]',
        reqC: 3,
        reqHp: 12,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[DHS]-2C-(P|D|2.)$/,
    explain: 'Partner overcall with 2C, we can raise',
    options: [
      {
        bid: '2o[@"Invitational, 3+!C"]',
        reqC: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '4C["Weak raise, 4+!C"]',
        reqC: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '3C["Single raise, 3+!C"]',
        reqC: 3,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[DHS]-2C-3.$/,
    explain:
      'Partner overcall with 2C, we can do single raise to 4C or good raise',
    options: [
      {
        bid: '3o[@"Invitational, 3+!C"]',
        reqC: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '4C["Single raise, 4+!C"]',
        reqC: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(?:-\.)*(?:-P)*-1([DHS])-2C-(?:P|D|..)-[23]\1-(?:P|D|..)$/,
    explain:
      "We overcalled with 2C, bid again over partner's invitational raise?",
    options: [
      {
        bid: '3N["To play"]',
        reqHp: 14,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5C["To play"]',
        reqC: 6,
        reqHp: 13,
      },
      {
        bid: '5C["To play"]',
        reqHp: 16,
      },
      {
        bid: '3C["Minimum"]',
      },
      {
        bid: '4C["Minimum"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[DHS]-2C-(P|D|2.)-3C-(P|D|..)$/,
    explain:
      "We overcalled with 2C, bid again over partner's single raise to 3C?",
    options: [
      {
        bid: '3N["To play"]',
        reqHp: 16,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5C["To play"]',
        reqC: 6,
        reqHp: 16,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[DHS]-2C-3.-4C-(P|D|..)$/,
    explain:
      "We overcalled with 2C, bid again over partner's single raise to 4C?",
    options: [
      {
        bid: '5C["To play"]',
        reqC: 6,
        reqHp: 13,
      },
      {
        bid: '5C["To play"]',
        reqHp: 16,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-2D-(P|D|..)$/,
    explain: 'Partner overcall with 2D, we can bid 3N or 5D',
    options: [
      {
        bid: '3N["To play"]',
        reqHp: 12,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5D["To play"]',
        reqD: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '5D["To play"]',
        reqD: 3,
        reqHp: 12,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-2D-(P|D|2.|3C)$/,
    explain: 'Partner overcall with 2D, we can raise',
    options: [
      {
        bid: '2o[@"Invitational, 3+!D"]',
        reqD: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '4D["Weak raise, 4+!D"]',
        reqD: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '3D["Single raise, 3+!D"]',
        reqD: 3,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-2D-(3[DHSN]|4C)$/,
    explain:
      'Partner overcall with 2D, we can do single raise to 4D or good raise',
    options: [
      {
        bid: '3o[@"Invitational, 3+!D"]',
        reqD: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '4D["Single raise, 4+!D"]',
        reqD: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(?:-\.)*(?:-P)*-1([HS])-2D-(?:P|D|..)-[23]\1-(?:P|D|..)$/,
    explain:
      "We overcalled with 2D, bid again over partner's invitational raise?",
    options: [
      {
        bid: '3N["To play"]',
        reqHp: 14,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5D["To play"]',
        reqD: 6,
        reqHp: 13,
      },
      {
        bid: '5D["To play"]',
        reqHp: 16,
      },
      {
        bid: '3D["Minimum"]',
      },
      {
        bid: '4D["Minimum"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-2D-(P|D|2.|3C)-3D-(P|D|..)$/,
    explain:
      "We overcalled with 2D, bid again over partner's single raise to 3D?",
    options: [
      {
        bid: '3N["To play"]',
        reqHp: 16,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5D["To play"]',
        reqD: 6,
        reqHp: 16,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-2D-(3[DHSN]|4C)-4D-(P|D|..)$/,
    explain:
      "We overcalled with 2D, bid again over partner's single raise to 4D?",
    options: [
      {
        bid: '5D["To play"]',
        reqD: 6,
        reqHp: 13,
      },
      {
        bid: '5D["To play"]',
        reqHp: 16,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2H-(P|D|2.|3C|3D)$/,
    explain: 'Partner overcall with 2H, we can raise',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 5,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: 12,
      },
      {
        bid: '2S[@"Invitational, 3+!H"]',
        reqH: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '3H["Single raise, 3+!H"]',
        reqH: 3,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2H-(3H|3S|3N|4C|4D)$/,
    explain: 'Partner overcall with 2H, we can bid 4H',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 5,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: 11,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2H-[PD]-2S-(?:P|D|..)$/,
    explain:
      "We overcalled with 2H, bid again over partner's invitational raise?",
    options: [
      {
        bid: '4H["To play"]',
        reqH: 6,
        reqHp: 12,
      },
      {
        bid: '4H["To play"]',
        reqHp: 15,
      },
      {
        bid: '3H["Minimum"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2H-(P|D|2.|3C|3D)-3H-(P|D|..)$/,
    explain: "We overcalled with 2H, bid again over partner's single raise?",
    options: [
      {
        bid: '4H["To play"]',
        reqH: 6,
        reqHp: 14,
      },
      {
        bid: '4H["To play"]',
        reqHp: 17,
      },
    ],
  },
];

export default overcall2Level;
