import { EventIcon } from '../icons/dailyIcon.jsx';
import { GroupsIcon } from '../icons/groupsIcon.jsx';
import { ChallengeIcon } from '../icons/challengeIcon.jsx';
import { FriendsIcon } from '../icons/friendsIcon.jsx';
import { HandshakeIcon } from '../icons/handShakeIcon.jsx';
import { PracticeIcon } from '../icons/practiceIcon.jsx';

export default function SessionTypeIcon ({ session }) {
  if (!session) return null;

  if (session.weekly !== 0) {
    return <EventIcon fill="#e29627" />;
  }
  if (session.daily !== 0) {
    return <EventIcon fill="#4d774e" />;
  }

  if (session.groupSession !== 0) {
    return (
      <div className="w-6 flex justify-center">
        <GroupsIcon className="fill-blue-400" width={20} />
      </div>
    );
  }

  if (session.challenge !== 0 && session.ranked) {
    return <ChallengeIcon fill="#9f4e4e" />;
  }
  if (session.challenge !== 0) {
    return <FriendsIcon fill="#9f4e4e" />;
  }
  if (session.matchmaking) {
    return <HandshakeIcon fill="#e29627" />;
  }
  return <PracticeIcon fill="#4d774e" />;
}
