import { useState, useEffect } from 'react';
import { useAuth } from '../../util/hooks.jsx';
import { AnimatePresence } from 'framer-motion';
import { useAppStateStore } from '../../appStateStore.jsx';
import { challengesBeforeTimestampLimit, getChallengesBeforeTimestamp } from '../../firebase/challenges.js';
import { useNavigate } from 'react-router-dom';
import ChallengeToCard from './challengeComponents/ChallengeToCard.jsx';
import { useTranslation } from 'react-i18next'

export default function ChallengeArchivePage() {
  const { t } = useTranslation('translation', { keyPrefix: 'challenge' });

  const { currentUser } = useAuth();
  const appStateChallenges = useAppStateStore((state) => state.challenges);
  const [challenges, setChallenges] = useState(appStateChallenges || []);
  const [numberOfChallengesRequested, setNumberOfChallengesRequested] = useState(
    challenges.length
  );
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  async function handleLoadMore() {
    setLoading(true);
    try {
      const timestamp = challenges.length
        ? challenges[challenges.length - 1].timestamp
        : Date.now();
      const moreChallenges = await getChallengesBeforeTimestamp({ timestamp });
      if (moreChallenges) {
        setChallenges(challenges.concat(moreChallenges));
      }
      setNumberOfChallengesRequested(
        numberOfChallengesRequested + challengesBeforeTimestampLimit
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(function () {
    if (challenges.length === numberOfChallengesRequested) {
      void handleLoadMore();
    }
    // We want to do one load more on mount, but not again later from effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="fade-in">
      <div className="page pb-24">
        <AnimatePresence>
          {challenges.map((c) => {
            return (
              <ChallengeToCard
                key={c.id}
                challenge={c}
                navigate={navigate}
                currentUser={currentUser}
              />
            );
          })}
        </AnimatePresence>
        {challenges.length === numberOfChallengesRequested && (
          <button
            className="btn-secondary btn my-6"
            disabled={loading}
            onClick={handleLoadMore}
          >
            {t('show_more')}
          </button>
        )}
      </div>
    </div>
  );
}
