import { useState } from 'react'
import './bidDisplay.css';
import BidDisplay from './bidDisplay';
import { getBidArrayWithAlertsIncludingPositionalSymbols } from 'cuebids-bidding-util';
import { useTranslation } from 'react-i18next';

function Header({ vulnerability, north, south }) {
  const { t } = useTranslation();

  const nsVul = vulnerability === 'NS' || vulnerability === 'ALL';
  const ewVul = vulnerability === 'EW' || vulnerability === 'ALL';
  return (
    <div className={'w-[240px] h-10 grid grid-cols-4 place-items-center'}>
      <div
        className={`flex w-[60px] flex-col items-center text-xs ${ewVul ? 'font-bold text-rose-800' : 'text-normal'}`}>
        {t('bridge.directions_short.W')}
        <span
          className={'text-[8px] '}>-</span>
      </div>
      
      <div
        className={`flex w-[60px] flex-col items-center text-xs ${nsVul ? 'font-bold text-rose-800' : 'text-normal'}`}>
        {t('bridge.directions_short.N')}
        {north && <span
          className={'text-[8px] overflow-hidden whitespace-nowrap'}>{north}</span>}
      </div>
      <div
        className={`flex w-[60px] flex-col items-center text-xs ${ewVul ? 'font-bold text-rose-800' : 'text-normal'}`}>
        {t('bridge.directions_short.E')}
        <span
          className={'text-[8px]'}>-</span>
      </div>
      <div
        className={`flex w-[60px] flex-col items-center text-xs ${nsVul ? 'font-bold text-rose-800' : 'text-normal'}`}>
        {t('bridge.directions_short.S')}
        {south && <span
          className={'text-[8px]  overflow-hidden whitespace-nowrap'}>{south}</span>}
      </div>
    </div>
  );
}

function BiddingRow({
  row
}) {
  return (
    <div className={'flex w-[240px]'}>
      {row.map(({ bid, index }, i) => {
        
        const alert = bid.indexOf('[');
        
        if (alert > -1) {
          bid = bid.substring(0, alert);
        }
          
        return (
          bid && (
            <div key={i} className={'flex items-center justify-center w-[60px] mb-2'}>
              {bid !== '.' && (
                <>
                  <div className="indicator">
                    <div>
                      <BidDisplay
                        bid={bid}
                        size={30}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )
        );
      })}
    </div>
  );
}

function partitionBids(bids) {
  const output = [];
  for (let i = 0; i < bids.length; i += 4) {
    output[output.length] = bids.slice(i, i + 4);
  }
  return output;
}

function generateUniqueId() {
  return Math.random().toString(16).slice(2)
}

export default function BiddingDisplayPrint({
  bidding,
  vulnerability,
  north,
  south
}) {
  const [componentId] = useState(generateUniqueId());
  let bids = getBidArrayWithAlertsIncludingPositionalSymbols(bidding);

  let bidObjects = bids.map((b, i) => ({ bid: b, index: i }));

  return (
    <div>
      <Header
        vulnerability={vulnerability}
        north={north}
        south={south}
      />
      <div className={'w-[240px]'}>
        {partitionBids(bidObjects).map((biddingRound, i) => {
          return (
            <BiddingRow
              key={i}
              biddingRowIndex={i}
              row={biddingRound}
              componentId={componentId}
            />
          );
        })}
      </div>
    </div>
  );
}
