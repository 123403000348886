import Alert from '../../components/alert/alert';
import AdminMessage from '../../components/AdminMessage';
import { useTranslation } from 'react-i18next'

export default function RefreshRequired() {
  const { t } = useTranslation();

  const refresh = () => {
    // Can't be client side navigation
    location.reload(true); // Firefox has a parameter to location.reload

    // Some issues reported on android webview about location.reload, so have a backup method
    // eslint-disable-next-line no-self-assign
    location.href = location.href
  };

  return (
    <div className="page">
      <h1>{t('refresh.title')}</h1>
      <AdminMessage />
      <Alert
        severity="warning"
        text={t('refresh.warning')}
      />
      <button
        className="btn btn-primary"
        style={{ marginTop: 10 }}
        onClick={refresh}
      >
        {t('refresh.refresh_button')}
      </button>
    </div>
  );
}
