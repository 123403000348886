import { FriendsIcon } from '../../../components/icons/friendsIcon';
import { ChallengeIcon } from '../../../components/icons/challengeIcon';
import { getWinPercent } from '../../../util/challenge';
import { useTranslation } from 'react-i18next'

// Note: The wrapper of this need to layout the blocks, for example with grid (with 5 cols)
export default function ChallengeStatisticBlock({
  numberOfChallenges = 0,
  wins = 0,
  draws = 0,
  losses = 0,
  numberOfBoards = 0,
  header,
  friendlyIcon = false
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'challenge' });

  return (
    <>
      <div className="flex flex-col justify-center text-center">
        <span className="info">{header}</span>
        <div style={{ padding: 5 }} className="stats">
          {numberOfChallenges}
        </div>
      </div>
      <div className="flex flex-col justify-center text-center">
        <span className="info">{t('wdl')}</span>
        <div style={{ padding: 5 }} className="stats">
          {`${wins}/${draws}/${losses}`}
        </div>
      </div>
      {friendlyIcon ? <FriendsIcon fill="#9f4e4e" /> : <ChallengeIcon fill="#9f4e4e" strokeWidth={0.5} />}
      <div className="flex flex-col justify-center text-center">
        <span className="info">{t('win_percent')}</span>
        <div style={{ padding: 5 }} className="stats">
          {getWinPercent(wins, draws, losses)}{' '} %
        </div>
      </div>
      <div className="flex flex-col justify-center text-center">
        <span className="info">{t('boards')}</span>
        <div style={{ padding: 5 }} className="stats">
          {numberOfBoards}
        </div>
      </div>
    </>
  );
}
