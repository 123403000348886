import { ranges } from './constants';

const overcall1Level = [
  // Note: reqFit is opening suit, will not work when we overcall
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C$/,
    explain: 'We can overcall with 1D',
    options: [
      {
        bid: '1D["5+!D"]',
        reqD: 5,
        reqMaxH: 4,
        reqMaxS: 4,
        reqHp: ranges.overcallOneLevel,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]$/,
    explain: 'We can overcall with 1H',
    options: [
      {
        bid: '1H["5+!H"]',
        reqH: 5,
        reqMaxS: 4,
        reqHp: ranges.overcallOneLevel,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDH]$/,
    explain: 'We can overcall with 1S',
    options: [
      {
        bid: '1S["5+!S"]',
        reqS: 5,
        reqHp: ranges.overcallOneLevel,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDHS]$/,
    explain: 'We can overcall with 1N',
    options: [
      {
        bid: '1N["Balanced, 15-18"]',
        reqHp: 15,
        reqMaxHp: 18,
        reqHcpInOpeningSuit: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-(P|D|..)$/,
    explain: 'Partner overcall with 1D, we can bid 3N or 5D',
    options: [
      {
        bid: '3N["To play"]',
        reqHp: 24 - ranges.overcallOneLevel,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5D["To play"]',
        reqD: 6,
        reqHp: ranges.raise,
      },
      {
        bid: '5D["To play"]',
        reqD: 3,
        reqHp: 26 - ranges.overcallOneLevel,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-(P|D|1.|2C)$/,
    explain: 'Partner overcall with 1D, we can raise',
    options: [
      {
        bid: '2C[@"Invitational, 3+!D"]',
        reqD: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '3C[@"Invitational, 3+!D"]',
        reqD: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '4D["Weak raise, 4+!D"]',
        reqD: 5,
        reqHp: ranges.weakRaise,
        reqMaxHp: ranges.invitational,
      },
      {
        bid: '3D["Weak raise, 4+!D"]',
        reqD: 4,
        reqHp: ranges.weakRaise,
        reqMaxHp: ranges.invitational,
      },
      {
        bid: '2D["Single raise, 3+!D"]',
        reqD: 3,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-(2D|2H|2S|2N|3C)$/,
    explain: 'Partner overcall with 1D, we can raise',
    options: [
      {
        bid: '3C[@"Invitational, 3+!D"]',
        reqD: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '4D["Weak raise, 4+!D"]',
        reqD: 5,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '3D["Single raise, 3+!D"]',
        reqD: 3,
        reqHp: ranges.intermediateRaise,
      },
      {
        bid: '3D["Single raise, 3+!D"]',
        reqD: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-(3D|3H|3S|3N|4C)$/,
    explain: 'Partner overcall with 1D, we can make single raise to 4D',
    options: [
      {
        bid: '4D["Single raise, 3+!D"]',
        reqD: 5,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '4D["Single raise, 3+!D"]',
        reqD: 3,
        reqHp: ranges.invitational,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-(P|D|..)-[23]C-(P|D|..)$/,
    explain:
      "We overcalled with 1D, bid again over partner's invitational raise?",
    options: [
      {
        bid: '3N["To play"]',
        reqHp: ranges.overcallOneLevel + 6,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5D["To play"]',
        reqD: 6,
        reqHp: ranges.overcallOneLevel + 6,
      },
      {
        bid: '2D["Minimum"]',
      },
      {
        bid: '3D',
        reqParD: 90,
      },
      {
        bid: '3D["Minimum"]',
        lastBid: 'P'
      },
      {
        bid: '3D["Minimum"]',
        lastBid: 'D'
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-(P|D|..)-2D-(P|D|..)$/,
    explain:
      "We overcalled with 1D, bid again over partner's single raise to 2D?",
    options: [
      {
        bid: '3N["To play"]',
        reqHp: ranges.overcallOneLevel + 10,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5D["To play"]',
        reqD: 6,
        reqHp: ranges.overcallOneLevel + 10,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-(2D|2H|2S|2N|3C)-3D-(P|D|..)$/,
    explain:
      "We overcalled with 1D, bid again over partner's single raise to 3D?",
    options: [
      {
        bid: '3N["To play"]',
        reqHp: ranges.overcallOneLevel + 8,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5D["To play"]',
        reqD: 6,
        reqHp: ranges.overcallOneLevel + 8,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-(3D|3H|3S|3N|4C)-4D-(P|D|..)$/,
    explain:
      "We overcalled with 1D, bid again over partner's single raise to 4D?",
    options: [
      {
        bid: '5D["To play"]',
        reqD: 6,
        reqHp: ranges.overcallOneLevel + 5,
      },
      {
        bid: '5D["To play"]',
        reqHp: ranges.overcallOneLevel + 8,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-(P|D|1.|2C)-3D-(P|D|..)$/,
    explain:
      "We overcalled with 1D, bid again over partner's weak raise to 3D?",
    options: [
      {
        bid: '3N["To play"]',
        reqHp: ranges.overcallOneLevel + 10,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5D["To play"]',
        reqD: 6,
        reqHp: ranges.overcallOneLevel + 8,
      },
      {
        bid: '5D["To play"]',
        reqHp: ranges.overcallOneLevel + 11,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1D-(P|D|1.|2.|3C)-3D-(P|D|..)$/,
    explain:
      "We overcalled with 1D, bid again over partner's weak raise to 4D?",
    options: [
      {
        bid: '5D["To play"]',
        reqD: 6,
        reqHp: ranges.overcallOneLevel + 3,
      },
      {
        bid: '5D["To play"]',
        reqHp: ranges.overcallOneLevel + 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-(P|D|1.|2C|2D)$/,
    explain: 'Partner overcall with 1H, we can raise',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: 23 - ranges.overcallOneLevel,
      },
      {
        bid: '4H["To play"]',
        reqH: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '4H["To play"]',
        reqH: 5,
        reqHcpInSuitPerVul: (vul) => (
          ['NS'].includes(vul) ? 0 :
            ['NONE'].includes(vul) ? 4 : 7
        ),
      },
      {
        bid: '2o[@"Invitational, 3+!H"]',
        reqH: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '3o[@"Invitational, 3+!H"]',
        reqH: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '3H["Weak raise, 4+!H"]',
        reqH: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '2H["Single raise, 3+!H"]',
        reqH: 3,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-(2H|2S|2N|3C|3D)$/,
    explain: 'Partner overcall with 1H, we can raise',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: 23 - ranges.overcallOneLevel,
      },
      {
        bid: '4H["To play"]',
        reqH: 5,
        reqHcpInSuitPerVul: (vul) => (
          ['NS'].includes(vul) ? 0 :
            ['NONE'].includes(vul) ? 4 : 7
        ),
      },
      {
        bid: '3o[@"Invitational, 3+!H"]',
        reqH: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '3H["Single raise, 3+!H"]',
        reqH: 3,
        reqHp: ranges.intermediateRaise,
      },
      {
        bid: '3H["Single raise, 3+!H"]',
        reqH: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-(3H|3S|3N|4C|4D)$/,
    explain: 'Partner overcall with 1H, we can bid 4H',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: 23 - ranges.overcallOneLevel,
      },
      {
        bid: '4H["To play"]',
        reqH: 5,
        reqHcpInSuitPerVul: (vul) => (
          ['NS'].includes(vul) ? 0 :
            ['NONE'].includes(vul) ? 4 : 7
        ),
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(?:-\.)*(?:-P)*-1([CD])-1H-(?:P|D|..)-[23]\1-(?:P|D|..)$/,
    explain:
      "We overcalled with 1H, bid again over partner's invitational raise?",
    options: [
      {
        bid: '4H["To play"]',
        reqHp: ranges.overcallOneLevel + 6,
      },
      {
        bid: '4H["To play"]',
        reqH: 6,
        reqHp: ranges.overcallOneLevel + 3,
      },
      {
        bid: '2H["Minimum"]',
      },
      {
        bid: '3H',
        reqParH: 110,
      },
      {
        bid: '3H["Minimum"]',
        lastBid: 'P',
      },
      {
        bid: '3H["Minimum"]',
        lastBid: 'D',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-(P|D|..)-2H-(P|D|..)$/,
    explain:
      "We overcalled with 1H, bid again over partner's single raise to 2H?",
    options: [
      {
        bid: '4H["To play"]',
        reqH: 6,
        reqHp: ranges.overcallOneLevel + 7,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-(2H|2S|2N|3C|3D)-3H-(P|D|..)$/,
    explain:
      "We overcalled with 1H, bid again over partner's single raise to 3H?",
    options: [
      {
        bid: '4H["To play"]',
        reqH: 6,
        reqHp: ranges.overcallOneLevel + 4,
      },
      {
        bid: '4H["To play"]',
        reqHp: ranges.overcallOneLevel + 7,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-[PD]$/,
    explain: 'Partner overcall with 1H, we can bid 1S',
    options: [
      {
        bid: '1S["Not forcing, 4+!S"]',
        reqS: 4,
        reqMaxH: 3,
        reqHp: ranges.newSuitUnforced,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-[PD]-1S-[PD]$/,
    explain:
      "We overcall with 1H, partner bid 1S, overcaller's rebid over no interference",
    options: [
      {
        bid: '2S',
        reqS: 4,
        weight: 2,
      },
      {
        bid: 'P',
        reqS: 3,
        weight: 2,
      },
      {
        bid: '2H["5+!H"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1H-[PD]-1S-[PD]$/,
    explain:
      "We overcall with 1H over 1C, partner bid 1S, overcaller's rebid over no interference",
    options: [
      {
        bid: '2D',
        reqD: 4,
      },
      {
        bid: '1N',
        reqHp: ranges.open,
        reqMaxS: 3,
        reqMaxH: 5,
        reqMaxD: 3,
      },
      {
        bid: '2H["5+!H"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-1H-[PD]-1S-[PD]$/,
    explain:
      "We overcall with 1H over 1D, partner bid 1S, overcaller's rebid over no interference",
    options: [
      {
        bid: '2C',
        reqC: 4,
      },
      {
        bid: '1N',
        reqHp: ranges.open,
        reqMaxS: 3,
        reqMaxH: 5,
        reqMaxC: 3,
      },
      {
        bid: '2H["5+!H"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-[PD]-1S-[PD]-1N-[PD]$/,
    explain:
      "We overcall with 1H, partner bid 1S, responder's preference over 1N",
    options: [
      {
        bid: '2H["To play"]',
        reqH: 2,
      },
      {
        bid: '2S["To play"]',
        reqS: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-1H-[PD]-1S-[PD]-2D-[PD]$/,
    explain:
      "We overcall with 1H, partner bid 1S, responder's preference over 2D",
    options: [
      {
        bid: 'P',
        reqD: 4,
      },
      {
        bid: '2S["To play"]',
        reqS: 6,
      },
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.D >= strains.H + 2;
        },
      },
      {
        bid: '2H["Preference"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-1H-[PD]-1S-[PD]-2C-[PD]$/,
    explain:
      "We overcall with 1H, partner bid 1S, responder's preference over 2C",
    options: [
      {
        bid: 'P',
        reqC: 4,
      },
      {
        bid: '2S["To play"]',
        reqS: 6,
      },
      {
        bid: 'P',
        reqStrains: function(strains) {
          return strains.C >= strains.H + 2;
        },
      },
      {
        bid: '2H["Preference"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-1H-[PD]-1S-(1N|2C|2D)$/,
    explain:
      "We overcall with 1H, partner bid 1S, overcaller's rebid over interference",
    options: [
      {
        bid: '2S',
        reqS: 4,
      },
      {
        bid: '2H',
        reqH: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDH]-1S-(P|D|1.|2C|2D|2H)$/,
    explain: 'Partner overcall with 1S, we can raise',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '4S["To play"]',
        reqS: 3,
        reqHp: 23 - ranges.overcallOneLevel,
      },
      {
        bid: '4S["To play"]',
        reqS: 5,
        reqHcpInSuitPerVul: (vul) => (
          ['NS'].includes(vul) ? 0 :
            ['NONE'].includes(vul) ? 4 : 7
        ),
      },
      {
        bid: '2o[@"Invitational, 3+!S"]',
        reqS: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '3o[@"Invitational, 3+!S"]',
        reqS: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '3S["Weak raise, 4+!S"]',
        reqS: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '2S["Single raise, 3+!S"]',
        reqS: 3,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDH]-1S-(2S|2N|3C|3D|3H)$/,
    explain: 'Partner overcall with 1S, we can raise',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '4S["To play"]',
        reqS: 3,
        reqHp: 23 - ranges.overcallOneLevel,
      },
      {
        bid: '4S["To play"]',
        reqS: 5,
        reqHcpInSuitPerVul: (vul) => (
          ['NS'].includes(vul) ? 0 :
            ['NONE'].includes(vul) ? 4 : 7
        ),
      },
      {
        bid: '3o[@"Invitational, 3+!S"]',
        reqS: 3,
        reqHp: ranges.invitational,
      },
      {
        bid: '3S["Single raise, 3+!S"]',
        reqS: 3,
        reqHp: ranges.intermediateRaise,
        reqMaxHp: ranges.invitational,
      },
      {
        bid: '3S["Single raise, 3+!S"]',
        reqS: 4,
        reqHp: ranges.raise,
        reqMaxHp: ranges.invitational,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDH]-1S-(3S|3N|4C|4D|4H)$/,
    explain: 'Partner overcall with 1S, we can bid 4S',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '4S["To play"]',
        reqS: 5,
        reqHcpInSuitPerVul: (vul) => (
          ['NS'].includes(vul) ? 0 :
            ['NONE'].includes(vul) ? 4 : 7
        ),
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(?:-\.)*(?:-P)*-1([CDH])-1S-(?:P|D|..)-[23]\1-(?:P|D|..)$/,
    explain:
      "We overcalled with 1S, bid again over partner's invitational raise?",
    options: [
      {
        bid: '4S["To play"]',
        reqHp: ranges.overcallOneLevel + 6,
      },
      {
        bid: '4S["To play"]',
        reqS: 6,
        reqHp: ranges.overcallOneLevel + 3,
      },
      {
        bid: '2S["Minimum"]',
      },
      {
        bid: '3S',
        reqParS: 110,
      },
      {
        bid: '3S["Minimum"]',
        lastBid: 'P',
      },
      {
        bid: '3S["Minimum"]',
        lastBid: 'D',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDH]-1S-(P|D|..)-2S-(P|D|..)$/,
    explain:
      "We overcalled with 1S, bid again over partner's single raise to 2S?",
    options: [
      {
        bid: '4S["To play"]',
        reqS: 6,
        reqHp: ranges.overcallOneLevel + 7,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDH]-1S-(2S|2N|3C|3D|3H)-3S-(P|D|..)$/,
    explain:
      "We overcalled with 1S, bid again over partner's single raise to 3S?",
    options: [
      {
        bid: '4S["To play"]',
        reqS: 6,
        reqHp: ranges.overcallOneLevel + 4,
      },
      {
        bid: '4S["To play"]',
        reqHp: ranges.overcallOneLevel + 7,
      },
    ],
  },
  // TODO: 1NT overcall
];

export default overcall1Level;
