export default function SmallButton({ text, icon, onClick, style }) {
  return (
    <button className={'btn-primary btn'} onClick={onClick} style={style}>
      <div className="flex flex-col items-center text-xs">
        {icon}
        <span>{text}</span>
      </div>
    </button>
  );
}
