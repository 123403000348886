import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { parsePBN } from 'pbn-parser';
import HandDiagram from '../../components/cards/diagram.jsx';
import { DeleteIcon } from '../../components/icons/deleteIcon.jsx';
import Alert from '../../components/alert/alert.jsx';
import BiddingDisplay from '../../components/bidding/biddingDisplay.jsx';
import {
  getBidArrayWithAlertsExcludingPositionalSymbols,
  getInitialBidding,
  pbnToCuebids
} from 'cuebids-bidding-util';
import { createEventDraft } from 'cuebids-firebase/dealGeneration';
import { checkBidding } from 'cuebids-firebase/bidding';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

export default function PbnImport() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.pbn_import' });
  const { groupId } = useParams();
  const [hands, setHands] = useState([]);
  const [splitter, setSplitter] = useState('[Event');
  const [eventName, setEventName] = useState('');
  const [includeBidding, setIncludeBidding] = useState(true);
  const [includeCommentary, setIncludeCommentary] = useState(true);

  const nav = useNavigate();

  const notify = useNotifications();


  const toggleIncludeBidding = (e) => {
    e.stopPropagation();
    setIncludeBidding((t) => !t);
  }

  const toggleIncludeCommentary = (e) => {
    e.stopPropagation();
    setIncludeCommentary((t) => !t);
  }

  const handleUpdateCommentary = (index, commentary) => {
    const newHands = [...hands];
    newHands[index].commentary = commentary;
    setHands(newHands);
  }

  const importPbn = (e) => {
    const value = e.target.value;
    if(value) {
      const regex = /\[Event "(.*?)"]/;
      const match = value.match(regex);

      if (match) {
        setEventName(match[1]);
      }
      const pbns = value.split(splitter)
      const parsed =  pbns.map((pbn) => {
        try {
          const x = parsePBN(pbn)
          return mapPbn(x);
        } catch (e) {
          return ''
        }
      }).filter((pbn) => pbn !== '' && pbn.deal !== '')

      const uniques = uniqueByField(parsed, 'deal');
      notify(
        {
          type: 'success',
          text: parsed.length - uniques.length > 0 ? t('success_duplicates', {count: uniques.length}) : t('success_no_duplicates', {count: uniques.length}),
        }
      )
      setHands(uniques)
    }
  }

  const mapPbn = (pbn) => {
    const bidding = pbn.auction.calls.map((bid) => {
      return pbnToCuebids(bid)
    }).join('-');

    const init = getInitialBidding(pbn.dealer)

    const spl = pbn.deal.split(':');
    const cards = spl[1];
    const startingPlayer = spl[0];
    const directions = cards.split(' ');
    const playerOrder = ['N', 'E', 'S', 'W'];
    const startingIndex = playerOrder.indexOf(startingPlayer);
    const offset = (4 - startingIndex) % 4;

    const north = directions[offset];
    const east = directions[(1 + offset) % 4];
    const south = directions[(2 + offset) % 4];
    const west = directions[(3 + offset) % 4];

    return {
      event: pbn.event,
      deal: 'Deal "N:' + north + ' ' + east + ' ' + south + ' ' + west + '"',
      dealer: pbn.dealer.toUpperCase(),
      vulnerability: pbn.vulnerable.toUpperCase(),
      bidding: includeBidding ? init + '-' + bidding : null,
      commentary: includeCommentary ? pbn.commentary : null,
    }
  }

  const handleCreateEventDraft = async () => {
    if(hands.some(h => !h.deal || !h.dealer || !h.vulnerability )) {
      notify(
        {
          type: 'failure',
          text: t('missing_info_error'),
        },
      );
      return;
    }
    if(hands.length === 0) {
      notify(
        {
          type: 'failure',
          text: t('no_hands_error'),
        },
      );
      return;
    }
    if(hands.length > 24) {
      notify(
        {
          type: 'failure',
          text: t('too_many_boards_error'),
        },
      );
      return;
    }
    const newHands = hands.map((h) => {
      return {
        hand: h.deal,
        dealer: h.dealer,
        vulnerability: h.vulnerability,
        bidding: checkBidding(h.bidding) && getBidArrayWithAlertsExcludingPositionalSymbols(h.bidding).length > 0 ? h.bidding : '',
        commentary: h.commentary.join('\n'),
      }});
    const eventDraftId = await createEventDraft(groupId, eventName, newHands);
    nav(`/eventDraft/${eventDraftId}`);
  }

  const uniqueByField = (array, fieldName) => {
    const seen = new Set();
    return array.filter(item => {
      const value = item[fieldName];
      if (seen.has(value)) {
        return false;
      }
      seen.add(value);
      return true;
    });
  };

  const removeHand = (index) => {
    const newHands = [...hands];
    newHands.splice(index, 1);
    setHands(newHands);
  }

  return (
    <div className="fade-in">
      <div className="page pb-24">
        <div className="flex flex-col gap-2 w-full m-4 p-4">
          {
            hands.length === 0 ? <>
              <Alert text={t('relevant_fields_info')} severity={'info'} />
              <span className={'info'}>{t('split_multiple_label')}</span>
              <input className="input input-primary" placeholder={'[Event'} onChange={(e) => setSplitter(e.target.value)} />
              <Alert text={t('deal_separation_info', {splitter})} severity={'warning'} />
              <div className={'flex flex-col items-start gap-2'}>
                <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
                  <label className="label w-full cursor-pointer p-0 font-bold">
                    {t('suggested_bidding_import_label')}
                    <input
                      type="checkbox"
                      className="toggle-primary toggle"
                      checked={includeBidding}
                      onChange={toggleIncludeBidding}
                    />
                  </label>
                </div>
                <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
                  <label className="label w-full cursor-pointer p-0 font-bold">
                    {t('import_commentary_label')}
                    <input
                      type="checkbox"
                      className="toggle-primary toggle"
                      checked={includeCommentary}
                      onChange={toggleIncludeCommentary}
                    />
                  </label>
                </div>
              </div>
              <textarea className="input input-primary w-full h-96" placeholder={t('paste_pbn_placeholder')} onChange={importPbn} />
            </>
              : <div className="flex flex-col gap-2 items-center justify-center w-full">
                <div className={'flex items-center justify-between w-full'}>
                  <button className={'btn btn-error h-4'} onClick={() => setHands([])}>{t('clear')}</button>
                  <button className={'btn btn-primary h-4'} onClick={handleCreateEventDraft}>{t('create_draft')}</button>
                </div>
                <div className={'flex flex-wrap gap-2 items-center justify-center'}>
                  <input className="input input-primary w-full" placeholder={t('event_name_placeholder')} value={eventName} onChange={(e) => setEventName(e.target.value)} />
                  {
                    hands.map((hand, i) => {
                      return (
                        <div key={i} className={'relative w-full my-4 md:m-4 border-heart-50 border rounded p-2 md:p-4'}>
                          <div className={'absolute top-0 right-0 btn btn-ghost '} onClick={() => removeHand(i)}>
                            <DeleteIcon className={'fill-rose-400'}/>
                          </div>
                          <div className={'grid grid-cols-1 sm:grid-cols-2 w-full'}>
                            <HandDiagram hand={hand.deal} vulnerability={hand.vulnerability} dealer={hand.dealer} />
                            {
                              hand.bidding && <div className={'flex items-center justify-center m-2'}>
                                <BiddingDisplay bidding={hand.bidding} variant={'compact full'}  />
                              </div>
                            }
                            {
                              hand.commentary && <div className={'sm:col-span-2'}>
                                <label htmlFor={'commentary'}> Commentary</label>
                                <textarea id={'commentary'} className={'input mt-2 w-full h-48 input-primary p-2'} value={hand.commentary}
                                  onChange={(e) => handleUpdateCommentary(i, e.target.value)}
                                /></div>
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  );
}

