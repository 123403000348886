import Icon from '../../images/icons/menu.svg?react';

export const MenuIcon = ({ width, color, fill, ...props }) => (
  <Icon
    width={width || '24px'}
    height={width || '24px'}
    color={color || 'white'}
    fill={fill || 'white'}
    {...props}
  />
);
