import { getFunctions } from '../base';
import { httpsCallable } from 'firebase/functions';
export async function submitLiaFeedback({ alert, suggestionAlert }) {
    const functions = getFunctions();
    const func = httpsCallable(functions, 'discordMessage');
    const request = {
        message: `Alert ${alert} should be ${suggestionAlert}`
    };
    await func({ request });
}
export async function submitSessionDealFeedback({ message, url }) {
    const functions = getFunctions();
    const func = httpsCallable(functions, 'discordMessage');
    const request = {
        message,
        url
    };
    await func({ request });
}
