import { Tooltip as RTooltip } from 'react-tooltip';

export default function Tooltip({ id, children, open, sx }) {
  return (
    <RTooltip
      isOpen={open}
      id={id}
      opacity={1}
      className={`z-[999] slated max-w-75 border border-sky-300 ${sx ? ' ' + sx : ''}`}
    >
      {children}
    </RTooltip>
  );
}
