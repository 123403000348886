import { TicketIcon } from '../icons/ticketsIcon';
import { useTranslation } from 'react-i18next'

export default function Price({ price, defaultPrice }) {
  const { t } = useTranslation('translation', { keyPrefix: 'components.price' });
  if (defaultPrice && defaultPrice !== price) {
    if (!price) {
      return (
        <div className="flex items-center gap-2">
          <span className="line-through flex items-center gap-2">
            {t('price')}<TicketIcon />
            &times;{defaultPrice}
          </span>
          {t('free')}
        </div>
      );
    }

    return (
      <div className="flex items-center gap-2">
        {t('price')}<TicketIcon />
        <span className="line-through">&times;{defaultPrice}</span>
        {price}
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2">
      {t('price')}<TicketIcon />
      {price ? <>&times;{price}</> : t('free')}
    </div>
  );
}
