import { useNavigate } from 'react-router-dom'
import OtherChallengeResultCard from '../../../components/challengeCard/otherChallengeCard';

export default function ChallengeHistory({ challengeHistory, firstUserId, header }) {
  const navigate = useNavigate();

  return (
    <div className="mt-2 flex flex-col items-center gap-4">
      {header && <div className="info -mb-3">{header}</div>}
      {challengeHistory.map(function (ch) {
        let me, partner, oppTeam, starsTeam1, starsTeam2;
        if (ch.team1Users) {
          const isTeam1 = !ch.users.includes(firstUserId) || ch.team1Users.includes(firstUserId);
          const myTeam = isTeam1 ? ch.team1Users : ch.team2Users;
          oppTeam = isTeam1 ? ch.team2Users : ch.team1Users;
          const isFirstInTeam = myTeam[0] === firstUserId;
          me = isFirstInTeam ? myTeam[0] : myTeam[1];
          partner = isFirstInTeam ? myTeam[1] : myTeam[0];
          starsTeam1 = isTeam1 ? ch.starsTeam1 : ch.starsTeam2;
          starsTeam2 = isTeam1 ? ch.starsTeam2 : ch.starsTeam1;
        } else {
          me = ch.users[0];
          partner = ch.users[1];
          oppTeam = ch.users.slice(2);
          starsTeam1 = ch.starsTeam1;
          starsTeam2 = ch.starsTeam2;
        }

        return (
          <div key={ch.id} role="button" onClick={() => navigate(`/challenge/${ch.id}`)}>
            <OtherChallengeResultCard
              user1={me}
              user2={partner}
              user3={oppTeam[0]}
              user4={oppTeam[1]}
              starsTeam1={starsTeam1}
              starsTeam2={starsTeam2}
              finished={true}
              ranked={ch.ranked}
              proChallenge={ch.proChallenge}
              links={false}
            />
          </div>
        );
      })}
    </div>
  );
}
