import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import BiddingBox from '../../../components/bidding/biddingBox';
import BiddingDisplay from '../../../components/bidding/biddingDisplay';
import Hand from '../../../components/cards/hand';
import Dropdown from '../../../components/dropdown/dropdown';
import { useAuth } from '../../../util/hooks';
import {
  directionToBid,
  getInitialBidding,
  isBiddingFinished,
  isValidBid,
  removeLastBid,
} from 'cuebids-bidding-util';
import Spade from '../../../images/suits/Spade';
import Heart from '../../../images/suits/Heart';
import Diamond from '../../../images/suits/Diamond';
import Club from '../../../images/suits/Club';
import Alert from '../../../components/alert/alert';
import { replaceSuitShorthandWithSymbols } from '../../../util/symbols';

function isCard(card) {
  if (['x', 'X'].includes(card)) return 'x';
  const newCard = card.toUpperCase();
  if ('AKQJT98765432'.includes(newCard)) {
    return newCard;
  }
  return '';
}

export default function CreateEditSpotForm({
  submitLabel,
  onSubmit,
  initialDealer  = 'W',
  initialVulnerability = 'NONE',
  initialScoring = 'TEAMS',
  initialHand = '...',
  initialDescription = '',
  initialAnswer = '',
  initialBiddingWithoutPositionalSymbols = '',
}) {
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(null);

  const [bidding, setBidding] = useState(initialBiddingWithoutPositionalSymbols);
  const [dealer, setDealer] = useState(initialDealer);
  const [vulnerability, setVulnerability] = useState(initialVulnerability);
  const [scoring, setScoring] = useState(initialScoring);

  const initialHandParts = initialHand.split('.');

  const [spades, setSpades] = useState(initialHandParts[0]);
  const [hearts, setHearts] = useState(initialHandParts[1]);
  const [diamonds, setDiamonds] = useState(initialHandParts[2]);
  const [clubs, setClubs] = useState(initialHandParts[3]);

  const [descriptionHeight, setDescriptionHeight] = useState(96);
  const [description, setDescription] = useState(initialDescription);
  const descriptionRef = useRef(null);

  const [includeAnswer, setIncludeAnswer] = useState(!!initialAnswer);
  const [answerHeight, setAnswerHeight] = useState(96);
  const [answer, setAnswer] = useState(initialAnswer);
  const answerRef = useRef(null);
  const bidButtonRef = useRef(null);
  const [didScroll, setDidScroll] = useState(false);

  const { t } = useTranslation();

  const adjustedBidding = getInitialBidding(dealer) + bidding;
  const turn = directionToBid(adjustedBidding);
  const hand = spades + '.' + hearts + '.' + diamonds + '.' + clubs;
  const handTooShort = hand.length < 16;
  const handTooLong = hand.length > 16;

  useEffect(function () {
    if (initialDescription) {
      resizeDescriptionTextArea();
    }
  }, [initialDescription]);

  useEffect(function () {
    if (initialAnswer) {
      setTimeout(resizeAnswerTextArea, 0);
    }
  }, [initialAnswer]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (handTooShort || handTooLong) {
      return;
    }
    setLoading(true);
    await onSubmit({
      bidding: adjustedBidding,
      dealer,
      vulnerability,
      description,
      answer: includeAnswer ? answer : '',
      scoring,
      hand
    });
    setLoading(false);
  }

  function resizeDescriptionTextArea () {
    descriptionRef.current.style.height = '96px'
    descriptionRef.current.style.height =
      descriptionRef.current.scrollHeight + 'px'
    setDescriptionHeight(descriptionRef.current.scrollHeight)
  }

  function resizeAnswerTextArea () {
    answerRef.current.style.height = '96px'
    answerRef.current.style.height =
      answerRef.current.scrollHeight + 'px'
    setAnswerHeight(answerRef.current.scrollHeight)
  }

  function handleSetDescription(e) {
    const temp = e.target.selectionStart;

    setDescription(replaceSuitShorthandWithSymbols(e.target.value));
    resizeDescriptionTextArea();
    setTimeout(() => e.target.setSelectionRange(temp, temp), 0);
  }

  const handleSetIncludeAnswer = (event) => {
    const newChecked = event.target.checked
    setIncludeAnswer(newChecked);
    if (newChecked) {
      setTimeout(resizeAnswerTextArea, 0);
    }
  };

  function handleSetAnswer(e) {
    const temp = e.target.selectionStart;

    setAnswer(replaceSuitShorthandWithSymbols(e.target.value));
    resizeAnswerTextArea();
    setTimeout(() => e.target.setSelectionRange(temp, temp), 0);
  }

  function undoLastBid() {
    const bid = removeLastBid(bidding);
    setBidding(bid);
  }

  function handleBid(bid) {
    if (isValidBid(bidding, bid) && !isBiddingFinished(bidding + '-' + bid)) {
      setBidding((bidding) => bidding + '-' + bid);
    }
  }

  function sortByCardRank(a, b) {
    const rank = 'AKQJT98765432Xx';
    return rank.indexOf(a) - rank.indexOf(b);
  }

  function suitReducer(suit) {
    return suit
      .split('')
      .reduce((cards, curr) => {
        const card = isCard(curr);
        if (card && (!cards.includes(card) || card.toUpperCase() === 'X')) {
          cards.push(card);
        }
        return cards;
      }, [])
      .sort(sortByCardRank)
      .join('');
  }

  function scrollIfNeeded() {
    if (!didScroll) {
      setTimeout(() => {
        bidButtonRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setDidScroll(true);
      });
    }
  }

  function handleSetSpades(e) {
    const suit = suitReducer(e.target.value);
    setSpades(suit);
    scrollIfNeeded();
  }

  function handleSetHearts(e) {
    const suit = suitReducer(e.target.value);
    setHearts(suit);
    scrollIfNeeded();
  }

  function handleSetDiamonds(e) {
    const suit = suitReducer(e.target.value);
    setDiamonds(suit);
    scrollIfNeeded();
  }

  function handleSetClubs(e) {
    const suit = suitReducer(e.target.value);
    setClubs(suit);
    scrollIfNeeded();
  }

  return (
    <div className="page">
      <div className="flex items-center justify-center gap-2">
        <BiddingDisplay
          bidding={adjustedBidding + '-?'}
          vulnerability={vulnerability}
          variant={'standard'}
          startWithEast={false}
        />
        <div className="flex flex-col items-center">
          <div>
            <label className="label">
              <span className="label-text">{t('bridge.dealer')}</span>
            </label>
            <Dropdown
              size="flex"
              options={[
                {
                  value: 'N',
                  label: t('bridge.directions.N'),
                  component: (
                    <button type="button" onClick={() => setDealer('N')}>
                      {t('bridge.directions.N')}
                    </button>
                  ),
                },
                {
                  value: 'E',
                  label: t('bridge.directions.E'),
                  component: (
                    <button type="button" onClick={() => setDealer('E')}>
                      {t('bridge.directions.E')}
                    </button>
                  ),
                },
                {
                  value: 'S',
                  label: t('bridge.directions.S'),
                  component: (
                    <button type="button" onClick={() => setDealer('S')}>
                      {t('bridge.directions.S')}
                    </button>
                  ),
                },
                {
                  value: 'W',
                  label: t('bridge.directions.W'),
                  component: (
                    <button type="button" onClick={() => setDealer('W')}>
                      {t('bridge.directions.W')}
                    </button>
                  ),
                },
              ]}
              value={dealer}
            />
          </div>
          <div>
            <label className="label">
              <span className="label-text">
                {t('bridge.vulnerability.name')}
              </span>
            </label>
            <Dropdown
              size="flex"
              options={[
                {
                  value: 'NONE',
                  label: t('bridge.vulnerability.NONE'),
                  component: (
                    <button
                      type="button"
                      onClick={() => setVulnerability('NONE')}
                    >
                      {t('bridge.vulnerability.NONE')}
                    </button>
                  ),
                },
                {
                  value: 'NS',
                  label: t('bridge.vulnerability.NS'),
                  component: (
                    <button
                      type="button"
                      onClick={() => setVulnerability('NS')}
                    >
                      {t('bridge.vulnerability.NS')}
                    </button>
                  ),
                },
                {
                  value: 'EW',
                  label: t('bridge.vulnerability.EW'),
                  component: (
                    <button
                      type="button"
                      onClick={() => setVulnerability('EW')}
                    >
                      {t('bridge.vulnerability.EW')}
                    </button>
                  ),
                },
                {
                  value: 'ALL',
                  label: t('bridge.vulnerability.ALL'),
                  component: (
                    <button
                      type="button"
                      onClick={() => setVulnerability('ALL')}
                    >
                      {t('bridge.vulnerability.ALL')}
                    </button>
                  ),
                },
              ]}
              value={vulnerability}
            />
          </div>
          <div>
            <label className="label">
              <span className="label-text">{t('bridge.scoring.name')}</span>
            </label>
            <Dropdown
              size="flex"
              options={[
                {
                  value: 'TEAMS',
                  label: t('bridge.scoring.TEAMS'),
                  component: (
                    <button type="button" onClick={() => setScoring('TEAMS')}>
                      {t('bridge.scoring.TEAMS')}
                    </button>
                  ),
                },
                {
                  value: 'PAIRS',
                  label: t('bridge.scoring.PAIRS'),
                  component: (
                    <button type="button" onClick={() => setScoring('PAIRS')}>
                      {t('bridge.scoring.PAIRS')}
                    </button>
                  ),
                },
                {
                  value: 'BAM',
                  label: t('bridge.scoring.BAM'),
                  component: (
                    <button type="button" onClick={() => setScoring('BAM')}>
                      {t('bridge.scoring.BAM')}
                    </button>
                  ),
                },
                {
                  value: 'RUBBER',
                  label: t('bridge.scoring.RUBBER'),
                  component: (
                    <button
                      type="button"
                      onClick={() => setScoring('RUBBER')}
                    >
                      {t('bridge.scoring.RUBBER')}
                    </button>
                  ),
                },
              ]}
              value={scoring}
            />
          </div>
        </div>
      </div>

      <div className="-my-2">
        <BiddingBox
          bidding={bidding}
          acceptShortcuts={false}
          onBid={handleBid}
        />
      </div>
      {bidding.length > 0 && (
        <button
          type="button"
          className="btn btn-small btn-secondary"
          onClick={undoLastBid}
        >
          {t('spots.undo_bid_button')}
        </button>
      )}
      <form
        onSubmit={handleSubmit}
        className="w-full md:w-2/3 mt-2 flex flex-col gap-4  p-4"
      >
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
          <div className="flex items-center gap-1">
            <Spade />
            <input
              className="input w-24"
              value={spades}
              onChange={handleSetSpades}
              placeholder="AKQ"
            />
          </div>
          <div className="flex items-center gap-1">
            <Heart />
            <input
              className="input w-24"
              value={hearts}
              onChange={handleSetHearts}
              placeholder="JT9"
            />
          </div>

          <div className="flex items-center gap-1">
            <Diamond />
            <input
              className="input w-24"
              value={diamonds}
              onChange={handleSetDiamonds}
              placeholder="876"
            />
          </div>
          <div className="flex items-center gap-1">
            <Club />
            <input
              className="input w-24"
              value={clubs}
              onChange={handleSetClubs}
              placeholder="5432"
            />
          </div>
        </div>
        {hand.length > 3 && (
          <Hand
            keyOverride="hand"
            hand={hand.slice(0, 16)}
            direction={turn}
            handLength={hand.slice(0, 16).length - 3}
          />
        )}

        <div style={{ height: descriptionHeight }} className="w-full">
          <textarea
            id="bid_description_input"
            rows={4}
            className="input h-[96px] w-full resize-none py-2"
            value={description}
            required
            onInvalid={(e) =>
              e.target.setCustomValidity(
                t('spots.warnings.description_required')
              )
            }
            onInput={(e) => e.target.setCustomValidity('')}
            ref={descriptionRef}
            autoComplete="off"
            onChange={handleSetDescription}
            type="text"
            placeholder={t('spots.spot_description_input')}
          />
        </div>

        <label className="label w-full cursor-pointer p-0 font-bold">
          {t('spots.spot_include_answer_input')}
          <input
            type="checkbox"
            className="toggle-primary toggle"
            checked={includeAnswer}
            onChange={handleSetIncludeAnswer}
          />
        </label>

        {includeAnswer && (
          <div style={{ height: answerHeight }} className="w-full">
            <textarea
              id="bid_answer_input"
              rows={4}
              className="input h-[96px] w-full resize-none py-2 -mt-2"
              value={answer}
              ref={answerRef}
              autoComplete="off"
              onChange={handleSetAnswer}
              type="text"
              placeholder={t('spots.spot_answer_input')}
            />
          </div>
        )}

        <div className="flex items-center gap-2 justify-end w-full">
          {currentUser && handTooLong && (
            <Alert
              severity="warning"
              text={t('spots.warnings.too_many_cards')}
            />
          )}
          {currentUser && handTooShort && (
            <Alert
              severity="warning"
              text={t('spots.warnings.too_few_cards')}
            />
          )}
          {!currentUser && (
            <Alert type="info" text={t('spots.warnings.login_to_create')} />
          )}
          <button
            ref={bidButtonRef}
            disabled={!currentUser || loading || handTooLong || handTooShort}
            type="submit"
            className="btn btn-secondary"
          >
            {submitLabel}
          </button>
        </div>
      </form>
    </div>
  );
}
