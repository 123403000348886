
export default function Diamond({ width = 12, dark = false }) {
  return (
    <svg
      width={width}
      height={width * (20 / 21)}
      viewBox="0 0 20 21"
      className={ dark ? 'fill-diamond-100' : 'diamonds'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10.7972L9.7589 20.8659L19.5178 10.7972L9.7589 0.728516L0 10.7972Z"/>
    </svg>
  );
}

