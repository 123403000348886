import i18next from 'i18next'

export default async function goToStore({ nav, isNative, iap, uid, tab }) {
  if (!isNative) {
    const storeUrl = tab ? `/store?tab=${tab}` : '/store';
    nav(storeUrl);
    return;
  }
  if (!iap) {
    alert(
      i18next.t('go_to_store.no_iap_error')
    );
    return;
  }
  if (!uid) {
    return;
  }

  await openIAPStore(uid);
}

export async function goToStoreHeart({ nav, isNative, iap, uid }) {
  if (!isNative) {
    const storeUrl = '/store?tab=sub&sub=heart';
    nav(storeUrl);
    return;
  }
  if (!iap) {
    alert(
      i18next.t('go_to_store.no_iap_error')
    );
    return;
  }
  if (!uid) {
    return;
  }

  await openIAPStoreHeart(uid);
}

export async function goToStoreDiamond({ nav, isNative, iap, uid  }) {
  if (!isNative) {
    const storeUrl = '/store?tab=sub&sub=diamond';
    nav(storeUrl);
    return;
  }
  if (!iap) {
    alert(
      i18next.t('go_to_store.no_iap_error')
    );
    return;
  }
  if (!uid) {
    return;
  }

  await openIAPStoreDiamond(uid);
}

async function openIAPStore(uid) {
  window.ReactNativeWebView.postMessage(
    JSON.stringify({ message: 'openStore',
      uid: uid }),
  );
  await new Promise((r) => setTimeout(r, 2500));
}

async function openIAPStoreHeart(uid) {
  window.ReactNativeWebView.postMessage(
    JSON.stringify({ message: 'buyHeart',
      uid: uid }),
  );
  await new Promise((r) => setTimeout(r, 2500));
}


async function openIAPStoreDiamond(uid) {
  window.ReactNativeWebView.postMessage(
    JSON.stringify({ message: 'buyDiamond',
      uid: uid }),
  );
  await new Promise((r) => setTimeout(r, 2500));
}
