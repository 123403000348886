import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import BiddingDisplay from '../../components/bidding/biddingDisplay';
import { getDeal } from '../../firebase/biddingSessions';
import { getCoachReview } from '../../firebase/dealReviews.js';
import PairDisplay from '../../components/users/pairDisplay.jsx';
import { useQuery } from '@tanstack/react-query';
import { StarRating } from '../../components/rating/starRating.jsx';
import { ContractDisplay } from '../../components/bidding/bidDisplay.jsx';
import {
  getGroupSessionWithSortedDeals
} from '../../firebase/groups.js';
import HandDiagram from '../../components/cards/diagram.jsx';
import { useTranslation } from 'react-i18next'

export default function CoachSessionDealPresentation() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.coach_session_deal_presentation' });
  const { dealId, eventId } = useParams();

  const [currentDealId, setCurrentDealId] = useState(dealId);

  const pageRef = useRef();

  const fullScreen = () => {
    if(document.fullscreenElement) {
      document.exitFullscreen();
      return;
    }
    if (pageRef.current) {
      pageRef.current.requestFullscreen();
    }
  }

  const { data: groupSession } = useQuery({
    queryKey: ['groupSession', eventId],
    queryFn: async () => await getGroupSessionWithSortedDeals(eventId)
  });

  const moveDeal = (forward) => {
    const currentIndex = groupSession.deals.indexOf(currentDealId);
    const totalDeals = groupSession.deals.length;

    let nextIndex;
    if (forward) {
      nextIndex = (currentIndex + 1) % totalDeals;
    } else {
      nextIndex = (currentIndex - 1 + totalDeals) % totalDeals;
    }

    setCurrentDealId(groupSession.deals[nextIndex])
  };

  const { data: deal } = useQuery({
    queryKey: ['deal', currentDealId],
    queryFn: async () => await getDeal(currentDealId)
  });

  const { data: review } = useQuery({
    queryKey: ['review', currentDealId],
    queryFn: async () => await getCoachReview(currentDealId)
  });

  if (!deal || !review || !groupSession) return null;

  const { hand, vulnerability, dealer } = deal;

  return (
    <div className={'flex bg-base-100 flex-col gap-0 md:gap-2 justify-start'} ref={pageRef}>
      <div className={'flex items-center justify-between'}>
        <button className={'btn btn-secondary btn-sm ml-4 mt-4'} onClick={fullScreen}>
          <svg height="14px" version="1.1" viewBox="0 0 14 14" width="14px" xmlns="http://www.w3.org/2000/svg" >
            <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
              <g fill="#000000" id="Core" transform="translate(-215.000000, -257.000000)">
                <g id="fullscreen" transform="translate(215.000000, 257.000000)">
                  <path d="M2,9 L0,9 L0,14 L5,14 L5,12 L2,12 L2,9 L2,9 Z M0,5 L2,5 L2,2 L5,2 L5,0 L0,0 L0,5 L0,5 Z M12,12 L9,12 L9,14 L14,14 L14,9 L12,9 L12,12 L12,12 Z M9,0 L9,2 L12,2 L12,5 L14,5 L14,0 L9,0 L9,0 Z" id="Shape"/>
                </g>
              </g>
            </g>
          </svg>
        </button>
        <h2>{t('title', {number: groupSession.deals.indexOf(currentDealId) + 1})}</h2>

        <div className="flex items-center gap-4 mr-1 md:mr-8 md:gap-8 ">
          <div className="">
            <button className="" onClick={() => moveDeal(false)}>
              <kbd className="kbd text-sm">◀︎</kbd>
            </button>
          </div>

          <div className="">
            <button className="" onClick={() => moveDeal(true)}>
              <kbd className="kbd text-sm">▶︎</kbd>
            </button>
          </div>
        </div>
      </div>

      <div className={'flex items-start origin-top pt-2 gap-4 p-2 flex-wrap'}>

        <HandDiagram hand={hand} dealer={dealer} vulnerability={vulnerability} />

        <div className={'gap-2 flex flex-wrap'}>
          {review.map((r) => {
            return (
              <div key={r.users[0] + r.users[1]} className={'flex flex-col scale-75'}>
                <div className={'flex items-center justify-around'}>
                  <PairDisplay uid1={r.users[0]} uid2={r.users[1]}/>
                  <ContractDisplay contract={r.contract} size={25}/>
                  <StarRating stars={r.grade} size={'sm'}/>
                </div>
                <BiddingDisplay bidding={r.bidding}/>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
}
