import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import Vector from '../../images/bottom-vectors.svg';

const showFooterUrls = ['/login', '/login/:fed', '/join/:referral'];

export default function Footer() {
  const location = useLocation();

  const showFooter = useMemo(
    () => showFooterUrls.some(u => matchPath(u, location.pathname)), 
    [location.pathname]);


  return (
    <img
      className={`absolute bottom-0 ${
        showFooter ? 'z-[999]' : 'z-[1]'
      } max-h-32 w-full`}
      src={Vector}
    />
  );
}
