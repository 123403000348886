
export function replaceSuitShorthandWithSymbols(text) {
  return text.replace(/!c/ig, '♣️')
    .replace(/!d/ig, '♦️')
    .replace(/!h/ig, '♥️')
    .replace(/!s/ig, '♠️')
    .replace(/!n/ig, 'NT');
}


const strainValue = {
  C: 1,
  D: 2,
  H: 3,
  S: 4,
  N: 5,
};

export function parseBidding(bidding, dealVersion) {
  return parseBiddingV3(bidding);
}

export function parseBiddingV3(bidding) {
  if (removeAlerts(bidding).indexOf('-P-P-P-P') > -1) {
    return {
      finalBid: 'P',
      declarer: null,
      doubled: '',
    };
  }
  const bids = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding);

  if (!bids.length) {
    return {
      finalBid: 'P',
      declarer: null,
      doubled: '',
    };
  }

  let counter = 0;
  let finalBid = '';
  let finalBidSuit = '';
  let declarer = '';
  let lastBidFound = false;
  let doubled = '';

  for (let i = bids.length - 1; i >= 0; i--) {
    counter++;
    if (bids[i] === 'P') {
      continue;
    }

    if (!lastBidFound) {
      if (bids[i] === 'D') {
        if (doubled === '') {
          doubled = 'X';
        }
        continue;
      }
      if (bids[i] === 'R') {
        doubled = 'XX';
        continue;
      }

      lastBidFound = true;
      finalBid = bids[i];
      finalBidSuit = finalBid.substring(1);
      declarer = counter;
      continue;
    }

    if (
      bids[i].substring(1) === finalBidSuit &&
            (counter - declarer) % 4 === 2
    ) {
      declarer = counter;
    }
  }

  const declarerIndex = (bids.length - declarer) % 4;

  return {
    finalBid: finalBid,
    declarer: ['W', 'N', 'E', 'S'][declarerIndex],
    doubled,
  };
}

export const disableLogic = (bidding) => {
  const bids = getBidArrayWithoutAlertsExcludingPositionalSymbols(bidding);

  let counter = 0;
  let lastSuitBid = '';
  let opponentBid = false;
  let lastBidWasD = false;
  let lastBidWasSuit = false;
  let lastBidFound = false;

  for (let i = bids.length - 1; i >= 0; i--) {
    counter++;
    if (bids[i] === 'P') {
      continue;
    }

    const hasSuitBid = /\d/.test(bids[i]);

    if (!lastBidFound) {
      lastBidFound = true;
      opponentBid = !(counter % 4 === 2);
      lastBidWasD = bids[i] === 'D';
      lastBidWasSuit = hasSuitBid;
    }

    if (hasSuitBid && !lastSuitBid) {
      lastSuitBid = bids[i];
      break;
    }
  }

  let disableBelow = false; let disableD = false; let disableR = false;
  disableBelow = lastSuitBid;
  if (opponentBid && lastBidWasD) {
    disableD = true;
  } else if (opponentBid && lastBidWasSuit) {
    disableR = true;
  } else {
    disableD = true;
    disableR = true;
  }

  return {
    disableD: disableD,
    disableR: disableR,
    disableBelow: disableBelow,
  };
};

function distinct(value, i, self) {
  const ix = self.findIndex(function (v) {
    return (
      getContractWithoutDeclarer(v.contract) ===
        getContractWithoutDeclarer(value.contract) &&
        getContractDeclaringSide(v.contract) ===
        getContractDeclaringSide(value.contract) &&
        v.stars === value.stars
    );
  });
  return ix === i;
}

export function getContracts(score) {
  let contracts = [];

  if(score.contract) {
    contracts.push({
      contract: score.contract,
      ev: score.ev,
      stars: score.resultGrade,
    });
  }
  if(score.bestContract) {
    contracts.push({
      contract: score.bestContract,
      ev: score.bestContractEv,
      stars: 3,
    });
  }
  if(score.parContract) {
    contracts.push({
      contract: score.parContract,
      ev: score.parEv,
      stars: 3,
    });
  }

  score.otherContracts.forEach((c) => {
    if (c.contract !== 'P') {
      contracts.push({ contract: c.contract, ev: c.ev, stars: c.stars });
    }
  });

  contracts = contracts.filter(distinct).sort((a, b) => b.ev - a.ev);


  return contracts;
}

export function getHighestRobotBid(bidding) {
  if (bidding.indexOf('-P-P-P-P') > -1) {
    return 'P';
  }
  const bids = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding);

  if (!bids.length) {
    return 'P';
  }

  let counter = 0;
  let finalBid = '';
  let finalBidSuit = '';
  let declarer = '';
  let lastBidFound = false;
  let doubled = '';

  for (let i = bids.length - 1; i >= 0; i--) {
    counter++;

    // NS bid
    if ((bids.length - counter) % 2 === 1) {
      if (bids[i] === 'D' && doubled === '') {
        doubled = 'X';
      }
      continue;
    }

    if (bids[i] === 'P' || bids[i] === 'D' || bids[i] === '.') {
      continue;
    }

    if (bids[i] === 'R') {
      doubled = 'XX';
      continue;
    }

    if (!lastBidFound) {
      lastBidFound = true;
      finalBid = bids[i] + doubled;
      finalBidSuit = bids[i].substring(1);
      declarer = counter;
      continue;
    }

    if (
      bids[i].substring(1) === finalBidSuit &&
            (counter - declarer) % 4 === 2
    ) {
      declarer = counter;
    }
  }

  if (!lastBidFound) {
    return 'P';
  }

  const declarerIndex = (bids.length - declarer) % 4;

  return finalBid + ['W', 'N', 'E', 'S'][declarerIndex];
}

export function isBidHigher(bid, bidToCompare) {
  if (['P', 'D', 'R'].includes(bidToCompare)) {
    return !(['P', 'D', 'R'].includes(bid));
  }

  if (['P', 'D', 'R'].includes(bid)) {
    return false;
  }

  if (bid[0] > bidToCompare[0]) {
    return true;
  }

  if (bid[0] === bidToCompare[0] && strainValue[bid[1]] > strainValue[bidToCompare[1]]) {
    return true;
  }

  return false;
}

export function isValidBid(bidding, bid) {
  const { disableD, disableR, disableBelow } = disableLogic(bidding);

  if (bid === 'P') {
    return true;
  }

  if (bid === 'D') {
    return !disableD;
  }

  if (bid === 'R') {
    return !disableR;
  }

  if (!disableBelow) {
    return true;
  }

  return isBidHigher(bid, disableBelow);
}

export function bidArrayToBidding(bidArray) {
  if (!bidArray.length) {
    return '';
  }

  if (bidArray[0] === '.') {
    return bidArray.join('-');
  }

  return '-' + bidArray.join('-');
}

export function removeAlerts(bidding) {
  return bidding.replaceAll(/\[.*?]/gs, '');
}

export function getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding) {
  return removeAlerts(bidding).match(/[^-]+/g) || [];
}

export function getAllSpotsForPair(bidding) {
  return getAllSpotsForDirection(bidding, 'N').concat(getAllSpotsForDirection(bidding, 'S'));
}

export function getAllSpotsForRobotPair(bidding) {
  return getAllSpotsForDirection(bidding, 'E').concat(getAllSpotsForDirection(bidding, 'W'));
}

export function getAllSpotsForDirection(bidding, direction) {
  const directionToIndexMap = {
    'W': 0,
    'N': 1,
    'E': 2,
    'S': 3,
  };

  const arr = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding);
  return arr.filter((b, i) => directionToIndexMap[direction] === (i % 4) && b !== '.');
}

export function getBidArrayWithAlertsIncludingPositionalSymbols(bidding) {
  return bidding.replaceAll(/\[.*?]/g, function(match) {
    return match.replaceAll('-', '—');
  }).match(/[^-]+/g) || [];
}

export function cuebidsToPbn(bid) {
  switch (bid) {
  case 'D':
    return 'X'
  case 'R':
    return 'XX'
  case 'P':
    return 'Pass'
  default:
    return bid.replace('N', 'NT')
  }
}

export function pbnToCuebids(bid) {
  switch (bid) {
  case 'Pass':
    return 'P'
  case 'X':
    return 'D'
  case 'XX':
    return 'R'
  default:
    return bid.replace('NT', 'N')
  }
}

export function getAlert(bid) {
  let explanation = '';

  const alert = bid.indexOf('[');
  const alertA = bid.indexOf('[@') > -1;

  if (alert > -1) {
    explanation = bid.substring(
      alert + (alertA ? 3 : 2),
      bid.length - 2
    );
    return replaceSuitShorthandWithSymbols(explanation);
  }
  return '';
}

export function getAlertAndExplain(bid) {
  let explanation = '';

  const alert = bid.indexOf('[');
  const alertA = bid.indexOf('[@') > -1;

  if (alert > -1) {
    explanation = bid.substring(
      alert + (alertA ? 3 : 2),
      bid.length - 2
    );
    explanation = replaceSuitShorthandWithSymbols(explanation);
  }
  return {
    explain: alertA ? '' : explanation,
    alert: alertA ? explanation : '',
  };
}

// Note: Alert in this case is with @, in other cases it's just any explanation
export function hasAlert(bid) {
  const explainStartIndex = bid.indexOf('[');
  if (explainStartIndex !== -1 && bid.substring(explainStartIndex + 1, explainStartIndex + 2) === '@') {
    return true;
  }

  return false;
}

export function getBidArrayWithoutAlertsExcludingPositionalSymbols(bidding) {
  return getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding).filter((b) => b !== '.');
}

export function getBidArrayWithAlertsExcludingPositionalSymbols(bidding) {
  return getBidArrayWithAlertsIncludingPositionalSymbols(bidding).filter((b) => b !== '.');
}

export function getSuitAndNtBids(bidding) {
  return getBidArrayWithoutAlertsExcludingPositionalSymbols(bidding).filter((b) => b.match(/\d./g));
}

export function getOpeningBid(bidding) {
  return getSuitAndNtBids(bidding)[0];
}

export function getLastBid(bidding) {
  const biddingArray = getBidArrayWithoutAlertsExcludingPositionalSymbols(bidding);
  return biddingArray[biddingArray.length - 1];
}

// The denomination of the current highest bid, e.g. N after 1D-2N-P
export function getCurrentDenomination(bidding) {
  const suitAndNtBids = getSuitAndNtBids(bidding);
  return suitAndNtBids.length ? suitAndNtBids[suitAndNtBids.length - 1][1] : undefined;
}

// Position is 1-indexed, (1,2,3,4)
export function getPositionForNextBidder(bidding) {
  return (getBidArrayWithoutAlertsExcludingPositionalSymbols(bidding).length % 4) + 1;
}

export function directionToBid(bidding) {
  const directionToIndexMap = {
    0: 'W',
    1: 'N',
    2: 'E',
    3: 'S',
  };

  const biddingLength = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding).length;

  return directionToIndexMap[biddingLength % 4];
}

export function isDirectionInTurn(bidding, direction) {
  const directionToIndexMap = {
    'W': 0,
    'N': 1,
    'E': 2,
    'S': 3,
  };

  const biddingLength = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding).length;

  // Bidding length 1 means North is next, and he has index 1 and so on
  return (biddingLength % 4) === directionToIndexMap[direction];
}

export function isBiddingFinished(bidding) {
  const strippedBidding = getBidArrayWithoutAlertsExcludingPositionalSymbols(bidding).join('-');
  return /\d/.test(strippedBidding) ? strippedBidding.substring(strippedBidding.length - 6) === '-P-P-P' : strippedBidding.indexOf('P-P-P-P') === 0;
}

export function removeLastBid(bidding) {
  // Bidding hasn't started yet - nothing to remove
  if (!bidding || bidding.endsWith('.')) {
    return bidding;
  }

  const bids = getBidArrayWithAlertsIncludingPositionalSymbols(bidding);
  bids.pop();

  if (!bids.length) {
    return '';
  }

  // To handle when West dealer, the bidding should start with -bid, in other cases .-bid
  return (bidding.startsWith('-') ? '-' : '') + bids.join('-');
}

export function getRobotBids(bidding) {
  const bids = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding);

  return bids.filter((b, i) => !(b === 'P' || b === '.' || i % 2 !== 0));
}

export function getBiddingRound(bidding) {
  const bids = getBidArrayWithoutAlertsExcludingPositionalSymbols(bidding);

  return Math.floor(bids.length / 4) + 1
}

export function getInitialBidding(dealer) {
  return {
    N: '.',
    E: '.-.',
    S: '.-.-.',
    W: '',
  }[dealer];
}

export function getOpeningBidWithIndex(bidding) {
  const bids = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding);

  if (!bids || bids.length === 0) {
    return { bid: 'P',
      index: -1 };
  }

  for (let i = 0; i < bids.length; i++) {
    if (bids[i] === 'P' || bids[i] === '.') {
      continue;
    }

    return { bid: bids[i],
      index: i };
  }

  return { bid: 'P',
    index: -1 };
}

export function getRobotBidCount(bidding) {
  const bids = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding);

  let count = 0;

  if (!bids || bids.length === 0) {
    return count;
  }

  for (let i = 0; i < bids.length; i++) {
    if (bids[i] === 'P' || bids[i] === '.' || i % 2 !== 0) {
      continue;
    }

    count++;
  }

  return count;
}

export function rollBackBiddingToBid(bidding, bid) {
  const bidArray = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding);
  const rolledBackBidArray = bidArray.slice(0, bidArray.indexOf(bid) + 1);
  return bidArrayToBidding(rolledBackBidArray);
}

export function getBidLevel(bid) {
  if (['P', 'D', 'R'].includes(bid)) {
    return null;
  }
  return parseInt(bid[0], 10);
}

export function getBidSuit(bid) {
  if (['P', 'D', 'R'].includes(bid)) {
    return null;
  }
  return bid[1];
}

export function contractFromOldStyle({ finalBid, declarer, doubled }) {
  if (doubled === true) {
    doubled = 'X';
  }
  if (doubled === false) {
    doubled = '';
  }

  if (finalBid === 'P') {
    return 'P';
  }

  return '' + finalBid + doubled + declarer;
}

// TODO: Should this be truthy or not for redoubled contracts?
export function isContractDoubled(contract) {
  return contract.slice(2, 3) === 'X';
}

export function isContractRedoubled(contract) {
  return contract.slice(2, 4) === 'XX';
}

export function getContractStrain(contract) {
  return contract.slice(1, 2);
}

export function getContractLevel(contract) {
  return contract.slice(0, 1);
}

export function getContractDeclarer(contract) {
  return contract.slice(-1);
}

export function getContractDeclaringSide(contract) {
  return ['N', 'S'].includes(getContractDeclarer(contract)) ? 'NS' : 'EW';
}

export function getContractLevelAndStrain(contract) {
  return contract.slice(0, 2);
}

export function getContractDoubled(contract) {
  return contract.slice(0, 2) + 'X' + contract.slice(-1);
}

export function getContractRedoubled(contract) {
  return contract.slice(0, 2) + 'XX' + contract.slice(-1);
}

export function getContractUndoubled(contract) {
  return contract.slice(0, 2) + contract.slice(-1);
}

export function getContractWithoutDeclarer(contract) {
  return contract.slice(0, -1);
}

export function replaceAlert({ bidding, index, alert, artificial }) {
  let bidArray = getBidArrayWithAlertsIncludingPositionalSymbols(bidding);
  let bid = bidArray[index]
  bid = removeAlerts(bid);
  if (artificial) {
    bid = `${bid}[@"${alert}"]`;
  } else {
    bid = `${bid}["${alert}"]`;
  }
  bidArray[index] = bid;
  return bidArrayToBidding(bidArray);
}
