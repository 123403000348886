import ConventionCardDisplay from './conventionCardDisplay';
import Dialog from '../dialog';
import { ConventionCardIcon } from '../icons/conventionCardIcon';
import { useTranslation } from 'react-i18next';

export default function ConventionCardDialog({ base, ob, cc }) {
  const { t } = useTranslation();
  return (
    <div>
      <label htmlFor="cc-dialog" className="btn-ghost btn">
        <ConventionCardIcon fill="#e29627" width="24px" />
      </label>
      <Dialog id="cc-dialog" title={t('convention_card.title')}>
        <ConventionCardDisplay base={base} ob={ob} cc={cc} />
      </Dialog>
    </div>
  );
}
