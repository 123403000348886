import { useState } from 'react';
import {
  updateScenario,
  requestDealTypeSample,
  saveNewScenario,
  getExistingGenerationRequest,
  validateHasChangedConstraints,
  directionAndSettingsToDirections,
} from 'cuebids-firebase/dealGeneration'
import DirectionHandTypes from './directionHandTypes.jsx';
import Dialog from '../dialog';
import { getHandFromDirection } from 'cuebids-hand-util';
import Hand from '../cards/hand.jsx';
import { ArrowBackIcon } from '../icons/arrowBackIcon.jsx';
import { ArrowForwardIcon } from '../icons/arrowForwardIcon.jsx';
import { useNavigate } from 'react-router-dom'
import { useAppStateStore } from '../../appStateStore.jsx'
import { useActiveSubs, useAuth } from '../../util/hooks.jsx';
import { isSubEnough } from '../../util/sub.js';
import Alert from '../alert/alert.jsx';
import DirectionSettings from './directionSettings.jsx'
import InfoPopup from '../infoPopup/infoPopup.jsx';
import goToStore from '../../util/goToStore.js';
import useConfirm from '../../hooks/confirm.jsx'
import { validateVariables, validateVulnerabilities } from '../../util/scenario.js'
import { capitalizeFirstLetter } from '../../util/text.js'
import DealerVulnerabilityDiagram from '../cards/dealerVulnerabilityDiagram.jsx'
import { UserDisplaySmallRight } from '../users/userDisplay.jsx'
import { replaceSuitShorthandWithSymbols } from 'cuebids-bidding-util'
import useNotifications from '../notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next';

function hasSettingsConstraints(settings) {
  return settings.nsHcpRange && (settings.nsHcpRange[0] > 0 || settings.nsHcpRange[1] < 40);
}

function hasHandTypeConstraints(handTypes) {
  return handTypes.length && handTypes.some(h => h.constraints != null);
}

export default function Constraints({
  id,
  parentId,
  parentCreatorUid,
  description,
  name = '',
  north = [],
  west = [],
  east = [],
  south = [],
  settings: initialSettings = {
    dealer: 'N',
    robots: 'NONE',
  },
}) {
  const [dealTypeName, setDealTypeName] = useState(name);
  const [generationRequest, setGenerationRequest] = useState();
  const [dealIndex, setDealIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [handTypes, setHandTypes] = useState({
    north,
    west,
    east,
    south,
  });
  const [settings, setSettings] = useState(initialSettings);
  const navigate = useNavigate();
  const isNative = useAppStateStore((state) => state.isNative);
  const iap = useAppStateStore((state) => state.iap);
  const user = useAppStateStore((state) => state.user);
  const notify = useNotifications();
  const { currentUser } = useAuth();
  const metadata = useAppStateStore((state) => state.metadata);
  const hasMaintenance = metadata?.maintenanceScenarios

  const { t } = useTranslation('translation', { keyPrefix: 'scenarios' });

  const userActiveSubs = useActiveSubs();
  const canUserCreateType = isSubEnough(userActiveSubs, 'heart');

  const [ConfirmEditScenario, confirmEditScenario] = useConfirm(
    'scenarios.edit_confirm_title',
    'scenarios.edit_confirm',
    'primary',
    'secondary',
  );

  function onAddSettings(newSettings) {
    setSettings(s => {
      const vars = {
        ...(newSettings.variables || s.variables || {}),
      }
      const res = {
        ...s,
        ...newSettings,
        variables: vars
      };

      if (res.robots === 'NONE') {
        setHandTypes((curr) => {
          return {
            ...curr,
            east: [],
            west: [],
          };
        });
      }

      return res;
    });
  }

  function handleAdd(dir, handType) {
    setHandTypes((curr) => {
      const isCommand = handType.command != null;

      if (isCommand) {
        if (['north', 'south'].includes(dir)) return curr;

        return {
          ...curr,
          [dir]: curr[dir].filter(h => h.command == null).concat({
            name: handType.name,
            command: handType.command,
            premise: handType.premise ?? null,
            label: handType.label
          })
        }
      }

      return {
        ...curr,
        [dir]: curr[dir].concat({
          name: handType.name,
          constraints: handType.constraints.map(c => ({ name: c.name, args: c.args })),
          label: handType.label
        })
      }
    });
  }

  function handleRemove(dir, i) {
    setHandTypes((curr) => {
      const temp = [...curr[dir]]
      temp.splice(i, 1)
      return {
        ...curr,
        [dir]: temp
      }
    });
  }

  async function handleEditCustom(dir, i, handType) {
    setHandTypes((curr) => {
      const temp = [...curr[dir]]
      temp.splice(i, 1, handType)
      return {
        ...curr,
        [dir]: temp
      }
    });
  }

  function getAddFunction(dir) {
    return (handType) => handleAdd(dir, handType);
  }

  function getRemoveFunction(dir) {
    return (i) => handleRemove(dir, i);
  }

  function getEditFunction(dir) {
    return (i, handType) => handleEditCustom(dir, i, handType);
  }

  function getAddNewCustomFunction(dir) {
    return async function (handType) {
      handleAdd(dir, handType);
    }
  }

  function handleClose() {
    if (isSaving) return;

    setLoading(false);
    setGenerationRequest(undefined);
  }

  async function validateConstraints() {
    const directions = ['north', 'south', 'east', 'west'];
    const request = { settings: { flip: settings.flip } };

    if ((settings.dealer ?? 'any') !== 'any') {
      request.settings.dealer = settings.dealer;
    }
    if ((settings.vulnerability ?? 'any') !== 'any') {
      request.settings.vulnerability = settings.vulnerability;
    }
    if (settings.nsHcpRange != null) {
      request.settings.ns_hp_sum = settings.nsHcpRange;
    }

    const requestSettingsVariables = {};
    if (settings.variables) {
      Object.keys(settings.variables).forEach(function (variable) {
        const suits = settings.variables[variable];
        if (suits.length > 0) {
          requestSettingsVariables[variable] = suits;
        }
      });
      if (Object.keys(requestSettingsVariables).length > 0) {
        request.settings.variables = requestSettingsVariables;
      }
    }

    for (const direction of directions) {
      if (handTypes[direction].filter(h => h.constraints != null).length > 0) {
        request[direction] = {
          hand_types: handTypes[direction].filter(h => h.constraints != null).map(({ constraints }) => {
            return { constraints: constraints.map(c => ({ name: c.name, args: c.args })) }
          })
        }
      }
    }

    let url = import.meta.env.VITE_ENDPLAYS_API_URL

    return await requestDealTypeSample(url, request);
  }

  async function saveScenarioAfterValidation(shouldUpdate) {
    setIsSaving(true);
    try {
      if (shouldUpdate) {
        await updateScenario(id, {
          name: dealTypeName,
          directions: handTypes,
          settings,
          parentId
        });
        notify(
          {
            type: 'success',
            text: t('scenario_edited_success')
          },
        );
      } else {
        await saveNewScenario({
          name: name === dealTypeName ? t('scenario_edit_new_name', {name: dealTypeName}) : dealTypeName,
          directions: handTypes,
          settings,
        });
        notify(
          {
            type: 'success',
            text: t('scenario_created_success')
          },
        );
      }
      navigate('/manageDealTypes', { replace: true });
    } catch (e) {
      notify(
        {
          type: 'failure',
          text: t('scenario_error')
        },
      );
    } finally {
      setIsSaving(false);
    }
  }

  async function handleSaveScenarioAfterTest(shouldUpdate) {
    if (shouldUpdate) {
      const existingGenerationRequestId = await getExistingGenerationRequest(id);
      if (existingGenerationRequestId) {
        notify(
          {
            type: 'failure',
            text: t('generation_ongoing_error')
          },
        );
        return;
      }

      const ans = await confirmEditScenario();
      if (!ans) return;
    }

    if (parentId) {
      const isValid = await validateHasChangedConstraints(directionAndSettingsToDirections(handTypes, settings), parentId);
      if (!isValid) {
        notify(
          {
            text: t('no_changes_error'),
            type: 'failure',
          },
        );
        return;
      }
    }

    return saveScenarioAfterValidation(shouldUpdate);
  }

  async function handleSaveScenario(shouldUpdate) {
    if (loading) {
      return
    }
    if (!hasAnyConstraints) {
      notify(
        {
          type: 'failure',
          text: t('no_constraints_error')
        },
      );
      return;
    }
    if (!dealTypeName.length) {
      notify(
        {
          type: 'failure',
          text: t('no_name_error')
        },
      );
      return;
    }
    if (!canUserCreateType) {
      notify(
        {
          type: 'failure',
          text: t('invalid_user_error')
        },
      );
      return;
    }

    const incorrectVars = validateVariables(handTypes, settings);
    if (incorrectVars.length > 0) {
      notify(
        {
          type: 'failure',
          text: t('invalid_vars_error', {incorrectVars: incorrectVars.join(', ')}),
        },
      );
      return;
    }

    const incorrectVulDirs = validateVulnerabilities(handTypes, settings);
    if (incorrectVulDirs.length > 0) {
      notify(
        {
          type: 'failure',
          text: t(incorrectVulDirs.length > 1 ? 'invalid_vuls_error_plural' : 'invalid_vuls_error_singular', {invalidDirs: incorrectVulDirs.map(capitalizeFirstLetter).join(', ')}),
        },
      );
      return;
    }

    if (shouldUpdate) {
      const existingGenerationRequestId = await getExistingGenerationRequest(id);
      if (existingGenerationRequestId) {
        notify(
          {
            type: 'failure',
            text: t('generation_ongoing_error')
          },
        );
        return;
      }

      const ans = await confirmEditScenario();
      if (!ans) return;
    }

    if (parentId) {
      const isValid = await validateHasChangedConstraints(directionAndSettingsToDirections(handTypes, settings), parentId);
      if (!isValid) {
        notify(
          {
            text: t('no_changes_error'),
            type: 'failure',
          },
        );
        return;
      }
    }

    try {
      setLoading(true);
      await validateConstraints();
      await saveScenarioAfterValidation(shouldUpdate);
    } catch (e) {
      if (e.code === 'functions/deadline-exceeded') {
        notify(
          {
            type: 'failure',
            text: t('too_rare_error')
          },
        );
      } else {
        notify(
          {
            type: 'failure',
            text: t('scenario_error')
          },
        );
      }
    } finally {
      setLoading(false);
    }
  }

  async function testConstraints () {
    if (loading) return;

    if (!hasAnyConstraints) {
      notify(
        {
          type: 'failure',
          text: t('no_constraints_error')
        },
      );
      return;
    }

    if (!canUserCreateType) {
      notify(
        {
          type: 'failure',
          text: t('invalid_user_error')
        },
      );
    }

    const incorrectVars = validateVariables(handTypes, settings);
    if (incorrectVars.length > 0) {
      notify(
        {
          type: 'failure',
          text: t('invalid_vars_error', {incorrectVars: incorrectVars.join(', ')}),
        },
      );
      return;
    }

    const incorrectVulDirs = validateVulnerabilities(handTypes, settings);
    if (incorrectVulDirs.length > 0) {
      notify(
        {
          type: 'failure',
          text: t(incorrectVulDirs.length > 1 ? 'invalid_vuls_error_plural' : 'invalid_vuls_error_singular', {invalidDirs: incorrectVulDirs.map(capitalizeFirstLetter).join(', ')}),
        },
      );
      return;
    }

    setLoading(true)
    setDealIndex(0)

    try {
      const deals = await validateConstraints()
      setGenerationRequest({
        deals: deals.map((d) => ({
          hand: d.pbn,
          dealer: d.dealer,
          vulnerability: d.vulnerability,
        }))
      })
    } catch (e) {
      if (e.code === 'functions/deadline-exceeded') {
        notify(
          {
            type: 'failure',
            text: t('too_rare_error')
          },
        )
      } else {
        notify(
          {
            type: 'failure',
            text: t('scenario_error')
          },
        )
      }
    } finally {
      setLoading(false);
    }
  }

  const hasAnyConstraints = hasHandTypeConstraints(handTypes.north)
    || hasHandTypeConstraints(handTypes.south)
    || hasHandTypeConstraints(handTypes.west)
    || hasHandTypeConstraints(handTypes.east)
  || hasSettingsConstraints(settings);

  const hasMultipleCommands = handTypes.north.concat(handTypes.south).concat(handTypes.west).concat(handTypes.east).filter(h => h.command != null).length >= 2;

  return (
    <div className='page'>
      <div className={'flex gap-8 items-center'}>
        <div className='flex flex-col max-w-75'>
          <label htmlFor='scenarioname'>{t('scenario_name')}</label>
          <input
            id='scenarioname'
            type='text'
            className='input input-primary'
            value={dealTypeName}
            onChange={(e) => setDealTypeName(e.target.value)}
          />
        </div>
        <InfoPopup
          id="deal-type-name"
          sx="w-5"
        >
          <span className={'info'}>
            {t('explain')}
          </span>
        </InfoPopup>
      </div>
      {parentCreatorUid && (
        <div className="flex items-center mt-2">
          <span className={'info mr-1'}>by</span>
          <UserDisplaySmallRight uid={parentCreatorUid} />
        </div>
      )}
      {description && (
        <div className="info max-w-lg mt-1">
          {replaceSuitShorthandWithSymbols(description)}
        </div>
      )}

      <div className='w-full md:w-4/5 grid grid-cols-3 gap-4 place-items-center mt-4'>
        <div /* for grid structure */ />
        <DirectionHandTypes
          dir='north'
          isDealer={settings.dealer === 'N'}
          isRhoDealer={settings.dealer === 'W'}
          selectedHandTypes={handTypes.north}
          onAdd={getAddFunction('north')}
          onAddPartner={getAddFunction('south')}
          onAddLho={getAddFunction('east')}
          onAddRho={getAddFunction('west')}
          onAddSettings={onAddSettings}
          onRemove={getRemoveFunction('north')}
          onEditCustom={getEditFunction('north')}
          onAddNewCustom={getAddNewCustomFunction('north')}
          variables={settings.variables ?? {}}
          robots={settings.robots ?? (settings.disableRobots ? 'NONE' : 'ADVANCED')}
        />
        <div /* for grid structure */ />

        <DirectionHandTypes
          dir='west'
          isRobot
          isDealer={settings.dealer === 'W'}
          isRhoDealer={settings.dealer === 'S'}
          selectedHandTypes={handTypes.west}
          onAdd={getAddFunction('west')}
          onAddPartner={getAddFunction('east')}
          onAddLho={getAddFunction('north')}
          onAddRho={getAddFunction('south')}
          onAddSettings={onAddSettings}
          onRemove={getRemoveFunction('west')}
          onEditCustom={getEditFunction('west')}
          onAddNewCustom={getAddNewCustomFunction('west')}
          variables={settings.variables ?? {}}
          robots={settings.robots ?? (settings.disableRobots ? 'NONE' : 'ADVANCED')}
        />
        <DirectionSettings
          settings={settings}
          onUpdateSettings={onAddSettings}
        />
        <DirectionHandTypes
          dir='east'
          isRobot
          isDealer={settings.dealer === 'E'}
          isRhoDealer={settings.dealer === 'N'}
          selectedHandTypes={handTypes.east}
          onAdd={getAddFunction('east')}
          onAddPartner={getAddFunction('west')}
          onAddLho={getAddFunction('south')}
          onAddRho={getAddFunction('north')}
          onAddSettings={onAddSettings}
          onRemove={getRemoveFunction('east')}
          onEditCustom={getEditFunction('east')}
          onAddNewCustom={getAddNewCustomFunction('east')}
          variables={settings.variables ?? {}}
          robots={settings.robots ?? (settings.disableRobots ? 'NONE' : 'ADVANCED')}
        />

        <div /* for grid structure */ />
        <DirectionHandTypes
          dir='south'
          isDealer={settings.dealer === 'S'}
          isRhoDealer={settings.dealer === 'E'}
          selectedHandTypes={handTypes.south}
          onAdd={getAddFunction('south')}
          onAddPartner={getAddFunction('north')}
          onAddLho={getAddFunction('west')}
          onAddRho={getAddFunction('east')}
          onAddSettings={onAddSettings}
          onRemove={getRemoveFunction('south')}
          onEditCustom={getEditFunction('south')}
          onAddNewCustom={getAddNewCustomFunction('south')}
          variables={settings.variables ?? {}}
          robots={settings.robots ?? (settings.disableRobots ? 'NONE' : 'ADVANCED')}
        />
        <div /* for grid structure */ />
      </div>
      {
        !hasAnyConstraints &&
          <Alert severity='warning' text={t('no_constraints_warning')} />
      }
      {
        !dealTypeName.length &&
          <Alert severity='warning' text={t('no_name_warning')} />
      }
      {
        hasMultipleCommands &&
          <Alert severity='warning' text={t('multiple_commands_warning')} />
      }
      {hasMaintenance && (
        <Alert
          severity="warning"
          text={t('maintenance_warning')}
        />
      )}
      <div className="flex gap-4 items-center mt-4">
        <button
          className='btn btn-secondary'
          onClick={testConstraints}
          disabled={loading || !hasAnyConstraints || hasMaintenance}>
          {t('test_scenario')}
        </button>
        {id && (
          <button
            className="btn btn-primary"
            onClick={() => handleSaveScenario(true)}
            disabled={loading || !dealTypeName.length || !hasAnyConstraints || !canUserCreateType || hasMultipleCommands || hasMaintenance}
          >
            {t('save_edit')}
          </button>
        )}
        <button
          className="btn btn-primary"
          onClick={() => handleSaveScenario(false)}
          disabled={loading || !dealTypeName.length || !hasAnyConstraints || !canUserCreateType || hasMultipleCommands || hasMaintenance}
        >
          {id ?  t('save_as_new') :  t('save')}
        </button>
      </div>
      {!canUserCreateType && (
        <div className={'flex flex-col items-center'}>
          <Alert
            sx="mt-4"
            severity="error"
            text={t('invalid_user_warning')}
          />
          <button
            className={'btn btn-secondary mt-4'}
            onClick={() => goToStore({
              nav: navigate,
              isNative,
              iap,
              uid: currentUser.uid,
              tab: 'sub',
            })}
          >
            {t('subscribe_here')}
          </button>
        </div>
      )}
      {(loading || generationRequest?.deals ) && (
        <Dialog
          id='generation-request'
          open={true}
          onClose={handleClose}
          title={t('testing_contraints_dialog_title')}
        >
          {generationRequest?.deals && !isSaving ? (
            <>
              <div className='w-full flex justify-around'>
                <button
                  className="btn-primary btn-sm btn h-8"
                  disabled={dealIndex === 0}
                  onClick={() =>
                    setDealIndex((n) => {
                      return Math.max(0, n - 1);
                    })
                  }
                >
                  <ArrowBackIcon width={20}/>
                </button>
                <button
                  className="btn-primary btn-sm btn h-8"
                  disabled={dealIndex === 2}
                  onClick={() =>
                    setDealIndex((n) => {
                      return Math.min(2, n + 1);
                    })
                  }
                >
                  <ArrowForwardIcon width={20}/>
                </button>
              </div>
              <div className="flex flex-col items-center gap-4">
                <div className={'grid grid-cols-3 grid-rows-3 gap-4'}>
                  <div />
                  <Hand variant={user?.cardVariant === 'diagram' ? 'diagram' : 'modern'} hand={getHandFromDirection(generationRequest.deals[dealIndex].hand, 'N')}/>
                  <div />

                  <Hand variant={'diagram'} hand={getHandFromDirection(generationRequest.deals[dealIndex].hand, 'W')}/>
                  <DealerVulnerabilityDiagram vulnerability={generationRequest.deals[dealIndex].vulnerability} dealer={generationRequest.deals[dealIndex].dealer} />
                  <Hand variant={'diagram'} hand={getHandFromDirection(generationRequest.deals[dealIndex].hand, 'E')}/>

                  <div />
                  <Hand variant={user?.cardVariant === 'diagram' ? 'diagram' : 'modern'} hand={getHandFromDirection(generationRequest.deals[dealIndex].hand, 'S')}/>
                  <div />
                </div>
                <div className="flex items-center gap-4">
                  <button className="btn btn-secondary" onClick={handleClose}>{t('testing_contraints_dialog_cancel')}</button>
                  {id && (
                    <button
                      className="btn btn-primary"
                      disabled={loading || !dealTypeName.length || !hasAnyConstraints || !canUserCreateType}
                      onClick={() => handleSaveScenarioAfterTest(true)}
                    >
                      {t('testing_contraints_dialog_save_edit')}
                    </button>
                  )}
                  <button
                    className="btn btn-primary"
                    disabled={loading || !dealTypeName.length || !hasAnyConstraints || !canUserCreateType}
                    onClick={() => handleSaveScenarioAfterTest(false)}
                  >
                    {id ? t('testing_contraints_dialog_save_new') : t('testing_contraints_dialog_save')}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className='w-full h-full flex flex-col justify-center items-center'>
              <span className="loading loading-ring loading-lg" />
              <Alert text={<span>{t('testing_contraints_dialog_generate_info_pre_styled')}<div className={'badge badge-secondary badge-sm m-2 font-bold p-2'}>{t('testing_contraints_dialog_generate_info_styled')}</div>{t('testing_contraints_dialog_generate_info_post_styled')}</span>} />
            </div>
          )}
        </Dialog>
      )}
      <ConfirmEditScenario />
    </div>
  )
}
