import { GroupsIcon } from '../icons/groupsIcon';
import { SettingsIcon } from '../icons/settingsIcon';

import { Link } from 'react-router-dom';
import { logoMap } from '../../pages/groups/logoMap.js';
import { useTranslation } from 'react-i18next'

export default function GroupHeader({
  name,
  description,
  links,
  groupId,
  withSettings,
  premium,
  groupFlag,
  logo,
}) {
  const { t } = useTranslation();
  let header;


  const groupImage = logo && logoMap[logo] ? logoMap[logo] : null;
  const image = groupImage && <img src={groupImage} alt={name} className="w-44 bg-white border-2 border-black p-2 rounded-xl bg-opacity-75" />


  const groupIcon = groupFlag ? (
    <span className={`fi fi-${groupFlag}`}></span>
  ) : (
    <GroupsIcon className="robot w-8" />
  )

  if (links) {
    header = (
      <Link to={`/groups/${groupId}`}>
        <div className="flex items-center gap-2 mb-2 px-2">
          {image ? image : <>{groupIcon}<div className="text-3xl">{name}</div></>}
          <div className="w-8" />
        </div>
      </Link>
    );
  } else if (withSettings) {
    header = (
      <Link to={`/groups/${groupId}/manage`}>
        <div className="flex items-center gap-2 mb-2 px-2">
          {image ? image :  <>{groupIcon}<div className="text-3xl">{name}</div></>}
          <SettingsIcon className="fill-gray-400 w-8" />
        </div>
      </Link>
    );
  } else {
    header = (
      <div className="flex items-center gap-2 mb-2 px-2">
        {image ? image :  <>{groupIcon}<div className="text-3xl">{name}</div></>}
        <div className="w-8" />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center text-center">
      {header}
      {description && <div className="text-xl mb-2 px-2">{description}</div>}
      {premium && <div className="badge badge-success">{t('components.group_header.premium_group_badge')}</div>}
    </div>
  );
}
