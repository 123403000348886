import { useRef, useState } from 'react';
import Animated from '../animation/animated';
import BidDisplay from '../bidding/bidDisplay';
import {
  UserDisplayAvatarSmall,
  UserDisplayNameSmallRight,
  UserDisplaySmallRight,
} from '../users/userDisplay';
import { LikeIcon } from '../icons/likeIcon';
import { useAuth } from '../../util/hooks.jsx';
import { ChatIcon } from '../icons/chatIcon';
import { replaceSuitShorthandWithSymbols } from '../../util/symbols';
import { DeleteIcon } from '../icons/deleteIcon';
import { isToday } from '../../util/dates';
import { replyToBid,   deleteReply,
  likeResponse,
  unLikeResponse, } from 'cuebids-firebase/spots';

export default function BidSpotCard({
  spotId,
  t,
  i,
  response,
  fed,
}) {
  const { currentUser } = useAuth();
  const [replyText, setReplyText] = useState('');
  const [isReplying, setIsReplying] = useState(false);

  const inputRef = useRef(null);
  const [height, setHeight] = useState(48);

  const { id, bid, uid, text, timestamp, likes, replies } = response;

  const dateTime = timestamp?.toDate();
  const displayDate = isToday(dateTime)
    ? dateTime?.toLocaleTimeString(t('locale'), {
      hour: '2-digit',
      minute: '2-digit',
    })
    : dateTime?.toLocaleDateString(t('locale'));

  const likeCount = likes?.length || 0;
  const iLike = likes?.includes(currentUser.uid);

  const replyCount = replies?.length || 0;

  async function like() {
    !iLike
      ? await likeResponse(currentUser.uid, spotId, id)
      : await unLikeResponse(currentUser.uid, spotId, id);
  }

  function handleChangeText(e) {
    const temp = e.target.selectionStart;

    setReplyText(replaceSuitShorthandWithSymbols(e.target.value));
    resizeTextArea();
    setTimeout(() => e.target.setSelectionRange(temp, temp), 0);
  }

  function resizeTextArea() {
    inputRef.current.style.height = '48px';
    inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    setHeight(inputRef.current.scrollHeight);
  }

  async function reply() {
    setIsReplying((prev) => !prev);
  }

  async function handleDeleteReply(replyObject) {
    await deleteReply(spotId, id, replyObject);
  }

  async function replyConfirm(e) {
    e.preventDefault();
    await replyToBid(
      currentUser.uid,
      spotId,
      id,
      replyText,
      fed,
      uid,
      replies ?? [],
      likes ?? [],
      t
    );

    setReplyText('');
  }

  const bidSpotDisplay = () => {
    return (
      <>
        <td>
          <BidDisplay bid={bid} size={40} />
        </td>
        <td>
          <div className="p-4 select-text">
            <span>{text}</span>
          </div>
        </td>
        <td>
          <div className="h-full flex flex-col items-center p-2">
            <div className="flex flex-col items-end">
              <UserDisplaySmallRight uid={uid} />
              <span className="info ml-2 text-end">{displayDate}</span>
            </div>
            <div className="flex justify-end w-full mt-2">
              <div className="w-24 flex items-center justify-between">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={like}
                >
                  <LikeIcon
                    className={`stroke-rose-400 ${
                      iLike ? 'fill-rose-400' : 'fill-transparent'
                    }`}
                  />
                  <span>{likeCount}</span>
                </div>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={reply}
                >
                  <ChatIcon
                    className={
                      isReplying ? 'stroke-sky-400' : 'stroke-base-400'
                    }
                  />
                  <span>{replyCount}</span>
                </div>
              </div>
            </div>
          </div>
        </td>
      </>
    );
  };

  return (
    <>
      <Animated
        element="tr"
        variants={{
          hidden: (i) => ({
            opacity: 0,
          }),
          visible: (i) => ({
            opacity: 1,
            transition: {
              delay: i * 0.02,
            },
          }),
        }}
        initial="hidden"
        exit="hidden"
        animate="visible"
        custom={i}
        key={id}
        sx={'h-14'}
      >
        {bidSpotDisplay()}
      </Animated>
      <tr />
      <tr>
        <td colSpan={3}>
          {isReplying && (
            <div className="w-full flex flex-col justify-end items-end">
              <div className="mb-4">
                {replies &&
                  replies.length > 0 &&
                  replies.map((r, i) => {
                    return (
                      <div key={i}>
                        <div className="chat chat-end">
                          <div className="chat-image gap-2 flex flex-col-reverse">
                            <UserDisplayAvatarSmall uid={r.uid} />
                            {currentUser.uid === r.uid && (
                              <DeleteIcon
                                className="fill-rose-400 cursor-pointer"
                                onClick={() => handleDeleteReply(r)}
                              />
                            )}
                          </div>
                          <div className="chat-header flex justify-end">
                            <UserDisplayNameSmallRight uid={r.uid} />
                          </div>
                          <div className="chat-bubble chat-bubble-info w-80 md:w-96 flex justify-between">
                            {r.reply}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <form
                onSubmit={replyConfirm}
                className="w-full flex justify-end mb-4"
              >
                <div className="form-control w-full md:w-1/2">
                  <div className={'join'} style={{ height }}>
                    <textarea
                      ref={inputRef}
                      value={replyText}
                      type="text"
                      autoComplete="off"
                      required
                      rows={1}
                      className="input w-full resize-none pt-2 join-item"
                      placeholder={t('spots.reply_placeholder')}
                      onChange={handleChangeText}
                    />
                    <button
                      className="btn-primary btn h-full join-item"
                      disabled={replyText.length < 1}
                    >
                      {t('spots.reply_button')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </td>
      </tr>
    </>
  );
}
