import { contractFromOldStyle } from 'cuebids-bidding-util';
import { getNextActorKey, removeAlerts } from './util';
import { currentUser, currentUserId, getFirestore, getRepos } from '../base';
import { arrayRemove, arrayUnion, getDocs, increment, limit, query, serverTimestamp, Timestamp, where, writeBatch } from 'firebase/firestore';
import { docToObject } from 'firebase-util';
import { createSessionFinishedNotification, createYourTurnNotification } from '../notifications';
import { getPublicUserAsync } from '../users';
import { submitInternalFeedback } from '../feedback';
function addEvaluationAndSwapTurns({ transactionOrBatch, users, actorKey, sessionRef, sessionDealRef, dateNow, oldBidding, newBidding, turn, finalBid, doubled, declarer, evaluation, isRobotBid }) {
    users.forEach((u) => {
        const userRef = getRepos().users.docRef(u);
        transactionOrBatch.update(userRef, {
            numberOfStars: increment(evaluation.resultGrade)
        });
    });
    const nextActorKey = getNextActorKey(actorKey);
    transactionOrBatch.update(sessionRef, {
        numberOfFinishedDeals: increment(1),
        numberOfStars: increment(evaluation.resultGrade),
        [actorKey]: increment(-1),
        [nextActorKey]: increment(1),
        updateTimestamp: dateNow
    });
    transactionOrBatch.update(sessionDealRef, {
        oldBidding,
        bidding: newBidding,
        turn,
        finished: true,
        finalBid,
        doubled,
        declarer,
        contract: contractFromOldStyle({
            finalBid,
            declarer,
            doubled
        }),
        lastAction: isRobotBid ? 'robotBid' : 'bid',
        ...evaluation
    });
}
export function swapTurns({ transactionOrBatch, sessionRef, sessionDealRef, dateNow, oldBidding, newBidding, turn, actorKey, isRobotBid }) {
    const nextActorKey = getNextActorKey(actorKey);
    transactionOrBatch.update(sessionRef, {
        [actorKey]: increment(-1),
        [nextActorKey]: increment(1),
        updateTimestamp: dateNow
    });
    transactionOrBatch.update(sessionDealRef, {
        oldBidding,
        bidding: newBidding,
        turn,
        lastAction: isRobotBid ? 'robotBid' : 'bid',
        lastBidTimestamp: serverTimestamp(),
        clientLastBidTimestamp: Timestamp.now()
    });
}
function addToExportDeal({ transactionOrBatch, sessionDealId, dealId, hand, vulnerability, dealer, users, contract, bidding, resultGrade, ev, type, parContract, parEv, bestContract, bestContractEv, timestamp, createdTimestamp }) {
    const finishedDealDoc = {
        sessionDealId,
        dealId,
        hand,
        vulnerability,
        dealer,
        users: JSON.stringify([...users].sort()),
        user1: users[0],
        user2: users[1],
        contract,
        bidding: removeAlerts(bidding),
        grade: resultGrade,
        ev,
        type: type ?? 'practice',
        parContract,
        parEv,
        bestContract,
        bestEv: bestContractEv,
        timestamp,
        createdTimestamp
    };
    const exportDealRef = getRepos().dealsForExport.docRef(sessionDealId);
    transactionOrBatch.set(exportDealRef, finishedDealDoc);
}
function addToDealReview({ transactionOrBatch, sessionDealId, dealId, users, contract, grade, bidding, ev, compete, evaluationVersion, parBeatBest, liaSystem }) {
    const dealReviewRef = getRepos().dealReviews.docRef(dealId);
    transactionOrBatch.set(dealReviewRef, {
        id: dealId,
        results: arrayUnion({
            sessionDealId,
            users,
            contract,
            grade,
            ev,
            bidding,
            compete,
            evaluationVersion,
            parBeatBest,
            liaSystem
        })
    }, { merge: true });
}
function updateChallenge({ transactionOrBatch, challenge, sessionDealId, grade, contract, challengeRef }) {
    const oldData = challenge.deals.find((d) => d.id === sessionDealId);
    const newData = {
        ...oldData,
        finished: true,
        resultGrade: grade,
        contract
    };
    transactionOrBatch.update(challengeRef, {
        deals: arrayRemove(oldData)
    });
    transactionOrBatch.update(challengeRef, {
        deals: arrayUnion(newData)
    });
}
async function leaderBoardSubmit({ transactionOrBatch, dealsRemaining, sessionData, sessionDealsCollectionRef, sessionDeal, dateNow, grade, sessionRef, dailySessionId, weeklySessionId, groupSessionId }) {
    if ((weeklySessionId !== 0 || dailySessionId !== 0 || groupSessionId !== 0) &&
        dealsRemaining < 1) {
        const findUnfinishedDeal = query(sessionDealsCollectionRef, where('sessionId', '==', sessionDeal.sessionId), where('finished', '==', false));
        const dealsRemainingRealSnapshot = await getDocs(findUnfinishedDeal);
        const dealsRemainingReal = dealsRemainingRealSnapshot.docs.length;
        // Only remaining deal should be the one we are finishing now
        if (dealsRemainingReal > 1) {
            transactionOrBatch.update(sessionRef, {
                numberOfFinishedDeals: sessionData.dealsCount - dealsRemainingReal
            });
            void submitInternalFeedback({
                category: 'bug',
                message: `numberOfFinishedDeals out of sync on session: ${sessionData.id}. Wrong: ${dealsRemaining}. Real: ${dealsRemainingReal}`
            });
            return;
        }
        let allowSubmit;
        let eventRef;
        if (groupSessionId !== 0) {
            eventRef = getRepos().groupSession.docRef(groupSessionId);
            const event = await getRepos().groupSession.doc(groupSessionId);
            const eventData = docToObject(event);
            allowSubmit = eventData?.endDate == null || dateNow <= eventData?.endDate;
        }
        else if (weeklySessionId !== 0) {
            eventRef = getRepos().weeklySession.docRef(weeklySessionId);
            const event = await getRepos().weeklySession.doc(weeklySessionId);
            const eventData = docToObject(event);
            allowSubmit = dateNow <= eventData?.endDate;
        }
        else {
            // dailySessionId !== 0
            eventRef = getRepos().dailySession.docRef(dailySessionId);
            const event = await getRepos().dailySession.doc(dailySessionId);
            const eventData = docToObject(event);
            allowSubmit = dateNow <= eventData?.endDate;
        }
        if (!allowSubmit) {
            return;
        }
        transactionOrBatch.update(eventRef, {
            leaderBoard: arrayUnion({
                users: sessionData.users,
                numberOfStars: sessionData.numberOfStars + grade,
                timestamp: dateNow
            })
        });
    }
}
export async function sendNotification({ sessionDealsCollectionRef, sessionDeal, turn, dealsRemaining }) {
    const q = query(sessionDealsCollectionRef, where('sessionId', '==', sessionDeal.sessionId), where('turn', '==', currentUserId()), where('finished', '==', false), limit(1));
    const sessionDealsNext = await getDocs(q);
    if (sessionDealsNext.docs.length === 0) {
        const user = await getPublicUserAsync(currentUserId());
        if (dealsRemaining < 1) {
            void createSessionFinishedNotification(turn, user.displayName ?? currentUser()?.displayName, sessionDeal.sessionId);
        }
        else {
            void createYourTurnNotification(turn, user.displayName ?? currentUser()?.displayName, sessionDeal.sessionId);
        }
    }
}
export async function handleFinishDeal({ transaction, sessionDeal, preparedData, actorKey, sessionRef, sessionDealRef, dateNow, turn, dailySessionId, weeklySessionId, groupSessionId, sessionData, sessionDealsCollectionRef, numberOfRemainingDeals, isRobotBid }) {
    const transactionOrBatch = transaction ?? writeBatch(getFirestore());
    const challengeData = preparedData.challengeData;
    if (challengeData != null) {
        updateChallenge({
            transactionOrBatch,
            sessionDealId: sessionDeal.id,
            grade: preparedData.evaluation.resultGrade,
            challenge: challengeData,
            challengeRef: getRepos().challengeSession.docRef(challengeData.id),
            contract: contractFromOldStyle({
                finalBid: preparedData.finalBid,
                declarer: preparedData.declarer,
                doubled: preparedData.doubled
            })
        });
    }
    addEvaluationAndSwapTurns({
        transactionOrBatch,
        users: sessionDeal.users,
        actorKey,
        sessionRef,
        sessionDealRef,
        dateNow,
        oldBidding: sessionDeal.bidding,
        newBidding: preparedData.newBidding,
        turn,
        finalBid: preparedData.finalBid,
        doubled: preparedData.doubled,
        declarer: preparedData.declarer,
        evaluation: preparedData.evaluation,
        isRobotBid
    });
    // Older evaluations have different fields. Probably nobody will finish a deal that old now.
    if (preparedData.evaluation.evaluationVersion === 3 || preparedData.evaluation.evaluationVersion === 4) {
        addToExportDeal({
            transactionOrBatch,
            sessionDealId: sessionDeal.id,
            dealId: sessionDeal.dealId,
            hand: sessionDeal.hand,
            vulnerability: sessionDeal.vulnerability,
            dealer: sessionDeal.dealer,
            users: sessionDeal.users,
            contract: contractFromOldStyle({
                finalBid: preparedData.finalBid,
                declarer: preparedData.declarer,
                doubled: preparedData.doubled
            }),
            resultGrade: preparedData.evaluation.resultGrade,
            bidding: preparedData.newBidding,
            ev: preparedData.evaluation.ev,
            type: sessionDeal.type,
            parContract: preparedData.evaluation.parContract,
            parEv: preparedData.evaluation.parEv,
            bestContract: preparedData.evaluation.bestContract,
            bestContractEv: preparedData.evaluation.bestContractEv ?? null,
            timestamp: Date.now(),
            createdTimestamp: sessionDeal.timestamp
        });
    }
    addToDealReview({
        transactionOrBatch,
        sessionDealId: sessionDeal.id,
        dealId: sessionDeal.dealId,
        users: sessionDeal.users,
        contract: contractFromOldStyle({
            finalBid: preparedData.finalBid,
            declarer: preparedData.declarer,
            doubled: preparedData.doubled
        }),
        grade: preparedData.evaluation.resultGrade,
        bidding: preparedData.newBidding,
        ev: preparedData.evaluation.ev,
        compete: sessionDeal.compete,
        evaluationVersion: preparedData.evaluation.evaluationVersion,
        parBeatBest: preparedData.evaluation.parBeatBest ?? false,
        liaSystem: sessionDeal.liaSystem ?? 'DEFAULT'
    });
    // We only send in sessionData in the cases we need to do leaderboardSubmit
    if (sessionData !== undefined) {
        const dealsRemaining = sessionData.dealsCount - sessionData.numberOfFinishedDeals - 1;
        await leaderBoardSubmit({
            transactionOrBatch,
            dealsRemaining,
            sessionData,
            sessionDealsCollectionRef,
            sessionDeal,
            dateNow,
            grade: preparedData.evaluation.resultGrade,
            sessionRef,
            dailySessionId,
            weeklySessionId,
            groupSessionId
        });
        return dealsRemaining;
    }
    if (transaction === undefined) {
        await transactionOrBatch.commit();
    }
    return numberOfRemainingDeals;
}
