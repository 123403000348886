import {
  contractFromOldStyle,
  getContractDeclarer,
  getContractDoubled,
  getContractLevel,
  getContractStrain,
  getContractUndoubled,
  getContractWithoutDeclarer,
  isContractDoubled,
  getHighestRobotBid,
  isBidHigher,
  rollBackBiddingToBid, getContractRedoubled,
} from 'cuebids-bidding-util';
import { getRobotAction, getRobotParStrains } from 'cuebids-ai';
import { checkHasPotentialForParBeatBest } from './evaluationV3';

// Scoring mainly to handle boards with big scores, so that if you make slam but stay in game you still get some star.
export function gradeQuotient(ev, parEv) {
  if (parEv < 1) {
    return 0;
  }

  const resultPercent = ev / parEv;
  if (resultPercent >= 0.85) {
    return 3;
  } else if (resultPercent >= 0.75) {
    return 2.5;
  } else if (resultPercent >= 0.6) {
    return 2;
  } else if (resultPercent >= 0.5) {
    return 1.5;
  } else if (resultPercent >= 0.3) {
    return 1;
  } else if (resultPercent >= 0.2) {
    return 0.5;
  }
  return 0;
}

function getImp(evDifference) {
  const impTable = [
    20, 50, 90, 130, 170, 220, 270, 320, 370, 430, 500, 600, 750, 900, 1100,
    1300, 1500, 1760, 2000, 2250, 2500, 3000, 3500, 4000,
  ];
  for (let i = 0; i < impTable.length; i++) {
    if (evDifference < impTable[i]) return i;
  }
  return 24;
}

// The "normal" scoring
export function gradeImp(ev, parEv) {
  const diff = parEv - ev;
  const imp = getImp(diff);

  if (imp <= 1) {
    return 3;
  } else if (imp <= 2) {
    return 2.5;
  } else if (imp <= 3) {
    return 2;
  } else if (imp <= 4) {
    return 1.5;
  } else if (imp <= 5) {
    return 1;
  } else if (imp <= 6) {
    return 0.5;
  }
  return 0;
}

export function grade(ev, parEv, useHalfStars = false) {
  const g = Math.max(gradeImp(ev, parEv), gradeQuotient(ev, parEv));
  if (!useHalfStars) {
    return Math.floor(g);
  }
  return g;
}

// Get the doubled/undoubled score of a contract, as well as the best version for defence (doubled/undoubled)
function getContractDoubledOrUndoubledBestForDefence(scores, contract) {
  if (contract === 'P') {
    return {
      contract: 'P',
      contractEv: 0,
      undoubledContract: 'P',
      undoubledContractEv: 0,
      doubledContract: 'P',
      doubledContractEv: 0,
    };
  }

  const contractUndoubled = getContractUndoubled(contract);
  const contractDoubled = getContractDoubled(contractUndoubled);
  const contractUndoubledEv = scores[contractUndoubled];
  const contractDoubledEv = scores[contractDoubled];

  const contractEv =
    // Never double if the contract is a plus for declarer (even if it is correct, which can happen)
    contractUndoubledEv < 0 ?
      // Only double if that is worse for declarer
      Math.min(contractUndoubledEv, contractDoubledEv) :
      contractUndoubledEv;
  const bestContract =
    contractUndoubledEv === contractEv ? contractUndoubled : contractDoubled;

  return {
    contract: bestContract,
    contractEv,
    undoubledContract: contractUndoubled,
    undoubledContractEv: contractUndoubledEv,
    doubledContract: contractDoubled,
    doubledContractEv: contractDoubledEv,
  };
}

// Score a deal, getting par and best contract possible to achieve at table (and some extra scores of interest)
// bestContract will always be at least as good as parContract (if EW bid over par then you can always punish them)
export function getScoring(
  scores,
  highestRobotBid,
  ourContract,
  hand,
  vulnerability,
  compete,
) {
  const levels = [1, 2, 3, 4, 5, 6, 7];
  const suits = ['C', 'D', 'H', 'S', 'N'];
  const directions = compete ? ['N', 'E', 'S', 'W'] : ['N', 'S'];

  let parContract = 'P';
  let parEv = 0;

  let {
    contract: bestContract,
    contractEv: bestContractEv,
    undoubledContract: robotHighestContract,
    undoubledContractEv: robotHighestContractEv,
    doubledContract: robotHighestContractDoubled,
    doubledContractEv: robotHighestContractDoubledEv,
  } = getContractDoubledOrUndoubledBestForDefence(scores, compete ? highestRobotBid : 'P');

  // const potentialRobotLevels = getPotentialRobotLevels(hand, vulnerability, ai);

  // EW scores are from perspective of declarer, not NS - hence invert it with minus
  bestContractEv = -bestContractEv;

  // If the table result was better than defending highest robot bid, start bestContract there instead.
  // This is mainly in place to handle doubled table contracts (ourContract), since
  // 1. in getContractDoubledOrUndoubledBestForDefence we can't double if a plus contract for declarer, even if it is correct to double
  // i.e. ourContract is a doubled robot contract that is plus for robots, but less plus than the doubled version.
  // 2. robots incorrectly doubled us at the table (ourContract). The loop below will not double if wrong for defence.
  const ourContractEv = ['E', 'W'].includes(getContractDeclarer(ourContract)) ? -scores[ourContract] : scores[ourContract];
  if (ourContractEv >= bestContractEv) {
    bestContract = ourContract;
    bestContractEv = ourContractEv;
  }

  let bestNS = 'P';
  let bestNSEv = -1000;

  let bestEW = 'P';
  let bestEWEv = -1000;

  levels.forEach((l) => {
    suits.forEach((s) => {
      directions.forEach((d) => {
        const { contract, contractEv, undoubledContract, undoubledContractEv } = getContractDoubledOrUndoubledBestForDefence(scores, l + s + d);
        if (['E', 'W'].includes(d)) {
          // Check for par contract
          if (compete) {
            // EW want lowest parEv
            // -contractEv to turn it into "board ev", i.e. from NS perspective
            if (-contractEv <= parEv) {
              // When par switches from NS to EW, save NS contract
              if (['N', 'S'].includes(getContractDeclarer(parContract))) {
                bestNS = parContract;
                bestNSEv = parEv;
              }
              // Note: bestContract never changes here, so best is only NS contract higher than robot bid at table
              parContract = contract;
              parEv = -contractEv;
            }
          }
        } else {
          // Check for best contract
          // Best contract can be any contract higher than robot highest bid
          if (isBidHigher(contract, highestRobotBid)) {
            if (
              // Our contract in the same strain and at least the same level was not doubled - robots cannot then double best contract (since they did not double us at the table)
              !isContractDoubled(ourContract) &&
              isContractDoubled(contract) &&
              getContractStrain(ourContract) === getContractStrain(contract) &&
              getContractLevel(ourContract) >= getContractLevel(contract)
            ) {
              if (undoubledContractEv >= bestContractEv) {
                bestContractEv = undoubledContractEv;
                bestContract = undoubledContract;
              }
            } else {
              if (contractEv >= bestContractEv) {
                bestContractEv = contractEv;
                bestContract = contract;
              }
            }
          }
          // Check for par contract
          // NS want highest parEv
          if (contractEv >= parEv) {
            // When par switches from EW to NS, save EW contract
            if (['E', 'W'].includes(getContractDeclarer(parContract))) {
              bestEW = parContract;
              bestEWEv = parEv;
            }
            parContract = contract;
            parEv = contractEv;
          }
        }
      });
    });
  });

  return {
    parContract,
    parEv,
    bestNS,
    bestNSEv,
    bestEW,
    bestEWEv,
    bestContract,
    bestContractEv,
    robotHighestContract,
    robotHighestContractEv: -robotHighestContractEv,
    robotHighestContractDoubled,
    robotHighestContractDoubledEv: -robotHighestContractDoubledEv,
  };
}

function getBestContractButOursOnTie({
  ourContract,
  ourEv,
  bestContract,
  bestContractEv,
}) {
  if (ourEv >= bestContractEv) {
    return { contract: ourContract, ev: ourEv };
  }

  return { contract: bestContract, ev: bestContractEv };
}

// Get a number (count or fewer) of scores for NS better than minScore (not guaranteed best contracts, just some alternatives)
export function getNSExtraContracts(scores, count, minScore = 0) {
  return Object.keys(scores)
    .filter(
      (s) =>
        s.length === 3 && // Only undoubled contracts
        ['N', 'S'].includes(s.slice(-1)) && // Only contracts for NS
        scores[s] > minScore, // Only better than min score
    )
    .sort((a, b) => (scores[b] - scores[a]) || a.localeCompare(b)) // Sort by score, then by contract
    .slice(0, count * 2) // Start with twice the count number of contracts since we will filter some
    .filter((s, i, arr) => {
      // Never show 1suit contracts
      if (['1C', '1D', '1H', '1S'].includes(s.slice(0, 2))) {
        return false;
      }

      // Check if we already had this contract
      const sameContractWithDifferentDeclarerEarlierInArr = arr.find(
        (n, i2) => n.slice(0, 2) === s.slice(0, 2) && i > i2,
      );
      if (sameContractWithDifferentDeclarerEarlierInArr) {
        // If same contract already included, only include it again if big score difference
        return (
          scores[sameContractWithDifferentDeclarerEarlierInArr] - scores[s] > 150
        );
      } else {
        // Check if contract in same denomination (same declarer) exists later in array. To for example not show both 2S and 3S if similar scores.
        // (Instead remove 2S, and only show the higher contract)
        const sameDenominationWithSameDeclarerLaterInArr = arr.find(
          (n, i2) => n.slice(1) === s.slice(1) && i < i2,
        );
        if (sameDenominationWithSameDeclarerLaterInArr) {
          // If a contract in same denomination (and same declarer) exists later, only include this contract if sufficiently different score.
          return (
            scores[s] - scores[sameDenominationWithSameDeclarerLaterInArr] > 20
          );
        } else {
          return true;
        }
      }
    })
    .slice(0, count); // End by trimming to count number
}

// Roll back auction to robot highest bid and let them finish.
// To for example not show that you could have doubled them in some artificial bid (i.e. transfer)
export function getHighestRobotBidIfAllowedToFinish({
  compete,
  bidding,
  deal,
  highestRobotBid,
}) {
  if (!compete || highestRobotBid === 'P') {
    return 'P';
  }

  const robotPars = getRobotParStrains({ scores: deal.score });
  // TODO: is hasPotentialForParBeatBest necessary in this scoring? I guess it is until we have removed that logic from robot bidding.
  const hasPotentialForParBeatBest =  checkHasPotentialForParBeatBest(deal.score, deal.hand, deal.vulnerability, deal.ai);

  let finalBidding = rollBackBiddingToBid(bidding, highestRobotBid.slice(0, 2)) + '-P';
  let robotAction = getRobotAction({
    compete,
    bidding: finalBidding,
    sessionDeal: {
      ...deal,
      robotPars,
      hasPotentialForParBeatBest,
    },
  });

  while (robotAction !== '-P') {
    finalBidding += `${robotAction}-P`;
    robotAction = getRobotAction({
      compete,
      bidding: finalBidding,
      sessionDeal: {
        ...deal,
        robotPars,
        hasPotentialForParBeatBest,
      },
    });
  }

  finalBidding += '-P-P';

  return getHighestRobotBid(finalBidding);
}

// Robot picks if they prefer the highest bid they bid, or the highest if they had continued and finished bidding from there.
export function getHighestRobotBidToCompare(highestRobotBid, highestRobotBidIfAllowedToFinish, scores) {
  if (highestRobotBid === 'P') {
    return 'P';
  }
  const highestRobotBidUndoubled = getContractUndoubled(highestRobotBid);
  const highestRobotBidDoubled = getContractDoubled(highestRobotBidUndoubled);

  const highestRobotBidIfAllowedToFinishedUndoubled = getContractUndoubled(highestRobotBidIfAllowedToFinish);
  const highestRobotBidIfAllowedToFinishedDoubled = getContractDoubled(highestRobotBidIfAllowedToFinishedUndoubled);

  // NS will pick best for them (doubled/undoubled). Score ev from declarer point of view.
  const noFinish = scores[highestRobotBidUndoubled] < scores[highestRobotBidDoubled] ?
    highestRobotBidUndoubled :
    highestRobotBidDoubled;

  // NS will pick best for them (doubled/undoubled). Score ev from declarer point of view.
  const finish = scores[highestRobotBidIfAllowedToFinishedUndoubled] < scores[highestRobotBidIfAllowedToFinishedDoubled] ?
    highestRobotBidIfAllowedToFinishedUndoubled : highestRobotBidIfAllowedToFinishedDoubled;

  // EW will pick best for them (from highest bid at table and if allowed to finish). Score ev from declarer point of view.
  return scores[noFinish] > scores[finish] ? noFinish : finish;
}

function shouldResetCap(level, suit) {
  // reset cap when game or slam level reached
  if(level === 3 && suit === 'N') {
    return true;
  }
  if(level === 4 && (suit === 'S' || suit === 'H')) {
    return true;
  }
  if(level === 5 && (suit === 'D' || suit === 'C')) {
    return true;
  }
  if (level === 6) {
    return true;
  }
  return level === 7;
}

// Merge similar scores of N and S contracts
export function mergeSimilarScores(scores) {
  const levels = [1, 2, 3, 4, 5, 6, 7];
  const suits = ['C', 'D', 'H', 'S', 'N'];
  const directions = ['N', 'E'];
  const partner = { N: 'S', E: 'W' };

  directions.forEach((d) => {
    suits.forEach((s) => {
      let cap = 99999;
      levels.forEach((l) => {
        if (shouldResetCap(l, s)) {
          cap = 99999;
        }

        const contract= l + s + d;
        const doubled = getContractDoubled(contract)
        const redoubled = getContractRedoubled(contract)

        const partnerContract = l + s + partner[d];
        const doubledP = getContractDoubled(partnerContract)
        const redoubledP = getContractRedoubled(partnerContract)

        let ev = scores[contract];
        const doubledEv = scores[doubled];
        const redoubledEv = scores[redoubled];

        let partnerEv = scores[partnerContract];
        const doubledEvP = scores[doubledP];
        const redoubledEvP = scores[redoubledP];

        if(ev > cap) {
          scores[contract] = cap;
          ev = cap
        }
        if(partnerEv > cap) {
          scores[partnerContract] = cap;
          partnerEv = cap
        }

        if (Math.abs(ev - partnerEv) < 10) {
          // only track cap for contracts not doubled or redoubled
          cap = Math.min(ev, partnerEv);
          scores[contract] = cap;
          scores[partnerContract] = cap;
        }
        if (Math.abs(doubledEv - doubledEvP) < 10) {
          scores[doubled] = Math.min(doubledEv, doubledEvP);
          scores[doubledP] = scores[doubled];
        }
        if (Math.abs(redoubledEv - redoubledEvP) < 10) {
          scores[redoubled] = Math.min(redoubledEv, redoubledEvP);
          scores[redoubledP] = scores[redoubled];
        }
      });
    });
  });
}

export default function evaluateV4({
  deal,
  finalBid,
  declarer,
  highestRobotBid,
  doubled = '',
  bidding,
  compete,
  useHalfStars = false,
}) {
  const ourContract = contractFromOldStyle({
    finalBid,
    declarer,
    doubled,
  });

  const highestRobotBidIfAllowedToFinish = getHighestRobotBidIfAllowedToFinish({
    compete,
    bidding,
    highestRobotBid,
    deal,
  });

  const highestRobotBidToCompare = getHighestRobotBidToCompare(highestRobotBid, highestRobotBidIfAllowedToFinish, deal.score);

  mergeSimilarScores(deal.score)

  const scoring = getScoring(
    deal.score,
    highestRobotBidToCompare,
    ourContract,
    deal.hand,
    deal.vulnerability,
    compete,
  );
  const ourEv =
    ourContract === 'P' ?
      0 :
      deal.score[ourContract] * (['N', 'S'].includes(declarer) ? 1 : -1);

  const grd = grade(ourEv, scoring.parEv, useHalfStars);

  const { contract, ev } = getBestContractButOursOnTie({
    ourContract,
    ourEv,
    bestContract: scoring.bestContract,
    bestContractEv: scoring.bestContractEv,
  });

  const minScore =
    ['N', 'S'].includes(declarer) && !doubled ? Math.min(0, ourEv) : 0;
  const extraContracts = getNSExtraContracts(deal.score, 5, minScore);

  const includeBestNS = Math.abs(scoring.parEv - scoring.bestNSEv) < 150;
  const includeBestEW = Math.abs(scoring.parEv - scoring.bestEWEv) < 150;

  const bonusGrade = ourEv >= (scoring.parEv + 300);

  return {
    evaluationVersion: 4,
    resultGrade: grd,
    ev: ourEv,
    bestContract: contract,
    bestContractEv: ev,
    parContract: scoring.parContract,
    parEv: scoring.parEv,
    otherContracts: [
      {
        contract: includeBestNS ? scoring.bestNS : 'P',
        ev: includeBestNS ? scoring.bestNSEv : 0,
        stars: grade(includeBestNS ? scoring.bestNSEv : 0, scoring.parEv, useHalfStars),
      },
      {
        contract: includeBestEW ? scoring.bestEW : 'P',
        ev: includeBestEW ? scoring.bestEWEv : 0,
        stars: grade(includeBestEW ? scoring.bestEWEv : 0, scoring.parEv, useHalfStars),
      },
      {
        contract: scoring.robotHighestContract ?? 'P',
        ev: scoring.robotHighestContractEv ?? 0,
        stars: grade(scoring.robotHighestContractEv ?? 0, scoring.parEv, useHalfStars),
      },
      {
        contract: scoring.robotHighestContractDoubled ?? 'P',
        ev: scoring.robotHighestContractDoubledEv ?? 0,
        stars: grade(scoring.robotHighestContractDoubledEv ?? 0, scoring.parEv, useHalfStars),
      },
      ...extraContracts.map((c) => ({
        contract: c,
        ev: deal.score[c],
        stars: grade(deal.score[c], scoring.parEv, useHalfStars),
      })),
    ],
    bonusGrade,
  };
}
