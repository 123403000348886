import { getRobotUserId } from './robotPartner.js';

export function getPartnerPriceFactor({
  friendId,
  sessions,
  numberOfSessionsWithLia,
}) {
  if (friendId !== getRobotUserId()) {
    return 0;
  }

  if (sessions.length < 10 && numberOfSessionsWithLia < 5) {
    return 0;
  }

  return 1;
}


export function getPrice({
  robots,
  friendId,
  handCount,
  sessions,
  numberOfSessionsWithLia,
}) {
  const robotsPriceFactor = robots === 2 ? 1 : 0;
  const partnerPriceFactor = getPartnerPriceFactor({ friendId, sessions, numberOfSessionsWithLia });
  const priceFactor = robotsPriceFactor + partnerPriceFactor;
  return priceFactor * handCount;
}