import { useMemo } from 'react';
import Leaderboard from '../leaderBoards/leaderBoard';
import { useAuth } from '../../util/hooks.jsx';
import { EventIcon } from '../icons/dailyIcon';
import { useAppStateStore } from '../../appStateStore.jsx';
import { getRankFromLeaderboard } from 'cuebids-firebase/stats';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function LeadersNavbar() {
  const { currentUser } = useAuth();
  const daily = useAppStateStore((state) => state.daily);
  const weekly = useAppStateStore((state) => state.weekly);
  const { t } = useTranslation();

  const rankDaily = useMemo(() => {
    if (!daily || !currentUser) {
      return;
    }
    const x = getRankFromLeaderboard(
      currentUser.uid,
      daily.leaderBoard
    );
    return x === 0 ? '-' : x;
  }, [daily, currentUser]);

  const rankWeekly = useMemo(() => {
    if (!weekly || !currentUser) {
      return;
    }
    const x = getRankFromLeaderboard(
      currentUser.uid,
      weekly.leaderBoard
    );
    return x === 0 ? '-' : x;
  }, [weekly, currentUser]);

  const dailyElement = <div className='flex flex-col items-center justify-center'>
    <div className='flex items-center'>
      <EventIcon fill="#4d774e" strokeWidth={0.5} width={16} />
      <span className="info">
        {t('routes.daily_event')}
      </span>
    </div>
    {daily && (
      <div className="h-12 w-40">
        <Leaderboard
          leaderBoard={daily.leaderBoard}
          top={1}
          header=""
          min={15}
          links={false}
          stars={false}
        />
      </div>
    )}
    {daily && (
      <span className="info" style={{ marginTop: 2 }}>
        {rankDaily} / {daily.pairCount}
      </span>
    )}
  </div>

  const weeklyElement =  <div className='flex flex-col items-center justify-center'>
    <div className='flex items-center'>
      <EventIcon fill="#e29627" strokeWidth={0.5} width={16} />
      <span className="info">
        {t('routes.weekly_event')}
      </span>
    </div>
    {weekly && (
      <div className="h-12 w-40">
        <Leaderboard
          leaderBoard={weekly.leaderBoard}
          top={1}
          header=""
          min={45}
          links={false}
          stars={false}
        />
      </div>
    )}
    {weekly && (
      <span className="info" style={{ marginTop: 2 }}>
        {rankWeekly} / {weekly.pairCount}
      </span>
    )}
  </div>

  return (
    <div className="navbar bg-base-100 flex items-center justify-around">
      <Link to={'/events'}>{dailyElement}</Link>
      <Link to={'/events'}>{weeklyElement}</Link>
    </div>
  )
}
