import { auth, db } from './firebase';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';

export async function submitFeedback(feedback) {
  const d = new Date();
  return setDoc(doc(collection(db, 'feedback')), {
    uid: auth.currentUser.uid,
    email: auth.currentUser.email,
    timestamp: d.getTime(),
    date: d.toLocaleString(),
    ...feedback,
  });
}

export async function submitApplication(feedback) {
  const d = new Date();
  return addDoc(collection(db, 'feedback'), {
    uid: auth.currentUser.uid,
    email: auth.currentUser.email,
    timestamp: d.getTime(),
    date: d.toLocaleString(),
    ...feedback,
  });
}

export async function submitInternalFeedback(feedback) {
  const d = new Date();
  return addDoc(collection(db, 'internalFeedback'), {
    uid: auth?.currentUser?.uid || '',
    timestamp: d.getTime(),
    date: d.toLocaleString(),
    ...feedback,
  });
}
