import { Star } from '../../../components/rating/starRating'
import { LinkedUserDisplaySmall } from '../../../components/users/userDisplay'
import { roundToTwoDecimals } from '../../../util/challenge'
import ChallengeStatisticUserBlocks from './ChallengeStatisticUserBlocks'
import { useAuth } from '../../../util/hooks'

export default function ChallengeStatisticUser({ user, header }) {
  const { currentUser } = useAuth();
  const stars = user.challengeStatistics?.numberOfStars ?? 0;
  const numberOfBoards = user.challengeStatistics?.numberOfBoards ?? 0;

  const isMe = user.id === currentUser.uid;
  const isLegendary = user.rank === 'ace' && user.rankStars === 6;
  const showRating = isMe || isLegendary;

  return (
    <div className={'border border-accent p-2 md:px-4 bg-base-200 rounded-md'}>
      {header && <div className="info text-center mb-2">{header}</div>}
      <div className='flex items-center justify-between'>
        <div
          className={'badge badge-outline border-info bg-base-100 pl-0'}
        >
          <Star /> {stars}
        </div>
        <div className="flex flex-col items-center gap-1">
          <LinkedUserDisplaySmall uid={user.id} />
          {showRating && <div className="badge badge-accent badge-sm">{user.rating ?? 1500}</div>}
        </div>
        <div
          className={'badge badge-outline border-info bg-base-100 pl-0'}
        >
          <Star /> {numberOfBoards ? roundToTwoDecimals(stars/numberOfBoards) : '-'}
        </div>
      </div>
      <ChallengeStatisticUserBlocks user={user} />
    </div>
  );
}
