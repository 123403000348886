import { query, updateDoc, where } from 'firebase/firestore';
import { getFunctions, getRepos } from '../base';
import { docToObject, queryToObjects } from 'firebase-util';
import { httpsCallable } from 'firebase/functions';
export async function getDeal(dealId) {
    const dealRef = await getRepos().deals.doc(dealId);
    return docToObject(dealRef);
}
export async function getSessionDealsInSession(sessionId) {
    const repo = getRepos().sessionDeals;
    const q = query(repo.collection, where(repo.fieldName('sessionId'), '==', sessionId));
    return await queryToObjects(q);
}
export async function getSession(sessionId) {
    const session = await getRepos().sessions.doc(sessionId);
    return docToObject(session);
}
export async function requestSessionPrint(email, sessionId) {
    const sessionRef = getRepos().sessions.docRef(sessionId);
    await updateDoc(sessionRef, {
        print: true
    });
    const functions = getFunctions();
    const func = httpsCallable(functions, 'sendSessionByPdf');
    await func({ email, sessionId });
}
