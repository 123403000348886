export { getRobotParStrains } from 'cuebids-ai';
import evaluateV2 from './evaluation/evaluationV2';
import evaluateV3 from './evaluation/evaluationV3';
import evaluateV4 from './evaluation/evaluationV4';

export { checkHasPotentialForParBeatBest } from './evaluation/evaluationV3'

export function evaluate({
  deal,
  finalBid,
  declarer,
  highestRobotBid,
  doubled,
  vulnerability,
  bidding,
  compete,
  ai,
  useHalfStars,
}) {
  if (deal.version === 3) {
    return evaluateV4({
      deal,
      finalBid,
      declarer,
      highestRobotBid,
      doubled, // doubled is string '' or 'X' or 'XX'
      bidding,
      compete,
      useHalfStars,
    });
  } else if (deal.version === 2) {
    return evaluateV3({
      deal,
      finalBid,
      declarer,
      highestRobotBid,
      doubled, // doubled is string '' or 'X' or 'XX'
      bidding,
      compete,
    });
  } else {
    return evaluateV2(
      deal,
      finalBid,
      declarer,
      vulnerability,
      doubled, // doubled is boolean
      bidding,
      compete,
      ai,
    );
  }
}
