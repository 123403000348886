import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Leaderboard from '../../../components/leaderBoards/leaderBoard';
import { getWeeklyById } from '../../../firebase/weekly';
import { EventIcon } from '../../../components/icons/dailyIcon';
import { getSessionForEvent } from '../../../firebase/events';
import { useAppStateStore } from '../../../appStateStore.jsx';
import { useTranslation } from 'react-i18next'

export default function WeeklyLeaderBoard() {
  const { t } = useTranslation('translation', { keyPrefix: 'weekly_leaderboard' });
  const { id } = useParams();
  const sessions = useAppStateStore((state) => state.sessions);
  const [weekly, setWeekly] = useState();
  const [loadedWeeklySession, setLoadedWeeklySession] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    async function getWeekly() {
      const w = await getWeeklyById(id);
      if (w) {
        setWeekly(w);
      } else {
        navigate(`/404${location.pathname}`);
      }
    }

    void getWeekly();
  }, [id, navigate]);

  useEffect(
    function () {
      if (id) {
        const getData = async () => {
          const weeklySess = await getSessionForEvent('weekly', id);
          setLoadedWeeklySession(weeklySess);
        };
        void getData();
      }
    },
    [id]
  );

  const weeklySession =
    sessions.find((s) => s.weekly === id) || loadedWeeklySession;

  if (!weekly) {
    return <></>;
  }

  return (
    <>
      <div className="page pb-24">
        <Link
          style={{ width: '100%' }}
          to={weeklySession ? `/session/${weeklySession.id}` : '/'}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              className="btn-primary btn"
              style={{
                marginTop: 10,
                marginBottom: 10,
                width: '350px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EventIcon fill="#e29627" />
              <span>
                {weeklySession
                  ? weeklySession.dealsCount -
                      weeklySession.numberOfFinishedDeals >
                    0
                    ? t('continue')
                    : t('completed')
                  : t('to_start')}
              </span>
            </button>
          </div>
        </Link>
        <div style={{ marginBottom: 5 }}>
          <span>{t('participants', {count: weekly.pairCount ?? 0})}</span>
        </div>
        {
          <span className="info">
            {t('leaderboard_minimum')}
          </span>
        }
        <div className="mt-4 w-full md:w-1/2">
          <Leaderboard
            leaderBoard={weekly.leaderBoard}
            top={30}
            header="Top 30"
            min={45}
            leadersIcon={<EventIcon fill="#e29627" />}
          />
        </div>
      </div>
    </>
  );
}
