import { auth, db, realDb } from './firebase';
import {
  writeBatch,
  collection,
  query,
  where,
  or,
  orderBy,
  getDoc,
  onSnapshot,
  doc,
  getDocs,
  startAfter,
  limit,
  increment,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getCountFromServer,
  addDoc,
} from 'firebase/firestore';

import { getHand } from 'cuebids-hand-util';
import {
  docListenWithCallback,
  docToObject,
  queryListenWithCallback,
  queryToObjects,
} from 'firebase-util';
import {
  update,
  increment as rtdbInc,
  ref,
  set,
  onValue,
  get,
} from 'firebase/database';
import {
  getInitialBidding,
  removeLastBid,
  contractFromOldStyle,
  getBidArrayWithAlertsExcludingPositionalSymbols
} from 'cuebids-bidding-util';
import { getRobotParStrains, checkHasPotentialForParBeatBest } from 'cuebids-evaluation';
import { chargeUsersTickets, checkAllowedToChargeFriendTicketsAndEvents, updateUser } from './userApi';
import { getRobotAction } from 'cuebids-ai';
import { createChatNotification, createSessionSharedNotification } from 'cuebids-firebase/notifications';
import {
  canPairAffordToPlay,
  getCharges,
  getUserRemainingTickets,
} from '../util/tickets';
import { getUserHighestSubscription } from './subscriptions';
import { getPublicUserAsync } from 'cuebids-firebase/users'
import { getRobotUserId } from '../util/robotPartner.js'
import { LowScenarioStock } from './errors.js';
import { aiSystemToLia, dealTypes } from 'cuebids-deals';
import i18next from 'i18next'

export function sessionAdapter(session) {
  if (!session) return null;

  if (!session.compete) {
    session.compete = 0;
  } else if (session.compete === true) {
    session.compete = session.robotVersion === 2 ? 2 : 1;
  }
  session.weekly = session.weekly ?? 0;
  session.daily = session.daily ?? 0;
  session.groupSession = session.groupSession ?? 0;
  session.challenge = session.challenge || 0;
  session.ranked = session.ranked ?? false;
  session.matchmaking = session.matchmaking ?? false;

  if (!session.users.includes(auth.currentUser?.uid)) {
    session.kibitzing = true;
  }

  if (!session.extraUsers) {
    session.extraUsers = [];
  }

  if (session.extraUsers.includes(auth.currentUser?.uid)) {
    session.shared = true;
  }

  return session;
}

function sessionDealAdapter(sessionDeal) {
  if (!sessionDeal) return null;

  if (!sessionDeal.compete) {
    sessionDeal.compete = 0;
  } else if (sessionDeal.compete === true) {
    sessionDeal.compete = sessionDeal.robotVersion === 2 ? 2 : 1;
  }

  if (sessionDeal.finished) {
    if (!sessionDeal.contract) {
      sessionDeal.contract = contractFromOldStyle({
        finalBid: sessionDeal.finalBid,
        declarer: sessionDeal.declarer,
        doubled: sessionDeal.doubled,
      });
    }

    if (!sessionDeal.doubled) {
      sessionDeal.doubled = '';
    } else if (sessionDeal.doubled === true) {
      sessionDeal.doubled = 'X';
    }

    if (
      !sessionDeal.evaluationVersion ||
      sessionDeal.evaluationVersion === 1 ||
      sessionDeal.evaluationVersion === 2
    ) {
      sessionDeal.bestContract = contractFromOldStyle({
        finalBid:
          sessionDeal.bestContract.length === 3 ?
            sessionDeal.bestContract.substring(0, 2) :
            sessionDeal.bestContract, // Handle P
        declarer: sessionDeal.bestContract?.substring(2, 3),
        doubled: sessionDeal.bestContractDoubled,
      });
      sessionDeal.bestContractEv = sessionDeal.evBest;
      sessionDeal.otherContracts = [];
      sessionDeal.parContract = sessionDeal.bestContract;
      sessionDeal.parEv = sessionDeal.bestContractEv;
    }
  }

  if (!sessionDeal.extraUsers) {
    sessionDeal.extraUsers = [];
  }

  if (sessionDeal.extraUsers.includes(auth.currentUser?.uid)) {
    sessionDeal.shared = true;
  }

  let userIndex = sessionDeal.users.indexOf(auth.currentUser?.uid);
  if (userIndex !== -1) {
    if (sessionDeal.users.length === 2 && userIndex === 1) {
      userIndex = 2;
    }
    sessionDeal.handPart = getHand(sessionDeal.hand, userIndex);

    if (sessionDeal.finished || sessionDeal.expired) {
      sessionDeal.partnerHandPart = getHand(sessionDeal.hand, (userIndex + 2) % 4);
    }
  } else {
    sessionDeal.kibitzing = true;
    if (sessionDeal.finished || sessionDeal.expired || sessionDeal.shared) {
      sessionDeal.northHandPart = getHand(sessionDeal.hand, 0);
      sessionDeal.southHandPart = getHand(sessionDeal.hand, 2);
    }
  }

  return sessionDeal;
}

export async function getDeal(dealId) {
  const dealsRef = doc(collection(db, 'deals'), dealId);
  const dealDoc = await getDoc(dealsRef);
  return docToObject(dealDoc);
}

export const sessionsObservableLimit = 10;
export function getSessionsObservable({ callback }) {
  const currentUser = auth.currentUser;

  const q = query(
    collection(db, 'sessions'),
    where('users', 'array-contains', currentUser.uid),
    where('deleted', '==', false),
    orderBy('timestamp', 'desc'),
    limit(sessionsObservableLimit),
  );

  return queryListenWithCallback(q, callback, sessionAdapter);
}

export function getSessionObservable({ sessionId, callback }) {
  const docRef = doc(collection(db, 'sessions'), sessionId);

  return docListenWithCallback(docRef, callback, sessionAdapter);
}

export function getSharedSessionsObservable({ callback }) {
  const currentUser = auth.currentUser;

  const q = query(
    collection(db, 'sessions'),
    where('extraUsers', 'array-contains', currentUser.uid),
    where('deleted', '==', false),
    orderBy('timestamp', 'desc'),
    limit(sessionsObservableLimit),
  );

  return queryListenWithCallback(q, callback, sessionAdapter);
}

export async function getSessionsIHaveShared() {
  const currentUser = auth.currentUser;

  const q = query(
    collection(db, 'sessions'),
    where('users', 'array-contains', currentUser.uid),
    where('extraUsers', '!=', []),
    limit(sessionsObservableLimit),
  );

  return await queryToObjects(q, sessionAdapter);
}

export const sessionsBeforeTimestampLimit = 100;
export async function getSessionsBeforeTimestamp({ timestamp }) {
  const currentUser = auth.currentUser;

  const q = query(
    collection(db, 'sessions'),
    where('users', 'array-contains', currentUser.uid),
    where('deleted', '==', false),
    orderBy('timestamp', 'desc'),
    startAfter(timestamp),
    limit(sessionsBeforeTimestampLimit),
  );

  return queryToObjects(q, sessionAdapter);
}

export async function getLastDealNumber(userIds, tag, challenge = false) {
  let lastDealNumber = 0;

  for (let i = 0; i < userIds.length; i++) {
    const docRef = doc(collection(db, 'dealTimestamps'), userIds[i]);
    const dealNumberDoc = await getDoc(docRef);
    const tagDealNumber =
      dealNumberDoc.data()?.[getLatestDealNumberObjectKey(tag, challenge)] || 0;
    if (tagDealNumber > lastDealNumber) {
      lastDealNumber = tagDealNumber;
    }
  }

  return lastDealNumber;
}

// Get deals not used by the sessions for the current user and a partner, by looking at the latest deal number
export async function getUnusedDeals({
  numberOfDeals,
  partnerId,
  minDealNumber = 0,
  tag,
}) {
  const currentUserId = auth.currentUser.uid;

  let users = [currentUserId, partnerId];

  users = users.filter(u => u !== getRobotUserId());

  const lastDealNumber = await getLastDealNumber(users, tag);

  const startAfterDealNumber = Math.max(lastDealNumber, minDealNumber);

  const dealsRef = collection(db, 'deals');

  const q = tag ?
    query(
      dealsRef,
      orderBy('dealNumber'),
      startAfter(startAfterDealNumber),
      where('version', '==', 3),
      where('type', '==', 'tagged'),
      where('tag', '==', tag),
      limit(numberOfDeals),
    ) :
    query(
      dealsRef,
      orderBy('dealNumber'),
      startAfter(startAfterDealNumber),
      where('version', '==', 3),
      where('type', '==', 'practice'),
      limit(numberOfDeals),
    );

  return await queryToObjects(q);
}

export async function getSession(id) {
  const sessionRef = doc(collection(db, 'sessions'), id);
  return sessionAdapter(docToObject(await getDoc(sessionRef)));
}

function getSessionType({ weekly, daily, challenge, groupSession }) {
  if (weekly) {
    return 'weekly';
  }

  if (daily) {
    return 'daily';
  }

  if (challenge) {
    return 'challenge';
  }

  if (groupSession) {
    return 'groupSession';
  }

  return 'practice';
}

function getEWSystem(tag, aiSystem) {
  if (aiSystem) {
    return aiSystemToLia[aiSystem] ?? 'DEFAULT';
  }
  if (tag) {
    return dealTypes[tag]?.system ?? 'DEFAULT';
  }
  return 'DEFAULT';
}

function getLatestDealNumberObjectKey(tag, challenge) {
  if (tag) {
    return tag;
  } else if (challenge) {
    return 'latestDealNumberChallenge';
  } else {
    return 'latestDealNumber'
  }
}

export async function createSession({
  deals,
  compete,
  userOneId,
  userTwoId,
  weekly = 0,
  daily = 0,
  challenge = 0,
  groupSession = 0,
  groupId = null,
  sessionHeadline = '',
  tags = null,
  tagNames = null,
  matchmaking = false,
  ranked = false,
  returnBatch = false,
  batchToUse = null,
  coachRobot = null,
  proChallenge = false,
  liaSystem = 'DEFAULT'
} = {}) {
  const batch = batchToUse || writeBatch(db);
  const sessionRef = doc(collection(db, 'sessions'));

  const northToAct = deals.filter(
    (d) => d.dealer === 'N' || d.dealer === 'W',
  ).length;

  batch.set(sessionRef, {
    users: [userOneId, userTwoId],
    timestamp: Date.now(),
    deleted: false,
    dealsCount: deals.length,
    numberOfFinishedDeals: 0,
    numberOfStars: 0,
    southToAct: deals.length - northToAct,
    northToAct: northToAct,
    compete,
    weekly,
    daily,
    challenge,
    groupSession,
    groupId,
    sessionHeadline,
    tags,
    tagNames,
    matchmaking,
    ranked,
    coachRobot,
    proChallenge,
    liaSystem,
    type: getSessionType({
      weekly,
      daily,
      challenge,
      groupSession,
    }),
  });

  const latestDealNumberObject = {};

  const sessionDeals = [];
  deals.forEach((d, i) => {
    const tag = d.tag;
    const latestDealNumber =
      latestDealNumberObject[getLatestDealNumberObjectKey(tag, challenge)] || 0;
    if (d.dealNumber > latestDealNumber) {
      latestDealNumberObject[getLatestDealNumberObjectKey(tag, challenge)] = d.dealNumber;
    }
    const sessionDealsRef = doc(collection(db, 'sessionDeals'));

    let bidding = getInitialBidding(d.dealer);

    const liaEWSystem = getEWSystem(tag, d.aiSystem);
    const robotPars = getRobotParStrains({ scores: d.score });
    const hasPotentialForParBeatBest = compete && d.version !== 3 && checkHasPotentialForParBeatBest(d.score, d.hand, d.vulnerability, d.ai);

    if (d.dealer === 'E' || d.dealer === 'W') {
      if (d.suggestedBidding) {
        const bids = getBidArrayWithAlertsExcludingPositionalSymbols(d.suggestedBidding.bidding);
        if (bids.length > 0) {
          bidding += '-' + bids[0]
        } else {
          bidding += getRobotAction({
            compete,
            sessionDeal: {
              ...d,
              robotPars,
              hasPotentialForParBeatBest,
            },
            bidding,
          })
        }
      } else {
        bidding += getRobotAction({
          compete,
          sessionDeal: {
            ...d,
            robotPars,
            hasPotentialForParBeatBest,
          },
          bidding,
        })
      }
    }

    batch.set(sessionDealsRef, {
      sessionId: sessionRef.id,
      timestamp: Date.now(),
      bidding: bidding,
      dealId: d.id,
      hand: d.hand,
      vulnerability: d.vulnerability,
      robotPars,
      hasPotentialForParBeatBest,
      dealer: d.dealer,
      finished: false,
      compete,
      weekly,
      daily,
      challenge,
      groupSession,
      groupId,
      sessionHeadline,
      proChallenge,
      liaSystem,
      liaEWSystem,
      tag: tag || null,
      tagName: d.tagName || null,
      type: getSessionType({
        weekly,
        daily,
        challenge,
        groupSession,
      }),
      users: [userOneId, userTwoId],
      turn: d.dealer === 'W' || d.dealer === 'N' ? userOneId : userTwoId,
      dealNumber: i + 1,
      withReview: true,
      coachRobot: coachRobot || null,
      suggestedBidding: d.suggestedBidding || false,
      ai: d.ai ||  null,
      aiSystem: d.aiSystem ||  null,
    });
    // TODO: This is tightly coupled to the format challenge wants
    sessionDeals.push({
      id: sessionDealsRef.id,
      sessionId: sessionRef.id,
      finished: false,
      dealNr: i + 1,
    });
  });

  const rbRef = ref(realDb, 'stats/dailyStats/');
  update(rbRef, { boardsDealt: rtdbInc(deals.length) });

  const partnerUserRef = doc(collection(db, 'dealTimestamps'), userTwoId);
  const currentUserRef = doc(collection(db, 'dealTimestamps'), userOneId);

  if (weekly === 0 && daily === 0 && groupSession === 0 && !proChallenge) {
    batch.set(
      partnerUserRef,
      {
        ...latestDealNumberObject,
      },
      {
        merge: true,
      },
    );

    batch.set(
      currentUserRef,
      {
        ...latestDealNumberObject,
      },
      {
        merge: true,
      },
    );
  }

  if (returnBatch) {
    return {
      batch,
      sessionRef,
      sessionDeals,
    };
  }

  await batch.commit();

  return sessionRef.id;
}

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export function shuffle(array) {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

function getRandomArrayItem(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function getNumberOfDealsPerTag(tags, numberOfDeals) {
  const result = {};
  for (let i = 0; i < numberOfDeals; i++) {
    const tag = getRandomArrayItem(tags);
    const oldNumber = result[tag] || 0;
    result[tag] = oldNumber + 1;
  }
  return result;
}

export async function createPracticeSession({
  userTwoId,
  numberOfDeals,
  compete = 0,
  tags = [],
  scenarios = [],
  minDealNumber,
  matchmaking = false,
  returnBatch = false,
  price,
  liaSystem
}) {
  if (price) {
    return createPracticeSessionWithCharge({
      userTwoId,
      numberOfDeals,
      compete,
      tags,
      scenarios,
      minDealNumber,
      price,
      liaSystem
    });
  }

  return createPracticeSessionWithoutCharge({
    userTwoId,
    numberOfDeals,
    compete,
    tags,
    scenarios,
    minDealNumber,
    matchmaking,
    returnBatch,
    liaSystem
  });
}

export async function createPracticeSessionWithCharge({
  userTwoId,
  numberOfDeals,
  compete = 0,
  tags = [],
  scenarios = [],
  minDealNumber,
  price,
  liaSystem
} = {}) {
  const currentUser = auth.currentUser;

  // Need to get partner's user for rating and tickets
  const partnerUserRef = doc(collection(db, 'users'), userTwoId);
  const partnerUserDoc = await getDoc(partnerUserRef);
  const partnerUserData = partnerUserDoc.data();

  const _user = doc(collection(db, 'users'), currentUser.uid);
  const userDoc = await getDoc(_user);
  const userDocData = userDoc.data();

  // Check available tickets
  const myHighestSub = await getUserHighestSubscription(currentUser.uid);
  const partnersHighestSub = await getUserHighestSubscription(userTwoId);
  const partnerTicketsSpent = partnerUserData.ticketsSpent ?? 0;
  const partnerExtraTickets = partnerUserData.extraTickets ?? 0;

  const myRemainingTickets = getUserRemainingTickets(
    userDocData.ticketsSpent ?? 0,
    myHighestSub,
    userDocData.extraTickets ?? 0,
  );
  const partnerRemainingTickets = partnerUserData.robot ? Infinity : getUserRemainingTickets(
    partnerTicketsSpent,
    partnersHighestSub,
    partnerExtraTickets,
  );

  if (myRemainingTickets < price) {
    throw new Error(i18next.t('bidding_sessions.error_cannot_afford'));
  }

  if (
    !canPairAffordToPlay({
      myRemainingTickets,
      partnerRemainingTickets,
      price,
    })
  ) {
    throw new Error(i18next.t('bidding_sessions.error_partner_cannot_afford'));
  }

  const { batch, sessionRef } = await createPracticeSessionWithoutCharge({
    userTwoId,
    numberOfDeals,
    compete,
    tags,
    scenarios,
    minDealNumber,
    returnBatch: true,
    liaSystem
  });

  const { myCharge, partnerCharge } = getCharges({
    myRemainingTickets,
    partnerRemainingTickets,
    price,
  });

  if (partnerCharge > 0) {
    const allowedToCharge = await checkAllowedToChargeFriendTicketsAndEvents(userTwoId);

    if (!allowedToCharge) {
      throw new Error(i18next.t('bidding_sessions.error_tickets_spending_disabled'));
    }
  }

  await chargeUsersTickets({
    userCharges: [
      {
        user: currentUser.uid,
        charge: myCharge,
      },
      {
        user: userTwoId,
        charge: partnerCharge,
      },
    ],
    batch,
  });

  await batch.commit();

  return sessionRef.id;
}

export async function createPracticeSessionWithoutCharge({
  userTwoId,
  numberOfDeals,
  compete = 0,
  tags = [],
  scenarios = [],
  minDealNumber,
  matchmaking = false,
  returnBatch = false,
  liaSystem = 'DEFAULT'
} = {}) {
  if (!userTwoId) {
    throw new Error(i18next.t('bidding_sessions.error_missing_partner'));
  }

  const tagsAndScenarios = tags.concat(scenarios.map(s => s.id));
  const tagNames = tags.concat(scenarios).map(t => t.name ?? null);

  let deals;
  if (tagsAndScenarios.length) {
    const numberOfDealsPerTag = getNumberOfDealsPerTag(tagsAndScenarios, numberOfDeals);

    const allDealsPromises = Object.keys(numberOfDealsPerTag).map(
      async function(tag) {
        const numberOfDealsForTag = numberOfDealsPerTag[tag];

        if (!numberOfDealsForTag) return [];
        const scenario = scenarios.find(s => s.id === tag);
        const minDealNumber = scenario?.minDealNumber ?? 0;
        return await getUnusedDeals({
          numberOfDeals: numberOfDealsForTag,
          partnerId: userTwoId,
          tag,
          minDealNumber,
        });
      },
    );
    const allDealsSettled = await Promise.allSettled(allDealsPromises);

    deals = allDealsSettled.reduce(function(ds, s) {
      if (s.status === 'rejected') {
        throw new Error(i18next.t('bidding_sessions.error_generic'));
      }
      if (s.status === 'fulfilled') {
        return ds.concat(s.value);
      }
      return ds;
    }, []);
    deals = shuffle(deals);
  } else {
    deals = await getUnusedDeals({
      numberOfDeals,
      partnerId: userTwoId,
      minDealNumber,
    });
  }

  if (!deals || deals.length < numberOfDeals) {
    if (scenarios.length) {
      throw new LowScenarioStock(i18next.t('bidding_sessions.error_insufficient_deals_scenario'));
    }
    throw new Error(i18next.t('bidding_sessions.error_insufficient_deals'));
  }

  const currentUser = auth.currentUser;

  const isRobot = userTwoId === getRobotUserId();

  if (!matchmaking) {
    const userUpdateObject = {};

    if (tags.length) {
      userUpdateObject.practiceDefaultTags = tags;
    }

    if (scenarios.length) {
      userUpdateObject.practiceDefaultScenarios = scenarios.map(s => s.id);
    }

    if (!isRobot) {
      userUpdateObject.practiceDefaultPartner = userTwoId;
    } else {
      userUpdateObject.defaultLiaSystem = liaSystem;
    }

    userUpdateObject.practiceDefaultNumberOfDeals = numberOfDeals;
    userUpdateObject.practiceDefaultRobots = compete;

    void updateUser(userUpdateObject);
  }

  return await createSession({
    deals,
    compete,
    userOneId: currentUser.uid,
    userTwoId,
    tags: tagsAndScenarios,
    tagNames,
    matchmaking,
    returnBatch,
    liaSystem
  });
}

export function getSessionDealsObservable({
  sessionId,
  callback,
  acceptCachedData = true,
}) {
  const q = query(
    collection(db, 'sessionDeals'),
    where('sessionId', '==', sessionId),
    orderBy('dealNumber'),
  );

  // If the data is correct the first snapshot and the only change is to the metadata (fromCache) you might not get a second snapshot update without opting in to metadata changes.
  // https://github.com/firebase/firebase-js-sdk/issues/3053
  return onSnapshot(
    q,
    { includeMetadataChanges: !acceptCachedData },
    (querySnapshot) => {
      const fromCache = querySnapshot.metadata.fromCache;
      if (fromCache && !acceptCachedData) {
        return;
      }
      callback(
        querySnapshot.docs.map((a) => {
          return sessionDealAdapter({
            ...a.data(),
            id: a.id,
          });
        }),
      );
    },
  );
}

export async function getSessionDeals(sessionId) {
  const q = query(
    collection(db, 'sessionDeals'),
    where('sessionId', '==', sessionId),
    orderBy('dealNumber'),
  );

  return await queryToObjects(q, sessionDealAdapter);
}

export function getSessionDealPublicObservable({ sessionDealId, callback }) {
  const sessionDealsRef = doc(collection(db, 'sessionDeals'), sessionDealId);

  return onSnapshot(sessionDealsRef, (sessionDeal) => {
    const data = sessionDeal.data();
    if (!data) {
      callback();
      return;
    }

    data.kibitzing = true;
    if (data.finished) {
      data.northHandPart = getHand(data.hand, 0);
      data.southHandPart = getHand(data.hand, 2);
    }

    callback(sessionDealAdapter({
      ...data,
      id: sessionDeal.id,
    }));
  });
}

// TODO: Can this be removed and only handled by the one that gets an entire session worth of deals?
export function getSessionDealObservable({ sessionDealId, callback }) {
  const sessionDealsRef = doc(collection(db, 'sessionDeals'), sessionDealId);

  return docListenWithCallback(sessionDealsRef, callback, sessionDealAdapter);
}

export async function getSessionDeal(sessionDealId) {
  const sessionDealsRef = doc(collection(db, 'sessionDeals'), sessionDealId);

  const sessionDealDoc = await getDoc(sessionDealsRef);

  return docToObject(sessionDealDoc, sessionDealAdapter);
}

function getBidderIndex(users, userId) {
  return users.indexOf(userId);
}

export async function undoLastBid({ deal }) {
  if (deal.finished) {
    return;
  }
  const userCount = deal.users.length;
  let bidding = removeLastBid(deal.bidding);
  if (userCount === 2) {
    // Only 2 players, remove a robot bid too
    bidding = removeLastBid(bidding);
  }
  const currentUser = auth.currentUser;
  const dec = increment(-1);
  const inc = increment(1);
  const sessionRef = doc(collection(db, 'sessions'), deal.sessionId);
  const sessionDealRef = doc(collection(db, 'sessionDeals'), deal.id);
  const batch = writeBatch(db);

  const actorKey = ['northToAct', 'southToAct'][
    getBidderIndex(deal.users, currentUser.uid)
  ];
  const nextActorKey = {
    northToAct: 'southToAct',
    southToAct: 'northToAct',
  }[actorKey];

  batch.update(sessionRef, {
    [actorKey]: inc,
    [nextActorKey]: dec,
  });

  batch.update(sessionDealRef, {
    bidding,
    turn: currentUser.uid,
    lastAction: 'undo',
    lastBidTimestamp: null,
    clientLastBidTimestamp: null,
  });

  return batch.commit();
}

export async function doubleUndo({ deal }) {
  if (deal.finished) {
    return;
  }

  // Remove two player and two robot bids (to make it my turn again)
  let bidding = removeLastBid(deal.bidding);
  bidding = removeLastBid(bidding);
  bidding = removeLastBid(bidding);
  bidding = removeLastBid(bidding);

  const sessionDealRef = doc(collection(db, 'sessionDeals'), deal.id);

  await updateDoc(sessionDealRef, {
    bidding,
    lastAction: 'doubleUndo',
    lastBidTimestamp: null,
    clientLastBidTimestamp: null,
  });
}

export async function deleteSession(sessionId) {
  const sessionRef = doc(collection(db, 'sessions'), sessionId);

  return updateDoc(sessionRef, {
    deleted: Date.now(),
  });
}

export async function joinSessionDeal(sessionDealId, userId) {
  const sessionDealRef = doc(collection(db, 'sessionDeals'), sessionDealId);

  const _sessionDeal = await getDoc(sessionDealRef);
  const { sessionId } = _sessionDeal.data()

  const sessionRef = doc(collection(db, 'sessions'), sessionId);

  await updateDoc(sessionRef, {
    extraUsers: arrayUnion(userId),
    lastAction: 'coach'
  })

  await updateDoc(sessionDealRef, {
    extraUsers: arrayUnion(userId),
    lastAction: 'coach'
  })

  const sessionDealsMessagesRef = collection(doc(collection(db, 'sessionDeals'), sessionDealId), 'messages')

  await addDoc(sessionDealsMessagesRef, {
    timestamp: Date.now(),
    message: i18next.t('bidding_sessions.coach_joined_chat'),
    userId: userId,
    specialMessage: true
  });
}

export async function shareSession(sessionId, friendId) {
  const currentUser = auth.currentUser;

  const sessionRef = doc(collection(db, 'sessions'), sessionId);

  const q = query(
    collection(db, 'sessionDeals'),
    where('sessionId', '==', sessionId),
  );

  const sessionDeals = await getDocs(q);

  const batch = writeBatch(db);

  batch.update(sessionRef, {
    extraUsers: arrayUnion(friendId),
  });

  sessionDeals.docs.forEach((sessionDeal) => {
    batch.update(sessionDeal.ref, {
      extraUsers: arrayUnion(friendId),
      lastAction: 'share',
    });
  });

  await batch.commit();

  void createSessionSharedNotification(friendId, currentUser.displayName, sessionId);
}

export async function unshareSession(sessionId, friendId) {
  const sessionRef = doc(collection(db, 'sessions'), sessionId);

  const q = query(
    collection(db, 'sessionDeals'),
    where('sessionId', '==', sessionId),
  );

  const sessionDeals = await getDocs(q);

  const batch = writeBatch(db);

  batch.update(sessionRef, {
    extraUsers: arrayRemove(friendId),
  });

  sessionDeals.docs.forEach((sessionDeal) => {
    batch.update(sessionDeal.ref, {
      extraUsers: arrayRemove(friendId),
      lastAction: 'unshare',
    });
  });

  return batch.commit();
}

export async function removeSharedSession(sessionId) {
  return await unshareSession(sessionId, auth.currentUser.uid);
}

export async function toggleMarkSessionDeal(sessionDealId, value) {
  const sessionDealsRef = doc(collection(db, 'sessionDeals'), sessionDealId);

  return updateDoc(sessionDealsRef, {
    marked: value,
    lastAction: value ? 'marked' : 'unmarked',
  });
}

export async function getAllMarkedSessionDeals() {
  const currentUser = auth.currentUser;
  const sessionDealsRef = collection(db, 'sessionDeals');

  const q = query(
    sessionDealsRef,
    where('users', 'array-contains', currentUser.uid),
    where('marked', '==', true),
    orderBy('timestamp', 'desc'),
  );

  return await queryToObjects(q, sessionDealAdapter);
}

export async function getAllMarkedSessionDealsForShared() {
  const currentUser = auth.currentUser;
  const sessionDealsRef = collection(db, 'sessionDeals');

  const q = query(
    sessionDealsRef,
    where('extraUsers', 'array-contains', currentUser.uid),
    where('marked', '==', true),
    orderBy('timestamp', 'desc'),
  );

  return await queryToObjects(q, sessionDealAdapter);
}

async function sendChatMessageNotification(sessionDealsRef, message) {
  const currentUser = auth.currentUser;

  const sessionDealDoc = await getDoc(sessionDealsRef);
  const data = sessionDealDoc.data();
  const allUsers = data.users.concat(data.extraUsers || []);

  const users = allUsers.filter((u) => u !== currentUser.uid);

  const usr = await getPublicUserAsync(currentUser.uid)

  const promises = users.map(async (u) => {
    const usrRef = doc(collection(db, 'users'), u);
    const user = await getDoc(usrRef);
    const userSettings = user.data();

    if (userSettings.allowChatPush) {
      await createChatNotification(u, usr.displayName ?? currentUser.displayName, message, 'https://cuebids.com/session/deal/' + sessionDealDoc.id + '?chatOpen=true');
    }
  });

  await Promise.all(promises);
}

export async function sendChatMessage(
  sessionId,
  sessionDealId,
  message,
  userIndex,
  shared,
) {
  const currentUser = auth.currentUser;
  const inc = increment(1);
  const field = ['southToRead', 'northToRead'][userIndex];
  const sessionDealsRef = doc(collection(db, 'sessionDeals'), sessionDealId);
  const sessionRef = doc(collection(db, 'sessions'), sessionId);
  const sessionDealsMessagesRef = doc(
    collection(doc(collection(db, 'sessionDeals'), sessionDealId), 'messages'),
  );

  const batch = writeBatch(db);

  if (shared) {
    batch.update(sessionRef, {
      southToRead: inc,
      northToRead: inc,
      numberOfMessages: inc,
    });

    batch.update(sessionDealsRef, {
      southToRead: inc,
      northToRead: inc,
      numberOfMessages: inc,
      lastAction: 'chatMessage',
    });
  } else {
    batch.update(sessionRef, {
      [field]: inc,
      numberOfMessages: inc,
    });

    batch.update(sessionDealsRef, {
      [field]: inc,
      numberOfMessages: inc,
      lastAction: 'chatMessage',
    });
  }

  batch.set(sessionDealsMessagesRef, {
    timestamp: Date.now(),
    displayName: currentUser.displayName,
    photoURL: currentUser.photoURL, // TODO: Detta borde kunna tas bort?
    message: message,
    userId: currentUser.uid,
    messageFromExtra: userIndex === -1
  });

  await batch.commit();

  void sendChatMessageNotification(sessionDealsRef, message);
}

export async function setChatMessagesAsRead(
  sessionId,
  sessionDealId,
  userIndex,
  unreadMessages,
) {
  const field = ['northToRead', 'southToRead'][userIndex];

  const sessionDealsRef = doc(collection(db, 'sessionDeals'), sessionDealId);
  const sessionRef = doc(collection(db, 'sessions'), sessionId);

  const batch = writeBatch(db);

  batch.update(sessionRef, {
    [field]: increment(-unreadMessages),
  });

  batch.update(sessionDealsRef, {
    [field]: 0,
    lastAction: 'chatRead',
  });

  return batch.commit();
}

export function getChatMessagesObservable({ sessionDealId, callback }) {
  const deal = doc(collection(db, 'sessionDeals'), sessionDealId);
  const messages = collection(deal, 'messages');
  const q = query(messages, orderBy('timestamp', 'desc'), limit(100));

  return onSnapshot(q, (querySnapshot) => {
    callback(
      querySnapshot
        .docChanges()
        .filter(c => c.type === 'added')
        .reverse()
        .map((c) => {
          return {
            id: c.doc.id,
            ...c.doc.data()
          }
        }),
    );
  });
}

// Note: This is only used on old deals without review when compete was a boolean
export async function getAllResponsesToSessionDeal({
  dealId,
  compete = false,
  evaluationVersion = 1,
}) {
  const sessionDealsRef = collection(db, 'sessionDeals');

  const q = query(
    sessionDealsRef,
    where('dealId', '==', dealId),
    where('finished', '==', true),
    where('compete', '==', Boolean(compete)),
    where('evaluationVersion', '==', evaluationVersion),
    orderBy('ev', 'desc'),
    limit(20),
  );

  const sessionDeals = await getDocs(q);

  return sessionDeals.docs.map((a) => {
    const data = a.data();
    return {
      contract: contractFromOldStyle({
        finalBid: data.finalBid,
        declarer: data.declarer,
        doubled: data.doubled,
      }),
      ev: data.ev,
      grade: data.resultGrade,
      users: data.users,
      bidding: data.bidding,
    };
  });
}

export async function setLastReadKibitzer(sessionDealId) {
  const lastReadData = ref(realDb, `sessionDeals/${sessionDealId}/lastReadData/${auth.currentUser.uid}`);

  await set(lastReadData, Date.now());
}

export function getLastReadKibitzerObservable({ sessionDealId, callback }) {
  const lastReadData = ref(realDb, `sessionDeals/${sessionDealId}/lastReadData/${auth.currentUser.uid}`);

  return onValue(lastReadData, (snapshot) => {
    callback(snapshot.val());
  });
}

function getLastReadKibitzer(sessionDealId) {
  const lastReadData = ref(realDb, `sessionDeals/${sessionDealId}/lastReadData/${auth.currentUser.uid}`);

  return get(lastReadData).then((snapshot) => {
    return snapshot.val();
  });
}

export async function getNumberOfUnreadMessagesForKibitzer(sessionDealId) {
  const lastReadTimestamp = await getLastReadKibitzer(sessionDealId) ?? 0;

  const sessionDeal = doc(collection(db, 'sessionDeals'), sessionDealId);
  const messages = collection(sessionDeal, 'messages');
  const q = query(messages, where('timestamp', '>', lastReadTimestamp));

  return (await getCountFromServer(q)).data().count;
}

export async function getNumberOfUnreadMessagesForSessionForKibitzer(sessionId) {
  // TODO: Can this query be avoided?
  const sessionDealsQuery = query(
    collection(db, 'sessionDeals'),
    where('sessionId', '==', sessionId),
    where('extraUsers', 'array-contains', auth.currentUser.uid),
  );

  const sessionDeals = await queryToObjects(sessionDealsQuery);

  const promises = [];
  let totalUnreadMessages = 0;
  sessionDeals.forEach(function(sessionDeal) {
    promises.push(getNumberOfUnreadMessagesForKibitzer(sessionDeal.id).then((unreadMessages) => {
      totalUnreadMessages += unreadMessages;
    }));
  });

  await Promise.all(promises);

  return totalUnreadMessages;
}

export async function syncSessionAggregatedFields(
  sessionId,
  { northToAct, southToAct, northToRead, southToRead },
) {
  const sessionRef = doc(collection(db, 'sessions'), sessionId);

  return updateDoc(sessionRef, {
    northToAct,
    southToAct,
    northToRead,
    southToRead,
  });
}

export function setFinished(sessionDeal) {
  const sessionDealsRef = doc(collection(db, 'sessionDeals'), sessionDeal.id);
  const sessionRef = doc(collection(db, 'sessions'), sessionDeal.sessionId);

  const batch = writeBatch(db);

  const actorKey = ['northToAct', 'southToAct'][
    getBidderIndex(sessionDeal.users, auth.currentUser.uid)
  ];

  batch.update(sessionDealsRef, {
    turn: null,
    lastAction: 'finished',
  });

  batch.update(sessionRef, {
    [actorKey]: increment(-1),
  });

  return batch.commit();
}

export async function getNumberOfSessionsWithLia() {
  const q = query(
    collection(db, 'sessions'),
    or(
      where('users', '==', [auth.currentUser.uid, getRobotUserId()]),
      where('users', '==', [getRobotUserId(), auth.currentUser.uid]),
    ),
  );

  return (await getCountFromServer(q)).data().count;
}
