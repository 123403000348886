import { create } from 'zustand';
import log from './util/logger';
import { initialAppState, resetOnLogoutInitialAppState } from 'cuebids-state';

function validateForbiddenKeys(update) {
  const forbiddenStateUpdateKeys = ['language', 'groupsMessages', 'groupsExtraData'];
  Object.keys(update).forEach(function (k) {
    if (forbiddenStateUpdateKeys.includes(k)) {
      throw new Error(`Trying to update state with forbidden key ${k} which should be updated via special setter`);
    }
  });
}

function validateKeysAreInInitialAppState(update) {
  Object.keys(update).forEach(function (k) {
    if (!(k in initialAppState)) {
      throw new Error(`Trying to add key ${k} to state which is not present in initialAppState`);
    }
  });
}

export const useAppStateStore = create((set) => ({
  // State
  ...initialAppState,

  // General setter
  updateAppState: (update) => {
    if (import.meta.env.VITE_INSTANCE_NAME === 'dev') {
      validateForbiddenKeys(update);
      validateKeysAreInInitialAppState(update);
    }
    if (import.meta.env.LOG_LEVEL === 'debug') {
      log('[STATE UPDATED]', update);
    }
    set(update);
  },
  // Special setters
  showReviewDialog: () => set(() => ({ reviewDialogOpen: true })),
  hideReviewDialog: () => set(() => ({ reviewDialogOpen: false })),
  showChat: () => set(() => ({ chatOpen: true })),
  hideChat: () => set(() => ({ chatOpen: false })),
  showMarketingDialog: () => set(() => ({ checkShowMarketing: false })),
  updateGroupsExtraData: (update) => set((state) => ({
    groupsExtraData: {
      ...state.groupsExtraData,
      ...update,
    },
  })),
  updateGroupsMessages: (update) => set((state) => ({
    groupsMessages: {
      ...state.groupsMessages,
      ...update,
    },
  })),
  updateNumberOfNewSpots: (update) => set((state) => ({
    numberOfNewSpots: {
      ...state.numberOfNewSpots,
      ...update,
    },
  })),
  updateSinglePlayerRobotErrors: (update) => set((state) => ({
    singlePlayerRobotErrors: {
      ...state.singlePlayerRobotErrors,
      ...update,
    },
  })),
  clearSinglePlayerRobotErrors: () => set(() => ({
    singlePlayerRobotErrors: {},
  })),
  setLanguage: (language) => set(() => {
    localStorage.setItem('language', language);
    return { language };
  }),
  resetAppStateAfterLogout: () => set(resetOnLogoutInitialAppState),
}));
