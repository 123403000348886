import { useState } from 'react';
import AddHandTypeDialog from './addHandTypeDialog.jsx';
import { DeleteIcon } from '../icons/deleteIcon.jsx';
import { useTranslation } from 'react-i18next';
import { replaceSuitShorthandWithSymbols } from 'cuebids-bidding-util';

export default function DirectionHandTypes({
  dir,
  isRobot = false,
  isDealer,
  isRhoDealer,
  selectedHandTypes,
  onAdd,
  onAddPartner,
  onAddLho,
  onAddRho,
  onAddSettings,
  onRemove,
  onEditCustom,
  onAddNewCustom,
  variables,
  robots,
}) {
  const [addingHandType, setAddingHandType] = useState(false);
  const [editingHandType, setEditingHandType] = useState(null);
  const [editingHandTypeIndex, setEditingHandTypeIndex] = useState(-1);

  const { t } = useTranslation('translation', {
    keyPrefix: 'scenarios.hand_types',
  });

  const handleAdd = (handType) => {
    onAdd(handType);
    setAddingHandType(false);
  };

  const handleEdit = (i) => {
    const handType = selectedHandTypes[i];
    setEditingHandTypeIndex(i);
    setEditingHandType(handType);
  };

  const handleStopEdit = () => {
    setEditingHandType(null);
    setEditingHandTypeIndex(-1);
  };

  const handleSaveEdit = async (handType) => {
    await onEditCustom?.(editingHandTypeIndex, handType);
    setEditingHandType(null);
    setEditingHandTypeIndex(-1);
  };

  const handleAddNewCustom = async (handType) => {
    await onAddNewCustom?.(handType);
    setAddingHandType(false);
  };

  return (
    <div className="flex flex-col items-center">
      <label className={`${isDealer ? 'text-secondary font-bold' : 'normal'}`}>
        {t(dir)}
      </label>
      {!isRobot || robots !== 'NONE' ? (
        <>
          <div className="text-xs mb-1">
            {selectedHandTypes.length === 0 ? (
              <span className="info">{t('no_hand_types')}</span>
            ) : (
              selectedHandTypes
                .sort(
                  (a, b) =>
                    (b.command != null ? 999 : 0) -
                    (a.command != null ? 999 : 0)
                )
                .map((h, i) => {
                  const isCommand = h.command != null;
                  return (
                    <div key={h.name + i}>
                      <div className="flex items-center justify-between gap-2">
                        <span
                          onClick={isCommand ? undefined : () => handleEdit(i)}
                          className={`${
                            isCommand
                              ? 'font-bold text-secondary'
                              : 'normal cursor-pointer'
                          }`}
                        >
                          {replaceSuitShorthandWithSymbols(h.label)}
                        </span>
                        <button
                          className="btn btn-sm btn-error h-4 w-6 p-0"
                          onClick={() => onRemove(i)}
                        >
                          <DeleteIcon width={12} />
                        </button>
                      </div>
                      {i !== selectedHandTypes.length - 1 && (
                        <div className="divider my-1">{!isCommand && t('or')}</div>
                      )}
                    </div>
                  );
                })
            )}
          </div>
          <button
            className="btn btn-primary btn-sm h-4 px-1"
            onClick={() => setAddingHandType(true)}
          >
            {t('add')}
          </button>
        </>
      ) : (
        <div className="info max-w-[100px]">{t('enable_robots_to_add')}</div>
      )}
      {editingHandType && (
        <AddHandTypeDialog
          key={selectedHandTypes.length + 'edit' + editingHandType?.name}
          open={true}
          dir={dir}
          onClose={handleStopEdit}
          onSaveEdit={handleSaveEdit}
          editHandType={editingHandType}
          variables={variables}
          isRobot={isRobot}
          robots={robots}
          isDealer={isDealer}
          isRhoDealer={isRhoDealer}
        />
      )}
      {addingHandType && (
        <AddHandTypeDialog
          key={selectedHandTypes.length}
          open={true}
          dir={dir}
          onClose={() => setAddingHandType(false)}
          onAdd={handleAdd}
          onAddPartner={onAddPartner}
          onAddLho={onAddLho}
          onAddRho={onAddRho}
          onAddSettings={onAddSettings}
          onAddNewCustom={handleAddNewCustom}
          variables={variables}
          isRobot={isRobot}
          robots={robots}
          isDealer={isDealer}
          isRhoDealer={isRhoDealer}
        />
      )}
    </div>
  );
}
