
import {
  contractFromOldStyle,
  getContractDeclarer,
  getContractDoubled,
  getContractLevel,
  getContractStrain,
  getContractUndoubled,
  getContractWithoutDeclarer,
  isContractDoubled,
  getHighestRobotBid,
  isBidHigher,
  rollBackBiddingToBid,
} from 'cuebids-bidding-util';
import { getPotentialRobotLevels, getRobotAction, getRobotParStrains } from 'cuebids-ai';

export function gradeQuotient(resultTotalPoints, doubleDummyScore) {
  if (doubleDummyScore < 1) {
    return 0;
  }
  let resultGrade = 0;
  const resultPercent = resultTotalPoints / doubleDummyScore;
  if (resultPercent >= 0.85) {
    resultGrade = 3;
  } else if (resultPercent >= 0.5) {
    resultGrade = 2;
  } else if (resultPercent >= 0.25) {
    resultGrade = 1;
  }
  return resultGrade;
}

function getImp(diff) {
  const impTable = [
    20, 50, 90, 130, 170, 220, 270, 320, 370, 430, 500, 600, 750, 900, 1100,
    1300, 1500, 1760, 2000, 2250, 2500, 3000, 3500, 4000,
  ];
  for (let i = 0; i < impTable.length; i++) {
    if (diff < impTable[i]) return i;
  }
  return 24;
}

export function gradeImp(ourEv, bestEv) {
  const diff = bestEv - ourEv;
  const imp = getImp(diff);
  let resultGrade = 0;

  if (imp <= 1) {
    resultGrade = 3;
  } else if (imp < 3) {
    resultGrade = 2;
  } else if (imp < 7) {
    resultGrade = 1;
  }
  return resultGrade;
}

export function grade(ev, evBest) {
  return Math.max(gradeImp(ev, evBest), gradeQuotient(ev, evBest));
}

function getBestInitContract(scores, contract) {
  if (contract === 'P') {
    return {
      contract: 'P',
      contractEv: 0,
      undoubledContract: 'P',
      undoubledContractEv: 0,
      doubledContract: 'P',
      doubledContractEv: 0,
    };
  }

  const contractUndoubled = getContractUndoubled(contract);

  const contractDoubled = getContractDoubled(contractUndoubled);
  const contractUndoubledEv = scores[contractUndoubled];
  const contractDoubledEv = scores[contractDoubled];

  const contractEv =
    contractUndoubledEv < 0 ?
      Math.min(contractUndoubledEv, contractDoubledEv) :
      contractUndoubledEv; // Only double if that is worse for declarer
  const bestContract =
    contractUndoubledEv === contractEv ? contractUndoubled : contractDoubled;

  return {
    contract: bestContract,
    contractEv,
    undoubledContract: contractUndoubled,
    undoubledContractEv: contractUndoubledEv,
    doubledContract: contractDoubled,
    doubledContractEv: contractDoubledEv,
  };
}

export function checkHasPotentialForParBeatBest(
  scores,
  hand,
  vulnerability,
  ai,
) {
  const levels = [1, 2, 3, 4, 5, 6, 7];
  const suits = ['C', 'D', 'H', 'S', 'N'];
  const directions = ['N', 'E', 'S', 'W'];

  let parContract = 'P';
  let parEv = 0;
  let bestContract = 'P';
  let bestContractEv = 0;

  const potentialRobotLevels = getPotentialRobotLevels(hand, vulnerability, ai);

  levels.forEach((l) => {
    suits.forEach((s) => {
      directions.forEach((d) => {
        const { contract, contractEv } = getBestInitContract(scores, l + s + d);
        if (['E', 'W'].includes(d)) {
          // -contractEv to turn it into "board ev", i.e. from NS perspective
          // EW want the lowest possible board ev
          if (-contractEv <= bestContractEv) {
            bestContract = contract;
            bestContractEv = -contractEv;
          }
          if (-contractEv <= parEv && potentialRobotLevels[s] >= l) {
            parContract = contract;
            parEv = -contractEv;
          }
        } else {
          if (contractEv >= bestContractEv) {
            bestContractEv = contractEv;
            bestContract = contract;
          }
          if (contractEv >= parEv) {
            parContract = contract;
            parEv = contractEv;
          }
        }
      });
    });
  });

  return parEv > bestContractEv;
}

export function getPar(
  scores,
  highestRobotBid,
  ourContract,
  hand,
  vulnerability,
  compete,
  ai,
) {
  const levels = [1, 2, 3, 4, 5, 6, 7];
  const suits = ['C', 'D', 'H', 'S', 'N'];
  const directions = compete ? ['N', 'E', 'S', 'W'] : ['N', 'S'];

  let parContract = 'P';
  let parEv = 0;

  let {
    contract: bestContract,
    contractEv: bestContractEv,
    undoubledContract: robotHighestContract,
    undoubledContractEv: robotHighestContractEv,
    doubledContract: robotHighestContractDoubled,
    doubledContractEv: robotHighestContractDoubledEv,
  } = getBestInitContract(scores, compete ? highestRobotBid : 'P');

  const potentialRobotLevels = getPotentialRobotLevels(hand, vulnerability, ai);

  // EW scores are from perspective of declarer, not NS - hence invert it with minus
  bestContractEv = -bestContractEv;

  if (
    ['N', 'S'].includes(getContractDeclarer(ourContract)) &&
    scores[ourContract] >= bestContractEv
  ) {
    bestContract = ourContract;
    bestContractEv = scores[ourContract];
  }
  if (
    ['E', 'W'].includes(getContractDeclarer(ourContract)) &&
    -scores[ourContract] >= bestContractEv
  ) {
    bestContract = ourContract;
    bestContractEv = -scores[ourContract];
  }

  let bestNS = 'P';
  let bestNSEv = -1000;

  let bestEW = 'P';
  let bestEWEv = -1000;

  levels.forEach((l) => {
    suits.forEach((s) => {
      directions.forEach((d) => {
        const { contract, contractEv } = getBestInitContract(scores, l + s + d);
        if (['E', 'W'].includes(d)) {
          // Only accept EW contracts that are within the potential level
          if (compete && potentialRobotLevels[s] >= l) {
            // EW want lowest parEv
            // -contractEv to turn it into "board ev", i.e. from NS perspective
            if (-contractEv <= parEv) {
              if (['N', 'S'].includes(getContractDeclarer(parContract))) {
                bestNS = parContract;
                bestNSEv = parEv;
              }
              parContract = contract;
              parEv = -contractEv;
            }
          }
        } else {
          if (
            isBidHigher(contract, bestContract) ||
            (['N', 'S'].includes(getContractDeclarer(bestContract)) &&
              isBidHigher(contract, highestRobotBid))
          ) {
            if (
              !isContractDoubled(ourContract) &&
              isContractDoubled(contract) &&
              getContractStrain(ourContract) === getContractStrain(contract) &&
              getContractLevel(ourContract) >= getContractLevel(contract)
            ) {
              // Our contract in the same strain and at least the same level was not doubled - robots cannot then double best contract (since they did not double us at the table)
              const undoubledContract = getContractUndoubled(contract);
              const undoubledContractEv = scores[undoubledContract];
              if (undoubledContractEv >= bestContractEv) {
                bestContractEv = undoubledContractEv;
                bestContract = undoubledContract;
              }
            } else {
              if (contractEv >= bestContractEv) {
                bestContractEv = contractEv;
                bestContract = contract;
              }
            }
          }
          // NS want highest parEv
          if (contractEv >= parEv) {
            if (['E', 'W'].includes(getContractDeclarer(parContract))) {
              bestEW = parContract;
              bestEWEv = parEv;
            }
            parContract = contract;
            parEv = contractEv;
          }
        }
      });
    });
  });

  return {
    parContract,
    parEv,
    bestNS,
    bestNSEv,
    bestEW,
    bestEWEv,
    bestContract,
    bestContractEv,
    robotHighestContract,
    robotHighestContractEv: -robotHighestContractEv,
    robotHighestContractDoubled,
    robotHighestContractDoubledEv: -robotHighestContractDoubledEv,
  };
}

function isBestContractSameAsOursWithOtherDeclarer({
  ourContract,
  ourEv,
  bestContract,
  bestContractEv,
}) {
  if (ourEv !== bestContractEv) {
    return false;
  }

  if (ourContract === bestContract) {
    return false;
  }

  return (
    getContractWithoutDeclarer(ourContract) ===
    getContractWithoutDeclarer(bestContract)
  );
}

export function getTopScores(scores, count, minScore = 0) {
  return Object.keys(scores)
    .filter(
      (s) =>
        s.length === 3 &&
        ['N', 'S'].includes(s.slice(-1)) &&
        scores[s] > minScore,
    )
    .sort((a, b) => scores[b] - scores[a])
    .slice(0, count * 2)
    .filter((s, i, arr) => {
      if (['1C', '1D', '1H', '1S'].includes(s.slice(0, 2))) {
        return false;
      }

      const sameContractWithDifferentDeclarerEarlierInArr = arr.find(
        (n, i2) => n.slice(0, 2) === s.slice(0, 2) && i > i2,
      );
      if (!sameContractWithDifferentDeclarerEarlierInArr) {
        const sameDenominationWithSameDeclarerLaterInArr = arr.find(
          (n, i2) => n.slice(1) === s.slice(1) && i < i2,
        );
        if (!sameDenominationWithSameDeclarerLaterInArr) {
          return true;
        }

        return (
          scores[s] - scores[sameDenominationWithSameDeclarerLaterInArr] > 20
        );
      }

      return (
        scores[sameContractWithDifferentDeclarerEarlierInArr] - scores[s] > 150
      );
    })
    .slice(0, count);
}

function isBidAlerted(bid, bidding) {
  const robotRx = new RegExp(
    `${bid.replace('X', '').slice(0, -1)}\\[@.*?\\]`,
    'g',
  );
  return robotRx.test(bidding);
}

export function getHighestRobotBidIfAllowedToFinish({
  compete,
  bidding,
  deal,
  highestRobotBid,
}) {
  if (!compete || highestRobotBid === 'P') {
    return 'P';
  }

  const robotPars = getRobotParStrains({ scores: deal.score });
  const hasPotentialForParBeatBest =  checkHasPotentialForParBeatBest(deal.score, deal.hand, deal.vulnerability, deal.ai);

  let finalBidding = rollBackBiddingToBid(bidding, highestRobotBid.slice(0, 2)) + '-P';
  let robotAction = getRobotAction({
    compete,
    bidding: finalBidding,
    sessionDeal: {
      ...deal,
      robotPars,
      hasPotentialForParBeatBest,
    },
  });

  while (robotAction !== '-P') {
    finalBidding += `${robotAction}-P`;
    robotAction = getRobotAction({
      compete,
      bidding: finalBidding,
      sessionDeal: {
        ...deal,
        robotPars,
        hasPotentialForParBeatBest,
      },
    });
  }

  finalBidding += '-P-P';

  return getHighestRobotBid(finalBidding);
}

export function getCompare(highestRobotBid, highestRobotBidIfAllowedToFinish, deal) {
  if (highestRobotBid === 'P') {
    return 'P';
  }
  const highestRobotBidUndoubled = getContractUndoubled(highestRobotBid);
  const highestRobotBidDoubled = getContractDoubled(highestRobotBidUndoubled);

  const highestRobotBidIfAllowedToFinishedUndoubled = getContractUndoubled(highestRobotBidIfAllowedToFinish);
  const highestRobotBidIfAllowedToFinishedDoubled = getContractDoubled(highestRobotBidIfAllowedToFinishedUndoubled);

  const noFinish = deal.score[highestRobotBidUndoubled] < deal.score[highestRobotBidDoubled] ?
    highestRobotBidUndoubled :
    highestRobotBidDoubled;

  const finish = deal.score[highestRobotBidIfAllowedToFinishedUndoubled] < deal.score[highestRobotBidIfAllowedToFinishedDoubled] ?
    highestRobotBidIfAllowedToFinishedUndoubled : highestRobotBidIfAllowedToFinishedDoubled;

  return deal.score[noFinish] > deal.score[finish] ? noFinish : finish;
}

export default function evaluateV3({
  deal,
  finalBid,
  declarer,
  highestRobotBid,
  doubled = '',
  bidding,
  compete,
}) {
  const ourContract = contractFromOldStyle({
    finalBid,
    declarer,
    doubled,
  });

  const highestRobotBidIfAllowedToFinish = getHighestRobotBidIfAllowedToFinish({
    compete,
    bidding,
    highestRobotBid,
    deal,
  });

  const toCompare = getCompare(highestRobotBid, highestRobotBidIfAllowedToFinish, deal);

  const par = getPar(
    deal.score,
    toCompare,
    ourContract,
    deal.hand,
    deal.vulnerability,
    compete,
    deal.ai,
  );
  const ourEv =
    ourContract === 'P' ?
      0 :
      deal.score[ourContract] * (['N', 'S'].includes(declarer) ? 1 : -1);

  // If robots bid higher than what is allowed in potential levels used in scoring, best ev can sometimes be worse than par, which should not happen (it should be possible to get 3 stars).
  const parForGrading =
    par.parEv > par.bestContractEv ? par.bestContractEv : par.parEv;

  const grd = grade(ourEv, parForGrading);

  const bestContractSameAsOursWithOtherDeclarer =
    isBestContractSameAsOursWithOtherDeclarer({
      ourContract,
      ourEv,
      bestContract: par.bestContract,
      bestContractEv: par.bestContractEv,
    });
  const minScore =
    ['N', 'S'].includes(declarer) && !doubled ? Math.min(0, ourEv) : 0;
  const extraContracts = getTopScores(deal.score, 5, minScore);

  const includeBestNS = Math.abs(parForGrading - par.bestNSEv) < 150;
  const includeBestEW = Math.abs(parForGrading - par.bestEWEv) < 150;

  return {
    evaluationVersion: 3,
    resultGrade: grd,
    ev: ourEv,
    bestContract: bestContractSameAsOursWithOtherDeclarer ?
      ourContract :
      par.bestContract,
    bestContractEv: bestContractSameAsOursWithOtherDeclarer ?
      ourEv :
      par.bestContractEv,
    parContract: par.parContract,
    parEv: par.parEv,
    otherContracts: [
      {
        contract: includeBestNS ? par.bestNS : 'P',
        ev: includeBestNS ? par.bestNSEv : 0,
        stars: grade(includeBestNS ? par.bestNSEv : 0, parForGrading),
      },
      {
        contract: includeBestEW ? par.bestEW : 'P',
        ev: includeBestEW ? par.bestEWEv : 0,
        stars: grade(includeBestEW ? par.bestEWEv : 0, parForGrading),
      },
      {
        contract: par.robotHighestContract ?? 'P',
        ev: par.robotHighestContractEv ?? 0,
        stars: grade(par.robotHighestContractEv ?? 0, parForGrading),
      },
      {
        contract: par.robotHighestContractDoubled ?? 'P',
        ev: par.robotHighestContractDoubledEv ?? 0,
        stars: grade(par.robotHighestContractDoubledEv ?? 0, parForGrading),
      },
      ...extraContracts.map((c) => ({
        contract: c,
        ev: deal.score[c],
        stars: grade(deal.score[c], parForGrading),
      })),
    ],
    parBeatBest: par.parEv > par.bestContractEv,
  };
}
