import { useState, useEffect } from 'react';
import '../../components/hand/hands.css';
import { SelectUserDropdown } from '../../components/dropdown/dropdown';
import { useNavigate, useParams } from 'react-router-dom';
import EventTimer from '../../components/areaCards/eventTimer';
import { timestampToString } from '../../util/dates';
import {
  checkIfAlreadyPlayingGroupSession,
  checkIfAlreadyPlayingTogetherInGroupSession,
  getGroupSession,
  GROUP_SESSION_COLLECTION,
  sendInviteToGroupSession,
} from '../../firebase/groups'
import Alert from '../../components/alert/alert';
import Price from '../../components/price/price';
import PriceDialog from '../../components/priceDialog/priceDialog';
import {
  canPairAffordToPlay,
  getCharges,
  getUserRemainingTickets,
  PAYMENT_STRATEGY_SPLIT,
} from '../../util/tickets';
import Invite from '../../components/invite/Invite';
import GroupSessionHeader from '../../components/groupSessionHeader/GroupSessionHeader';
import RemainingTicketsWarning from '../../components/remainingTicketsWarning/remainingTicketsWarning';
import { getEventParticipants, joinEvent } from '../../firebase/events'
import { getUserHighestSubscription } from '../../firebase/subscriptions';
import { getUser } from '../../firebase/userApi';
import { useActiveSubs, useAuth, useGoToPublicGroupPage, useRemainingTicketsNavbarItems } from '../../util/hooks';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useQuery } from '@tanstack/react-query'
import { getRobotUserId, isPartnerRobot, getInitialLiaSystem } from '../../util/robotPartner.js'
import TagsDisplay from '../../components/tagsDisplay/TagsDisplay.jsx'
import { LiaSystemDropdown } from '../../components/dropdown/liaSystemDropdown.jsx'
import { removeEventBadge } from 'cuebids-firebase/groups';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

function getRobotsText(compete, t) {
  return {
    0: t('no_robots'),
    1: t('basic_robots'),
    2: t('advanced_robots'),
  }[compete];
}

export default function JoinGroupSession() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.join_group_session' });
  const { id, groupId } = useParams();
  const groups = useAppStateStore((state) => state.groups);
  const user = useAppStateStore((state) => state.user);
  const eventInvites = useAppStateStore((state) => state.eventInvites);
  const notify = useNotifications();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useRemainingTicketsNavbarItems();
  useGoToPublicGroupPage(groupId);

  const [, setEventTimerState] = useState(); // Only used to trigger re-rendering
  const [partnerId, setPartnerId] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [liaSystem, setLiaSystem] = useState(getInitialLiaSystem(user?.defaultLiaSystem));

  useEffect(() => {
    if(user?.eventBadges?.includes(id)) {
      void removeEventBadge(id);
    }
  }, [id, user?.eventBadges]);

  const { data: groupSession } = useQuery({
    queryKey: ['groupSessions', id],
    queryFn: async () => await getGroupSession(id),
  });

  const { data: participants } = useQuery({
    queryKey: ['eventParticipants', id],
    queryFn: async () => {
      const res = await getEventParticipants(GROUP_SESSION_COLLECTION, id);
      return (res || []).map((p) => p.id);
    },
  });

  const { data: partnerData } = useQuery({
    queryKey: ['partnerData', partnerId],
    queryFn: async () => {
      if (partnerId) {
        const partnerSub = await getUserHighestSubscription(partnerId);
        const partner = await getUser({ userId: partnerId });
        return { partner, partnerSub };
      } else {
        return {};
      }
    },
  });

  const partner = partnerId === 'coachRobot' ? { robot: true, id: 'coachRobot' } :  partnerData?.partner;
  const partnerSub = partnerData?.partnerSub;

  const group = (groups || []).find(function (group) {
    return group.id === groupId;
  });

  const [alreadyPlay, setAlreadyPlay] = useState(false);
  const [partnerAlreadyPlay, setPartnerAlreadyPlay] = useState(false);
  const [alreadyPlayTogether, setAlreadyPlayTogether] = useState(false);

  const handleSelectPartner = (p) => {
    setPartnerId(p);
    setPartnerAlreadyPlay(false);
    setAlreadyPlayTogether(false);
  }

  const highestSub = useActiveSubs()[0];

  const handleClose = () => {
    setConfirmationModalOpen(false);
  };

  const handleChangeLiaSystem = (val) => {
    setLiaSystem(val);
  };

  const groupHasCoaching = (group?.premiumFeatures || []).includes('coaching');
  const isCoach = groupHasCoaching && group?.admins.includes(currentUser.uid);
  const partnerIsCoach = groupHasCoaching && partnerId && group?.admins.includes(partnerId);
  const multipleJoins = (group?.premiumFeatures || []).includes('multipleJoins');

  const coachRobot = groupSession?.coach;
  const partnerIsRobot = isPartnerRobot(partnerId);

  useEffect(() => {
    async function updateStatus() {
      if (id && currentUser?.uid) {
        const already = await checkIfAlreadyPlayingGroupSession({
          id,
          userId: currentUser.uid,
        });
        setAlreadyPlay(already);

        if (partnerId && !partnerIsRobot) {
          const alreadyPartner = await checkIfAlreadyPlayingGroupSession({
            id,
            userId: partnerId,
          });
          setPartnerAlreadyPlay(alreadyPartner);
        }

        if ((isCoach && (partnerIsCoach || partnerIsRobot)) || multipleJoins) {
          const alreadyTogether = await checkIfAlreadyPlayingTogetherInGroupSession({
            id,
            userId: currentUser.uid,
            partnerUserId: partnerId,
          })
          setAlreadyPlayTogether(alreadyTogether);
        }
      }
    }

    void updateStatus();
  }, [id, partnerId, currentUser.uid, isCoach, partnerIsCoach, partnerIsRobot, multipleJoins]);

  if (!user || !groupSession || !group) {
    return <></>;
  }

  const members = group.members;

  const membersAndRobots = members.concat(groupSession?.disableRobotPartner ? [] : [getRobotUserId()]);

  const hasStarted = groupSession.startDate < Date.now();
  const permanent = !groupSession.endDate;
  const hasFinished = !permanent && groupSession.endDate < Date.now();

  const sentInvites = eventInvites.filter(
    (i) => i.eventId === groupSession.id && i.inviter === currentUser.uid
  );
  const receivedInvites = eventInvites.filter(
    (i) => i.eventId === groupSession.id && i.invitee === currentUser.uid
  );
  const hasInvites = receivedInvites.length > 0 || sentInvites.length > 0;

  // Note: not check with partnerIsRobot here, since playing with coach should not cost extra
  // Note: Free events are free with Lia too
  const price = groupSession.price ? groupSession.price + (partnerId === getRobotUserId() ? groupSession.numberOfDeals : 0) : 0;

  const myRemainingTickets = getUserRemainingTickets(
    user.ticketsSpent ?? 0,
    highestSub,
    user.extraTickets ?? 0
  );

  const partnerRemainingTickets = partner ?
    (partner.robot ? Infinity : getUserRemainingTickets(
      partner.ticketsSpent ?? 0,
      partnerSub,
      partner.extraTickets ?? 0
    )
    )
    : 0;

  const paymentStrategy = partnerIsRobot ? null : PAYMENT_STRATEGY_SPLIT; // if split then partner can pay for me which is not what we want for robot partner

  const { myCharge, partnerCharge } = getCharges({
    myRemainingTickets,
    partnerRemainingTickets,
    price,
    paymentStrategy,
  });

  const joinGroupSession = () => {
    if (myCharge !== 0 || partnerCharge !== 0) {
      setConfirmationModalOpen(true);
    } else {
      void joinGroupSessionConfirmed();
    }
  };

  const joinGroupSessionsWithRobot = async (coach = false) => {
    setLoading(true);
    try {
      const sessionId = await joinEvent({
        eventCollection: GROUP_SESSION_COLLECTION,
        groupId: groupSession.groupId,
        eventId: groupSession.id,
        partnerUserId: coach ? getRobotUserId() : partnerId,
        defaultPrice: groupSession.price,
        additionalPrice: (groupSession.price === 0 || coach) ? 0 : groupSession.numberOfDeals, // Note: free events or play with coach no additional price. Else Lia costs one ticket per board
        paymentStrategy,
        fromInvite: true, // arguable
        allowedToPlayMultiple: multipleJoins || isCoach,
        partnerAllowedToPlayMultiple: true,
        coachRobot: coach ? coachRobot : null,
        liaSystem,
      });

      if (sessionId) {
        notify(
          {
            type: 'success',
            text: t('robot_success'),
          },
        );
        navigate(`/session/${sessionId}`, { replace: true });
      }
    } catch (e) {
      notify(
        {
          type: 'failure',
          text: t('robot_error', {error: e.message}),
        },
      );
    } finally {
      setLoading(false);
    }
    setConfirmationModalOpen(false);
  };

  const joinGroupSessionConfirmed = async () => {
    if (partnerIsRobot) {
      return joinGroupSessionsWithRobot(partnerId === 'coachRobot');
    }

    setLoading(true);
    try {
      const res = await sendInviteToGroupSession({
        groupId,
        name: groupSession.name,
        id,
        partnerUserId: partnerId,
        price,
        expiration: groupSession.endDate,
      });
      if (res?.sessionId) {
        notify(
          {
            type: 'success',
            text: t('already_invited_success'),
          },
        );
        navigate(`/session/${res.sessionId}`, { replace: true });
      }

      if (res?.alreadyInvited) {
        notify(
          {
            type: 'failure',
            text: t('already_invited_error'),
          },
        );
        return;
      }

      if (res?.invited) {
        notify(
          {
            type: 'success',
            text: t('invite_success'),
          },
        );
        navigate(`/groups/${groupId}/eventLeaderboard/${id}`);
        return;
      }
    } catch (e) {
      notify(
        {
          type: 'failure',
          text: t('invite_error', {error: e.message}),
        },
      );
    } finally {
      setLoading(false);
    }
    setConfirmationModalOpen(false);
  };

  return (
    <div className="fade-in">
      <div className="page pb-24 px-2">
        <GroupSessionHeader
          name={groupSession.name}
          description={groupSession.description}
          groupName={group.name}
          links
          groupId={groupId}
          groupFlag={group.groupFlag}
        />
        <div className="w-full max-w-[420px] flex justify-between px-1">
          <div className="text-center">
            {!hasStarted ? (
              <div>{t('not_started')}</div>
            ) : hasFinished ? (
              <div>{t('finished')}</div>
            ) : permanent ? (<div>{t('permanent')}</div>)
              : (
                <div>{t('runs_until')}</div>
              )}
            {
              !permanent && <EventTimer
                event={groupSession}
                startDate={timestampToString(groupSession.startDate)}
                endDate={timestampToString(groupSession.endDate)}
                callback={setEventTimerState}
                label={t('event_timer_label')}
              />
            }
          </div>
          <div className="flex flex-col">
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">
                  {getRobotsText(groupSession.compete || 0, t)}
                </span>
                <input
                  type="checkbox"
                  className="checkbox-accent checkbox ml-2"
                  checked={true}
                  disabled
                />
              </label>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">
                  {t('boards_count_label', {count: groupSession.numberOfDeals})}
                </span>
                <input
                  type="checkbox"
                  className="checkbox-accent checkbox ml-2"
                  checked={true}
                  disabled
                />
              </label>
            </div>
          </div>
        </div>
        <TagsDisplay
          tags={groupSession.tags}
          tagNames={groupSession.tagNames}
        />
        {hasFinished ? (
          <Alert severity="warning" text={t('already_finished')} />
        ) : (
          <>
            {hasInvites && (
              <>
                {receivedInvites.length > 0 && !hasFinished && (
                  <>
                    <div className="mt-4">{t('received_invites')}</div>
                    {receivedInvites.map(function (invite) {
                      return (
                        <Invite
                          key={invite.id}
                          invite={invite}
                          currentUserId={currentUser.uid}
                        />
                      );
                    })}
                  </>
                )}
                {sentInvites.length > 0 && !hasFinished && (
                  <>
                    <div className="mt-4">{t('sent_invites')}</div>
                    {sentInvites.map(function (invite) {
                      return (
                        <Invite
                          key={invite.id}
                          invite={invite}
                          currentUserId={currentUser.uid}
                        />
                      );
                    })}
                  </>
                )}
              </>
            )}
            {(!hasInvites || isCoach || multipleJoins) && (
              <>
                <div className="mb-4 flex w-full justify-center">
                  <SelectUserDropdown
                    value={partnerId}
                    onChange={handleSelectPartner}
                    label={t('partner_label')}
                    coachRobot={coachRobot}
                    users={membersAndRobots}
                    disableUserIds={(participants || []).filter(p => (!group.admins.includes(p) || !groupHasCoaching) && p !== getRobotUserId() && !multipleJoins)}
                    removeUserIds={(isCoach || multipleJoins) ? [currentUser.uid].concat(receivedInvites.map(i => i.inviter)).concat(sentInvites.map(i => i.invitee)) : [currentUser.uid]}
                  />
                </div>
                {partnerId === getRobotUserId() && (
                  <LiaSystemDropdown liaSystem={liaSystem} handleChangeLiaSystem={handleChangeLiaSystem} />
                )}
              </>
            )}
            <div className="flex flex-col items-center gap-2">
              {!loading && (alreadyPlay && !isCoach && !multipleJoins) && (
                <Alert
                  severity="error"
                  text={t('already_participating_error')}
                />
              )}
              {!loading && (partnerAlreadyPlay && !partnerIsCoach && !partnerIsRobot && !multipleJoins) && (
                <Alert
                  severity="error"
                  text={t('partner_participating_error')}
                />
              )}
              {!loading && alreadyPlayTogether && (
                <Alert
                  severity="error"
                  text={t('together_participating_error')}
                />
              )}
              {!alreadyPlay && !partnerAlreadyPlay && !hasInvites && !partnerIsRobot && (
                <Alert text={t('invite_info')} />
              )}
            </div>
            <Price price={price} />
            {!hasInvites && partner && (
              <RemainingTicketsWarning
                myRemainingTickets={myRemainingTickets}
                partnerRemainingTickets={partnerRemainingTickets}
                price={price}
                loading={loading}
                setLoading={setLoading}
                paymentStrategy={paymentStrategy}
              />
            )}
          </>
        )}
        <button
          className="btn-primary btn mt-4"
          disabled={
            !hasStarted ||
            hasFinished ||
            (hasInvites && !isCoach && !multipleJoins) ||
            !partnerId ||
            !partner ||
            loading ||
            (alreadyPlay && !isCoach && !multipleJoins) ||
            (partnerAlreadyPlay && !partnerIsCoach && !multipleJoins) ||
            alreadyPlayTogether ||
            !canPairAffordToPlay({
              myRemainingTickets,
              partnerRemainingTickets,
              price,
              paymentStrategy,
            })
          }
          onClick={joinGroupSession}
        >
          {t('join_event_submit')}
        </button>
      </div>
      <PriceDialog
        paymentStrategy={paymentStrategy}
        open={confirmationModalOpen}
        onClose={handleClose}
        title={t('price_dialog_title')}
        myRemainingTickets={myRemainingTickets}
        partnerRemainingTickets={partnerRemainingTickets}
        myCharge={myCharge}
        partnerCharge={partnerCharge}
        actions={[
          {
            component: (
              <button
                disabled={loading}
                type="button"
                key="join-event"
                className="btn-primary btn"
                onClick={joinGroupSessionConfirmed}
              >
                {t('price_dialog_submit')}
              </button>
            ),
          },
        ]}
      />
    </div>
  );
}
