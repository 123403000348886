import { getDocs, limit, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { currentUserId, getDatabase, getRepos } from '../base';
import { onValue, ref } from 'firebase/database';
export function getLeaderBoardEntry(uid, leaderBoard) {
    return leaderBoard.find((l) => (l.users?.includes(uid)) ||
        (l.userData?.some((u) => u.uid === uid)));
}
export function getRankFromLeaderboard(uid, leaderBoard) {
    const stats = getLeaderBoardEntry(uid, leaderBoard);
    if (stats === undefined) {
        return 0;
    }
    return (leaderBoard.filter((f) => f.numberOfStars > stats.numberOfStars).length + 1);
}
export async function getAllWeeklies() {
    const repo = getRepos().weeklySessions;
    const q = query(repo.collection, where(repo.fieldName('scored'), '==', true), orderBy(repo.fieldName('week'), 'desc'), limit(20));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty || querySnapshot.docs.length === 0) {
        return [];
    }
    return querySnapshot.docs.map((d) => {
        const data = d.data();
        return {
            week: data.week,
            leaderBoard: data.leaderBoard,
            id: d.id,
            startDate: data.startDate,
            endDate: data.endDate,
            scored: data.scored
        };
    });
}
export async function getAllDailies() {
    const repo = getRepos().dailySessions;
    const q = query(repo.collection, where(repo.fieldName('scored'), '==', true), orderBy(repo.fieldName('day'), 'desc'), limit(30));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty || querySnapshot.docs.length === 0) {
        return [];
    }
    return querySnapshot.docs.map((d) => {
        const data = d.data();
        return {
            day: data.day,
            leaderBoard: data.leaderBoard,
            id: d.id,
            endDate: data.endDate,
            startDate: data.startDate,
            scored: data.scored
        };
    });
}
export async function getAllDailiesForUser(uid) {
    const dailies = await getAllDailies();
    return dailies
        .map((d) => {
        const leaderBoardEntry = getLeaderBoardEntry(uid, d.leaderBoard);
        if (leaderBoardEntry !== undefined) {
            const newPartner = leaderBoardEntry.users?.filter((u) => u !== uid)[0];
            const oldPartner = leaderBoardEntry.userData?.filter((u) => u.uid !== uid).map((u) => u.uid)[0];
            const partner = newPartner === undefined ? oldPartner : newPartner;
            return {
                stars: leaderBoardEntry.numberOfStars,
                day: d.day,
                id: d.id ?? '',
                partner,
                rank: getRankFromLeaderboard(uid, d.leaderBoard),
                pairs: d.leaderBoard.length,
                end: d.endDate
            };
        }
        return undefined;
    })
        .filter(Boolean)
        .sort((a, b) => a.day - b.day);
}
export async function getAllWeekliesForUser(uid) {
    const weeklies = await getAllWeeklies();
    return weeklies
        .map((w) => {
        const leaderBoardEntry = getLeaderBoardEntry(uid, w.leaderBoard);
        if (leaderBoardEntry !== undefined) {
            const newPartner = leaderBoardEntry.users?.filter((u) => u !== uid)[0];
            const oldPartner = leaderBoardEntry.userData?.filter((u) => u.uid !== uid).map((u) => u.uid)[0];
            const partner = newPartner === undefined ? oldPartner : newPartner;
            return {
                stars: leaderBoardEntry.numberOfStars,
                week: w.week,
                id: w.id,
                partner,
                rank: getRankFromLeaderboard(uid, w.leaderBoard),
                pairs: w.leaderBoard.length,
                end: w.endDate
            };
        }
        return undefined;
    })
        .filter(Boolean)
        .sort((a, b) => a.week - b.week);
}
export async function getAllPairPerformanceReportsForUser(uid) {
    const q = query(getRepos().reports.collection, where(getRepos().reports.fieldName('users'), 'array-contains', uid), where(getRepos().reports.fieldName('type'), '==', 'pair-performance'));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty || querySnapshot.docs.length === 0) {
        return [];
    }
    return querySnapshot.docs.map((d) => {
        const data = d.data();
        return {
            startDate: data.startDate,
            averageGrade: data.averageGrade,
            dealsFinished: data.dealsFinished,
            users: data.users,
            endDate: data.endDate,
            type: data.type
        };
    });
}
export async function markPerformanceReportsAsRead() {
    const docRef = getRepos().users.docRef(currentUserId());
    await updateDoc(docRef, {
        hasUnreadPerformanceReports: false
    });
}
export async function getAllPairPerformanceReportsForGroups(users, minDate) {
    const thirtyDaysAgoString = minDate.toISOString().slice(0, 19).replace('T', ' ');
    const copy = [...users];
    const batches = [];
    while (copy.length > 0) {
        const batch = copy.splice(0, 30);
        const q = query(getRepos().reports.collection, where(getRepos().reports.fieldName('users'), 'array-contains-any', [...batch]), where(getRepos().reports.fieldName('type'), '==', 'pair-performance'), where(getRepos().reports.fieldName('startDate'), '>', thirtyDaysAgoString));
        batches.push(getDocs(q).then((querySnapshot) => {
            if (querySnapshot.empty || querySnapshot.docs.length === 0) {
                return [];
            }
            return querySnapshot.docs.map((d) => {
                const data = d.data();
                return {
                    startDate: data.startDate,
                    averageGrade: data.averageGrade,
                    dealsFinished: data.dealsFinished,
                    users: data.users,
                    endDate: data.endDate,
                    type: data.type
                };
            });
        }));
    }
    return await Promise.all(batches).then((content) => content.flat());
}
export function getsDailyStatsObservable({ callback }) {
    const connectedRef = ref(getDatabase(), 'stats/dailyStats');
    return onValue(connectedRef, (snap) => {
        const val = snap.val();
        if (val !== undefined) {
            callback(val);
        }
    });
}
export async function getTopTenDaily(robotUserId) {
    const q = query(getRepos().users.collection, orderBy('dailyWins', 'desc'), orderBy('numberOfStars', 'desc'), limit(11));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty || querySnapshot.docs.length === 0) {
        return [];
    }
    return querySnapshot.docs.map((d) => {
        const data = d.data();
        return {
            displayName: data.displayName,
            dailyWins: data.dailyWins,
            numberOfStars: data.numberOfStars,
            id: d.id
        };
    }).filter(d => d.id !== robotUserId)
        .slice(0, 10);
}
export async function getTopTenWeekly(robotUserId) {
    const q = query(getRepos().users.collection, orderBy('weeklyWins', 'desc'), orderBy('numberOfStars', 'desc'), limit(11));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty || querySnapshot.docs.length === 0) {
        return [];
    }
    return querySnapshot.docs.map((d) => {
        const data = d.data();
        return {
            displayName: data.displayName,
            weeklyWins: data.weeklyWins,
            numberOfStars: data.numberOfStars,
            id: d.id
        };
    }).filter(d => d.id !== robotUserId)
        .slice(0, 10);
}
export async function getTopTenStars(robotUserId) {
    const q = query(getRepos().users.collection, orderBy('numberOfStars', 'desc'), limit(11));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty || querySnapshot.docs.length === 0) {
        return [];
    }
    return querySnapshot.docs.map((d) => {
        const data = d.data();
        return {
            displayName: data.displayName,
            numberOfStars: data.numberOfStars,
            id: d.id
        };
    }).filter(d => d.id !== robotUserId)
        .slice(0, 10);
}
export async function getTopRanked(count) {
    const q = query(getRepos().users.collection, where('rankedLadderActive', '==', true), orderBy('rankTotalStars', 'desc'), orderBy('rating', 'desc'), limit(count));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty || querySnapshot.docs.length === 0) {
        return [];
    }
    return querySnapshot.docs.map((d) => {
        const data = d.data();
        return {
            displayName: data.displayName,
            rankTotalStars: data.rankTotalStars,
            rankLevel: data.rankLevel,
            rankLevelStars: data.rankLevelStars,
            rating: data.rating,
            id: d.id
        };
    });
}
