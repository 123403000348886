import { Trans, useTranslation } from 'react-i18next'

export default function ContactSales() {
  const { t } = useTranslation('translation', { keyPrefix: 'contact_sales' });

  return (
    <div className="page p-4 pb-24">
      <article className="prose select-text">
        <h1>{t('title')}</h1>
        <p>{t('p1')}</p>
        <p>
          <Trans
            i18nKey="p2_pre_link"
            t={t}
          />
          <a href="mailto:sales@cuebids.com" className="link">
            <strong>sales@cuebids.com</strong>
          </a>{' '}
          {t('p2_post_link')}
        </p>
        <p>{t('p3')}</p>
        <ul>
          <li className="whitespace-normal">
            {t('ul_li1')}
          </li>
          <li>{t('ul_li2')}</li>
          <li className="whitespace-normal">
            {t('ul_li3')}
            <ul>
              <li className="whitespace-normal">{t('ul_li3_ul_li1')}</li>
              <li className="whitespace-normal">{t('ul_li3_ul_li2')}</li>
            </ul>
          </li>
          <li className="whitespace-normal">
            {t('ul_li4')}
          </li>
        </ul>
      </article>
    </div>
  );
}
