import axios from 'axios';
import { docToObject, queryToObjects } from 'firebase-util';
import { deleteDoc, query, where } from 'firebase/firestore';
import { getRepos } from '../base';
import { getToken } from '../users';
export function flipVulnerability(vuln) {
    switch (vuln) {
        case 'NONE':
            return 'NONE';
        case 'NS':
            return 'EW';
        case 'EW':
            return 'NS';
        case 'ALL':
            return 'ALL';
        default:
            throw new Error('Invalid vulnerability');
    }
}
export function shiftDealer(dealer) {
    switch (dealer) {
        case 'N':
            return 'E';
        case 'E':
            return 'S';
        case 'S':
            return 'W';
        case 'W':
            return 'N';
        default:
            throw new Error('Invalid dealer');
    }
}
export function rotateHand(pbnHand) {
    const { hand, dealer, vulnerability, score } = pbnHand;
    const regex = /:([^"]*)/;
    const allHands = hand.match(regex)?.[1];
    const hands = allHands.split(' ');
    const last = hands.pop();
    hands.unshift(last);
    const newScore = {};
    if (score != null) {
        for (const [key, value] of Object.entries(score)) {
            const dealer = shiftDealer(key[key.length - 1]);
            const newKey = key.substring(0, key.length - 1) + dealer;
            newScore[newKey] = value;
        }
        return ({
            hand: hand.split(':')[0] + ':' + hands.join(' ') + '"]',
            dealer: shiftDealer(dealer),
            vulnerability: flipVulnerability(vulnerability),
            score: newScore
        });
    }
    return ({
        hand: hand.split(':')[0] + ':' + hands.join(' ') + '"]',
        dealer: shiftDealer(dealer),
        vulnerability: flipVulnerability(vulnerability)
    });
}
export async function scoreDeals(url, deals, scoring) {
    const token = await getToken();
    const body = {
        deals: deals.map((deal) => {
            const hand = deal.hand.match(/"([^"]+)"/);
            if (hand == null) {
                throw new Error('Invalid hand');
            }
            return {
                deal: hand[1],
                vulnerability: deal.vulnerability
            };
        }),
        scoring
    };
    const response = await axios.post(url + 'deal/score', body, {
        headers: {
            Authorization: `Bearer ${token}`,
            'x-cuebids': 'vpH1ddRYgsMI4j25QaTZ0Q3EWjV2'
        }
    });
    return response.data;
}
export async function createEventDraft(groupId, eventName, hands) {
    const doc = await getRepos().groupSessionDraft.add?.({
        groupId,
        eventName,
        deals: hands
    });
    return doc?.id;
}
export async function getEventDraft(id) {
    const doc = await getRepos().groupSessionDraft.doc(id);
    return docToObject(doc);
}
export async function updateEventDraft(id, draft) {
    const ref = getRepos().groupSessionDraft.docRef(id);
    await getRepos().groupSessionDraft.update?.(ref, draft);
}
export async function deleteDraft(id) {
    const ref = getRepos().groupSessionDraft.docRef(id);
    await deleteDoc(ref);
}
export async function getEventDrafts(groupId) {
    const col = getRepos().groupSessionDraft.collection;
    const q = query(col, where('groupId', '==', groupId));
    return queryToObjects(q);
}
