import { useEffect, useMemo, useState } from 'react'
import data from './convention_picker_data.json';
import { replaceSuitShorthandWithSymbols } from 'cuebids-bidding-util';
import { Tab, TabPanel, Tabs } from '../tabs/tabs.jsx';
import Collapse from '../collapse/Collapse.jsx';
import { AlertInline } from '../alert/alert.jsx';
import { useAppStateStore } from '../../appStateStore.jsx';
import { setConventions } from 'cuebids-firebase/users';
import { SaveIcon } from '../icons/saveIcon.jsx';
import { UserDependentPage } from '../routing/userDependentPage.jsx'
import useNotifications from '../notifications/useNotifications.jsx'
import LanguageDisclaimer from '../languageDisclaimer/LanguageDisclaimer.jsx'

function arraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((element, index) => element === arr2[index]);
}

const findOptionById = (questionId, optionId) => {
  const stack = [];

  data.forEach(section => {
    section.questions.forEach(question => stack.push(question));
  });

  while (stack.length > 0) {
    const question = stack.pop();

    for (let option of question.options) {
      if (question.id === questionId && option.id === optionId) {
        return option;
      }

      if (option.questions) {
        option.questions.forEach(subQuestion => stack.push(subQuestion));
      }
    }
  }

  return null;
};

function ConventionPickerInternal() {
  const user = useAppStateStore((state) => state.user);
  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [activeTab, setActiveTab] = useState('0');
  const oldName =  user?.myLiaSystem || '';
  const [name, setName] = useState(oldName);
  const notify = useNotifications();

  const newData = useMemo(function () {
    let output = [];

    const setOutput = (questions) => {
      questions.forEach(question => {
        const userOptionIdChoice = selectedOptions[question.id] ?? question.options[0].id;
        const userOptionChoice = question.options.find(o => o.id === userOptionIdChoice) ?? question.options[0];

        output.push(userOptionChoice.lia_label);
        if (userOptionChoice.questions) {
          setOutput(userOptionChoice.questions);
        }

      });
    };

    data.forEach(section => {
      section.questions.forEach(question => {
        setOutput([question]);
      });
    });

    return output.filter(r => r !== 'default');
  }, [selectedOptions]);

  const hasUnsavedChanges = useMemo(function () {
    if(name !== oldName) {
      return true;
    }
    if(!user?.conventions) {
      return true;
    }

    return !arraysAreEqual(newData, user?.conventions)
  }, [name, newData, oldName, user?.conventions]);

  useEffect(() => {
    const setInitialValues = () => {
      const userData = user?.conventions || [];
      const initialSelections = {};

      const setDefaults = (questions) => {
        questions.forEach(question => {
          const userChoice = userData.find(value => question.options.some(opt => opt.lia_label === value));
          const defaultOption = userChoice ?
            question.options.find(opt => opt.lia_label === userChoice) :
            question.options[0];

          if (defaultOption) {
            initialSelections[question.id] = defaultOption.id;
            if (defaultOption.questions) {
              setDefaults(defaultOption.questions);
            }
          }
        });
      };

      data.forEach(section => {
        section.questions.forEach(question => {
          setDefaults([question]);
        });
      });

      setSelectedOptions(initialSelections);
    };

    setInitialValues()
  }, [user?.conventions]);

  useEffect(() => {
    function _save () {
      if(newData && newData.length > 0) {
        setConventions(name || 'My Lia System', newData).then(function () {
          notify({ text: 'System saved', type: 'success' });
        });
      }
    }

    updateAppState({
      title: hasUnsavedChanges ? (
        <div className="text-error">Unsaved changes</div>
      ) : <div className="mt-2 text-primary"></div>,
      navbarItems: (
        <button
          key="navbar-items-profile-save"
          className={`btn-ghost btn ${hasUnsavedChanges ? 'text-primary' : ''}`}
          onClick={_save}
          disabled={!hasUnsavedChanges}
        >
          <SaveIcon className="fill-primary" />
        </button>
      ),
    });
    return () =>
      updateAppState({
        title: undefined,
        navbarItems: undefined,
      });
  }, [updateAppState, hasUnsavedChanges, newData, name, notify]);

  const handleOptionChange = (questionId, optionId) => {
    setSelectedOptions(prev => {
      let selected = {
        ...prev,
        [questionId]: optionId
      }

      const setDefaults = (questions) => {
        questions.forEach(q => {
            if (selected[q.id] == null) {
              selected[q.id] = q.options[0].id
              if ((q.options[0].questions ?? []).length > 0) {
                setDefaults(q.options[0].questions);
              }
            }
        });
      };

      const option = findOptionById(questionId, optionId);
      if ((option?.questions ?? []).length > 0) {
        setDefaults(option.questions);
      }

      return selected
    })
  }

  function handleSetName(e) {
    setName(e.target.value);
  }


  return (
    <>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">{'System name'}</span>
        </label>
        <input
          value={name}
          onChange={handleSetName}
          type="text"
          required
          placeholder="Give your system a name"
          className="input"
        />
      </div>
      <Tabs>
        {
          data.slice(0,6).map((section) => {
            return (
              <Tab
                key={section.id}
                label={section.id}
                value={section.id}
                active={activeTab === section.id}
                onChange={setActiveTab}
              />
            );
          })
        }
      </Tabs>
      {
        data.map((section) => {
          return <TabPanel key={section.id} value={activeTab} index={section.id} className={'w-full'}>
            <h3 className={'text-lg mb-4'}>{section.label}</h3>
            <div key={section.id} className={'flex w-full flex-col justify-start'}>
              {section.questions.filter((q) => !q.advanced).map((question) => {
                return (
                  <Collapse fullSizeAlways title={replaceSuitShorthandWithSymbols(
                    question.label
                  )} key={question.id}
                  >

                    <div className="flex flex-col gap-2 w-full items-start justify-start">
                      {question.options
                        .filter((o) => o.supported !== false && !o.advanced)
                        .map((option) => {
                          const checked =
                                    selectedOptions[question.id] === option.id;
                          return (
                            <div key={option.id} className="form-control flex justify-start items-start">
                              <label className="label cursor-pointer gap-2">
                                <input
                                  type="radio"
                                  name={question.id}
                                  className="radio !radio-secondary"
                                  checked={checked}
                                  onChange={() =>
                                    handleOptionChange(question.id, option.id)
                                  }
                                />
                                <span className="label-text">
                                  {replaceSuitShorthandWithSymbols(
                                    option.value
                                  )}
                                </span>
                              </label>
                              {checked && option.description && (
                                <span
                                  className={
                                    'rounded-xl p-2 text-sm bg-primary'
                                  }
                                >
                                  {replaceSuitShorthandWithSymbols(
                                    option.description
                                  )}
                                </span>
                              )}
                            </div>
                          );
                        })}
                    </div>
                    {question.options
                      .filter((o) => o.supported !== false && !o.advanced)
                      .map((option) => {
                        const notAdvancedOptions = selectedOptions[question.id] === option.id && option.questions && option.questions.filter((q) => !q.advanced);
                        return (
                          <div key={'sub' + option.id} className={'ml-4'}>
                            {selectedOptions[question.id] === option.id &&
                                        option.questions && (
                              <div
                                className="sub-questions mb-2 mt-4 text-sm flex flex-col items-start justify-start gap-4">
                                {notAdvancedOptions.length > -0 && <div className="info -mb-3">{replaceSuitShorthandWithSymbols(option.value)} options</div>}
                                {notAdvancedOptions.map((subQ) => (
                                  <div key={subQ.id}>
                                    <div>
                                      {replaceSuitShorthandWithSymbols(
                                        subQ.label
                                      )}
                                      {subQ.options.map((subOption) => {
                                        let checked =
                                                            selectedOptions[subQ.id] ===
                                                            subOption.id;
                                        return (
                                          <div
                                            key={subOption.id}
                                            className="form-control flex items-start justify-start "
                                          >
                                            <label className="label cursor-pointer gap-2">
                                              <input
                                                type="radio"
                                                name={subQ.id}
                                                className="radio !radio-secondary"
                                                checked={checked}
                                                onChange={() =>
                                                  handleOptionChange(
                                                    subQ.id,
                                                    subOption.id
                                                  )
                                                }
                                              />
                                              <span className="label-text text-xs">
                                                {replaceSuitShorthandWithSymbols(
                                                  subOption.value
                                                )}
                                              </span>
                                            </label>
                                            {checked && (
                                              <span
                                                className={
                                                  'rounded-xl p-2 text-sm bg-primary'
                                                }
                                              >
                                                {replaceSuitShorthandWithSymbols(
                                                  subOption.description
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </Collapse>
                );
              })}
            </div>
          </TabPanel>
        })
      }
    </>
  );
}

export default function ConventionPicker() {
  return (
    <div className={'page px-4 py-2 gap-2 min-h-screen !justify-start'}>
      <article className={'prose mb-4'}>
        <h1>Configure your own Lia system</h1>
      </article>
      <LanguageDisclaimer />
      <AlertInline severity={'info'} title={'How to answer the questions'}
                   text={'You can answer as many or as few questions as you like. If you don\'t know the answer, you can simply ignore it.'}/>
      <UserDependentPage>
        <ConventionPickerInternal/>
      </UserDependentPage>
    </div>
  )
}
