import { useEffect, useState } from 'react';
import {
  disableTicketsAndEventsForFriend,
  enableTicketsAndEventsForFriend,
  removeFriend,
  replyToFriendRequest,
  unblockFriend,
} from '../../firebase/friends';
import UserDisplay, {
} from '../../components/users/userDisplay';
import { Link } from 'react-router-dom';
import Alert from '../../components/alert/alert';
import Dialog from '../../components/dialog';
import {
  getCurrentOnboardingStep,
  onboardingSteps,
} from '../../util/onboarding';
import { TicketIcon } from '../../components/icons/ticketsIcon';
import { useAppStateStore } from '../../appStateStore.jsx';
import { FriendsIcon } from '../../components/icons/friendsIcon.jsx';
import { useTranslation } from 'react-i18next'

function FriendsInfo({ friends, t }) {
  const { blocked, mutual, following, pending } = friends.reduce(
    function (a, f) {
      a[f.status]++;
      return a;
    },
    {
      blocked: 0,
      mutual: 0,
      following: 0,
      pending: 0,
    }
  );

  if (mutual !== 0) {
    return null;
  }

  if (friends.length === 0) {
    return (
      <tr>
        <td>
          <Alert text={t('no_friends_added_info')} severity="info" />
        </td>
      </tr>
    );
  }

  if (friends.length === blocked) {
    // All friends blocked
    return (
      <tr>
        <td>
          <Alert
            text={t('all_friends_blocked_info')}
            severity="info"
          />
        </td>
      </tr>
    );
  }

  if (pending !== 0) {
    // I have friend requests to accept
    return (
      <tr>
        <td>
          <Alert
            text={t('pending_requests_info')}
            severity="info"
          />
        </td>
      </tr>
    );
  }

  // I'm waiting for request accepts from my friends (or have been blocked)
  return (
    <tr>
      <td>
        <Alert
          text={t('outstanding_requests_info')}
          severity="info"
        />
      </td>
    </tr>
  );
}

// For now only show friend requests at top
const friendStatusSortOrder = {
  pending: 0,
};

function FriendsTab({ friends, user, t }) {
  const [showBlocked, setShowBlocked] = useState(false);
  const [unconfirmedRemoveFriend, setUnconfirmedRemoveFriend] = useState(null);
  const [unconfirmedToggleTicketsFriend, setUnconfirmedToggleTicketsFriend] =
    useState(null);

  const currentOnboardingStep = getCurrentOnboardingStep({ user });

  const updateShowBlocked = (event) => {
    setShowBlocked(event.target.checked);
  };

  const filteredFriends = showBlocked
    ? friends || []
    : (friends || []).filter((u) => u.status !== 'blocked');

  filteredFriends.sort((a, b) => {
    const friendStatusSortOrderA = friendStatusSortOrder[a.status] ?? 999;
    const friendStatusSortOrderB = friendStatusSortOrder[b.status] ?? 999;

    if (friendStatusSortOrderA !== friendStatusSortOrderB) {
      return friendStatusSortOrderA - friendStatusSortOrderB;
    }

    return a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
  });

  const numberOfBlockedFriends = (friends || []).filter(
    (u) => u.status === 'blocked'
  ).length;

  const handleCloseConfirmRemoveFriendModal = () => {
    setUnconfirmedRemoveFriend(null);
  };

  const handleConfirmRemoveFriend = () => {
    if (!unconfirmedRemoveFriend) return;
    removeFriend(unconfirmedRemoveFriend);
    setUnconfirmedRemoveFriend(null);
  };

  const handleCloseConfirmToggleTicketsModal = () => {
    setUnconfirmedToggleTicketsFriend(null);
  };

  const handleConfirmToggleTickets = () => {
    if (!unconfirmedToggleTicketsFriend) return;
    if (unconfirmedToggleTicketsFriend.disableFriendFromTicketsAndEvents) {
      enableTicketsAndEventsForFriend(unconfirmedToggleTicketsFriend.id);
    } else {
      disableTicketsAndEventsForFriend(unconfirmedToggleTicketsFriend.id);
    }

    setUnconfirmedToggleTicketsFriend(null);
  };

  const addFriends =  <Link to={'/addFriend'}><button
    className="btn-accent btn"
    key="navbar-items-add-friend"
  >
    <div className="indicator">
      {currentOnboardingStep === onboardingSteps.addFriend && (
        <div className="pulse w-5 badge badge-error indicator-item -top-5 -right-5" />
      )}
      <div className='flex items-center'>
        {t('add_button')}
        <FriendsIcon width="24px" className="ml-2 fill-emerald-400" />
      </div>
    </div>

  </button></Link>

  return (
    <>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="my-2 flex flex-row items-center w-full justify-around">
          <div>
            <label className="label w-full cursor-pointer p-0">
              {numberOfBlockedFriends ? t('show_blocked_count', {count: numberOfBlockedFriends}) : t('show_blocked')}
              <input
                type="checkbox"
                className="toggle-primary toggle ml-2"
                checked={showBlocked}
                onChange={updateShowBlocked}
              />
            </label>
          </div>
          {
            addFriends
          }
        </div>
        <table className="mt-4 w-full border-collapse rounded-sm">
          <thead>
            <tr>
              <th>{t('friends_count', {count: filteredFriends.length})}</th>
            </tr>
          </thead>
          <tbody>
            <FriendsInfo friends={friends || []} t={t} />
            {filteredFriends.map((u, i) => {
              return (
                <tr key={u.id}>
                  <td
                    className={`p-2 ${
                      u.status === 'blocked' ? 'text-red-400' : ''
                    }`}
                  >
                    <Link to={'/player/' + u.id}>
                      <UserDisplay uid={u.id} />
                    </Link>
                  </td>
                  <td className="align-right">
                    {u.status === 'blocked' && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <button
                          style={{ marginLeft: '10px' }}
                          onClick={function () {
                            unblockFriend(u);
                          }}
                          className={'btn-accent btn-sm btn'}
                        >
                          {t('restore')}
                        </button>
                      </div>
                    )}
                    {u.status === 'following' && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <button
                          onClick={function () {
                            setUnconfirmedRemoveFriend(u);
                          }}
                          className={'btn-accent btn-sm btn'}
                        >
                          {t('unfollow')}
                        </button>
                      </div>
                    )}
                    {u.status === 'mutual' && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <button
                          onClick={function () {
                            setUnconfirmedToggleTicketsFriend(u);
                          }}
                          className={'btn-accent btn-sm btn mr-2'}
                        >
                          {u.disableFriendFromTicketsAndEvents ? (
                            <TicketIcon className="opacity-25" />
                          ) : (
                            <TicketIcon />
                          )}
                        </button>
                        <button
                          onClick={function () {
                            setUnconfirmedRemoveFriend(u);
                          }}
                          className={'btn-secondary btn-sm btn'}
                        >
                          {t('remove')}
                        </button>
                      </div>
                    )}
                    {u.status === 'pending' && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <button
                          onClick={function () {
                            replyToFriendRequest({ user: u, accepted: true });
                          }}
                          className={'btn-primary btn-sm btn mr-1'}
                        >
                          {t('accept')}
                        </button>
                        <button
                          onClick={function () {
                            replyToFriendRequest({ user: u, accepted: false });
                          }}
                          className={'btn-error btn-sm btn'}
                        >
                          {t('block')}
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Dialog
        open={!!unconfirmedRemoveFriend}
        onClose={handleCloseConfirmRemoveFriendModal}
        title={t('remove_friend_dialog_title')}
        actions={[
          {
            component: (
              <div
                key="yes"
                className="btn-error btn"
                role="button"
                onClick={handleConfirmRemoveFriend}
              >
                {t('remove_friend_dialog_confirm')}
              </div>
            ),
          },
        ]}
      >
        {t('remove_friend_dialog_info', {displayName: unconfirmedRemoveFriend?.displayName})}
      </Dialog>
      <Dialog
        open={!!unconfirmedToggleTicketsFriend}
        onClose={handleCloseConfirmToggleTicketsModal}
        title={
          unconfirmedToggleTicketsFriend?.disableFriendFromTicketsAndEvents
            ? t('enable_tickets_dialog_title')
            : t('disable_tickets_dialog_title')
        }
        actions={[
          {
            component: (
              <div
                key="confirm"
                className={`btn ${
                  unconfirmedToggleTicketsFriend?.disableFriendFromTicketsAndEvents
                    ? 'btn-primary'
                    : 'btn-error'
                }`}
                role="button"
                onClick={handleConfirmToggleTickets}
              >
                {t('toggle_tickets_dialog_confirm')}
              </div>
            ),
          },
        ]}
      >
        {unconfirmedToggleTicketsFriend?.disableFriendFromTicketsAndEvents
          ? t('enable_tickets_dialog_info')
          : t('disable_tickets_dialog_info')}
      </Dialog>
    </>
  );
}

export default function Friends() {
  const { t } = useTranslation('translation', { keyPrefix: 'friends_page' });
  const user = useAppStateStore((state) => state.user);
  const friends = useAppStateStore((state) => state.friends);
  const updateAppState = useAppStateStore((state) => state.updateAppState);

  const friendKey = user?.friendKey;

  useEffect(() => {
    if(friendKey) {
      updateAppState({
        title:  <div className='w-96 flex items-center justify-center'>
          <>{t('my_key')}<div className='badge badge-lg badge-accent select-text'>{friendKey}</div></>
        </div>
      })
    }

    return () => updateAppState({ title: undefined });

  }, [updateAppState, friendKey])

  if (!user) {
    return <></>;
  }

  return (
    <div className="fade-in">
      <div className="page pb-24">
        <FriendsTab friends={friends} user={user} t={t} />
      </div>
    </div>
  );
}
