import { useAuth } from '../../../util/hooks.jsx';
import { useAppStateStore } from '../../../appStateStore.jsx';
import { getRobotUserId, robotSystemMap } from '../../../util/robotPartner.js';
import { ConventionCardIcon } from '../../../components/icons/conventionCardIcon';

export function RobotSystemDisplay({session}) {
    const { currentUser } = useAuth();

    const mySys = useAppStateStore((s) => s.user.myLiaSystem)

    const imPlaying = session?.users.includes(currentUser.uid);

    const partnerIsRobot = session?.users.includes(getRobotUserId());
  
    const robotSystem = session?.liaSystem;

    if(robotSystem === 'UNKNOWN') {
        return null;
    }

    const robotSystemName = robotSystem === 'MY_SYSTEM' ? (imPlaying ? mySys : 'Custom') : robotSystemMap[robotSystem];

    if (!partnerIsRobot) {
        return null;
    }

    return <div className='info flex justify-center items-center gap-1'>
        <ConventionCardIcon fill="#e29627" width="12px" />
        {
            robotSystemName
        }
    </div>
}