export const bids = [
    '',
    '.',
    'P',
    'D',
    'R',
    '1C',
    '2C',
    '3C',
    '4C',
    '5C',
    '6C',
    '7C',
    '1D',
    '2D',
    '3D',
    '4D',
    '5D',
    '6D',
    '7D',
    '1H',
    '2H',
    '3H',
    '4H',
    '5H',
    '6H',
    '7H',
    '1S',
    '2S',
    '3S',
    '4S',
    '5S',
    '6S',
    '7S',
    '1N',
    '2N',
    '3N',
    '4N',
    '5N',
    '6N',
    '7N'
];
