export const ACH_100_STARS = '100_STARS';
export const ACH_100_STARS_REWARD = 10;

export const DOUBLE_OPT_IN = 'DOUBLE_OPT_IN';
export const DOUBLE_OPT_IN_REWARD = 50;

export const ACH_500_STARS = '500_STARS';
export const ACH_500_STARS_REWARD = 30;

export const ACH_1000_STARS = '1000_STARS';
export const ACH_1000_STARS_REWARD = 50;

export const achievementsOrder = [
  ACH_100_STARS,
  DOUBLE_OPT_IN,
  ACH_500_STARS,
  ACH_1000_STARS,
];

export function hasAttemptedCompleteAchievement(userAchievements, achievement) {
  return !!userAchievements[achievement]?.pendingTimestamp;
}

export function hasCompletedAchievement(userAchievements, achievement) {
  return !!userAchievements[achievement]?.completedTimestamp;
}

export function hasClaimedAchievement(userAchievements, achievement) {
  return !!userAchievements[achievement]?.claimedTimestamp;
}

export function getFirstUnclaimedAchievementIndex(userAchievements) {
  for (let i = 0; i < achievementsOrder.length; i++) {
    const achievement = achievementsOrder[i];
    if (!hasClaimedAchievement(userAchievements, achievement)) {
      return i;
    }
  }

  // All achievements claimed
  return achievementsOrder.length;
}

export function getFirstUncompletedAchievementIndex(userAchievements) {
  for (let i = 0; i < achievementsOrder.length; i++) {
    const achievement = achievementsOrder[i];
    if (!hasCompletedAchievement(userAchievements, achievement)) {
      return i;
    }
  }

  // All achievements completed
  return achievementsOrder.length;
}

// Note: This does not work if claimed achievements can have "gaps" (i.e. you have claimed second achievement, but not first).
// This will not happen unless we change the order of achievements
export function getNumberOfUnclaimedAchievements(userAchievements) {
  const firstUncompletedAchievementIndex = getFirstUncompletedAchievementIndex(userAchievements);
  const firstUnclaimedAchievementIndex = getFirstUnclaimedAchievementIndex(userAchievements);
  return Math.max(0, firstUncompletedAchievementIndex - firstUnclaimedAchievementIndex);
}

export function checkForCompletedAchievements(user, userAchievements) {
  if (user.optIn === 'DOUBLE_OPT_IN' && !hasAttemptedCompleteAchievement(userAchievements, DOUBLE_OPT_IN)) {
    return DOUBLE_OPT_IN;
  }
  // if ((user.numberOfStars || 0) >= 100 && !hasAttemptedCompleteAchievement(userAchievements, ACH_100_STARS)) {
  //   return ACH_100_STARS;
  // }
  // if ((user.numberOfStars || 0) >= 500 && !hasAttemptedCompleteAchievement(userAchievements, ACH_500_STARS)) {
  //   return ACH_500_STARS;
  // }
  // if ((user.numberOfStars || 0) >= 1000 && !hasAttemptedCompleteAchievement(userAchievements, ACH_1000_STARS)) {
  //   return ACH_1000_STARS;
  // }
}
