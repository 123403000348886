import { getBidArrayWithAlertsIncludingPositionalSymbols, getBidArrayWithoutAlertsIncludingPositionalSymbols, isBiddingFinished } from 'cuebids-bidding-util';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { getRobotAction } from 'cuebids-ai';
import { bids } from './types';
export function isCurrentBiddingPartOfSuggestedBidding(currentBiddingArray, suggestedBiddingArray) {
    for (let i = 0; i < currentBiddingArray.length; i++) {
        if (currentBiddingArray[i] !== suggestedBiddingArray[i]) {
            return false;
        }
    }
    return true;
}
export function checkHasViolatedSuggestedBidding(bidding, suggestedBidding) {
    const suggestedBiddingArray = getBidArrayWithoutAlertsIncludingPositionalSymbols(suggestedBidding);
    const currentBidArray = getBidArrayWithoutAlertsIncludingPositionalSymbols(bidding);
    return !isCurrentBiddingPartOfSuggestedBidding(currentBidArray, suggestedBiddingArray);
}
export function getNewBidding({ sessionDeal, bid, compete }) {
    const newBidding = `${sessionDeal.bidding}-${bid}`;
    const suggestedBidding = sessionDeal.suggestedBidding?.bidding ?? null;
    if (suggestedBidding != null) {
        const suggestedBiddingArray = getBidArrayWithoutAlertsIncludingPositionalSymbols(suggestedBidding);
        const currentBidArray = getBidArrayWithoutAlertsIncludingPositionalSymbols(newBidding);
        if (isCurrentBiddingPartOfSuggestedBidding(currentBidArray, suggestedBiddingArray)) {
            const next = currentBidArray.length;
            const bidWithAlert = getBidArrayWithAlertsIncludingPositionalSymbols(suggestedBidding).at(next);
            // TODO: Should coach be allowed to enter suggested biddings that have not ended?
            if (bidWithAlert != null) {
                return `${newBidding}-${bidWithAlert}`;
            }
        }
    }
    const b = (Boolean(isBiddingFinished(newBidding)));
    if (b) {
        return newBidding;
    }
    if (compete === 0)
        return newBidding + '-P';
    return newBidding + getRobotAction({
        compete,
        sessionDeal,
        bidding: newBidding
    });
}
export function removeAlerts(bidding) {
    return bidding.replaceAll(/\[.*?]/gs, '');
}
export function checkBidding(bidding) {
    return removeAlerts(bidding)
        .split('-')
        .every((a) => bids.includes(a));
}
export function canRobotsDouble(dealVersion, compete) {
    return (dealVersion === 2 || dealVersion === 3) && compete === 2;
}
export function getBidderIndex(users, userId) {
    return users.indexOf(userId);
}
export function getNextUserId(users, userId) {
    const nextIndex = (getBidderIndex(users, userId) + 1) % users.length;
    return users[nextIndex];
}
export function getActorKey(bidderIndex) {
    return ['northToAct', 'southToAct'][bidderIndex];
}
export function getDirection(bidderIndex) {
    return ['N', 'S'][bidderIndex];
}
export function getPartnerDirection(bidderIndex) {
    return ['S', 'N'][bidderIndex];
}
export function getNextActorKey(actorKey) {
    return {
        northToAct: 'southToAct',
        southToAct: 'northToAct'
    }[actorKey];
}
