import { useRef, useState } from 'react'
import { useOutsideClick } from '../../util/hooks';
import { isSubEnough } from '../../util/sub';
import { dealTypes } from 'cuebids-deals'
import { SubIcon } from '../subIcons/subIcons';
import RequireHeartDialog from '../../marketing/RequireHeartDialog.jsx'
import RequireDiamondDialog from '../../marketing/RequireDiamondDialog.jsx';
import { useTranslation } from 'react-i18next';

export function DealSourcesSelect({
  selected = [],
  setSelected,
  activeSubs,
  hideSymbols = false,
  defaultTags = [],
  label = true
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);

  const [promptToSubscribe, setPromptToSubscribe] = useState('');

  const [filter, setFilter] = useState('');
  const wrapperRef = useRef(null);

  const { t } = useTranslation();

  useOutsideClick(wrapperRef, () => setDropdownOpen(false));

  function toggleDropdown() {
    const newOpen = !dropdownOpen;
    if (newOpen) {
      if (!hasOpened && selected.length === 0) {
        setSelected(defaultTags.filter(t => dealTypes[t]));
      }
      setHasOpened(true);
    }
    setDropdownOpen(newOpen);
  }

  function onSelect(tag) {
    const tagIndex = selected.indexOf(tag);

    if (tagIndex > -1) {
      setSelected(selected.filter((a) => a !== tag));
    } else {
      setSelected([...selected, tag]);
      if (!isSubEnough(
        activeSubs,
        dealTypes[tag].sub,
      )) {
        setPromptToSubscribe(dealTypes[tag].sub);
      }
    }
  }

  const filtered = Object.values(dealTypes)
    .filter((f) =>
      t(`deal_types.${f.tag}`).toLowerCase().includes(filter.toLowerCase())
    )

  return (
    <div className="w-full" ref={wrapperRef}>
      {
        promptToSubscribe === 'heart' && <RequireHeartDialog onClose={() => setPromptToSubscribe('')} />
      }
      {
        promptToSubscribe === 'diamond' && <RequireDiamondDialog onClose={() => setPromptToSubscribe('')} />
      }
      {
        label && (
          <label className="label">
            <span className="label-text">Deal types</span>
          </label>
        )
      }
      <button
        style={{ height: 'unset' }}
        className="btn-accent btn flex min-h-[5rem] w-80 flex-row items-center justify-between"
        type="button"
        onClick={toggleDropdown}
      >
        {dropdownOpen ? (
          <input
            type="text"
            placeholder="Filter..."
            className="input w-4/5"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        ) : selected.length > 0 ? (
          t('deal_type_select.deal_types')
        ) : (
          t('deal_type_select.random')
        )}

        <svg
          className="mx-1.5 h-4 w-4"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div
        className={
          (!dropdownOpen ? 'hidden ' : '') +
          'scroll absolute z-10 mt-0 max-h-44 overflow-x-hidden overflow-y-scroll rounded border border-slate-500 border-opacity-50 bg-base-100 p-4 shadow-xl'
        }
      >
        <div className="flex w-72 flex-col items-start justify-start">
          {filtered.length > 0 ? (
            filtered
              .map((dealType) => {
                const subNotEnough = !isSubEnough(
                  activeSubs,
                  dealType.sub,
                );

                return (
                  <li
                    className={`btn-ghost w-full btn flex items-center justify-between${subNotEnough ? ' opacity-30' : ''}`}
                    key={dealType.tag}
                    value={dealType.tag}
                  >
                    <div className="form-control grow">
                      <label className="label cursor-pointer gap-2">
                        <input
                          type="checkbox"
                          className="checkbox-accent checkbox"
                          checked={selected.includes(dealType.tag)}
                          onChange={() => onSelect(dealType.tag)}
                        />
                        <div className="flex w-full flex-col items-start">
                          <span className="text-xs">{t(`deal_types.${dealType.tag}`)}</span>
                          {subNotEnough && (
                            <span className="text-[8px]">
                              Requires subscription
                            </span>
                          )}
                        </div>
                      </label>
                    </div>

                    {dealType.sub && !hideSymbols && (
                      <SubIcon sub={dealType.sub} />
                    )}
                  </li>
                );
              })
          ) : (
            <>{t('deal_type_select.no_types')}</>
          )}
        </div>
      </div>
    </div>
  );
}
