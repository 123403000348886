export const ranges = {
  open: 11,
  weakOpen: 10,
  weakRaise: 3,
  raise: 5,
  intermediateRaise: 8,
  weakJumpShift: 2,
  newSuitForced: 2,
  newSuitUnforced: 6,
  overcallOneLevel: 8,
  overcallTwoLevel: 10,
  overcallTwoLevelSound: 13,
  extraValues: 15,
  invitational: 10,
  takeoutDouble: 12,
  penaltyDouble: 16,
};
