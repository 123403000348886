import { addDoc } from 'firebase/firestore';
import { currentUserId, getRepos } from '../base';
export async function submitInternalFeedback(feedback) {
    const d = new Date();
    const internalFeedbackRef = getRepos().internalFeedback.collection;
    await addDoc(internalFeedbackRef, {
        uid: currentUserId(),
        timestamp: d.getTime(),
        date: d.toLocaleString(),
        ...feedback
    });
}
