import { useRef, useState } from 'react';
import ChevronDown from '../icons/chevronDown'
import ChevronUp from '../icons/chevronUp'

export default function Collapse({
  title,
  titleElement,
  children,
  fullSizeAlways = false,
  contextSx,
  initialOpen = false, }) {

  const [open, setOpen] = useState(initialOpen)

  const collapse = useRef();

  const scrollToElement = () => {
    const { current } = collapse
    if (current !== null) {
      current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }


  const handleClick = () => {
    scrollToElement()
    setOpen((e) => !e);
  }

  return (
    <div
      className={`rounded-box w-full border border-base-300 slated ${
        fullSizeAlways ? '' : ' md:w-3/4'
      }`}

      ref={collapse}
    >
      {title && (
        <button
          type={'button'}
          className="p-4 text-xl text-start font-medium justify-between w-full gap-4 flex items-center"
          onClick={handleClick}
        ><>{title}</>
          {
            open ? <ChevronUp /> : <ChevronDown />
          }
        </button>
      )}
      {titleElement && <button
        type={'button'}
        className="p-4 justify-between flex items-center w-full gap-4"
        onClick={() => setOpen((e) => !e)}
      ><>{titleElement}</>
        {
          open ? <ChevronUp /> : <ChevronDown />
        }
      </button>}

      {
        open && <div className={`${contextSx} p-2`}>{children}</div>
      }

    </div>
  );
}
