import { Link } from 'react-router-dom';
import { EventIcon } from '../icons/dailyIcon';
import Leaderboard from '../leaderBoards/leaderBoard';
import EventTimer from './eventTimer';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useTranslation } from 'react-i18next';

export default function Daily({ session }) {
  const daily = useAppStateStore((state) => state.daily);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.daily_card' });

  const endDateString = daily
    ? new Date(daily.endDate).toLocaleDateString(i18n.language, {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    })
    : '';


  return (
    <div className="fade-in-slow card w-[350px] border-2 border-accent md:w-[400px]">
      <div className="flex items-center">
        <div className="flex w-[60%] flex-col items-center md:pr-4">
          {daily && (
            <div className="ml-1 w-full md:ml-4">
              <Leaderboard
                leaderBoard={daily.leaderBoard}
                top={3}
                links={true}
                header=""
                min={15}
              />
            </div>
          )}
        </div>

        <div className="my-2 flex w-[40%] flex-col items-center justify-center text-center">
          <div className="flex items-center">
            <EventIcon fill="#4d774e" />
            <span>{t('title')}</span>
          </div>
          <div>
            <div className="mb-3">
              <span className="info">{daily.pairCount ?? 0} {t('pairs')}</span>
            </div>
            <Link to={session ? '/dailyLeaderboard/' + daily.id : '/daily'}>
              <button className="btn-primary btn-sm btn mb-4">
                {session ? (
                  session.dealsCount - session.numberOfFinishedDeals > 0 ? (
                    <span>{t('continue')}</span>
                  ) : (
                    <span>{t('leaderboard')}</span>
                  )
                ) : (
                  <span>{t('join_event')}</span>
                )}
              </button>
            </Link>
            {daily && (
              <EventTimer
                event={daily}
                endDate={endDateString}
                label={'Daily'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
