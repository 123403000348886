import { ranges } from './constants';

const michaels = [
  // Spades + minor
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H$/,
    explain: 'Spades and a minor',
    options: [
      {
        bid: '2H[@"5!S and 5+!C or !D"]',
        reqC: 5,
        reqS: 5,
        reqMaxS: 6,
        reqHp: ranges.overcallTwoLevel,
        weight: 3,
      },
      {
        bid: '2H[@"5!S and 5+!C or !D"]',
        reqD: 5,
        reqS: 5,
        reqMaxS: 6,
        reqHp: ranges.overcallTwoLevel,
        weight: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-[P|D]$/,
    explain: 'Partner overcall with Michaels over 1H, opps pass or double (fallback bids)',
    options: [
      {
        bid: '2S["Preference"]',
        reqS: 2,
        weight: 0.5, // fallback
      },
      {
        // This will be fallback with hands such as 1-5-6-1, but that might be ok since that's difficult to handle and rare
        bid: '3C[@"Pass or correct"]',
        reqMaxS: 2,
        weight: 0.5, // fallback
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)$/,
    explain: 'Partner overcall with Michaels over 1H, opps bid anything',
    options: [
      // TODO
      // {
      //   bid: '2N["Asking for side suit, invitational or better"]',
      //   reqMaxS: 2,
      //   reqHp: ranges.raise,
      // },.
      {
        bid: '3C[@"Pass or correct"]',
        reqMaxS: 2,
        reqC: 3,
        reqD: 3,
      },
      {
        bid: '3D[@"Pass or correct"]',
        reqMaxS: 2,
        reqD: 3,
        reqC: 4,
        weight: 1.5,
      },
      {
        bid: '3H[@"Good raise in spades"]',
        reqS: 3,
        weight: 2.5,
        reqHp: ranges.intermediateRaise,
      },
      {
        bid: '3S["Support in spades"]',
        reqS: 3,
        weight: 2,
        reqHp: ranges.raise
      },
      {
        bid: '3S["Support in spades"]',
        reqS: 4,
        weight: 2,
        reqHp: ranges.weakRaise
      },
      {
        bid: '4S["To play"]',
        reqHp: ranges.open,
        reqS: 3,
        weight: 3,
      },
      {
        bid: '4S["To play"]',
        reqHp: ranges.raise,
        reqS: 4,
        weight: 3,
      },
      {
        bid: '4S["To play"]',
        reqS: 5,
        weight: 3,
      },
      {
        bid: '5C["Pass or correct"]',
        reqD: 4,
        reqC: 4,
        reqHp: ranges.raise,
        weight: 2,
      },
      {
        bid: '5C["Pass or correct"]',
        reqD: 5,
        reqC: 5,
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)-2S-(P|D|..)$/,
    explain: 'I overcall with Michaels over 1H, partner preferred spades',
    options: [
      {
        bid: '3C["Extra values"]',
        reqHp: ranges.overcallTwoLevelSound,
        reqC: 6,
      },
      {
        bid: '3D["Extra values"]',
        reqHp: ranges.overcallTwoLevelSound,
        reqD: 6,
      },
      {
        bid: '3S["Extra values"]',
        reqHp: ranges.extraValues,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)-3H-(P|D|..)$/,
    explain: 'I overcall with Michaels over 1H, partner good raise in spades',
    options: [
      {
        bid: '3S["To play"]',
      },
      {
        bid: '4S["To play"]',
        weight: 2,
        reqHp: ranges.overcallTwoLevelSound,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)-3H-(P|D|..)-3S-(P|D|..)$/,
    explain: 'partner overcall with Michaels over 1H, I good raise in spades, partner didnt bid game',
    options: [
      {
        bid: '4S["To play"]',
        reqHp: ranges.invitational,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)-3C-([PD])$/,
    explain: 'I overcall with Michaels over 1H, partner PC',
    options: [
      {
        bid: '3D[@"5+!S and 5+!D"]',
        reqD: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)-3C-([PD])-3D-(P|D|..)$/,
    explain: 'Partner overcall with Michaels over 1H, I PC, partner corrected to diamonds',
    options: [
      {
        bid: '4D["To play"]',
        reqD: 4,
      },
      {
        bid: '5D["To play"]',
        reqD: 4,
        weight: 2,
        reqHp: ranges.intermediateRaise
      },
      {
        bid: '5D["To play"]',
        reqD: 5,
        weight: 2,
        reqHp: ranges.newSuitUnforced
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)-3D-(P|D|..)$/,
    explain: 'I overcall with Michaels over 1H, partner PC and supported clubs, we compete if we can',
    options: [
      {
        bid: '4C[@"5+!S and 5+!C"]',
        reqC: 5,
      },
      {
        bid: '5C[@"5+!S and 5+!C"]',
        reqC: 5,
        weight: 2,
        reqHp: ranges.overcallTwoLevelSound
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)-3D-(P|D|..)-4C-(P|D|..)$/,
    explain: 'Partner overcall with Michaels over 1H, I PC and supported clubs, partner corrected to clubs',
    options: [
      {
        bid: '5C["To play"]',
        reqC: 4,
        reqHp: ranges.intermediateRaise
      },
      {
        bid: '5C["To play"]',
        reqC: 5,
        reqHp: ranges.newSuitUnforced
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)-3S-(P|D|..)$/,
    explain: 'I overcall with Michaels over 1H, partner supported spades',
    options: [
      {
        bid: '4S["To play"]',
        reqHp: ranges.overcallTwoLevelSound,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)-4S-(..)$/,
    explain: 'I overcall with Michaels over 1H, partner jumped to game, fight at 5 level',
    options: [
      {
        bid: '5S["To play"]',
        reqHp: ranges.overcallTwoLevelSound,
        reqParS: 160,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)-4S-(P|D|..)-P-(..)$/,
    explain: 'Partner overcall with Michaels over 1H, I jumped to game, fight at 5 level',
    options: [
      {
        bid: '5S["To play"]',
        reqS: 5,
        reqParS: 160,
      },
      {
        bid: '5S["To play"]',
        reqHp: ranges.invitational,
        reqParS: 160,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-2H-(P|D|..)-5C-([PD])$/,
    explain: 'I overcall with Michaels over 1H, partner PC with 5C',
    options: [
      {
        bid: '5D[@"5+!S and 5+!D"]',
        reqD: 5,
      },
    ],
  },

  // Hearts + minor
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S$/,
    explain: 'Hearts and a minor',
    options: [
      {
        bid: '2S[@"5!H and 5+!C or !D"]',
        reqC: 5,
        reqH: 5,
        reqMaxH: 6,
        reqHp: ranges.overcallTwoLevel,
        weight: 3,
      },
      {
        bid: '2S[@"5!H and 5+!C or !D"]',
        reqD: 5,
        reqH: 5,
        reqMaxH: 6,
        reqHp: ranges.overcallTwoLevel,
        weight: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2S-[P|D]$/,
    explain: 'Partner overcall with Michaels over 1S, opps pass or double (fallback bids)',
    options: [
      {
        // This will be fallback with hands such as 5-2-5-1, but that might be ok since that's difficult to handle and rare
        bid: '3C[@"Pass or correct"]',
        reqMaxH: 2,
        weight: 0.5, // fallback
      },
      // TODO: Fallback to 3H sometimes?
      {
        bid: '3H["Preference"]',
        reqH: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2S-..$/,
    explain: 'Partner overcall with Michaels over 1S, opps make active bid (active fallback bids)',
    options: [
      {
        bid: '3H["Support"]',
        reqH: 3,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2S-(P|D|..)$/,
    explain: 'Partner overcall with Michaels over 1S, opps bid anything',
    options: [
      // TODO
      // {
      //   bid: '2N["Asking for side suit, invitational or better"]',
      //   reqMaxH: 2,
      //   reqHp: ranges.raise,
      // },.
      {
        bid: '3C[@"Pass or correct"]',
        reqMaxH: 2,
        reqC: 3,
        reqD: 3,
      },
      {
        bid: '3D[@"Pass or correct"]',
        reqMaxH: 2,
        reqD: 3,
        reqC: 4,
        weight: 1.5,
      },
      {
        bid: '4H["To play"]',
        reqHp: ranges.open,
        reqH: 3,
        weight: 3,
      },
      {
        bid: '4H["To play"]',
        reqHp: ranges.raise,
        reqH: 4,
        weight: 3,
      },
      {
        bid: '4H["To play"]',
        reqH: 5,
        weight: 3,
      },
      {
        bid: '5C["Pass or correct"]',
        reqD: 4,
        reqC: 4,
        reqHp: ranges.raise,
        weight: 2,
      },
      {
        bid: '5C["Pass or correct"]',
        reqD: 5,
        reqC: 5,
        weight: 2,
      },
    ],
  },
  // TODO: Opener bid again over 3H?
  // {
  //   biddingMatch: /^\.?(-\.)*(-P)*-1S-2S-(P|D|..)-3H-(P|D|..)$/,
  //   explain: 'I overcall with Michaels over 1S, partner preferred hearts',
  //   options: [
  //     {
  //       bid: '3C["Extra values"]',
  //       reqHp: ranges.overcallTwoLevelSound,
  //       reqC: 6,
  //     },
  //     {
  //       bid: '3D["Extra values"]',
  //       reqHp: ranges.overcallTwoLevelSound,
  //       reqD: 6,
  //     },
  //     {
  //       bid: '3S["Extra values"]',
  //       reqHp: ranges.extraValues,
  //     },
  //   ],
  // },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2S-(P|D|..)-3C-([PD])$/,
    explain: 'I overcall with Michaels over 1S, partner PC',
    options: [
      {
        bid: '3D[@"5+!H and 5+!D"]',
        reqD: 5,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2S-(P|D|..)-3C-([PD])-3D-(P|D|..)$/,
    explain: 'Partner overcall with Michaels over 1S, I PC, partner corrected to diamonds',
    options: [
      {
        bid: '4D["To play"]',
        reqD: 4,
      },
      {
        bid: '5D["To play"]',
        reqD: 4,
        weight: 2,
        reqHp: ranges.intermediateRaise
      },
      {
        bid: '5D["To play"]',
        reqD: 5,
        weight: 2,
        reqHp: ranges.newSuitUnforced
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2S-(P|D|..)-3D-(P|D|..)$/,
    explain: 'I overcall with Michaels over 1S, partner PC and supported clubs, we compete if we can',
    options: [
      {
        bid: '4C[@"5+!H and 5+!C"]',
        reqC: 5,
      },
      {
        bid: '5C[@"5+!H and 5+!C"]',
        reqC: 5,
        weight: 2,
        reqHp: ranges.overcallTwoLevelSound
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2S-(P|D|..)-3D-(P|D|..)-4C-(P|D|..)$/,
    explain: 'Partner overcall with Michaels over 1S, I PC and supported clubs, partner corrected to clubs',
    options: [
      {
        bid: '5C["To play"]',
        reqC: 4,
        reqHp: ranges.intermediateRaise
      },
      {
        bid: '5C["To play"]',
        reqC: 5,
        reqHp: ranges.newSuitUnforced
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2S-(P|D|..)-4H-(..)$/,
    explain: 'I overcall with Michaels over 1S, partner jumped to game, fight at 5 level',
    options: [
      {
        bid: '5H["To play"]',
        reqHp: ranges.overcallTwoLevelSound,
        reqParH: 160,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2S-(P|D|..)-4H-(P|D|..)-P-(..)$/,
    explain: 'Partner overcall with Michaels over 1S, I jumped to game, fight at 5 level',
    options: [
      {
        bid: '5H["To play"]',
        reqH: 5,
        reqParH: 160,
      },
      {
        bid: '5H["To play"]',
        reqHp: ranges.invitational,
        reqParH: 160,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-2S-(P|D|..)-5C-([PD])$/,
    explain: 'I overcall with Michaels over 1S, partner PC with 5C',
    options: [
      {
        bid: '5D[@"5+!H and 5+!D"]',
        reqD: 5,
      },
    ],
  },

  // Both minors
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]$/,
    explain: 'Both minors',
    options: [
      {
        bid: '2N[@"5+!D and 5+!C"]',
        reqC: 5,
        reqD: 5,
        reqHp: ranges.overcallTwoLevel,
        weight: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-2N-(P|D|..)$/,
    explain: 'Partner overcall with Böes over 1M',
    options: [
      {
        bid: '3C["Preference"]',
        weight: 0.5, // fallback
      },
      {
        bid: '3D["Preference"]',
        reqStrains: function(strains) {
          return strains.D > strains.C;
        },
      },
      {
        bid: '4C["Support"]',
        reqHp: ranges.raise,
        reqC: 4,
        reqStrains: function(strains) {
          return strains.D <= strains.C;
        },
        weight: 2,
      },
      {
        bid: '4C["Support"]',
        reqHp: ranges.invitational,
        reqC: 3,
        reqStrains: function(strains) {
          return strains.D <= strains.C;
        },
        weight: 2,
      },
      {
        bid: '4D["Support"]',
        reqHp: ranges.raise,
        reqD: 4,
        reqStrains: function(strains) {
          return strains.D > strains.C;
        },
        weight: 2,
      },
      {
        bid: '4D["Support"]',
        reqHp: ranges.invitational,
        reqD: 3,
        reqStrains: function(strains) {
          return strains.D > strains.C;
        },
        weight: 2,
      },
      {
        bid: '5C["To play"]',
        reqHp: ranges.open,
        reqC: 3,
        reqStrains: function(strains) {
          return strains.D <= strains.C;
        },
        weight: 3,
      },
      {
        bid: '5C["To play"]',
        reqHp: ranges.invitational,
        reqC: 4,
        reqStrains: function(strains) {
          return strains.D <= strains.C;
        },
        weight: 3,
      },
      {
        bid: '5C["To play"]',
        reqHp: ranges.raise,
        reqC: 5,
        reqStrains: function(strains) {
          return strains.D <= strains.C;
        },
        weight: 3,
      },
      {
        bid: '5D["To play"]',
        reqHp: ranges.open,
        reqD: 3,
        reqStrains: function(strains) {
          return strains.D > strains.C;
        },
        weight: 3,
      },
      {
        bid: '5D["To play"]',
        reqHp: ranges.invitational,
        reqD: 4,
        reqStrains: function(strains) {
          return strains.D > strains.C;
        },
        weight: 3,
      },
      {
        bid: '5D["To play"]',
        reqHp: ranges.raise,
        reqD: 5,
        reqStrains: function(strains) {
          return strains.D > strains.C;
        },
        weight: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-2N-(P|D|..)-4C-(P|D|..)$/,
    explain: 'I overcall with Böes over 1M, partner support with 4C',
    options: [
      {
        bid: '5C["To play"]',
        reqHp: ranges.overcallTwoLevelSound,
      },
      {
        bid: '5C["To play"]',
        reqHp: ranges.open,
        reqC: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[HS]-2N-(P|D|..)-4D-(P|D|..)$/,
    explain: 'I overcall with Böes over 1M, partner support with 4D',
    options: [
      {
        bid: '5D["To play"]',
        reqHp: ranges.overcallTwoLevelSound,
      },
      {
        bid: '5D["To play"]',
        reqHp: ranges.open,
        reqD: 6,
      },
    ],
  },
];

export default michaels;
