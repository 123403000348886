import InfoPopup from '../../../components/infoPopup/infoPopup.jsx';
import { StarRating } from '../../../components/rating/starRating.jsx';

const ExplainStars = ({ bonusGrade }) => {
  if (bonusGrade) {
    return (
      <span className="text-base">
        Your score is higher than par, that&apos;s why you see this rainbow effect!
      </span>
    );
  }
  return (
    <span className="text-base">
      Stars are calculated based on your Expected Value (EV) compared to the
      best contract&apos;s EV. The closer you are to the optimal contract the
      more stars you get, up to 3.
    </span>
  );
};

export default function StarRatingDisplay({ id, numberOfStars, bonusGrade }) {
  return <InfoPopup
    id="tooltip-stars"
    anchorElement={
      <StarRating
        key={id}
        stars={numberOfStars}
        maxStars={3}
        bonus={bonusGrade}
        size={'lg'}
      />
    }
  >
    <ExplainStars bonusGrade={bonusGrade} />
  </InfoPopup>
}
