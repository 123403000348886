import { TicketIcon } from '../components/icons/ticketsIcon.jsx';
import Checkbox from '../components/checkbox/Checkbox.jsx';
import { TypeIcon } from '../components/icons/typeIcon.jsx';
import { GroupsIcon } from '../components/icons/groupsIcon.jsx';
import { useTranslation } from 'react-i18next';

export default function DiamondToHeartInfo() {
  const { t } = useTranslation();
  
  return (
    <table className="table w-full">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th className={'diamonds text-md'}>{t('marketing.diamond')}</th>
          <th className={'hearts text-xl'}>{t('marketing.heart')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{t('marketing.tickets')}</th>
          <td>
            <TicketIcon />
          </td>
          <td>240</td>
          <td className={'text-sky-400 font-bold'}>
            {t('marketing.infinite')}
          </td>
        </tr>
        <tr>
          <th>{t('marketing.deal_types')}</th>
          <td />
          <td>14</td>
          <td className={'text-sky-400 font-bold'}>{t('marketing.all')}</td>
        </tr>

        <tr>
          <th>{t('marketing.custom_scenarios')}</th>
          <td>
            <TypeIcon className={'stroke-sky-400'} />
          </td>
          <td>
            <Checkbox checked={false} />
          </td>
          <td>
            <Checkbox checked={true} />
          </td>
        </tr>
        <tr>
          <th>{t('marketing.private_groups')}</th>
          <td>
            <GroupsIcon className={'fill-sky-400'} />
          </td>
          <td>
            <Checkbox checked={false} />
          </td>
          <td>
            <Checkbox checked={true} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
