import { currentUserId, getFirestore, getRepos } from '../base';
import { docToObject } from 'firebase-util';
import { arrayRemove, arrayUnion, updateDoc, writeBatch } from 'firebase/firestore';
export async function getGroupSession(id) {
    const groupSession = await getRepos().groupSession.doc(id);
    const data = await docToObject(groupSession);
    if (data.deleted === true) {
        return null;
    }
    return data;
}
export async function deleteGroupSession(id) {
    const groupSession = await getGroupSession(id);
    if (groupSession == null || groupSession.deleted) {
        throw new Error('the event does not exist');
    }
    // if (groupSession.pairCount > 0) {
    //   throw new Error('players have already started the event')
    // }
    const batch = writeBatch(getFirestore());
    const groupSessionRef = getRepos().groupSession.docRef(id);
    batch.update(groupSessionRef, {
        deleted: true,
        endDate: Date.now()
    });
    await batch.commit();
    await removeEventBadgeForAllUsers(groupSession.groupId, id);
}
export async function addEventBadgeToAllUsersInGroup(groupId, groupSessionId) {
    const group = await getRepos().groups.doc(groupId);
    const data = await docToObject(group);
    const batch = writeBatch(getFirestore());
    data.members.forEach((userId) => {
        const userRef = getRepos().users.docRef(userId);
        batch.update(userRef, {
            eventBadges: arrayUnion(groupSessionId)
        });
    });
    await batch.commit();
}
export async function removeEventBadge(groupSessionId) {
    const currentUser = currentUserId();
    const userRef = getRepos().users.docRef(currentUser);
    await updateDoc(userRef, {
        eventBadges: arrayRemove(groupSessionId)
    });
}
export async function removeEventBadgeForAllUsers(groupId, groupSessionId) {
    const group = await getRepos().groups.doc(groupId);
    const data = await docToObject(group);
    const batch = writeBatch(getFirestore());
    data.members.forEach((userId) => {
        if (userId !== '') {
            const userRef = getRepos().users.docRef(userId);
            batch.update(userRef, {
                eventBadges: arrayRemove(groupSessionId)
            });
        }
    });
    await batch.commit();
}
