import { useEffect } from 'react';
import Alert from '../../components/alert/alert';
import { Link } from 'react-router-dom';
import { submitInternalFeedback } from '../../firebase/feedback';
import { useTranslation } from 'react-i18next'

export default function NotFoundPage() {
  const { t } = useTranslation();

  useEffect(function () {
    void submitInternalFeedback({
      category: '404',
      href: location.href,
    });
  }, []);

  return (
    <div className="page">
      <Alert
        severity="warning"
        text={t('404.warning')}
      />
      <Link className="btn-primary btn mt-10" to="/">
        {t('404.to_start_page')}
      </Link>
    </div>
  );
}
