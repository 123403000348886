export function getTrimmedDisplayName(displayName) {
  return (displayName || '').replace(/\s+/g, ' ').trim();
}

export function isDisplayNameTooShort(displayName) {
  return getTrimmedDisplayName(displayName).length < 3;
}

export function doesDisplayNameHaveForbiddenCharacters(displayName) {
  return !(displayName || '').match(/^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-]+$/u);
}

export function doesDescriptionHaveForbiddenCharacters(displayName) {
  return !(displayName || '').match(/^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-]*$/u);
}

export function isDisplayNameValid(displayName) {
  return !isDisplayNameTooShort(displayName) && !doesDisplayNameHaveForbiddenCharacters(displayName);
}
