import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Hand from '../../components/cards/hand';
import { getSessionDealObservable, toggleMarkSessionDeal, } from '../../firebase/biddingSessions';
import KibitzDeal from './sessionComponents/kibitzDeal';
import SessionDealResult from './sessionComponents/sessionDealResult';
import { getPublicUser } from '../../firebase/subscriptions';
import ExpiredSessionDeal from './sessionComponents/expiredSessionDeal';
import ActiveSessionDeal from './sessionComponents/activeSessionDeal';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useAuth, useDisplay } from '../../util/hooks.jsx';
import SessionDealChat from './sessionComponents/sessionDealChat.jsx';
import { getRobotUserId } from '../../util/robotPartner.js';
import ReviewModal from './sessionComponents/reviewModal.jsx';
import { getDealReview } from '../../firebase/dealReviews.js';
import { hasCompletedOnboardingStep, onboardingSteps } from '../../util/onboarding.js';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export default function SessionDeal() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUser } = useAuth();
  const updateAppState = useAppStateStore((state) => state.updateAppState);

  const user = useAppStateStore((state) => state.user);
  const appStateSessionDeals = useAppStateStore((state) => state.sessionDeals);
  const currentSession = useAppStateStore((state) => state.currentSession);
  const showChat = useAppStateStore((state) => state.showChat);
  const chatOpen = useAppStateStore((state) => state.chatOpen);
  const showReview = useAppStateStore((state) => state.reviewDialogOpen);
  const showOnboarding = !hasCompletedOnboardingStep({ user, step: onboardingSteps.finishSession });
  const hideReviewDialog = useAppStateStore((state) => state.hideReviewDialog);

  const navigate = useNavigate();
  const { height, tinyHeight, isSmall, isSmallNew, isSmallNewNew } =
      useDisplay();

  const [sessionDeal, setSessionDeal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [shouldAnimateResult, setShouldAnimateResult] = useState(false);
  const [marked, setMarked] = useState(false);
  const [alertPopup, setAlertPopup] = useState({ show: false, bidIndex: -1 });

  const robotId = getRobotUserId();

  const mySession = currentSession?.users?.includes(currentUser.uid);
  const sharedWithOthers = sessionDeal?.extraUsers?.length > 0;
  const sharedWithMe = sessionDeal?.shared;
  const partnerIsNotRobot = partner.id && partner.id !== robotId;

  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    if (sessionDeal?.dealId && !sessionDeal?.finished && showReview) {
      hideReviewDialog();
    }
  }, [hideReviewDialog, sessionDeal?.dealId, sessionDeal?.finished, showReview]);

  const { data: reviewData } = useQuery({
    queryKey: ['dealReview', sessionDeal?.id, sessionDeal?.finished],
    queryFn: async () => {
      if (!sessionDeal?.dealId || !sessionDeal?.finished) {
        return null;
      }
      return await getDealReview({
        dealId: sessionDeal.dealId,
        compete: sessionDeal.compete,
        withReview: sessionDeal.withReview,
        evaluationVersion: sessionDeal.evaluationVersion,
        result: {
          bidding: sessionDeal.bidding,
          contract: sessionDeal.contract,
          ev: sessionDeal.ev,
          grade: sessionDeal.resultGrade,
          users: sessionDeal.users,
          compete: sessionDeal.compete,
          evaluationVersion: sessionDeal.evaluationVersion,
          parBeatBest: sessionDeal.parBeatBest,
        },
      });
    }
  });

  useEffect(() => {
    if (searchParams && searchParams.get('chatOpen') && !chatOpen) {
      showChat();
    }
  }, [chatOpen, searchParams, showChat]);



  useEffect(
    function () {
      setSearchParams(
        function (oldParams) {
          oldParams.delete('chatOpen');
          return oldParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );



  useEffect(() => {
    if (!sessionDeal?.dealNumber) {
      return;
    }

    if (sessionDeal?.kibitzing) {
      updateAppState({
        title: 'Hand ' + sessionDeal.dealNumber,
        subtitle: sessionDeal?.id,
      });
    } else {
      if (!partner) {
        return;
      } else {
        updateAppState({
          title: 'Hand ' + sessionDeal.dealNumber,
          subtitle: sessionDeal?.coachRobot ? sessionDeal.coachRobot.name : partner.displayName,
        });
      }
    }

    return () => updateAppState({ title: undefined, subtitle: undefined });
  }, [
    updateAppState,
    sessionDeal?.dealNumber,
    partner,
    sessionDeal?.kibitzing,
    sessionDeal?.id,
    sessionDeal?.coachRobot,
  ]);

  let sessionDeals = appStateSessionDeals;
  if (
    sessionDeals.length &&
      sessionDeals[0].sessionId !== sessionDeal?.sessionId
  ) {
    // Session deals is cached from another session, wait for the new ones to be loaded
    sessionDeals = [];
  }

  let previousDealNumber;
  let nextDealNumber;
  let previousDealIndex;
  let nextDealIndex;

  if (sessionDeal && sessionDeals.length) {
    previousDealNumber =
        sessionDeal.dealNumber === 1
          ? sessionDeals.length
          : sessionDeal.dealNumber - 1;
    nextDealNumber =
        sessionDeal.dealNumber === sessionDeals.length
          ? 1
          : sessionDeal.dealNumber + 1;
    previousDealIndex = previousDealNumber - 1;
    nextDealIndex = nextDealNumber - 1;
  }

  const moveDeal = useCallback(
    (next) => {
      if (next) {
        if (nextDealIndex != null) {
          navigate(`/session/deal/${sessionDeals[nextDealIndex].id}`, {
            replace: true,
          });
        }
      } else {
        if (previousDealIndex != null) {
          navigate(`/session/deal/${sessionDeals[previousDealIndex].id}`, {
            replace: true,
          });
        }
      }
    },
    [navigate, sessionDeals, nextDealIndex, previousDealIndex]
  );

  useEffect(() => {
    if (!sessionDeal) return;

    updateAppState({
      navbarItems: (
        <div className="flex items-center gap-4 mr-1 md:mr-8 md:gap-8 ">
          <div className="">
            <button className="" onClick={() => moveDeal(false)}>
              <kbd className="kbd text-sm">◀︎</kbd>
            </button>
          </div>

          <div className="">
            <button className="" onClick={() => moveDeal(true)}>
              <kbd className="kbd text-sm">▶︎</kbd>
            </button>
          </div>
        </div>
      ),
    });
    return () => updateAppState({ navbarItems: undefined });
  }, [updateAppState, sessionDeal, moveDeal]);

  useEffect(() => {
    if (sessionDeal?.sessionId) {
      updateAppState({ sessionId: sessionDeal.sessionId });
    }
  }, [sessionDeal?.sessionId, updateAppState]);

  useEffect(() => {
    if (sessionDeal?.sessionId && !sessionDeal?.kibitzing) {
      const userIndex = sessionDeal.users.indexOf(currentUser.uid);
      const pdId = sessionDeal.users[userIndex === 0 ? 1 : 0];
      return getPublicUser({ uid: pdId, callback: setPartner });
    }
  }, [
    sessionDeal?.sessionId,
    currentUser.uid,
    sessionDeal?.users,
    sessionDeal?.kibitzing,
  ]);

  // Reset all values when new deal loads. Got some jumpy behaviour when remounting entire component, so do it like this instead (which is maybe more correct anyway).
  useEffect(
    function () {
      setLoading(false);
      setShowAlert(false);
      setShouldAnimateResult(false);
      setAlertPopup({ show: false, bidIndex: -1 });
    },
    [id]
  );




  useEffect(
    function () {
      setSessionDeal(null);
      return getSessionDealObservable({
        sessionDealId: id,
        callback: function (sd) {
          if (sd) {
            setSessionDeal(sd);
          } else {
            navigate(`/404${location.pathname}`);
          }
        },
      });
    },
    [id, navigate]
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (showAlert || chatOpen || alertPopup?.show || feedbackOpen) {
        return;
      }
      if (event.key === 'ArrowRight') {
        moveDeal(true);
      }
      if (event.key === 'ArrowLeft') {
        moveDeal(false);
      }
    },
    [moveDeal, chatOpen, showAlert, alertPopup, feedbackOpen]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  let topHandDirection = 'N';
  let bottomHandDirection = 'S';
  if (sessionDeal && sessionDeal.users.indexOf(currentUser.uid) === 0) {
    topHandDirection = 'S';
    bottomHandDirection = 'N';
  }

  useEffect(() => {
    sessionDeal && setMarked(sessionDeal.marked ?? false);
  }, [sessionDeal]);

  const markForDiscussion = async () => {
    await toggleMarkSessionDeal(sessionDeal.id, !marked);
    setMarked(!marked);
  };

  let content = null;

  if (sessionDeal?.kibitzing) {
    content = (
      <KibitzDeal
        currentUser={currentUser}
        sessionDeal={sessionDeal}
        isSmall={isSmall}
        marked={marked}
        markForDiscussion={markForDiscussion}
        review={reviewData}
      />
    );
  } else if (sessionDeal?.expired) {
    content = (
      <ExpiredSessionDeal
        sessionDeal={sessionDeal}
        isSmall={isSmallNewNew}
        topHandDirection={topHandDirection}
      />
    );
  } else if (sessionDeal?.finished) {
    content = (
      <SessionDealResult
        key={sessionDeal.id}
        isSmall={isSmallNew}
        sessionDeal={sessionDeal}
        currentUser={currentUser}
        topHandDirection={topHandDirection}
        bottomHandDirection={bottomHandDirection}
        setFeedbackOpen={setFeedbackOpen}
        marked={marked}
        markForDiscussion={markForDiscussion}
        shouldAnimateResult={shouldAnimateResult}
        setShouldAnimateResult={setShouldAnimateResult}
        review={reviewData}
      />
    );
  } else if (sessionDeal) {
    content = (
      <ActiveSessionDeal
        sessionDeal={sessionDeal}
        session={currentSession}
        bottomHandDirection={bottomHandDirection}
        currentUser={currentUser}
        height={height}
        loading={loading}
        setLoading={setLoading}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        chatOpen={chatOpen}
        partner={partner}
        marked={marked}
        markForDiscussion={markForDiscussion}
        setShouldAnimateResult={setShouldAnimateResult}
        alertPopup={alertPopup}
        setAlertPopup={setAlertPopup}
      />
    );
  }



  return (
    <>
      <div className="page fade-in relative h-[calc(100%-70px)]">
        {sessionDeal && (
          <div
            key={id}
            className="flex h-full max-h-[800px] w-full flex-col md:w-4/5 justify-between"
          >
            {content}
            {sessionDeal.handPart && !sessionDeal.kibitzing && (
              <div className={'h-[200px] mb-2'}>
                <Hand
                  scale={user?.interfaceScale}
                  showHcp={!(user?.hideHcpCount ?? false)}
                  hand={sessionDeal.handPart}
                  direction={bottomHandDirection}
                  variant={
                    user?.cardVariant ??
                              (user?.handAsDiagram ? 'diagram' : 'modern')
                  }
                  size={user?.cardSize ?? 'sm'}
                  order={user?.suitOrder ?? 'default'}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <ReviewModal
        show={showReview}
        sessionDeal={sessionDeal}
        results={reviewData}
        topHandDirection={topHandDirection}
        isSmall={isSmall}
        showOnboarding={showOnboarding}
        moveDeal={moveDeal}
      />
      {sessionDeal && ((partnerIsNotRobot && mySession) || (sharedWithOthers && mySession) || sharedWithMe) && (
        <SessionDealChat key={sessionDeal.id} sessionDeal={sessionDeal}
          currentUserId={currentUser.uid} moveDeal={moveDeal}/>
      )}
    </>
  );
}
