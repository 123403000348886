import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';
import '../../components/hand/hands.css';
import {
  getTopTenDaily,
  getTopTenStars,
  getTopTenWeekly,
} from 'cuebids-firebase/stats';
import { Star } from '../../components/rating/starRating';
import UserDisplay from '../../components/users/userDisplay';
import { Tab, TabPanel, Tabs } from '../../components/tabs/tabs';
import Animated from '../../components/animation/animated';
import { EventIcon } from '../../components/icons/dailyIcon';
import { getRobotUserId } from '../../util/robotPartner'
import RankedLeaderboard from '../../components/rankedLeaderboard/RankedLeaderboard'
import { useTranslation } from 'react-i18next'

function WeeklyLeaderBoard() {
  const [topTenWeekly, setTopTenWeekly] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const data = await getTopTenWeekly(getRobotUserId());
      setTopTenWeekly(data);
    }

    getData();
  }, [setTopTenWeekly]);

  const weeklyUser = (u, i) => {
    return (
      <Animated
        element="tr"
        variants={{
          hidden: () => ({
            opacity: 0,
            scale: 0,
          }),
          visible: (i) => ({
            opacity: 1,
            scale: 1,
            transition: {
              delay: i * 0.02,
            },
          }),
        }}
        initial="hidden"
        exit="hidden"
        animate="visible"
        custom={i}
        onClick={() => {
          navigate('/player/' + u.id);
        }}
        style={{ cursor: 'pointer' }}
        key={i}
      >
        <td style={{ width: 25 }}>{i + 1}</td>
        <td className="p-4">
          <UserDisplay uid={u.id} />
        </td>
        <td>
          <div>
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center">
                <EventIcon fill="#e29627" />
                {u.weeklyWins ?? 0}
              </div>
              <span className="info flex h-5 flex-row-reverse items-center">
                {(u.numberOfStars ?? 0).toLocaleString()} <Star />
              </span>
            </div>
          </div>
        </td>
      </Animated>
    );
  };

  return (
    <table className="mt-10 mb-1 w-full border-collapse rounded-sm">
      <thead>
        <tr>
          <th />
        </tr>
      </thead>
      <tbody>
        <AnimatePresence>
          {topTenWeekly.map((u, i) => {
            return weeklyUser(u, i);
          })}
        </AnimatePresence>
      </tbody>
    </table>
  );
}

function DailyLeaderBoard() {
  const [topTenDaily, setTopTenDaily] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const data = await getTopTenDaily(getRobotUserId());
      setTopTenDaily(data);
    }

    getData();
  }, [setTopTenDaily]);

  const dailyUser = (u, i) => {
    return (
      <Animated
        element="tr"
        variants={{
          hidden: () => ({
            opacity: 0,
            scale: 0,
          }),
          visible: (i) => ({
            opacity: 1,
            scale: 1,
            transition: {
              delay: i * 0.02,
            },
          }),
        }}
        initial="hidden"
        exit="hidden"
        animate="visible"
        custom={i}
        onClick={() => {
          navigate('/player/' + u.id);
        }}
        style={{ cursor: 'pointer' }}
        key={i}
      >
        <td style={{ width: 25 }}>{i + 1}</td>
        <td className="p-4">
          <UserDisplay uid={u.id} />
        </td>
        <td>
          <div>
            <div>
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center">
                  <EventIcon fill="#4d774e" />
                  {u.dailyWins ?? 0}
                </div>
                <span className="info flex flex-row-reverse items-center">
                  {(u.numberOfStars ?? 0).toLocaleString()} <Star />
                </span>
              </div>
            </div>
          </div>
        </td>
      </Animated>
    );
  };

  return (
    <table className="mt-10 mb-1 w-full border-collapse rounded-sm">
      <thead>
        <tr>
          <th />
        </tr>
      </thead>
      <tbody>
        <AnimatePresence>
          {topTenDaily.map((u, i) => {
            return dailyUser(u, i);
          })}
        </AnimatePresence>
      </tbody>
    </table>
  );
}


function StarsLeaderBoard() {
  const [topTenStars, setTopTenStars] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const data = await getTopTenStars(getRobotUserId());
      setTopTenStars(data);
    }

    getData();
  }, [setTopTenStars]);

  const starUser = (u, i) => {
    return (
      <Animated
        element="tr"
        variants={{
          hidden: () => ({
            opacity: 0,
            scale: 0,
          }),
          visible: (i) => ({
            opacity: 1,
            scale: 1,
            transition: {
              delay: i * 0.02,
            },
          }),
        }}
        initial="hidden"
        exit="hidden"
        animate="visible"
        custom={i}
        onClick={() => {
          navigate('/player/' + u.id);
        }}
        style={{ cursor: 'pointer' }}
        key={i}
      >
        <td style={{ width: 25 }}>{i + 1}</td>
        <td className="p-4">
          <UserDisplay uid={u.id} />
        </td>
        <td>
          <div className="flex items-center p-2">
            <Star /> {(u.numberOfStars ?? 0).toLocaleString()}
          </div>
        </td>
      </Animated>
    );
  };

  return (
    <table className="mt-10 mb-1 w-full border-collapse rounded-sm">
      <thead>
        <tr>
          <th />
        </tr>
      </thead>
      <tbody>
        <AnimatePresence>
          {topTenStars.map((u, i) => {
            return starUser(u, i);
          })}
        </AnimatePresence>
      </tbody>
    </table>
  );
}

export default function StatsOverview() {
  const { t } = useTranslation('translation', { keyPrefix: 'stats_overview' });
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? 'ranked';
  const navigate = useNavigate();

  const handeTabChange = (tabId) => {
    navigate(`/stats?tab=${tabId}`, { replace: true });
  };

  return (
    <div className="fade-in">
      <div className="page pb-24">
        <div className="md:w-4/5">
          <Tabs>
            <Tab
              key={'ranked'}
              label={t('tab_ranked')}
              value="ranked"
              active={tab === 'ranked'}
              onChange={handeTabChange}
            />
            <Tab
              key={'stars'}
              label={t('tab_stars')}
              value="stars"
              active={tab === 'stars'}
              onChange={handeTabChange}
            />
            <Tab
              key={'weekly'}
              label={t('tab_weekly')}
              value="weekly"
              active={tab === 'weekly'}
              onChange={handeTabChange}
            />
            <Tab
              key={'daily'}
              label={t('tab_daily')}
              value="daily"
              active={tab === 'daily'}
              onChange={handeTabChange}
            />
          </Tabs>
          <TabPanel key={'rankedPanel'} value={tab} index={'ranked'}>
            <RankedLeaderboard />
          </TabPanel>
          <TabPanel key={'starsPanel'} value={tab} index={'stars'}>
            <StarsLeaderBoard />
          </TabPanel>
          <TabPanel key={'weeklyPanel'} value={tab} index={'weekly'}>
            <WeeklyLeaderBoard />
          </TabPanel>
          <TabPanel key={'dailyPanel'} value={tab} index={'daily'}>
            <DailyLeaderBoard />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
