import { useEffect, useState } from 'react';
import Dialog from '../dialog';
import Alert, { AlertInline } from '../alert/alert.jsx'
import Checkbox from '../checkbox/Checkbox.jsx';
import InfoPopup from '../infoPopup/infoPopup.jsx';
import {
  entries,
  entry,
  openingBids,
  openings,
  sequences,
} from './handTypes.js'
import Spade from '../../images/suits/Spade.jsx';
import Diamond from '../../images/suits/Diamond.jsx';
import Heart from '../../images/suits/Heart.jsx';
import Club from '../../images/suits/Club.jsx';
import { replaceSuitShorthandWithSymbols } from 'cuebids-bidding-util'
import { Tab, TabPanel, Tabs } from '../tabs/tabs.jsx';
import BidDisplay from '../bidding/bidDisplay.jsx';
import Collapse from '../collapse/Collapse.jsx';
import { getFirstUnusedVariable, resolveDirectionForSettings, sortSuits, sortVariables } from '../../util/scenario.js'
import useNotifications from '../notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next';

function isValidShape(shape) {
  let real_shape = shape.replace(/^(!=|!|=)/, '');

  if (real_shape.length !== 4) {
    return false;
  }

  if(real_shape.match(/[^0-9x]/)) {
    return false;
  }

  if (/^\d+$/.test(real_shape)) {
    const sum = real_shape.split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);
    return sum === 13;
  }
  else {
    let wildcardsCount = Array.from(real_shape).filter(char => char === 'x').length;
    let digitSum = Array.from(real_shape).filter(char => char !== 'x').map(Number).reduce((a, b) => a + b, 0);

    return digitSum <= 13 - wildcardsCount;
  }
}

function validateShapeString(shape) {
  return /^(!|=|!=)?[0-9x]{0,4}$/.test(shape);
}

function getValues(values, max, float=false) {
  const parse1 = float ? parseFloat(values[0]) : parseInt(values[0])
  const parse2 = float ? parseFloat(values[1]) : parseInt(values[1])


  const val1 = Math.min(isNaN(parse1) ? 0 : parse1, max);
  const val2 = Math.min(Math.min(isNaN(parse2) ? max : parse2), max);

  return [val1, val2]
}

const suitIconsMap = {
  'spades': <Spade key="spade" />,
  'hearts': <Heart key="heart" />,
  'diamonds': <Diamond key="diamond" />,
  'clubs': <Club key="club" />,
};

export default function AddHandTypeDialog({
  dir,
  isRobot,
  open,
  onClose,
  onAdd,
  onAddPartner,
  onAddLho,
  onAddRho,
  onAddSettings,
  onAddNewCustom,
  editHandType,
  onSaveEdit,
  variables,
  robots,
  isDealer,
  isRhoDealer,
}) {
  const notify = useNotifications();
  const { t } = useTranslation('translation', { keyPrefix: 'scenarios.hand_types' });
  const [handType, setHandType] = useState(null)

  const [activeTab, setActiveTab] = useState((isRobot && isRhoDealer) ? 1 : 0);

  const [checkboxes, setCheckboxes] = useState({});

  const [customType, setCustomType] = useState(!!editHandType);

  const editHpRange = editHandType?.constraints?.find((h) => h.name === 'hp_range')

  const [hasCustomHcpRange, setHasCustomHcpRange] = useState(!!editHpRange);
  const [customHcpRange, setCustomHcpRange] = useState(editHpRange?.args ?? [10, 20]);
  const [name, setName] = useState(editHandType?.label ?? '');

  const editShapes = editHandType?.constraints?.find((h) => h.name === 'shapes')

  const [hasCustomShape, setHasCustomShape] = useState(!!editShapes);
  const [shapes, setShapes] = useState(editShapes?.args ?? []);
  const [shapeErrors, setShapeErrors] = useState([]);

  const editSpades = editHandType?.constraints?.find((h) => h.name === 'spades')
  const [hasSpadesRange, setHasSpadesRange] = useState(!!editSpades);
  const [spadesRange, setSpadesRange] = useState(editSpades?.args ?? [0, 13]);

  const editHearts = editHandType?.constraints?.find((h) => h.name === 'hearts')
  const [hasHeartsRange, setHasHeartsRange] = useState(!!editHearts);
  const [heartsRange, setHeartsRange] = useState(editHearts?.args ?? [0, 13]);

  const editDiamonds = editHandType?.constraints?.find((h) => h.name === 'diamonds')
  const [hasDiamondsRange, setHasDiamondsRange] = useState(!!editDiamonds);
  const [diamondsRange, setDiamondsRange] = useState(editDiamonds?.args ?? [0, 13]);

  const editClubs = editHandType?.constraints?.find((h) => h.name === 'clubs')
  const [hasClubsRange, setHasClubsRange] = useState(!!editClubs);
  const [clubsRange, setClubsRange] = useState(editClubs?.args ?? [0, 13]);

  const editSuitX = editHandType?.constraints?.find((h) => h.name === 'X')
  const [hasSuitXRange, setHasSuitXRange] = useState(!!editSuitX);
  const [suitXRange, setSuitXRange] = useState(editSuitX?.args ?? [0, 13]);

  const editSuitY = editHandType?.constraints?.find((h) => h.name === 'Y')
  const [hasSuitYRange, setHasSuitYRange] = useState(!!editSuitY);
  const [suitYRange, setSuitYRange] = useState(editSuitY?.args ?? [0, 13]);

  const editSuitZ = editHandType?.constraints?.find((h) => h.name === 'Z')
  const [hasSuitZRange, setHasSuitZRange] = useState(!!editSuitZ);
  const [suitZRange, setSuitZRange] = useState(editSuitZ?.args ?? [0, 13]);

  const editSuitW = editHandType?.constraints?.find((h) => h.name === 'W')
  const [hasSuitWRange, setHasSuitWRange] = useState(!!editSuitW);
  const [suitWRange, setSuitWRange] = useState(editSuitW?.args ?? [0, 13]);

  const editCCCC = editHandType?.constraints?.find((h) => h.name === 'cccc');
  const [hasCCCCRange, setHasCCCCRange] = useState(!!editCCCC);
  const [ccccRange, setCCCCRange] = useState(editCCCC?.args ?? [10, 20]);

  const editSuitQuality = editHandType?.constraints?.find((h) => h.name === 'suit_quality');
  const [hasSuitQuality, setHasSuitQuality] = useState(!!editSuitQuality);
  const [suitQualityRange, setSuitQualityRange] = useState(editSuitQuality?.args ?? [0, 5]);

  const editVuls = editHandType?.constraints?.find((h) => h.name === 'vuls');
  const [hasVuls, setHasVuls] = useState(!!editVuls);
  const [allowedVuls, setAllowedVuls] = useState(editVuls?.args ?? ['NONE', 'NS', 'EW', 'ALL']);

  const suitMap = {
    'spades': { icon: <Spade />, range: spadesRange, setRange: setSpadesRange, hasRange: hasSpadesRange, setHasRange: setHasSpadesRange },
    'hearts': { icon: <Heart />, range: heartsRange, setRange: setHeartsRange, hasRange: hasHeartsRange, setHasRange: setHasHeartsRange },
    'diamonds': { icon: <Diamond />, range: diamondsRange, setRange: setDiamondsRange, hasRange: hasDiamondsRange, setHasRange: setHasDiamondsRange },
    'clubs': { icon: <Club />, range: clubsRange, setRange: setClubsRange, hasRange: hasClubsRange, setHasRange: setHasClubsRange },
  };

  const variablesSuitMap = {
    'X': { range: suitXRange, setRange: setSuitXRange, hasRange: hasSuitXRange, setHasRange: setHasSuitXRange },
    'Y': { range: suitYRange, setRange: setSuitYRange, hasRange: hasSuitYRange, setHasRange: setHasSuitYRange },
    'Z': { range: suitZRange, setRange: setSuitZRange, hasRange: hasSuitZRange, setHasRange: setHasSuitZRange },
    'W': { range: suitWRange, setRange: setSuitWRange, hasRange: hasSuitWRange, setHasRange: setHasSuitWRange },
  };

  function createCustomType () {
    setCustomType({})
  }

  function cancelCustomType () {
    setCustomType(null)
    onClose();
  }

  function toSnakeCase(str) {
    return str
      .replace(/[^a-zA-Z0-9\s-]/g, '')  // Remove special characters except space and dash
      .replace(/\s+/g, '_')            // Replace spaces with underscores
      .replace(/-+/g, '-')             // Collapse multiple dashes to one
      .toLowerCase();                  // Convert to lowercase
  }

  function handleSaveEditOrAddNewCustomType () {
    const constraints = []
    if (hasCustomHcpRange) {
      constraints.push(
        {
          'name': 'hp_range',
          'args': [customHcpRange[0], customHcpRange[1]]
        }
      )
    }
    if (hasCustomShape) {
      constraints.push(
        {
          'name': 'shapes',
          'args': shapes
        }
      )
    }
    if (hasSpadesRange) {
      constraints.push(
        {
          'name': 'spades',
          'args': [spadesRange[0], spadesRange[1]]
        }
      )
    }
    if (hasHeartsRange) {
      constraints.push(
        {
          'name': 'hearts',
          'args': [heartsRange[0], heartsRange[1]]
        }
      )
    }
    if (hasDiamondsRange) {
      constraints.push(
        {
          'name': 'diamonds',
          'args': [diamondsRange[0], diamondsRange[1]]
        }
      )
    }
    if (hasClubsRange) {
      constraints.push(
        {
          'name': 'clubs',
          'args': [clubsRange[0], clubsRange[1]]
        }
      )
    }
    Object.keys(variables).forEach(function (variable) {
      const { range, hasRange } = variablesSuitMap[variable]
      if (variables[variable].length > 0 && hasRange) {
        constraints.push(
          {
            'name': variable,
            'args': [range[0], range[1]]
          }
        )
      }
    });
    if (hasCCCCRange) {
      constraints.push(
        {
          'name': 'cccc',
          'args': [parseFloat(ccccRange[0].toString()), parseFloat(ccccRange[1].toString())]
        }
      )
    }
    if (hasSuitQuality) {
      constraints.push(
        {
          'name': 'suit_quality',
          'args': [parseInt(suitQualityRange[0].toString()), parseInt(suitQualityRange[1].toString())]
        }
      )
    }
    if (hasVuls) {
      constraints.push(
        {
          'name': 'vuls',
          'args': allowedVuls
        }
      )
    }
    if (name && constraints.length > 0) {
      // One of these will exist depending on if doing edit or add new
      onSaveEdit?.({
        name: toSnakeCase(name),
        label: name,
        constraints: constraints,
      });
      onAddNewCustom?.({
        name: toSnakeCase(name),
        label: name,
        constraints: constraints,
      });
    }
  }

  const addShape = () => {
    const newValues = [...shapes];
    newValues.push('')

    setShapes(newValues);
    setHasCustomShape(true);
  };

  const removeShape = (i) => {
    const newValues = [...shapes];
    newValues.splice(i, 1);
    setShapes(newValues);
    validateShapes(newValues)
  };

  const validateShapes = (shapes) => {
    let errorIndices = [];

    shapes.forEach((shape, index) => {
      if(!isValidShape(shape)) {
        errorIndices.push(index);
      }
    });

    setShapeErrors(errorIndices);
  }

  useEffect(() => {
    if (shapes.length === 0) {
      setHasCustomShape(false)
    }
  }, [shapes]);

  const handleChangeSuit = (suit, index) => (event) => {
    const newValues = [...(suitMap[suit].range)];
    newValues[index] = event.target.value;

    suitMap[suit].setRange(newValues);
  };


  const handleBlurSuit = (suit, index) => () => {
    const range = (suitMap[suit].range);
    const newValues = [...range];
    if (isNaN(range[index])) {
      newValues[index] = 0
      suitMap[suit].setRange(newValues)
    }

    if (index === 0 && parseInt(newValues[0]) > parseInt(newValues[1])) {
      newValues[1] = (parseInt(newValues[0])).toString();
    } else if (index === 1 && parseInt(newValues[1]) <= parseInt(newValues[0])) {
      newValues[0] = (parseInt(newValues[1])).toString();
    }

    const vals = getValues(newValues, 13);

    suitMap[suit].setRange(vals);
  }

  const handleChangeVariableSuit = (variable, index) => (event) => {
    const newValues = [...(variablesSuitMap[variable].range)];
    newValues[index] = event.target.value;

    variablesSuitMap[variable].setRange(newValues);
  };

  const handleBlurVariableSuit = (variable, index) => () => {
    const range = (variablesSuitMap[variable].range);
    const newValues = [...range];
    if (isNaN(range[index])) {
      newValues[index] = 0
      variablesSuitMap[variable].setRange(newValues)
    }

    if (index === 0 && parseInt(newValues[0]) > parseInt(newValues[1])) {
      newValues[1] = (parseInt(newValues[0])).toString();
    } else if (index === 1 && parseInt(newValues[1]) <= parseInt(newValues[0])) {
      newValues[0] = (parseInt(newValues[1])).toString();
    }

    const vals = getValues(newValues, 13);

    variablesSuitMap[variable].setRange(vals);
  }

  const handleChangeShape = (index) => (event) => {
    const newValues = [...shapes];
    const newValue = event.target.value
    if (validateShapeString(newValue)) {
      newValues[index] = newValue;
    }

    setShapes(newValues);
  };

  const handleHcpChange = (index) => (event) => {
    const newValues = [...customHcpRange];
    newValues[index] = event.target.value;
    setCustomHcpRange(newValues);
  };

  const handleChangeCCCC = (index) => (event) => {
    const newValues = [...ccccRange];
    newValues[index] = event.target.value;
    setCCCCRange(newValues);
  };

  const handleBlurCCCC = (index) => () => {
    const newValues = [...ccccRange];
    if (isNaN(ccccRange[index])) {
      newValues[index] = 0
      setCCCCRange(newValues)
    }

    if (index === 0 && parseFloat(newValues[0]) > parseFloat(newValues[1])) {
      newValues[1] = newValues[0];
    } else if (index === 1 && parseFloat(newValues[1]) < parseFloat(newValues[0])) {
      newValues[0] = newValues[1];
    }

    const vals = getValues(newValues, 35.6, true);

    setCCCCRange(vals);
  }

  const handleChangeSuitQuality = (index) => (event) => {
    const newValues = [...suitQualityRange];
    newValues[index] = event.target.value;
    setSuitQualityRange(newValues);
  };

  const handleBlurSuitQuality = (index) => () => {
    const newValues = [...suitQualityRange];
    if(isNaN(suitQualityRange[index])) {
      newValues[index] = 0
      setSuitQualityRange(newValues)
    }

    if (index === 0 && parseInt(newValues[0]) > parseInt(newValues[1])) {
      newValues[1] = newValues[0];
    } else if (index === 1 && parseInt(newValues[1]) < parseInt(newValues[0])) {
      newValues[0] = newValues[1];
    }

    const vals = getValues(newValues, 5, true);

    setSuitQualityRange(vals);
  }


  const handleBlurHcp = (index) => () => {
    const newValues = [...customHcpRange];
    if(isNaN(customHcpRange[index])) {
      newValues[index] = 0
      setCustomHcpRange(newValues)
    }

    if (index === 0 && parseInt(newValues[0]) > parseInt(newValues[1])) {
      newValues[1] = newValues[0];
    } else if (index === 1 && parseInt(newValues[1]) < parseInt(newValues[0])) {
      newValues[0] = newValues[1];
    }

    const vals = getValues(newValues, 40);

    setCustomHcpRange(vals);
  }

  const handleCheckboxChange = (areaLabel, checkboxId) => {
    setCheckboxes(prevBoxes => {
      if (!prevBoxes[areaLabel]) return prevBoxes;


      const boxIndex = prevBoxes[areaLabel].findIndex(box => box.id === checkboxId);
      if (boxIndex === -1) return prevBoxes;

      const newBoxes = JSON.parse(JSON.stringify(prevBoxes));

      newBoxes[areaLabel][boxIndex].isChecked = !newBoxes[areaLabel][boxIndex].isChecked;

      return newBoxes;
    });
  };

  const handleChangeAllowedVuls = (vul) => () => {
    let newAllowedVuls = [...allowedVuls];
    const index = newAllowedVuls.indexOf(vul);
    if (index > -1) {
      newAllowedVuls.splice(index, 1);
    } else {
      newAllowedVuls.push(vul);
    }
    setAllowedVuls(newAllowedVuls);
  };

  useEffect(() => {
    if(handType?.hand_types) {
      const typeBoxes = handType.hand_types.map((h, i) => ({
        type: h.name,
        boxes: h.optional?.map((o) => ({
          id: o.id,
          label: o.description,
          isChecked: o.default ?? false,
        }))
      }))
      const boxes = {}
      for (const typeBox of typeBoxes) {
        boxes[typeBox.type] = typeBox.boxes
      }
      setCheckboxes(boxes)
    }
    else if(handType?.optional) {
      const boxes = handType.optional.map((o) => ({
        id: o.id,
        label: o.description,
        isChecked: o.default ?? false,
      }));
      setCheckboxes({ [handType.name]: boxes });
    } else {
      setCheckboxes({})
    }
  }, [handType])

  function addTypeWithOptions (handType, addFn, variableName = '') {
    if (handType.type === 'command') {
      addFn({
        name: handType.name,
        label: t(`types.${handType.label}.name`),
        command: handType.command,
        premise: handType.premise ?? null,
      });
      return;
    }

    const checkedOptionsIds = checkboxes[handType.name]?.filter(c => c.isChecked).map(c => c.id);
    const optionals = handType?.optional || [];

    const { toAdd, toAddFalse } = optionals.reduce((acc, o) => {
      if (checkedOptionsIds.includes(o.id)) {
        acc.toAdd.push(...(o['constraints'] || []));
      } else {
        acc.toAddFalse.push(...(o['falseConstraints'] || []));
      }
      return acc;
    }, { toAdd: [], toAddFalse: [] });

    const constraints = ([...handType.constraints, ...toAdd, ...toAddFalse])
      .map(c => ({
        name: c.name === 'variable' ? variableName : c.name,
        args: c.args,
        description: c.description,
      }));

    const mergedData = constraints.reduce((acc, curr) => {
      const existing = acc.find(item => item.name === curr.name);

      if (existing) {
        existing.args = [...new Set([...existing.args, ...curr.args])];
      } else {
        acc.push({ ...curr });
      }

      return acc;
    }, []);

    addFn({
      name: handType.name,
      label: t(`types.${handType.label}.name`),
      constraints: mergedData,
    });
  }

  function handleAddType () {
    if (!handType) {
      return;
    }

    let variableName;
    if (handType.settings?.variable) {
      variableName = getFirstUnusedVariable(variables);
      if (!variableName) {
        notify(
          {
            text: t('notify_required_variable'),
            type: 'failure'
          }
        );
        return;
      }
    }

    if (handType.settings) {
      let s = { ...handType.settings };
      if (s.variable) {
        s.variables = {
          ...variables,
          [variableName]: sortSuits(s.variable),
        };
        delete s.variable;
      }
      if (s.dealer) {
        s.dealer = resolveDirectionForSettings(dir, s.dealer);
      }
      onAddSettings(s);
    }

    if (handType.type ==='sequence') {
      handType.hand_types.forEach(h => {
        addTypeWithOptions(h, onAdd, variableName);
      });
      (handType.partner_hand_types ?? []).forEach(h => {
        addTypeWithOptions(h, onAddPartner, variableName);
      });
      (handType.lho_hand_types ?? []).forEach(h => {
        addTypeWithOptions(h, onAddLho, variableName);
      });
      (handType.rho_hand_types ?? []).forEach(h => {
        addTypeWithOptions(h, onAddRho, variableName);
      });
    } else if (handType.type ==='opening') {
      handType.hand_types.forEach(h => {
        addTypeWithOptions(h, onAdd, variableName);
      });
    } else if (handType.type ==='entry') {
      handType.hand_types.forEach((h) => {
        addTypeWithOptions(h, onAdd, variableName);
      });
    }
    // For hand types
    // else {
    //   addTypeWithOptions(handType, onAdd, variableName);
    // }
  }

  const isRobotWithSpecialSystem = isRobot && ['MULTI', 'MINI_NT'].includes(robots);

  return (
    <Dialog
      id="add-hand-type-dialog"
      open={open}
      onClose={onClose}
      sx={'w-full h-[700px]'}
      title={t('title', { dir: t(dir) })}
    >
      <div className="h-[500px]">
        {customType ? (
          <div>
            <div className="flex flex-col m-2 max-w-75 mb-8">
              <label htmlFor="typename">{t('name')}</label>
              <input
                id="typename"
                type="text"
                className="input input-primary"
                placeholder={t('name_placeholder')}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <Collapse title={t('hcp')} fullSizeAlways>
              <div className="p-3 flex items-center">
                <Checkbox
                  label={t('hcp')}
                  checked={hasCustomHcpRange}
                  onChange={() => setHasCustomHcpRange((r) => !r)}
                />
                <input
                  type="number"
                  min={0}
                  max={40}
                  value={customHcpRange[0]}
                  onChange={handleHcpChange(0)}
                  onBlur={handleBlurHcp(0)}
                  className={`input input-primary w-20 mr-2 ${
                    !hasCustomHcpRange ? 'opacity-25' : ''
                  }`}
                  onFocus={() => setHasCustomHcpRange(true)}
                />
                {t('to')}
                <input
                  type="number"
                  min={0}
                  max={40}
                  value={customHcpRange[1]}
                  onChange={handleHcpChange(1)}
                  onBlur={handleBlurHcp(1)}
                  className={`input input-primary w-20 ml-2 ${
                    !hasCustomHcpRange ? 'opacity-25' : ''
                  }`}
                  onFocus={() => setHasCustomHcpRange(true)}
                />
              </div>
            </Collapse>

            <Collapse title={t('suits')} fullSizeAlways>
              <div className={'grid grid-cols-4'}>
                {['spades', 'hearts', 'diamonds', 'clubs'].map((suit) => {
                  const { icon, range, setRange, hasRange, setHasRange } =
                    suitMap[suit];
                  return (
                    <div className="py-3 flex flex-col items-center" key={suit}>
                      <Checkbox
                        label={icon}
                        checked={hasRange}
                        onChange={() => setHasRange((r) => !r)}
                      />
                      <input
                        type="number"
                        min={0}
                        max={13}
                        value={range[0]}
                        onChange={handleChangeSuit(suit, 0)}
                        onBlur={handleBlurSuit(suit, 0)}
                        className={`input input-primary w-20 h-8 ${
                          !hasRange ? 'opacity-25' : ''
                        }`}
                        onFocus={() => setHasRange(true)}
                      />
                      {t('to')}
                      <input
                        type="number"
                        min={0}
                        max={13}
                        value={range[1]}
                        onChange={handleChangeSuit(suit, 1)}
                        onBlur={handleBlurSuit(suit, 1)}
                        className={`input input-primary w-20 h-8 ${
                          !hasRange ? 'opacity-25' : ''
                        }`}
                        onFocus={() => setHasRange(true)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={'grid grid-cols-4'}>
                {sortVariables(Object.keys(variables)).map(function (variable) {
                  const suits = variables[variable];
                  if (suits.length > 0) {
                    const { range, setRange, hasRange, setHasRange } =
                      variablesSuitMap[variable];
                    return (
                      <div
                        key={variable}
                        className="pb-3 flex flex-col items-center"
                      >
                        <Checkbox
                          label={variable}
                          checked={hasRange}
                          onChange={() => setHasRange((r) => !r)}
                        />
                        <div
                          key={variable}
                          className="info flex items-center gap-1 mb-2"
                        >
                          {suits.map((s) => suitIconsMap[s])}
                        </div>
                        <input
                          type="number"
                          min={0}
                          max={13}
                          value={range[0]}
                          onChange={handleChangeVariableSuit(variable, 0)}
                          onBlur={handleBlurVariableSuit(variable, 0)}
                          className={`input input-primary w-20 h-8 ${
                            !hasRange ? 'opacity-25' : ''
                          }`}
                          onFocus={() => setHasRange(true)}
                        />
                        {t('to')}
                        <input
                          type="number"
                          min={0}
                          max={13}
                          value={range[1]}
                          onChange={handleChangeVariableSuit(variable, 1)}
                          onBlur={handleBlurVariableSuit(variable, 1)}
                          className={`input input-primary w-20 h-8 ${
                            !hasRange ? 'opacity-25' : ''
                          }`}
                          onFocus={() => setHasRange(true)}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </Collapse>

            <Collapse title={t('shapes')} fullSizeAlways>
              <div className="p-3 items-center">
                <div className="flex items-center">
                  <div className={'flex items-center gap-4 mb-2'}>
                    <Checkbox
                      label={t('shapes')}
                      checked={hasCustomShape}
                      onChange={() => setHasCustomShape((r) => !r)}
                    />
                    <button
                      className={`btn btn-sm btn-primary h-2 w-28 ${
                        !hasCustomShape ? 'opacity-25' : ''
                      }`}
                      onClick={addShape}
                    >
                      {t('add_shape')}
                    </button>
                    {!!hasCustomShape && (
                      <InfoPopup id={'shapes'} sx={'stroke-info'}>
                        <div className="flex flex-col items-start">
                          <span className="text-info">
                            {t('shape_explain.type_like')}
                            <strong className={'text-emerald-300'}>
                              4333
                            </strong>{' '}
                            {t('shape_explain.or')}
                            <strong className={'text-emerald-300'}>5431</strong>
                          </span>
                          <span className="text-info">
                            {t('shape_explain.exact_shape')}
                            <strong className={'text-emerald-300'}>
                              =5431
                            </strong>
                          </span>
                          <span className="text-info">
                            {t('shape_explain.forbid_shape')}
                            <strong className={'text-emerald-300'}>
                              !5431
                            </strong>
                          </span>
                          <span className="text-info">
                            {t('shape_explain.forbid_exact_shape')}
                            <strong className={'text-emerald-300'}>
                              !=5431
                            </strong>
                          </span>
                          <span className="text-info">
                            {t('shape_explain.allow_any')}
                            <strong className={'text-emerald-300'}>x</strong>,
                            {t('shape_explain.like')}
                            <strong className={'text-emerald-300'}>54xx</strong>
                          </span>
                          <span className="text-info">
                            {t('shape_explain.positions')}
                          </span>
                          <span className="text-info">
                            {t('shape_explain.explain')}
                          </span>
                        </div>
                      </InfoPopup>
                    )}
                  </div>
                </div>
                <div className="">
                  <ul className="grid grid-cols-3 gap-1 mb-2">
                    {shapes.map((s, i) => {
                      return (
                        <li key={i} className="flex relative w-20">
                          <input
                            type="text"
                            value={s}
                            onBlur={() => validateShapes(shapes)}
                            onChange={handleChangeShape(i)}
                            className={`input input-primary w-20 h-8 text-sm ${
                              !hasCustomShape ? 'opacity-25' : ''
                            } ${
                              shapeErrors.includes(i)
                                ? ' !bg-rose-400 !bg-opacity-25'
                                : ''
                            }`}
                            onFocus={() => setHasCustomShape(true)}
                          />
                          <button
                            className="absolute top-0 -right-4 btn btn-error btn-sm h-0.5 w-1 text-xs"
                            disabled={!hasCustomShape}
                            onClick={() => removeShape(i)}
                          >
                            x
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                  {shapeErrors.length > 0 && (
                    <Alert text={t('shape_issue_warning')} severity={'error'} />
                  )}
                </div>
              </div>
            </Collapse>

            <Collapse title={t('advanced')} fullSizeAlways>
              <div className={'grid grid-cols-3'}>
                <div className="py-3 flex flex-col items-center" key={'cccc'}>
                  <Checkbox
                    label="CCCC"
                    checked={hasCCCCRange}
                    onChange={() => setHasCCCCRange((r) => !r)}
                  />
                  <input
                    type="number"
                    min={-0.5}
                    max={36.5}
                    value={ccccRange[0]}
                    onChange={handleChangeCCCC(0)}
                    onBlur={handleBlurCCCC(0)}
                    className={`input input-primary w-20 h-8 ${
                      !hasCCCCRange ? 'opacity-25' : ''
                    }`}
                    onFocus={() => setHasCCCCRange(true)}
                  />
                  {t('to')}
                  <input
                    type="number"
                    min={-0.5}
                    max={36.5}
                    value={ccccRange[1]}
                    onChange={handleChangeCCCC(1)}
                    onBlur={handleChangeCCCC(1)}
                    className={`input input-primary w-20 h-8 ${
                      !hasCCCCRange ? 'opacity-25' : ''
                    }`}
                    onFocus={() => setHasCCCCRange(true)}
                  />
                </div>
                <div
                  className="py-3 flex flex-col items-center"
                  key={'suit_qual'}
                >
                  <Checkbox
                    label={t('long_suit_quality')}
                    checked={hasSuitQuality}
                    onChange={() => setHasSuitQuality((r) => !r)}
                  />
                  <select
                    value={suitQualityRange[0]}
                    onChange={handleChangeSuitQuality(0)}
                    onBlur={handleBlurSuitQuality(0)}
                    className={`select input-primary w-32 h-8 ${
                      !hasSuitQuality ? 'opacity-25' : ''
                    }`}
                    onFocus={() => setHasSuitQuality(true)}
                  >
                    <option value={0}>{t('suit_quality.garbage')}</option>
                    <option value={1}>{t('suit_quality.very_weak')}</option>
                    <option value={2}>{t('suit_quality.weak')}</option>
                    <option value={3}>{t('suit_quality.medium')}</option>
                    <option value={4}>{t('suit_quality.semi_solid')}</option>
                    <option value={5}>{t('suit_quality.running')}</option>
                  </select>
                  {t('to')}
                  <select
                    value={suitQualityRange[1]}
                    onChange={handleChangeSuitQuality(1)}
                    onBlur={handleBlurSuitQuality(1)}
                    className={`select input-primary w-32 h-8 ${
                      !hasSuitQuality ? 'opacity-25' : ''
                    }`}
                    onFocus={() => setHasSuitQuality(true)}
                  >
                    <option value={0}>{t('suit_quality.garbage')}</option>
                    <option value={1}>{t('suit_quality.very_weak')}</option>
                    <option value={2}>{t('suit_quality.weak')}</option>
                    <option value={3}>{t('suit_quality.medium')}</option>
                    <option value={4}>{t('suit_quality.semi_solid')}</option>
                    <option value={5}>{t('suit_quality.running')}</option>
                  </select>
                </div>
                <div className="py-3 flex flex-col items-start" key={'vuls'}>
                  <Checkbox
                    label={t('vulnerabilities_label')}
                    checked={hasVuls}
                    onChange={() => setHasVuls((r) => !r)}
                  />
                  <Checkbox
                    onFocus={() => setHasVuls(true)}
                    sx={!hasVuls ? 'opacity-25' : ''}
                    label={t('vulnerabilities.none')}
                    checked={allowedVuls.includes('NONE')}
                    onChange={handleChangeAllowedVuls('NONE')}
                  />
                  <Checkbox
                    onFocus={() => setHasVuls(true)}
                    sx={!hasVuls ? 'opacity-25' : ''}
                    label={t('vulnerabilities.NS')}
                    checked={allowedVuls.includes('NS')}
                    onChange={handleChangeAllowedVuls('NS')}
                  />
                  <Checkbox
                    onFocus={() => setHasVuls(true)}
                    sx={!hasVuls ? 'opacity-25' : ''}
                    label={t('vulnerabilities.EW')}
                    checked={allowedVuls.includes('EW')}
                    onChange={handleChangeAllowedVuls('EW')}
                  />
                  <Checkbox
                    onFocus={() => setHasVuls(true)}
                    sx={!hasVuls ? 'opacity-25' : ''}
                    label={t('vulnerabilities.all')}
                    checked={allowedVuls.includes('ALL')}
                    onChange={handleChangeAllowedVuls('ALL')}
                  />
                </div>
              </div>
            </Collapse>

            <div className="flex items-center gap-4 mt-2 pb-2">
              <button className="btn btn-secondary" onClick={cancelCustomType}>
                {t('cancel')}
              </button>
              <div className="divider divider-horizontal">{t('or')}</div>
              <button
                className="btn btn-primary"
                onClick={handleSaveEditOrAddNewCustomType}
                disabled={
                  !name ||
                  shapeErrors.length > 0 ||
                  !(
                    hasCustomHcpRange ||
                    hasCustomShape ||
                    hasSpadesRange ||
                    hasHeartsRange ||
                    hasDiamondsRange ||
                    hasClubsRange ||
                    hasSuitXRange ||
                    hasSuitYRange ||
                    hasSuitZRange ||
                    hasSuitWRange ||
                    hasCCCCRange ||
                    hasSuitQuality
                  )
                }
              >
                {onSaveEdit ? t('save') : t('add_new')}
              </button>
            </div>
          </div>
        ) : (
          <>
            {isRobotWithSpecialSystem ? (
              <DisplayTypes
                selected={handType}
                types={{
                  ...openings,
                  ...sequences,
                }}
                onSelect={setHandType}
                isRobot={isRobot}
                robots={robots}
              />
            ) : (
              <>
                <Tabs>
                  <Tab
                    key={'open'}
                    label={t('openings')}
                    value={0}
                    active={activeTab === 0}
                    onChange={setActiveTab}
                  />
                  {isRobot ? (
                    <Tab
                      key={'entry'}
                      label={t('second_seat_overcalls')}
                      value={1}
                      active={activeTab === 1}
                      onChange={setActiveTab}
                    />
                  ) : (
                    <Tab
                      key={'sequences'}
                      label={t('sequences')}
                      value={1}
                      active={activeTab === 1}
                      onChange={setActiveTab}
                    />
                  )}
                  {/*<Tab*/}
                  {/*  key={'hand_types'}*/}
                  {/*  label="Hand types"*/}
                  {/*  value={2}*/}
                  {/*  active={activeTab === 2}*/}
                  {/*  onChange={setActiveTab}*/}
                  {/*/>*/}
                </Tabs>

                <TabPanel key={'openPanel'} value={activeTab} index={0}>
                  {isRobot && !isDealer && (
                    <AlertInline
                      sx="mt-2"
                      text={t('not_dealer_warning')}
                      severity="warning"
                    />
                  )}
                  <DisplayTypes
                    selected={handType}
                    subcategories={openingBids}
                    types={openings}
                    onSelect={setHandType}
                    isRobot={isRobot}
                    robots={robots}
                  />
                </TabPanel>
                {isRobot ? (
                  <TabPanel key={'entryPanel'} value={activeTab} index={1}>
                    {isRobot && !isRhoDealer && (
                      <AlertInline
                        sx="mt-2"
                        text={t('not_second_seat_warning')}
                        severity="warning"
                      />
                    )}
                    <DisplayTypes
                      selected={handType}
                      subcategories={entries}
                      types={entry}
                      onSelect={setHandType}
                      isRobot={isRobot}
                      robots={robots}
                    />
                  </TabPanel>
                ) : (
                  <TabPanel key={'sequencePanel'} value={activeTab} index={1}>
                    <DisplayTypes
                      selected={handType}
                      types={sequences}
                      onSelect={setHandType}
                      isRobot={isRobot}
                      robots={robots}
                    />
                  </TabPanel>
                )}
                {/* <TabPanel key={'handTypesPanel'} value={activeTab} index={2}> */}
                {/*   <DisplayTypes */}
                {/*     selected={handType} */}
                {/*     types={handTypes} */}
                {/*     onSelect={setHandType} */}
                {/*   /> */}
                {/* </TabPanel> */}
              </>
            )}
            {handType && (
              <h2 className={'text-2xl mt-4'}>
                <strong className={'text-success'}>{t('selected')}:</strong>{' '}
                {replaceSuitShorthandWithSymbols(t(`types.${handType.name}.name`))}
              </h2>
            )}

            {handType?.description && <Alert text={replaceSuitShorthandWithSymbols(t(`types.${handType.name}.description`))} />}
            {checkboxes && (
              <div>
                {Object.entries(checkboxes).map((e, i) => {
                  if (e[1])
                    return (
                      <div key={e[0] + i}>
                        {e[1].map((o) => (
                          <Checkbox
                            key={o.id}
                            label={t(`types.${o.id}.name`)}
                            checked={o.isChecked}
                            onChange={() => handleCheckboxChange(e[0], o.id)}
                          />
                        ))}
                      </div>
                    );
                })}
              </div>
            )}
            <div className="flex items-center mt-4 justify-between">
              <button
                className="btn btn-primary"
                onClick={handleAddType}
                disabled={!handType?.name}
              >
                {t('add_new')}
              </button>
              <div className="divider divider-horizontal"> {t('or')}</div>
              <button className="btn btn-secondary" onClick={createCustomType}>
                {t('create_custom')}
              </button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

function DisplayTypes({ selected, subcategories, types, onSelect, isRobot, robots }) {

  const { t } = useTranslation('translation', {
    keyPrefix: 'scenarios.hand_types',
  });

  const [selectedSubcategory, setSelectedSubcategory] = useState(subcategories ? subcategories[0] : null)

  if (!types) {
    return;
  }

  const typelist = Object.entries(types)
    .filter((t) => !!t[1].label)
    .map((t) => t[1])
    .filter(t => !isRobot || (t.robotOptions ?? []).includes(robots));

  return (
    <div>
      {subcategories && (
        <div className={'flex flex-wrap items-center gap-0.5 mt-2'}>
          {subcategories
            .filter(
              (s) => typelist?.filter((r) => r.subcategory === s).length > 0
            )
            .map((s) => (
              <div
                key={s}
                className={`${
                  selectedSubcategory === s
                    ? 'opacity-100'
                    : 'opacity-50 cursor-pointer hover:opacity-100'
                }`}
                onClick={() => setSelectedSubcategory(s)}
              >
                <BidDisplay bid={s} size={35} />
              </div>
            ))}
        </div>
      )}
      <table
        id="handtypes-table"
        className="mt-4 w-full border-collapse rounded-sm md:w-4/5"
      >
        <thead>
          <tr>
            <th>{t('type')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {typelist
            ?.filter(
              (r) =>
                !selectedSubcategory || r.subcategory === selectedSubcategory
            )
            .map((r, i) => {
              return (
                <tr
                  key={r.name}
                  className={`cursor-pointer ${
                    (selected?.name ?? 'x') === r.name ? '!bg-primary' : ''
                  } `}
                >
                  <td onClick={() => onSelect(r)} className={'p-1.5'}>
                    {replaceSuitShorthandWithSymbols(t(`types.${r.name}.name`) ?? t('no_name') + i)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
