import { get, ref, set, goOffline } from 'firebase/database';
import {
  collection,
  doc,
  onSnapshot,
  query,
  where,
  getDoc,
} from 'firebase/firestore';
import { auth, db, realDb } from './firebase';
import { getHighestSubFromUser } from '../util/sub';

function checkEntitlement(rcCustomer, level, subs) {
  if (!rcCustomer.entitlements) {
    return;
  }

  if (rcCustomer.entitlements[level]) {
    const date = new Date(rcCustomer.entitlements[level].expires_date);
    if (date > Date.now() && subs.indexOf(level) === -1) {
      subs.push(level);
    }
  }
}

export function myIAPSubscriptionObservable({ callback }) {
  const q = doc(collection(db, 'RCCustomers'), auth.currentUser.uid);

  return onSnapshot(q, async (snapshot) => {
    if (!snapshot.exists()) {
      callback([]);
      return;
    }
    const subs = [];
    const data = snapshot.data();
    ['club', 'diamond', 'heart'].forEach((l) => {
      checkEntitlement(data, l, subs);
    });

    if (subs.length > 0) {
      callback(subs);
    } else {
      callback([]);
    }

    void updateUserStateIAPSubscriptions({
      ...(subs ?? []),
    });
  });
}

export function mySubscriptionObservable({ callback }) {
  const q = query(
    collection(
      doc(collection(db, 'users'), auth.currentUser.uid),
      'subscriptions',
    ),
    where('status', 'in', ['trialing', 'active']),
  );

  return onSnapshot(q, async (snapshot) => {
    const userSnapshot = await getDoc(
      doc(collection(db, 'users'), auth.currentUser.uid),
    );
    if (!userSnapshot.exists()) {
      callback([]);
      return;
    }

    const user = userSnapshot.data();

    if (!user) {
      callback([]);
      return;
    }

    const subs = snapshot.docs.map((p) => {
      return p.data().role;
    });


    if (user.junior && subs.indexOf('heart') === -1) {
      subs.push('heart');
    }

    if (subs.length > 0) {
      callback(subs);
    } else {
      callback([]);
    }

    const officialSubs = subs.filter((s) => ['club', 'diamond', 'heart'].includes(s));
    const otherSubs = subs.filter((s) => !['club', 'diamond', 'heart'].includes(s));

    await updateUserStateSubscriptions({
      ...(officialSubs ?? []),
    });

    await updateOtherSubscriptions({
      ...(otherSubs ?? []),
    });
  });
}

export async function updateUserStateSubscriptions(user) {
  const uid = auth.currentUser.uid;

  const mySubRef = ref(realDb, `users/${uid}/subscriptions`);

  await set(mySubRef, user);
}

export async function updateOtherSubscriptions(user) {
  const uid = auth.currentUser.uid;

  const mySubRef = ref(realDb, `users/${uid}/otherSubscriptions`);

  await set(mySubRef, user);
}

export async function updateUserStateIAPSubscriptions(user) {
  const uid = auth.currentUser.uid;

  const mySubRef = ref(realDb, `users/${uid}/IAPsubscriptions`);

  await set(mySubRef, user);
}

export async function updateUserStateGroupSubscriptions(user) {
  const uid = auth.currentUser.uid;

  const mySubRef = ref(realDb, `users/${uid}/groupSubscriptions`);

  await set(mySubRef, user);
}


export async function getUserHighestSubscription(uid) {
  const mySubRef = ref(realDb, `users/${uid}`);

  const data = await get(mySubRef);

  return getHighestSubFromUser(data.val() ?? {});
}


export async function checkIfSubscribedToGroup(userId, groupId) {
  const mySubRef = ref(realDb, `users/${userId}/otherSubscriptions`);

  const data = await get(mySubRef);

  return data.val()?.includes(groupId);
}


export function getPublicUser({ uid, callback }) {
  try {
    const userRef = ref(realDb, `users/${uid}`);

    get(userRef)
      .then((u) => {
        callback({ id: uid,
          ...u.val() });
      })
      .catch(() => {
        goOffline(realDb);
      });
  } catch (e) {
    goOffline(realDb);
  }
}

export async function getPublicUserAsync(uid) {
  const userRef = ref(realDb, `users/${uid}`);
  return { ...(await get(userRef)).val(),
    uid };
}

export async function pingOwnUser() {
  const currentUser = auth.currentUser;
  const userRef = ref(realDb, `users/${currentUser.uid}`);

  const snapshot = await get(userRef);
  return snapshot.val();
}

export async function getPublicUserAdmin({ uid }) {
  const userRef = ref(realDb, `users/${uid}`);

  const snapshot = await get(userRef);
  return snapshot.val();
}
