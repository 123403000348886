import { useTranslation } from 'react-i18next'

export default function DealerVulnerabilityDiagram({ vulnerability, dealer }) {
  const { t } = useTranslation();

  return (
    <div className="justify-center items-center flex">
      <div className="relative w-20 h-20 border-slate-500 border rounded-md">
        <div
          className={`absolute top-0 left-1/2 transform -translate-x-1/2 text-xs
                            ${
    dealer === 'N'
      ? 'uppercase font-bold'
      : 'font-medium'
    }
                            ${
    ['ALL', 'NS'].includes(
      vulnerability,
    )
      ? 'text-rose-500'
      : 'text-emerald-500'
    }
                            `}
        >
          {t('bridge.directions.N')}
        </div>

        {dealer === 'N' && (
          <div
            className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full">
            <div
              className="border-t-4 border-l-4 border-r-4 border-gray-600 w-0 h-0"></div>
          </div>
        )}

        <div
          className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 text-xs
                        ${
    dealer === 'S'
      ? 'uppercase font-bold'
      : 'font-medium'
    }
                        ${
    ['ALL', 'NS'].includes(
      vulnerability,
    )
      ? 'text-rose-500'
      : 'text-emerald-500'
    }
                        `}
        >
          {t('bridge.directions.S')}
        </div>

        {dealer === 'S' && (
          <div
            className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full">
            <div
              className="border-b-4 border-l-4 border-r-4 border-gray-600 w-0 h-0"></div>
          </div>
        )}

        <div
          className={`absolute right-0 top-1/2 transform -translate-y-1/2 rotate-90 text-xs
                        ${
    dealer === 'E'
      ? 'uppercase font-bold -mr-1'
      : 'font-medium'
    }
                        ${
    ['ALL', 'EW'].includes(
      vulnerability,
    )
      ? 'text-rose-500'
      : 'text-emerald-500'
    }
                        `}
        >
          {t('bridge.directions.E')}
        </div>

        {dealer === 'E' && (
          <div
            className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-full">
            <div
              className="border-b-4 border-t-4 border-r-4 border-gray-600 w-0 h-0"></div>
          </div>
        )}

        <div
          className={`absolute left-0 top-1/2 transform -translate-y-1/2 -rotate-90 text-xs
                        ${
    dealer === 'W'
      ? 'uppercase font-bold -ml-1'
      : 'font-medium'
    }
                        ${
    ['ALL', 'EW'].includes(
      vulnerability,
    )
      ? 'text-rose-500'
      : 'text-emerald-500'
    }
                        `}
        >
          {t('bridge.directions.W')}
        </div>

        {dealer === 'W' && (
          <div
            className="absolute left-0 top-1/2 transform -translate-y-1/2 -ml-1">
            <div
              className="border-b-4 border-t-4 border-l-4 border-gray-600 w-0 h-0"></div>
          </div>
        )}
      </div>
    </div>
  );
}
