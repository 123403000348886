import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import algoliasearch from 'algoliasearch'
import {
  getLatestChallengeHistoryOpponents,
  getLatestChallengeHistoryPartnership,
  getTopChallengeOpponents,
  getTeamFromId,
  getTeamId,
  getTopChallengePartnership,
  getChallengeStatisticsPartnership,
  getPartnershipTopChallengeOpponents,
  getChallengeStatistics,
  getLatestChallengeHistoryUser,
} from 'cuebids-firebase/challenge'
import ChallengeStatistic from './ChallengeStatistic'
import ChallengeHistory from './ChallengeHistory'
import ChallengeStatisticPartnership from './ChallengeStatisticPartnership'
import ChallengeStatisticUser from './ChallengeStatisticUser'
import { SelectFriendDropdown, SelectPartnershipDropdown, SelectUserDropdown } from '../../../components/dropdown/dropdown'
import { getUserPublic } from '../../../firebase/userApi'
import { Tab, TabPanel, Tabs } from '../../../components/tabs/tabs'
import UserDisplay, { LinkedUserDisplay, UserDisplayInstantNameFlag } from '../../../components/users/userDisplay'
import { resToObjects } from '../../../util/algolia';
import TextField from '../../../components/textField/TextField';
import Alert from '../../../components/alert/alert';
import { useAppStateStore } from '../../../appStateStore';
import { useTranslation } from 'react-i18next'

const algoliaSearchApiKey = 'bbc7a654026166c01044ca839e5072f8';
const algoliaAppId = 'JVCVRW8KT6';

const algoliaClient = algoliasearch(algoliaAppId, algoliaSearchApiKey);

const algoliaIndexName =
  import.meta.env.VITE_INSTANCE_NAME === 'dev' ? 'dev_USERS' : 'prod_USERS';

const algoliaIndex = algoliaClient.initIndex(algoliaIndexName);

function PlayerTab({
  userId,
  setUserId,
  setPair,
  user,
  challengeTopPartnership,
  challengeTopMatchup,
  userHistory,
  t,
}) {
  return (
    <>
      {user && <ChallengeStatisticUser user={user} />}
      {challengeTopPartnership && (
        <ChallengeStatisticPartnership
          sx="mt-4"
          challengeStatisticPartnership={challengeTopPartnership}
          firstUserId={userId}
          onUserClick={setUserId}
          header={t('most_frequent_partner')}
        />
      )}
      {challengeTopMatchup && (
        <ChallengeStatistic
          sx="mt-4"
          challengeStatistic={challengeTopMatchup}
          firstUserId={userId}
          onPairClick={setPair}
          header={t('most_frequent_matchup')}
        />
      )}
      <div className="mt-6" />
      {userHistory && userHistory.length > 0 && (
        <ChallengeHistory challengeHistory={userHistory} firstUserId={userId} />
      )}
    </>
  );
}

function PartnerTab({
  userId,
  setUserId,
  partnerId,
  setPartnerId,
  setPair,
  partners,
  challengeStatisticsPartnership,
  partnershipTopOpponents,
  partnershipHistory,
  t,
}) {
  return (
    <>
      <SelectUserDropdown
        label={t('partner')}
        value={partnerId}
        onChange={setPartnerId}
        users={partners}
      />
      {challengeStatisticsPartnership && (
        <ChallengeStatisticPartnership
          sx="mt-4"
          challengeStatisticPartnership={challengeStatisticsPartnership}
          firstUserId={userId}
          onUserClick={setUserId}
          header={t('partnership_stats')}
        />
      )}
      {partnershipTopOpponents && (
        <ChallengeStatistic
          sx="mt-4"
          challengeStatistic={partnershipTopOpponents}
          firstUserId={userId}
          onPairClick={setPair}
          header={t('most_frequent_opponents')}
        />
      )}
      {!challengeStatisticsPartnership && !partnershipTopOpponents && <div className="h-44" />}
      <div className="mt-6" />
      {partnershipHistory && partnershipHistory.length > 0 && (
        <ChallengeHistory challengeHistory={partnershipHistory} firstUserId={userId} />
      )}
    </>
  );
}

function MatchupTab({
  userId,
  partnerId,
  setPartnerId,
  setPair,
  opponentTeamId,
  setOpponentTeamId,
  partners,
  opponents,
  challengeStatistics,
  opponentHistory,
  t,
}) {
  return (
    <>
      <SelectUserDropdown
        label={t('partner')}
        value={partnerId}
        onChange={setPartnerId}
        users={partners}
      />
      {opponents.length > 0 && (
        <SelectPartnershipDropdown
          label={t('opponents')}
          value={opponentTeamId}
          onChange={setOpponentTeamId}
          partnerships={opponents}
        />
      )}
      {challengeStatistics ? (
        <ChallengeStatistic
          sx="mt-4"
          challengeStatistic={challengeStatistics}
          firstUserId={userId}
          onPairClick={setPair}
        />
      ) : (
        <div className="h-44" />
      )}
      <div className="mt-6" />
      {opponentHistory && opponentHistory.length > 0 && (
        <ChallengeHistory challengeHistory={opponentHistory} firstUserId={userId} />
      )}
    </>
  );
}

function SearchUserTab({
  setUserId,
  t,
}) {
  const friends = useAppStateStore((state) => state.friends);
  const [searchString, setSearchString] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  const searchUsers = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);
    setSearchResults(null);
    const res = await algoliaIndex.search(searchString);
    setSearchResults(resToObjects(res));
    setLoadingSearch(false);
  };

  return (
    <>
      <SelectFriendDropdown
        value={null}
        onChange={setUserId}
        friends={friends}
      />
      <div className="divider">{t('or')}</div>
      <div>
        <form className="w-80" onSubmit={searchUsers}>
          <TextField
            value={searchString}
            label={t('search_by_name_label')}
            placeholder={t('search_by_name_placeholder')}
            onChange={setSearchString}
          />
          <button
            type="submit"
            disabled={searchString.length < 3 || loadingSearch}
            className="btn btn-primary mt-4 w-full"
          >
            {t('search_by_name_submit')}
          </button>
        </form>
        {searchResults &&
          (searchResults.length > 0 ? (
            <table className="mt-4 w-full border-collapse rounded-sm">
              <thead>
                <tr>
                  <th>{t('search_by_name_results', {count: searchResults.length})}</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((u) => {
                  const uid = u.id;
                  const flag = u.flag;
                  const showFlag = u.showFlag;
                  const displayName = u.displayName;

                  return (
                    <tr key={uid} className="fade-in-slow">
                      <td className="p-2">
                        <UserDisplayInstantNameFlag
                          key={uid}
                          flag={flag}
                          showFlag={showFlag}
                          name={displayName}
                        />
                      </td>
                      <td>
                        <div className="flex justify-end">
                          <button
                            className="btn btn-secondary btn-xs h-8"
                            onClick={function () {
                              setUserId(uid);
                            }}
                          >
                            {t('select')}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <Alert text={t('search_by_name_empty')} severity="warning" />
          ))}
      </div>
    </>
  );
}

export default function UserChallengeStats({
  activeTab,
  setActiveTab,
  currentUserId,
  userId,
  setUserId,
  partnerId,
  setPartnerId,
  setPair,
  opponentTeamId,
  setOpponentTeamId,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'challenge' });

  // Before a partner is selected
  const { data: user } = useQuery({
    queryKey: ['getUserPublic', userId],
    queryFn: () => getUserPublic(userId),
  });
  const partners = user?.challengeStatistics?.partners ?? [];
  const { data: challengeTopMatchup } = useQuery({
    queryKey: ['getTopChallengeOpponents', userId],
    queryFn: async () => getTopChallengeOpponents(userId),
  });
  const { data: challengeTopPartnership } = useQuery({
    queryKey: ['getTopChallengePartnership', userId],
    queryFn: async () => getTopChallengePartnership(userId),
  });
  const { data: userHistory } = useQuery({
    queryKey: ['getLatestChallengeHistoryUser', userId],
    queryFn: async () => {
      return getLatestChallengeHistoryUser(userId, 5);
    },
  });

  // When a partner is selected
  const team = partnerId && [userId, partnerId];
  const teamId = team && getTeamId(team);
  const { data: challengeStatisticsPartnership } = useQuery({
    queryKey: ['getChallengeStatisticsPartnership', teamId],
    queryFn: async () => {
      if (!teamId) return null;

      return getChallengeStatisticsPartnership(team);
    },
  });
  const { data: partnershipHistory } = useQuery({
    queryKey: ['getLatestChallengeHistoryPartnership', teamId],
    queryFn: async () => {
      if (!teamId) return null;

      return getLatestChallengeHistoryPartnership(team, 5)
    },
  });
  const { data: partnershipTopOpponents } = useQuery({
    queryKey: ['getPartnershipTopChallengeOpponents', teamId],
    queryFn: async () => {
      if (!teamId) return null;

      return getPartnershipTopChallengeOpponents(team);
    },
  });
  const opponents = challengeStatisticsPartnership?.opponents ?? [];

  // When an opponent is selected
  const { data: opponentHistory } = useQuery({
    queryKey: ['getLatestChallengeHistoryOpponents', teamId, opponentTeamId],
    queryFn: async () => {
      if (!opponentTeamId) return null;

      return getLatestChallengeHistoryOpponents(team, getTeamFromId(opponentTeamId), 5);
    },
  });
  const { data: challengeStatistics } = useQuery({
    queryKey: ['getChallengeStatistics', teamId, opponentTeamId],
    queryFn: async () => {
      if (!opponentTeamId) return null;

      return getChallengeStatistics(team, getTeamFromId(opponentTeamId));
    },
  });

  const isMe = userId === currentUserId;

  return (
    <div className="fade-in-slow">
      <div className="flex justify-center my-4">
        {isMe ? (
          <UserDisplay uid={userId} />
        ): (
          <>
            <LinkedUserDisplay uid={userId} />
            {userId !== currentUserId && <button className='btn btn-error btn-sm h-0.5 w-1 text-xs ml-4' onClick={() => setUserId(currentUserId)}>x</button>}
          </>
        )}
      </div>
      <Tabs>
        <Tab
          key={'player'}
          label={t('tab_player_stats')}
          value="player"
          active={activeTab === 'player'}
          onChange={setActiveTab}
        />
        <Tab
          key={'partner'}
          label={t('tab_partner')}
          value="partner"
          active={activeTab === 'partner'}
          onChange={setActiveTab}
        />
        <Tab
          key={'matchup'}
          label={t('tab_matchup')}
          value="matchup"
          active={activeTab === 'matchup'}
          onChange={setActiveTab}
        />
        <Tab
          key={'search'}
          label={t('tab_search_player')}
          value="search"
          active={activeTab === 'search'}
          onChange={setActiveTab}
        />
      </Tabs>
      <div className="mt-4" />
      <TabPanel key={'playerPanel'} value={activeTab} index={'player'}>
        <PlayerTab
          userId={userId}
          setUserId={setUserId}
          setPair={setPair}
          user={user}
          challengeTopPartnership={challengeTopPartnership}
          challengeTopMatchup={challengeTopMatchup}
          userHistory={userHistory}
          t={t}
        />
      </TabPanel>
      <TabPanel key={'partnerPanel'} value={activeTab} index={'partner'}>
        <PartnerTab
          userId={userId}
          setUserId={setUserId}
          partnerId={partnerId}
          setPartnerId={setPartnerId}
          setPair={setPair}
          partners={partners}
          challengeStatisticsPartnership={challengeStatisticsPartnership}
          partnershipTopOpponents={partnershipTopOpponents}
          partnershipHistory={partnershipHistory}
          t={t}
        />
      </TabPanel>
      <TabPanel key={'matchupPanel'} value={activeTab} index={'matchup'}>
        <MatchupTab
          userId={userId}
          partnerId={partnerId}
          setPartnerId={setPartnerId}
          setPair={setPair}
          opponentTeamId={opponentTeamId}
          setOpponentTeamId={setOpponentTeamId}
          partners={partners}
          opponents={opponents}
          challengeStatistics={challengeStatistics}
          opponentHistory={opponentHistory}
          t={t}
        />
      </TabPanel>
      <TabPanel key={'searchPanel'} value={activeTab} index={'search'}>
        <SearchUserTab setUserId={setUserId} t={t} />
      </TabPanel>
    </div>
  );
}
