const entryCommand = [
  {
    premise: 'Over1C',
    biddingMatch: /^\.?(-\.)*(-P)*-1C$/,
    explain: 'Over opening 1C',
    options: [
      {
        bid: '1D["5+!D"]',
        reqCommand: true,
      },
      {
        bid: '2D["Weak, 6+!D"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over1CD',
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]$/,
    explain: 'Over opening 1CD',
    options: [
      {
        bid: '1H["5+!H"]',
        reqCommand: true,
      },
      {
        bid: '2H["Weak, 6+!H"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over1CDH',
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDH]$/,
    explain: 'Over opening 1CDH',
    options: [
      {
        bid: '1S["5+!S"]',
        reqCommand: true,
      },
      {
        bid: '2S["Weak, 6+!S"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over1CDHS',
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDHS]$/,
    explain: 'Over opening 1CDHS',
    options: [
      {
        bid: '2C["5+!C"]',
        reqCommand: true,
      },
      // TODO: (This does not exist in system and might need some continuations)
      // {
      //   bid: '1N["15-18"]',
      //   reqCommand: true,
      // },
    ],
  },
  {
    premise: 'Over1CDHS',
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDHS]$/,
    explain: 'Over opening 1CDHS',
    options: [
      {
        bid: '1N["Balanced, 15—18"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over1DHS2C',
    biddingMatch: /^\.?(-\.)*(-P)*-(1[DHS]|2C)$/,
    explain: 'Over opening 1DHS and 2C',
    options: [
      {
        bid: '2D["5+!D"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over1HS2CD',
    biddingMatch: /^\.?(-\.)*(-P)*-(1[HS]|2[CD])$/,
    explain: 'Over opening 1HS and 2CD',
    options: [
      {
        bid: '2H["5+!H"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over1S2CDH',
    biddingMatch: /^\.?(-\.)*(-P)*-(1S|2[CDH])$/,
    explain: 'Over opening 1S and 2CDH',
    options: [
      {
        bid: '2S["5+!S"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over2CDHS',
    biddingMatch: /^\.?(-\.)*(-P)*-2[CDHS]$/,
    explain: 'Over opening 2CDHS',
    options: [
      {
        bid: '3C["5+!C"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over2DHS3C',
    biddingMatch: /^\.?(-\.)*(-P)*-(2[DHS]|3C)$/,
    explain: 'Over opening 2DHS and 3C',
    options: [
      {
        bid: '3D["5+!D"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over2HS3CD',
    biddingMatch: /^\.?(-\.)*(-P)*-(2[HS]|3[CD])$/,
    explain: 'Over opening 2HS and 3CD',
    options: [
      {
        bid: '3H["5+!H"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over2S3CDH',
    biddingMatch: /^\.?(-\.)*(-P)*-(2S|3[CDH])$/,
    explain: 'Over opening 2S and 3CDH',
    options: [
      {
        bid: '3S["5+!S"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over1CDHSN',
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDHSN]$/,
    explain: 'Over opening 1CDHSN',
    options: [
      {
        // TODO: In NT defence this is explained as "6+!C, wide-range"
        bid: '3C["Weak, 6+!C"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over1CDHSN2C',
    biddingMatch: /^\.?(-\.)*(-P)*-(1[CDHSN]|2C)$/,
    explain: 'Over opening 1CDHSN and 2C',
    options: [
      {
        // TODO: In NT defence this is explained as "6+!D, wide-range"
        bid: '3D["Weak, 6+!D"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over1CDHSN2CD',
    biddingMatch: /^\.?(-\.)*(-P)*-(1[CDHSN]|2[CD])$/,
    explain: 'Over opening 1CDHSN and 2CD',
    options: [
      {
        bid: '3H["Weak, 6+!H"]',
        reqCommand: true,
      },
    ],
  },
  {
    premise: 'Over1CDHSN2CDH',
    biddingMatch: /^\.?(-\.)*(-P)*-(1[CDHSN]|2[CDH])$/,
    explain: 'Over opening 1CDHSN and 2CDH',
    options: [
      {
        bid: '3S["Weak, 6+!S"]',
        reqCommand: true,
      },
    ],
  },
];

export default entryCommand;
