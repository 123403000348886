import Constraints from '../../components/constraints/constraints';
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getScenario } from 'cuebids-firebase/dealGeneration'

export default function CreateDealType() {
  const { id } = useParams();

  const { isFetchedAfterMount, data: scenario } = useQuery({
    queryKey: ['scenario', id],
    queryFn: async () => await getScenario(id),
  });

  return (
    <div className="page pb-24">
      <div className='w-full flex items-center justify-center'>
        {!isFetchedAfterMount && <span className="loading loading-lg"></span>}
        {isFetchedAfterMount && scenario && (
          <Constraints
            id={scenario.id}
            parentId={scenario.parentId}
            parentCreatorUid={scenario.parentCreatorUid}
            description={scenario.description}
            name={scenario.name}
            north={scenario.directions.north}
            west={scenario.directions.west}
            east={scenario.directions.east}
            south={scenario.directions.south}
            settings={scenario.directions.settings}
          />
        )}
      </div>
    </div>
  );
}
