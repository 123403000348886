import {
  getContractLevelAndStrain,
  isContractDoubled,
  isContractRedoubled,
} from 'cuebids-bidding-util';
import C1 from './bids/1C.png';
import C2 from './bids/2C.png';
import C3 from './bids/3C.png';
import C4 from './bids/4C.png';
import C5 from './bids/5C.png';
import C6 from './bids/6C.png';
import C7 from './bids/7C.png';
import D1 from './bids/1D.png';
import D2 from './bids/2D.png';
import D3 from './bids/3D.png';
import D4 from './bids/4D.png';
import D5 from './bids/5D.png';
import D6 from './bids/6D.png';
import D7 from './bids/7D.png';
import H1 from './bids/1H.png';
import H2 from './bids/2H.png';
import H3 from './bids/3H.png';
import H4 from './bids/4H.png';
import H5 from './bids/5H.png';
import H6 from './bids/6H.png';
import H7 from './bids/7H.png';
import S1 from './bids/1S.png';
import S2 from './bids/2S.png';
import S3 from './bids/3S.png';
import S4 from './bids/4S.png';
import S5 from './bids/5S.png';
import S6 from './bids/6S.png';
import S7 from './bids/7S.png';
import N1 from './bids/1N.png';
import N2 from './bids/2N.png';
import N3 from './bids/3N.png';
import N4 from './bids/4N.png';
import N5 from './bids/5N.png';
import N6 from './bids/6N.png';
import N7 from './bids/7N.png';
import d from './bids/D.png';
import ps from './bids/P.png';
import r from './bids/R.png';

const preloads = {
  C1, C2, C3, C4, C5, C6, C7,
  D1, D2, D3, D4, D5, D6, D7,
  H1, H2, H3, H4, H5, H6, H7,
  S1, S2, S3, S4, S5, S6, S7,
  N1, N2, N3, N4, N5, N6, N7,
  d, ps, r
}

export function ContractDisplay({ contract, size = 70 }) {
  const con = getContractLevelAndStrain(contract);

  const d = isContractDoubled(contract);
  const re = isContractRedoubled(contract);

  return (
    <BidDisplay bid={con} size={size} doubled={re ? 'XX' : d ? 'X' : false} />
  );
}

export function BidsPreload() {
  return Object.keys(preloads).map((p) => {
    return <img key={p} src={preloads[p]} alt={'preload'}/>
  })
}

export default function BidDisplay({ bid, size = 70, doubled = '' }) {
  if(!bid) {
    return;
  }
  bid = bid.length === 1 ? bid.toLowerCase() : bid[1] + bid[0];
  if (bid === 'p') {
    bid = 'ps';
  }

  if (doubled === 'X') {
    doubled = 'D';
  }

  if (doubled === 'XX') {
    doubled = 'R';
  }

  return (
    <div
      style={{ height: size, width: size, position: 'relative' }}
      key={bid}
    >
      <img src={preloads[bid]} alt={bid}/>
      {doubled && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        >
          <BidDisplay bid={doubled} size={size / 2} />
        </div>
      )}
    </div>
  );
}
