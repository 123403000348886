import { useState } from 'react';
import {
  addMemberToGroup,
  addMemberToGroupViaFriendKey,
  inviteMemberToGroup,
  removeMemberInviteFromGroup, updateGroupSettings,
} from '../../firebase/groups';
import { SelectFriendDropdown } from '../../components/dropdown/dropdown';
import { useParams } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import { UserDisplayInstantNameFlag } from '../../components/users/userDisplay';
import Collapse from '../../components/collapse/Collapse';
import TextField from '../../components/textField/TextField';
import Alert from '../../components/alert/alert';
import GroupHeader from '../../components/groupHeader/GroupHeader';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useGoToPublicGroupPage } from '../../util/hooks';
import { resToObjects } from '../../util/algolia.js'
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

const algoliaSearchApiKey = 'bbc7a654026166c01044ca839e5072f8';
const algoliaAppId = 'JVCVRW8KT6';

const algoliaClient = algoliasearch(algoliaAppId, algoliaSearchApiKey);

const algoliaIndexName =
  import.meta.env.VITE_INSTANCE_NAME === 'dev' ? 'dev_USERS' : 'prod_USERS';

const algoliaIndex = algoliaClient.initIndex(algoliaIndexName);

function SearchUsers({
  groupId,
  groupName,
  loading,
  setLoading,
  members,
  invited,
  t,
}) {
  const [searchString, setSearchString] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  const notify = useNotifications();

  useGoToPublicGroupPage(groupId);

  const searchUsers = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);
    setSearchResults(null);
    const res = await algoliaIndex.search(searchString);
    setSearchResults(resToObjects(res));
    setLoadingSearch(false);
  };

  const inviteMember = async (uid) => {
    setLoading(true);
    const result = await inviteMemberToGroup({
      userId: uid,
      groupId,
      groupName,
    });
    setLoading(false);
    if (result?.alreadyMember) {
      notify(
        { type: 'warning', text: t('already_member_error') },
      );
      return;
    }

    if (result?.alreadyInvited) {
      notify(
        { type: 'warning', text: t('already_invited_error') },
      );
      return;
    }

    if (!result) {
      notify(
        {
          text: t('invite_error'),
          type: 'failure',
        },
      );
      return;
    }

    if (result?.isNew) {
      notify({ type: 'success', text: t('invite_success') });
    }
  };

  const uninviteMember = async (uid) => {
    setLoading(true);
    await removeMemberInviteFromGroup({
      groupId,
      userId: uid,
    });
    setLoading(false);
  };

  return (
    <div>
      <form className="w-80" onSubmit={searchUsers}>
        <TextField
          value={searchString}
          label={t('user_name_label')}
          placeholder={t('user_name_placeholder')}
          onChange={setSearchString}
        />
        <button
          type="submit"
          disabled={searchString.length < 3 || loading || loadingSearch}
          className="btn btn-primary mt-4 w-full"
        >
          {t('user_name_search')}
        </button>
      </form>
      {searchResults &&
        (searchResults.length > 0 ? (
          <table className="mt-4 w-full border-collapse rounded-sm">
            <thead>
              <tr>
                <th>{t('user_name_search_results', {count: searchResults.length})}</th>
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(odd)]:bg-base-100">
              {searchResults.map((u) => {
                const uid = u.id;
                const flag = u.flag;
                const showFlag = u.showFlag;
                const displayName = u.displayName;
                const alreadyMember = members.includes(uid);
                const alreadyInvited = invited.includes(uid);

                return (
                  <tr key={uid} className="fade-in-slow">
                    <td className="p-2">
                      <UserDisplayInstantNameFlag
                        key={uid}
                        flag={flag}
                        showFlag={showFlag}
                        name={displayName}
                      />
                    </td>
                    <td>
                      <div className="flex justify-end">
                        {alreadyMember ? (
                          <div className="badge badge-accent badge-md ml-4">
                            {t('already_member')}
                          </div>
                        ) : alreadyInvited ? (
                          <button
                            disabled={loading}
                            className="btn btn-error btn-xs"
                            onClick={function () {
                              void uninviteMember(uid);
                            }}
                          >
                            {t('remove_invite')}
                          </button>
                        ) : (
                          <button
                            disabled={loading}
                            className="btn btn-secondary btn-xs"
                            onClick={function () {
                              void inviteMember(uid);
                            }}
                          >
                            {t('invite_member')}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <Alert text={t('search_empty')} severity="warning" />
        ))}
    </div>
  );
}

function AddFriends({ groupId, groupName, loading, setLoading, members, t }) {
  const friends = useAppStateStore((state) => state.friends);
  const notify = useNotifications();
  const [friendId, setFriendId] = useState('');

  const addMember = async () => {
    setLoading(true);
    const result = await addMemberToGroup({
      userId: friendId,
      groupId,
      groupName,
    });
    setLoading(false);
    if (result?.alreadyMember) {
      notify(
        { type: 'warning', text: t('already_member_error') },
      );
      return;
    }

    if (!result) {
      notify(
        {
          text: t('add_error'),
          type: 'failure',
        },
      );
      return;
    }

    if (result?.isNew) {
      notify({ type: 'success', text: t('add_success') });
    }
  };

  return (
    <div
      className="h-72" // Add height to fit friend dropdown, since collapse won't allow overflow
    >
      <SelectFriendDropdown
        value={friendId}
        onChange={setFriendId}
        label={t('select_friend_label')}
        friends={friends}
        disableFriendIds={members}
      />
      <button
        disabled={loading}
        onClick={addMember}
        className="btn btn-primary mt-4"
      >
        {t('add_friend_submit')}
      </button>
    </div>
  );
}

async function addFriendByFriendKeyLocal(
  groupId,
  groupName,
  friendKey,
  notify,
  setLoading,
  setFriendKey,
  t,
) {
  const newFriend = await addMemberToGroupViaFriendKey({
    groupId,
    friendKey,
    groupName,
  });

  if (newFriend?.disableFriendKey) {
    notify(
      {
        text: t('friend_key_disabled_error'),
        type: 'failure',
      },
    );
    return;
  }

  if (newFriend?.alreadyMember) {
    notify(
      {
        text: t('already_member_error'),
        type: 'warning',
      },
    );
    return;
  }

  if (newFriend?.noSuchFriendKey) {
    notify(
      {
        text: t('no_such_friend_key_error'),
        type: 'failure',
      },
    );
    return;
  }

  if (!newFriend) {
    notify(
      {
        text: t('add_error'),
        type: 'failure',
      },
    );
    return;
  }

  setFriendKey('');
  if (newFriend.isNew) {
    notify(
      { text: t('friend_key_add_success', {displayName: newFriend.displayName}), type: 'success' },
    );
  }
}

function AddViaFriendKey({ groupId, loading, setLoading, groupName, t }) {
  const [friendKey, setFriendKey] = useState('');

  const notify = useNotifications();

  const handleAddFriend = async (e) => {
    e.preventDefault();
    setLoading(true);
    await addFriendByFriendKeyLocal(
      groupId,
      groupName,
      friendKey,
      notify,
      setLoading,
      setFriendKey,
      t
    );
    setLoading(false);
  };

  const handleUpdateFriendKey = (v) => {
    setFriendKey(v);
  };

  return (
    <form className="w-40" onSubmit={handleAddFriend}>
      <TextField
        value={friendKey}
        label={t('friend_key_label')}
        placeholder={t('friend_key_placeholder')}
        onChange={handleUpdateFriendKey}
      />
      <button
        type="submit"
        disabled={!(friendKey.length === 6) || loading}
        className="btn btn-primary mt-4 w-full"
      >
        {t('friend_key_submit')}
      </button>
    </form>
  );
}

export default function AddMembersToGroup() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.add_members' });
  const { groupId } = useParams();
  const [loading, setLoading] = useState(false);
  const groups = useAppStateStore((state) => state.groups);
  const notify = useNotifications();

  useGoToPublicGroupPage(groupId);

  const group = (groups || []).find(function (group) {
    return group.id === groupId;
  });

  const [allowLinks, setAllowLinks] = useState(group?.openGroup || false);


  const link = `https://cuebids.com/joinGroup/${groupId}`;

  const handleCopyGroupLink = async () => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(link);
    } else {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ message: 'copyUrl', url: link })
      );
    }
    notify(
      {
        text: t('link_copied'),
        type: 'success',
      },
    );
  };

  const updateAllowLinks = async (e) => {
    setAllowLinks(e.target.checked);
    await updateGroupSettings({
      groupId,
      settings: {
        openGroup: e.target.checked
      }
    }
    );
  }

  if (!group) {
    return null;
  }

  return (
    <div className="page pb-24">
      <GroupHeader name={group.name} links groupId={groupId} groupFlag={group.groupFlag} logo={group.logo} />
      <div className="text-2xl mb-4">{t('title')}</div>
      <Collapse title={t('using_link_title')}>
        <div className="my-2 flex w-3/4 flex-row items-center justify-between md:w-2/3">
          <label className="label w-full cursor-pointer p-0 font-bold">
            {t('link_invites_label')}
            <input
              type="checkbox"
              className="toggle-primary toggle"
              checked={allowLinks}
              onChange={updateAllowLinks}
            />
          </label>
        </div>
        {
          allowLinks ? <><span className="info">{t('copy_link_info')}</span>

            <p onClick={handleCopyGroupLink} className={'cursor-pointer underline underline-offset-1 decoration-primary text-lg decoration-2 m-2'}>{link}</p>

            <button
              className="btn-primary btn-sm btn"
              onClick={handleCopyGroupLink}
            >
              {t('copy_link')}
            </button></> :
            <>
              <span className="info">{t('closed_group_info')}</span>
            </>
        }


      </Collapse>
      <Collapse title={t('search_users_title')}>
        <SearchUsers
          groupId={groupId}
          groupName={group.name}
          loading={loading}
          setLoading={setLoading}
          members={group.members}
          invited={group.invited}
          t={t}
        />
      </Collapse>
      <Collapse title={t('add_friends_title')}>
        <AddFriends
          groupId={groupId}
          groupName={group.name}
          loading={loading}
          setLoading={setLoading}
          members={group.members}
          t={t}
        />
      </Collapse>
      <Collapse title={t('friend_key_title')}>
        <AddViaFriendKey
          groupId={groupId}
          groupName={group.name}
          loading={loading}
          setLoading={setLoading}
          t={t}
        />
      </Collapse>
    </div>
  );
}
