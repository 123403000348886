import { useEffect, useState } from 'react';
import Alert from '../alert/alert';
import { useTranslation } from 'react-i18next';

const getDiffFormat = (difference) => {
  if (difference <= 0) return {};
  return {
    d: Math.floor(difference / (1000 * 60 * 60 * 24)),
    h: Math.floor((difference / (1000 * 60 * 60)) % 24),
    m: Math.floor((difference / 1000 / 60) % 60),
    s: Math.floor((difference / 1000) % 60),
  };
};

const calculateTimeLeft = (endDate) => {
  let scoredDate = new Date(endDate);
  if (scoredDate.getMinutes() !== 0) {
    scoredDate.setHours(scoredDate.getHours() + 1);
    scoredDate.setMinutes(0);
    scoredDate.setSeconds(0);
  }

  scoredDate = scoredDate.getTime();
  const now = +new Date();

  return getDiffFormat(scoredDate - now);
};

export default function GroupSessionTimer({ endDate }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.event_timer' });

  useEffect(
    function () {
      const intervalId = setInterval(function () {
        setTimeLeft(calculateTimeLeft(endDate));
      }, 1000);
      return function () {
        clearInterval(intervalId);
      };
    },
    [endDate]
  );

  const timerComponents = [];

  try {
    Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }

      timerComponents.push(
        <span key={interval}>
          {timeLeft[interval]}
          {t(interval)}{' '}
        </span>
      );
    });
  } catch (e) {
    //
  }

  return (
    <Alert
      sx={'w-75'}
      text={
        <div>
          {timerComponents.length ? (
            <>
              <span>{t('scored_in')}</span>
              {timerComponents.length > 0 && timerComponents}
            </>
          ) : (
            <span>{t('scoring')}</span>
          )}
        </div>
      }
    />
  );
}
