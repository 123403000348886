export const deals = [
  {
    hand: 'KJ98.AQ6.K5.QJ43',
    direction: 'S',
    biddingSteps: [
      '.-P-P',
      '.-P-P-1N-P-2N-P',
    ],
    partner: 'A5.JT9.JT96.K875',
    best: '3N',
    scoring: {
      '3N': { numberOfStars: 3, ev: 321, contract: '3NS' },
      'P': { numberOfStars: 1, ev: 123, contract: '2NS' }
    }
  },
  {
    hand: 'KJ98.AQ6.K5.QJ43',
    direction: 'S',
    biddingSteps: [
      '.-P-P',
      '.-P-P-1N-P-2N-P',
    ],
    partner: 'A5.JT9.JT96.K875',
    best: '3N',
    scoring: {
      '3N': { numberOfStars: 3, ev: 321, contract: '3NS' },
      'P': { numberOfStars: 1, ev: 123, contract: '2NS' }
    }
  }
]