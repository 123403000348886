import { getRobotParContract } from '../util/scoreUtil';
import {
  disableLogic,
  getHighestRobotBid,
  isBiddingFinished,
  isBidHigher,
  isValidBid,
  parseBidding
} from 'cuebids-bidding-util'

const suitMap = {
  N: 5,
  S: 4,
  H: 3,
  D: 2,
  C: 1,
};

export function getDoubledParContractAtLeastCurrentContract({
  scores,
  currentContract,
}) {
  let par = currentContract;
  let parEv = scores[currentContract];
  const level = currentContract[0];
  const suit = currentContract[1];
  const suitLevel = suitMap[suit];

  Object.keys(scores).forEach((s) => {
    if (s.length !== 4) return;
    if (!['N', 'S'].includes(s.slice(-1))) return;
    if (!(s[0] > level || (s[0] === level && suitMap[s[1]] > suitLevel))) {
      return;
    }
    if (scores[s] < parEv) return;

    parEv = scores[s];
    par = s;
  });

  return par;
}

function shouldWeDouble(scores, bidding, vulnerability, dealVersion) {
  if (dealVersion !== 2 && dealVersion !== 3) return false;

  const { finalBid, declarer, doubled } = parseBidding(bidding, dealVersion);

  if (['E', 'W'].includes(declarer)) {
    return false;
  }

  if (doubled) {
    return false;
  }

  const scoreUndoubled = scores[finalBid + declarer];
  const scoreDoubled = scores[finalBid + 'X' + declarer];

  if (scoreDoubled > scoreUndoubled) {
    return false;
  }

  const robotParContract = getRobotParContract({ scores });
  const robotMaxBid = getHighestRobotBid(bidding);

  const playersVulnerable = ['ALL', 'NS'].includes(vulnerability);

  let threshold = playersVulnerable ? -400 : -250;
  if (robotParContract.parEv < 100 || robotMaxBid === 'P') {
    threshold = playersVulnerable ? -750 : -500;
  }

  if (scoreDoubled > threshold) {
    return false;
  }

  const playerParContractAboveCurrentBid =
    getDoubledParContractAtLeastCurrentContract({
      scores,
      currentContract: finalBid + 'X' + declarer,
    });

  if (scores[playerParContractAboveCurrentBid] > threshold) {
    return false;
  }

  return true;
}

export function willRobotsDouble({ deal, bidding, compete }) {
  if (!compete) {
    return false;
  }

  // Can't double finished bidding
  if (isBiddingFinished(bidding)) {
    return false;
  }

  // Don't double if not in last seat (passout)
  if (!isBiddingFinished(`${bidding}-P`)) {
    return false;
  }

  // Can't double if illegal
  if (!isValidBid(bidding, 'D')) {
    return false;
  }

  // Don't double contracts under 3C (could be interpreted as take-out by partner)
  const { disableBelow } = disableLogic(bidding);
  if (disableBelow && isBidHigher('3C', disableBelow)) {
    return false;
  }

  return shouldWeDouble(deal.score, bidding, deal.vulnerability, deal.version);
}
