import Icon from '../../images/icons/question.svg?react';

export const QuestionIcon = (props) => {
  return <Icon
    width={props.width || '16px'}
    height={props.width || '16px'}
    color={props.color || 'white'}
    fill={props.fill || 'white'}
    {...props}
  />
}
