export function sortVariables(variables) {
  const sortOrder = ['X', 'Y', 'Z', 'W'];
  return variables.sort(function (a, b) {
    return sortOrder.indexOf(a) - sortOrder.indexOf(b);
  });
}

export function validateVariables(handTypes, settings) {
  const vars = ['X', 'Y', 'Z', 'W'];

  const incorrectVars = new Set();
  Object.values(handTypes).forEach(function (directionHandTypes) {
    directionHandTypes.forEach(function (handType) {
      handType.constraints?.forEach(function (constraint) {
        if (vars.includes(constraint.name) && (settings.variables[constraint.name] ?? []).length === 0) {
          incorrectVars.add(constraint.name);
        }
      });
    });
  });

  return sortVariables(Array.from(incorrectVars));
}

export function getFirstUnusedVariable(variables) {
  for (const variable of ['X', 'Y', 'Z', 'W']) {
    if (variables[variable] == null) {
      return variable;
    }
  }
}

export function sortSuits(suits) {
  const sortOrder = ['spades', 'hearts', 'diamonds', 'clubs'];
  return suits.sort(function (a, b) {
    return sortOrder.indexOf(a) - sortOrder.indexOf(b);
  });
}

export function validateVulnerabilities(handTypes, settings) {
  let settingVuls = ['NONE', 'NS', 'EW', 'ALL'];
  if ((settings.vulnerability ?? 'any') !== 'any') {
    settingVuls = [settings.vulnerability];
  }

  const incorrectDirs = new Set();
  Object.keys(handTypes).forEach(function (dir) {
    const directionHandTypes = handTypes[dir];
    if (directionHandTypes.length > 0) {
      let unusedVulsForDir = [...settingVuls];
      directionHandTypes.forEach(function (handType) {
        let handTypeVuls = ['NONE', 'NS', 'EW', 'ALL'];
        handType.constraints?.forEach(function (constraint) {
          if (constraint.name === 'vuls') {
            handTypeVuls = constraint.args;
          }
        });
        unusedVulsForDir = unusedVulsForDir.filter(v => !handTypeVuls.includes(v));
      });
      if (unusedVulsForDir.length > 0) {
        incorrectDirs.add(dir);
      }
    }
  });

  return Array.from(incorrectDirs);
}

const directionToPartner =  {
  S: 'N',
  N: 'S',
  E: 'W',
  W: 'E',
};

const directionToRho =  {
  S: 'E',
  N: 'W',
  E: 'N',
  W: 'S',
};

const directionToLho =  {
  S: 'W',
  N: 'E',
  E: 'S',
  W: 'N',
};

export function resolveDirectionForSettings(dir, settingDealer) {
  let direction = dir[0];
  if (settingDealer === 'player') {
    return direction;
  } else if (settingDealer === 'partner') {
    return directionToPartner[direction];
  } else if (settingDealer === 'rho') {
    return directionToRho[direction];
  } else if (settingDealer === 'lho') {
    return directionToLho[direction];
  } else {
    return settingDealer; // Will be N/S/E/W/any
  }
}
