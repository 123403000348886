import {
  getBidArrayWithoutAlertsExcludingPositionalSymbols,
  getBiddingRound,
  getBidSuit,
  getCurrentDenomination,
  getLastBid,
  getOpeningBid,
  getRobotBids,
  isBidHigher,
  isValidBid,
} from 'cuebids-bidding-util'
import opening from './opening';
import respondingTo1Minor from './respondingTo1Minor';
import respondingTo1Major from './respondingTo1Major';
import respondingTo1N from './respondingTo1N';
import respondingToPreEmpt from './respondingToPreEmpt';
import preEmpt2Level from './preEmpt2Level';
import preEmpt3Level from './preEmpt3Level';
import preEmpt4Level from './preEmpt4Level';
import overcall1Level from './overcall1Level';
import overcall2Level from './overcall2Level';
import overcallOver1N from './overcallOver1N';
import takeoutDouble from './takeoutDouble';
import competeAgain from './competeAgain';
import overcall4thSeat from './overcall4thSeat';
import balance from './balance';
import openerBidTwice from './openerBidTwice';
import ntDoubledEscapes from './ntDoubledEscapes';
import multi from './special/multi';
import miniNT from './special/miniNT';
import entryCommand from './entryCommand'
import michaels from './michaels';

export const baseSystem = opening.concat(
  respondingTo1Minor,
  respondingTo1Major,
  respondingTo1N,
  respondingToPreEmpt,
  preEmpt2Level,
  preEmpt3Level,
  preEmpt4Level,
  overcall1Level,
  overcall2Level,
  overcallOver1N,
  takeoutDouble,
  competeAgain,
  overcall4thSeat,
  balance,
  openerBidTwice,
  ntDoubledEscapes,
  entryCommand,
  michaels,
);

export const multiSystem = multi;

export const miniNTSystem = miniNT.concat(
  ntDoubledEscapes,
);

export function getActualBid(
  bid,
  openingBidSuit,
  responderSuit,
  currentDenomination,
) {
  const optSuit = bid[1];
  let x = bid.replaceAll('!o', '!' + openingBidSuit);
  x = x.replaceAll('!r', '!' + responderSuit);

  if (optSuit === 'o') {
    x = x.replace('o', openingBidSuit);
  }
  if (optSuit === 'l') {
    x = x.replace('l', currentDenomination);
  }
  if (optSuit === 'r') {
    x = x.replace('r', responderSuit);
  }

  return x;
}

export function canOverridePotentialLevel(splitBidding, bid) {
  if (!splitBidding.length) {
    return false;
  }

  const lastHumanBid = splitBidding[splitBidding.length - 1];
  if (!lastHumanBid) {
    return false;
  }

  if (['D', 'R'].includes(lastHumanBid)) {
    // If last human bid was a double or redouble, we can do anything.
    return true;
  }

  if (lastHumanBid !== 'P') {
    // If last human bid was an active bid, we must respect potential level.
    return false;
  }

  const lastRobotBid = splitBidding[splitBidding.length - 2];

  if (!lastRobotBid) {
    return false;
  }

  if (['D', 'R'].includes(lastRobotBid)) {
    // If last robot bid was a double or redouble, we can do anything.
    return true;
  }

  if (lastRobotBid !== 'P') {
    // If last robot bid was an active bid, we can bid a new suit to avoid being stuck in artificial bids.
    return getBidSuit(bid) !== getBidSuit(lastRobotBid);
  }

  const penultimateHumanBid = splitBidding[splitBidding.length - 3];

  if (!penultimateHumanBid) {
    return false;
  }

  if (['D', 'R'].includes(penultimateHumanBid)) {
    // If the penultimate human bid was a double or redouble, we can do anything.
    return true;
  }

  // Penultimate human bid was an active bid followed by two passes or the bidding is P-P-P to robot. Must respect potential level.
  return false;
}

// Note: Try not to exclude bids on reqMax just because a better option also is available (e.g. removing 2S because you have enough HCP for 4S)
// Other things (such as potential levels) might remove the higher bid, and then we are left with no bid at all and will pass.
// Order of bids or weights can be used to bid 4S instead in the above example.
// An exception to this is if you would prefer a pass rather than a worse bid, for example partner bids 1S, reqMaxS on 1N might make sense so that robots do not bid 1N instead of 2S (pass is better if 2S is ruled out).
// Raise should generally be allowed by potential bid, since the level is dependent on fit and hcp, just like a raise (but will not always be allowed).
export function findOptions(biddingObj, ai, instructions) {
  const openingBid = getOpeningBid(biddingObj.bidding);
  const openingBidSuit = openingBid && openingBid[1];
  const currentDenomination = getCurrentDenomination(biddingObj.bidding);
  const splitBidding = getBidArrayWithoutAlertsExcludingPositionalSymbols(
    biddingObj.bidding,
  );
  const lastBid = getLastBid(biddingObj.bidding);
  const robotBids = getRobotBids(biddingObj.bidding);
  const biddingRound = getBiddingRound(biddingObj.bidding);

  let responderSuit;
  if (openingBid) {
    const responderBid = splitBidding[splitBidding.indexOf(openingBid) + 2];
    if (responderBid) {
      responderSuit = responderBid[1];
    }
  }

  return ai.options.reduce((a, o) => {
    const test = false;
    
    if (test) console.log('Testing bid: ', o.bid);
    const bid = getActualBid(
      o.bid,
      openingBidSuit,
      responderSuit,
      currentDenomination,
    );
    const suit = bid[1];
    const bidWithoutExplanation = bid[1] === '[' ? bid.substring(0, 1) : bid.substring(0, 2);

    if (!isValidBid(biddingObj.bidding, bidWithoutExplanation)) {
      if (test) console.log('Failed on valid bid');
      return a;
    }

    // AI Commanded to do something
    if (instructions && instructions.command) {
      if (instructions.command.substring(0, 5) === 'enter') {
        // Only enter in second seat after an opening
        if (biddingObj.position === 2 && openingBid && robotBids.length === 0) {
          // no premise or premise match bid premise
          if (!instructions.premise || (ai.premise && ai.premise === instructions.premise)) {
            const cmd = instructions.command.substring(6)
            if (cmd === bidWithoutExplanation) {
              a.push({
                bid,
                weight: 1000,
                explain: ai.explain,
              })
              return a
            }
          }
        }
      }
    }

    // If a command system, should not happen in other cases
    if (o.reqCommand) {
      return a;
    }

    if (o.positions && !o.positions.includes(biddingObj.position)) {
      if (test) console.log('Failed on position');
      return a;
    }
    if (o.lastBid && o.lastBid !== lastBid) {
      if (test) console.log('Failed on lastBid');
      return a;
    }
    if (o.lastBidExclusions && o.lastBidExclusions.includes(lastBid)) {
      if (test) console.log('Failed on lastBidExclusions');
      return a;
    }
    if (o.reqHp != null && biddingObj.hp < o.reqHp) {
      if (test) console.log('Failed on reqHp');
      return a;
    }
    if (o.reqMaxHp != null && biddingObj.hp > o.reqMaxHp) {
      if (test) console.log('Failed on reqMaxHp');
      return a;
    }
    if (
      openingBidSuit &&
      o.reqFit != null &&
      biddingObj.strains[openingBidSuit] < o.reqFit
    ) {
      // TODO: reqFit is opening suit, can it be smarter for overcalls? (Maybe change reqFit to reqO for opening suit)
      if (test) console.log('Failed on reqFit');
      return a;
    }
    if (
      openingBidSuit &&
      o.reqMaxFit != null &&
      biddingObj.strains[openingBidSuit] > o.reqMaxFit
    ) {
      if (test) console.log('Failed on reqMaxFit');
      return a;
    }
    if (suit && o.reqSuit != null && biddingObj.strains[suit] < o.reqSuit) {
      if (test) console.log('Failed on reqSuit');
      return a;
    }
    if (
      suit &&
      o.reqHcpInSuitPerVul &&
      o.reqHcpInSuitPerVul(biddingObj.vulnerability) > biddingObj.hpSuits[suit]
    ) {
      if (test) console.log('Failed on reqHcpInSuitPerVul');
      return a;
    }
    if (o.reqS != null && biddingObj.strains.S < o.reqS) {
      if (test) console.log('Failed on reqS');
      return a;
    }
    if (o.reqH != null && biddingObj.strains.H < o.reqH) {
      if (test) console.log('Failed on reqH');
      return a;
    }
    if (o.reqD != null && biddingObj.strains.D < o.reqD) {
      if (test) console.log('Failed on reqD');
      return a;
    }
    if (o.reqC != null && biddingObj.strains.C < o.reqC) {
      if (test) console.log('Failed on reqC');
      return a;
    }
    if (o.reqMaxS != null && biddingObj.strains.S > o.reqMaxS) {
      if (test) console.log('Failed on reqMaxS');
      return a;
    }
    if (o.reqMaxH != null && biddingObj.strains.H > o.reqMaxH) {
      if (test) console.log('Failed on reqMaxH');
      return a;
    }
    if (o.reqMaxD != null && biddingObj.strains.D > o.reqMaxD) {
      if (test) console.log('Failed on reqMaxD');
      return a;
    }
    if (o.reqMaxC != null && biddingObj.strains.C > o.reqMaxC) {
      if (test) console.log('Failed on reqMaxC');
      return a;
    }
    if (
      responderSuit &&
      o.reqR != null &&
      biddingObj.strains[responderSuit] < o.reqR
    ) {
      if (test) console.log('Failed on reqR');
      return a;
    }
    if (
      responderSuit &&
      o.reqMaxR != null &&
      biddingObj.strains[responderSuit] > o.reqMaxR
    ) {
      if (test) console.log('Failed on reqMaxR');
      return a;
    }
    if (
      o.reqHcpInLastSuit != null &&
      biddingObj.hpSuits[currentDenomination] < o.reqHcpInLastSuit
    ) {
      if (test) console.log('Failed on reqHcpInLastSuit');
      return a;
    }
    if (
      o.reqHcpInOpeningSuit != null &&
      biddingObj.hpSuits[openingBidSuit] < o.reqHcpInOpeningSuit
    ) {
      if (test) console.log('Failed on reqHcpInOpeningSuit');
      return a;
    }
    if (o.reqStrains && !o.reqStrains(biddingObj.strains)) {
      if (test) console.log('Failed on reqStrains');
      return a;
    }
    if (
      openingBidSuit &&
        o.reqParO != null &&
        biddingObj.robotPars[openingBidSuit] < o.reqParO) {
      if (test) console.log('Failed on reqParO');
      return a;
    }
    if (o.reqParC != null && biddingObj.robotPars.C < o.reqParC) {
      if (test) console.log('Failed on reqParC');
      return a;
    }
    if (o.reqParD != null && biddingObj.robotPars.D < o.reqParD) {
      if (test) console.log('Failed on reqParD');
      return a;
    }
    if (o.reqParH != null && biddingObj.robotPars.H < o.reqParH) {
      if (test) console.log('Failed on reqParH');
      return a;
    }
    if (o.reqParS != null && biddingObj.robotPars.S < o.reqParS) {
      if (test) console.log('Failed on reqParS');
      return a;
    }
    if (o.reqParN != null && biddingObj.robotPars.N < o.reqParN) {
      if (test) console.log('Failed on reqParN');
      return a;
    }
    if (
      o.reqMaxBiddingRound != null &&
      biddingRound > o.reqMaxBiddingRound
    ) {
      if (test) console.log('Failed on reqMaxBiddingRound');
      return a;
    }
    if (
      o.reqMaxRobotBidCount != null &&
      robotBids.length > o.reqMaxRobotBidCount
    ) {
      if (test) console.log('Failed on reqMaxRobotBidCount');
      return a;
    }
    if (biddingObj.hasPotentialForParBeatBest &&
        isBidHigher(bidWithoutExplanation, biddingObj.highestPotentialBid) &&
        !canOverridePotentialLevel(splitBidding, bidWithoutExplanation)) {
      // Note: Not checking potential per strain risks allowing e.g. 2N which beats par when 3C was highest potential contract which did not beat par.
      // Checking per strain risks blocking artificial bids. The above case should be extremely rare, so do don't check per strain for now.
      if (test) console.log('Failed on highest potential bid');
      return a;
    }
    if (test) console.log('Success');

    a.push({
      bid,
      weight: o.weight || 1,
      explain: ai.explain,
      id: o.id,
    });

    return a;
  }, []);
}
