import getRobotActionV1 from './src/V1/robotAction';
import getRobotActionV2 from './src/V2/getRobotAction';

export function getRobotAction({ compete, sessionDeal, bidding, test = false }) {
  if (compete === 2) {
    return getRobotActionV2({ sessionDeal,
      bidding,
      test
    });
  } else if (compete === 1) {
    return getRobotActionV1(
      sessionDeal.hand,
      bidding,
      sessionDeal.vulnerability,
      sessionDeal.ai,
    );
  } else {
    return '-P'
  }
}
