import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Alert from '../../components/alert/alert';
import { useAuth } from '../../util/hooks.jsx';
import {
  addFriendByFriendKey,
  sendFriendRequest,
  setNewFriendKey,
} from '../../firebase/friends';
import { updateUser } from '../../firebase/userApi';
import algoliasearch from 'algoliasearch';
import TextField from '../../components/textField/TextField';
import { UserDisplayInstantNameFlag } from '../../components/users/userDisplay';
import { useAppStateStore } from '../../appStateStore.jsx';
import { resToObjects } from '../../util/algolia.js'
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { Trans, useTranslation } from 'react-i18next'

const algoliaSearchApiKey = 'bbc7a654026166c01044ca839e5072f8';
const algoliaAppId = 'JVCVRW8KT6';

const algoliaClient = algoliasearch(algoliaAppId, algoliaSearchApiKey);

const algoliaIndexName =
  import.meta.env.VITE_INSTANCE_NAME === 'dev' ? 'dev_USERS' : 'prod_USERS';

const algoliaIndex = algoliaClient.initIndex(algoliaIndexName);

function getSearchFriendText(friendStatus, isYou, t) {
  if (isYou) {
    return t('status_you');
  }

  const textsMap = {
    mutual: t('status_mutual'),
    pending: t('status_pending'),
    blocked: t('status_blocked'),
    following: t('status_following'),
  };

  return textsMap[friendStatus] || '';
}

function SearchUsers({ friends, currentUserId, loading, setLoading, t }) {
  const [searchString, setSearchString] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  const notify = useNotifications();

  const searchUsers = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);
    setSearchResults(null);
    const res = await algoliaIndex.search(searchString);
    setSearchResults(resToObjects(res));
    setLoadingSearch(false);
  };

  const sendRequest = async (uid) => {
    setLoading(true);
    const newFriend = await sendFriendRequest(uid);
    setLoading(false);

    if (newFriend?.isNew) {
      notify(
        {
          text: t('request_sent_notification', {displayName: newFriend.displayName}),
          type: 'success',
        },
      );
    }
  };

  return (
    <div>
      <form className="w-80" onSubmit={searchUsers}>
        <TextField
          value={searchString}
          label={t('search_name_label')}
          placeholder={t('search_name_placeholder')}
          onChange={setSearchString}
        />
        <button
          type="submit"
          disabled={searchString.length < 3 || loading || loadingSearch}
          className="btn btn-primary mt-4 w-full"
        >
          {t('search_name_button')}
        </button>
      </form>
      {searchResults &&
        (searchResults.length > 0 ? (
          <table className="mt-4 w-full border-collapse rounded-sm">
            <thead>
              <tr>
                <th>{t('search_results_count', {count: searchResults.length})}</th>
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(odd)]:bg-base-500">
              {searchResults.map((u) => {
                const uid = u.id;
                const flag = u.flag;
                const showFlag = u.showFlag;
                const displayName = u.displayName;

                const isYou = uid === currentUserId;
                const friendStatus =
                  friends?.find((f) => f.id === uid)?.status ?? 'none';

                return (
                  <tr key={uid} className="fade-in-slow">
                    <td className="p-2">
                      <Link to={'/player/' + uid}>
                        <UserDisplayInstantNameFlag
                          key={uid}
                          flag={flag}
                          showFlag={showFlag}
                          name={displayName}
                        />
                      </Link>
                    </td>
                    <td>
                      <div className="flex justify-end">
                        {friendStatus !== 'none' || isYou ? (
                          <div className="badge badge-accent badge-md ml-4">
                            {getSearchFriendText(friendStatus, isYou, t)}
                          </div>
                        ) : (
                          <button
                            disabled={loading}
                            className="btn btn-secondary btn-xs"
                            onClick={function () {
                              sendRequest(uid);
                            }}
                          >
                            {t('send_request_button')}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <Alert text={t('search_results_empty')} severity="warning" />
        ))}
    </div>
  );
}

async function addFriendByFriendKeyLocal(
  friendKey,
  notify,
  setLoading,
  setFriendKey,
  t
) {
  const newFriend = await addFriendByFriendKey(friendKey);

  if (newFriend?.blocked) {
    notify(
      {
        text: t('blocked_error'),
        type: 'failure',
      },
    );
    setLoading(false);
    return;
  }

  if (newFriend?.disableFriendKey) {
    notify(
      {
        text: t('key_disabled_error'),
        type: 'failure',
      },
    );
    setLoading(false);
    return;
  }

  if (!newFriend) {
    notify(
      {
        text: t('no_friend_error'),
        type: 'failure',
      },
    );
    setLoading(false);
  } else {
    setFriendKey('');
    if (newFriend.isNew) {
      notify(
        { text: t('friend_added', {displayName: newFriend.displayName}), type: 'success' },
      );
    } else {
      notify(
        {
          text: t('already_friends_error', {displayName: newFriend.displayName}),
          type: 'warning',
        },
      );
    }
  }
}

export default function AddFriend() {
  const { t } = useTranslation('translation', { keyPrefix: 'add_friend' });
  const { newFriendKey } = useParams();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(!!newFriendKey);
  const user = useAppStateStore((state) => state.user);
  const isNative = useAppStateStore((state) => state.isNative);
  const appCopy = useAppStateStore((state) => state.appCopy);
  const friends = useAppStateStore((state) => state.friends);
  const notify = useNotifications();
  const [friendKey, setFriendKey] = useState('');
  const [disableFriendKey, setDisableFriendKey] = useState(
    !!user?.disableFriendKey
  );

  const nav = useNavigate();

  const link = `https://cuebids.com/addFriend/${user?.friendKey}`;

  useEffect(() => {
    if (!newFriendKey || !user) return;
    if (newFriendKey.toLowerCase() === user?.friendKey?.toLowerCase()) {
      notify(
        {
          text: t('link_error'),
          type: 'failure',
        },
      );
      setLoading(false);
      return;
    } else {
      addFriendByFriendKeyLocal(
        newFriendKey,
        notify,
        setLoading,
        setFriendKey,
        t
      ).then(function () {
        nav('/');
      });
    }
  }, [newFriendKey, notify, user, nav, t]);

  useEffect(
    function () {
      if (user) {
        setDisableFriendKey(!!user.disableFriendKey);
      }
    },
    [user]
  );

  const handleAddFriend = async () => {
    setLoading(true);
    if (friendKey.toLowerCase() === user?.friendKey.toLowerCase()) {
      notify(
        {
          text: t('own_key_error'),
          type: 'failure',
        },
      );
      setLoading(false);
      return;
    }
    await addFriendByFriendKeyLocal(
      friendKey,
      notify,
      setLoading,
      setFriendKey,
      t
    );
    setLoading(false);
  };

  const handleUpdateFriendKey = (e) => {
    setFriendKey(e.target.value);
  };

  const handleClickGenerateFriendKey = async () => {
    await setNewFriendKey();
  };

  const handleCopyFriendKeyLink = async () => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(link);
    } else {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ message: 'copyUrl', url: link })
      );
    }
    notify(
      {
        text: t('link_copied'),
        type: 'success',
      },
    );
  };

  const handleGoToSettings = async () => {
    nav('/settings');
  };

  const handleToggleDisableFriendKey = () => {
    const v = !disableFriendKey;
    setDisableFriendKey(v);
    updateUser({
      disableFriendKey: v,
    });
  };

  const handleNewFriendKey = () => {
    setNewFriendKey();
  };

  if (!user) {
    return <></>;
  }

  if (loading && newFriendKey) {
    return (
      <div className="mt-20 flex w-full items-center justify-center">
        <article className="prose w-1/2">
          <h1>{t('adding_friend_loading')}</h1>
        </article>
      </div>
    );
  }

  return (
    <div className="fade-in">
      <div className="page pb-24">
        {user && (
          <div className="flex w-full flex-col items-center gap-4">
            {!user?.friendKey && (
              <Alert
                severity="warning"
                onClick={handleClickGenerateFriendKey}
                text={
                  <div>
                    <Trans
                      i18nKey="no_key_info"
                      t={t}
                    />
                  </div>
                }
              />
            )}
            {(!user?.displayName || user?.displayName === 'New User') && (
              <Alert
                severity="warning"
                onClick={handleGoToSettings}
                text={
                  <div>
                    <Trans
                      i18nKey="no_name_info"
                      t={t}
                    />
                  </div>
                }
              />
            )}
          </div>
        )}
        <div className="card w-4/5 max-w-[400px]">
          <div className="card-body p-0 mb-2">
            <div className="text-md flex w-full justify-center">{t('your_key')}</div>
            <div className="flex select-text justify-between p-2 rounded-md slated">
              <label className="label cursor-pointer p-0 w-1/3 flex flex-col md:flex-row items-center justify-start">
                {t('disable_key_label')}
                <input
                  type="checkbox"
                  className="toggle-primary toggle md:ml-2"
                  checked={disableFriendKey}
                  onChange={handleToggleDisableFriendKey}
                />
              </label>
              <span style={{ fontSize: '30px' }}>{user?.friendKey}</span>
              <div className='w-1/3 flex justify-end'>
                <button
                  onClick={handleNewFriendKey}
                  className={'btn-outline btn-secondary btn-sm btn'}
                >
                  {t('new_key_button')}
                </button>
              </div>
            </div>

            {disableFriendKey && (
              <div className="info">
                {t('disable_key_info')}
              </div>
            )}
          </div>

          <div>
            <div className="text-md flex w-full justify-center"></div>
            <div className="flex w-full flex-col border-opacity-50">
              <div className="card rounded-box grid h-20 place-items-center bg-base-200">
                <span className="info">
                   <Trans
                     i18nKey="add_by_key_info"
                     t={t}
                   />
                </span>

                <div className="flex justify-around w-full">
                  <input
                    value={friendKey}
                    placeholder={t('key_placeholder')}
                    onChange={handleUpdateFriendKey}
                    type="text"
                    required
                    className="input w-28 uppercase text-white"
                  />
                  <button
                    className="btn-secondary btn grow"
                    disabled={!(friendKey.length === 6) || loading}
                    onClick={handleAddFriend}
                  >
                    {t('add_by_key_button')}
                  </button>
                </div>
              </div>
              <div className="divider">{t('or')}</div>
              <div className="rounded-box flex items-center justify-between bg-base-300 p-4">
                {isNative && !appCopy ? (
                  <span>{link}</span>
                ) : (
                  <>
                    <span className="info">{t('link_info')}</span>
                    <button
                      className="btn-primary btn-sm btn"
                      onClick={handleCopyFriendKeyLink}
                    >
                      {t('copy_link_button')}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="divider">{t('or')}</div>
        </div>
        <div className="w-full flex items-center justify-center">
          <SearchUsers
            currentUserId={currentUser.uid}
            friends={friends}
            loading={loading}
            setLoading={setLoading}
            t={t}
          />
        </div>
      </div>
    </div>
  );
}
