import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { optInConfirm } from '../../firebase/achievements';
import { useAuth } from '../../util/hooks.jsx';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

export default function SubscribeNewsletterPage() {
  const { t } = useTranslation('translation', { keyPrefix: 'subscribe_newsletter' });
  const { currentUser } = useAuth();
  const { uid } = useParams();
  const nav = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const notify = useNotifications();

  const isLoggedIn = !!currentUser;

  useEffect(
    function () {
      setLoading(true);
      optInConfirm(uid)
        .then(function () {
          notify(
            {
              text: t('notification_success'),
              type: 'success',
            },
          );

          if (isLoggedIn) {
            // nav('/achievements', {replace: true});
            nav('/settings', { replace: true });
          } else {
            setLoading(false);
          }
        })
        .catch(function () {
          setError(true);
          setLoading(false);
        });
    },
    [uid, nav, isLoggedIn, notify, t]
  );

  return (
    <div className="page pb-24 px-4">
      {loading ? (
        t('verification_in_progress')
      ) : error ? (
        <>
          {t('error')}
          <div>
            <button
              onClick={function () {
                // Note: Firefox supports a non-standard forceGet boolean parameter for location.reload(), to tell Firefox to bypass its cache and force-reload the current document. However, in all other browsers, any parameter you specify in a location.reload() call will be ignored and have no effect of any kind.
                // https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
                window.location?.reload?.(true);
              }}
              className="btn btn-primary mt-4"
            >
              {t('try_again')}
            </button>
          </div>
        </>
      ) : (
        <>
          {t('success')}
          <div>
            <div />
            <a
              // TODO: When activating achievements, change href page
              href={
                window.location.origin
                  ? window.location.origin + '/settings'
                  : 'https://cuebids.com/settings'
              }
              className="btn btn-primary mt-4"
            >
              {t('claim_reward')}
            </a>
          </div>
        </>
      )}
    </div>
  );
}
