import { httpsCallable } from 'firebase/functions';
import { auth, db, functions } from './firebase';
import {
  getDoc,
  increment,
  onSnapshot,
  runTransaction,
  serverTimestamp,
  setDoc,
  collection,
  doc
} from 'firebase/firestore';
import { docToObject } from 'firebase-util';
import i18next from 'i18next'

export function getUserAchievementsObservable(callback) {
  const currentUser = auth.currentUser;
  const achievementsRef = collection(
    doc(collection(db, 'users'), currentUser.uid),
    'achievements'
  );

  return onSnapshot(achievementsRef, (querySnapshot) => {
    const result = {};
    querySnapshot.docs.forEach(function (d) {
      result[d.id] = {
        name: d.id,
        ...d.data(),
      };
    });
    callback(result);
  });
}

export async function optInForNewsletter(email) {
  const currentUser = auth.currentUser;
  const request = httpsCallable(functions, 'optInForNewsletter');
  await request({ uid: currentUser.uid, email });
}

export async function optInConfirm(uid) {
  const request = httpsCallable(functions, 'optInConfirm');
  await request({ uid });
}

export async function completeAchievement(achievement) {
  const currentUser = auth.currentUser;

  const achievementRef = doc(
    collection(doc(collection(db, 'users'), currentUser.uid), 'achievements'),
    achievement
  );

  const achievementData = docToObject(await getDoc(achievementRef));

  if (!achievementData) {
    await setDoc(achievementRef, {
      name: achievement,
      status: 'PENDING',
      pendingTimestamp: serverTimestamp(),
    });

    const request = httpsCallable(functions, 'completeAchievement');
    await request({ uid: currentUser.uid, achievement });
  }
}

export async function retryCompleteAchievement(achievement) {
  const currentUser = auth.currentUser;

  const achievementRef = doc(
    collection(doc(collection(db, 'users'), currentUser.uid), 'achievements'),
    achievement
  );

  const achievementData = docToObject(await getDoc(achievementRef));

  if (achievementData?.status === 'PENDING') {
    const request = httpsCallable(functions, 'completeAchievement');
    await request({ uid: currentUser.uid, achievement });
  }
}

export async function tryClaimAchievement(achievement, reward) {
  const currentUser = auth.currentUser;
  const userRef = doc(collection(db, 'users'), currentUser.uid);
  const achievementRef = doc(
    collection(doc(collection(db, 'users'), currentUser.uid), 'achievements'),
    achievement
  );

  await runTransaction(db, async (transaction) => {
    const _achievementDoc = await transaction.get(achievementRef);

    if (!_achievementDoc.exists()) {
      throw new Error(i18next.t('achievements.error_not_completed'));
    }

    const achievementData = _achievementDoc.data();

    if (achievementData.status === 'CLAIMED') {
      throw new Error(i18next.t('achievements.error_already_claimed'));
    }

    if (achievementData.status !== 'COMPLETED') {
      throw new Error(i18next.t('achievements.error_not_completed'));
    }

    transaction.update(userRef, {
      extraTickets: increment(reward),
      achievementPoints: increment(reward),
    });

    const ticketTransactionsRef = doc(
      collection(userRef, 'ticketsTransactions')
    );
    transaction.set(ticketTransactionsRef, {
      datetime: serverTimestamp(),
      type: 'achievementClaim',
      paymentId: null,
      tickets: reward,
    });

    transaction.update(achievementRef, {
      status: 'CLAIMED',
      claimedTimestamp: serverTimestamp(),
      reward,
    });
  });
}
