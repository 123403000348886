import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import BiddingDisplay from '../../components/bidding/biddingDisplay';
import BiddingBox from '../../components/bidding/biddingBox';
import { useAuth } from '../../util/hooks.jsx';
import { replaceSuitShorthandWithSymbols } from '../../util/symbols';
import BidDisplay from '../../components/bidding/bidDisplay';
import { useTranslation } from 'react-i18next';
import { UserDisplayNameSmall } from '../../components/users/userDisplay';
import Hand from '../../components/cards/hand';
import { directionToBid } from 'cuebids-bidding-util';
import Alert, { AlertInline } from '../../components/alert/alert';
import BidSpotCard from '../../components/spot/bidSpotCard';
import { PieChart, Pie, Cell } from 'recharts';
import Collapse from '../../components/collapse/Collapse';
import BidDisplaySvg from '../../components/bidding/bidDisplaySvg';
import {
  deleteSpot,
  getAllResponsesObservable,
  getSpot,
  handleBidOnSpot,
  setLastReadTimestamp,
} from 'cuebids-firebase/spots';
import { useAppStateStore } from '../../appStateStore.jsx';
import DisplayHand from '../../components/hand/displayHand.jsx';
import ScrollToTopButton from '../../components/scrollToTop/ScrollToTopButton.jsx';
import { Tab, TabPanel, Tabs } from '../../components/tabs/tabs.jsx';
import { useQuery } from '@tanstack/react-query';
import { getPros } from 'cuebids-firebase/users';
import useConfirm from '../../hooks/confirm.jsx';
import { isAdmin } from '../../util/admin.js';
import useNotifications from '../../components/notifications/useNotifications.jsx';

function BidForm({
  spot,
  handleBid,
  handleSubmit,
  handleAbstain,
  bid,
  text,
  inputRef,
  handleChangeText,
  height,
  currentUser,
  loading,
  t,
}) {
  function handleBidAndScroll(e) {
    handleBid(e);
    inputRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      <div>
        <BiddingBox
          bidding={spot.bidding}
          acceptShortcuts={false}
          onBid={handleBidAndScroll}
        />
      </div>
      <form
        onSubmit={handleSubmit}
        className="w-full md:w-1/2 flex flex-col gap-4 px-4"
      >
        <div className="w-full flex items-start flex-row gap-2">
          {bid && <BidDisplay bid={bid} />}
          <div style={{ height }} className="grow">
            <textarea
              id="bid_description_input"
              rows={4}
              className="input h-[96px] w-full resize-none py-2"
              value={text}
              ref={inputRef}
              autoComplete="off"
              onChange={handleChangeText}
              type="text"
              placeholder={t('spots.bid_description_input.placeholder')}
            />
          </div>
        </div>
        <div className="flex items-center gap-2 justify-end w-full">
          {!bid && <Alert type="info" text={t('spots.warnings.select_bid')} />}
          <button
            disabled={!currentUser || loading || !bid}
            type="submit"
            className="btn btn-primary w-24"
          >
            {t('spots.bid_confirm_button')}
          </button>
          <button
            disabled={!currentUser || loading}
            type="button"
            onClick={handleAbstain}
            className="btn btn-error w-24"
          >
            {t('spots.bid_abstain_button')}
          </button>
        </div>
      </form>
    </>
  );
}

function getChartData(responses) {
  const numberOfResponsesPerBid = responses.reduce(function (acc, r) {
    const oldValue = acc[r.bid] || 0;
    return {
      ...acc,
      [r.bid]: oldValue + 1,
    };
  }, {});

  let chartData = Object.keys(numberOfResponsesPerBid).reduce(
    function (acc, k) {
      const percentage = Math.round(
        (numberOfResponsesPerBid[k] / responses.length) * 100
      );

      if (percentage >= 8) {
        acc.push({
          name: k,
          value: numberOfResponsesPerBid[k],
          percentage,
        });
      } else {
        acc[0].value += numberOfResponsesPerBid[k];
        acc[0].percentage += percentage;
      }

      return acc;
    },
    [
      {
        name: 'Other',
        value: 0,
        percentage: 0,
      },
    ]
  );

  if (chartData[0].percentage === 0) {
    chartData = chartData.slice(1);
  }

  return chartData.sort(function (a, b) {
    if (a.name === 'Other') {
      return 1;
    }

    if (b.name === 'Other') {
      return -1;
    }

    return b.percentage - a.percentage;
  });
}

function labelSize(value) {
  return value > 50 ? 20 : value > 25 ? 16 : value > 15 ? 12 : 8;
}

function getBidLabel({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  name,
  t,
}) {
  const RADIAN = Math.PI / 180;
  const size = 35;

  const radius = 30 + innerRadius + (outerRadius - innerRadius);

  const x = cx + radius * Math.cos(-midAngle * RADIAN);

  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const xText = cx + (radius / 2) * Math.cos(-midAngle * RADIAN);

  const yText = cy + (radius / 2) * Math.sin(-midAngle * RADIAN);

  if (name === 'Other') {
    return (
      <>
        <text
          x={x}
          y={y}
          fontSize={labelSize(value)}
          fontWeight={'bold'}
          fill="#FFF"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {t('spots.other')}
        </text>
        {value >= 8 && (
          <text
            x={xText}
            y={yText}
            fontSize={labelSize(value)}
            fontWeight={'bold'}
            fill="#000"
            textAnchor="middle"
            dominantBaseline="central"
          >
            {value}%
          </text>
        )}
      </>
    );
  }

  return (
    <>
      <BidDisplaySvg bid={name} x={x} y={y} size={size} />
      <text
        x={xText}
        y={yText}
        fontSize={labelSize(value)}
        fontWeight={'bold'}
        fill={chartColoursMap[getSuit(name)].stroke}
        textAnchor="middle"
        dominantBaseline="central"
      >
        {value}%
      </text>
    </>
  );
}

function getSuit(bid) {
  if (bid === 'D') {
    return 'X';
  }
  if (['P', 'R'].includes(bid)) {
    return bid;
  }

  return bid[1];
}

const chartColoursMap = {
  P: {
    fill: '#0d3f15',
    stroke: '#FFF',
  },
  X: {
    fill: '#d7443e',
    stroke: '#FFF',
  },
  R: {
    fill: '#4a4fff',
    stroke: '#FFF',
  },
  S: {
    fill: '#C0CCFB',
    stroke: '#00129B',
  },
  H: {
    fill: '#F2A8A6',
    stroke: '#C74F45',
  },
  D: {
    fill: '#ffcdaa',
    stroke: '#ff8b00',
  },
  C: {
    fill: '#DAFDBB',
    stroke: '#44982B',
  },
  N: {
    fill: '#d3d3d3',
    stroke: '#000',
  },
};

function Discussion({ spotId, responses, t, fed }) {
  if (responses.length === 0) {
    return (
      <Alert className="mt-4 w-24" type="info" text={t('spots.no_responses')} />
    );
  }

  const chartData = getChartData(responses);

  return (
    <>
      <div className="dashboard-wrapper">
        <PieChart width={300} height={150}>
          <Pie
            animationDuration={750}
            label={({
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              value,
              name,
            }) =>
              getBidLabel({
                cx,
                cy,
                midAngle,
                innerRadius,
                outerRadius,
                value,
                name,
                t,
              })
            }
            outerRadius={80}
            data={chartData}
            dataKey="percentage"
            nameKey="name"
            cy="100%"
            startAngle={180}
            endAngle={0}
          >
            {chartData.map((entry, index) => (
              <Cell
                key={index}
                stroke="#000"
                fontSize={12}
                fill={
                  entry.name === 'Other'
                    ? '#FFF'
                    : chartColoursMap[getSuit(entry.name)].fill
                }
              />
            ))}
          </Pie>
        </PieChart>
      </div>
      <span className="flex w-full justify-center">
        {t('spots.total')}:&nbsp;{responses.length}
      </span>
      <table
        id="spot-responses"
        className="mt-4 w-full border-collapse rounded-sm md:w-4/5"
      >
        <thead>
          <tr>
            <th className="">{t('spots.labels.bid')}</th>
            <th className="w-full">{t('spots.labels.reason')}</th>
            <th className="text-right">
              <span className="mr-5">{t('spots.labels.by')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {responses.map((r) => {
            return (
              <BidSpotCard
                spotId={spotId}
                t={t}
                response={r}
                key={r.id}
                fed={fed}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function AlreadyBid({ spotId, uid, answer, responses, t, fed }) {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? 'all';
  const navigate = useNavigate();

  const handeTabChange = (tabId) => {
    navigate(`/spots/${fed}/${spotId}?tab=${tabId}`, { replace: true });
  };

  const appStateFriends = useAppStateStore((state) => state.friends);
  const user = useAppStateStore((state) => state.user);

  const friends = appStateFriends
    .filter((f) => f.status !== 'blocked')
    .map((f) => f.uid);

  const { data: pros } = useQuery({
    queryKey: ['pros'],
    queryFn: async () => await getPros(),
  });

  const proResponses = responses.filter((r) => pros?.includes(r.uid));

  const friendResponses = responses.filter(
    (r) => friends?.includes(r.uid) || r.uid === user?.id
  );

  return (
    <>
      {answer && (
        <div className="w-full md:w-2/3">
          <div className="chat chat-start w-80 -mb-4 mt-2">
            <div
              className="chat-bubble chat-bubble-primary text-white select-text"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {' '}
              {answer}
            </div>
          </div>
          <div className="w-96 mt-3 flex items-center">
            <UserDisplayNameSmall uid={uid} />
          </div>
        </div>
      )}
      <div className="mt-4" />
      <Tabs>
        <Tab
          key={'all'}
          label={t('spots.all')}
          value={'all'}
          active={tab === 'all'}
          onChange={handeTabChange}
        />
        <Tab
          key={'friends'}
          label={t('spots.friends')}
          value={'friends'}
          active={tab === 'friends'}
          onChange={handeTabChange}
        />
        <Tab
          key={'pros'}
          label={t('spots.pros')}
          value={'pros'}
          active={tab === 'pros'}
          onChange={handeTabChange}
        />
      </Tabs>

      <TabPanel
        key={'allPanel'}
        value={tab}
        index={'all'}
        className="w-full flex flex-col items-center"
      >
        <Discussion spotId={spotId} responses={responses} t={t} fed={fed} />
      </TabPanel>
      <TabPanel
        key={'friendsPanel'}
        value={tab}
        index={'friends'}
        className="w-full flex flex-col items-center"
      >
        <Discussion
          spotId={spotId}
          responses={friendResponses}
          t={t}
          fed={fed}
        />
      </TabPanel>
      <TabPanel
        key={'proPanel'}
        value={tab}
        index={'pros'}
        className="w-full flex flex-col items-center"
      >
        <Discussion spotId={spotId} responses={proResponses} t={t} fed={fed} />
        {proResponses.length < 3 && (
          <Link className="w-full mt-4 flex justify-center" to={'/contact'}>
            <AlertInline
              sx={'w-4/5 p-2'}
              text={
                <span>
                  {t('spots.pros_new')}{' '}
                  <div className="badge badge-xs badge-secondary">PRO</div>
                </span>
              }
            />
          </Link>
        )}
      </TabPanel>
    </>
  );
}

const scoringColorMap = {
  TEAMS: 'text-spade-50',
  PAIRS: 'text-heart-50',
  BAM: 'text-diamond-50',
  RUBBER: 'text-club-50',
}

export default function SpotDisplay() {
  const { id, fed } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const inputRef = useRef(null);
  const [text, setText] = useState('');
  const [spotLocal, setSpotLocal] = useState(null);
  const [bid, setBid] = useState(null);
  const [height, setHeight] = useState(96);
  const [responses, setResponses] = useState([]);
  const [hasDeletedSpot, setHasDeletedSpot] = useState(false);

  const { t } = useTranslation();

  const [ConfirmDelete, confirmDelete] = useConfirm(
    'app.confirm_title',
    'spots.delete_confirm',
    'error',
    'secondary'
  );

  const [ConfirmAbstain, confirmAbstain] = useConfirm(
    'app.confirm_title',
    'spots.bid_abstain_confirm',
    'error',
    'secondary'
  );

  function handleChangeText(e) {
    const temp = e.target.selectionStart;

    setText(replaceSuitShorthandWithSymbols(e.target.value));
    resizeTextArea();
    setTimeout(() => e.target.setSelectionRange(temp, temp), 0);
  }

  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const spots = useAppStateStore((state) => state.spots);
  const user = useAppStateStore((state) => state.user);
  const notify = useNotifications();
  const spotFromStore = spots?.find((s) => s.id === id);
  const spot = spotFromStore ?? spotLocal;

  function handleBid(bid) {
    setBid(bid);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const response = {
      spotId: id,
      bid,
      text,
      uid: currentUser?.uid,
      spotOwnerUid: spot.uid,
      responses,
      fed: spot.fed,
      t,
    };
    await handleBidOnSpot(response);
  }

  async function handleAbstain() {
    if (await confirmAbstain()) {
      const response = {
        spotId: id,
        bid: null,
        text,
        uid: currentUser.uid,
        spotOwnerUid: spot.uid,
        responses,
        fed: spot.fed,
        t,
      };
      await handleBidOnSpot(response);
    }
  }

  function resizeTextArea() {
    inputRef.current.style.height = '96px';
    inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    setHeight(inputRef.current.scrollHeight);
  }

  function handleSetResponse(responses) {
    setResponses(responses);
    setLoading(false);
  }

  useEffect(() => {
    return getAllResponsesObservable(id, handleSetResponse);
  }, [id]);

  useEffect(() => {
    async function getSpotLocal() {
      if (!spotFromStore && !spotLocal && !hasDeletedSpot) {
        const s = await getSpot(id);
        if (s) {
          setSpotLocal(s);
        } else {
          navigate(`/404${location.pathname}`);
        }
      }
    }

    void getSpotLocal();
  }, [id, spotFromStore, spotLocal, navigate, hasDeletedSpot]);

  const myResponse = responses?.find((r) => r.uid === currentUser?.uid);
  const alreadyBid = !!myResponse;

  useEffect(
    function () {
      if (alreadyBid) {
        void setLastReadTimestamp(id);
      }
    },
    [id, alreadyBid, responses]
  );

  useEffect(
    function () {
      return function () {
        // Reset values when changing spot
        setResponses([]);
        setLoading(true);
        setText('');
        setSpotLocal(null);
        setBid(null);
        setHeight(96);
        setHasDeletedSpot(false);
      };
    },
    [id]
  );

  let previousSpotIndex;
  let nextSpotIndex;
  if (spot && spots?.length) {
    const currentSpotIndex = spots.findIndex(function (s) {
      return s.id === spot.id;
    });

    if (currentSpotIndex === -1) {
      // If old spot not in app state, default to moving to first spot
      previousSpotIndex = 0;
      nextSpotIndex = 0;
    } else if (currentSpotIndex === spots.length - 1) {
      previousSpotIndex = currentSpotIndex - 1;
      nextSpotIndex = 0;
    } else if (currentSpotIndex === 0) {
      previousSpotIndex = spots.length - 1;
      nextSpotIndex = currentSpotIndex + 1;
    } else {
      previousSpotIndex = currentSpotIndex - 1;
      nextSpotIndex = currentSpotIndex + 1;
    }
  }

  const moveSpot = useCallback(
    (next) => {
      if (next) {
        if (nextSpotIndex != null) {
          setLoading(true);
          navigate(`/spots/${fed}/${spots[nextSpotIndex].id}`, {
            replace: true,
          });
        }
      } else {
        if (previousSpotIndex != null) {
          setLoading(true);
          navigate(`/spots/${fed}/${spots[previousSpotIndex].id}`, {
            replace: true,
          });
        }
      }
    },
    [fed, navigate, spots, nextSpotIndex, previousSpotIndex]
  );

  useEffect(() => {
    if (!spot) return;

    updateAppState({
      navbarItems: (
        <div className="flex items-center gap-4 mr-4 md:mr-8 md:gap-8">
          <div className="">
            <button className="" onClick={() => moveSpot(false)}>
              <kbd className="kbd text-sm">◀︎</kbd>
            </button>
          </div>

          <div className="">
            <button className="" onClick={() => moveSpot(true)}>
              <kbd className="kbd text-sm">▶︎</kbd>
            </button>
          </div>
        </div>
      ),
    });
    return () => updateAppState({ navbarItems: undefined });
  }, [updateAppState, spot, moveSpot]);

  if (!spot || loading) {
    return null;
  }

  const handleDeleteSpot = async () => {
    const ans = await confirmDelete();
    if (ans) {
      if (loading) return;

      setLoading(true);
      setHasDeletedSpot(true);
      try {
        await deleteSpot(id);
        navigate(`/spots/${fed}`);
      } catch (e) {
        setHasDeletedSpot(false);
        notify(
          { text: t('spots.spot_delete_error'), type: 'error' },
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const isMySpot = currentUser?.uid === spot.uid;

  const spotInformationContent = (
    <div>
      <div className="flex items-center justify-center gap-2">
        <BiddingDisplay
          bidding={spot.bidding + '-?'}
          vulnerability={spot.vulnerability}
          variant={'compact'}
          startWithEast={false}
        />
        <div className="flex flex-col items-start">
          <label className="label">
            <span className="label-text">
              <strong>{t('bridge.dealer')}:</strong>{' '}
              {t(`bridge.directions.${spot.dealer}`)}
            </span>
          </label>
          {
            <label className="label">
              <span className="label-text">
                <strong>{t('bridge.vulnerability.name')}:</strong>{' '}
                {t(`bridge.vulnerability.${spot.vulnerability}`)}
              </span>
            </label>
          }
          {
            <label className="label">
              <span className="label-text">
                <strong>{t('bridge.scoring.name')}:</strong>{' '}
                <span className={scoringColorMap[spot.scoring]}>{t(`bridge.scoring.${spot.scoring ?? 'TEAMS'}`)}</span>
              </span>
            </label>
          }
        </div>
      </div>
      <div className="chat chat-start w-80 -mb-4 mt-2">
        <div
          className="chat-bubble chat-bubble-primary text-white select-text"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {' '}
          {spot.description}
        </div>
      </div>
      <div className="w-96 mt-3 flex items-center">
        <UserDisplayNameSmall uid={spot.uid} />
      </div>
      <Hand
        hand={spot.hand}
        direction={directionToBid(spot.bidding)}
        variant={
          user?.cardVariant ?? (user?.handAsDiagram ? 'diagram' : 'modern')
        }
        size={user?.cardSize ?? 'sm'}
        order={user?.suitOrder ?? 'default'}
      />
    </div>
  );

  return (
    <>
      <div className="page pb-16">
        {((isMySpot && spot.numberOfBids < 10) || isAdmin(currentUser?.uid)) && (
          <div className="mb-4">
            <button
              onClick={handleDeleteSpot}
              disabled={loading}
              className="btn btn-error btn-outline btn-sm h-4 mr-4"
            >
              {t('spots.delete')}
            </button>
            <Link
              to={`/spots/${fed}/${id}/edit`}
              className="btn btn-secondary btn-outline btn-sm h-4"
            >
              {t('spots.edit')}
            </Link>
          </div>
        )}
        {alreadyBid ? (
          <Collapse
            titleElement={
              <div className="flex gap-4">
                <DisplayHand
                  hand={spot.hand}
                  hideDealNumber={true}
                  dealNumber={null}
                />
                <BidDisplay bid={myResponse.bid} size={30} />
              </div>
            }
            contextSx="flex flex-col items-center"
          >
            {spotInformationContent}
          </Collapse>
        ) : (
          spotInformationContent
        )}
        {loading ? (
          <></>
        ) : alreadyBid ? (
          <AlreadyBid
            spotId={id}
            uid={spot.uid}
            answer={spot.answer}
            responses={responses.filter((r) => r.bid !== null)}
            t={t}
            fed={spot.fed}
          />
        ) : !currentUser ? (
          <Alert type="info" text={t('spots.warnings.login_to_bid')} />
        ) : (
          <BidForm
            spot={spot}
            handleBid={handleBid}
            handleSubmit={handleSubmit}
            handleAbstain={handleAbstain}
            bid={bid}
            text={text}
            inputRef={inputRef}
            handleChangeText={handleChangeText}
            height={height}
            currentUser={currentUser}
            loading={loading}
            t={t}
          />
        )}
      </div>
      <ConfirmDelete />
      <ConfirmAbstain />
      <ScrollToTopButton />
    </>
  );
}
