export function isAcceptedInputForAlert(e) {
  const regex = /^[\s0-9a-zA-Z (\-|.!,=_"'`/♦♣♥♠?@#$%^&*)+]+$/;
  if (e.match(regex) || e === '') {
    return true;
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
