import { useNavigate, useParams } from 'react-router-dom';
import {
  getEventDraft, rotateHand,
  scoreDeals,
  updateEventDraft
} from 'cuebids-firebase/dealGeneration';
import { useQuery } from '@tanstack/react-query';
import { UnscoredDeal } from '../../components/unscoredDeal/unscoredDeal.jsx';
import { useEffect, useState } from 'react';
import { DeleteIcon } from '../../components/icons/deleteIcon.jsx';
import { getBidArrayWithAlertsExcludingPositionalSymbols, getInitialBidding } from 'cuebids-bidding-util';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'


export default function EventDraft() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.event_draft' });
  const notify = useNotifications();
  const { eventId } = useParams();
  const nav = useNavigate();

  const { data: draft, isLoading } = useQuery({
      queryKey: ['draft', eventId],
      queryFn: () => {
        return getEventDraft(eventId)
      },
    }
  );
  const [localDraft, setLocalDraft] = useState(draft);
  const [loading, setLoading] = useState([]);
  const [loadingAll, setLoadingAll] = useState(false);

  useEffect(() => {
    setLocalDraft(draft);
  }, [draft]);

  const scoreDeal = async (i, score) => {
    setLoading((prevState) => ({ ...prevState, [i]: true }));
    try {
      const newDraft = { ...localDraft };
      let url = import.meta.env.VITE_ENDPLAYS_API_URL;
      const scores = await scoreDeals(url, [localDraft.deals[i]], score)

      newDraft.deals[i].score = scores[0].score;
      newDraft.deals[i].version = 3
      newDraft.deals[i].type = 'pbn'
      newDraft.deals[i].scoreKey = Date.now();

      setLocalDraft(newDraft);
    } catch (e) {
      notify(
        {
          text: t('error'),
          type: 'failure',
          autoHide: 5000,
        },
      );
    }
    setLoading((prevState) => ({ ...prevState, [i]: false }));
  }

  const createEvent = async () => {
    await updateEventDraft(eventId, localDraft);
    nav(`/groups/${localDraft.groupId}/createEvent?draft=${eventId}`);
  }

  const scoreAll = async () => {
    setLoadingAll(true)
    try {
      let url = import.meta.env.VITE_ENDPLAYS_API_URL
      const scores = await scoreDeals(url, localDraft.deals, 'INSTANT')
      const newDraft = { ...localDraft };

      for (let i = 0; i < scores.length; i++) {
        const deal = newDraft.deals[i];
        const s = scores[i];
        deal.score = s.score;
        deal.version = 3;
        deal.type = 'pbn';
        deal.scoreKey = Date.now();
      }
      setLocalDraft(newDraft);
      await updateEventDraft(eventId, newDraft);
    } catch (e) {
      notify(
        {
          text: t('error'),
          type: 'failure',
          autoHide: 5000,
        },
      );
    }
    setLoadingAll(false);
  }

  const setEventName = async (name) => {
    const newDraft = { ...localDraft };
    newDraft.eventName = name;
    setLocalDraft(newDraft);
    await updateEventDraft(eventId, newDraft);
  }

  const handleRotate = async (index) => {
    const newDraft = { ...localDraft };
    const newHands = [...newDraft.deals];

    const biddingArray = getBidArrayWithAlertsExcludingPositionalSymbols(newHands[index].bidding)
    const temp = rotateHand(newHands[index]);

    newHands[index] = {
      ...newHands[index],
      ...temp,
    }

    const init = getInitialBidding(newHands[index].dealer)

    newHands[index].bidding = init + '-' + biddingArray.join('-')
    newHands[index].scoreKey = Date.now()
    newDraft.deals = newHands;

    setLocalDraft(newDraft);

    await updateEventDraft(eventId, newDraft);
  }

  const removeHand = async (index) => {
    const newDraft = { ...localDraft };
    const newHands = [...newDraft.deals];
    newHands.splice(index, 1);
    newDraft.deals = newHands;
    setLocalDraft(newDraft);

    await updateEventDraft(eventId, newDraft);
  }

  const removeBidding = async (index) => {
    const newDraft = { ...localDraft };
    const newHands = [...newDraft.deals];
    newHands[index].bidding = ''
    newDraft.deals = newHands;
    setLocalDraft(newDraft);
    await updateEventDraft(eventId, newDraft);
  }

  if(isLoading) {
    return <div>Loading...</div>
  }

  const hands = localDraft?.deals;

  if(!hands) {
    return <div>No hands</div>
  }

  const allScored = hands.every(h => h.score);

  const anyLoading = Object.values(loading).some(l => l) || loadingAll;

  return (
    <div className="fade-in">
      <div className="page pb-24">
        <div className="flex flex-col gap-2 w-full m-4 p-4">
          <div className="flex flex-col gap-2 items-center justify-center w-full">
            <div className={'flex items-center justify-between w-full'}>
              <div className={'flex flex-col items-start'}>
                <button className={'btn h-4 btn-secondary'} onClick={scoreAll} disabled={anyLoading || allScored}>{t('score_all')}</button>
                <span className={'info'}>{t('score_all_info')}</span>
              </div>
              <button className={'btn btn-primary h-4'} onClick={createEvent} disabled={!allScored || anyLoading}>{t('create_event')}</button>
            </div>
            <div className={'flex flex-wrap gap-2 items-center justify-center'}>
              <input className="input input-primary w-full"
                placeholder={t('event_name_placeholder')}
                value={localDraft.eventName}
                onChange={(e) => setEventName(e.target.value)} />
              {
                hands.map((hand, i) => {
                  let isLoading = loading[i] || loadingAll;
                  return (
                    <div key={hand.hand} className={'m-4 border-heart-50 rounded border p-4 relative' + (isLoading ? ' opacity-70' : '')}>
                      <div className={'absolute top-0 right-0 flex items-center'}>
                        <div className={'btn btn-ghost '} onClick={() => handleRotate(i)}>
                          {t('rotate')}
                        </div>
                        <div className={'btn btn-ghost '} onClick={() => removeHand(i)}>
                          {t('remove_hand')} <DeleteIcon className={'fill-rose-400'}/>
                        </div>
                        {
                          hand.bidding && <div className={'btn btn-ghost '} onClick={() => removeBidding(i)}>
                            {t('remove_bidding')}
                          </div>
                        }
                      </div>
                      <div className={'mt-8 relative'}>
                        <UnscoredDeal loading={isLoading} deal={hand} onScore={(score) => scoreDeal(i, score)}/>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

