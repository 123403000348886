import { useEffect, useRef, useState } from 'react'
import './bidDisplay.css';
import BidDisplay from './bidDisplay';
import { replaceSuitShorthandWithSymbols } from '../../util/symbols';
import { getBidArrayWithAlertsIncludingPositionalSymbols } from 'cuebids-bidding-util';
import Tooltip from '../tooltip/tooltip';
import { QuestionIcon } from '../icons/questionIcon';
import { useTranslation } from 'react-i18next';

function Header({ vulnerability, variant = 'standard', startWithEast = false }) {
  const { t } = useTranslation();

  const nsVul = vulnerability === 'NS' || vulnerability === 'ALL';
  const ewVul = vulnerability === 'EW' || vulnerability === 'ALL';
  return (
    <div className={'bid-display-header ' + variant}>
      <div
        className={
          'bid-display-column bg-base-300' + (ewVul ? ' vulnerable !text-black ' : ' ') + variant
        }
      >
        {startWithEast
          ? t('bridge.directions_short.E')
          : t('bridge.directions_short.W')}
      </div>
      <div
        className={
          'bid-display-column bg-base-300' + (nsVul ? ' vulnerable !text-black ' : ' ') + variant
        }
      >
        {startWithEast
          ? t('bridge.directions_short.S')
          : t('bridge.directions_short.N')}
      </div>
      <div
        className={
          'bid-display-column bg-base-300' + (ewVul ? ' vulnerable !text-black ' : ' ') + variant
        }
      >
        {startWithEast
          ? t('bridge.directions_short.W')
          : t('bridge.directions_short.E')}
      </div>
      <div
        className={
          'bid-display-column bg-base-300' + (nsVul ? ' vulnerable !text-black ' : ' ') + variant
        }
      >
        {startWithEast
          ? t('bridge.directions_short.N')
          : t('bridge.directions_short.S')}
      </div>
    </div>
  );
}

function BiddingRow({
  row,
  onBidClick,
  variant = 'standard',
  hideAlertsIndex,
  biddingRowIndex,
  componentId,
}) {
  return (
    <>
      <div className={'bid-display-row ' + variant}>
        {row.map(({ bid, index }, i) => {
          if (bid === '?') {
            return (
              <div key={i} className={'bid-display-bid ' + variant}>
                <QuestionIcon width="36px" className="normal" />
              </div>
            );
          }
          let explanation = '';

          const alert = bid.indexOf('[');
          const alertA = bid.indexOf('[@') > -1;

          if (alert > -1) {
            if (hideAlertsIndex !== i) {
              explanation = bid.substring(
                alert + (alertA ? 3 : 2),
                bid.length - 2
              );
              explanation = replaceSuitShorthandWithSymbols(explanation);
            }
            bid = bid.substring(0, alert);
          }

          const tooltipId = `${componentId}-${biddingRowIndex}-${i}`;


          const parts = explanation.replaceAll('——', '\n').split('\n').map(part => part.trim());

          return (
            bid && (
              <div key={i} className={'bid-display-bid ' + variant}>
                {bid !== '.' && (
                  <>
                    <div className="indicator" data-tooltip-id={tooltipId}>
                      {explanation && (
                        <span
                          className={`badge indicator-item h-[8px] px-[3px] ${
                            alertA ? 'badge-info' : 'badge-error'
                          }`}
                        />
                      )}
                      <div
                        onClick={
                          onBidClick &&
                          function () {
                            onBidClick(bid, index, i);
                          }
                        }
                      >
                        <BidDisplay
                          bid={bid}
                          size={variant === 'standard' ? 40 : 30}
                        />
                      </div>
                    </div>
                    {explanation && (
                      <Tooltip
                        id={tooltipId}
                        sx="slated"
                      >
                        {parts.map((part, index) => (
                          <span key={index}>
                            {part.replaceAll('—', '-')}
                            {index !== parts.length - 1 && <br />}
                          </span>
                        ))}
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            )
          );
        })}
      </div>
    </>
  );
}

function partitionBids(bids) {
  const output = [];
  for (let i = 0; i < bids.length; i += 4) {
    output[output.length] = bids.slice(i, i + 4);
  }
  return output;
}

function generateUniqueId() {
  return Math.random().toString(16).slice(2)
}

export default function BiddingDisplay({
  bidding,
  onBidClick,
  vulnerability,
  variant = 'standard',
  startWithEast = false,
  hideAlertsIndex,
}) {
  const [componentId] = useState(generateUniqueId());
  let bids = getBidArrayWithAlertsIncludingPositionalSymbols(bidding);

  let bidObjects = bids.map((b, i) => ({ bid: b, index: i }));

  if (startWithEast) {
    if (bids.length >= 2 && bids[0] === '.' && bids[1] === '.') {
      bidObjects = bidObjects.slice(2);
    } else {
      bidObjects = [{ bid: '.', index: -2 }, { bid: '.', index: -1 }].concat(bidObjects);
    }
  }

  const biddingRowsRef = useRef(null);

  useEffect(() => {
    biddingRowsRef.current.scrollTop = biddingRowsRef.current.scrollHeight;
  }, [bidding]);

  return (
    <div className={'bid-display-container fade-in bg-accent bg-opacity-40 ' + variant}>
      <Header
        vulnerability={vulnerability}
        variant={variant}
        startWithEast={startWithEast}
      />
      <div className={'bid-display-rows ' + variant} ref={biddingRowsRef}>
        {partitionBids(bidObjects).map((biddingRound, i) => {
          return (
            <BiddingRow
              key={i}
              biddingRowIndex={i}
              row={biddingRound}
              onBidClick={onBidClick}
              variant={variant}
              hideAlertsIndex={hideAlertsIndex}
              componentId={componentId}
            />
          );
        })}
      </div>
    </div>
  );
}
