import Alert from './alert/alert';
import { useAppStateStore } from '../appStateStore.jsx';

export default function AdminMessage() {
  const metadata = useAppStateStore((state) => state.metadata);

  if (!metadata?.adminMessage) {
    return null;
  }

  return (
    <Alert
      text={metadata.adminMessage}
      severity={metadata.adminMessageSeverity || 'info'}
    />
  );
}
