import { useEffect, Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { initiateAccountRemoval } from '../../firebase/userApi'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Alert from '../../components/alert/alert';
import { LeadersNavbar } from '../../components/areaCards/leaders';
import CardGrid from '../../components/cardGrid/cardGrid';
import { ChallengeIcon } from '../../components/icons/challengeIcon';
import SessionCard from '../../components/session/sessionCard';
import { sessionsObservableLimit } from '../../firebase/biddingSessions';
import NewUser from '../../components/newUser/newUser';
import AdminMessage from '../../components/AdminMessage';
import { shouldShowOnboarding } from '../../util/onboarding';
import { HandshakeIcon } from '../../components/icons/handShakeIcon';
import { DOUBLE_OPT_IN, hasClaimedAchievement } from '../../util/achievements';
import { useAppStateStore } from '../../appStateStore.jsx';
import {
  useActiveSubs,
  useAuth,
  useAvatarAndMessagesAndTicketsNavbarItems,
} from '../../util/hooks.jsx'
import { getNumberOfNewSpots } from 'cuebids-firebase/spots'
import { addDays } from '../../firebase/groups.js'
import { filterFeds } from '../../util/feds.js'
import { getSpotsLastSeenTimestampUserField } from '../../util/spots.js'
import { useQuery } from '@tanstack/react-query'
import { getSessionForEvent } from '../../firebase/events.js'
import { isAdmin } from '../../util/admin.js'
import { UserDisplaySmall } from '../../components/users/userDisplay.jsx'
import Invite from '../../components/invite/Invite.jsx';
import { goToStoreHeart } from '../../util/goToStore.js';
import { getLatestRankedChallengeHistoryObservable } from 'cuebids-firebase/challenge'
import ChallengeHistory from '../challenge/challengeComponents/ChallengeHistory'
import { useTranslation } from 'react-i18next';

export default function StartPage() {
  const { currentUser } = useAuth();

  const { t } = useTranslation();

  const eventInvites = useAppStateStore((state) => state.eventInvites);

  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const updateNumberOfNewSpots = useAppStateStore((state) => state.updateNumberOfNewSpots);
  const showCompleted = useAppStateStore((state) => state.showCompleted);
  const sessions = useAppStateStore((state) => state.sessions);
  const sessionId = useAppStateStore((state) => state.sessionId);
  const user = useAppStateStore((state) => state.user);
  const metadata = useAppStateStore((state) => state.metadata);
  const achievements = useAppStateStore((state) => state.achievements);
  const matchmaking = useAppStateStore((state) => state.matchmaking);
  const challengeMatchmaking = useAppStateStore((state) => state.challengeMatchmaking);
  const challengeQueue = useAppStateStore((state) => state.challengeQueue);
  const isNative = useAppStateStore((state) => state.isNative);
  const iap = useAppStateStore((state) => state.iap);
  const singlePlayerRobotErrors = useAppStateStore((state) => state.singlePlayerRobotErrors);
  const clearSinglePlayerRobotErrors = useAppStateStore((state) => state.clearSinglePlayerRobotErrors);

  const weekly = useAppStateStore((state) => state.weekly);
  const daily = useAppStateStore((state) => state.daily);

  const activeSubs = useActiveSubs();
  const highestSub = activeSubs[0];

  const receivedInvites = eventInvites.filter(
    (i) => i.invitee === currentUser.uid
  );
  const hasInvites = receivedInvites.length > 0

  const handleClickStoreHeart = async () => {
    await goToStoreHeart({
      nav,
      isNative,
      iap,
      uid: currentUser.uid,
    });
  };

  const { data: loadedDailySession } = useQuery({
    queryKey: ['loadedDailySession', daily?.id],
    queryFn: async () => {
      if (daily?.id) {
        return await getSessionForEvent('daily', daily?.id)
      } else {
        return null;
      }
    }
  });

  const { data: loadedWeeklySession } = useQuery({
    queryKey: ['loadedWeeklySession', weekly?.id],
    queryFn: async () => {
      if (weekly?.id) {
        return  await getSessionForEvent('weekly', weekly?.id)
      } else {
        return null;
      }
    }
  });

  const dailySession =
      sessions.find((s) => s.daily === daily?.id) || loadedDailySession;
  const weeklySession =
      sessions.find((s) => s.weekly === weekly?.id) || loadedWeeklySession;

  const lastSession = sessionId;
  useEffect(function () {
    if (!user) return;

    const feds = filterFeds(user?.flag);
    const fallbackTimestamp = addDays(new Date(), -10).getTime(); // 10 days ago

    feds.forEach(async function (f) {
      const fed = f.language;
      const c = await getNumberOfNewSpots({
        fed,
        timestamp: user?.[getSpotsLastSeenTimestampUserField(fed)] ?? fallbackTimestamp
      });

      updateNumberOfNewSpots({ [fed]: c });
    });
  }, [user, updateNumberOfNewSpots]);

  useAvatarAndMessagesAndTicketsNavbarItems();

  const nav = useNavigate();

  const updateShowCompleted = (event) => {
    updateAppState({ showCompleted: event.target.checked });
  };

  const handleShowOlderSessions = () => {
    nav('/session/archive');
  };

  const handleRestoreAccount = () => {
    initiateAccountRemoval(false);
    nav('/');
  };

  const showOnboarding = shouldShowOnboarding({ user });

  // const tipOfTheDay = metadata?.tipOfTheDayText;
  const showReleaseLink = metadata?.showReleaseLink;
  const campaign = metadata?.campaign;

  const [challengeHistory, setChallengeHistory] = useState([]);
  useEffect(function () {
    return getLatestRankedChallengeHistoryObservable({ count: 1, callback: setChallengeHistory });
  }, []);

  if (!user) {
    return null;
  }

  const events = <LeadersNavbar key={'leadersNavbar'} />;
  const specialDaily = daily?.headline && (
    <div className="flex flex-col items-center justify-center">
      <button
        className={`btn ${
          campaign === 'blackweek' ? 'bg-black' : 'btn-primary'
        } mr-1`}
        onClick={() =>
          nav(dailySession ? `/dailyLeaderboard/${daily?.id}` : '/daily')
        }
      >
        <div className="flex flex-col">
          <span>{t('routes.daily_event')}</span>
          <span className="text-xs info">{daily?.headline}</span>
        </div>
      </button>
      <span className="info">
        {daily?.pairCount ?? 0} {t('start.pairs')}
      </span>
    </div>
  );
  const specialWeekly = weekly?.headline && (
    <div className='flex flex-col items-center justify-center'>
      <button className={`btn ${campaign === 'blackweek' ? 'bg-black' : 'btn-secondary'} mr-1`} onClick={() => nav(weeklySession ? `/weeklyLeaderboard/${weekly?.id}` : '/weekly')}>
        <div className='flex flex-col'>
          <span>
            {t('routes.weekly_event')}
          </span>
          <span className="text-xs text-white">
            {weekly?.headline}
          </span>
        </div>
      </button>
      <span className='info'>{weekly?.pairCount ?? 0} {t('start.pairs')}</span>
    </div>
  );
  const specialEvent = (specialDaily || specialWeekly) && (
    <div
      key={'specialEvent'}
      className="h-full w-full justify-center items-center"
    >
      <div className='w-full justify-center items-center flex gap-4'>
        {specialDaily}
        {specialWeekly}
      </div>
    </div>
  );

  const campaignMap = {
    'blackweek': '',
    'christmas': '',
  }

  const campaignBannerMap = {
    christmas: (
      <a href={'https://www.cuebids.com/gift?:ext'}>
        <div
          className={
            'cursor-pointer w-full mb-2 p-2 text-center border-x-0 alert-info bg-black bg-opacity-80 border border-sky-300 text-sky-300'
          }
        >
          <strong className={'font-extrabold'}>
            {t('start.gift_friend_yearly')}
          </strong>
        </div>
      </a>
    ),
    blackweek: (
      <div
        className={
          'cursor-pointer w-full mb-2 p-2 text-center border-x-0 alert-info bg-black bg-opacity-80 border border-sky-300 text-sky-300'
        }
        onClick={handleClickStoreHeart}
      >
        <strong className={'font-extrabold'}>Black Week</strong>{' '}
        <span className={''}>
          - <strong>Heart subscription</strong> is{' '}
          <strong
            className={
              'decoration-2 underline-offset-2 underline decoration-secondary'
            }
          >
            free
          </strong>{' '}
          for 7 days -{' '}
          {highestSub === 'heart'
            ? 'tell your partners!'
            : '(New subs) Click here to start!'}
        </span>
      </div>
    ),
  };

  return (
    <>
      <div className={`fade-in ${campaignMap[campaign] ?? ''}`} key={'start'}>
        {showReleaseLink && (
          <Link to={'/releaseNotes'}>
            <div
              className={
                'w-full mb-2 p-2 text-center border-x-0 alert-info bg-info bg-opacity-10 border robot'
              }
            >
              <p>
              Cuebids 3.31 - Two new deal types!{' '}
                <strong
                  className={
                    'underline-offset-2 decoration-2 decoration-secondary underline'
                  }
                >
                Splinter
                </strong>{' '}
              and{' '}
                <strong
                  className={
                    'underline-offset-2 decoration-2 decoration-secondary underline'
                  }
                >
                Opponents Two Suited Overcalls
                </strong>{' '}
              </p>
              <span className={'info'}>Click here the full release notes</span>
            </div>
          </Link>
        )}
        {campaign && campaignBannerMap[campaign]}
        {!showOnboarding && !user?.hiddenSections?.includes('Events') && (
          <Carousel
            autoPlay
            infiniteLoop
            interval={10000}
            stopOnHover
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
          >
            {[
              specialEvent,
              events,
              <ChallengeHistory
                key="challengeHistory"
                challengeHistory={challengeHistory}
                firstUserId={currentUser.uid}
                header={t('start.latest_challenge')}
              />,
            ].filter((e) => e)}
          </Carousel>
        )}
        <div className="page pb-24">
          {user?.removeMe && (
            <Alert
              sx={'m-2 w-1/2'}
              severity="warning"
              onClick={handleRestoreAccount}
              text={t('start.account_removal_warning')}
            />
          )}
          {!showOnboarding && <AdminMessage />}
          {!showOnboarding &&
            !hasClaimedAchievement(achievements, DOUBLE_OPT_IN) &&
            user?.optIn === 'DOUBLE_OPT_IN' && (
            <Alert
              onClick={() => nav('/settings')}
              sx={'w-75'}
              text={t('start.subscribed_claim_pending')}
            />
          )}

          {hasInvites &&
            receivedInvites.map(function (invite) {
              return (
                <Fragment key={invite.id}>
                  <span className={'info mt-3'}>
                    {t('start.invited_to_group_event')}: {invite.eventName}
                  </span>
                  <Invite invite={invite} currentUserId={currentUser.uid} />
                </Fragment>
              );
            })}
          {matchmaking &&
            !user?.hiddenSections?.includes('Matchmaking') &&
            !matchmaking.sessionId && (
            <Alert
              onClick={() => nav('/practice/match')}
              icon={
                <HandshakeIcon
                  className="handshake-animation"
                  fill="#e29627"
                  strokeWidth={0.5}
                  width={24}
                />
              }
              sx={'w-75'}
              text={
                isAdmin(user.id) ? (
                  <div className="flex items-center">
                    <UserDisplaySmall uid={matchmaking.userId} />
                    <span> {t('start.looking_for_partner')}</span>
                  </div>
                ) : (
                  (matchmaking?.userId === currentUser.uid
                    ? t('start.you_are')
                    : t('start.somebody_is')) + t('start.looking_for_partner')
                )
              }
            />
          )}
          {!showOnboarding &&
            !user?.hiddenSections?.includes('Challenges') &&
            challengeQueue.length > 0 && (
            <Alert
              icon={
                <ChallengeIcon
                  className="handshake-animation"
                  fill="#9f4e4e"
                  strokeWidth={0.5}
                  width={24}
                />
              }
              onClick={() => nav('/challenge/match')}
              sx={'w-75'}
              text={
                (challengeMatchmaking && !challengeMatchmaking.challengeId
                  ? t('start.you_are')
                  : t('start.somebody_is')) + t('start.looking_for_challenge')
              }
            />
          )}
          {<CardGrid isNative={isNative} iap={iap} />}
          {showOnboarding && <NewUser />}
          {Object.keys(singlePlayerRobotErrors).length > 0 && (
            <button
              className={'btn btn-error btn-sm my-4'}
              onClick={clearSinglePlayerRobotErrors}
            >
              {t('start.lia_stuck')}
            </button>
          )}
          {sessions.length > 0 && (
            <div
              key={'sessionList'}
              className="flex w-full flex-col items-center justify-center"
            >
              {!showOnboarding && (
                <div className="mt-4 flex w-full justify-center items-center gap-8">
                  <div className="form-control">
                    <label className="label cursor-pointer">
                      <span className="label-text mr-2">{t('start.completed')}</span>
                      <input
                        type="checkbox"
                        className="toggle-primary toggle"
                        checked={showCompleted}
                        onChange={updateShowCompleted}
                      />
                    </label>
                  </div>
                  <Link to="flagged">
                    <button className="btn btn-xs btn-secondary h-6">
                      {t('start.flagged')}
                    </button>
                  </Link>
                </div>
              )}
              <table
                id="session-list"
                className="mt-4 w-full border-collapse rounded-sm md:w-4/5"
              >
                <thead>
                  <tr>
                    <th>{t('start.partner')}</th>
                    <th>{t('start.progress')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {sessions
                    .filter((s) => {
                      if (showCompleted) return true;
                      const {
                        dealsCount,
                        northToAct,
                        southToAct,
                        numberOfFinishedDeals,
                        users,
                        northToRead,
                        southToRead,
                      } = s;
                      const userIndex = users.indexOf(currentUser.uid);
                      const yourTurn = [northToAct, southToAct][userIndex];
                      const unreadMessages = [northToRead, southToRead][
                        userIndex
                      ];
                      const isFinished = numberOfFinishedDeals === dealsCount;

                      return yourTurn || unreadMessages || !isFinished;
                    })
                    .map((s, i) => {
                      return (
                        <SessionCard
                          session={s}
                          key={s.id}
                          currentUser={currentUser}
                          highlight={lastSession === s.id}
                          i={i}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
          {sessions.length === sessionsObservableLimit && (
            <button
              className="btn-secondary btn my-6"
              onClick={handleShowOlderSessions}
            >
              {t('start.show_older_sessions')}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
