import Alert from '../../components/alert/alert';
import AdminMessage from '../../components/AdminMessage';
import { useTranslation } from 'react-i18next'

export default function Maintenance() {
  const { t } = useTranslation();

  return (
    <div className="page">
      <h1>{t('maintenance.title')}</h1>
      <AdminMessage />
      <Alert
        severity="warning"
        text={t('maintenance.warning')}
      />
    </div>
  );
}
