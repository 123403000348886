import { useState } from 'react';
import Dialog from '../../components/dialog';
import { TicketIcon } from '../../components/icons/ticketsIcon';
import { setMessageRead, tryClaimItem } from '../../firebase/messages';
import Alert from '../../components/alert/alert';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useAvatarAndMessagesAndTicketsNavbarItems } from '../../util/hooks.jsx';
import { useNavigate } from 'react-router-dom'
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

function ItemRenderer({ item, onClick, loading, t }) {
  if (item.type === 'tickets') {
    return (
      <button
        onClick={onClick}
        className="btn-primary btn-sm btn w-64 flex items-center justify-center rounded-lg border p-0 "
        disabled={loading || item.claimed}
      >
        <span className="pl-4">
          {item.claimed ? t('already_claimed') : t('claim')}
        </span>
        <div className="flex w-14 flex-col items-center justify-center ">
          <TicketIcon />
          <span className="font-bold text-amber-500">{item.quantity}</span>
        </div>
      </button>
    );
  }
}

export default function Messages() {
  useAvatarAndMessagesAndTicketsNavbarItems();
  const nav = useNavigate();
  const messages = useAppStateStore((state) => state.messages);
  const notify = useNotifications();

  const [openMessageId, setOpenMessageId] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('translation', { keyPrefix: 'messages' });

  function handleSetOpenMessage(msgId) {
    setOpenMessageId(msgId);
    void setMessageRead(msgId);
  }

  const openMessage = messages.find((m) => m.id === openMessageId);

  async function tryClaim(msg) {
    setLoading(true);
    try {
      await tryClaimItem(msg.id);
      notify({ text: t('item_claimed'), type: 'success' });
    } catch (e) {
      notify({ text: e.message, type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  async function markAllAsRead() {
    setLoading(true);
    try {
      await Promise.all(messages.map(async function (m) {
        if (m.item?.claimed === false) {
          try {
            await tryClaimItem(m.id);
          } catch (e) {
            // noop
          }
        }

        if (!m.read) {
          try {
            await setMessageRead(m.id);
          } catch (e) {
            // noop
          }
        }
      }));
    } finally {
      setLoading(false);
    }
  }

  function removeDomainFromLink(link) {
    const url = new URL(link);
    return url.pathname;
  }

  const hasUnreadMessages = messages.some(m => !m.read);

  return (
    <div className="page p-4 pb-24">
      {messages.length === 0 ? (
        <Alert text={t('empty_inbox')} />
      ) : (
        <>
          <button className="mt-4 btn btn-sm h-6 btn-secondary" onClick={markAllAsRead} disabled={loading || !hasUnreadMessages}>
            {t('mark_all_as_read')}
          </button>
          <table className="mt-8 w-full table-auto border-collapse rounded-sm md:w-4/5">
            <thead>
              <tr>
                <th>{t('message')}</th>
                <th>{t('from')}</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((m, i) => {
                return (
                  <tr
                    key={i}
                    onClick={(e) => handleSetOpenMessage(m.id)}
                    className="h-14 cursor-pointer"
                  >
                    <td className="">
                      <div className="indicator max-w-[250px]">
                        {!m.read && (
                          <span className={'badge-error badge indicator-item'}>
                          {t('new')}
                          </span>
                        )}
                        {m.item?.claimed === false && (
                          <span className={'badge-error badge indicator-item'}>
                          {t('item')}
                          </span>
                        )}
                        {m.subject}
                      </div>
                    </td>
                    <td>{m.from}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
      {openMessage && (
        <Dialog
          key={openMessage.id}
          id={openMessage.id}
          open={!!openMessage}
          title={openMessage?.subject}
          onClose={() => setOpenMessageId(false)}
        >
          <div className="flex flex-col items-center justify-center">
            <article className="prose">
              {openMessage?.text}
              <br />
              <p>{t('regards', {from: openMessage.from})}</p>
            </article>
            {openMessage?.url && (openMessage?.url !== 'https://cuebids.com') &&  (
              <div className="mt-4 w-75">
                <button className={'btn btn-sm btn-primary'} onClick={() => nav(removeDomainFromLink(openMessage.url))}>{t('click_here')}</button>
              </div>
            )}
            {openMessage?.item && (
              <div className="mt-4 w-75">
                <ItemRenderer
                  loading={loading}
                  onClick={() => tryClaim(openMessage)}
                  item={openMessage.item}
                  t={t}
                />
              </div>
            )}
          </div>
        </Dialog>
      )}
    </div>
  );
}
