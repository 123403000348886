import { useQuery } from '@tanstack/react-query';
import {
  deleteScenario,
  editScenarioDescription,
  getDealTypeStockSize,
  getExistingGenerationRequest,
  getGenerationRequestObservable,
  getMyScenarios,
  initiateGenerationRequests,
  publishScenario, requestGeneration,
  retryGenerationRequest,
  setGenerationRequestsError, setGenerationRequestsFinished,
  setGenerationRequestsRetry,
  validateHasChangedConstraints,
} from 'cuebids-firebase/dealGeneration'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Animated from '../../components/animation/animated.jsx';
import log from '../../util/logger.js';
import Alert from '../../components/alert/alert.jsx'
import { useActiveSubs, useAuth, useHasLoadedDataToGetSubs } from '../../util/hooks.jsx'
import { isSubEnough } from '../../util/sub.js';
import { SubIcon } from '../../components/subIcons/subIcons.jsx';
import useConfirm from '../../hooks/confirm.jsx';
import { DeleteIcon } from '../../components/icons/deleteIcon.jsx';
import { useAppStateStore } from '../../appStateStore.jsx';
import { TypeIcon } from '../../components/icons/typeIcon.jsx';
import goToStore from '../../util/goToStore.js'
import RequireHeartDialog from '../../marketing/RequireHeartDialog.jsx';
import { ShareIcon } from '../../components/icons/shareIcon.jsx'
import useConfirmWithInput from '../../hooks/confirmWithInput.jsx'
import { LikeIcon } from '../../components/icons/likeIcon.jsx'
import { UserDisplaySmallRight } from '../../components/users/userDisplay.jsx'
import { EditIcon } from '../../components/icons/editIcon.jsx'
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

function GenerationRequest({ scenario, currentRequests, setCurrentRequests, t }) {

  const { data: stockSize, refetch: refetchStockSize, isFetchedAfterMount: hasLoadedStockSize } = useQuery({
    queryKey: ['dealTypeStock', scenario.id],
    queryFn: async () => await getDealTypeStockSize(scenario.id, scenario.minDealNumber),
  });

  const { data: existingGenerationRequestId, refetch: refetchExistingGenerationRequest, isFetchedAfterMount: hasLoadedExistingGenerationRequest } = useQuery({
    queryKey: ['existingGenerationRequest', scenario.id],
    queryFn: async () => await getExistingGenerationRequest(scenario.id),
  });

  const [generationRequestId, setGenerationRequestId] = useState();
  const [generationRequest, setGenerationRequest] = useState();
  const [loading, setLoading] = useState(false);

  const userActiveSubs = useActiveSubs();
  const canUserCreateType = isSubEnough(userActiveSubs, 'heart');

  const notify = useNotifications();

  const hasLoadedComponent = hasLoadedStockSize && hasLoadedExistingGenerationRequest;

  const percentDone = ((generationRequest?.numberOfFinished ?? 0) / (generationRequest?.count ?? 1) * 100).toFixed(0);

  const hasError = generationRequest?.progress === 'ERROR';
  const finishedRequest = generationRequest?.progress === 'FINISHED';

  const diffInMilliseconds = new Date() - generationRequest?.timestamp.toDate();
  const diffInMinutes = diffInMilliseconds / (1000 * 60);
  const expired = diffInMinutes > 10;
  const allowed = !loading && canUserCreateType && stockSize < 48 ;
  const maximumRequests = 3;
  const canRequest = allowed && !generationRequestId && currentRequests < maximumRequests;
  const canRetry = allowed && generationRequest && (expired || hasError) && currentRequests < maximumRequests;

  const [ConfirmGeneration, confirmGeneration] = useConfirm(
    'manage_deal_types.generation_confirm_title',
    'manage_deal_types.generation_confirm_message',
    'secondary',
    'primary',
    'scenarios.high_quality',
    'scenarios.fast_generation',
  );

  const request = async (scenario) => {
    if(!canRequest) return;
    setLoading(true);
    let requestId;
    setCurrentRequests((curr) => curr + 1);
    try {
      let speed = await confirmGeneration();
      let sims = speed ? 20 : 5;
      let url = import.meta.env.VITE_ENDPLAYS_API_URL
      requestId = await initiateGenerationRequests(scenario, sims);
      setGenerationRequestId(requestId);
      await requestGeneration(url, scenario, {
        id: requestId,
        count: 12,
        sims
      });
    } catch (error) {
      if (requestId) {
        await setGenerationRequestsError(requestId)
      }
      notify(
        {
          text: t('manage_deal_types.generation_error'),
          type: 'failure',
          autoHide: 10000,
        },
      );
    } finally {
      setCurrentRequests((curr) => curr - 1);
      setLoading(false);
    }
  }

  const retry = async () => {
    if (!canRetry) return;
    try {
      setLoading(true);
      await setGenerationRequestsRetry(generationRequestId)
      setCurrentRequests((curr) => curr + 1);
      if (generationRequest.count - generationRequest.numberOfFinished <= 0) {
        await setGenerationRequestsFinished(generationRequestId);
        setLoading(false);
        return;
      }
      let url = import.meta.env.VITE_ENDPLAYS_API_URL
      await retryGenerationRequest(url, scenario, {
        id: generationRequestId,
        count: generationRequest.count - generationRequest.numberOfFinished,
        sims: generationRequest.sims,
      });
    } catch (error) {
      await setGenerationRequestsError(generationRequestId)
      notify(
        {
          text: t('manage_deal_types.generation_retry_error'),
          type: 'failure',
          autoHide: 10000,
        },
      );
    } finally {
      setCurrentRequests((curr) => curr - 1);
      setLoading(false);
    }
  }

  useEffect(() => {
    async function refetchIfFinished() {
      if (generationRequestId && finishedRequest) {
        await refetchExistingGenerationRequest();
        await refetchStockSize();
        setGenerationRequestId(undefined)
        setGenerationRequest(undefined)
        log('refetched')
      }
    }

    void refetchIfFinished();

  }, [finishedRequest, refetchExistingGenerationRequest, generationRequestId, refetchStockSize]);


  useEffect(function () {
    if (existingGenerationRequestId) {
      setGenerationRequestId(existingGenerationRequestId);
    }
  }, [existingGenerationRequestId]);

  useEffect(function () {
    if (generationRequestId) {
      return getGenerationRequestObservable({
        id: generationRequestId,
        callback: function (result) {
          setGenerationRequest(result);
        }
      });
    }
  }, [generationRequestId]);


  if (!hasLoadedComponent) {
    return (
      <td colSpan={2} className="h-14"></td>
    );
  }

  return (
    <>
      <td className={'w-24 px-2'}>
        <div className={'absolute'}>
          <ConfirmGeneration />
        </div>
        <div className="stat-value">{stockSize ?? 0}</div>
      </td>
      <td className={'w-64 hidden sm:table-cell'}>
        {(hasError || expired) ? (
          <div className="text-error">{t('manage_deal_types.generation_error_text')}</div>
        ): (
          <progress className="progress progress-secondary w-56" value={(generationRequest?.numberOfFinished ?? 0)} max={(generationRequest?.count ?? 0)}></progress>
        )}
      </td>
      <td className={'text-right w-28'}>
        {(hasError || expired) ? (
          <button
            disabled={
              !canRetry
            } className={'btn w-20 btn-error'} onClick={() => retry(scenario)}>
            {t('manage_deal_types.retry')}
          </button>
        ) : (
          <button
            disabled={
              !canRequest
            } className={'btn w-20 md:w-24 btn-secondary'} onClick={() => request(scenario)}>
            {
              generationRequestId ?
                <div className="radial-progress radial-progress-sm text-primary" style={{ '--value':percentDone, '--size':'3rem' }}>{percentDone}%</div>
                : stockSize >= 48 ?
                  t('manage_deal_types.stock_full')
                  : t('manage_deal_types.generate_more')
            }
          </button>
        )}
      </td>
    </>
  );
}

export default function ManageDealTypes() {
  const { isFetchedAfterMount, data: scenarios, refetch } = useQuery({
    queryKey: ['myScenarios'],
    queryFn: async () => await getMyScenarios(),
  });

  const { currentUser } = useAuth();
  const isNative = useAppStateStore((state) => state.isNative);
  const metadata = useAppStateStore((state) => state.metadata);
  const hasMaintenance = metadata?.maintenanceScenarios
  const iap = useAppStateStore((state) => state.iap);
  const navigate = useNavigate();
  const { t } = useTranslation()

  const notify = useNotifications();

  const [promptToSubscribe, setPromptToSubscribe] = useState(false);

  const userActiveSubs = useActiveSubs();
  const hasLoadedDataToGetSubs = useHasLoadedDataToGetSubs();

  const [ currentRequests, setCurrentRequests ] = useState(0)

  const canUserCreateType = isSubEnough(userActiveSubs, 'heart');


  const genTokens = Infinity

  const [ConfirmDelete, confirmDelete] = useConfirm(
    'scenarios.delete_confirm_title',
    'scenarios.delete_confirm',
    'error',
    'secondary'
  );
  const [ConfirmShare, confirmShare] = useConfirmWithInput(
    t('manage_deal_types.share_confirm_title'),
    t('manage_deal_types.share_confirm_message')
  );
  const [ConfirmEditDescription, confirmEditDescription] = useConfirmWithInput(
    t('manage_deal_types.edit_description_confirm_title'),
    t('manage_deal_types.edit_description_confirm_message')
  );

  useEffect(() => {
    if (hasLoadedDataToGetSubs && !canUserCreateType) {
      setPromptToSubscribe(true)
    }
  }, [canUserCreateType, hasLoadedDataToGetSubs]);

  async function handleDelete(id, parentId) {
    const confirmed = await confirmDelete();
    if (confirmed) {
      await deleteScenario(id, parentId);
      await refetch();
    }
  }

  async function handleShare(scenario) {
    const isValid = await validateHasChangedConstraints(scenario.directions, scenario.parentId);
    if (!isValid) {
      notify(
        {
          text: t('manage_deal_types.share_unchanged_error'),
          type: 'failure',
        },
      );
      return
    }

    const msg = await confirmShare();
    if (msg) {
      await publishScenario(scenario.id, msg);
      await refetch();
    }
  }

  async function handleUpdateDescription(scenarioId) {
    const msg = await confirmEditDescription();
    if (msg) {
      await editScenarioDescription(scenarioId, msg);
      await refetch();
    }
  }

  return (
    <div className="page pb-24">
      {
        promptToSubscribe && <RequireHeartDialog onClose={() => setPromptToSubscribe(false)} />
      }
      <div className="mt-4 flex flex-col md:flex-row gap-4 md:gap-8 items-end">
        <button
          className={'btn btn-accent'}
          onClick={() => navigate('/communityScenarios')}
        >
          {t('manage_deal_types.scenario_market')}
        </button>
        <button
          className={'btn btn-primary relative pr-10'}
          disabled={!canUserCreateType}
          onClick={() => navigate('/createDealType')}
        >
          {t('manage_deal_types.crate_scenario')}
          <div className="absolute right-2">
            <SubIcon sub={'heart'} />
          </div>
        </button>
      </div>
      {
        <div className={'flex flex-col items-center mt-4'}>
          <div className={'flex gap-2 mb-2 opacity-70'}>
            <TypeIcon className="stroke-scenarios" width={24} /> <span>{t('manage_deal_types.generation_tokens')}<strong className="text-scenarios">{genTokens}</strong></span>
          </div>
          <span className={'info'}>{t('manage_deal_types.beta_info_1')}<strong>{t('manage_deal_types.beta_info_2')}</strong>{t('manage_deal_types.beta_info_3')}<span className={'text-scenarios'}>{t('manage_deal_types.beta_info_4')}</span>{t('manage_deal_types.beta_info_5')}</span>
          <span className={'info'}>{t('manage_deal_types.beta_info_6')}<span className={'text-scenarios'}>{t('manage_deal_types.beta_info_7')}</span>{t('manage_deal_types.beta_info_8')}</span>
        </div>
      }
      {hasMaintenance && (
        <Alert
          severity="warning"
          text={t('manage_deal_types.maintenance_warning')}
        />
      )}
      {canUserCreateType ? (
        <div className='w-full flex flex-wrap gap-2 mt-4 items-center justify-center flex-col'>
          {
            !isFetchedAfterMount && <span className="loading loading-lg"></span>
          }
          <table
            id="session-list"
            className="mt-4 w-full border-collapse rounded-sm md:w-4/5"
          >
            <thead>
              <tr>
                <th>{t('manage_deal_types.th_scenario')}</th>
                <th>{t('manage_deal_types.th_stock')}</th>
                <th className={'hidden sm:table-cell'}>{t('manage_deal_types.th_progress')}</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {
                isFetchedAfterMount && scenarios && scenarios.map((scenario, i) => (
                  <Animated
                    element="tr"
                    variants={{
                      hidden: (i) => ({
                        opacity: 0,
                      }),
                      visible: (i) => ({
                        opacity: 1,
                        transition: {
                          delay: i * 0.02,
                        },
                      }),
                    }}
                    initial="hidden"
                    exit="hidden"
                    animate="visible"
                    custom={i}
                    key={scenario.id}
                  >
                    <td>
                      <Link
                        to={`/editDealType/${scenario.id}`}
                        className='btn w-36 btn-primary flex justify-around items-center text-xs'
                      >
                        {scenario.name}
                      </Link>
                      {scenario.published ? (
                        <div className="flex items-center pt-2 pb-4">
                          <span className={'info mr-1'}>by</span>
                          <UserDisplaySmallRight uid={scenario.uid} />
                        </div>) : scenario.parentCreatorUid && (
                        <div className="flex items-center pt-2 pb-4">
                          <span className={'info mr-1'}>{t('manage_deal_types.by')}</span>
                          <UserDisplaySmallRight uid={scenario.parentCreatorUid} />
                        </div>)}
                    </td>
                    {hasMaintenance ? <td colSpan={3}><span className="info">{t('manage_deal_types.maintenance_info')}</span></td> :
                      <GenerationRequest scenario={scenario} currentRequests={currentRequests} setCurrentRequests={setCurrentRequests} t={t} />}

                    <td>
                      <div className="flex">
                        <button className="btn-ghost btn-sm btn px-2" onClick={() => handleDelete(scenario.id, scenario.parentId)}>
                          <DeleteIcon className="fill-red-400" />
                        </button>
                        {scenario.published ? (
                          <div className="flex flex-col gap-2">
                            <div
                              className="flex items-center"
                            >
                              <LikeIcon className={'stroke-rose-400 fill-rose-400'} />
                              <span>{scenario.numberOfClones}</span>
                            </div>
                            <button onClick={() => handleUpdateDescription(scenario.id)}>
                              <EditIcon className="stroke-primary h-5" />
                            </button>
                          </div>
                        ) : (
                          <button className="btn-ghost btn-sm btn px-2" onClick={() => handleShare(scenario)}>
                            <ShareIcon className="fill-primary" />
                          </button>
                        )}
                      </div>
                    </td>
                  </Animated>
                ))}
            </tbody>
          </table>
          <ConfirmDelete />
          <ConfirmShare />
          <ConfirmEditDescription />
        </div>
      ) : (
        <div className={'flex flex-col items-center'}>
          <Alert
            sx="mt-4"
            severity="warning"
            text={t('manage_deal_types.heart_sub_info')}
          />
          <button
            className={'btn btn-secondary mt-4'}
            onClick={() => goToStore({
              nav: navigate,
              isNative,
              iap,
              uid: currentUser.uid,
              tab: 'sub',
            })}
          >
            {t('manage_deal_types.subscribe')}
          </button>
        </div>
      )}
    </div>
  );
}
