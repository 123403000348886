import { useAppStateStore } from '../../appStateStore.jsx';

export function UserDependentPage({ children }) {
  const user = useAppStateStore((state) => state.user);

  if (!user) return null;

  // TODO: Pass user to children. Note: React docs warn about using cloneElement, instead preferring for example context. So maybe the current version is best
  // https://react.dev/reference/react/cloneElement#alternatives
  return (
    <>
      {children}
    </>
  );
}
