import { useEffect, useState } from 'react';
import { Star } from '../../components/rating/starRating';
import PairDisplay from '../../components/users/pairDisplay';
import {
  getAllPairPerformanceReportsForGroups,
} from 'cuebids-firebase/stats'
import { useQuery } from '@tanstack/react-query'
import { UserDisplaySmall } from '../../components/users/userDisplay.jsx'
import { Tab, TabPanel, Tabs } from '../../components/tabs/tabs.jsx'
import Alert from '../../components/alert/alert.jsx'
import { useNavigate, useSearchParams } from 'react-router-dom'

function PairPerformanceTab({
  pairs,
  t,
}) {

  if (!pairs.length) {
    return (
      <div className="w-full flex flex-col mt-4 items-center">
        <Alert text={t('stats.no_pair_info')} />
      </div>
    );
  }

  return (
    <div className="card w-full flex flex-col mt-4 items-center">
      <div className="card w-full">
        <div className="card-body flex flex-col items-center justify-center rounded-xl shadow-xl">
          <table
            id="pair-performance"
            className="w-full border-collapse rounded-sm"
          >
            <thead>
              <tr>
                <th>{t('stats.pair')}</th>
                <th className="pl-0">{t('stats.deals')}</th>
                <th className="pl-0 text-end pr-5">{t('stats.stars')}</th>
              </tr>
            </thead>
            <tbody className="shadow-lg">
              {pairs.map((p) => {
                return (
                  <tr
                    key={p.users}
                  >
                    <td className="p-2">
                      <PairDisplay uid1={p.users[0]} uid2={p.users[1]} />
                    </td>
                    <td>
                      <span>{p.dealsFinished}</span>
                    </td>
                    <td>
                      <div className="flex justify-end items-center h-full">
                        {p.averageGrade.toFixed(2)}
                        <Star />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function PlayersPerformanceTab({
  players,
  t,
}) {

  if (!players.length) {
    return (
      <div className="w-full flex flex-col mt-4 items-center">
        <Alert text={t('no_player_info')} />
      </div>
    );
  }

  return (
    <div className="card w-full flex flex-col mt-4 items-center">
      <div className="card w-full">
        <div className="card-body flex flex-col items-center justify-center rounded-xl shadow-xl">
          <table
            id="pair-performance"
            className="w-full border-collapse rounded-sm"
          >
            <thead>
            <tr>
              <th>{t('stats.pair')}</th>
              <th className="pl-0">{t('stats.deals')}</th>
              <th className="pl-0 text-end pr-5">{t('stats.stars')}</th>
            </tr>
            </thead>
            <tbody className="shadow-lg">
            {players.map((p) => {
              return (
                <tr
                  key={p.uid}
                  >
                    <td className="p-2">
                      <UserDisplaySmall uid={p.uid} />
                    </td>
                    <td>
                      <span>{p.dealsFinished}</span>
                    </td>
                    <td>
                      <div className="flex justify-end items-center h-full">
                        {p.averageGrade.toFixed(2)}
                        <Star />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function Tabulars({ pairs, players, groupId, t }) {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('statsTab') ?? 'pairs';
  const navigate = useNavigate();

  const handeTabChange = (tabId) => {
    navigate(`/groups/${groupId}?tab=stats&statsTab=${tabId}`, { replace: true });
  };

  return (
    <div className="w-full mt-4">
      <Tabs>
        <Tab
          key={'pairs'}
          label={t('stats.tab_pairs')}
          value="pairs"
          active={tab === 'pairs'}
          onChange={handeTabChange}
        />
        <Tab
          key={'players'}
          label={t('stats.tab_players')}
          value={'players'}
          active={tab === 'players'}
          onChange={handeTabChange}
        />
      </Tabs>
      <TabPanel key={'pairsPanel'} value={tab} index={'pairs'}>
        <PairPerformanceTab
          pairs={pairs}
          t={t}
        />
      </TabPanel>
      <TabPanel key={'playersPanel'} value={tab} index={'players'}>
        <PlayersPerformanceTab
          players={players}
          t={t}
        />
      </TabPanel>
    </div>
  );
}

function addPlayerData(a, v, p) {
  const oldValue = a[p] ?? {
    uid: p,
    dealsFinished: 0,
    averageGrade: 0,
  };

  const totalStars = oldValue.dealsFinished * oldValue.averageGrade + v.dealsFinished * v.averageGrade;
  const dealsFinished = oldValue.dealsFinished + v.dealsFinished;
  const averageGrade = totalStars / dealsFinished;

  a[p] = {
    uid: p,
    dealsFinished,
    averageGrade,
  };
}

export default function GroupStats({ groupId, members, t }) {
  const [pairs, setPairs] = useState([]);
  const [players, setPlayers] = useState([]);

  const [showMore, setShowMore] = useState(false);

  const useTabs = true;

  const { data } = useQuery({
    queryKey: ['group-report', groupId],
    queryFn: async () => await getAllPairPerformanceReports(),
  });

  useEffect(() => {
    if (!data) return;
    const pairsData = data.result
      .filter((r) => r.users.every(userId => members.includes(userId)))
      .sort((a, b) => b.dealsFinished - a.dealsFinished)
      .slice(0, (showMore ? 100 : 10))
    setPairs(pairsData);

    const playersDataObject = data.result
      .reduce(function(a, v) {
        const p1 = v.users[0];
        const p2 = v.users[1];

        if (members.includes(p1)) {
          addPlayerData(a, v, p1);
        }
        if (members.includes(p2)) {
          addPlayerData(a, v, p2);
        }

        return a;
      }, {});

    const playersData = Object.values(playersDataObject)
      .slice(0, (showMore ? 100 : 10))
      .sort((a, b) => b.dealsFinished - a.dealsFinished);
    setPlayers(playersData);
  } , [members, showMore, data])

  async function getAllPairPerformanceReports() {

    const minTimestamp = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
    const pairPerformance = await getAllPairPerformanceReportsForGroups(members, minTimestamp);

    const result = [];
    pairPerformance.reduce(function (res, value) {
      const key = value.users.join('');
      if (!res[key]) {
        res[key] = {
          users: value.users,
          dealsFinished: 0,
          averageGrade: 0,
        };
        result.push(res[key]);
      }
      res[key].averageGrade =
        (res[key].averageGrade * res[key].dealsFinished +
          value.averageGrade * value.dealsFinished) /
        (value.dealsFinished + res[key].dealsFinished);
      res[key].dealsFinished += value.dealsFinished;
      return res;
    }, {});
    return { result, pairPerformance };
  }

  if (useTabs) {
    return (
      <div className="dashboard-wrapper w-full flex flex-col justify-center items-center">

        <h1 className='text-xl'>{t('stats.title')}</h1>
        <span className='info'>{t('stats.info')}</span>
        <Tabulars
          pairs={pairs}
          players={players}
          groupId={groupId}
          t={t}
        />
        <button className='btn btn-primary btn-sm h-10 mt-4' onClick={() => setShowMore(!showMore)}>{showMore ? t('stats.show_fewer') : t('stats.show_more')}</button>
      </div>
    );
  }
}
