import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  where,
  orderBy,
  deleteDoc,
  Timestamp,
  addDoc,
} from 'firebase/firestore';
import { auth, db } from './firebase';
import {
  firstDocToObject,
  queryFirstItemToObject,
  queryListenFirstItemWithCallback,
} from 'firebase-util';
import { createPracticeSession } from './biddingSessions';
import { createMatchmakingSuccessfulNotification } from 'cuebids-firebase/notifications';
import i18next from 'i18next'

const MATCHMAKING_COLLECTION_STRING = 'matchmaking';

export async function getMatchmakingForUser(userId) {
  const matchmakingRef = collection(db, MATCHMAKING_COLLECTION_STRING);
  const q = query(
    matchmakingRef,
    where('userId', '==', userId),
    orderBy('timestamp', 'desc'),
    limit(1),
  );

  return await queryFirstItemToObject(q);
}

async function startMatchmaking(userId) {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const matchmakingRef = collection(db, MATCHMAKING_COLLECTION_STRING);
  return await addDoc(matchmakingRef, {
    userId,
    timestamp: Date.now(),
    sessionId: null,
    ttl: Timestamp.fromDate(date),
  });
}

export async function cancelMatchmakingPracticeSession() {
  const currentUser = auth.currentUser;
  const existingMatchmakingForUser = await getMatchmakingForUser(
    currentUser.uid,
  );
  if (!existingMatchmakingForUser) {
    return;
  }
  await deleteDoc(
    doc(
      collection(db, MATCHMAKING_COLLECTION_STRING),
      existingMatchmakingForUser.id,
    ),
  );
}

export async function createMatchmakingPracticeSession({ minDealNumber }) {
  const currentUser = auth.currentUser;
  const existingMatchmakingForUser = await getMatchmakingForUser(
    currentUser.uid,
  );
  if (existingMatchmakingForUser && !existingMatchmakingForUser.sessionId) {
    return;
  }

  const matchmakingRef = collection(db, MATCHMAKING_COLLECTION_STRING);
  const q = query(
    matchmakingRef,
    where('userId', '!=', currentUser.uid),
    where('sessionId', '==', null),
    limit(1),
  );

  const matchmakingDocs = await getDocs(q);
  const matchmaking = firstDocToObject(matchmakingDocs);

  if (matchmaking) {
    const matchmakingRef = matchmakingDocs.docs[0].ref;

    try {
      const { batch, sessionRef } = await createPracticeSession({
        userTwoId: matchmaking.userId,
        numberOfDeals: 10,
        compete: 1,
        minDealNumber: minDealNumber,
        matchmaking: true,
        returnBatch: true,
        price: 0,
      });

      batch.update(matchmakingRef, {
        sessionId: sessionRef.id,
      });

      await batch.commit();

      void createMatchmakingSuccessfulNotification(
        matchmaking.userId,
        currentUser.displayName,
        sessionRef.id,
      );

      return sessionRef.id;
    } catch (e) {
      throw new Error(i18next.t('practice.match.error_matchmaking_failed'));
    }
  } else {
    await startMatchmaking(currentUser.uid);
  }
}

export function getMatchmakingObservable(callback) {
  const matchmakingRef = collection(db, MATCHMAKING_COLLECTION_STRING);
  const q = query(matchmakingRef, orderBy('timestamp', 'desc'), limit(1));
  return queryListenFirstItemWithCallback(q, callback);
}
