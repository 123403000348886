import Dialog from '../components/dialog/index.jsx';
import { useAppStateStore } from '../appStateStore.jsx';
import { useNavigate } from 'react-router-dom';
import { goToStoreDiamond } from '../util/goToStore.js';
import { useAuth } from '../util/hooks.jsx'
import { useTranslation } from 'react-i18next';

export default function RequireDiamondDialog({ onClose }) {
  const isNative = useAppStateStore((state) => state.isNative);
  const iap = useAppStateStore((state) => state.iap);
  const nav = useNavigate();
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  

  const hasTrial = false;

  const handleGoToDiamond = async () => {
    await goToStoreDiamond({
      nav,
      isNative,
      iap,
      uid: currentUser.uid,
    });
  }

  return (
    <Dialog open={true} title={t('marketing.unlock_more')} onClose={onClose}>
      <div className="overflow-x-auto w-full text-center gap-8 flex flex-col">
        <h2>{t('marketing.features_requires')}<strong className={'decoration-2 underline-offset-2 decoration-diamond-50 underline'}>{t('marketing.diamond_sub')}</strong></h2>

        <div className={'w-full flex flex-col items-center justify-center mt-2'}>
          <button className={'btn btn-primary text-xl'} onClick={handleGoToDiamond}>
            {
              hasTrial ? t('marketing.start_trial') : t('marketing.subscribe')
            }
          </button>
        </div>
      </div>
    </Dialog>
  );
}
