import axios from 'axios';
import { getCountFromServer, limit, orderBy, query, serverTimestamp, startAfter, Timestamp, updateDoc, where } from 'firebase/firestore';
import { currentUserId, getRepos } from '../base';
import { docListenWithCallback, docToObject, queryFirstItemToObject } from 'firebase-util';
import { getToken } from '../users';
import { scenarioToDirections } from './utils';
export async function requestDealTypeSample(url, directions) {
    const token = await getToken();
    const response = await axios.post(url + 'deal/sample', directions, {
        headers: {
            Authorization: `Bearer ${token}`,
            'x-cuebids': 'vpH1ddRYgsMI4j25QaTZ0Q3EWjV2'
        }
    });
    return response.data;
}
export async function requestGeneration(url, scenario, meta) {
    const token = await getToken();
    const directions = scenarioToDirections(scenario);
    const request = {
        directions,
        count: meta.count,
        community_scenario_id: scenario.id,
        name: scenario.name,
        uid: scenario.uid,
        generation_request_id: meta.id,
        sims: meta.sims
    };
    const response = await axios.post(url + 'deal/generation', request, {
        headers: {
            Authorization: `Bearer ${token}`,
            'x-cuebids': 'vpH1ddRYgsMI4j25QaTZ0Q3EWjV2'
        }
    });
    return response.data;
}
export async function retryGenerationRequest(url, scenario, meta) {
    return requestGeneration(url, scenario, meta);
}
export async function initiateGenerationRequests(scenario, sims) {
    if (scenario.id == null) {
        return;
    }
    const directionsToUse = scenarioToDirections(scenario);
    const gr = {
        name: scenario.name,
        community_scenario_id: scenario.id,
        directions: directionsToUse,
        count: 12,
        sims,
        numberOfFinished: 0,
        dealIds: [],
        uid: currentUserId(),
        timestamp: serverTimestamp(),
        progress: 'CREATED'
    };
    const newDoc = await getRepos().generationRequests.add?.(gr);
    return newDoc?.id;
}
export async function setGenerationRequestsError(id) {
    const generationRef = getRepos().generationRequests.docRef(id);
    await updateDoc(generationRef, {
        progress: 'ERROR'
    });
}
export async function setGenerationRequestsFinished(id) {
    const generationRef = getRepos().generationRequests.docRef(id);
    await updateDoc(generationRef, {
        progress: 'FINISHED'
    });
}
export async function setGenerationRequestsRetry(id) {
    const generationRef = getRepos().generationRequests.docRef(id);
    await updateDoc(generationRef, {
        progress: 'RETRY',
        timestamp: Timestamp.fromDate(new Date()) // serverTimestamp can return null on first snapshot https://stackoverflow.com/questions/64287252/time-field-servertimestamp-in-cloud-firestore-returns-null-on-the-first-snapsh
    });
}
export async function getDealTypeStockSize(dealType, minDealNumber = 0) {
    const dealTimestampsDoc = await getRepos().dealTimestamps.doc(currentUserId());
    const dealTimestamps = docToObject(dealTimestampsDoc);
    const latestDealNumber = (dealTimestamps?.[dealType]) ?? 0;
    const startAfterDealNumber = Math.max(latestDealNumber, minDealNumber);
    const q = query(getRepos().deals.collection, orderBy('dealNumber'), startAfter(startAfterDealNumber), where('version', '==', 3), where('type', '==', 'tagged'), where('tag', '==', dealType));
    const res = await getCountFromServer(q);
    return res.data().count;
}
export function getGenerationRequestObservable({ id, callback }) {
    const docRef = getRepos().generationRequests.docRef(id);
    return docListenWithCallback(docRef, callback);
}
export async function getExistingGenerationRequest(dealType) {
    const q = query(getRepos().generationRequests.collection, where('community_scenario_id', '==', dealType), where('progress', '!=', 'FINISHED'), limit(1));
    const res = await queryFirstItemToObject(q);
    return res?.id ?? null;
}
