import { ranges } from './constants';

const respondingToPreEmpt = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-(P|D|2.|3C)$/,
    explain: 'Partner open with 2D, we can bid 3D or 4D or 5D',
    options: [
      {
        bid: '5D["To play"]',
        reqD: 5,
        reqHp: ranges.weakRaise,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 11 : 0), // Does not happen when VUL
      },
      {
        bid: '4D["To play"]',
        reqD: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '3D["To play"]',
        reqD: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-(3D|3H|3S|3N|4C)$/,
    explain: 'Partner open with 2D, we can bid 4D or 5D over competition',
    options: [
      {
        bid: '5D["To play"]',
        reqD: 5,
        reqHp: ranges.weakRaise,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 11 : 0), // Does not happen when VUL
      },
      {
        bid: '4D["To play"]',
        reqD: 4,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-(4D|4H|4S|4N|5C)$/,
    explain: 'Partner open with 2D, we can bid 5D in competition',
    options: [
      {
        bid: '5D["To play"]',
        reqD: 5,
        reqHp: ranges.weakRaise,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 11 : 0), // Does not happen when VUL
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-3D-(.|..)$/,
    explain: 'Partner open with 3D, raise?',
    options: [
      {
        bid: '5D["To play"]',
        reqD: 4,
        reqHp: ranges.weakRaise,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 11 : 0), // Does not happen when VUL
      },
      {
        bid: '4D["To play"]',
        reqD: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2H-(.|..)$/,
    explain: 'Partner open with 2H, we can bid 3H or 4H',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '3H["To play"]',
        reqH: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-3H-(P|D|2.|3.|4C|4D)$/,
    explain: 'Partner open with 3H, we can bid 4H',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2S-(.|..)$/,
    explain: 'Partner open with 2S, we can bid 3S or 4S',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '3S["To play"]',
        reqS: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-3S-(P|D|2.|3.|4C|4D|4H)$/,
    explain: 'Partner open with 3S, we can bid 4S',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-3C-(.|..)$/,
    explain: 'Partner open with 3C, raise?',
    options: [
      {
        bid: '5C["To play"]',
        reqC: 4,
        reqHp: ranges.weakRaise,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 11 : 0), // Does not happen when VUL
      },
      {
        bid: '4C["To play"]',
        reqC: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-4C-(.|..)$/,
    explain: 'Partner opens with 4C, raise?',
    options: [
      {
        bid: '5C["To play"]',
        reqC: 3,
        reqHp: ranges.raise,
      },
      {
        bid: '5C["To play"]',
        reqC: 2,
        reqHp: ranges.weakOpen,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-4D-(.|..)$/,
    explain: 'Partner opens with 4D, raise?',
    options: [
      {
        bid: '5D["To play"]',
        reqD: 3,
        reqHp: ranges.raise,
      },
      {
        bid: '5D["To play"]',
        reqD: 2,
        reqHp: ranges.weakOpen,
      },
    ],
  },
];

export default respondingToPreEmpt;
