import club from '../../images/symbols/Club.svg';
import diamond from '../../images/symbols/Diamond.svg';
import heart from '../../images/symbols/Heart.svg';
import spade from '../../images/symbols/Spade.svg';

const subIcon = {
  club: club,
  diamond: diamond,
  heart: heart,
  spade: spade,
};

export function PreloadSubIcon() {
  return Object.keys(subIcon).map((p) => {
    return <img key={p} src={subIcon[p]} alt={'preload'}/>
  })
}

export function SubIcon({ sub }) {
  return <img width={25} height={25} src={subIcon[sub]} alt={sub} />;
}
