import { auth, realDb } from './firebase';
import {
  ref,
  onValue,
  onDisconnect,
  set,
  serverTimestamp,
} from 'firebase/database';

export function startPresenceObservable({ callback }) {
  const connectedRef = ref(realDb, '.info/connected');
  return onValue(connectedRef, (snap) => {
    if (snap.val() === true) {
      callback(true);
      if (auth.currentUser) {
        const uid = auth.currentUser.uid;
        const myConnectionsRef = ref(realDb, `users/${uid}/online`);
        const lastOnlineRef = ref(realDb, `users/${uid}/online`);
        set(myConnectionsRef, true);
        onDisconnect(lastOnlineRef).set(serverTimestamp());
      }
    } else {
      callback(false);
    }
  });
}
