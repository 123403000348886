import { addDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getFunctions, getRepos } from '../base';
// SPOTS - START
export async function createNewBidsOnSpotNotification(userId, fed, spotId, t) {
    await createNotification(userId, t('spots.notifications.bid_on_spot.header'), t('spots.notifications.bid_on_spot.text'), t('spots.notifications.bid_on_spot.url', { spotId }));
}
export async function createFirstBidOnSpotNotification(userId, fed, spotId, t) {
    await createNotification(userId, t('spots.notifications.first_bid_on_spot.header'), t('spots.notifications.first_bid_on_spot.text'), t('spots.notifications.first_bid_on_spot.url', { spotId }));
}
export async function createCommentOnYourBidNotification(userId, userName, message, fed, spotId, t) {
    await createNotification(userId, t('spots.notifications.comment_on_bid.header', { userName }), message, t('spots.notifications.comment_on_bid.url', { spotId }));
}
export async function createCommentOnBidYouFollowNotification(userId, userName, message, fed, spotId, t) {
    await createNotification(userId, t('spots.notifications.comment_on_bid_you_follow.header', { userName }), message, t('spots.notifications.comment_on_bid_you_follow.url', { spotId }));
}
// SPOTS - END
export async function createYourTurnNotification(userId, partner, sessionId) {
    // await createNotification(
    //   userId,
    //   'Your turn',
    //   `Partner ${userName} finished bidding - your turn!`,
    //   'https://cuebids.com/session/' + sessionId
    // )
    await sendPushNotificationByType(userId, 'partner_finished_bidding', 'https://cuebids.com/session/' + sessionId, { partner });
}
export async function createChatNotification(userId, userName, message, url) {
    await sendPushNotification(userId, `${userName}:`, message, url);
}
export async function createSessionStartedNotification(userId, partner, sessionId) {
    // await createNotification(
    //   userId,
    //   'New session',
    //   `Partner ${userName} started a new session!`,
    //   'https://cuebids.com/session/' + sessionId
    // )
    await sendPushNotificationByType(userId, 'partner_started_session', 'https://cuebids.com/session/' + sessionId, { partner });
}
export async function createEventJoinedNotification(userId, partner, sessionId, event) {
    // await createNotification(
    //   userId,
    //   'Event joined',
    //   `You have joined the ${eventName} with partner ${userName}!`,
    //   'https://cuebids.com/session/' + sessionId
    // )
    await sendPushNotificationByType(userId, 'event_joined', 'https://cuebids.com/session/' + sessionId, { partner, event });
}
export async function createSessionFinishedNotification(userId, partner, sessionId) {
    // await createNotification(
    //   userId,
    //   'Session finished',
    //   `Session with partner ${userName} just finished!`,
    //   'https://cuebids.com/session/' + sessionId
    // )
    await sendPushNotificationByType(userId, 'session_finished', 'https://cuebids.com/session/' + sessionId, { partner });
}
export async function createAddedFriendNotification(userId, partner) {
    // await createNotification(
    //   userId,
    //   'New friend',
    //   `Partner ${userName} added you - start bidding!`,
    //   'https://cuebids.com/friends'
    // )
    await sendPushNotificationByType(userId, 'new_friend', 'https://cuebids.com/friends', { partner });
}
export async function createSentYouAFriendRequestNotification(userId, partner) {
    // await createNotification(
    //   userId,
    //   'New friend',
    //   `${userName} sent you a friend request!`,
    //   'https://cuebids.com/friends'
    // )
    await sendPushNotificationByType(userId, 'friend_request', 'https://cuebids.com/friends', { partner });
}
export async function createChallengeNotification(opp1Id, opp2Id, opponent1, opponent2, challengeId) {
    // const p1 = createNotification(
    //   opp1Id,
    //   'Challenge',
    //   `${challenger1Name} and ${challenger2Name} have challenged you!`,
    //   `https://cuebids.com/challenge/${challengeId}`
    // )
    //
    // const p2 = createNotification(
    //   opp2Id,
    //   'Challenge',
    //   `${challenger1Name} and ${challenger2Name} have challenged you!`,
    //   `https://cuebids.com/challenge/${challengeId}`
    // )
    const p1 = sendPushNotificationByType(opp1Id, 'challenged', `https://cuebids.com/challenge/${challengeId}`, { opponent1, opponent2 });
    const p2 = sendPushNotificationByType(opp2Id, 'challenged', `https://cuebids.com/challenge/${challengeId}`, { opponent1, opponent2 });
    await Promise.all([p1, p2]);
}
export async function createMatchmakingSuccessfulNotification(userId, partner, sessionId) {
    // await createNotification(
    //   userId,
    //   'Found a match',
    //   `A session has started with ${userName} - good luck!`,
    //   'https://cuebids.com/session/' + sessionId
    // )
    await sendPushNotificationByType(userId, 'found_matchmaking', 'https://cuebids.com/session/' + sessionId, { partner });
}
export async function createChallengeMatchmakingSuccessfulNotification(opp1Id, opp2Id, opponent1, opponent2, challengeId) {
    // const p1 = createNotification(
    //   opp1Id,
    //   'Found opponents',
    //   `${challenger1Name} and ${challenger2Name} have challenged you!`,
    //   `https://cuebids.com/challenge/${challengeId}`
    // )
    //
    // const p2 = createNotification(
    //   opp2Id,
    //   'Found opponents',
    //   `${challenger1Name} and ${challenger2Name} have challenged you!`,
    //   `https://cuebids.com/challenge/${challengeId}`
    // )
    const p1 = sendPushNotificationByType(opp1Id, 'found_challenge', `https://cuebids.com/challenge/${challengeId}`, { opponent1, opponent2 });
    const p2 = sendPushNotificationByType(opp2Id, 'found_challenge', `https://cuebids.com/challenge/${challengeId}`, { opponent1, opponent2 });
    await Promise.all([p1, p2]);
}
export async function createGroupSessionInviteNotification(userId, partner, event, groupId, groupSessionId) {
    // await createNotification(
    //   userId,
    //   'Invite to play',
    //   `${partnerName} has invited you to play the event ${groupSessionName}`,
    //   `https://cuebids.com/groups/${groupId}/joinEvent/${groupSessionId}`
    // )
    await sendPushNotificationByType(userId, 'group_event_invite', `https://cuebids.com/groups/${groupId}/joinEvent/${groupSessionId}`, { partner, event });
}
export async function createGroupSessionInviteAcceptedNotification(userId, partner, event, sessionId) {
    // await createNotification(
    //   userId,
    //   'Invite accepted',
    //   `${partnerName} has accepted your invite to play ${groupSessionName} - start bidding!`,
    //   'https://cuebids.com/session/' + sessionId
    // )
    await sendPushNotificationByType(userId, 'group_event_accepted', 'https://cuebids.com/session/' + sessionId, { partner, event });
}
export async function createGroupInviteNotification(userId, group) {
    // await createNotification(
    //   userId,
    //   'Group invite',
    //   `You have been invited to the group ${groupName}`,
    //   'https://cuebids.com/groups'
    // )
    await sendPushNotificationByType(userId, 'group_invite', 'https://cuebids.com/groups', { group });
}
export async function createAddedToGroupNotification(userId, group, groupId) {
    // await createNotification(
    //   userId,
    //   'Added to group',
    //   `You have been added to the group ${groupName}`,
    //   `https://cuebids.com/groups/${groupId}`
    // )
    await sendPushNotificationByType(userId, 'group_added', 'https://cuebids.com/groups', { group });
}
export async function createMadePremiumInGroupNotification(userId, group, groupId) {
    // await createNotification(
    //   userId,
    //   'Premium group member',
    //   `You have been promoted to a premium member in the group ${groupName}`,
    //   `https://cuebids.com/groups/${groupId}`
    // )
    await sendPushNotificationByType(userId, 'group_promotion', `https://cuebids.com/groups/${groupId}`, { group });
}
export async function createGroupChatMessageNotification(userId, groupId, groupName, message) {
    await createNotificationForGroup(userId, groupId, `${groupName}:`, message, 'chat', `https://cuebids.com/groups/${groupId}?chatOpen=true`);
}
export async function createGroupSessionCreatedNotification(groupId, groupSessionName, groupName, groupSessionId) {
    await createNotificationForGroup(null, groupId, 'New event', `New event ${groupSessionName} in the group ${groupName}`, 'session', `https://cuebids.com/groups/${groupId}/joinEvent/${groupSessionId}`);
    // TODO: Handle group notifications
}
export async function createSessionSharedNotification(userId, user, sessionId) {
    // await createNotification(
    //   userId,
    //   'Session shared',
    //   `${userName} shared a session with you`,
    //   'https://cuebids.com/session/' + sessionId
    // )
    await sendPushNotificationByType(userId, 'session_shared', 'https://cuebids.com/session/' + sessionId, { user });
}
// User id is excluding user from notification
async function createNotificationForGroup(userId, groupId, header, text, type, url) {
    const pushNotificationsRef = getRepos().pushNotifications.collection;
    if (userId != null) {
        await addDoc(pushNotificationsRef, {
            userId,
            groupId,
            header,
            text,
            type,
            url: url ?? null
        });
    }
    else {
        await addDoc(pushNotificationsRef, {
            groupId,
            header,
            text,
            type,
            url: url ?? null
        });
    }
}
async function createNotification(userId, header, text, url) {
    const pushNotificationsRef = getRepos().pushNotifications.collection;
    await addDoc(pushNotificationsRef, {
        userId,
        header,
        text,
        url: url ?? null
    });
}
export async function sendPushNotificationByType(uid, type, url, args) {
    const functions = getFunctions();
    try {
        const func = httpsCallable(functions, 'sendNotification');
        await func({
            userId: uid,
            type,
            url,
            args
        });
    }
    catch (error) {
        const pushNotificationsRef = getRepos().pushNotifications.collection;
        await addDoc(pushNotificationsRef, {
            userId: uid,
            type,
            url,
            args
        });
    }
}
export async function sendPushNotification(uid, title, text, url) {
    const functions = getFunctions();
    const func = httpsCallable(functions, 'sendNotification');
    await func({
        userId: uid,
        title,
        text,
        url
    });
}
