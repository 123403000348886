import { useEffect, useRef, useState } from 'react'
import Alert from '../alert/alert';
import { useTranslation } from 'react-i18next';

const getDiffFormat = (difference) => {
  if (difference <= 0) return {};
  return {
    d: Math.floor(difference / (1000 * 60 * 60 * 24)),
    h: Math.floor((difference / (1000 * 60 * 60)) % 24),
    m: Math.floor((difference / 1000 / 60) % 60),
    s: Math.floor((difference / 1000) % 60),
  };
};

const calculateTimeLeft = (endDate) => {
  const end = endDate;
  const now = +new Date();

  return getDiffFormat(end - now);
};


export function Timer({ seconds, compare, callback }) {
  const [timeLeft, setTimeLeft] = useState(compare + (seconds * 1000) - Date.now());
  const callbackRef = useRef();
  callbackRef.current = callback;

  useEffect(
    function () {
      const intervalId = setInterval(function () {
        const newTimeLeft = compare + (seconds * 1000) - Date.now()
        setTimeLeft(newTimeLeft);
        if (newTimeLeft <= 0 && callbackRef.current) {
          callbackRef.current();
        }
      }, 1000);
      return function () {
        clearInterval(intervalId);
      };
    },
    [compare, seconds]
  );

  if (timeLeft < 0) {
    return null;
  }

  return (
    <div className="relative info">{(timeLeft/1000).toFixed()}</div>
  );
}


export default function ChallengeTimer({ endDate }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));
  const { t } = useTranslation();

  useEffect(
    function () {
      const intervalId = setInterval(function () {
        setTimeLeft(calculateTimeLeft(endDate));
      }, 1000);
      return function () {
        clearInterval(intervalId);
      };
    },
    [endDate]
  );

  const timerComponents = [];

  try {
    Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }

      timerComponents.push(
        <span key={interval}>
          {timeLeft[interval]}
          {interval}{' '}
        </span>
      );
    });
  } catch (e) {
    //
  }

  return (
    <Alert
      sx={'w-44'}
      severity={(timeLeft.d ?? -1) === 0 ? 'warning' : 'info'}
      text={
        <div>
          {timerComponents.length ? (
            timerComponents
          ) : (
            <span>{t('components.challenge_timer.end_at_reset')}</span>
          )}
        </div>
      }
    />
  );
}
