import { useEffect, useRef, useState } from 'react';
import { setFinished } from '../../../firebase/biddingSessions.js';
import BiddingDisplay from '../../../components/bidding/biddingDisplay';
import Hand from '../../../components/cards/hand';
import { ContractDisplay } from '../../../components/bidding/bidDisplay';
import { StarRating } from '../../../components/rating/starRating';
import NextDealButton from './nextDealButton';
import {
  getContractDeclarer,
  getContracts,
} from 'cuebids-bidding-util';
import Dialog from '../../../components/dialog';
import {
  hasCompletedOnboardingStep,
  onboardingSteps,
} from '../../../util/onboarding';
import Alert from '../../../components/alert/alert';
import { useAppStateStore } from '../../../appStateStore.jsx';
import Animated from '../../../components/animation/animated.jsx';
import { MenuIcon } from '../../../components/icons/menuIcon.jsx'
import CreateSpotDialog from '../../../components/createSpotDialog/CreateSpotDialog'
import { Link } from 'react-router-dom'
import ContractEvDisplay from './contractEvDisplay';
import StarRatingDisplay from './starRatingDisplay.jsx';
import { useOutsideClick } from '../../../util/hooks.jsx';
import { useQuery } from '@tanstack/react-query'
import { getGroupSession } from '../../../firebase/groups.js'
// import { getRobotUserId } from '../../../util/robotPartner.js';
import {
  // submitLiaFeedback,
  submitSessionDealFeedback } from 'cuebids-firebase/discord';
import useConfirmWithInput from '../../../hooks/confirmWithInput.jsx';
import { useTranslation } from 'react-i18next';
import useNotifications from '../../../components/notifications/useNotifications.jsx';

function getMatchpointsForSameSettings(review, compete, contract) {
  review = review.filter((r) => r.compete === compete);
  
  const compare = review.map((r) => {
    const ties = review.filter((r2) => r2.ev === r.ev).length - 1;
    const below = review.filter((r2) => r2.ev < r.ev).length;
    const total = review.length - 1;

    // Handle case when only 1 result
    const mp = total === 0 ? 0.5 : (ties * 0.5 + below * 1) / total;

    return {
      ...r,
      mp: (mp * 100).toFixed(0),
    };
  });

  return compare.find((r) => r.contract === contract)?.mp ?? '?';
}

export default function SessionDealResult({
  sessionDeal,
  isSmall,
  topHandDirection,
  bottomHandDirection,
  isKibitzing,
  isShared,
  currentUser,
  setFeedbackOpen,
  marked,
  markForDiscussion,
  shouldAnimateResult,
  setShouldAnimateResult,
  review,
}) {
  const { data: groupSession } = useQuery({
    queryKey: ['groupSession', sessionDeal.groupSession],
    queryFn: async () => {
      if (sessionDeal.groupSession) {
        return await getGroupSession(sessionDeal.groupSession)
      } else {
        return null;
      }
    },
  });

  const shouldShowSuggestedBiddingBadge = !!groupSession?.suggestedBidding?.[sessionDeal.dealId]?.bidding;

  const sessionDeals = useAppStateStore((state) => state.sessionDeals);
  const user = useAppStateStore((state) => state.user);
  const showReviewDialog = useAppStateStore((state) => state.showReviewDialog);
  const notify = useNotifications();

  const [showCreateSpot, setShowCreateSpot] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const wrapperRef = useRef(null);

  const { t } = useTranslation()


  useOutsideClick(wrapperRef, () => setDropdown(false));

  const [showOtherContractsDialog, setShowOtherContractsDialog] =
      useState(false);

  function handleCloseCreateSpot() {
    setShowCreateSpot(false)
  }

  function handleShowReview() {
    showReviewDialog()
  }

  const [ConfirmationDialog, confirm] = useConfirmWithInput(
    t('session_deal_result.let_us_know'),
    <>

      <br/><br/>
      {t('session_deal_result.remember')} <Link to={'/help'}><strong className={'underline underline-offset-1 decoration-rose-400'}>{t('session_deal_result.read_the_faq')}
      </strong></Link>!
      <br/>
      {t('session_deal_result.scoring_is_dd')}
      <br/>
      <br/>
      <span>
        {t('session_deal_result.discuss_with_partner')}
      </span>
    </>,
    'secondary',
    'error',
  );

  // async function liaFeedback() {
  //   await submitLiaFeedback({
  //     alert: '1!s 0-9 HCP',
  //     suggestionAlert: '1!S 10-12 HCP'
  //   })
  // }

  async function submitGeneralFeedback() {
    setFeedbackOpen(true);
    const msg = await confirm();
    setFeedbackOpen(false);
    setDropdown(false);
    if (!msg) {
      return;
    }

    await submitSessionDealFeedback({
      message: msg,
      url: `<https://cuebids.com/session/deal/${sessionDeal.id}?uid=${user.id}>`
    })

    notify(
      {
        type: 'success',
        text: t('session_deal_result.thanks_for_feedback')
      },
    )
  }

  useEffect(() => {
    if (currentUser.uid === sessionDeal.turn && sessionDeal.finished) {
      void setFinished(sessionDeal);
    }
  }, [sessionDeal, currentUser.uid]);

  if (!sessionDeal) {
    return null;
  }

  const userTurnOnFinished = currentUser.uid === sessionDeal.turn; // This user has not seen this finished deal before
  const _shouldAnimateResult = userTurnOnFinished || shouldAnimateResult;

  const showOnboarding = !hasCompletedOnboardingStep({ user, step: onboardingSteps.finishSession });

  // const isRobotPartner = sessionDeal.users[1] === getRobotUserId();


  return (
    <>
      {sessionDeal && showCreateSpot && (
        <CreateSpotDialog
          bidding={sessionDeal.bidding}
          direction={bottomHandDirection}
          vulnerability={sessionDeal.vulnerability}
          partnersHand={
            isKibitzing
              ? sessionDeal.northHandPart
              : sessionDeal.partnerHandPart
          }
          hand={isKibitzing ? sessionDeal.southHandPart : sessionDeal.handPart}
          dealer={sessionDeal.dealer}
          sessionDealId={sessionDeal.id}
          open={showCreateSpot}
          onClose={(id) => handleCloseCreateSpot(id)}
        />
      )}
      <div className="flex grow select-none flex-col items-center justify-around ">
        <Hand
          scale={user?.interfaceScale}
          size={user?.cardSize ?? 'sm'}
          variant={
            user?.cardVariant ?? (user?.handAsDiagram ? 'diagram' : 'modern')
          }
          showHcp={!(user?.hideHcpCount ?? false)}
          hand={
            isKibitzing
              ? sessionDeal.northHandPart
              : sessionDeal.partnerHandPart
          }
          direction={topHandDirection}
          order={user?.suitOrder ?? 'default'}
        />
      
        <div className="mt-4 mb-8 flex h-[200px] w-full justify-between">
          <Animated
            key={'contractYou' + sessionDeal.id}
            initial={{ opacity: _shouldAnimateResult ? 0 : 1 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0,
              duration: 0.5,
            }}
            sx="flex w-1/4 select-none flex-col items-center pl-4"
          >
            <ContractEvDisplay
              contract={sessionDeal.contract}
              ev={sessionDeal.ev}
              isSmall={isSmall}
              me={true}
            />
            <div className="indicator mt-6">
              {(showOnboarding || shouldShowSuggestedBiddingBadge) && (
                <div className="pulse w-5 badge badge-error indicator-item -right-1 -top-1" />
              )}
              <button
                className="btn-outline btn-secondary btn-sm btn h-4 text-xs text-white"
                onClick={handleShowReview}
              >
                {t('session_deal_result.compare')}
              </button>
            </div>
          </Animated>
          <div className="flex w-1/2 justify-center">
            <div className="w-[152px] text-base-content scaled-lg">
              <BiddingDisplay
                bidding={sessionDeal.bidding}
                vulnerability={sessionDeal.vulnerability}
                variant={'compact'}
                startWithEast={topHandDirection === 'S'}
              />
            </div>
          </div>
          <Animated
            key={'contractBest' + sessionDeal.id}
            initial={{ opacity: _shouldAnimateResult ? 0 : 1 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.5,
              duration: 0.5,
            }}
            sx="flex w-1/4 select-none flex-col items-center pr-4"
          >
            <ContractEvDisplay
              contract={sessionDeal.bestContract}
              ev={sessionDeal.bestContractEv}
              isSmall={isSmall}
              me={false}
            />
            <div className="indicator mt-6">
              {showOnboarding && (
                <div className="pulse w-5 badge badge-error indicator-item -right-1 -top-1" />
              )}
              <button
                className={
                  'btn-outline btn-secondary btn-sm btn h-4 text-xs text-white'
                }
                onClick={() => setShowOtherContractsDialog(true)}
              >
                {t('session_deal_result.contracts')}
              </button>
            </div>
          </Animated>
        </div>
        <div className="flex w-full items-start justify-around relative">
          {showOnboarding ||
          ((sessionDeal.spots?.length ?? 0 === 0) &&
            isKibitzing &&
            !isShared) ? (
              <div className="w-28" />
            ) : (
              <div className="w-28 flex justify-center">
                <button
                  type={'button'}
                  onClick={() => setDropdown((e) => !e)}
                  className="btn btn-primary btn-outline"
                >
                  <MenuIcon className={marked ? 'stroke-secondary' : 'normal'} />
                </button>
              </div>
            )}
          {dropdown && (
            <div
              ref={wrapperRef}
              className="menu absolute z-[15000] top-12 left-20 p-2 shadow bg-neutral rounded-box w-72 gap-2"
            >
              <ConfirmationDialog key={sessionDeal.id} />
              <div className="mr-2 flex w-full flex-col items-center justify-start text-amber-600">
                {(!isKibitzing || isShared) && (
                  <button
                    className="btn-ghost btn w-full text-white flex flex-row items-center justify-start"
                    onClick={submitGeneralFeedback}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25px"
                      height="25px"
                      viewBox="0 -28.5 256 256"
                      version="1.1"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g>
                        <path
                          d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
                          fill="#5865F2"
                          fillRule="nonzero"
                        ></path>
                      </g>
                    </svg>
                    {t('session_deal_result.feedback_to_cuebids')}
                  </button>
                )}
                {(!isKibitzing || isShared) && (
                  <button
                    className="btn-ghost btn w-full text-white flex flex-row items-center justify-start"
                    onClick={markForDiscussion}
                  >
                    <input
                      type="checkbox"
                      className={'checkbox checkbox-primary'}
                      checked={marked}
                      readOnly={true}
                    />
                    {marked ? (
                      <span className="ml-2 text-amber-600">
                        {t('session_deal_result.marked_for_discussion')}
                      </span>
                    ) : (
                      <span className="ml-2 text-gray-500">
                        {t('session_deal_result.mark_for_discussion')}
                      </span>
                    )}
                  </button>
                )}
              </div>
              {sessionDeal.spots?.map((s, i) => {
                return (
                  <div
                    key={s.id}
                    className="mr-2 flex w-full flex-row items-center justify-start text-amber-600"
                  >
                    <Link to={`/spots/${s.fed}/${s.id}`} className={'w-full'}>
                      <button className="btn-ghost btn w-full text-white flex flex-row items-center justify-start">
                        <span className="ml-2 text-amber-600">
                          {t('session_deal_result.visit_poll')}
                        </span>
                      </button>
                    </Link>
                  </div>
                );
              })}
              {(sessionDeal.spots?.length ?? 0) < 1 && (
                <div className="mr-2 flex w-full flex-row items-center justify-start text-amber-600">
                  {(!isKibitzing || isShared) && (
                    <button
                      className="btn-ghost btn w-full text-white flex flex-row items-center justify-start"
                      onClick={() => setShowCreateSpot(true)}
                      disabled={sessionDeal.daily || sessionDeal.weekly}
                    >
                      {sessionDeal.daily || sessionDeal.weekly ? (
                        <span className={'info'}>
                          {t('session_deal_result.poll_not_available')}
                        </span>
                      ) : (
                        <span className="ml-2 text-amber-600">
                          {t('session_deal_result.post_poll')}
                        </span>
                      )}
                    </button>
                  )}
                </div>
              )}
            </div>
          )}

          <Animated
            key={'dealResult' + sessionDeal.id}
            initial={{ opacity: _shouldAnimateResult ? 0 : 1 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 1,
              duration: 0.5,
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onAnimationComplete={function () {
              if (_shouldAnimateResult) {
                setShouldAnimateResult(false);
              }
            }}
          >
            <div className="h-5 mb-1">
              {review &&
                review.length > 0 &&
                getMatchpointsForSameSettings(
                  review,
                  sessionDeal.compete,
                  sessionDeal.contract
                ) + ' %'}
            </div>
            <StarRatingDisplay id={sessionDeal.id} numberOfStars={sessionDeal.resultGrade} bonusGrade={sessionDeal.bonusGrade} />
          </Animated>
          <div className="w-28">
            {!isKibitzing && (
              <NextDealButton
                sessionDeals={sessionDeals}
                userId={currentUser.uid}
                sessionId={sessionDeal.sessionId}
                currentDealIndex={sessionDeals
                  .map((s) => s.dealId)
                  .indexOf(sessionDeal.dealId)}
              />
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={showOtherContractsDialog}
        onClose={() => setShowOtherContractsDialog(false)}
      >
        <div>
          {showOnboarding && (
            <Alert text={t('onboarding.step_bidding.contracts')} />
          )}
          <table>
            <thead>
              <tr>
                <th>{t('session_deal_result.contracts')}</th>
                <th>{t('session_deal_result.stars')}</th>
                <th className="pr-4 text-end">EV</th>
              </tr>
            </thead>
            <tbody>
              {getContracts(sessionDeal).map((c, i) => {
                return (
                  <tr key={i}>
                    <td width={100}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ContractDisplay size={40} contract={c.contract} />
                        <div style={{ width: '5px', height: '0px' }} />
                        {c.contract.length > 1 &&
                          getContractDeclarer(c.contract)}
                      </div>
                    </td>
                    <td>
                      <StarRating stars={c.stars} size={'lg'} />
                    </td>
                    <td width={100} className="text-end">
                      <span style={isSmall ? { fontSize: '12px' } : undefined}>
                        {c.ev}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialog>
    </>
  );
}
