import { auth, db } from './firebase';
import {
  doc,
  getDoc,
  collection,
  runTransaction,
  query,
  getDocs,
  orderBy,
  where,
  limit,
  updateDoc,
  setDoc,
  arrayUnion,
  arrayRemove,
  documentId,
  serverTimestamp,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import {
  docsToObjects,
  docToObject,
  queryFirstItemToObject,
  queryListenWithCallback,
  queryToObjects
} from 'firebase-util'
import {
  acceptEventInvite,
  checkIfAlreadyPlaying,
  checkIfAlreadyPlayingTogether,
  EVENT_INVITES_COLLECTION,
  getEventById,
  getEventDeals,
} from './events'
import {
  createAddedToGroupNotification,
  createGroupInviteNotification,
  createGroupSessionCreatedNotification,
  createGroupSessionInviteNotification,
  createMadePremiumInGroupNotification,
} from 'cuebids-firebase/notifications';
import { PAYMENT_STRATEGY_SPLIT } from '../util/tickets';
import { validateCanCreateFreeWeeklyEvent } from '../util/groups';
import { checkIf30DaysAgo } from '../util/dates';
import { getDeal, getNumberOfDealsPerTag, shuffle } from './biddingSessions.js'
import { LowScenarioStock } from './errors.js'
import { aiSystemNotPlayableWithRobot, tagsNotPlayableWithRobot } from 'cuebids-deals'
import { addEventBadgeToAllUsersInGroup } from 'cuebids-firebase/groups';
import i18next from 'i18next'
import { getHand } from 'cuebids-hand-util'
import {
  bidArrayToBidding,
  getBidArrayWithAlertsExcludingPositionalSymbols,
  getInitialBidding,
} from 'cuebids-bidding-util'

export const GROUPS_COLLECTION = 'groups';
export const GROUP_SESSION_COLLECTION = 'groupSession';
export const DEALS_COLLECTION = 'deals';

export async function createGroup({ name, description, allowChatPush }) {
  const currentUser = auth.currentUser;

  const q = query(
    collection(db, GROUPS_COLLECTION),
    where('deleted', '==', false),
    where('lowerCaseName', '==', name.toLowerCase()),
    limit(1),
  );

  const sameNameGroupsData = await queryFirstItemToObject(q);

  if (sameNameGroupsData) {
    return {
      nameAlreadyExists: true,
    };
  }

  const groupRef = doc(collection(db, GROUPS_COLLECTION));

  await setDoc(groupRef, {
    owner: currentUser.uid,
    admins: [currentUser.uid],
    members: [currentUser.uid],
    invited: [],
    name,
    lowerCaseName: name.toLowerCase(), // Used to find groups with same name
    description,
    deleted: false,
  });

  if (allowChatPush) {
    const userRef = doc(collection(db, 'users'), currentUser.uid);
    updateDoc(userRef, {
      allowPushNotificationsInGroup: arrayUnion(groupRef.id),
    });
  }

  return {
    groupId: groupRef.id,
  };
}

export async function updateGroupSettings({ groupId, settings }) {
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  await updateDoc(groupRef, {
    ...settings
  });
}

export async function updateGroup({ groupId, name, description }) {
  const q = query(
    collection(db, GROUPS_COLLECTION),
    where(documentId(), '!=', groupId),
    where('deleted', '==', false),
    where('lowerCaseName', '==', name.toLowerCase()),
    limit(1),
  );

  const sameNameGroupsData = await queryFirstItemToObject(q);

  if (sameNameGroupsData) {
    return {
      nameAlreadyExists: true,
    };
  }

  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  await updateDoc(groupRef, {
    name,
    lowerCaseName: name.toLowerCase(), // Used to find groups with same name
    description,
  });

  return {
    success: true,
  };
}

export async function deleteGroup(id) {
  const currentUser = auth.currentUser;
  const groupRef = doc(collection(db, GROUPS_COLLECTION), id);

  const groupDoc = await getDoc(groupRef);
  const groupData = docToObject(groupDoc);

  if (groupData.owner !== currentUser.uid) {
    throw new Error(i18next.t('groups.error_only_owner_can_delete'));
  }

  return await updateDoc(groupRef, {
    deleted: true,
  });
}

export function getCurrentUserGroupsObservable(callback) {
  const currentUser = auth.currentUser;

  const q = query(
    collection(db, GROUPS_COLLECTION),
    where('members', 'array-contains', currentUser.uid),
    where('deleted', '==', false),
  );

  return queryListenWithCallback(q, callback);
}

export async function getGroup(id) {
  const groupRef = doc(collection(db, GROUPS_COLLECTION), id);

  const group = await getDoc(groupRef);

  const data = docToObject(group);

  if (data?.deleted) {
    return null;
  }

  return data;
}

export function getCurrentUserGroupInvitesObservable(callback) {
  const currentUser = auth.currentUser;

  const q = query(
    collection(db, GROUPS_COLLECTION),
    where('invited', 'array-contains', currentUser.uid),
    where('deleted', '==', false),
  );

  return queryListenWithCallback(q, callback);
}

export async function leaveGroup(id) {
  const currentUser = auth.currentUser;
  const groupRef = doc(collection(db, GROUPS_COLLECTION), id);
  const userRef = doc(collection(db, 'users'), currentUser.uid);

  const groupDoc = await getDoc(groupRef);
  const groupData = docToObject(groupDoc);

  if (groupData.owner === currentUser.uid) {
    throw new Error(i18next.t('groups.error_owner_cannot_leave'));
  }

  void updateDoc(userRef, {
    allowPushNotificationsInGroup: arrayRemove(id),
  });

  return updateDoc(groupRef, {
    members: arrayRemove(currentUser.uid),
    admins: arrayRemove(currentUser.uid),
    invited: arrayRemove(currentUser.uid),
  });
}

export async function joinGroup(groupId) {
  const currentUser = auth.currentUser;

  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  const group = await getDoc(groupRef);
  const groupData = docToObject(group);


  const alreadyMember = groupData.members.includes(currentUser.uid);
  if (alreadyMember) {
    return {
      alreadyMember: true,
    };
  }

  if(!groupData.openGroup) {
    return;
  }

  await updateDoc(groupRef, {
    lastUserJoinId: currentUser.uid,
    members: arrayUnion(currentUser.uid),
    invited: arrayRemove(currentUser.uid),
  });
}

export async function addMemberToGroup({ groupId, groupName, userId }) {
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  const group = await getDoc(groupRef);
  const groupData = docToObject(group);

  const alreadyMember = groupData.members.includes(userId);

  if (alreadyMember) {
    return {
      alreadyMember: true,
    };
  }

  await updateDoc(groupRef, {
    members: arrayUnion(userId),
    invited: arrayRemove(userId),
  });

  const userRef = doc(collection(db, 'users'), userId);

  const userDoc = await getDoc(userRef);
  const userData = docToObject(userDoc);

  if (userData.allowChatPush ?? true) {
    void updateDoc(userRef, {
      allowPushNotificationsInGroup: arrayUnion(groupId),
    });
  }

  if(groupName) {
    await createAddedToGroupNotification(userId, groupName, groupId);
  }

  return {
    isNew: true,
  };
}

export async function inviteMemberToGroup({ groupId, userId, groupName }) {
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  const group = await getDoc(groupRef);
  const groupData = docToObject(group);

  const alreadyMember = groupData.members.includes(userId);

  if (alreadyMember) {
    return {
      alreadyMember: true,
    };
  }

  const alreadyInvited = groupData.invited.includes(userId);

  if (alreadyInvited) {
    return {
      alreadyInvited: true,
    };
  }

  await updateDoc(groupRef, {
    invited: arrayUnion(userId),
  });

  void createGroupInviteNotification(userId, groupName);

  return {
    isNew: true,
  };
}

export async function removeMemberInviteFromGroup({ groupId, userId }) {
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  await updateDoc(groupRef, {
    invited: arrayRemove(userId),
  });

  return true;
}

export async function acceptGroupInvite(groupId, allowChatPush) {
  const currentUser = auth.currentUser;
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  const group = await getDoc(groupRef);
  const groupData = docToObject(group);

  const invited = groupData.invited.includes(currentUser.uid);

  if (!invited) {
    return {
      notInvited: true,
    };
  }

  await updateDoc(groupRef, {
    invited: arrayRemove(currentUser.uid),
    members: arrayUnion(currentUser.uid),
  });

  if (allowChatPush) {
    const userRef = doc(collection(db, 'users'), currentUser.uid);
    void updateDoc(userRef, {
      allowPushNotificationsInGroup: arrayUnion(groupId),
    });
  }


  return {
    groupId: groupData.id,
  };
}

export async function rejectGroupInvite(groupId) {
  const currentUser = auth.currentUser;
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  await updateDoc(groupRef, {
    invited: arrayRemove(currentUser.uid),
  });

  return true;
}

export async function addMemberToGroupViaFriendKey({ groupId, groupName, friendKey }) {
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  const q = query(
    collection(db, 'users'),
    where('friendKey', '==', friendKey.toUpperCase()),
    limit(1),
  );

  const friend = await getDocs(q);

  if (!friend?.docs || friend.docs.length < 1) {
    return {
      noSuchFriendKey: true,
    };
  }

  const friendUser = {
    id: friend.docs[0].id,
    ...friend.docs[0].data(),
  };

  if (friendUser.disableFriendKey) {
    return {
      disableFriendKey: true,
    };
  }

  const group = await getDoc(groupRef);
  const groupData = docToObject(group);

  const alreadyMember = groupData.members.includes(friendUser.id);

  if (alreadyMember) {
    return {
      alreadyMember: true,
    };
  }

  await updateDoc(groupRef, {
    members: arrayUnion(friendUser.id),
    invited: arrayRemove(friendUser.id),
  });

  if (friendUser.allowChatPush ?? true) {
    const userRef = doc(collection(db, 'users'), friendUser.id);
    updateDoc(userRef, {
      allowPushNotificationsInGroup: arrayUnion(groupId),
    });
  }

  createAddedToGroupNotification(friendUser.id, groupName, groupId);

  return {
    isNew: true,
    ...friendUser,
  };
}

export async function removeMemberFromGroup({ groupId, userId }) {
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  await updateDoc(groupRef, {
    members: arrayRemove(userId),
    invited: arrayRemove(userId),
  });

  const userRef = doc(collection(db, 'users'), userId);

  updateDoc(userRef, {
    allowPushNotificationsInGroup: arrayRemove(groupId),
  });

  return true;
}

export async function addAdminToGroup({ groupId, userId }) {
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  await updateDoc(groupRef, {
    members: arrayUnion(userId),
    admins: arrayUnion(userId),
  });

  // No change to user push settings for group since they are most likely already a member (and might have changed settings)

  return true;
}

export async function removeAdminFromGroup({ groupId, userId }) {
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  await updateDoc(groupRef, {
    admins: arrayRemove(userId),
  });

  return true;
}

export async function transferOwnershipOfGroup({ groupId, userId }) {
  const currentUser = auth.currentUser;
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  const groupDoc = await getDoc(groupRef);
  const groupData = docToObject(groupDoc);

  if (groupData.owner !== currentUser.uid) {
    throw new Error(i18next.t('groups.error_only_owner_can_transfer_ownership'));
  }

  await updateDoc(groupRef, {
    members: arrayUnion(userId),
    admins: arrayUnion(userId),
    owner: userId,
  });

  // No change to user push settings for group since they are most likely already a member (and might have changed settings)

  return true;
}

export async function makeMemberPremium({ groupId, userId, groupName }) {
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  await runTransaction(db, async (transaction) => {
    const _group = await transaction.get(groupRef);

    const groupData = _group.data();

    const premiumMembersLimit = groupData.premiumMembersLimit ?? 0;
    const premiumMembers = groupData.premiumMembers || [];
    if (premiumMembers.length >= premiumMembersLimit) {
      throw new Error(i18next.t('groups.error_premium_limit_reached'));
    }

    transaction.update(groupRef, {
      premiumMembers: arrayUnion({
        uid: userId,
        addedTimestamp: Timestamp.now() // Cannot use serverTimestamp in arrayUnion.
      }),
    });
  });

  void createMadePremiumInGroupNotification(userId, groupName, groupId);

  return true;
}

export function checkIfPremiumCanBeRemovedFromMember({ premiumMembers, userId }) {
  const premiumMemberData = premiumMembers.find((m) => m.uid === userId);
  if (!premiumMemberData) {
    throw new Error(i18next.t('groups.error_user_not_premium'));
  }
  if (!checkIf30DaysAgo(premiumMemberData.addedTimestamp.toDate(), Timestamp.now().toDate())) {
    throw new Error(i18next.t('groups.error_user_recently_premium'));
  }
}

export async function removePremiumFromMember({ groupId, userId }) {
  const groupRef = doc(collection(db, GROUPS_COLLECTION), groupId);

  await runTransaction(db, async (transaction) => {
    const _group = await transaction.get(groupRef);

    const groupData = _group.data();

    const oldPremiumMembers = groupData.premiumMembers || [];

    checkIfPremiumCanBeRemovedFromMember({
      premiumMembers: oldPremiumMembers,
      userId
    });

    const newPremiumMembers = oldPremiumMembers.filter((m) => m.uid !== userId);

    transaction.update(groupRef, {
      premiumMembers: newPremiumMembers,
    });
  });
  return true;
}

export function updateAllowPushNotificationsInGroup({ groupId, value }) {
  const currentUser = auth.currentUser;
  const userRef = doc(collection(db, 'users'), currentUser.uid);

  if (value) {
    return updateDoc(userRef, {
      allowPushNotificationsInGroup: arrayUnion(groupId),
    });
  }

  return updateDoc(userRef, {
    allowPushNotificationsInGroup: arrayRemove(groupId),
  });
}

// START - GROUP SESSION

export function getGroupSessionsObservable({ groupId, callback }) {
  const groupSessionRef = collection(db, GROUP_SESSION_COLLECTION);

  const q = query(
    groupSessionRef,
    where('groupId', '==', groupId),
    orderBy('endDate', 'desc'),
    limit(100),
  );

  return queryListenWithCallback(q, callback);
}

export async function getNextGroupSessionToFinishForGroup(groupId) {
  const groupSessionRef = collection(db, GROUP_SESSION_COLLECTION);

  const q = query(
    groupSessionRef,
    where('groupId', '==', groupId),
    where('endDate', '>', Date.now()),
    orderBy('endDate', 'asc'),
    limit(1),
  );


  return await queryFirstItemToObject(q);
}

export function addDays(date, numberOfDays) {
  date.setDate(date.getDate() + numberOfDays);
  return date;
}

async function getLatestDealNumberForTags(tags) {
  const dealTimestampsRef = collection(db, 'dealTimestamps');

  const dealNumberForTagPromises = tags.map(async (tag) => {
    const dealNumberQuery = query(
      dealTimestampsRef,
      orderBy(tag, 'desc'),
      limit(1),
    );

    const dealTimestampData = await queryFirstItemToObject(dealNumberQuery);

    return dealTimestampData?.[tag] ?? 0;
  });

  await Promise.all(dealNumberForTagPromises);

  return tags.reduce(async function (a, t, i) {
    a[t] = await dealNumberForTagPromises[i];
    return a;
  }, {});
}

export async function updateGroupSession(
  {
    groupSessionId,
    updateObject
  }
) {

  const groupSessionRef = doc(collection(db, GROUP_SESSION_COLLECTION), groupSessionId);

  await updateDoc(groupSessionRef, { ...updateObject });
}

export async function createGroupSession({
  groupId,
  draftEventId,
  groupName,
  numberOfDeals,
  compete = 2,
  name,
  description = '',
  showLeaderboard = true,
  startDate = Date.now(),
  endDate = addDays(new Date(), 7).getTime(),
  price,
  tags = [],
  coach,
  scenarios = [],
}) {
  const dealsRef = collection(db, DEALS_COLLECTION);

  const tagsAndScenarios = tags.concat(scenarios.map(s => s.id));
  const tagNames = tags.concat(scenarios).map(t => t.name ?? null);
  const latestDealNumberForTags = await getLatestDealNumberForTags(tagsAndScenarios);
  const disableRobotPartner = scenarios.some(s => aiSystemNotPlayableWithRobot.includes(s.directions.settings?.aiSystem)) || tags.some(t => tagsNotPlayableWithRobot.includes(t));

  const batch = writeBatch(db);

  let groupData
  if (!price || tagsAndScenarios.length) {
    groupData = await getGroup(groupId)
  }

  if (!price) {
    const hasFreeEvents = groupData.premium && (groupData.premiumFeatures || []).includes('freeEvents');
    const hasFreeWeeklyEvent = groupData.premium && (groupData.premiumFeatures || []).includes('freeWeeklyEvent');
    const canCreateFreeWeeklyEvent = hasFreeWeeklyEvent && validateCanCreateFreeWeeklyEvent(groupData.lastFreeWeeklyEventTimestamp?.toDate(), Timestamp.now().toDate());

    const canCreateFreeEvent = hasFreeEvents || canCreateFreeWeeklyEvent;

    if (!canCreateFreeEvent) {
      if (hasFreeWeeklyEvent && !canCreateFreeWeeklyEvent) {
        throw new Error(i18next.t('groups.error_free_weekly_spent'));
      }
      throw new Error(i18next.t('groups.error_cannot_create_free_event'));
    }

    if (hasFreeWeeklyEvent && !hasFreeEvents) {
      batch.update(groupRef, {
        lastFreeWeeklyEventTimestamp: serverTimestamp(),
      });
    }
  }

  const suggestedBiddings = {}
  let deals = [];
  if (draftEventId) {
    // create deals from event
    const eventRef = doc(collection(db, 'groupSessionDraft'), draftEventId);
    const eventData = docToObject(await getDoc(eventRef));

    let dealNumber = 0;

    for (const deal of eventData.deals) {
      dealNumber++;
      const dealRef = doc(collection(db, 'deals'));
      const newDeal = {
        type: 'group-pbn',
        version: 3,
        hand: deal.hand,
        dealNumber: dealNumber,
        score: deal.score,
        vulnerability: deal.vulnerability,
        dealer: deal.dealer
      }

      batch.set(dealRef, newDeal);
      suggestedBiddings[dealRef.id] = {
        bidding: deal.bidding,
        comment: deal.commentary,
      };
      deals.push({
        ...newDeal,
        id: dealRef.id
      });
      batch.delete(eventRef)
    }
  } else if (tagsAndScenarios.length) {
    const canHaveDealTypes = groupData.premium && (groupData.premiumFeatures || []).includes('eventDealTypes');

    if (!canHaveDealTypes) {
      throw new Error(i18next.t('groups.error_cannot_have_event_deal_types'));
    }

    const numberOfDealsPerTag = getNumberOfDealsPerTag(tagsAndScenarios, numberOfDeals);

    const allDealsPromises = Object.keys(numberOfDealsPerTag).map(async function (tag) {
      const numberOfDealsForTag = numberOfDealsPerTag[tag];

      if (!numberOfDealsForTag) return [];

      const q = query(
        dealsRef,
        orderBy('dealNumber', 'desc'),
        where('version', '==', 3),
        where('type', '==', 'tagged'),
        where('tag', '==', tag),
        limit(numberOfDealsForTag),
      );

      const dealsForTag = await queryToObjects(q);

      if (!dealsForTag.length || (dealsForTag.sort((a, b) => a.dealNumber - b.dealNumber)[0].dealNumber) <= latestDealNumberForTags[tag]) {
        if (scenarios.length) {
          throw new LowScenarioStock(i18next.t('groups.error_insufficient_deals_scenario'));
        }
        throw new Error(i18next.t('groups.error_insufficient_deals'));
      }

      return dealsForTag;
    });
    const allDealsSettled = await Promise.allSettled(allDealsPromises);

    deals = allDealsSettled.reduce(function (ds, s) {
      if (s.status === 'rejected') {
        if (s.reason.name === 'Low Scenario Stock') {
          throw new LowScenarioStock(i18next.t('groups.error_insufficient_deals_scenario'));
        } else if (s.reason.message === 'Insufficient number of available deals') {
          throw new Error(i18next.t('groups.error_insufficient_deals'));
        }
        throw new Error(i18next.t('groups.error_generic'));
      }
      if (s.status === 'fulfilled') {
        return ds.concat(s.value);
      }
      return ds;
    }, []);
    deals = shuffle(deals);
  } else {
    const q = query(
      dealsRef,
      orderBy('dealNumber', 'desc'),
      where('version', '==', 3),
      where('type', '==', 'practice'),
      limit(numberOfDeals));

    const dealsDocs = await getDocs(q);
    deals = docsToObjects(dealsDocs);
  }

  if (!deals || deals.length < numberOfDeals) {
    throw new Error(i18next.t('groups.error_insufficient_deals'));
  }

  const groupSessionRef = doc(collection(db, GROUP_SESSION_COLLECTION));

  const groupSession = {
    groupId: groupId,
    numberOfDeals: numberOfDeals,
    deals: deals.map((d) => d.id),
    tags: tagsAndScenarios,
    tagNames,
    compete,
    showLeaderboard,
    name,
    headline: name,
    description,
    startDate,
    endDate,
    price,
    suggestedBidding: suggestedBiddings,
    scored: false,
    leaderBoard: [],
    coach,
    disableRobotPartner,
  }
  batch.set(groupSessionRef, groupSession);

  if (tagsAndScenarios.length > 0) {
    deals.forEach((d) => {
      // Remove deals from normal pool by setting them to special group type
      batch.update(doc(dealsRef, d.id), {
        type: 'group-tagged',
      });
    })
  } else if (!draftEventId) {
    deals.forEach((d) => {
      // Remove deals from normal pool by setting them to special group type
      batch.update(doc(dealsRef, d.id), {
        type: 'group-session',
      });
    })
  }

  await batch.commit();

  const groupSessionId = groupSessionRef.id;

  void createGroupSessionCreatedNotification(groupId, name, groupName, groupSessionId);
  void addEventBadgeToAllUsersInGroup(groupId, groupSessionId);

  return groupSessionId;
}

export async function getGroupSession(id) {
  return getEventById({
    eventCollection: GROUP_SESSION_COLLECTION,
    eventId: id
  });
}

export async function getGroupSessionWithSortedDeals(id) {
  const groupSession = await getGroupSession(id);
  const sortedDealDocs = await getEventDeals(groupSession.deals);
  groupSession.deals = sortedDealDocs.map(d => d.id);
  return groupSession;
}

// TODO: Can move this to events file
export async function sendInviteToGroupSession({ groupId, id, name, partnerUserId, price, expiration }) {
  const currentUser = auth.currentUser;

  const inviteRef = doc(collection(db, EVENT_INVITES_COLLECTION));

  const partnerInviteQuery = query(
    collection(db, EVENT_INVITES_COLLECTION),
    where('eventId', '==', id),
    where('inviter', '==', currentUser.uid),
    where('invitee', '==', partnerUserId),
    limit(1),
  );

  const partnerInvite = await queryFirstItemToObject(partnerInviteQuery);

  if (partnerInvite) {
    const sessionId = await acceptEventInvite(partnerInvite.id);
    return {
      sessionId,
    };
  }

  const myInviteQuery = query(
    collection(db, EVENT_INVITES_COLLECTION),
    where('eventId', '==', id),
    where('inviter', '==', currentUser.uid),
    where('invitee', '==', partnerUserId),
    limit(1),
  );

  const myInvite = await queryFirstItemToObject(myInviteQuery);

  if (myInvite) {
    return {
      alreadyInvited: true,
    };
  }

  await setDoc(inviteRef, {
    type: 'groupSession',
    groupId,
    eventId: id,
    eventName: name,
    inviter: currentUser.uid,
    invitee: partnerUserId,
    users: [currentUser.uid, partnerUserId],
    paymentStrategy: PAYMENT_STRATEGY_SPLIT,
    price,
    expiration,
  });

  void createGroupSessionInviteNotification(partnerUserId, currentUser.displayName, name, groupId, id);

  return {
    invited: true,
  };
}

export async function checkIfAlreadyPlayingGroupSession({ id, userId }) {
  return await checkIfAlreadyPlaying({
    eventCollection: 'groupSession',
    eventId: id,
    userId,
  });
}

export async function checkIfAlreadyPlayingTogetherInGroupSession({ id, userId, partnerUserId }) {
  return await checkIfAlreadyPlayingTogether({
    eventCollection: 'groupSession',
    eventId: id,
    userId,
    partnerUserId,
  });
}

// END - GROUP SESSION

// START - TEACHING

export async function addSuggestedBidding({ groupSessionId, dealId, bidding, comment }) {
  const groupSessionRef = doc(collection(db, GROUP_SESSION_COLLECTION), groupSessionId);

  return await updateDoc(groupSessionRef, {
    [`suggestedBidding.${dealId}`]: { bidding, comment },
  });
}

export async function replaceDeal({ groupSessionId, dealId, tags }) {
  const dealsRef = collection(db, 'deals');

  const shouldUseTag = tags?.length ?? 0 > 0;

  return await runTransaction(db, async (transaction) => {
    const groupSessionRef = doc(collection(db, GROUP_SESSION_COLLECTION), groupSessionId);
    const _groupSession = await transaction.get(groupSessionRef);
    const groupSession = _groupSession.data()

    if (groupSession.pairCount && groupSession.pairCount > 0) {
      throw Error(i18next.t('groups.error_cannot_replace_played_deal'))
    }

    let ref;
    if (shouldUseTag) {
      const randomIndex = Math.floor(Math.random() * tags.length);
      const tag = tags[randomIndex];

      const q = query(
        dealsRef,
        orderBy('dealNumber', 'desc'),
        where('version', '==', 3),
        where('type', '==', 'tagged'),
        where('tag', '==', tag),
        limit(1),
      );

      const _docs = await getDocs(q)

      ref = _docs.docs.length > 0 && _docs.docs[0]?.ref

      if (!ref) {
        throw Error(i18next.t('groups.error_insufficient_deals'))
      }

      transaction.update(ref, { type: 'group-tagged' })
    } else {
      const q =  query(
        dealsRef,
        orderBy('dealNumber', 'desc'),
        where('version', '==', 3),
        where('type', '==', 'practice'),
        limit(1));

      const _docs = await getDocs(q)

      ref = _docs.docs.length > 0 && _docs.docs[0]?.ref

      if (!ref) {
        throw Error(i18next.t('groups.error_insufficient_deals'))
      }

      transaction.update(ref, { type: 'group-session' })
    }

    const deals = [...groupSession.deals]
    const replaceIndex = deals.indexOf(dealId)
    deals[replaceIndex] = ref.id

    transaction.update(groupSessionRef, {
      deals,
    })

    return ref.id;
  });
}

function getOppositeDirection(direction) {
  return {
    N: 'S',
    S: 'N',
    E: 'W',
    W: 'E',
  }[direction];
}

export async function rotateDeal({ groupSessionId, dealId }) {
  const groupSession = await getGroupSession(groupSessionId)

  if (groupSession.pairCount && groupSession.pairCount > 0) {
    throw Error(i18next.t('groups.error_cannot_rotate_played_deal'))
  }

  const batch = writeBatch(db);

  const dealRef = doc(collection(db, 'deals'), dealId);
  const deal = await getDeal(dealId);

  const newDealer = getOppositeDirection(deal.dealer);

  // const newHp = [deal.hp[2], deal.hp[3], deal.hp[0], deal.hp[1]]

  const northHand = getHand(deal.hand, 0);
  const southHand = getHand(deal.hand, 2);
  const eastHand = getHand(deal.hand, 1);
  const westHand = getHand(deal.hand, 3);
  const newHand = `[Deal "N:${southHand} ${westHand} ${northHand} ${eastHand}"]`;
  const newPbn = `N:${southHand} ${westHand} ${northHand} ${eastHand}`;

  const newScore = [1, 2, 3, 4, 5, 6, 7].reduce(function (score, level) {
    return ['N', 'S', 'H', 'D', 'C'].reduce(function (score, strain) {
      return ['N', 'S', 'E', 'W'].reduce(function (score, direction) {
        return ['', 'X', 'XX'].reduce(function (score, doubled) {
          const k = level + strain + doubled + direction;
          const newK = level + strain + doubled + getOppositeDirection(direction);
          score[newK] = deal.score[k];
          return score;
        }, score)
      }, score)
    }, score)
  }, {});

  const suggestedBidding = groupSession.suggestedBidding?.[dealId]?.bidding;

  if (suggestedBidding) {
    const groupSessionRef = doc(collection(db, 'groupSession'), groupSessionId);

    const newInitialBidding = getInitialBidding(newDealer);
    const suggestedBiddingWithoutPositionalSymbols = bidArrayToBidding(getBidArrayWithAlertsExcludingPositionalSymbols(suggestedBidding));
    const newSuggestedBidding = newInitialBidding + suggestedBiddingWithoutPositionalSymbols;

    batch.update(groupSessionRef, {
      [`suggestedBidding.${dealId}.bidding`]: newSuggestedBidding,
    })
  }

  batch.update(dealRef, {
    dealer: newDealer,
    hand: newHand,
    pbn: newPbn,
    score: newScore,
    action: 'rotate',
  });

  return batch.commit();
}

// END - TEACHING
