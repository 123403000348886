import { useEffect, useMemo, useState } from 'react'
import { createGroupSession, addDays } from '../../firebase/groups';
import Dropdown from '../../components/dropdown/dropdown';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import TextField from '../../components/textField/TextField';
import Price from '../../components/price/price';
import Alert, { AlertInline } from '../../components/alert/alert'
import GroupHeader from '../../components/groupHeader/GroupHeader';
import { validateCanCreateFreeWeeklyEvent } from '../../util/groups';
import { useAuth, useDisplay, useGoToPublicGroupPage } from '../../util/hooks';
import { useAppStateStore } from '../../appStateStore.jsx';
import { DealSourcesSelect } from '../../components/dealSources/dealSourcesSelect.jsx';
import { Tab, TabPanel, Tabs } from '../../components/tabs/tabs.jsx'
import { ScenarioSelect } from '../../components/dealSources/scenarioSelect.jsx'
import { LowScenarioStock } from '../../firebase/errors.js'
import { useQuery } from '@tanstack/react-query';
import { getEventDraft } from 'cuebids-firebase/dealGeneration';
import { UserDisplayCoach } from '../../components/users/userDisplay.jsx';
import { getRobotUserId } from '../../util/robotPartner.js';
import { NoRobotIcon } from '../../components/icons/noRobotIcon.jsx';
import { RobotIcon } from '../../components/icons/robotIcon.jsx';
import { AdvancedRobotIcon } from '../../components/icons/advancedRobotIcon.jsx';
import Checkbox from '../../components/checkbox/Checkbox.jsx';
import { dealTypes } from 'cuebids-deals';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

function toDate(dateTimeLocalValue) {
  return new Date(dateTimeLocalValue).getTime();
}

function zeroPad(value) {
  const stringValue = '' + value;
  if (stringValue.length === 1) {
    return '0' + stringValue;
  }

  return stringValue;
}

function fromDate(dateTime) {
  const date = new Date(dateTime);
  return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1)}-${zeroPad(
    date.getDate()
  )}T${zeroPad(date.getHours())}:${zeroPad(date.getMinutes())}`;
}

function validateTimes(startImmediately, permanent, startTime, endTime) {
  const now = Date.now();

  const startTimeValid = startImmediately || startTime > now
  const endTimeValid = permanent || (startImmediately ? endTime > now : endTime > startTime)

  return startTimeValid && endTimeValid;
}

const settingMap = {
  0: <NoRobotIcon className={'fill-gray-500 opacity-40'} />,
  1: <RobotIcon className={'fill-sky-400'}/>,
  2: <AdvancedRobotIcon className={'fill-rose-400'} />,
}

export default function CreateGroupSession() {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const [searchParams] = useSearchParams();
  const draftEventId = searchParams.get('draft');
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const groups = useAppStateStore((state) => state.groups);
  const notify = useNotifications();
  const [eventName, setEventName] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [showLeaderboard, setShowLeaderboard] = useState(true);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [handCountInput, setHandCountInput] = useState('8');
  const handCount = parseInt(handCountInput, 10);
  const [robots, setRobots] = useState(2);
  const [startImmediately, setStartImmediately] = useState(true);
  const [permanent, setPermanent] = useState(false);
  const [startTime, setStartTime] = useState(addDays(new Date(), 1).getTime());
  const [endTime, setEndTime] = useState(addDays(new Date(), 7).getTime());
  const [tags, setTags] = useState([]);

  const [playWithCoach, setPlayWithCoach] = useState(false);
  const coachName = currentUser.displayName;
  const [coachDirection, setCoachDirection] = useState('N');
  const [showWarning, setShowWarning] = useState(false);

  const [scenarios, setScenarios] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const { isSmall } = useDisplay();

  useGoToPublicGroupPage(groupId);

  const settingNameMap = {
    0: t('groups.create_group_session.robots_none'),
    1: t('groups.create_group_session.robots_basic'),
    2: t('groups.create_group_session.robots_advanced'),
  }

  const { data: draft } = useQuery({
      queryKey: ['draft', draftEventId],
      queryFn: () => {
        return getEventDraft(draftEventId)
      },
      enabled: !!draftEventId,
    },
  )

  const shouldForceAdvancedRobots = useMemo(function () {
    if (tags.length > 0 && tags.some(tg => dealTypes[tg].ai)) {
      return true;
    } else if (scenarios.length > 0 && scenarios.some(scenario =>
    {
      const hasAi = scenario?.directions?.settings?.ai ?? 'P' !== 'P';
      const hasAiSystem = !!scenario?.directions?.settings?.aiSystem;
      const hasEastCommand = scenario?.directions?.east?.some(d => d.command);
      const hasWestCommand = scenario?.directions?.west?.some(d => d.command);

      return (hasAi || hasAiSystem || hasEastCommand || hasWestCommand)
    })) {
      return true;
    }
    return false;
  }, [tags, scenarios]);

  const shouldForceNoRobots = useMemo(function () {
    return scenarios.length > 0 && scenarios.some(scenario =>
      (scenario?.directions?.settings?.ai === 'P') ||
      (scenario?.directions?.settings?.robots === 'NONE') ||
      (scenario?.directions?.settings?.disableRobots));

  }, [scenarios]);

  useEffect(() => {
    if(shouldForceAdvancedRobots && shouldForceNoRobots) {
      notify(
        {
          type: 'failure',
          text: t('groups.create_group_session.scenarios_robot_error'),
        }
      )
      setScenarios([])
      return;
    }
    if (shouldForceNoRobots && robots !== 0) {
      handleChangeRobots(0);
    }
    if (shouldForceAdvancedRobots && robots !== 2) {
      handleChangeRobots(2);
    }
  }, [notify, robots, shouldForceAdvancedRobots, shouldForceNoRobots, t]);

  useEffect(() => {
    if (draft) {
      setEventName(draft.eventName);
      setHandCountInput(draft.deals.length);
    }
  }, [draft]);

  const group = (groups || []).find(function (group) {
    return group.id === groupId;
  });

  if (!group) {
    return null;
  }

  const hasFreeEvents =
    group.premium && (group.premiumFeatures || []).includes('freeEvents');
  const hasFreeWeeklyEvent =
    group.premium && (group.premiumFeatures || []).includes('freeWeeklyEvent');
  const canCreateFreeWeeklyEvent =
    hasFreeWeeklyEvent &&
    validateCanCreateFreeWeeklyEvent(
      group.lastFreeWeeklyEventTimestamp?.toDate(),
      new Date()
    );

  const canCreateFreeEvent = hasFreeEvents || canCreateFreeWeeklyEvent;

  const shouldUseFreeEventNumberOfBoards =
    canCreateFreeWeeklyEvent && !!group.freeWeeklyEventNumberOfBoards;

  const isGroupAdmin = group.admins.includes(currentUser.uid);

  const canHaveDealTypes = group.premium && !draft && (group.premiumFeatures || []).includes('eventDealTypes');

  const hasCoaching = group.premium && (group.premiumFeatures || []).includes('coaching');

  const handleChangeHandCount = (e) => {
    setHandCountInput(e.target.value);
    setError(null);
  };

  const handleSetTags = (ts) => {
    setScenarios([]);
    setTags(ts);
  };

  const handleSetScenarios = (ss) => {
    setTags([]);
    setScenarios(ss);
  };

  const handleRemoveTag = (tag) => {
    setTags(tags.filter((tg) => tg !== tag));
  }

  const handleRemoveScenario = (scenario) => {
    setScenarios(scenarios.filter((s) => s.id !== scenario.id));
  }

  const handleChangeRobots = (val) => {
    setRobots(val);
  };

  const price = canCreateFreeEvent ? 0 : handCount || 0;

  async function handleCreateEvent() {
    if (!isGroupAdmin || loading) return;

    if (!eventName) {
      setError(t('groups.create_group_session.missing_name_error'));
      return;
    }

    if (!handCount ||
      handCount < 1 ||
      handCount > 24) {
      setError(t('groups.create_group_session.board_count_error'));
      return;
    }

    if (!validateTimes(startImmediately, permanent, startTime, endTime)) {
      if (startImmediately) {
        if(!permanent) {
          setError(t('groups.create_group_session.end_time_past_error'));
        }
      } else {
        if(!permanent) {
          setError(t('groups.create_group_session.start_time_error'));
        }
        if(startTime < Date.now())
          setError(t('groups.create_group_session.start_time_past_error'));
        else
          setError(t('groups.create_group_session.end_time_before_start_error'));
      }
      return;
    }


    setError(null);
    setLoading(true);
    try {
      const groupSessionId = await createGroupSession({
        groupId,
        draftEventId,
        numberOfDeals: shouldUseFreeEventNumberOfBoards
          ? group.freeWeeklyEventNumberOfBoards
          : handCount,
        compete: robots,
        name: eventName,
        description: eventDescription,
        showLeaderboard,
        startDate: startImmediately ? Date.now() : startTime,
        permanent: permanent,
        endDate: permanent ? null : endTime,
        price,
        groupName: group.name,
        tags: tags,
        scenarios: scenarios,
        coach: playWithCoach ? {
          name: coachName,
          direction: coachDirection,
          warning: showWarning,
        } : null,
      });
      setLoading(false);
      if (groupSessionId) {
        navigate(`/groups/${groupId}/eventLeaderboard/${groupSessionId}`);
      }
    } catch (e) {
      if (e instanceof LowScenarioStock) {
        notify(
          {
            type: 'failure',
            text: t('groups.create_group_session.scenario_stock_error'),
            url: '/manageDealTypes'
          },
        );
      }
      else {
        notify(
          {
            text: t('groups.create_group_session.create_event_error', {error: e.message}),
            type: 'failure',
          },
        );
      }
      setLoading(false);
    }
  }

  function handleUpdateShowLeaderboard(e) {
    setShowLeaderboard(e.target.checked);
  }

  function handleUpdateStartImmediately(e) {
    setStartImmediately(e.target.checked);
    setError(null);
    if (!e.target.checked) {
      setStartTime(addDays(new Date(), 1).getTime());
    }
  }

  function handleUpdatePermanent(e) {
    setPermanent(e.target.checked);
    setError(null);
    if (!e.target.checked) {
      setEndTime(addDays(new Date(), 7).getTime());
    }
  }

  const coachDirectionOptions = [
    {
      value: 'N',
      label: t('groups.create_group_session.north'),
      component: (
        <button
          className="btn-ghost btn w-full justify-between flex items-center"
          onClick={() => setCoachDirection('N')}
        >
          <span className={'h-full flex items-center'}>{t('groups.create_group_session.north')}</span>
        </button>
      ),
    },
    {
      value: 'S',
      label: t('groups.create_group_session.south'),
      component: (
        <button
          className="btn-ghost btn w-full justify-between flex items-center"
          onClick={() => setCoachDirection('S')}
        >
          <span className={'h-full flex items-center'}>{t('groups.create_group_session.south')}</span>
        </button>
      ),
    },
  ];

  const robotOptions = [
    {
      value: 0,
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {settingMap[0]}{settingNameMap[0]}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn w-full justify-between flex items-center"
          onClick={() => handleChangeRobots(0)}
          disabled={shouldForceAdvancedRobots}
        >
          <div className="w-full h-full flex items-center justify-start gap-2">
            {settingMap[0]}{settingNameMap[0]}
          </div>
        </button>
      ),
    },
    {
      value: 2,
      label: (
        <div className="h-full flex items-center justify-start relative gap-2">
          <div className="w-full h-full flex items-center justify-start gap-2">
            {settingMap[2]}{settingNameMap[2]}
          </div>
        </div>
      ),
      component: (
        <button
          className="btn-ghost btn relative w-full justify-between flex items-center"
          onClick={() => handleChangeRobots(2)}
          disabled={shouldForceNoRobots}
        >
          <div className="h-full flex items-center justify-start relative gap-2">
            <div className="w-full h-full flex items-center justify-start gap-2">
              {settingMap[2]}{settingNameMap[2]}
            </div>
          </div>
        </button>
      ),
    },
  ];

  return (
    <div className="fade-in">
      <div className="page pb-24">
        <GroupHeader
          name={group.name}
          links
          groupId={groupId}
          premium={group.premium}
          groupFlag={group.groupFlag}
          logo={group.logo}
        />
        <div className="mt-4 flex w-80 items-start justify-between gap-4">
          <div className="flex w-20 shrink flex-col">
            <label className="label">
              <span className="label-text">{t('groups.create_group_session.boards_label')}</span>
            </label>
            <input
              disabled={shouldUseFreeEventNumberOfBoards || draft}
              className={`input${
                shouldUseFreeEventNumberOfBoards ? ' opacity-50' : ''
              }`}
              type="number"
              min={1}
              onChange={handleChangeHandCount}
              max={24}
              value={
                draft ? draft.deals.length :
                  shouldUseFreeEventNumberOfBoards
                    ? group.freeWeeklyEventNumberOfBoards
                    : handCountInput
              }
            />
          </div>
          <div className="flex grow flex-col">
            <label className="label">
              <span className="label-text">{t('groups.create_group_session.robots_label')}</span>
            </label>
            <Dropdown value={robots} label={t('robots_label')} options={robotOptions} />
          </div>
        </div>
        {
          hasCoaching && <div className="w-96 mt-4 border border-primary rounded p-4">
            <div className="form-control w-full">
              <label className="label w-full cursor-pointer p-0">
                {t('groups.create_group_session.coach_robot_label')}
                <input
                  type="checkbox"
                  className="toggle-primary toggle"
                  checked={playWithCoach}
                  onChange={e => setPlayWithCoach(e.target.checked)}
                />
              </label>
            </div>
            <span className={'info'}>{t('groups.create_group_session.coach_robot_info_1_pre_styled')}<strong className={'robot'}>{t('groups.create_group_session.coach_robot_info_1_pre_styled_1')}</strong>{t('groups.create_group_session.coach_robot_info_1_between_styled')}<strong className={'hearts'}>{t('groups.create_group_session.coach_robot_info_1_styled_2')}</strong>{t('groups.create_group_session.coach_robot_info_1_post_styled')}</span>
            <span className={'info'}>{t('groups.create_group_session.coach_robot_info_2_pre_styled')}<strong className={'text-secondary'}>{t('groups.create_group_session.coach_robot_info_2_pre_styled_1')}</strong>{t('groups.create_group_session.coach_robot_info_2_between_styled')}<strong className={'robot'}>{t('groups.create_group_session.coach_robot_info_2_styled_2')}</strong>{t('groups.create_group_session.coach_robot_info_2_post_styled')}</span>
            {
              playWithCoach &&
              <div className="w-80 mt-4">
                <UserDisplayCoach name={coachName} uid={getRobotUserId()} />
              </div>
            }
            {
              playWithCoach &&
                  <div className="w-80 mt-4">
                    <div className="flex grow flex-col">
                      <label className="label">
                        <span className="label-text">{t('groups.create_group_session.coach_direction_label')}</span>
                      </label>
                      <Dropdown value={coachDirection} label={t('groups.create_group_session.coach_direction_label')} options={coachDirectionOptions} />
                    </div>
                  </div>
            }
            {
              playWithCoach &&
                <div className="w-80 mt-4">
                  <div className="flex grow flex-col">
                    <Checkbox value={showWarning} label={t('groups.create_group_session.show_warning_label')} onChange={() => setShowWarning((w) => !w)}/>
                  </div>
                  {
                    showWarning &&
                    <AlertInline severity={'info'} text={t('groups.create_group_session.show_warning_info')} />
                  }
                </div>
            }
          </div>
        }
        <div className="w-80 mt-4">
          <TextField
            label={t('groups.create_group_session.name_label')}
            value={eventName}
            onChange={(v) => {
              setError(null);
              setEventName(v);
            }}
            placeholder={t('groups.create_group_session.name_placeholder')}
          />
        </div>
        <div className="w-80 mt-4">
          <TextField
            label={t('groups.create_group_session.description_label')}
            value={eventDescription}
            onChange={setEventDescription}
            placeholder={t('groups.create_group_session.description_placeholder')}
          />
        </div>
        <div className="w-80 mt-4">
          <div className="form-control w-full">
            <label className="label w-full cursor-pointer p-0">
              {t('groups.create_group_session.leaderboard_label')}
              <input
                type="checkbox"
                className="toggle-primary toggle"
                checked={showLeaderboard}
                onChange={handleUpdateShowLeaderboard}
              />
            </label>
          </div>
        </div>
        <div className="w-80 mt-4">
          <div className="form-control w-full">
            <label className="label w-full cursor-pointer p-0">
              {t('groups.create_group_session.start_immediately_label')}
              <input
                type="checkbox"
                className="toggle-primary toggle"
                checked={startImmediately}
                onChange={handleUpdateStartImmediately}
              />
            </label>
          </div>
        </div>
        {!startImmediately && (
          <div className="w-80 flex flex-col">
            <label className="label label-text" htmlFor="startTime">
              {t('groups.create_group_session.start_time_label')}
            </label>
            <input
              value={fromDate(startTime)}
              className="input"
              type="datetime-local"
              id="startTime"
              onChange={function (e) {
                setStartTime(toDate(e.target.value));
                setError(null);
              }}
            />
          </div>
        )}
        <div className="w-80 mt-4">
          <div className="form-control w-full">
            <label className="label w-full cursor-pointer p-0">
              {t('groups.create_group_session.permanent_event_label')}
              <input
                type="checkbox"
                className="toggle-primary toggle"
                checked={permanent}
                onChange={handleUpdatePermanent}
              />
            </label>
          </div>
        </div>
        {
          !permanent && <div className="w-80 flex flex-col mt-4">
            <label className="label label-text" htmlFor="endTime">
              {t('groups.create_group_session.end_time_label')}
            </label>
            <input
              value={fromDate(endTime)}
              className="input"
              type="datetime-local"
              id="endTime"
              onChange={function (e) {
                setEndTime(toDate(e.target.value));
                setError(null);
              }}
            />
          </div>
        }
        {canHaveDealTypes && (
          <div className="my-4">
            <Tabs>
              <Tab
                key={'public'}
                label={t('groups.create_group_session.tab_deal_types')}
                value={0}
                active={activeTab === 0}
                onChange={(i) => {
                  setScenarios([]);
                  setActiveTab(i);
                }}
              />
              <Tab
                key={'private'}
                label={t('groups.create_group_session.tab_scenarios')}
                value={1}
                className={'indicator'}
                active={activeTab === 1}
                onChange={(i) => {
                  setTags([]);
                  setActiveTab(i);
                }}
              >
              </Tab>
            </Tabs>
            <TabPanel key={'types'} value={activeTab} index={0}>
              <div className={'w-80 py-2'}>
                <div className="flex gap-2 m-1 flex-wrap">
                  {tags.map((s, i) => {
                    return (
                      <div
                        key={'outer-badge' + s + i}
                        className="badge badge-accent justify-start text-ellipsis whitespace-nowrap text-start relative cursor-pointer"
                        onClick={() => handleRemoveTag(s)}
                      >
                        <span>{t(`deal_types.${dealTypes[s].tag}`)}</span>
                        <div
                          className={'badge badge-error absolute -top-2 -right-2 z-10 p-1 leading-none text-xs pb-1.5'}>x
                        </div>
                      </div>
                    );
                  })}
                </div>
                <DealSourcesSelect
                  selected={tags}
                  setSelected={handleSetTags}
                  fullWidth={isSmall}
                  activeSubs={['heart']}
                  hideSymbols
                  label={false}
                />
              </div>
            </TabPanel>
            <TabPanel key={'scenarios'} value={activeTab} index={1}>
              <div className={'w-80 py-2'}>
                <div className="flex gap-2 m-1 flex-wrap">
                  {scenarios.map((s, i) => {
                    return (
                      <div
                        key={'outer-badge' + s + i}
                        className="badge badge-accent justify-start text-ellipsis whitespace-nowrap text-start relative cursor-pointer"
                        onClick={() => handleRemoveScenario(s)}
                      >
                        <span>{s.name}</span>
                        <div
                          className={'badge badge-error absolute -top-2 -right-2 z-10 p-1 leading-none text-xs pb-1.5'}>x
                        </div>
                      </div>
                    );
                  })}
                </div>
                <ScenarioSelect
                  selected={scenarios}
                  setSelected={handleSetScenarios}
                  fullWidth={isSmall}
                  activeSubs={['heart']}
                  label={false}
                />
              </div>
            </TabPanel>
          </div>
        )}

        <div className="my-4 flex flex-col items-center w-full md:w-4/5">
          <Price
            price={price}
            defaultPrice={
              shouldUseFreeEventNumberOfBoards
                ? group.freeWeeklyEventNumberOfBoards
                : handCount
            }
          />
          <Alert
            text={
              <span>
                {t('groups.create_group_session.group_event_info')}
                {canCreateFreeEvent ? (
                  <>
                    <br/>
                    {hasFreeEvents ? t('groups.create_group_session.group_free_events_info') : ''}{t('groups.create_group_session.free_event_info')}
                  </>
                ) : (
                  <>
                    <br />
                    {t('groups.create_group_session.event_price_info')}
                  </>
                )}
              </span>
            }
          />
        </div>
        {hasFreeWeeklyEvent && !canCreateFreeWeeklyEvent && (
          <div className="mb-4 flex justify-center w-full md:w-4/5">
            <Alert
              text={
                <span>
                  {t('groups.create_group_session.free_event_used_info_1')}
                  <br />
                  {t('groups.create_group_session.free_event_used_info_2')}
                </span>
              }
            />
          </div>
        )}
        {!isGroupAdmin && (
          <div className="mb-4 flex justify-center w-full md:w-4/5">
            <Alert
              severity="error"
              text={t('groups.create_group_session.group_admin_error')}
            />
          </div>
        )}
        <button
          className="btn-primary btn pr-8 relative w-64"
          disabled={
            !isGroupAdmin ||
            loading ||
              (shouldForceAdvancedRobots && shouldForceNoRobots)
          }
          onClick={handleCreateEvent}
        >
          {t('groups.create_group_session.create_submit')}
        </button>
        {error && (
          <span className="text-error">
            {error}
          </span>
        )}
      </div>
    </div>
  );
}
