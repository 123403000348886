import { useAppStateStore } from '../appStateStore.jsx';
import { useEffect, useState } from 'react'
import CloseButton from './buttons/closeButton.jsx'

function getNotificationCssClass(type) {
  switch (type) {
  case 'success':
    return 'alert alert-success text-white';
  case 'warning':
    return 'alert alert-warning text-black';
  case 'failure':
    return 'alert alert-error text-white';
  case 'error':
    return 'alert alert-error text-white';
  case 'info':
    return 'alert alert-info text-black';
  default:
    return 'alert alert-info text-black';
  }
}

export default function AdminMessageGlobal() {
  const [adminMessageGlobal, setAdminMessageGlobal] = useState('');
  const metadata = useAppStateStore((state) => state.metadata);

  useEffect(function () {
    if (!metadata?.adminMessageGlobal) {
      setAdminMessageGlobal('');
    } else {
      setAdminMessageGlobal(metadata.adminMessageGlobal);
    }
  }, [metadata?.adminMessageGlobal]);

  if (!adminMessageGlobal) {
    return null;
  }

  let severity = metadata.adminMessageGlobalSeverity || 'info';

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAdminMessageGlobal('');
  }

  return (
    <div className="toast toast-center toast-bottom bottom-16 flex items-center justify-center max-w-[800px] w-[100%] z-[100000]">
      <div
        className={
          getNotificationCssClass(severity) + ' relative pl-8 pr-12 w-5/6 flex items-center justify-center'
        }
      >
        <div className="absolute top-0 right-0">
          <CloseButton
            onClose={handleClose}
          />
        </div>
        <span className="!m-0 whitespace-normal">{adminMessageGlobal}</span>
      </div>
    </div>
  );
}
