export default function TextField({
  label,
  value,
  onChange,
  placeholder,
  fullWidth,
  id,
  error,
}) {
  const _onChange = (event) => {
    const value = event.target.value;
    onChange?.(value);
  };

  return (
    <div className={`flex flex-col${fullWidth ? ' w-full' : ''}`}>
      <label className="label label-text">{label}</label>
      <input
        className="input-accent input w-full border-2 border-solid border-slate-200 bg-white text-black caret-secondary"
        onChange={_onChange}
        value={value}
        placeholder={placeholder}
        aria-describedby={error ? `${id}-helper-text` : undefined}
      />
      {error && (
        <span id={`${id}-helper-text`} className="text-error">
          {error}
        </span>
      )}
    </div>
  );
}
