import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import Dialog from '../../components/dialog';
import Dropdown from '../../components/dropdown/dropdown';
import '../../components/hand/hands.css';
import { UserDisplayFakeSub } from '../../components/users/userDisplay';
import {
  eligibleForTrial,
  getAllProducts,
  getCustomerPortalLink,
  getUserCurrency,
  startCheckoutSession,
} from 'cuebids-firebase/store';
import Club from '../../images/suits/Club';
import Diamond from '../../images/suits/Diamond';
import Heart from '../../images/suits/Heart';
import getFlag, { getCurrency } from '../../util/getFlag';
import { ticketsMap } from '../../util/tickets';
import { useActiveSubs, useAuth, useRemainingTicketsNavbarItems } from '../../util/hooks';
import { useAppStateStore } from '../../appStateStore.jsx';
import { getSubsFromGroups } from '../../util/sub.js'
import goToStore from '../../util/goToStore.js'
import FreeToHeartInfo from '../../marketing/FreeToHeartInfo.jsx'
import ClubToHeartInfo from '../../marketing/ClubToHeartInfo.jsx'
import DiamondToHeartInfo from '../../marketing/DiamondToHeartInfo.jsx'
import FreeToDiamondInfo from '../../marketing/FreeToDiamondInfo.jsx'
import { capitalizeFirstLetter } from '../../util/text.js'
import { Trans, useTranslation } from 'react-i18next'

function UpsellInfo({ highestSub, sub }) {
  if (highestSub === 'none' && sub === 'heart') {
    return <FreeToHeartInfo />;
  }

  if (highestSub === 'club' && sub === 'heart') {
    return <ClubToHeartInfo />;
  }

  if (highestSub === 'diamond' && sub === 'heart') {
    return <DiamondToHeartInfo />;
  }

  if (highestSub === 'none' && sub === 'diamond') {
    return <FreeToDiamondInfo />;
  }

  return <Navigate to="/store?tab=sub" />;
}

const currencySymbols = {
  USD: {
    symbol: '$',
    type: 'prefix',
  },
  EUR: {
    symbol: '€',
    type: 'prefix',
  },
  SEK: {
    symbol: ' kr',
    type: 'suffix',
  },
  GBP: {
    symbol: '£',
    type: 'prefix',
  },
};

export default function Store() {
  const { t } = useTranslation('translation', { keyPrefix: 'store' });
  const [products, setProducts] = useState([]);
  const { currentUser } = useAuth();
  const [hasLoadedProducts, setHasLoadedProducts] = useState(false);
  const [hasLoadedUserCurrency, setHasLoadedUserCurrency] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState('');
  const [interval, setInterval] = useState('year');
  const [userCurrency, setUserCurrency] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentStatus = searchParams.get('status');
  let tab = searchParams.get('tab');
  const sub = searchParams.get('sub') ?? null;
  const nav = useNavigate();
  const user = useAppStateStore((state) => state.user);
  const activeIAPSubs = useAppStateStore((state) => state.activeIAPSubs) || [];
  const activeWebSubs = useAppStateStore((state) => state.activeWebSubs) || [];
  const groups = useAppStateStore((state) => state.groups) || [];
  const isNative = useAppStateStore((state) => state.isNative);
  const iap = useAppStateStore((state) => state.iap);

  const activeSubs = useActiveSubs();

  const highestSub = activeSubs[0] ?? 'none';

  tab = tab || (highestSub === 'none' ? 'sub' : 'tick');

  const hasWebSub = activeWebSubs.includes(highestSub);
  const hasIapSub = activeIAPSubs.includes(highestSub);
  const groupSubs = getSubsFromGroups(groups, currentUser.uid);
  const hasGroupSub = groupSubs.includes(highestSub);
  const hasJuniorSub = user?.junior;

  // TODO: Handle users with multiple subs?

  useRemainingTicketsNavbarItems();

  const hasLoadedPage = hasLoadedProducts && hasLoadedUserCurrency && user;

  useEffect(function () {
    if (isNative) {
      nav('/', { replace: true });
      goToStore({ nav, isNative, iap, uid: currentUser?.uid });
    }
  }, [isNative, nav, iap, currentUser?.uid])

  useEffect(() => {
    async function asyncFn() {
      try {
        const userCurrency = await getUserCurrency();
        if (userCurrency) {
          setUserCurrency(userCurrency.toUpperCase());
        } else {
          const ctry = getFlag();
          const cur = getCurrency(ctry.toUpperCase());
          if (cur) {
            setCurrency(cur);
          }
        }
      } finally {
        setHasLoadedUserCurrency(true);
      }
    }

    asyncFn();
  }, []);

  useEffect(() => {
    async function getData() {
      const data = await getAllProducts();
      setProducts(data);
      setHasLoadedProducts(true);
    }

    getData();
  }, [setProducts]);

  const handleBuyProduct = async (price, productName) => {
    setLoading(true);
    await startCheckoutSession(
      price,
      productName,
      () => setLoading(false),
      null, // put BW promo code here
      tab,
      sub,
      eligibleForTrial(price, currentUser?.uid)
    );
  };

  const currencyToUse = userCurrency || currency;

  const currencies = [
    {
      value: 'USD',
      label: 'USD',
      component: (
        <button className="btn-ghost btn" onClick={() => setCurrency('USD')}>
          USD
        </button>
      ),
    },
    {
      value: 'EUR',
      label: 'EUR',
      component: (
        <button className="btn-ghost btn" onClick={() => setCurrency('EUR')}>
          EUR
        </button>
      ),
    },
    {
      value: 'SEK',
      label: 'SEK',
      component: (
        <button className="btn-ghost btn" onClick={() => setCurrency('SEK')}>
          SEK
        </button>
      ),
    },
    {
      value: 'GBP',
      label: 'GBP',
      component: (
        <button className="btn-ghost btn" onClick={() => setCurrency('GBP')}>
          GBP
        </button>
      ),
    },
  ];

  function getPriceFromCurrency(product) {
    if ((product?.prices?.length ?? 0) === 0) {
      return;
    }
    const price = product.prices.find(
      (p) =>
        p.currency.toLowerCase() === currencyToUse.toLowerCase() &&
        (p.interval === interval || !p.interval)
    );
    if (!price) {
      return;
    }

    return price;
  }

  function getProductPrice(p) {
    const currencySymbol = currencySymbols[currencyToUse];
    const price = getPriceFromCurrency(p);

    if (!price) {
      return <span className="text-md text-black">{t('not_available')}</span>;
    }

    if (!price.interval) {
      return (
        <span className="text-md">
          {currencySymbol.type === 'prefix'
            ? `${currencySymbol.symbol}${price.unit_amount.toFixed(2) / 100}`
            : `${price.unit_amount.toFixed(2) / 100}${currencySymbol.symbol}`}
        </span>
      );
    }

    const hasTrial = eligibleForTrial(price, currentUser?.uid)

    return (
      <div className="mt-2 flex w-full flex-col items-center justify-center">
        <span className="text-md badge badge-ghost font-bold">
          {currencySymbol.type === 'prefix'
            ? `${currencySymbol.symbol}${(
              price.unit_amount / (interval === 'month' ? 100 : 1200)
            ).toFixed(2)} ${t('per_month')}`
            : `${(
              price.unit_amount / (interval === 'month' ? 100 : 1200)
            ).toFixed(2)}${currencySymbol.symbol} ${t('per_month')}`}
        </span>
        {interval === 'year' ? (
          <span className="text-xs text-black">
            {currencySymbol.type === 'prefix'
              ? `${currencySymbol.symbol}${
                price.unit_amount / 100
              } ${t('per_year')}`
              : `${price.unit_amount / 100}${
                currencySymbol.symbol
              } ${t('per_year')}`}
          </span>
        ) : (
          <span className="text-xs text-black opacity-0">-</span>
        )}
        <button
          className="btn-primary btn-sm btn md:btn-md"
          onClick={() => handleBuyProduct(price, p.role)}
        >
          {hasTrial
            ? <span>{t('trial_pre_styled')}<strong className={'decoration-2 underline-offset-2 decoration-black underline'}>{t('trial_styled')}</strong>{t('trial_post_styled')}</span>
            : t('subscribe')}
        </button>
      </div>
    );
  }

  function getPrice(productName) {
    const p = products.find((p) => p.role === productName);
    return getProductPrice(p);
  }

  function handleCloseErrorDialog() {
    setSearchParams(
      function (oldParams) {
        oldParams.delete('status');
        return oldParams;
      },
      { replace: true }
    );
  }

  function handleSwitchTabs(tabId) {
    nav('/store?tab=' + tabId, { replace: true });
  }

  if (!hasLoadedPage) return null;

  if (!products) {
    return <>{t('store_not_available')}</>;
  }

  const intervals = [
    {
      value: 'month',
      label: t('interval_monthly'),
      component: (
        <button className="btn-ghost btn" onClick={() => setInterval('month')}>
          {t('interval_monthly')}
        </button>
      ),
    },
    {
      value: 'year',
      label: t('interval_yearly'),
      component: (
        <button className="btn-ghost btn" onClick={() => setInterval('year')}>
          {t('interval_yearly')}
        </button>
      ),
    },
  ];

  const ticketProducts = products
    .filter((p) => p.metadata.tickets)
    .sort((a, b) => a.metadata.tickets - b.metadata.tickets);

  const handleSubscription = () => {
    let url;
    setLoading(true);
    getCustomerPortalLink()
      .then((e) => {
        url = e.data.url;
        window.open(url, '_blank').focus();
      })
      .catch(() => {
        if (url) {
          window.location.replace(url);
        }
      })
      .finally(() => setLoading(false));
  };

  const subMap = {
    'club': <p>{t('sub_map_club')}</p>,
    'diamond': <p>{t('sub_map_diamond')}</p>,
    'heart': <p className={'text-xl'}>{t('sub_map_heart_pre_styled')}<strong className={'decoration-2 decoration-secondary underline-offset-2 underline'}>{t('sub_map_heart_styled')}</strong>{t('sub_map_heart_post_styled')}</p>
  }

  const content = sub ? (
    <>
      {loading ? (
        <h1 className="flex w-full justify-center p-4">
          {t('payment_loading')}
        </h1>
      ) : (
        <>
          <div className="p-4">{subMap[sub]}</div>
          {!userCurrency && (
            <div className="mb-4">
              <p>{t('select_currency')}</p>
              <div className="flex flex-row items-center gap-4">
                <article className="prose">
                  <h3>{t('currency')}</h3>
                </article>
                <Dropdown size="small" value={currency} options={currencies} />
              </div>
            </div>
          )}
          <div className="w-75">
            <Dropdown value={interval} options={intervals} />
          </div>
          <div
            className={`card p-4 mt-4 w-full border-2 border-solid border-base-100 shadow-xl md:w-56 ${
              sub === 'diamond' ? 'bg-diamond-50' : ''
            }${sub === 'heart' ? 'bg-heart-50' : ''}`}
          >
            <span className="text-black text-center font-bold text-lg">
              {t('subscription', {sub: t(`subs.${sub}`)})}
            </span>
            {getPrice(sub)}
          </div>
          <UpsellInfo sub={sub} highestSub={highestSub} />
        </>
      )}
    </>
  ) : (
    <>
      <div className="tabs tabs-boxed mb-4">
        <a
          role={'tab'}
          onClick={() => handleSwitchTabs('tick')}
          className={'tab h-16 text-lg' + (tab === 'tick' ? ' tab-active' : '')}
        >
          {t('tab_tickets')}
        </a>
        <a
          role={'tab'}
          onClick={() => handleSwitchTabs('sub')}
          className={'tab h-16 text-lg' + (tab === 'sub' ? ' tab-active' : '')}
        >
          {t('tab_subscriptions')}
        </a>
      </div>
      {loading ? (
        <h1 className="flex w-full justify-center p-4">
          {t('payment_loading')}
        </h1>
      ) : (
        <>
          {tab === 'tick' && (
            <div className="flex w-full flex-col items-center justify-center p-4">
              <article className="prose w-4/5">
                <h1>{t('tab_tickets')}</h1>
                <p>{t('tickets_info_1')}</p>
                <p>{t('tickets_info_2')}</p>
              </article>
              {!userCurrency && <p>{t('select_currency')}</p>}
              <div className="my-4 flex flex-row items-center">
                {!userCurrency && (
                  <div className="mb-6 flex flex-row items-center gap-4">
                    <article className="prose">
                      <h3>{t('currency')}</h3>
                    </article>
                    <Dropdown
                      size="small"
                      value={currency}
                      options={currencies}
                    />
                  </div>
                )}
              </div>
              <div className="mb-10 mt-4 grid grid-cols-3 gap-4">
                {ticketProducts.map((p) => {
                  const price = getPriceFromCurrency(p);
                  return (
                    <div
                      key={p.id}
                      className="card h-36 w-32 bg-base-300 shadow-xl"
                    >
                      {p.metadata.valueAdd && (
                        <div className="badge-neutral badge badge-lg absolute -top-4 w-full rounded-b-none rounded-t-md">
                          <p className="text-xl font-bold">
                            {p.metadata.valueAdd}%
                          </p>
                        </div>
                      )}
                      <div className="flex h-full w-full flex-col items-center justify-end p-2">
                        <img src={p.images[0]} alt={p.name} width={50} />
                        <p className="flex w-full justify-center">
                          <strong>{t(p.name)}</strong>
                        </p>
                        <div className="card-actions w-full">
                          <button
                            key={p.id}
                            className="btn-primary btn-block btn"
                            onClick={() =>
                              handleBuyProduct(
                                price,
                                p.metadata.tickets + 'tickets'
                              )
                            }
                          >
                            {getProductPrice(p)}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {tab === 'sub' && (
            <div className="flex w-full flex-col items-center justify-center p-4">
              {activeSubs.length > 0 ? (
                <>
                  <article className="prose w-4/5">
                    <h1>{t('thanks')}</h1>
                    <p>{t('already_subscribed')}</p>
                    <div className="flex flex-col mb-4">
                      {activeSubs.map((s) => {
                        const preload = [
                          'text-hearts',
                          'text-diamonds',
                          'text-clubs',
                        ];
                        return (
                          <div
                            key={s}
                            className={`${s}s font-bold text-xl`}
                          >
                            {t('subscription', {sub: t(`subs.${s}`)})}
                          </div>
                        );
                      })}
                    </div>
                    {hasIapSub && (
                      <p>{t('iap_sub_info')}</p>
                    )}
                    {hasGroupSub && (
                      <p>{t('group_sub_info')}</p>
                    )}
                    {hasJuniorSub && (
                      <p>{t('junior_sub_info')}</p>
                    )}
                    {hasWebSub && (
                      <>
                        <div className="flex items-center w-full justify-between">
                          {highestSub === 'club' && (
                            <div className="w-1/2 h-56 p-2 m-2 rounded-md border-diamond-50 border flex flex-col items-start justify-start">
                              <span className="text-xl font-bold">
                                {t('upgrade_to_diamond.info_1')}
                                <span className="font-bold diamonds">
                                  {t('upgrade_to_diamond.info_2')}
                                </span>
                              </span>
                              <p>
                                {t('upgrade_to_diamond.info_3')}<strong className="diamonds">{t('upgrade_to_diamond.info_4')}</strong>
                                {t('upgrade_to_diamond.info_5')}
                                <strong className="diamonds">{t('upgrade_to_diamond.info_6')}</strong>
                                {t('upgrade_to_diamond.info_7')}
                              </p>
                              <p>
                                {t('manage_subscription_pre_styled')}
                                <strong className="diamonds">
                                  {t('manage_subscription_styled')}
                                </strong>
                                {t('manage_subscription_post_styled')}
                              </p>
                            </div>
                          )}
                          {['club', 'diamond'].includes(highestSub) && (
                            <div className="w-1/2 m-2 h-56 p-2 rounded-md border-hearts-50 border flex flex-col items-start justify-start">
                              <span className="text-xl font-bold">
                                {t('upgrade_to_heart.info_1')}
                                <span className="font-bold hearts">{t('upgrade_to_heart.info_2')}</span>
                              </span>
                              <p>
                                {t('upgrade_to_heart.info_3')}<strong className="hearts">{t('upgrade_to_heart.info_4')}</strong>
                                {t('upgrade_to_heart.info_5')}
                                <strong className="hearts">{t('upgrade_to_heart.info_6')}</strong>
                                {t('upgrade_to_heart.info_7')}
                              </p>
                              <p>
                                {t('manage_subscription_pre_styled')}
                                <strong className="hearts">
                                  {t('manage_subscription_styled')}
                                </strong>{' '}
                                {t('manage_subscription_post_styled')}
                              </p>
                            </div>
                          )}
                          {highestSub === 'heart' && (
                            <div>
                              {t('already_heart.info_1')}
                              <strong className="hearts">{t('already_heart.info_2')}</strong>
                              {t('already_heart.info_3')}
                              <strong className="hearts">{t('already_heart.info_4')}</strong>
                              {t('already_heart.info_5')}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </article>
                  {hasWebSub && (
                    <button
                      className="btn-secondary btn mt-4"
                      onClick={handleSubscription}
                      disabled={loading}
                    >
                      {loading ? t('manage_subscription_loading') : t('manage_subscription')}
                    </button>
                  )}
                </>
              ) : (
                <>
                  <div>
                    <article className="prose">
                      <h1>{t('tab_subscriptions')}</h1>
                    </article>
                    <div className="mt-4" />
                    <p>
                      {t('subscribe_info_1')}
                    </p>
                    <p>
                      {t('subscribe_info_2')}
                    </p>
                    <p>
                      <Trans
                        i18nKey="subscribe_info_3"
                        t={t}
                      />
                    </p>
                    <p>
                      {t('subscribe_info_4_pre_styled')}
                      <span className="font-bold text-amber-500">
                        {t('subscribe_info_4_styled')}
                      </span>
                      {t('subscribe_info_4_post_styled')}
                    </p>
                    <p>
                      <span className="font-bold text-rose-400">
                        {t('subscribe_info_5_1')}
                      </span>
                      <Trans
                        i18nKey="subscribe_info_5_2"
                        t={t}
                      />
                    </p>
                  </div>
                  {!userCurrency && (
                    <div className="mt-4">
                      <p>{t('select_currency')}</p>
                      <div className="flex flex-row items-center gap-4">
                        <article className="prose">
                          <h3>{t('currency')}</h3>
                        </article>
                        <Dropdown
                          size="small"
                          value={currency}
                          options={currencies}
                        />
                      </div>
                    </div>
                  )}
                  <div className="my-4 w-75 md:mb-10">
                    <Dropdown value={interval} options={intervals} />
                  </div>
                  <div className="mt-0 flex w-full flex-col items-center justify-center md:mt-10 md:flex-row">
                    <div className="card card-compact bg-club-50 shadow-xl md:w-56 md:pr-8">
                      <div className="card-body h-36 justify-between md:h-64">
                        <div className="flex h-full w-full flex-row items-start justify-between md:flex-col md:items-center">
                          <div className="flex w-1/2 flex-col items-center justify-center md:w-full">
                            <div className="flex w-full items-center justify-between">
                              <h2 className="card-title text-black">{t('club.title')}</h2>
                              <div className="badge bg-base-100 p-5">
                                <Club width={24} />
                              </div>
                            </div>
                            <div className="flex w-full flex-col items-center justify-center">
                              <ul className="ml-3">
                                <li>
                                  <span className="text-black">
                                    <Trans
                                      i18nKey="club.deal_types_info"
                                      t={t}
                                    />
                                  </span>
                                </li>
                                <li>
                                  <span className="text-black">
                                    <strong>{ticketsMap['club']}</strong>
                                    {t('club.tickets_per_month')}
                                  </span>
                                </li>
                                <li className="opacity-0">-</li>
                              </ul>
                            </div>
                          </div>
                          <div className="hidden rounded-xl bg-base-100 p-3 md:block">
                            <UserDisplayFakeSub
                              uid={currentUser.uid}
                              sub={'club'}
                            />
                          </div>
                          <div className="card-actions">{getPrice('club')}</div>
                        </div>
                      </div>
                    </div>
                    <div className="card card-compact z-10 -m-5 w-full border-2 border-solid border-base-100 bg-diamond-50 pb-0 shadow-xl md:-m-10 md:w-56">
                      <div className="badge badge-lg -mt-4 h-8 w-full">
                        {t('diamond.most_popular')}
                      </div>
                      <div className="card-body h-32 justify-between md:h-80">
                        <div className="flex h-full w-full flex-row items-center justify-between md:flex-col">
                          <div className="flex w-1/2 flex-col items-center justify-center md:w-full">
                            <div className="flex h-full w-full items-center justify-between">
                              <h2 className="card-title text-black">{t('diamond.title')}</h2>
                              <div className="badge bg-base-100 p-5">
                                <Diamond width={24} />
                              </div>
                            </div>
                            <div className="mt-4 flex w-full flex-col items-center justify-center">
                              <ul className="ml-3">
                                <li>
                                  <span className="text-black">
                                     <Trans
                                       i18nKey="diamond.deal_types_info"
                                       t={t}
                                     />
                                  </span>
                                </li>
                                <li>
                                  <span className="text-black">
                                    <strong>{ticketsMap['diamond']}</strong>
                                    {t('diamond.tickets_per_month')}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="hidden rounded-xl bg-base-100 p-3 md:block">
                            <UserDisplayFakeSub
                              uid={currentUser.uid}
                              sub={'diamond'}
                            />
                          </div>
                          <div className="card-actions justify-end">
                            {getPrice('diamond')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card card-compact mt-10 bg-heart-50 shadow-xl md:mt-0 md:w-56 md:pl-8">
                      <div className="badge badge-accent badge-md absolute -top-5 right-0 h-8 w-full rounded-b-none rounded-t-md md:w-48">
                        {t('heart.unlock_everything')}
                      </div>
                      <div className="card-body h-36 justify-between md:h-64">
                        <div className="flex h-full w-full flex-row items-center justify-between md:flex-col md:items-center">
                          <div className="mt-8 flex w-1/2 flex-col items-center justify-center md:mt-0 md:w-full">
                            <div className="flex w-full items-center justify-between">
                              <h2 className="card-title text-black">{t('heart.title')}</h2>
                              <div className="badge bg-base-100 p-5">
                                <Heart width={24} />
                              </div>
                            </div>
                            <div className="flex w-full flex-col items-center justify-center">
                              <ul className="ml-3">
                                <li>
                                  <span className="text-black">
                                    <Trans
                                      i18nKey="heart.deal_types_info"
                                      t={t}
                                    />
                                  </span>
                                </li>
                                <li>
                                  <span className="text-black">
                                     <Trans
                                       i18nKey="heart.endless_tickets"
                                       t={t}
                                     />
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="hidden rounded-xl bg-base-100 p-3 md:block">
                            <UserDisplayFakeSub
                              uid={currentUser.uid}
                              sub={'heart'}
                            />
                          </div>
                          <div className="card-actions">
                            {getPrice('heart')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <div className="fade-in">
        <div className="page pb-40">
          {content}
        </div>
      </div>
      <Dialog
        open={paymentStatus === 'failed'}
        onClose={handleCloseErrorDialog}
      >
        {t('payment_failed')}
      </Dialog>
    </>
  );
}
