import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useActiveSubs, useAuth } from '../../util/hooks.jsx';
import HandCard from '../../components/hand/handCard';
import { useNavigate } from 'react-router-dom';
import '../../components/hand/hands.css';
import {
  deleteSession,
  getSession,
  shareSession,
  unshareSession,
} from '../../firebase/biddingSessions';

import { Star } from '../../components/rating/starRating';
import WeeklyEndWarning from '../../components/infoBoxes/weeklyEnded';
import { AnimatePresence } from 'framer-motion';
import { UserDisplaySmall, UserDisplaySmallCoach } from '../../components/users/userDisplay';
import {
  SelectFriendDropdown,
  SelectUserDropdown,
} from '../../components/dropdown/dropdown';
import { ChallengeIcon } from '../../components/icons/challengeIcon';
import Dialog from '../../components/dialog';
import { WhenToFinishText } from '../challenge/challengeSession';
import {
  hasCompletedOnboardingStep,
  onboardingSteps,
} from '../../util/onboarding';
import Alert, { AlertInline } from '../../components/alert/alert';
import Animated from '../../components/animation/animated';
import { PracticeIcon } from '../../components/icons/practiceIcon';
import { EventIcon } from '../../components/icons/dailyIcon';
import { DeleteIcon } from '../../components/icons/deleteIcon';
import { ShareIcon } from '../../components/icons/shareIcon';
import { GroupsIcon } from '../../components/icons/groupsIcon';
import { useAppStateStore } from '../../appStateStore.jsx';
import TagsDisplay from '../../components/tagsDisplay/TagsDisplay.jsx'
import { FriendsIcon } from '../../components/icons/friendsIcon.jsx';
import { PrintIcon } from '../../components/icons/printIcon.jsx';
import { requestSessionPrint } from 'cuebids-firebase/bidding';
import { isSubEnough } from '../../util/sub.js';
import { useTranslation } from 'react-i18next';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { getRobotUserId } from '../../util/robotPartner.js';
import { RobotSystemDisplay } from './sessionComponents/robotSystemDisplay';

function SessionLinkIcon({ session, t }) {
  if (session.challenge) {
    return (
      <Link to={`/challenge/${session.challenge}`}>
        {session.ranked ? (
          <ChallengeIcon fill="#9f4e4e" />
        ) : (
          <div className={'flex flex-col items-center'}>
            <FriendsIcon fill="#9f4e4e" />
            {session.proChallenge && <div className="badge badge-xs badge-secondary">{t('session.pro_badge')}</div>}
          </div>
        )}
      </Link>
    );
  }

  if (session.daily) {
    return (
      <Link className="flex gap-2" to={`/dailyLeaderboard/${session.daily}`}>
        <EventIcon fill="#4d774e" />
        {session.sessionHeadline && (
          <span className="max-w-[200px] overflow-hidden overflow-ellipsis whitespace-nowrap">
            {session.sessionHeadline}
          </span>
        )}
      </Link>
    );
  }

  if (session.weekly) {
    return (
      <Link className="flex gap-2" to={`/weeklyLeaderboard/${session.weekly}`}>
        <EventIcon fill="#e29627" />
        {session.sessionHeadline && (
          <span className="max-w-[200px] overflow-hidden overflow-ellipsis whitespace-nowrap">
            {session.sessionHeadline}
          </span>
        )}
      </Link>
    );
  }

  if (session.groupSession) {
    return (
      <Link
        className="flex gap-2"
        to={`/groups/${session.groupId}/eventLeaderboard/${session.groupSession}`}
      >
        <GroupsIcon className="fill-blue-400" />
        {session.sessionHeadline && (
          <span className="max-w-[200px] overflow-hidden overflow-ellipsis whitespace-nowrap">
            {session.sessionHeadline}
          </span>
        )}
      </Link>
    );
  }

  return <PracticeIcon fill="#4d774e" />;
}

const scaleMap = {
  'sm': '',
  'md': 'scaled-md',
  'lg': 'scaled-lg',
}

export default function Session() {
  const { id } = useParams();
  const { currentUser } = useAuth();
  const [session, setSession] = useState();
  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const sessionDeals = useAppStateStore((state) => state.sessionDeals);
  const friends = useAppStateStore((state) => state.friends);
  const weekly = useAppStateStore((state) => state.weekly);
  const user = useAppStateStore((state) => state.user);
  const sessions = useAppStateStore((state) => state.sessions);
  const challenges = useAppStateStore((state) => state.challenges);

  const notify = useNotifications();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmPrintOpen, setConfirmPrintOpen] = useState(false);
  const [selectFriendForShare, setSelectFriendForShare] = useState(false);
  const [selectedFriendId, setSelectedFriendId] = useState('');
  const [unshareModalOpen, setUnshareModalOpen] = useState(false);
  const [selectedUserIdForUnshare, setSelectedUserIdForUnshare] = useState('');
  const userActiveSubs = useActiveSubs();
  const canUserPrint = isSubEnough(userActiveSubs, 'diamond');
  const { t } = useTranslation()
  const [challenge, setChallenge] = useState();

  const navigate = useNavigate();

  const finished = session?.dealsCount <= session?.numberOfFinishedDeals;

  const partnerIsRobot = session?.users.includes(getRobotUserId());


  let deals = sessionDeals;
  if (deals.length && deals[0].sessionId !== id) {
    // Session deals is cached from another session, wait for the new ones to be loaded
    deals = [];
  }

  useEffect(() => {
    if (!session?.challenge) {
      return;
    }

    updateAppState({ challengeId: session?.challenge });
    const chall = challenges?.find(
      (s) => s.id === session?.challenge
    );
    setChallenge(chall);
  }, [id, updateAppState, challenges, session?.challenge]);

  useEffect(() => {
    updateAppState({ sessionId: id });
    const sess = sessions?.find((s) => s.id === id);
    if (!sess) {
      getSession(id).then((s) => {
        if (s) {
          if (
            s.users.includes(currentUser.uid) ||
            s.extraUsers.includes(currentUser.uid)
          ) {
            setSession(s);
          } else {
            navigate(`/404${location.pathname}`);
          }
        } else {
          navigate(`/404${location.pathname}`);
        }
      });
    } else {
      setSession(sess);
    }
  }, [id, updateAppState, sessions, navigate, currentUser.uid]);

  const handleClose = () => {
    setConfirmDeleteOpen(false);
  };

  const handleConfirmDeleteSession = async () => {
    setConfirmDeleteOpen(false);
    await deleteSession(id);
    navigate(-1);
  };

  const handleConfirmPrintSession = async () => {
    if(!canUserPrint) return;
    setConfirmPrintOpen(false);
    await requestSessionPrint(user.email, id);
  }

  const handleClosePrint = () => {
    setConfirmPrintOpen(false);
  };

  const handleCloseShare = () => {
    setSelectFriendForShare(false);
  };

  const handleShareWithFriend = async () => {
    setSelectFriendForShare(false);
    const friendId = selectedFriendId;
    setSelectedFriendId('');
    await shareSession(id, friendId);

    const newSess = await getSession(id);
    setSession(newSess);

    notify({ text: t('session.shared_notification'), type: 'success' });
  };

  const handleOpenUnshare = () => {
    setUnshareModalOpen(true);
  };

  const handleCloseUnshare = () => {
    setUnshareModalOpen(false);
  };

  const handleUnshareSession = async () => {
    if (!selectedUserIdForUnshare) return;

    await unshareSession(id, selectedUserIdForUnshare);

    const newSess = await getSession(id);
    setSession(newSess);

    notify({ text: t('session.unshared_notification'), type: 'success' });
    setSelectedUserIdForUnshare('');
    setUnshareModalOpen(false);
  };

  const observer = !session?.users?.includes(currentUser.uid);

  useEffect(() => {
    if (!session || observer) return;
    const handleShareSession = () => {
      setSelectFriendForShare(true);
    };

    const handleDeleteSession = () => {
      setConfirmDeleteOpen(true);
    };

    const handlePrint = async () => {
      setConfirmPrintOpen(true);
    };

    updateAppState({
      navbarItems: (
        <div key="navbar-items-session" style={{ display: 'flex', gap: 10 }}>
          <button className="btn-ghost btn-sm btn"
            onClick={handlePrint}>
            <PrintIcon className="fill-transparent stroke-primary"/>
          </button>
          <button className="btn-ghost btn-sm btn"
            onClick={handleShareSession}>
            <ShareIcon className="fill-primary"/>
          </button>
          <button
            className="btn-ghost btn-sm btn"
            onClick={handleDeleteSession}
          >
            <DeleteIcon className="fill-red-400"/>
          </button>
        </div>
      ),
    });
    return () => updateAppState({ navbarItems: undefined });
  }, [updateAppState, session, observer]);

  if (!session) {
    return <></>;
  }

  const pdId = session.users.find((u) => u !== currentUser.uid);
  const shouldShowOnboarding = !hasCompletedOnboardingStep({ user, step: onboardingSteps.finishSession });

  return (
    <Animated
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <div className="page pb-24">
        <WeeklyEndWarning session={session} weekly={weekly} />
        {challenge && !challenge.finished && challenge.ranked && (
          <WhenToFinishText timestamp={challenge.timestamp} />
        )}
        <div className="grid w-full grid-cols-3 justify-center">
          <div className="badge-accent badge-outline badge flex justify-self-center border-slate-500 h-6 px-2">
            <Star /> <span className="info">{session.numberOfStars}</span>
          </div>
          {session.compete ? (
            <div className="badge-accent badge-outline badge flex justify-self-center border-slate-500 h-6 px-2">
              <span className="info">
                {session.compete === 2 ? t('session.advanced_robots') : t('session.basic_robots')}
              </span>
            </div>
          ) : (
            <div />
          )}
          <div className="badge-accent badge-outline badge flex justify-self-center border-slate-500 h-6 px-2">
            <span className="info">
              {t('session.deals_left')}: {session.dealsCount - session.numberOfFinishedDeals}
            </span>
          </div>
        </div>
        <div className="mt-6 flex items-center">
          {observer ? (
            session.users.map((u) => {
              return (
                <Link
                  style={{
                    marginRight: '16px',
                    width: '125px',
                  }}
                  to={'/player/' + u}
                  key={u}
                >
                  <UserDisplaySmall uid={u} />
                </Link>
              );
            })
          ) : (
            <>
              {pdId && (
                <Link
                  style={{
                    marginRight: '16px',
                    width: '125px',
                  }}
                  to={'/player/' + pdId}
                >
                  {
                    session.coachRobot ? <UserDisplaySmallCoach uid={pdId} name={session.coachRobot.name} /> : <UserDisplaySmall uid={pdId} />
                  }
                </Link>
              )}
              <SessionLinkIcon session={session} t={t} />
            </>
          )}
        </div>
        {
          partnerIsRobot && (
            <RobotSystemDisplay session={session} />
          )
        }
        <TagsDisplay
          tags={session.tags}
          tagNames={session.tagNames}
          sx="mt-4"
        />
        {shouldShowOnboarding && (
          <Alert
            sx="border-pulse"
            text={t('onboarding.step_bidding.click_on_session')}
          />
        )}
        {shouldShowOnboarding &&
          deals.filter((d) => d.turn === currentUser.uid).length === 0 &&
          deals.filter((d) => !d.finished).length !== 0 && (
          <Alert
            sx="border-pulse"
            text={t('onboarding.step_bidding.partners_turn')}
          />
        )}
        {session.extraUsers?.length > 0 && !observer && (
          <div className="flex items-center gap-2 mt-4">
            <span className="text-sm">
              {t('session.shared_with_users', { users: session.extraUsers?.length ?? 0 })}
            </span>
            <button
              className="btn-outline btn-error btn-xs btn h-6"
              onClick={handleOpenUnshare}
            >
              {t('session.unshare')}
            </button>
          </div>
        )}
        <table className={`mt-10 w-full table-auto border-collapse rounded-sm md:w-4/5`}>
          <thead>
            <tr>
              <th>{t('session.hand')}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <AnimatePresence>
              {deals.map((s, i) => {
                return (
                  <HandCard
                    i={i}
                    deal={s}
                    key={s.id}
                    currentUser={currentUser}
                  />
                );
              })}
            </AnimatePresence>
          </tbody>
        </table>
        <Dialog
          open={confirmPrintOpen}
          onClose={handleClosePrint}
          title={t('session.print.title')}
          actions={[
            {
              component: (
                <button
                  key="yes"
                  className="btn-error btn"
                  role="button"
                  disabled={!canUserPrint || !finished || !user.email}
                  onClick={handleConfirmPrintSession}
                >
                  {t('session.print.confirm_button')}
                </button>
              ),
            },
          ]}
        >
          {t('session.print.info_1')}
           <strong
            className={'underline decoration-2 decoration-secondary'}>{user.email}</strong>
          <br/>
          <br/>
          {t('session.print.info_2')}
          {
            !finished && (
              <Alert
                sx="mt-4"
                severity={'warning'}
                text={t('session.print.warning_not_finished')} />
            )
          }
          {
            !user.email && (
              <Alert
                sx="mt-4"
                severity={'warning'}
                text={t('session.print.warning_no_email')} />
            )
          }
          {
            !canUserPrint && (
              <Alert
                sx="mt-4"
                severity={'warning'}
                text={t('session.print.warning_missing_sub')} />
            )
          }
        </Dialog>
        <Dialog
          open={confirmDeleteOpen}
          onClose={handleClose}
          title={t('session.delete.title')}
          actions={[
            {
              component: (
                <div
                  key="yes"
                  className="btn-error btn"
                  role="button"
                  onClick={handleConfirmDeleteSession}
                >
                  {t('session.delete.confirm_button')}
                </div>
              ),
            },
          ]}
        >
          {t('session.delete.info')}
          {session.weekly !== 0 && (
            <>
              <div className="mt-4" />
              <span>
                <EventIcon fill="#e29627" /> {t('session.delete.info_weekly')}
              </span>
            </>
          )}
          {session.daily !== 0 && (
            <>
              <div className="mt-4" />
              <span>
                <EventIcon fill="#4d774e" /> {t('session.delete.info_daily')}
              </span>
            </>
          )}
        </Dialog>
        <Dialog
          open={selectFriendForShare}
          onClose={handleCloseShare}
          title={t('session.share_session')}
          actions={[
            {
              component: (
                <div
                  key="yes"
                  className="btn-primary btn"
                  role="button"
                  onClick={handleShareWithFriend}
                >
                  {t('session.share')}
                </div>
              ),
            },
          ]}
        >
          <div className="h-64">
            <SelectFriendDropdown
              value={selectedFriendId}
              onChange={setSelectedFriendId}
              label={t('session.share_with')}
              friends={friends}
              disableFriendIds={session.users.concat(session.extraUsers)}
            />
            <AlertInline
              sx="mt-4"
              text={t('session.share_with_friends')}
            />
          </div>
        </Dialog>
        {unshareModalOpen && (
          <Dialog
            open={unshareModalOpen}
            onClose={handleCloseUnshare}
            title={t('session.unshare')}
            actions={[
              {
                component: (
                  <div
                    disabled={!selectedUserIdForUnshare}
                    key="yes"
                    className="btn-primary btn"
                    role="button"
                    onClick={handleUnshareSession}
                  >
                    {t('session.unshare')}
                  </div>
                ),
              },
            ]}
          >
            <div className="grid h-64">
              <SelectUserDropdown
                users={session.extraUsers}
                label={t('session.remove_access_for')}
                value={selectedUserIdForUnshare}
                onChange={setSelectedUserIdForUnshare}
              />
            </div>
          </Dialog>
        )}
      </div>
    </Animated>
  );
}
