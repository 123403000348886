import { FriendsIcon } from '../../components/icons/friendsIcon.jsx';
import { GridCard } from '../../components/cardGrid/cardGrid.jsx';
import { PracticeIcon } from '../../components/icons/practiceIcon.jsx';
import { RobotIcon } from '../../components/icons/robotIcon.jsx';
import Dropdown from '../../components/dropdown/dropdown.jsx';
import { TicketIcon } from '../../components/icons/ticketsIcon.jsx';
import { AnimatePresence } from 'framer-motion';
import HandCard from '../../components/hand/handCard.jsx';
import SessionDealMock from '../mocks/sessionDealMocks.jsx';
import { Helmet } from 'react-helmet-async';
import LanguageDisclaimer from '../../components/languageDisclaimer/LanguageDisclaimer.jsx'

export default function SwedishGuide() {
  return (
    <>
      <Helmet>
        <title>Cuebids - Finslipa din budgivning</title>
        <meta name="description" content="Lär dig använda Cuebids" />
        <meta name="robots" content="index, follow" />
        <meta name="canonical" content="https://cuebids.com/guides/swe" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Cuebids - Finslipa din budgivning" />
        <meta property="og:description" content="Lär dig använda Cuebids" />
        <meta property="og:url" content="https://cuebids.com/guides/swe" />
        <meta property="og:site_name" content="Cuebids" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@cuebids" />
        <meta name="twitter:title" content="Cuebids - Finslipa din budgivning" />
        <meta name="twitter:description" content="Lär dig använda Cuebids" />
        <meta name="twitter:url" content="https://cuebids.com/guides/swe" />
        <meta name="keywords" content="Cuebids,guide,budträning,svenska,träna" />
      </Helmet>
      <div className="page p-4">
        <LanguageDisclaimer language="sv" />
        <article className="prose select-text">
          <h1>Finslipa din budgivning</h1>
          <blockquote>Vi är alla i olika skeden i vår bridgeresa, men oavsett om man är nybörjare eller veteran är en sak säkert
          - man är <strong className={'decoration-2 underline-offset-2 decoration-secondary underline'}>aldrig
            fullärd!</strong></blockquote>

          <p>En av de mest tillfredsställande stunderna i bridge är att,
          efter en spännande budgivning, landa i <strong className={'decoration-2 underline-offset-2 decoration-secondary underline'}>rätt
            kontrakt</strong>.</p>
          <p>På andra sidan av myntet, är det jobbigt och lite skamset när det blir <strong className={'decoration-2 underline-offset-2 decoration-secondary underline'}>
          missförstånd</strong> mellan dig och din partner.</p>
          <p>Det är här <a href={'https://cuebids.com'} className={'decoration-2 underline-offset-2 decoration-primary underline'}>Cuebids</a> kommer in.
          Vi gör det möjligt att träna budgivning på din nivå, med din partner.</p>
          <p>Bjud när du har tid, ni behöver inte koordinera något. Använder ni mobil-appen får ni notiser när din partner är färdig.</p>

          <h2>Kom igång - det är <span className={'decoration-2 underline-offset-3 decoration-secondary underline'}>gratis</span></h2>
          <p>Ladda hem appen Cuebids på iPhone, Android eller iPad.</p>
          <p>Cuebids finns också tillgängligt som en hemsida på <a href={'https://cuebids.com'} className={'decoration-2 underline-offset-2 decoration-primary underline'}>www.cuebids.com</a>.</p>

          <p>Skapa ett konto genom att följa instruktionerna. Du kan sedan testa att bjuda en bricka med roboten Lia eller få in din partner i Cuebids.</p>

          <h2>Starta en session</h2>
          Väl inne i Cuebids, följ dessa steg för att starta en session:

          <ol>
            <li>
              <strong>Tryck på Practice</strong>
              <br />
              <GridCard
                name="Practice"
                icon={<PracticeIcon strokeWidth={0.5} fill="#4d774e" width={32} />}
                i={1}
              />
            </li>
            <li>
              <strong>Och sedan</strong>
              <br />
              <div className={'flex gap-2 w-32 items-center'}>
                <GridCard
                  name="With a friend"
                  icon={<FriendsIcon strokeWidth={0.5} fill="#4d774e" width={32} />}
                  i={1}
                />
            eller
                <GridCard
                  name="With Robot"
                  icon={<RobotIcon className={'fill-sky-400'} width="32px" />}
                  i={1}
                />
              </div>
            </li>
            <li>
              <strong>Välj antal brickor, och eventuellt robot-motstånd</strong>
              <br />
              <div className="mt-4 flex w-80 items-start justify-between gap-4">
                <div className="flex w-20 shrink flex-col">
                  <label className="label">
                    <span className="label-text">Boards</span>
                  </label>
                  <input
                    className="input w-20"
                    type="number"
                    min={1}
                    max={24}
                    value={4}
                    readOnly
                  />
                </div>
                <div className="flex grow flex-col  not-prose">
                  <label className="label">
                    <span className="label-text">Robots (opponents)</span>
                  </label>
                  <Dropdown size={'normal'} value={'None'} label={'Robots'} options={[
                    {
                      value: 0,
                      label: 'None',
                      component: (
                        <button
                          className="btn-ghost btn w-full justify-start items-center flex"
                        >
                          <div className="w-full h-full flex items-center justify-start">
                  None
                          </div>
                        </button>
                      ),
                    },
                    {
                      value: 1,
                      label: 'Basic',
                      component: (
                        <button
                          className="btn-ghost btn w-full justify-between items-center flex"
                        >
                          <div className="w-full h-full flex items-center justify-start">
                  Basic</div>
                        </button>
                      ),
                    },
                    {
                      value: 2,
                      label: (
                        <div className="h-full flex items-center justify-start relative gap-2">
                Advanced&nbsp;
                          <TicketIcon />
                        </div>
                      ),
                      component: (
                        <button
                          className="btn-ghost btn relative w-full justify-between items-center flex"
                        >
                          <div className="h-full flex items-center justify-start">
                  Advanced
                            <div className="absolute right-10">
                              <TicketIcon />
                            </div>
                          </div>
                        </button>
                      ),
                    },
                  ]} />
                </div>
              </div>
            </li>
            <li>
              <strong>Starta genom att klicka på den gröna knappen</strong>
              <br />
              <button
                className="btn-primary btn mt-4"
              >
            Create new session
              </button>
            </li>
          </ol>

          <h2>En session</h2>
          I en session kan du se alla händerna i en tabell:
          <table className="mt-10 w-full table-auto border-collapse rounded-sm md:w-4/5 not-prose">
            <thead>
              <tr>
                <th>Hand</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <AnimatePresence>
                {[
                  {
                    id: 1,
                    users: [1, 2],
                    hand: 'KJ98.AQ6.K5.QJ43',
                    turn: 1,
                  },
                  {
                    id: 2,
                    users: [1, 2],
                    hand: 'K96532.QT.AK.T82',
                    turn: 1,
                  },            {
                    id: 3,
                    users: [1, 2],
                    hand: 'JT92.AJ8752.42.Q',
                    turn: 0,
                  }
                ].map((s, i) => {
                  return (
                    <HandCard
                      i={i}
                      deal={s}
                      key={s.id}
                      currentUser={{ uid: 1 }}
                      mock={true}
                    />
                  );
                })}
              </AnimatePresence>
            </tbody>
          </table>
          Om raden är <strong className={'decoration-2 underline-offset-2 decoration-error underline'}>röd</strong> är det din tur att bjuda, då har den även en ikon till höger.
          <p>Klicka på raden för att se läget</p>

          <h2>En hand</h2>
          Väl inne i handen kan du se budgivningen och bjuda själv.
          Du kan testa hur det kommer att fungera nedan:
          <div className={'not-prose -ml-10 scale-75 md:scale-100'}>
            <SessionDealMock id={1}/>
          </div>


          <h2>Feedback och annat</h2>
          När ni har bjudit klart en giv får ni betyg på hur ni har bjudit.
          <table className="mt-10 w-full table-auto border-collapse rounded-sm md:w-4/5 not-prose">
            <thead>
              <tr>
                <th>Hand</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <AnimatePresence>
                {[
                  {
                    id: 1,
                    users: [1, 2],
                    hand: 'KJ98.AQ6.K5.QJ43',
                    finished: true,
                    resultGrade: 3,
                    contract: '3NT',
                  },
                  {
                    id: 2,
                    users: [1, 2],
                    hand: 'K96532.QT.AK.T82',
                    finished: true,
                    resultGrade: 2,
                    contract: '2S',
                  },            {
                    id: 3,
                    users: [1, 2],
                    hand: 'JT92.AJ8752.42.Q',
                    finished: true,
                    resultGrade: 1,
                    contract: '3H',
                  }
                ].map((s, i) => {
                  return (
                    <HandCard
                      i={i}
                      deal={s}
                      key={s.id}
                      currentUser={{ uid: 1 }}
                      mock={true}
                    />
                  );
                })}
              </AnimatePresence>
            </tbody>
          </table>

          <h2>Lycka till!</h2>
          Hoppas det här hjälper till för att komma igång med Cuebids, och det är bara att höra av sig om du behöver hjälp.
          <br />
          <a href={'mailto:support@cuebids.com'}>support@cuebids.com</a>
          <br />
          <br /><br />

        </article>
      </div>
    </>
  );
}
