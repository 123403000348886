import { cuebidsToPbn, getAlertAndExplain, getBidArrayWithAlertsIncludingPositionalSymbols, getBidArrayWithoutAlertsExcludingPositionalSymbols, getBidArrayWithoutAlertsIncludingPositionalSymbols, getHighestRobotBid, isBiddingFinished, parseBidding, pbnToCuebids, replaceAlert } from 'cuebids-bidding-util';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { getHandFromDirection } from 'cuebids-hand-util';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { willRobotsDouble } from 'cuebids-ai';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { evaluate } from 'cuebids-evaluation';
import { submitInternalFeedback } from '../feedback';
import { currentUserId, getFirestore, getFunctions, getRepos } from '../base';
import { runTransaction, updateDoc, writeBatch } from 'firebase/firestore';
import { canRobotsDouble, checkBidding, checkHasViolatedSuggestedBidding, getActorKey, getBidderIndex, getDirection, getNewBidding, getNextUserId, getPartnerDirection, isCurrentBiddingPartOfSuggestedBidding, removeAlerts } from './util';
import { getDeal } from './deals';
import { docToObject } from 'firebase-util';
import { handleFinishDeal, sendNotification, swapTurns } from './bidOnSessionDealHelperFunctions';
import { sessionAdapter } from './adapters';
import { httpsCallable } from 'firebase/functions';
import { parseJson } from 'nx/src/utils/json';
export async function prepareBidOnSessionDeal({ sessionDeal, bid, alert = '', explain = '', partnerBidAlert, partnerBidAlertArtificial, challengeId }) {
    if ((alert ?? '') !== '') {
        bid = `${bid}[@"${alert}"]`;
    }
    if ((explain ?? '') !== '') {
        bid = `${bid}["${explain}"]`;
    }
    const compete = sessionDeal.compete;
    let oldBidding = sessionDeal.bidding;
    if (partnerBidAlert != null) {
        const biddingLength = getBidArrayWithAlertsIncludingPositionalSymbols(oldBidding).length;
        const arr = getBidArrayWithoutAlertsExcludingPositionalSymbols(oldBidding);
        const partnerBid = arr.at(-2);
        const cleanedAlert = cleanBid({
            bid: partnerBid,
            alert: partnerBidAlert
        }).alert ?? '';
        oldBidding = replaceAlert({
            bidding: oldBidding,
            index: biddingLength - 2,
            alert: cleanedAlert,
            artificial: partnerBidAlertArtificial
        });
    }
    const newBidding = getNewBidding({
        sessionDeal: {
            ...sessionDeal,
            bidding: oldBidding
        },
        bid,
        compete
    });
    if (!checkBidding(newBidding)) {
        void submitInternalFeedback({
            category: 'bug',
            message: `Error with bidding on session deal: ${sessionDeal.id}. Old bidding: ${oldBidding}. Bid: ${bid}. New bidding: ${newBidding}.`
        });
        throw new Error('error with bidding');
    }
    const isFinalBid = isBiddingFinished(newBidding);
    if (!isFinalBid) {
        return newBidding;
    }
    const dealPromise = getDeal(sessionDeal.dealId);
    let challengeData;
    if (challengeId !== 0) {
        const challengeDoc = await getRepos().challengeSession.doc(challengeId);
        challengeData = docToObject(challengeDoc);
    }
    const deal = await dealPromise;
    const suggestedBidding = sessionDeal.suggestedBidding?.bidding ?? null;
    if (canRobotsDouble(deal.version, compete) &&
        !(suggestedBidding != null &&
            !checkHasViolatedSuggestedBidding(newBidding, suggestedBidding))) {
        if (newBidding === `${oldBidding}-${bid}-P`) {
            const shouldAddDouble = willRobotsDouble({
                deal,
                bidding: `${oldBidding}-${bid}`,
                compete
            });
            if (shouldAddDouble) {
                return `${oldBidding}-${bid}-D`;
            }
        }
    }
    const { finalBid, declarer, doubled } = parseBidding(newBidding, deal.version);
    const highestRobotBid = getHighestRobotBid(newBidding);
    return {
        evaluation: evaluate({
            deal,
            finalBid,
            declarer,
            highestRobotBid,
            doubled,
            vulnerability: sessionDeal.vulnerability,
            bidding: newBidding,
            compete: sessionDeal.compete,
            ai: sessionDeal.ai,
            useHalfStars: true
        }),
        newBidding,
        finalBid,
        declarer,
        doubled,
        challengeData
    };
}
export async function updateAlert(sessionDeal, bidding) {
    const isMe = sessionDeal.turn === currentUserId();
    const sessionDealRef = getRepos().sessionDeals.docRef(sessionDeal.id);
    if (isMe) {
        await updateDoc(sessionDealRef, {
            bidding,
            lastAction: 'alert'
        });
    }
}
export async function bidOnSessionDeal({ sessionDeal, preparePromise, weeklySessionId, dailySessionId, groupSessionId, numberOfRemainingDeals }) {
    const dateNow = Date.now();
    const sessionRef = getRepos().sessions.docRef(sessionDeal.sessionId);
    const sessionDealRef = getRepos().sessionDeals.docRef(sessionDeal.id);
    const sessionDealsCollectionRef = getRepos().sessionDeals.collection;
    const turn = getNextUserId(sessionDeal.users, currentUserId());
    const actorKey = getActorKey(getBidderIndex(sessionDeal.users, currentUserId()));
    const preparedData = await preparePromise;
    let dealsRemaining = numberOfRemainingDeals;
    let isFinalBid = false;
    if (typeof preparedData === 'string') {
        // Just a new bidding
        const batch = writeBatch(getFirestore());
        swapTurns({
            transactionOrBatch: batch,
            sessionRef,
            sessionDealRef,
            dateNow,
            oldBidding: sessionDeal.bidding,
            newBidding: preparedData,
            turn,
            actorKey,
            isRobotBid: false
        });
        await batch.commit();
    }
    else {
        // Was final bid, should do evaluation etc
        isFinalBid = true;
        // If close to finishing session with leaderboard
        const shouldUseTransaction = numberOfRemainingDeals < 4 && (dailySessionId !== 0 || weeklySessionId !== 0 || groupSessionId !== 0);
        if (shouldUseTransaction) {
            dealsRemaining = await runTransaction(getFirestore(), async function (transaction) {
                const session = await transaction.get(sessionRef);
                const sessionData = docToObject(session, sessionAdapter);
                return await handleFinishDeal({
                    transaction,
                    sessionDeal,
                    preparedData,
                    actorKey,
                    sessionRef,
                    sessionDealRef,
                    dateNow,
                    turn,
                    dailySessionId,
                    weeklySessionId,
                    groupSessionId,
                    sessionData,
                    sessionDealsCollectionRef,
                    numberOfRemainingDeals,
                    isRobotBid: false
                });
            });
        }
        else {
            dealsRemaining = await handleFinishDeal({
                sessionDeal,
                preparedData,
                actorKey,
                sessionRef,
                sessionDealRef,
                dateNow,
                turn,
                dailySessionId,
                weeklySessionId,
                groupSessionId,
                sessionDealsCollectionRef,
                numberOfRemainingDeals,
                isRobotBid: false
            });
        }
    }
    // TODO: Could pass in data on how many more deals it is my turn and only call this (which has proper check) when few deals left, like how we check for using transaction or batch.
    void sendNotification({
        sessionDealsCollectionRef,
        sessionDeal,
        turn,
        dealsRemaining
    });
    return isFinalBid;
}
export async function robotBidOnSessionDeal({ sessionDeal, preparePromise, weeklySessionId, dailySessionId, groupSessionId, numberOfRemainingDeals }) {
    const dateNow = Date.now();
    const sessionRef = getRepos().sessions.docRef(sessionDeal.sessionId);
    const sessionDealRef = getRepos().sessionDeals.docRef(sessionDeal.id);
    const sessionDealsCollectionRef = getRepos().sessionDeals.collection;
    const turn = currentUserId();
    const actorKey = getActorKey(getBidderIndex(sessionDeal.users, currentUserId()));
    const preparedData = await preparePromise;
    await runTransaction(getFirestore(), async function (transaction) {
        const sessionDealDoc = await transaction.get(sessionDealRef);
        // TODO: Adapter? (might exist in other branch, not really needed here)
        const sessionDealData = sessionDealDoc.data();
        if (sessionDeal.bidding !== sessionDealData.bidding) {
            // Bidding has changed (undo), do nothing
            return;
        }
        if (typeof preparedData === 'string') {
            // Just a new bidding
            swapTurns({
                transactionOrBatch: transaction,
                sessionRef,
                sessionDealRef,
                dateNow,
                oldBidding: sessionDeal.bidding,
                newBidding: preparedData,
                turn,
                actorKey,
                isRobotBid: true
            });
        }
        else {
            // Was final bid, should do evaluation etc
            const session = await transaction.get(sessionRef);
            const sessionData = docToObject(session, sessionAdapter);
            await handleFinishDeal({
                transaction,
                sessionDeal,
                preparedData,
                actorKey,
                sessionRef,
                sessionDealRef,
                dateNow,
                turn,
                dailySessionId,
                weeklySessionId,
                groupSessionId,
                sessionData,
                sessionDealsCollectionRef,
                numberOfRemainingDeals,
                isRobotBid: true
            });
        }
    });
}
const robotMap = {
    0: 'DEFAULT',
    1: 'DEFAULT',
    2: 'ADVANCED'
};
async function httpGetRobotBid(sessionDeal, robotUserId, conventions, lang = 'en') {
    const functions = getFunctions();
    const func = httpsCallable(functions, 'request_bid');
    const direction = getDirection(getBidderIndex(sessionDeal.users, robotUserId));
    const request = {
        dealer: sessionDeal.dealer,
        auction: getBidArrayWithoutAlertsExcludingPositionalSymbols(sessionDeal.bidding).map(cuebidsToPbn),
        vuln: sessionDeal.vulnerability,
        hand: getHandFromDirection(sessionDeal.hand, direction),
        sysNS: sessionDeal.liaSystem ?? 'DEFAULT',
        sysEW: sessionDeal.liaEWSystem ?? robotMap[sessionDeal.compete],
        conventions: sessionDeal.liaSystem === 'MY_SYSTEM' ? conventions : [],
        lang
    };
    const bid = await func({ request });
    if (typeof bid.data === 'string') {
        const response = (parseJson(bid.data));
        return cleanBid(response);
    }
    else {
        throw new Error('Failed to reach Lia');
    }
}
export async function httpGetLiaAlerts(sessionDeal, robotUserId, conventions, lang = 'en') {
    const functions = getFunctions();
    const func = httpsCallable(functions, 'request_alerts');
    const liaPartnerDirection = getPartnerDirection(getBidderIndex(sessionDeal.users, robotUserId));
    const request = {
        dealer: sessionDeal.dealer,
        auction: getBidArrayWithoutAlertsExcludingPositionalSymbols(sessionDeal.bidding).map(cuebidsToPbn),
        vuln: sessionDeal.vulnerability,
        hand: getHandFromDirection(sessionDeal.hand, liaPartnerDirection),
        sysNS: sessionDeal.liaSystem ?? 'DEFAULT',
        sysEW: sessionDeal.liaEWSystem ?? robotMap[sessionDeal.compete],
        conventions: sessionDeal.liaSystem === 'MY_SYSTEM' ? conventions : [],
        lang
    };
    const alerts = await func({ request });
    if (typeof alerts.data === 'string') {
        const response = (parseJson(alerts.data));
        return Object.keys(response.alerts).reduce(function (a, key) {
            const cleaned = cleanAlert(response.alerts[key]);
            if (cleaned != null && cleaned !== '') {
                a[key] = cleaned;
            }
            return a;
        }, {});
    }
    else {
        throw new Error('Failed to reach Lia');
    }
}
function cleanBid(bid) {
    const newBid = {
        ...bid
    };
    if (newBid.bid === 'P') {
        newBid.alert = '';
    }
    newBid.alert = cleanAlert(newBid.alert ?? '');
    return newBid;
}
function cleanAlert(alert) {
    if (alert != null) {
        alert = alert.trim();
        alert = replaceDashWithNewLine(alert);
        alert = removeDefaultBid(alert);
        alert = removeNotRecommendedBid(alert);
        alert = takeOutDouble(alert);
        alert = removeNone(alert);
        alert = removeZeroHp(alert);
        alert = withRegexRemoveRepeatedNumbers(alert);
        alert = removeWarning(alert);
        alert = replaceMichaels(alert);
        alert = removeTheLaw(alert);
        alert = removeAmbiguousRaise(alert);
        alert = removeBothGoodAndBad(alert);
        alert = replaceIllegalCharacters(alert);
        alert = removeHumanOnly(alert);
        alert = removeEmptyLines(alert);
        alert = prettifyTransfer(alert);
    }
    return alert;
}
function prettifyTransfer(alert) {
    return alert
        .replace(/Transfer:/, 'Transfer to ');
}
function replaceDashWithNewLine(alert) {
    return alert
        .replaceAll(/--/g, '\n');
}
function removeEmptyLines(alert) {
    return alert.split('\n')
        .filter(line => line.trim() !== '')
        .join('\n');
}
function takeOutDouble(alert) {
    return alert;
    // return alert.includes('Take out') ? 'Takeout double' : alert
}
function removeNone(alert) {
    return alert.replace(/\n(?!.*\n.*:None).*:None/g, '');
}
function removeHumanOnly(alert) {
    return alert.replace(/^0\+ Human only --/, '');
}
function removeDefaultBid(alert) {
    return alert.replace(/(\n)?default.*/i, '');
}
function removeNotRecommendedBid(alert) {
    // Note: Two cases in case they fix their typo
    return (alert.includes('This bid is not referenced. It is recommanded to avoid it') ||
        alert.includes('This bid is not referenced. It is recommended to avoid it'))
        ? ''
        : alert;
}
function withRegexRemoveRepeatedNumbers(alert) {
    return alert.replace(/\b(\d+)-\1\b/g, '$1');
}
function removeZeroHp(alert) {
    return alert
        .replace(/^0\+ hcp \n/, '')
        .replace(/^0\+ hcp/, '')
        .replace(/\b0\+ hcp\b/g, '')
        .replace(/\b0\+ total points\b/g, '');
}
function removeWarning(alert) {
    return alert.replace(/<!>/, '');
}
function replaceMichaels(alert) {
    return alert.replace(/michael:.*/, 'Michaels');
}
function removeTheLaw(alert) {
    return alert.replace(/ltt data:The LAW.*/, '');
}
function removeAmbiguousRaise(alert) {
    return alert.replace(/ambiguous raise:.*/, '');
}
function removeBothGoodAndBad(alert) {
    return alert.replace(/(good.*)\n bad:*/, '$1');
}
function replaceIllegalCharacters(alert) {
    return alert
        .replaceAll('-', '—')
        .replaceAll('[', '(')
        .replaceAll(']', ')');
}
async function sleep(ms) {
    await new Promise(resolve => setTimeout(resolve, ms));
}
async function getBidWithRetries(sessionDeal, robotId, conventions, attemptNr = 0, lang = 'en') {
    try {
        return await httpGetRobotBid(sessionDeal, robotId, conventions, lang);
    }
    catch (e) {
        if (attemptNr < 3) {
            await sleep(5000 * Math.pow(2, attemptNr));
            return await getBidWithRetries(sessionDeal, robotId, conventions, attemptNr + 1, lang);
        }
        else {
            return undefined;
        }
    }
}
export async function singlePlayerRobotBidOnSessionDeal({ sessionDeal, numberOfRemainingDeals, weeklySessionId, dailySessionId, groupSessionId, robotId, conventions, t }) {
    const suggestedBidding = sessionDeal.suggestedBidding?.bidding ?? '';
    if ((suggestedBidding !== '') && (sessionDeal.coachRobot != null)) {
        const suggestedBiddingArray = getBidArrayWithoutAlertsIncludingPositionalSymbols(suggestedBidding);
        const currentBidArray = getBidArrayWithoutAlertsIncludingPositionalSymbols(sessionDeal.bidding);
        if (isCurrentBiddingPartOfSuggestedBidding(currentBidArray, suggestedBiddingArray)) {
            const nextBid = currentBidArray.length;
            const suggestions = getBidArrayWithAlertsIncludingPositionalSymbols(suggestedBidding);
            const bidWithAlert = suggestions.at(nextBid);
            // TODO: Should coach be allowed to enter suggested biddings that have not ended?
            if (bidWithAlert != null) {
                const bid = removeAlerts(bidWithAlert);
                const { alert, explain } = getAlertAndExplain(bidWithAlert);
                const preparePromise = prepareBidOnSessionDeal({
                    sessionDeal,
                    bid,
                    explain,
                    alert,
                    challengeId: 0
                });
                return robotBidOnSessionDeal({
                    sessionDeal,
                    preparePromise,
                    weeklySessionId,
                    dailySessionId,
                    groupSessionId,
                    numberOfRemainingDeals
                });
            }
        }
    }
    const partnerRobotBid = await getBidWithRetries(sessionDeal, robotId, conventions, 0, t('lia_lang'));
    if (partnerRobotBid == null) {
        throw new Error('Failed to get Lia bid');
    }
    const preparePromise = prepareBidOnSessionDeal({
        sessionDeal,
        bid: pbnToCuebids(partnerRobotBid.bid),
        [partnerRobotBid.artificial === true ? 'alert' : 'explain']: (partnerRobotBid.alert ?? ''),
        partnerBidAlert: partnerRobotBid.partnerBidAlert,
        partnerBidAlertArtificial: partnerRobotBid.partnerBidAlertArtificial,
        challengeId: 0
    });
    return robotBidOnSessionDeal({
        sessionDeal,
        preparePromise,
        weeklySessionId,
        dailySessionId,
        groupSessionId,
        numberOfRemainingDeals
    });
}
