export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`} // TODO: Was this something from material? Dooes this label exist?
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export function Tabs({ children }) {
  return (
    <div role="tablist" className="tabs tabs-boxed justify-center">
      {children}
    </div>
  );
}

export function Tab({ label, active, onChange, value }) {
  return (
    <a
      role={'tab'}
      onClick={function (e) {
        e.preventDefault();
        onChange(value);
      }}
      className={`tab leading-4 h-10 ${
        active ? ' tab-active' : ''
      }`}
    >
      {label}
    </a>
  );
}
