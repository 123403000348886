export function checkMobileDevice() {
  if (!window) {
    return false;
  }
  if (!window.navigator) {
    return false;
  }
  if (!window.navigator.userAgent) {
    return false;
  }


  return (window.navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Opera Mini/i) ||
  navigator.userAgent.match(/Windows Phone/i));
}


export function checkIfNativeIos() {
  const standalone = window.navigator.standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  return (ios && !standalone && !safari);
}


export default function checkIfNative() {
  const standalone = window.navigator.standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (!standalone && safari) {
      return false;
    } else if (!standalone && !safari) {
      return true;
    }
  } else {
    if (userAgent.includes('wv')) {
      return true;
    } else {
      return false;
    }
  }
}

