import Alert from '../alert/alert';
import AdminMessage from '../AdminMessage';
import { useTranslation } from 'react-i18next'

export default function ErrorBoundary() {
  const { t } = useTranslation('translation', { keyPrefix: 'components.error_boundary' });
  // const error = useRouteError();

  // useEffect(() => {
  //   try {
  //     const isNative = !!(checkIfNative() || window.isNative);
  //     // submitInternalFeedback({
  //     //   category: 'crash',
  //     //   message: error.message ?? 'No message',
  //     //   stack: error.stack ?? 'No stack',
  //     //   isNative,
  //     //   href: location.href,
  //     // });
  //   } catch (e) {
  //     console.warn('error in error boundary');
  //   }
  // }, [error]);

  return (
    <div className="page">
      <AdminMessage />
      <Alert
        severity="error"
        text={
          <span>
            {t('info_1')}
            <a
              target="_blank"
              href="https://discord.gg/U75379cD3G?:ext"
              rel="noreferrer"
              className="link"
            >
              {t('info_2')}
            </a>
            {t('info_3')}
            <a href="mailto:support@cuebids.com" className="link">
              {t('info_4')}
            </a>
            {t('info_5')}
          </span>
        }
      />
      <button
        className="btn-primary btn mt-10"
        onClick={() => {
          location.href = '/';
        }}
      >
        {t('try_again')}
      </button>
    </div>
  );
}
