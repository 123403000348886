import { getPotentialContract } from 'cuebids-ai';
import {
  getBidArrayWithoutAlertsIncludingPositionalSymbols,
  getHighestRobotBid,
  isBidHigher,
} from 'cuebids-bidding-util';


export function gradeQuotient(resultTotalPoints, doubleDummyScore) {
  if (doubleDummyScore < 1) {
    return 0;
  }
  let resultGrade = 0;
  const resultPercent = resultTotalPoints / doubleDummyScore;
  if (resultPercent >= 0.85) {
    resultGrade = 3;
  } else if (resultPercent >= 0.5) {
    resultGrade = 2;
  } else if (resultPercent >= 0.25) {
    resultGrade = 1;
  }
  return resultGrade;
}

function getImp(diff) {
  const impTable = [
    20, 50, 90, 130, 170, 220, 270, 320, 370, 430, 500, 600, 750, 900, 1100,
    1300, 1500, 1760, 2000, 2250, 2500, 3000, 3500, 4000,
  ];
  for (let i = 0; i < impTable.length; i++) {
    if (diff < impTable[i]) return i;
  }
  return 24;
}

export function gradeImp(ourEv, bestEv) {
  const diff = bestEv - ourEv;
  const imp = getImp(diff);
  let resultGrade = 0;

  if (imp <= 1) {
    resultGrade = 3;
  } else if (imp < 3) {
    resultGrade = 2;
  } else if (imp < 7) {
    resultGrade = 1;
  }
  return resultGrade;
}

export function grade(ev, evBest) {
  return Math.max(gradeImp(ev, evBest), gradeQuotient(ev, evBest));
}

const suitMap = {
  N: 5,
  S: 4,
  H: 3,
  D: 2,
  C: 1,
};

export function getBestAvailableContract(
  deal,
  minContract,
  opponentScore,
  opponentScoreUndoubled,
  vulnerable,
  doubled,
) {
  const level = minContract === 'P' ? '0' : minContract[0];
  const suit = minContract === 'P' ? 'C' : minContract[1];
  const suitLevel = suitMap[suit];

  let ourBestContractAboveMinimum = minContract;
  let ourBestContractAboveMinimumEv = -opponentScore;

  if (doubled && -opponentScoreUndoubled > -opponentScore) {
    ourBestContractAboveMinimumEv = -opponentScoreUndoubled;
    doubled = false;
  }

  Object.keys(deal.score).forEach((s) => {
    if (s.length === 3) {
      if (s[0] > level || (s[0] === level && suitMap[s[1]] > suitLevel)) {
        const score = deal.score[s];
        const tempEv = vulnerable ? score.evVuln : score.evSafe;
        if (
          tempEv > ourBestContractAboveMinimumEv ||
          (tempEv === ourBestContractAboveMinimumEv &&
            isBidHigher(s, ourBestContractAboveMinimum))
        ) {
          ourBestContractAboveMinimumEv = tempEv;
          ourBestContractAboveMinimum = s;
        }
      }
    }
  });

  return {
    contract: ourBestContractAboveMinimum,
    ev: ourBestContractAboveMinimumEv,
    doubled: ourBestContractAboveMinimum === minContract ? doubled : false,
  };
}

export function getRobotTricks(deal, contract) {
  const strain = contract[1];
  const maxSuit = '7' + strain + 'S';
  const evIn7 = deal.score[maxSuit].evVuln;
  const tricks = -evIn7 / 100;

  const ceilTricks = Math.ceil(tricks);
  const ceilProbability = tricks % 1; // If e.g. 8.7 tricks -> .7 or 70 % probability of 9 tricks
  const floorTricks = Math.floor(tricks);
  const floorProbability = 1 - ceilProbability;

  return [
    { tricks: floorTricks,
      probability: floorProbability },
    { tricks: ceilTricks,
      probability: ceilProbability },
  ];
}

export function makeMajorContract(tricks, level, vulnerable, doubled) {
  let score = (tricks - 6) * (doubled ? 60 : 30);
  score += makeSlam(level, vulnerable) ?? 0;
  score += makeMajorGame(level, vulnerable, doubled) ?? 50;
  score += doubled ? 50 : 0;
  const overTricks = tricks - 6 - level;
  score += doubled ? (vulnerable ? overTricks * 140 : overTricks * 40) : 0;
  return score;
}

export function makeMinorContract(tricks, level, vulnerable, doubled) {
  let score = (tricks - 6) * (doubled ? 40 : 20);
  score += makeSlam(level, vulnerable) ?? 0;
  score += makeMinorGame(level, vulnerable, doubled) ?? 50;
  score += doubled ? 50 : 0;
  const overTricks = tricks - 6 - level;
  score += doubled ? (vulnerable ? overTricks * 160 : overTricks * 60) : 0;
  return score;
}

export function makeNTContract(tricks, level, vulnerable, doubled) {
  let score = (tricks - 6) * (doubled ? 60 : 30);
  score += makeSlam(level, vulnerable) ?? 0;
  score += makeNTGame(level, vulnerable, doubled) ?? 50;
  score += doubled ? 50 : 0;
  const overTricks = tricks - 6 - level;
  score += doubled ? (vulnerable ? overTricks * 140 : overTricks * 40) : 0;
  return score + (doubled ? 20 : 10);
}

export function makeNTGame(level, vulnerable, doubled) {
  if (level >= 3 || (level >= 2 && doubled)) {
    return vulnerable ? 500 : 300;
  }
}

export function makeMinorGame(level, vulnerable, doubled) {
  if (level >= 5 || (level >= 3 && doubled)) {
    return vulnerable ? 500 : 300;
  }
}

export function makeMajorGame(level, vulnerable, doubled) {
  if (level >= 4 || (level >= 2 && doubled)) {
    return vulnerable ? 500 : 300;
  }
}

export function makeSlam(level, vulnerable) {
  if (level === 6) {
    return vulnerable ? 750 : 500;
  }
  if (level === 7) {
    return vulnerable ? 1500 : 1000;
  }
}

export function calcMakeContract(strain, level, tricks, vulnerable, doubled) {
  if (strain === 'S' || strain === 'H') {
    return makeMajorContract(tricks, level, vulnerable, doubled);
  }
  if (strain === 'D' || strain === 'C') {
    return makeMinorContract(tricks, level, vulnerable, doubled);
  }
  if (strain === 'N') {
    return makeNTContract(tricks, level, vulnerable, doubled);
  }
}

export function calcFailContract(undertricks, vulnerable, doubled) {
  if (doubled) {
    return vulnerable ?
      -300 * undertricks + 100 :
      -200 * undertricks +
          100 +
          (undertricks > 3 ? -100 * (undertricks - 3) : 0);
  }
  return (vulnerable ? -100 : -50) * undertricks;
}

export function scoreRobotContract(deal, contract, vulnerable, doubled) {
  if (contract === 'P') {
    return 0;
  }

  const tricks = getRobotTricks(deal, contract);
  const strain = contract[1];
  const level = parseInt(contract[0]);

  return Math.round(
    tricks.reduce(function(score, { tricks, probability }) {
      if (tricks >= level + 6) {
        return (
          score +
          probability *
            calcMakeContract(strain, level, tricks, vulnerable, doubled)
        );
      }

      return (
        score +
        probability * calcFailContract(level + 6 - tricks, vulnerable, doubled)
      );
    }, 0),
  );
}

export function isRobotDeclarer(declarer) {
  return declarer === 'W' || declarer === 'E';
}

export function isRobotVulnerable(vulnerability) {
  return vulnerability === 'EW' || vulnerability === 'ALL';
}

export function isPlayerVulnerable(vulnerability) {
  return vulnerability === 'NS' || vulnerability === 'ALL';
}

export function evaluateWithRobotDeclarer(
  deal,
  finalBid,
  declarer,
  vulnerability,
  doubled,
) {
  const contract = finalBid + declarer;
  const robotScore = scoreRobotContract(
    deal,
    finalBid,
    isRobotVulnerable(vulnerability),
    doubled,
  );
  const robotScoreUndoubled = scoreRobotContract(
    deal,
    finalBid,
    isRobotVulnerable(vulnerability),
    false,
  );

  // Note: Since a robot is declarer, they will have bid to the level and strain of potential contract, so no need to check it.

  const bestAvailable = getBestAvailableContract(
    deal,
    contract,
    robotScore,
    robotScoreUndoubled,
    isPlayerVulnerable(vulnerability),
    doubled,
  );

  return {
    evaluationVersion: 2,
    resultGrade: grade(-robotScore, bestAvailable.ev),
    chanceToMake: finalBid === 'P' ? null : 100,
    bestContract: bestAvailable.contract,
    bestContractDoubled: bestAvailable.doubled,
    bestContractChanceToMake: deal.score[bestAvailable.contract]?.chance ?? 100,
    ev: -robotScore,
    evBest: bestAvailable.ev,
  };
}

export function evaluateWithPlayerDeclarer(
  deal,
  finalBid,
  declarer,
  vulnerability,
  bidding,
  compete,
  ai,
) {
  const ourContract = finalBid + declarer;
  const playerVulnerable = isPlayerVulnerable(vulnerability);

  let potentialRobotContract;
  let potentialRobotScore;
  if (compete) {
    potentialRobotContract = getPotentialContract(deal.hand, vulnerability, ai);
    potentialRobotScore = scoreRobotContract(
      deal,
      potentialRobotContract,
      isRobotVulnerable(vulnerability),
      false,
    );
  }

  const bestAvailable = getBestAvailableContract(
    deal,
    compete ? potentialRobotContract : 'P',
    compete ? potentialRobotScore : 0,
    compete ? potentialRobotScore : 0,
    playerVulnerable,
    false,
  );

  if (
    bestAvailable.contract === potentialRobotContract &&
    potentialRobotContract !== 'P'
  ) {
    // Need to add declarer, since potentialRobotContract has none (not for pass as best contract)
    const hrb = getHighestRobotBid(bidding);
    if (hrb.declarer) {
      // If robots bid, they will have bid in the same suit as potentialRobotContract
      bestAvailable.contract = bestAvailable.contract + hrb.declarer;
    } else {
      // If robots never bid, just pick a declarer (east)
      bestAvailable.contract = bestAvailable.contract + 'E';
    }
  }

  const bestContract = bestAvailable.contract;
  const evBest = bestAvailable.ev;
  const ev =
    finalBid === 'P' ?
      0 :
      playerVulnerable ?
        deal.score[ourContract].evVuln :
        deal.score[ourContract].evSafe;

  return {
    evaluationVersion: 2,
    resultGrade: grade(ev, evBest),
    chanceToMake:
      finalBid === 'P' ? null : deal.score[ourContract]?.chance ?? 100,
    bestContract: ev >= evBest ? ourContract : bestContract,
    bestContractDoubled: false,
    bestContractChanceToMake:
      deal.score[ev >= evBest ? ourContract : bestContract]?.chance ?? 100,
    ev: ev,
    evBest: ev >= evBest ? ev : evBest,
  };
}

export default function evaluateV2(
  deal,
  finalBid,
  declarer,
  vulnerability,
  doubled,
  bidding,
  compete,
  ai,
) {
  if (isRobotDeclarer(declarer)) {
    return evaluateWithRobotDeclarer(
      deal,
      finalBid,
      declarer,
      vulnerability,
      doubled,
    );
  }

  return evaluateWithPlayerDeclarer(
    deal,
    finalBid,
    declarer,
    vulnerability,
    bidding,
    compete,
    ai,
  );
}
