import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Animated from '../animation/animated';
import { DeleteIcon } from '../icons/deleteIcon';
import { MessageIcon } from '../icons/messageIcon';
import { NotificationCircleIcon } from '../icons/notificationCircle';
import { StarRating } from '../rating/starRating';
import PairDisplay from '../users/pairDisplay';
import { UserDisplayCoachName, UserDisplayName } from '../users/userDisplay';
import { getNumberOfUnreadMessagesForSessionForKibitzer } from '../../firebase/biddingSessions';
import SessionTypeIcon from '../sessionTypeIcon/sessionTypeIcon.jsx';
import { useTranslation } from 'react-i18next';

export function SharedSessionCard({ session, i, onDelete }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [unreadMessagesCount, setUnreadMessagesCounts] = useState(0);

  const { dealsCount, numberOfFinishedDeals, id } = session;

  const isFinished = numberOfFinishedDeals === dealsCount;

  useEffect(
    function () {
      async function getData() {
        const count = await getNumberOfUnreadMessagesForSessionForKibitzer(
          session.id
        );
        setUnreadMessagesCounts(count);
      }
      getData();
    },
    [session.id]
  );

  const sessionCardDisplay = () => {
    return (
      <>
        <td>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <PairDisplay uid1={session.users[0]} uid2={session.users[1]} />
            </div>
          </div>
        </td>
        <td>
          {isFinished ? (
            <div className="w-24 flex justify-center">
              <StarRating stars={session.numberOfStars / dealsCount} bonus={(dealsCount >= 8) && (session.numberOfStars === dealsCount * 3)} />
            </div>
          ) : (
            <div className="items-left flex flex-col gap-1">
              <progress
                className="progress progress-primary w-24 bg-accent"
                value={(numberOfFinishedDeals / dealsCount) * 100}
                max="100"
              ></progress>

              <span className="info">
                {numberOfFinishedDeals}/{dealsCount} {t('shared_session_card.completed')}
              </span>
            </div>
          )}
        </td>
        <td>
          <div className="flex justify-between items-center gap-2">
            <div className="indicator align-middle">
              {unreadMessagesCount > 0 && (
                <span className="indicator-item badge badge-xs badge-error text-[10px]">
                  {unreadMessagesCount > 9 ? '9+' : unreadMessagesCount}
                </span>
              )}
              <MessageIcon
                className={
                  unreadMessagesCount > 0 ? 'fill-gray-100' : 'fill-gray-400'
                }
              />
            </div>
            <button className="btn-ghost btn-sm btn" onClick={onDelete}>
              <DeleteIcon className="fill-red-400" />
            </button>
          </div>
        </td>
      </>
    );
  };

  return (
    <Animated
      element="tr"
      variants={{
        hidden: (i) => ({
          opacity: 0,
          scale: 0,
        }),
        visible: (i) => ({
          opacity: 1,
          scale: 1,
          transition: {
            delay: i * 0.02,
          },
        }),
      }}
      initial="hidden"
      exit="hidden"
      animate="visible"
      custom={i}
      key={id}
      style={{ cursor: 'pointer' }}
      onClick={function () {
        navigate(`/session/${id}`);
      }}
    >
      {sessionCardDisplay()}
    </Animated>
  );
}

const isToday = (someDate) => {
  const today = new Date()
  return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
}

export default function SessionCard({
  session,
  currentUser,
  i,
  highlight,
  withDelete = false,
  showExtraUsers = false,
}) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const {
    dealsCount,
    northToAct,
    southToAct,
    numberOfFinishedDeals,
    id,
    users,
    timestamp,
    northToRead,
    southToRead,
    numberOfMessages,
    coachRobot,
  } = session;

  const displayDate = new Date(timestamp);

  const userIndex = users.indexOf(currentUser.uid);
  const partnerUserId = users.find((f) => f !== currentUser.uid);

  const yourTurn = [northToAct, southToAct][userIndex];
  const unreadMessages = [northToRead, southToRead][userIndex];

  const isFinished = numberOfFinishedDeals === dealsCount;

  const isTodaySession = isToday(displayDate);

  const sessionCardDisplay = () => {
    return (
      <>
        <td>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontWeight: highlight ? 'bold' : 'normal',
            }}
          >
            <div className="flex flex-col">
              {
                coachRobot ? <UserDisplayCoachName uid={partnerUserId} name={coachRobot.name} noPro noRobot /> : <UserDisplayName uid={partnerUserId} noPro noRobot />
              }
              <span className="info">{
                displayDate.toLocaleDateString(i18n.language, {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                })
              }&nbsp;
              {
                isTodaySession &&
                  displayDate.toLocaleTimeString(i18n.language, {
                    hour: 'numeric',
                    minute: 'numeric',
                  })
              }
              </span>
            </div>
            {showExtraUsers && (
              <div className="flex items-center text-sm px-2 md:px-4">
                +{session.extraUsers.length} {t('session_card.users')}
              </div>
            )}
            <div className="flex flex-col items-center justify-center">
              <SessionTypeIcon session={session} />
              {session.proChallenge && (<div className='badge badge-xs badge-secondary'>{t('session_card.pro')}</div>)}
            </div>
          </div>
        </td>
        <td>
          {isFinished ? (
            <div className="w-24 flex justify-center">
              <StarRating stars={session.numberOfStars / dealsCount} bonus={(dealsCount >= 8) && (session.numberOfStars === dealsCount * 3)} />
            </div>
          ) : (
            <div className="items-left flex flex-col gap-1">
              <progress
                className="progress progress-primary w-24 bg-accent"
                value={(numberOfFinishedDeals / dealsCount) * 100}
                max="100"
              ></progress>

              <span className="info">
                {numberOfFinishedDeals}/{dealsCount} {t('session_card.completed')}
              </span>
            </div>
          )}
        </td>
        <td className="text-right">
          <div className="indicator align-middle">
            {unreadMessages > 0 && (
              <span className="indicator-item badge badge-xs badge-error text-[10px]">
                {unreadMessages > 9 ? '9+' : unreadMessages}
              </span>
            )}
            {yourTurn ? (
              <NotificationCircleIcon className="fill-red-400" />
            ) : (
              <MessageIcon
                className={
                  (unreadMessages > 0 || numberOfMessages > 0) ? 'fill-gray-100' : 'fill-gray-400'
                }
              />
            )}
          </div>
        </td>
        {withDelete && (
          <td className="text-right">
            <button className="btn-ghost btn-sm btn" onClick={withDelete}>
              <DeleteIcon className="fill-red-400" />
            </button>
          </td>
        )}
      </>
    );
  };

  return (
    <>
      <Animated
        element="tr"
        variants={{
          hidden: (i) => ({
            opacity: 0,
          }),
          visible: (i) => ({
            opacity: 1,
            transition: {
              delay: i * 0.02,
            },
          }),
        }}
        initial="hidden"
        exit="hidden"
        animate="visible"
        custom={i}
        key={id}
        sx={
          'h-14 cursor-pointer ' +
          (yourTurn ? (isFinished ? ' finished' : ' turn') : '')
        }
        onClick={function () {
          navigate(`/session/${id}`);
        }}
      >
        {sessionCardDisplay()}
      </Animated>
    </>
  );
}
