import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../util/hooks.jsx';
import Alert from '../alert/alert';
import goToStore from '../../util/goToStore';
import { useAppStateStore } from '../../appStateStore';
import { getRemainingTicketsWarning } from '../../util/tickets';
import { useTranslation } from 'react-i18next'

export default function RemainingTicketsWarning({
  myRemainingTickets,
  partnerRemainingTickets,
  price,
  loading,
  setLoading,
  paymentStrategy,
}) {
  const { t } = useTranslation();
  const isNative = useAppStateStore((state) => state.isNative);
  const iap = useAppStateStore((state) => state.iap);
  const { currentUser } = useAuth();
  const nav = useNavigate();

  const handleClickStore = async () => {
    if (loading) return;

    setLoading(true);
    await goToStore({ nav, isNative, iap, uid: currentUser.uid });
    setLoading(false);
  };

  const remainingTicketsWarning = getRemainingTicketsWarning({
    myRemainingTickets,
    partnerRemainingTickets,
    price,
    paymentStrategy,
    t
  });

  return remainingTicketsWarning ? (
    <Alert
      severity={remainingTicketsWarning.severity}
      sx="mt-1"
      onClick={remainingTicketsWarning.withStore ? handleClickStore : undefined}
      text={remainingTicketsWarning.textLong}
    />
  ) : null;
}
