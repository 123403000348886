import { useEffect, useRef, useState } from 'react'
import { useOutsideClick } from '../../util/hooks';
import { isSubEnough } from '../../util/sub';
import { useQuery } from '@tanstack/react-query';
import { getMyScenarios } from 'cuebids-firebase/dealGeneration';
import { aiSystemNotPlayableWithRobot } from 'cuebids-deals';
import { useTranslation } from 'react-i18next';

export function ScenarioSelect({
  selected = [],
  setSelected,
  activeSubs,
  defaultScenarioIds = [],
  label = true,
  disableScenariosNotPlayableWithRobot = false,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);
  const { t } = useTranslation();
  const { isFetchedAfterMount, data: scenarios } = useQuery({
    queryKey: ['myScenarios'],
    queryFn: async () => await getMyScenarios(),
  });

  const [filter, setFilter] = useState('');
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => setDropdownOpen(false));

  useEffect(function () {
    const filteredSelected = selected.filter(function (scenario) {
      return !disableScenariosNotPlayableWithRobot || !aiSystemNotPlayableWithRobot.includes(scenario.directions.settings?.aiSystem);
    });

    if (filteredSelected.length !== selected.length) {
      setSelected(filteredSelected);
    }
  }, [selected, disableScenariosNotPlayableWithRobot, setSelected]);

  function toggleDropdown() {
    const newOpen = !dropdownOpen;
    if (newOpen) {
      if (!hasOpened && selected.length === 0) {
        const allowedScenarios = isSubEnough(
          activeSubs,
          'heart',
        ) ? defaultScenarioIds.reduce(function (a, scenarioId) {
            const scenario = scenarios.find(s => s.id === scenarioId);
            if (scenario) {
              if (!disableScenariosNotPlayableWithRobot || !aiSystemNotPlayableWithRobot.includes(scenario.directions.settings?.aiSystem)) {
                a.push(scenario);
              }
            }
            return a;
          }, []) : [];

        setSelected(allowedScenarios);
      }
      setHasOpened(true);
    }
    setDropdownOpen(newOpen);
  }

  function onSelect(scenario) {
    const scenarioIndex = selected.findIndex(s => s.id === scenario.id);

    if (scenarioIndex > -1) {
      setSelected(selected.filter((a) => a.id !== scenario.id));
    } else {
      setSelected([...selected, scenario]);
    }
  }

  if (!isFetchedAfterMount) {
    return <div className="h-20 text-center p-4">Loading...</div>;
  }

  return (
    <div className="w-full" ref={wrapperRef}>
      {
        label && (
          <label className="label">
            <span className="label-text">{label}</span>
          </label>
        )
      }
      <button
        style={{ height: 'unset' }}
        className="btn-accent btn flex min-h-[5rem] w-80 flex-row items-center justify-between"
        type="button"
        onClick={toggleDropdown}
      >
        {dropdownOpen ? (
          <input
            type="text"
            placeholder="Filter..."
            className="input w-4/5"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        ) : selected.length > 0 ? (
          t('scenarios.scenarios')
        ) : (
          t('scenarios.random')
        )}

        <svg
          className="mx-1.5 h-4 w-4"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div
        className={
          (!dropdownOpen ? 'hidden ' : '') +
          'scroll absolute z-10 mt-0 max-h-44 overflow-x-hidden overflow-y-scroll rounded border border-slate-500 border-opacity-50 bg-base-100 p-4 shadow-xl'
        }
      >
        <div className="flex w-72 flex-col items-start justify-start">
          {scenarios.length > 0 ? (
            scenarios
              .filter((s) =>
                s.name.toLowerCase().includes(filter.toLowerCase()),
              )
              .map((scenario) => {
                const disabled = !!(disableScenariosNotPlayableWithRobot && aiSystemNotPlayableWithRobot.includes(scenario.directions.settings?.aiSystem));
                return (
                  <li
                    className="btn-ghost w-full btn flex items-center justify-between"
                    key={scenario.id}
                    value={scenario.id}
                    disabled={disabled}
                  >
                    <div className="form-control grow">
                      <label className="label cursor-pointer gap-2">
                        <input
                          disabled={disabled}
                          type="checkbox"
                          className="checkbox-accent checkbox"
                          checked={selected.map(s => s.id).includes(scenario.id)}
                          onChange={() => onSelect(scenario)}
                        />
                        <div className="flex w-full flex-col items-start">
                          <span className="text-xs">{scenario.name}</span>
                        </div>
                      </label>
                    </div>
                  </li>
                );
              })
          ) : (
            <>{t('scenarios.no_scenarios')}</>
          )}
        </div>
      </div>
    </div>
  );
}
