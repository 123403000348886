const openerBidTwice = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*-P-P-1[CDH]-[PD]-1[DHS]-[PD]$/,
    explain: 'We opened 1X, partner was a passed hand and we have a 7 fit, sneak a pass',
    options: [
      {
        bid: 'P',
        reqR: 3,
        reqMaxR: 3,
        reqMaxHp: 14,
        weight: 10,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-P-P-1[CDH]-[PD]-1[DHS]-[PD]$/,
    explain: 'We opened 1X, partner was a passed hand and we have a 8 fit, sneak a pass with min',
    options: [
      {
        bid: 'P',
        reqR: 4,
        reqMaxR: 4,
        reqMaxHp: 12,
        weight: 10,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDHS](?!-D-P-P)-(.|..)-P-(.|..)$/,
    explain: 'We opened 1X, partner did not bid - can we bid again?',
    options: [
      {
        bid: '2o["6+!o and 12+ HCP"]',
        reqFit: 6,
        reqParO: 50,
        reqHp: 12,
        id: 'rebid_2_12'
      },
      {
        bid: '3o["6+!o and 15+ HCP"]',
        reqFit: 6,
        reqParO: 80,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 7 : 6),
        reqHp: 15,
      },
      {
        bid: '3o["6+!o and 15+ HCP"]',
        reqFit: 6,
        reqParO: 80,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 6 : 4),
        reqHp: 18,
      },
      {
        bid: '4o["6+!o and 16+ HCP"]',
        reqFit: 6,
        reqParO: 120,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 8 : 7),
        reqHp: 16,
        id: 'rebid_4_16'
      },
      {
        bid: '4o["6+!o and 16+ HCP"]',
        reqFit: 6,
        reqParO: 120,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 7 : 5),
        reqHp: 18,
        id: 'rebid_4_18'
      },
    ],
  },
];

export default openerBidTwice;
