import { ranges } from './constants';

const preEmpt3Level = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*-(1[DHS]|1[DHS]-P-1[HSN])$/,
    explain: 'We can pre-empt with 3C',
    options: [
      {
        bid: '3C["Weak, 7+!C"]',
        reqC: 7,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-(1[CHS]|2C|1[CHSN]-P-(1[HSN]|2C))$/,
    explain: 'We can pre-empt with 3D',
    options: [
      {
        bid: '3D["Weak, 7+!D"]',
        reqD: 7,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-(1[CDSN]|2[CD]|(1[CDSN]|2C)-P-(1[DSN]|2[CD]))$/,
    explain: 'We can pre-empt with 3H',
    options: [
      {
        bid: '3H["Weak, 7+!H"]',
        reqH: 7,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 3,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(-\.)*(-P)*-(1[CDHN]|2[CDH]|(1[CDHN]|2[CD])-P-(1[DHN]|2[CDH]))$/,
    explain: 'We can pre-empt with 3S',
    options: [
      {
        bid: '3S["Weak, 7+!S"]',
        reqS: 7,
        reqMaxH: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
        weight: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-(1[DHS]|1[DHS]-P-1[HSN])-3C-(P|D|3.)$/,
    explain: 'Partner pre-empt with 3C, we can bid 4C',
    options: [
      {
        bid: '4C["To play"]',
        reqC: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-(1[DHS]|1[DHS]-P-1[HSN])-3C-(P|D|3.|4.)$/,
    explain: 'Partner pre-empt with 3C, we can bid 5C',
    options: [
      {
        bid: '5C["To play"]',
        reqC: 4,
        reqHp: ranges.weakRaise,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 11 : 0), // Does not happen when VUL
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(-\.)*(-P)*-(1[CHS]|2C|1[CHSN]-P-(1[HSN]|2C))-3D-(P|D|3.|4C)$/,
    explain: 'Partner pre-empt with 3D, we can bid 4D',
    options: [
      {
        bid: '4D["To play"]',
        reqD: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(-\.)*(-P)*-(1[CHS]|2C|1[CHSN]-P-(1[HSN]|2C))-3D-(P|D|3.|4.|5C)$/,
    explain: 'Partner pre-empt with 3D, we can bid 5D',
    options: [
      {
        bid: '5D["To play"]',
        reqD: 4,
        reqHp: ranges.weakRaise,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 11 : 0), // Does not happen when VUL
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(-\.)*(-P)*-(1[CDSN]|2[CD]|(1[CDSN]|2C)-P-(1[DSN]|2[CD]))-3H-(P|D|3.|4C|4D)$/,
    explain: 'Partner pre-empt with 3H, we can bid 4H',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(-\.)*(-P)*-(1[CDHN]|2[CDH]|(1[CDHN]|2[CD])-P-(1[DHN]|2[CDH]))-3S-(P|D|3.|4C|4D|4H)$/,
    explain: 'Partner pre-empt with 3S, we can bid 4S',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-P-1[DHS]$/,
    explain: 'Partner is a passed hand, we can preempt',
    options: [
      {
        bid: '3C["Weak, 6+!C"]',
        reqC: 6,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 2 : 5),
      },
    ],
  },
];

export default preEmpt3Level;
