import Dialog from '../components/dialog/index.jsx';
import { useAppStateStore } from '../appStateStore.jsx';
import { useEffect, useState } from 'react';
import { updateUser } from '../firebase/userApi.js';
import { optInForNewsletter } from '../firebase/achievements.js';
import { useAuth } from '../util/hooks.jsx';
import useNotifications from '../components/notifications/useNotifications.jsx';

export default function SubscribeToNewsletter() {
  const { currentUser } = useAuth();
  const [email, setEmail] = useState('');
  const user = useAppStateStore(state => state.user)
  const [loading, setLoading] = useState(false);
  const notify = useNotifications();

  useEffect(() => {
    if (user.email) {
      setEmail(user.email);
    }
  }, [user.email]);
  function isPrivateAppleEmail(email) {
    return email?.endsWith('@privaterelay.appleid.com');
  }

  const confirmSubscribeNewsletter = async () => {
    if (loading) return;

    setLoading(true);
    try {
      await updateUser({
        oldEmail: user.email ?? currentUser.email,
        email,
        optIn: 'STARTED',
      });

      await optInForNewsletter(email);

      notify(
        {
          text: 'Verification email sent. Check your inbox',
          type: 'success',
        },
      );
      
      setLoading(false);
    } catch (e) {
      notify(
        {
          text: 'Something went wrong',
          type: 'failure',
        },
      );
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} title={'Newsletter'} noClose={true}>
      <div className="overflow-x-auto w-full">

        <h2>Subscribe to our newsletter!</h2>

        <div>
          Make sure this is your email address - you can edit it if needed
        </div>
        <div className="form-control my-2">
          <input
            value={email}
            onChange={function (e) {
              setEmail(e.target.value);
            }}
            type="email"
            required
            placeholder="example@cuebids.com"
            className="input"
          />
          {isPrivateAppleEmail(email) && (
            <div className="info">
                Private Apple email addresses cannot be used to subscribe to
                newsletter
            </div>
          )}
        </div>

        <div className={'w-full flex flex-col items-center justify-center mt-2'}>
          <button className={'btn btn-primary text-xl'} onClick={confirmSubscribeNewsletter}>Yes!</button>
          <button className={'btn btn-accent btn-sm text-sm mt-2'}>No Thanks</button>
        </div>
      </div>
    </Dialog>
  );
}
