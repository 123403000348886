import { ref, push, onValue, child, limitToLast, query, orderByChild, set, serverTimestamp } from 'firebase/database';
import { auth, realDb } from './firebase';
import { createGroupChatMessageNotification } from 'cuebids-firebase/notifications';

export async function sendGroupChatMessage(groupId, groupName, message) {
  const currentUser = auth.currentUser;
  const messagesRef = child(ref(realDb), `groups/${groupId}/chats`);

  push(messagesRef, {
    timestamp: serverTimestamp(),
    message,
    userId: auth.currentUser.uid,
  });

  void createGroupChatMessageNotification(currentUser.uid, groupId, groupName, message);
}

export async function setLastRead(groupId) {
  const lastReadData = ref(realDb, `groups/${groupId}/lastReadData/${auth.currentUser.uid}`);

  await set(lastReadData, serverTimestamp());
}

export function getGroupDbObservable({ groupId, callback }) {
  const lastReadData = ref(realDb, `groups/${groupId}/lastReadData`);

  return onValue(lastReadData, (snapshot) => {
    const data = {};
    snapshot.forEach((snap) => {
      data[snap.key] = snap.val();
    });
    callback(data);
  });
}

export function getGroupChatObservable({ groupId, callback }) {
  const chatRef = ref(realDb, `groups/${groupId}/chats`);
  const mostResentChats = query(chatRef, orderByChild('timestamp'), limitToLast(100));


  return onValue(mostResentChats, (snapshot) => {
    const chats = [];
    snapshot.forEach((snap) => {
      chats.push(snap.val());
    });
    callback(chats);
  });
}
