import { useTranslation } from 'react-i18next';
import { useAuth } from '../../util/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { createSpot } from 'cuebids-firebase/spots';
import { updateUser } from '../../firebase/userApi'
import { getSpotsLastSeenTimestampUserField } from '../../util/spots.js'
import { useAppStateStore } from '../../appStateStore.jsx'
import CreateEditSpotForm from './components/CreateEditSpotForm.jsx'

export default function CreateSpot() {
  const { currentUser } = useAuth();
  const { fed } = useParams();
  const updateNumberOfNewSpots = useAppStateStore((state) => state.updateNumberOfNewSpots);

  const nav = useNavigate();

  const { t } = useTranslation();

  async function onSubmit({
    bidding,
    dealer,
    vulnerability,
    description,
    answer,
    scoring,
    hand,
  }) {
    const id = await createSpot({
      bidding,
      dealer,
      vulnerability,
      description,
      answer,
      scoring,
      fed,
      uid: currentUser?.uid,
      hand,
    });

    updateNumberOfNewSpots({ [fed]: 0 });
    void updateUser({ [getSpotsLastSeenTimestampUserField(fed)]: Date.now() });

    nav(`/spots/${fed}/${id}`, { replace: true });
  }

  return (
    <CreateEditSpotForm
      submitLabel={t('spots.spot_create_submit')}
      onSubmit={onSubmit}
    />
  );
}
