import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import Animated from '../../components/animation/animated';
import SessionCard, {
  SharedSessionCard,
} from '../../components/session/sessionCard';
import Alert from '../../components/alert/alert';
import {
  getAllMarkedSessionDealsForShared,
  getSessionsIHaveShared,
  removeSharedSession,
} from '../../firebase/biddingSessions';
import Dialog from '../../components/dialog';
import { useAuth } from '../../util/hooks.jsx';
import HandCard from '../../components/hand/handCard';
import { Tab, TabPanel, Tabs } from '../../components/tabs/tabs';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SelectUserDropdown } from '../../components/dropdown/dropdown.jsx'

function FlaggedSessionDeals() {
  const { currentUser } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const playerId = searchParams.get('player') ?? '';

  const [markedSessionDeals, setMarkedSessionDeals] = useState();
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    getAllMarkedSessionDealsForShared().then(function (d) {
      setMarkedSessionDeals(d);
      setPlayers(Array.from(d.reduce(function (a, { users }) {
        a.add(users[0]);
        a.add(users[1]);
        return a;
      }, new Set())))
    });
  }, []);

  if (!markedSessionDeals) {
    return <></>;
  }

  if (!markedSessionDeals.length) {
    return (
      <Alert
        severity={'info'}
        text="You don't have any flagged hands right now"
      />
    );
  }

  const filteredSessionDeals = playerId ? markedSessionDeals.filter(({ users }) => users.includes(playerId)) : markedSessionDeals;

  const setPlayerId = (uid) => {
    setSearchParams(
      function (oldParams) {
        if (uid) {
          oldParams.set('player', uid);
        } else {
          oldParams.delete('player');
        }
        return oldParams;
      },
      { replace: true }
    );
  }

  return (
    <>
      <SelectUserDropdown
        label="Player"
        value={playerId}
        onChange={setPlayerId}
        users={players}
      />
      {playerId && <button className="btn btn-sm btn-secondary h-8 mt-4" onClick={() => setPlayerId(null)}>Show all players</button>}
      <table className="mt-4 w-full table-auto border-collapse rounded-sm md:w-4/5">
        <thead>
          <tr>
            <th>Hand</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredSessionDeals.map((s) => {
            return (
              <HandCard
                i={s.id}
                deal={s}
                key={s.id}
                currentUser={currentUser}
                hideDealNumber
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function IHaveSharedTab() {
  const { currentUser } = useAuth();
  const [sessions, setSessions] = useState();

  useEffect(function () {
    getSessionsIHaveShared().then(function (sessions) {
      setSessions(sessions);
    });
  }, []);

  if (!sessions) {
    return null;
  }

  if (!sessions.length) {
    return (
      <div className="flex flex-col items-center mt-8">
        <Alert severity={'info'} text="You have not shared any sessions" />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center mt-8">
      <table className="w-full border-collapse rounded-sm md:w-4/5">
        <thead>
          <tr>
            <th>Partner</th>
            <th>Progress</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {sessions.map((s, i) => {
            return (
              <SessionCard
                session={s}
                key={s.id}
                currentUser={currentUser}
                i={i}
                showExtraUsers
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function SharedWithMeTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const showFlagged = searchParams.get('flagged') === 'true';

  const sharedSessions = useAppStateStore((state) => state.sharedSessions);
  const [loading, setLoading] = useState(false);
  const [
    unconfirmedRemoveSharedSessionId,
    setUnconfirmedRemoveSharedSessionId,
  ] = useState(null);

  const handleRemove = (e, sessionId) => {
    if (e.stopPropagation) e.stopPropagation();
    setUnconfirmedRemoveSharedSessionId(sessionId);
  };

  const handleClose = () => {
    setUnconfirmedRemoveSharedSessionId(false);
  };

  const handleConfirmRemoveSession = async () => {
    if (!unconfirmedRemoveSharedSessionId || loading) return;
    setLoading(true);
    await removeSharedSession(unconfirmedRemoveSharedSessionId);
    setUnconfirmedRemoveSharedSessionId(false);
    setLoading(false);
  };

  const updateShowFlagged = (event) => {
    setSearchParams(
      function (oldParams) {
        const checked = event.target.checked;
        oldParams.set('flagged', checked ? 'true' : 'false');
        if (!checked) {
          oldParams.delete('player');
        }
        return oldParams;
      },
      { replace: true }
    );
  };

  const sessions = sharedSessions;

  return (
    <>
      <div className="flex flex-col items-center mt-6">
        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text mr-2">Flagged</span>
            <input
              type="checkbox"
              className="toggle-secondary toggle"
              checked={showFlagged}
              onChange={updateShowFlagged}
            />
          </label>
        </div>
        <AnimatePresence>
          {showFlagged ? (
            <FlaggedSessionDeals />
          ) : sessions.length > 0 ? (
            <table
              id="session-list"
              className="mt-4 w-full border-collapse rounded-sm md:w-4/5"
            >
              <thead>
                <tr>
                  <th>Players</th>
                  <th>Progress</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {sessions.map((s, i) => {
                  return (
                    <SharedSessionCard
                      key={s.id}
                      session={s}
                      i={i}
                      onDelete={function (e) {
                        handleRemove(e, s.id);
                      }}
                    />
                  );
                })}
              </tbody>
            </table>
          ) : (
            <Alert
              severity="info"
              text="You don't have any shared sessions. Others can invite you to see the deals in their session."
            />
          )}
        </AnimatePresence>
      </div>
      {unconfirmedRemoveSharedSessionId && (
        <Dialog
          open={!!unconfirmedRemoveSharedSessionId}
          onClose={handleClose}
          title="Remove shared session"
          actions={[
            {
              component: (
                <div
                  disabled={loading}
                  key="yes"
                  className="btn-error btn"
                  role="button"
                  onClick={handleConfirmRemoveSession}
                >
                  Confirm
                </div>
              ),
            },
          ]}
        >
          You will no longer be able to see what happens in this session. (Note
          that the session will not be deleted for the players.)
        </Dialog>
      )}
    </>
  );
}

function Tabulars() {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? 'me';
  const navigate = useNavigate();

  const handeTabChange = (tabId) => {
    navigate(`/sharedSessions?tab=${tabId}`, { replace: true });
  };

  return (
    <div className="w-full md:w-4/5">
      <Tabs>
        <Tab
          key={'sharedWithMe'}
          label="Shared with me"
          value="me"
          active={tab === 'me'}
          onChange={handeTabChange}
        />
        <Tab
          key={'iHaveShared'}
          label="I have shared"
          value="others"
          active={tab === 'others'}
          onChange={handeTabChange}
        />
      </Tabs>
      <TabPanel key={'sharedWithMePanel'} value={tab} index={'me'}>
        <SharedWithMeTab />
      </TabPanel>
      <TabPanel key={'iHaveSharedPanel'} value={tab} index={'others'}>
        <IHaveSharedTab />
      </TabPanel>
    </div>
  );
}

export default function SharedSessions() {
  return (
    <Animated
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      key={'sharedSessions'}
      exit={{ opacity: 0 }}
      transition={{
        delay: 0.1,
      }}
    >
      <div className="page pb-24">
        <Tabulars />
      </div>
    </Animated>
  );
}
