const competeAgain = [
  {
    biddingMatch: /^.*-.C-(P|D|\d\w)-.C-(P|D|\d\w)$/,
    explain: 'Partner raised clubs, opponent bid once more',
    options: [
      {
        bid: '3C',
        reqC: 5,
        reqParC: 70,
        weight: 0.4,
        lastBidExclusions: ['P'],
      },
      {
        bid: '3C',
        reqC: 5,
        reqParC: 100,
        weight: 0.4,
        lastBid: 'P',
      },
      {
        bid: '4C',
        reqC: 5,
        reqParC: 90,
        weight: 0.5,
        lastBidExclusions: ['P'],
      },
      {
        bid: '4C',
        reqC: 5,
        reqParC: 120,
        weight: 0.5,
        lastBid: 'P',
      },
      {
        bid: '5C',
        reqC: 5,
        reqParC: 150,
        weight: 0.6,
        lastBidExclusions: ['P'],
      },
      {
        bid: '5C',
        reqC: 5,
        reqParC: 250,
        weight: 0.6,
        lastBid: 'P',
      },
    ],
  },
  {
    biddingMatch: /^.*-.C-(P|D|\d\w)-.C-(P|D|\d\w)-P-(P|D|\d\w)$/,
    explain:
      'We raised clubs, partner has passed once, opponents bid once more',
    options: [
      {
        bid: '3C',
        reqC: 5,
        reqParC: 70,
        weight: 0.6,
        lastBidExclusions: ['P'],
      },
      {
        bid: '3C',
        reqC: 5,
        reqParC: 60,
        weight: 0.6,
        lastBid: 'P',
      },
      {
        bid: '4C',
        reqC: 5,
        reqParC: 100,
        weight: 0.6,
        lastBidExclusions: ['P'],
      },
      {
        bid: '4C',
        reqC: 5,
        reqParC: 90,
        weight: 0.6,
        lastBid: 'P',
      },
      {
        bid: '5C',
        reqC: 5,
        reqParC: 150,
        weight: 0.5,
        lastBidExclusions: ['P'],
      },
      {
        bid: '5C',
        reqC: 5,
        reqParC: 250,
        weight: 0.5,
        lastBid: 'P',
      },
    ],
  },
  {
    biddingMatch: /^.*-.C-(P|D|\d\w)-.C-D-P-P$/,
    explain: 'We raised clubs, opponents penalty doubled us',
    options: [
      {
        bid: 'P',
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^.*-.C-(P|D|\d\w)-.C-(P|D|\d\w)-P-\d\w-P-P$/,
    explain:
      'We raised clubs, both robots has passed once, opponents bid twice more',
    options: [
      {
        bid: '3C',
        reqC: 5,
        reqParC: 90,
        weight: 0.6,
      },
      {
        bid: '4C',
        reqC: 5,
        reqParC: 110,
        weight: 0.6,
      },
      {
        bid: '5C',
        reqC: 4,
        reqParC: 250,
        weight: 0.5,
      },
    ],
  },

  {
    biddingMatch: /^.*-.D-(P|D|\d\w)-.D-(P|D|\d\w)$/,
    explain: 'Partner raised diamonds, opponent bid once more',
    options: [
      {
        bid: '3D',
        reqD: 5,
        reqParD: 70,
        weight: 0.46,
        lastBidExclusions: ['P'],
      },
      {
        bid: '3D',
        reqD: 5,
        reqParD: 100,
        weight: 0.4,
        lastBid: 'P',
      },
      {
        bid: '4D',
        reqD: 5,
        reqParD: 90,
        weight: 0.5,
        lastBidExclusions: ['P'],
      },
      {
        bid: '4D',
        reqD: 5,
        reqParD: 120,
        weight: 0.5,
        lastBid: 'P',
      },
      {
        bid: '5D',
        reqD: 5,
        reqParD: 150,
        weight: 0.6,
        lastBidExclusions: ['P'],
      },
      {
        bid: '5D',
        reqD: 5,
        reqParD: 250,
        weight: 0.6,
        lastBid: 'P',
      },
    ],
  },
  {
    biddingMatch: /^.*-.D-(P|D|\d\w)-.D-(P|D|\d\w)-P-(P|D|\d\w)$/,
    explain:
      'We raised diamonds, partner has passed once, opponents bid once more',
    options: [
      {
        bid: '3D',
        reqD: 5,
        reqParD: 70,
        weight: 0.6,
        lastBidExclusions: ['P'],
      },
      {
        bid: '3D',
        reqD: 5,
        reqParD: 60,
        weight: 0.6,
        lastBid: 'P',
      },
      {
        bid: '4D',
        reqD: 5,
        reqParD: 100,
        weight: 0.5,
        lastBidExclusions: ['P'],
      },
      {
        bid: '4D',
        reqD: 5,
        reqParD: 90,
        weight: 0.5,
        lastBid: 'P',
      },
      {
        bid: '5D',
        reqD: 5,
        reqParD: 150,
        weight: 0.4,
        lastBidExclusions: ['P'],
      },
      {
        bid: '5D',
        reqD: 5,
        reqParD: 250,
        weight: 0.4,
        lastBid: 'P',
      },
    ],
  },
  {
    biddingMatch: /^.*-.D-(P|D|\d\w)-.D-D-P-P$/,
    explain: 'We raised diamonds, opponents penalty doubled us',
    options: [
      {
        bid: 'P',
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^.*-.D-(P|D|\d\w)-.D-(P|D|\d\w)-P-\d\w-P-P$/,
    explain:
      'We raised diamonds, both robots has passed once, opponents bid twice more',
    options: [
      {
        bid: '3D',
        reqD: 5,
        reqParD: 90,
        weight: 0.6,
      },
      {
        bid: '4D',
        reqD: 5,
        reqParD: 110,
        weight: 0.5,
      },
      {
        bid: '5D',
        reqD: 4,
        reqParD: 250,
        weight: 0.4,
      },
    ],
  },
  {
    biddingMatch: /^.*-.H-(.|..)-.H-(P|D|\d\w)$/,
    explain: 'Partner raised hearts, opponent bid once more',
    // prefer to bid game or as low as possible
    options: [
      {
        bid: '3H',
        reqParH: 90,
        weight: 0.4,
        lastBidExclusions: ['P'],
      },
      {
        bid: '3H',
        reqParH: 110,
        weight: 0.4,
        lastBid: 'P',
      },
      {
        bid: '4H',
        reqParH: 150,
        weight: 0.5,
        lastBidExclusions: ['P'],
      },
      {
        bid: '4H',
        reqParH: 160,
        weight: 0.5,
        lastBid: 'P',
      },
      {
        bid: '5H',
        reqH: 6,
        reqParH: 170,
        weight: 0.4,
        lastBidExclusions: ['P', 'D'],
      },
    ],
  },
  {
    biddingMatch: /^.*-.H-(P|D|\d\w)-.H-(P|D|\d\w)-P-(P|D|\d\w)$/,
    explain:
      'We raised hearts, partner has passed once, opponents bid once more',
    options: [
      {
        bid: '3H',
        reqH: 4,
        reqParH: 90,
        weight: 0.6,
        lastBidExclusions: ['P'],
      },
      {
        bid: '3H',
        reqH: 4,
        reqParH: 110,
        weight: 0.6,
        lastBid: 'P',
      },
      {
        bid: '4H',
        reqH: 4,
        reqParH: 150,
        weight: 0.5,
        lastBidExclusions: ['P'],
      },
      {
        bid: '4H',
        reqH: 4,
        reqParH: 170,
        weight: 0.5,
        lastBid: 'P',
      },
      {
        bid: '5H',
        reqH: 5,
        reqParH: 170,
        weight: 0.4,
        lastBidExclusions: ['D'],
      },
    ],
  },
  {
    biddingMatch: /^.*-.H-(P|D|\d\w)-.H-D-P-P$/,
    explain: 'We raised hearts, opponents penalty doubled us',
    options: [
      {
        bid: 'P',
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^.*-.H-(P|D|\d\w)-.H-(P|D|\d\w)-P-\d\w-P-P$/,
    explain:
      'We raised hearts, both robots has passed once, opponents bid twice more',
    options: [
      {
        bid: '3H',
        reqH: 4,
        reqParH: 90,
        weight: 0.6,
      },
      {
        bid: '4H',
        reqH: 4,
        reqParH: 150,
        weight: 0.5,
      },
      {
        bid: '5H',
        reqH: 5,
        reqParH: 170,
        weight: 0.4,
      },
    ],
  },

  {
    biddingMatch: /^.*-.S-(P|D|\d\w)-.S-(P|D|\d\w)$/,
    explain: 'Partner raised spades, opponent bid once more',
    // prefer to bid game or as low as possible
    options: [
      {
        bid: '3S',
        reqParS: 90,
        weight: 0.4,
        lastBidExclusions: ['P'],
      },
      {
        bid: '3S',
        reqParS: 110,
        weight: 0.4,
        lastBid: 'P',
      },
      {
        bid: '4S',
        reqParS: 150,
        weight: 0.5,
        lastBidExclusions: ['P'],
      },
      {
        bid: '4S',
        reqParS: 160,
        weight: 0.5,
        lastBid: 'P',
      },
      {
        bid: '5S',
        reqS: 6,
        reqParS: 170,
        weight: 0.4,
        lastBidExclusions: ['P', 'D'],
      },
    ],
  },
  {
    biddingMatch: /^.*-.S-(P|D|\d\w)-.S-(P|D|\d\w)-P-(P|D|\d\w)$/,
    explain:
      'We raised spades, partner has passed once, opponents bid once more',
    options: [
      {
        bid: '3S',
        reqS: 4,
        reqParS: 90,
        weight: 0.6,
        lastBidExclusions: ['P'],
      },
      {
        bid: '3S',
        reqS: 4,
        reqParS: 110,
        weight: 0.6,
        lastBid: 'P',
      },
      {
        bid: '4S',
        reqS: 4,
        reqParS: 150,
        weight: 0.5,
        lastBidExclusions: ['P'],
      },
      {
        bid: '4S',
        reqS: 4,
        reqParS: 170,
        weight: 0.5,
        lastBid: 'P',
      },
      {
        bid: '5S',
        reqS: 5,
        reqParS: 170,
        weight: 0.4,
        lastBidExclusions: ['D'],
      },
    ],
  },
  {
    biddingMatch: /^.*-.S-(P|D|\d\w)-.S-D-P-P$/,
    explain: 'We raised spades, opponents penalty doubled us',
    options: [
      {
        bid: 'P',
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^.*-.S-(P|D|\d\w)-.S-(P|D|\d\w)-P-\d\w-P-P$/,
    explain:
      'We raised spades, both robots has passed once, opponents bid twice more',
    options: [
      {
        bid: '3S',
        reqS: 4,
        reqParS: 90,
        weight: 0.6,
      },
      {
        bid: '4S',
        reqS: 4,
        reqParS: 150,
        weight: 0.5,
      },
      {
        bid: '5S',
        reqS: 5,
        reqParS: 170,
        weight: 0.4,
      },
    ],
  },
];

export default competeAgain;
