export function replaceSuitShorthandWithSymbols(text) {
  return text.replace(/!c/ig, '♣')
    .replace(/♣️/ig, '♣')
    .replace(/!d/ig, '♦')
    .replace(/♦️/ig, '♦')
    .replace(/!h/ig, '♥')
    .replace(/♥️/ig, '♥')
    .replace(/!s/ig, '♠')
    .replace(/♠️/ig, '♠')
    .replace(/!n/ig, 'NT');
}

