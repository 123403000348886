import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
  orderBy,
  deleteDoc,
  Timestamp,
  onSnapshot,
  addDoc,
} from 'firebase/firestore';
import { auth, db } from './firebase';
import {
  docsToObjects,
  docToObject,
  queryFirstItemToObject,
  queryListenFirstItemWithCallback,
  queryListenWithCallback,
} from 'firebase-util';
import { createChallengeMatchmakingSuccessfulNotification } from 'cuebids-firebase/notifications';
import { getUserHighestSubscription } from './subscriptions';
import {
  canPairAffordToPlay, getCharges,
  getUserRemainingTickets,
} from '../util/tickets';
import { chargeUsersTickets, checkAllowedToChargeFriendTicketsAndEvents, updateUser } from './userApi';
import { createChallenge } from './challenges';
import { getAdjacentRanks, getHighestRank } from '../util/rankUtil';
import i18next from 'i18next'

const MATCHMAKING_COLLECTION_STRING = 'challengeMatchmaking';

export async function getChallengeMatchmakingForUser(userId) {
  const matchmakingRef = collection(db, MATCHMAKING_COLLECTION_STRING);
  const q = query(
    matchmakingRef,
    where('userIds', 'array-contains', userId),
    orderBy('timestamp', 'desc'),
    limit(1),
  );

  return await queryFirstItemToObject(q);
}

export function getChallengeMatchmakingForUserObservable(userId, callback) {
  const matchmakingRef = collection(db, MATCHMAKING_COLLECTION_STRING);
  const q = query(
    matchmakingRef,
    where('userIds', 'array-contains', userId),
    orderBy('timestamp', 'desc'),
    limit(1),
  );
  return queryListenFirstItemWithCallback(q, callback);
}

export function getChallengeQueueObservable(callback) {
  const matchmakingRef = collection(db, MATCHMAKING_COLLECTION_STRING);
  const q = query(matchmakingRef, where('challengeId', '==', null));
  return queryListenWithCallback(q, callback);
}

async function startChallengeMatchmaking(userIds, rating, rank, ticketCharges) {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const matchmakingRef = collection(db, MATCHMAKING_COLLECTION_STRING);
  return await addDoc(matchmakingRef, {
    userIds,
    rating,
    rank,
    timestamp: Date.now(),
    challengeId: null,
    ttl: Timestamp.fromDate(date),
    ticketCharges,
  });
}

export async function cancelChallengeMatchmaking() {
  const currentUser = auth.currentUser;
  const existingMatchmakingForUsers = await getChallengeMatchmakingForUser(
    currentUser.uid,
  );
  if (!existingMatchmakingForUsers) {
    return;
  }
  await deleteDoc(
    doc(
      collection(db, MATCHMAKING_COLLECTION_STRING),
      existingMatchmakingForUsers.id,
    ),
  );
}

export async function createChallengeMatchmaking({
  minDealNumber,
  partnerUserId,
  userRating = 1500,
  userRank = 'jack',
  userTicketsSpent,
  userExtraTickets = 0,
}) {
  const currentUser = auth.currentUser;

  const existingMatchmakingForUser1 = await getChallengeMatchmakingForUser(
    currentUser.uid,
  );
  if (existingMatchmakingForUser1 && !existingMatchmakingForUser1.challengeId) {
    throw new Error(i18next.t('challenge_matchmaking.error_already_searching'));
  }

  const existingMatchmakingForUser2 = await getChallengeMatchmakingForUser(
    partnerUserId,
  );
  if (existingMatchmakingForUser2 && !existingMatchmakingForUser2.challengeId) {
    throw new Error(i18next.t('challenge_matchmaking.error_partner_already_searching'));
  }

  // Need to get partner's user for rating and tickets
  const partnerUserRef = doc(collection(db, 'users'), partnerUserId);
  const partnerUserDoc = await getDoc(partnerUserRef);
  const partnerUserData = partnerUserDoc.data();
  const partnerRating = partnerUserData.rating ?? 1500;

  // Check available tickets
  const myHighestSub = await getUserHighestSubscription(currentUser.uid);
  const partnersHighestSub = await getUserHighestSubscription(partnerUserId);
  const partnerTicketsSpent = partnerUserData.ticketsSpent ?? 0;
  const partnerExtraTickets = partnerUserData.extraTickets ?? 0;
  const PRICE = 8;
  const myRemainingTickets = getUserRemainingTickets(
    userTicketsSpent,
    myHighestSub,
    userExtraTickets,
  );
  const partnerRemainingTickets = getUserRemainingTickets(
    partnerTicketsSpent,
    partnersHighestSub,
    partnerExtraTickets,
  );

  if (myRemainingTickets < PRICE) {
    throw new Error(i18next.t('challenge_matchmaking.error_cannot_afford'));
  }

  if (!canPairAffordToPlay({ myRemainingTickets,
    partnerRemainingTickets,
    price: PRICE })) {
    throw new Error(i18next.t('challenge_matchmaking.error_partner_cannot_afford'));
  }

  const { myCharge, partnerCharge } = getCharges({ myRemainingTickets,
    partnerRemainingTickets,
    price: PRICE });

  const allowedToCharge = await checkAllowedToChargeFriendTicketsAndEvents(partnerUserId)

  if (!allowedToCharge) {
    throw new Error(i18next.t('challenge_matchmaking.error_challenges_disabled'))
  }

  const teamRating = userRating + partnerRating;

  const rank = getHighestRank([userRank ?? 'jack', partnerUserData.rankLevel ?? 'jack']);

  const matchmakingRef = collection(db, MATCHMAKING_COLLECTION_STRING);
  const q = query(
    matchmakingRef,
    where('challengeId', '==', null),
    where('rating', '<=', teamRating / 2 + 100),
    where('rating', '>=', teamRating / 2 - 100),
    where('rank', 'in', getAdjacentRanks(rank)),
  );

  const matchmakingDocs = await getDocs(q);
  const matchmaking = docsToObjects(matchmakingDocs);

  matchmaking.forEach(function(m) {
    if (m.userIds.includes(currentUser.uid)) {
      throw new Error(i18next.t('challenge_matchmaking.error_already_searching'));
    }

    if (m.userIds.includes(partnerUserId)) {
      throw new Error(i18next.t('challenge_matchmaking.error_partner_already_searching'));
    }
  });

  void updateUser({ challengeDefaultPartner: partnerUserId });

  if (matchmaking.length) {
    // Since we cannot sort in query, find oldest here
    const oldestTimestamp = matchmaking.reduce(function(a, v) {
      return Math.min(a, v.timestamp);
    }, Infinity);
    const oldestMatchmakingIndex = matchmaking.findIndex(function(m) {
      return m.timestamp === oldestTimestamp;
    });
    const matchmakingRef = matchmakingDocs.docs[oldestMatchmakingIndex].ref;
    const matchmakingData = matchmaking[oldestMatchmakingIndex];
    try {
      const { batch, challengeRef } = await createChallenge({
        partnerUserId,
        opp1UserId: matchmakingData.userIds[0],
        opp2UserId: matchmakingData.userIds[1],
        numberOfDeals: 8,
        compete: 2,
        minDealNumber,
        matchmaking: true,
        returnBatch: true,
      });

      batch.update(matchmakingRef, {
        challengeId: challengeRef.id,
      });

      await chargeUsersTickets({
        userCharges: [
          {
            user: currentUser.uid,
            charge: myCharge,
          },
          {
            user: partnerUserId,
            charge: partnerCharge,
          },
          {
            user: matchmakingData.userIds[0],
            charge: matchmakingData.ticketCharges[0],
          },
          {
            user: matchmakingData.userIds[1],
            charge: matchmakingData.ticketCharges[1],
          },
        ],
        batch,
      });

      await batch.commit();

      createChallengeMatchmakingSuccessfulNotification(
        matchmakingData.userIds[0],
        matchmakingData.userIds[1],
        currentUser.displayName,
        partnerUserData.displayName,
        challengeRef.id,
      );

      return challengeRef.id;
    } catch (e) {
      throw new Error(i18next.t('challenge_matchmaking.error_matchmaking_failed'));
    }
  } else {
    await startChallengeMatchmaking(
      [currentUser.uid, partnerUserId],
      teamRating / 2,
      rank,
      [
        myCharge,
        partnerCharge,
      ],
    );
  }
}

export function getChallengeFinishedNotificationsObservable(callback) {
  const currentUser = auth.currentUser;

  const q = query(
    collection(db, 'challengeFinishedNotifications'),
    where('userId', '==', currentUser.uid),
    orderBy('timestamp', 'asc'),
  );

  return onSnapshot(q, function(querySnapshot) {
    const filteredChanges = querySnapshot.docChanges().filter(function(dc) {
      return dc.type === 'added';
    });
    callback(filteredChanges.map((d) => docToObject(d.doc)));
  });
}

export function deleteChallengeFinishedNotification(id) {
  const ref = doc(collection(db, 'challengeFinishedNotifications'), id);
  try {
    return deleteDoc(ref);
  } catch (e) {
    // if it had previously been deleted we get error here
  }
}
