import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { getTopRanked } from 'cuebids-firebase/stats'
import { sortByRank } from '../../util/rankUtil'
import Animated from '../animation/animated'
import RankCard from '../rankCard/rankCard'
import { UserDisplayName } from '../users/userDisplay'
import { StarRating } from '../rating/starRating.jsx';

export default function RankedLeaderboard() {
  const [topTenRanked, setTopTenRanked] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      // TODO: Since we cannot sort on rankLevel in query we get some extra and sort manually, which is not great
      // Either we can fix the query sorting, or remove this when enough people reach ace
      const data = await getTopRanked(20);
      setTopTenRanked(sortByRank(data).slice(0, 10));
    }

    void getData();
  }, [setTopTenRanked]);

  const rankedUser = (u, i) => {
    const isLegendary = u.rankLevel === 'ace' && u.rankLevelStars === 6;

    return (
      <Animated
        key={i}
        element="tr"
        variants={{
          hidden: () => ({
            opacity: 0,
            scale: 0,
          }),
          visible: (i) => ({
            opacity: 1,
            scale: 1,
            transition: {
              delay: i * 0.06,
            },
          }),
        }}
        initial="hidden"
        exit="hidden"
        animate="visible"
        custom={i}
        onClick={() => {
          navigate('/player/' + u.id);
        }}
        className="cursor-pointer w-full"
      >
        <td style={{ width: 25 }}>{i + 1}</td>
        <td className="py-2">
          <div>
            <RankCard
              uid={u.id}
              rank={u.rankLevel}
              rankStars={u.rankLevelStars}
              rating={u.rating}
              hideRating
              size={'md'}
            />
          </div>
        </td>
        <td>
          <div className={'flex flex-col items-start gap-0.5 ml-2'}>
            <UserDisplayName uid={u.id} />
            <div
              className="w-20 ml-1 flex items-center justify-center"
              style={{
                filter: isLegendary
                  ? 'drop-shadow(0px 0px 6px rgb(103,30,207)) drop-shadow(0px 0px 10px rgb(103,30,207))'
                  : undefined,
              }}
            >
              <StarRating stars={u.rankLevelStars} maxStars={5} size="sm" />
            </div>
          </div>
        </td>
        <td>
          {isLegendary && (
            <div className="badge badge-accent badge-lg">{u.rating}</div>
          )}
        </td>
      </Animated>
    );
  };

  return (
    <table className="mt-10 mb-1 w-full border-collapse rounded-sm">
      <thead>
        <tr>
          <th />
        </tr>
      </thead>
      <tbody>
        <AnimatePresence>
          {topTenRanked.map((u, i) => {
            return rankedUser(u, i);
          })}
        </AnimatePresence>
      </tbody>
    </table>
  );
}
