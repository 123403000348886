import { useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ChallengeIcon } from '../icons/challengeIcon';
import {
  getCurrentOnboardingStep,
  onboardingSteps,
} from '../../util/onboarding';
import { PracticeIcon } from '../icons/practiceIcon';
import { FriendsIcon } from '../icons/friendsIcon';
import { HomeIcon } from '../icons/homeIcon';
import { EventIcon } from '../icons/dailyIcon';
import { useAppStateStore } from '../../appStateStore.jsx';
import { useTranslation } from 'react-i18next';

export default function BottomNavigation() {
  const [page, setPage] = useState(0);
  const [hideMenu, setHideMenu] = useState(false);
  const nav = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const friends = useAppStateStore((state) => state.friends);
  const user = useAppStateStore((state) => state.user);

  const friendRequests = friends.filter((f) => f.status === 'pending').length;

  const currentOnboardingStep = getCurrentOnboardingStep({ user });

  const menu = useMemo(() => {
    return [
      {
        icon: HomeIcon,
        hidden: false,
        label: 'home',
        url: [
          '/',
          '/settings',
          '/store',
          '/thanks/:subscriptionLevel',
          '/flagged',
          '/contact',
          '/contact/sales',
          '/help',
          '/stats',
          '/messages',
          // '/achievements',
          '/manageDealTypes',
        ],
      },
      {
        icon: PracticeIcon,
        hidden: false,
        label: 'practice',
        url: ['/practice', '/session', '/session/:id', '/practice/match'],
      },
      {
        icon: EventIcon,
        hidden: user?.hiddenSections?.includes('Events'),
        label: 'events',
        url: [
          '/events',
          '/daily',
          '/weekly',
          '/dailyLeaderboard/:id',
          '/weeklyLeaderboard/:id',
          '/groups/:groupId/createEvent',
          '/groups/:groupId/joinEvent/:id',
          '/groups/:groupId/eventLeaderboard/:id',
        ],
      },
      {
        icon: ChallengeIcon,
        hidden: user?.hiddenSections?.includes('Challenges'),
        label: 'challenge',
        url: [
          '/challenge',
          '/challengeSession',
          '/challenge/match',
          '/challenge/archive',
          '/challenge/:id',
        ],
      },
      {
        icon: FriendsIcon,
        label: 'friends',
        url: [
          '/friends',
          '/pair/:user1/:user2',
          '/player/:user1',
          '/chat',
          '/sharedSessions',
          '/addFriend',
          '/addFriend/:newFriendKey',
          '/groups',
          '/groups/:groupId',
          '/groupPublic/:groupId',
          '/groups/:groupId/manage',
          '/groups/:groupId/addMembers',
          '/createGroup',
        ],
        badge:
          (currentOnboardingStep === onboardingSteps.addFriend ? ' ' : null) ||
          (friendRequests > 0 ? friendRequests : null),
      },
    ];
  }, [user?.hiddenSections, currentOnboardingStep, friendRequests]);

  useEffect(() => {
    const url = menu.findIndex((m) => {
      for (let i = 0; i < m.url.length; i++) {
        if (matchPath(m.url[i], location.pathname)) {
          return true;
        }
      }
    });

    if (url === -1) {
      setHideMenu(true);
    } else {
      setHideMenu(false);
      setPage(url);
    }
  }, [location, menu]);

  const handleClickNavigation = (value) => {
    setPage(value);
    nav(menu[value].url[0]);
  };

  if (hideMenu) {
    return <></>;
  }

  return (
    <div className="btm-nav left-1/2 z-[9996] h-20 w-[inherit] -translate-x-1/2 transform pb-4">
      {menu.map((menuItem, i) => {
        const Icon = menuItem.icon;
        const isActive = page === i;
        if(menuItem.hidden) return null;
        return (
          <button
            key={menuItem.label}
            className={
              isActive ? 'active text-primary' : 'normal'
            }
            onClick={() => handleClickNavigation(i)}
          >
            <div className="indicator" key={'bad' + i}>
              {menuItem.badge && (
                <span
                  className={
                    'pulse w-5 badge badge-error indicator-item -top-3 -right-3'
                  }
                >
                  {menuItem.badge}
                </span>
              )}
              <div className="flex flex-col items-center justify-center">
                <Icon
                  // color={isActive ? '#4d754d' : 'white'}
                  // fill={isActive ? '#4d754d' : 'white'}
                  className={isActive ? 'fill-primary stroke-primary' : 'normal'}
                />
                <span className="info">{t(`routes.${menuItem.label}`)}</span>
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
}
