import {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { auth, db, functions } from './firebase';
import { httpsCallable } from 'firebase/functions';
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  deleteField,
  query,
  where,
  limit,
  setDoc,
  deleteDoc,
} from 'firebase/firestore'
import {
  OAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  createUserWithEmailAndPassword,
  updateProfile,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { getToken, isSupported } from 'firebase/messaging';
import { createUser } from './userApi';
import log from '../util/logger';
import { getMessaging } from 'cuebids-firebase';
import { getNewFriendKey, getRandomKey } from './friends'
import { AuthContext } from './authContext';
import { queryFirstItemToObject } from 'firebase-util'
import { setExpoPushToken } from 'cuebids-firebase/users';
import { tryClaimCode } from 'cuebids-firebase/codes';

async function setRememberMe(remember = true) {
  await setPersistence(
    auth,
    remember ? browserLocalPersistence : browserSessionPersistence
  );
}

async function createUserIfNotExists({ displayName, fed, fedNr, fedVerified }) {
  const userDoc = await getDoc(
    doc(collection(db, 'users'), auth.currentUser.uid)
  );

  if (userDoc.exists()) {
    return;
  }

  const friendKey = await getNewFriendKey();
  return createUser({ friendKey, displayName, fed, fedNr, fedVerified });
}

async function signInWithGoogle({ displayName, fed, fedNr, fedVerified, referral = null }) {
  const provider = new GoogleAuthProvider();

  try {
    await signInWithPopup(auth, provider);

    await createUserIfNotExists({ displayName, fed, fedNr, fedVerified });

    if(referral) { 
      await tryClaimCode(referral); 
    } 
  } catch (error) {
    log(error);
    const errorCode = error.code;
    const errorMessage = error.message;
    return { errorCode, errorMessage };
  }
}

async function signInWithApple({ displayName, fed, fedNr, fedVerified, referral = null }) {
  const provider = new OAuthProvider('apple.com');
  try {
    await signInWithPopup(auth, provider);

    await createUserIfNotExists({ displayName, fed, fedNr, fedVerified });

    if(referral) { 
      await tryClaimCode(referral); 
    } 

  } catch (error) {
    log(error);
    const errorCode = error.code;
    const errorMessage = error.message;
    return { errorCode, errorMessage };
  }
}

async function signInWithAdminToken(token) {
  try {
    await signInWithCustomToken(auth, token);
  } catch (error) {
    log(error);
  }
}

async function signInWithGoogleWithToken({ token, displayName, fed, fedNr, fedVerified, referral = null }) {
  const credential = GoogleAuthProvider.credential(null, token.accessToken);

  try {
    await signInWithCredential(auth, credential);

    await createUserIfNotExists({ displayName, fed, fedNr, fedVerified });
    
    if(referral) { 
      await tryClaimCode(referral); 
    } 
    
  } catch (error) {
    log(error);
  }
}

async function signInWithAppleWithToken({ token, nonce, displayName, fed, fedNr, fedVerified, referral = null }) {
  const provider = new OAuthProvider('apple.com');
  const credential = provider.credential({
    idToken: token,
    rawNonce: nonce,
  });

  try {
    await signInWithCredential(auth, credential);

    await createUserIfNotExists({ displayName, fed, fedNr, fedVerified });

    if(referral) { 
      await tryClaimCode(referral); 
    } 
    
  } catch (error) {
    log(error);
  }
}

function generateRandomPassword() {
  return getRandomKey(20);
}

async function signInWithSbf({ token }) {
  const request = httpsCallable(functions, 'loginWithSbfToken');
  const response = await request({ token });
  const data = response.data;

  if (data.noAccount) {
    if (data.email) {
      const existingUserPrepQuery = query(
        collection(db, 'sbfUsersPrep'),
        where('fedNr', '==', data.mid),
        limit(1),
      );
      const existingUserPrep = await queryFirstItemToObject(existingUserPrepQuery);

      if (existingUserPrep) {
        const error = createAccount({
          email: data.email,
          password: generateRandomPassword(),
          displayName: existingUserPrep?.displayName ?? 'New User',
          fed: 'sv',
          fedNr: data.mid,
          fedVerified: true
        });

        if (!error) {
          void deleteDoc(doc(collection(db, 'sbfUsersPrep'), existingUserPrep.id));
        }

        return error || { newAccount: true };
      } else {
        return { noPrep: true, email: data.email, fedNr: data.mid };
      }
    } else {
      return { errorCode: 'sbf/no-email' };
    }
  } else if (data.token) {
    await signInWithAdminToken(data.token);
  }
}

async function createAccountWithSbf({ displayName, sbfData }) {
  return createAccount({
    email: sbfData.email,
    password: generateRandomPassword(),
    displayName,
    fed: 'sv',
    fedNr: sbfData.fedNr,
    fedVerified: true
  });
}

async function prepareCreateAccountWithSbf({ displayName, fedNr }) {
  const existingUserQuery = query(
    collection(db, 'users'),
    where('fed', '==', 'sv'),
    where('fedNr', '==', fedNr),
    limit(1),
  );

  const existingUser = await queryFirstItemToObject(existingUserQuery);
  if (existingUser) {
    // Handle like normal login
    return;
  }

  const existingUserPrepQuery = query(
    collection(db, 'sbfUsersPrep'),
    where('fedNr', '==', fedNr),
    limit(1),
  );

  const existingUserPrep = await queryFirstItemToObject(existingUserPrepQuery);
  if (existingUserPrep) {
    // Prep already made
    return;
  }

  await setDoc(doc(collection(db, 'sbfUsersPrep')), {
    fedNr,
    displayName,
  });
}

async function createAccount({ email, password, displayName, fed, fedNr, fedVerified, referral = null }) {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    await updateProfile(user, {
      displayName: displayName,
    });

    await signInWithCredentials(email, password);
    await createUserIfNotExists({ displayName, fed, fedNr, fedVerified });

    if(referral) { 
      await tryClaimCode(referral); 
    } 

  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    return { errorCode, errorMessage };
  }
}

async function signInWithCredentials(email, password) {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    return { errorCode, errorMessage };
  }
}

async function forgotPassword(email) {
  const passwordResetRequest = httpsCallable(functions, 'passwordResetRequest');
  try {
    await passwordResetRequest({ email: email });
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    return { errorCode, errorMessage };
  }
}

async function signOut(userId) {
  // Must update user before auth.signOut, otherwise you will have insufficient permissions
  try {
    await updateDoc(doc(collection(db, 'users'), userId), {
      expoPushToken: deleteField(),
      webPushToken: deleteField(),
    });
  } catch (e) {
    //
  }
  return auth.signOut();
}

const setPushToken = async (currentUserId, pushToken) => {
  if (currentUserId && pushToken) {
    await setExpoPushToken(pushToken);
  }
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const setPushTokenWeb = useCallback(
    async function () {
      try {
        const supported = await isSupported();
        if (!supported) {
          return;
        }
        const messaging = getMessaging()
        getToken(messaging, {
          vapidKey: import.meta.env.VITE_FIREBASE_MESSAGING_VAPIDKEY
        })
          .then(async (response) => {
            if (response) {
              try {
                await updateDoc(doc(collection(db, 'users'), currentUser.uid), {
                  webPushToken: response,
                });
              } catch (e) {
                //
              }
            }
          })
          .catch(() => {
            //
          });
      } catch (e) {
        //
      }
    },
    [currentUser]
  );

  const isVerified = useCallback(
    function () {
      return currentUser !== null;
    },
    [currentUser]
  );

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      setLoading(false);
    });
  }, []);

  const value = {
    currentUser,
    signInWithGoogle,
    signInWithGoogleWithToken,
    signInWithApple,
    signInWithAppleWithToken,
    signInWithAdminToken,
    signInWithSbf,
    prepareCreateAccountWithSbf,
    createAccountWithSbf,
    signOut,
    createAccount,
    setPushToken,
    setPushTokenWeb,
    signInWithCredentials,
    forgotPassword,
    setRememberMe,
    isVerified,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
