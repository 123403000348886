import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../components/alert/alert';
import EventTimer from '../../../components/areaCards/eventTimer';
import { SelectFriendDropdown } from '../../../components/dropdown/dropdown';
import '../../../components/hand/hands.css';
import Price from '../../../components/price/price';
import PriceDialog from '../../../components/priceDialog/priceDialog';
import RemainingTicketsWarning from '../../../components/remainingTicketsWarning/remainingTicketsWarning';
import { getUserHighestSubscription } from '../../../firebase/subscriptions';
import { getUser } from '../../../firebase/userApi';
import {
  checkIfAlreadyPlayingWeekly,
  joinWeeklySession,
} from '../../../firebase/weekly';
import { timestampToString } from '../../../util/dates';
import {
  canPairAffordToPlay,
  getCharges,
  getUserRemainingTickets,
} from '../../../util/tickets';
import { createEventJoinedNotification } from 'cuebids-firebase/notifications';
import {
  useActiveSubs,
  useAuth,
  useRemainingTicketsNavbarItems,
} from '../../../util/hooks';
import { useAppStateStore } from '../../../appStateStore.jsx';
import TagsDisplay from '../../../components/tagsDisplay/TagsDisplay.jsx';
import { getRobotUserId, getInitialLiaSystem } from '../../../util/robotPartner.js';
import RequireDiamondDialog from '../../../marketing/RequireDiamondDialog.jsx';
import { isHighestSubEnough } from '../../../util/sub.js';
import Diamond from '../../../images/suits/Diamond.jsx';
import useNotifications from '../../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next';
import {LiaSystemDropdown} from '../../../components/dropdown/liaSystemDropdown';

const DEFAULT_PRICE = 24;

export default function JoinWeeklySession() {
  const { t } = useTranslation('translation', { keyPrefix: 'join_weekly' });
  const friends = useAppStateStore((state) => state.friends);
  const user = useAppStateStore((state) => state.user);
  const weekly = useAppStateStore((state) => state.weekly);
  const notify = useNotifications();
  const { currentUser } = useAuth();

  const [promptToSubscribe, setPromptToSubscribe] = useState(false);

  const [friend, setFriend] = useState(null);
  const [friendSub, setFriendSub] = useState(null);

  const [friendId, setFriendId] = useState(user?.weeklyDefaultPartner || '');

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [alreadyPlay, setAlreadyPlay] = useState(false);
  const [friendAlreadyPlay, setFriendAlreadyPlay] = useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const highestSub = useActiveSubs()[0];

  const [liaSystem, setLiaSystem] = useState(getInitialLiaSystem(user?.defaultLiaSystem));

  const price = weekly?.price ?? DEFAULT_PRICE;

  const canUserPlayWithLia = isHighestSubEnough(highestSub, 'diamond');

  const friendsAndRobots = friends.concat([
    {
      id: getRobotUserId(),
      status: 'mutual',
      robot: true,
      uid: getRobotUserId(),
      icon: <Diamond width={24} />,
    },
  ]);

  const handleChangeLiaSystem = (val) => {
    setLiaSystem(val);
  };


  useEffect(() => {
    if(friendId === getRobotUserId() && !canUserPlayWithLia) {
      setPromptToSubscribe(true);
      if(friendId === user?.weeklyDefaultPartner) {
        setFriendId('');
      } else {
        setFriendId(user?.weeklyDefaultPartner || '')
      }
    }
  }, [canUserPlayWithLia, friendId, user?.weeklyDefaultPartner]);

  const handleClose = () => {
    setConfirmationModalOpen(false);
  };

  const handleSelectFriend = (id) => {
    setFriendId(id);
    setFriend(null);
    setFriendSub(null);
    setFriendAlreadyPlay(false);
  };

  useRemainingTicketsNavbarItems();

  useEffect(() => {
    async function updateStatus() {
      if (weekly?.id && currentUser?.uid) {
        const already = await checkIfAlreadyPlayingWeekly({
          id: weekly.id,
          userId: currentUser.uid,
        });
        setAlreadyPlay(already);

        if (friendId) {
          const alreadyFriend = await checkIfAlreadyPlayingWeekly({
            id: weekly.id,
            userId: friendId,
          });
          setFriendAlreadyPlay(alreadyFriend);
        }
      }
    }

    updateStatus();
  }, [weekly, friendId, currentUser?.uid]);

  useEffect(
    function () {
      if (friendId) {
        // Slightly ugly to nest, but now we know that when friend is loaded we have loaded both.
        getUserHighestSubscription(friendId).then(function (fs) {
          setFriendSub(fs);
          getUser({ userId: friendId }).then(function (f) {
            setFriend(f);
          });
        });
      }
    },
    [friendId]
  );

  if (!friends || !user) {
    return <></>;
  }

  const banned = user?.banned ?? false;
  const friendBanned = friend?.banned ?? false;

  const myRemainingTickets = getUserRemainingTickets(
    user.ticketsSpent ?? 0,
    highestSub,
    user.extraTickets ?? 0
  );

  const partnerRemainingTickets = friend
    ? friend.robot
      ? Infinity
      : getUserRemainingTickets(
        friend.ticketsSpent ?? 0,
        friendSub,
        friend.extraTickets ?? 0
      )
    : 0;

  const { myCharge, partnerCharge } = getCharges({
    myRemainingTickets,
    partnerRemainingTickets,
    price,
  });

  const handleClick = async () => {
    if (myCharge !== 0 || partnerCharge !== 0) {
      setConfirmationModalOpen(true);
    } else {
      await joinWeeklyConfirmed();
    }
  };

  const joinWeeklyConfirmed = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const { uid: partnerUserId, robot } = friendsAndRobots.find(
        (f) => f.id === friendId
      );
      const id = await joinWeeklySession({
        id: weekly.id,
        partnerUserId,
        liaSystem
      });
      if (id) {
        if (!robot) {
          await createEventJoinedNotification(
            partnerUserId,
            user.displayName,
            id,
            'Weekly Event'
          );
        }
        navigate(`/session/${id}`, { replace: true });
      }
    } catch (e) {
      notify(
        {
          type: 'failure',
          text: t('join_error', {error: e.message}),
        },
      );
    } finally {
      setLoading(false);
    }
    setConfirmationModalOpen(false);
  };

  if (!weekly) {
    return null;
  }

  return (
    <div className="fade-in">
      <div className="page pb-24">
        <article className="prose">
          <h1>{weekly.headline || t('headline')}</h1>
        </article>
        <TagsDisplay tags={weekly.tags} tagNames={weekly.tagNames} sx="mt-2" />
        <Alert
          text={
            weekly.description ||
            t('description')
          }
        />
        <Alert
          severity="success"
          text={
            <EventTimer
              event={weekly}
              endDate={timestampToString(weekly.endDate)}
            />
          }
        />
        <div className="mb-4 flex w-full justify-center">
          <SelectFriendDropdown
            value={friendId}
            onChange={handleSelectFriend}
            label={t('partner_label')}
            friends={friendsAndRobots}
            disableFromTicketsAndEvents
          />
        </div>
        {friendId === getRobotUserId() && (
          <LiaSystemDropdown liaSystem={liaSystem} handleChangeLiaSystem={handleChangeLiaSystem} />
        )}
        <div className="flex flex-col items-center gap-2">
          {friendId === getRobotUserId() && !canUserPlayWithLia && (
            <Alert
              severity="error"
              text={t('lia_sub_error')}
            />
          )}
          {
            promptToSubscribe && <RequireDiamondDialog onClose={() => setPromptToSubscribe(false)} />
          }
          <Alert
            severity="info"
            text={t('fair_play_info')}
          />
          {!loading && alreadyPlay && (
            <Alert
              severity="error"
              text={t('already_participating_error')}
            />
          )}
          {!loading && (friendAlreadyPlay && !friend?.robot ) && (
            <Alert
              severity="error"
              text={t('partner_participating_error')}
            />
          )}
        </div>
        <Price price={price} defaultPrice={DEFAULT_PRICE} />
        {friend && (
          <RemainingTicketsWarning
            myRemainingTickets={myRemainingTickets}
            partnerRemainingTickets={partnerRemainingTickets}
            price={price}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        {banned && (
          <Alert
            severity="error"
            sx={'w-75 mt-4'}
            text={t('banned_error')}
          />
        )}
        {friendBanned && (
          <Alert
            severity="error"
            sx={'w-75 mt-4'}
            text={t('partner_banned_error')}
          />
        )}
        <button
          className="btn-primary btn mt-4"
          disabled={
            banned ||
            friendBanned ||
            !friendId ||
            !friend ||
            loading ||
            alreadyPlay ||
            (friendAlreadyPlay && !friend?.robot) ||
            !canPairAffordToPlay({
              myRemainingTickets,
              partnerRemainingTickets,
              price,
            })
          }
          onClick={handleClick}
        >
          {t('join_weekly_submit')}
        </button>
      </div>
      <PriceDialog
        open={confirmationModalOpen}
        onClose={handleClose}
        title={t('price_dialog_title')}
        myRemainingTickets={myRemainingTickets}
        partnerRemainingTickets={partnerRemainingTickets}
        myCharge={myCharge}
        partnerCharge={partnerCharge}
        actions={[
          {
            component: (
              <div
                disabled={loading}
                key="join-weekly"
                role="button"
                className="btn-primary btn"
                onClick={joinWeeklyConfirmed}
              >
                {t('price_dialog_submit')}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
