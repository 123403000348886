export default function Club({ width = 12, dark = false }) {
  return (
    <svg
      width={width}
      height={width * (15 / 13)}
      xmlns="http://www.w3.org/2000/svg"
          
      viewBox="0 0 13 15"
      className={ dark ? 'fill-club-100' : 'clubs'}>
        
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.73416 5.03049C9.3416 5.03049 8.96752 5.11618 8.62639 5.27079C8.9467 4.76497 9.13469 4.1522 9.13469 3.49135C9.13469 1.75731 7.84317 0.351562 6.25009 0.351562C4.65702 0.351562 3.36556 1.75731 3.36556 3.49135C3.36556 4.12053 3.53624 4.70596 3.82897 5.19719C3.53791 5.08968 3.22625 5.03049 2.90181 5.03049C1.30868 5.03049 0.0172729 6.43623 0.0172729 8.17027C0.0172729 9.90431 1.30868 11.3101 2.90181 11.3101C3.2616 11.3101 3.60575 11.2377 3.92338 11.1067C3.92332 11.1074 5.26582 10.7128 6.14831 8.96446V10.0172C6.14831 10.0172 5.69584 13.3355 2.5059 13.6987H1.99682V14.2159H10.6844V13.6987H10.1754C6.98541 13.3355 6.53289 10.0172 6.53289 10.0172V9.03532C6.69726 9.42931 7.60605 11.3557 9.64846 11.3079C9.67699 11.3086 9.70541 11.3101 9.73416 11.3101C11.3273 11.3101 12.6187 9.90431 12.6187 8.17027C12.6187 6.43623 11.3273 5.03049 9.73416 5.03049Z"
      />
    </svg>
  );
}
