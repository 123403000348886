// One per calendar week Mon-Sun permitted (i.e. you can start one on Tuesday then another next Monday).
export function validateCanCreateFreeWeeklyEvent(lastEventDate, nowDate) {
  if (!lastEventDate) return true;

  const timeDiff = nowDate.getTime() - lastEventDate.getTime();
  const oneDay = 24 * 60 * 60 * 1000;
  const oneWeek = 7 * 24 * 60 * 60 * 1000;

  // Sundays are index 0 in JS
  const lastEventDay = lastEventDate.getDay() || 7;
  const nowDay = nowDate.getDay() || 7;

  const weekDayDiff = nowDay - lastEventDay;

  // More than one week ago or an earlier weekday (this week) or the same weekday but more than a day ago (last week)
  // E.g. if you started on Tuesday, then next Monday will be earlier weekday.
  // If you started on Monday then next Monday will be same weekday, but more than 24 hours ago, so not same day.
  return timeDiff > oneWeek || weekDayDiff < 0 || (weekDayDiff === 0 && timeDiff > oneDay);
}

export function getNumberOfUnreadGroupMessages({ groupId, userId, groupsExtraData, groupsMessages }) {
  const timestamp = groupsExtraData[groupId] ? groupsExtraData[groupId]?.[userId] ?? 0 : Date.now();
  const messages = groupsMessages[groupId] || [];
  return messages.filter((m) => m.timestamp > timestamp).length;
}
