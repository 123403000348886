import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAuth } from '../../util/hooks.jsx'
import { useTranslation } from 'react-i18next'
import { useAppStateStore } from '../../appStateStore.jsx'
import Alert from '../../components/alert/alert.jsx'
import useNotifications from '../../components/notifications/useNotifications.jsx';

export default function Login() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const { signInWithSbf, isVerified } =  useAuth();
  const { fed } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [sbfData, setSbfData] = useState(null);
  const notify = useNotifications();
  const updateAppState = useAppStateStore((state) => state.updateAppState);

  const isLoggedIn = isVerified();

  useEffect(function () {
    if (fed !== 'sv') {
      return;
    }
    if (token) {
      const doStuff = async () => {
        const result = await signInWithSbf({ token });

        if (result?.errorCode) {
          if (result.errorCode === 'auth/email-already-in-use') {
            setError({
              errorMessage: 'Ett konto finns redan med din email. För att koppla ditt Cuebids-konto till Sbf, logga in på Cuebids på vanligt sätt och koppla på sidan Settings. Du kan sedan logga in på ditt Cuebids-konto via Sbf:s inloggning.',
            });
          } else if (result.errorCode === 'sbf/no-email') {
            setError({
              errorMessage: 'Ditt Sbf-konto saknar email. Du behöver först skapa ett Cuebids-konto med en annan inloggningsmetod och sedan kan du koppla ditt Cuebids-konto till Sbf på sidan Settings.',
            });
          } else {
            setError({
              errorMessage: 'Något gick fel',
            });
          }
        } else if (result?.noPrep) {
          setSbfData({ email: result.email, fedNr: result.fedNr });
        } else {
          const newAccount = result?.newAccount;
          notify(
            {
              text: newAccount
                ? t('login.messages.create_account_success', { lng: 'sv' })
                : t('login.messages.login_success', { lng: 'sv' }),
              type: 'success',
              autoHide: newAccount ? 10000 : 2000,
            },
          );
        }
      }
      doStuff();
    } else {
      navigate('/login');
    }
  }, [token, navigate, signInWithSbf, fed, notify, t]);

  if (fed !== 'sv') {
    return <Navigate to="/login" />;
  }

  if (isLoggedIn) {
    return <Navigate to="/spots/sv" />;
  }

  if (sbfData) {
    return (
      <div className="page">
        <Alert
          text="Du har inget Cuebids-konto kopplat till Sbf. Du kan koppla ditt existerande Cuebids-konto eller skapa ett nytt konto."
        />
        <button
          className="btn btn-primary"
          onClick={function () {
            updateAppState({ sbfData });
            navigate('/login');
          }}
        >
          Till inloggningssidan
        </button>
      </div>
    );
  }

  if (error) {
    return (
      <div className="page">
        <div className="flex w-full justify-center">
          <Alert
            onClick={() => setError(null)}
            text={error.errorMessage}
            severity="error"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="page">
      <div className="flex w-full justify-center mb-4">
        Laddar...
      </div>
    </div>
  );
}
