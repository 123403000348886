export default function Checkbox({ onChange, checked, disabled, label, onFocus, sx }) {
  return (
    <div className="form-control">
      <label className="label cursor-pointer justify-start gap-2">
        <input
          type="checkbox"
          className={`checkbox-accent checkbox ${sx || ''}`}
          checked={checked}
          disabled={disabled}
          onChange={() => {
            onChange?.(!checked);
          }}
          onFocus={onFocus}
        />
        <span className="label-text">{label}</span>
      </label>
    </div>
  );
}
