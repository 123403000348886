import { ranges } from './constants';

// TODO: Invitational bids/responses

const takeoutDouble = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C$/,
    explain: 'Takeout double over 1C',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqD: 3,
        reqH: 3,
        reqS: 3,
        reqMaxC: 2,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxD: 5,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D$/,
    explain: 'Takeout double over 1D',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqC: 3,
        reqH: 3,
        reqS: 3,
        reqMaxD: 2,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxC: 6,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H$/,
    explain: 'Takeout double over 1H',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqC: 3,
        reqD: 3,
        reqS: 3,
        reqMaxH: 2,
        reqMaxS: 4,
        reqMaxC: 6,
        reqMaxD: 6,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S$/,
    explain: 'Takeout double over 1S',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqC: 3,
        reqD: 3,
        reqH: 3,
        reqMaxS: 2,
        reqMaxH: 4,
        reqMaxC: 6,
        reqMaxD: 6,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-D-[PR]$/,
    explain: "Takeout double over 1C, partner's response",
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqC: 5,
        reqMaxD: 3,
        reqMaxH: 3,
        reqMaxS: 3,
      },
      {
        bid: '2D',
        reqD: 5,
        reqMaxH: 3,
        reqMaxS: 3,
        reqHp: ranges.intermediateRaise,
        lastBidExclusions: ['R'],
      },
      {
        bid: '2H',
        reqH: 5,
        reqHp: ranges.intermediateRaise,
        lastBidExclusions: ['R'],
        reqStrains: function(strains) {
          return strains.H >= strains.S - 1;
        },
      },
      {
        bid: '2S',
        reqS: 5,
        reqHp: ranges.intermediateRaise,
        lastBidExclusions: ['R'],
        reqStrains: function(strains) {
          return strains.S >= strains.H - 1;
        },
      },
      {
        bid: '2D["Weak"]',
        reqD: 5,
        reqMaxH: 3,
        reqMaxS: 3,
        lastBidExclusions: ['P'],
      },
      {
        bid: '2H["Weak"]',
        reqH: 5,
        lastBidExclusions: ['P'],
        reqStrains: function(strains) {
          return strains.H >= strains.S - 1;
        },
      },
      {
        bid: '2S["Weak"]',
        reqS: 5,
        lastBidExclusions: ['P'],
        reqStrains: function(strains) {
          return strains.S >= strains.H - 1;
        },
      },
      {
        bid: '1D',
        reqD: 4,
        reqStrains: function(strains) {
          return strains.D > strains.S && strains.D > strains.H;
        },
      },
      {
        bid: '1H',
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.D;
        },
      },
      {
        bid: '1S',
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.D;
        },
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxH: 3,
        reqMaxS: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '1D',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-D-(1D|1H|1S|1N|2C)$/,
    explain:
      "Takeout double over 1C, partner's response over opponents bid again",
    options: [
      {
        bid: 'D["Responsive double, 4-4 majors"]',
        reqH: 4,
        reqS: 4,
        lastBidExclusions: ['1H', '1S'],
      },
      {
        bid: '2D',
        reqD: 4,
        lastBidExclusions: ['1D'],
        reqHp: ranges.intermediateRaise,
        reqStrains: function(strains) {
          return strains.D > strains.H && strains.D > strains.S;
        },
      },
      {
        bid: '2H',
        reqH: 4,
        lastBidExclusions: ['1H'],
        reqHp: ranges.intermediateRaise,
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.D;
        },
      },
      {
        bid: '2S',
        reqS: 4,
        lastBidExclusions: ['1S'],
        reqHp: ranges.intermediateRaise,
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.D;
        },
      },
      {
        bid: '1H',
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.D;
        },
      },
      {
        bid: '1S',
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.D;
        },
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxH: 3,
        reqMaxS: 3,
        reqHcpInLastSuit: 4,
        lastBid: '1D',
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxD: 4,
        reqMaxS: 3,
        reqHcpInLastSuit: 4,
        lastBid: '1H',
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxD: 4,
        reqMaxH: 3,
        reqHcpInLastSuit: 4,
        lastBid: '1S',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-D-(1D|1N|2C)-D-[PR]$/,
    explain: "Takeout double over 1C, doubler's response to responsive double",
    options: [
      {
        bid: '1H["Preference"]',
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '1S["Preference"]',
        weight: 0.1,
      },
      {
        bid: '2H["Preference"]',
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '2S["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-D-[PR]$/,
    explain: "Takeout double over 1D, partner's response",
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqD: 5,
        reqMaxC: 3,
        reqMaxH: 3,
        reqMaxS: 3,
      },
      {
        bid: '2H',
        reqH: 5,
        reqHp: ranges.intermediateRaise,
        lastBidExclusions: ['R'],
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.C;
        },
      },
      {
        bid: '2S',
        reqS: 5,
        reqHp: ranges.intermediateRaise,
        lastBidExclusions: ['R'],
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.C;
        },
      },
      {
        bid: '2H["Weak"]',
        reqH: 5,
        lastBidExclusions: ['P'],
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.C;
        },
      },
      {
        bid: '2S["Weak"]',
        reqS: 5,
        lastBidExclusions: ['P'],
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.C;
        },
      },
      {
        bid: '3C["Weak"]',
        reqC: 6,
        lastBidExclusions: ['P'],
        reqStrains: function(strains) {
          return strains.C > strains.H && strains.C > strains.S;
        },
      },
      {
        bid: '1H',
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.C;
        },
      },
      {
        bid: '1S',
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.C;
        },
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxH: 3,
        reqMaxS: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '2C',
        reqC: 4,
        reqStrains: function(strains) {
          return strains.C > strains.H && strains.C > strains.S;
        },
      },
      {
        bid: '2C',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-D-(1H|1S|1N|2C|2D)$/,
    explain:
      "Takeout double over 1D, partner's response over opponents bid again",
    options: [
      {
        bid: 'D["Responsive double, 4-4 majors"]',
        reqH: 4,
        reqS: 4,
        lastBidExclusions: ['1H', '1S'],
      },
      {
        bid: '2H',
        reqH: 4,
        lastBidExclusions: ['1H'],
        reqHp: ranges.intermediateRaise,
        reqStrains: function(strains) {
          return strains.H > strains.S && strains.H >= strains.C;
        },
      },
      {
        bid: '2S',
        reqS: 4,
        lastBidExclusions: ['1S'],
        reqHp: ranges.intermediateRaise,
        reqStrains: function(strains) {
          return strains.S >= strains.H && strains.S >= strains.C;
        },
      },
      {
        bid: '1S',
        reqS: 4,
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxS: 3,
        reqMaxC: 4,
        reqHcpInLastSuit: 4,
        lastBid: '1H',
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxH: 3,
        reqMaxC: 4,
        reqHcpInLastSuit: 4,
        lastBid: '1S',
      },
      {
        bid: '2C',
        reqC: 4,
        reqHp: ranges.newSuitUnforced,
        reqStrains: function(strains) {
          return strains.C > strains.S && strains.C > strains.H;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-D-(1N|2C|2D)-D-[PR]$/,
    explain: "Takeout double over 1D, doubler's response to responsive double",
    options: [
      {
        bid: '2H["Preference"]',
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '2S["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-D-[PR]$/,
    explain: "Takeout double over 1H, partner's response",
    options: [
      {
        // Note: some of the logic for 2suit assumes max 4hearts if no other 4c suit (5+ will pass)
        bid: 'P["Penalty pass"]',
        reqH: 5,
        reqMaxC: 3,
        reqMaxD: 3,
        reqMaxS: 3,
      },
      {
        bid: '2S',
        reqS: 5,
        reqHp: ranges.intermediateRaise,
        lastBidExclusions: ['R'],
        reqStrains: function(strains) {
          return strains.S >= strains.C && strains.S >= strains.D;
        },
      },
      {
        bid: '2S["Weak"]',
        reqS: 5,
        lastBidExclusions: ['P'],
        reqStrains: function(strains) {
          return strains.S >= strains.C && strains.S >= strains.D;
        },
      },
      {
        bid: '3C["Weak"]',
        reqC: 6,
        lastBidExclusions: ['P'],
        reqStrains: function(strains) {
          return strains.C > strains.D && strains.C > strains.S;
        },
      },
      {
        bid: '3D["Weak"]',
        reqD: 6,
        lastBidExclusions: ['P'],
        reqStrains: function(strains) {
          return strains.D >= strains.C && strains.D > strains.S;
        },
      },
      {
        bid: '1S',
        reqS: 4,
        reqStrains: function(strains) {
          return strains.S >= strains.D && strains.S >= strains.C;
        },
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxS: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      // Note: some of the logic below assumes max 4hearts if no other 4c suit
      {
        bid: '2C',
        weight: 0.1, // Fallback bid, to handle 3433
      },
      {
        bid: '2C',
        reqStrains: function(strains) {
          return strains.C >= strains.D && strains.C > strains.S;
        },
      },
      {
        bid: '2D',
        reqStrains: function(strains) {
          return strains.D > strains.C && strains.D > strains.S;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-D-(1S|1N|2C|2D|2H)$/,
    explain:
      "Takeout double over 1H, partner's response over opponents bid again",
    options: [
      {
        bid: 'D["Responsive double, 4-4 minors"]',
        reqC: 4,
        reqD: 4,
        lastBidExclusions: ['2C', '2D'],
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxC: 4,
        reqMaxD: 4,
        reqHcpInLastSuit: 4,
        lastBid: '1S',
      },
      {
        bid: '2C',
        reqC: 4,
        reqHp: ranges.intermediateRaise,
        reqStrains: function(strains) {
          return strains.C > strains.S && strains.C > strains.D;
        },
      },
      {
        bid: '2D',
        reqD: 4,
        reqMaxS: 3,
        reqHp: ranges.intermediateRaise,
        reqStrains: function(strains) {
          return strains.D > strains.S && strains.D >= strains.C;
        },
      },
      {
        bid: '2S',
        reqS: 4,
        lastBidExclusions: ['1S'],
        reqHp: ranges.intermediateRaise,
        reqStrains: function(strains) {
          return strains.S >= strains.C && strains.S >= strains.D;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-D-(1S|1N|2H)-D-[PR]$/,
    explain: "Takeout double over 1H, doubler's response to responsive double",
    options: [
      {
        bid: '2C["Preference"]',
        reqStrains: function(strains) {
          return strains.C >= strains.D;
        },
      },
      {
        bid: '2D["Preference"]',
        weight: 0.1,
      },
      {
        bid: '3C["Preference"]',
        reqStrains: function(strains) {
          return strains.C >= strains.D;
        },
      },
      {
        bid: '3D["Preference"]',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-D-[PR]$/,
    explain: "Takeout double over 1S, partner's response",
    options: [
      {
      // Note: some of the logic for 2suit assumes max 4spades if no other 4c suit (5+ will pass)
        bid: 'P["Penalty pass"]',
        reqS: 5,
        reqMaxC: 3,
        reqMaxD: 3,
        reqMaxH: 3,
      },
      {
        bid: '3C["Weak"]',
        reqC: 6,
        lastBidExclusions: ['P'],
        reqStrains: function(strains) {
          return strains.C > strains.D && strains.C > strains.H;
        },
      },
      {
        bid: '3D["Weak"]',
        reqD: 6,
        lastBidExclusions: ['P'],
        reqStrains: function(strains) {
          return strains.D >= strains.C && strains.D > strains.H;
        },
      },
      {
        bid: '3H["Weak"]',
        reqH: 6,
        lastBidExclusions: ['P'],
        reqStrains: function(strains) {
          return strains.H >= strains.C && strains.H >= strains.D;
        },
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxH: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      // Note: some of the logic below assumes max 4spades if no other 4c suit
      {
        bid: '2C',
        weight: 0.1, // Fallback bid, to handle 4333
      },
      {
        bid: '2C',
        reqStrains: function(strains) {
          return strains.C >= strains.D && strains.C > strains.H;
        },
      },
      {
        bid: '2D',
        reqStrains: function(strains) {
          return strains.D > strains.C && strains.D > strains.H;
        },
      },
      {
        bid: '2H',
        reqH: 4,
        reqStrains: function(strains) {
          return strains.H >= strains.C && strains.H >= strains.D;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-D-(1N|2C|2D|2H|2S)$/,
    explain:
      "Takeout double over 1S, partner's response over opponents bid again",
    options: [
      {
        bid: 'D["Responsive double, 4-4 minors"]',
        reqC: 4,
        reqD: 4,
        lastBidExclusions: ['2C', '2D'],
      },
      {
        bid: '2C',
        reqC: 4,
        reqMaxH: 3,
        reqHp: ranges.intermediateRaise,
        reqStrains: function(strains) {
          return strains.C > strains.D && strains.C > strains.H;
        },
      },
      {
        bid: '2D',
        reqD: 4,
        reqMaxH: 3,
        reqHp: ranges.intermediateRaise,
        reqStrains: function(strains) {
          return strains.D >= strains.C && strains.D >= strains.H;
        },
      },
      {
        bid: '2H',
        reqH: 4,
        reqHp: ranges.intermediateRaise,
        reqStrains: function(strains) {
          return strains.H >= strains.C && strains.H >= strains.D;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-D-(1N|2H|2S)-D-[PR]$/,
    explain: "Takeout double over 1S, doubler's response to responsive double",
    options: [
      {
        bid: '2C["Preference"]',
        reqStrains: function(strains) {
          return strains.C >= strains.D;
        },
      },
      {
        bid: '2D["Preference"]',
        weight: 0.1,
      },
      {
        bid: '3C["Preference"]',
        reqStrains: function(strains) {
          return strains.C >= strains.D;
        },
      },
      {
        bid: '3D["Preference"]',
        weight: 0.1,
      },
    ],
  },

  /*
  4th seat TO
  */

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1D$/,
    explain: 'Opps opened 1C and responded 1D, we can take out',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxC: 3,
        reqMaxD: 3,
        reqH: 4,
        reqS: 4,
        reqMaxH: 5,
        reqMaxS: 5,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1D-D-[PR]$/,
    explain:
      'Opps opened 1C and responded 1D, I passed and partner take out, my response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqD: 5,
        reqMaxH: 3,
        reqMaxS: 3,
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxH: 3,
        reqMaxS: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '1H',
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
      },
      {
        bid: '1S',
        reqStrains: function(strains) {
          return strains.S > strains.H;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1D-D-(1H|1S|1N|2C|2D|2H)$/,
    explain:
      'Opps opened 1C and responded 1D, I passed and partner take out, my response over new bid',
    options: [
      {
        bid: '2H',
        reqH: 4,
        reqHp: ranges.weakRaise,
        reqStrains: function(strains) {
          return strains.H >= strains.S;
        },
        lastBidExclusions: ['1H'],
      },
      {
        bid: '2S',
        reqHp: ranges.weakRaise,
        reqS: 4,
        lastBidExclusions: ['1S'],
      },
    ],
  },

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1H$/,
    explain: 'Opps opened 1C and responded 1H, we can take out',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxC: 3,
        reqMaxH: 3,
        reqD: 4,
        reqS: 4,
        reqMaxD: 5,
        reqMaxS: 5,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1H-D-[PR]$/,
    explain:
      'Opps opened 1C and responded 1H, I passed and partner take out, my response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqH: 5,
        reqMaxD: 3,
        reqMaxS: 3,
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxD: 3,
        reqMaxS: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '1S',
        reqStrains: function(strains) {
          return strains.S >= strains.D;
        },
      },
      {
        bid: '2D',
        reqStrains: function(strains) {
          return strains.D > strains.S;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1H-D-(1S|1N|2C|2D|2H)$/,
    explain:
      'Opps opened 1C and responded 1H, I passed and partner take out, my response over new bid',
    options: [
      {
        bid: '2D',
        reqD: 4,
        reqHp: ranges.weakRaise,
        reqStrains: function(strains) {
          return strains.D > strains.S;
        },
      },
      {
        bid: '2S',
        reqHp: ranges.weakRaise,
        reqS: 4,
        lastBidExclusions: ['1S'],
      },
    ],
  },

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-P-1H$/,
    explain: 'Opps opened 1D and responded 1H, we can take out',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxD: 3,
        reqMaxH: 3,
        reqC: 4,
        reqS: 4,
        reqMaxC: 5,
        reqMaxS: 5,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-P-1H-D-[PR]$/,
    explain:
      'Opps opened 1D and responded 1H, I passed and partner take out, my response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqH: 5,
        reqMaxC: 3,
        reqMaxS: 3,
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxC: 3,
        reqMaxS: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '1S',
        reqStrains: function(strains) {
          return strains.S >= strains.C;
        },
      },
      {
        bid: '2C',
        reqStrains: function(strains) {
          return strains.C > strains.S;
        },
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-P-1H-D-(1S|1N|2C|2D|2H)$/,
    explain:
      'Opps opened 1D and responded 1H, I passed and partner take out, my response over new bid',
    options: [
      {
        bid: '2S',
        reqHp: ranges.weakRaise,
        reqS: 4,
        lastBidExclusions: ['1S'],
      },
      {
        bid: '2C',
        reqHp: ranges.weakRaise,
        reqC: 4,
      },
      {
        bid: '3C',
        reqHp: ranges.intermediateRaise,
        reqC: 4,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '3C',
        reqHp: ranges.weakRaise,
        reqC: 5,
        lastBidExclusions: ['2C'],
      },
    ],
  },

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1S$/,
    explain: 'Opps opened 1C and responded 1S, we can take out',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxS: 3,
        reqMaxC: 3,
        reqD: 4,
        reqH: 4,
        reqMaxD: 5,
        reqMaxH: 5,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1S-D-[PR]$/,
    explain:
      'Opps opened 1C and responded 1S, pd take out and our response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqS: 5,
        reqMaxD: 3,
        reqMaxH: 3,
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxD: 3,
        reqMaxH: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '2D',
        reqD: 3,
        reqStrains: function(strains) {
          return strains.D > strains.H;
        },
      },
      {
        bid: '2H',
        reqH: 3,
      },
      {
        bid: '1N',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1S-D-(1N|2C|2D|2H|2S)$/,
    explain:
      'Opps opened 1C and responded 1S, pd take out and our response over new bid',
    options: [
      {
        bid: '2D',
        reqHp: ranges.weakRaise,
        reqD: 4,
      },
      {
        bid: '3D',
        reqHp: ranges.intermediateRaise,
        reqD: 4,
        lastBidExclusions: ['2D'],
      },
      {
        bid: '3D',
        reqHp: ranges.weakRaise,
        reqD: 5,
        lastBidExclusions: ['2D'],
      },
      {
        bid: '2H',
        reqHp: ranges.weakRaise,
        reqH: 4,
      },
      {
        bid: '3H',
        reqHp: ranges.intermediateRaise,
        reqH: 4,
        lastBidExclusions: ['2H'],
      },
      {
        bid: '3H',
        reqHp: ranges.weakRaise,
        reqH: 5,
        lastBidExclusions: ['2H'],
      },
    ],
  },

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-P-1S$/,
    explain: 'Opps opened 1D and responded 1S, we can take out',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxS: 3,
        reqMaxD: 3,
        reqC: 4,
        reqH: 4,
        reqMaxC: 5,
        reqMaxH: 5,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-P-1S-D-[PR]$/,
    explain:
      'Opps opened 1D and responded 1S, pd take out and our response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqS: 5,
        reqMaxC: 3,
        reqMaxH: 3,
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxC: 3,
        reqMaxH: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '2C',
        reqC: 3,
        reqStrains: function(strains) {
          return strains.C >= strains.H;
        },
      },
      {
        bid: '2H',
        reqH: 3,
      },
      {
        bid: '1N',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-P-1S-D-(1N|2C|2D|2H|2S)$/,
    explain:
      'Opps opened 1D and responded 1S, pd take out and our response over new bid',
    options: [
      {
        bid: '2C',
        reqHp: ranges.weakRaise,
        reqC: 4,
      },
      {
        bid: '3C',
        reqHp: ranges.intermediateRaise,
        reqC: 4,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '3C',
        reqHp: ranges.weakRaise,
        reqC: 5,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '2H',
        reqHp: ranges.weakRaise,
        reqH: 4,
      },
      {
        bid: '3H',
        reqHp: ranges.intermediateRaise,
        reqH: 4,
        lastBidExclusions: ['2H'],
      },
      {
        bid: '3H',
        reqHp: ranges.weakRaise,
        reqH: 5,
        lastBidExclusions: ['2H'],
      },
    ],
  },

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-P-1S$/,
    explain: 'Opps opened 1H and responded 1S, we can take out',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxH: 3,
        reqMaxS: 3,
        reqC: 4,
        reqD: 4,
        reqMaxC: 5,
        reqMaxD: 5,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-P-1S-D-[PR]$/,
    explain:
      'Opps opened 1H and responded 1S, pd take out and our response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqS: 5,
        reqMaxC: 3,
        reqMaxD: 3,
      },
      {
        bid: '1N',
        reqHp: ranges.newSuitUnforced,
        reqMaxC: 3,
        reqMaxD: 3,
        reqHcpInLastSuit: 3,
        lastBidExclusions: ['R'],
      },
      {
        bid: '2C',
        reqC: 3,
        reqStrains: function(strains) {
          return strains.C >= strains.D;
        },
      },
      {
        bid: '2D',
        reqD: 3,
      },
      {
        bid: '1N',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-P-1S-D-(1N|2C|2D|2H|2S)$/,
    explain:
      'Opps opened 1H and responded 1S, pd take out and our response over new bid',
    options: [
      {
        bid: '2C',
        reqHp: ranges.weakRaise,
        reqC: 4,
      },
      {
        bid: '3C',
        reqHp: ranges.intermediateRaise,
        reqC: 4,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '3C',
        reqHp: ranges.weakRaise,
        reqC: 5,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '2D',
        reqHp: ranges.weakRaise,
        reqD: 4,
      },
      {
        bid: '3D',
        reqHp: ranges.intermediateRaise,
        reqD: 4,
        lastBidExclusions: ['2D'],
      },
      {
        bid: '3D',
        reqHp: ranges.weakRaise,
        reqD: 5,
        lastBidExclusions: ['2D'],
      },
    ],
  },

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1N$/,
    explain: 'Opps opened 1C and responded 1N, we can take out',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxC: 2,
        reqD: 3,
        reqH: 3,
        reqS: 3,
        reqMaxD: 5,
        reqMaxH: 5,
        reqMaxS: 5,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1N-D-[PR]$/,
    explain:
      'Opps opened 1C and responded 1N, pd take out and our response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqC: 5,
        reqMaxD: 3,
        reqMaxH: 3,
        reqMaxS: 3,
      },
      {
        bid: '2H',
        reqH: 4,
      },
      {
        bid: '2S',
        reqS: 4,
      },
      {
        bid: '2D',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1N-D-(2C|2D|2H)$/,
    explain:
      'Opps opened 1C and responded 1N, pd take out and our response over new bid',
    options: [
      {
        bid: '2D',
        reqHp: ranges.raise,
        reqD: 4,
      },
      {
        bid: '2H',
        reqHp: ranges.raise,
        reqH: 4,
      },
      {
        bid: '2S',
        reqHp: ranges.raise,
        reqS: 4,
      },
    ],
  },

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-P-1N$/,
    explain: 'Opps opened 1D and responded 1N, we can take out',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxD: 2,
        reqC: 3,
        reqH: 3,
        reqS: 3,
        reqMaxC: 5,
        reqMaxH: 5,
        reqMaxS: 5,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-P-1N-D-[PR]$/,
    explain:
      'Opps opened 1D and responded 1N, pd take out and our response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqD: 5,
        reqMaxC: 3,
        reqMaxH: 3,
        reqMaxS: 3,
      },
      {
        bid: '2H',
        reqH: 4,
      },
      {
        bid: '2S',
        reqS: 4,
      },
      {
        bid: '2C',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-P-1N-D-(2C|2D|2H|2S)$/,
    explain:
      'Opps opened 1D and responded 1N, pd take out and our response over new bid',
    options: [
      {
        bid: '3C',
        reqHp: ranges.intermediateRaise,
        reqC: 4,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '3C',
        reqHp: ranges.weakRaise,
        reqC: 5,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '2H',
        reqHp: ranges.raise,
        reqH: 4,
      },
      {
        bid: '2S',
        reqHp: ranges.raise,
        reqS: 4,
      },
    ],
  },

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-P-1N$/,
    explain: 'Opps opened 1H and responded 1N, we can take out',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxH: 2,
        reqC: 3,
        reqD: 3,
        reqS: 3,
        reqMaxC: 5,
        reqMaxD: 5,
        reqMaxS: 5,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-P-1N-D-[PR]$/,
    explain:
      'Opps opened 1H and responded 1N, pd take out and our response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqH: 5,
        reqMaxC: 3,
        reqMaxD: 3,
        reqMaxS: 3,
      },
      {
        bid: '2D',
        reqD: 4,
      },
      {
        bid: '2S',
        reqS: 4,
      },
      {
        bid: '2C',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-P-1N-D-(2C|2D|2H|2S)$/,
    explain:
      'Opps opened 1H and responded 1N, pd take out and our response over new bid',
    options: [
      {
        bid: '2D',
        reqHp: ranges.raise,
        reqD: 4,
      },
      {
        bid: '2D',
        reqHp: ranges.weakRaise,
        reqD: 5,
      },
      {
        bid: '3D',
        reqHp: ranges.intermediateRaise,
        reqD: 4,
        lastBidExclusions: ['2D'],
      },
      {
        bid: '3D',
        reqHp: ranges.weakRaise,
        reqD: 5,
        lastBidExclusions: ['2D'],
      },
      {
        bid: '3C',
        reqHp: ranges.intermediateRaise,
        reqC: 4,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '3C',
        reqHp: ranges.weakRaise,
        reqC: 5,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '2S',
        reqHp: ranges.raise,
        reqS: 4,
      },
    ],
  },

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-P-1N$/,
    explain: 'Opps opened 1S and responded 1N, we can take out',
    options: [
      {
        bid: 'D["Takeout double"]',
        reqMaxS: 2,
        reqC: 3,
        reqD: 3,
        reqH: 3,
        reqMaxC: 5,
        reqMaxD: 5,
        reqMaxH: 5,
        reqHp: ranges.takeoutDouble,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-P-1N-D-[PR]$/,
    explain:
      'Opps opened 1S and responded 1N, pd take out and our response over pass/re',
    options: [
      {
        bid: 'P["Penalty pass"]',
        reqS: 5,
        reqMaxC: 3,
        reqMaxD: 3,
        reqMaxH: 3,
      },
      {
        bid: '2D',
        reqD: 4,
      },
      {
        bid: '2H',
        reqH: 4,
      },
      {
        bid: '2C',
        weight: 0.1,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-P-1N-D-(2C|2D|2H|2S)$/,
    explain:
      'Opps opened 1S and responded 1N, pd take out and our response over new bid',
    options: [
      {
        bid: '3C',
        reqHp: ranges.intermediateRaise,
        reqC: 4,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '3C',
        reqHp: ranges.weakRaise,
        reqC: 5,
        lastBidExclusions: ['2C'],
      },
      {
        bid: '2D',
        reqHp: ranges.raise,
        reqD: 4,
      },
      {
        bid: '2D',
        reqHp: ranges.weakRaise,
        reqD: 5,
      },
      {
        bid: '3D',
        reqHp: ranges.intermediateRaise,
        reqD: 4,
        lastBidExclusions: ['2D'],
      },
      {
        bid: '3D',
        reqHp: ranges.weakRaise,
        reqD: 5,
        lastBidExclusions: ['2D'],
      },
      {
        bid: '2H',
        reqHp: ranges.raise,
        reqH: 4,
      },
      {
        bid: '2H',
        reqHp: ranges.weakRaise,
        reqH: 5,
      },
      {
        bid: '3H',
        reqHp: ranges.intermediateRaise,
        reqH: 4,
        lastBidExclusions: ['2H'],
      },
      {
        bid: '3H',
        reqHp: ranges.weakRaise,
        reqH: 5,
        lastBidExclusions: ['2H'],
      },
    ],
  },
];

export default takeoutDouble;
