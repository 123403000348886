export const PAYMENT_STRATEGY_SPLIT = 'SPLIT';

export const ticketsMap = {
  heart: Infinity,
  diamond: 240,
  club: 96,
  basic: 48,
};

export function getUserRemainingTickets(ticketsSpent, sub, extraTickets = 0) {
  return Math.max(ticketsMap[sub ?? 'basic'] - ticketsSpent, 0) + extraTickets;
}

export function getChargesOnePlayer(remainingTickets, price) {
  return remainingTickets === Infinity ? 0 : price;
}

export function getCharges({
  partnerRemainingTickets,
  myRemainingTickets,
  price,
  paymentStrategy,
}) {
  if (paymentStrategy === PAYMENT_STRATEGY_SPLIT) {
    if (myRemainingTickets === Infinity) {
      // I can pay for partner if heart
      return getCharges({
        partnerRemainingTickets,
        myRemainingTickets,
        price,
        paymentStrategy: null,
      });
    } else if (partnerRemainingTickets === Infinity) {
      // Partner can pay for me if heart
      const myCharge = Math.min(price, myRemainingTickets);

      return {
        partnerCharge: 0,
        myCharge,
      };
    }
    return {
      partnerCharge: getChargesOnePlayer(partnerRemainingTickets, price),
      myCharge: getChargesOnePlayer(myRemainingTickets, price),
    };
  } else {
    const partnerCharge = Math.min(price, partnerRemainingTickets);
    const myCharge = price + (price - partnerCharge);

    return {
      partnerCharge: partnerRemainingTickets === Infinity ? 0 : partnerCharge,
      myCharge: myRemainingTickets === Infinity ? 0 : myCharge,
    };
  }
}

export function canPairAffordToPlay({
  partnerRemainingTickets,
  myRemainingTickets,
  price,
  paymentStrategy,
}) {
  const { myCharge, partnerCharge } = getCharges({
    partnerRemainingTickets,
    myRemainingTickets,
    price,
    paymentStrategy,
  });

  return myRemainingTickets >= myCharge && partnerRemainingTickets >= partnerCharge;
}

export function getRemainingTicketsWarning({
  myRemainingTickets,
  partnerRemainingTickets,
  price,
  paymentStrategy,
  t
}) {
  const { myCharge } = getCharges({
    partnerRemainingTickets,
    myRemainingTickets,
    price,
    paymentStrategy,
  });

  if (
    myRemainingTickets < price &&
    myCharge <= price &&
    partnerRemainingTickets === Infinity &&
    paymentStrategy === PAYMENT_STRATEGY_SPLIT
  ) {
    return {
      textSimple: t('components.remaining_tickets_warning.you_cannot_afford_partner_heart_short'),
      textLong: t('components.remaining_tickets_warning.you_cannot_afford_partner_heart'),
      severity: 'info',
      withStore: false,
    };
  }

  if (myRemainingTickets < price) {
    return {
      textSimple: t('components.remaining_tickets_warning.you_cannot_afford_short'),
      textLong: t('components.remaining_tickets_warning.you_cannot_afford'),
      severity: 'warning',
      withStore: true,
    };
  }

  if (partnerRemainingTickets < price && myRemainingTickets === Infinity) {
    return {
      textSimple: t('components.remaining_tickets_warning.partner_cannot_afford_you_heart_short'),
      textLong: t('components.remaining_tickets_warning.partner_cannot_afford_you_heart'),
      severity: 'info',
      withStore: false,
    };
  }

  if (
    partnerRemainingTickets < price &&
      myRemainingTickets >= myCharge &&
      paymentStrategy !== PAYMENT_STRATEGY_SPLIT
  ) {
    return {
      textSimple: t('components.remaining_tickets_warning.partner_cannot_afford_you_pay_short'),
      textLong: t('components.remaining_tickets_warning.partner_cannot_afford_you_pay'),
      severity: 'warning',
      withStore: false,
    };
  }

  if (partnerRemainingTickets < price) {
    return {
      textSimple: t('components.remaining_tickets_warning.partner_cannot_afford_get_heart_short'),
      textLong: t('components.remaining_tickets_warning.partner_cannot_afford_get_heart'),
      severity: 'warning',
      withStore: true,
    };
  }
}

export function getRemainingTicketsWarningTextSimple({
  myRemainingTickets,
  partnerRemainingTickets,
  price,
  paymentStrategy,
  t,
}) {
  return (
    getRemainingTicketsWarning({
      myRemainingTickets,
      partnerRemainingTickets,
      price,
      paymentStrategy,
      t,
    })?.textSimple || null
  );
}
