import { useMemo, useState } from 'react'
import Logo from '../../images/logos/logo512.png';
import sbu from '../../images/logos/feds/sbu.png';
import { AnimatePresence } from 'framer-motion';
import { useAuth } from '../../util/hooks.jsx';
import log from '../../util/logger';
import { Link, Navigate, useParams } from 'react-router-dom'
import SignInWithCustomToken from '../../components/users/signInWithCustomToken';
import AdminMessage from '../../components/AdminMessage';
import {
  doesDisplayNameHaveForbiddenCharacters,
  isDisplayNameTooShort,
} from '../../util/displayName';
import Animated from '../../components/animation/animated';
import Alert from '../../components/alert/alert';
import { useTranslation } from 'react-i18next';
import { useAppStateStore } from '../../appStateStore.jsx';
import { PlayIcon } from '../../components/icons/playIcon.jsx';
import Checkbox from '../../components/checkbox/Checkbox.jsx';
import { InfoIcon } from '../../components/icons/infoIcon.jsx';
import SessionDealMock from '../mocks/sessionDealMocks.jsx';
import CloseButton from '../../components/buttons/closeButton.jsx';
import { updateUser } from '../../firebase/userApi.js'
import { shouldShowOnboarding } from '../../util/onboarding.js'
import sbfLogo from '../../images/logos/feds/sv.png';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { LanguageDropdown } from '../../components/dropdown/languageDropdown.jsx'
import { getBrowserPreferredLanguages } from '../../util/language.js'
import InfoPopup from "../../components/infoPopup/infoPopup.jsx";

function getErrorText(t, error) {
  if (error === 'auth/invalid-email') {
    return t('login.messages.auth_invalid_email');
  } else if (error === 'auth/user-not-found') {
    return t('login.messages.auth_user_not_found');
  } else if (error === 'auth/too-many-requests') {
    return t('login.messages.auth_too_many_requests');
  } else if (error === 'auth/wrong-password') {
    return t('login.messages.auth_wrong_password');
  }
}

function LoginMethodSelect({ displayName, fed, fedNr, referral }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const { t, i18n } = useTranslation();

  const updateAppState = useAppStateStore((state) => state.updateAppState);
  const notify = useNotifications();
  const sbfData = useAppStateStore((state) => state.sbfData);
  const android = useAppStateStore((state) => state.android);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [fedNrInput, setFedNrInput] = useState('');
  const [showFedNrInput, setShowFedNrInput] = useState(false);

  const createNewAccount = !!displayName;

  const {
    signInWithGoogle,
    signInWithApple,
    signInWithCredentials,
    createAccount,
    prepareCreateAccountWithSbf,
    createAccountWithSbf,
    forgotPassword,
    setRememberMe,
  } = useAuth();

  async function updateRememberMe(e) {
    setRemember(() => e.target.checked);
    const error = await setRememberMe(e.target.checked);
    if (error) {
      log('change remember me', error);
    }
  }

  async function handlePasswordReset(e) {
    e.preventDefault();
    setError();

    if (email.length < 1) {
      setError({
        errorMessage: t('login.messages.no_email'),
      });
      return;
    }

    if (email.length < 3) {
      setError({ errorMessage: t('login.messages.short_email') });
      return;
    }

    setLoading(true);

    try {
      const error = await forgotPassword(email);

      if (error) {
        log('Forgot password error', error, 'err');
        if (getErrorText(t, error.errorCode)) {
          setError({
            errorMessage: getErrorText(t, error.errorCode),
          });
        } else {
          setError({
            errorMessage: t('login.messages.pw_reset_error_default'),
          });
        }
      } else {
        notify(
          {
            text: t('login.messages.pw_reset_success'),
            type: 'success',
            autoHide: 10000,
          },
        );
      }
    } catch (e) {
      setError({
        errorMessage: t('login.messages.pw_reset_error_default'),
      });
      setLoading(false);
    }
    setLoading(false);
  }

  async function handleSignInWithEmailAndPassword(e) {
    e.preventDefault();

    if (email.length === 0) {
      return;
    }

    setError();

    if (email.length < 3) {
      setError({ errorMessage: t('login.messages.short_email') });
      return;
    }

    if (password.length < 6) {
      setError({
        errorMessage: t('login.messages.password_required'),
      });
      return;
    }

    setLoading(true);

    const error = createNewAccount
      ? await createAccount({ email, password, displayName, fed: sbfData ? 'sv' : fed, fedNr: sbfData ? sbfData.fedNr : fedNr, referral })
      : await signInWithCredentials(email, password);

    if (!error && !createNewAccount && sbfData) {
      await updateUser({ fed: 'sv', fedNr: sbfData.fedNr, fedVerified: true });
    }

    setLoading(false);

    if (error?.errorCode) {
      log('Signin email/password error', error, 'err');
      if (getErrorText(t, error.errorCode)) {
        setError({
          errorMessage: getErrorText(t, error.errorCode),
        });
      } else {
        setError({
          errorMessage: createNewAccount
            ? t('login.messages.create_account_error_default')
            : t('login.messages.login_error_default'),
        });
      }
    } else {
      notify(
        {
          text: createNewAccount
            ? t('login.messages.create_account_success')
            : t('login.messages.login_success'),
          type: 'success',
          autoHide: createNewAccount ? 10000 : 2000,
        },
      );
    }
  }

  async function handleSignInWithApple(e) {
    e.preventDefault();

    if (window?.isNative) {
      if (createNewAccount) {
        updateAppState({ newAccountPrepData: {
          displayName,
          fed: sbfData ? 'sv': fed,
          fedNr: sbfData ? sbfData.fedNr : fed,
          referral
        } });
      }

      window.ReactNativeWebView.postMessage('loginWithApple');
    } else {
      setLoading(true);
      const error = await signInWithApple({ displayName, fed, fedNr, referral });
      if (error?.errorCode) {
        setError({
          errorMessage: createNewAccount
            ? t('login.messages.create_account_error_default')
            : t('login.messages.login_error_default'),
        });
        setLoading(false);
        return;
      }

      if (sbfData) {
        await updateUser({ fed: 'sv', fedNr: sbfData.fedNr, fedVerified: true });
      }

      notify(
        {
          text: createNewAccount
            ? t('login.messages.create_account_success')
            : t('login.messages.login_success'),
          type: 'success',
          autoHide: createNewAccount ? 10000 : 2000,
        },
      );
      setLoading(false);
    }
  }

  async function handleSignInWithGoogle(e) {
    e.preventDefault();

    if (window?.isNative) {
      if (createNewAccount) {
        updateAppState({ newAccountPrepData: { displayName, fed: sbfData ? 'sv': fed, fedNr: sbfData ? sbfData.fedNr : fed, referral } });
      }

      window.ReactNativeWebView.postMessage('loginWithGoogle');
    } else {
      setLoading(true);
      const error = await signInWithGoogle({ displayName, fed, fedNr, referral });
      if (error?.errorCode) {
        setError({
          errorMessage: createNewAccount
            ? t('login.messages.create_account_error_default')
            : t('login.messages.login_error_default'),
        });
        setLoading(false);
        return;
      }

      if (sbfData) {
        await updateUser({ fed: 'sv', fedNr: sbfData.fedNr, fedVerified: true });
      }

      notify(
        {
          text: createNewAccount
            ? t('login.messages.create_account_success')
            : t('login.messages.login_success'),
          type: 'success',
          autoHide: createNewAccount ? 10000 : 2000,
        },
      );
      setLoading(false);
    }
  }

  async function handleLoginWithSbf(e) {
    e.preventDefault();

    if (createNewAccount && !(showFedNrInput || sbfData)) {
      setShowFedNrInput(true);
      return;
    }

    if (createNewAccount && !(fedNrInput || sbfData)) {
      return;
    }

    if (createNewAccount) {
      if (!sbfData) {
        await prepareCreateAccountWithSbf({ displayName, fedNr: fedNrInput });
        location.href = 'https://www.svenskbridge.se/api/v1/auth/bp';
      } else {
        const error = await createAccountWithSbf({
          sbfData,
          displayName
        });
        if (error?.errorCode) {
          if (getErrorText(t, error.errorCode)) {
            setError({
              errorMessage: getErrorText(t, error.errorCode),
            });
          } else {
            setError({
              errorMessage: createNewAccount
                ? t('login.messages.create_account_error_default')
                : t('login.messages.login_error_default'),
            });
          }
        } else {
          notify(
            {
              text: createNewAccount
                ? t('login.messages.create_account_success')
                : t('login.messages.login_success'),
              type: 'success',
              autoHide: createNewAccount ? 10000 : 2000,
            },
          );
        }
      }
    } else {
      location.href = 'https://www.svenskbridge.se/api/v1/auth/bp';
    }
  }

  function handleUpdateEmail(e) {
    setEmail(e.target.value);
    setError(null);
  }

  const isSwedish = i18n.language === 'sv';

  return (
    <div
      className="fade-in rounded-t-box rounded-b-box
       top-px flex w-96 justify-center overflow-y-auto text-base-content"
    >
      <form onSubmit={handleSignInWithEmailAndPassword} className="pb-1">
        <div className="flex w-80 flex-col gap-5">
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">{t('app.email')}</span>
            </label>
            <input
              value={email}
              onChange={handleUpdateEmail}
              type="email"
              required
              disabled={loading}
              placeholder={t('login.email_placeholder')}
              className="input"
            />
          </div>

          {error && (
            <div className="flex w-full justify-center">
              <Alert
                onClick={() => setError()}
                text={error.errorMessage}
                severity="error"
              />
            </div>
          )}

          <AnimatePresence mode={"wait"}>
            {email.length === 0 ? (
              <Animated
                key="0auth"
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1 }}
                transition={{ duration: 0.1 }}
                sx="w-full"
              >
                <div className="divider">{t('login.or')}</div>
                <div className="flex flex-col gap-4">
                  {isSwedish && (
                    sbfData && (!createNewAccount || !sbfData.email) ? (
                      <span className="info">Du håller på att koppla ett konto till Sbf. Om du loggar in eller skapar ett konto kommer du att kunna logga in via Sbf:s inloggning.</span>
                    ) : (
                      <div className="flex flex-col">
                        <button
                          disabled={loading}
                          onClick={handleLoginWithSbf}
                          type="button"
                          className="text-md inline-flex items-center rounded-lg bg-sbf px-5 py-2.5 text-center font-medium text-white hover:bg-[#050708]/90 focus:outline-none focus:ring-4 focus:ring-[#050708]/50 dark:hover:bg-[#050708]/30 dark:focus:ring-[#050708]/50"
                        >
                          <img src={sbfLogo} alt="Sbf logga" className="h-4 mr-2 -ml-1" />
                      Logga in via Sbf
                        </button>
                        {showFedNrInput && (
                          <div className="form-control w-full">
                            <label className="label" htmlFor="fedNrInput">
                          Medlemsnummer (mid)
                            </label>
                            <input
                              id="fedNrInput"
                              value={fedNrInput}
                              onChange={(e) => setFedNrInput(e.target.value)}
                              className="input"
                            />
                          </div>
                        )}
                        {sbfData ? (
                          <span className="info">Du håller på att koppla ett konto till Sbf. Om du loggar in eller skapar ett konto kommer du att kunna logga in via Sbf:s inloggning.</span>
                        ) : (
                          <span className="info">Om du redan har ett Cuebids-konto men inte har kopplat det till Sbf loggar du först in som vanligt på Cuebids och kopplar kontot under Settings. Du kan sedan logga in på Cuebids via Sbf:s inloggning.</span>
                        )}
                      </div>
                    )
                  )}
                  <button
                    disabled={loading}
                    onClick={handleSignInWithGoogle}
                    type="button"
                    className="special text-md dark:focus:ring-[#4285F4]/55 inline-flex items-center rounded-lg bg-[#4285F4] px-5 py-2.5 text-center font-medium text-white hover:bg-[#4285F4]/90 focus:outline-none focus:ring-4 focus:ring-[#4285F4]/50"
                  >
                    <svg
                      className="mr-2 -ml-1 h-4 w-4"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="google"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 488 512"
                    >
                      <path
                        fill="currentColor"
                        d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                      ></path>
                    </svg>
                    {t('login.login_with_google')}
                  </button>
                  {
                    !android && (
                      <button
                        disabled={loading}
                        onClick={handleSignInWithApple}
                        type="button"
                        className="special text-md inline-flex items-center rounded-lg bg-[#050708] px-5 py-2.5 text-center font-medium text-white hover:bg-[#050708]/90 focus:outline-none focus:ring-4 focus:ring-[#050708]/50 dark:hover:bg-[#050708]/30 dark:focus:ring-[#050708]/50"
                      >
                        <svg
                          className="mr-2 -ml-1 h-5 w-5"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="apple"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                          ></path>
                        </svg>
                        {t('login.login_with_apple')}
                      </button>
                    )
                  }
                </div>
              </Animated>
            ) : (
              <Animated
                key="password"
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                sx="flex w-full flex-col gap-2"
              >
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">{t('app.password')}</span>
                  </label>
                  <div className="relative flex w-full flex-row items-center">
                    <input
                      value={password}
                      disabled={loading}
                      onChange={(e) => setPassword(e.target.value)}
                      type={showPassword ? 'text' : 'password'}
                      placeholder={t('login.password_placeholder')}
                      className="input-accent input w-full border-2 border-solid border-slate-200 bg-white text-black caret-secondary"
                    />
                    <div className="absolute right-4 w-8">
                      <button
                        type="button"
                        className="btn-ghost btn-square btn"
                        onClick={() => setShowPassword((x) => !x)}
                      >
                        <div className="w-8">
                          {showPassword ? (
                            <svg
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                              ></path>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                              ></path>
                            </svg>
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex w-full flex-row justify-between">
                  <div className="form-control">
                    <label className="label cursor-pointer">
                      <span className="label-text">
                        {t('login.remember_me')}
                      </span>
                      <input
                        type="checkbox"
                        disabled={loading}
                        className="checkbox-accent checkbox ml-2"
                        checked={remember}
                        onChange={updateRememberMe}
                      />
                    </label>
                  </div>
                  {!createNewAccount && (
                    <div className="form-control">
                      <label
                        className="label cursor-pointer"
                        onClick={handlePasswordReset}
                      >
                        <a className="label-text">
                          {t('login.forgot_password')}
                        </a>
                      </label>
                    </div>
                  )}
                </div>
                <div className="flex flex-col">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn-secondary btn w-full rounded-[10px] "
                  >
                    {createNewAccount
                      ? t('login.create_account')
                      : t('app.login')}
                  </button>
                </div>
              </Animated>
            )}
          </AnimatePresence>
        </div>
      </form>
    </div>
  );
}

function getDisplayNameError(displayName, t) {
  if (!displayName || isDisplayNameTooShort(displayName)) {
    return t('profile.errors.display_name_too_short');
  }

  if (doesDisplayNameHaveForbiddenCharacters(displayName)) {
    return t('profile.errors.display_name_forbidden_characters');
  }

  return '';
}

function CreateNewAccount({ fed, referral }) {
  const { t } = useTranslation();
  const [displayName, setDisplayName] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [referralCode, setReferralCode] = useState(referral || '');
  const [fedNr, setFedNr] = useState('');
  const [fedNrError, setFedNrError] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [agree, setAgree] = useState(false);

  const [step, setStep] = useState(1);

  function handleNextStep(e) {
    e.preventDefault();

    setHasSubmitted(true);

    if (displayNameError) {
      return;
    }

    const err = getDisplayNameError(displayName, t);
    if (err) {
      setDisplayNameError(err);
      return;
    }

    if (fed && fedNrError) {
      return
    }

    if (fed && fedNr.length < 1) {
      setFedNrError('Please fill in your membership number');
      return;
    }

    setStep(2);
  }

  function handleUpdateDisplayName(e) {
    const newValue = e.target.value.trimStart();
    setDisplayName(newValue);

    if (hasSubmitted) {
      setDisplayNameError(getDisplayNameError(newValue, t));
    }
  }

  function handleUpdateFedNr(e) {
    const newValue = e.target.value.trimStart();
    setFedNr(newValue);

    if (hasSubmitted) {
      setFedNrError(fedNr.length < 1 ? 'Please fill in your membership number' : '');
    }
  }

  function handleUpdateReferralCode(e) {
    const newValue = e.target.value.trim();
    setReferralCode(newValue);
  }

  return (
    <div className="flex w-96 flex-col items-center justify-center overflow-y-auto text-base-content">
      <ul className="steps mb-8">
        <li data-content="1" className="step-primary step cursor-pointer">
          <span className={step !== 1 ? 'opacity-50' : undefined}>
            {t('profile.display_name')}
          </span>
        </li>
        <li
          data-content="2"
          className={'step' + (step >= 2 ? ' step-primary' : '')}
        >
          <span className={step !== 2 ? 'opacity-50' : undefined}>
            {t('login.login_method_label')}
          </span>
        </li>
      </ul>
      {step === 1 && (
        <form className="mt-4 flex w-80 flex-col gap-5 pb-1">
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">{t('profile.display_name')}</span>
            </label>
            <input
              value={displayName}
              onChange={handleUpdateDisplayName}
              type="text"
              autoFocus
              required
              placeholder={t('login.display_name_placeholder')}
              className="input"
              aria-describedby={
                displayNameError ? 'username-helper-text' : undefined
              }
            />
            {displayNameError && (
              <span id="username-helper-text" className="mt-1 text-error">
                {displayNameError}
              </span>
            )}
            {fed && (
              <div className="form-control">
                <label className="label">
                  <span className="label-text">SBU membership number</span>
                </label>
                <input
                  value={fedNr}
                  onChange={handleUpdateFedNr}
                  type="text"
                  required
                  className="input"
                  aria-describedby={
                    fedNrError ? 'fednr-helper-text' : undefined
                  }
                />
                {fedNrError && (
                  <span id="fednr-helper-text" className="mt-1 text-error">
                    {fedNrError}
                  </span>
                )}
              </div>
            )}
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">
                  {t('login.read_terms')}
                  <Link to="/terms">
                    <span className="link">{t('login.terms')}</span>
                  </Link>
                </span>
                <input
                  type="checkbox"
                  className="checkbox-accent checkbox ml-2"
                  checked={agree}
                  onChange={(e) => setAgree(e.target.checked)}
                />
              </label>
            </div>
            {displayName && displayName.length > 2 && agree && (
                <>
                  <div className="form-control fade-in flex flex-row justify-center items-center mt-4">
                    <label className="label w-32 flex gap-2 m-4">
                      <span className="label-text">{t('login.referral_code_label')}</span>
                      <InfoPopup
                          id="referral-code-info"
                          anchorElement={<InfoIcon />}
                      >
                        {t('login.referral_code_info')}
                      </InfoPopup>
                    </label>

                    <input
                        value={referralCode}
                        onChange={handleUpdateReferralCode}
                        type="text"
                        placeholder={t('login.referral_code_placeholder')}
                        required
                        className="input input-xs w-32 h-8"
                    />
                  </div>
                </>
            )}
          </div>
          <div className="flex flex-col">
            <button
              disabled={displayName.length < 3 || !agree || displayNameError || (fed && fedNrError)}
              onClick={handleNextStep}
              className="btn-secondary btn w-full rounded-[10px]"
            >
              {t('app.next')}
            </button>
          </div>
        </form>
      )}
      {step === 2 && <LoginMethodSelect displayName={displayName} fed={fed} fedNr={fedNr} referral={referralCode} />}
    </div>
  );
}

function ExplainFed({ onClose }) {
  return (
    <div className='absolute z-[999999] w-[95%] md:w-4/5 top-20 h-[550px] right-0 m-auto left-0 bg-base-100 border-diamond-50 border rounded-md p-4'>
      <article className='prose'>
        <h1>Cuebids and SBU</h1>
        <h2 className='mt-2 mb-2'>Connect your account</h2>
        <p>You can connect your Cuebids account to SBU by entering your SBU membership number</p>
        <h2 className='mt-2 mb-2'>Create an account</h2>
        <p>You can create a Cuebids account in multiple ways. Either using email/password or by logging in using Google or Apple.</p>
        <p>When you create your account you can enter your SBU membership number</p>
        <h2 className='mt-2 mb-2'>I already have an account</h2>
        <p>Then just log in and you can connect to SBU immediately!</p>
      </article>
      <div className='flex items-center justify-around mt-4'>
        <button className='btn btn-primary' onClick={onClose}>
          <div className='flex w-full gap-2 items-center justify-center'>
            Got it! <Checkbox checked={true} />
          </div>
        </button>
      </div>
    </div>
  );
}

function Landing({ setStep, fed }) {
  const { t, i18n } = useTranslation();
  const [introStep, setIntroStep] = useState('landing');
  const [shouldExplainFed, setShouldExplainFed] = useState(false);
  const [counter, setCounter] = useState(0);
  const setLanguage = useAppStateStore((state) => state.setLanguage);

  const mountLanguage = useMemo(() => {
    return localStorage.getItem('language');
  }, []);

  const preferredLanguages = getBrowserPreferredLanguages();

  const optionsAvailable = preferredLanguages.length > 1;
  const alreadySelectedOption = fed !== 'sbu' && !mountLanguage;

  const shouldShowLanguageDropdown = optionsAvailable && alreadySelectedOption;

  function handleShowBidding () {
    setCounter((c) => c + 1);
    setIntroStep('bidding')
  }

  if (shouldExplainFed) {
    return <ExplainFed onClose={() => setShouldExplainFed(false)} />;
  }

  if (introStep !== 'landing') {
    return (
      <div className='absolute z-[999999] w-[95%] md:w-4/5 top-20 h-[550px] right-0 m-auto left-0 bg-base-100 border-primary border rounded-md p-4'>
        {introStep === 'bidding' ? (
          <div className="h-64 relative">
            <div className="scale-75 origin-top-left md:origin-top">
              <SessionDealMock key={counter} id={counter} />
            </div>
            <div
              className="absolute top-0 right-0"
              onClick={() => setIntroStep('start')}
            >
              <CloseButton />
            </div>
          </div>
        ) : (
          <>
            <article className="prose">
              <h1>{t('login.intro.title')}</h1>
              <h2 className="mt-2 mb-2">{t('login.intro.practice_title')}</h2>
              <h4>
                  <strong className="underline decoration-amber-500">
                    {t('login.intro.practice_p1_styled')}
                  </strong>
                  {t('login.intro.practice_p1_post_styled')}
              </h4>
              <p>{t('login.intro.practice_p2')}</p>
              <h2 className="mt-2 mb-2">{t('login.intro.learn_title')}</h2>
              <p>
                {t('login.intro.learn_p1_pre_styled')}
                <strong className="underline decoration-amber-500">
                  {t('login.intro.learn_p1_styled')}
                </strong>
                {t('login.intro.learn_p1_post_styled')}
              </p>
              <p>
                {t('login.intro.learn_p2_pre_styled')}
                <strong className="underline decoration-amber-500">
                  {t('login.intro.learn_p2_styled')}
                </strong>{' '}
                {t('login.intro.learn_p2_post_styled')}
              </p>
              <h3 className="mt-2">
                {t('login.intro.free_pre_styled')}
                <strong className="underline decoration-amber-500">{t('login.intro.free_styled')}</strong>
              </h3>
            </article>
            <div className='flex items-center justify-around mt-4'>
              <button className='btn btn-sm btn-outline' onClick={handleShowBidding}><div className='flex items-center gap-3'>{t('login.intro.example')} <InfoIcon className={'fill-info'} /></div></button>
              <button className='btn btn-primary' onClick={() => setIntroStep('landing')}>
                <div className='flex w-full gap-2 items-center justify-center'>
                  {t('login.intro.confirm')} <Checkbox checked={true} />
                </div>
              </button>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <Animated initial={{ opacity: 1 }} animate={{ opacity: 1 }} sx="pb-1">
      {
      shouldShowLanguageDropdown && (
        <LanguageDropdown
          language={i18n.language}
          handleChangeLanguage={setLanguage}
        />
      )}

      <div className="flex flex-col items-center">
        <button
          className="btn btn-ghost btn-wide"
          onClick={() => setIntroStep('start')}
        >
          <div className="flex w-full gap-2 items-center justify-center">
            {t('login.intro_button')} <PlayIcon/>
          </div>
        </button>
        {fed && (
          <button
            className="btn btn-ghost btn-wide"
            onClick={() => setShouldExplainFed(true)}
          >
            How to connect to SBU?
          </button>
        )}
      </div>
      {import.meta.env.DEV && import.meta.env.MODE === 'prod' && (
        <div style={{ marginTop: '20px' }}>
          <SignInWithCustomToken />
        </div>
      )}
      <div className="mt-4 sm:mt-10 flex flex-col gap-4">
        <button
          className="btn-primary btn w-64 rounded-[10px] text-white"
          onClick={() => setStep('loginMethodSelect')}
        >
          {t('app.login')}
        </button>
        <div className="flex flex-col">
          <span> {t('login.not_member')}</span>
          <button
            onClick={() => setStep('createNewAccount')}
            className="btn-secondary btn w-64 rounded-[10px]"
          >
            {t('login.create_free_account')}
          </button>
        </div>
      </div>
    </Animated>
  );
}

function HasConnectedToFed() {
  return (
    <div className="flex flex-col items-center mt-4">
        You are connected to SBU!
      <Link
        to="/"
        className="btn-primary btn w-full rounded-[10px] mt-4"
      >
          To start page
      </Link>
    </div>
  );
}

function ConnectToFed({
  fed
}) {
  const [fedNr, setFedNr] = useState('');
  const [fedNrError, setFedNrError] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [shouldExplainFed, setShouldExplainFed] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useAppStateStore(state => state.user);

  if (shouldExplainFed) {
    return <ExplainFed onClose={() => setShouldExplainFed(false)} />;
  }

  if (!user) {
    return null;
  }

  if (user?.fedNr) {
    if (shouldShowOnboarding({ user })) {
      return <Navigate to="/" />;
    } else {
      return <HasConnectedToFed/>;
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setHasSubmitted(true);

    if (fedNrError) {
      return;
    }

    if (fedNr.length < 1) {
      setFedNrError('Please fill in your membership number');
      return;
    }

    setLoading(true);
    await updateUser({ fedNr, fed });
    setLoading(false);
  }

  function handleUpdateFedNr(e) {
    const newValue = e.target.value.trimStart();
    setFedNr(newValue);

    if (hasSubmitted) {
      setFedNrError(fedNr.length < 1 ? 'Please fill in your membership number' : '');
    }
  }

  return (
    <div className="pb-8">
      <button className='btn btn-ghost btn-wide' onClick={() => setShouldExplainFed(true)}>
        How to connect to SBU?
      </button>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">SBU membership number</span>
        </label>
        <input
          value={fedNr}
          onChange={handleUpdateFedNr}
          type="text"
          required
          className="input"
          aria-describedby={
            fedNrError ? 'fednr-helper-text' : undefined
          }
        />
        {fedNrError && (
          <span id="fednr-helper-text" className="mt-1 text-error">
            {fedNrError}
          </span>
        )}
      </div>
      <div className="flex flex-col mt-4">
        <button
          disabled={loading}
          onClick={handleSubmit}
          className="btn-primary btn w-full rounded-[10px]"
        >
          Submit
        </button>
        <Link
          disabled={loading}
          to="/"
          className="btn-secondary btn w-full rounded-[10px] mt-4"
        >
          Skip connect to SBU
        </Link>
      </div>
    </div>
  );
}

export default function Login() {
  const [step, setStep] = useState('landing');
  const { fed, referral } = useParams();

  const { isVerified } = useAuth();
  const { t } = useTranslation();

  if(referral && step === 'landing') {
    setStep('createNewAccount');
  }

  const isLoggedIn = isVerified();

  if (fed && fed.toLowerCase() !== 'sbu') {
    return <Navigate to="/login" />;
  }

  return (
    <div id="login-page" className="flex flex-col justify-between pb-[100px]">
      <div className="page">
        {fed ? (
          <div className='flex items-center justify-center gap-2'>
            <Animated
              element="img"
              initial={{ width: 100 }}
              animate={{ width: step === 'landing' ? 100 : 50 }}
              src={Logo}
              sx={'w-[100px]'}
              style={{ flexGrow: 1, marginBottom: 20 }}
              alt="logo"
            />
            <Animated
              element="img"
              initial={{ width: 100 }}
              animate={{ width: step === 'landing' ? 100 : 40 }}
              src={sbu}
              sx={'w-[100px]'}
              style={{ marginBottom: 20 }}
              alt="logo"
            />
          </div>
        ) : (
          <Animated
            element="img"
            initial={{ width: 150 }}
            animate={{ width: step === 'landing' ? 150 : 50 }}
            src={Logo}
            sx={'w-[150px]'}
            style={{ flexGrow: 1, marginBottom: 20 }}
            alt="logo"
          />
        )}
        <AdminMessage />
        <div className="flex justify-center">
          {isLoggedIn ? <ConnectToFed fed={fed} /> : (
            <>
              {step === 'landing' && <Landing key="landing" setStep={setStep} fed={fed} />}
              {step === 'loginMethodSelect' && <LoginMethodSelect key="login" />}
              {step === 'createNewAccount' && (
                <CreateNewAccount key="create" fed={fed} referral={referral} />
              )}
            </>
          )}
        </div>
      </div>
      {
        step === 'landing' &&
      <footer className="fixed bottom-0 -z-[1] flex w-full items-start justify-between rounded-t-md slated px-4 py-2 pb-24 md:w-[800px] md:p-8 md:py-4 md:pb-32">
        <div className="flex flex-col">
          <span className="footer-title">{t('login.footer.contact')}</span>
          <a href="mailto:support@cuebids.com" className="link-hover link">
            support@cuebids.com
          </a>
          <a
            target="_blank"
            href="https://discord.gg/U75379cD3G?:ext"
            className="link-hover link"
            rel="noreferrer"
          >
            {t('login.footer.discord')}
          </a>
        </div>
        <div className="flex flex-col">
          <span className="footer-title">{t('login.footer.legal')}</span>
          <Link to="/terms">
            <span className="link-hover link">{t('login.footer.terms_of_service')}</span>
          </Link>
          <Link to="/privacy">
            <span className="link-hover link">{t('login.footer.privacy_policy')}</span>
          </Link>
        </div>
      </footer>
      }
    </div>
  );
}
