import { useEffect, useState } from 'react';
import {
  addGift,
  createCheckoutSession, getAllProducts, setGiftPaid,
  updateGift
} from 'cuebids-firebase/store';
import { useAppStateStore } from '../../appStateStore.jsx';
import { Link, useSearchParams } from 'react-router-dom'
import { useAuth } from '../../util/hooks.jsx';
import Diamond from '../../images/suits/Diamond.jsx';
import Heart from '../../images/suits/Heart.jsx';
import useNotifications from '../../components/notifications/useNotifications.jsx';

export default function GiftSub() {
  const [step, setStep] = useState(1);
  const { currentUser } = useAuth();
  const [yourName, setYourName] = useState(currentUser?.displayName || '');
  const [yourEmail, setYourEmail] = useState(currentUser?.email || '');
  const [searchParams] = useSearchParams();
  const [productMap, setProductMap] = useState({});

  const status = searchParams.get('status');
  const giftId = searchParams.get('g');

  const [friendName, setFriendName] = useState('');
  const [friendEmail, setFriendEmail] = useState('');

  const [subscription, setSubscription] = useState('heart');

  const [message, setMessage] = useState('');

  const [checkoutSession, setCheckoutSession] = useState();

  const notify = useNotifications();

  async function handleNextStep(e) {
    e.preventDefault();
    setStep((s) => s + 1);
    if(step === 3) {
      const ref = await addGift({
        checkoutSessionId: '',
        name: yourName,
        email: yourEmail,
        friendName,
        friendEmail,
        message
      });
      if(!ref) {
        notify(
          {
            type: 'error',
            text: 'Something went wrong, check your email address.',
          },
        );
        return;
      }
      const data = await createCheckoutSession(
        {
          success_url: window.location.origin + '/gift?status=success&g=' + ref.id,
          cancel_url: window.location.origin + '/gift?status=failure',
          customer_email: yourEmail,
          line_items: [
            {
              price: productMap[subscription],
              quantity: 1
            }
          ],
          mode: 'payment'
        }
      )
      if(data?.url) {
        await updateGift({ ref, checkoutSessionId: data.id });
        setCheckoutSession(data);
      } else {
        notify(
          {
            type: 'error',
            text: 'Something went wrong, check your email address.',
          },
        );
      }
    }
  }

  const localCreateCheckoutSession = async () => {
    if (checkoutSession) {
      window.location.href = checkoutSession.url
    }
  }

  useEffect(() => {
    async function getProducts() {
      const products = await getAllProducts();
      try {
        setProductMap({
          'heart': products.find(p => p.name === 'Heart Gift').prices[0].id,
          'diamond': products.find(p => p.name === 'Diamond Gift').prices[0].id,
        })
      } catch (e) {
        notify(
          {
            type: 'error',
            text: 'Something went wrong.',
          }
        )
      }

    }
    getProducts();
  }, [notify]);

  useEffect( () => {
    if (status === 'failure') {
      notify(
        {
          type: 'error',
          text: 'Something went wrong.',
        },
      );
      return;
    }
    if (!giftId) {
      return;
    }
    setGiftPaid(giftId).then(() => {
      if (status === 'success') {
        notify(
          {
            type: 'success',
            text: 'What a great gift! We will be in touch shortly to confirm when to activate the subscription.',
          },
        );
      }
    });

  }, [notify, giftId, status]);

  if (status === 'success') {
    return (
      <div className="page p-4">
        <article className="prose w-full">
          <h1>Success!</h1>
          <h3>We wish you a happy holiday season.</h3>
          <h5>We will be in touch shortly to confirm when to activate the subscription.</h5>
        </article>
        <Link className="btn-primary btn mt-10" to="/">
          To start page
        </Link>
      </div>
    )
  }

  return (
    <div className="page p-4">
      <article className="prose w-full">
        <h1>Give the Gift of Bridge</h1>
        <h5>Show your love during this holiday season by <br /> gifting a Cuebids subscription to a friend.</h5>
        <div className={'not-prose flex flex-col items-center justify-center mt-4 w-full'}>
          <ul className="steps mb-8 w-full">
            <li data-content="1" className="step-primary step cursor-pointer" onClick={
              () => step > 1 && setStep(1)
            }
            >
              <span className={step !== 1 ? 'opacity-50' : undefined}>
              Your details
              </span>
            </li>
            <li
              data-content="2"
              className={'step' + (step >= 2 ? ' step-primary  cursor-pointer' : '')}
              onClick={
                () => step > 2 && setStep(2)
              }
            >
              <span className={step !== 2 ? 'opacity-50' : undefined}>
              Friend details
              </span>
            </li>
            <li
              data-content="3"
              className={'step' + (step >= 3 ? ' step-primary  cursor-pointer' : '')}
              onClick={
                () => step > 3 && setStep(3)
              }
            >
              <span className={step !== 3 ? 'opacity-50' : undefined}>
              Subscription
              </span>
            </li>
            <li
              data-content="4"
              className={'step' + (step >= 4 ? ' step-primary' : '')}
            >
              <span className={step !== 4 ? 'opacity-50' : undefined}>
              Payment
              </span>
            </li>
          </ul>
          <div>
            {
              step === 1 &&
                  <form className="mt-4 flex w-80 flex-col gap-5 pb-1">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Your name</span>
                      </label>
                      <input
                        value={yourName}
                        onChange={(e) => setYourName(e.target.value)}
                        type="text"
                        autoFocus
                        required
                        placeholder="Your name"
                        className="input"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Your email</span>
                      </label>
                      <input
                        value={yourEmail}
                        onChange={(e) => setYourEmail(e.target.value)}
                        type="text"
                        required
                        placeholder="Your email"
                        className="input"
                      />
                    </div>
                    <button
                      type={'button'}
                      disabled={yourName.length < 3 || yourEmail.length < 3}
                      onClick={handleNextStep}
                      className="btn-secondary btn w-full rounded-[10px]"
                    >
                      Next
                    </button>
                  </form>
            }
            {
              step === 2 &&
                  <form className="mt-4 flex w-80 flex-col gap-5 pb-1">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Friend&apos;s name</span>
                      </label>
                      <input
                        value={friendName}
                        onChange={(e) => setFriendName(e.target.value)}
                        type="text"
                        autoFocus
                        required
                        placeholder="Friend's name"
                        className="input"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Friend&apos;s email</span>
                      </label>
                      <input
                        value={friendEmail}
                        onChange={(e) => setFriendEmail(e.target.value)}
                        type="text"
                        required
                        placeholder="Friend's email"
                        className="input"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span
                          className="label-text">Message</span>
                      </label>
                      <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Write a message to your friend"
                        className="input h-32 p-4"
                        rows={4}
                      />
                    </div>
                    <button
                      type={'button'}
                      disabled={friendName.length < 3 || friendEmail.length < 3}
                      onClick={handleNextStep}
                      className="btn-secondary btn w-full rounded-[10px] mt-4"
                    >
                      Next
                    </button>
                  </form>
            }
            {
              step === 3 &&
                <form className="mt-4 flex w-80 flex-col gap-5 pb-1">
                  <div className="form-control w-full">
                    <label className="label">
                      <span className="label-text">Subscription</span>
                    </label>
                    <div className={'flex items-center justify-center gap-2'}>
                      <div
                        className={`w-36 h-40 rounded ${subscription === 'heart' ? 'border-2' : 'border border-opacity-40'} border-heart-50`}
                        onClick={
                          () => setSubscription('heart')
                        }>
                        <h2 className="text-xl text-heart-50 flex gap-4 m-4">
                          Heart <Heart width={24}/>
                        </h2>
                        <p className={'mx-4 mb-2'}>150 USD</p>
                        <p className={'info mx-2'}>One time payment</p>
                        <p className={'info mx-2'}>Last for a year</p>
                      </div>
                      <div
                        className={`w-36 h-40 rounded ${subscription === 'diamond' ? 'border-2' : 'border border-opacity-40'} border-diamond-50`}
                        onClick={
                          () => setSubscription('diamond')
                        }>
                        <h2 className="text-xl text-diamond-50 flex gap-4 m-4">
                          Diamond <Diamond width={24}/>
                        </h2>
                        <p className={'mx-4 mb-2'}>40 USD</p>
                        <p className={'info mx-2'}>One time payment</p>
                        <p className={'info mx-2'}>Last for a year</p>
                      </div>
                    </div>


                    <button
                      type={'button'}
                      disabled={friendName.length < 3 || friendEmail.length < 3}
                      onClick={handleNextStep}
                      className="btn-secondary btn w-full rounded-[10px] mt-4"
                    >
                    Next
                    </button>

                  </div>
                </form>
            }
            {
              step === 4 &&
                <form className="mt-4 flex w-80 flex-col gap-5 pb-1">
                  <div className="form-control w-full">
                    <div className="form-control w-full  m-4">

                      <span
                        className="label-text text-heart-50">Double check your email</span>
         
                      {yourEmail}
                    </div>
                    <div className="form-control w-full m-4">

                      <span
                        className="label-text text-heart-50">Double check your friends email</span>

                      {friendEmail}
                    </div>
                    <p className={'info'}>We will <strong>not</strong> automatically send an email to your friend. We
                      will first
                      contact you to confirm when the subscription should be activated.</p>
                    <button
                      type={'button'}
                      disabled={!checkoutSession}
                      onClick={localCreateCheckoutSession}
                      className="btn-primary btn w-full rounded-[10px] mt-4"
                    >
                      Confirm and pay
                    </button>
                  </div>
                </form>
            }
          </div>
        </div>

      </article>
    </div>
  );
}
