export const supportedLanguages = [
  'en',
  'sv',
];

export const supportedLanguagesDev = [
  'en',
  'sv',
  'pl'
];

export function getSupportedLanguages() {
  if (import.meta.env.DEV || import.meta.env.MODE === 'dev') {
    return supportedLanguagesDev;
  }
  return supportedLanguages;
}

export const defaultLanguage = 'en';

function removeDuplicates(arr) {
  return Array.from(new Set([...arr])); // TODO: Is this guaranteed to preserve order?
}

function removeUnsupportedLanguages(languages) {
  return languages.filter(function (lang) {
    return supportedLanguages.includes(lang);
  })
}

export function getBrowserPreferredLanguages() {
  const navigatorLanguages = [...(navigator?.languages ?? [])];
  navigatorLanguages.push(defaultLanguage); // TODO: Is this bad? For many cases it will be nice to assume that we always have our default language here
  return removeUnsupportedLanguages(removeDuplicates(navigatorLanguages.map(function (lang) {
    if (lang.includes('-')) {
      return lang.split('-')[0];
    }
    return lang;
  })));
}

export function getBrowserPreferredLanguage() {
  return getBrowserPreferredLanguages()[0] ?? defaultLanguage;
}
