import { type PBNGame } from './pbn.types'

export function parsePBN (pbnData: string): PBNGame {
  const game: PBNGame = {
    dealer: '',
    vulnerable: '',
    deal: '',
    commentary: [],
    auction: {
      calls: [],
      annotations: []
    }
  }
  // comment

  let currentSection: string = ''

  const lines = pbnData.split('\n')

  const regex = /{([^}]*)}/g
  const commentary: string[] = []
  let match

  while ((match = regex.exec(pbnData)) !== null) {
    commentary.push(match[1].trim())
  }

  game.commentary = commentary

  for (let line of lines) {
    line = line.trim()
    if (line == null || line === '') continue

    if (line.startsWith('[')) {
      const tagPair = parseTagPair(line)
      currentSection = tagPair.tag
      applyTagToGame(game, tagPair)
    } else {
      if (currentSection === 'Auction') {
        line.split(' ').forEach((call) => {
          game.auction.calls.push(call)
        })
      }
    }
  }

  return game
}

function parseTagPair (line: string): { tag: string, value: string } {
  // Remove the brackets and split the line at the first space
  const tagContent = line.slice(1, -1).trim()
  const spaceIndex = tagContent.indexOf(' ')

  const tag = tagContent.substring(0, spaceIndex)
  const value = tagContent.substring(spaceIndex + 1).replace(/"/g, '') // Remove quotes

  return { tag, value }
}

function applyTagToGame (game: PBNGame, tagPair: { tag: string, value: string }): void {
  switch (tagPair.tag) {
    case 'Dealer':
      game.dealer = tagPair.value
      break
    case 'Vulnerable':
      game.vulnerable = tagPair.value
      break
    case 'Deal':
      game.deal = tagPair.value
      break
    default:
    // Handle unknown tags or log them
      break
  }
}
