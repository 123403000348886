import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import './animation.css';
import { useAppStateStore } from '../../appStateStore.jsx';

function AnimatedInternal(
  {
    element = 'div',
    children,
    sx,
    animate,
    initial,
    exit,
    transition,
    onAnimationComplete,
    ...props
  },
  ref
) {
  const user = useAppStateStore((state) => state.user);
  const allowAnimations = user?.allowAnimations;
  const Element = element;

  if (!allowAnimations && allowAnimations !== undefined) {
    return (
      <Element
        ref={ref}
        {...props}
        onAnimationEnd={onAnimationComplete}
        className={
          (initial?.opacity === 0 ? 'fade-in ' : '') +
          (sx ?? '') +
          (props.className ?? '')
        }
      >
        {children}
      </Element>
    );
  }

  const MotionElement = motion[element];

  return (
    <MotionElement
      ref={ref}
      className={sx ?? ''}
      animate={animate}
      initial={initial}
      exit={exit}
      transition={transition}
      onAnimationComplete={onAnimationComplete}
      {...props}
    >
      {children}
    </MotionElement>
  );
}

const Animated = forwardRef(AnimatedInternal);

export default Animated;
