import Constraints from '../../components/constraints/constraints';

export default function CreateDealType() {

  return (
    <div className="page pb-24">
      <div className='w-full flex items-center justify-center'>
        <Constraints />
      </div>
    </div>
  );
}
