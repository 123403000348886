import { useEffect, useState } from 'react';
import { useAppStateStore } from '../appStateStore.jsx';

function DebugDisplay() {
  const [memoryUsage, setMemoryUsage] = useState({
    usedJSHeapSize: 0,
    totalJSHeapSize: 0,
    jsHeapSizeLimit: 0,
  });

  const [eventCount, setEventCount] = useState(0);
  const [redirectCount, setRedirectCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const { memory, navigation, eventCounts } = window?.performance ?? {
        memory: {},
        navigation: {
          redirectCount: 0,
        },
        eventCounts: {
          size: 0,
        },
      };

      setMemoryUsage({
        usedJSHeapSize: (memory.usedJSHeapSize / 1000000).toFixed(2),
        totalJSHeapSize: (memory.totalJSHeapSize / 1000000).toFixed(2),
        jsHeapSizeLimit: (memory.jsHeapSizeLimit / 1000000).toFixed(2),
      });

      setEventCount(eventCounts.size);
      setRedirectCount(navigation.redirectCount);
    }, 1000); // Update every 1000 milliseconds (1 second)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return <div
    className={'fixed bottom-0 h-12 bg-black text-white p-2 flex gap-4'}>
    <span>
      Memory: <span className={'text-red-400 font-bold'}>
        {memoryUsage.usedJSHeapSize}</span> MB /
      {memoryUsage.totalJSHeapSize } MB (Limit: {memoryUsage.jsHeapSizeLimit} MB)
    </span>
    <span>
      Events: <span className={'text-red-400 font-bold'}>{
        (eventCount)
      }</span></span>
    <span>
      Redirects: <span className={'text-red-400 font-bold'}>{
        (redirectCount)
      }</span></span>
  </div>
}

export function Debug() {
  const user = useAppStateStore((state) => state.user);
  if (!user?.debug) {
    return;
  }
  return <DebugDisplay />
}
