export const options = {
  five_card_major: {
    'description': '5 card major',
    'id': 'five_card_major',
    'default': true,
    'falseConstraints': [
      {
        'name': 'spades',
        'args': [2,4],
      }, {
        'name': 'hearts',
        'args': [2,4],
      }
    ],
    'constraints': []
  },
  six_card_minor: {
    'description': '6 card minor',
    'id': 'six_card_minor',
    'default': false,
    'falseConstraints': [],
    'constraints': [
      {
        'name': 'shapes',
        'args': ['6322', '!=6xxx', '!=x6xx'],
      }
    ]
  },
  four_card_diamonds: {
    'description': '4 card diamonds',
    'id': 'four_card_diamonds',
    'default': true,
    'falseConstraints': [
      {
        'name': 'diamonds',
        'args': [0,3],
      }
    ],
    'constraints': [
      {
        'name': 'diamonds',
        'args': [4,13],
      }
    ]
  },
}

const withOption = (handType, option, overrideValue) => {
  if (overrideValue !== undefined) {
    option.default = overrideValue;
  }
  return {
    ...handType,
    optional: [{ ...option }]
  };
};

const withLabel = (handType, label) => {
  return {
    ...handType,
    label,
  };
}

const withExtraConstraints = (handType, constraints) => {
  const handTypeConstraints = [...handType.constraints].filter(function (c1) {
    return !constraints.some(function (c2) {
      return c2.name === c1.name;
    });
  });
  return {
    ...handType,
    constraints: [...handTypeConstraints, ...constraints]
  };
};

const withConstraintsFromOption = (handType, option, value) => {
  const { optional, ...rest } = handType;
  return {
    ...rest,
    constraints: [...handType.constraints, ...(value ? [...option.constraints] : [...option.falseConstraints])]
  };
};

function createHandTypeCopy(handType) {
  return JSON.parse(JSON.stringify(handType));
}

function withExtraShapes(handType, shapes) {
  const newHandType = createHandTypeCopy(handType);
  let shapeConstraint = newHandType.constraints.find(c => c.name === 'shapes');

  if (!shapeConstraint) {
    shapeConstraint = { name: 'shapes', args: [] };
    newHandType.constraints.push(shapeConstraint);
  }

  for (const shape of shapes) {
    if (!shapeConstraint.args.includes(shape)) {
      shapeConstraint.args.push(shape);
    }
  }

  return newHandType;
}

function removeShapes(handType, shapes) {
  const newHandType = createHandTypeCopy(handType);
  let shapeConstraint = newHandType.constraints.find(c => c.name === 'shapes');

  if (shapeConstraint) {
    for (const shape of shapes) {
      const index = shapeConstraint.args.indexOf(shape);
      if (index !== -1) {
        shapeConstraint.args.splice(index, 1);
      }
    }
  }

  return newHandType;
}

function removeOptional(handType) {
  const res = { ...handType };
  delete res.optional;
  return res;
}

export const handTypes = {
  strong: {
    name: 'strong',
    label: 'strong',
    type: 'handType',
    display: false,
    constraints: [
      {
        'name': 'hp_range',
        'args': [17,40]
      }
    ],
  },
  balanced: {
    name: 'balanced',
    label: 'balanced',
    type: 'handType',
    display: true,
    constraints: [
      {
        'name': 'shapes',
        'args': ['4333', '4432', '5332']
      }
    ],
    optional: [ options.five_card_major, options.six_card_minor ]
  },
  naturalClubs: {
    name: 'naturalClubs',
    label: 'naturalClubs',
    type: 'handType',
    display: true,
    constraints: [
      {
        'name': 'shapes',
        'args': ['5431', '5440', '5422', '=xxx6', '=xxx7', '=xxx8']
      },
      {
        'name': 'clubs',
        'args': [5,8]
      },
      {
        'name': 'hearts',
        'args': [0,5]
      },
      {
        'name': 'spades',
        'args': [0,5]
      },
      {
        'name': 'diamonds',
        'args': [0,5]
      }
    ],
  },
  naturalClubs6: {
    name: 'naturalClubs6',
    label: 'naturalClubs6',
    type: 'handType',
    display: true,
    constraints: [
      {
        'name': 'shapes',
        'args': ['=xxx6', '=xxx7', '=xxx8']
      },
      {
        'name': 'hearts',
        'args': [0,5]
      },
      {
        'name': 'spades',
        'args': [0,5]
      },
      {
        'name': 'diamonds',
        'args': [0,5]
      }
    ],
  },
  natClubs6: {
    name: 'natClubs6',
    label: 'natClubs6',
    type: 'handType',
    display: false,
    constraints: [
      {
        'name': 'clubs',
        'args': [6,6]
      },
      {
        'name': 'hearts',
        'args': [0,4]
      },
      {
        'name': 'spades',
        'args': [0,4]
      },
      {
        'name': 'diamonds',
        'args': [0,4]
      },
      {
        'name': 'suit_quality',
        'args': [3,5]
      },
    ],
  },
  marmicWithClubs: {
    name: 'marmicWithClubs',
    label: 'marmicWithClubs',
    type: 'handType',
    constraints: [
      {
        'name': 'shapes',
        'args': ['=4414']
      },
    ],
  },
  naturalDiamonds: {
    name: 'naturalDiamonds',
    label: 'naturalDiamonds',
    type: 'handType',
    display: true,
    constraints: [
      {
        'name': 'shapes',
        'args': ['5431', '5440', '5422', '=xx6x', '=xx7x', '=xx8x', '=xx55']
      },
      {
        'name': 'clubs',
        'args': [0,5]
      },
      {
        'name': 'hearts',
        'args': [0,5]
      },
      {
        'name': 'spades',
        'args': [0,5]
      },
      {
        'name': 'diamonds',
        'args': [5,8]
      }
    ],
  },
  marmicWithDiamonds: {
    name: 'marmicWithDiamonds',
    label: 'marmicWithDiamonds',
    type: 'handType',
    constraints: [
      {
        'name': 'shapes',
        'args': ['4441']
      },
      {
        'name': 'diamonds',
        'args': [4, 4]
      },
    ],
  },
  naturalHearts: {
    name: 'naturalHearts',
    label: 'naturalHearts',
    type: 'handType',
    display: true,
    constraints: [
      {
        'name': 'shapes',
        'args': ['5431', '5440', '5422', '=x6xx', '=x7xx', '=x8xx', '55xx', '!=55xx', '=56xx']
      },
      {
        'name': 'clubs',
        'args': [0,6]
      },
      {
        'name': 'hearts',
        'args': [5,8]
      },
      {
        'name': 'spades',
        'args': [0,5]
      },
      {
        'name': 'diamonds',
        'args': [0,6]
      }
    ],
  },
  naturalHearts4: {
    name: 'naturalHearts4',
    label: 'naturalHearts4',
    type: 'handType',
    display: false,
    constraints: [
      {
        'name': 'shapes',
        'args': ['=x4xx', '!=54xx', '!=x45x', '!=x4x5', '=x5xx', '!=55xx', '=x6xx', '=x7xx', '=x8xx']
      },
      {
        'name': 'clubs',
        'args': [0,5]
      },
      {
        'name': 'hearts',
        'args': [4,8]
      },
      {
        'name': 'spades',
        'args': [0,5]
      },
      {
        'name': 'diamonds',
        'args': [0,5]
      }
    ],
  },
  naturalSpades4: {
    name: 'naturalSpades4',
    label: 'naturalSpades4',
    type: 'handType',
    display: false,
    constraints: [
      {
        'name': 'shapes',
        'args': ['=4xxx', '!=45xx', '!=4x5x', '!=4xx5', '=5xxx', '=6xxx', '=7xxx', '=8xxx']
      },
      {
        'name': 'clubs',
        'args': [0,5]
      },
      {
        'name': 'hearts',
        'args': [0,5]
      },
      {
        'name': 'spades',
        'args': [4,8]
      },
      {
        'name': 'diamonds',
        'args': [0,5]
      }
    ],
  },
  naturalSpades: {
    name: 'naturalSpades',
    label: 'naturalSpades',
    type: 'handType',
    display: true,
    constraints: [
      {
        'name': 'shapes',
        'args': ['=5xxx', '=6xxx', '=7xxx', '=8xxx', '!=56xx', '!=5x6x', '!=5xx6']
      },
      {
        'name': 'clubs',
        'args': [0,6]
      },
      {
        'name': 'hearts',
        'args': [0,6]
      },
      {
        'name': 'spades',
        'args': [5,8]
      },
      {
        'name': 'diamonds',
        'args': [0,6]
      }
    ],
  },
  natDiamonds6: {
    name: 'natDiamonds6',
    label: 'natDiamonds6',
    type: 'handType',
    display: false,
    constraints: [
      {
        'name': 'clubs',
        'args': [0,4]
      },
      {
        'name': 'hearts',
        'args': [0,4]
      },
      {
        'name': 'spades',
        'args': [0,4]
      },
      {
        'name': 'diamonds',
        'args': [6,6]
      },
      {
        'name': 'suit_quality',
        'args': [3,5]
      },
    ],
  },
  natHeart6: {
    name: 'natHeart6',
    label: 'natHeart6',
    type: 'handType',
    display: false,
    constraints: [
      {
        'name': 'clubs',
        'args': [0,4]
      },
      {
        'name': 'hearts',
        'args': [6,6]
      },
      {
        'name': 'spades',
        'args': [0,4]
      },
      {
        'name': 'diamonds',
        'args': [0,4]
      },
      {
        'name': 'suit_quality',
        'args': [3,5]
      },
    ],
  },
  natSpade6: {
    name: 'natSpades6',
    label: 'natSpades6',
    type: 'handType',
    display: false,
    constraints: [
      {
        'name': 'clubs',
        'args': [0,4]
      },
      {
        'name': 'hearts',
        'args': [0,4]
      },
      {
        'name': 'spades',
        'args': [6,6]
      },
      {
        'name': 'diamonds',
        'args': [0,4]
      },
      {
        'name': 'suit_quality',
        'args': [3,5]
      },
    ],
  },
}

export const sequences = {
  major_support: {
    name: 'major_support',
    label: 'major_support',
    type: 'sequence',
    category: 'sequence',
    description: 'One hand will be a major opening. The other will have support.',
    robotOptions: ['ADVANCED'],
    hand_types: [
      withExtraConstraints(
        handTypes.naturalHearts,
        [{
          'name': 'variable',
          'args': [5,8]
        }, {
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
      withExtraConstraints(
        handTypes.naturalSpades,
        [{
          'name': 'variable',
          'args': [5,8]
        }, {
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
    ],
    partner_hand_types: [
      {
        name: 'support_major',
        label: 'support_major',
        type: 'handType',
        constraints: [
          {
            'name': 'variable',
            'args': [3,13]
          }
        ],
      }
    ],
    settings: {
      variable: ['hearts', 'spades'],
      dealer: 'player',
      flip: true,
    }
  },
  takeOutDouble: {
    name: 'take_out_double_weak_2',
    label: 'take_out_double_weak_2',
    type: 'sequence',
    category: 'sequence',
    description: 'RHO will have a weak 2 opening, next hand a take out double',
    hand_types: [{
      name: 'take_out_double',
      label: 'take_out_double',
      type: 'handType',
      constraints: [
        {
          'name': 'shapes',
          'args': ['5431', '5440', '4441', '4432', '5530', '5332']
        },
        {
          'name': 'variable',
          'args': [0,2]
        },
        {
          'name': 'hp_range',
          'args': [11, 23]
        },
      ],
    },
    handTypes.strong,
    ],
    rho_hand_types: [
      {
        name: 'weak2NonVul',
        label: 'weak2NonVul',
        type: 'handType',
        constraints:         [{
          'name': 'hp_range',
          'args': [3, 10]
        }, {
          'name': 'variable',
          'args': [6,6]
        }, {
          'name': 'shapes',
          'args': ['6430', '6421', '6331', '6322']
        }, {
          'name': 'vuls',
          'args': ['NONE', 'NS']
        }, {
          'name': 'suit_quality',
          'args': [2, 5]
        }],
      },       {
        name: 'weak2Vul',
        label: 'weak2Vul',
        type: 'handType',
        constraints:         [{
          'name': 'hp_range',
          'args': [3, 10]
        }, {
          'name': 'variable',
          'args': [6,6]
        }, {
          'name': 'shapes',
          'args': ['6430', '6421', '6331', '6322']
        }, {
          'name': 'vuls',
          'args': ['ALL', 'EW']
        }, {
          'name': 'suit_quality',
          'args': [3, 5]
        }],
      }],
    settings: {
      variable: ['diamonds', 'hearts', 'spades'],
      dealer: 'rho',
      flip: true,
      robots: 'ADVANCED',
    }
  },
  twoOverOne: {
    name: 'two_over_one_',
    label: 'two_over_one_',
    type: 'sequence',
    category: 'sequence',
    description: 'One player will have a major opening and partner gameforcing hand with no suit biddable on one-level',
    hand_types: [
      withExtraConstraints(
        handTypes.naturalHearts,
        [{
          'name': 'variable',
          'args': [5,8]
        }, {
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
      withExtraConstraints(
        handTypes.naturalSpades,
        [{
          'name': 'variable',
          'args': [5,8]
        }, {
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
    ],
    partner_hand_types: [
      {
        name: 'twoOverOne',
        label: 'twoOverOne',
        type: 'handType',
        constraints: [
          {
            'name': 'spades',
            'args': [0,3]
          },
          {
            'name': 'variable',
            'args': [0,2]
          },
          {
            'name': 'hp_range',
            'args': [11, 23]
          },
        ],
      }
    ],
    settings: {
      variable: ['hearts', 'spades'],
      dealer: 'player',
      flip: true,
    }
  },
  majorsVsNT: {
    name: 'majors_vs_nt',
    label: 'majors_vs_nt',
    type: 'sequence',
    category: 'sequence',
    description: '5-3 fit in a major, two balanced hands and values for game',
    hand_types: [
      removeOptional(
        withExtraConstraints(
          handTypes.balanced,
          [{
            'name': 'variable',
            'args': [5,5]
          }]
        ),
      ),
    ],
    partner_hand_types: [
      removeOptional(
        withExtraConstraints(
          handTypes.balanced,
          [{
            'name': 'variable',
            'args': [3,3]
          }]
        ),
      ),
    ],
    settings: {
      variable: ['hearts', 'spades'],
      nsHcpRange: [23,29],
      dealer: 'any',
      flip: true,
    }
  },
  minorsVsNT: {
    name: 'minors_vs_nt',
    label: 'minors_vs_nt',
    type: 'sequence',
    category: 'sequence',
    description: '9 card fit in a minor, no major fit and values for game',
    hand_types: [
      {
        name: 'minorHand',
        label: 'minorHand',
        type: 'handType',
        constraints: [
          {
            'name': 'shapes',
            'args': ['55xx', '5431', '5422', '5332', '6322', '6331']
          },
          {
            'name': 'variable',
            'args': [5,6]
          },
          {
            'name': 'hearts',
            'args': [0,3]
          },
          {
            'name': 'spades',
            'args': [0,3]
          },
        ],
      }
    ],
    partner_hand_types: [
      {
        name: 'minorSupport',
        label: 'minorSupport',
        type: 'handType',
        constraints: [
          {
            'name': 'variable',
            'args': [3,4] // TODO: Now you can have 6-4 fit, which is not intended
          },
          {
            'name': 'hearts',
            'args': [0,4]
          },
          {
            'name': 'spades',
            'args': [0,4]
          },
        ],
      },
    ],
    settings: {
      variable: ['diamonds', 'clubs'],
      nsHcpRange: [23,30],
      dealer: 'any',
      flip: true,
    }
  },
  gazzilli: {
    name: 'gazzilli',
    label: 'gazzilli',
    type: 'sequence',
    category: 'sequence',
    description: 'Opening hand is a major opening that is strong or has clubs. Responder does not have support or a two over one bid.',
    hand_types: [
      withLabel(
        withExtraConstraints(
          handTypes.naturalHearts,
          [{
            'name': 'variable',
            'args': [5,8]
          }, {
            'name': 'hp_range',
            'args': [11, 15]
          }, {
            'name': 'clubs',
            'args': [4, 6]
          }]
        ),
        'gazzilliOpener_hearts_clubs',
      ),
      withLabel(
        withExtraConstraints(
          handTypes.naturalHearts,
          [{
            'name': 'variable',
            'args': [5,8]
          }, {
            'name': 'hp_range',
            'args': [16, 23]
          }]
        ),
        'gazzilliOpener_hearts_strong'
      ),
      withLabel(
        withExtraConstraints(
          handTypes.naturalSpades,
          [{
            'name': 'variable',
            'args': [5,8]
          }, {
            'name': 'hp_range',
            'args': [11, 15]
          }, {
            'name': 'clubs',
            'args': [4, 6]
          }]
        ),
        'gazzilliOpener_spades_clubs',
      ),
      withLabel(
        withExtraConstraints(
          handTypes.naturalSpades,
          [{
            'name': 'variable',
            'args': [5,8]
          }, {
            'name': 'hp_range',
            'args': [16, 23]
          }]
        ),
        'gazzilliOpener_spades_strong',
      ),
    ],
    partner_hand_types: [
      {
        name: 'gazzilliResponder',
        label: 'gazzilliResponder',
        type: 'handType',
        constraints: [
          {
            'name': 'variable',
            'args': [0,2]
          },
          {
            'name': 'hp_range',
            'args': [3, 11]
          },
        ],
      },
      withLabel(
        withExtraShapes(
          withExtraConstraints(
            handTypes.naturalSpades,
            [{
              'name': 'spades',
              'args': [4,8]
            }, {
              'name': 'variable',
              'args': [0,2]
            }, {
              'name': 'hp_range',
              'args': [12, 23]
            }]
          ),
          ['4333', '4432'],
        ),
        'gazzilliResponder_spades',
      ),
    ],
    settings: {
      variable: ['hearts', 'spades'],
      dealer: 'player',
      flip: true,
    }
  },
  vs1N: {
    name: 'vs_1N',
    label: 'vs_1N',
    type: 'sequence',
    category: 'sequence',
    description: 'Opponents open 1N and we can overcall',
    hand_types: [
      // TODO: One-two-three suits could be one hand type with all shapes. Not sure which is best
      {
        name: 'oneSuit',
        label: 'oneSuit',
        type: 'handType',
        constraints: [
          {
            'name': 'shapes',
            'args': ['64xx', '63xx', '74xx', '73xx', '7222', '8xxx']
          },
          {
            'name': 'hp_range',
            'args': [8,15]
          }
        ],
      },
      {
        name: 'twoSuits',
        label: 'twoSuits',
        type: 'handType',
        constraints: [
          {
            'name': 'shapes',
            'args': ['65xx', '55xx', '=54xx', '=5x4x', '=5xx4', '=45xx', '=x54x', '=x5x4']
          },
          {
            'name': 'hp_range',
            'args': [8,15]
          }
        ],
      },
      {
        name: 'threeSuits',
        label: 'threeSuits',
        type: 'handType',
        constraints: [
          {
            'name': 'shapes',
            'args': ['5440', '4441']
          },
          {
            'name': 'hp_range',
            'args': [8,15]
          }
        ],
      },
      {
        name: 'strong',
        label: 'strong',
        type: 'handType',
        constraints: [
          {
            'name': 'hp_range',
            'args': [15,37]
          }
        ],
      },
    ],
    rho_hand_types: [
      removeOptional(
        withLabel(
          withExtraConstraints(
            handTypes.balanced,
            [{
              'name': 'hp_range',
              'args': [15, 17]
            }]
          ),
          'balanced_15_17'
        ),
      ),
    ],
    settings: {
      dealer: 'rho',
      flip: true,
      robots: 'ADVANCED',
    }
  },
  vs1NEntry: {
    name: 'vs_1N_Entry',
    label: 'vs_1N_Entry',
    type: 'sequence',
    category: 'sequence',
    description: 'You open 1N and opponents overcall',
    hand_types: [
      withLabel(
        withExtraConstraints(
          handTypes.balanced,
          [{
            'name': 'hp_range',
            'args': [15, 17]
          }]
        ),
        'balanced_15_17'
      ),
    ],
    lho_hand_types: [
      // TODO: One-two-three suits could be one hand type with all shapes. Not sure which is best
      {
        name: 'oneSuit',
        label: 'oneSuit',
        type: 'handType',
        constraints: [
          {
            'name': 'shapes',
            'args': ['64xx', '63xx', '74xx', '73xx', '7222', '8xxx']
          },
          {
            'name': 'hp_range',
            'args': [8,15]
          }
        ],
      },
      {
        name: 'twoSuits',
        label: 'twoSuits',
        type: 'handType',
        constraints: [
          {
            'name': 'shapes',
            'args': ['65xx', '55xx', '=54xx', '=5x4x', '=5xx4', '=45xx', '=x54x', '=x5x4']
          },
          {
            'name': 'hp_range',
            'args': [8,15]
          }
        ],
      },
      {
        name: 'threeSuits',
        label: 'threeSuits',
        type: 'handType',
        constraints: [
          {
            'name': 'shapes',
            'args': ['=4450']
          },
          {
            'name': 'hp_range',
            'args': [8,15]
          }
        ],
      },
    ],
    settings: {
      dealer: 'player',
      flip: true,
      robots: 'ADVANCED',
    }
  },
};

export const openingBids = [
  '1C',
  '1D',
  '1H',
  '1S',
  '1N',
  '2C',
  '2D',
  '2H',
  '2S',
  '2N',
  '3C',
  '3D',
  '3H',
  '3S',
  '4C',
  '4D',
  '4H',
  '4S',
]

export const openings = {
  open1C_2plus: {
    name: 'open1C_2plus',
    label: 'open1C_2plus',
    type: 'opening',
    category: 'opening',
    subcategory: '1C',
    description: 'Hand will be balanced 12-14 HCP or 18-19, or a natural club opening',
    hand_types: [
      withExtraConstraints(
        handTypes.naturalClubs,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
      withExtraConstraints(
        handTypes.marmicWithClubs,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
      withLabel(
        withExtraConstraints(
          withConstraintsFromOption(handTypes.balanced, options.five_card_major, false),
          [{
            'name': 'hp_range',
            'args': [12, 14]
          }]
        ),
        'balanced_12_14'
      ),
      withLabel(
        withExtraConstraints(
          withConstraintsFromOption(handTypes.balanced, options.five_card_major, false),
          [{
            'name': 'hp_range',
            'args': [18, 19]
          }]
        ),
        'balanced_18_19'
      ),
    ],
  },
  open1C_betterMinor: {
    name: 'open1C_betterMinor',
    label: 'open1C_betterMinor',
    type: 'opening',
    category: 'opening',
    subcategory: '1C',
    description: 'Hand will be balanced 12-14/18-19 with longer clubs or 3-3 in minors, or a natural club opening',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open1C',
        label: 'Will open 1C',
        type: 'command',
        command: 'open-1C',
      },
      withExtraConstraints(
        handTypes.naturalClubs,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
      withExtraConstraints(
        handTypes.marmicWithClubs,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
      withLabel(
        withExtraShapes(
          withExtraConstraints(
            withConstraintsFromOption(
              withConstraintsFromOption(handTypes.balanced, options.five_card_major, false),
              options.four_card_diamonds,
              false
            ),
            [
              {
                'name': 'hp_range',
                'args': [12, 14]
              },
            ]
          ),
          ['!=4423']
        ),
        'balanced_12_14'
      ),
      withLabel(
        withExtraShapes(
          withExtraConstraints(
            withConstraintsFromOption(
              withConstraintsFromOption(handTypes.balanced, options.five_card_major, false),
              options.four_card_diamonds,
              false
            ),
            [
              {
                'name': 'hp_range',
                'args': [18, 19]
              },
            ]
          ),
          ['!=4423']
        ),
        'balanced_18_19'
      ),
    ],
  },
  open1C_polish: {
    name: 'open1C_polish',
    label: 'open1C_polish',
    type: 'opening',
    category: 'opening',
    subcategory: '1C',
    description: 'Hand will be 12-14 balanced, natural clubs 15-17, or any 18+',
    hand_types: [
      withLabel(
        withExtraConstraints(
          withConstraintsFromOption(handTypes.balanced, options.five_card_major, false),
          [{
            'name': 'hp_range',
            'args': [12, 14]
          }]
        ),
        'balanced_12_14'
      ),
      withExtraConstraints(
        handTypes.naturalClubs,
        [{
          'name': 'hp_range',
          'args': [15, 17]
        }]
      ),
      {
        name: 'strong',
        label: 'strong',
        type: 'handType',
        display: false,
        constraints: [
          {
            'name': 'hp_range',
            'args': [18,40]
          }
        ],
      },
    ],
  },
  open1C_strong: {
    name: 'open1C_strong',
    label: 'open1C_strong',
    type: 'opening',
    category: 'opening',
    subcategory: '1C',
    description: 'Hand will have 17+ HCP',
    hand_types: [ handTypes.strong ],
  },
  open1D_betterMinor: {
    name: 'open1D_betterMinor',
    label: 'open1D_betterMinor',
    type: 'opening',
    category: 'opening',
    subcategory: '1D',
    description: 'Hand will be balanced 12-14 HCP or 18-19 with longer diamonds or 4-4 in minors, or a diamond opening',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open1D',
        label: 'Will open 1D',
        type: 'command',
        command: 'open-1D',
      },
      withExtraConstraints(
        handTypes.naturalDiamonds,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
      withExtraConstraints(
        handTypes.marmicWithDiamonds,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
      withLabel(
        withExtraShapes(
          withExtraConstraints(
            withConstraintsFromOption(handTypes.balanced, options.five_card_major, false),
            [
              {
                'name': 'hp_range',
                'args': [12, 14]
              },
              {
                'name': 'diamonds',
                'args': [3, 5]
              },
            ]
          ),
          ['!=3334', '!=4234', '!=2434']
        ),
        'balanced_12_14'
      ),
      withLabel(
        withExtraShapes(
          withExtraConstraints(
            withConstraintsFromOption(handTypes.balanced, options.five_card_major, false),
            [
              {
                'name': 'hp_range',
                'args': [18, 19]
              },
              {
                'name': 'diamonds',
                'args': [3, 5]
              },
            ]
          ),
          ['!=3334', '!=4234', '!=2434']
        ),
        'balanced_18_19'
      ),
    ],
  },
  open1D_4plus: {
    name: 'open1D_fourPlus',
    label: 'open1D_fourPlus',
    type: 'opening',
    category: 'opening',
    subcategory: '1D',
    description: 'Hand will be balanced 12-14 HCP or 18-19 with 4 card diamonds, or a natural diamond opening',
    hand_types: [
      withExtraConstraints(
        handTypes.naturalDiamonds,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
      withExtraConstraints(
        handTypes.marmicWithDiamonds,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
      withLabel(
        withExtraConstraints(
          withConstraintsFromOption(
            withConstraintsFromOption(handTypes.balanced, options.five_card_major, false),
            options.four_card_diamonds,
            true,
          )
          ,
          [
            {
              'name': 'hp_range',
              'args': [12, 14]
            },
          ]
        ),
        'balanced_12_14',
      ),
      withLabel(
        withExtraConstraints(
          withConstraintsFromOption(
            withConstraintsFromOption(handTypes.balanced, options.five_card_major, false),
            options.four_card_diamonds,
            true,
          )
          ,
          [
            {
              'name': 'hp_range',
              'args': [18, 19]
            },
          ]
        ),
        'balanced_18_19',
      ),
    ],
  },
  open1D_4plusunbal: {
    name: 'open1D_4PlusUnbal',
    label: 'open1D_4PlusUnbal',
    type: 'opening',
    category: 'opening',
    subcategory: '1D',
    description: 'Hand will be natural diamond opening, promises unbalanced hand',
    hand_types: [
      withExtraConstraints(
        handTypes.naturalDiamonds,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
      withExtraConstraints(
        handTypes.marmicWithDiamonds,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
    ],
  },
  open1H_4plus: {
    name: 'open1H_4plus',
    label: 'open1H_4plus',
    type: 'opening',
    category: 'opening',
    subcategory: '1H',
    description: 'Hand will be natural heart opening, at least four hearts',
    hand_types: [
      withExtraConstraints(
        handTypes.naturalHearts4,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
    ],
  },
  open1H_5plus: {
    name: 'open1H_5plus',
    label: 'open1H_5plus',
    type: 'opening',
    category: 'opening',
    subcategory: '1H',
    description: 'Hand will be natural heart opening, at least five hearts',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open1H',
        label: 'Will open 1H',
        type: 'command',
        command: 'open-1H',
      },
      withExtraConstraints(
        handTypes.naturalHearts,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
    ],
  },
  open1S_4plus: {
    name: 'open1S_4plus',
    label: 'open1S_4plus',
    type: 'opening',
    category: 'opening',
    subcategory: '1S',
    description: 'Hand will be natural spade opening, at least four spades',
    hand_types: [
      withExtraConstraints(
        handTypes.naturalSpades4,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
    ],
  },
  open1S_5plus: {
    name: 'open1S_5plus',
    label: 'open1S_5plus',
    type: 'opening',
    category: 'opening',
    subcategory: '1S',
    description: 'Hand will be natural spade opening, at least five spades',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open1S',
        label: 'Will open 1S',
        type: 'command',
        command: 'open-1S',
      },
      withExtraConstraints(
        handTypes.naturalSpades,
        [{
          'name': 'hp_range',
          'args': [11, 23]
        }]
      ),
    ],
  },
  open1N_weak: {
    name: 'open1N_weak',
    label: 'open1N_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '1N',
    description: 'Hand will be balanced hand, 10-13 HCP',
    robotOptions: ['MINI_NT'],
    hand_types: [
      {
        name: 'open1N',
        label: 'command_open1N',
        type: 'command',
        command: 'open-1N',
      },
      withLabel(
        withExtraConstraints(
          handTypes.balanced,
          [{
            'name': 'hp_range',
            'args': [10, 13]
          }]
        ),
        'balanced_10_13'
      ),
    ],
    settings: {
      dealer: 'player',
      vulnerability: 'NS',
    },
  },
  open1N_strong: {
    name: 'open1N_strong',
    label: 'open1N_strong',
    type: 'opening',
    category: 'opening',
    subcategory: '1N',
    description: 'Hand will be balanced hand, 15-17 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open1N',
        label: 'command_open1N',
        type: 'command',
        command: 'open-1N',
      },
      withLabel(
        withExtraConstraints(
          handTypes.balanced,
          [{
            'name': 'hp_range',
            'args': [15, 17]
          }]
        ),
        'balanced_15_17'
      ),
    ],
  },
  open2C_strong: {
    name: 'open2C_strong',
    label: 'open2C_strong',
    type: 'opening',
    category: 'opening',
    subcategory: '2C',
    description: 'Hand will be very strong, 23+ HCP or balanced 20-21 HCP',
    hand_types: [
      withLabel(
        withExtraConstraints(
          handTypes.balanced,
          [{
            'name': 'hp_range',
            'args': [20, 21]
          }]
        ),
        'balanced_20_21'
      ),
      {
        name: 'strong',
        label: 'strong',
        type: 'handType',
        constraints: [
          {
            'name': 'hp_range',
            'args': [23, 40]
          }
        ],
      }
    ],
  },
  open2C_nat: {
    name: 'open2C_nat',
    label: 'open2C_nat',
    type: 'opening',
    category: 'opening',
    subcategory: '2C',
    description: 'Hand will be a natural club opening, 6+ and 11-16 HCP',
    hand_types: [
      withExtraConstraints(
        handTypes.naturalClubs6,
        [{
          'name': 'hp_range',
          'args': [11, 16]
        }]
      )
    ],
  },
  open2C_nat54: {
    name: 'open2C_nat54',
    label: 'open2C_nat54',
    type: 'opening',
    category: 'opening',
    subcategory: '2C',
    description: 'Hand will be a natural club opening, 5 clubs with a 4 card major or 6+ clubs and 11-16 HCP',
    hand_types: [
      withExtraShapes(
        withExtraConstraints(
          handTypes.naturalClubs,
          [{
            'name': 'hp_range',
            'args': [11, 16]
          }]
        ),
        ['!=xx45']
      ),
    ],
  },
  open2D_nat_weak: {
    name: 'open2D_nat_weak',
    label: 'open2D_nat_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '2D',
    description: 'Hand will be a weak diamond opening, 6 card diamonds, 3-9 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open2D',
        label: 'command_open2D',
        type: 'command',
        command: 'open-2D',
      },
      withExtraConstraints(
        handTypes.natDiamonds6,
        [{
          'name': 'hp_range',
          'args': [3, 9]
        }]
      )
    ],
  },
  open2D_multi: {
    name: 'open2D_multi',
    label: 'open2D_multi',
    type: 'opening',
    category: 'opening',
    subcategory: '2D',
    description: 'Hand will be a weak hearts or spades opening, 3-9 HCP',
    robotOptions: ['MULTI'],
    hand_types: [
      {
        name: 'open2D',
        label: 'command_open2D',
        type: 'command',
        command: 'open-2D',
      },
      withExtraConstraints(
        handTypes.natSpade6,
        [{
          'name': 'hp_range',
          'args': [3, 9]
        }]
      ),
      withExtraConstraints(
        handTypes.natHeart6,
        [{
          'name': 'hp_range',
          'args': [3, 9]
        }]
      )
    ],
    settings: {
      dealer: 'player',
    },
  },
  open2H_nat_weak: {
    name: 'open2H_weak',
    label: 'open2H_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '2H',
    description: 'Hand will be weak hearts 3-9 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open2H',
        label: 'command_open2H',
        type: 'command',
        command: 'open-2H',
      },
      withExtraConstraints(
        handTypes.natHeart6,
        [{
          'name': 'hp_range',
          'args': [3, 9]
        }]
      )
    ],
  },
  open2H_nat_10_13: {
    name: 'open2H_nat_10_13',
    label: 'open2H_nat_10_13',
    type: 'opening',
    category: 'opening',
    subcategory: '2H',
    description: 'Hand will be an intermediate heart opening 10-13 HCP',
    hand_types: [
      withLabel(
        withExtraConstraints(
          handTypes.natHeart6,
          [{
            'name': 'hp_range',
            'args': [10, 13]
          }]
        ),
        'natHeart6_10_13'
      ),
    ],
  },
  open2S_nat_weak: {
    name: 'open2S_nat_weak',
    label: 'open2S_nat_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '2S',
    description: 'Hand will be weak spades 3-9 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open2S',
        label: 'command_open2S',
        type: 'command',
        command: 'open-2S',
      },
      withExtraConstraints(
        handTypes.natSpade6,
        [{
          'name': 'hp_range',
          'args': [3, 9]
        }]
      ) ],
  },
  open2S_nat_10_13: {
    name: 'open2S_nat_10_13',
    label: 'open2S_nat_10_13',
    type: 'opening',
    category: 'opening',
    subcategory: '2S',
    description: 'Hand will be an intermediate spade opening 10-13 HCP',
    hand_types: [
      withLabel(
        withExtraConstraints(
          handTypes.natSpade6,
          [{
            'name': 'hp_range',
            'args': [10, 13]
          }]
        ),
        '6 card spades'
      ),
    ],
  },
  open2N_2224: {
    name: 'open2N_2224',
    label: 'open2N_2224',
    type: 'opening',
    category: 'opening',
    subcategory: '2N',
    description: 'Hand will be a very strong balanced hand, 22-24 HCP',
    hand_types: [
      withLabel(
        withExtraConstraints(
          handTypes.balanced,
          [{
            'name': 'hp_range',
            'args': [22, 24]
          }]
        ),
        'balanced_22_24'
      ),
    ],
  },
  open3C_nat_weak: {
    name: 'open3C_nat_weak',
    label: 'open3C_nat_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '3C',
    description: 'Hand will be a weak club opening, 7 card clubs, 3-9 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open3C',
        label: 'command_open3C',
        type: 'command',
        command: 'open-3C',
      },
      withLabel(
        withExtraConstraints(
          handTypes.natClubs6,
          [{
            'name': 'hp_range',
            'args': [3, 9]
          }, {
            'name': 'clubs',
            'args': [7, 7]
          }]
        ),
        'natClubs7'
      ),
    ],
  },
  open3D_nat_weak: {
    name: 'open3D_nat_weak',
    label: 'open3D_nat_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '3D',
    description: 'Hand will be a weak diamond opening, 7 card diamonds, 3-9 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open3D',
        label: 'command_open3D',
        type: 'command',
        command: 'open-3D',
      },
      withLabel(
        withExtraConstraints(
          handTypes.natDiamonds6,
          [{
            'name': 'hp_range',
            'args': [3, 9]
          }, {
            'name': 'diamonds',
            'args': [7, 7]
          }]
        ),
        'natDiamonds7'
      ),
    ],
  },
  open3H_nat_weak: {
    name: 'open3H_nat_weak',
    label: 'open3H_nat_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '3H',
    description: 'Hand will be a weak heart opening, 7 card hearts, 3-9 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open3H',
        label: 'command_open3H',
        type: 'command',
        command: 'open-3H',
      },
      withLabel(
        withExtraConstraints(
          handTypes.natHeart6,
          [{
            'name': 'hp_range',
            'args': [3, 9]
          }, {
            'name': 'hearts',
            'args': [7, 7]
          }]
        ),
        'natHearts7'
      ),
    ],
  },
  open3S_nat_weak: {
    name: 'open3S_nat_weak',
    label: 'open3S_nat_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '3S',
    description: 'Hand will be a weak spade opening, 7 card spades, 3-9 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open3S',
        label: 'command_open3S',
        type: 'command',
        command: 'open-3S',
      },
      withLabel(
        withExtraConstraints(
          handTypes.natSpade6,
          [{
            'name': 'hp_range',
            'args': [3, 9]
          }, {
            'name': 'spades',
            'args': [7, 7]
          }]
        ),
        'natSpades7'
      ),
    ],
  },
  open4C_nat_weak: {
    name: 'open4C_nat_weak',
    label: 'open4C_nat_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '4C',
    description: 'Hand will be a weak club opening, 8 card clubs, 3-9 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open4C',
        label: 'command_open4C',
        type: 'command',
        command: 'open-4C',
      },
      withLabel(
        withExtraConstraints(
          handTypes.natClubs6,
          [{
            'name': 'hp_range',
            'args': [3, 9]
          }, {
            'name': 'clubs',
            'args': [8, 8]
          }]
        ),
        'natClubs8'
      ),
    ],
  },
  open4D_nat_weak: {
    name: 'open4D_nat_weak',
    label: 'open4D_nat_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '4D',
    description: 'Hand will be a weak diamond opening, 8 card diamonds, 3-9 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open4D',
        label: 'command_open4D',
        type: 'command',
        command: 'open-4D',
      },
      withLabel(
        withExtraConstraints(
          handTypes.natDiamonds6,
          [{
            'name': 'hp_range',
            'args': [3, 9]
          }, {
            'name': 'diamonds',
            'args': [8, 8]
          }]
        ),
        'natDiamonds8'
      ),
    ],
  },
  open4H_nat_weak: {
    name: 'open4H_nat_weak',
    label: 'open4H_nat_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '4H',
    description: 'Hand will be a weak heart opening, 8 card hearts, 3-9 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open4H',
        label: 'command_open4H',
        type: 'command',
        command: 'open-4H',
      },
      withLabel(
        withExtraConstraints(
          handTypes.natHeart6,
          [{
            'name': 'hp_range',
            'args': [3, 9]
          }, {
            'name': 'hearts',
            'args': [8, 8]
          }]
        ),
        'natHearts8'
      ),
    ],
  },
  open4S_nat_weak: {
    name: 'open4S_nat_weak',
    label: 'open4S_nat_weak',
    type: 'opening',
    category: 'opening',
    subcategory: '4S',
    description: 'Hand will be a weak spade opening, 8 card spades, 3-9 HCP',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'open4S',
        label: 'command_open4S',
        type: 'command',
        command: 'open-4S',
      },
      withLabel(
        withExtraConstraints(
          handTypes.natSpade6,
          [{
            'name': 'hp_range',
            'args': [3, 9]
          }, {
            'name': 'spades',
            'args': [8, 8]
          }]
        ),
        'natSpades8'
      ),
    ],
  },
}

export const entries = [
  '1C',
  '1D',
  '1H',
  '1S',
  '1N',
  '2C',
  '2D',
  '2H',
  '2S',
  '3C',
  '3D',
  '3H',
  '3S',
]

export const entry = {
  entry1D: {
    name: 'entry1D',
    label: 'entry1D',
    type: 'entry',
    category: 'entry',
    subcategory: '1D',
    description: '10-16 hcp with 5+D',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry1D',
        label: 'command_entry1D',
        type: 'command',
        command: 'enter-1D',
        premise: 'Over1C',
      },
      withExtraConstraints(
        handTypes.naturalDiamonds,
        [{
          'name': 'hp_range',
          'args': [10, 16]
        }]
      ),
    ],
  },
  entry1H: {
    name: 'entry1H',
    label: 'entry1H',
    type: 'entry',
    category: 'entry',
    subcategory: '1H',
    description: '10-16 hcp with 5+H',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry1H',
        label: 'command_entry1H',
        type: 'command',
        command: 'enter-1H',
        premise: 'Over1CD',
      },
      withExtraConstraints(
        handTypes.naturalHearts,
        [{
          'name': 'hp_range',
          'args': [10, 16]
        }]
      ),
    ],
  },
  entry1S: {
    name: 'entry1S',
    label: 'entry1S',
    type: 'entry',
    category: 'entry',
    subcategory: '1S',
    description: '10-16 hcp with 5+S',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry1S',
        label: 'command_entry1S',
        type: 'command',
        command: 'enter-1S',
        premise: 'Over1CDH',
      },
      withExtraConstraints(
        handTypes.naturalSpades,
        [{
          'name': 'hp_range',
          'args': [10, 16]
        }]
      ),
    ],
  },
  entry1N: {
    name: 'entry1N',
    label: 'entry1N',
    type: 'entry',
    category: 'entry',
    subcategory: '1N',
    description: '15-18 hcp, balanced',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry1N',
        label: 'command_entry1N',
        type: 'command',
        command: 'enter-1N',
        premise: 'Over1CDHS',
      },
      withLabel(
        withExtraConstraints(
          handTypes.balanced,
          [{
            'name': 'hp_range',
            'args': [15, 18]
          }]
        ),
        'balanced_15_18'
      ),
    ],
  },
  entry2C: {
    name: 'entry2C',
    label: 'Overcall 2!C',
    type: 'entry',
    category: 'entry',
    subcategory: '2C',
    description: '11-17 hcp with 5+C',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry2C',
        label: 'command_entry2C',
        type: 'command',
        command: 'enter-2C',
        premise: 'Over1CDHS'
      },
      withExtraConstraints(
        handTypes.naturalClubs,
        [{
          'name': 'hp_range',
          'args': [11, 17]
        }]
      ),
    ],
  },
  entry2D: {
    name: 'entry2D',
    label: 'Overcall 2!D',
    type: 'entry',
    category: 'entry',
    subcategory: '2D',
    description: '11-17 hcp with 5+D',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry2D',
        label: 'command_entry2D',
        type: 'command',
        command: 'enter-2D',
        premise: 'Over1DHS2C'
      },
      withExtraConstraints(
        handTypes.naturalDiamonds,
        [{
          'name': 'hp_range',
          'args': [11, 17]
        }]
      ),
    ],
  },
  entry2H: {
    name: 'entry2H',
    label: 'entry2H',
    type: 'entry',
    category: 'entry',
    subcategory: '2H',
    description: '11-17 hcp with 5+H',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry2H',
        label: 'command_entry2H',
        type: 'command',
        command: 'enter-2H',
        premise: 'Over1HS2CD'
      },
      withExtraConstraints(
        handTypes.naturalHearts,
        [{
          'name': 'hp_range',
          'args': [11, 17]
        }]
      ),
    ],
  },
  entry2S: {
    name: 'entry2S',
    label: 'entry2S',
    type: 'entry',
    category: 'entry',
    subcategory: '2S',
    description: '11-17 hcp with 5+S',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry2S',
        label: 'command_entry2S',
        type: 'command',
        command: 'enter-2S',
        premise: 'Over1S2CDH'
      },
      withExtraConstraints(
        handTypes.naturalSpades,
        [{
          'name': 'hp_range',
          'args': [11, 17]
        }]
      ),
    ],
  },
  entry3C: {
    name: 'entry3C',
    label: 'entry3C',
    type: 'entry',
    category: 'entry',
    subcategory: '3C',
    description: '12-18 hcp with 5+C',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry3C',
        label: 'command_entry3C',
        type: 'command',
        command: 'enter-3C',
        premise: 'Over2CDHS'
      },
      withExtraConstraints(
        handTypes.naturalClubs,
        [{
          'name': 'hp_range',
          'args': [12, 18]
        }]
      ),
    ],
  },
  entry3D: {
    name: 'entry3D',
    label: 'entry3D',
    type: 'entry',
    category: 'entry',
    subcategory: '3D',
    description: '12-18 hcp with 5+D',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry3D',
        label: 'command_entry3D',
        type: 'command',
        command: 'enter-3D',
        premise: 'Over2DHS3C'
      },
      withExtraConstraints(
        handTypes.naturalDiamonds,
        [{
          'name': 'hp_range',
          'args': [12, 18]
        }]
      ),
    ],
  },
  entry3H: {
    name: 'entry3H',
    label: 'entry3H',
    type: 'entry',
    category: 'entry',
    subcategory: '3H',
    description: '12-18 hcp with 5+H',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry3H',
        label: 'command_entry3H',
        type: 'command',
        command: 'enter-3H',
        premise: 'Over2HS3CD'
      },
      withExtraConstraints(
        handTypes.naturalHearts,
        [{
          'name': 'hp_range',
          'args': [12, 18]
        }]
      ),
    ],
  },
  entry3S: {
    name: 'entry3S',
    label: 'entry3S',
    type: 'entry',
    category: 'entry',
    subcategory: '3S',
    description: '12-18 hcp with 5+S',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry3S',
        label: 'command_entry3S',
        type: 'command',
        command: 'enter-3S',
        premise: 'Over2S3CDH'
      },
      withExtraConstraints(
        handTypes.naturalSpades,
        [{
          'name': 'hp_range',
          'args': [12, 18]
        }]
      ),
    ],
  },
  entry2DJump: {
    name: 'entry2DJump',
    label: 'entry2DJump',
    type: 'entry',
    category: 'entry',
    subcategory: '2D',
    description: '6-9 hcp with 6+D',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry2DJump',
        label: 'command_entry2DJump',
        type: 'command',
        command: 'enter-2D',
        premise: 'Over1C'
      },
      withExtraConstraints(
        handTypes.natDiamonds6,
        [{
          'name': 'hp_range',
          'args': [6, 9]
        }]
      )
    ],
  },
  entry2HJump: {
    name: 'entry2HJump',
    label: 'entry2HJump',
    type: 'entry',
    category: 'entry',
    subcategory: '2H',
    description: '6-9 hcp with 6+H',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry2HJump',
        label: 'command_entry2HJump',
        type: 'command',
        command: 'enter-2H',
        premise: 'Over1CD'
      },
      withExtraConstraints(
        handTypes.natHeart6,
        [{
          'name': 'hp_range',
          'args': [6, 9]
        }]
      )
    ],
  },
  entry2SJump: {
    name: 'entry2SJump',
    label: 'entry2SJump',
    type: 'entry',
    category: 'entry',
    subcategory: '2S',
    description: '6-9 hcp with 6+S',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry2SJump',
        label: 'command_entry2SJump',
        type: 'command',
        command: 'enter-2S',
        premise: 'Over1CDH'
      },
      withExtraConstraints(
        handTypes.natSpade6,
        [{
          'name': 'hp_range',
          'args': [6, 9]
        }]
      )
    ],
  },
  entry3CJump: {
    name: 'entry3CJump',
    label: 'entry3CJump',
    type: 'entry',
    category: 'entry',
    subcategory: '3C',
    description: '6-10 hcp with 6+C',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry3CJump',
        label: 'command_entry3CJump',
        type: 'command',
        command: 'enter-3C',
        premise: 'Over1CDHSN'
      },
      withExtraConstraints(
        handTypes.natClubs6,
        [{
          'name': 'hp_range',
          'args': [6, 10]
        }]
      )
    ],
  },
  entry3DJump: {
    name: 'entry3DJump',
    label: 'entry3DJump',
    type: 'entry',
    category: 'entry',
    subcategory: '3D',
    description: '6-10 hcp with 6+D',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry3DJump',
        label: 'command_entry3DJump',
        type: 'command',
        command: 'enter-3D',
        premise: 'Over1CDHSN2C'
      },
      withExtraConstraints(
        handTypes.natDiamonds6,
        [{
          'name': 'hp_range',
          'args': [6, 10]
        }]
      )
    ],
  },
  entry3HJump: {
    name: 'entry3HJump',
    label: 'entry3HJump',
    type: 'entry',
    category: 'entry',
    subcategory: '3H',
    description: '6-10 hcp with 6+H',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry3HJump',
        label: 'command_entry3HJump',
        type: 'command',
        command: 'enter-3H',
        premise: 'Over1CDHSN2CD'
      },
      withExtraConstraints(
        handTypes.natHeart6,
        [{
          'name': 'hp_range',
          'args': [6, 10]
        }]
      )
    ],
  },
  entry3SJump: {
    name: 'entry3SJump',
    label: 'entry3SJump',
    type: 'entry',
    category: 'entry',
    subcategory: '3S',
    description: '6-10 hcp with 6+S',
    robotOptions: ['ADVANCED'],
    hand_types: [
      {
        name: 'entry3SJump',
        label: 'command_entry3SJump',
        type: 'command',
        command: 'enter-3S',
        premise: 'Over1CDHSN2CDH'
      },
      withExtraConstraints(
        handTypes.natSpade6,
        [{
          'name': 'hp_range',
          'args': [6, 10]
        }]
      )
    ],
  },
};
