export default function Spade({ width = 12, dark = false }) {
  return (
    <svg
      width={width}
      height={width * (21/18)}
      viewBox="0 0 18 21"
      xmlns="http://www.w3.org/2000/svg"
      className={ dark ? 'fill-spade-100' : 'spades'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0319 16.375C16.4618 16.4193 18.5718 13.8954 17.4112 11.2387C16.2506 8.582 15.0936 7.98427 13.2717 6.05818C11.4489 4.1321 8.79838 0.800193 8.81091 0.0142823C8.82433 0.800193 6.28299 4.1321 4.4602 6.05818C2.63831 7.98427 1.48218 8.582 0.320675 11.2387C-0.839931 13.8954 1.27099 16.4193 4.70002 16.375C7.05434 16.3445 8.11383 14.7912 8.54603 13.82L8.54603 15.0911C8.54603 15.0911 7.79437 19.6759 2.48976 20.1778L1.64414 20.1778L1.64414 20.8922L16.0886 20.8922L16.0886 20.1778L15.2421 20.1778C9.9384 19.6759 9.18584 15.0911 9.18584 15.0911L9.18584 13.9869C9.69501 14.9591 10.8082 16.3462 13.0319 16.375Z"
      />
    </svg>
  );
}
