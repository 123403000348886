import { ranges } from './constants';

const overcall4thSeat = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-P-1[DH]$/,
    explain: 'Opps opened 1C/1D and responded 1D/1H, we can overcall 1H or 1S',
    options: [
      {
        bid: '1H["5+!H"]',
        reqH: 5,
        reqMaxS: 4,
        reqHp: ranges.overcallOneLevel,
      },
      {
        bid: '1S["5+!S"]',
        reqS: 5,
        reqHp: ranges.overcallOneLevel,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-P-1[DH]-1H-(.|..)$/,
    explain: 'Partner overcalled 1H in 4th seat, raise to game or 2H?',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '4H["To play"]',
        reqH: 4,
        reqHp: ranges.weakOpen,
      },
      {
        bid: '2H["Single raise, 3+!H"]',
        reqH: 3,
        reqHp: ranges.raise,
        weight: 0.9,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-P-1[DH]-1H-(.|1.|2[CD])$/,
    explain: 'Partner overcalled 1H in 4th seat, jump raise to 3H?',
    options: [
      {
        bid: '3H["Weak, 4+!H"]',
        reqH: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-P-1[DH]-1H-(2[HSN]|3.)$/,
    explain: 'Partner overcalled 1H in 4th seat, single raise to 3H?',
    options: [
      {
        bid: '3H["Single raise, 3+!H"]',
        reqH: 3,
        reqHp: ranges.intermediateRaise,
      },
      {
        bid: '3H["Single raise, 3+!H"]',
        reqH: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-P-1[DH]-1S-(.|..)$/,
    explain: 'Partner overcalled 1S in 4th seat, raise to game or 2S?',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '4S["To play"]',
        reqS: 4,
        reqHp: ranges.weakOpen,
      },
      {
        bid: '2S["Single raise, 3+!S"]',
        reqS: 3,
        reqHp: ranges.raise,
        weight: 0.9,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-P-1[DH]-1S-(.|1N|2[CDH])$/,
    explain: 'Partner overcalled 1S in 4th seat, jump raise to 3S?',
    options: [
      {
        bid: '3S["Weak, 4+!S"]',
        reqS: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-P-1[DH]-1S-(2[SN]|3.)$/,
    explain: 'Partner overcalled 1S in 4th seat, single raise to 3S?',
    options: [
      {
        bid: '3S["Single raise, 3+!S"]',
        reqS: 3,
        reqHp: ranges.intermediateRaise,
      },
      {
        bid: '3S["Single raise, 3+!S"]',
        reqS: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1[DHSN]$/,
    explain:
      'Opps opened 1C and responded 1X, we can overcall on the 2 level or preempt',
    options: [
      {
        bid: '2D["5+!D"]',
        reqD: 5,
        reqHp: ranges.overcallTwoLevel + 3,
        lastBidExclusions: ['1D'],
      },
      {
        bid: '2D["5+!D"]',
        reqD: 6,
        reqHp: ranges.overcallTwoLevel,
        lastBidExclusions: ['1D'],
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '3D["Weak, 6+!D"]',
        reqD: 6,
        lastBidExclusions: ['1D'],
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 6),
      },
      {
        bid: '2H["5+!H"]',
        reqH: 5,
        reqHp: ranges.overcallTwoLevel + 3,
        lastBidExclusions: ['1D', '1H'],
      },
      {
        bid: '2H["5+!H"]',
        reqH: 6,
        reqHp: ranges.overcallTwoLevel,
        lastBidExclusions: ['1D', '1H'],
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '2S["5+!S"]',
        reqS: 5,
        reqHp: ranges.overcallTwoLevel + 3,
        lastBidExclusions: ['1D', '1H', '1S'],
      },
      {
        bid: '2S["5+!S"]',
        reqS: 6,
        reqHp: ranges.overcallTwoLevel,
        lastBidExclusions: ['1D', '1H', '1S'],
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '2H["Weak, 6+!H"]',
        reqH: 6,
        lastBidExclusions: ['1H', '1S', '1N'],
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 6),
      },
      {
        bid: '2S["Weak, 6+!S"]',
        reqS: 6,
        lastBidExclusions: ['1S', '1N'],
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 6),
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1D-P-1[HSN]$/,
    explain:
      'Opps opened 1D and responded 1X, we can overcall on the 2 level or preempt',
    options: [
      {
        bid: '2C["5+!C"]',
        reqC: 5,
        reqHp: ranges.overcallTwoLevel + 3,
      },
      {
        bid: '2C["5+!C"]',
        reqC: 6,
        reqHp: ranges.overcallTwoLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '2H["5+!H"]',
        reqH: 5,
        reqHp: ranges.overcallTwoLevel + 3,
        lastBidExclusions: ['1H'],
      },
      {
        bid: '2H["5+!H"]',
        reqH: 6,
        reqHp: ranges.overcallTwoLevel,
        lastBidExclusions: ['1H'],
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '2S["5+!S"]',
        reqS: 5,
        reqHp: ranges.overcallTwoLevel + 3,
        lastBidExclusions: ['1H', '1S'],
      },
      {
        bid: '2S["5+!S"]',
        reqS: 6,
        reqHp: ranges.overcallTwoLevel,
        lastBidExclusions: ['1H', '1S'],
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '2S["Weak, 6+!S"]',
        reqS: 6,
        lastBidExclusions: ['1S', '1N'],
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 6),
      },
      {
        bid: '3C["Weak, 6+!C"]',
        reqC: 6,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 6),
      },
    ],
  },

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1H-P-1[SN]$/,
    explain: 'Opps opened 1H and responded 1X, we can overcall on the 2 level or pre-empt',
    options: [
      {
        bid: '2C["5+!C"]',
        reqC: 5,
        reqHp: ranges.overcallTwoLevel + 3,
      },
      {
        bid: '2C["5+!C"]',
        reqC: 6,
        reqHp: ranges.overcallTwoLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '2D["5+!D"]',
        reqD: 5,
        reqHp: ranges.overcallTwoLevel + 3,
      },
      {
        bid: '2D["5+!D"]',
        reqD: 6,
        reqHp: ranges.overcallTwoLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '2S["5+!S"]',
        reqS: 5,
        reqHp: ranges.overcallTwoLevel + 3,
        lastBidExclusions: ['1S'],
      },
      {
        bid: '2S["5+!S"]',
        reqS: 6,
        reqHp: ranges.overcallTwoLevel,
        lastBidExclusions: ['1S'],
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '3C["Weak, 6+!C"]',
        reqC: 6,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 6),
      },
      {
        bid: '3D["Weak, 6+!D"]',
        reqD: 6,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 6),
      },
    ],
  },

  {
    biddingMatch: /^\.?(-\.)*(-P)*-1S-P-1N$/,
    explain: 'Opps opened 1S and responded 1N, we can overcall on the 2 level or pre-empt',
    options: [
      {
        bid: '2C["5+!C"]',
        reqC: 5,
        reqHp: ranges.overcallTwoLevel + 3,
      },
      {
        bid: '2C["5+!C"]',
        reqC: 6,
        reqHp: ranges.overcallTwoLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '2D["5+!D"]',
        reqD: 5,
        reqHp: ranges.overcallTwoLevel + 3,
      },
      {
        bid: '2D["5+!D"]',
        reqD: 6,
        reqHp: ranges.overcallTwoLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '2H["5+!H"]',
        reqH: 5,
        reqHp: ranges.overcallTwoLevel + 3,
      },
      {
        bid: '2H["5+!H"]',
        reqH: 6,
        reqHp: ranges.overcallTwoLevel,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 4 : 6),
      },
      {
        bid: '3C["Weak, 6+!C"]',
        reqC: 6,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 6),
      },
      {
        bid: '3D["Weak, 6+!D"]',
        reqD: 6,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 6),
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[DHS]-P-1[HSN]-2C-(.|..)$/,
    explain: 'Partner overcalls 2C in 4th seat, bid game or make single raise to 3C?',
    options: [
      {
        bid: '3N["To play"]',
        reqHp: 12,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5C["To play"]',
        reqC: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '5C["To play"]',
        reqC: 3,
        reqHp: 12,
      },
      {
        bid: '3C["Single raise, 3+!C"]',
        reqC: 3,
        reqHp: ranges.raise,
        weight: 0.9,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[DHS]-P-1[HSN]-2C-(.|2.)$/,
    explain: 'Partner overcalls 2C in 4th seat, jump raise to 4C?',
    options: [
      {
        bid: '4C["Weak, 4+!C"]',
        reqC: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[DHS]-P-1[HSN]-2C-3.$/,
    explain: 'Partner overcalls 2C in 4th seat, single raise to 4C?',
    options: [
      {
        bid: '4C["Single raise, 3+!C"]',
        reqC: 3,
        reqHp: ranges.intermediateRaise,
      },
      {
        bid: '4C["Single raise, 3+!C"]',
        reqC: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CHS]-P-1[HSN]-2D-(.|..)$/,
    explain: 'Partner overcalls 2D in 4th seat, bid game or make single raise to 3D?',
    options: [
      {
        bid: '3N["To play"]',
        reqHp: 12,
        reqHcpInOpeningSuit: 2,
      },
      {
        bid: '5D["To play"]',
        reqD: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '5D["To play"]',
        reqD: 3,
        reqHp: 12,
      },
      {
        bid: '3D["Single raise, 3+!D"]',
        reqD: 3,
        reqHp: ranges.raise,
        weight: 0.9,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CHS]-P-1[HSN]-2D-(.|2.|3C)$/,
    explain: 'Partner overcalls 2D in 4th seat, jump raise to 4D?',
    options: [
      {
        bid: '4D["Weak, 4+!D"]',
        reqD: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CHS]-P-1[HSN]-2D-(3[DHSN]|4C)$/,
    explain: 'Partner overcalls 2D in 4th seat, single raise to 4D?',
    options: [
      {
        bid: '4D["Single raise, 3+!D"]',
        reqD: 3,
        reqHp: ranges.intermediateRaise,
      },
      {
        bid: '4D["Single raise, 3+!D"]',
        reqD: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDS]-P-1[DSN]-2H-(.|..)$/,
    explain: 'Partner overcalls 2H in 4th seat, bid game or make single raise?',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 5,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: 12,
      },
      {
        bid: '3H["Single raise, 3+!H"]',
        reqH: 3,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CDH]-P-1[DHN]-2S-(.|..)$/,
    explain: 'Partner overcalls 2S in 4th seat, bid game or make single raise?',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 5,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '4S["To play"]',
        reqS: 3,
        reqHp: 12,
      },
      {
        bid: '3S["Single raise, 3+!S"]',
        reqS: 3,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-P-1D-2H-(.|..)$/,
    explain: 'P pre-empts with 2H in 4th seat, bid game or make single raise?',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: ranges.open,
      },
      {
        bid: '3H["To play"]',
        reqH: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CD]-P-1[DH]-2S-(.|..)$/,
    explain: 'P pre-empts with 2S in 4th seat, bid game or make single raise?',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '4S["To play"]',
        reqS: 3,
        reqHp: ranges.open,
      },
      {
        bid: '3S["To play"]',
        reqS: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[DHS]-P-1[HSN]-3C-(.|..)$/,
    explain: 'P pre-empts with 3C in 4th seat, raise?',
    options: [
      {
        bid: '5C["To play"]',
        reqC: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '5C["To play"]',
        reqC: 4,
        reqHp: 12,
      },
      {
        bid: '4C["To play"]',
        reqC: 4,
        reqHp: ranges.raise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1[CHS]-P-1[HSN]-3D-(.|..)$/,
    explain: 'P pre-empts with 3D in 4th seat, raise?',
    options: [
      {
        bid: '5D["To play"]',
        reqD: 5,
        reqHp: ranges.raise,
      },
      {
        bid: '5D["To play"]',
        reqD: 4,
        reqHp: 12,
      },
      {
        bid: '4D["To play"]',
        reqD: 4,
        reqHp: ranges.raise,
      },
    ],
  },
];

export default overcall4thSeat;
