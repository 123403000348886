import { StarRating } from '../../components/rating/starRating.jsx';
import {Link} from "react-router-dom";
import BidDisplay from "../../components/bidding/bidDisplay.jsx";

export default function ReleaseNotes() {
  return (
    <div className="page p-4 mb-24">
      <article className="prose select-text w-full">
        <h1>Cuebids 3.36</h1>
        <i>Released 2024-08-18</i>

        <h2 className="mt-4">Bug Fixes & Improvements</h2>
        <ul>
          <li className="whitespace-normal">
            Increased time for <strong>Lia</strong> to make a bid, allowing the{" "}
            <strong>Undo</strong> feature to be possible.
          </li>
          <li className="whitespace-normal">
            Fixed an issue where challenges were marked as not finished after
            timing out.
          </li>
        </ul>

        <h2 className="mt-4">Group Module Enhancements</h2>
        <ul>
          <li className="whitespace-normal">
            Improved functionality to <strong>edit</strong> group event names and descriptions.
          </li>
          <li className="whitespace-normal">
            Added the ability to <strong>delete</strong> group events from the list page.
          </li>
          <li className="whitespace-normal">
            Star scoring is now visible when creating your own events in the
            coaching module.
          </li>
          <li className="whitespace-normal">
            You can now <strong>rotate</strong> a deal 180 degrees in the coaching module.
          </li>
        </ul>

        <h2 className="mt-4">Robot Improvements</h2>
        <ul>
          <li className="whitespace-normal">
            <div className={"not-prose flex items-center"}>
              Robots can now overcall with&nbsp;{" "}
              <BidDisplay bid={"1N"} size={32} />.
            </div>
          </li>
          <li className="whitespace-normal">
            Added <strong>1NT Entry</strong> as a command in Scenarios. Now you
            can force the robots to overcall 1NT to practice your defense.
          </li>
          <li className="whitespace-normal">
            Multiple bug fixes for advanced robot behavior.
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.35</h1>
        <i>Released 2024-07-31</i>
        <h2 className="mt-4">Language Support</h2>
        <ul>
          <li className="whitespace-normal">
            First official translation of Cuebids to Swedish.
          </li>
          <li className="whitespace-normal">
            Change your language in the{" "}
            <Link
              to={"/settings"}
              className={
                "underline-offset-2 decoration-secondary underline decoration-2"
              }
            >
              settings
            </Link>
            .
          </li>
        </ul>

        <h2 className="mt-4">Additional translations</h2>
        <ul>
          <li className="whitespace-normal">
            We will be on the lookout for someone to help us with translations
            for various languages.
          </li>
          <li className="whitespace-normal">
            Please contact us on{" "}
            <Link
              to={"mailto:support@cuebids.com"}
              className={
                "underline-offset-2 decoration-secondary underline decoration-2"
              }
            >
              support@cuebids.com
            </Link>{" "}
            or join our{" "}
            <Link
              to={"https://discord.gg/U75379cD3G?:ext"}
              className={
                "underline-offset-2 decoration-purple-600 underline decoration-2"
              }
            >
              Discord server
            </Link>{" "}
            to reach out.
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.34</h1>
        <i>Released 2024-07-27</i>
        <h2 className="mt-4">Use Lia Systems in Events</h2>
        <ul>
          <li className="whitespace-normal">
            Now you can select a Lia system when playing events - even your own!
          </li>
          <li className="whitespace-normal">
            When joining an event with Lia, select what system you want to play
            in the dropdown.
          </li>
        </ul>

        <h2 className="mt-4">Review Modal</h2>
        <ul>
          <li className="whitespace-normal">
            Players playing with robots are now separated in it's own tab.
          </li>
        </ul>

        <h2 className="mt-4">Card size</h2>
        <ul>
          <li className="whitespace-normal">
            On larger devices, you can now select a larger card size in the
            settings.
          </li>
        </ul>

        <h2 className="mt-4">Robot pairs</h2>
        <ul>
          <li className="whitespace-normal">
            We will throw in some robot + robot as a test in daily events. They
            will not be competing for leaderboard spots, but will be there for
            comparison.
          </li>
          <li className={"whitespace-normal"}>
            Ben, the neural network robot will be trying daily events alongside
            Lia.
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.33</h1>
        <i>Released 2024-06-23</i>
        <h2 className="mt-4">Advanced robot updates</h2>
        <ul>
          <li className="whitespace-normal">
            Balancing with 1NT, last to act shows 11-16 HCP instead of just
            "Balanced".
          </li>
          <li className="whitespace-normal">
            Balacing with 1NT after opening, shows 18-19 instead of just
            "Balanced".
          </li>
          <li className="whitespace-normal">
            Robots can pass a seemingly forcing bid if partner is known to be
            weak.
          </li>
          <li className="whitespace-normal">
            Robots can jump overcall with 4M.
          </li>
          <li className="whitespace-normal">
            Fixed issues with moving to game after partner is weak.
          </li>
        </ul>

        <h2 className="mt-4">Lia & Advanced robots</h2>
        <ul>
          <li className="whitespace-normal">
            Lia now understand our robots play Multi Landy as their NT defense
            system.
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.32</h1>
        <i>Released 2024-06-12</i>
        <h2 className="mt-4">Build your own Lia system</h2>
        <ul>
          <li className="whitespace-normal">
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              Configure your own system
            </strong>{" "}
            when playing practice (or group sessions) with Lia.
          </li>
          <li className="whitespace-normal">
            You can now select from a list of conventions.
          </li>
          <li className="whitespace-normal">
            In this first release, only a subset of the most popular conventions
            are available.
          </li>
          <li className="whitespace-normal">
            You can find the system settings from the practice view, after
            selecting Lia as your partner.
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.31</h1>
        <i>Released 2024-05-26</i>
        <h2 className="mt-4">New Deal type: Splinter</h2>
        <ul>
          <li className="whitespace-normal">
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              4 card support with shortness
            </strong>{" "}
            (singleton or void) opposite a major opening.
          </li>
        </ul>

        <h2 className="mt-4">New Deal type: Opponents Two Suited Overcall</h2>
        <ul>
          <li className="whitespace-normal">
            Opponents will enter the auction with a two suited overcall.
          </li>
          <li className="whitespace-normal">
            Currently supported overcalls are{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              1M (2M)
            </strong>{" "}
            showing other major and a minor and{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              1M (2NT)
            </strong>{" "}
            showing both minors.
          </li>
        </ul>

        <h2 className="mt-4">Challenge Rematch</h2>
        <ul>
          <li className="whitespace-normal">
            You can now issue a{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              rematch
            </strong>{" "}
            after a friendly challenge. Saving time when creating new
            challenges.
          </li>
          <li className="whitespace-normal">
            The rematch buttons will appear at the top when starting new
            challenges and will populate the dropdowns with the correct players,
            both for partner and opponents.
          </li>
          <li className="whitespace-normal">
            You can also issue a rematch from the challenge overview page, as
            long as the challenge is finished.
          </li>
        </ul>

        <h2 className="mt-4">New Lia System: Gavin Wolpert&apos;s 2/1</h2>
        <ul>
          <li className="whitespace-normal">
            Two versions of Gavin Wolpert&apos;s 2/1 system are now available
            with Lia.
          </li>
          <li className="whitespace-normal">
            There is a light version and an advanced version, as taught by Gavin
            in his online series.
          </li>
        </ul>

        <h2 className="mt-4">New Lia System: Swedish Basic</h2>
        <ul>
          <li className="whitespace-normal">
            One of the most popular systems in Sweden, now available for Lia.
          </li>
          <li className="whitespace-normal">
            These Lia updates have been made possible by Luc at{" "}
            <a
              href={"https://www.intobridge.com?:ext"}
              className={
                "decoration-2 underline-offset-2 decoration-sky-600 underline hover:decoration-secondary"
              }
              target={"_blank"}
              rel="noreferrer"
            >
              IntoBridge
            </a>{" "}
            , if you have any feedback, please let us know!
          </li>
          <li className="whitespace-normal">
            <a
              target="_blank"
              href="https://discord.gg/U75379cD3G?:ext"
              className={
                "decoration-2 underline-offset-2 decoration-sky-600 underline hover:decoration-secondary"
              }
              rel="noreferrer"
            >
              Join our Discord server
            </a>{" "}
            and discuss the new systems with other players and developers.
          </li>
        </ul>

        <h2 className="mt-4">Other</h2>
        <ul>
          <li className="whitespace-normal">
            When you bid a contract tied for best, yours will be highlighted as
            the best one.
          </li>
          <li className="whitespace-normal">
            Group events should be more visible, both with badges and accessible
            directly from the group menu.
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.30</h1>
        <i>Released 2024-05-08</i>
        <h2 className="mt-4">PDF Print</h2>
        <ul>
          <li className="whitespace-normal">
            You can now request a{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              PDF
            </strong>{" "}
            for a session. Your PDF will be generated and sent to your email.
          </li>
          <li className="whitespace-normal">
            The PDF will contain all boards and bidding for a session.
          </li>
          <li className="whitespace-normal">
            It will not contain any information about scoring or stars.
          </li>
          <li className="whitespace-normal">
            PDF print is available for Diamond subscribers and you can find the
            print button in the session overview (top).
          </li>
        </ul>
        <h2 className="mt-4">Scoring</h2>
        <ul>
          <li className="whitespace-normal">
            Expected value (EV) for declaring a contract on S or N will be
            considered equal if they are very close.
          </li>
          <li className="whitespace-normal">
            Sometimes you would unfairly be punished for siding a contract when
            there was no real benefit other than variance in simulations. To
            combat this, these scores will be considered equal if close enough.
          </li>
        </ul>
        <h2 className="mt-4">New Contract/Bid icons</h2>
        <ul>
          <li className="whitespace-normal">
            We had issues with the old icons rendering correctly on all devices,
            so we have updated them. They have received a slight modification.
          </li>
        </ul>
        <h2 className="mt-4">Lia</h2>
        <ul>
          <li className="whitespace-normal">Lia receives a few bugfixes.</li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.29</h1>
        <i>Released 2024-05-04</i>
        <h2 className="mt-4">Light mode</h2>
        <ul>
          <li className="whitespace-normal">
            We are introducing{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              Light mode
            </strong>{" "}
            for those that don&apos;t like dark mode!
          </li>
          <li className="whitespace-normal">
            Switch between visual modes in your settings.
          </li>
          <li className="whitespace-normal">Feedback is much appreciated!</li>
        </ul>
        <h2 className="mt-4">Scenarios</h2>
        <ul>
          <li className="whitespace-normal">
            It is now{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              much faster
            </strong>{" "}
            to test your scenarios, and generation has also received a{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              performance boost!
            </strong>
          </li>
          <li className="whitespace-normal">
            When generating, you have the option to opt for very fast generation
            (with worse scoring) or normal generation with standard scoring.
          </li>
        </ul>
        <h2 className="mt-4">Scoring</h2>
        <ul>
          <li className="whitespace-normal">
            We have improved the quality of{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              scoring
            </strong>{" "}
            for all deal types.
          </li>
        </ul>
        <h2 className="mt-4">Lia</h2>
        <ul>
          <li className="whitespace-normal">Lia receives a lot of updates.</li>
          <li className="whitespace-normal">
            Luc at IntoBridge has been hard at work to deliver a lot of bug
            fixes and new conventions for Lia, and there is more to come!
          </li>
        </ul>
        <h2 className="mt-4">Other</h2>
        <ul>
          <li className="whitespace-normal">
            You can now{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              filter flagged deals
            </strong>{" "}
            played with a specific partner
          </li>
          <li className="whitespace-normal">
            We have increased the{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              history limit to 20 pairs!
            </strong>{" "}
          </li>
          <li className="whitespace-normal">
            You can now supply a{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              {" "}
              suggested answer
            </strong>{" "}
            to your polls.
          </li>
          <li className="whitespace-normal">
            Advanced Robots have learned{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              Michaels Cuebid
            </strong>
            !
          </li>
          <li>
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              Groups
            </strong>{" "}
            have moved to the dropdown menu (top right corner)
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.28</h1>
        <i>Released 2024-03-22</i>

        <h2 className="mt-4">Improved Challenges</h2>
        <ul>
          <li className="whitespace-normal">
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              More statistics
            </strong>{" "}
            (a lot more!) for challenges
          </li>
          <li className="whitespace-normal">
            See how you have done with a specific partner or against certain
            opponents
          </li>
          <li className="whitespace-normal">
            Follow the latest ranked challenges as the results come in
          </li>
          <li className="whitespace-normal">
            Watch the challenge results of other players
          </li>
          <li className="whitespace-normal">
            See your own rating even before reaching the highest rank
          </li>
          <li className="whitespace-normal">
            Inactive players will be hidden from challenge Top 10 list (play
            another ranked challenge to be shown again)
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.27</h1>
        <i>Released 2024-02-25</i>
        <h2 className="mt-4">
          New Scoring System - Half Stars <StarRating stars={2.5} />
        </h2>
        <ul>
          <li className="whitespace-normal">
            Your{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              sessions
            </strong>{" "}
            will now be scored with more granularity.
            <br />
            It is also possible to get a bonus effect ;)
          </li>
          <li className="whitespace-normal">
            We will also update the scoring system for deals, and will try it in
            the new deal type, scenario deals and in{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              Daily events starting 1st of March and onwards
            </strong>
            .
          </li>
          <li className="whitespace-normal">
            Stars have a new sleeker look, to better match the site and app.
          </li>
        </ul>
        <h2 className="mt-4">New deal type: Takeout after weak 2</h2>
        <ul>
          <li className="whitespace-normal">
            Practice takeout doubles after a weak two opening!
          </li>
          <li className="whitespace-normal">
            This is a scenario coming from the scenario market and it was the
            most popular scenario. Now it will be available as a deal type,
            which means you can compare against others and you don&apos;t need
            to generate deals yourself.
          </li>
        </ul>
        <h2 className="mt-4">Lia plays Swedish conventions</h2>
        You can now play with Lia using some methods{" "}
        <strong
          className={
            "decoration-2 underline-offset-2 decoration-secondary underline"
          }
        >
          popular in Sweden
        </strong>
        :
        <ul>
          <li className="whitespace-normal">Multi 2D</li>
          <li className="whitespace-normal">Häxan (Gazilli)</li>
          <li className="whitespace-normal">Stenbergs (Jacoby 2NT variant)</li>
          <li className="whitespace-normal">
            Askeröd (2M opening with 10-13 HCP)
          </li>
        </ul>
        <h2 className="mt-4">Deal quality improvements</h2>
        <ul>
          <li className="whitespace-normal">
            It should be less likely to get different stars for the same
            contract.
          </li>
        </ul>
        <h2 className="mt-4">Groups</h2>
        <ul>
          <li className="whitespace-normal">
            You can delete a group event if you made a mistake when creating it.
          </li>
        </ul>
        <h2 className="mt-4">PayPal</h2>
        <ul>
          <li className="whitespace-normal">
            We now support PayPal as a payment method for subscriptions and
            tickets.
          </li>
        </ul>
        <h2 className="mt-4">Robot and scenario improvements</h2>
        <ul>
          <li className="whitespace-normal">
            Our robots are now a bit braver and will more often pre-empt/open
            with 3X and 4X
          </li>
          <li className="whitespace-normal">
            In Scenarios we have added 3X and 4X openings
          </li>
          <li className="whitespace-normal">
            Improved robot responses to various openings/overcalls
          </li>
        </ul>
        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.26</h1>
        <i>Released 2024-02-10</i>

        <h2 className="mt-4">Lia plays Acol and SEF</h2>
        <ul>
          <li className="whitespace-normal">
            You can now play with Lia using Acol or SEF in practice mode
          </li>
          <li className="whitespace-normal">
            Additional updates to Lia&apos;s bidding and explanations.
          </li>
        </ul>

        <h2 className="mt-4">Advanced Robot fixes</h2>
        <ul>
          <li className="whitespace-normal">
            Advanced robots will not raise it&apos;s own preempts.
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.25</h1>
        <i>Released 2024-01-31</i>

        {/*<h2 className="mt-4">Lia plays Acol and SEF</h2>*/}
        {/*<ul>*/}
        {/*  <li className="whitespace-normal">*/}
        {/*    You can now play with Lia using Acol or SEF in practice mode*/}
        {/*  </li>*/}
        {/*  <li className="whitespace-normal">*/}
        {/*    Additional updates to Lia&apos;s bidding and explanations.*/}
        {/*  </li>*/}
        {/*</ul>*/}

        <h2 className="mt-4">Interface updates</h2>
        <ul>
          <li className="whitespace-normal">
            Icons (practice, challenge etc) visible during bidding.
          </li>
        </ul>

        <h2 className="mt-4">Advanced Robot fixes</h2>
        <ul>
          <li className="whitespace-normal">
            Made advanced robots less likely to miss their fits.
          </li>
        </ul>

        <h2 className="mt-4">Lia requires Diamond in events</h2>
        <ul>
          <li className="whitespace-normal">
            Diamond subscription is required to play with Lia in events.
          </li>
        </ul>

        <h2 className="mt-4">Group fixes</h2>
        <ul>
          <li className="whitespace-normal">
            When using <strong>Play as Coach</strong> in a group event, you can
            opt in or out of deviation comment.
          </li>
          <li className="whitespace-normal">
            Can save suggested bidding without comments
          </li>
          <li className="whitespace-normal">
            Fixed an issue where leaderboards didn&apos;t work for permanent
            events.
          </li>
        </ul>

        <h2 className="mt-4">Pro challenge</h2>
        <ul>
          <li className="whitespace-normal">
            When all players in a challenge are Pro, you can opt in for a pro
            challenge.
          </li>
          <li className="whitespace-normal">
            Later, we will add pro vs pro statistics and save these games for
            special events.
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.24</h1>
        <i>Released 2024-01-12</i>

        <h2 className="mt-4">Scenario and robot updates</h2>
        <ul>
          <li className="whitespace-normal">
            You can now force the robots to bid certain bid when creating a
            scenario
          </li>
          <li className="whitespace-normal">
            In this first update, some opening bids and suit overcalls are
            supported. We will add more in the future.
          </li>
          <li className="whitespace-normal">
            The robot&apos;s multi system has been improved
          </li>
        </ul>

        <h2 className="mt-4">Compare dialog</h2>
        <ul>
          <li className="whitespace-normal">
            Navigate between deals without closing the dialog
          </li>
          <li className="whitespace-normal">
            Icons added to easily see which robot settings other people have
            used
          </li>
          <li className="whitespace-normal">
            Your result and bidding is always at the top for easier comparison
          </li>
        </ul>

        <h2 className="mt-4">Play events with Lia</h2>
        <ul>
          <li className="whitespace-normal">
            You can now play the Daily and Weekly events with Lia
          </li>
          <li className="whitespace-normal">
            And additionally Lia has been updated to be even better!
          </li>
        </ul>

        <h2 className="mt-4">Interface Improvements</h2>
        <ul>
          <li className="whitespace-normal">
            A better interface for selecting (and deselecting) deal types and
            scenarios.
          </li>
          <li className="whitespace-normal">
            Can toggle off some areas of Cuebids to tailor the app more to your
            liking.
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.23</h1>
        <i>Released 2024-01-02</i>
        <h2 className="mt-4">Multi 2D</h2>

        <ul>
          <li className="whitespace-normal">
            Practice this{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              new deal type
            </strong>
            .
            <br />
            Defending against 2D multi can be really tough...Good luck!
          </li>
          <li className="whitespace-normal">
            &quot;Opps 2D Multi&quot; is available for{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-heart-50 underline"
              }
            >
              heart subscribers
            </strong>
            .
          </li>
          <li className="whitespace-normal">
            Tomorrow&apos;s daily will feature this new deal type!
          </li>
        </ul>

        <h2 className="mt-4">Scenarios</h2>
        <ul>
          <li className="whitespace-normal">
            Two new robot conventions can be used when creating scenarios.
            <br />
            Choose between Multi or Weak NT or regular robots.
          </li>
          <li className="whitespace-normal">
            To use robots in your scenarios, you need to specify a robot system
            in the settings.
          </li>
        </ul>

        <h2 className="mt-4">Play with coach (Premium Coaching feature)</h2>
        <ul>
          <li className="whitespace-normal">
            If you create a group event, you can now play with a robot partner
            that will follow the suggested bidding. This includes deals imported
            with PBN.
          </li>
          <li className="whitespace-normal">
            You can control if the coach is North or South.
          </li>
          <li className="whitespace-normal">
            You can optionally control East and West as well using the suggested
            bidding tool. Click on <strong>Robot bid</strong> to follow their
            system or manually select any bid you see fit.
          </li>
          <li className="whitespace-normal">
            If you stray from the suggestions, you will get a notification.
          </li>
        </ul>

        <h2 className="mt-4">PBN Import (Premium feature)</h2>
        <ul>
          <li className="whitespace-normal">
            You can now rotate deals when importing PBN.
          </li>
        </ul>

        <h2 className="mt-4">Friend page</h2>
        <ul>
          <li className="whitespace-normal">
            Friend requests has been moved to the top of the page for easier
            access.
          </li>
          <li className="whitespace-normal">
            You can now select your friend key to copy it.
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      <br />
      <br />
      <br />

      <article className="prose select-text w-full">
        <h1>Cuebids 3.22</h1>
        <i>Released 2023-12-03</i>
        <h2 className="mt-4">Scenario Market</h2>

        <ul>
          <li className="whitespace-normal">
            You can now{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              share
            </strong>{" "}
            your scenarios with the community!
            <br />
            Publishing a scenario will make it available for anyone to use.
            Currently, when you download a scenario, it will be{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-error underline"
              }
            >
              copied
            </strong>{" "}
            to your scenarios.
            <br />
            In the future, we intend to officially publish popular scenarios and
            make them part of the deal types.
            <br />
            When you copy a scenario, the scenario will score a point. The most
            popular scenarios will be featured on the front page.
            <br />
            Now, show everyone how creative you are and create some amazing
            scenarios!
          </li>
        </ul>

        <h2 className="mt-4">Scenarios</h2>

        <ul>
          <li className="whitespace-normal">
            We now supply some predefined sequences, available after clicking{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-secondary underline"
              }
            >
              Add
            </strong>
          </li>
          <li className="whitespace-normal">
            The maximum scenario cap has been{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-primary underline"
              }
            >
              removed!
            </strong>
          </li>
          <li className="whitespace-normal">
            Improved the suit quality operator (advanced) to better reflect the
            descriptions. This can affect your current scenarios when you
            generate new deals.
          </li>
        </ul>

        <h2 className="mt-4">Lia</h2>
        <h5>A great number of improvements to Lia, including:</h5>
        <ul>
          <li className="whitespace-normal">
            Adjustments of 5 of a minor bids
          </li>
          <li className="whitespace-normal">Stayman issues fixed</li>
          <li className="whitespace-normal">
            Forcing vs Non-forcing bids adjustments
          </li>
          <li className="whitespace-normal">
            Alerts have been significantly improved.
          </li>
          <li className="whitespace-normal">
            Artificial bids will be highlighted with blue circle.
          </li>
        </ul>

        <p>
          Lia has been{" "}
          <strong
            className={
              "decoration-2 underline-offset-2 decoration-secondary underline"
            }
          >
            significantly improved
          </strong>{" "}
          since the launch. Thanks to Luc at IntoBridge for a lot of hard work.
        </p>
        <p>
          If you tried Lia before and didn&apos;t like it,{" "}
          <strong
            className={
              "decoration-2 underline-offset-2 decoration-primary underline"
            }
          >
            this is the time to try again!
          </strong>
        </p>

        <h2 className="mt-4">Groups</h2>

        <ul>
          <li className="whitespace-normal">
            You can now create events using{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-error underline"
              }
            >
              PBN import
            </strong>
            . Simply copy paste the entire PBN content to generate hands, then
            you can using Cuebids regular scoring, or fast scoring with no
            simulations.
          </li>
          <li className="whitespace-normal">
            You can use{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-error underline"
              }
            >
              Scenarios
            </strong>{" "}
            when creating group events.
          </li>
          <li className="whitespace-normal">
            New{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-error underline"
              }
            >
              Presentation mode
            </strong>{" "}
            for coaches to get an overview of a deal and how all pairs in a
            group event bid it.
          </li>
          <li className="whitespace-normal">
            These features are currently only available to{" "}
            <strong
              className={
                "decoration-2 underline-offset-2 decoration-sky-400 underline"
              }
            >
              premium groups
            </strong>
            , please contact sales@cuebids.com to learn more.
          </li>
        </ul>

        <strong>Thank you for your continued support!</strong>
      </article>

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.21</h1>*/}
      {/*  <i>Released 2023-11-11</i>*/}
      {/*  <h2 className="mt-4">Scenarios</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        You can now control{' '}*/}
      {/*      <strong*/}
      {/*        className={*/}
      {/*          'decoration-2 underline-offset-2 decoration-secondary underline'*/}
      {/*        }*/}
      {/*      >*/}
      {/*          vulnerability*/}
      {/*      </strong>*/}
      {/*        !*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        You can now specify a vulnerability on a hand type.*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*    This give you a lot of flexibility when designing scenarios. For*/}
      {/*    instance, if you are practicing preempts, you can have different suit*/}
      {/*    quality requirements if you are in green or red!*/}
      {/*  <h4 className="mt-4">Lia & Advanced Robots</h4>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        A lot of minor updates on both robots.*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*    A small release today, I hope you are having a great day. <br/>*/}
      {/*  <br/> <br/>*/}
      {/*  <strong>Thank you for your continued support!</strong>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.20</h1>*/}
      {/*  <i>Released 2023-11-02</i>*/}
      {/*  <h2 className="mt-4">Scenarios</h2>*/}
      {/*    A lot of you have been using scenarios and we have received a lot of*/}
      {/*    great feedback. <br/>*/}
      {/*    We have now made some improvements to scenarios, and we hope you will*/}
      {/*    like them! <br/>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Limit of active scenarios{' '}*/}
      {/*      <strong*/}
      {/*        className={*/}
      {/*          'decoration-2 underline-offset-2 decoration-secondary underline'*/}
      {/*        }*/}
      {/*      >*/}
      {/*          raised*/}
      {/*      </strong>{' '}*/}
      {/*        to 10!*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Save as new - You can now clone and continue working on your*/}
      {/*        scenario.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Variables - You can now set variables in the settings: this is{' '}*/}
      {/*      <strong*/}
      {/*        className={*/}
      {/*          'decoration-2 underline-offset-2 decoration-secondary underline'*/}
      {/*        }*/}
      {/*      >*/}
      {/*          very powerful*/}
      {/*      </strong>*/}
      {/*        , read more below!*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Suit Quality - You can now control the suit quality. Useful for*/}
      {/*        preempts!*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        CCCC evaluation (an advanced HCP replacement tool)*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <h4 className="mt-4">Variables</h4>*/}
      {/*    Variables are a powerful tool to create scenarios with a lot of*/}
      {/*    flexibility. <br/>*/}
      {/*    You can set a variable to be a suit or multiple suits. <br/>*/}
      {/*    Then you can use these variables in the constraints for the hands you*/}
      {/*    want to create. <br/>*/}
      {/*    We want to give you some{' '}*/}
      {/*  <strong*/}
      {/*    className={*/}
      {/*      'decoration-2 underline-offset-2 decoration-secondary underline'*/}
      {/*    }*/}
      {/*  >*/}
      {/*      cool examples*/}
      {/*  </strong>{' '}*/}
      {/*    of how to use variables:*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Set X to be spades or hearts, then have one hand with 11+ HCP and at*/}
      {/*        least 5 X to create a major opening scenario.*/}
      {/*      <br/>*/}
      {/*        Then give partner 4 card X and 10+ HCP, and now you can practice{' '}*/}
      {/*      <strong*/}
      {/*        className={*/}
      {/*          'decoration-2 underline-offset-2 decoration-secondary underline'*/}
      {/*        }*/}
      {/*      >*/}
      {/*          Jacoby 2NT*/}
      {/*      </strong>*/}
      {/*        .*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Set X to be spades, hearts or diamonds, and give east a suit quality*/}
      {/*        constraint, less then 10 HCP and at least 6 cards in X.*/}
      {/*      <br/>*/}
      {/*        Then give south opening values and 0-2 X, with 4441 or 4432*/}
      {/*        distribution.*/}
      {/*      <br/>*/}
      {/*        Now you can practice{' '}*/}
      {/*      <strong*/}
      {/*        className={*/}
      {/*          'decoration-2 underline-offset-2 decoration-secondary underline'*/}
      {/*        }*/}
      {/*      >*/}
      {/*          take-outs after a weak two preempt*/}
      {/*      </strong>*/}
      {/*        .*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        I&apos;m sure you can think of many more ways to use these new*/}
      {/*        tools!*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*    Try out these amazing tools and let us know what you think!*/}
      {/*  <br/> <br/>*/}
      {/*  <strong>Thank you for your continued support!</strong>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.19</h1>*/}
      {/*  <i>Released 2023-10-23</i>*/}
      {/*  <h2 className="mt-4">Lia Updates</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Made Lia available in group events!*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Introduced a new feedback button inside the deal. Accessible from*/}
      {/*        the menu with three dots after a deal is finished.*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2 className="mt-4">Advanced Robots</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Improved robots constructive bidding.*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2 className="mt-4">Profile</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Made it possible to select flag manually instead of relying on geo*/}
      {/*        location.*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2 className="mt-4">QoL Improvements</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Increased visibility for bidding when hidden by scroll.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Fixed a bug where you could not click an event in your history with*/}
      {/*        a low score.*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <strong>Thank you for your continued support!</strong>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.18</h1>*/}
      {/*  <i>Released 2023-10-18</i>*/}

      {/*  <h2 className="mt-4">QoL improvements</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        The main menu will now close when you click menu instead of*/}
      {/*        re-opening.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        The dropdown for selecting deal types and scenarios, will displayed*/}
      {/*        selected options above it when it is open.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        The history will now show 10 pairs instead of just 5.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        You can now create a link to enable joining your group easier.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        It will be more visible when there are bids hidden by scrolling.*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <strong>Thank you for your continued support!</strong>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.17</h1>*/}
      {/*  <i>Released 2023-10-16</i>*/}

      {/*  <h2 className="mt-4">Scenarios (BETA)</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Create your own deal types with the new tool called Scenarios*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">Find Scenarios in the menu</li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Create a scenario, and request deals*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Scenarios can be used in practice sessions, with friends or Lia*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Scenario BETA is only available to heart subscribers*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <h2 className="mt-4">Lia full release</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">Anyone can use Lia for practice</li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Hover or Hold Tap to see next bid explanation!*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Continue to email us or use the Discord to give feedback*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        There is a dedicated Lia-feedback channel in the discord*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <h2 className="mt-4">Bug fixes</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Fixed an issue where some group events could not be joined.*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <p>*/}
      {/*      We hope you are all doing well and enjoying Cuebids. Should you*/}
      {/*      encounter any issues or have any feedback, please reach out to our*/}
      {/*      team.*/}
      {/*  </p>*/}

      {/*  <strong>Thank you for your continued support!</strong>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.16</h1>*/}
      {/*  <i>Released 2023-09-24</i>*/}

      {/*  <h2 className="mt-4">Two Suiters - Two new deal types!</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Two Suiters - Opening hand will have at least 5-5 in two suits.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Two Suited Overcalls - Your opponents will open, and you (or you*/}
      {/*        partner) will have a two-suiter to enter the auction with!*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <h2 className="mt-4">Lia improvements</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        A <strong>lot</strong> of updates to Lia!*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <h2 className="mt-4">Bug fixes</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Chat position could be off screen for some devices.*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.15</h1>*/}
      {/*  <i>Released 2023-09-22</i>*/}

      {/*  <h2 className="mt-4">Welcome Lia!</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Practice with Lia, a bot implemented in collaboration with*/}
      {/*        IntoBridge*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Lia plays a natural Sayc 2/1 system*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Lia will explain her bids and the interpretation of your bids*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Available in Practice, just select Lia - The Bot as your partner*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Initially Lia will be available to Beta test for heart subscribers*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Lia is in early development - expect some bugs*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        We appreciate any feedback on possible odd bids or explanations!*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <h2 className="mt-4">Groups</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Group events can now be permanent (no end date)*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Can have more compact view of existing group events (change in group*/}
      {/*        settings)*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">Can filter group events on name</li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Can filter group events to exclude finished events*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Show group event invites on start page*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <h2 className="mt-4">Premium groups</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Can replace deals in group events*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Can edit alerts in suggested bidding - just click on the bid*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.14</h1>*/}
      {/*  <i>Released 2023-09-07</i>*/}

      {/*  <h2 className="mt-4">Polls</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Edit or delete polls after creating them*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <h2 className="mt-4">Premium groups</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Coaches can play with multiple partners in group events*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Add explanations to each bid in suggested bidding in group events*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <h2 className="mt-4">Misc</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Some fixes for advanced robots (thank you for reporting any problems*/}
      {/*        you encounter!)*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        When looking for older sessions with filters on, clicking show more*/}
      {/*        should now always load some results*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Go to next deal will now stop on deals where you have unread*/}
      {/*        messages*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.13</h1>*/}
      {/*  <i>Released 2023-08-31</i>*/}

      {/*  <h1>New Features</h1>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Connect your Cuebids account to the Swedish federation.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Mark a deal for discussion when it is your turn to bid.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Your selected deal types are remembered for next time.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">Improved new player onboarding.</li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Polls: Confirm before abstain (to avoid misclicks).*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">Premium groups</li>*/}
      {/*    <ul>*/}
      {/*      <li className="whitespace-normal">Correctly sort player stats.</li>*/}
      {/*      <li className="whitespace-normal">*/}
      {/*          Easier to spot deals with suggested bidding in group events.*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </ul>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.12</h1>*/}
      {/*  <i> Released 2023-08-20</i>*/}

      {/*  <h1>New Deal Types</h1>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">Two new deal types introduced:</li>*/}
      {/*    <ul>*/}
      {/*      <li className="whitespace-normal">*/}
      {/*          1NT Defence - practice your 1NT defence, as the robots will open*/}
      {/*          1NT on every deal.*/}
      {/*      </li>*/}
      {/*      <li className="whitespace-normal">*/}
      {/*          1NT Entry - Hone your skills when the robots bid against your 1NT*/}
      {/*          opening (15-17).*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </ul>*/}

      {/*  <h1>Design Changes</h1>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Deal number displayed on challenge page for finished deals.*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h1>Bug Fixes</h1>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Resolved graphical issues experienced on older Android phones.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Poll creators will no longer see a badge for polls they created*/}
      {/*        themselves.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Addressed a bug where a poll comment could mistakenly appear on the*/}
      {/*        incorrect bid.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Solved an issue with suggested bidding for teachers in premium*/}
      {/*        groups.*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.11</h1>*/}
      {/*  <i> Released 2023-08-12</i>*/}

      {/*  <h1>Android improvements</h1>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal text-bold">*/}
      {/*        Make sure to update your Android app separately to the latest*/}
      {/*        version (3.0.3)*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Fixed app icon and splash screen*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Android back button now works (instead of minimizing app)*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Can recover after connection loss (without restarting app)*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h1>Polls improvements</h1>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Tabs for showing replies from all/friends/pros*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Improved chart showing replies (previously bids which few had*/}
      {/*        selected was not possible to read)*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Scroll to top button (when you have scrolled down on page)*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Notifications when further discussion on a bid you have liked or*/}
      {/*        commented on*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Many fewer notifications for bids on your polls (now only every 10th*/}
      {/*        bid instead of all)*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h1>Premium groups improvements</h1>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Coach can add suggested bidding to deals in group events (visible*/}
      {/*        for players in Compare popup on the deal)*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Coach can see activity of group members/pairs in Cuebids*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h1>Misc</h1>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        On landing page (logged out) you can get a demo of how Cuebids works*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Setting for notifications when we start special events or promos*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Fixed issue where people in chat for shared session would get a*/}
      {/*        badge for their own messages*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.10</h1>*/}
      {/*  <i> Released 2023-07-20</i>*/}

      {/*  <h2>Pros</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Easily find pro players&apos; results to compare with*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">Their names are now orange</li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Contact us if you think you should be one*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2>Compare</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Will not show pairs where robots behaved differently for others*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2>Friends Page</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">Easier to see your friend key</li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Online tab removed (if you want to find a friend, you can search for*/}
      {/*        users)*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2>Start Page</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Redesigned to have fewer things going on*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2>Polls</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">Renamed from spots</li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Navigate between them when on a poll page*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">See your bid on a poll page</li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Notifications activated (option to turn them off for new ones)*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Badge in the menu when there are new ones*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2>Menu</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">Redesigned</li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Chat page removed (use Discord!)*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.9</h1>*/}
      {/*  <i> Released 2023-07-16</i>*/}

      {/*  <h2>Chicago NABC special Cuebids week bonanza</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        This week the weekly and all daily events are free to join!*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        The daily events will have a different deal type every day!*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        If you are in Chicago, come say hi to us (and get a free t-shirt if*/}
      {/*        we haven&apos;t run out of them yet!)*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2>Change the alert of your bid</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">When it is your turn</li>*/}
      {/*    <li className="whitespace-normal">Before the deal is finished</li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        To change, just click on your bid and change the description*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Some new characters are allowed to include in the alert*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2>Group event improvements</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Easier to find - now you can see ongoing group events on the event*/}
      {/*        page (currently one per group)*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Easier to create - you now get feedback if you&apos;re trying to*/}
      {/*        create an event and some setting is incorrect*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Premium groups can have the option to create group events with Deal*/}
      {/*        types. Contact us for more information.*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</article>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<br/>*/}

      {/*<article className="prose select-text w-full">*/}
      {/*  <h1>Cuebids 3.8</h1>*/}
      {/*  <i> Released 2023-07-06</i>*/}
      {/*  <h2>New Deal Types</h2>*/}
      {/*  <p>Introducing three new deal types:</p>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*      <b>1 Major Opening</b> - Opener will have a major suit of at least 5*/}
      {/*        cards and opening values.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*      <b>Canape</b> - Opener will have a four card major and a longer*/}
      {/*        minor.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*      <b>Three Suited</b> - Opener will have 4441 or 5440 distribution.*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2>Optimizations</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Optimized the speed of bidding, ensuring faster response times and*/}
      {/*        improved performance.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Enhanced stability for bidding, addressing any potential stability*/}
      {/*        issues and providing a more reliable experience.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Implemented an undo/confirm feature that allows users a 5-second*/}
      {/*        window to make changes or confirm their selections after completing*/}
      {/*        a session deal.*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <h2>Undo Countdown in Competition</h2>*/}
      {/*  <p>*/}
      {/*      Fixed an issue where the countdown timer was not synchronized with the*/}
      {/*      user&apos;s local time. It now accurately reflects the user&apos;s*/}
      {/*      time, ensuring a seamless competition experience.*/}
      {/*  </p>*/}

      {/*  <h2>Select Chat Text (for copy)</h2>*/}
      {/*  <p>*/}
      {/*      You can now easily select and copy text within the chat feature,*/}
      {/*      making it more convenient to share or save important information.*/}
      {/*  </p>*/}

      {/*  <h2>Enhanced Clarity for Extra User in Chat (Shared)</h2>*/}
      {/*  <p>*/}
      {/*      To improve clarity and communication within the chat, we have made it*/}
      {/*      more apparent which user is which in shared sessions.*/}
      {/*  </p>*/}

      {/*  <h2>Improved Compare Functionality in Challenges</h2>*/}
      {/*  <p>*/}
      {/*      We have enhanced the compare feature in challenges, it should now be*/}
      {/*      much easier to compare your results with your opponents&apos;.*/}
      {/*  </p>*/}

      {/*  <h2>Premium Groups</h2>*/}
      {/*  <p>*/}
      {/*      This update includes improvements to our coaching feature. If you have*/}
      {/*      a premium group with coaching, you can now navigate between all the*/}
      {/*      boards in an event and communicate directly with players and give them*/}
      {/*      feedback on their bidding.*/}
      {/*  </p>*/}

      {/*  <h2>Other</h2>*/}
      {/*  <ul>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Animated the first-time presentation of results for a more engaging*/}
      {/*        and visually appealing experience.*/}
      {/*    </li>*/}
      {/*    <li className="whitespace-normal">*/}
      {/*        Fixed an issue where push notifications were not working for web or*/}
      {/*        Android.*/}
      {/*    </li>*/}
      {/*  </ul>*/}

      {/*  <p>*/}
      {/*      We hope you are all doing well and enjoying Cuebids. Should you*/}
      {/*      encounter any issues or have any feedback, please reach out to our*/}
      {/*      team.*/}
      {/*  </p>*/}

      {/*  <strong>Thank you for your continued support!</strong>*/}
      {/*</article>*/}
    </div>
  );
}


