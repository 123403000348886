import React from 'react';
import Hand from '../../../components/cards/hand';
import SessionDealResult from './sessionDealResult';
import BiddingDisplay from '../../../components/bidding/biddingDisplay';
import { useAppStateStore } from '../../../appStateStore.jsx';

export default function KibitzDeal({
  sessionDeal,
  isSmall,
  currentUser,
  marked,
  markForDiscussion,
  review,
}) {
  const user = useAppStateStore((state) => state.user);

  const isShared = sessionDeal.shared;

  if (!sessionDeal.finished && !isShared) {
    return (
      <article className="prose p-10">
        <b>
          Shh, the players are bidding, do not disturb them! When they have
          finished you can see their result.
        </b>
      </article>
    );
  }

  let content;
  if (!sessionDeal.finished) {
    content = (
      // Made to have same layout as result
      <div className="flex grow flex-col items-center justify-around">
        <Hand
          size={user?.cardSize ?? 'sm'}
          variant={
            user?.cardVariant ??
            (user?.handAsDiagram ? 'diagram' : 'modern')
          }
          showHcp={!(user?.hideHcpCount ?? false)}
          hand={sessionDeal.northHandPart}
          direction="N"
          order={user?.suitOrder ?? 'default'}
        />
        <div className="mt-4 mb-8 h-[200px]">
          <BiddingDisplay
            bidding={sessionDeal.bidding + '-?'}
            vulnerability={sessionDeal.vulnerability}
            variant={'standard'}
          />
        </div>
        <div className="h-14">
          {isShared && (
            <button
              className="btn-outline btn-primary btn ml-2 text-white"
              onClick={markForDiscussion}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={
                  'h-6 w-6  ' + (marked ? 'stroke-secondary' : 'stroke-white')
                }
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M6 5H12.1716C12.702 5 13.2107 5.21071 13.5858 5.58579L14.4142 6.41421C14.7893 6.78929 15.298 7 15.8284 7H18C19.1046 7 20 7.89543 20 9V13C20 14.1046 19.1046 15 18 15H14.8284C14.298 15 13.7893 14.7893 13.4142 14.4142L12.5858 13.5858C12.2107 13.2107 11.702 13 11.1716 13H6M6 3V21"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    );
  } else {
    content = (
      <SessionDealResult
        currentUser={currentUser}
        sessionDeal={sessionDeal}
        isSmall={isSmall}
        topHandDirection="N"
        bottomHandDirection="S"
        isKibitzing
        isShared={isShared}
        marked={marked}
        markForDiscussion={markForDiscussion}
        shouldAnimateResult={false}
        setShouldAnimateResult={() => {}}
        review={review}
      />
    );
  }

  return (
    <React.Fragment key={sessionDeal.id}>
      {content}
      <div className="h-[200px] p-4">
        <Hand
          size={user?.cardSize ?? 'sm'}
          variant={
            user?.cardVariant ??
            (user?.handAsDiagram ? 'diagram' : 'modern')
          }
          showHcp={!(user?.hideHcpCount ?? false)}
          hand={sessionDeal.southHandPart}
          direction={'S'}
          order={user?.suitOrder ?? 'default'}
        />
      </div>
    </React.Fragment>
  );
}
