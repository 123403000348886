import { dealTypes, tags } from 'cuebids-deals';
import { Link } from 'react-router-dom';
import LanguageDisclaimer from '../../components/languageDisclaimer/LanguageDisclaimer.jsx'
import { useTranslation } from 'react-i18next'

export default function DealTypes() {
  const { t } = useTranslation();

  return (
    <div className="page pb-24">
      <LanguageDisclaimer />
      <div className='w-full flex flex-wrap gap-2 mt-4 items-center justify-center flex-col p-2'>
        <h1 className="text-3xl font-bold">Deal Types</h1>
        <span>
        Missing a deal type? Request it <Link to={'/contact'} className="underline underline-offset-2 decoration-2 decoration-primary">Here</Link>
        </span>
        <table
          id="session-list"
          className="mt-4 w-full border-collapse rounded-sm md:w-4/5"
        >
          <thead>
            <tr>
              <th className={'w-32'}>Type</th>
              <th className={'p-0'}>Description</th>
              <th className={'p-0'}>Dealer</th>
            </tr>
          </thead>
          <tbody>
            {
              tags.map((tag, index) => {
                const dealType = dealTypes[tag];
                return (
                  <tr key={index} className="p-2">
                    <td className={'py-2'}>
                      <h2 className="text-sm font-bold">{t(`deal_types.${dealType.tag}`)}</h2>
                    </td>
                    <td className={'py-2'}>
                      <p className="text-sm text-emerald-500">{dealType.description}</p>
                    </td>
                    <td className={'p-2'}>
                      <p className="text-sm text-emerald-500">{dealType.dealer}</p>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}
