import 'flag-icons/css/flag-icons.css';
import { useAuth } from '../../util/hooks.jsx';
import { Link, useNavigate } from 'react-router-dom'
import { feds, filterFeds } from '../../util/feds'
import { useAppStateStore } from '../../appStateStore.jsx';
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export function SelectFedDropdown({ onSelect }) {
  const { currentUser } = useAuth();
  const user = useAppStateStore((state) => state.user);


  const options = feds.filter(
    (fed) => fed.id === 1 || (fed.flag !== 'none' && fed.flag === user?.flag)
  );

  useEffect(() => {
    if(options.length === 1) {
      onSelect(options[0].id)
    }
  }, [onSelect, options])


  if (!currentUser) {
    return null;
  }

  return (
    <div className="p-4">
      <div className="flex flex-col items-start gap-2 mt-4">
        {options.map((fed) => {
          return (
            <button key={fed.id} onClick={() => onSelect(fed.id)} className="btn btn-ghost btn-sm btn-block text-start justify-start">
              <img src={fed.image} className="w-8 mr-2" alt={fed.name} />
              {fed.name}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default function SelectFed() {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const user = useAppStateStore((state) => state.user);
  const numberOfNewSpots = useAppStateStore((state) => state.numberOfNewSpots);
  const nav = useNavigate();

  const options = filterFeds(user?.flag);

  useEffect(() => {
    if(options.length === 1) {
      nav(options[0].url, { replace: true })
    }
  }, [nav, options])


  if (!currentUser) {
    return null;
  }


  return (
    <div className="p-4">
      <article className="prose">
        <h3>{t('components.select_fed.title')}</h3>
      </article>
      <div className="flex flex-col items-start gap-2 mt-4">
        {options.map((fed) => {
          const badgeCount = numberOfNewSpots[fed.language] ?? 0;
          return (
            <Link key={fed.id} to={fed.url}>
              <button className="btn btn-ghost btn-sm btn-block text-start justify-start indicator">
                {badgeCount > 0 && <span className="indicator-item badge badge-sm badge-error">{badgeCount > 9 ? '9+' : badgeCount}</span>}
                <img src={fed.image} className="w-8 mr-2" alt={fed.name} />
                {fed.name}
              </button>
            </Link>
          );
        })}
        {/*{options && options.length === 1 && (*/}
        {/*  <div className="text-sm text-gray-500">*/}
        {/*    You can connect to your federation in the settings area.*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
}
