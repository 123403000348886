import { create } from 'zustand';

const initState = {
  users: [
    'Emanuel Unge',
    'Daniel Gullberg'
  ],
  currentDeal: 0,
  deals: [
    {
      dealNumber: 0,
      finalBid: null,
      step: 0
    }
  ]
}

const useMockStore = create((set) => ({
  ...initState,
  reset: () => {
    set((s) => {
      const newDeals = [...s.deals]
      newDeals[s.currentDeal].step = 0
      newDeals[s.currentDeal].waiting = false
      newDeals[s.currentDeal].finalBid = null
      return { deals: newDeals }
    })
  },
  nextStep: () => {
    set((s) => {
      const newDeals = [...s.deals]
      newDeals[s.currentDeal].waiting = true
      return { deals: newDeals }
    })
  },
  partnerBid: () => {
    set((s) => {
      const newDeals = [...s.deals]
      newDeals[s.currentDeal].step = newDeals[s.currentDeal].step + 1
      newDeals[s.currentDeal].waiting = false
      return { deals: newDeals }
    })
  },
  finalBid: (bid) => {
    set((s) => {
      const newDeals = [...s.deals]
      newDeals[s.currentDeal].step = newDeals[s.currentDeal].step + 1
      newDeals[s.currentDeal].finalBid = bid
      return { deals: newDeals }
    })
  },
  update: (update) => {
    set(update);
  },
}))

export default useMockStore;
