export function getRobotUserId() {
  return import.meta.env.VITE_INSTANCE_NAME === 'dev' ? 'VNhax78p6JW2LBEcOmGfOGrry7C3' : 'ZOFRjVOi5LhPxPzvdrfd5kZo1B43';
}

export function getAllRobots() {
  return import.meta.env.VITE_INSTANCE_NAME === 'dev' ? [
    'VNhax78p6JW2LBEcOmGfOGrry7C3',
    'B9x7qLmnjkgU4VuTgcZtcVoritB3',
  ] : [
    'ZOFRjVOi5LhPxPzvdrfd5kZo1B43',
    'jmqIAakiTpbTRZgwr0yDvQIBdf52',
  ];
}

export function isPartnerRobot(partnerId) {
  return [getRobotUserId(), 'coachRobot'].includes(partnerId);
}

export function shouldRobotBidOnSession(sessionId, session, sessionDeals) {
  const activeSession = sessionId === sessionDeals?.[0]?.sessionId
  const robotIsSouth = session?.users[1] === getRobotUserId();
  const southToAct = session?.southToAct > 0;
  const robotIsNorth = session?.users[0] === getRobotUserId();
  const northToAct = session?.northToAct > 0;
  return activeSession && ((robotIsSouth && southToAct) || (robotIsNorth && northToAct));
}

export function getRobotDealToBidOn(sessionDeals, singlePlayerRobotErrors) {
  return sessionDeals.find(sd => sd.turn === getRobotUserId() && !sd.finished && !singlePlayerRobotErrors[sd.id]);
}

export const base =   {
  'DEFAULT': 'Sayc 2/1',
  'SEF': 'Standard French',
  'SWEDISH': 'Standard Swedish',
  'ACOL': 'Acol' };

export const OB = {
  'DEFAULT': [
    { bid: '1C',
      explain: '12+ HCP, 3+!C' },
    { bid: '1D',
      explain: '12+ HCP, 3+!D' },
    { bid: '1H',
      explain: '12+ HCP, 5+!H' },
    { bid: '1S',
      explain: '12+ HCP, 5+!S' },
    { bid: '1N',
      explain: '15-17 HCP' },
    { bid: '2C',
      explain: '20+ HCP' },
    { bid: '2D',
      explain: 'Weak, (5)6+!D' },
    { bid: '2H',
      explain: 'Weak, (5)6+!H' },
    { bid: '2S',
      explain: 'Weak, (5)6+!S' },
    { bid: '2N',
      explain: '20-21 HCP' },
  ],
  'SEF': [
    { bid: '1C',
      explain: '12+ HCP, 3+!C' },
    { bid: '1D',
      explain: '12+ HCP, 3+!D' },
    { bid: '1H',
      explain: '12+ HCP, 5+!H' },
    { bid: '1S',
      explain: '12+ HCP, 5+!S' },
    { bid: '1N',
      explain: '15-17 HCP' },
    { bid: '2C',
      explain: '18-23+ HCP' },
    { bid: '2D',
      explain: '24+, GF' },
    { bid: '2H',
      explain: 'Weak, (5)6+!H' },
    { bid: '2S',
      explain: 'Weak, (5)6+!S' },
    { bid: '2N',
      explain: '20-21 HCP' },
  ],
  'SWEDISH': [
    { bid: '1C',
      explain: '12+ HCP, 3+!C' },
    { bid: '1D',
      explain: '12+ HCP, 3+!D' },
    { bid: '1H',
      explain: '12+ HCP, 5+!H' },
    { bid: '1S',
      explain: '12+ HCP, 5+!S' },
    { bid: '1N',
      explain: '15-17 HCP' },
    { bid: '2C',
      explain: '20+ HCP' },
    { bid: '2D',
      explain: 'Weak, Multi, (5)6+!H or !S' },
    { bid: '2H',
      explain: 'Askeröd, 6+!H 10-13' },
    { bid: '2S',
      explain: 'Askeröd, 6+!S 10-13' },
    { bid: '2N',
      explain: '20-21 HCP' },
  ],
  'ACOL': [
    { bid: '1C',
      explain: '12+ HCP, 4+!C' },
    { bid: '1D',
      explain: '12+ HCP, 4+!D' },
    { bid: '1H',
      explain: '12+ HCP, 4+!H' },
    { bid: '1S',
      explain: '12+ HCP, 4+!S' },
    { bid: '1N',
      explain: '12-14 HCP' },
    { bid: '2C',
      explain: '23+ HCP' },
    { bid: '2D',
      explain: 'Weak, (5)6+!D' },
    { bid: '2H',
      explain: 'Weak, (5)6+!H' },
    { bid: '2S',
      explain: 'Weak, (5)6+!S' },
    { bid: '2N',
      explain: '20-22 HCP' },
  ],
}

export const CC =
    {
      'DEFAULT': [
        { explain: 'RKCB 0314' },
        { explain: 'Lebensohl' },
        { explain: 'Splinter' },
        { explain: 'Kokish 2C' },
        { explain: 'Jacoby 2NT' },
        { explain: 'Bergen Raises' },
        { explain: 'Gambling 3NT' },
        { explain: 'Inverted minors' },
      ],
      'SEF': [
        { explain: 'RKCB 0314' },
        { explain: 'Lebensohl' },
        { explain: 'Splinter' },
        { explain: 'Gambling 3NT' },
        { explain: 'Roudi' },
        { explain: 'Ghestem' },
      ],
      'SWEDISH': [
        { explain: 'RKCB 0314' },
        { explain: 'Lebensohl' },
        { explain: 'Splinter' },
        { explain: 'Stenberg' },
        { explain: 'XY-NT' },
        { explain: 'Häxan' },
        { explain: 'Multi' },
        { explain: 'Askeröd' },
      ],
      'SWEDISH_BASIC': [
        { explain: 'RKCB 0314' },
        { explain: 'Lebensohl' },
        { explain: 'Splinter' },
        { explain: 'Stenberg' },
      ],
      'ACOL': [
        { explain: 'RKCB 0314' },
        { explain: 'Lebensohl' },
        { explain: 'Splinter' },
      ],
      'GAVIN_LIGHT': [
        { explain: 'RKCB 1430' },
        { explain: 'Lebensohl' },
        { explain: 'Jacoby 2NT' },
        { explain: '2/1 structure' },
      ],
      'GAVIN_ADVANCED': [
        { explain: 'Two tiered splinters' },
        { explain: 'Trf over 1M (X)' },
        { explain: 'Trf Lebensohl' },
        { explain: 'And more...' },
      ],
    };

// This is in case we need to remove systems, to avoid a user having saved a deprecated system.
export function getInitialLiaSystem(defaultLiaSystem) {
  if (!defaultLiaSystem) {
    return 'DEFAULT'
  }

  return [
    'MY_SYSTEM',
    'DEFAULT',
    'ACOL',
    'SEF',
    'GAVIN_ADVANCED',
    'GAVIN_LIGHT',
    'SWEDISH_BASIC',
    'SWEDISH'].includes(defaultLiaSystem) ? defaultLiaSystem : 'DEFAULT';
}


export const robotSystemMap = {
  'MY_SYSTEM': 'My System',
  'DEFAULT': '2 over 1',
  'SEF': 'Standard French',
  'ACOL': 'Acol',
  'GAVIN_LIGHT': 'Gavin Light',
  'GAVIN_ADVANCED': 'Gavin Advanced',
  'SWEDISH': 'Expert Swedish',
  'SWEDISH_BASIC': 'Basic Swedish'
}
