export const rankLevelSortValues = {
  ace: 4,
  king: 3,
  queen: 2,
  jack: 1,
};

export function getHighestRank(ranks) {
  return ranks.sort(function(a, b) {
    return rankLevelSortValues[b] - rankLevelSortValues[a];
  })[0];
}

// Descending
export function sortByRank(users) {
  return users.sort(function(a, b) {
    if (a.rankTotalStars !== b.rankTotalStars) {
      return b.rankTotalStars - a.rankTotalStars;
    }

    if (a.rankLevel !== b.rankLevel) {
      return (
        rankLevelSortValues[b.rankLevel] - rankLevelSortValues[a.rankLevel]
      );
    }

    return b.rating - a.rating;
  });
}

export function getAdjacentRanks(rank) {
  const ranks = ['jack', 'queen', 'king', 'ace'];
  const myRankIndex = ranks.indexOf(rank);
  return ranks.slice(Math.max(myRankIndex - 1, 0), myRankIndex + 2);
}
