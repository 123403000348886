export const dealTypes = {
  "weak1N": {
    "sub": "",
    "tag": "weak1N",
    "id": "bHj4oMOa6Utls216JfXL",
    "description": "Dealer will have a balanced hand with 10-13 points.",
    "dealer": "N/S"
  },
  "weakish1N": {
    "sub": "club",
    "tag": "weakish1N",
    "id": "EgqcTfANp5smGFZACvW5",
    "description": "Dealer will have a balanced hand with 12-14 points.",
    "dealer": "N/S"
  },
  "strongish1N": {
    "sub": "club",
    "tag": "strongish1N",
    "id": "AywLezMCESJwkrkESajh",
    "description": "Dealer will have a balanced hand with 14-16 points.",
    "dealer": "N/S"
  },
  "strong1N": {
    "sub": "club",
    "tag": "strong1N",
    "id": "FrHiTYNtcoqDoBJPiWR1",
    "description": "Dealer will have a balanced hand with 15-17 points.",
    "dealer": "N/S"
  },
  "variedNT": {
    "sub": "club",
    "tag": "variedNT",
    "id": "wqoyU5IshvdaLxlFDWrF",
    "description": "Dealer will have a balanced hand. If N/S are Non Vulnerable, they will have a weak (10-13) hand. If they are Vulnerable, it will be strong (15-17).",
    "dealer": "N/S"
  },
  "stronger1N": {
    "sub": "club",
    "tag": "stronger1N",
    "id": "HYs9VxERtKOF9NBdiV51",
    "description": "Dealer will have a balanced hand with 18-19 points.",
    "dealer": "N/S"
  },
  "veryStrong1N": {
    "sub": "club",
    "tag": "veryStrong1N",
    "id": "sRBzUcr8lTc8UAH9KaMQ",
    "description": "Dealer will have a balanced hand with 20+ points.",
    "dealer": "N/S"
  },
  "majorOpen": {
    "sub": "club",
    "tag": "majorOpen",
    "id": "iQJoE7rQkbOljLZV1TWP",
    "description": "Dealer will have a 5 card major suit and opening values.",
    "dealer": "N/S"
  },
  "strong1C": {
    "sub": "club",
    "tag": "strong1C",
    "id": "JXjtnI29XLa7z9JOzmRy",
    "description": "Dealer will have a balanced hand with 16+ points.",
    "dealer": "N/S"
  },
  "strong2C": {
    "sub": "club",
    "tag": "strong2C",
    "id": "YmcAisPWzex45UuZNDeV",
    "description": "Dealer will have a balanced hand with 20+ points.",
    "dealer": "N/S"
  },
  "canape": {
    "sub": "diamond",
    "tag": "canape",
    "id": "sq1YSyYQZ5s7",
    "description": "Dealer will have a four card major and longer minor.",
    "dealer": "N/S"
  },
  "pdPreempt": {
    "sub": "diamond",
    "tag": "pdPreempt",
    "id": "Tjacmvji26eblNSKlU9u",
    "description": "Dealer will have a weak hand and should preempt.",
    "dealer": "N/S"
  },
  "vsPreempt": {
    "sub": "diamond",
    "tag": "vsPreempt",
    "ai": true,
    "id": "g4J0zIo42IpOaXGkUdxf",
    "description": "Dealer will have a weak hand and should preempt.",
    "dealer": "E/W"
  },
  "gameTry": {
    "sub": "diamond",
    "tag": "gameTry",
    "id": "20tBEEQZcmtyQJ8VZ61q",
    "description": "North and South will have a combined HCP value of at least 24.",
    "dealer": "?"
  },
  "slamTry": {
    "sub": "diamond",
    "tag": "slamTry",
    "id": "MhKZ5zkkv72p3RPQUqxx",
    "description": "North and South will have a combined HCP value of at least 31.",
    "dealer": "?"
  },
  "twoOverOne": {
    "sub": "diamond",
    "tag": "twoOverOne",
    "id": "tTmBOYFv6hSrxrPaxOm2",
    "description": "Dealer will have a 5+ card major and opening values, responder will have game forcing values.",
    "dealer": "N/S"
  },
  "twoSuiter": {
    "sub": "diamond",
    "tag": "twoSuiter",
    "id": "QzClYJCIThIsZDs39RxH",
    "description": "Dealer will have a hand with two 5+ card suits. Dealer will swap between South and North.",
    "dealer": "N/S"
  },
  "splinter": {
    "sub": "diamond",
    "tag": "splinter",
    "ai": false,
    "id": "hBfY1QTxHEjJJSKKjKDA",
    "description": "Dealer will have a 5+ card major and opening values. Partner will have 4 card support and shortness in a side suit.",
    "dealer": "N/S"
  },
  "jacoby2N": {
    "sub": "heart",
    "tag": "jacoby2N",
    "id": "rpUEzAb6p4N70GpAdmL6",
    "description": "Dealer will have a 5+ card major and opening values, responder will have 4+ support of that major and 10+ HCP.",
    "dealer": "N/S"
  },
  "gazzilli": {
    "sub": "heart",
    "tag": "gazzilli",
    "id": "kc5TiRENyA5UiG839ABC",
    "description": "Dealer will have a 5+ card major and opening values. Responder should respond 1S or 1NT, after which opener either has 16+ HCP or a natural 4 card club suit.",
    "dealer": "N/S"
  },
  "marmic": {
    "sub": "heart",
    "tag": "marmic",
    "id": "gE24SnEgCfDsRrjsCkhD",
    "description": "Dealer 5440 or 4441 and opening values.",
    "dealer": "N/S"
  },
  "majorsVsNT": {
    "sub": "heart",
    "tag": "majorsVsNT",
    "id": "YcNFOsvMl5dUv9zVY5wk",
    "description": "N/S will have a major fit and values for game. Balanced features will pose the question if the game is better played in 3NT or 4 of a major.",
    "dealer": "?"
  },
  "minorsVsNT": {
    "sub": "heart",
    "tag": "minorsVsNT",
    "id": "Zy89AemFSIgZENtkFVW6",
    "description": "N/S will have a minor fit and values for game. Balanced features will pose the question if the game is better played in 3NT or 5 of a minor.",
    "dealer": "?"
  },
  "vs1N": {
    "sub": "heart",
    "tag": "vs1N",
    "ai": true,
    "id": "02e9IaQugPfS862eP3jl",
    "description": "Dealer will have a balanced hand with 15-17 points and will open 1NT.",
    "dealer": "E/W"
  },
  "vs1NEntry": {
    "sub": "heart",
    "tag": "vs1NEntry",
    "ai": true,
    "id": "WyTyiVEZ6M7EzRL26D8s",
    "description": "Dealer will have a balanced hand with 15-17 points and should open 1NT. E/W will overcall.",
    "dealer": "N/S"
  },
  "michaels": {
    "sub": "heart",
    "tag": "michaels",
    "ai": true,
    "id": "uqnZ9km1FSyw7qV5zRpA",
    "description": "Dealer will have a 5+ card major and opening values. N/S will have a two suited overcall available.",
    "dealer": "E/W"
  },
  "oppsMichaels": {
    "sub": "heart",
    "tag": "oppsMichaels",
    "ai": true,
    "id": "VE3gvDzbbHfGt4XxgLQ1",
    "description": "Dealer will have a 5+ card major and opening values. E/W will make a two suited overcall.",
    "dealer": "N/S"
  },
  "vs2DMulti": {
    "sub": "heart",
    "tag": "vs2DMulti",
    "ai": true,
    "system": "MULTI",
    "id": "qV9AiFJxzZaOupRRcgVf",
    "description": "Dealer will have a 6+ card major and a weak hand, they will open with 2D multi.",
    "dealer": "E/W"
  },
  "weakTwoX": {
    "sub": "heart",
    "tag": "weakTwoX",
    "ai": true,
    "id": "VWJMuteMiW8ztEDdCB3c",
    "description": "Dealer will have a 6+ card major and a weak hand, they will open with 2M weak. N/S will have a takeout double.",
    "dealer": "E/W"
  }
};

export const tags = Object.keys(dealTypes);

export const aiSystemNotPlayableWithRobot = ["miniNT"];
export const tagsNotPlayableWithRobot = [];
export const aiSystemToLia = {
  'multi': 'MULTI'
}
