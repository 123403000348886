const miniNT = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*$/,
    explain: 'no one opened',
    options: [
      {
        bidWithoutAlert: '1N',
        bid: '1N["Balanced, 10-13"]',
        reqHp: 10,
        reqMaxHp: 13,
        reqS: 2,
        reqH: 2,
        reqD: 2,
        reqC: 2,
        reqMaxS: 5,
        reqMaxH: 5,
        reqMaxD: 6,
        reqMaxC: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-P$/,
    explain: 'Partner opens 1N, no interference',
    // Double Stayman
    options: [
      {
        // TODO: handle invitational hand with a major (4/5c)
        bid: '2C[@"Stayman"]',
        reqH: 4,
        reqS: 4,
        reqMaxHp: 13,
      },
      // {
      //   bid: '2C[@"Stayman"]',
      //   reqHp: 12,
      //   reqS: 4,
      // },
      // {
      //   bid: '2C[@"Stayman"]',
      //   reqHp: 12,
      //   reqH: 4,
      // },
      // Note: 6c M bids 4M with weight 2 below
      // TODO: Handle 5c M in 2D
      {
        bid: '2D[@"Stayman, gameforcing"]',
        reqHp: 14,
        reqH: 4,
      },
      {
        bid: '2D[@"Stayman, gameforcing"]',
        reqHp: 14,
        reqS: 4,
      },
      {
        bid: '2H["To play"]',
        reqH: 5,
      },
      {
        bid: '2S["To play"]',
        reqS: 5,
      },
      {
        bid: '3C["To play"]',
        reqC: 6,
      },
      {
        bid: '3D["To play"]',
        reqD: 6,
      },
      {
        bid: '3N["To play"]',
        reqHp: 14,
        reqMaxH: 3,
        reqMaxS: 3,
        weight: 2,
      },
      {
        bid: '4H["To play"]',
        reqHp: 13,
        reqH: 6,
        weight: 2,
      },
      {
        bid: '4S["To play"]',
        reqHp: 13,
        reqS: 6,
        weight: 2,
      }
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-P-2C-[PD]$/,
    explain: 'We open 1N, partner bids (weak) Stayman, no interference',
    options: [
      {
        bid: '2H["4+!H"]',
        reqH: 4,
      },
      {
        bid: '2S["4+!S"]',
        reqS: 4,
      },
      {
        bid: '2D[@"No 4-card M"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-P-2C-[PD]-2D-[PD]$/,
    explain: 'Partner opens 1N, we bid (weak) Stayman, opener denies major',
    options: [
      // TODO: This should not be garbage stayman
      {
        bid: 'P["!D, To play"]',
        reqD: 4,
      },
      {
        bid: '2H["Weak with both majors"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-P-2C-[PD]-2D-[PD]-2H-[PD]$/,
    explain: 'We open 1N, partner bids (weak) Stayman, and shows both majors (garbage)',
    options: [
      {
        bid: '2S["Preference"]',
        reqS: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-P-2D-[PD]$/,
    explain: 'We open 1N, partner bids GF Stayman, no interference',
    options: [
      {
        bid: '2H["4+!H"]',
        reqH: 4,
      },
      {
        bid: '2S["4+!S"]',
        reqS: 4,
      },
      {
        bid: '2N[@"No 4-card M"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-P-2D-..-P-(.|..)$/,
    explain: 'Competition over GF Stayman, responder can bid game',
    options: [
      {
        bid: '3N["To play"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-P-2D-[PD]-2H-(.|..)$/,
    explain: 'Partner shows hearts over GF Stayman',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 4,
      },
      {
        bid: '3N["To play"]',
      }
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-P-2D-[PD]-2H-(.|..)-3N-(.|..)$/,
    explain: 'We open 1N and show hearts over GF Stayman, can make preference to spades if responder bids 3N',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-P-2D-[PD]-2S-(.|..)$/,
    explain: 'Partner shows spades over GF Stayman',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 4,
      },
      {
        bid: '3N["To play"]',
      }
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-P-2D-[PD]-2N-(.|..)$/,
    explain: 'Partner opens 1N, we bid GF Stayman, opener denies major',
    options: [
      {
        bid: '3N["To play"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-D$/,
    explain: 'Partner opens 1N, they double',
    options: [
      {
        bid: '3N["To play"]',
        reqHp: 14,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1N-[23][CDHSN]$/,
    explain: 'Partner opens 1N, they overcall',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 6,
        reqHp: 13,
      },
      {
        bid: '4S["To play"]',
        reqS: 6,
        reqHp: 13,
      },
      {
        bid: '3N["To play"]',
        reqHp: 14,
      },
      {
        bid: '2D["To play"]',
        reqD: 5,
        reqHp: 6,
      },
      {
        bid: '2H["To play"]',
        reqH: 5,
        reqHp: 6,
      },
      {
        bid: '2S["To play"]',
        reqS: 5,
        reqHp: 6,
      },
      {
        bid: '3C["To play"]',
        reqC: 5,
        reqHp: 8,
      },
      {
        bid: '3C["To play"]',
        reqC: 6,
        reqHp: 6,
      },
      {
        bid: '3D["To play"]',
        reqD: 5,
        reqHp: 8,
      },
      {
        bid: '3D["To play"]',
        reqD: 6,
        reqHp: 6,
      },
      {
        bid: '3H["To play"]',
        reqH: 5,
        reqHp: 8,
      },
      {
        bid: '3H["To play"]',
        reqH: 6,
        reqHp: 6,
      },
      {
        bid: '3S["To play"]',
        reqS: 5,
        reqHp: 8,
      },
      {
        bid: '3S["To play"]',
        reqS: 6,
        reqHp: 6,
      },
    ],
  },
];

export default miniNT;
