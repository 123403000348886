export function getSubAndExtraTicketCharges(
  ticketFromSubscription,
  spentTickets,
  ticketCost,
) {
  let subTicketCharge = Math.min(
    ticketFromSubscription - spentTickets,
    ticketCost,
  );
  const extraTicketsCharge = ticketCost - Math.max(subTicketCharge, 0);
  subTicketCharge = Math.max(subTicketCharge, 0);

  return { subTicketCharge,
    extraTicketsCharge };
}
