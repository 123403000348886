import LanguageDisclaimer from '../../components/languageDisclaimer/LanguageDisclaimer.jsx'

export default function TermsOfUse() {
  return (
    <div className="page p-4">
      <LanguageDisclaimer />
      <article className="prose">
        <h1>1. AGREEMENT TO TERMS </h1>
        These Terms and Conditions constitute a legally binding agreement made
        between you, whether personally or on behalf of an entity (“you”) and
        Stockholm Bridge AB (&apos;Company&apos;, “we”, “us”, or “our”),
        concerning your access to and use of the https://www.cuebids.com website
        as well as any other media form, media channel, mobile website or mobile
        application related, linked, or otherwise connected thereto
        (collectively, the “Site”).
        <br />
        We are registered in Sweden and have our registered office at
        Ligustervägen 18, Älta 13839. Our VAT number is SE559390606701.
        <br />
        <strong>
          You agree that by accessing the Site, you have read, understood, and
          agree to be bound by all of these Terms and Conditions.
        </strong>
        <br /> <br />
        We reserve the right, in our sole discretion, to make changes or
        modifications to these Terms and Conditions from time to time.
        <br />
        <h1>2. INTELLECTUAL PROPERTY RIGHTS</h1>
        Unless otherwise indicated, the Site is our proprietary property and all
        source code, databases, functionality, software, website designs, audio,
        video, text, photographs, and graphics on the Site (collectively, the
        “Content”) and the trademarks, service marks, and logos contained
        therein (the “Marks”) are owned or controlled by us or licensed to us,
        and are protected by copyright and trademark laws and various other
        intellectual property rights and unfair competition laws of the United
        States, international copyright laws, and international conventions. The
        Content and the Marks are provided on the Site “AS IS” for your
        information and personal use only. Except as expressly provided in these
        Terms and Conditions, no part of the Site and no Content or Marks may be
        copied, reproduced, aggregated, republished, uploaded, posted, publicly
        displayed, encoded, translated, transmitted, distributed, sold,
        licensed, or otherwise exploited for any commercial purpose whatsoever,
        without our express prior written permission. Provided that you are
        eligible to use the Site, you are granted a limited license to access
        and use the Site and to download or print a copy of any portion of the
        Content to which you have properly gained access solely for your
        personal, non-commercial use. We reserve all rights not expressly
        granted to you in and to the Site, the Content and the Marks.
        <br />
        <br />
        <h1>3. USER REPRESENTATIONS</h1>
        By using the Site, you represent and warrant that: (1) all registration
        information you submit will be true, accurate, current, and complete;
        (2) you will maintain the accuracy of such information and promptly
        update such registration information as necessary; (3) you have the
        legal capacity and you agree to comply with these Terms and Conditions;
        (4) you will not access the Site through automated or non-human means,
        whether through a bot, script or otherwise; (5) you will not use the
        Site for any illegal or unauthorized purpose; and (6) your use of the
        Site will not violate any applicable law or regulation. If you provide
        any information that is untrue, inaccurate, not current, or incomplete,
        we have the right to suspend or terminate your account and refuse any
        and all current or future use of the Site (or any portion thereof).
        <br />
        <br />
        <h1>4. USER REGISTRATION</h1>
        You are required to register with the Site to access it. You agree to
        keep your password confidential and will be responsible for all use of
        your account and password. We reserve the right to remove, reclaim, or
        change a username you select if we determine, in our sole discretion,
        that such username is inappropriate, obscene, or otherwise
        objectionable.
        <br />
        <br />
        <h1>5. FEES AND PAYMENT </h1>
        On the iOS app, in regards to fees, payment and subscriptions, these
        terms will not apply, and instead the terms of Apple INC will apply. A
        link to those terms can be found from the store. -- -- We accept the
        following forms of payment:
        <ul>
          <li>Credit card</li>
          <li>Debit card</li>
          <li>Google Pay</li>
          <li>Paypal</li>
        </ul>
        <br />
        We use a third party &apos;Stripe&apos; for handling payments.
        <a href="https://www.stripe.com">www.stripe.com</a>. Issues with
        payments might be referred to Stripe.
        <br />
        If you subscribe to our services or buy anything via the Site, you agree
        to provide current, complete, and accurate purchase and account
        information for all purchases made via the Site. You further agree to
        promptly update account and payment information, including email
        address, payment method, and payment card expiration date, so that we
        can complete your transactions and contact you as needed. We bill you
        through an online billing account for purchases made via the Site. Sales
        tax might be added to the price of purchases as deemed required by us.
        We may change prices at any time. You agree to pay all charges or fees
        at the prices then in effect for your purchases, and you authorize us to
        charge your chosen payment provider for any such amounts upon making
        your purchase. If your purchase is subject to recurring charges, then
        you consent to our charging your payment method on a recurring basis
        without requiring your prior approval for each recurring charge, until
        you notify us of your cancellation. We reserve the right to correct any
        errors or mistakes in pricing, even if we have already requested or
        received payment. We also reserve the right to refuse any order placed
        through the Site.
        <br />
        <br />
        <h1>6. FREE TRIAL</h1> Occasionally we offer a free trial to new users
        who register with the Site. The account will be charged according to the
        user&apos;s chosen subscription at the end of the free trial.
        <br />
        <br />
        <h1>7. CANCELLATION</h1>
        You can cancel your subscription at any time by logging into your
        account (visit Subscription Management via the settings page) or
        contacting us using the contact information provided below. Your
        cancellation will take effect at the end of the current paid term. If
        you are unsatisfied with our services, please email us at
        emanuel@stockholmbridge.se.
        <br />
        <br />
        <h1>8. PROHIBITED ACTIVITIES</h1>
        You may not access or use the Site for any purpose other than that for
        which we make the Site available. The Site may not be used in connection
        with any commercial endeavors except those that are specifically
        endorsed or approved by us. As a user of the Site, you agree not to:
        Systematically retrieve data or other content from the Site to create or
        compile, directly or indirectly, a collection, compilation, database, or
        directory without written permission from us. Trick, defraud, or mislead
        us and other users, especially in any attempt to learn sensitive account
        information such as user passwords. Circumvent, disable, or otherwise
        interfere with security-related features of the Site, including features
        that prevent or restrict the use or copying of any Content or enforce
        limitations on the use of the Site and/or the Content contained therein.
        Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
        Site. Use any information obtained from the Site in order to harass,
        abuse, or harm another person. Make improper use of our support services
        or submit false reports of abuse or misconduct. Use the Site in a manner
        inconsistent with any applicable laws or regulations. Engage in
        unauthorized framing of or linking to the Site. Upload or transmit (or
        attempt to upload or to transmit) viruses, Trojan horses, or other
        material, including excessive use of capital letters and spamming
        (continuous posting of repetitive text), that interferes with any
        party&apos;s uninterrupted use and enjoyment of the Site or modifies,
        impairs, disrupts, alters, or interferes with the use, features,
        functions, operation, or maintenance of the Site. Engage in any
        automated use of the system, such as using scripts to send comments or
        messages, or using any data mining, robots, or similar data gathering
        and extraction tools. Delete the copyright or other proprietary rights
        notice from any Content. Attempt to impersonate another user or person
        or use the username of another user. Upload or transmit (or attempt to
        upload or to transmit) any material that acts as a passive or active
        information collection or transmission mechanism, including without
        limitation, clear graphics interchange formats (“gifs”), 1x1 pixels, web
        bugs, cookies, or other similar devices (sometimes referred to as
        “spyware” or “passive collection mechanisms” or “pcms”). Interfere with,
        disrupt, or create an undue burden on the Site or the networks or
        services connected to the Site. Harass, annoy, intimidate, or threaten
        any of our employees or agents engaged in providing any portion of the
        Site to you. Attempt to bypass any measures of the Site designed to
        prevent or restrict access to the Site, or any portion of the Site. Copy
        or adapt the Site&apos;s software, including but not limited to Flash,
        PHP, HTML, JavaScript, or other code. Except as permitted by applicable
        law, decipher, decompile, disassemble, or reverse engineer any of the
        software comprising or in any way making up a part of the Site. Except
        as may be the result of standard search engine or Internet browser
        usage, use, launch, develop, or distribute any automated system,
        including without limitation, any spider, robot, cheat utility, scraper,
        or offline reader that accesses the Site, or using or launching any
        unauthorized script or other software. Use a buying agent or purchasing
        agent to make purchases on the Site. Make any unauthorized use of the
        Site, including collecting usernames and/or email addresses of users by
        electronic or other means for the purpose of sending unsolicited email,
        or creating user accounts by automated means or under false pretenses.
        Use the Site as part of any effort to compete with us or otherwise use
        the Site and/or the Content for any revenue-generating endeavor or
        commercial enterprise.
        <br />
        <br />
        <h1>9. USER GENERATED CONTRIBUTIONS </h1>
        The Site may invite you to chat, contribute to, or participate in blogs,
        message boards, online forums, and other functionality, and may provide
        you with the opportunity to create, submit, post, display, transmit,
        perform, publish, distribute, or broadcast content and materials to us
        or on the Site, including but not limited to text, writings, video,
        audio, photographs, graphics, comments, suggestions, or personal
        information or other material (collectively, &apos;Contributions&apos;).
        Contributions may be viewable by other users of the Site and through
        third-party websites. As such, any Contributions you transmit may be
        treated as non-confidential and non-proprietary. When you create or make
        available any Contributions, you thereby represent and warrant that: The
        creation, distribution, transmission, public display, or performance,
        and the accessing, downloading, or copying of your Contributions do not
        and will not infringe the proprietary rights, including but not limited
        to the copyright, patent, trademark, trade secret, or moral rights of
        any third party. You are the creator and owner of or have the necessary
        licenses, rights, consents, releases, and permissions to use and to
        authorize us, the Site, and other users of the Site to use your
        Contributions in any manner contemplated by the Site and these Terms and
        Conditions. You have the written consent, release, and/or permission of
        each and every identifiable individual person in your Contributions to
        use the name or likeness of each and every such identifiable individual
        person to enable inclusion and use of your Contributions in any manner
        contemplated by the Site and these Terms and Conditions. Your
        Contributions are not false, inaccurate, or misleading. Your
        Contributions are not unsolicited or unauthorized advertising,
        promotional materials, pyramid schemes, chain letters, spam, mass
        mailings, or other forms of solicitation. Your Contributions are not
        obscene, lewd, lascivious, filthy, violent, harassing, libelous,
        slanderous, or otherwise objectionable (as determined by us). Your
        Contributions do not ridicule, mock, disparage, intimidate, or abuse
        anyone. Your Contributions are not used to harass or threaten (in the
        legal sense of those terms) any other person and to promote violence
        against a specific person or class of people. Your Contributions do not
        violate any applicable law, regulation, or rule. Your Contributions do
        not violate the privacy or publicity rights of any third party. Your
        Contributions do not violate any applicable law concerning child
        pornography, or otherwise intended to protect the health or well-being
        of minors. Your Contributions do not include any offensive comments that
        are connected to race, national origin, gender, sexual preference, or
        physical handicap. Your Contributions do not otherwise violate, or link
        to material that violates, any provision of these Terms and Conditions,
        or any applicable law or regulation. Any use of the Site in violation of
        the foregoing violates these Terms and Conditions and may result in,
        among other things, termination or suspension of your rights to use the
        Site.
        <br />
        <br />
        <h1>10. CONTRIBUTION LICENSE</h1>
        By posting your Contributions to any part of the Site or making
        Contributions accessible to the Site by linking your account from the
        Site to any of your social networking accounts, you automatically grant,
        and you represent and warrant that you have the right to grant, to us an
        unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
        transferable, royalty-free, fully-paid, worldwide right, and license to
        host, use, copy, reproduce, disclose, sell, resell, publish, broadcast,
        retitle, archive, store, cache, publicly perform, publicly display,
        reformat, translate, transmit, excerpt (in whole or in part), and
        distribute such Contributions (including, without limitation, your image
        and voice) for any purpose, commercial, advertising, or otherwise, and
        to prepare derivative works of, or incorporate into other works, such
        Contributions, and grant and authorize sublicenses of the foregoing. The
        use and distribution may occur in any media formats and through any
        media channels. This license will apply to any form, media, or
        technology now known or hereafter developed, and includes our use of
        your name, company name, and franchise name, as applicable, and any of
        the trademarks, service marks, trade names, logos, and personal and
        commercial images you provide. You waive all moral rights in your
        Contributions, and you warrant that moral rights have not otherwise been
        asserted in your Contributions. We do not assert any ownership over your
        Contributions. You retain full ownership of all of your Contributions
        and any intellectual property rights or other proprietary rights
        associated with your Contributions. We are not liable for any statements
        or representations in your Contributions provided by you in any area on
        the Site. You are solely responsible for your Contributions to the Site
        and you expressly agree to exonerate us from any and all responsibility
        and to refrain from any legal action against us regarding your
        Contributions. We have the right, in our sole and absolute discretion,
        (1) to edit, redact, or otherwise change any Contributions; (2) to
        re-categorize any Contributions to place them in more appropriate
        locations on the Site; and (3) to pre-screen or delete any Contributions
        at any time and for any reason, without notice. We have no obligation to
        monitor your Contributions.
        <br />
        <br />
        <h1>11. MOBILE APPLICATION LICENSE </h1>
        Use License If you access the Site via a mobile application, then we
        grant you a revocable, nonexclusive, non-transferable, limited right to
        install and use the mobile application on wireless electronic devices
        owned or controlled by you, and to access and use the mobile application
        on such devices strictly in accordance with the terms and conditions of
        this mobile application license contained in these Terms and Conditions.
        You shall not: (1) except as permitted by applicable law, decompile,
        reverse engineer, disassemble, attempt to derive the source code of, or
        decrypt the application; (2) make any modification, adaptation,
        improvement, enhancement, translation, or derivative work from the
        application; (3) violate any applicable laws, rules, or regulations in
        connection with your access or use of the application; (4) remove,
        alter, or obscure any proprietary notice (including any notice of
        copyright or trademark) posted by us or the licensors of the
        application; (5) use the application for any revenue generating
        endeavor, commercial enterprise, or other purpose for which it is not
        designed or intended; (6) make the application available over a network
        or other environment permitting access or use by multiple devices or
        users at the same time; (7) use the application for creating a product,
        service, or software that is, directly or indirectly, competitive with
        or in any way a substitute for the application; (8) use the application
        to send automated queries to any website or to send any unsolicited
        commercial e-mail; or (9) use any proprietary information or any of our
        interfaces or our other intellectual property in the design,
        development, manufacture, licensing, or distribution of any
        applications, accessories, or devices for use with the application.
        Apple and Android Devices The following terms apply when you use a
        mobile application obtained from either the Apple Store or Google Play
        (each an “App Distributor”) to access the Site: (1) the license granted
        to you for our mobile application is limited to a non-transferable
        license to use the application on a device that utilizes the Apple iOS
        or Android operating systems, as applicable, and in accordance with the
        usage rules set forth in the applicable App Distributor&apos;s terms of
        service; (2) we are responsible for providing any maintenance and
        support services with respect to the mobile application as specified in
        the terms and conditions of this mobile application license contained in
        these Terms and Conditions or as otherwise required under applicable
        law, and you acknowledge that each App Distributor has no obligation
        whatsoever to furnish any maintenance and support services with respect
        to the mobile application; (3) in the event of any failure of the mobile
        application to conform to any applicable warranty, you may notify the
        applicable App Distributor, and the App Distributor, in accordance with
        its terms and policies, may refund the purchase price, if any, paid for
        the mobile application, and to the maximum extent permitted by
        applicable law, the App Distributor will have no other warranty
        obligation whatsoever with respect to the mobile application; (4) you
        represent and warrant that (i) you are not located in a country that is
        subject to a U.S. government embargo, or that has been designated by the
        U.S. government as a “terrorist supporting” country and (ii) you are not
        listed on any U.S. government list of prohibited or restricted parties;
        (5) you must comply with applicable third-party terms of agreement when
        using the mobile application, e.g., if you have a VoIP application, then
        you must not be in violation of their wireless data service agreement
        when using the mobile application; and (6) you acknowledge and agree
        that the App Distributors are third-party beneficiaries of the terms and
        conditions in this mobile application license contained in these Terms
        and Conditions, and that each App Distributor will have the right (and
        will be deemed to have accepted the right) to enforce the terms and
        conditions in this mobile application license contained in these Terms
        and Conditions against you as a third-party beneficiary thereof.
        <br />
        <br />
        <h1>12. SUBMISSIONS</h1>
        You acknowledge and agree that any questions, comments, suggestions,
        ideas, feedback, or other information regarding the Site
        (&apos;Submissions&apos;) provided by you to us are non-confidential and
        shall become our sole property. We shall own exclusive rights, including
        all intellectual property rights, and shall be entitled to the
        unrestricted use and dissemination of these Submissions for any lawful
        purpose, commercial or otherwise, without acknowledgment or compensation
        to you. You hereby waive all moral rights to any such Submissions, and
        you hereby warrant that any such Submissions are original with you or
        that you have the right to submit such Submissions. You agree there
        shall be no recourse against us for any alleged or actual infringement
        or misappropriation of any proprietary right in your Submissions.
        <br />
        <br />
        <h1>13. THIRD-PARTY WEBSITES AND CONTENT</h1>
        The Site may contain (or you may be sent via the Site) links to other
        websites (&apos;ThirdParty Websites&apos;) as well as articles,
        photographs, text, graphics, pictures, designs, music, sound, video,
        information, applications, software, and other content or items
        belonging to or originating from third parties (&apos;Third-Party
        Content&apos;). Such Third-Party Websites and Third-Party Content are
        not investigated, monitored, or checked for accuracy, appropriateness,
        or completeness by us, and we are not responsible for any Third-Party
        Websites accessed through the Site or any Third-Party Content posted on,
        available through, or installed from the Site, including the content,
        accuracy, offensiveness, opinions, reliability, privacy practices, or
        other policies of or contained in the Third-Party Websites or the
        Third-Party Content. Inclusion of, linking to, or permitting the use or
        installation of any Third-Party Websites or any Third-Party Content does
        not imply approval or endorsement thereof by us. If you decide to leave
        the Site and access the Third-Party Websites or to use or install any
        Third-Party Content, you do so at your own risk, and you should be aware
        these Terms and Conditions no longer govern. You should review the
        applicable terms and policies, including privacy and data gathering
        practices, of any website to which you navigate from the Site or
        relating to any applications you use or install from the Site. Any
        purchases you make through Third-Party Websites will be through other
        websites and from other companies, and we take no responsibility
        whatsoever in relation to such purchases which are exclusively between
        you and the applicable third party. You agree and acknowledge that we do
        not endorse the products or services offered on Third-Party Websites and
        you shall hold us harmless from any harm caused by your purchase of such
        products or services. Additionally, you shall hold us harmless from any
        losses sustained by you or harm caused to you relating to or resulting
        in any way from any Third-Party Content or any contact with Third-Party
        Websites.
        <br />
        <br />
        <h1>14. SITE MANAGEMENT</h1>
        We reserve the right, but not the obligation, to: (1) monitor the Site
        for violations of these Terms and Conditions; (2) take appropriate legal
        action against anyone who, in our sole discretion, violates the law or
        these Terms and Conditions, including without limitation, reporting such
        user to law enforcement authorities; (3) in our sole discretion and
        without limitation, refuse, restrict access to, limit the availability
        of, or disable (to the extent technologically feasible) any of your
        Contributions or any portion thereof; (4) in our sole discretion and
        without limitation, notice, or liability, to remove from the Site or
        otherwise disable all files and content that are excessive in size or
        are in any way burdensome to our systems; and (5) otherwise manage the
        Site in a manner designed to protect our rights and property and to
        facilitate the proper functioning of the Site.
        <br />
        <br />
        <h1>15. PRIVACY POLICY</h1>
        We care about data privacy and security. Please review our Privacy
        Policy: https://www.cuebids.com/privacy. By using the Site, you agree to
        be bound by our Privacy Policy, which is incorporated into these Terms
        and Conditions. Please be advised the Site is hosted in Europe (EU). If
        you access the Site from any other region of the world with laws or
        other requirements governing personal data collection, use, or
        disclosure that differ from applicable laws in Europe, then through your
        continued use of the Site, you are transferring your data to Europe, and
        you agree to have your data transferred to and processed in Europe.
        <br />
        <br />
        <h1>16. COPYRIGHT INFRINGEMENTS</h1> We respect the intellectual
        property rights of others. If you believe that any material available on
        or through the Site infringes upon any copyright you own or control,
        please immediately notify us using the contact information provided
        below (a “Notification”). A copy of your Notification will be sent to
        the person who posted or stored the material addressed in the
        Notification. Please be advised that pursuant to applicable law you may
        be held liable for damages if you make material misrepresentations in a
        Notification. Thus, if you are not sure that material located on or
        linked to by the Site infringes your copyright, you should consider
        first contacting an attorney.
        <br />
        <br />
        <h1>17. TERM AND TERMINATION</h1>
        These Terms and Conditions shall remain in full force and effect while
        you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS
        AND CONDITIONS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
        WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
        (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON
        OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
        REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS AND
        CONDITIONS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
        USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT
        OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
        DISCRETION. If we terminate or suspend your account for any reason, you
        are prohibited from registering and creating a new account under your
        name, a fake or borrowed name, or the name of any third party, even if
        you may be acting on behalf of the third party. In addition to
        terminating or suspending your account, we reserve the right to take
        appropriate legal action, including without limitation pursuing civil,
        criminal, and injunctive redress.
        <br />
        <h1>18. MODIFICATIONS AND INTERRUPTIONS</h1> We reserve the right to
        change, modify, or remove the contents of the Site at any time or for
        any reason at our sole discretion without notice. However, we have no
        obligation to update any information on our Site. We also reserve the
        right to modify or discontinue all or part of the Site without notice at
        any time. We will not be liable to you or any third party for any
        modification, price change, suspension, or discontinuance of the Site.
        We cannot guarantee the Site will be available at all times. We may
        experience hardware, software, or other problems or need to perform
        maintenance related to the Site, resulting in interruptions, delays, or
        errors. We reserve the right to change, revise, update, suspend,
        discontinue, or otherwise modify the Site at any time or for any reason
        without notice to you. You agree that we have no liability whatsoever
        for any loss, damage, or inconvenience caused by your inability to
        access or use the Site during any downtime or discontinuance of the
        Site. Nothing in these Terms and Conditions will be construed to
        obligate us to maintain and support the Site or to supply any
        corrections, updates, or releases in connection therewith.
        <br />
        <br />
        <h1>19. GOVERNING LAW</h1> These conditions are governed by and
        interpreted following the laws of Sweden, and the use of the United
        Nations Convention of Contracts for the International Sale of Goods is
        expressly excluded. If your habitual residence is in the EU, and you are
        a consumer, you additionally possess the protection provided to you by
        obligatory provisions of the law of your country of residence. Stockholm
        Bridge AB and yourself both agree to submit to the non-exclusive
        jurisdiction of the courts of Sweden, which means that you may make a
        claim to defend your consumer protection rights in regards to these
        Conditions of Use in Sweden, or in the EU country in which you reside.
        <br />
        <br />
        <h1>20. DISPUTE RESOLUTION</h1>
        Informal Negotiations To expedite resolution and control the cost of any
        dispute, controversy, or claim related to these Terms and Conditions
        (each &apos;Dispute&apos; and collectively, the “Disputes”) brought by
        either you or us (individually, a “Party” and collectively, the
        “Parties”), the Parties agree to first attempt to negotiate any Dispute
        (except those Disputes expressly provided below) informally for at least
        90 days before initiating arbitration. Such informal negotiations
        commence upon written notice from one Party to the other Party. <br />
        <br />
        <br />
        <strong>Binding Arbitration</strong> <br />
        Any dispute arising from the relationships between the Parties to this
        contract shall be determined by one arbitrator who will be chosen in
        accordance with the Arbitration and Internal Rules of the European Court
        of Arbitration being part of the European Centre of Arbitration having
        its seat in Strasbourg, and which are in force at the time the
        application for arbitration is filed, and of which adoption of this
        clause constitutes acceptance. The seat of arbitration shall be Sweden.
        The language of the proceedings shall be in English. Applicable rules of
        substantive law shall be the law of Sweden. Restrictions The Parties
        agree that any arbitration shall be limited to the Dispute between the
        Parties individually. To the full extent permitted by law, (a) no
        arbitration shall be joined with any other proceeding; (b) there is no
        right or authority for any Dispute to be arbitrated on a class-action
        basis or to utilize class action procedures; and (c) there is no right
        or authority for any Dispute to be brought in a purported representative
        capacity on behalf of the general public or any other persons.
        Exceptions to Informal Negotiations and Arbitration The Parties agree
        that the following Disputes are not subject to the above provisions
        concerning informal negotiations and binding arbitration: (a) any
        Disputes seeking to enforce or protect, or concerning the validity of,
        any of the intellectual property rights of a Party; (b) any Dispute
        related to, or arising from, allegations of theft, piracy, invasion of
        privacy, or unauthorized use; and (c) any claim for injunctive relief.
        If this provision is found to be illegal or unenforceable, then neither
        Party will elect to arbitrate any Dispute falling within that portion of
        this provision found to be illegal or unenforceable and such Dispute
        shall be decided by a court of competent jurisdiction within the courts
        listed for jurisdiction above, and the Parties agree to submit to the
        personal jurisdiction of that court.
        <br />
        <br />
        <h1>21. CORRECTIONS</h1> There may be information on the Site that
        contains typographical errors, inaccuracies, or omissions, including
        descriptions, pricing, availability, and various other information. We
        reserve the right to correct any errors, inaccuracies, or omissions and
        to change or update the information on the Site at any time, without
        prior notice.
        <br />
        <br />
        <h1>22. DISCLAIMER</h1>
        THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
        YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE
        FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
        IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING,
        WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
        FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE&apos;S
        CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL
        ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
        INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
        OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
        AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
        STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
        FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH
        MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
        ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
        DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
        POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT
        WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
        SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY
        HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
        ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
        WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
        USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
        <br />
        <br />
        <h1>23. LIMITATIONS OF LIABILITY</h1> IN NO EVENT WILL WE OR OUR
        DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR
        ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
        PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
        OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO
        THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
        WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES
        BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE TWO (2)
        MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS
        AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
        THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
        YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY
        TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        <br />
        <br />
        <h1>24. INDEMNIFICATION </h1>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys&apos; fees and
        expenses, made by any third party due to or arising out of: (1) your
        Contributions; (2) use of the Site; (3) breach of these Terms and
        Conditions; (4) any breach of your representations and warranties set
        forth in these Terms and Conditions; (5) your violation of the rights of
        a third party, including but not limited to intellectual property
        rights; or (6) any overt harmful act toward any other user of the Site
        with whom you connected via the Site. Notwithstanding the foregoing, we
        reserve the right, at your expense, to assume the exclusive defense and
        control of any matter for which you are required to indemnify us, and
        you agree to cooperate, at your expense, with our defense of such
        claims. We will use reasonable efforts to notify you of any such claim,
        action, or proceeding which is subject to this indemnification upon
        becoming aware of it.
        <br />
        <br />
        <h1>25. USER DATA</h1>
        We will maintain certain data that you transmit to the Site for the
        purpose of managing the performance of the Site, as well as data
        relating to your use of the Site. Although we perform regular routine
        backups of data, you are solely responsible for all data that you
        transmit or that relates to any activity you have undertaken using the
        Site. You agree that we shall have no liability to you for any loss or
        corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.
        <br />
        <br />
        <h1>26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES </h1>
        Visiting the Site, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Site, satisfy any legal requirement that such communication be in
        writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE SITE. You hereby waive any rights or requirements under
        any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.
        <br />
        <br />
        <h1>27. MISCELLANEOUS </h1>
        These Terms and Conditions and any policies or operating rules posted by
        us on the Site or in respect to the Site constitute the entire agreement
        and understanding between you and us. Our failure to exercise or enforce
        any right or provision of these Terms and Conditions shall not operate
        as a waiver of such right or provision. These Terms and Conditions
        operate to the fullest extent permissible by law. We may assign any or
        all of our rights and obligations to others at any time. We shall not be
        responsible or liable for any loss, damage, delay, or failure to act
        caused by any cause beyond our reasonable control. If any provision or
        part of a provision of these Terms and Conditions is determined to be
        unlawful, void, or unenforceable, that provision or part of the
        provision is deemed severable from these Terms and Conditions and does
        not affect the validity and enforceability of any remaining provisions.
        There is no joint venture, partnership, employment or agency
        relationship created between you and us as a result of these Terms and
        Conditions or use of the Site. You agree that these Terms and Conditions
        will not be construed against us by virtue of having drafted them. You
        hereby waive any and all defenses you may have based on the electronic
        form of these Terms and Conditions and the lack of signing by the
        parties hereto to execute these Terms and Conditions.
        <br />
        <br />
        <h1>28. CONTACT US</h1>
        In order to resolve a complaint regarding the Site or to receive further
        information regarding use of the Site, please contact us at:
        <br />
        <br />
        Stockholm Bridge AB <br />
        Ligustervägen 18
        <br />
        Älta 13839 <br />
        Sweden
        <br />
        <br />
        or: <br />
        emanuel@stockholmbridge.se
        <br />
        <br />
        <div>
          These terms of use were created using Termly&apos;s{' '}
          <a
            className="link-hover link"
            href="https://termly.io/products/terms-and-conditions-generator/"
          >
            Terms and Conditions Generator
          </a>
          .
        </div>
      </article>
    </div>
  );
}
