import {
  getLatestEventObservable,
  joinEvent,
  getEventById,
  checkIfAlreadyPlaying,
} from './events';
import { updateUser } from './userApi';
import { getRobotUserId } from '../util/robotPartner'

export function getWeeklyObservable({ callback }) {
  return getLatestEventObservable({
    eventCollection: 'weeklySession',
    callback,
  });
}

export async function getWeeklyById(id) {
  return getEventById({ eventCollection: 'weeklySession',
    eventId: id });
}

export async function joinWeeklySession({ id, partnerUserId, liaSystem }) {
  void updateUser({ weeklyDefaultPartner: partnerUserId });
  return joinEvent({
    eventCollection: 'weeklySession',
    eventId: id,
    partnerUserId,
    defaultPrice: 24,
    partnerAllowedToPlayMultiple: partnerUserId === getRobotUserId(),
    liaSystem
  });
}

export async function checkIfAlreadyPlayingWeekly({ id, userId }) {
  return await checkIfAlreadyPlaying({
    eventCollection: 'weeklySession',
    eventId: id,
    userId,
  });
}
