import { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChallengeIcon } from '../icons/challengeIcon';
import goToStore from '../../util/goToStore';
import {
  getCurrentOnboardingStep,
  onboardingSteps,
} from '../../util/onboarding';
import Animated from '../animation/animated';
import { PracticeIcon } from '../icons/practiceIcon';
import { EventIcon } from '../icons/dailyIcon';
import { FriendsIcon } from '../icons/friendsIcon';
import { HandshakeIcon } from '../icons/handShakeIcon';
import { useAuth, useOutsideClick } from '../../util/hooks';
import { useAppStateStore } from '../../appStateStore.jsx';
import { RobotIcon } from '../icons/robotIcon.jsx';
import { useTranslation } from 'react-i18next';

export function GridCard({
  name,
  icon,
  i,
  onClick,
  disabled,
  badgeColor,
  badgeContent,
}) {
  const btn = (
    <button
      className="btn-ghost btn-square btn-lg btn h-[100px] w-[100px] border-2 border-slate text-xs"
      disabled={disabled}
    >
      <div
        className={
          'm-4 flex flex-col items-center justify-center' +
          (disabled ? ' opacity-25' : '')
        }
      >
        {icon}
        <span>{name}</span>
      </div>
    </button>
  );
  return (
    <Animated
      variants={{
        hidden: {
          opacity: 0,
          scale: 0,
        },
        visible: (i) => ({
          opacity: 1,
          scale: 1,
          transition: {
            delay: i * 0.1,
          },
        }),
      }}
      initial="hidden"
      exit="hidden"
      key={name}
      animate="visible"
      style={{ width: '100%' }}
      custom={i}
      onClick={onClick}
    >
      {badgeContent ? (
        <div className="indicator" key={'bad' + i}>
          <span className={'badge indicator-item right-4 top-4 ' + badgeColor}>
            {badgeContent}
          </span>
          {btn}
        </div>
      ) : (
        btn
      )}
    </Animated>
  );
}

export default function CardGrid({ isNative, iap }) {
  const [showSubMenu, setShowSubMenu] = useState();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const user = useAppStateStore((state) => state.user);
  const nav = useNavigate();
  const wrapperRef = useRef();

  const { t } = useTranslation()

  const handleToggleSubMenu = (id) => {
    if (showSubMenu === id) {
      setShowSubMenu();
      return;
    }

    setShowSubMenu(id);
  };

  const handleClickStore = async () => {
    setLoading(true);
    await goToStore({ nav,
      isNative,
      iap,
      uid: currentUser.uid });
    setLoading(false);
  };

  const showOnboardingBadge =
    getCurrentOnboardingStep({ user }) === onboardingSteps.createSession;

  useOutsideClick(wrapperRef, () => setShowSubMenu());

  const noCompete = user.hiddenSections?.includes('Events') && user.hiddenSections?.includes('Challenges');

  return (
    <div className="mt-4" key={'card-grids'} ref={wrapperRef}>
      <div className={'grid grid-cols-3 gap-2'}>
        <GridCard
          name={t('routes.practice')}
          key={'prac'}
          icon={<PracticeIcon fill="#4d774e" width="32px" />}
          i={1}
          disabled={showSubMenu && showSubMenu !== 1}
          onClick={() => handleToggleSubMenu(1)}
          badgeColor={
            showOnboardingBadge ? 'badge-error w-5 pulse' : 'badge-info'
          }
          badgeContent={showOnboardingBadge ? ' ' : ''}
        />
        {!noCompete ? (
          <GridCard
            name={t('routes.compete')}
            icon={
              <svg
                className="h-8 w-8"
                fill="#e29627"
                stroke="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M20,2H4V4L9.81,8.36C6.14,9.57 4.14,13.53 5.35,17.2C6.56,20.87 10.5,22.87 14.19,21.66C17.86,20.45 19.86,16.5 18.65,12.82C17.95,10.71 16.3,9.05 14.19,8.36L20,4V2M14.94,19.5L12,17.78L9.06,19.5L9.84,16.17L7.25,13.93L10.66,13.64L12,10.5L13.34,13.64L16.75,13.93L14.16,16.17L14.94,19.5Z" />
              </svg>
            }
            key={'other'}
            i={2}
            disabled={showSubMenu && showSubMenu !== 2}
            onClick={() => handleToggleSubMenu(2)}
          />
        ) : (
          <div />
        )}
        <GridCard
          name={loading ? t('app.opening') : t('routes.store') }
          icon={
            <svg
              className="h-8 w-8"
              fill="#9f4e4e"
              stroke="none"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
              ></path>
            </svg>
          }
          key={'store'}
          disabled={showSubMenu || (isNative && !iap) || loading}
          onClick={handleClickStore}
          i={3}
        />
      </div>
      {showSubMenu === 1 && (
        <div className="mt-2 grid grid-cols-3 gap-2" key={'play-menu'}>
          <Link to={'/session'} key={'quick-friend-sub-menu-item'}>
            <GridCard
              name={t('routes.with_friends') }
              icon={<FriendsIcon strokeWidth={0.5} fill="#4d774e" width={32} />}
              i={1}
              badgeColor={showOnboardingBadge && 'badge-error pulse w-5'}
              badgeContent={showOnboardingBadge && ' '}
            />
          </Link>
          {!user?.hiddenSections?.includes('Matchmaking') && (
            <Link to={'/practice/match'} key={'events-sub-menu-item'}>
              <GridCard
                name={t('routes.with_random') }
                icon={<HandshakeIcon fill="#e29627" width="32px" />}
                i={2}
              />
            </Link>
          )}
          {!user?.hiddenSections?.includes('Challenges') && (
            <Link to={'/challengeSession'} key={'challenge-sub-menu-item'}>
              <GridCard
                name={t('routes.challenge_friends') }
                icon={
                  <FriendsIcon strokeWidth={0.5} fill="#9f4e4e" width={32} />
                }
                i={3}
              />
            </Link>
          )}
          {!user?.hiddenSections?.includes('Robots') && (
            <Link to={'/session?pd=lia'} key={'robot-sub-menu-item'}>
              <GridCard
                name={t('routes.with_robot') }
                icon={<RobotIcon className={'fill-sky-400'} width="32px" />}
                i={4}
              />
            </Link>
          )}
        </div>
      )}
      {showSubMenu === 2 && (
        <div className="mt-2 grid grid-cols-3 gap-2" key={'social-menu'}>
          {!user?.hiddenSections?.includes('Events') && (
            <Link to={'/daily'} key={'daily-sub-menu-item'}>
              <GridCard
                name={t('routes.daily_event') }
                icon={<EventIcon fill={'#4d774e'} width={32} />}
                i={1}
              />
            </Link>
          )}
          {!user?.hiddenSections?.includes('Events') && (
            <Link to={'/weekly'} key={'weekly-sub-menu-item'}>
              <GridCard
                name={t('routes.weekly_event') }
                icon={<EventIcon fill={'#e29627'} width={32} />}
                i={2}
              />
            </Link>
          )}
          {!user?.hiddenSections?.includes('Challenges') && (
            <Link to={'/challenge/match'} key={'ranked-sub-menu-item'}>
              <GridCard
                name={t('routes.ranked') }
                icon={<ChallengeIcon fill="#9f4e4e" width={32} />}
                i={3}
              />
            </Link>
          )}
        </div>
      )}
    </div>
  );
}
