import { dealTypes } from 'cuebids-deals'
import { useTranslation } from 'react-i18next'

export default function TagsDisplay({ tags, tagNames, sx = '' }) {
  const { t } = useTranslation();

  if (!tags) return;

  const values = Object.values(dealTypes);

  return (
    <div
      className={`flex flex-wrap justify-center gap-2 ${sx}`}
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '8px',
      }}
    >
      {values.filter(s => tags.includes(s.tag)).map((tag, i) => (
        <div className="badge-accent badge" key={tag.tag}>
          {(tagNames ?? [])[i] ?? t(`deal_types.${tag.tag}`)}
        </div>
      ))}
    </div>
  );
}
