import Checkbox from '../checkbox/Checkbox.jsx'
import Dropdown from '../dropdown/dropdown.jsx'
import Dialog from '../dialog/index.jsx'
import { useState } from 'react'
import Spade from '../../images/suits/Spade.jsx'
import Heart from '../../images/suits/Heart.jsx'
import Diamond from '../../images/suits/Diamond.jsx'
import Club from '../../images/suits/Club.jsx'
import InfoPopup from '../infoPopup/infoPopup.jsx'
import { sortSuits, sortVariables } from '../../util/scenario.js'
import { useTranslation } from 'react-i18next'

const suitIconsMap = {
  'spades': <Spade key="spade" />,
  'hearts': <Heart key="heart" />,
  'diamonds': <Diamond key="diamond" />,
  'clubs': <Club key="club" />,
};

function getValues(values, max) {
  const parse1 = parseInt(values[0])
  const parse2 = parseInt(values[1])

  const val1 = Math.min(isNaN(parse1) ? 0 : parse1, max);
  const val2 = Math.min(Math.min(isNaN(parse2) ? 40 : parse2), max);

  return [val1, val2]
}

export default function DirectionSettings({ settings, onUpdateSettings }) {
  const { t } = useTranslation('translation', { keyPrefix: 'scenarios.settings' });
  const settingsDealer = settings.dealer ?? 'any';
  const settingsVulnerability = settings.vulnerability ?? 'any';
  const settingsFlip = settings.flip ?? false;
  const settingsNsHcpRange = settings.nsHcpRange ?? [0, 40];
  const settingsRobots = settings.robots ?? (settings.disableRobots ? 'NONE' : 'ADVANCED');
  const settingsVariables = settings.variables ?? {};

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dealer, setDealer] = useState(settingsDealer);
  const [vulnerability, setVulnerability] = useState(settingsVulnerability);
  const [flip, setFlip] = useState(settingsFlip);
  const [hasNsHcpRange, setHasNsHcpRange] = useState(settingsNsHcpRange[0] !== 0 || settingsNsHcpRange[1] !== 40);
  const [nsHcpRange, setNsHcpRange] = useState(settingsNsHcpRange);
  const [robots, setRobots] = useState(settingsRobots);
  const [variables, setVariables] = useState(settingsVariables);
  // Note: new variables should also be set in handleOpenSettings, in case they have been modified from addHandTypeDialog.
  const handleOpenSettings = () => {
    setDealer(settingsDealer);
    setVulnerability(settingsVulnerability);
    setFlip(settingsFlip);
    setHasNsHcpRange(settingsNsHcpRange[0] !== 0 || settingsNsHcpRange[1] !== 40);
    setNsHcpRange(settingsNsHcpRange);
    setRobots(settingsRobots);
    setVariables(settingsVariables);
    setDialogOpen(true);
  };

  const handleNsHcpChange = (index) => (event) => {
    const newValues = [...nsHcpRange];
    newValues[index] = event.target.value;
    // const vals = getValues(newValues, 40);
    setNsHcpRange(newValues);
  };

  const handleNsHcpBlur = (index) => () => {
    const newValues = [...nsHcpRange]
    if (isNaN(nsHcpRange[index])) {
      newValues[index] = 0
      setNsHcpRange(newValues)
    }

    if (index === 0 && parseInt(newValues[0]) > parseInt(newValues[1])) {
      newValues[1] = newValues[0]
    } else if (index === 1 && parseInt(newValues[1]) < parseInt(newValues[0])) {
      newValues[0] = newValues[1]
    }

    const vals = getValues(newValues, 40)

    setNsHcpRange(vals)
  }

  const handleAddVariable = () => {
    for (const variable of ['X', 'Y', 'Z', 'W']) {
      if (variables[variable] == null) {
        setVariables(vs => ({
          ...vs,
          [variable]: [],
        }));
        return;
      }
    }
  }

  const handleRemoveVariable = (variable) => {
    setVariables(vs => {
      const newVariables = { ...vs };
      delete newVariables[variable];
      return newVariables;
    })
  }

  const handleSuitsForVariableChange = (variable, suit) => {
    setVariables(function (vs) {
      let newSuits = [...vs[variable]];
      const index = newSuits.indexOf(suit);
      if (index > -1) {
        newSuits.splice(index, 1);
      } else {
        newSuits.push(suit);
        newSuits = sortSuits(newSuits);
      }
      return {
        ...vs,
        [variable]: newSuits,
      };
    });
  };

  const dealerDropdownOptions = [{
    label: t('dialog.dealer_north'),
    value: 'N'
  }, {
    label: t('dialog.dealer_east'),
    value: 'E'
  }, {
    label: t('dialog.dealer_south'),
    value: 'S'
  }, {
    label: t('dialog.dealer_west'),
    value: 'W'
  }];

  const dealerDropdownAnyOption = {
    label: t('dialog.dealer_any'),
    value: 'any',
  }

  const vulnerabilityDropdownOptions = [{
    label: t('dialog.vul_none'),
    value: 'NONE'
  }, {
    label: t('dialog.vul_ns'),
    value: 'NS'
  }, {
    label: t('dialog.vul_ew'),
    value: 'EW'
  }, {
    label: t('dialog.vul_all'),
    value: 'ALL'
  }];

  const vulnerabilityDropdownAnyOption = {
    label: t('dialog.vul_any'),
    value: 'any',
  }

  const vulnerabilityShortLabels = {
    NONE: t('dialog.vul_none_short'),
    NS: t('dialog.vul_ns_short'),
    EW: t('dialog.vul_ew_short'),
    ALL: t('dialog.vul_all_short'),
  };

  const robotOptionLabels = {
    NONE: t('dialog.robots_none'),
    ADVANCED: t('dialog.robots_advanced'),
    MULTI: t('dialog.robots_multi'),
    'MINI_NT': t('dialog.robots_mini_nt'),
  };

  const robotDropdownOptions =  [{
    label: t('dialog.robots_none'),
    value: 'NONE'
  }, {
    label: t('dialog.robots_advanced'),
    value: 'ADVANCED'
  }, {
    label: t('dialog.robots_multi'),
    value: 'MULTI'
  }, {
    label: t('dialog.robots_mini_nt'),
    value: 'MINI_NT'
  }];

  return (
    <div className="flex flex-col items-center">
      <button
        className="btn btn-secondary btn-sm h-4 mt-3 mb-1 px-1"
        onClick={handleOpenSettings}
      >
        {t('settings_button')}
      </button>
      <div className="text-xs mb-1 text-rose-500">
        {settingsFlip && (
          <div className="info">
            <strong>{t('flip')}</strong>
          </div>
        )}
        {settingsDealer !== 'any' && (
          <div className="info">
            {t('dealer')}<strong>{settingsDealer}</strong>
          </div>
        )}
        {settingsVulnerability !== 'any' && (
          <div className="info">
            {t('vul')}
            <strong>{vulnerabilityShortLabels[settingsVulnerability]}</strong>
          </div>
        )}
        {(settingsNsHcpRange[0] !== 0 || settingsNsHcpRange[1] !== 40) && (
          <div className="info">
            {t('ns_hcp')}
            <strong>
              {settingsNsHcpRange[0]} to {settingsNsHcpRange[1]}
            </strong>
          </div>
        )}
        {settingsRobots && (
          <div className="info">
            {t('robots')}<strong>{robotOptionLabels[settingsRobots]}</strong>
          </div>
        )}
        {sortVariables(Object.keys(settingsVariables)).map(function (variable) {
          const suits = settingsVariables[variable];
          if (suits.length > 0) {
            return (
              <div key={variable} className="info flex items-center gap-1">
                <strong>{variable} =</strong>
                {suits.map((s) => suitIconsMap[s])}
              </div>
            );
          }
        })}
      </div>

      <Dialog
        id="add-hand-type-dialog"
        open={dialogOpen}
        className="max-w-[200px]"
        onClose={() => setDialogOpen(false)}
        title={t('dialog.title')}
        sx={'w-[400px]'}
      >
        <div className="w-full h-[500px]">
          <div>
            <div className="p-3 flex items-center">
              <Checkbox
                label={t('dialog.flip_label')}
                checked={flip}
                onChange={() => setFlip((r) => !r)}
              />
            </div>
            <div className="p-3 flex items-center gap-2">
              <span className="w-24">{t('dialog.dealer_label')}</span>
              <div className="w-24">
                <Dropdown
                  value={dealer}
                  label={t('dialog.dealer_label')}
                  options={(dealer === 'any' ? [] : [dealerDropdownAnyOption])
                    .concat(dealerDropdownOptions)
                    .map(function ({ value, label }) {
                      return {
                        label,
                        value,
                        component: (
                          <button
                            className="btn-ghost btn flex justify-start items-center content-center"
                            onClick={() => setDealer(value)}
                          >
                            {label}
                          </button>
                        ),
                      };
                    })}
                />
              </div>
            </div>
            <div className="p-3 flex items-center gap-2">
              <span className="w-24">{t('dialog.vul_label')}</span>
              <div className="w-24">
                <Dropdown
                  value={vulnerability}
                  label={t('dialog.vul_label')}
                  options={(vulnerability === 'any'
                    ? []
                    : [vulnerabilityDropdownAnyOption]
                  )
                    .concat(vulnerabilityDropdownOptions)
                    .map(function ({ value, label }) {
                      return {
                        label,
                        value,
                        component: (
                          <button
                            className="btn-ghost btn flex justify-start items-center content-center"
                            onClick={() => setVulnerability(value)}
                          >
                            {label}
                          </button>
                        ),
                      };
                    })}
                />
              </div>
            </div>
            <div className="p-3 flex items-center gap-2">
              <span className="w-24">{t('dialog.robots_label')}</span>
              <div className="w-24">
                <Dropdown
                  value={robots}
                  label={t('dialog.robots_label')}
                  options={robotDropdownOptions.map(function ({
                    value,
                    label,
                  }) {
                    return {
                      label,
                      value,
                      component: (
                        <button
                          className="btn-ghost btn flex justify-start items-center content-center"
                          onClick={() => setRobots(value)}
                        >
                          {label}
                        </button>
                      ),
                    };
                  })}
                />
              </div>
            </div>
            <div className="p-3 flex items-center gap-2">
              <Checkbox
                label={t('dialog.ns_hcp_label')}
                checked={hasNsHcpRange}
                onChange={() => setHasNsHcpRange((r) => !r)}
              />
              <input
                type="number"
                min={0}
                max={40}
                value={nsHcpRange[0]}
                onChange={handleNsHcpChange(0)}
                onBlur={handleNsHcpBlur(0)}
                className={`input input-primary w-20 mr-2 ${
                  !hasNsHcpRange ? 'opacity-25' : ''
                }`}
                onFocus={function () {
                  setHasNsHcpRange(true);
                }}
              />
              {t('dialog.to')}
              <input
                type="number"
                min={0}
                max={40}
                value={nsHcpRange[1]}
                onChange={handleNsHcpChange(1)}
                onBlur={handleNsHcpBlur(1)}
                className={`input input-primary w-20 ml-2 ${
                  !hasNsHcpRange ? 'opacity-25' : ''
                }`}
                onFocus={function () {
                  setHasNsHcpRange(true);
                }}
              />
            </div>
            <div className="p-3">
              <div className="flex items-center gap-4">
                <button
                  className="btn btn-sm btn-primary h-2"
                  disabled={Object.keys(variables).length >= 4}
                  onClick={handleAddVariable}
                >
                  {t('dialog.add_variable_button')}
                </button>
                <InfoPopup id={'variables'} sx={'stroke-info'}>
                  <div className="flex flex-col items-start">
                    <span className="text-info">
                      {t('dialog.var_info_1')}
                    </span>
                    <span className="text-info">
                      {t('dialog.var_info_2')}
                    </span>
                  </div>
                </InfoPopup>
              </div>
              {sortVariables(Object.keys(variables)).map(function (variable) {
                return (
                  <div
                    className="flex items-center justify-between"
                    key={variable}
                  >
                    <span>{variable}</span>
                    {['spades', 'hearts', 'diamonds', 'clubs'].map((suit) => {
                      return (
                        <div
                          className="py-1 flex flex-col items-center"
                          key={suit}
                        >
                          <Checkbox
                            label={suitIconsMap[suit]}
                            checked={variables[variable].includes(suit)}
                            onChange={() =>
                              handleSuitsForVariableChange(variable, suit)
                            }
                          />
                        </div>
                      );
                    })}
                    <button
                      className="btn btn-error btn-sm h-0.5 w-1 text-xs"
                      onClick={() => handleRemoveVariable(variable)}
                    >
                      x
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex items-center justify-end gap-4 mt-2 pb-2">
            <button
              className="btn btn-secondary"
              onClick={() => setDialogOpen(false)}
            >
              {t('dialog.cancel_button')}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                setDialogOpen(false);
                onUpdateSettings({
                  dealer,
                  vulnerability,
                  flip,
                  nsHcpRange: hasNsHcpRange ? nsHcpRange : [0, 40],
                  robots,
                  variables,
                });
              }}
            >
              {t('dialog.save_button')}
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
