import { ranges } from './constants';

const preEmpt2Level = [
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C$/,
    explain: 'We can pre-empt with 2D',
    options: [
      {
        bid: '2D["Weak, 6+!D"]',
        reqD: 6,
        reqMaxH: 4,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 5),
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-(1[CD]|1C-P-1D)$/,
    explain: 'We can pre-empt with 2H',
    options: [
      {
        bid: '2H["Weak, 6+!H"]',
        reqH: 6,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 5),
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-(1[CDH]|1[CD]-P-1[DH])$/,
    explain: 'We can pre-empt with 2S',
    options: [
      {
        bid: '2S["Weak, 6+!S"]',
        reqS: 6,
        reqMaxH: 4,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 3 : 5),
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-2D-(P|D|2.|3C)$/,
    explain:
      'Partner pre-empt with 2D, opps bid up to 3C, we can bid 3D or 4D or 5D',
    options: [
      {
        bid: '5D["To play"]',
        reqD: 5,
        reqHp: ranges.weakRaise,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 11 : 0), // Does not happen when VUL
      },
      {
        bid: '4D["To play"]',
        reqD: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '3D["To play"]',
        reqD: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-2D-(3D|3H|3S|3N|4C)$/,
    explain: 'Partner pre-empt with 2D, opps bid up to 4C, we can bid 4D or 5D',
    options: [
      {
        bid: '5D["To play"]',
        reqD: 5,
        reqHp: ranges.weakRaise,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 11 : 0), // Does not happen when VUL
      },
      {
        bid: '4D["To play"]',
        reqD: 4,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-1C-2D-(4D|4H|4S|4N|5C)$/,
    explain: 'Partner pre-empt with 2D, opps bid up to 5C, we can bid 5D',
    options: [
      {
        bid: '5D["To play"]',
        reqD: 5,
        reqHp: ranges.weakRaise,
        reqHcpInSuitPerVul: (vul) => (['EW', 'ALL'].includes(vul) ? 11 : 0), // Does not happen when VUL
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-(1[CD]|1C-P-1D)-2H-(P|D|2.|3C|3D)$/,
    explain: 'Partner pre-empt with 2H, opps bid up to 3D, we can bid 3H or 4H',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '3H["To play"]',
        reqH: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-(1[CD]|1C-P-1D)-2H-(3H|3S|3N|4C|4D)$/,
    explain: 'Partner pre-empt with 2H, opps bid up to 4D, we can bid 4H',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 4,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch:
      /^\.?(-\.)*(-P)*-(1[CDH]|1[CD]-P-1[DH])-2S-(P|D|2.|3C|3D|3H)$/,
    explain: 'Partner pre-empt with 2S, opps bid up to 3H, we can bid 3S or 4S',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 4,
        reqHp: ranges.weakRaise,
      },
      {
        bid: '3S["To play"]',
        reqS: 3,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-(1[CDH]|1[CD]-P-1[DH])-2S-(3S|3N|4C|4D|4H)$/,
    explain: 'Partner pre-empt with 2S, opps bid up to 4H, we can bid 4S',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 4,
        reqHp: ranges.weakRaise,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-P-1[CDH]$/,
    explain: 'Partner is a passed hand, we can preempt',
    options: [
      {
        bid: '2D["Weak, 6+!D"]',
        reqD: 6,
        lastBid: '1C',
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 2 : 5),
        weight: 2,
      },
      {
        bid: '2H["Weak, 6+!H"]',
        reqH: 6,
        lastBidExclusions: ['1H'],
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 2 : 5),
        weight: 2,
      },
      {
        bid: '2S["Weak, 6+!S"]',
        reqS: 6,
        reqMaxHp: ranges.weakOpen,
        reqHcpInSuitPerVul: (vul) => (['NONE', 'NS'].includes(vul) ? 2 : 5),
        weight: 2,
      },
    ],
  },
];

export default preEmpt2Level;
