import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserPublic } from '../../firebase/userApi';
import UserCard from '../../components/users/userCard/userCard';
import { useAuth } from '../../util/hooks.jsx';
import { useAppStateStore } from '../../appStateStore.jsx';
import { getChallengeStatisticsPartnership } from 'cuebids-firebase/challenge'
import ChallengeStatisticPartnership from '../challenge/challengeComponents/ChallengeStatisticPartnership'

export default function Pair() {
  const { user1, user2 } = useParams();
  const friends = useAppStateStore((state) => state.friends);
  const { currentUser } = useAuth();

  const [userOne, setUserOne] = useState();
  const [userTwo, setUserTwo] = useState();

  const [challengeStatisticsPartnership, setChallengeStatisticsPartnership] = useState(null);

  useEffect(function () {
    getChallengeStatisticsPartnership([user1, user2]).then(setChallengeStatisticsPartnership);
  }, [user1, user2]);

  useEffect(() => {
    async function getUserOne() {
      const usr = await getUserPublic(user1);
      if (currentUser.uid === user1) {
        usr['me'] = true;
      } else {
        usr['friendStatus'] =
          friends?.find((f) => f.id === user1)?.status ?? 'none';
      }
      setUserOne(usr);
    }

    void getUserOne();
  }, [user1, friends, currentUser]);

  useEffect(() => {
    async function getUserTwo() {
      const usr = await getUserPublic(user2);
      if (currentUser.uid === user2) {
        usr['me'] = true;
      } else {
        usr['friendStatus'] =
          friends?.find((f) => f.id === user2)?.status ?? 'none';
      }
      setUserTwo(usr);
    }

    void getUserTwo();
  }, [user2, friends, currentUser]);

  if (!userOne || !userTwo) {
    return null;
  }

  return (
    <div className="fade-in">
      <div>
        <div className="page flex flex-col gap-4 pb-24">
          {challengeStatisticsPartnership && <ChallengeStatisticPartnership firstUserId={user1} challengeStatisticPartnership={challengeStatisticsPartnership} linkToChallengeStats />}
          <UserCard user={userOne} />
          <UserCard user={userTwo} />
        </div>
      </div>
    </div>
  );
}
