import { documentId, getDocs, onSnapshot, query, where, } from "firebase/firestore";
export function docToObject(documentSnapshot, adapter) {
    if (!documentSnapshot.id || !documentSnapshot.exists()) {
        return null;
    }
    if (typeof adapter === "function") {
        return adapter({ id: documentSnapshot.id, ...documentSnapshot.data() });
    }
    return { id: documentSnapshot.id, ...documentSnapshot.data() };
}
export function firstDocToObject(querySnapshot) {
    if (querySnapshot.empty || querySnapshot.docs.length === 0) {
        return null;
    }
    return { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
}
export function docsToObjects(querySnapshot, adapter) {
    if (querySnapshot.empty || querySnapshot.docs.length === 0) {
        return [];
    }
    return querySnapshot.docs.map((d) => {
        if (typeof adapter === "function") {
            return adapter({ id: d.id, ...d.data() });
        }
        return { id: d.id, ...d.data() };
    });
}
export async function queryFirstItemToObject(q) {
    const docs = await getDocs(q);
    return firstDocToObject(docs);
}
export async function queryToObjects(q, adapter) {
    const docs = await getDocs(q);
    return docsToObjects(docs, adapter);
}
export function queryListenWithCallback(q, callback, adapter) {
    return onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty ||
            !querySnapshot.docs ||
            querySnapshot.docs.length === 0) {
            callback([]);
            return;
        }
        if (typeof adapter === "function") {
            callback(querySnapshot.docs.map((d) => adapter(docToObject(d))));
        }
        else {
            callback(querySnapshot.docs.map((d) => docToObject(d)));
        }
    });
}
export function queryListenFirstItemWithCallback(query, callback) {
    return onSnapshot(query, (querySnapshot) => {
        if (querySnapshot.empty ||
            !querySnapshot.docs ||
            querySnapshot.docs.length === 0) {
            callback(null);
            return;
        }
        callback({ ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id });
    });
}
export function docListenWithCallback(docRef, callback, adapter) {
    return onSnapshot(docRef, (querySnapshot) => {
        callback(docToObject(querySnapshot, adapter));
    });
}
export async function getContentByIds(collection, ids) {
    if (!ids || !ids.length || !collection)
        return [];
    const copy = [...ids];
    const batches = [];
    while (copy.length) {
        const batch = copy.splice(0, 10);
        batches.push(getDocs(query(collection, where(documentId(), "in", [...batch]))).then((results) => {
            return results.docs.map((d) => docToObject(d));
        }));
    }
    return Promise.all(batches).then((content) => content.flat());
}
