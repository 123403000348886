import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import {
  getLatestRankedChallengeHistoryObservable,
} from 'cuebids-firebase/challenge'
import { useAuth } from '../../util/hooks';
import ChallengeToCard from './challengeComponents/ChallengeToCard';
import RankCard from '../../components/rankCard/rankCard';
import { ChallengeIcon } from '../../components/icons/challengeIcon';
import { challengesObservableLimit } from '../../firebase/challenges';
import { GridCard } from '../../components/cardGrid/cardGrid';
import { FriendsIcon } from '../../components/icons/friendsIcon';
import { useAppStateStore } from '../../appStateStore';
import ChallengeHistory from './challengeComponents/ChallengeHistory'
import { Tab, TabPanel, Tabs } from '../../components/tabs/tabs';
import UserChallengeStats from './challengeComponents/UserChallengeStats'
import RankedLeaderboard from '../../components/rankedLeaderboard/RankedLeaderboard'
import { useTranslation } from 'react-i18next'
import Alert from '../../components/alert/alert.jsx'

function StatsTab({ currentUser }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get('user') ?? currentUser.uid;
  const partnerId = searchParams.get('partner') ?? null;
  const opponentTeamId = searchParams.get('opponents') ?? null; // Note: This is a teamId
  const statsTab = searchParams.get('statsTab') ?? 'player';

  function setStatsTab(tabId) {
    setSearchParams(function (oldParams) {
      oldParams.set('statsTab', tabId);
      return oldParams;
    })
  }

  function setUserId(uid) {
    setSearchParams({
      tab: 'stats',
      statsTab: 'player',
      user: uid,
    });
  }

  function setPair(team) {
    setSearchParams({
      tab: 'stats',
      statsTab: 'partner',
      user: team[0],
      partner: team[1],
    });
  }

  function setPartnerId(uid) {
    setSearchParams({
      tab: 'stats',
      statsTab: statsTab === 'matchup' ? 'matchup' : 'partner', // TODO: This is ugly, but matchup tab has a dropdown for partner
      user: userId,
      partner: uid,
    });
  }

  function setOpponentTeamId(teamId) {
    setSearchParams({
      tab: 'stats',
      statsTab: 'matchup',
      user: userId,
      partner: partnerId,
      opponents: teamId,
    });
  }

  return (
    <>
      <div className="mt-2" />
      <UserChallengeStats
        activeTab={statsTab}
        setActiveTab={setStatsTab}
        currentUserId={currentUser.uid}
        userId={userId}
        setUserId={setUserId}
        setPair={setPair}
        partnerId={partnerId}
        setPartnerId={setPartnerId}
        opponentTeamId={opponentTeamId}
        setOpponentTeamId={setOpponentTeamId}
      />
    </>
  );
}

function GlobalTab({ currentUser }) {
  const [challengeHistory, setChallengeHistory] = useState([]);

  useEffect(function () {
    return getLatestRankedChallengeHistoryObservable({ count: 10, callback: setChallengeHistory });
  }, []);

  return (
    <>
      {challengeHistory.length > 0 && (
        <ChallengeHistory challengeHistory={challengeHistory} firstUserId={currentUser.uid} />
      )}
    </>
  );
}

function MineTab({ navigate, currentUser, t }) {
  const challenges = useAppStateStore((state) => state.challenges);

  if (challenges.length === 0) {
    return (
      <Alert text={t('no_challenges')} />
    );
  }

  return (
    <>
      <div className={'grid grid-cols-1'}>
        {challenges.length > 0 && (
          <AnimatePresence>
            {challenges.map((c) => {
              return (
                <ChallengeToCard
                  key={c.id}
                  challenge={c}
                  navigate={navigate}
                  currentUser={currentUser}
                />
              );
            })}
          </AnimatePresence>
        )}
        {challenges.length === challengesObservableLimit && (
          <Link to="/challenge/archive" className="btn-secondary btn my-6">
            {t('show_older_challenges')}
          </Link>
        )}
      </div>
    </>
  );
}

function Top10Tab() {
  return <RankedLeaderboard />
}

export default function Challenge() {
  const { t } = useTranslation('translation', { keyPrefix: 'challenge' });

  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const user = useAppStateStore((state) => state.user);

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? 'mine';

  const handeTabChange = (tabId) => {
    navigate(`/challenge?tab=${tabId}`, { replace: true });
  };

  return (
    <div className="fade-in min-h-screen" key={'challenge'}>
      <div className="page relative pb-24">
        <div className="flex justify-center w-full gap-4 mb-4">
          <div className="grid grid-cols-3 gap-2">
            <Link to="/challengeSession">
              <GridCard
                name={t('challenge_friends')}
                icon={<FriendsIcon fill="#9f4e4e" width={32} />}
              />
            </Link>
            <Link to={`/player/${currentUser.uid}`}>
              <RankCard
                uid={currentUser.uid}
                rank={user?.rankLevel || 'jack'}
                rankStars={user?.rankLevelStars || 0}
                rating={user?.rating || 1500}
                alwaysShowRating
              />
            </Link>
            <Link to="/challenge/match">
              <GridCard
                name={t('ranked_match')}
                icon={<ChallengeIcon fill="#9f4e4e" width={32} />}
              />
            </Link>
          </div>
        </div>
        <Tabs>
          <Tab
            key={'mine'}
            label={t('tab_my_challenges')}
            value="mine"
            active={tab === 'mine'}
            onChange={handeTabChange}
          />
          <Tab
            key={'global'}
            label={t('tab_global')}
            value="global"
            active={tab === 'global'}
            onChange={handeTabChange}
          />
          <Tab
            key={'Stats'}
            label={t('tab_stats')}
            value="stats"
            active={tab === 'stats'}
            onChange={handeTabChange}
          />
          <Tab
            key={'Top'}
            label={t('tab_top')}
            value="top"
            active={tab === 'top'}
            onChange={handeTabChange}
          />
        </Tabs>
        <TabPanel key={'minePanel'} value={tab} index={'mine'}>
          <MineTab currentUser={currentUser} navigate={navigate} t={t} />
        </TabPanel>
        <TabPanel key={'globalPanel'} value={tab} index={'global'}>
          <GlobalTab currentUser={currentUser} />
        </TabPanel>
        <TabPanel key={'statsPanel'} value={tab} index={'stats'}>
          <StatsTab currentUser={currentUser} />
        </TabPanel>
        <TabPanel key={'topPanel'} value={tab} index={'top'} className={'w-4/5 md:w-1/2'}>
          <Top10Tab />
        </TabPanel>
      </div>
    </div>
  );
}
