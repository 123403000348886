const iconMap = {
  error: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 flex-shrink-0 stroke-current"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  ),
  info: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className="h-6 w-6 flex-shrink-0 stroke-current"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      ></path>
    </svg>
  ),
  warning: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 flex-shrink-0 stroke-current"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
      />
    </svg>
  ),
  success: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 flex-shrink-0 stroke-current"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  ),
};

const classMap = {
  info: 'alert-info bg-info bg-opacity-10 border text-info',
  warning: 'alert-warning bg-warning bg-opacity-10 border text-warning',
  success: 'alert-success bg-success bg-opacity-10 border text-success',
  error: 'alert-error bg-error bg-opacity-10 border text-error',
};

export default function Alert({
  title,
  text,
  onClick,
  sx,
  icon,
  severity = 'info',
}) {
  return (
    <div
      className={`alert my-2 max-w-[90%] md:max-w-[80%] justify-center flex ${sx || ''} ${
        classMap[severity]
      } ${onClick ? 'cursor-pointer' : ''}`}
      onClick={onClick}
    >
      <div className='flex gap-2 items-center justify-center w-full'>
        {icon}
        <div className="flex items-center justify-start">
          {!icon && <div className="mr-2">{iconMap[severity]}</div>}
          <div>
            <h3 className="font-bold">{title}</h3>
            <div className="text-xs">{text}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AlertInline({
  title,
  text,
  onClick,
  sx,
  icon,
  severity = 'info',
}) {
  return (
    <div
      className={`alert ${sx} ${classMap[severity]} ${
        onClick ? 'cursor-pointer' : ''
      }`}
      onClick={onClick}
    >
      <div>
        {icon}
        <div className="flex items-center justify-start">
          {!icon && <div className="mr-2">{iconMap[severity]}</div>}
          <div>
            <h3 className="font-bold">{title}</h3>
            <div className="text-xs">{text}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
