import './chatMessage.css';
import { UserDisplayAvatarSmall } from '../users/userDisplay';
import { Link } from 'react-router-dom';

const classMap = {
  me: 'alert-info bg-info bg-opacity-50 text-white',
  extra: 'alert-warning bg-warning bg-opacity-40 text-white',
  you: 'alert-success bg-success bg-opacity-50 text-white',
};

export default function ChatMessageDisplay({
  isMe,
  uid,
  message,
  messageFromExtra,
  specialMessage,
  showProfilePicture,
  links = false,
}) {

  if (specialMessage) {
    return <div className={'w-full my-4 text-sm text-warning flex justify-center'}>{message}</div>
  }

  const c = messageFromExtra ? 'extra' : (isMe ? 'me' : 'you');

  return (
    <div style={{ display: 'flex' }}>
      <li
        className={`flex items-center relative w-full text-left ml-[1px] p-[3px] ${
          isMe ? 'flex-row-reverse' : 'flex-row'
        }`}
      >
        <div style={{ minWidth: 25 }}>
          {showProfilePicture &&
            (links ? (
              <Link to={'/player/' + uid}>
                <UserDisplayAvatarSmall uid={uid} />
              </Link>
            ) : (
              <UserDisplayAvatarSmall uid={uid} />
            ))}
        </div>
        <div className="bubble-container">
          <div
            style={{ whiteSpace: 'pre-wrap', fontFamily: 'Arial, sans-serif' }}
            className={`bubble text-sm ${isMe ? 'me' : 'you'} ${classMap[c]}`}
          >
            {message}
          </div>
        </div>
      </li>
    </div>
  );
}
