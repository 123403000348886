import { useEffect, useState } from 'react'
import '../../components/hand/hands.css';
import Dropdown, {
  SelectFriendDropdown,
} from '../../components/dropdown/dropdown';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createChallengeNotification } from 'cuebids-firebase/notifications';
import { SubIcon } from '../../components/subIcons/subIcons';
import { isSubEnough } from '../../util/sub';
import { createChallenge } from '../../firebase/challenges';
import { useActiveSubs, useAuth, useDisplay } from '../../util/hooks';
import { useAppStateStore } from '../../appStateStore.jsx';
import { getPublicUserAsync } from '../../firebase/subscriptions.js';
import { NoRobotIcon } from '../../components/icons/noRobotIcon.jsx';
import { RobotIcon } from '../../components/icons/robotIcon.jsx';
import { AdvancedRobotIcon } from '../../components/icons/advancedRobotIcon.jsx';
import { AlertInline } from '../../components/alert/alert.jsx'
import PairDisplay from '../../components/users/pairDisplay.jsx';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next'

const settingMap = {
  0: <NoRobotIcon className={'fill-gray-500 opacity-40'} />,
  1: <RobotIcon className={'fill-sky-400'}/>,
  2: <AdvancedRobotIcon className={'fill-rose-400'} />,
}

export default function StartChallenge() {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [searchParams] = useSearchParams();
  const pdParam = searchParams.get('pd');
  const opp1Param = searchParams.get('o1');
  const opp2Param = searchParams.get('o2');
  const user = useAppStateStore((state) => state.user);
  const friends = useAppStateStore((state) => state.friends);
  // const metadata = useAppStateStore((state) => state.metadata);
  const notify = useNotifications();
  const challenges = useAppStateStore((state) => state.challenges);

  const activeSubs = useActiveSubs();
  const nav = useNavigate();
  const { isSmall } = useDisplay();

  const [friendId, setFriendId] = useState(
    pdParam || user?.challengeDefaultPartner || ''
  );
  const [handCountInput, setHandCountInput] = useState(
    user?.challengeDefaultNumberOfDeals || 12
  );
  const handCount = parseInt(handCountInput, 10);
  const [loading, setLoading] = useState(false);
  const [robots, setRobots] = useState(isSubEnough(activeSubs, 'heart') ? 2 : 1);

  const [opp1FriendId, setOpp1FriendId] = useState(opp1Param || user?.challengeDefaultOpponent1 || '');
  const [opp2FriendId, setOpp2FriendId] = useState(opp2Param ||user?.challengeDefaultOpponent2 || '');

  const [everybodyIsPro, setEverybodyIsPro] = useState(false);
  const [proChallengeToggleActive, setProChallengeToggleActive] = useState(false);

  // Note: This is automatically done in friend dropdown component if you pass disabled friend id, so this could be removed.
  function handleSetFriendId(uid) {
    setFriendId(uid);
    if (uid === opp1FriendId) {
      setOpp1FriendId('');
    } else if (uid === opp2FriendId) {
      setOpp2FriendId('');
    }
  }

  useEffect(function () {
    if (!user?.pro || !friendId || !opp1FriendId || !opp2FriendId) {
      return;
    }

    let shouldIgnore = false;

    async function doStuff() {
      const friendsAndOppsPromises = [getPublicUserAsync(friendId), getPublicUserAsync(opp1FriendId), getPublicUserAsync(opp2FriendId)];
      const friendsAndOppsData = await Promise.all(friendsAndOppsPromises);

      const allPros = friendsAndOppsData.every(f => f.pro);
      if (!shouldIgnore) {
        setEverybodyIsPro(allPros);
        setProChallengeToggleActive(allPros);
      }
    }

    void doStuff();

    return function () {
      shouldIgnore = true;
    }
  }, [user?.pro, friendId, opp1FriendId, opp2FriendId]);

  const toggleProChallengeToggle = () => {
    setProChallengeToggleActive(x => !x);
  };

  const handleChange = (e) => {
    setHandCountInput(e.target.value);
  };

  const handleChangeRobots = (e) => {
    setRobots(e);
  };

  const settingNameMap = {
    0: t('robots.none'),
    1: t('robots.basic'),
    2: t('robots.advanced'),
  }

  const robotOptions = [
    {
      value: 0,
      label: (<div className="h-full flex items-center justify-start relative gap-2">
        <div className="w-full h-full flex items-center justify-start gap-2">
          {settingMap[0]}{settingNameMap[0]}
        </div>
      </div>),
      component: (
        <button
          className="btn-ghost btn w-full justify-between"
          onClick={() => handleChangeRobots(0)}
        >
          <div className="w-full h-full flex items-center justify-start gap-2">
            {settingMap[0]}{settingNameMap[0]}
          </div>
        </button>
      ),
    },
    {
      value: 1,
      label: <div className="h-full flex items-center justify-start relative gap-2">
        <div className="w-full h-full flex items-center justify-start gap-2">
          {settingMap[1]}{settingNameMap[1]}
        </div>
      </div>,
      component: (
        <button
          className="btn-ghost btn w-full justify-between"
          onClick={() => handleChangeRobots(1)}
        >
          <div className="w-full h-full flex items-center justify-start gap-2">
            {settingMap[1]}{settingNameMap[1]}
          </div>
        </button>
      ),
    },
    {
      value: 2,
      label: <div className="h-full flex items-center justify-start relative gap-2">
        <div className="w-full h-full flex items-center justify-start gap-2">
          {settingMap[2]}{settingNameMap[2]}
        </div>
      </div>,
      component: (
        <button
          className="btn-ghost btn relative w-full justify-between"
          disabled={!isSubEnough(activeSubs, 'heart')}
          onClick={() => handleChangeRobots(2)}
        >
          <div className="w-full h-full flex items-center justify-start gap-2">
            {settingMap[2]}{settingNameMap[2]}
          </div>
          <div className="absolute right-2">
            <SubIcon sub={'heart'}/>
          </div>
        </button>
      ),
    },
  ];

  const handleRematch = (partner, opp1, opp2) => {
    handleSetFriendId(partner);
    setOpp1FriendId(opp1);
    setOpp2FriendId(opp2);
  }

  const handleClick = async () => {
    setLoading(true);
    try {
      const id = await createChallenge({
        partnerUserId: friendId,
        numberOfDeals: handCount,
        compete: robots,
        opp1UserId: opp1FriendId,
        opp2UserId: opp2FriendId,
        // minDealNumber: metadata?.minDealNumber,
        proChallenge: proChallengeToggleActive,
      });

      const friendUser = await getPublicUserAsync(friendId);

      void createChallengeNotification(
        opp1FriendId,
        opp2FriendId,
        currentUser.displayName,
        friendUser?.displayName ?? '',
        id
      );

      setLoading(false);
      nav(`/challenge/${id}`, { replace: true });
    } catch (e) {
      setLoading(false);
      notify(
        {
          type: 'failure',
          text: t('start_challenge.create_error', {error: e.message}),
        },
      );
    }
  };

  if (!friends) {
    return <></>;
  }

  const isValidChallenge =
    opp1FriendId &&
    opp2FriendId &&
    friendId &&
    new Set([friendId, opp1FriendId, opp2FriendId]).size === 3;

  const rematches = challenges.
    filter((c) => c.finished && !c.ranked).
    map((c) => {
      const [ourTeam, opps] = c.team1Users.includes(currentUser.uid)
        ? [c.team1Users, c.team2Users]
        : [c.team2Users, c.team1Users];

      const partner = ourTeam[0] === currentUser.uid ? ourTeam[1] : ourTeam[0]
      const opp1 = opps[0]
      const opp2 = opps[1]

      return {
        id: c.id,
        partner,
        opp1,
        opp2,
      }
    }).filter((c, index, self) => {
      const combination = [c.opp1, c.opp2].sort().join('-') + '-' + c.partner;
      return index === self.findIndex((t) => {
        const tCombination = [t.opp1, t.opp2].sort().join('-') + '-' + t.partner;

        return tCombination === combination;
      });
    }).slice(0, 3);

  return (
    <div>
      <div className="page pb-24">

        <div className={'flex flex-col gap-1'}>
          {t('challenge.rematch')}
          {
            rematches.map((c) => {
              return (
                <button key={c.id} className="btn-primary btn" onClick={() => handleRematch(c.partner, c.opp1, c.opp2)}>
                  <PairDisplay uid1={currentUser.uid} uid2={c.partner} /> vs <PairDisplay uid1={c.opp1} uid2={c.opp2} reverse />
                </button>
              )})
          }
        </div>
        <div className="flex w-full justify-center">
          <SelectFriendDropdown
            disabled={loading}
            value={friendId}
            onChange={handleSetFriendId}
            label={t('start_challenge.partner_label')}
            friends={friends}
          />
        </div>

        <div className="mt-4 flex w-80 items-center justify-between gap-4">
          <div className="flex w-20 shrink flex-col">
            <label className="label">
              <span className="label-text">{t('start_challenge.boards_label')}</span>
            </label>
            <input
              className="input"
              type="number"
              min={1}
              onChange={handleChange}
              max={24}
              value={handCountInput}
            />
          </div>
          <div className="flex grow flex-col">
            <label className="label">
              <span className="label-text">{t('start_challenge.robots_label')}</span>
            </label>
            <Dropdown value={robots} label={t('start_challenge.robots_label')} options={robotOptions} />
          </div>
        </div>

        <div>
          <div
            className={
              'option-wrapper align-left' + (isSmall ? ' full-width' : ' half')
            }
            style={{ marginTop: 10 }}
          >
            <SelectFriendDropdown
              disabled={loading}
              value={opp1FriendId}
              onChange={setOpp1FriendId}
              label={t('start_challenge.opponent_one_label')}
              friends={friends}
              disableFriendIds={[friendId, opp2FriendId]}
            />
          </div>
          <div
            className={
              'option-wrapper align-left' + (isSmall ? ' full-width' : ' half')
            }
            style={{ marginTop: 10 }}
          >
            <SelectFriendDropdown
              disabled={loading}
              value={opp2FriendId}
              onChange={setOpp2FriendId}
              label={t('start_challenge.opponent_two_label')}
              friends={friends}
              disableFriendIds={[opp1FriendId, friendId]}
            />
          </div>
        </div>

        {everybodyIsPro && (
          <div className="form-control w-80 mt-4">
            <label className="label w-full cursor-pointer p-0 font-bold">
              {t('start_challenge.pro_challenge')}
              <input
                type="checkbox"
                className="toggle-primary toggle"
                checked={proChallengeToggleActive}
                onChange={toggleProChallengeToggle}
              />
            </label>
            {proChallengeToggleActive && (
              <AlertInline
                text={
                  <span>
                    {t('start_challenge.pro_challenge_pre_styled')}
                    <div className="badge badge-xs badge-secondary">{t('start_challenge.pro_challenge_styled')}</div>
                    <br />
                    {t('start_challenge.pro_challenge_post_styled')}
                  </span>
                }
                sx="mt-2"
              />
            )}
          </div>
        )}

        <button
          className="btn-primary btn"
          style={{ marginTop: 20 }}
          disabled={
            !friendId ||
            !handCount ||
            handCount < 1 ||
            handCount > 24 ||
            !isValidChallenge ||
            loading
          }
          onClick={handleClick}
        >
          {t('start_challenge.create_challenge')}
        </button>
      </div>
    </div>
  );
}
