import { useState, useEffect } from 'react';
import '../../components/hand/hands.css';
import { useNavigate } from 'react-router-dom';
import {
  cancelMatchmakingPracticeSession,
  createMatchmakingPracticeSession,
} from '../../firebase/matchmaking';
import { useAuth } from '../../util/hooks.jsx';
import ConventionCardDialog from '../../components/conventionCards/conventionCardDialog';
import Alert from '../../components/alert/alert';
import Checkbox from '../../components/checkbox/Checkbox';
import Animated from '../../components/animation/animated';
import { HandshakeIcon } from '../../components/icons/handShakeIcon';
import { useAppStateStore } from '../../appStateStore.jsx';
import useNotifications from '../../components/notifications/useNotifications.jsx';
import { useTranslation } from 'react-i18next';

export default function CreateMatchmakingSession() {
  const { currentUser } = useAuth();
  const metadata = useAppStateStore((state) => state.metadata);
  const matchmaking = useAppStateStore((state) => state.matchmaking);
  const notify = useNotifications();

  const [loading, setLoading] = useState(false);
  const [waitingForPartner, setWaitingForPartner] = useState(null);

  const { t } = useTranslation();

  const nav = useNavigate();

  useEffect(
    function () {
      if (
        currentUser &&
        matchmaking &&
        matchmaking.userId === currentUser.uid
      ) {
        if (matchmaking.sessionId) {
          if (waitingForPartner === matchmaking.id) {
            // Notification is handled in AppEffects, do navigation here if on this page
            nav(`/session/${matchmaking.sessionId}`, {
              replace: true,
            });
          }
          setWaitingForPartner(null);
        } else {
          setWaitingForPartner(matchmaking.id);
        }
      } else {
        setWaitingForPartner(null);
      }
    },
    [currentUser, matchmaking, waitingForPartner, nav]
  );

  const handleCancel = async () => {
    setLoading(true);
    try {
      await cancelMatchmakingPracticeSession();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      notify(
        { type: 'failure', text: t('practice.match.cancel_error') },
      );
    }
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      const id = await createMatchmakingPracticeSession({
        minDealNumber: metadata?.minDealNumber,
      });
      setLoading(false);
      if (id) {
        nav(`/session/${id}`, { replace: true });
      }
    } catch (e) {
      setLoading(false);
      notify(
        { type: 'failure', text: t('practice.match.create_error') },
      );
    }
  };

  return (
    <Animated
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <div className="page pb-24">
        <Alert
          sx="w-full md:w-1/2"
          title={t('practice.match.title')}
          text={t('practice.match.description')}
        />
        <div className="flex flex-col px-8 mt-2">
          <Checkbox disabled checked label={t('practice.match.robots')} />
          <Checkbox disabled checked label={t('practice.match.boards')} />
          <div className="flex items-center">
            <Checkbox disabled checked label={t('practice.match.system')} />
            <ConventionCardDialog />
          </div>
          <span className="info mt-2">
            {t('practice.match.tip')}
          </span>
        </div>
        {waitingForPartner && (
          <Alert
            icon={
              <HandshakeIcon
                className="handshake-animation"
                fill="#e29627"
                strokeWidth={0.5}
                width={24}
              />
            }
            sx="mt-4"
            text={t('practice.match.waiting')}
          />
        )}
        {!waitingForPartner &&
          matchmaking &&
          !matchmaking.sessionId && (
          <Alert
            sx={'w-75 mt-4'}
            icon={
              <HandshakeIcon
                className="handshake-animation"
                fill="#e29627"
                strokeWidth={0.5}
                width={24}
              />
            }
            severity="info"
            text={t('practice.match.someone_looking')}
          />
        )}
        <Alert severity={'warning'} text={t('practice.match.warning_slow')} />
        {waitingForPartner ? (
          <button
            className="btn-secondary btn mt-4"
            disabled={loading}
            onClick={handleCancel}
          >
            {t('practice.match.cancel')}
          </button>
        ) : (
          <button
            className="btn-primary btn-wide btn"
            style={{ marginTop: 20 }}
            disabled={loading}
            onClick={handleClick}
          >
            {t('practice.match.find_match')}
          </button>
        )}
      </div>
    </Animated>
  );
}
