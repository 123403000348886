import { useEffect, useRef, useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import './dropdown.css';

import UserDisplay, {
  UserDisplayCoach,
} from '../users/userDisplay';
import { capitalizeFirstLetter } from '../../util/text';
import { useOutsideClick } from '../../util/hooks';
import { AlertInline } from '../alert/alert';
import { getPublicUserAsync } from '../../firebase/subscriptions'
import { TicketIcon } from '../icons/ticketsIcon'
import { getRobotUserId } from '../../util/robotPartner';
import PairDisplay from '../users/pairDisplay'
import { getTeamFromId } from 'cuebids-firebase/challenge'
import { useTranslation } from 'react-i18next';

// export function SelectFedDropdown({ label, value, onChange, feds }) {
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [filter, setFilter] = useState('');
//   const wrapperRef = useRef(null);
//
//   useOutsideClick(wrapperRef, () => setDropdownOpen(false));
//
//   function toggleDropdown() {
//     setDropdownOpen((x) => !x);
//   }
//
//   function onSelect(id) {
//     if (id !== value) {
//       onChange(id);
//     }
//     setDropdownOpen(false);
//   }
//
//   const options = feds
//     .filter(
//       (f) => !filter || f.name?.toLowerCase().startsWith(filter.toLowerCase())
//     )
//     .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
//     .map((fed) => {
//       return {
//         display: (
//           <button
//             tabIndex={0}
//             className="btn-ghost btn flex w-full justify-start text-start"
//             key={fed.id}
//             onClick={() => onSelect(fed.id)}
//           >
//             <span>{fed.name}</span>
//           </button>
//         ),
//         value: fed.id,
//       };
//     });
//
//   return (
//     <div className="relative w-full" ref={wrapperRef}>
//       <label className="label">
//         <span className="label-text">{capitalizeFirstLetter(label)}</span>
//       </label>
//       <button
//         className="btn-accent btn flex h-20 w-full flex-row items-center justify-between overflow-hidden"
//         type="button"
//         onClick={toggleDropdown}
//       >
//         {dropdownOpen && feds.length > 4 ? (
//           <input
//             type="text"
//             placeholder="Filter..."
//             className="input w-4/5"
//             autoFocus
//             value={filter}
//             onChange={(e) => setFilter(e.target.value)}
//             onClick={(e) => e.stopPropagation()}
//           />
//         ) : value ? (
//           <span className="w-56 whitespace-nowrap text-ellipsis overflow-hidden">
//             {feds.find((fed) => fed.id === value)?.name}
//           </span>
//         ) : (
//           <>Select federation</>
//         )}
//
//         <svg
//           className="mx-1.5 h-4 w-4"
//           aria-hidden="true"
//           fill="currentColor"
//           viewBox="0 0 20 20"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             fillRule="evenodd"
//             d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
//             clipRule="evenodd"
//           ></path>
//         </svg>
//       </button>
//       <div
//         className={
//           (!dropdownOpen ? 'hidden ' : '') +
//           // Note: rounded + max height + modal resulted in a bug with blurry text, so rounded was removed
//           'scroll absolute z-[9997] mt-0 max-h-44 overflow-x-hidden overflow-y-scroll border border-slate-500 border-opacity-50 bg-base-100 p-4'
//         }
//       >
//         <div className="flex w-[281px] flex-col items-start justify-start">
//           {options.length > 0 ? (
//             options.map((o) => {
//               return o.display;
//             })
//           ) : (
//             <>No federations match filter...</>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

export function SelectUserDropdown({
  label,
  value,
  onChange,
  coachRobot,
  users,
  disableUserIds = [],
  removeUserIds = [],
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const wrapperRef = useRef(null);
  const { t } = useTranslation();

  const lia = getRobotUserId();

  const userQueries = useQueries({
    queries: users.map((uid) => {
      return {
        queryKey: ['user', uid],
        queryFn: async () => await getPublicUserAsync(uid),
      };
    }),
  });

  useOutsideClick(wrapperRef, () => setDropdownOpen(false));

  function toggleDropdown() {
    setDropdownOpen((x) => !x);
  }

  function onSelect(id) {
    if (id !== value) {
      onChange(id);
    }
    setDropdownOpen(false);
  }

  const coachRobotOption = {
    display: (
      <button
        tabIndex={0}
        className="relative btn-ghost btn flex w-full justify-start text-start"
        key="coachRobot"
        onClick={() => onSelect('coachRobot')}
      >
        <UserDisplayCoach uid={lia} name={coachRobot?.name} />
        {(
          <div className="absolute right-4">
            <TicketIcon />
          </div>
        )}
      </button>
    ),
    value: 'coachRobot',
  };

  const options = userQueries
    .filter((q) => q && q.data)
    .map((q) => q.data)
    .filter(
      (f) =>
        !filter || f.displayName?.toLowerCase().startsWith(filter.toLowerCase())
    )
    .sort((a, b) => {
      if (a.robot) return -1;
      if (b.robot) return 1;
      return a.displayName?.toLowerCase() > b.displayName?.toLowerCase() ? 1 : -1
    })
    .filter((u) => {
      return !removeUserIds.includes(u.uid);
    })
    .map((u) => {
      const uid = u.uid;
      return {
        display: (
          <button
            disabled={disableUserIds.includes(uid)}
            tabIndex={0}
            className="relative btn-ghost btn flex w-full justify-start text-start"
            key={uid}
            onClick={() => onSelect(uid)}
          >
            <UserDisplay uid={uid} />
            {u.robot && (
              <div className="absolute right-4">
                <TicketIcon />
              </div>
            )}
          </button>
        ),
        value: uid,
      };
    });

  return (
    <div className="relative" ref={wrapperRef}>
      <label className="label">
        <span className="label-text">{capitalizeFirstLetter(label)}</span>
      </label>
      <button
        className="btn-accent btn flex h-20 w-80 flex-row items-center justify-between overflow-hidden"
        type="button"
        onClick={toggleDropdown}
      >
        {dropdownOpen && users.length > 4 ? (
          <input
            type="text"
            placeholder={t('app.search')}
            className="input w-4/5"
            autoFocus
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        ) : value ? (
          <>
            {
              value === 'coachRobot' ? <UserDisplayCoach uid={lia} name={coachRobot.name} /> : <UserDisplay uid={value} />
            }
          </>
        ) : (
          <>{t('friends.select_friend')}</>
        )}

        <svg
          className="mx-1.5 h-4 w-4"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div
        className={
          (!dropdownOpen ? 'hidden ' : '') +
          // Note: rounded + max height + modal resulted in a bug with blurry text, so rounded was removed
          'scroll absolute z-[9997] mt-0 max-h-44 overflow-x-hidden overflow-y-scroll border border-slate-500 border-opacity-50 bg-base-100 p-4'
        }
      >
        <div className="flex w-[281px] flex-col items-start justify-start">
          {
            coachRobot && coachRobotOption.display
          }
          {options.length > 0 ? (
            options.map((o) => {
              return o.display;
            })
          ) : (
            <>{t('app.no_hits')}</>
          )}
        </div>
      </div>
    </div>
  );
}

export function SelectPartnershipDropdown({
  label,
  value, // teamId
  onChange,
  partnerships, // teamIds
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'components.select_partnership_dropdown' });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => setDropdownOpen(false));

  function toggleDropdown() {
    setDropdownOpen((x) => !x);
  }

  function onSelect(partnership) {
    if (partnership !== value) {
      onChange(partnership);
    }
    setDropdownOpen(false);
  }

  const options = partnerships
    .map((teamId) => {
      const players = getTeamFromId(teamId);
      return {
        display: (
          <button
            tabIndex={0}
            className="relative btn-ghost btn flex w-full justify-start text-start"
            key={teamId}
            onClick={() => onSelect(teamId)}
          >
            <PairDisplay uid1={players[0]} uid2={players[1]} />
          </button>
        ),
        value: teamId,
      };
    });

  const selectedPlayers = value && getTeamFromId(value);

  return (
    <div className="relative" ref={wrapperRef}>
      <label className="label">
        <span className="label-text">{capitalizeFirstLetter(label)}</span>
      </label>
      <button
        className="btn-accent btn flex h-20 w-80 flex-row items-center justify-between overflow-hidden"
        type="button"
        onClick={toggleDropdown}
      >
        {selectedPlayers ? <PairDisplay uid1={selectedPlayers[0]} uid2={selectedPlayers[1]} /> : <>{t('select_pair')}</>}
        <svg
          className="mx-1.5 h-4 w-4"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div
        className={
          (!dropdownOpen ? 'hidden ' : '') +
          // Note: rounded + max height + modal resulted in a bug with blurry text, so rounded was removed
          'scroll absolute z-[9997] mt-0 max-h-44 overflow-x-hidden overflow-y-scroll border border-slate-500 border-opacity-50 bg-base-100 p-4'
        }
      >
        <div className="flex w-[281px] flex-col items-start justify-start">
          {options.map((o) => {
            return o.display;
          })}
        </div>
      </div>
    </div>
  );
}

export function SelectFriendDropdown({
  label,
  value,
  onChange,
  friends,
  disableFriendIds = [],
  disableFromTicketsAndEvents = false,
  disableFromTicketsAndEventsLabel = 'TICKETS AND EVENTS DISABLED',
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const wrapperRef = useRef(null);
  const { t } = useTranslation();
  const [error, setError] = useState('');

  useOutsideClick(wrapperRef, () => setDropdownOpen(false));

  const friend = friends.find((f) => {
    return f.id === value;
  });

  // An invalid friendId might be sent in from saved defaultPartner.
  useEffect(function () {
    if (!value) return;
    if (value === getRobotUserId()) {
      return;
    }

    if(friend) {
      setError('');
    }

    if (
      !friend ||
        friend.status !== 'mutual' ||
        (disableFromTicketsAndEvents &&
          friend.friendHasDisabledMeFromTicketsAndEvents) ||
        disableFriendIds.includes(friend.id)
    ) {
      if(friend?.status !== 'mutual' ||  (disableFromTicketsAndEvents &&
          friend.friendHasDisabledMeFromTicketsAndEvents) ) {

        setError(t('components.select_friend_dropdown.not_mutual_error'));
      }
      onChange('');
    }
  }, [friend, value, onChange, disableFromTicketsAndEvents, disableFriendIds, t]);

  function toggleDropdown() {
    setDropdownOpen((x) => !x);
  }

  function onSelect(id) {
    if (id !== value) {
      onChange(id);
    }
    setDropdownOpen(false);
  }

  const mutualFriends = friends.filter((f) => {
    return f.status === 'mutual';
  })

  const userQueries = useQueries({
    queries: mutualFriends.map((user) => {
      return {
        queryKey: ['user', user.id],
        queryFn: async () => await getPublicUserAsync(user.id),
      };
    }),
  });

  const options = mutualFriends
    .map((f, i) => {
      const q = userQueries[i];
      if (q && q.data) {
        return {
          ...f,
          ...q.data,
        };
      }
      return f;
    })
    .filter(
      (f) =>
        !filter || f.displayName?.toLowerCase().startsWith(filter.toLowerCase())
    )
    .sort((a, b) =>
    {
      if (a.robot) return -1;
      if (b.robot) return 1;
      return a.displayName?.toLowerCase() > b.displayName?.toLowerCase() ? 1 : -1
    }
    )
    .map((f) => {
      const ticketsAndEventsDisabled =
        disableFromTicketsAndEvents &&
        f.friendHasDisabledMeFromTicketsAndEvents;
      return {
        display: (
          <button
            disabled={
              disableFriendIds.includes(f.id) || ticketsAndEventsDisabled
            }
            tabIndex={0}
            className="btn-ghost btn relative flex w-full justify-start text-start"
            key={f.id}
            onClick={() => onSelect(f.id)}
          >
            <UserDisplay uid={f.id} />
            {ticketsAndEventsDisabled && (
              <span className="text-[8px] ml-12 -mt-3 text-slate-200">
                {disableFromTicketsAndEventsLabel}
              </span>
            )}
            {f.icon && (
              <div className="absolute right-4">
                {f.icon}
              </div>
            )}
          </button>
        ),
        value: f.id,
      };
    });

  let content;

  if (!friends.length || !mutualFriends.length) {
    content = (
      <AlertInline
        sx="h-20 !w-80"
        severity="info"
        text={t('friends.add_friends_cta')}
      />
    );
  } else {
    content = (
      <button
        className="btn-accent btn relative flex h-20 w-80 flex-row items-center justify-between overflow-hidden"
        type="button"
        onClick={toggleDropdown}
      >
        {dropdownOpen && friends.length > 4 ? (
          <input
            type="text"
            placeholder={t('app.search')}
            className="input w-4/5"
            autoFocus
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        ) : value ? (
          <>
            <UserDisplay uid={value} />
            {friend?.icon && (
              <div className="absolute right-10">
                {friend.icon}
              </div>
            )}
          </>
        ) : (
          <>{t('friends.select_friend')}</>
        )}

        <svg
          className="mx-1.5 h-4 w-4"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    );
  }


  return (
    <div className="relative" ref={wrapperRef}>
      <label className="label">
        <span className="label-text">{typeof label === 'string' ? capitalizeFirstLetter(label) : label}</span>
      </label>
      {content}
      <div
        className={
          (!dropdownOpen ? 'hidden ' : '') +
          // Note: rounded + max height + modal resulted in a bug with blurry text, so rounded was removed
          'scroll absolute z-[9997] mt-0 max-h-44 overflow-x-hidden overflow-y-scroll border border-slate-500 border-opacity-50 bg-base-100 p-4'
        }
      >
        <div className="flex w-[281px] flex-col items-start justify-start">
          {options.length > 0 ? (
            options.map((o) => {
              return o.display;
            })
          ) : (
            <>{t('components.select_friend_dropdown.no_matches')}</>
          )}
        </div>
      </div>
      <div className={'w-80 mt-2'}>
        {
          error && <AlertInline severity="error" sx={'w-full'} text={error} />
        }
      </div>
    </div>
  );
}

export default function Dropdown({ label, options, value, size = 'normal' }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => setDropdownOpen(false));

  const selectedOption = options.find((o) => o.value === value);
  const sizeMap = {
    small: 'w-[100px]',
    normal: 'min-w-[200px] w-full',
    flex: 'min-w-[120px]',
  };
  return (
    <div
      className={`dropdown-start dropdown ${sizeMap[size]}`}
      ref={wrapperRef}
    >
      <label
        tabIndex="0"
        className="btn-accent rounded-btn btn-block btn flex items-center justify-between"
        onClick={() => setDropdownOpen((x) => !x)}
      >
        {selectedOption ? selectedOption.label : label}
        <svg
          width="12px"
          height="12px"
          className="ml-1 h-3 w-3 fill-current opacity-60"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2048 2048"
        >
          <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path>
        </svg>
      </label>
      <div
        className={
          (!dropdownOpen ? 'hidden ' : '') +
          'popup rounded-t-box rounded-b-box absolute top-px z-[9997] mt-12 max-h-52 border-b-4 border-accent overflow-hidden overflow-y-auto overflow-x-hidden slated text-base-content ' +
          sizeMap[size]
        }
      >
        <ul className="menu" tabIndex={0}>
          {options.map((option) => (
            <li
              key={option.value}
              className="w-full "
              onClick={() => setDropdownOpen(false)}
            >
              {option.component}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
