import { useState } from 'react';
import Alert from '../../components/alert/alert';
import { Link, useNavigate } from 'react-router-dom';
import GroupCard from '../../components/groupCard/GroupCard';
import {
  acceptGroupInvite,
  getGroupSession,
  getNextGroupSessionToFinishForGroup,
  rejectGroupInvite
} from '../../firebase/groups';
import { useAppStateStore } from '../../appStateStore';
import { getNumberOfUnreadGroupMessages } from '../../util/groups';
import { useAuth, useGroupsNavbarItems } from '../../util/hooks.jsx';
import { logoMap } from './logoMap.js';
import { useQueries } from '@tanstack/react-query';
import GroupSessionCard from '../../components/areaCards/groupSession.jsx';
import { useTranslation } from 'react-i18next'

export default function GroupList() {
  const { t } = useTranslation('translation', { keyPrefix: 'groups.group_list' });
  const groups = useAppStateStore((state) => state.groups);
  const groupsExtraData = useAppStateStore((state) => state.groupsExtraData);
  const groupsMessages = useAppStateStore((state) => state.groupsMessages);
  const groupInvites = useAppStateStore((state) => state.groupInvites);
  const eventInvites = useAppStateStore((state) => state.eventInvites);
  const user = useAppStateStore((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const groupEventsBadges = user?.eventBadges;

  const groupSessionQueries = useQueries({
    queries: (groupEventsBadges ?? []).map((g) => {
      return {
        queryKey: ['groupSession', g],
        queryFn: async () => await getGroupSession(g),
      };
    }),
  });

  const groupSessions = groupSessionQueries.reduce(function (gss, q, i) {
    if (q && q.data) {
      const group = groups[i];
      if(!group) return gss;
      gss.push({ ...q.data, groupName: group.name, groupFlag: group.groupFlag });
    }
    return gss;
  }, []);

  const { currentUser } = useAuth();

  useGroupsNavbarItems();

  if (!groups || !user) {
    return null;
  }

  return (
    <div className="page pb-24">
      {
        groupSessions.length > 0 && (
          <div className="mt-4 flex justify-center flex-col items-center gap-2">
            <h2>{t('new_events')}</h2>
            {groupSessions.map(function (gs) {
              const numberOfEventInvites = eventInvites.filter((i) => i.invitee === currentUser.uid && i.eventId === gs.id).length;

              return (
                <div key={gs.id}>
                  <GroupSessionCard
                    event={gs}
                    numberOfEventInvites={numberOfEventInvites}
                    groupName={gs.groupName}
                    groupFlag={gs.groupFlag}
                    eventBadge={user?.eventBadges && user?.eventBadges?.includes(gs.id)}
                  />
                </div>
              );
            })}
          </div>
        )
      }
      {groupInvites.length > 0 && (
        <>
          <div className="mt-4">{t('group_invite_info')}</div>
          {groupInvites.map(function (invite) {
            return (
              <div
                key={invite.id}
                className="my-2 flex items-center w-full max-w-[420px] rounded-xl border bg-info border-info bg-opacity-10"
              >
                <div className="w-full p-2 md:p-4 flex items-center justify-between">
                  <div>
                    <span>{invite.name}</span>
                    {invite.premium && (
                      <div className="badge badge-success">{t('premium_group_badge')}</div>
                    )}
                  </div>
                  <div className="flex items-center">
                    <button
                      disabled={loading}
                      onClick={async function () {
                        if (loading) return;
                        setLoading(true);
                        const res = await acceptGroupInvite(
                          invite.id,
                          user.allowChatPush ?? true
                        );
                        setLoading(false);
                        if (res?.groupId) {
                          navigate(`/groups/${res.groupId}`);
                        }
                      }}
                      className="btn btn-primary btn-sm"
                    >
                      {t('accept')}
                    </button>
                    <button
                      disabled={loading}
                      onClick={async function () {
                        if (loading) return;
                        setLoading(true);
                        await rejectGroupInvite(invite.id);
                        setLoading(false);
                      }}
                      className="btn btn-error btn-sm ml-2"
                    >
                      {t('reject')}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      {groups.length < 1 ? (
        <Alert sx="mt-8" text={t('no_group_info')} />
      ) : (
        <div className="flex flex-col gap-4 mt-8">
          {groups.map(function (group) {
            const numberOfUnreadMessages = getNumberOfUnreadGroupMessages({ groupId: group.id, groupsMessages, groupsExtraData, userId: currentUser.uid });

            const numberOfEventInvites = eventInvites.filter(
              (i) => i.invitee === currentUser.uid && i.groupId === group.id
            ).length;

            const groupImage = group.logo && logoMap[group.logo] ? logoMap[group.logo] : null;
            const logo = groupImage && <img src={groupImage} alt={group.name} className="w-44 bg-white border-2 border-black p-2 rounded-xl bg-opacity-75" />

            return (
              <Link key={group.id} to={`/groups/${group.id}`}>
                <div className="flex flex-col items-center justify-center">
                  <div className="indicator">
                    {numberOfUnreadMessages > 0 && (
                      <span className="badge badge-error indicator-item">
                        {numberOfUnreadMessages}
                      </span>
                    )}
                    {numberOfEventInvites > 0 && (
                      <span className="badge badge-success indicator-item indicator-start">
                        {numberOfEventInvites}
                      </span>
                    )}
                    <GroupCard
                      name={group.name}
                      description={group.description}
                      premium={group.premium}
                      groupFlag={group.groupFlag}
                      logo={logo}
                    />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      )}
      <Link to={'/createGroup'} className="btn btn-primary mt-8">
        {t('create_group')}
      </Link>
    </div>
  );
}
