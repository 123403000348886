import {
  checkIfAlreadyPlaying,
  getEventById,
  getLatestEventObservable,
  joinEvent,
} from './events';
import { updateUser } from './userApi';
import { getRobotUserId } from '../util/robotPartner'

export function getDailyObservable({ callback }) {
  return getLatestEventObservable({
    eventCollection: 'dailySession',
    callback,
  });
}

export async function getDailyById(id) {
  return getEventById({ eventCollection: 'dailySession',
    eventId: id });
}

export async function joinDailySession({ id, partnerUserId, liaSystem }) {
  void updateUser({ dailyDefaultPartner: partnerUserId });
  return joinEvent({
    eventCollection: 'dailySession',
    eventId: id,
    partnerUserId,
    defaultPrice: 8,
    partnerAllowedToPlayMultiple: partnerUserId === getRobotUserId(),
    liaSystem
  });
}

export async function checkIfAlreadyPlayingDaily({ id, userId }) {
  return await checkIfAlreadyPlaying({
    eventCollection: 'dailySession',
    eventId: id,
    userId,
  });
}
