export function getRobotParContract({ scores }) {
  let par = '0CE';
  let parEv = -10000;
  Object.keys(scores).forEach((s) => {
    if (s.length !== 3) return;
    if (!['E', 'W'].includes(s.slice(-1))) return;
    if (scores[s] < parEv) return;

    parEv = scores[s];
    par = s;
  });

  return {
    par,
    parEv,
  };
}


export function getRobotParStrains({ scores }) {
  const pars = {
    N: -10000,
    H: -10000,
    D: -10000,
    C: -10000,
    S: -10000,
  };

  Object.keys(scores).forEach((s) => {
    if (s.length !== 3) return;
    if (!['E', 'W'].includes(s.slice(-1))) return;
    const strain = s[1];
    if (scores[s] < pars[strain]) return;

    pars[strain] = scores[s];
  });

  return pars;
}