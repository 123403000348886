import {useEffect} from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import '../polyfills.js';
import {
  AdminRoute,
  LoginRoute,
  PrivatePublicRoute,
  PrivateRoute,
} from './components/routing/privateRoute';
import './components/table/table.css';
import {AuthProvider} from './firebase/authContextProvider';
import Notifications from './components/notifications/notifications';
import Login from './pages/base/login';
import LoginSbfToken from './pages/base/loginSbfToken';
import StartPage from './pages/base/start';
import Session from './pages/sessions/session';
import SessionArchive from './pages/sessions/sessionArchive';
import SessionDeal from './pages/sessions/sessionDeal';
import {DisplayProvider} from './util/displayContextProvider';


import './App.css';
import './fallback.css';
import JoinWeeklySession from './pages/events/weekly/joinWeeklySession';
import WeeklyLeaderBoard from './pages/events/weekly/weeklyLeaderBoard';
import CreateMatchmakingSession
  from './pages/practice/createMatchmakingSession';
import CreateNewSession from './pages/practice/createSession';

// @ts-ignore
import logo from './images/logos/logo512.png';

import {
  DocumentAggregationSync,
  EnsureConnection,
  LanguageEffects,
  MetadataSubscribers,
  NativeFunctionalitySubscribers,
  SharedAppStateSubscribers,
  SpotsSubscribers,
  UserSync,
} from './AppEffects';
import ChallengeFinishedNotifications
  from './components/notifications/challengeFinishedNotifications';
import {BidsPreload} from './components/bidding/bidDisplay'
import SignOut from './components/signOut/signOut';
import Maintenance from './pages/base/maintenance';
import RefreshRequired from './pages/base/refreshRequired';
import Challenge from './pages/challenge/challenge';
import ChallengeMatchmaking from './pages/challenge/challengeMatchmaking';
import ChallengeSession from './pages/challenge/challengeSession';
import StartChallenge from './pages/challenge/startChallenge';
import ChallengeArchive from './pages/challenge/challengeArchive';
import DailyLeaderBoard from './pages/events/daily/dailyLeaderboard';
import JoinDailySession from './pages/events/daily/joinDailySession';
import Events from './pages/events/events';
import Admin from './pages/other/admin';
// import TestScoring from './pages/admin/testScoring';
// import PlotScoring from './pages/admin/plotScoring';
import Contact from './pages/other/contact';
import ContactSales from './pages/other/contactSales';
import Help from './pages/other/help';
import JuniorApply from './pages/other/juniorApply';
import PrivacyPolicy from './pages/other/privacyPolicy';
import TermsOfUse from './pages/other/terms';
import Practice from './pages/practice/practice';
import Friends from './pages/profile/friends';
import Pair from './pages/profile/pair';
import Player from './pages/profile/player';
import Profile from './pages/profile/profile';
import FlaggedSessionDealsPage from './pages/sessions/flaggedSessionDeals';
import SessionDealPublic from './pages/sessions/sessionDealPublic';
import SharedSessions from './pages/sessions/sharedSessions';
import StatsOverview from './pages/stats/statsOverview';
import Store from './pages/store/store';
import ThanksPage from './pages/store/thanks';
import ScrollToTop from './components/scrollToTop/ScrollToTop.jsx';
import ErrorBoundary from './components/errorBoundary/errorBoundary';
import Hand from './components/cards/hand';
import AppsPage from './pages/other/apps';

import BottomNavigation from './components/bottomNavigation/BottomNavigation';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import NotFoundPage from './pages/base/NotFound';

import 'react-tooltip/dist/react-tooltip.css';
import AddFriend from './pages/profile/addFriend';
import Messages from './pages/other/messages';
import Group from './pages/groups/group';
import PublicGroup from './pages/groups/publicGroup';
import CreateGroup from './pages/groups/createGroup';
import GroupList from './pages/groups/groupList';
import ManageGroup from './pages/groups/manageGroup';
import CreateGroupSession from './pages/groups/createGroupSession';
import JoinGroupSession from './pages/groups/joinGroupSession';
import GroupSessionLeaderboard from './pages/groups/groupSessionLeaderboard';
import AddMembersToGroup from './pages/groups/addMembersToGroup';
import CoachSessionDeal from './pages/groups/coachSessionDeal.jsx';
import JoinGroupByLink from './pages/groups/joinGroup.jsx';
import DashBoard from './pages/stats/dashboard';

// import Achievements from './pages/profile/achievements';
import SubscribeNewsletterPage from './pages/other/subscribeNewsletter';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import SpotDashboard from './pages/spots/spots';
import SpotDisplay from './pages/spots/spot';

import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import swedish from 'cuebids-locale/sv.json';
import english from 'cuebids-locale/en.json';
import polish from 'cuebids-locale/pl.json';
import czech from 'cuebids-locale/pl.json';
import CreateSpot from './pages/spots/createSpot';
import EditSpot from './pages/spots/editSpot';
import {swedishSpotPages} from './util/feds';
import ReleaseNotes from './pages/other/releaseNotes.jsx'
import Spade from './images/suits/Spade.jsx'
import Heart from './images/suits/Heart.jsx'
import Diamond from './images/suits/Diamond.jsx'
import Club from './images/suits/Club.jsx'
import {QuestionIcon} from './components/icons/questionIcon.jsx'
import {PreloadSubIcon} from './components/subIcons/subIcons.jsx'
import {PreloadRankIcons} from './components/rankCard/rankCard.jsx'
import SessionDealMock from './pages/mocks/sessionDealMocks.jsx';
import CreateDealType from './pages/dealTypes/createDealType.jsx';
import EditDealType from './pages/dealTypes/editDealType.jsx';
import ManageDealTypes from './pages/dealTypes/manageDealTypes.jsx';
import CommunityScenarios from './pages/dealTypes/communityScenarios.jsx';
import SwedishGuide from './pages/other/guides.jsx';
import CoachSessionDealPresentation
  from './pages/groups/coachSessionDealPresentation.jsx';
import PbnImport from './pages/groups/pbnImport.jsx';
import EventDraft from './pages/groups/eventDraft.jsx';
import GiftSub from './pages/other/gift.jsx';
import { Debug } from './debug/debug.jsx';
import ConventionPicker from './components/conventionPicker/conventionPicker.jsx';
import { useAppStateStore } from './appStateStore.jsx'
import AdminMessageGlobal from './components/AdminMessageGlobal.jsx'
import DealTypes from './pages/dealTypes/dealTypes.jsx';
import Pdf from './pages/other/pdf.jsx';
import { defaultLanguage } from './util/language.js'
import Codes from './pages/other/codes.jsx';
import EditGroupSession from './pages/groups/editGroupSession.jsx';


const queryClient = new QueryClient();

i18next.init({
  interpolation: { escapeValue: false },
  fallbackLng: [defaultLanguage],
  resources: {
    sv: {
      translation: swedish,
    },
    en: {
      translation: english,
    },
    pl: {
      translation: polish,
    },
    cs: {
      translation: czech,
    },
  },
});

function Root() {
  return (
    <>
      <EnsureConnection>
        <SharedAppStateSubscribers/>
        <NativeFunctionalitySubscribers/>
        <MetadataSubscribers/>
        <DocumentAggregationSync/>
        <LanguageEffects />
        <SpotsSubscribers/>
        <UserSync/>
        <ScrollToTop/>
        <Notifications/>
        {/*<Marketing />*/}
        <ChallengeFinishedNotifications/>
        <div id="app" className="w-full select-none md:w-[800px] bg-base-100">
          <Debug />
          <SignOut/>
          <Navbar/>
          <Outlet/>
          <BottomNavigation/>
          <AdminMessageGlobal />
        </div>
      </EnsureConnection>
      <Footer/>
    </>
  );
}

function MetadataRoot() {
  return (
    <>
      <EnsureConnection>
        <MetadataSubscribers/>
        <ScrollToTop/>
        <div id="app" className="w-full select-none md:w-[800px] bg-base-100">
          <div className="navbar top-0 z-40 w-full bg-opacity-25 flex-none border-slate-50/[0.06] transition-colors lg:z-50 lg:border-b lg:border-slate-900/10">
            <div className="fade-in w-full flex justify-center">
              <img
                src={logo}
                className={'w-[40px]'}
                alt="Cuebids"
              />
            </div>
          </div>
          <Outlet/>
          <AdminMessageGlobal />
        </div>
      </EnsureConnection>
    </>
  );
}

const currentVersionNumber = 3361; // 3.36.1
function shouldShowRefresh(metadata) {
  return metadata && (metadata.versionNumber > currentVersionNumber);
}

function shouldShowMaintenance(metadata) {
  return metadata?.maintenance;
}

function getAppRouter(metadata) {
  if (shouldShowMaintenance(metadata)) {
    return createBrowserRouter(
      createRoutesFromElements(
        <Route path="*" element={<MetadataRoot/>} errorElement={<ErrorBoundary/>}>
          <Route path="*" element={<Maintenance/>}/>
        </Route>
      )
    );
  }

  if (shouldShowRefresh(metadata)) {
    return createBrowserRouter(
      createRoutesFromElements(
        <Route exact path="*" element={<MetadataRoot/>} errorElement={<ErrorBoundary/>}>
          <Route path="*" element={<RefreshRequired/>}/>
        </Route>
      )
    );
  }

  const StartPageRoute = swedishSpotPages.includes(window?.location?.hostname) ? (
    <Route
      exact
      path="/"
      element={<Navigate to="/spots/sv" replace={true}/>}
    />
  ) : (
    <Route exact path="/" element={<PrivateRoute/>}>
      <Route exact path="/" element={<StartPage/>}/>
    </Route>
  );

  return createBrowserRouter(
    createRoutesFromElements(
      <Route exact path="/" element={<Root/>}
        errorElement={<ErrorBoundary/>}>
        <Route path="*" element={<NotFoundPage/>}/>
        {StartPageRoute}
        <Route exact path="/apps" element={<AppsPage/>}/>
        <Route exact path="/privacy" element={<PrivacyPolicy/>}/>
        <Route exact path="/gift" element={<GiftSub/>}/>
        <Route exact path="/dealTypes" element={<DealTypes/>}/>
        <Route exact path="/guides/swe" element={<SwedishGuide/>}/>
        <Route exact path="/releaseNotes" element={<ReleaseNotes/>}/>
        <Route exact path="/conventionPicker" element={<ConventionPicker/>}/>
        <Route exact path="/terms" element={<TermsOfUse/>}/>
        <Route
          exact
          path="/pdf/:sessionId"
          element={<Pdf />}
        />
        <Route
          exact
          path="/subscribeNewsletter/:uid"
          element={<SubscribeNewsletterPage/>}
        />
        <Route exact path="/login" element={<LoginRoute/>}>
          <Route path="/login" element={<Login/>}/>
        </Route>
        <Route exact path="/join/:referral" element={<LoginRoute/>}>
          <Route path="/join/:referral" element={<Login />}/>
        </Route>
        <Route exact path="/spots/:fed/login" element={<LoginRoute/>}>
          <Route path="/spots/:fed/login" element={<LoginSbfToken/>}/>
        </Route>
        <Route exact path="/login/:fed" element={<LoginRoute/>}>
          <Route path="/login/:fed" element={<Login/>}/>
        </Route>
        <Route exact path="/sharedSessions" element={<PrivateRoute/>}>
          <Route exact path="/sharedSessions" element={<SharedSessions/>}/>
        </Route>



        <Route exact path="/flagged" element={<PrivateRoute/>}>
          <Route
            exact
            path="/flagged"
            element={<FlaggedSessionDealsPage/>}
          />
        </Route>

        <Route exact path="/mock" element={<SessionDealMock/>}/>

        <Route exact path="/practice" element={<PrivateRoute/>}>
          <Route exact path="/practice" element={<Practice/>}/>
        </Route>

        <Route exact path="/createDealType" element={<PrivateRoute/>}>
          <Route exact path="/createDealType" element={<CreateDealType/>}/>
        </Route>

        <Route exact path="/editDealType/:id" element={<PrivateRoute/>}>
          <Route exact path="/editDealType/:id" element={<EditDealType/>}/>
        </Route>

        <Route exact path="/communityScenarios" element={<PrivateRoute/>}>
          <Route exact path="/communityScenarios"
            element={<CommunityScenarios/>}/>
        </Route>

        <Route exact path="/manageDealTypes" element={<PrivateRoute/>}>
          <Route exact path="/manageDealTypes"
            element={<ManageDealTypes/>}/>
        </Route>

        <Route exact path="/junior" element={<PrivateRoute/>}>
          <Route exact path="/junior" element={<JuniorApply/>}/>
        </Route>

        <Route path="/code" element={<PrivateRoute/>}>
          <Route path="/code" element={<Codes/>}/>
        </Route>

        <Route path="/code/:code" element={<PrivateRoute/>}>
          <Route path="/code/:code" element={<Codes/>}/>
        </Route>

        <Route exact path="/events" element={<PrivateRoute/>}>
          <Route exact path="/events" element={<Events/>}/>
        </Route>

        <Route exact path="/challenge" element={<PrivateRoute/>}>
          <Route exact path="/challenge" element={<Challenge/>}/>
        </Route>

        <Route exact path="/challengeSession" element={<PrivateRoute/>}>
          <Route exact path="/challengeSession"
            element={<StartChallenge/>}/>
        </Route>

        <Route exact path="/challenge/match" element={<PrivateRoute/>}>
          <Route
            exact
            path="/challenge/match"
            element={<ChallengeMatchmaking/>}
          />
        </Route>

        <Route exact path="/challenge/archive" element={<PrivateRoute/>}>
          <Route
            exact
            path="/challenge/archive"
            element={<ChallengeArchive/>}
          />
        </Route>

        <Route exact path="/challenge/:id" element={<PrivateRoute/>}>
          <Route exact path="/challenge/:id" element={<ChallengeSession/>}/>
        </Route>

        <Route exact path="/friends" element={<PrivateRoute/>}>
          <Route exact path="/friends" element={<Friends/>}/>
        </Route>

        <Route exact path="/spots/:fed" element={<SpotDashboard/>}/>
        <Route exact path="/spots/:fed/:id" element={<SpotDisplay/>}/>
        <Route exact path="/spots/:fed/new" element={<CreateSpot/>}/>
        <Route exact path="/spots/:fed/:id/edit" element={<EditSpot/>}/>

        <Route exact path="/addFriend" element={<PrivateRoute/>}>
          <Route exact path="/addFriend" element={<AddFriend/>}/>
        </Route>

        <Route exact path="/addFriend/:newFriendKey"
          element={<PrivateRoute/>}>
          <Route
            exact
            path="/addFriend/:newFriendKey"
            element={<AddFriend/>}
          />
        </Route>

        {/* <Route exact path="/achievements" element={<PrivateRoute />}>*/}
        {/*  <Route exact path="/achievements" element={<Achievements />} />*/}
        {/* </Route>*/}

        <Route exact path="/pair/:user1/:user2" element={<PrivateRoute/>}>
          <Route exact path="/pair/:user1/:user2" element={<Pair/>}/>
        </Route>

        <Route exact path="/player/:user1" element={<PrivateRoute/>}>
          <Route exact path="/player/:user1" element={<Player/>}/>
        </Route>

        <Route exact path="/help" element={<PrivateRoute/>}>
          <Route exact path="/help" element={<Help/>}/>
        </Route>

        <Route exact path="/admin" element={<AdminRoute/>}>
          <Route exact path="/admin" element={<Admin/>}/>
        </Route>

        {/* <Route exact path="/testScoring" element={<AdminRoute />}> */}
        {/*   <Route exact path="/testScoring" element={<TestScoring />} /> */}
        {/* </Route> */}

        {/* <Route exact path="/plotScoring" element={<AdminRoute />}> */}
        {/*   <Route exact path="/plotScoring" element={<PlotScoring />} /> */}
        {/* </Route> */}

        <Route exact path="/session/:id" element={<PrivateRoute/>}>
          <Route exact path="/session/:id" element={<Session/>}/>
        </Route>

        <Route exact path="/session" element={<PrivateRoute/>}>
          <Route exact path="/session" element={<CreateNewSession/>}/>
        </Route>

        <Route exact path="/practice/match" element={<PrivateRoute/>}>
          <Route
            exact
            path="/practice/match"
            element={<CreateMatchmakingSession/>}
          />
        </Route>

        <Route
          exact
          path="/session/deal/:id"
          element={<PrivatePublicRoute
            publicRoute={<SessionDealPublic/>}/>}
        >
          <Route exact path="/session/deal/:id" element={<SessionDeal/>}/>
        </Route>

        <Route exact path="/session/archive" element={<PrivateRoute/>}>
          <Route exact path="/session/archive" element={<SessionArchive/>}/>
        </Route>

        <Route exact path="/weekly" element={<PrivateRoute/>}>
          <Route exact path="/weekly" element={<JoinWeeklySession/>}/>
        </Route>

        <Route exact path="/weeklyLeaderboard/:id"
          element={<PrivateRoute/>}>
          <Route
            exact
            path="/weeklyLeaderboard/:id"
            element={<WeeklyLeaderBoard/>}
          />
        </Route>

        <Route exact path="/dailyLeaderboard/:id" element={<PrivateRoute/>}>
          <Route
            exact
            path="/dailyLeaderboard/:id"
            element={<DailyLeaderBoard/>}
          />
        </Route>

        <Route exact path="/store" element={<PrivateRoute/>}>
          <Route exact path="/store" element={<Store/>}/>
        </Route>

        <Route
          exact
          path="/thanks/:subscriptionLevel"
          element={<PrivateRoute/>}
        >
          <Route
            exact
            path="/thanks/:subscriptionLevel"
            element={<ThanksPage/>}
          />
        </Route>

        <Route exact path="/messages" element={<PrivateRoute/>}>
          <Route exact path="/messages" element={<Messages/>}/>
        </Route>

        <Route exact path="/stats" element={<PrivateRoute/>}>
          <Route exact path="/stats" element={<StatsOverview/>}/>
        </Route>

        <Route exact path="/dashboard" element={<PrivateRoute/>}>
          <Route exact path="/dashboard" element={<DashBoard/>}/>
        </Route>

        <Route exact path="/groups" element={<PrivateRoute/>}>
          <Route exact path="/groups" element={<GroupList/>}/>
        </Route>

        <Route exact path="/createGroup" element={<PrivateRoute/>}>
          <Route exact path="/createGroup" element={<CreateGroup/>}/>
        </Route>

        <Route exact path="/groups/:groupId" element={<PrivateRoute/>}>
          <Route exact path="/groups/:groupId" element={<Group/>}/>
        </Route>

        <Route exact path="/groupPublic/:groupId" element={<PrivateRoute/>}>
          <Route exact path="/groupPublic/:groupId"
            element={<PublicGroup/>}/>
        </Route>


        <Route exact path="/joinGroup/:groupId" element={<PrivateRoute/>}>
          <Route exact path="/joinGroup/:groupId"
            element={<JoinGroupByLink/>}/>
        </Route>

        <Route exact path="/groups/:groupId/manage"
          element={<PrivateRoute/>}>
          <Route
            exact
            path="/groups/:groupId/manage"
            element={<ManageGroup/>}
          />
        </Route>

        <Route
          exact
          path="/groups/:groupId/addMembers"
          element={<PrivateRoute/>}
        >
          <Route
            exact
            path="/groups/:groupId/addMembers"
            element={<AddMembersToGroup/>}
          />
        </Route>

        <Route
          exact
          path="/groups/:groupId/createEvent"
          element={<PrivateRoute/>}
        >
          <Route
            exact
            path="/groups/:groupId/createEvent"
            element={<CreateGroupSession/>}
          />
        </Route>


        <Route
          exact
          path="/groups/:groupId/editEvent/:groupSessionId"
          element={<PrivateRoute/>}
        >
          <Route
            exact
            path="/groups/:groupId/editEvent/:groupSessionId"
            element={<EditGroupSession/>}
          />
        </Route>

        <Route
          exact
          path="/groups/:groupId/pbnImport"
          element={<PrivateRoute/>}
        >
          <Route
            exact
            path="/groups/:groupId/pbnImport"
            element={<PbnImport/>}
          />
        </Route>

        <Route
          exact
          path="/eventDraft/:eventId"
          element={<PrivateRoute/>}
        >
          <Route
            exact
            path="/eventDraft/:eventId"
            element={<EventDraft/>}
          />
        </Route>

        <Route
          exact
          path="/groups/:groupId/joinEvent/:id"
          element={<PrivateRoute/>}
        >
          <Route
            exact
            path="/groups/:groupId/joinEvent/:id"
            element={<JoinGroupSession/>}
          />
        </Route>

        <Route
          exact
          path="/groups/:groupId/eventLeaderboard/:id"
          element={<PrivateRoute/>}
        >
          <Route
            exact
            path="/groups/:groupId/eventLeaderboard/:id"
            element={<GroupSessionLeaderboard/>}
          />
        </Route>

        <Route
          exact
          path="/groups/:groupId/event/:eventId/coach/:dealId"
          element={<PrivateRoute/>}
        >
          <Route
            exact
            path="/groups/:groupId/event/:eventId/coach/:dealId"
            element={<CoachSessionDeal/>}
          />
        </Route>

        <Route
          exact
          path="/groups/:groupId/event/:eventId/coachPresent/:dealId"
          element={<PrivateRoute/>}
        >
          <Route
            exact
            path="/groups/:groupId/event/:eventId/coachPresent/:dealId"
            element={<CoachSessionDealPresentation/>}
          />
        </Route>

        <Route
          exact
          path="/groups/:groupId/event/:eventId/coach"
          element={<PrivateRoute/>}
        >
          <Route
            exact
            path="/groups/:groupId/event/:eventId/coach"
            element={<CoachSessionDeal/>}
          />
        </Route>

        <Route exact path="/daily" element={<PrivateRoute/>}>
          <Route exact path="/daily" element={<JoinDailySession/>}/>
        </Route>

        <Route exact path="/contact" element={<PrivateRoute/>}>
          <Route exact path="/contact" element={<Contact/>}/>
        </Route>

        <Route exact path="/contact/sales" element={<PrivateRoute/>}>
          <Route exact path="/contact/sales" element={<ContactSales/>}/>
        </Route>

        <Route exact path="/settings" element={<PrivateRoute/>}>
          <Route exact path="/settings" element={<Profile/>}/>
        </Route>
      </Route>
    )
  );
}

function App() {
  const metadata = useAppStateStore((state) => state.metadata);

  useEffect(() => {
    const theme = localStorage.getItem('theme')
    document.documentElement.setAttribute('data-theme', theme ?? 'dark');
  }, [])

  useEffect(function () {
    const isOnSwedishSpotsPage = swedishSpotPages.includes(window?.location?.hostname)

    if (isOnSwedishSpotsPage) {
      window.document.title = 'Cuebids - Finslipa din budgivning';
    }
  }, [])

  return (
    <I18nextProvider i18n={i18next}>
      <div id="backdrop"></div>
      <div className="hidden">
        <img src={logo} alt=""/>
        <Hand
          variant="classic"
          hand="AKQJT98765432.AKQJT98765432.AKQJT98765432.AKQJT98765432"
        />
        <PreloadSubIcon/>
        <PreloadRankIcons/>
        <BidsPreload/>
        <QuestionIcon/>
        <Spade dark/>
        <Heart dark/>
        <Diamond dark/>
        <Club dark/>
        <Spade/>
        <Heart/>
        <Diamond/>
        <Club/>
      </div>
      <div id="site">
        <DisplayProvider>
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider
                router={getAppRouter(metadata)}
                fallbackElement={<span>Loading</span>}
              />
            </QueryClientProvider>
          </AuthProvider>
        </DisplayProvider>
      </div>
    </I18nextProvider>
  );
}

export default App;
