import { useRef, useEffect, useState } from 'react';
import BidDisplay from './bidDisplay';
import { cuebidsToPbn, disableLogic, replaceSuitShorthandWithSymbols } from 'cuebids-bidding-util';
import Tooltip from '../tooltip/tooltip.jsx';
import { useTranslation } from 'react-i18next';

const suits = ['C', 'D', 'H', 'S', 'N'];

const values = [1, 2, 3, 4, 5, 6, 7];

export default function BiddingBox({ bidding, alerts, onBid, acceptShortcuts }) {
  const { t } = useTranslation('translation', { keyPrefix: 'components.bidding_box' });
  const [currentBiddingLevelTab, setCurrentBiddingLevelTab] = useState(null);
  const keydownRef = useRef();

  const [showMobileTooltip, setShowMobileTooltip] = useState('');

  const { disableD, disableR, disableBelow } = disableLogic(bidding);

  const highestSuit = suits.indexOf(disableBelow[1]);
  const highestValue = parseInt(disableBelow[0]);

  let lowestTab = highestSuit === 4 ? highestValue + 1 : highestValue;
  if (!lowestTab) {
    lowestTab = 1;
  }

  useEffect(() => {
    setCurrentBiddingLevelTab(lowestTab);
  }, [lowestTab]);

  useEffect(
    function () {
      keydownRef.current = (event) => {
        if (!acceptShortcuts) {
          return;
        }
        const upperCaseKey = event.key.toUpperCase();

        if (upperCaseKey === 'P') {
          onBid('P');
        }

        if (upperCaseKey === 'X' && !disableD) {
          onBid('D');
        }

        if (upperCaseKey === 'R' && !disableR) {
          onBid('R');
        }

        if (
          ['1', '2', '3', '4', '5', '6', '7'].includes(upperCaseKey) &&
          parseInt(event.key) >= lowestTab
        ) {
          setCurrentBiddingLevelTab(parseInt(upperCaseKey));
        }
        const suit = upperCaseKey;
        const suits = ['C', 'D', 'H', 'S', 'N'];
        if (!suits.includes(suit) || currentBiddingLevelTab > 7) {
          return;
        }
        const si = suits.indexOf(suit);
        if (si > highestSuit || currentBiddingLevelTab > highestValue) {
          onBid(currentBiddingLevelTab + suit);
        }
      };
    },
    [
      acceptShortcuts,
      lowestTab,
      highestSuit,
      highestValue,
      currentBiddingLevelTab,
      onBid,
      disableD,
      disableR,
    ]
  );

  useEffect(() => {
    const eventHandler = (event) => {
      keydownRef.current?.(event);
    };
    document.addEventListener('keydown', eventHandler);
    return () => {
      document.removeEventListener('keydown', eventHandler);
    };
  }, []);

  const passAlertParts = alerts?.[cuebidsToPbn('P')]?.split('\n').map(part => part.trim());
  const doubleAlertParts = alerts?.[cuebidsToPbn('D')]?.split('\n').map(part => part.trim());
  const redoubleAlertParts = alerts?.[cuebidsToPbn('R')]?.split('\n').map(part => part.trim());

  return (
    <div className="fade-in my-4 flex flex-col items-center justify-center">
      <div className="tabs tabs-lg">
        {values
          .filter((v) => v >= lowestTab)
          .map((v) => {
            return (
              <a
                role="tab"
                key={v}
                className={
                  'tab rounded-t-md bg-accent bg-opacity-25 text-xl ' +
                  (currentBiddingLevelTab === v
                    ? 'tab-active bg-opacity-75'
                    : '')
                }
                onClick={() => setCurrentBiddingLevelTab(v)}
              >
                {v}
              </a>
            );
          })}
      </div>
      <div className="card flex min-w-[400px] flex-row items-center justify-between bg-accent bg-opacity-75 p-4 ">
        <div className="flex flex-row items-center">
          {suits.map((s, si) => {
            const bid = `${currentBiddingLevelTab + s}`;
            const parts = alerts?.[cuebidsToPbn(bid)]?.split('\n').map(part => part.trim());
            if(currentBiddingLevelTab > 7) {
              return s === 'N' ? t('good_luck') : '';
            }
            return (
              <button
                className="m-[1px] border-0 p-0"
                data-tooltip-id={bid}
                type="button"
                disabled={
                  !(
                    si > highestSuit || currentBiddingLevelTab > highestValue
                  ) || currentBiddingLevelTab > 7
                }
                style={
                  si > highestSuit || currentBiddingLevelTab > highestValue
                    ? { opacity: 1 }
                    : { opacity: 0.1 }
                }
                key={s + currentBiddingLevelTab}
                onClick={() => onBid(bid)}
                onMouseEnter={() => setShowMobileTooltip(bid)}
                onMouseLeave={() => setShowMobileTooltip(null)}
                onTouchStart={() => setShowMobileTooltip(bid)}
                onTouchEnd={() => setShowMobileTooltip(null)}
              >
                <Tooltip
                  id={bid}
                  sx="slated"
                  open={showMobileTooltip === bid}
                >
                  {parts?.map((part, index) => (
                    <span key={index}>
                      {replaceSuitShorthandWithSymbols(part.replaceAll('—', '-'))}
                      {index !== parts.length - 1 && <br />}
                    </span>
                  ))}
                </Tooltip>
                <BidDisplay bid={bid} size={'40px'} />
              </button>
            );
          })}
        </div>
        <div className="ml-6 flex flex-row items-center justify-center">
          {!disableR && (
            redoubleAlertParts ? (
              <button type="button" className="mr-2" onClick={() => onBid('R')}
                data-tooltip-id={'R'}
                onMouseEnter={() => setShowMobileTooltip('R')}
                onMouseLeave={() => setShowMobileTooltip(null)}
                onTouchStart={() => setShowMobileTooltip('R')}
                onTouchEnd={() => setShowMobileTooltip(null)}
              >
                <Tooltip
                  id="R"
                  sx="slated"
                  open={showMobileTooltip === 'R'}
                >
                  {redoubleAlertParts.map((part, index) => (
                    <span key={index}>
                      {replaceSuitShorthandWithSymbols(part.replaceAll('—', '-'))}
                      {index !== redoubleAlertParts.length - 1 && <br />}
                    </span>
                  ))}
                </Tooltip>
                <BidDisplay bid={'R'} size={'40px'} />
              </button>
            ) : (
              <button type="button" className="mr-2" onClick={() => onBid('R')}>
                <BidDisplay bid={'R'} size={'40px'} />
              </button>
            )
          )}
          {!disableD && (
            doubleAlertParts ? (
              <button type="button" className="mr-2" onClick={() => onBid('D')}
                data-tooltip-id={'D'}
                onMouseEnter={() => setShowMobileTooltip('D')}
                onMouseLeave={() => setShowMobileTooltip(null)}
                onTouchStart={() => setShowMobileTooltip('D')}
                onTouchEnd={() => setShowMobileTooltip(null)}
              >
                <Tooltip
                  id="D"
                  sx="slated"
                  open={showMobileTooltip === 'D'}
                >
                  {doubleAlertParts.map((part, index) => (
                    <span key={index}>
                      {replaceSuitShorthandWithSymbols(part.replaceAll('—', '-'))}
                      {index !== doubleAlertParts.length - 1 && <br />}
                    </span>
                  ))}
                </Tooltip>
                <BidDisplay bid={'D'} size={'40px'} />
              </button>
            ) : (
              <button type="button" className="mr-2" onClick={() => onBid('D')}>
                <BidDisplay bid={'D'} size={'40px'} />
              </button>
            )
          )}
          {passAlertParts ? (
            <button
              type="button"
              className="relative btn-primary btn text-xl text-white"
              key={'P'}
              onClick={() => onBid('P')}
              data-tooltip-id={'P'}
              onMouseEnter={() => setShowMobileTooltip('P')}
              onMouseLeave={() => setShowMobileTooltip(null)}
              onTouchStart={() => setShowMobileTooltip('P')}
              onTouchEnd={() => setShowMobileTooltip(null)}
            >
              <Tooltip
                id="P"
                sx="slated !text-sm font-normal normal-case"
                open={showMobileTooltip === 'P'}
              >
                {passAlertParts.map((part, index) => (
                  <span key={index}>
                    {replaceSuitShorthandWithSymbols(part.replaceAll('—', '-'))}
                    {index !== passAlertParts.length - 1 && <br />}
                  </span>
                ))}
              </Tooltip>
              PASS
            </button>
          ) : (
            <button
              type="button"
              className="btn-primary btn text-xl text-white"
              key={'P'}
              onClick={() => onBid('P')}
            >
            PASS
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
