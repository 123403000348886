import { ranges } from '../constants';

const multi = [
  {
    biddingMatch: /^\.?(-\.)*(-P){0,2}$/,
    explain: 'no one opened, but not in fourth seat',
    options: [
      {
        bid: '2D[@"Weak, 6+!H or !S"]',
        bidWithoutAlert: '2D',
        reqH: 6,
        reqMaxS: 4,
        reqMaxHp: ranges.weakOpen,
      },
      {
        bid: '2D[@"Weak, 6+!H or !S"]',
        bidWithoutAlert: '2D',
        reqS: 6,
        reqMaxH: 4,
        reqMaxHp: ranges.weakOpen,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-D$/,
    explain: 'Partner opens 2D multi, show own suit over D',
    options: [
      {
        bid: 'R[@"Own long suit"]',
        reqH: 6,
        reqMaxS: 1,
      },
      {
        bid: 'R[@"Own long suit"]',
        reqS: 6,
        reqMaxH: 1,
      },
      {
        bid: 'R[@"Own long suit"]',
        reqC: 6,
        reqMaxH: 1,
      },
      {
        bid: 'R[@"Own long suit"]',
        reqC: 6,
        reqMaxS: 1,
      },
      // Note: Diamond suit handled below (pass)
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-D-R-P$/,
    explain: 'We open 2D multi, partner shows own suit over D',
    options: [
      {
        bid: '2H[@"Pass or correct"]',
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-D-R-P-2H-[PD]$/,
    explain: 'Partner opens 2D multi, we showed own suit over D, show what suit it is',
    options: [
      {
        bid: '2H["Natural"]',
        reqH: 6,
      },
      {
        bid: '2S["Natural"]',
        reqS: 6,
      },
      {
        bid: '3C["Natural"]',
        reqC: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-[PD]$/,
    explain: 'Partner opens 2D multi, bid P/C',
    options: [
      {
        bid: 'P["Long !D"]',
        reqD: 6,
        reqMaxS: 1,
      },
      {
        bid: 'P["Long !D"]',
        reqD: 6,
        reqMaxH: 1,
      },
      {
        bid: '2H[@"Pass or correct"]',
      },
      {
        bid: '2S[@"Pass or correct"]',
        reqMaxS: 1,
        reqH: 3,
        weight: 2,
      },
      {
        bid: '3H[@"Pass or correct"]',
        reqS: 3,
        reqH: 3,
        weight: 2,
      },
      {
        bid: '3S[@"Pass or correct"]',
        reqS: 3,
        reqMaxS: 3,
        reqH: 4,
        weight: 3,
      },
      {
        bid: '4H[@"Pass or correct"]',
        reqS: 4,
        reqH: 4,
        weight: 3,
      },
      {
        bid: '4H[@"Pass or correct"]',
        reqS: 3,
        reqH: 3,
        reqHp: 15,
        weight: 4,
      },
      {
        bid: '4H[@"Pass or correct"]',
        reqS: 2,
        reqH: 2,
        reqHp: 18,
        weight: 4,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-(2N|3C|3D|3N|4C|4D)$/,
    explain: 'Partner opens 2D multi, bid P/C over minor/NT competition',
    options: [
      {
        bid: '3H[@"Pass or correct"]',
        reqHp: ranges.raise,
        reqS: 3,
        reqH: 3,
      },
      {
        bid: '3H[@"Pass or correct"]',
        reqHp: ranges.weakRaise,
        reqS: 4,
        reqH: 4,
      },
      {
        bid: '3S[@"Pass or correct"]',
        reqHp: ranges.raise,
        reqS: 3,
        reqMaxS: 3,
        reqH: 4,
        weight: 2,
      },
      {
        bid: '4H[@"Pass or correct"]',
        reqHp: ranges.raise,
        reqS: 4,
        reqH: 4,
        weight: 2,
      },
      {
        bid: '4H[@"Pass or correct"]',
        reqS: 3,
        reqH: 3,
        reqHp: 15,
        weight: 3,
      },
      {
        bid: '4H[@"Pass or correct"]',
        reqS: 2,
        reqH: 2,
        reqHp: 18,
        weight: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-[PD]-2H-[PD]$/,
    explain: 'We open 2D multi, partner bid 2H P/C',
    options: [
      {
        bid: '2S["6+!S"]',
        reqS: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-[PD]-2H-[PD]-2S-(.|..)$/,
    explain: 'Partner opens 2D multi and corrects to 2S, bid again?',
    options: [
      {
        bid: '3S["To play"]',
        reqS: 3,
      },
      {
        bid: '4S["To play"]',
        reqS: 4,
        weight: 2,
      },
      {
        bid: '4S["To play"]',
        reqS: 3,
        reqHp: 15,
        weight: 2,
      },
      {
        bid: '4S["To play"]',
        reqS: 2,
        reqHp: 18,
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-(.|..)-2S-(.|..)$/,
    explain: 'We open 2D multi, partner bid 2S P/C',
    options: [
      {
        bid: '3H["6+!H"]',
        reqH: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-(.|..)-2S-(.|..)-3H-(.|..)$/,
    explain: 'Partner opens 2D multi and corrects to 3H, bid again?',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 4,
      },
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: 15,
      },
      {
        bid: '4H["To play"]',
        reqH: 2,
        reqHp: 18,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-(.|..)-3H-[PD]$/,
    explain: 'We open 2D multi, partner bid 3H P/C',
    options: [
      {
        bid: '3S["6+!S"]',
        reqS: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-(.|..)-3H-[PD]-3S-(.|..)$/,
    explain: 'Partner opens 2D multi and corrects to 3S, bid again?',
    options: [
      {
        bid: '4S["To play"]',
        reqS: 4,
      },
      {
        bid: '4S["To play"]',
        reqS: 3,
        reqHp: 15,
      },
      {
        bid: '4S["To play"]',
        reqS: 2,
        reqHp: 18,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-(.|..)-3S-(.|..)$/,
    explain: 'We open 2D multi, partner bid 3S P/C',
    options: [
      {
        bid: '4H["6+!H"]',
        reqH: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-(.|..)-4H-[PD]$/,
    explain: 'We open 2D multi, partner bid 4H P/C',
    options: [
      {
        bid: '4S["6+!S"]',
        reqS: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-2H$/,
    explain: 'Partner opens 2D multi, bid D as P/C over 2H competition',
    options: [
      {
        bid: 'D[@"Pass or correct"]',
        reqS: 2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-2H-D-[PR]$/,
    explain: 'We open 2D multi, bid over D as P/C over 2H competition',
    options: [
      {
        bid: 'P["6+!H"]',
        reqH: 6,
      },
      {
        bid: '2S["6+!S"]',
        reqS: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-2H-D-[PR]-2S-(.|..)$/,
    explain: 'Partner opens 2D multi, and corrects to 2S over D as P/C, bid again?',
    options: [
      {
        bid: '3S["To play"]',
        reqS: 3,
      },
      {
        bid: '4S["To play"]',
        reqS: 4,
        weight: 2,
      },
      {
        bid: '4S["To play"]',
        reqS: 3,
        reqHp: 15,
        weight: 2,
      },
      {
        bid: '4S["To play"]',
        reqS: 2,
        reqHp: 18,
        weight: 2,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-2S$/,
    explain: 'Partner opens 2D multi, bid D as P/C over 2S competition',
    options: [
      {
        bid: 'D[@"Pass or correct"]',
        reqH: 3,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-2S-D-[PR]$/,
    explain: 'We open 2D multi, bid over D as P/C over 2S competition',
    options: [
      {
        bid: 'P["6+!S"]',
        reqS: 6,
      },
      {
        bid: '3H["6+!H"]',
        reqH: 6,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-2S-D-[PR]-3H-(.|..)$/,
    explain: 'Partner opens 2D multi and corrects to 3H over D as P/C, bid again?',
    options: [
      {
        bid: '4H["To play"]',
        reqH: 4,
      },
      {
        bid: '4H["To play"]',
        reqH: 3,
        reqHp: 15,
      },
      {
        bid: '4H["To play"]',
        reqH: 2,
        reqHp: 18,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-3H$/,
    explain: 'Partner opens 2D multi, bid D as Pen over 3H competition',
    options: [
      {
        bid: 'D["Penalty"]',
        reqH: 4,
        reqHp: ranges.penaltyDouble,
      },
      {
        bid: 'D["Penalty"]',
        reqH: 5,
        reqHp: ranges.open,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-3S$/,
    explain: 'Partner opens 2D multi, bid D as Pen over 3S competition',
    options: [
      {
        bid: 'D["Penalty"]',
        reqS: 4,
        reqHp: ranges.penaltyDouble,
      },
      {
        bid: 'D["Penalty"]',
        reqS: 5,
        reqHp: ranges.open,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-4H$/,
    explain: 'Partner opens 2D multi, bid D as Pen over 4H competition',
    options: [
      {
        bid: 'D["Penalty"]',
        reqH: 4,
        reqHp: ranges.penaltyDouble,
      },
      {
        bid: 'D["Penalty"]',
        reqH: 5,
        reqHp: ranges.open,
      },
    ],
  },
  {
    biddingMatch: /^\.?(-\.)*(-P)*-2D-4S$/,
    explain: 'Partner opens 2D multi, bid D as Pen over 4S competition',
    options: [
      {
        bid: 'D["Penalty"]',
        reqS: 4,
        reqHp: ranges.penaltyDouble,
      },
      {
        bid: 'D["Penalty"]',
        reqS: 5,
        reqHp: ranges.open,
      },
    ],
  },

];

export default multi;
