import { ContractDisplay } from '../../../components/bidding/bidDisplay.jsx';
import { getContractDeclarer } from 'cuebids-bidding-util';
import InfoPopup from '../../../components/infoPopup/infoPopup.jsx';
import { useTranslation } from 'react-i18next';


const ExplainEv = () => {
  const { t } = useTranslation();
  return (
    <span className="text-base">
      {t('session_deal_result.explain_ev')}
    </span>
  );
};

export default function ContractEvDisplay({ contract, isSmall, ev, me }) {
  const { t } = useTranslation();
  
  return (
    <>
      <div>{me ? t('session_deal_result.you')
        : (isSmall ?  t('session_deal_result.best')
          :  t('session_deal_result.best_contract'))}</div>
      <div className="flex flex-col items-center h-[100px] md:h-[120px]">
        <ContractDisplay
          contract={contract}
          size={isSmall ? 50 : undefined}
        />
        <div>{t(`bridge.directions.${getContractDeclarer(contract)}`) ?? <span>None</span>}</div>
        {
          me ? <span className="info leading-6">{ev}</span>
            :  <InfoPopup
              id="tooltip-ev"
              anchorElement={
                <div data-tooltip-id="tooltip-ev">
                  <span className="info">{ev}</span>
                </div>
              }
            >
              <ExplainEv />
            </InfoPopup>
        }
      </div>
    </>
  )
}
