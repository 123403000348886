import { db } from './firebase';
import { collection, doc, getDoc, onSnapshot } from 'firebase/firestore'
import { getAllResponsesToSessionDeal } from './biddingSessions';
import { docToObject } from 'firebase-util'

function reviewAdapter(review) {
  if (!review) {
    review = {};
  }
  if (!review.results) {
    review.results = [];
  }
  review.results.forEach(function(r) {
    if (r.compete == null || r.compete === false) {
      r.compete = 0;
    } else if (r.compete === true) {
      r.compete = 1;
    }
    if (!r.parBeatBest) {
      r.parBeatBest = false;
    }
  });

  return review;
}

function filterOnSameSettings(review, compete, evaluationVersion) {
  return review.filter(function(r) {
    // Some reviews were saved without evaluationVersion, exclude them to avoid confusion.
    if (r.evaluationVersion === undefined) {
      return false;
    }

    return r.evaluationVersion === evaluationVersion;
  });
}

export function getReviewResultKey({ contract, grade, parBeatBest }) {
  return `${contract}-${grade}-${!!parBeatBest}`;
}

function addResultIfNotThere(array, result) {
  if (
    !array.some(function(r) {
      return r.users[0] === result.users[0] && r.users[1] === result.users[1];
    })
  ) {
    array.push(result);
  }
}

export async function getDealReview({
  dealId,
  withReview= true,
  compete,
  evaluationVersion,
  result,
}) {
  if (withReview) {
    // We had an issue that the deal review was fetched before the batch.commit that finished a deal was done.
    // This, combined with how we update the review (set with merge + arrayUnion) somehow gave us only our result and nothing from server.
    // To get around this we use a snapshot listener that waits for pending writes to be done.
    return new Promise(function(resolve) {
      const reviewRef = doc(collection(db, 'dealReviews'), dealId);
      const unsubscribe = onSnapshot(
        reviewRef,
        { includeMetadataChanges: true },
        (querySnapshot) => {
          if (
            querySnapshot.metadata.fromCache ||
            querySnapshot.metadata.hasPendingWrites
          ) {
            return;
          }
          const data = reviewAdapter(querySnapshot.data());
          const filteredResults = filterOnSameSettings(
            data.results,
            compete,
            evaluationVersion,
          );
          addResultIfNotThere(filteredResults, result);
          unsubscribe();
          resolve(filteredResults);
        },
      );
    });
  } else {
    const rev = await getAllResponsesToSessionDeal({
      dealId,
      compete,
      evaluationVersion,
    });

    addResultIfNotThere(rev, result);

    return rev;
  }
}


export async function getCoachReview(dealId) {
  const reviewRef = doc(collection(db, 'dealReviews'), dealId);
  const reviewDoc = await getDoc(reviewRef);

  // Note: We do not pass adapter to docToObject, since we want to convert null to a review
  return reviewAdapter(docToObject(reviewDoc)).results;
}
