import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

function getNotificationCssClass(type) {
  switch (type) {
  case 'success':
    return 'alert-success bg-success bg-opacity-95 border border-success text-white';
  case 'warning':
    return 'alert-warning bg-warning bg-opacity-95 border border-warning text-white';
  case 'failure':
    return 'alert-error bg-error bg-opacity-95 border border-error text-white';
  case 'error':
    return 'alert-error bg-error bg-opacity-95 border border-error text-white';
  case 'info':
    return 'alert-info bg-info bg-opacity-95 border border-info text-white';
  default:
    return 'alert-info bg-info bg-opacity-95 border border-info text-white';
  }
}

export default function useNotifications() {
  const nav = useNavigate();

  return useCallback(function (notification) {
    const t = toast(notification.title, {
      className: getNotificationCssClass(notification.type),
      position: 'bottom-center',
      description: notification.text,
      duration: notification.autoHide ?? 4000,
      action: notification.url ? (
        <div className="grow flex justify-end">
          <kbd
            className="kbd text-sm bg-transparent border-2 cursor-pointer"
            onClick={() => {
              toast.dismiss(t);
              const base = 'https://cuebids.com';
              let url = notification.url.replace(base, '');
              nav(url);
            }}
          >
            ▶
          </kbd>
        </div>
      ) : (
        ''
      ),
    });
  }, [nav]);
}
